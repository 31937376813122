import React, { CSSProperties, useEffect, useState } from 'react';
import { components } from 'react-select';
import { KeyValueModel } from '../../../models';
import styles from './SelectStyle.module.scss';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
interface IInput {
  error?: string;
  placeholder?: string;
  type?: string;
  value: any;
  handleChange?: (value: any) => void;
  handleUnselect?: () => void;
  customClasses?: any;
  height?: string | number;
  name?: string;
  wrapperStyles?: CSSProperties;
  disabled?: boolean;
  // Additional in MenuList on top of the options
  topAdditional?: JSX.Element;
  // Additional in MenuList on bottom of the options
  bottomAdditional?: JSX.Element;
  isMulti?: boolean;
  selectedTimezone?: any;
  onChange?: any;
  ariaLabel?: string;
  noError?: boolean;
}

dayjs.extend(timezone);
dayjs.extend(utc);

const MenuList = (
  { children, ...rest }: any,
  topAdditional?: JSX.Element,
  bottomAdditional?: JSX.Element,
) => {
  return (
    <components.MenuList {...rest}>
      {topAdditional}
      {children}
      {bottomAdditional}
    </components.MenuList>
  );
};

const TimeZoneSelectView = (props: IInput) => {
  const {
    error,
    placeholder,
    value,
    customClasses,
    height,
    name,
    wrapperStyles,
    topAdditional,
    bottomAdditional,
    onChange,
    ariaLabel,
    noError,
  } = props;
  const [isFocus, setFocus] = useState(false);
  const [timeZonesList, setTimeZonesList] = useState({});

  const getBorderColor = (isFocused: boolean) => {
    if (error) {
      return 'red';
    }
    if (isFocused) {
      return 'var(--primary-60)';
    }
    return 'var(--gray-30)';
  };

  const getOptionBackground = (isFocused: boolean) => {
    if (isFocused) {
      return 'var(--primary-60)';
    }
    return 'transparent';
  };

  const customStyles = {
    control: (base: any, { isFocused }: any) => ({
      ...base,
      minHeight: height || '52px',
      color: 'var(--gray-60)',
      background: 'inherit',
      opacity: '1',
      borderColor: getBorderColor(isFocused),
      boxShadow: isFocused ? ' 0 0 0 1px var(--primary-60)' : 0,
    }),
    singleValue: (base: any) => ({
      ...base,
      color: 'var(--primary-navy)',
      fontFamily: 'Rubik',
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 9999,
      color: 'var(--primary-navy)',
      background: 'var(--background)',
    }),
    option: (base: any, { isFocused }: any) => {
      return {
        ...base,
        backgroundColor: `${getOptionBackground(isFocused)} !important`,
        color: isFocused ? 'var(--white-black)' : 'var(--primary-navy)',
      };
    },
    input: (base: any) => ({
      ...base,
      color: 'var(--gray-60)',
    }),
    placeholder: (base: any) => ({
      ...base,
      color: 'var(--gray-60)',
    }),
  };

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  useEffect(() => {
    const timeZonesList: KeyValueModel<string> = {};
    Object.keys(allTimezones).map(key => {
      if (dayjs().tz(key).format('Z').split(':')[1] === '00') {
        timeZonesList[key] = allTimezones[key];
      }
    });
    setTimeZonesList(timeZonesList);
  }, []);
  //
  // setTimeout(() => {
  //   debugger;
  // }, 3000);

  return (
    <div style={wrapperStyles} className={styles.select_wrapper}>
      <div
        className={`${styles.input_placeholder_block} ${error ? styles.select_wrapper_error : ''} ${
          isFocus ? styles.labelFocus : ''
        }`}
        data-content={value ? placeholder : ''}
      >
        <TimezoneSelect
          value={value}
          onChange={onChange}
          timezones={{
            ...timeZonesList,
            'America/Lima': 'Pittsburgh',
            'Europe/Berlin': 'Frankfurt',
          }}
          name={name}
          placeholder={placeholder}
          aria-label={ariaLabel}
          components={{
            IndicatorSeparator: () => null,
            MenuList: props => MenuList(props, topAdditional, bottomAdditional),
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          styles={customStyles}
          className={`${(customClasses && customClasses) || ''} ${error ? styles.select_error : ''}`}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: 'var(--primary-60)',
              primary25: 'var(--gray-10)',
              neutral20: error ? 'red' : 'var(--gray-30)',
            },
          })}
        />
        {!noError ? (
          <div className={'error-notification'}>
            <span className={'error-notification-text'}>{error}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TimeZoneSelectView;
