import {
  JoinOrganizationModel,
  CreateOrganizationModel,
  AssociationOrganizationModel,
  ActionModel,
  OrganizationDetailsModel,
  AddNewUserModel,
  CreateAffiliationModel,
  JoinRequestModel,
  DefaultsOrgTab,
} from '../../models';
import { actionCreator } from '../actionCreator';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';
import { OrganizationState } from './organizationReducer';
import { ReqParamsModel } from '../../hooks/common/use-table-data';

//-----CREATE-ORGANIZATION----//

export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';

export interface CreateOrganizationAction {
  data: CreateOrganizationModel;
  callback: (response: any) => void;
}

export const createOrganization = actionCreator<CreateOrganizationAction>(CREATE_ORGANIZATION);

//-----JOIN-ORGANIZATION----//

export const JOIN_ORGANIZATION = 'JOIN_ORGANIZATION';

export interface JoinOrganizationAction {
  data: JoinOrganizationModel;
  callback: (response: any, errors: any) => void;
}

export const joinOrganization = actionCreator<JoinOrganizationAction>(JOIN_ORGANIZATION);

//-----ASSOCIATION-ORGANIZATION----//

export const ASSOCIATION_ORGANIZATION = 'ASSOCIATION_ORGANIZATION';

export interface AssociationOrganizationAction {
  data: AssociationOrganizationModel;
  callback: (response: any) => void;
}

export const associationOrganization = actionCreator<AssociationOrganizationAction>(ASSOCIATION_ORGANIZATION);

//-----CREATE-SUB-ORGANIZATION----//

export const CREATE_SUB_ORGANIZATION = 'CREATE_SUB_ORGANIZATION';

export interface CreateSubOrganizationAction {
  data: CreateOrganizationModel;
  callback: (response: any) => void;
}
export const createSubOrganization = actionCreator<CreateSubOrganizationAction>(CREATE_SUB_ORGANIZATION);

//-----GET_ORGANIZATION_DETAILS----//
export const GET_ORGANIZATION_DETAILS = 'GET_ORGANIZATION_DETAILS';

export interface GetOrganizationDetailsPayload {
  orgId: number;
  callback?: (res?: OrganizationDetailsModel, error?: any) => void;
}
export const getOrganizationDetails = actionCreator<GetOrganizationDetailsPayload>(GET_ORGANIZATION_DETAILS);

//-----GET_ORGANIZATION_DETAILS_SUCCESS----//
export const GET_ORGANIZATION_DETAILS_SUCCESS = 'GET_ORGANIZATION_DETAILS_SUCCESS';
export const getOrganizationDetailsSuccess = actionCreator<OrganizationDetailsModel>(
  GET_ORGANIZATION_DETAILS_SUCCESS,
);

//-----GET_ORGANIZATION_USERS----//
export const UPDATE_ORGANIZATION_DETAILS = ' UPDATE_ORGANIZATION_DETAILS';

export interface UpdateOrganizationDetailsPayload {
  orgId: number;
  data: CreateOrganizationModel | null;
  callback?: (response?: any, errors?: any) => void;
}
export const updateOrganizationDetails =
  actionCreator<UpdateOrganizationDetailsPayload>(UPDATE_ORGANIZATION_DETAILS);

//-----PATCH_ORGANIZATION----//
export const PATCH_ORGANIZATION_DETAILS = ' PATCH_ORGANIZATION_DETAILS';

export interface PatchOrganizationDetailsPayload {
  orgId: number;
  data: Partial<CreateOrganizationModel> | null;
  callback?: (response?: Partial<CreateOrganizationModel>, errors?: any) => void;
}
export const patchOrganizationDetails =
  actionCreator<PatchOrganizationDetailsPayload>(PATCH_ORGANIZATION_DETAILS);

//-----UPDATE_ORGANIZATION_DETAILS_SUCCESS----//
export const UPDATE_ORGANIZATION_DETAILS_SUCCESS = 'UPDATE_ORGANIZATION_DETAILS_SUCCESS';
export const updateOrganizationDetailsSuccess = actionCreator<OrganizationDetailsModel>(
  UPDATE_ORGANIZATION_DETAILS_SUCCESS,
);

//-----GET_ORGANIZATION_USERS----//
export const GET_ORGANIZATION_USERS = 'GET_ORGANIZATION_USERS';

export interface GetOrganizationUsersPayload {
  extraData?: {
    organization_id?: string;
  };
  params: ReqParamsModel;
  callback?: () => void;
}

export const getOrganizationUsers = actionCreator<GetOrganizationUsersPayload>(GET_ORGANIZATION_USERS);

//-----GET_ORGANIZATION_DETAILS_SUCCESS----//
export const GET_ORGANIZATION_USERS_SUCCESS = 'GET_ORGANIZATION_USERS_SUCCESS';

export interface GetOrganizationUsersModel {
  data: CreateOrganizationModel | null;
}

export const getOrganizationUsersSuccess = actionCreator<GetOrganizationUsersModel>(
  GET_ORGANIZATION_USERS_SUCCESS,
);

//-----CREATE_PROFILE_SUB_ORGANIZATION----//
export const CREATE_PROFILE_SUB_ORGANIZATION = 'CREATE_PROFILE_SUB_ORGANIZATION';

export interface CreateProfileSubOrganizationPayload {
  orgId: number;
  data: any;
  callback?: (response: any, error: any) => void;
}
export const createProfileSubOrganization = actionCreator<CreateProfileSubOrganizationPayload>(
  CREATE_PROFILE_SUB_ORGANIZATION,
);

//-----CREATE_PROFILE_SUB_ORGANIZATION_SUCCESS----//
export const CREATE_PROFILE_SUB_ORGANIZATION_SUCCESS = 'CREATE_PROFILE_SUB_ORGANIZATION_SUCCESS';
export const createProfileSubOrganizationSuccess = actionCreator<OrganizationDetailsModel>(
  CREATE_PROFILE_SUB_ORGANIZATION_SUCCESS,
);

//-----CREATE_USER_ORG_AFFILIATION----//
export const CREATE_USER_ORG_AFFILIATION = 'CREATE_USER_ORG_AFFILIATION';

export interface CreateAffiliationPayload {
  organization_id: string;
  data: CreateAffiliationModel;
  callback?: (response: any, error: any) => void;
}
export const createUserOrgAffiliation = actionCreator<CreateAffiliationPayload>(CREATE_USER_ORG_AFFILIATION);

//-----UPDATE_USER_ORG_AFFILIATION----//
export const UPDATE_USER_ORG_AFFILIATION = 'UPDATE_USER_ORG_AFFILIATION';
export interface UpdateAffiliationPayload {
  organization_id: string;
  affiliation_id: string;
  data: {
    user_type_id: number;
    user_role_id: number;
  };
  callback?: (response: any, error: any) => void;
}
export const updateUserOrgAffiliation = actionCreator<UpdateAffiliationPayload>(UPDATE_USER_ORG_AFFILIATION);

//-----ADD_NEW_USER----//
export const ADD_NEW_USER = 'ADD_NEW_USER';

export interface AddNewUserPayload {
  organization_id: string;
  data: AddNewUserModel;
  callback?: (response: any, error?: any) => void;
}
export const addNewUser = actionCreator<AddNewUserPayload>(ADD_NEW_USER);

//-----CREATE_USER_ORG_AFFILIATION----//
export const GET_USER_DETAILS = 'GET_USER_DETAILS';

interface GetUserDetailsModal {
  organization_id: string;
  id: string;
}

export interface GetUserDetailsPayload {
  data: GetUserDetailsModal;
  callback?: (response: any, error?: any) => void;
}
export const getUserDetails = actionCreator<GetUserDetailsPayload>(GET_USER_DETAILS);

//-----GET_AVAILABLE_ORGS----//
export const GET_AVAILABLE_ORGS = 'GET_AVAILABLE_ORGS';

interface GetAvailableOrgsModal {
  organization_id: string;
  id: string;
}

export interface GetAvailableOrgsPayload {
  data: GetAvailableOrgsModal;
  callback?: (response: any, error?: any) => void;
}
export const getAvailableOrgs = actionCreator<GetAvailableOrgsPayload>(GET_AVAILABLE_ORGS);

//-----USER_ORG_DEACTIVATED----//
export const USER_SWITCH_STATUS = 'USER_SWITCH_STATUS';

interface UserSwitchStatusModal {
  team_ids: number[];
  is_active: boolean;
}

export interface UserSwitchStatusModalPayload {
  organization_id: string;
  data: UserSwitchStatusModal;
  callback?: (response: any, error?: any) => void;
}
export const userSwitchStatus = actionCreator<UserSwitchStatusModalPayload>(USER_SWITCH_STATUS);

//-----USER_INVITE----//
export const USERS_INVITE = 'USERS_INVITE';

interface UsersInviteModal {
  team_ids: number[];
}

export interface UsersInvitePayload {
  organization_id: string;
  data: UsersInviteModal;
  callback?: (response: any, error?: any) => void;
}
export const usersInvite = actionCreator<UsersInvitePayload>(USERS_INVITE);

//-----USER_REMOVE----//
export const USERS_REMOVE = 'USERS_REMOVE';

interface UsersRemoveModal {
  team_ids: number[];
}

export interface UsersRemovePayload {
  organization_id: string;
  data: UsersRemoveModal;
  callback?: (response: any, error?: any) => void;
}
export const usersRemove = actionCreator<UsersRemovePayload>(USERS_REMOVE);

//-----RESEND_JOIN_ORGANIZATION----//
export const RESEND_JOIN_ORGANIZATION = 'RESEND_JOIN_ORGANIZATION';
export interface ResendJoinOrganizationPayload {
  callback?: (res: any) => void;
  data: JoinRequestModel;
}

export const resendJoinOrganization = actionCreator<ResendJoinOrganizationPayload>(RESEND_JOIN_ORGANIZATION);

//-----REJECT_JOIN_ORGANIZATION----//
export const REJECT_JOIN_ORGANIZATION = 'REJECT_JOIN_ORGANIZATION';
export interface RejectJoinOrganizationPayload {
  callback?: (res: any) => void;
}
export const rejectJoinOrganization = actionCreator<RejectJoinOrganizationPayload>(REJECT_JOIN_ORGANIZATION);

export const SET_DEFAULT_ORG_TAB = 'SET_DEFAULT_ORG_TAB';
export const setDefaultOrgTab = actionCreator<DefaultsOrgTab>(SET_DEFAULT_ORG_TAB);

export const ORG_CHANGED = 'org/ORG_CHANGED';
export const orgChanged = actionCreator(ORG_CHANGED);

//-----SET_QUERY---//
export const SET_QUERY = 'app-users/SET_QUERY';
export const setQuery = actionCreator<OrganizationState['query']>(SET_QUERY);

export type OrganizationActionsTypes =
  | ActionModel<typeof CREATE_ORGANIZATION, CreateOrganizationAction>
  | ActionModel<typeof JOIN_ORGANIZATION, JoinOrganizationAction>
  | ActionModel<typeof ASSOCIATION_ORGANIZATION, AssociationOrganizationAction>
  | ActionModel<typeof CREATE_SUB_ORGANIZATION, CreateSubOrganizationAction>
  | ActionModel<typeof GET_ORGANIZATION_DETAILS, GetOrganizationDetailsPayload>
  | ActionModel<typeof GET_ORGANIZATION_DETAILS_SUCCESS, OrganizationDetailsModel>
  | ActionModel<typeof UPDATE_ORGANIZATION_DETAILS, UpdateOrganizationDetailsPayload>
  | ActionModel<typeof UPDATE_ORGANIZATION_DETAILS_SUCCESS, OrganizationDetailsModel>
  | ActionModel<typeof UPDATE_ORGANIZATION_DETAILS_SUCCESS, OrganizationDetailsModel>
  | ActionModel<typeof CREATE_PROFILE_SUB_ORGANIZATION, CreateProfileSubOrganizationPayload>
  | ActionModel<typeof CREATE_USER_ORG_AFFILIATION, CreateAffiliationPayload>
  | ActionModel<typeof UPDATE_USER_ORG_AFFILIATION, UpdateAffiliationPayload>
  | ActionModel<typeof CREATE_PROFILE_SUB_ORGANIZATION_SUCCESS, OrganizationDetailsModel>
  | ActionModel<typeof GET_ORGANIZATION_USERS_SUCCESS, GetOrganizationUsersModel>
  | ActionModel<typeof GET_USER_DETAILS, GetUserDetailsPayload>
  | ActionModel<typeof GET_AVAILABLE_ORGS, GetAvailableOrgsPayload>
  | ActionModel<typeof USER_SWITCH_STATUS, UserSwitchStatusModalPayload>
  | ActionModel<typeof USERS_INVITE, UsersInvitePayload>
  | ActionModel<typeof USERS_REMOVE, UsersRemovePayload>
  | ActionModel<typeof RESEND_JOIN_ORGANIZATION, ResendJoinOrganizationPayload>
  | ActionModel<typeof REJECT_JOIN_ORGANIZATION, RejectJoinOrganizationPayload>
  | ActionModel<typeof GET_ORGANIZATION_USERS, GetOrganizationUsersPayload>
  | ActionModel<typeof SET_DEFAULT_ORG_TAB, DefaultsOrgTab>
  | ActionModel<typeof SET_QUERY, OrganizationState['query']>
  | ActionModel<typeof LOGOUT_SUCCESS>;
