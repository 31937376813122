import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { BelOrgModel, IRouteParamsBase, MessageModel, TableDataEntities } from '../../../../../models';
import { Checkbox, ComaList, Link } from '../../../../controls';
import { useTableDataV2 } from '../../../../../hooks/common/use-table-data-v2';
import Api from '../../../../../services/api';
import { useFilters } from '../../../../../hooks/filters/use-filters';
import FilterModalButton from '../../../../common/FilterModalButton/FilterModalButton';
import { DataTable } from '../../../../common/DataTable/DataTable';
import { ROOTS } from '../../../../../routes/routes';
import { basicDateFormat, cutString } from '../../../../../helpers';
import { TableDateFilter } from '../../../../common';
import { useRowSelection } from 'react-data-grid';
import useHasAccess from '../../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../../constants';

interface RouteParams {
  messageId?: string;
}

const MessageOrganizations = () => {
  const { t } = useTranslation();
  const params = useParams<RouteParams>();
  const location = useLocation();
  const { navBack } = useNavBack();
  const messageData: MessageModel = location.state?.message;
  const { filterOptions } = useFilters();
  const baseUrl = `beloved/organizations/?marketing_messages=${messageData.id}`;
  const hasAccessToMarketingComUpdate = useHasAccess(BelovedPermissions.MARKETING_COMMUNICATION_UPDATE);

  const {
    query,
    fetchData,
    handleSearch,
    data,
    handleSelectDateFilter,
    handleSelectFilter,
    handleApplyFilter,
    filterData,
    isFiltersChanged,
    selectedEntitiesIds,
    setSelectedEntitiesIds,
    handleSelectOne,
  } = useTableDataV2<BelOrgModel>({
    baseUrl,
    entityName: TableDataEntities.BELOVED_MESSAGE_ORGANIZATIONS,
    enableSelectedEntities: true,
  });

  useEffect(() => {
    fetchData(query);
  }, []);

  const handleDelete = async () => {
    const organization_ids: number[] = [];
    data?.result.forEach(item => {
      if (!selectedEntitiesIds.includes(item.id)) {
        organization_ids.push(item.id);
      }
    });
    await Api.patch(`beloved/marketing_communications/${params.messageId}/`, undefined, {
      organization_ids,
    });
    setSelectedEntitiesIds([]);
    fetchData(query);
  };

  return (
    <>
      <main className="b-page">
        <h1 className={'b-page__title'}>
          {t('common:label.marketing-communication')}:&nbsp;
          <span className="mark">{t('common:headlines.organizations')}</span>
        </h1>
        <h2 className="b-page__title2 -backIcon font-navy mb-4">
          <Link onClick={navBack} className="b-button-icon-back" />
          <span>{messageData.subject || t('common:label.subject')}</span>
        </h2>
        <div className="b-page__controls">
          <div className="b-tableToolContainer">
            {!messageData.is_delivered || hasAccessToMarketingComUpdate ? (
              <button
                onClick={handleDelete}
                disabled={!selectedEntitiesIds.length}
                className="b-tableTool -delete -mobView -mr"
              >
                {t('common:btn.delete')}
              </button>
            ) : null}
            {filterOptions ? (
              <FilterModalButton
                query={query}
                handleSelectFilter={handleSelectFilter}
                handleApplyFilter={handleApplyFilter}
                filterData={filterData}
                isFiltersChanged={isFiltersChanged}
                filters={filterOptions.organizations}
              />
            ) : null}
          </div>
          <div className="b-page__search">
            <form action="#" className="searchForm">
              <input
                type="search"
                value={query.search}
                onChange={e => handleSearch(e.target.value)}
                placeholder={t('common:label.search')}
                aria-label="Search field"
              />
              <span className="searchForm__icon" />
            </form>
          </div>
        </div>
        <DataTable<BelOrgModel>
          baseUrl={baseUrl}
          entityName={TableDataEntities.BELOVED_MESSAGE_ORGANIZATIONS}
          selectedEntitiesIds={selectedEntitiesIds}
          handleSelectOne={handleSelectOne}
          cols={[
            {
              key: 'organization_name',
              name: t('common:column.organization-name'),
              width: 170,
              sortable: true,
              editable: false,
              resizable: true,
            },
            {
              key: 'affiliation',
              name: t('common:column.affiliation'),
              sortable: true,
              editable: false,
              resizable: true,
            },
            {
              key: 'org_type',
              name: t('common:column.org-type'),
              sortable: true,
              editable: false,
              resizable: true,
              headerCellClass: 'b-rdgTable__headerCell',
              formatter(props) {
                return <>{props.row.type}</>;
              },
            },
            {
              key: 'project_manager',
              name: t('common:column.project-manager'),
              sortable: true,
              editable: false,
              resizable: true,
            },
            {
              key: 'joined',
              name: t('common:column.joined'),
              sortable: true,
              editable: false,
              resizable: true,
              headerCellClass: 'b-rdgTable__headerCell',
              formatter(props) {
                return (
                  <>
                    {props.row.joined ? basicDateFormat(new Date(props.row.joined)) : t('common:column.n-a')}
                  </>
                );
              },
              headerRenderer(props) {
                if (query.dateFilters) {
                  return (
                    <TableDateFilter
                      dateFilterId={'joined'}
                      selectedFilters={query.dateFilters['joined']}
                      periodFieldName={'joined_period'}
                      dateRangeFieldName={'joined_date_range'}
                      onDateFilterChanged={handleSelectDateFilter}
                      rdgProps={props}
                      fiscalOption={true}
                    />
                  );
                }
              },
            },
            {
              key: 'last_audit',
              name: t('common:column.last-audit'),
              width: 90,
              sortable: true,
              editable: false,
              resizable: true,
              formatter(props) {
                return (
                  <>
                    {props.row.last_audit
                      ? basicDateFormat(new Date(props.row.last_audit))
                      : t('common:column.n-a')}
                  </>
                );
              },
              headerRenderer(props) {
                if (query.dateFilters) {
                  return (
                    <TableDateFilter
                      dateFilterId={'last_audit'}
                      selectedFilters={query.dateFilters['last_audit']}
                      periodFieldName={'last_audit_period'}
                      dateRangeFieldName={'last_audit_date_range'}
                      onDateFilterChanged={handleSelectDateFilter}
                      rdgProps={props}
                      fiscalOption={true}
                    />
                  );
                }
              },
            },
            {
              key: 'address',
              name: t('common:label.address'),
              width: 90,
              sortable: true,
              editable: false,
              resizable: true,
              isShow: false,
              formatter(props) {
                return <>{props.row.address?.full_address || t('common:column.n-a')}</>;
              },
            },
            {
              key: 'legacy_audit',
              name: t('common:label.legacy-audit-organization'),
              width: 90,
              sortable: true,
              editable: false,
              resizable: true,
              isShow: false,
              formatter(props) {
                if (!props.row.legacy_audit) {
                  return null;
                }
                return (
                  <div className="d-flex justify-content-center h-100 align-items-center">
                    <div className="b-button-icon -checkmark" />
                  </div>
                );
              },
            },
            {
              key: 'website',
              name: t('common:label.website'),
              width: 40,
              sortable: true,
              editable: false,
              resizable: true,
              isShow: false,
              formatter(props) {
                const handleClick = (e: any) => {
                  e.stopPropagation();
                };
                return (
                  <>
                    {props.row.website ? (
                      <a
                        onClick={handleClick}
                        className="b-link"
                        target="_blank"
                        rel="noreferrer"
                        href={`//${props.row.website}`}
                      >
                        {props.row.website}
                      </a>
                    ) : (
                      t('common:column.n-a')
                    )}
                  </>
                );
              },
            },
            {
              key: 'beloved_team',
              name: t('common:column.beloved-team'),
              sortable: false,
              resizable: true,
              editable: false,
              width: 190,
              isShow: false,
              headerCellClass: 'b-rdgTable__headerCell',
              formatter(props) {
                const history = useHistory();
                const handleClick = (e: any, index: number) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const { orgId } = useParams<IRouteParamsBase>();

                  history.push(
                    ROOTS.BELOVED_USER_PROFILE.replace(
                      ':id',
                      `${props.row.beloved_team[index].value}`,
                    ).replace(':orgId', `${orgId || 'general'}`),
                  );
                };
                return (
                  <div className="h-100 d-flex justify-content-between align-items-center">
                    <div className="b-adminToolUsers__statusCell">
                      <ComaList
                        tag={'a'}
                        uKey="b-l-"
                        handleClick={handleClick}
                        nullText={t('common:column.n-a')}
                        arr={
                          props.row.beloved_team?.length
                            ? props.row.beloved_team
                                .slice(0, 3)
                                .map(user => ({ title: cutString(user.label, 20) }))
                            : []
                        }
                      />
                    </div>
                  </div>
                );
              },
            },
            {
              key: 'tools',
              name: '',
              disableDrag: true,
              width: 40,
              maxWidth: 40,
            },
            {
              key: 'select',
              name: '',
              width: 40,
              maxWidth: 40,
              disableDrag: true,
              headerRenderer(props) {
                return (
                  <Checkbox
                    checked={props.allRowsSelected}
                    onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
                  />
                );
              },
              formatter: props => {
                const [isRowSelected, onRowSelectionChange] = useRowSelection();

                return (
                  <div className="b-rdgTable__cellCenter">
                    <div className={'checkBox'} onClick={e => e.stopPropagation()}>
                      <Checkbox
                        checked={isRowSelected}
                        onChange={e => {
                          onRowSelectionChange({
                            row: props.row,
                            checked: e.target.checked,
                            isShiftClick: false,
                          });
                        }}
                      />
                    </div>
                  </div>
                );
              },
            },
          ]}
        />
      </main>
    </>
  );
};

export default MessageOrganizations;
