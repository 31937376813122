import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StateType } from '../../../store/reducers';

const FreshDesk = () => {
  const user = useSelector((state: StateType) => state.profile.authUser);

  useEffect(() => {
    if (user) {
      try {
        // @ts-ignore
        FreshworksWidget('identify', 'ticketForm', {
          name: `${user.user.first_name} ${user.user.last_name}`,
          email: user.user.email,
        });
        // @ts-ignore
        FreshworksWidget('disable', 'ticketForm', ['name', 'email']);
      } catch (err) {
        console.log('err', err);
      }
    }
  }, [user]);

  return <div />;
};

export default FreshDesk;
