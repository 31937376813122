import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from './saga';
import { globals } from './globals';
import history from './history';

declare var window: any;

let composeEnhancers;
if (process.env.REACT_APP_ENV === 'dev') {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ realtime: true });
  } else {
    composeEnhancers = composeWithDevTools({});
  }
} else {
  composeEnhancers = compose;
}

const sagaMiddleware = createSagaMiddleware();

const configureStore = createStore(reducers, composeEnhancers(applyMiddleware(sagaMiddleware)));

globals.store = configureStore;
globals.history = history;

sagaMiddleware.run(rootSaga);

export default configureStore;
