import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useLocation, useParams } from 'react-router';
import { Button, Link, Loader } from '../../../../controls';
import { BelEaSavedAvgModel, EAStatuses, IRouteParamsBase, TableDataEntities } from '../../../../../models';
import { HeadlineOrganization, OrgBreadcrumbs } from '../../../../common';
import { basicDateFormat, onlyUnique } from '../../../../../helpers';
import { useTranslation } from 'react-i18next';
import SortModalButton from '../../../../common/SortModalButton/SortModalButton';
import CheckedItems from '../../../../common/CheckedItems/CheckedItems';
import { useSelectCurrentOrg } from '../../../../../hooks/org/use-select-current-org';
import { useTableDataV2 } from '../../../../../hooks/common/use-table-data-v2';
import { DataTable } from '../../../../common/DataTable/DataTable';
import { eaAVgListType } from '../../../../../constants/ea';
import { getColumns } from './columns';
import { ROOTS } from '../../../../../routes/routes';
import { useDispatch } from 'react-redux';
import { getAvgChart } from '../../../../../store/avg/avgActions';
interface IRouteParams extends IRouteParamsBase {
  type: eaAVgListType;
}

interface ILocationState {
  org?: { id: number; name: string };
}

const GroupAveragesSavedForEAs = () => {
  const { t } = useTranslation();
  const location = useLocation<ILocationState | undefined>();
  const currentOrg = useSelectCurrentOrg();
  const { orgId } = useParams<IRouteParams>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const isBeloved = location.pathname.match('beloved');
  const baseUrl = isBeloved
    ? `beloved/equity_audit/get_group_avg_list/`
    : `organizations/${orgId}/equity_audit/group_average/get_groups_list/`;

  const {
    query,
    data,
    fetchData,
    handleSort,
    setSortingData,
    sortingData,
    handleChangeDirection,
    handleChangeSortField,
    fetchMoreMobile,
    selectedEntitiesIds,
    handleSearch,
    setSelectedEntitiesIds,
    selectedEntities,
    setSelectedEntities,
    infiniteList,
  } = useTableDataV2<BelEaSavedAvgModel>({
    entityName: TableDataEntities.AVG_SAVED_EAs,
    baseUrl,
    enableSelectedEntities: false,
    extraData: { status: EAStatuses.COMPLETED },
  });

  const onCellClick = (row: BelEaSavedAvgModel) => {
    const route = isBeloved
      ? ROOTS.BEL_GROUP_AVERAGES.replace(':avg_id', `${row.id}`)
      : ROOTS.GROUP_AVERAGES.replace(':orgId', orgId).replace(':avg_id', `${row.id}`);
    history.push(route);
  };

  const columns = getColumns({ baseUrl, onCellClick });

  useEffect(() => {
    fetchData(query);
  }, [location.key]);

  useEffect(() => {
    if (history.location.state?.reportEA?.selectedAVG) {
      setSelectedEntitiesIds([history.location.state.reportEA.selectedAVG]);
    }
  }, [history.location.state?.reportEA]);

  const handleNavAddAvg = () => {
    const route = isBeloved
      ? ROOTS.BEL_GROUP_AVERAGES.replace(':avg_id', 'list')
      : ROOTS.GROUP_AVERAGES.replace(':orgId', orgId).replace(':avg_id', 'list');
    history.push(route);
  };

  const customHandleSelectOne = (selectedRows: Set<number>) => {
    // @ts-ignore
    let lastSelected = [[...selectedRows][[...selectedRows].length - 1]];
    if (!lastSelected[0]) {
      lastSelected = [];
    }
    // @ts-ignore
    setSelectedEntitiesIds(lastSelected);
    const filtered = [...(data?.result || []), ...selectedEntities].filter((f: any) =>
      selectedRows.has(f.id),
    );
    setSelectedEntities(filtered.filter(onlyUnique));
  };

  const handleCompute = () => {
    if (history.location.state?.reportEA) {
      setLoading(true);
      const { orgId, eaId, chartId } = history.location.state?.reportEA;
      dispatch(
        getAvgChart({
          org_id: orgId,
          id: selectedEntitiesIds[0],
          isBeloved: isBeloved,
          callback: res => {
            if (res) {
              setLoading(false);
              history.replace(
                ROOTS.REPORT_CHART_BAR.replace(':orgId', orgId)
                  .replace(':eaId', eaId)
                  .replace(':chartId', chartId),
                { selectedAVG: res },
              );
            }
          },
        }),
      );
    }
  };

  const navBack = () => {
    if (history.location.state?.reportEA) {
      setLoading(true);
      const { orgId, eaId, chartId, selectedAVG } = history.location.state?.reportEA;
      const route = ROOTS.REPORT_CHART_BAR.replace(':orgId', orgId)
        .replace(':eaId', eaId)
        .replace(':chartId', chartId);
      if (!selectedAVG) {
        history.replace(route);
        return;
      }
      dispatch(
        getAvgChart({
          org_id: orgId,
          id: selectedAVG,
          isBeloved: isBeloved,
          callback: res => {
            if (res) {
              setLoading(false);
              history.replace(route, { selectedAVG: res });
            }
          },
        }),
      );
    }
  };

  const renderContent = () => {
    if (!data || loading) return <Loader fitParent />;

    return (
      <>
        <div className="-desktop position-relative">
          <CheckedItems value={selectedEntitiesIds.length} total={data.count} />
          <DataTable<BelEaSavedAvgModel>
            baseUrl={baseUrl}
            entityName={TableDataEntities.AVG_SAVED_EAs}
            selectedEntitiesIds={selectedEntitiesIds}
            handleSelectOne={customHandleSelectOne}
            cols={columns}
          />
        </div>
        {isMobile && (
          <div className="-mobile b-panelTitleOutside__list">
            <InfiniteScroll
              dataLength={infiniteList.length}
              next={fetchMoreMobile}
              hasMore={!(data.current_page === data.total_pages)}
              loader={<h4>{t('common:label.loading')}...</h4>}
            >
              {infiniteList.map((ea: BelEaSavedAvgModel) => {
                return (
                  <div key={`mob-item-${ea.id}`} className="b-panelTitleOutside__container">
                    <div className="b-panelTitleOutside">
                      <table>
                        <tr>
                          <th>{t('common:column.title')}</th>
                          <td>{ea.name}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.create-by')}</th>
                          <td>{ea.created_by_name}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.create-date')}</th>
                          <td>
                            {ea.created_at
                              ? basicDateFormat(new Date(ea.created_at))
                              : t('common:column.n-a')}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <div>
          <div className="b-page__header -desktop mb-0 align-items-baseline">
            <HeadlineOrganization
              orgName={currentOrg?.name || ''}
              orgId={currentOrg?.id || ''}
              headline={t('main:ea.ea')}
            />
            <div className="b-page__headerBtns -desktop">
              <Button
                className="btn -newEA"
                type={'orange-light'}
                onPress={handleCompute}
                title={t('common:btn.compare-averages')}
                disabled={selectedEntitiesIds.length !== 1}
              />
            </div>
          </div>
          <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />

          <div className="b-page__title2 font-navy">
            <Link className="b-report__backBtn" onClick={navBack}>
              <div className="back-icon" />
            </Link>
            {t('common:tabs.group-averages')}
          </div>

          <div className="b-page__controls">
            <div className="b-tableToolContainer">
              <button onClick={handleNavAddAvg} className="b-tableTool -addPlus -mobView -mr">
                {t('common:tabs.group-average')}
              </button>
            </div>
            <div className="b-page__search">
              <form action="#" className="searchForm">
                <input
                  type="search"
                  value={query.search}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder={t('common:label.search')}
                  aria-label="Search field"
                />
                <span className="searchForm__icon" />
              </form>
            </div>
          </div>
          <div className="-mobile">
            <div className="b-tableToolContainer -centered">
              <SortModalButton
                sortingData={sortingData}
                setSortingData={setSortingData}
                columns={columns}
                query={query}
                handleChangeSortField={handleChangeSortField}
                handleSort={handleSort}
              />
              <button
                onClick={handleChangeDirection}
                type="button"
                className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                    ${query.sorting?.direction === 'DESC' && '-active'}
                  `}
              >
                {t('common:btn.a-z-order')}
              </button>
            </div>
          </div>
        </div>
        {renderContent()}
      </main>
    </>
  );
};

export default GroupAveragesSavedForEAs;
