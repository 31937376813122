import React, { useEffect, useRef, useState } from 'react';
import styles from './Input.module.scss';
import { Image } from '../index';
import Eye from '../../../assets/images/icons/eye.svg';
import { useTranslation } from 'react-i18next';

interface IInput {
  error?: string;
  placeholder?: string;
  type: string;
  value: string;
  handleChange?: (value: string) => void;
  handleUnselect?: () => void;
  onFocus?: (name: string) => void;
  name?: string;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  hideErrorBlock?: boolean;
  // Auto focus on initialization
  autoFocus?: boolean;
  tabIndex?: number;
  ariaLabel?: string;
  number?: boolean;
  navToError?: boolean;
}

const InputBlock = ({
  error,
  placeholder,
  type,
  handleChange,
  value,
  handleUnselect,
  onFocus,
  disabled,
  className,
  inputClassName,
  hideErrorBlock,
  name,
  autoFocus,
  tabIndex,
  ariaLabel,
  navToError,
}: IInput) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(type);
  const [isFocus, setFocus] = useState(false);
  const handleClick = () => {
    setShowPassword(showPassword === 'text' ? 'password' : 'text');
  };

  const ref = useRef<any>(null);

  useEffect(() => {
    if (autoFocus) {
      ref.current.focus();
    }
  }, []);

  useEffect(() => {
    if (navToError) {
      ref.current.focus();
    }
  }, [error]);

  const onInputFocus = (e: any) => {
    setFocus(true);
    onFocus && onFocus(e.target.name);
  };

  const onBlur = () => {
    setFocus(false);
    handleUnselect && handleUnselect();
  };
  return (
    <div className={`${styles.input_wrapper} ${className ? className : ''}`}>
      <div
        className={`${styles.input_placeholder_block} ${error ? styles.input_wrapper_error : ''} ${
          isFocus ? styles.input_label_focus : ''
        }`}
        data-content={(value || '').length > 0 ? placeholder : ''}
      >
        <input
          className={`
                ${styles.input}
                ${error ? styles.input_error : ''}
                ${disabled ? styles.input_disable : ''}
                ${isFocus ? styles.input_focus : ''}
                ${inputClassName || ''}
                `}
          placeholder={placeholder}
          value={value || ''}
          onBlur={onBlur}
          name={name}
          disabled={disabled}
          onFocus={onInputFocus}
          type={showPassword}
          onChange={e => {
            handleChange && handleChange(e.target.value);
          }}
          ref={ref}
          tabIndex={tabIndex}
          aria-label={ariaLabel || placeholder}
        />
      </div>
      {type === 'password' ? (
        <span className={`${styles.password__show}`} onClick={handleClick}>
          <Image width={'24px'} height={'24px'} alt={t('common:alt.show-hide-password')} source={Eye} />
        </span>
      ) : null}
      {!hideErrorBlock && (
        <div className={`error-notification`}>
          <span className={`error-notification-text`}>{error}</span>
        </div>
      )}
    </div>
  );
};

export default InputBlock;
