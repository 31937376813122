import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChartEwpModel } from '../../../models';
import { ROOTS } from '../../../routes/routes';
import { useGetEwpRoute } from '../../../hooks/ewp/use-get-ewp-route';
// @ts-ignore
import TextTrim from 'react-text-trim/dist';

const percent = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

interface IProps {
  chart: ChartEwpModel;
  title: string;
  legendTitle: string;
  redirectToFilteredTable: (status: number) => void;
}

enum StatusesEnum {
  notYetStarted = 0,
  inProgress = 1,
  stalled = 2,
  noLongerPursuing = 3,
  complete = 4,
}

const EwpChartBar = ({ chart, title, legendTitle, redirectToFilteredTable }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const EWP_PRIORITY_DETAILS = useGetEwpRoute(ROOTS.EWP_PRIORITY_DETAILS);

  const navToDetails = (e: any, priorityId: number) => {
    e.preventDefault();
    history.push(EWP_PRIORITY_DETAILS.replace(':elId', `${priorityId}`));
  };

  if (!chart) {
    return null;
  }

  const renderLabelList = (): ReactNode => {
    return chart.labels.map((label, i: number) => {
      return (
        <li key={`chart-tree-${label.id}-${i}`} className={'b-ewpDashboard__label'}>
          <div className={'title'}>
            <TextTrim
              refId={`labelTrim-${label.id}`}
              text={label.title}
              minLines={5}
              maxLines={5}
              delimiter="..."
              fontSize={12}
            />
          </div>
          <a href="#" onClick={e => navToDetails(e, label.id)} className={'b-panelItem__link'}>
            {t('common:btn.details')}
          </a>
        </li>
      );
    });
  };

  const renderLines = () => {
    return chart.complete.map((percent, percentIndex) => {
      return (
        <div key={`ewp-org-lines-${percent}`} className={'b-customChart__multilineBlock'}>
          <div
            style={{ width: `${percent || 1}%` }}
            className={'b-customChart__boldLine -complete'}
            onClick={() => redirectToFilteredTable(StatusesEnum.complete)}
          >
            <span className={'percent'}>{percent}%</span>
          </div>
          <div
            style={{ width: `${chart.in_progress[percentIndex] || 1}%` }}
            className={'b-customChart__boldLine -in_progress'}
            onClick={() => redirectToFilteredTable(StatusesEnum.inProgress)}
          >
            <span className={'percent'}>{chart.in_progress[percentIndex]}%</span>
          </div>
          <div
            style={{ width: `${chart.not_yet_started[percentIndex] || 1}%` }}
            className={'b-customChart__boldLine -not_yet_started'}
            onClick={() => redirectToFilteredTable(StatusesEnum.notYetStarted)}
          >
            <span className={'percent'}>{chart.not_yet_started[percentIndex]}%</span>
          </div>
          <div
            style={{ width: `${chart.stalled[percentIndex] || 1}%` }}
            className={'b-customChart__boldLine -stalled'}
            onClick={() => redirectToFilteredTable(StatusesEnum.stalled)}
          >
            <span className={'percent'}>{chart.stalled[percentIndex]}%</span>
          </div>
          <div
            style={{ width: `${chart.no_longer_pursuing[percentIndex] || 1}%` }}
            className={'b-customChart__boldLine -no_longer_pursuing'}
            onClick={() => redirectToFilteredTable(StatusesEnum.noLongerPursuing)}
          >
            <span className={'percent'}>{chart.no_longer_pursuing[percentIndex]}%</span>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={'b-customChart'}>
      <h2 className={'b-ewpDashboard__chartTitle'}>{title}</h2>
      <div className={'b-customChart__chart'}>
        <div className={'b-customChart__labels'}>
          <div className={'b-customChart__labels__title'}>
            {t('main:ewp-create.intro-details.priorities.title')}
          </div>
          <ul>{renderLabelList()}</ul>
        </div>
        <div className={'b-customChart__graph'}>
          <div className={'b-customChart__percentage'}>
            {percent.map((per, index) => {
              return (
                <span key={`per-number-${per}`} style={{ left: `calc(${index * 10}%)` }}>
                  {per}%
                </span>
              );
            })}
          </div>
          <div className={'b-customChart__lines'}>
            {renderLines()}
            {percent.map((per, index) => {
              return (
                <span
                  key={`per-line-${per}-${index}`}
                  style={{ left: `calc(${index * 10}% - 1px)` }}
                  className={'b-customChart__horizontalLine'}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className={'b-customChart__legendGeneralTitle'}>{legendTitle || ''}</div>
      <div className={'b-customChart__legend justify-content-center'}>
        <div className={'b-customChart__legendTitle'}>
          <span className={`b-customChart__legendDot -complete`} />
          <span>{t('common:status.completed')}</span>
        </div>
        <div className={'b-customChart__legendTitle'}>
          <span className={`b-customChart__legendDot -in_progress`} />
          <span>{t('common:status.in-progress')}</span>
        </div>
        <div className={'b-customChart__legendTitle'}>
          <span className={`b-customChart__legendDot -not_yet_started`} />
          <span>{t('common:status.not-started')}</span>
        </div>
        <div className={'b-customChart__legendTitle'}>
          <span className={`b-customChart__legendDot -stalled`} />
          <span>{t('common:label.stalled')}</span>
        </div>
        <div className={'b-customChart__legendTitle'}>
          <span className={`b-customChart__legendDot -no_longer_pursuing`} />
          <span>{t('common:label.No-Longer-Pursuing')}</span>
        </div>
      </div>
    </div>
  );
};

export default EwpChartBar;
