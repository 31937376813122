import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { belAtGetAvailableRoles } from '../../../store/beloved/adminTools/adminToolActions';
import { ISelectOptions } from '../../../models';

const useGetAvailableRoles = (): ISelectOptions[] | undefined => {
  const dispatch = useDispatch();
  let [roles, setRoles] = useState<ISelectOptions[] | null>(null);

  useEffect(() => {
    dispatch(
      belAtGetAvailableRoles({
        callback: roles => {
          setRoles(roles);
        },
      }),
    );
  }, []);

  if (roles) {
    return roles;
  }
};

export default useGetAvailableRoles;
