import i18n from 'i18next';
import { components } from 'react-select';
import React from 'react';

export const NoOptions = (props: any, noOptionsMessage?: string, optionsCount?: number) => {
  let text = i18n.t('common:errors.no-options');
  if (optionsCount) {
    text = i18n.t('common:errors.all-selected');
  }
  if (noOptionsMessage) {
    text = noOptionsMessage;
  }
  return (
    <components.NoOptionsMessage {...props}>
      <span>{text}</span>
    </components.NoOptionsMessage>
  );
};
