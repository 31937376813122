import { actionCreator } from '../actionCreator';
import {
  CheckUniqueOrganizationModel,
  AppOptions,
  ActionModel,
  CreateFieldModel,
  ISelectOptions,
  InfoModel,
  CohortUtilsModel,
  UtilsFilterModel,
} from '../../models';

//-----GET_APP_OPTIONS----//
export const GET_APP_OPTIONS = 'GET_APP_OPTIONS';
export const getAppOptions = actionCreator(GET_APP_OPTIONS);

//-----GET_APP_OPTIONS_SUCCESS----//
export const GET_APP_OPTIONS_SUCCESS = 'GET_APP_OPTIONS_SUCCESS';
export const getAppOptionsSuccess = actionCreator<AppOptions>(GET_APP_OPTIONS_SUCCESS);

//-----user_type_roles----//
export const CHECK_UNIQ_ORGANIZATION = 'CHECK_UNIQ_ORGANIZATION';

export interface CheckUniqOrganizationPayload {
  callback: (response: any) => void;
  data: CheckUniqueOrganizationModel;
}

export const checkUniqOrganization = actionCreator<CheckUniqOrganizationPayload>(CHECK_UNIQ_ORGANIZATION);

//-----CREATE_ETHNICITY----//
export const CREATE_ETHNICITY = 'CREATE_ETHNICITY';
export interface CreateEthnicityPayload {
  data: CreateFieldModel;
  callback: (response: any) => void;
}
export const createEthnicity = actionCreator<CreateEthnicityPayload>(CREATE_ETHNICITY);

export const CREATE_ETHNICITY_SUCCESS = 'CREATE_ETHNICITY_SUCCESS';
export const createEthnicitySuccess = actionCreator<any>(CREATE_ETHNICITY_SUCCESS);

//-----CREATE_GENDER----//
export const CREATE_GENDER = 'CREATE_GENDER';
export interface CreateGenderPayload {
  data: CreateFieldModel;
  callback: (response: any) => void;
}
export const createGender = actionCreator<CreateGenderPayload>(CREATE_GENDER);

export const CREATE_GENDER_SUCCESS = 'CREATE_GENDER_SUCCESS';
export const createGenderSuccess = actionCreator<any>(CREATE_GENDER_SUCCESS);

//-----CREATE_PRONOUNS----//
export const CREATE_PRONOUNS = 'CREATE_PRONOUNS';
export interface CreatePronounsPayload {
  data: CreateFieldModel;
  callback: (response: any) => void;
}
export const createPronouns = actionCreator<CreatePronounsPayload>(CREATE_PRONOUNS);

export const CREATE_PRONOUNS_SUCCESS = 'CREATE_PRONOUNS_SUCCESS';
export const createPronounsSuccess = actionCreator<any>(CREATE_PRONOUNS_SUCCESS);

//-----CREATE_RACE----//
export const CREATE_RACE = 'CREATE_RACE';
export interface CreateRacePayload {
  data: CreateFieldModel;
  callback: (response: any) => void;
}
export const createRace = actionCreator<CreateRacePayload>(CREATE_RACE);

export const CREATE_RACE_SUCCESS = 'CREATE_RACE_SUCCESS';
export const createRaceSuccess = actionCreator<any>(CREATE_RACE_SUCCESS);

//-----CREATE_EDUCATIONS----//
export const CREATE_EDUCATION = 'CREATE_EDUCATION';
export interface CreateEducationPayload {
  data: CreateFieldModel;
  callback: (response: any) => void;
}
export const createEducation = actionCreator<CreateRacePayload>(CREATE_EDUCATION);

export const CREATE_EDUCATION_SUCCESS = 'CREATE_EDUCATION_SUCCESS';
export const createEducationSuccess = actionCreator<any>(CREATE_EDUCATION_SUCCESS);

//-----GET_INFO----//
export const GET_INFO = 'GET_INFO';
export const GET_INFO_SUCCESS = 'GET_INFO_SUCCESS';
export interface GetInfoPayload {
  callback?: (response: InfoModel | null, err?: any) => void;
}
export const getInfo = actionCreator<GetInfoPayload>(GET_INFO);
export const getInfoSuccess = actionCreator<InfoModel>(GET_INFO_SUCCESS);

//-----GET_COHORT_UTILS----//
export const GET_COHORT_UTILS = 'GET_COHORT_UTILS';
export const GET_COHORT_UTILS_SUCCESS = 'GET_COHORT_UTILS_SUCCESS';

export interface GetCommonPayload {
  callback?: (res: CohortUtilsModel) => void;
}

export const getCohortUtils = actionCreator<GetCommonPayload>(GET_COHORT_UTILS);
export const getCohortUtilsSuccess = actionCreator<CohortUtilsModel>(GET_COHORT_UTILS_SUCCESS);

//-----GET_FILTERS----//
export const GET_FILTERS = 'GET_FILTERS';
export const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS';
export interface GetFiltersPayload {
  callback?: (response: UtilsFilterModel | null, err?: any) => void;
}
export const getFilters = actionCreator<GetFiltersPayload>(GET_FILTERS);
export const getFiltersSuccess = actionCreator<UtilsFilterModel>(GET_FILTERS_SUCCESS);

export type UtilsActionsTypes =
  | ActionModel<typeof GET_APP_OPTIONS_SUCCESS, AppOptions>
  | ActionModel<typeof CREATE_PRONOUNS, CreatePronounsPayload>
  | ActionModel<typeof CREATE_RACE, CreateRacePayload>
  | ActionModel<typeof CREATE_GENDER, CreateGenderPayload>
  | ActionModel<typeof CREATE_ETHNICITY, CreateEthnicityPayload>
  | ActionModel<typeof CREATE_PRONOUNS_SUCCESS, ISelectOptions>
  | ActionModel<typeof CREATE_RACE_SUCCESS, ISelectOptions>
  | ActionModel<typeof CREATE_GENDER_SUCCESS, ISelectOptions>
  | ActionModel<typeof CREATE_EDUCATION, ISelectOptions>
  | ActionModel<typeof GET_INFO, GetInfoPayload>
  | ActionModel<typeof GET_INFO_SUCCESS, InfoModel>
  | ActionModel<typeof CREATE_EDUCATION_SUCCESS, ISelectOptions>
  | ActionModel<typeof GET_COHORT_UTILS>
  | ActionModel<typeof GET_COHORT_UTILS_SUCCESS, any>
  | ActionModel<typeof GET_FILTERS>
  | ActionModel<typeof GET_FILTERS_SUCCESS, any>
  | ActionModel<typeof CREATE_ETHNICITY_SUCCESS, ISelectOptions>;
