import React from 'react';
// @ts-ignore
import Modal from 'react-modal';
import { KeyValueModel } from '../../../models';

interface IProps {
  isOpen: boolean;
  afterOpenModal?: (data: any) => void;
  onRequestClose: (show: boolean) => void;
  children?: JSX.Element;
  contentStyles?: any;
  customContentStyles?: KeyValueModel<string | number>;
}

const ModalView = ({
  isOpen,
  afterOpenModal,
  children,
  onRequestClose,
  contentStyles,
  customContentStyles,
}: IProps) => {
  const closeModal = () => {
    onRequestClose(false);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      borderRadius: '8px',
      border: 'none',
      padding: 0,
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.05)',
      maxWidth: '100%',
      maxHeight: '100%',
      ...(customContentStyles || {}),
    },
    overlay: {
      backgroundColor: 'rgba(34, 34, 34, 0.9)',
      zIndex: 999,
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={{
        content: { ...customStyles.content, ...(contentStyles || {}) },
        overlay: customStyles.overlay,
      }}
      contentLabel="Example Modal"
    >
      {children}
    </Modal>
  );
};

export default ModalView;
