import React, { FC, useState } from 'react';
import styles from '../MyProfile/MyProfile.module.scss';
import { Button, Input } from '../../../controls';
import { Modal } from '../../../common';
import { KeyValueModel, PasswordModel } from '../../../../models';
import { validate } from '../../../../helpers';
import { changePassword } from '../../../../store/profile/profileActions';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface IProps {
  closeModal: () => void;
  passwordChangedSuccess: () => void;
}

const newPasswordInit = {
  new_pass: '',
  confirmPassword: '',
  old_pass: '',
};

const samePasswordValidator = {
  required: ['new_pass'],
};

export const ResetPassword: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const { passwordChangedSuccess } = props;
  const [passwords, setNewPassword] = useState<PasswordModel>(newPasswordInit);
  const [errors, setErrors] = useState<KeyValueModel<string>>({});
  const dispatch = useDispatch();

  const closeModal = () => {
    props.closeModal();
    setNewPassword(newPasswordInit);
  };

  const handleChangePassword = (name: string) => (value: string) => {
    if (errors[name]) {
      delete errors[name];
    }
    setNewPassword({
      ...passwords,
      [name]: value,
    });
  };

  const submitPassword = () => {
    let { errors }: any = validate(samePasswordValidator, passwords);
    if (errors.password || errors.new_pass) {
      setErrors({ ...errors });
      return;
    }
    dispatch(
      changePassword({
        callback: (res, err) => {
          if (err) {
            return;
          }
          setNewPassword(newPasswordInit);
          closeModal();
          toast.success(t('common:toast.password-changed'));
          passwordChangedSuccess();
        },
        data: passwords,
      }),
    );
  };

  return (
    <Modal width={545} onClose={closeModal} className={styles.password__modal} title={`Reset password`}>
      <div className={styles.container}>
        <div className={`${styles.password__fields}`}>
          <div className={'container-row'}>
            <Input
              error={errors?.new_pass}
              value={passwords?.new_pass || ''}
              type={'password'}
              placeholder={t('common:label.password')}
              handleChange={handleChangePassword('new_pass')}
            />
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            className={styles.btn}
            title={t('common:btn.cancel')}
            onPress={closeModal}
            type={'transparency'}
            size={'large'}
          />
          <Button
            className={styles.btn}
            title={t('common:btn.save')}
            onPress={submitPassword}
            type={'orange-light'}
            size={'large'}
          />
        </div>
      </div>
    </Modal>
  );
};
