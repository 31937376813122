import React, { FC, useState } from 'react';
import { Modal, PopupPortal } from '../../index';
import { useHistory } from 'react-router';
import { Button, Checkbox } from '../../../controls';
import { useTranslation } from 'react-i18next';
import { ROOTS } from '../../../../routes/routes';
import { getUser, updateUserSettings } from '../../../../store/profile/profileActions';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsModel } from '../../../../models';
import { StateType } from '../../../../store/reducers';

interface IProps {
  setPopupStatus: (status: boolean) => void;
}

const TaskNotification: FC<IProps> = ({ setPopupStatus }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: StateType) => state.profile.authUser)?.user;
  const [checked, setChecked] = useState<boolean>(false);

  const closePopup = (action: 'continue' | 'my-task') => {
    if (checked && user?.settings) {
      const data: SettingsModel = { ...user.settings, show_task_notification_logic: false };
      dispatch(
        updateUserSettings({
          data,
          callback: () => {
            dispatch(getUser({}));
          },
        }),
      );
    }
    if (action === 'my-task') {
      history.push(ROOTS.MY_TASKS);
    }
    setPopupStatus(false);
  };

  return (
    <PopupPortal portalElementId={'user-avatar-portal'}>
      <Modal minHeight={300} width={450} title={''} onClose={() => setPopupStatus(false)}>
        <div className={`b-modal`}>
          <p className={'simple-text text-center mb-4 mt-4'}>
            <b>{t('awa:N48.title')}</b>
          </p>
          <div className={'d-flex justify-content-center mb-5'}>
            <Checkbox
              checked={checked}
              onChange={e => setChecked(e.target.checked)}
              className={'d-flex justify-content-center'}
            >
              <span className={'text-long-description gray-text'}>
                {t('common:checkbox.not-notifications-login')}
              </span>
            </Checkbox>
          </div>
          <div className="b-modal__buttons">
            <Button
              onPress={() => closePopup('continue')}
              title={t('common:btn.continue')}
              size={'large'}
              type={'transparency'}
            />
            <Button
              onPress={() => closePopup('my-task')}
              title={t('common:btn.my-tasks')}
              size={'large'}
              type={'primary'}
            />
          </div>
        </div>
      </Modal>
    </PopupPortal>
  );
};

export default TaskNotification;
