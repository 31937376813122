import { BelCertifiedNewUserModel, ISelectOptions, KeyValueModel, PaginationModel } from '../../models';
import { actionCreator } from '../actionCreator';

export const CERTIFICATION_CONSULTANT_CREATE = 'beloved/CERTIFICATION_CONSULTANT_CREATE';
export interface CertificationConsultantPayload {
  data: BelCertifiedNewUserModel;
  userId?: number;
  callback: (error?: KeyValueModel<string>) => void;
}

export const certificationConsultantCreate = actionCreator<CertificationConsultantPayload>(
  CERTIFICATION_CONSULTANT_CREATE,
);

export const CERTIFICATION_CONSULTANT_UPDATE = 'beloved/CERTIFICATION_CONSULTANT_UPDATE';
export const certificationConsultantUpdate = actionCreator<CertificationConsultantPayload>(
  CERTIFICATION_CONSULTANT_UPDATE,
);

export const CERTIFICATION_CONSULTANT_DEACTIVATE_USERS = 'beloved/CERTIFICATION_CONSULTANT_DEACTIVATE_USERS';
export interface CertificationConsultantDeactivatePayload {
  usersIds: number[];
  is_active: boolean;
  callback: () => void;
}
export const certificationConsultantDeactivate = actionCreator<CertificationConsultantDeactivatePayload>(
  CERTIFICATION_CONSULTANT_DEACTIVATE_USERS,
);

export interface CertificationConsultantOrgPayload {
  data?: {
    consultant_id: number;
    organization_name?: string;
    organization_ids?: number[];
    page?: number;
  };
  callback?: (res: PaginationModel<ISelectOptions[]>) => void;
}

export const CERTIFICATION_CONSULTANT_AVAILABLE_ORGS = 'beloved/CERTIFICATION_CONSULTANT_AVAILABLE_ORGS';
export const certificationConsultantAvailableOrgs = actionCreator<CertificationConsultantOrgPayload>(
  CERTIFICATION_CONSULTANT_AVAILABLE_ORGS,
);
