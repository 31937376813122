import React, { CSSProperties, FC } from 'react';
import styles from './OrgBreadcrumbs.module.scss';
import { useSelector } from 'react-redux';
import { StateType } from '../../../store/reducers';
import { OrganizationDetailsModel } from '../../../models';
import { getOrgsFamily } from '../../../helpers';

interface IProps {
  containerStyles?: CSSProperties;
}

const OrgBreadcrumbs: FC<IProps> = ({ containerStyles }) => {
  const user = useSelector((state: StateType) => state.profile.authUser);
  const currentOrg: OrganizationDetailsModel | null = useSelector(
    (state: StateType) => state.organization.currentOrg,
  );

  if (!(user && currentOrg)) return null;

  const orgsFamily = getOrgsFamily(currentOrg.id, user);

  if (orgsFamily.length === 1) {
    return (
      <div className={styles.container} style={containerStyles}>
        <strong className={styles.bigTitle}>{orgsFamily[0].org_name}</strong>
      </div>
    );
  }

  if (orgsFamily.length === 2) {
    return (
      <div className={styles.container} style={containerStyles}>
        <strong className={styles.bigTitle}>{orgsFamily[0].org_name}</strong>
        <div className={styles.row}>
          <span className={styles.boldTitle}>{orgsFamily[1].org_name}</span>
        </div>
      </div>
    );
  }

  if (!orgsFamily.length) {
    return null;
  }

  return (
    <div className={styles.container} style={containerStyles}>
      <strong className={styles.bigTitle}>{orgsFamily[0].org_name}</strong>
      <div className={styles.row}>
        <span className={styles.simpleTitle}>{orgsFamily[2].org_name}</span>
        <span className={styles.boldTitle}>{orgsFamily[1].org_name}</span>
      </div>
    </div>
  );
};

export default OrgBreadcrumbs;
