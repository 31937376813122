import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '../../../../common';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CreateEAStep1 from './CreateEAStep1/CreateEAStep1';
import CreateEAStep3 from './CreateEAStep3/CreateEAStep3';
import CreateEAStep4 from './CreateEAStep4/CreateEAStep4';
import CreateEAStep5 from './CreateEAStep5/CreateEAStep5';
import CreateEAStep6 from './CreateEAStep6/CreateEAStep6';
import CreateEAStep7 from './CreateEAStep7/CreateEAStep7';
import CreateEAFinish from './CreateEAFinish/CreateEAFinish';
import {
  getOrgListForCreate,
  getEADetails,
  clearCreateEAData,
  getUsersListForCreate,
  getEAOptionsForCreate,
  cancelEA,
  updateEA,
} from '../../../../../store/equityAudit/equityAuditActions';
import CreateEAStep2 from './CreateEAStep2/CreateEAStep2';
import { StateType } from '../../../../../store/reducers';
import { CreateEAStep, EAUpdateData, IRouteParamsBase, ISelectOptions } from '../../../../../models';
import { Loader } from '../../../../controls';
import { ROOTS } from '../../../../../routes/routes';
import { useTranslation } from 'react-i18next';

interface IState {
  orgList: ISelectOptions[] | null;
  currentStep: CreateEAStep;
  cancelConfirmModal: boolean;
}

interface ILocationState {
  eaId: number;
}

const CreateEA = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation<ILocationState>();
  const history: any = useHistory();
  const { orgId } = useParams<IRouteParamsBase>();

  const currentOrg = useSelector((state: StateType) => state.organization.currentOrg);
  const createEAData = useSelector((state: StateType) => state.equityAudit.createEAData);
  let [isLoading, setIsLoading] = useState(false);

  const initialState = {
    orgList: null,
    currentStep: '0' as CreateEAStep,
    cancelConfirmModal: false,
  };

  let [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (location.state?.eaId) {
      dispatch(
        getEADetails({
          eaId: location.state.eaId,
        }),
      );
    }
    dispatch(getUsersListForCreate());
  }, []);

  useEffect(() => {
    if (currentOrg && !state.orgList) {
      dispatch(
        getOrgListForCreate({
          callback: (list: ISelectOptions[]) => {
            setState(prevState => ({
              ...prevState,
              orgList: list,
            }));
          },
        }),
      );
    }
  }, [currentOrg, state.orgList]);

  useEffect(() => {
    if (createEAData) {
      setState(prevState => ({ ...prevState, currentStep: createEAData.ea_step }));
    }
  }, [createEAData]);

  useEffect(() => {
    dispatch(getEAOptionsForCreate());
    return () => {
      dispatch(clearCreateEAData());
    };
  }, []);

  const onChangeStep = (eaStep: CreateEAStep) => {
    setState(prevState => ({ ...prevState, currentStep: eaStep }));
  };

  const handleCancel = () => {
    setState({ ...state, cancelConfirmModal: true });
  };

  const cancelEa = () => {
    if (createEAData) {
      dispatch(
        cancelEA({
          eaId: createEAData.id,
          callback: () => {
            history.goBack();
          },
        }),
      );
    } else {
      console.error('handleCancel create ea');
    }
  };

  const saveData = (data: EAUpdateData, isNext: boolean) => {
    if (createEAData) {
      setIsLoading(true);
      dispatch(
        updateEA({
          eaId: createEAData.id,
          data,
          callback: () => {
            if (isNext) {
              setIsLoading(false);
            } else {
              history.push(ROOTS.EQUITY_AUDIT_LIST.replace(':orgId', `${orgId}`));
            }
          },
        }),
      );
    }
  };

  const renderSteps = () => {
    switch (state.currentStep) {
      case '0': {
        if (state.orgList) {
          return <CreateEAStep1 orgList={state.orgList} />;
        }
        return <Loader fitParent />;
      }
      case 'set_ea_team':
        return <CreateEAStep2 saveData={saveData} handleCancel={handleCancel} onChangeStep={onChangeStep} />;
      case 'set_date':
        return <CreateEAStep3 saveData={saveData} handleCancel={handleCancel} onChangeStep={onChangeStep} />;
      case '2':
        return <CreateEAStep4 saveData={saveData} handleCancel={handleCancel} onChangeStep={onChangeStep} />;
      case 'set_org_details':
        return <CreateEAStep5 saveData={saveData} handleCancel={handleCancel} onChangeStep={onChangeStep} />;
      case 'set_geo':
        return <CreateEAStep6 saveData={saveData} handleCancel={handleCancel} onChangeStep={onChangeStep} />;
      case 'set_sector':
        return <CreateEAStep7 saveData={saveData} handleCancel={handleCancel} onChangeStep={onChangeStep} />;
      case 'finish':
        return <CreateEAFinish />;
    }
  };

  return (
    <>
      <main className={'pageContainer -noRelative pageContainer__content'}>
        {isLoading && <Loader fitParent />}
        {renderSteps()}
      </main>
      {state.cancelConfirmModal ? (
        <Alert
          isOpen={state.cancelConfirmModal}
          onRequestClose={() => setState({ ...state, cancelConfirmModal: false })}
          title={t('awa:M39.msg.p1')}
          text={t('awa:M39.msg.p2') + '\n' + t('awa:M39.msg.p3')}
          buttons={{
            left: {
              title: t('common:btn.no'),
              type: 'white',
              onClick: () => setState({ ...state, cancelConfirmModal: false }),
            },
            right: {
              title: t('common:btn.yes'),
              type: 'orange-light',
              onClick: cancelEa,
            },
          }}
        />
      ) : null}
    </>
  );
};

export default CreateEA;
