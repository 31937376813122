import React, { ReactElement, useEffect, useState } from 'react';
import { Link, Loader } from '../../controls';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../store/reducers';
import { useHistory } from 'react-router';
import { ROOTS } from '../../../routes/routes';
import { useTranslation } from 'react-i18next';
import { ImageUploader } from '../index';
import { OrganizationDetailsModel, UploadFileModel } from '../../../models';
import { useRole } from '../../../helpers/roles';
import {
  getOrganizationDetails,
  patchOrganizationDetails,
} from '../../../store/organization/organizationActions';

interface IProps {
  headline: string;
  orgName: string;
  orgId: number | string;
  customClasses?: string;
  backBtn?: ReactElement<any, any>;
  noOrgLogo?: boolean;
  showActions?: boolean;
}

interface OrgLogoModel {
  org_logo: string | null;
  org_logo_id: number | null;
}

const HeadlineOrganization = ({
  headline,
  orgName,
  orgId,
  customClasses,
  backBtn,
  noOrgLogo,
  showActions,
}: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: StateType) => state.profile.authUser);
  const history = useHistory();
  const isBelovedUser = user?.beloved_user;
  const access = useRole();
  const [loading, setLoading] = useState(false);
  const isEditLogo = access(['project_admin', 'project_manager']);
  const currentOrg: OrganizationDetailsModel | null = useSelector((state: StateType) => {
    return state.organization.currentOrg;
  });
  const currentBelOrg = useSelector((state: StateType) => state.belOrganizations.currentBelOrg);
  const isBelovedUrl = !!location.pathname.match('beloved');

  const [image, setImage] = useState<OrgLogoModel>({
    org_logo: currentOrg?.org_logo || null,
    org_logo_id: currentOrg?.org_logo_id || null,
  });

  const navToOrganization = () => {
    if (isBelovedUser) {
      history.push(ROOTS.BELOVED_ORGANIZATION_DETAILS.replace(':orgId', `${orgId}`));
    }
  };

  useEffect(() => {
    if (isBelovedUrl && currentBelOrg) {
      setImage({
        org_logo: currentBelOrg?.org_logo || null,
        org_logo_id: currentBelOrg?.org_logo_id || null,
      });
    } else if (currentOrg) {
      setImage({
        org_logo: currentOrg?.org_logo || null,
        org_logo_id: currentOrg?.org_logo_id || null,
      });
    }
  }, [isBelovedUrl, currentBelOrg, currentOrg]);

  const callback = () => {
    dispatch(getOrganizationDetails({ orgId: Number(orgId) }));
    setLoading(false);
  };

  const handleAvatarChange = (file: UploadFileModel | null) => {
    setLoading(true);
    dispatch(
      patchOrganizationDetails({
        orgId: Number(orgId),
        data: { org_logo_id: file?.id || null },
        callback: callback,
      }),
    );

    setImage({
      org_logo: file ? file.file : null,
      org_logo_id: file ? file.id : null,
    });
  };

  return (
    <h1 className={`b-page__title ${customClasses || ''}`}>
      <div className="d-flex">
        {backBtn && backBtn}
        {headline}
        {orgName ? ':' : ''}&nbsp;
        <Link onClick={navToOrganization}>
          <span className="mark">{orgName}</span>
        </Link>
        {!noOrgLogo ? (
          <ImageUploader
            placeholderText={t('common:label.cohort-image')}
            photo={image.org_logo || null}
            onChange={handleAvatarChange}
            type={'org-profile'}
            cropProps={{
              aspectRatio: 16 / 9,
            }}
            disabled={!showActions || isBelovedUrl || !isEditLogo}
          />
        ) : null}
        {loading ? <Loader fitParent /> : null}
      </div>
    </h1>
  );
};

export default HeadlineOrganization;
