import { useEffect, useState } from 'react';
import Api from '../../services/api';
import { useParams } from 'react-router';
import { IRouteParamsBase, ISelectOptions } from '../../models';

export const useGetGoalOptions = () => {
  const { orgId, id } = useParams<IRouteParamsBase>();

  let [years, setYears] = useState<ISelectOptions[] | null>(null);
  let [priorities, setPriorities] = useState<ISelectOptions[] | null>(null);

  useEffect(() => {
    Api.get(`organizations/${orgId}/ewp/${id}/goals/options/`).then(res => {
      if (res) {
        setYears(res.year_list);
      }
    });
    Api.get(`organizations/${orgId}/ewp/${id}/goals/get_available_priorities/`).then(res => {
      if (res) {
        setPriorities(res);
      }
    });
  }, []);

  return {
    years,
    priorities,
  };
};
