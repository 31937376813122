import React, { FC, useState } from 'react';

interface IProps {
  text: string;
}

const TableCellWarning: FC<IProps> = ({ text }) => {
  let [show, setShow] = useState(false);
  return (
    <>
      <div
        onMouseLeave={() => setShow(false)}
        onMouseOver={() => setShow(true)}
        className="b-button-icon -info -colorRed mr-1"
      />
      {show ? (
        <div
          className="position-absolute"
          style={{ top: 0, left: 5, lineHeight: 1.3, color: 'var(--primary-80)' }}
        >
          {text}
        </div>
      ) : null}
    </>
  );
};

export default TableCellWarning;
