import { actionCreator } from '../actionCreator';
import { IFields } from '../../components/pages/Profile/Profile/CreateProfileContainer';
import { ActionModel, PasswordModel, ProfileModel, SettingsModel } from '../../models';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';

//-----GET-USER----//

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export interface GetUserActionModel {
  callback?: (response: any) => void;
}

export interface GetUserSuccessActionModel {
  data: any;
}

export const getUser = actionCreator<GetUserActionModel>(GET_USER);
export const getUserSuccess = actionCreator<GetUserSuccessActionModel>(GET_USER_SUCCESS);

//-----CHANGE-STEP----//

export const CHANGE_AUTH_STEP = 'CHANGE_AUTH_STEP';

export interface ChangeAuthStepActionModel {
  data: { next_step: number };
}

export const changeAuthStepAction = actionCreator<ChangeAuthStepActionModel>(CHANGE_AUTH_STEP);

//-----GET_PROFILE----//

export const GET_PROFILE = 'GET_PROFILE';

export interface GetProfilePayload {
  user_id: number;
  callback: (event: any) => void;
}

export const getProfile = actionCreator<GetProfilePayload>(GET_PROFILE);

//-----UPDATE_PROFILE----//

export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export interface UpdateProfileModel {
  data: { user: IFields | ProfileModel; id: number };
  callback: (event: any) => void;
}

export const updateProfile = actionCreator<UpdateProfileModel>(UPDATE_PROFILE);

export const CHANGE_OLD_PASSWORD = 'CHANGE_OLD_PASSWORD';
export interface ChangePasswordPayload {
  data: PasswordModel;
  callback: (res: any, error?: any) => void;
}

export const changePassword = actionCreator<ChangePasswordPayload>(CHANGE_OLD_PASSWORD);

export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
export interface UpdateUserSettingsPayload {
  data: SettingsModel;
  callback?: (res: any, error?: any) => void;
}

export const updateUserSettings = actionCreator<UpdateUserSettingsPayload>(UPDATE_USER_SETTINGS);

export const RESEND_SECONDARY_EMAIL = 'RESEND_SECONDARY_EMAIL';
export interface ResendSecondaryEmailPayload {
  data: any;
  callback?: (res: any, error?: any) => void;
}

export const resendSecondaryEmail = actionCreator<ResendSecondaryEmailPayload>(RESEND_SECONDARY_EMAIL);

export const CONFIRM_SECONDARY_EMAIL = 'CONFIRM_SECONDARY_EMAIL';
export interface ConfirmSecondaryEmailPayload {
  data: any;
  callback?: (res: any, error?: any) => void;
}

export const confirmSecondaryEmail = actionCreator<ResendSecondaryEmailPayload>(CONFIRM_SECONDARY_EMAIL);

export type ProfileActionsTypes<T = never> =
  | ActionModel<typeof GET_USER, GetUserActionModel>
  | ActionModel<typeof GET_USER_SUCCESS, GetUserSuccessActionModel>
  | ActionModel<typeof CHANGE_AUTH_STEP, ChangeAuthStepActionModel>
  | ActionModel<typeof UPDATE_PROFILE, UpdateProfileModel>
  | ActionModel<typeof RESEND_SECONDARY_EMAIL>
  | ActionModel<typeof CONFIRM_SECONDARY_EMAIL>
  | ActionModel<typeof LOGOUT_SUCCESS>
  | ActionModel<typeof GET_PROFILE, GetProfilePayload>
  | ActionModel<typeof CHANGE_OLD_PASSWORD, ChangePasswordPayload>
  | ActionModel<typeof UPDATE_USER_SETTINGS, UpdateUserSettingsPayload>
  | T;
