import Api from '../../services/api';
import { BelEwpModel, ISelectOptions } from '../../models';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const useEwpListApi = () => {
  const { t } = useTranslation();

  const getAvailableLeaders = (callback?: (leaders: ISelectOptions[]) => void) => {
    Api.get('beloved/ewp/available_ewp_leaders/').then(res => {
      if (res && callback) {
        callback(res);
      }
    });
  };

  const getAvailableOrgsForCreate = (callback?: (orgs: ISelectOptions[]) => void) => {
    Api.get('beloved/ewp/get_available_orgs_to_create/').then(res => {
      if (res && callback) {
        callback(res);
      }
    });
  };

  const deleteEwp = (ids: number[], callback?: () => void) => {
    Api.delete('beloved/ewp/remove_ewp/', undefined, { ewp_ids: ids }).then(res => {
      if (res) {
        toast.success(t('common:toast.success'));
        callback && callback();
      }
    });
  };

  const editLeaders = (ewp: BelEwpModel, ids: number[], callback?: () => void) => {
    const { organization_id, id } = ewp;
    Api.patch(`organizations/${organization_id}/ewp/${id}/`, undefined, { beloved_team_ids: ids }).then(
      res => {
        if (res) {
          toast.success(t('common:toast.beloved-team-updated'));
          callback && callback();
        }
      },
    );
  };

  const editLeadersFromOrg = (
    { organization_id, id }: { organization_id: number; id: number },
    ids: number[],
    callback?: () => void,
  ) => {
    Api.patch(`organizations/${organization_id}/ewp/${id}/`, undefined, { beloved_team_ids: ids }).then(
      res => {
        if (res) {
          toast.success(t('common:toast.beloved-team-updated'));
          callback && callback();
        }
      },
    );
  };

  return {
    getAvailableLeaders,
    deleteEwp,
    editLeaders,
    editLeadersFromOrg,
    getAvailableOrgsForCreate,
  };
};
