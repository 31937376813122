import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import CheckIc from '../../../../../assets/images/icons/round-action-check-circle.svg';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getStandardResponses } from '../../../../../store/report/reportActions';
import {
  IRouteParamsBase,
  KeyValueNumberModel,
  ResponsesGroupModel,
  StandardResponsesModel,
} from '../../../../../models';
import { Link, Loader } from '../../../../controls';
import { ROOTS } from '../../../../../routes/routes';
import { useTranslation } from 'react-i18next';
import { HeadlineOrganization } from '../../../../common';

const StandardResponses = () => {
  const { t } = useTranslation();
  const [responses, setResponses] = useState<StandardResponsesModel<ResponsesGroupModel[]>>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { orgId, eaId, standardId } = useParams<IRouteParamsBase>();

  useEffect(() => {
    dispatch(
      getStandardResponses({
        data: {
          standard_id: Number(standardId),
          eaId: Number(eaId),
        },
        callback: (res: StandardResponsesModel<KeyValueNumberModel<ResponsesGroupModel>>) => {
          if (!res.is_detailed) {
            history.push(ROOTS.REPORTS.replace(':orgId', orgId).replace(':eaId', `${eaId}`));
            return;
          }
          const responses = {
            ...res,
            groups: Object.values(res.groups),
          };
          setResponses(responses);
        },
      }),
    );
  }, []);

  const goBack = (e: any) => {
    e.stopPropagation();
    history.go(-1);
  };

  const renderContent = () => {
    if (responses) {
      return (
        <div className="b-report">
          <div className="b-report__header">
            {isMobileOnly && <div className="b-report__arrow"></div>}
            {!isMobileOnly && (
              <HeadlineOrganization
                orgName={responses.organization_name}
                orgId={orgId}
                headline={t('main:ea.ea')}
                customClasses={'b-report__pageTitle'}
              />
            )}

            <div className="b-report__pageSubTitle">
              <Link tabIndex={0} className="b-report__backBtn" onClick={goBack}>
                <div className="back-icon" />
              </Link>
              <span>{t('main:reports.substandards-responses')}</span>
            </div>
          </div>

          <div className="b-reportScore">
            {responses.name}
            <span className="b-reportScore__num">
              {typeof responses.score === 'number' ? ` ${responses.score}%` : ` ${responses.score}`}
            </span>
          </div>

          <div className="b-reportTableHolder">
            <table className="b-reportTable">
              <thead>
                <tr>
                  <th>
                    <b>{t('main:reports.stakeholder')}</b>
                  </th>
                  <th>
                    <b>{t('main:reports.standard')}</b>
                  </th>
                  <th>{t('awa:M34.msg.p3')}</th>
                  <th>{t('awa:M34.msg.p4')}</th>
                  <th>{t('awa:M34.msg.p5')}</th>
                  <th>{t('awa:M34.msg.p6')}</th>
                </tr>
              </thead>
              <tbody>
                {responses.groups.map(group => {
                  return group.questions.map((question, questionIndex) => {
                    return (
                      <tr key={`question-${questionIndex}`}>
                        <td>{questionIndex === 0 && group.f_group_name}</td>
                        <td>{question.question_text}</td>

                        {/*Answer 1*/}
                        {Object.values(question.answers).map(answer => {
                          if (
                            answer.text.includes('Yes,') &&
                            answer.text.includes('we have clear data about it.')
                          ) {
                            return (
                              <td key={`answer-${1}`}>
                                {answer.checked ? (
                                  <img width={24} height={24} src={CheckIc} alt={t('common:alt.check')} />
                                ) : (
                                  ''
                                )}
                              </td>
                            );
                          }
                        })}

                        {/*Answer 2*/}
                        {Object.values(question.answers).map(answer => {
                          if (
                            answer.text.includes('Yes,') &&
                            answer.text.includes('we need a data tracking tool.')
                          ) {
                            return (
                              <td key={`answer-${1}`}>
                                {answer.checked ? (
                                  <img width={24} height={24} src={CheckIc} alt={t('common:alt.check')} />
                                ) : (
                                  ''
                                )}
                              </td>
                            );
                          }
                        })}

                        {/*Answer 3*/}
                        {Object.values(question.answers).map(answer => {
                          if (
                            answer.text.includes('No,') &&
                            answer.text.includes('we have clear data about it.')
                          ) {
                            return (
                              <td key={`answer-${1}`}>
                                {answer.checked ? (
                                  <img width={24} height={24} src={CheckIc} alt={t('common:alt.check')} />
                                ) : (
                                  ''
                                )}
                              </td>
                            );
                          }
                        })}

                        {/*Answer 4*/}
                        {Object.values(question.answers).map(answer => {
                          if (
                            answer.text.includes('No,') &&
                            answer.text.includes('we need a data tracking tool.')
                          ) {
                            return (
                              <td key={`answer-${1}`}>
                                {answer.checked ? (
                                  <img width={24} height={24} src={CheckIc} alt={t('common:alt.check')} />
                                ) : (
                                  ''
                                )}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  });
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    return <Loader fitParent />;
  };

  return (
    <>
      <main className={'pageContainer pageContainer__content'}>{renderContent()}</main>
    </>
  );
};

export default StandardResponses;
