import React, { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import Api from '../../../../../services/api';
import { BelResourceCommentModel } from '../../../../../models';
import { getLocaleUtcDateTime } from '../../../../../helpers';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';

interface IProps {
  data: BelResourceCommentModel[];
  onChange: (data: BelResourceCommentModel[]) => void;
  resourceId?: number;
}

const ResourceComments: FC<IProps> = ({ data, onChange, resourceId }) => {
  const { t } = useTranslation();
  let [text, setText] = useState('');
  let [updateComment, setUpdateComment] = useState<BelResourceCommentModel | null>(null);
  let [show, setShow] = useState(false);
  const user = useSelector((state: StateType) => state.profile.authUser);

  const handleSave = async () => {
    if (updateComment) {
      const resp = await Api.put('beloved/resource/comments/update/', {
        resource_comment_id: updateComment.id,
        description: text,
      });

      const newData = data.map(item => {
        if (item.id === updateComment?.id) {
          return resp.result.resource;
        }
        return item;
      });
      setText('');
      setUpdateComment(null);
      onChange(newData);
    } else {
      const reqData: any = { description: text };
      if (resourceId) {
        reqData.resource_id = resourceId;
      }
      const resp = await Api.post('beloved/resource/comments/create/', reqData);
      setText('');
      onChange([...data, resp.result.resource]);
    }
  };

  const handleRemove = async (commentId: number) => {
    const resp = await Api.put('beloved/resource/comments/update/', {
      resource_comment_id: commentId,
      is_active: 0,
    });
    const newData = data.map(item => {
      if (item.id === commentId) {
        return resp.result.resource;
      }
      return item;
    });
    onChange(newData);
  };

  const handleEdit = (comment: BelResourceCommentModel) => {
    setUpdateComment(comment);
    setText(comment.description);
  };

  return (
    <div className="b-resourceNote">
      <a
        onClick={e => {
          e.preventDefault();
          setShow(!show);
        }}
        className={`b-resourceNote__open ${show && '-opened'}`}
        href="#"
      >
        {t('common:btn.comments')}
      </a>
      {show ? (
        <>
          <div className="b-resourceNote__list">
            {data
              .filter(item => item.is_active)
              .map(item => {
                const isMyComment = user?.user.id === item.author.id;
                return (
                  <div key={`note-${item.id}`} className="b-resourceNote__item">
                    <div>
                      <div className="b-resourceNote__itemDate">{getLocaleUtcDateTime(item.created_at)}</div>
                      <div className="b-resourceNote__itemUser">{item.author.name}</div>
                    </div>
                    <div className="b-resourceNote__itemText text-break">{item.description}</div>
                    {isMyComment ? (
                      <div className="b-resourceNote__controls">
                        <a
                          href="#"
                          className="b-button-icon -edit"
                          onClick={e => {
                            e.preventDefault();
                            handleEdit(item);
                          }}
                        />
                        <a
                          onClick={e => {
                            e.preventDefault();
                            handleRemove(item.id);
                          }}
                          href="#"
                          className="b-button-icon -delete"
                        />
                      </div>
                    ) : null}
                  </div>
                );
              })}
          </div>
          <div className="b-resourceNote__inputWrapper">
            <textarea
              onChange={e => setText(e.target.value)}
              value={text}
              className="b-resourceNote__input"
              rows={4}
              name="Note Text"
              aria-label="Note Text"
              placeholder={t('common:label.leave-comment-here')}
            />
            <a
              className={`b-resourceNote__add ${text.length < 2 ? '-disabled' : ''}`}
              href="#"
              onClick={e => {
                e.preventDefault();
                handleSave();
              }}
            >
              {updateComment ? t('common:btn.update-comment') : t('common:btn.add-comment')}
            </a>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ResourceComments;
