import React, { FC } from 'react';
import { Button } from '../../../../../controls';
import Illustration from '../../../../../../assets/images/group@2x.png';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import { HandleSaveType } from '../../../../../../models';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSave: HandleSaveType;
  onSubmit: () => void;
}

const Submission: FC<IProps> = ({ onSave, onSubmit }) => {
  const { t } = useTranslation();

  const handleSave = () => {
    onSave({
      next: false,
      isIntro: true,
    });
  };

  return (
    <>
      <div className="-desktop">
        <Button
          iconLeft={<img src={SaveIc} alt={t('common:alt.saveIc')} />}
          className="b-elmTake__saveBtn -desktop"
          type={'bordered'}
          onPress={handleSave}
          title={t('common:btn.save-exit')}
        />
      </div>
      <div className="b-EATaking__box">
        <img className="b-elmBox__illus" src={Illustration} alt={t('awa:AT3')} />
        <h2 className="b-EATaking__boxTitle">{t('awa:M67.title')}</h2>
        <div className="b-elmTake__descBox">
          <p className="b-elm__p">{t('awa:M67.msg.p1')}</p>
          <p className="b-elm__p">{t('awa:M67.msg.p2')}</p>
        </div>
        <Button
          className={'btn -type_form -desktop'}
          type={'orange-light'}
          onPress={onSubmit}
          title={t('common:btn.submit')}
        />
      </div>
      <div className="-mobile">
        <div className="b-EATaking__buttons">
          <Button
            iconLeft={<img src={SaveIc} alt={t('common:alt.saveIc')} />}
            className="b-elmTake__saveBtn "
            type={'bordered'}
            onPress={handleSave}
            title={t('common:btn.save-exit')}
          />
          <Button className={'btn'} type={'orange-light'} onPress={onSubmit} title={t('common:btn.submit')} />
        </div>
      </div>
    </>
  );
};

export default Submission;
