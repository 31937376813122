import React from 'react';
import styles from './TooltipStyles.module.scss';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

interface IRadioButton {
  title: any;
  id?: string | number;
  children?: JSX.Element | null | undefined;
  effect?: 'float' | 'solid';
  place?: 'top' | 'right' | 'bottom' | 'left';
  hideDescription?: boolean;
  customProps?: TooltipProps;
  className?: string;
  style?: any;
}

const Tooltip = ({
  title,
  children,
  id,
  effect,
  place,
  hideDescription,
  customProps,
  className,
  style,
}: IRadioButton) => {
  return (
    <>
      {title && !hideDescription ? (
        <>
          <span style={style} className={className} data-tip="" data-for={`tooltip-${id || ''}`}>
            {children}
          </span>
          <ReactTooltip
            id={`tooltip-${id || ''}`}
            place={place || 'bottom'}
            type="dark"
            effect={effect || 'solid'}
            className={styles.ReactTooltip}
            {...(customProps || {})}
          >
            <div className={styles.container}>{title || null}</div>
          </ReactTooltip>
        </>
      ) : (
        <span>{children}</span>
      )}
    </>
  );
};

export default Tooltip;
