import {
  EwpDeleteElementData,
  EwpElementApprovalStatuses,
  ISelectOptions,
  TeamType,
  EWPTeamModel,
} from '../models';
import { t } from 'i18next';

export class EwpHelpers {
  static getUserList = (
    team: number[],
    managers: number[],
    userList: ISelectOptions[],
    teamType: TeamType | null,
  ): ISelectOptions[] => {
    if (teamType === 'team') {
      if (managers.length) {
        return userList.filter(user => !managers.includes(Number(user.value)));
      } else {
        return userList;
      }
    } else {
      if (team.length) {
        return userList.filter(user => !team.includes(Number(user.value)));
      } else {
        return userList;
      }
    }
  };

  static getElDeleteDisabledText = (info?: EwpDeleteElementData) => {
    if (!info) return '';
    if (!info.permissions.has_delete_right) {
      return t('awa:N45.msg');
    }
    return '';
  };
  static getAvailableUsers = (users: EWPTeamModel[], values: ISelectOptions[]): ISelectOptions[] => {
    return [...users.map(user => ({ label: user.name, value: user.id })), ...values];
  };
}
