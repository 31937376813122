import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DOMAIN_URI, ResourceStatuses } from '../../../../../constants';
import { Button } from '../../../../controls/Button';
import { useHistory, useParams } from 'react-router';
import { BelResourceCommentModel, BelResourceModel, IRouteParamsBase } from '../../../../../models';
import { useDispatch } from 'react-redux';
import {
  belChangeResourceStatus,
  belGetResource,
} from '../../../../../store/beloved/resources/resourcesActions';
import { Checkbox, ComaList, ImageSecured, Loader, ResourceStatusLabel } from '../../../../controls';
import ResourceComments from '../parts/ResourceComments';
import { ROOTS } from '../../../../../routes/routes';
import BelovedLogoIc from '../../../../../assets/images/icons/beloved.svg';
import { useIsMyResource } from '../../../../../hooks/resources/use-is-my-resource';
import { useCanEditMyResource } from '../../../../../hooks/resources/use-can-edit-my-resource';
import { useNavToViewResource } from '../../../../../hooks/resources/use-nav-to-view-resource';
import useHasAccess from '../../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../../constants/roles';
import { basicDateFormat } from '../../../../../helpers';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';
import ResourceTools from '../parts/ResourceTools';

const ResourceDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<IRouteParamsBase>();
  let [data, setData] = useState<BelResourceModel | null>(null);
  const navToView = useNavToViewResource();
  const { navBack } = useNavBack();
  const canEditMyResource = useCanEditMyResource(data);
  const isMyResource = useIsMyResource(data);

  const isReviewer = useHasAccess(BelovedPermissions.RESOURCE_LIBRARY_REVIEW);

  useEffect(() => {
    dispatch(
      belGetResource({
        id: Number(id),
        callback: resource => {
          setData(resource);
        },
      }),
    );
  }, []);

  const handleChangeNotes = (comments: BelResourceCommentModel[]) => {
    if (!data) return;
    setData({ ...data, comments });
  };

  const handleEdit = () => {
    history.push(ROOTS.BELOVED_CREATE_RESOURCE, { updateData: data });
  };

  const handlePreview = () => {
    data && navToView(data);
  };

  if (!data) return <Loader fitParent />;

  const refDocValue =
    (data.referenced_links.length && data.referenced_links[0]) ||
    (data.docs.length && data.docs[0].original_file_name) ||
    t('common:column.n-a');

  const handleUnpublish = () => {
    if (!data) return;
    dispatch(
      belChangeResourceStatus({
        status: ResourceStatuses.UNPUBLISH,
        ids: [data.id],
        callback: () => {
          dispatch(
            belGetResource({
              id: Number(id),
              callback: resource => {
                setData(resource);
              },
            }),
          );
        },
      }),
    );
  };

  const renderAdditionalBtn = () => {
    if (!data) return;
    if (data.status !== ResourceStatuses.PUBLISH && (isReviewer || canEditMyResource)) {
      return (
        <>
          <Button
            className="button-mh"
            size={'small'}
            title={t('common:btn.preview')}
            type={'transparency'}
            onPress={handlePreview}
          />
          <Button size={'small'} title={t('common:btn.edit')} onPress={handleEdit} />
        </>
      );
    } else if (isReviewer || canEditMyResource) {
      return (
        <>
          <Button
            className="button-mh"
            size={'small'}
            title={t('common:btn.unpublish')}
            onPress={handleUnpublish}
          />
          <Button size={'small'} title={t('common:btn.view')} onPress={handlePreview} />
        </>
      );
    } else {
      return <Button size={'small'} title={t('common:btn.view')} onPress={handlePreview} />;
    }
  };

  return (
    <>
      <main className="b-page">
        <div className="b-page__header">
          <h1 className="b-page__title -noDesktopMb">
            {t('common:tabs.resource-library')}:<span className="mark"> {t('common:btn.resource')}</span>
          </h1>
          <div className="b-page__headerBtns">{renderAdditionalBtn()}</div>
        </div>
        <h2 className="b-page__title2 d-flex justify-content-between -backIcon font-navy">
          <EmptyLink onClick={navBack} className="b-button-icon-back" />
          <span>{data.title}</span>
          <ResourceTools data={data} />
        </h2>
        <div className="b-panel b-resourcePanel">
          <div className="b-resourceForm__field">
            <div className="b-panelData__fieldTitle">{t('common:label.submitted-by')}</div>
            <div className="b-panelData__fieldValue">{data.submitter?.name}</div>
            <div className="b-resourceForm__selfEnd">
              <ResourceStatusLabel status={data.status} />
            </div>
          </div>
          <div className="b-resourceForm__field -dates">
            <div className="b-panelData__fieldTitle">{t('common:label.date-created')}</div>
            <div className="b-panelData__fieldValue">{basicDateFormat(data.created_at)}</div>
            <div className="b-resourceForm__lastUpdate">
              <div className="b-panelData__fieldTitle">{t('common:column.last-updated')}</div>
              <div className="b-panelData__fieldValue">{basicDateFormat(data.updated_at)}</div>
            </div>
          </div>
          <div className="b-resourceForm__field">
            <div className="b-panelData__fieldTitle">{t('common:label.type')}</div>
            <div className="b-panelData__fieldValue">{data.type.name}</div>
            <div className="b-resourceForm__selfEnd">
              <Checkbox id="Application" onChange={() => null} checked={data.distribution.id === 2}>
                <div className="b-resourceForm__publicLabel">
                  <div className="b-resourceCard__headingIcon" />
                  <span className={'checkbox-text'}> {t('common:label.public')}</span>
                </div>
              </Checkbox>
            </div>
          </div>
          <div className="b-resourceForm__field -description">
            <div className="b-panelData__fieldTitle">{t('common:column.description')}</div>
            <div className="b-panelData__fieldValue">{data.description || t('common:column.n-a')}</div>
          </div>
          <div className="b-resourceForm__field -description">
            <div className="b-panelData__fieldTitle">{t('common:column.referenced-document')}</div>
            <div className="b-panelData__fieldValue">{refDocValue}</div>
            <div></div>
          </div>
          <div className="b-resourceForm__field">
            <div className="b-panelData__fieldTitle">{t('common:column.time-to-read-view')}</div>
            <div className="b-panelData__fieldValue">{data.time_to_read || t('common:column.n-a')}</div>
          </div>
          <div className="b-resourceForm__field">
            <div className="b-panelData__fieldTitle">{t('common:column.thumbnail')}:</div>
            <div>
              {data.thumbnail.length ? (
                <ImageSecured
                  src={`${DOMAIN_URI}${data.thumbnail[0].file}`}
                  className="b-resourceForm__thumbnail"
                  alt={t('awa:AT31')}
                />
              ) : (
                <div className="b-panelData__fieldValue">{t('common:column.n-a')}</div>
              )}
            </div>
          </div>
          <div className="b-resourceForm__field -description">
            <div className="b-panelData__fieldTitle">{t('common:column.authors')}</div>
            <div className="b-panelData__fieldValue">{data.authors || t('common:column.n-a')}</div>
          </div>
          <div className="b-resourceForm__field -description">
            <div className="b-panelData__fieldTitle">{t('common:btn.notes')}</div>
            <div className="b-panelData__fieldValue">{data.notes || t('common:column.n-a')}</div>
          </div>
          <div className="b-resourceForm__field -description">
            <div className="b-panelData__fieldTitle">{t('common:label.categories')}</div>
            <div className="b-panelData__fieldValue">
              <ComaList arr={data.categories} uKey="categories" nullText={t('common:column.n-a')} />
            </div>
          </div>
          <div className="b-resourceForm__field">
            <div className="b-panelData__fieldTitle">{t('common:label.target-sectors')}</div>
            <div className="b-panelData__fieldValue">
              <ComaList arr={data.sectors} uKey="sectors" nullText={t('common:column.n-a')} />
            </div>
          </div>
          <div className="b-resourceForm__field">
            <div className="b-panelData__fieldTitle">{t('common:label.access')}</div>
            <div className="b-panelData__fieldValue">{data.access_type.name}</div>
          </div>
          <div className="b-resourceForm__field">
            <div className="b-panelData__fieldTitle">{t('common:label.tags')}</div>
            <div className="b-panelData__fieldValue">
              <ComaList arr={data.tags} uKey="tags" nullText={t('common:column.n-a')} />
            </div>
          </div>
          <div className="b-resourceForm__field">
            <div className="b-panelData__fieldTitle">{t('common:label.publisher')}</div>
            <div className="b-panelData__fieldValue">
              {data.ownership.id === 1 ? (
                <img className="b-resourceCard__logo" src={BelovedLogoIc} alt={t('awa:AT12')} />
              ) : (
                data.ownership.name
              )}
            </div>
          </div>
          <div className="b-resourceForm__field">
            <div className="b-panelData__fieldTitle">{t('common:label.publish-date')}</div>
            <div className="b-panelData__fieldValue">
              {data.published_date ? basicDateFormat(data.published_date) : t('common:column.n-a')}
            </div>
          </div>
          {isReviewer || isMyResource ? (
            <ResourceComments resourceId={data.id} data={data.comments} onChange={handleChangeNotes} />
          ) : null}
        </div>
      </main>
    </>
  );
};

export default ResourceDetails;
