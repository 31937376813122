import React, { FC, useState } from 'react';
import styles from './PaginationStyles.module.scss';
import { ISelectOptions, PaginationModel } from '../../../models';
import BackIc from '../../../assets/images/icons/round-navigation-chevron-left.svg';
import { Popover } from 'react-tiny-popover';
import { useTranslation } from 'react-i18next';

interface IProps {
  handleChangePageSize: (value: number, name?: string | undefined) => void;
  data: PaginationModel;
  changePage: (page: number) => void;
}

const countPages = [
  {
    label: '15',
    value: 15,
  },
  {
    label: '30',
    value: 30,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

const Pagination: FC<IProps> = ({ handleChangePageSize, data, changePage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleIsOpen = (status: boolean) => {
    setIsOpen(status);
  };

  const handleChange = (page: ISelectOptions) => {
    handleChangePageSize(+page.value);
    setIsOpen(false);
  };

  const getContent = () => {
    return (
      <div className="b-dropDownMenu">
        <ul>
          {countPages.map((v: ISelectOptions) => {
            return (
              <li
                key={`pagination-${v.value}`}
                className={`${data.page_size === v.value ? styles.filters_active : ''}`}
                onClick={() => handleChange(v)}
              >
                {v.label}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const prevPage = () => {
    if (data.current_page > 1) {
      changePage(data.current_page - 1);
    }
  };

  const nextPage = () => {
    if (data.current_page && data.current_page !== data.total_pages) {
      changePage(data.current_page + 1);
    }
  };

  return (
    <div className={styles.pagination}>
      <div className={styles.counts}>
        <span className={styles.counts__title}>{t('common:label.rows-per-page')}</span>
        <Popover
          isOpen={isOpen}
          positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
          content={getContent()}
          reposition={false}
          containerClassName={'popover'}
          onClickOutside={() => handleIsOpen(false)}
        >
          <div onClick={() => handleIsOpen(!isOpen)} className={styles.popover}>
            <span>{data && data.page_size}</span>
            <img src={BackIc} alt={t('common:alt.backIc')} />
          </div>
        </Popover>
      </div>
      <div className={styles.navigation}>
        <span className={styles.currentPage}>
          {data.start_index}-{data.end_index} {t('common:label.of')} {data.count}
        </span>
        <div className={styles.btns}>
          <div
            onClick={prevPage}
            className={`${styles.button} ${data.current_page > 1 ? styles.button_active : ''}`}
          >
            <button className={styles.button__icon} aria-label="previous page" />
          </div>
          <div
            onClick={nextPage}
            className={`${styles.button} ${styles.right} ${
              data.current_page < data.total_pages ? styles.button_active : ''
            }`}
          >
            <button className={styles.button__icon} aria-label="next page" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
