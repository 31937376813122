import { EwpStatuses } from '../models';
import { ElmStatuses } from '../constants/elm';

export const getBelovedEaStatusClass = (statusId: number) => {
  switch (statusId) {
    case 0:
      return 'inPreparation';
    case 1:
      return 'preparationCompleted';
    case 2:
      return 'inProgress';
    case 3:
      return 'completed';
    case 4:
      return 'inactive';
  }
};

export const getEwpApproveStatusClass = (statusId: number) => {
  switch (statusId) {
    case EwpStatuses.IN_PREP:
      return 'inProgress';
    case EwpStatuses.SUBMITTED_FOR_REVIEW:
      return 'preparationCompleted';
    case EwpStatuses.IN_REVIEW:
      return 'inProgress';
    case EwpStatuses.REVIEW_COMPLETED:
    case EwpStatuses.APPROVED:
      return 'completed';
  }
};

export const getBelovedEwpStatusClass = (statusId: number) => {
  switch (statusId) {
    case EwpStatuses.IN_PREP:
      return 'inProgress';
    case EwpStatuses.SUBMITTED_FOR_REVIEW:
      return 'color4';
    case EwpStatuses.IN_REVIEW:
      return 'color2';
    case EwpStatuses.REVIEW_COMPLETED:
      return 'color5';
    case EwpStatuses.APPROVED:
    case EwpStatuses.COMPLETED:
      return 'completed';
    case EwpStatuses.IN_PROGRESS:
      return 'inProgress';
  }
};

export const getBelovedElmStatusClass = (statusId: number) => {
  switch (statusId) {
    case ElmStatuses.IN_PREPARATION:
      return 'inPreparation';
    case ElmStatuses.SCHEDULED:
      return 'preparationCompleted';
    case ElmStatuses.IN_PROGRESS:
      return 'inProgress';
    case ElmStatuses.COMPLETED:
      return 'red';
    case ElmStatuses.COMPLETED_LOCKED:
      return 'completed';
  }
};
