import { DebriefChartModel, SplitDebriefChartResultModel } from '../models';

export const splitDebriefChart = (chart: DebriefChartModel): SplitDebriefChartResultModel => {
  const chart1Data = {
    global_data: [],
    data: [],
    labels: [],
  };

  const chart2Data = {
    global_data: [],
    data: [],
    labels: [],
  };

  const chart3Data = {
    global_data: [],
    data: [],
    labels: [],
  };

  if (chart?.labels?.length) {
    for (let i = 0; i < chart.labels.length; i++) {
      if (i >= 0 && i <= 4) {
        //@ts-ignore
        chart1Data.data.push(chart.data[i]);
        //@ts-ignore
        chart1Data.global_data.push(chart.global_data[i]);
        //@ts-ignore
        chart1Data.labels.push(chart.labels[i]);
      }
      if (i >= 5 && i <= 10) {
        //@ts-ignore
        chart2Data.data.push(chart.data[i]);
        //@ts-ignore
        chart2Data.global_data.push(chart.global_data[i]);
        //@ts-ignore
        chart2Data.labels.push(chart.labels[i]);
      }
      if (i >= 11) {
        //@ts-ignore
        chart3Data.data.push(chart.data[i]);
        //@ts-ignore
        chart3Data.global_data.push(chart.global_data[i]);
        //@ts-ignore
        chart3Data.labels.push(chart.labels[i]);
      }
    }
  }

  return { chart1Data, chart2Data, chart3Data };
};
