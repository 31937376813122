import { useSelector } from 'react-redux';
import { StateType } from '../../../store/reducers';
import { BelCohortModel } from '../../../models';

export const useIsAccessEditCohort = (cohort: BelCohortModel | null): boolean => {
  const user = useSelector((state: StateType) => state.profile.authUser?.user);
  const isBeloved = location.pathname.match('beloved');
  return !!(
    (isBeloved && cohort?.beloved_team.find(u => u.user_id === user?.id)) ||
    (cohort && cohort.submitter?.id === user?.id) ||
    (!cohort && isBeloved)
  );
};
