import React, { useEffect } from 'react';
import { BelovedDashboardTabs, DateFilter, NewsBlock } from '../../../../common';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardEA } from '../../../../../store/dashboard/dashboardActions';
import { KeyValueModel, EaByMonthModel, FilterByDatePayloadModel } from '../../../../../models';
import ChartPie from '../../../../common/ChartPie/ChartPie';
import { Loader } from '../../../../controls';
import styles from '../DashboardElm/DashboardElmStyles.module.scss';
import { useTranslation } from 'react-i18next';
import { StateType } from '../../../../../store/reducers';

const DashboardEa = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dashboardEA = useSelector((state: StateType) => state.dashboard.ea);

  useEffect(() => {
    dispatch(getDashboardEA({}));
  }, []);

  const handleFilterByDate = ({ period, date_range, to_date, from_date }: FilterByDatePayloadModel) => {
    const params: KeyValueModel<string> = {};
    if (period) params.period = period;
    if (to_date || from_date) params.date_range = date_range;
    dispatch(getDashboardEA({ params }));
  };

  const renderContent = () => {
    if (!dashboardEA) {
      return <Loader fitParent />;
    }

    return (
      <div className="b-dashboardBoxList">
        <div className="b-dashboardBoxList__item">
          <h2 className="b-dashboardBoxList__title">{t('main:dashboard.total-completed-ea')}</h2>
          <div className="b-dashboardBoxList__content">
            <div className="b-dashboardBoxList__numTotal">
              <b className="b-dashboardBoxList__num">{dashboardEA.ea_by_status.completed.val}</b>
            </div>
          </div>
        </div>

        <div className="b-dashboardBoxList__item">
          <h2 className="b-dashboardBoxList__title">{t('main:dashboard.debriefs')}</h2>
          <div className="b-dashboardBoxList__content">
            <ul className="b-dashboardBoxList__numList">
              <li>
                <b className="b-dashboardBoxList__num">{dashboardEA.ea_debriefs.completed}</b>
                <span>{t('common:status.completed')}</span>
              </li>
              <li>
                <b className="b-dashboardBoxList__num">{dashboardEA.ea_debriefs.scheduled}</b>
                <span>{t('common:status.scheduled')}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="b-dashboardBoxList__item">
          <h2 className="b-dashboardBoxList__title">{t('main:dashboard.ea-status')}</h2>
          <div className={styles.total}>
            <span className={styles.total__number}>{dashboardEA.total_ea}</span>
            <span className={styles.total__title}>{t('main:dashboard.total-ea')}</span>
          </div>

          <div className="b-dashboardBoxList__content">
            {/* statusChart start */}
            <div className="b-statusChart">
              <div className="b-statusChart__item b-statusChart__item--inPrep">
                <span className="b-statusChart__title">{t('common:status.in-preparation')}</span>
                <div className="b-statusChart__value">
                  <span>{dashboardEA.ea_by_status.in_preparation.val}</span>
                  <span className="b-statusChart__divider" />
                  <span>{dashboardEA.ea_by_status.in_preparation.percent}%</span>
                </div>
              </div>
              <div className="b-statusChart__item b-statusChart__item--prepCompleted">
                <span className="b-statusChart__title">{t('common:status.preparation-completed')}</span>
                <div className="b-statusChart__value">
                  <span>{dashboardEA.ea_by_status.preparation_completed.val}</span>
                  <span className="b-statusChart__divider" />
                  <span>{dashboardEA.ea_by_status.preparation_completed.percent}%</span>
                </div>
              </div>
              <div className="b-statusChart__item b-statusChart__item--inProgress">
                <span className="b-statusChart__title">{t('common:status.in-progress')}</span>
                <div className="b-statusChart__value">
                  <span>{dashboardEA.ea_by_status.in_progress.val}</span>
                  <span className="b-statusChart__divider" />
                  <span>{dashboardEA.ea_by_status.in_progress.percent}%</span>
                </div>
              </div>
              <div className="b-statusChart__item b-statusChart__item--completed">
                <span className="b-statusChart__title">{t('common:status.completed')}</span>
                <div className="b-statusChart__value">
                  <span>{dashboardEA.ea_by_status.completed.val}</span>
                  <span className="b-statusChart__divider" />
                  <span>{dashboardEA.ea_by_status.completed.percent}%</span>
                </div>
              </div>
            </div>
            {/* statusChart end */}
          </div>
        </div>

        <div className="b-dashboardBoxList__item">
          <h2 className="b-dashboardBoxList__title">{t('main:dashboard.ea-status')}</h2>
          <span className="b-dashboardBoxList__subTitle">({t('common:status.last-3-months')})</span>
          <div className="b-dashboardBoxList__content">
            {/* b-statusMonthsChart start */}
            <div className="b-statusMonthsChart">
              <div className="b-statusMonthsChart__chart">
                {Object.keys(dashboardEA.ea_by_status_3month || {}).map(month => {
                  const monthData: EaByMonthModel | any = dashboardEA?.ea_by_status_3month[month];
                  return (
                    <div key={`month-${month}`} className="b-statusMonthsChart__col">
                      <div className="b-statusMonthsChart__valuesHolder">
                        {Object.keys(monthData || {}).map((status: keyof KeyValueModel<EaByMonthModel>) => {
                          return (
                            <div
                              key={`percent-${month}-${status}`}
                              className={`b-statusMonthsChart__value b-statusMonthsChart__value--${status}`}
                              style={{ height: '26%' }}
                            >
                              {monthData[status]}
                            </div>
                          );
                        })}
                      </div>
                      <span className="b-statusMonthsChart__monthName">{month}</span>
                    </div>
                  );
                })}
              </div>

              <div className="b-statusMonthsChart__legend">
                <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--inactive">
                  {t('common:status.inactive')}
                </div>
                <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--completed">
                  {t('common:status.complete')}
                </div>
                <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--inProgress">
                  {t('common:status.in-progress')}
                </div>
                <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--prepCompleted">
                  {t('common:status.preparation-completed')}
                </div>
                <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--inPrep">
                  {t('common:status.in-preparation')}
                </div>
              </div>
            </div>
            {/* statusMonthsChart end */}
          </div>
        </div>

        <div className="b-dashboardBoxList__item">
          <h2 className="b-dashboardBoxList__title">{t('main:dashboard.ea-organization')}</h2>
          <div className="b-dashboardBoxList__content">
            <ChartPie type={'ea'} chart={dashboardEA.ea_by_orgs} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <main className={'b-page'}>
      <h1 className={'b-page__title'}>{t('main:dashboard.dashboard')}</h1>
      <div className="b-heading">
        <BelovedDashboardTabs activeItemAlias={'equity-audit'} />
        <DateFilter onSubmit={handleFilterByDate} />
      </div>
      {renderContent()}
      <NewsBlock />
    </main>
  );
};

export default DashboardEa;
