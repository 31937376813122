import React, { useEffect, useState } from 'react';
import {
  userSwitchStatus,
  usersInvite,
  usersRemove,
  setQuery,
} from '../../../../store/organization/organizationActions';
import styles from './OrgUsers.module.scss';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import {
  IRouteParamsBase,
  OrganizationUserModel,
  OrganizationUsersModel,
  PaginationModel,
  TableDataEntities,
} from '../../../../models';
import {
  Alert,
  HeadlineOrganization,
  Modal,
  OrgBreadcrumbs,
  OrgProfileTabs,
  Pagination,
} from '../../../common';
import { Button, Tooltip } from '../../../controls';
import { ROOTS } from '../../../../routes/routes';
import { useHistory, useLocation } from 'react-router';
import DataGrid, { SortColumn } from 'react-data-grid';
import { Modals, ModalsModel, orderValues } from '../../../../constants';
import { getColumns } from './parts/columns';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { useRole } from '../../../../helpers/roles';
import { convertUserStatus } from '../../../../helpers';
import { OrganizationState } from '../../../../store/organization/organizationReducer';
import { useTranslation } from 'react-i18next';
import { useIsDarkMode } from '../../../../hooks/common/use-is-dark-mode';
import useTableConfig from '../../../../hooks/common/use-table-config';
import useTableData from '../../../../hooks/common/use-table-data';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import FilterModal from './parts/FilterModal';
import EmptyButton from '../../../controls/Button/EmptyButton';

interface IState {
  // query: OrganizationState['query'];
  isSelectAll: boolean;
  selectedUserIds: number[];
  modals: ModalsModel;
  orgUsers: OrganizationUsersModel | null;
  sorting: SortColumn | null;
}

interface ILocationState {
  org?: { id: number; name: string };
  noFilters?: boolean;
}

const OrgUsers = () => {
  const { t } = useTranslation();
  const { orgId } = useParams<IRouteParamsBase>();
  const currentOrg = useSelector((state: StateType) => state.organization.currentOrg);
  const access = useRole();
  const { isDarkMode } = useIsDarkMode();
  const [state, setState] = useState<IState>({
    sorting: null,
    isSelectAll: false,
    selectedUserIds: [],
    modals: {},
    orgUsers: null,
  });
  const isAccess = access(['project_admin', 'project_manager']);
  const history = useHistory();
  const location = useLocation<ILocationState | undefined>();

  const { modals, orgUsers } = state;
  const dispatch = useDispatch();
  const orgUsersSelector: OrganizationUsersModel | null = useSelector(
    (state: StateType) => state.organization.currentOrgUsers || null,
  );

  const {
    query,
    fetchData,
    handleChangePage,
    handleChangePageSize,
    handleSearch,
    handleSort,
    selectedEntitiesIds,
    setSelectedEntitiesIds,
    handleSelectOne,
  } = useTableData(TableDataEntities.ORG_USERS);

  const { columns, onColumnResize } = useTableConfig<OrganizationUserModel>(
    TableDataEntities.ORG_USERS,
    getColumns(),
  );

  useEffect(() => {
    let newQuery: OrganizationState['query'] = { ...query };
    if (location.state) {
      if (location.state.org) {
        newQuery.search = location.state.org.name;
        newQuery.page = 1;
        dispatch(setQuery(newQuery));
      }
    }
    fetchData(newQuery);
  }, [location.key]);

  useEffect(() => {
    if (query.sorting !== state.sorting) {
      setState({
        ...state,
        sorting: query.sorting,
      });
    }
  }, [query.sorting]);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      orgUsers: orgUsersSelector,
    }));
  }, [orgUsersSelector]);

  const handleChangeOrder = (value: string) => {
    setState({
      ...state,
      sorting: {
        columnKey: value,
        direction: 'ASC',
      },
    });
  };

  const handleSelectAll = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const selected: number[] = [];
    if (target.checked) {
      orgUsers?.result.map(r => {
        selected.push(r.id);
      });
    }
    setState({
      ...state,
      isSelectAll: target.checked,
      selectedUserIds: selected,
    });
  };

  useEffect(() => {
    if (state.selectedUserIds !== selectedEntitiesIds) {
      setState({
        ...state,
        selectedUserIds: selectedEntitiesIds,
      });
    }
  }, [selectedEntitiesIds]);

  const handleSelectUser = (e: React.ChangeEvent<HTMLInputElement>, user: OrganizationUserModel) => {
    e.stopPropagation();
    const selectedUserIds = state.selectedUserIds || [];
    if (e.target.checked) {
      selectedUserIds.push(user.id);
    } else {
      const findUserIndex = selectedUserIds.findIndex(f => f === user.id);
      selectedUserIds.splice(findUserIndex, 1);
    }
    setState({
      ...state,
      selectedUserIds: selectedUserIds,
    });
  };

  const handleAddUser = () => {
    history.push(ROOTS.ORG_USERS_CREATE.replace(':orgId', `${orgId}`));
  };

  const navigateToUserProfile = (user: OrganizationUserModel) => {
    history.push(ROOTS.USER_PROFILE.replace(':orgId', orgId).replace(':userId', String(user.id)));
  };

  const openModal = (modalName: string) => {
    setState({
      ...state,
      modals: {
        ...state.modals,
        [modalName]: true,
      },
    });
  };

  const closeModal = (modalName: string) => {
    setState(prevState => ({
      ...prevState,
      modals: {
        ...state.modals,
        [modalName]: false,
      },
    }));
  };

  const handleDeactivateUser = () => {
    const activatedUsers = state.orgUsers?.result
      .filter(f => f.is_active && state.selectedUserIds.includes(f.id))
      .map(u => u.id);
    dispatch(
      userSwitchStatus({
        organization_id: String(currentOrg?.id),
        data: {
          team_ids: activatedUsers || [],
          is_active: false,
        },
        callback: (res, err) => {
          if (err) {
            return;
          }
          setState({
            ...state,
            modals: {
              ...state.modals,
              [Modals.isDeactivateUser]: false,
            },
            selectedUserIds: [],
          });
          setSelectedEntitiesIds([]);
          fetchData(query);
        },
      }),
    );
  };

  const handleActivateUser = () => {
    const deactivatedUsers = state.orgUsers?.result
      .filter(f => !f.is_active && state.selectedUserIds.includes(f.id))
      .map(u => u.id);
    dispatch(
      userSwitchStatus({
        organization_id: String(currentOrg?.id),
        data: {
          team_ids: deactivatedUsers || [],
          is_active: true,
        },
        callback: (res, err) => {
          if (err) {
            toast.error(t('common:toast.error'));
            return;
          }
          closeModal(Modals.isActivateUser);
          setSelectedEntitiesIds([]);
          fetchData(query);
        },
      }),
    );
  };

  const handleDeleteUser = () => {
    dispatch(
      usersRemove({
        organization_id: String(currentOrg?.id),
        data: {
          team_ids: state.selectedUserIds || [],
        },
        callback: (res, err) => {
          if (err) {
            return;
          }
          fetchData(query);
          setState({
            ...state,
            modals: {
              ...state.modals,
              [Modals.isDeleteUser]: false,
            },
            selectedUserIds: [],
          });
          setSelectedEntitiesIds([]);
        },
      }),
    );
  };

  const handleResendEmail = () => {
    dispatch(
      usersInvite({
        organization_id: String(currentOrg?.id),
        data: {
          team_ids: state.selectedUserIds || [],
        },
        callback: (result, err) => {
          if (err) {
            return;
          }
          setState({
            ...state,
            modals: {
              ...state.modals,
              [Modals.isResendEmail]: false,
            },
            selectedUserIds: [],
          });
          setSelectedEntitiesIds([]);
          toast.success(result.msg);
          fetchData(query);
        },
      }),
    );
  };

  const handleSorting = () => {
    if (state.sorting) {
      handleSort(state.sorting);
    }

    setState({
      ...state,
      modals: {},
    });
  };

  const onRowClick = (row: OrganizationUserModel) => {
    navigateToUserProfile(row);
  };

  const handleChangeOrderDir = () => {
    let sorting: SortColumn = {
      columnKey: state.sorting?.columnKey || 'id',
      direction: state.sorting?.direction === 'ASC' ? 'DESC' : 'ASC',
    };

    if (!state.sorting) {
      const sorting: SortColumn = {
        columnKey: 'id',
        direction: 'DESC',
      };
      handleSort(sorting);
      return;
    }

    handleSort(sorting);

    setState({
      ...state,
      sorting: sorting,
    });
  };

  const renderContent = () => {
    if (isMobile) {
      return (
        <div className={styles.boxList}>
          {orgUsers?.result?.map(user => {
            const isChecked = !!state.selectedUserIds.find(f => f === user.id);
            return (
              <div
                key={`user-${user.id}`}
                className={`${styles.boxList_item} b-panel`}
                onClick={() => navigateToUserProfile(user)}
              >
                <label className={'checkbox ' + styles.checkbox} onClick={e => e.stopPropagation()}>
                  <input
                    type="checkbox"
                    checked={isChecked || state.isSelectAll}
                    onChange={e => handleSelectUser(e as any, user)}
                  />
                  <span className="checkbox_fakeInput"></span>
                </label>
                <span className={styles.boxList_userName}>
                  {user.first_name} {user.last_name}
                </span>
                <ul>
                  <li>{user.email}</li>
                  <li>{user.division}</li>
                  <li>{convertUserStatus(user.status, user.status_date)}</li>
                </ul>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <DataGrid
          onRowClick={onRowClick}
          onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
          columns={columns}
          onColumnResize={onColumnResize}
          sortColumns={query.sorting ? [query.sorting] : []}
          rows={orgUsers?.result || []}
          className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
          style={{
            height: ((orgUsers?.result.length || 0) + 1) * 60,
          }}
          rowHeight={60}
          rowKeyGetter={(row: OrganizationUserModel) => {
            return row.id;
          }}
          onSelectedRowsChange={handleSelectOne}
          selectedRows={new Set(state.selectedUserIds)}
        />
      </DndProvider>
    );
  };

  const infoContentRender = () => {
    return (
      <div className={styles.infoPopup}>
        {!isMobile && <strong className={styles.infoPopup_title}>{t('awa:M19.title')}</strong>}
        <div className={styles.infoPopup_item}>
          <span className={`${styles.infoPopup__icon} ${styles.infoPopup__icon_send}`} />
          <span className={styles.infoPopup_subTitle}>{t('awa:M19.msg.p1.title')}</span>
          <p>{t('awa:M19.msg.p1.msg')}</p>
        </div>
        <div className={styles.infoPopup_item}>
          <span className={`${styles.infoPopup__icon} ${styles.infoPopup__icon_personOff}`} />
          <span className={styles.infoPopup_subTitle}>{t('awa:M19.msg.p2.title')}</span>
          <p>
            When you deactivate a user from your organization, they no longer have access to any of your
            organization’s data or Equity Audits. You may re-activate a user at a later time.
          </p>
        </div>
        <div className={styles.infoPopup_item}>
          <span className={`${styles.infoPopup__icon} ${styles.infoPopup__icon_delete}`} />
          <span className={styles.infoPopup_subTitle}>{t('awa:M19.msg.p3.title')}</span>
          <p>{t('awa:M19.msg.p3.msg')}</p>
        </div>
      </div>
    );
  };

  const collectUserStatuses = (is_active: boolean): boolean => {
    return state.selectedUserIds
      .map(id => {
        const user = state.orgUsers?.result.find(u => u.id === id);
        if (user) {
          return user.is_active;
        }
      })
      .includes(is_active);
  };

  const isInviteStatus = (): boolean => {
    return state.selectedUserIds
      .map(id => {
        const user = state.orgUsers?.result.find(u => u.id === id);
        if (user) {
          return user.status === 'Invited';
        }
      })
      .includes(true);
  };

  return (
    <>
      <main className={'b-page'}>
        <HeadlineOrganization
          orgName={currentOrg?.name || ''}
          orgId={orgId}
          headline={t('common:headlines.users')}
          customClasses={'-desktop'}
        />
        <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
        <OrgProfileTabs activeItemAlias={'org-users'} />
        <div>
          <div className="b-page__controls">
            <div className="b-tableToolContainer">
              <button
                aria-label="Click to Filter"
                onClick={() => openModal(Modals.isFilter)}
                className={`
                    b-tableTool
                    -mr
                    -filter
                    -colorGrey
                    ${query.filters?.roles?.length || query.filters?.statuses?.length ? '-active' : ''}
                  `}
              >
                {t('common:btn.filter')}
              </button>
              <button
                aria-label="Click to Sort"
                onClick={() => openModal(Modals.isSorting)}
                type="button"
                className={`
                    b-tableTool -sort -colorGrey -mr
                    ${query.sorting && state.sorting?.columnKey !== 'id' && '-active'}
                  `}
              >
                {t('common:btn.sort')}
              </button>
              <button
                onClick={handleChangeOrderDir}
                type="button"
                className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                    ${state.sorting?.direction === 'DESC' && '-active'}
                  `}
              >
                {t('common:btn.a-z-order')}
              </button>
              {/*</li>*/}
            </div>
            <div className="b-page__search">
              <form action="#" className="searchForm">
                <input
                  type="search"
                  placeholder={t('common:label.search')}
                  aria-label="Search"
                  value={query.search}
                  onChange={e => handleSearch(e.target.value)}
                />
                <span className="searchForm__icon" />
              </form>
            </div>
          </div>
          {isAccess && (
            <div className="b-page__controls -mobHorizontal">
              <div className="b-tableToolContainer">
                <>
                  {!isMobile ? (
                    <Tooltip title={infoContentRender()}>
                      <EmptyButton className="b-button-icon -mr -info" />
                    </Tooltip>
                  ) : (
                    <EmptyButton
                      className="b-button-icon -mr -info"
                      onClick={() => openModal(Modals.isInfo)}
                    />
                  )}
                </>
                <button
                  className="b-tableTool -send -mobView -mr"
                  disabled={!state.selectedUserIds.length || !isInviteStatus()}
                  onClick={() => openModal(Modals.isResendEmail)}
                >
                  {t('common:btn.invite')}
                </button>
                <button
                  className="b-tableTool -userOn -mobView -mr"
                  disabled={!state.selectedUserIds.length || !collectUserStatuses(false)}
                  onClick={() => openModal(Modals.isActivateUser)}
                >
                  {t('common:btn.activate')}
                </button>
                <button
                  className="b-tableTool -userOff -mobView -mr"
                  disabled={!state.selectedUserIds.length || !collectUserStatuses(true)}
                  onClick={() => openModal(Modals.isDeactivateUser)}
                >
                  {t('common:btn.deactivate')}
                </button>
                <button
                  className="b-tableTool -delete -mobView -mr"
                  disabled={!state.selectedUserIds.length}
                  onClick={() => openModal(Modals.isDeleteUser)}
                >
                  {t('common:btn.delete')}
                </button>
              </div>
              <div className="b-tableToolContainer">
                <button className="b-tableTool -addPlus -mobView -mr" onClick={handleAddUser}>
                  {t('common:btn.new-user')}
                </button>
                {isMobile ? (
                  <label className="checkbox">
                    <input type="checkbox" onChange={handleSelectAll} />
                    <span className="checkbox_fakeInput"></span>
                  </label>
                ) : null}
              </div>
            </div>
          )}
          {renderContent()}
        </div>

        {!isMobile && (
          <Pagination
            data={orgUsers || ({} as PaginationModel)}
            handleChangePageSize={handleChangePageSize}
            changePage={handleChangePage}
          />
        )}
      </main>
      {modals.isDeactivateUser ? (
        <Alert
          isOpen={modals.isDeactivateUser}
          onRequestClose={() => closeModal(Modals.isDeactivateUser)}
          buttons={{
            left: {
              type: 'white',
              onClick: () => closeModal(Modals.isDeactivateUser),
              title: t('common:btn.cancel'),
            },
            right: {
              type: 'orange-light',
              onClick: handleDeactivateUser,
              title: t('common:btn.deactivate'),
            },
          }}
          title={t('awa:N7.title')}
          text={t('awa:N7.msg')}
          contentStyles={{
            width: '350px',
            transform: 'translate(-50%, -80%)',
          }}
        />
      ) : null}
      {modals.isActivateUser ? (
        <Alert
          isOpen={modals.isActivateUser}
          onRequestClose={() => closeModal(Modals.isActivateUser)}
          buttons={{
            left: {
              type: 'white',
              onClick: () => closeModal(Modals.isActivateUser),
              title: t('common:btn.cancel'),
            },
            right: {
              type: 'orange-light',
              onClick: handleActivateUser,
              title: t('common:btn.activate'),
            },
          }}
          title={t('awa:N11.title')}
          text={t('awa:N11.msg')}
          contentStyles={{
            width: '350px',
            transform: 'translate(-50%, -80%)',
          }}
        />
      ) : null}
      {modals.isDeleteUser ? (
        <Alert
          isOpen={modals.isDeleteUser}
          onRequestClose={() => closeModal(Modals.isDeleteUser)}
          buttons={{
            left: {
              type: 'white',
              onClick: () => closeModal(Modals.isDeleteUser),
              title: t('common:btn.cancel'),
            },
            right: {
              type: 'orange-light',
              onClick: handleDeleteUser,
              title: t('common:btn.delete'),
            },
          }}
          title={t('main:org-users.delete-user.title')}
          text={t('main:org-users.delete-user.msg')}
          contentStyles={{
            width: '350px',
            transform: 'translate(-50%, -80%)',
          }}
        />
      ) : null}
      {modals.isResendEmail ? (
        <Alert
          isOpen={!!modals.isResendEmail}
          onRequestClose={() => closeModal(Modals.isResendEmail)}
          buttons={{
            left: {
              type: 'white',
              onClick: () => closeModal(Modals.isResendEmail),
              title: t('common:btn.cancel'),
            },
            right: {
              type: 'orange-light',
              onClick: handleResendEmail,
              title: t('common:btn.send'),
            },
          }}
          title={t('main:org-users.new-invite')}
          text={` `}
          contentStyles={{
            width: '350px',
            transform: 'translate(-50%, -80%)',
          }}
        />
      ) : null}
      {modals.isInfo ? (
        <Modal
          width={545}
          onClose={() => closeModal(Modals.isInfo)}
          className="b-filtersModal__mobileModal"
          title={t('common:label.Learn-More')}
        >
          {infoContentRender()}
        </Modal>
      ) : null}
      {modals.isFilter ? <FilterModal onClose={() => closeModal(Modals.isFilter)} /> : null}
      {modals.isSorting ? (
        <Modal
          showCloseBtn={false}
          width={350}
          onClose={() => closeModal(Modals.isSorting)}
          className={!isMobile ? 'b-filtersModal__mobileModal' : ''}
          title={t('common:filter.sort')}
          classNameTitle="b-filtersModal__modalTitle"
        >
          <div className="b-filtersModal">
            <div className="b-filtersModal__list">
              <div className="b-filtersModal__title">{t('common:filter.by')}</div>
              <ul>
                {orderValues().map(o => {
                  const isActive = state.sorting?.columnKey === o.value;
                  return (
                    <li key={o.value}>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          handleChangeOrder(o.value);
                        }}
                        className={isActive ? '-active' : ''}
                      >
                        {o.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="b-filtersModal__buttons">
            <Button
              onPress={handleSorting}
              title={t('common:filter.sort')}
              size={'large'}
              type={'orange-light'}
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default OrgUsers;
