import React, { FC, useState } from 'react';
import { ROOTS } from '../../../../routes/routes';
import styles from './MyAccount.module.scss';
import { StateType } from '../../../../store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logoutRequest } from '../../../../store/authData/authDataActions';
import { ResetPassword } from '../ResetPassword/ResetPassword';
import { useTranslation } from 'react-i18next';
import { getCurrentYear } from '../../../../helpers';

interface IProps {
  activeItemAlias: 'my-profile' | 'my-settings' | 'terms-conditions' | 'privacy-policy' | 'support';
}

const MyAccount: FC<IProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: StateType) => state.profile.authUser);
  const infoList = useSelector((state: StateType) => state.utils.info);
  const [modal, setModal] = useState<boolean>(false);

  if (!(user && user.user)) return null;

  const handleClick = (alias: IProps['activeItemAlias']) => (e: any) => {
    e.preventDefault();
    switch (alias) {
      case 'my-profile':
        history.push(ROOTS.MY_PROFILE);
        break;
      case 'my-settings':
        history.push(ROOTS.MY_SETTINGS);
        break;
      case 'terms-conditions':
        history.push(ROOTS.TERMS_CONDITIONS);
        break;
      case 'privacy-policy':
        history.push(ROOTS.PRIVACY_POLICY);
        break;
      case 'support': {
        // @ts-ignore
        FreshworksWidget('open');
        break;
      }
    }
  };

  const handleLogout = (e: any) => {
    e.preventDefault();
    dispatch(
      logoutRequest({
        callback: () => {
          history.push(ROOTS.SIGNIN);
        },
      }),
    );
    history.push(ROOTS.SIGNIN);
  };

  const passwordChangedSuccess = () => {
    setModal(false);
  };

  const resetPassword = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setModal(true);
  };

  return (
    <>
      <main className={'pageContainer pageContainer__content'}>
        <div className={styles.myAccount}>
          <h1 className={styles.myAccount__title}>{t('common:headlines.my-account')}</h1>
          <div className={styles.myAccount__userInfo}>
            <span className={styles.myAccount__userName}>
              {user.user.first_name} {user.user.last_name}
            </span>
            <span className={styles.myAccount__userEmail}>{user.user.email}</span>
            {user.user.secondary_email && (
              <>
                <span className={styles.myAccount__userText}>
                  {t('common:label.secondary-email-address')}
                </span>
                <span className={styles.myAccount__userSecondEmail}>{user.user.secondary_email}</span>
              </>
            )}
          </div>
          <a href="" className={styles.myAccount__reset} onClick={resetPassword}>
            {t('common:btn.reset-password')}
          </a>
          <div className={styles.myAccount__linkWrapper}>
            <a href="" className={styles.myAccount__link} onClick={handleClick('my-profile')}>
              <span className={styles.myAccount__text}>{t('common:tabs.my-profile')}</span>
              <div className={`${styles.myAccount__icon} ${styles.myAccount__icon_profile}`} />
            </a>
            <a href="" className={styles.myAccount__link} onClick={handleClick('my-settings')}>
              <span className={styles.myAccount__text}>{t('common:tabs.settings')}</span>
              <div className={`${styles.myAccount__icon} ${styles.myAccount__icon_settings}`} />
            </a>
            <a href="" className={styles.myAccount__link} onClick={handleClick('terms-conditions')}>
              <span className={styles.myAccount__text}>{t('common:tabs.terms-conditions')}</span>
              <div className={`${styles.myAccount__icon} ${styles.myAccount__icon_termsConditions}`} />
            </a>
            <a href="" className={styles.myAccount__link} onClick={handleClick('privacy-policy')}>
              <span className={styles.myAccount__text}>{t('common:tabs.privacy-policy')}</span>
              <div className={`${styles.myAccount__icon} ${styles.myAccount__icon_privacyPolicy}`} />
            </a>
            <a href="" className={styles.myAccount__link} onClick={handleClick('support')}>
              <span className={styles.myAccount__text}>{t('common:tabs.support')}</span>
              <div className={`${styles.myAccount__icon} ${styles.myAccount__icon_support}`} />
            </a>
          </div>
          <div className={styles.myAccount__bottomWrapper}>
            <a href="#" onClick={handleLogout} className={styles.myAccount__logout}>
              {t('common:btn.log-out')}
            </a>
            <div className={styles.myAccount__version}>
              <div>Awa by Beloved {infoList.app_version}</div>
              <div>© {getCurrentYear()}, Beloved Community</div>
            </div>
          </div>
        </div>
      </main>
      {modal && (
        <ResetPassword closeModal={() => setModal(false)} passwordChangedSuccess={passwordChangedSuccess} />
      )}
    </>
  );
};

export default MyAccount;
