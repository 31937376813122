import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROOTS } from '../../../routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../store/reducers';
import { Alert } from '../index';
import { isMobileOnly } from 'react-device-detect';
import useHasAccess from '../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../constants/roles';
import { IRouteParamsBase } from '../../../models';
import { belGetEaDetails } from '../../../store/beloved/equityAudit/equityAuditActions';
import { useTranslation } from 'react-i18next';

interface IProps {
  activeItemAlias: 'details' | 'report' | 'debrief';
}

const BelovedEaTabs: FC<IProps> = ({ activeItemAlias }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentEa = useSelector((state: StateType) => state.belEquityAudits.currentEa);
  let [notMobileAlert, setNotMobileAlert] = useState(false);
  const { eaId } = useParams<IRouteParamsBase>();

  const hasAccessToEaReport = useHasAccess(BelovedPermissions.EA_REPORT);
  const hasAccessToViewDebrief = useHasAccess(BelovedPermissions.DEBRIEF_VIEW_PRESENTATION);

  useEffect(() => {
    if (!currentEa || (currentEa && currentEa.id !== Number(eaId))) {
      dispatch(
        belGetEaDetails({
          orgId: Number(eaId),
        }),
      );
    }
  }, [eaId]);

  if (!currentEa) return null;

  const handleClick = (alias: IProps['activeItemAlias']) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    switch (alias) {
      case 'details':
        history.push(ROOTS.BELOVED_EQUITY_AUDIT_DETAILS.replace(':eaId', `${currentEa.id}`));
        break;
      case 'report':
        navToReport();
        break;
      case 'debrief':
        navToDebrief();
        break;
    }
  };

  const navToReport = () => {
    history.push(
      ROOTS.REPORTS.replace(':orgId', `${currentEa.organization_id}`).replace(':eaId', `${currentEa.id}`),
      { isBelovedApp: true },
    );
  };

  const navToDebrief = () => {
    if (isMobileOnly) {
      setNotMobileAlert(true);
    } else {
      history.push(
        ROOTS.VIEW_DEBRIEF.replace(':orgId', `${currentEa.organization_id}`).replace(
          ':eaId',
          `${currentEa.id}`,
        ),
        { belovedApp: true },
      );
    }
  };

  return (
    <>
      <nav className="b-tabs">
        <a
          href="#"
          className={`b-tabs__item ${activeItemAlias === 'details' ? 'b-tabs__item_active' : ''}`}
          onClick={handleClick('details')}
        >
          <div className={`b-tabs__icon b-tabs__icon_equility`} />
          <span className="b-tabs__text">{t('common:tabs.details')}</span>
        </a>
        {currentEa.has_report && hasAccessToEaReport && (
          <a
            href="#"
            className={`
                b-tabs__item
                ${activeItemAlias === 'report' ? 'b-tabs__item_active' : ''}
              `}
            onClick={handleClick('report')}
          >
            <div className="b-tabs__icon b-tabs__icon_ea_reports" />
            <span className="b-tabs__text">{t('common:tabs.ea-report')}</span>
          </a>
        )}
        {currentEa.has_debrief && hasAccessToViewDebrief && (
          <a
            href="#"
            className={`
                b-tabs__item
                ${activeItemAlias === 'debrief' ? 'b-tabs__item_active' : ''}
              `}
            onClick={handleClick('debrief')}
          >
            <div className="b-tabs__icon b-tabs__icon_ea_debrief " />
            <span className="b-tabs__text">{t('common:tabs.debrief')}</span>
          </a>
        )}
      </nav>
      {notMobileAlert && (
        <Alert
          isOpen={notMobileAlert}
          onRequestClose={() => setNotMobileAlert(false)}
          title={t('awa:M54.title')}
          text={t('awa:M54.msg')}
        />
      )}
    </>
  );
};

export default BelovedEaTabs;
