import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROOTS } from '../../../routes/routes';
import { IRouteParamsBase, OrganizationDetailsModel } from '../../../models';
import { useRole } from '../../../helpers/roles';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../store/reducers';
import { setDefaultOrgTab } from '../../../store/organization/organizationActions';
import { useTranslation } from 'react-i18next';

interface IProps {
  activeItemAlias:
    | 'org-profile'
    | 'org-users'
    | 'equity-audit'
    | 'elm'
    | 'beloved-user'
    | 'cohort'
    | 'EWP'
    | 'beloved-consultant';
}

const OrgProfileTabs: FC<IProps> = ({ activeItemAlias }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { orgId } = useParams<IRouteParamsBase>();
  const access = useRole();
  const dispatch = useDispatch();
  const currentOrg: OrganizationDetailsModel | null = useSelector(
    (state: StateType) => state.organization.currentOrg,
  );
  const user = useSelector((state: StateType) => state.profile.authUser);

  const showUserTab = access(['project_manager', 'project_admin']);
  const showEATab = access(['project_manager', 'project_admin', 'audit_contributor']);
  const showElmTab = access(['project_manager', 'project_admin', 'audit_contributor', 'employee_staff']);
  const showCohortTab = access(['project_manager']) || (currentOrg && currentOrg.cohort.is_visible_cohort);
  const showEwpTab = !user?.beloved_user && (access(['project_manager']) || currentOrg?.is_visible_ewp);
  const has_elm = currentOrg?.user_role.has_elm;

  useEffect(() => {
    if (showEATab !== null) {
      const list = document.getElementById('org-tabs');
      const el = document.getElementById('active-org-tab');

      if (list && el) {
        list.scrollLeft = el.offsetLeft - list.offsetWidth / 2 + el.offsetWidth / 2;
      }
    }
  }, [showEATab]);

  const handleClick = (alias: IProps['activeItemAlias']) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    switch (alias) {
      case 'org-profile':
        history.push(ROOTS.ORG_PROFILE.replace(':orgId', `${orgId}`));
        dispatch(setDefaultOrgTab({ orgId: Number(orgId), orgRoot: ROOTS.ORG_PROFILE }));
        break;
      case 'org-users':
        history.push(ROOTS.ORG_USERS.replace(':orgId', `${orgId}`));
        dispatch(setDefaultOrgTab({ orgId: Number(orgId), orgRoot: ROOTS.ORG_USERS }));
        break;
      case 'equity-audit':
        history.push(ROOTS.EQUITY_AUDIT_LIST.replace(':orgId', `${orgId}`));
        dispatch(setDefaultOrgTab({ orgId: Number(orgId), orgRoot: ROOTS.EQUITY_AUDIT_LIST }));
        break;
      case 'elm':
        history.push(ROOTS.ELM_LIST.replace(':orgId', `${orgId}`));
        dispatch(setDefaultOrgTab({ orgId: Number(orgId), orgRoot: ROOTS.ELM_LIST }));
        break;
      case 'cohort':
        history.push(ROOTS.COHORT_LIST.replace(':orgId', `${orgId}`));
        dispatch(setDefaultOrgTab({ orgId: Number(orgId), orgRoot: ROOTS.COHORT_LIST }));
        break;
      case 'beloved-user':
        history.push(ROOTS.ORG_BELOVED_USER_LIST.replace(':orgId', `${orgId}`));
        dispatch(setDefaultOrgTab({ orgId: Number(orgId), orgRoot: ROOTS.ORG_BELOVED_USER_LIST }));
        break;
      case 'EWP':
        history.push(ROOTS.EWP_DETAILS.replace(':orgId', `${orgId}`));
        break;
      case 'beloved-consultant':
        history.push(ROOTS.ORG_BELOVED_CONSULTANT.replace(':orgId', `${orgId}`));
        dispatch(setDefaultOrgTab({ orgId: Number(orgId), orgRoot: ROOTS.ORG_BELOVED_CONSULTANT }));
        break;
    }
  };

  return (
    <nav className="b-tabs" id="org-tabs">
      <a
        id={activeItemAlias === 'org-profile' ? 'active-org-tab' : ''}
        href="#"
        className={`b-tabs__item ${activeItemAlias === 'org-profile' ? 'b-tabs__item_active' : ''}`}
        onClick={handleClick('org-profile')}
      >
        <div className={`b-tabs__icon b-tabs__icon_organization`} />
        <span className="b-tabs__text">{t('common:tabs.profile')}</span>
      </a>
      {showUserTab && (
        <a
          id={activeItemAlias === 'org-users' ? 'active-org-tab' : ''}
          href="#"
          className={`b-tabs__item ${activeItemAlias === 'org-users' ? 'b-tabs__item_active' : ''}`}
          onClick={handleClick('org-users')}
        >
          <div className="b-tabs__icon b-tabs__icon_users" />
          <span aria-label={'Users'} className="b-tabs__text">
            {t('common:tabs.users')}
          </span>
        </a>
      )}
      {showEATab && (
        <a
          id={activeItemAlias === 'equity-audit' ? 'active-org-tab' : ''}
          href="#"
          className={`b-tabs__item ${activeItemAlias === 'equity-audit' ? 'b-tabs__item_active' : ''}`}
          onClick={handleClick('equity-audit')}
        >
          <div aria-label={'EA'} className="b-tabs__icon b-tabs__icon_equility" />
          <span aria-label={'EA'} className="b-tabs__text">
            {t('common:tabs.ea')}
          </span>
        </a>
      )}
      {(showElmTab || has_elm) && (
        <a
          id={activeItemAlias === 'elm' ? 'active-org-tab' : ''}
          href="#"
          className={`b-tabs__item ${activeItemAlias === 'elm' ? 'b-tabs__item_active' : ''}`}
          onClick={handleClick('elm')}
        >
          <div aria-label={'ELM'} className="b-tabs__icon b-tabs__icon_elm" />
          <span aria-label={'ELM'} className="b-tabs__text">
            {t('common:tabs.elm')}
          </span>
        </a>
      )}
      {showCohortTab && (
        <a
          id={activeItemAlias === 'cohort' ? 'active-org-tab' : ''}
          href="#"
          className={`b-tabs__item ${activeItemAlias === 'cohort' ? 'b-tabs__item_active' : ''}`}
          onClick={handleClick('cohort')}
        >
          <div aria-label={'Cohort'} className="b-tabs__icon b-tabs__icon_cohort" />
          <span aria-label={'Cohort'} className="b-tabs__text">
            {t('common:tabs.cohort')}
          </span>
        </a>
      )}
      {showEwpTab ? (
        <a
          id={activeItemAlias === 'EWP' ? 'active-org-tab' : ''}
          href="#"
          className={`b-tabs__item ${activeItemAlias === 'EWP' ? 'b-tabs__item_active' : ''}`}
          onClick={handleClick('EWP')}
        >
          <div aria-label={'EWP'} className="b-tabs__icon b-tabs__icon_ewp" />
          <span aria-label={'EWP'} className="b-tabs__text">
            {t('common:tabs.ewp')}
          </span>
        </a>
      ) : null}
      {currentOrg?.is_visible_beloved_team ? (
        <a
          id={activeItemAlias === 'beloved-user' ? 'active-org-tab' : ''}
          href="#"
          className={`b-tabs__item ${activeItemAlias === 'beloved-user' ? 'b-tabs__item_active' : ''}`}
          onClick={handleClick('beloved-user')}
        >
          <div aria-label={'Beloved Team'} className="b-tabs__icon b-tabs__icon_belovedTeam" />
          <span aria-label={'Beloved Team'} className="b-tabs__text">
            {t('common:column.beloved-team')}
          </span>
        </a>
      ) : null}
      {currentOrg?.is_visible_beloved_consultant ? (
        <a
          id={activeItemAlias === 'beloved-consultant' ? 'active-org-tab' : ''}
          href="#"
          className={`b-tabs__item ${activeItemAlias === 'beloved-consultant' ? 'b-tabs__item_active' : ''}`}
          onClick={handleClick('beloved-consultant')}
        >
          <div aria-label={'Beloved Team'} className="b-tabs__icon b-tabs__icon_belovedTeam" />
          <span aria-label={'Beloved Team'} className="b-tabs__text">
            {t('common:column.beloved-certified-consultants')}
          </span>
        </a>
      ) : null}
    </nav>
  );
};

export default OrgProfileTabs;
