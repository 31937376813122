import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { ISelectGroup } from '../../../models';
import styles from './SelectStyle.module.scss';
import { NoOptions } from './parts/NoOptions';

interface IInput {
  error?: string;
  placeholder?: string;
  type?: string;
  value: any;
  options: ISelectGroup[] | undefined;
  handleChange?: (value: any) => void;
  handleUnselect?: () => void;
  customClasses?: any;
  height?: string | number;
  name?: string;
  wrapperStyles?: CSSProperties;
  disabled?: boolean;
  // Additional in MenuList on top of the options
  topAdditional?: JSX.Element;
  // Additional in MenuList on bottom of the options
  bottomAdditional?: JSX.Element;
  isMulti?: boolean;
  autoFocus?: boolean;
  tabIndex?: number;
  ariaLabel?: string;
  isSearchable?: boolean;
  noOptionsMessage?: string;
  isClearable?: boolean;
}

const MenuList = (
  { children, ...rest }: any,
  topAdditional?: JSX.Element,
  bottomAdditional?: JSX.Element,
) => {
  return (
    <components.MenuList {...rest}>
      {topAdditional}
      {children}
      {bottomAdditional}
    </components.MenuList>
  );
};

const SelectBlock: FC<IInput> = props => {
  const {
    error,
    placeholder,
    handleChange,
    value,
    customClasses,
    height,
    name,
    wrapperStyles,
    disabled,
    topAdditional,
    bottomAdditional,
    isMulti,
    autoFocus,
    tabIndex,
    ariaLabel,
    isSearchable,
    noOptionsMessage,
    isClearable,
  } = props;

  let { options } = props;

  const ref = useRef<any>(null);
  const [isFocus, setFocus] = useState(false);

  useEffect(() => {
    if (autoFocus) {
      ref.current.focus();
    }
  }, []);

  const getBorderColor = (isFocused: boolean) => {
    if (error) {
      return 'red';
    }
    if (isFocused) {
      return 'var(--primary-60)';
    }
    return 'var(--gray-30)';
  };

  const getOptionBackground = (isFocused: boolean) => {
    if (isFocused) {
      return 'var(--primary-60)';
    }
    return 'transparent';
  };

  const customStyles = {
    control: (base: any, { isFocused }: any) => ({
      ...base,
      minHeight: height || '52px',
      color: 'var(--gray-60)',
      background: 'inherit',
      opacity: '1',
      borderColor: getBorderColor(isFocused),
      boxShadow: isFocused ? ' 0 0 0 1px var(--primary-60)' : 0,
    }),
    singleValue: (base: any) => ({
      ...base,
      color: 'var(--primary-navy)',
      fontFamily: 'Rubik',
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 9999,
      color: 'var(--primary-navy)',
      background: 'var(--background)',
    }),
    option: (base: any, { isFocused }: any) => ({
      ...base,
      backgroundColor: getOptionBackground(isFocused),
      color: isFocused ? 'var(--white-black)' : 'var(--primary-navy)',
    }),
    input: (base: any) => ({
      ...base,
      color: 'var(--gray-60)',
    }),
    placeholder: (base: any) => ({
      ...base,
      color: 'var(--gray-60)',
    }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor: 'var(--gray-10)',
      color: 'var(--gray-60)',
      '& div': {
        color: 'var(--gray-60)',
      },
    }),
  };

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  const renderGroupLabel = (item: any) => {
    if (handleChange && item.options && item.value) {
      const valuesIds = value.map((item: ISelectGroup) => item.value);
      const isSelected = valuesIds.includes(item.value);
      return (
        <div
          className={`${styles.groupLabelClickable} ${isSelected ? styles.groupLabelClickable_hidden : ''}`}
          onClick={() => handleChange([...value, item])}
        >
          {item.label}
        </div>
      );
    }
    return item.label;
  };

  return (
    <div style={wrapperStyles} className={styles.select_wrapper}>
      <div
        className={`${styles.input_placeholder_block} ${error ? styles.select_wrapper_error : ''} ${
          isFocus ? styles.labelFocus : ''
        }`}
        data-content={value ? placeholder : ''}
      >
        <Select
          formatGroupLabel={renderGroupLabel} // if we will need different behavior by clicking on the group title it can be pass from props
          closeMenuOnSelect={!isMulti}
          name={name}
          placeholder={placeholder}
          isSearchable={isSearchable}
          value={value}
          ref={ref}
          components={{
            IndicatorSeparator: () => null,
            MenuList: props => MenuList(props, topAdditional, bottomAdditional),
            NoOptionsMessage: props => NoOptions(props, noOptionsMessage, options?.length),
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          isDisabled={disabled}
          onChange={handleChange}
          options={options}
          styles={customStyles}
          isClearable={isClearable}
          isMulti={isMulti}
          isOptionDisabled={option => option.disabled}
          menuPlacement={'auto'}
          className={`${(customClasses && customClasses) || ''} ${error ? styles.select_error : ''}`}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: 'var(--primary-60)',
              primary25: 'var(--gray-10)',
              primary50: 'var(--primary-60)',
              neutral20: error ? 'red' : 'var(--gray-30)',
            },
          })}
          // @ts-ignore
          tabIndex={tabIndex}
          aria-label={ariaLabel || placeholder}
        />
        <div className={'error-notification'}>
          <span className={'error-notification-text'}>{error}</span>
        </div>
      </div>
    </div>
  );
};

export default SelectBlock;
