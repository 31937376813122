import { ChartAlias } from '../constants';
import { t } from 'i18next';

interface ScoreDataModel {
  label: string;
  color: string;
  alias: string;
}

export const getScoreData = (score: number | string): ScoreDataModel => {
  if (score <= 25) {
    return {
      label: t('awa:M61.msg.p3'),
      color: 'var(--gray-60)',
      alias: 'inquiring',
    };
  }
  if (score >= 25 && score <= 50) {
    return {
      label: t('awa:M61.msg.p5'),
      color: 'var(--primary-navy)',
      alias: 'emerging',
    };
  }
  if (score >= 51 && score <= 74) {
    return {
      label: t('awa:M61.msg.p6'),
      color: '#edd808',
      alias: 'developing',
    };
  }
  if (score >= 75 && score <= 90) {
    return {
      label: t('awa:M61.msg.p7'),
      color: 'var(--primary-60)',
      alias: 'expanding',
    };
  }
  if (score >= 91) {
    return {
      label: t('awa:M61.msg.p8'),
      color: 'var(--green-60)',
      alias: 'refining',
    };
  }
  if (score === 'N/A') {
    return {
      label: t('common:column.n-a'),
      color: 'var(--gray-60)',
      alias: 'inquiring',
    };
  }
  return {
    label: '',
    color: '',
    alias: '',
  };
};

export const getChartLineColor = (alias: ChartAlias): string => {
  if (alias === ChartAlias.diversity) {
    return 'rgb(255, 183, 27)';
  } else if (alias === ChartAlias.sub_diversity) {
    return 'rgba(255, 183, 27, 0.5)';
  } else if (alias === ChartAlias.equity) {
    return 'rgb(234, 0, 41)';
  } else if (alias === ChartAlias.sub_equity) {
    return 'rgba(234, 0, 41, 0.5)';
  } else if (alias === ChartAlias.inclusion) {
    return 'rgb(255, 144, 21)';
  } else if (alias === ChartAlias.sub_inclusion) {
    return 'rgba(255, 144, 21, 0.5)';
  } else if (alias === ChartAlias.f_group) {
    return 'rgb(255, 183, 27)';
  }
  return '#666';
};

export const getChartLabelColor = (alias: ChartAlias, darkMode?: boolean): string => {
  if (alias === ChartAlias.diversity) {
    return 'rgb(255, 183, 27)';
  } else if (alias === ChartAlias.equity) {
    return 'rgb(234, 0, 41)';
  } else if (alias === ChartAlias.inclusion) {
    return 'rgb(255, 144, 21)';
  }
  return darkMode ? '#ccc' : '#666';
};
