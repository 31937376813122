import {
  ActionModel,
  AdminToolRoleModel,
  AdminToolPermissionModel,
  AdminToolUserModel,
  PaginationModel,
  ISelectOptions,
  AdminToolUpdateUserModel,
  BelCreatePmFormModel,
  AdminToolOrgUserModel,
} from '../../../models';
import { actionCreator } from '../../actionCreator';
import { LOGOUT_SUCCESS } from '../../authData/authDataActions';
import { BelAdminToolState } from './adminToolReducer';
import { QueryParamsModel, ReqParamsModel } from '../../../hooks/common/use-table-data';

//-----BEL_AT_GET_ROLES----//
export const BEL_AT_GET_ROLES = 'beloved/BEL_AT_GET_ROLES';
export const BEL_AT_GET_ROLES_SUCCESS = 'beloved/BEL_AT_GET_ROLES_SUCCESS';
export interface BelAtGetRolesSuccessPayload {
  roles: AdminToolRoleModel[];
  permissions: AdminToolPermissionModel[];
}
export const belAtGetRoles = actionCreator(BEL_AT_GET_ROLES);
export const belAtGetRolesSuccess = actionCreator<any>(BEL_AT_GET_ROLES_SUCCESS);

//-----BEL_AT_CREATE_ROLE----//
export const BEL_AT_CREATE_ROLE = 'beloved/BEL_AT_CREATE_ROLE';
export const BEL_AT_CREATE_ROLE_SUCCESS = 'beloved/BEL_AT_CREATE_ROLE_SUCCESS';
export interface BelAtCreateRolePayload {
  name: string;
  callback?: () => void;
}
export const belAtCreateRole = actionCreator<BelAtCreateRolePayload>(BEL_AT_CREATE_ROLE);
export const belAtCreateRoleSuccess = actionCreator<AdminToolRoleModel>(BEL_AT_CREATE_ROLE_SUCCESS);

//-----BEL_AT_SET_CURRENT_ROLE----//
export const BEL_AT_SET_CURRENT_ROLE = 'beloved/BEL_AT_SET_CURRENT_ROLE';
export const belAtSetCurrentRole = actionCreator<AdminToolRoleModel>(BEL_AT_SET_CURRENT_ROLE);

//-----BEL_AT_UPDATE_ROLE----//
export const BEL_AT_UPDATE_ROLE = 'beloved/BEL_AT_UPDATE_ROLE';
export const BEL_AT_UPDATE_ROLE_SUCCESS = 'beloved/BEL_AT_UPDATE_ROLE_SUCCESS';
export interface BelAtUpdateRolePayload {
  roleId: number;
  data: {
    permissions?: number[];
    label?: string;
  };
  callback?: () => void;
}
export const belAtUpdateRole = actionCreator<BelAtUpdateRolePayload>(BEL_AT_UPDATE_ROLE);
export const belAtUpdateRoleSuccess = actionCreator<AdminToolRoleModel>(BEL_AT_UPDATE_ROLE_SUCCESS);

//-----BEL_AT_GET_USERS----//
export const BEL_AT_GET_USERS = 'beloved/BEL_AT_GET_USERS';
export const BEL_AT_GET_USERS_SUCCESS = 'beloved/BEL_AT_GET_USERS_SUCCESS';
export interface BelAtGetUserPayload {
  params: ReqParamsModel;
  callback?: () => void;
}
export const belAtGetUsers = actionCreator<BelAtGetUserPayload>(BEL_AT_GET_USERS);
export const belAtGetUsersSuccess =
  actionCreator<PaginationModel<AdminToolUserModel[]>>(BEL_AT_GET_USERS_SUCCESS);

//-----BEL_AT_GET_ORG_USERS----//
export const BEL_AT_GET_ORG_USERS = 'beloved/BEL_AT_GET_ORG_USERS';
export const BEL_AT_GET_ORG_USERS_SUCCESS = 'beloved/BEL_AT_GET_ORG_USERS_SUCCESS';
export interface BelAtGetOrgUserPayload {
  params: ReqParamsModel;
  callback?: () => void;
}
export const belAtGetOrgUsers = actionCreator<BelAtGetUserPayload>(BEL_AT_GET_ORG_USERS);
export const belAtGetOrgUsersSuccess = actionCreator<PaginationModel<AdminToolOrgUserModel[]>>(
  BEL_AT_GET_ORG_USERS_SUCCESS,
);

//-----BEL_AT_GET_AVAILABLE_ROLES----//
export const BEL_AT_GET_AVAILABLE_ROLES = 'beloved/BEL_AT_GET_AVAILABLE_ROLES';
export interface BelAtGetAvailableRolesPayload {
  callback: (roles: ISelectOptions[]) => void;
}
export const belAtGetAvailableRoles =
  actionCreator<BelAtGetAvailableRolesPayload>(BEL_AT_GET_AVAILABLE_ROLES);

//-----BEL_AT_CREATE_USER----//
export const BEL_AT_CREATE_USER = 'beloved/BEL_AT_CREATE_USER';
export interface BelAtCreateUserPayload {
  data: AdminToolUpdateUserModel;
  callback: (error?: string) => void;
}
export const belAtCreateUser = actionCreator<BelAtCreateUserPayload>(BEL_AT_CREATE_USER);

//-----BEL_AT_UPDATE_USER----//
export const BEL_AT_UPDATE_USER = 'beloved/BEL_AT_UPDATE_USER';
export interface BelAtUpdateUserPayload {
  data: AdminToolUpdateUserModel;
  userId: number;
  callback: () => void;
}
export const belAtUpdateUser = actionCreator<BelAtUpdateUserPayload>(BEL_AT_UPDATE_USER);

//-----BEL_AT_DELETE_USERS----//
export const BEL_AT_DELETE_USERS = 'beloved/BEL_AT_DELETE_USERS';
export interface BelAtDeleteUsersPayload {
  usersIds: number[];
  isOrgUser?: boolean;
  callback: () => void;
}
export const belAtDeleteUser = actionCreator<BelAtDeleteUsersPayload>(BEL_AT_DELETE_USERS);

//-----BEL_AT_DEACTIVATE_USERS----//
export const BEL_AT_DEACTIVATE_USERS = 'beloved/BEL_AT_DEACTIVATE_USERS';
export interface BelAtDeactivateUsersPayload {
  usersIds: number[];
  is_active: boolean;
  isOrgUser?: boolean;
  callback: () => void;
}
export const belAtDeactivateUser = actionCreator<BelAtDeactivateUsersPayload>(BEL_AT_DEACTIVATE_USERS);

//-----SET_QUERY---//
export const SET_USERS_QUERY = 'beloved-admin-tool/SET_USERS_QUERY';
export const setUsersQuery = actionCreator<QueryParamsModel>(SET_USERS_QUERY);

//-----SET_QUERY---//
export const SET_ORG_USERS_QUERY = 'beloved-admin-tool/SET_ORG_USERS_QUERY';
export const setOrgUsersQuery = actionCreator<QueryParamsModel>(SET_ORG_USERS_QUERY);

//-----BEL_CREATE_PM----//
export const BEL_CREATE_PM = 'beloved/BEL_CREATE_PM';
export interface BelCreatePmPayload {
  data: BelCreatePmFormModel;
  orgId: number;
  callback: () => void;
}
export const belCreatePm = actionCreator<BelCreatePmPayload>(BEL_CREATE_PM);

//-----BEL_CHANGE_PM_ACTIVITY----//
export const BEL_CHANGE_PM_ACTIVITY = 'beloved/BEL_CHANGE_PM_ACTIVITY';
export interface BelChangePmActivity {
  data: { id: number; is_active: boolean }[];
  orgId: number;
  callback: () => void;
}
export const belChangePmActivity = actionCreator<BelChangePmActivity>(BEL_CHANGE_PM_ACTIVITY);

export type BelAdminToolActionsTypes =
  | ActionModel<typeof BEL_AT_GET_ROLES_SUCCESS, BelAtGetRolesSuccessPayload>
  | ActionModel<typeof BEL_AT_CREATE_ROLE_SUCCESS, AdminToolRoleModel>
  | ActionModel<typeof BEL_AT_SET_CURRENT_ROLE, AdminToolRoleModel>
  | ActionModel<typeof BEL_AT_UPDATE_ROLE_SUCCESS, AdminToolRoleModel>
  | ActionModel<typeof BEL_AT_GET_USERS_SUCCESS, PaginationModel<AdminToolUserModel[]>>
  | ActionModel<typeof BEL_AT_GET_ORG_USERS_SUCCESS, PaginationModel<AdminToolOrgUserModel[]>>
  | ActionModel<typeof SET_USERS_QUERY, BelAdminToolState['usersQuery']>
  | ActionModel<typeof SET_ORG_USERS_QUERY, BelAdminToolState['orgUsersQuery']>
  | ActionModel<typeof BEL_AT_DEACTIVATE_USERS, BelAtDeactivateUsersPayload>
  | ActionModel<typeof BEL_CREATE_PM, BelCreatePmPayload>
  | ActionModel<typeof BEL_CHANGE_PM_ACTIVITY, BelChangePmActivity>
  | ActionModel<typeof LOGOUT_SUCCESS>;
