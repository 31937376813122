import React, { useState } from 'react';
import styles from '../CreateEA.module.scss';
import Ill from '../../../../../../assets/images/group@2x.png';
import { AddToCalendar, Alert } from '../../../../../common';
import { Button, Loader } from '../../../../../controls';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../../store/reducers';
import { useHistory, useParams } from 'react-router';
import { ROOTS } from '../../../../../../routes/routes';
import { TeamType, IRouteParamsBase } from '../../../../../../models';
import { sendNotification } from '../../../../../../store/equityAudit/equityAuditActions';
import { basicDateFormat } from '../../../../../../helpers';
import { useTranslation } from 'react-i18next';

interface IState {
  alertModalType: TeamType | null;
  eaSent: boolean;
  managerSent: boolean;
}

const CreateEAFinish = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const createEAData = useSelector((state: StateType) => state.equityAudit.createEAData);
  const history: any = useHistory();
  const { orgId } = useParams<IRouteParamsBase>();

  const initialState: IState = {
    alertModalType: null,
    eaSent: false,
    managerSent: false,
  };

  let [state, setState] = useState<IState>(initialState);

  const handleNext = () => {
    history.push(ROOTS.EQUITY_AUDIT_LIST.replace(':orgId', `${orgId}`));
  };

  const handleSendNotification = () => {
    if (createEAData && state.alertModalType) {
      dispatch(
        sendNotification({
          target: state.alertModalType,
          ea_id: createEAData.id,
          callback: target => {
            if (target === 'team') {
              setState({ ...state, alertModalType: null, eaSent: true });
            } else {
              setState({ ...state, alertModalType: null, managerSent: true });
            }
          },
        }),
      );
    }
  };

  const handlePressSend = (type: TeamType) => (e: any) => {
    e.preventDefault();
    setState({ ...state, alertModalType: type });
  };

  const handleCloseAlert = () => {
    setState({ ...state, alertModalType: null });
  };

  const renderSendStatus = (type: TeamType) => {
    if (type === 'team') {
      if (createEAData?.audit_equity_team.length) {
        if (state.eaSent) {
          return (
            <>
              {t('main:sent-status.sent-on')} <b>{basicDateFormat(new Date())}</b>
            </>
          );
        } else {
          return <>{t('main:sent-status.not-sent')}</>;
        }
      } else {
        return <>{t('main:sent-status.no-users')}</>;
      }
    } else if (type === 'managers') {
      if (createEAData?.audit_managers.length) {
        if (state.managerSent) {
          return (
            <>
              {t('main:sent-status.sent-on')} <b>{basicDateFormat(new Date())}</b>
            </>
          );
        } else {
          return <>{t('main:sent-status.not-sent')}</>;
        }
      } else {
        return <>{t('main:sent-status.no-users')}</>;
      }
    }
  };

  if (!createEAData) return <Loader fitParent />;

  return (
    <>
      <div className={styles.finishStep__container}>
        <img src={Ill} className={styles.finishStep__ill} alt={t('awa:AT3')} />
        <h1 className={styles.finishStep__title}>{t('awa:M77.title')}</h1>
        <p className={styles.finishStep__text}>{t('awa:M77.msg')}</p>
        <div className={styles.finishStep__info}>
          <div className="b-equityAudit__text16">{t('common:headlines.organization')}</div>
          <div className={'b-step__orgText'}>{createEAData.organization_name}</div>
        </div>
        <div className={styles.finishStep__info}>
          <div className="b-equityAudit__text16">{t('common:headlines.scheduled-date')}</div>
          <div className={'b-step__orgText'}>{basicDateFormat(createEAData.scheduled_date || '')}</div>
          <div style={{ marginTop: 20 }}>
            {createEAData.scheduled_date && (
              <AddToCalendar type={'ea'} startDate={createEAData.scheduled_date} />
            )}
          </div>
        </div>
        <div className={styles.finishStep__notifBox}>
          <div className={styles.finishStep__notifTitle}>{t('awa:M78.title')}</div>
          <div className={styles.finishStep__notifLine}>
            <div className={styles.finishStep__notifKey}>{t('awa:M40.title')}</div>
            <div className={styles.finishStep__notifStatus}>{renderSendStatus('team')}</div>
            <button
              disabled={!createEAData.audit_equity_team.length}
              onClick={handlePressSend('team')}
              className="b-EADetails__btn b-EADetails__btn_send -congrat_page"
            >
              <span className="sr-only">{t('common:btn.send')}</span>
            </button>
          </div>
          <div className={styles.finishStep__notifLine}>
            <div className={styles.finishStep__notifKey}>{t('common:headlines.managers')}</div>
            <div className={styles.finishStep__notifStatus}>{renderSendStatus('managers')}</div>
            <button
              disabled={!createEAData.audit_managers.length}
              onClick={handlePressSend('managers')}
              className="b-EADetails__btn b-EADetails__btn_send -congrat_page"
            >
              <span className="sr-only">{t('common:btn.send')}</span>
            </button>
          </div>
        </div>
        <Button
          className={`btn  ${styles.finishStep__nextBtn}`}
          type={'orange-light'}
          onPress={handleNext}
          title={t('common:btn.next')}
          enableEnter
        />
      </div>
      {state.alertModalType && (
        <Alert
          text={`${t('awa:M78.msg.p1')} ${
            state.alertModalType === 'managers' ? t('awa:M78.msg.p2') : t('awa:M78.msg.p3')
          }`}
          buttons={{
            left: {
              type: 'white',
              onClick: handleCloseAlert,
              title: t('common:btn.cancel'),
            },
            right: {
              type: 'orange-light',
              onClick: handleSendNotification,
              title: t('common:btn.send'),
            },
          }}
          isOpen={!!state.alertModalType}
          onRequestClose={handleCloseAlert}
        />
      )}
    </>
  );
};

export default CreateEAFinish;
