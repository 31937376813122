import { ISelectOptions } from '../global';
import { ScheduleAlias } from '../../constants';

export enum EAStatuses {
  IN_PREPARATION = 0,
  PREP_COMPLETED = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
}

export interface EAUser {
  id: number;
  name: string;
  role_id: number;
  role_name: string;
  status: string | null;
  status_date: string | null;
}

export interface EAOptions {
  employees_number: ISelectOptions[];
  org_age: ISelectOptions[];
  geographic_scope: ISelectOptions[];
  geographic_area: ISelectOptions[];
  sector_type: ISelectOptions[];
  primary_type: ISelectOptions[];
}

export type TeamType =
  | 'team'
  | 'managers'
  | 'team_empty'
  | 'managers_empty'
  | 'consultants'
  | 'consultants_empty';

export type InstructionStep =
  | 'intro1'
  | 'intro2'
  | 'step1'
  | 'step2'
  | 'step3'
  | 'step3_5'
  | 'step4'
  | 'step5';

export type CreateEAStep =
  | '0'
  | 'set_ea_team'
  | 'set_date'
  | '2'
  | 'set_org_details'
  | 'set_geo'
  | 'set_sector'
  | 'finish';

export interface DebriefScheduledDateModel {
  book_date: string;
  book_time: string;
  book_timezone: string;
  book_full_date: string;
  timezone_offset: string;
  alias?: ScheduleAlias;
}

export interface EquityAuditModel {
  id: number;
  audit_equity_team: EAUser[];
  audit_managers: EAUser[];
  audit_pm: EAUser;
  ea_type: string;
  ea_status_id: EAStatuses;
  ea_status_name: string;
  ea_step: CreateEAStep;
  organization_id: string;
  organization_name: string;
  last_update: string | null;
  completed_date: string | null;
  scheduled_date: string | null;
  created_at: string | null;
  employees_number: number | null;
  org_age: number | null;
  geographic_scope: number[];
  geographic_area: number[];
  sector_type: number | null;
  primary_type: number | null;
  ea_user_type?: {
    id: 0 | 1 | 2;
    name: string;
  };
  debrief_scheduled_date: DebriefScheduledDateModel;
  debrief: boolean;
  show_view_debrief: boolean;
  show_purchase_debrief: boolean;
  has_recommended_resources: boolean;
}

export interface EAUpdateData {
  ea_step?: CreateEAStep;
  organization_id?: number;
  audit_managers_ids?: number[];
  audit_equity_team_ids?: number[];
  scheduled_date?: Date;
  employees_number?: number;
  org_age?: number;
  geographic_scope?: number[];
  geographic_area?: number[];
  sector_type?: number;
  primary_type?: number;
}

export interface FunctionGroup {
  name: string;
  alias: string;
  id: string;
  is_skipped: boolean;
  description: {
    text: string;
    list: string[];
    more_text?: string;
    more_list?: string[];
  };
}

export interface FunctionGroupQ extends FunctionGroup {
  questions: Question[];
}

export interface QuestionOption {
  id: string;
  text: string;
}

export interface Question {
  id: number;
  position: number;
  question_text: string;
  explanation: string;
  options: QuestionOption[];
  answer: string | null;
  function_group_id: string;
  function_group_name: string;
  standard_id: number;
  standard_name: string;
  sub_standard_id: number;
  sub_standard_name: string;
}

export interface EATaking {
  organization_name: string;
  org_type: number;
  org_type_name: string;
  current_function_group_id: string | null;
  current_question_id: number | null;
  function_groups: FunctionGroup[];
  questions: Question[];
  first_submit_ea: boolean;
}
