import React, { ReactNode, useEffect, useState } from 'react';
import { HeadlineOrganization, OrgBreadcrumbs } from '../../../common';
import { useHistory, useParams } from 'react-router-dom';
import { GroupAnalysisModel, GroupAnalysisTreeModel, IRouteParamsBase } from '../../../../models';
import { useSelector } from 'react-redux';
import { ROOTS } from '../../../../routes/routes';
import { EmptyLink, Link } from '../../../controls';
import { StateType } from '../../../../store/reducers';
import { isMobileOnly } from 'react-device-detect';
import { isEmptyObject } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import { useNavBack } from '../../../../hooks/common/use-nav-back';
import LineChart from '../../../common/LineChart/LineChart';

const GroupAnalysis = () => {
  const { t } = useTranslation();
  const { orgId } = useParams<IRouteParamsBase>();
  const history = useHistory();
  const { navBack } = useNavBack();
  const [orgTree, setOrgTree] = useState<GroupAnalysisTreeModel[]>();
  const [orgScores, setOrgScores] = useState<GroupAnalysisTreeModel[]>([]);
  const currentOrg = useSelector((state: StateType) => state.organization.currentOrg);
  const gaData: GroupAnalysisModel | null = useSelector((state: StateType) => state.report.gaData);

  const deepOrgs = (orgs: GroupAnalysisTreeModel[]): GroupAnalysisTreeModel[] => {
    const orgsArray: GroupAnalysisTreeModel[] = [];
    const func = (temp: GroupAnalysisTreeModel[]) => {
      if (!temp) {
        return;
      }
      temp.map(org => {
        orgsArray.push(org);
        if (org.sub_orgs.length) {
          func(org.sub_orgs as any);
        }
      });
    };
    func(orgs);
    return orgsArray;
  };

  const filterOrgs = (orgs: GroupAnalysisTreeModel[], orgId: number) => {
    const result: GroupAnalysisTreeModel[] = [];
    let isReturn = false;
    const f = (orgs: GroupAnalysisTreeModel[]) => {
      if (isReturn) return;
      orgs.forEach(o => {
        if (isReturn) return;
        if (o.org_id === orgId) {
          result.push(o);
          isReturn = true;
        }
        if (o.sub_orgs.length) {
          f(o.sub_orgs);
        }
      });
    };
    f(orgs);
    return result;
  };

  useEffect(() => {
    if (isMobileOnly || gaData.ea_report_counter <= 1) {
      history.push(ROOTS.ORG_PROFILE.replace(':orgId', `${orgId}`));
      return;
    }
  }, []);

  useEffect(() => {
    if (isEmptyObject(gaData)) {
      return;
    }

    const orgs_tree = Number(orgId) ? filterOrgs(gaData.orgs_tree, Number(orgId)) : gaData.orgs_tree;
    const scores = deepOrgs(orgs_tree);

    setOrgScores(scores);
    gaData.orgs_tree.map(org => {
      if (org.standards_score_report) {
        org.standards_score_report = Object.values(org.standards_score_report);
      }
      return org;
    });
    setOrgTree(orgs_tree);
  }, [gaData, orgId]);

  if (!orgTree) {
    return null;
  }

  const renderTree = (orgs: GroupAnalysisTreeModel[], padding: number): ReactNode => {
    return orgs.map((org, i) => {
      if (!org.standards_score_report && !org.sub_orgs.length) {
        return null;
      }
      if (!org.standards_score_report && org.sub_orgs.length) {
        return renderTree(org.sub_orgs, padding);
      }
      return (
        <li
          key={`org-tree-${org.org_id}-${i}-${padding}`}
          className={padding > 0 ? `b-customChart__labels__children` : ''}
        >
          <div className="elem" style={{ paddingLeft: padding }}>
            <span>{org.org_name}</span>
          </div>
          {org.sub_orgs.length ? renderTree(org.sub_orgs, padding + 20) : null}
        </li>
      );
    });
  };

  const renderChart = () => {
    if (!orgTree) {
      return null;
    }
    return (
      <div className={'b-customChart'}>
        <div className={'b-customChart__chart'}>
          <div className={'b-customChart__labels'}>
            <ul>{renderTree(orgTree, 0)}</ul>
          </div>
          <LineChart
            data={orgScores.map(item => ({
              id: item.org_id,
              standards_score_report: item.standards_score_report,
            }))}
          />
        </div>
        <div className={'b-customChart__legend'}>
          <div className={'b-customChart__legendTitle'}>
            <span className={`b-customChart__legendDot Diversity`} />
            <span>{t('awa:M35.msg.p9')}</span>
          </div>
          <div className={'b-customChart__legendTitle'}>
            <span className={`b-customChart__legendDot Equity`} />
            <span>{t('awa:M35.msg.p14')}</span>
          </div>
          <div className={'b-customChart__legendTitle'}>
            <span className={`b-customChart__legendDot Inclusion`} />
            <span>{t('awa:M35.msg.p20')}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    if (orgId === 'my-organizations') {
      return (
        <div>
          <h1 className={'b-page__title'}>{t('common:tabs.my-organizations')}</h1>
          <nav className="b-tabs">
            <a href="#" className="b-tabs__item b-tabs__item_active">
              <div aria-label={'Group Analysis'} className="b-tabs__icon b-tabs__icon_ga" />
              <span aria-label={'Group Analysis'} className="b-tabs__text">
                {t('common:tabs.group-analysis')}
              </span>
            </a>
          </nav>
          {renderChart()}
        </div>
      );
    }
    return (
      <div>
        <HeadlineOrganization
          orgName={currentOrg?.name || ''}
          orgId={currentOrg?.id || ''}
          headline={t('main:ea.ea')}
        />
        <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
        <h2 className="b-page__title2 font-navy -backIcon">
          <EmptyLink onClick={navBack} className="b-button-icon-back" />
          {t('common:tabs.group-analysis')}
        </h2>
        <div className="b-report__holder">{renderChart()}</div>
      </div>
    );
  };

  return (
    <>
      <main className="b-page">{renderHeader()}</main>
    </>
  );
};

export default GroupAnalysis;
