import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { Button } from '../../../../controls';
import { Modal } from '../../../../common';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { elmStatusList } from '../../../../../constants/elm';
import { useTableDataV2 } from '../../../../../hooks/common/use-table-data-v2';
import { BelElmModel, TableDataEntities } from '../../../../../models';

interface IProps {
  onClose: () => void;
}

const FilterModal: FC<IProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { organizationsTypes } = useSelector((state: StateType) => state.utils.data);
  const { filterData, handleSelectFilter, handleApplyFilter, isFiltersChanged } = useTableDataV2<BelElmModel>(
    {
      baseUrl: 'beloved/elm/',
      entityName: TableDataEntities.ELM,
    },
  );

  const handleSaveFilter = () => {
    handleApplyFilter();
    onClose();
  };

  return (
    <>
      <Modal
        width={450}
        className={!isMobile ? 'b-filtersModal__mobileModal' : ''}
        title={t('common:btn.filter')}
        onClose={onClose}
        classNameTitle="b-filtersModal__modalTitle"
      >
        <div className="b-filtersModal">
          <div className="b-filtersModal__list w-100 mr-0">
            <div className="b-filtersModal__title">{t('common:filter.by')}</div>
            <ul>
              <li>
                <span>{t('common:column.org-type')}:</span>
              </li>
              {organizationsTypes ? (
                <li>
                  <ul>
                    {organizationsTypes.map(item => {
                      const isActive = filterData.type?.includes(item.value);
                      return (
                        <li key={`filter-org-type-${item.value}`}>
                          <a
                            href="#"
                            className={isActive ? '-active' : ''}
                            onClick={e => {
                              e.preventDefault();
                              handleSelectFilter(item.value, 'type');
                            }}
                          >
                            {item.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ) : null}
              <li>
                <span>{t('common:column.status')}:</span>
              </li>
              <li>
                <ul>
                  {Object.values(elmStatusList()).map(item => {
                    const isActive = filterData.status?.includes(item.id);
                    return (
                      <li key={`filter-status-${item.id}`}>
                        <a
                          href="#"
                          className={isActive ? '-active' : ''}
                          onClick={e => {
                            e.preventDefault();
                            handleSelectFilter(item.id, 'status');
                          }}
                        >
                          {item.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="b-filtersModal__buttons">
          <Button
            disabled={!isFiltersChanged}
            onPress={handleSaveFilter}
            title={t('common:btn.filter')}
            size={'large'}
            type={'orange-light'}
          />
        </div>
      </Modal>
    </>
  );
};

export default FilterModal;
