import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
//@ts-ignore
import html2pdf from 'html2pdf.js';
import { viewDebrief, changeCurrentDebriefSlide } from '../../../../store/equityAudit/equityAuditActions';
import { ChartReportModel, IRouteParamsBase, DebriefModel, DebriefSlideModel } from '../../../../models';
import { useParams, useLocation } from 'react-router';
import { HeadlineOrganization, OrgBreadcrumbs } from '../../../common';
import { StateType } from '../../../../store/reducers';
import { Button, Loader } from '../../../controls';
import { DebriefSlideContentTypes, DebriefSlideTypes, getSlides } from '../../../../constants/debrief';
import Slide1 from './parts/Slide1';
import Slide2 from './parts/Slide2';
import ChartSlide from './parts/ChartSlide';
import { basicDateFormat, isEmptyObject, splitDebriefChart } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import useStateCallback from '../../../../hooks/common/use-state-callback';

interface ILocationState {
  belovedApp: boolean;
}

const ViewDebrief = () => {
  const { t } = useTranslation();
  const { eaId, orgId } = useParams<IRouteParamsBase>();
  const dispatch = useDispatch();
  const currentDebriefSlide = useSelector((state: StateType) => state.equityAudit.currentDebriefSlide);
  const location = useLocation<ILocationState | undefined>();

  let [data, setData] = useState<DebriefModel | null>(null);
  let [navs, setNavs] = useState<any>({});
  let [isFullScreen, setIsFullScreen] = useState(false);
  let [currentSliderIndex, setCurrentSliderIndex] = useState(0);
  let [isLoading, setIsLoading] = useState(true);
  let [pdfLoading, setPdfLoading] = useStateCallback(false);

  const mainSlider = useRef<Slider>(null);
  const pagSlider = useRef(null);

  const slides = getSlides();

  useEffect(() => {
    dispatch(
      viewDebrief({
        ea_id: Number(eaId),
        callback: data => {
          setData(data);
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (data && isEmptyObject(navs)) {
      setNavs({
        nav1: mainSlider.current,
        nav2: pagSlider.current,
      });
      const element = document.getElementById('main-slider');

      if (element) {
        element.addEventListener('fullscreenchange', () => {
          if (document.fullscreenElement) {
            setIsFullScreen(true);
          } else {
            setIsFullScreen(false);
          }
        });
        document.addEventListener('keydown', event => {
          if (event.code === 'ArrowLeft') {
            handlePrevPag();
          }
          if (event.code === 'ArrowRight') {
            handleNextPag();
          }
        });
      }
      if (mainSlider.current && currentDebriefSlide && currentDebriefSlide.debriefId === data.id) {
        mainSlider.current.slickGoTo(currentDebriefSlide.index);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 1100);
    }
  }, [data]);

  const handleChangeSlider = (index: number) => {
    if (!data) return;
    setCurrentSliderIndex(index);
    dispatch(changeCurrentDebriefSlide({ index, debriefId: data?.id }));
  };

  const handlePrevPag = () => {
    //@ts-ignore
    pagSlider?.current?.slickPrev();
  };

  const handleNextPag = () => {
    //@ts-ignore
    pagSlider?.current?.slickNext();
  };

  const handleFullScreen = () => {
    const mainSlider = document.getElementById('main-slider');
    if (mainSlider) {
      mainSlider.requestFullscreen().then(() => {});
    }
  };

  const handleDownload = () => {
    if (!data) return null;
    const opt = {
      filename: `${data.organizations.org} - ${t('common:headlines.debrief')} - ${t(
        'common:headlines.ea',
      )} - ${basicDateFormat(data.completed_date)}.pdf`,
      html2canvas: { scale: 1.3 },
      jsPDF: { unit: 'px', format: [1340, 782], orientation: 'landscape' },
    };
    setPdfLoading(true, () => {
      const el = document.getElementById('download-to-pdf-el');
      html2pdf(el, opt).then(() => {
        setPdfLoading(false);
      });
    });
  };

  const goBack = (e: any) => {
    e.stopPropagation();
    history.go(-1);
  };

  const _getSlides = (slides: DebriefSlideModel[], data: DebriefModel) => {
    const { standards_sub_standards_chart, function_group_chart } = data.reports;

    const { chart1Data, chart2Data, chart3Data } = splitDebriefChart(standards_sub_standards_chart);

    return slides.map(item => {
      if (item.type === DebriefSlideTypes.content) {
        if (item.contentType === DebriefSlideContentTypes.chart_1) {
          return {
            ...item,
            html: (
              <ChartSlide
                is_legacy_audit={data.is_legacy_audit}
                chart={chart1Data as ChartReportModel}
                type={'11'}
              />
            ),
          };
        }
        if (item.contentType === DebriefSlideContentTypes.chart_2) {
          return {
            ...item,
            html: (
              <ChartSlide
                is_legacy_audit={data.is_legacy_audit}
                chart={chart2Data as ChartReportModel}
                type={'14'}
              />
            ),
          };
        }
        if (item.contentType === DebriefSlideContentTypes.chart_3) {
          return {
            ...item,
            html: (
              <ChartSlide
                is_legacy_audit={data.is_legacy_audit}
                chart={chart3Data as ChartReportModel}
                type={'17'}
              />
            ),
          };
        }
        if (item.contentType === DebriefSlideContentTypes.chart_4) {
          return {
            ...item,
            html: (
              <ChartSlide
                is_legacy_audit={data.is_legacy_audit}
                chart={function_group_chart as ChartReportModel}
                type={'18'}
              />
            ),
          };
        }
        if (item.contentType === DebriefSlideContentTypes.slide_1) {
          return {
            ...item,
            html: <Slide1 data={data} />,
          };
        }
        if (item.contentType === DebriefSlideContentTypes.slide_2) {
          return {
            ...item,
            html: <Slide2 data={data} isFullScreen={isFullScreen} />,
          };
        }
      }
      return item;
    });
  };

  if (!data) return <Loader />;

  const renderPageNumber = (index: number, dist?: number) => {
    if (index === 0) return;

    return (
      <div className="position-absolute font-navy font-m" style={{ bottom: dist || 22, right: dist || 22 }}>
        {index + 1}
      </div>
    );
  };

  if (window.innerWidth <= 768) {
    return (
      <main className={'pageContainer pageContainer__content'}>
        <div className="pageContainer__title b-viewDebrief__pageTitle">
          <span>{t('awa:M54.title')}</span>
        </div>
        <div>{t('awa:M54.msg')}</div>
      </main>
    );
  }

  return (
    <>
      <main className="pageContainer pageContainer__content">
        {isLoading && (
          <div className="b-viewDebrief__loadCover">
            <Loader fitParent />
          </div>
        )}
        {pdfLoading && <Loader fitParent />}
        <div className="pageContainer__title b-viewDebrief__pageTitle -desktop">
          <HeadlineOrganization
            orgName={data.organizations.org}
            orgId={orgId}
            headline={t('common:headlines.debrief')}
            customClasses={'mb-0'}
          />
          <Button
            onPress={handleDownload}
            title={t('common:label.download-pdf')}
            iconLeft={<div className="b-button-icon download mr-2 -colorWhite" />}
          />
        </div>
        <div className="pageContainer__title b-equityAudit__pageTitle -mobile">
          {t('common:headlines.debrief')}
        </div>
        {!location.state?.belovedApp && <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />}
        <div className="b-viewDebrief__subTitle">
          <a className="b-viewDebrief__backBtn" onClick={goBack}>
            <div className="back-icon" />
          </a>
          {t('common:headlines.ea')}:{' '}
          <span className="orange-light-text">{basicDateFormat(data.completed_date)}</span>
        </div>
        <div className="b-viewDebrief__slider">
          <div className="b-viewDebrief__fullScreenBtn" onClick={handleFullScreen} />
          <div className="b-viewDebrief__mainSliderWrapper" id="main-slider">
            <Slider
              afterChange={handleChangeSlider}
              asNavFor={navs.nav2}
              ref={mainSlider}
              speed={500}
              slidesToShow={1}
              infinite={false}
              slidesToScroll={1}
              dots={false}
              accessibility={true}
            >
              {_getSlides(slides, data).map((slide, index) => {
                if (slide.type === DebriefSlideTypes.content) {
                  return (
                    <div
                      id={`main-slide-${index}`}
                      key={`main-slide-${index}`}
                      className="b-viewDebrief__slide"
                    >
                      {slide.html}
                      {renderPageNumber(index)}
                    </div>
                  );
                }
                return (
                  <div
                    id={`main-slide-${index}`}
                    key={`main-slide-${index}`}
                    className="b-viewDebrief__slide"
                  >
                    <img src={slide.imgPath} alt={slide.alt} />
                    {renderPageNumber(index)}
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="b-viewDebrief__pag">
            <div
              className={`s-prev s-arrow ${(currentSliderIndex === 0 && '-disabled') || ''}`}
              onClick={handlePrevPag}
            />
            <Slider
              asNavFor={navs.nav1}
              ref={pagSlider}
              speed={500}
              slidesToShow={8}
              infinite={false}
              slidesToScroll={1}
              dots={false}
              focusOnSelect={true}
              accessibility={true}
            >
              {_getSlides(slides, data).map((slide, index) => {
                return (
                  <div key={`pag-slide-${index}`}>
                    <div className="b-viewDebrief__pagSlide">
                      <img src={slide.thumbPath} alt={slide.altThump} />
                    </div>
                  </div>
                );
              })}
            </Slider>
            <div
              className={`s-next s-arrow ${(slides.length === currentSliderIndex + 1 && '-disabled') || ''}`}
              onClick={handleNextPag}
            />
          </div>
        </div>
        <div className="position-absolute" style={{ left: -99999, top: -99000, width: 1340 }}>
          <div id="download-to-pdf-el">
            {_getSlides(slides, data).map((slide, index) => {
              if (slide.type === DebriefSlideTypes.content) {
                return (
                  <div key={`main-slide-${index}`} className="b-viewDebrief__slide -pdf">
                    {slide.html}
                    {renderPageNumber(index, 30)}
                  </div>
                );
              }
              return (
                <div key={`main-slide-${index}`} className="b-viewDebrief__slide">
                  <img src={slide.imgPath} alt={`Slide ${index}`} />
                  {renderPageNumber(index, 30)}
                </div>
              );
            })}
          </div>
        </div>
      </main>
    </>
  );
};

export default ViewDebrief;
