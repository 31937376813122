import React, { FC, useEffect, useState } from 'react';
import { getUser } from '../../../store/profile/profileActions';
import { getInfo } from '../../../store/utils/utilsActions';
import { getGroupAnalysisChart } from '../../../store/report/reportActions';
import { useDispatch, useSelector } from 'react-redux';
import { PhoneRequired, Sidebar, TaskNotification } from '../index';
import { AuthUserModel } from '../../../models';
import { StateType } from '../../../store/reducers';

const InternalAppWrapper: FC = ({ children }) => {
  const dispatch = useDispatch();
  const [needPhoneNumber, setNeedPhoneNumber] = useState<boolean>(false);
  const [taskNotification, setTaskNotification] = useState<boolean>(false);
  const user = useSelector((state: StateType) => state.profile.authUser);

  useEffect(() => {
    dispatch(
      getUser({
        callback: (user: AuthUserModel) => {
          setNeedPhoneNumber(user.user.notification.need_phone_number);
          setTaskNotification(user.user.notification.show_task_notification_logic);
        },
      }),
    );
    dispatch(getInfo({}));
    if (user?.orgs_tree) {
      dispatch(
        getGroupAnalysisChart({
          data: {},
        }),
      );
    }
  }, []);

  return (
    <div className="appContainer">
      <Sidebar />
      {children}
      {needPhoneNumber ? <PhoneRequired setPopupStatus={setNeedPhoneNumber} /> : null}
      {!user?.user?.notification?.need_phone_number && taskNotification ? (
        <TaskNotification setPopupStatus={setTaskNotification} />
      ) : null}
    </div>
  );
};

export default InternalAppWrapper;
