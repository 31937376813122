import React, { useEffect } from 'react';
import { BelovedDashboardTabs, DateFilter } from '../../../../common';
import { getDashboardElm } from '../../../../../store/dashboard/dashboardActions';
import { useDispatch, useSelector } from 'react-redux';
import ChartPie from '../../../../common/ChartPie/ChartPie';
import styles from './DashboardElmStyles.module.scss';
import { Loader } from '../../../../controls';
import { useTranslation } from 'react-i18next';
import { StateType } from '../../../../../store/reducers';
import { FilterByDatePayloadModel, KeyValueModel } from '../../../../../models';

const DashboardElm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dashboardELM = useSelector((state: StateType) => state.dashboard.elm);

  useEffect(() => {
    dispatch(getDashboardElm({}));
  }, []);

  const handleFilterByDate = ({ period, date_range, to_date, from_date }: FilterByDatePayloadModel) => {
    const params: KeyValueModel<string> = {};
    if (period) params.period = period;
    if (to_date || from_date) params.date_range = date_range;
    dispatch(getDashboardElm({ params }));
  };

  const renderContent = () => {
    if (!dashboardELM) {
      return <Loader fitParent />;
    }
    return (
      <div className={`${styles.boxList} b-dashboardBoxList`}>
        <div className={`${styles.elmBlock} b-dashboardBoxList__item`}>
          <h2 className="b-dashboardBoxList__title">{t('main:dashboard.total-completed-elms')}</h2>
          <div className="b-dashboardBoxList__content">
            <div className="b-dashboardBoxList__numTotal">
              <b className="b-dashboardBoxList__num">{dashboardELM?.elm_by_status.completed.val}</b>
            </div>
          </div>
        </div>

        <div className={`${styles.elmBlock} b-dashboardBoxList__item`}>
          <h2 className="b-dashboardBoxList__title">{t('main:dashboard.elms-status')}</h2>
          <div className={styles.total}>
            <span className={styles.total__number}>{dashboardELM.total_elm}</span>
            <span className={styles.total__title}>{t('main:dashboard.total-elms')}</span>
          </div>
          <div>
            <ChartPie type={'elm'} chart={dashboardELM?.elm_by_status} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <main className={'b-page'}>
      <h1 className={'b-page__title'}>{t('main:dashboard.dashboard')}</h1>
      <div className="b-heading">
        <BelovedDashboardTabs activeItemAlias={'ELM'} />
        <DateFilter onSubmit={handleFilterByDate} />
      </div>
      {renderContent()}
    </main>
  );
};

export default DashboardElm;
