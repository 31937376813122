import React from 'react';
import { SortDirection, useRowSelection } from 'react-data-grid';
import { BelEaModel, BelEaSavedAvgModel, ColumnType, TableDataEntities } from '../../../../../models';
import { basicDateFormat } from '../../../../../helpers';
import { useTranslation } from 'react-i18next';
import { TableDateFilter } from '../../../../common';
import { useTableDataV2 } from '../../../../../hooks/common/use-table-data-v2';
import { Checkbox, SimpleTooltip } from '../../../../controls';
import EmptyButton from '../../../../controls/Button/EmptyButton';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

interface ColumnsHandlers {
  baseUrl: string;
  onCellClick: (row: BelEaSavedAvgModel) => void;
}

export const getColumns = ({ baseUrl, onCellClick }: ColumnsHandlers): ColumnType<BelEaSavedAvgModel>[] => {
  const { t } = useTranslation();
  return [
    {
      key: 'name',
      name: t('common:column.name'),
      width: 170,
      sortable: true,
      editable: false,
      resizable: true,
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'created_at',
      name: t('common:column.create-date'),
      width: 110,
      sortable: true,
      editable: false,
      resizable: true,
      formatter(props) {
        return (
          <>
            {props.row.created_at ? basicDateFormat(new Date(props.row.created_at)) : t('common:column.n-a')}
          </>
        );
      },
      headerRenderer(props) {
        const { handleSelectDateFilter, query } = useTableDataV2<BelEaModel>({
          entityName: TableDataEntities.AVG_SAVED,
          baseUrl,
          enableSelectedEntities: true,
        });
        if (query.dateFilters) {
          return (
            <TableDateFilter
              dateFilterId={'created_at'}
              selectedFilters={query.dateFilters['created_at']}
              periodFieldName={'created_at_period'}
              dateRangeFieldName={'created_at_date_range'}
              onDateFilterChanged={handleSelectDateFilter}
              rdgProps={props}
              fiscalOption={true}
            />
          );
        }
      },
    },
    {
      key: 'updated_at',
      name: t('common:column.last-update'),
      width: 110,
      sortable: true,
      editable: false,
      resizable: true,
      formatter(props) {
        return (
          <>
            {props.row.updated_at ? basicDateFormat(new Date(props.row.updated_at)) : t('common:column.n-a')}
          </>
        );
      },
      headerRenderer(props) {
        const { handleSelectDateFilter, query } = useTableDataV2({
          entityName: TableDataEntities.AVG_SAVED,
          baseUrl,
          enableSelectedEntities: true,
        });
        if (query.dateFilters) {
          return (
            <TableDateFilter
              dateFilterId={'updated_at'}
              selectedFilters={query.dateFilters['updated_at']}
              periodFieldName={'updated_at_period'}
              dateRangeFieldName={'updated_at_date_range'}
              onDateFilterChanged={handleSelectDateFilter}
              rdgProps={props}
              fiscalOption={true}
            />
          );
        }
      },
    },
    {
      key: 'created_by_name',
      name: t('common:column.create-by'),
      sortable: true,
      editable: false,
      resizable: true,
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'tools',
      name: '',
      disableDrag: true,
      width: 70,
      maxWidth: 70,
      formatter: props => {
        return (
          <div className="b-rdgTable__cellCenter" onClick={e => e.stopPropagation()}>
            <EmptyButton
              ariaTitleLabel={t('common:tooltip.ea-avg-btn')}
              onClick={onCellClick ? () => onCellClick(props.row) : () => null}
              className="b-button-icon -colorOrange -mh2  -ea"
            />
          </div>
        );
      },
    },
    {
      key: 'select',
      name: '',
      width: 40,
      maxWidth: 40,
      disableDrag: true,
      headerRenderer(props) {
        return (
          <Checkbox
            checked={props.allRowsSelected}
            className="b-equityAudits__checkbox-header"
            onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
          />
        );
      },
      formatter: props => {
        const [isRowSelected, onRowSelectionChange] = useRowSelection();

        return (
          <div className="b-rdgTable__cellEnd">
            <div className={'checkBox b-equityAudits__checkbox'} onClick={e => e.stopPropagation()}>
              <Checkbox
                checked={isRowSelected}
                onChange={e => {
                  onRowSelectionChange({
                    row: props.row,
                    checked: e.target.checked,
                    isShiftClick: false,
                  });
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];
};
