import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DataGrid from 'react-data-grid';
import { useHistory, useParams } from 'react-router';
import { ROOTS } from '../../../../../routes/routes';
import { BelResourceShortModel, IRouteParamsBase, TableDataEntities } from '../../../../../models';
import { Pagination } from '../../../../common';
import { getColumns } from './parts/columns';
import { Button, Loader } from '../../../../controls';
import useTableData from '../../../../../hooks/common/use-table-data';
import { useDispatch } from 'react-redux';
import { useNavToViewResource } from '../../../../../hooks/resources/use-nav-to-view-resource';
import { setSelectedCohortResourceIds } from '../../../../../store/cohort/cohortActions';
import { useIsDarkMode } from '../../../../../hooks/common/use-is-dark-mode';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import useTableConfig from '../../../../../hooks/common/use-table-config';

interface IRouteParams extends IRouteParamsBase {
  id: string;
  sessionId: string;
}

const BelResourceLibrary = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isDarkMode } = useIsDarkMode();
  const dispatch = useDispatch();
  const navToView = useNavToViewResource();
  const { id, sessionId } = useParams<IRouteParams>();

  const {
    data,
    query,
    fetchData,
    handleChangePage,
    handleChangePageSize,
    handleSearch,
    handleSelectOne,
    selectedEntitiesIds,
    handleSort,
  } = useTableData(TableDataEntities.COHORT_RESOURCES, { session_id: sessionId, cohort_id: id });

  const onCellClick = (fieldName: string, data: BelResourceShortModel) => {
    if (fieldName === 'navToView') {
      navToView(data);
    }
  };

  const { columns, onColumnResize } = useTableConfig<BelResourceShortModel>(
    TableDataEntities.COHORT_RESOURCES,
    getColumns({ onCellClick }),
  );

  useEffect(() => {
    fetchData(query);
  }, []);

  const navToDetails = (data: BelResourceShortModel) => {
    history.push(ROOTS.BELOVED_RESOURCE_DETAILS.replace(':id', `${data.id}`));
  };

  const handleSelectRow = (ids: Set<number>) => {
    handleSelectOne(ids);
  };

  const renderContent = () => {
    if (!data) return <Loader fitParent />;

    return (
      <div>
        <DndProvider backend={HTML5Backend}>
          <DataGrid
            onRowClick={navToDetails}
            onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
            columns={columns}
            onColumnResize={onColumnResize}
            sortColumns={query.sorting ? [query.sorting] : []}
            rows={data.result}
            onSelectedRowsChange={handleSelectRow}
            className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
            style={{
              height: ((data.result.length || 0) + 1) * 60,
            }}
            rowHeight={60}
            selectedRows={new Set(selectedEntitiesIds)}
            rowKeyGetter={(row: BelResourceShortModel) => row.id}
          />
        </DndProvider>
        <Pagination data={data} handleChangePageSize={handleChangePageSize} changePage={handleChangePage} />
      </div>
    );
  };

  const navBack = (e?: any) => {
    e && e.preventDefault();
    history.go(-1);
  };

  const handleLinkResource = () => {
    dispatch(setSelectedCohortResourceIds(selectedEntitiesIds));
    history.go(-1);
  };

  return (
    <>
      <main className="b-page">
        <h1 className="b-page__title">{t('common:tabs.resource-library')}</h1>
        <div className="b-page__controls">
          <div className="b-page__search">
            <form action="#" className="searchForm">
              <input
                type="search"
                value={query.search}
                onChange={e => handleSearch(e.target.value)}
                placeholder={t('common:label.search')}
                aria-label="Search field"
              />
              <span className="searchForm__icon" />
            </form>
          </div>
          <div className="b-tableToolContainer">
            <div className="b-modal__buttons">
              <Button onPress={navBack} title={t('common:btn.cancel')} size={'large'} type={'transparency'} />
              <Button
                width={'auto'}
                onPress={handleLinkResource}
                title={t('common:btn.assign-cohort')}
                size={'large'}
                type={'orange-light'}
              />
            </div>
          </div>
        </div>
        {renderContent()}
      </main>
    </>
  );
};

export default BelResourceLibrary;
