import React from 'react';
import { Button } from '../../../controls';
import { Footer, Header } from '../../../common';
import Input from '../../../controls/Inputs/Input';
import { IRoleFields } from './RoleContainer';
import styles from './RoleStyles.module.scss';
import { useTranslation } from 'react-i18next';
import PhoneInput from '../../../controls/Inputs/PhoneInput';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import { useValidationPhoneNumber } from '../../../../hooks/common/use-validation-phone-number';

interface IProps {
  errors?: any;
  loader?: string;
  fields: IRoleFields;
  confirmPassword?: string;
  onChange: (field: string) => (value: string) => void;
  onSubmit: () => void;
  onBackNavigation: () => void;
  handleOnBlur: (field: string) => () => void;
}

const RoleView = ({
  loader,
  errors,
  fields,
  onSubmit,
  onChange,
  handleOnBlur,
}: // onBackNavigation,
IProps) => {
  const { t } = useTranslation();
  const user = useSelector((state: StateType) => state.profile.authUser?.user);
  const { errorPhoneNumber, setErrorPhoneNumber, onBlurPhoneValidation } = useValidationPhoneNumber();

  const blockSubmit = () => {
    if (user?.notification.need_phone_number && (errorPhoneNumber || (fields.phone_number || 0) <= 4)) {
      return true;
    }
    return !(fields.title.length && fields.division.length);
  };

  const handleChangePhoneNumber = (value: string) => {
    setErrorPhoneNumber('');
    onChange('phone_number')(value);
  };

  return (
    <>
      {loader}
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        <main className={'page-container'}>
          <div className={'white-container content-center-wrapper'} style={{ alignItems: 'flex-start' }}>
            <div className={'container-column'}>
              <div className={`container-row container-column mb-5 ${styles.sub_title}`}>
                <span className={'b-title gray-text font-l'}>{t('awa:M91.title')}</span>
                <span className={'text-long-description gray-text'}>{t('awa:M91.msg')}</span>
              </div>
              <div className={'container-row'}>
                <Input
                  error={errors.title}
                  value={fields.title}
                  handleUnselect={handleOnBlur('title')}
                  handleChange={onChange('title')}
                  type={'text'}
                  placeholder={t('common:label.official-organization-title')}
                  autoFocus
                />
              </div>
              <div className={'container-row'}>
                <Input
                  error={errors.division}
                  value={fields.division}
                  handleUnselect={handleOnBlur('division')}
                  handleChange={onChange('division')}
                  type={'text'}
                  placeholder={t('common:label.division/department/unit')}
                />
              </div>
              {user?.notification.need_phone_number ? (
                <div className={'container-row'}>
                  <PhoneInput
                    error={errorPhoneNumber}
                    value={fields.phone_number || ''}
                    handleUnselect={handleOnBlur('phone_number')}
                    handleChange={handleChangePhoneNumber}
                    placeholder={t('common:label.phone-number')}
                    onBlurPhoneValidation={onBlurPhoneValidation}
                  />
                </div>
              ) : null}
              <div
                className={`container-row mb-0 d-flex justify-content-between`}
                style={{ marginTop: '160px' }}
              >
                <Button
                  onPress={onSubmit}
                  title={t('common:btn.next')}
                  size={'middle'}
                  type={'orange-light'}
                  disabled={blockSubmit()}
                  enableEnter
                />
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default RoleView;
