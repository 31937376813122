import React, { FC, ReactNode, useEffect, useState } from 'react';
import { getLocaleUtcDateTime, sanitizedHTML } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { Link, Editor } from '../../controls';
import { GenericNoteEntityType, GenericNoteModel, GeneticNoteUpdateModel } from '../../../models';
import { useSelector } from 'react-redux';
import { StateType } from '../../../store/reducers';
import Api from '../../../services/api';

interface IProps {
  entityType: GenericNoteEntityType;
  entityId: number | null;
  renderLink?: (show: boolean) => ReactNode;
  isAllowToCreate?: boolean;
  onChangeNoteList?: (notes: GenericNoteModel[]) => void;
  isOpen?: boolean;
}

interface GenericNotesService {
  createNote?: (text: string, is_autonote: boolean) => void;
}

export const genericNotesService: GenericNotesService = {
  createNote: undefined,
};

const GenericNotes: FC<IProps> = ({
  entityType,
  entityId,
  renderLink,
  isAllowToCreate,
  onChangeNoteList,
  isOpen,
}) => {
  const { t } = useTranslation();
  let [show, setShow] = useState(isOpen || false);
  let [text, setText] = useState('');
  const [inputType, setInputType] = useState('');
  const user = useSelector((state: StateType) => state.profile.authUser);
  let [updateNoteData, setUpdateNoteData] = useState<GenericNoteModel | null>(null);
  let [notesList, setNotesList] = useState<GenericNoteModel[] | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (notesList) {
      onChangeNoteList && onChangeNoteList(notesList);
    }
    genericNotesService.createNote = (text: string, is_autonote: boolean) => {
      createNote(text, is_autonote);
    };

    return () => {
      genericNotesService.createNote = undefined;
    };
  }, [notesList]);

  const fetchData = async () => {
    if (!entityId) {
      return;
    }
    const res = await Api.get(`generic_notes/${entityType}/${entityId}/`);
    if (res) {
      setNotesList(res);
    }
  };

  const updateNote = async (id: number, data: GeneticNoteUpdateModel) => {
    const res = await Api.patch(`/generic_notes/${id}/`, undefined, data);
    if (res) {
      fetchData();
    }
  };

  const createNote = async (text: string, is_autonote: boolean) => {
    const res = await Api.post(`generic_notes/${entityType}/${entityId}/`, { body: text, is_autonote });
    if (res && notesList) {
      setNotesList([...notesList, res]);
      setInputType(`generic-new-${res.id}`);
    }
  };

  const handleSave = async () => {
    if (!entityId) {
      if (updateNoteData && notesList?.length) {
        const list = notesList || [];
        const listIndex = list.findIndex(f => f.id === updateNoteData?.id);
        list.splice(listIndex, 1, {
          body: text,
          author: user?.user,
          id: notesList[listIndex].id,
        } as unknown as GenericNoteModel);
        setNotesList(list);
        setUpdateNoteData(null);
        setText('');
        return;
      }
      const list = notesList || [];
      list.push({ body: text, author: user?.user, id: new Date().getTime() } as unknown as GenericNoteModel);
      setNotesList(list);
      setText('');
      return;
    }
    if (updateNoteData) {
      await updateNote(updateNoteData.id, { body: text });
      setUpdateNoteData(null);
      setText('');
      setInputType(`generic-update-${updateNoteData.id}`);
    } else {
      createNote(text, false);
    }
    setText('');
  };

  const handleRemove = async (noteId: number) => {
    if (!entityId && notesList) {
      const list = [...notesList];
      const listIndex = list.findIndex(f => f.id === noteId);
      list.splice(listIndex, 1);
      setNotesList(list);
      return;
    }
    updateNote(noteId, { is_removed: true });
  };

  // const handleArchive = async (noteId: number) => { will be needed in future
  //   updateNote(noteId, { is_archived: true });
  // };

  const handleEdit = (note: GenericNoteModel) => {
    setUpdateNoteData(note);
    setText(note.body);
    setInputType(`generic-${note.id}`);
  };

  const cancelEdit = () => {
    setUpdateNoteData(null);
    setText('');
    setInputType('generic');
  };

  return (
    <div>
      <Link onClick={() => setShow(!show)} className="d-flex align-items-center mb-4">
        {renderLink ? (
          renderLink(show)
        ) : (
          <div className="d-flex align-items-center ">
            <div className="b-button-icon -colorNavy -noHover -link_res b-ewpElementsTree__icon" />
            <div className="font-m ml-2 mr-3 font-weight-bold font-navy">{t('common:btn.notes')}</div>
          </div>
        )}
        <div className={`b-triangleIcon ${show ? '-opened' : ''}`} />
        <div />
      </Link>
      {show ? (
        <>
          <div className="b-resourceNote__list">
            {notesList?.length
              ? notesList.map(item => {
                  const isMyNote = user?.user.id === item.author.id;
                  return (
                    <div key={`note-${item.id}`} className="b-resourceNote__item">
                      <div>
                        {item.created_at ? (
                          <div className="b-resourceNote__itemDate">
                            {getLocaleUtcDateTime(item.created_at)}
                          </div>
                        ) : null}
                        <div className="b-resourceNote__itemUser">
                          {item.author.first_name} {item.author.last_name}
                        </div>
                      </div>
                      <div
                        className="b-resourceNote__itemText b-editorText w-100 text-break"
                        dangerouslySetInnerHTML={{
                          __html: sanitizedHTML(item.body),
                        }}
                      />
                      {isMyNote && !item.is_autonote ? (
                        <div className="b-resourceNote__controls">
                          {updateNoteData?.id === item.id ? (
                            <button className="b-button-icon -close" onClick={cancelEdit} />
                          ) : (
                            <button className="b-button-icon -edit" onClick={() => handleEdit(item)} />
                          )}
                          <button onClick={() => handleRemove(item.id)} className="b-button-icon -delete" />
                        </div>
                      ) : null}
                    </div>
                  );
                })
              : null}
            {!notesList?.length && !isAllowToCreate ? (
              <div className="b-no-content font-m">{t('common:label.no-notes')}</div>
            ) : null}
          </div>
          {isAllowToCreate ? (
            <>
              <Editor
                onChange={setText}
                defaultValue={sanitizedHTML(text)}
                placeholder={t('common:label.leave-note-here')}
                type={inputType || 'generic'}
              />
              <div className="d-flex justify-content-end mt-3">
                <Link
                  disabled={text.length < 2}
                  className={`orange-light-text ${text.length < 2 ? 'font-disabled' : ''}`}
                  onClick={handleSave}
                >
                  {updateNoteData ? t('common:btn.update-note') : t('common:btn.add-note')}
                </Link>
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

GenericNotes.defaultProps = {
  isAllowToCreate: true,
};

export default GenericNotes;
