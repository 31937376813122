import { call, all, take, StrictEffect, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { BelGetEaDetailsPayload, BEL_GET_EA_DETAILS, belGetEaDetailsSuccess } from './equityAuditActions';
import Api from '../../../services/api';
import { t } from 'i18next';

function* belGetEaDetailsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, orgId },
    }: { payload: BelGetEaDetailsPayload } = yield take(BEL_GET_EA_DETAILS);

    try {
      const response = yield call(Api.get, `beloved/equity_audit/${orgId}/details/`);

      if (response) {
        yield put(belGetEaDetailsSuccess(response));
        callback && callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

export function* saga() {
  yield all([belGetEaDetailsSaga()]);
}
