import React, { FC } from 'react';
import { ComaList, Link, ResourceStatusLabel, SimpleTooltip } from '../../controls';
import { useTranslation } from 'react-i18next';
import { BelResourceOptionModel, BelResourceShortModel, ResourceFileType } from '../../../models';
import BelovedLogoIc from '../../../assets/images/icons/beloved.svg';
import { ROOTS } from '../../../routes/routes';
import { useHistory } from 'react-router';
import { useNavToViewResource } from '../../../hooks/resources/use-nav-to-view-resource';
import { basicDateFormat, cutString, getResourceType } from '../../../helpers';
import { isMobileOnly } from 'react-device-detect';
import ResourceTools from '../../pages/Beloved/Resource/parts/ResourceTools';

interface IProps {
  data: BelResourceShortModel;
  handleTag: (tag: BelResourceOptionModel) => void;
}

const ResourceCard: FC<IProps> = ({ data, handleTag }) => {
  const { t } = useTranslation();
  const isBeloved = location.pathname.match('beloved');
  const history = useHistory();
  const navToView = useNavToViewResource();
  const docPath = data.docs.length ? data.docs[0].file : data.referenced_links[0];

  const navToDetails = (e: any) => {
    e.preventDefault();
    history.push(ROOTS.BELOVED_RESOURCE_DETAILS.replace(':id', `${data.id}`));
  };

  const getFileIconClass = () => {
    const resourceType = docPath ? getResourceType(docPath) : '';
    switch (resourceType) {
      case ResourceFileType.PDF:
        return 'b-resourceCard__categoryIcon--pdf';
      case ResourceFileType.VIDEO:
        return 'b-resourceCard__categoryIcon--video';
      default:
        return 'b-resourceCard__categoryIcon--docs';
    }
  };

  const getTitleTooltip = () => {
    return data.type.name;
  };
  return (
    <div className="b-resourceCard">
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          navToView(data);
        }}
        className="b-resourceCard__heading"
      >
        <h2 className="b-resourceCard__title">{data.title}</h2>
        {isBeloved && data.distribution.id !== 1 ? (
          <SimpleTooltip id={`globe-${data.id}`} title={t('common:tooltip.resource-public')}>
            <span className="b-resourceCard__headingIcon" />
          </SimpleTooltip>
        ) : null}
      </a>
      <div className="b-resourceCard__infoPanel">
        <div className="b-resourceCard__infoPanelLeft">
          <SimpleTooltip place={'right'} title={getTitleTooltip()} id={`resource-article-${data.id}`}>
            <div className={`b-resourceCard__categoryIcon ${getFileIconClass()}`} />
          </SimpleTooltip>
          {data.distribution.id === 1 ? (
            <img className="b-resourceCard__logo" src={BelovedLogoIc} alt={t('awa:AT12')} />
          ) : null}
        </div>
        <div className="b-resourceCard__infoPanelRight">
          {isBeloved ? (
            <>
              {!isMobileOnly ? (
                <a onClick={navToDetails} href="#" className="b-resourceCard__detailsLink">
                  {t('common:btn.details')}
                </a>
              ) : null}
              <ResourceStatusLabel status={data.status} />
            </>
          ) : null}
          {data.published_date ? (
            <span className="b-resourceCard__date">{basicDateFormat(data.published_date)}</span>
          ) : null}
        </div>
      </div>
      <p>{data.description}</p>
      <div className="b-resourceCard__dList">
        <dl>
          <dt>{t('common:column.authors')}:</dt>
          <dd>{data.authors || t('common:column.n-a')}</dd>
        </dl>
        <dl>
          <dt>{t('common:label.categories')}:</dt>
          <dd>
            {data.categories.length
              ? cutString(data.categories.map(cat => cat.title).join(', '), 108)
              : t('common:column.n-a')}
          </dd>
        </dl>
        <dl>
          <dt>{t('common:label.target-sectors')}:</dt>
          <dd>
            {data.sectors.length
              ? cutString(data.sectors.map(cat => cat.title).join(', '), 108)
              : t('common:column.n-a')}
          </dd>
        </dl>
        <dl>
          <dt>{t('common:label.access')}:</dt>
          <dd>{data.access_type.name}</dd>
        </dl>
        <ul className="tagList">
          {data.tags.map(item => {
            return (
              <li key={`tag-${item.id}`} className="tagList__item">
                <Link href="#" onClick={() => handleTag(item)} className="tagList__link">
                  #{item.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <ResourceTools data={data} />
    </div>
  );
};

export default ResourceCard;
