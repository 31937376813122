import React, { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import 'quill/dist/quill.snow.css';

interface IProps {
  onChange: (result: string) => void;
  defaultValue: string;
  placeholder: string;
  type?: string;
  disabled?: boolean;
  withoutImage?: boolean;
}

const defaultFormats = [
  'header',
  'blockquote',
  'indent',
  'bold',
  'italic',
  'color',
  'strike',
  'underline',
  'font',
  'size',
  'background',
  'link',
  'bullet',
  'list',
  'align',
  'image',
  'height',
  'width',
  'class',
  'style',
];

const defaultColors = [
  'transparent',
  '#00002d',
  '#e5e5e5',
  '#3366cc',
  '#dc3912',
  '#ff9900',
  '#109618',
  '#990099',
  '#0099c6',
  '#dd4477',
  '#66aa00',
  '#b82e2e',
  '#316395',
  '#3366cc',
  '#994499',
  '#22aa99',
  '#aaaa11',
  '#6633cc',
  '#e67300',
  '#8b0707',
  '#651067',
  '#329262',
  '#5574a6',
  '#3b3eac',
  '#b77322',
  '#16d620',
  '#b91383',
  '#f4359e',
  '#9c5935',
  '#a9c413',
  '#2a778d',
  '#668d1c',
  '#bea413',
  '#0c5922',
  '#743411',
];

const Editor = ({ onChange, defaultValue, placeholder, type, disabled, withoutImage }: IProps) => {
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      blotFormatter: {},
      toolbar: [
        [
          'bold',
          'italic',
          'underline',
          'strike',
          { color: defaultColors },
          { background: defaultColors },
          'blockquote',
          'align',
        ],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        withoutImage ? ['link'] : ['link', 'image'],
      ],
    },
    placeholder: placeholder,
    formats: defaultFormats,
  });

  useEffect(() => {
    if (disabled && quill) {
      quill?.disable();
    }
  }, [disabled, quill]);

  const isQuillEmpty = () => {
    if (!quill) {
      return true;
    }
    if ((quill.getContents()['ops'] || []).length !== 1) {
      return false;
    }
    return quill.getText().trim().length === 0;
  };

  if (Quill && !quill) {
    Quill.register('modules/blotFormatter', BlotFormatter);
  }

  useEffect(() => {
    if (quill) {
      const delta = quill.clipboard.convert(defaultValue as any);
      quill.setContents(delta);
      quill.on('text-change', (delta: any) => {
        if (delta.length() <= 1) {
          if (isQuillEmpty()) {
            onChange('');
            const delta = quill.clipboard.convert('' as any);
            quill.setContents(delta);
          } else {
            onChange(quill.root.innerHTML);
          }
          return;
        }
        onChange(quill.root.innerHTML);
      });
    }
  }, [quill, Quill]);

  useEffect(() => {
    if (type && quill) {
      const delta = quill.clipboard.convert(defaultValue as any);
      quill.setContents(delta);
    }
  }, [type]);

  return (
    <div>
      <div ref={quillRef} />
    </div>
  );
};

export default Editor;
