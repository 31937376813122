import { FilterItemModel, BasicFileModel, IRouteParamsBase, ISelectOptions } from '../global';

export enum EwpPreparationStepEnum {
  intro1 = 'intro1',
  introDetails = 'intro-details',
  intro2 = 'intro2',
  step1 = 'step1',
  step2 = 'step2',
  step3 = 'step3',
  step4 = 'step4',
  step5 = 'step5',
  finish = 'finish',
}

export enum EwpTypes {
  beloved_deep_dive_clients = 1,
  beloved_cohort_clients = 2,
  org_with_consultant_active_role = 3,
  org_with_consultant_reviewer_role = 4,
  self_guided_clients = 5,
}

export enum EwpElementTypes {
  priority = 'priority',
  goal = 'goal',
  strategy = 'strategy',
  key_action = 'key_action',
}

export enum EwpStatuses {
  IN_PREP = 1,
  SUBMITTED_FOR_REVIEW = 2,
  IN_REVIEW = 3,
  REVIEW_COMPLETED = 4,
  APPROVED = 5,
  IN_PROGRESS = 6,
  COMPLETED = 7,
}

export enum EwpElementStatuses {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  STALLED = 3,
  NO_LONGER_PURSUING = 4,
  COMPLETED = 5,
}

export enum GoalStatuses {
  NOT_UPDATED_YET = 1,
  MET = 2,
  POSITIVE_GROWTH = 3,
  NO_CHANGE = 4,
  NEGATIVE_GROWTH = 5,
  NO_LONGER_PURSUING = 6,
}

export enum EwpElementApprovalStatuses {
  IN_PREP = 1,
  SUBMITTED_FOR_REVIEW = 2,
  IN_REVIEW = 3,
  REVIEW_COMPLETED = 4,
  APPROVED = 5,
}

export type EwpTeamType = 'team' | 'managers' | 'consultants' | 'ewp_pm_users';
export type UserEwpRoleType = 'reviewer' | 'creator' | null;

export enum EwpCreateStepEnum {
  team = 'team',
  date = 'date',
  dateResult = 'dateResult',
  vision = 'vision',
  finish = 'finish',
}

export interface EWPTeamModel {
  id: number;
  name: string;
  user_id: number;
  status: string;
  status_date: string;
}

export interface IRouteParamsEwp extends IRouteParamsBase {
  elId: string;
}

export interface EWPElementsFilterOptions {
  priority: FilterItemModel[];
  goal: FilterItemModel[];
  strategy: FilterItemModel[];
  key_action: FilterItemModel[];
  review_items: FilterItemModel[];
  users_list: FilterItemModel[];
}

export interface EWPElementsTreeItemModel {
  id: number;
  title: string;
  is_broken: boolean;
  broken_childs: number[];
  child_ids: number[];
}

export interface EWPElementsTreeModel {
  goals: EWPElementsTreeItemModel[];
  key_actions: EWPElementsTreeItemModel[];
  priorities: EWPElementsTreeItemModel[];
  strategies: EWPElementsTreeItemModel[];
}

export interface EwpElStatusHistoryItemModel {
  id: number;
  status: number;
  status_name: string;
  status_type: string;
  updated_at: string;
  updated_by: string;
}

export interface EWPPriorityModel {
  id: number;
  title: string;
  description: string;
  ewp_id: number;
  user_create_id: number;
  user_create_name: string;
  created_at: string;
  updated_at: string;
  status: EwpElementStatuses;
  status_name: string;
  user_update_id: number | null;
  user_update_name: string | null;
  organization_id: number;
  stakeholders: ISelectOptions[];
  stakeholders_ids: number[];
  sub_standards: ISelectOptions[];
  sub_standards_ids: number[];
  elements_tree: EWPElementsTreeModel;
  user_ewp_role: UserEwpRoleType;
  approval_status: EwpElementApprovalStatuses;
  approval_status_name: string;
  ewp_status_id: EwpStatuses;
  ewp_status_name: string;
  is_broken: boolean;
  permissions: EwpElementPermissions;
  has_approved_parents: boolean;
  status_history: EwpElStatusHistoryItemModel[];
}

export interface EWPGoalModel {
  id: number;
  title: string;
  description: string;
  ewp_id: number;
  year_list: number[];
  year_list_data: ISelectOptions[];
  priorities: ISelectOptions[];
  priorities_ids: number[];
  updated_at: string;
  user_create_name: string;
  status: GoalStatuses;
  status_name: string;
  user_create_id: number;
  created_at: string;
  user_update_id: number | null;
  user_update_name: string | null;
  organization_id: number;
  elements_tree: EWPElementsTreeModel;
  user_ewp_role: UserEwpRoleType;
  approval_status: EwpElementApprovalStatuses;
  approval_status_name: string;
  ewp_status_id: EwpStatuses;
  ewp_status_name: string;
  is_broken: boolean;
  permissions: EwpElementPermissions;
  has_approved_parents: boolean;
  status_history: EwpElStatusHistoryItemModel[];
}

export interface EWPStrategyModel {
  id: number;
  title: string;
  description: string;
  ewp_id: number;
  owner_id: number;
  goals: ISelectOptions[];
  goals_ids: number[];
  owner: string;
  owners: EWPTeamModel[];
  user_create_id: number;
  user_create_name: string;
  created_at: string;
  status: EwpElementStatuses;
  status_name: string;
  updated_at: string;
  user_update_id: number | null;
  user_update_name: string | null;
  organization_id: number;
  elements_tree: EWPElementsTreeModel;
  user_ewp_role: UserEwpRoleType;
  approval_status: EwpElementApprovalStatuses;
  approval_status_name: string;
  ewp_status_id: EwpStatuses;
  ewp_status_name: string;
  is_broken: boolean;
  permissions: EwpElementPermissions;
  has_approved_parents: boolean;
  status_history: EwpElStatusHistoryItemModel[];
}

export interface EWPKeyActionModel {
  id: number;
  ewp_id: number;
  title: string;
  data: null | string;
  description: string;
  created_at: string;
  estimated_completion_date: string;
  key_types: ISelectOptions[];
  key_types_ids: number[];
  last_updated: string;
  owner: string;
  owners: EWPTeamModel[];
  start_date: string;
  status_name: string;
  status: EwpElementStatuses;
  strategies: ISelectOptions[];
  strategies_ids: number[];
  user_create_id: number;
  user_create_name: string;
  user_update_id: number | null;
  user_update_name: string | null;
  organization_id: number;
  elements_tree: EWPElementsTreeModel;
  user_ewp_role: UserEwpRoleType;
  approval_status: EwpElementApprovalStatuses;
  approval_status_name: string;
  ewp_status_id: EwpStatuses;
  ewp_status_name: string;
  is_broken: boolean;
  permissions: EwpElementPermissions;
  has_approved_parents: boolean;
  status_history: EwpElStatusHistoryItemModel[];
}

export interface EwpElementPermissions {
  edit: boolean;
  partial_edit: boolean;
  assign_owner: boolean;
  has_delete_right: boolean;
  has_approved_child: boolean;
}

export interface EWPPermissions {
  ewp_obj: {
    edit: boolean;
  };
  ewp_priority: {
    create: boolean;
  };
  ewp_goal: {
    create: boolean;
  };
  ewp_strategy: {
    create: boolean;
  };
  ewp_key_action: {
    create: boolean;
  };
  notes: {
    edit: boolean;
    create: boolean;
  };
}

export interface EWPModel {
  id: number;
  ewp_screen: EwpCreateStepEnum;
  end_date: string | null;
  introduction: string;
  manager_ids: number[];
  managers: EWPTeamModel[];
  last_update: string | null;
  organization_id: number;
  organization_name: string;
  start_date: string | null;
  status_date: string | null;
  status_id: number;
  status_name: string;
  ewp_type_name: string;
  ewp_type_id: EwpTypes;
  updated_by: string;
  ewp_pm_users: EWPTeamModel[];
  ewp_pm_user_ids: number[];
  team: EWPTeamModel[];
  team_ids: number[];
  consultants: EWPTeamModel[];
  consultant_ids: number[];
  beloved_team: EWPTeamModel[];
  beloved_team_ids: number[];
  vision: string;
  dei_statement: string;
  plan_document_link: string | null;
  plan_document: BasicFileModel | null;
  user_ewp_role: UserEwpRoleType;
  ewp_type_details: string[];
  ewp_type_details_title: string;
  elements: {
    has_goal: boolean;
    has_key_action: boolean;
    has_priority: boolean;
    has_strategy: boolean;
  };
  elements_tree: EWPElementsTreeModel;
  ewp_permissions: EWPPermissions;
  is_broken: boolean;
  unread_elements_count: number;
}

export interface BelEwpModel {
  id: number;
  organization_id: number;
  organization_name: string;
  affiliation: string | null;
  status: number;
  status_name: string;
  ewp_type: number;
  ewp_type_name: string;
  updated_date: string;
  start_date: string;
  beloved_leaders: ISelectOptions[];
  project_manager: {
    user_id: number;
    name: string;
  } | null;
  created_by: {
    user_id: number;
    name: string;
  } | null;
}

export interface EwpReviewItem {
  id: number;
  element_id: number;
  element_type: EwpElementTypes;
  title: string;
  approval_status_id: EwpElementApprovalStatuses;
  approval_status_name: string;
  user_ewp_role: UserEwpRoleType;
  ewp_status_name: string;
  ewp_status_id: EwpStatuses;
  last_update: string;
  is_read: string;
  is_snoozed: string;
  requester_name: string | null;
  reviewer_name: string | null;
}

export interface EwpUserTableElement {
  element_type: string;
  id: number;
  title: string;
}

export interface EwpUserTableItem {
  user_id: number;
  title: null;
  name: string;
  id: number;
  email: string;
  ewp_roles: string[];
  ewp_elements: EwpUserTableElement[];
  awa_role: string;
  division: string | null;
  ewp_status: string | null;
  ewp_status_date: string | null;
  org_status: string | null;
  org_status_date: string | null;
}

export interface EWPUpdateData {
  pm_ids?: number[];
  start_date?: string | Date | null;
  end_date?: string;
  introduction?: string;
  vision?: string;
  dei_statement?: string;
  plan_document_link?: string;
  plan_document_id?: number | null;
  team_ids?: number[];
  manager_ids?: number[];
  consultant_ids?: number[];
  ewp_screen: EwpCreateStepEnum;
}

export interface EWPSendEmailPayload {
  target: EwpTeamType;
  ewp_id: number;
  team_ids: number[];
}

export interface EWPPriorityCreateData {
  title: string;
  description: string;
  sub_standards_ids: number[];
  stakeholders_ids: number[];
}

export interface EWPGoalCreateData {
  title: string;
  description: string;
  year_list: number[];
  priorities_ids: number[];
}

export interface EWPStrategyCreateData {
  title: string;
  description: string;
  owners: number[];
  goals_ids: number[];
}

export interface EWPKeyActionCreateData {
  title: string;
  description: string;
  owners: number[];
  start_date: Date;
  estimated_completion_date: Date;
  data: string;
  // status: EwpElementStatuses;
  key_types_ids: number[];
  strategies_ids: number[];
}

export interface ChartEwpLabelModel {
  id: number;
  title: string;
  description: string;
}

export interface ChartEwpModel {
  complete: number[];
  in_progress: number[];
  no_longer_pursuing: number[];
  not_yet_started: number[];
  stalled: number[];
  labels: ChartEwpLabelModel[];
}

export interface ChartEwpDashboardModel {
  key_action: ChartEwpModel;
  strategy: ChartEwpModel;
}

export enum EwpReviewItemActionTypes {
  READ = 'read',
  UNREAD = 'unread',
  SNOOZE = 'snooze',
  UNSNOOZE = 'unsnooze',
}

export interface EwpDeleteElementData {
  approval_status: EwpElementApprovalStatuses;
  permissions: {
    edit: boolean;
    partial_edit: boolean;
    assign_owner: boolean;
    has_delete_right: boolean;
    has_approved_child: boolean;
  };
}
