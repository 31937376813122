import React, { FC, useEffect, useState } from 'react';
import { AdminToolUserModel, ISelectOptions, AdminToolUpdateUserModel } from '../../../../../models';
import { Modal } from '../../../../common';
import { Button, Input, Select } from '../../../../controls';
import { atLeastOneOption, email, isEmptyObject, validate } from '../../../../../helpers';
import { useTranslation } from 'react-i18next';

interface IProps {
  userData?: AdminToolUserModel;
  onClose: () => void;
  availableRoles: ISelectOptions[];
  onSave: (data: AdminToolUpdateUserModel, callback: (error?: string) => void) => void;
}

const UserForm: FC<IProps> = ({ userData, onClose, availableRoles, onSave }) => {
  const validator = {
    required: ['first_name', 'last_name', 'email'],
    custom: [email(['email']), atLeastOneOption(['roles'])],
  };

  const initialForm = {
    first_name: '',
    last_name: '',
    email: '',
    roles: [],
  };
  const { t } = useTranslation();

  let [form, setForm] = useState<AdminToolUpdateUserModel>(initialForm);
  let [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (userData) {
      setForm({
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        roles: userData.user_roles,
      });
    }
  }, []);

  const handleChangeInput = (val: string, fieldName: string) => {
    setForm({ ...form, [fieldName]: val });
    setErrors({});
  };

  const handleChangeSelect = (val: ISelectOptions, fieldName: string) => {
    setForm({ ...form, [fieldName]: val });
    setErrors({});
  };

  const handleSave = () => {
    let { errors } = validate(validator, form);
    setErrors(errors);
    if (isEmptyObject(errors)) {
      onSave(form, (error?: string) => {
        if (error) {
          setErrors({ email: error });
        }
      });
    }
  };

  return (
    <Modal
      className="b-adminToolUsers__userModal"
      width={855}
      title={userData ? t('common:label.Edit-Beloved-User') : t('common:label.New-Beloved-User')}
      onClose={onClose}
    >
      <div className="b-modal">
        <div className="b-modal__content b-adminToolUsers__userForm">
          <div className="b-adminToolUsers__userFormRow">
            <div className="b-adminToolUsers__userFormInput">
              <Input
                disabled={!!userData?.user_id}
                error={errors.first_name}
                handleChange={(val: string) => handleChangeInput(val, 'first_name')}
                type="text"
                placeholder={t('common:label.first-name')}
                value={form.first_name}
              />
            </div>
            <div className="b-adminToolUsers__userFormInput">
              <Input
                disabled={!!userData?.user_id}
                error={errors.last_name}
                handleChange={(val: string) => handleChangeInput(val, 'last_name')}
                type="text"
                placeholder={t('common:label.last-name')}
                value={form.last_name}
              />
            </div>
          </div>
          <div className="b-adminToolUsers__userFormRow">
            <div className="b-adminToolUsers__userFormInput">
              <Input
                disabled={!!userData?.user_id}
                error={errors.email}
                handleChange={(val: string) => handleChangeInput(val, 'email')}
                type="email"
                placeholder={t('common:label.email')}
                value={form.email}
              />
            </div>
            <div className="b-adminToolUsers__userFormInput">
              <Select
                error={errors.roles}
                placeholder={t('common:column.roles')}
                handleChange={value => handleChangeSelect(value, 'roles')}
                isMulti
                value={form.roles}
                options={availableRoles}
              />
            </div>
          </div>
        </div>
        <div className="b-modal__buttons">
          <Button
            title={t('common:btn.cancel')}
            type="transparency"
            size="large"
            onPress={onClose}
            className="button-mh"
          />
          <Button title={t('common:btn.save')} size="large" onPress={handleSave} className="button-mh" />
        </div>
      </div>
    </Modal>
  );
};

export default UserForm;
