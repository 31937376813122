import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useLocation, useParams } from 'react-router';
import { Button, Link, Loader, SimpleTooltip, Tooltip } from '../../../../controls';
import { Modals, ModalsModel } from '../../../../../constants';
import { BelEaModel, EAStatuses, IRouteParamsBase, TableDataEntities } from '../../../../../models';
import { getColumns } from './parts/columns';
import { Alert, HeadlineOrganization, OrgBreadcrumbs } from '../../../../common';
import { basicDateFormat, compareArrays, getBelovedEaStatusClass } from '../../../../../helpers';
import { useTranslation } from 'react-i18next';
import SortModalButton from '../../../../common/SortModalButton/SortModalButton';
import CheckedItems from '../../../../common/CheckedItems/CheckedItems';
import { useSelectCurrentOrg } from '../../../../../hooks/org/use-select-current-org';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import SaveGroupAverage from './parts/SaveGroupAverage';
import { useTableDataV2 } from '../../../../../hooks/common/use-table-data-v2';
import { DataTable } from '../../../../common/DataTable/DataTable';
import { eaAVgListType } from '../../../../../constants/ea';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { getAvgChart } from '../../../../../store/avg/avgActions';
import UpdateGroupAverage from './parts/UpdateGroupAverage';
import { ROOTS } from '../../../../../routes/routes';

interface IState {
  modals: ModalsModel;
}

interface IRouteParams extends IRouteParamsBase {
  avg_id: eaAVgListType | string;
}

interface ILocationState {
  org?: { id: number; name: string };
}

const GroupAveragesEAs = () => {
  const { t } = useTranslation();
  const location = useLocation<ILocationState | undefined>();
  let [notMobileAlert, setNotMobileAlert] = useState(false);
  const { navBack } = useNavBack();
  const currentOrg = useSelectCurrentOrg();
  const { orgId, avg_id } = useParams<IRouteParams>();
  const dispatch = useDispatch();
  const activeChart = useSelector((state: StateType) => state.avg.activeChart);
  const [loading, setLoading] = useState(false);
  const isBeloved = location.pathname.match('beloved');
  const history = useHistory();
  const baseUrl = isBeloved
    ? `beloved/equity_audit/`
    : `organizations/${orgId}/equity_audit/group_average/ea_list/`;

  const isList = avg_id === 'list';

  const {
    query,
    data,
    fetchData,
    handleSearch,
    handleSort,
    setSortingData,
    sortingData,
    handleChangeDirection,
    handleChangeSortField,
    fetchMoreMobile,
    handleSelectOne,
    selectedEntitiesIds,
    handleSelectOneMob,
    infiniteList,
    setExtraData,
    setSelectedEntitiesIds,
  } = useTableDataV2<BelEaModel>({
    entityName: TableDataEntities.AVG,
    baseUrl,
    enableSelectedEntities: true,
    extraData: { average_id: !isList ? avg_id : undefined, status: EAStatuses.COMPLETED },
  });

  const initialState: IState = {
    modals: {},
  };

  const columns = getColumns({ baseUrl, isList, avg_id });
  let [state, setState] = useState<IState>(initialState);
  const [initialSelectedEntitiesIds, setInitialSelectedEntitiesIds] = useState<number[]>([]);

  useEffect(() => {
    if (activeChart && activeChart.id === Number(avg_id)) {
      setSelectedEntitiesIds(activeChart.equity_audits || []);
      setInitialSelectedEntitiesIds(activeChart.equity_audits || []);
    } else if (!isList && activeChart?.id !== Number(avg_id)) {
      setLoading(true);
      dispatch(
        getAvgChart({
          org_id: orgId,
          id: Number(avg_id),
          isBeloved: isBeloved,
          callback: res => {
            setLoading(false);
            setInitialSelectedEntitiesIds(res.equity_audits);
            setSelectedEntitiesIds(res.equity_audits);
          },
        }),
      );
    }
    if (!isList) {
      setExtraData({ average_id: avg_id });
    }
    fetchData(query);
  }, [location.key]);

  const manipulateModal = (modalName: string, open: boolean) => {
    setState(prevState => ({
      ...prevState,
      modals: {
        ...state.modals,
        [modalName]: open,
      },
    }));
  };

  const handleRedirectToChart = () => {
    if (!activeChart) {
      return;
    }
    const route = isBeloved
      ? ROOTS.BEL_GROUP_AVERAGES_CHART.replace(':id', `${activeChart.id}`)
      : ROOTS.GROUP_AVERAGES_CHART.replace(':orgId', `${orgId}`).replace(':id', `${activeChart.id}`);

    history.replace(route, {
      ea_ids: activeChart.equity_audits,
      name: activeChart.name,
    });
  };

  const getSubTitle = () => {
    if (isList) {
      return `${t('common:tabs.standards-substandards-averages')}`;
    } else {
      return (
        <>
          <div className="b-page__title2 font-navy d-flex">
            <span>{t('common:tabs.group-average')}: &nbsp;</span>
            <SimpleTooltip title={t('common:tooltip.view-avg')} id={'active-view-averages'}>
              <Link
                onClick={handleRedirectToChart}
                className="b-report__avg-subTitle orange-light-text ellipse b-link-icon -right"
              >
                {activeChart && activeChart.name}
              </Link>
            </SimpleTooltip>
          </div>
        </>
      );
    }
  };

  const isDisableAvg =
    selectedEntitiesIds.length < 2 ||
    (initialSelectedEntitiesIds.length === selectedEntitiesIds.length &&
      compareArrays(initialSelectedEntitiesIds, selectedEntitiesIds));

  if (loading) {
    return <Loader fitParent />;
  }

  const renderContent = () => {
    if (!data) return <Loader fitParent />;

    return (
      <>
        <div className="-desktop position-relative">
          <CheckedItems value={selectedEntitiesIds.length} total={data.count} />
          <DataTable<BelEaModel>
            baseUrl={baseUrl}
            entityName={TableDataEntities.AVG}
            selectedEntitiesIds={selectedEntitiesIds}
            handleSelectOne={handleSelectOne}
            cols={columns}
            extraData={{ average_id: !isList ? avg_id : undefined, status: EAStatuses.COMPLETED }}
          />
        </div>
        {isMobile && (
          <div className="-mobile b-panelTitleOutside__list">
            <InfiniteScroll
              dataLength={infiniteList.length}
              next={fetchMoreMobile}
              hasMore={!(data.current_page === data.total_pages)}
              loader={<h4>{t('common:label.loading')}...</h4>}
            >
              {infiniteList.map((ea: BelEaModel) => {
                const isChecked = !!selectedEntitiesIds.find(f => f === ea.id);

                return (
                  <div key={`mob-item-${ea.id}`} className="b-panelTitleOutside__container">
                    <span className="b-panelTitleOutside__name">{ea.organization_name}</span>
                    <div className="b-panelTitleOutside">
                      <label className="checkbox b-at-user__check" onClick={e => e.stopPropagation()}>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={e => handleSelectOneMob(e, ea.id)}
                        />
                        <span className="checkbox_fakeInput" />
                      </label>
                      <table>
                        <tr>
                          <th>{t('common:column.org-affiliation')}</th>
                          <td>{ea.affiliation}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.org-type')}</th>
                          <td>{ea.type}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.status')}</th>
                          <td>
                            <span className={`b-tableStatusMark ${getBelovedEaStatusClass(ea.status_id)}`}>
                              {ea.status_name}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:label.submission-date')}</th>
                          <td>
                            {ea.submit_date
                              ? basicDateFormat(new Date(ea.submit_date))
                              : t('common:column.n-a')}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <div>
          <div className="b-page__header -desktop mb-0 align-items-baseline">
            <HeadlineOrganization
              orgName={currentOrg?.name || ''}
              orgId={currentOrg?.id || ''}
              headline={t('common:btn.equity-audits')}
            />
            {isList ? (
              <div className="b-page__headerBtns -desktop">
                {data && (
                  <Tooltip title={isDisableAvg ? t('common:tooltip.ea-compute-avg') : ''}>
                    <Button
                      className="btn -newEA"
                      type={'orange-light'}
                      onPress={() => manipulateModal(Modals.isSaveAverage, true)}
                      title={t('common:btn.compute-averages')}
                      disabled={isDisableAvg}
                    />
                  </Tooltip>
                )}
              </div>
            ) : (
              <div className="b-page__headerBtns -desktop">
                <Button
                  className="btn -newEA"
                  type={'orange-light'}
                  onPress={() => manipulateModal(Modals.isUpdateAverage, true)}
                  title={t('common:btn.update-averages')}
                  disabled={isDisableAvg}
                />
              </div>
            )}
          </div>
          <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
          <h2 className="b-page__title2 font-navy">
            <Link className="b-report__backBtn" onClick={navBack}>
              <div className="back-icon" />
            </Link>
            {getSubTitle()}
            {/*{t('common:tabs.standards-substandards-averages')}*/}
          </h2>
          <div className="b-page__controls">
            <div className="b-tableToolContainer" />
            <div className="b-page__search">
              <form action="#" className="searchForm">
                <input
                  type="search"
                  value={query.search}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder={t('common:label.search')}
                  aria-label="Search field"
                />
                <span className="searchForm__icon" />
              </form>
            </div>
          </div>
          <div className="-mobile">
            <div className="b-tableToolContainer -centered">
              <SortModalButton
                sortingData={sortingData}
                setSortingData={setSortingData}
                columns={columns}
                query={query}
                handleChangeSortField={handleChangeSortField}
                handleSort={handleSort}
              />
              <button
                onClick={handleChangeDirection}
                type="button"
                className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                    ${query.sorting?.direction === 'DESC' && '-active'}
                  `}
              >
                {t('common:btn.a-z-order')}
              </button>
            </div>
          </div>
        </div>
        {renderContent()}
      </main>
      {state.modals.isSaveAverage ? (
        <SaveGroupAverage
          onClose={() => manipulateModal(Modals.isSaveAverage, false)}
          ea_ids={selectedEntitiesIds}
        />
      ) : null}
      {state.modals.isUpdateAverage ? (
        <UpdateGroupAverage
          onClose={() => manipulateModal(Modals.isUpdateAverage, false)}
          ea_ids={selectedEntitiesIds}
        />
      ) : null}
      {notMobileAlert && (
        <Alert
          isOpen={notMobileAlert}
          onRequestClose={() => setNotMobileAlert(false)}
          title={t('awa:M54.title')}
          text={t('awa:M54.msg')}
        />
      )}
    </>
  );
};

export default GroupAveragesEAs;
