import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { useHistory, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useEwpApi } from '../../../../../hooks/ewp/use-ewp-api';
import {
  EwpElementApprovalStatuses,
  EwpElementTypes,
  EWPKeyActionModel,
  EwpStatuses,
  IRouteParamsEwp,
} from '../../../../../models';
import { useGetEwpRoute } from '../../../../../hooks/ewp/use-get-ewp-route';
import { ROOTS } from '../../../../../routes/routes';
import { Button, ComaList, Loader, Link, SimpleTooltip } from '../../../../controls';
import { basicDateFormat, sanitizedHTML } from '../../../../../helpers';
import ElementTree from '../parts/ElementTree';
import ElementStatusController from '../parts/ElementStatusController';
import { GenericNotes, HeadlineOrganization } from '../../../../common';
import { useEwpDetails } from '../../../../../hooks/ewp/use-ewp-details';
import { EwpHelpers } from '../../../../../helpers/ewpHelpers';
import { showAlert } from '../../../../common/Alert/RemoteAlert';
import { toast } from 'react-toastify';

const EwpKaDetails = () => {
  const { t } = useTranslation();
  const { ewpDetails } = useEwpDetails();
  const { navBack } = useNavBack();
  const history = useHistory();
  const { getKeyActionDetails, deleteKeyAction } = useEwpApi();
  const { elId, orgId, id } = useParams<IRouteParamsEwp>();
  const EWP_KA_CREATE = useGetEwpRoute(ROOTS.EWP_KA_CREATE);
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState<EWPKeyActionModel | null>(null);
  const isAllowToEdit =
    data && (data.permissions.edit || data.permissions.partial_edit || data.permissions.assign_owner);
  const elementCanBeStarted =
    data &&
    data.approval_status === EwpElementApprovalStatuses.APPROVED &&
    data.ewp_status_id >= EwpStatuses.IN_PROGRESS &&
    data.has_approved_parents;

  const handleDelete = () => {
    showAlert({
      title: t('awa:M170.title'),
      text: (
        <>
          <p>{t('awa:M170.msg.p1')}</p>
          <p>{t('awa:M170.msg.p2')}</p>
        </>
      ),
      buttons: {
        left: {
          title: t('common:btn.cancel'),
          type: 'transparency',
        },
        right: {
          title: t('common:btn.yes'),
          onClick: () => {
            deleteKeyAction(Number(elId), () => {
              toast.success(t('awa:S19.msg'));
              history.replace(ROOTS.EWP_KA_LIST.replace(':orgId', orgId).replace(':id', id));
            });
          },
        },
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getKeyActionDetails(Number(elId), data => {
      setData(data);
      setLoading(false);
    });
  }, [elId]);

  if (!data) return <Loader />;

  const deleteDisabledText = EwpHelpers.getElDeleteDisabledText(data);

  return (
    <main className="b-page">
      {loading ? <Loader /> : null}
      <div className="b-page__header">
        <HeadlineOrganization
          orgName={ewpDetails?.organization_name || ''}
          orgId={ewpDetails?.organization_id || ''}
          headline={t('common:headlines.equity-work-plan')}
          customClasses={'-noMb -desktop'}
        />
        <div className="d-flex">
          {!elementCanBeStarted ? (
            <SimpleTooltip title={deleteDisabledText} id={'del-element'}>
              <Button
                disabled={!data.permissions.has_delete_right}
                type="transparency"
                className="button-mh"
                size={'small'}
                onPress={handleDelete}
                title={t('common:btn.delete')}
              />
            </SimpleTooltip>
          ) : null}
          {isAllowToEdit ? (
            <Button
              size={'small'}
              onPress={() => history.push(EWP_KA_CREATE, data)}
              title={t('common:btn.edit')}
            />
          ) : null}
        </div>
      </div>
      <div className="d-flex mb-5">
        <Link onClick={navBack} className="b-page-back-link mb-0">
          {t('common:label.key-action')}
        </Link>
        <ElementStatusController
          addNoteOnAStatusChanged
          onStatusChange={(newData: EWPKeyActionModel) => setData(newData)}
          onApproveStatusChange={(newData: EWPKeyActionModel) => setData(newData)}
          data={{
            orgId: data.organization_id,
            entityId: data.id,
            status: data.status,
            approval_status: data.approval_status,
            ewp_status_id: data.ewp_status_id,
            ewp_id: data.ewp_id,
            owners: data.owners,
            ewp_pms: ewpDetails?.ewp_pm_users,
            has_approved_parents: data.has_approved_parents,
            status_history: data.status_history,
          }}
          type={EwpElementTypes.key_action}
        />
      </div>
      <div className="contentContainer">
        <div className="font-grey mb-4">{data.title}</div>
        <div className="d-flex mb-4">
          <div className="w200px flex-shrink-0 font-navy font-m font-weight-bold">
            {t('common:label.description')}:
          </div>
          <div className="font-grey">{data.description}</div>
        </div>
        <div className="d-lg-flex mb-4">
          <div className="d-flex mr-5 mb-4 mb-xl-0">
            <div className="w200px flex-shrink-0 font-navy font-m font-weight-bold">
              {t('common:label.created-on')}:
            </div>
            <div className="font-grey">
              {data.created_at ? basicDateFormat(data.created_at) : t('common:column.n-a')}
            </div>
          </div>
          <div className="d-flex">
            <div className="flex-shrink-0 mr-3 font-navy font-m font-weight-bold">
              {t('common:label.author')}:
            </div>
            <div className="font-grey">{data.user_create_name || t('common:column.n-a')}</div>
          </div>
        </div>
        <div className="d-lg-flex mb-4">
          <div className="d-flex mr-5 mb-4 mb-xl-0">
            <div className="w200px flex-shrink-0 font-navy font-m font-weight-bold">
              {t('common:label.last-update')}:
            </div>
            <div className="font-grey">
              {data.last_updated ? basicDateFormat(data.last_updated) : t('common:column.n-a')}
            </div>
          </div>
          <div className="d-flex">
            <div className="flex-shrink-0 mr-3 font-navy font-m font-weight-bold">
              {t('common:label.editor')}:
            </div>
            <div className="font-grey">{data.user_update_name || t('common:column.n-a')}</div>
          </div>
        </div>
        <div className="d-flex mb-4">
          <div className="w200px flex-shrink-0 font-navy font-m font-weight-bold">
            {t('common:column.start-date')}:
          </div>
          <div className="font-grey">
            {data.start_date ? basicDateFormat(data.start_date) : t('common:column.n-a')}
          </div>
        </div>
        <div className="d-flex mb-4">
          <div className="w200px flex-shrink-0 font-navy font-m font-weight-bold">
            {t('common:label.estimated-completion')}:
          </div>
          <div className="font-grey">
            {data.estimated_completion_date
              ? basicDateFormat(data.estimated_completion_date)
              : t('common:column.n-a')}
          </div>
        </div>
        <div className="d-flex mb-4">
          <div className="w200px flex-shrink-0 font-navy font-m font-weight-bold">
            {t('common:label.key-action-owner')}:
          </div>
          <div className="font-grey">
            <ComaList arr={data.owners.map(item => ({ title: item.name }))} uKey={'owner'} />
          </div>
        </div>
        <div className="d-flex mb-4">
          <div className="w200px flex-shrink-0 font-navy font-m font-weight-bold">
            {t('common:label.key-action-type')}:
          </div>
          <div className="font-grey">
            <ComaList arr={data.key_types.map(item => ({ title: item.label }))} uKey={'k-type-'} />
          </div>
        </div>
        <div className="d-flex mb-4">
          <div className="w200px flex-shrink-0 font-navy font-m font-weight-bold">
            {t('common:label.data')}
          </div>
          <div
            className="font-grey b-editorText text-break"
            dangerouslySetInnerHTML={{ __html: sanitizedHTML(data.data) || t('common:column.n-a') }}
          />
        </div>
        {!loading ? (
          <GenericNotes
            isAllowToCreate={ewpDetails?.ewp_permissions.notes.create}
            entityType={'key_action'}
            entityId={data.id}
          />
        ) : null}
        {ewpDetails ? (
          <RouterLink
            to={ROOTS.LINK_RESOURCE.replace(':entity', 'key_action').replace(':entityId', `${data.id}`)}
            className="d-flex align-items-center mb-4"
          >
            <div className="b-button-icon -colorOrange -noHover -ewp_priority b-ewpElementsTree__icon" />
            <div className="font-m b-link ml-2 mr-3 font-weight-bold">{t('common:column.resources')}</div>
          </RouterLink>
        ) : null}
        <ElementTree entityId={data.id} type="ka" tree={data.elements_tree} />
      </div>
    </main>
  );
};

export default EwpKaDetails;
