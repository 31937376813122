import React, { FC } from 'react';
import { ComaList, Link } from '../../../../../controls';
import { useTranslation } from 'react-i18next';
import { EwpElementTypes, EWPKeyActionModel } from '../../../../../../models';
import { basicDateFormat } from '../../../../../../helpers';
import { useHistory } from 'react-router';
import { useGetEwpRoute } from '../../../../../../hooks/ewp/use-get-ewp-route';
import { ROOTS } from '../../../../../../routes/routes';
import ElementStatusCombined from '../../parts/ElementStatusCombined';

interface IProps {
  data: EWPKeyActionModel;
}

const EwpKaItem: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const EWP_KA_DETAILS = useGetEwpRoute(ROOTS.EWP_KA_DETAILS);

  return (
    <div className="b-resourceCard">
      <Link
        onClick={() => history.push(EWP_KA_DETAILS.replace(':elId', `${data.id}`))}
        className="b-resourceCard__heading"
      >
        <h2 className="b-resourceCard__title">{data.title}</h2>
        <div className="flex-shrink-0 b-button-icon -colorOrange -w40 -ewp_ka" />
      </Link>
      <div className="b-resourceCard__dList position-relative">
        <div className="position-absolute" style={{ top: 0, right: 0 }}>
          <ElementStatusCombined
            type={EwpElementTypes.key_action}
            data={{
              status_history: data.status_history,
              status: data.status,
              approval_status: data.approval_status,
              ewp_status_id: data.ewp_status_id,
              has_approved_parents: data.has_approved_parents,
            }}
          />
        </div>
        <dl>
          <dt>{t('common:column.last-update')}:</dt>
          <dd>{basicDateFormat(data.last_updated)}</dd>
        </dl>
        <dl>
          <dt>{t('common:label.start')}:</dt>
          <dd>{basicDateFormat(data.start_date)}</dd>
          <dt className="pl-3">{t('common:label.end')}:</dt>
          <dd>{basicDateFormat(data.estimated_completion_date)}</dd>
        </dl>
        <dl>
          <dd>{data.description}</dd>
        </dl>
        <dl>
          <dt>{t('common:label.owners')}:</dt>
          <dd>
            <ComaList arr={data.owners.map(item => ({ title: item.name }))} uKey={'owner'} />
          </dd>
        </dl>
        <dl>
          <dt>{t('common:column.type')}:</dt>
          <dd>
            <ComaList
              arr={data.key_types.map(item => ({ title: item.label }))}
              nullText={t('common:column.n-a')}
              uKey={'ka-type'}
            />
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default EwpKaItem;
