import { ActionModel, BelUpdateElmModel } from '../../../models';
import { actionCreator } from '../../actionCreator';
import { BelElmDetailsModel } from '../../../models';
import { LOGOUT_SUCCESS } from '../../authData/authDataActions';

//-----BEL_GET_ELM_DETAILS---//
export const BEL_GET_ELM_DETAILS = 'beloved/BEL_GET_ELM_DETAILS';
export const BEL_GET_ELM_DETAILS_SUCCESS = 'beloved/BEL_GET_ELM_DETAILS_SUCCESS';
export interface BelGetElmDetailsPayload {
  elmId: number;
  callback?: (data: BelElmDetailsModel) => void;
}
export const belGetElmDetails = actionCreator<BelGetElmDetailsPayload>(BEL_GET_ELM_DETAILS);
export const belGetElmDetailsSuccess = actionCreator<BelElmDetailsModel>(BEL_GET_ELM_DETAILS_SUCCESS);

//-----BEL_UPDATE_ELM---//
export const BEL_UPDATE_ELM = 'beloved/BEL_UPDATE_ELM';
export const BEL_UPDATE_ELM_SUCCESS = 'beloved/BEL_UPDATE_ELM_SUCCESS';

export interface BelUpdateElmPayload {
  elmId: number;
  data: BelUpdateElmModel;
  callback?: (data: BelElmDetailsModel) => void;
}

export const belUpdateElm = actionCreator<BelUpdateElmPayload>(BEL_UPDATE_ELM);
export const belUpdateElmSuccess = actionCreator<BelElmDetailsModel>(BEL_UPDATE_ELM_SUCCESS);

export type BelElmActionsTypes =
  | ActionModel<typeof BEL_GET_ELM_DETAILS_SUCCESS, BelElmDetailsModel>
  | ActionModel<typeof BEL_UPDATE_ELM, BelUpdateElmPayload>
  | ActionModel<typeof BEL_UPDATE_ELM_SUCCESS, BelElmDetailsModel>
  | ActionModel<typeof LOGOUT_SUCCESS>;
