export enum ScheduleAlias {
  ea = 'equity_audit_debrief',
  elm = 'elm_debrief',
  eaELM = 'ea_elm_debrief',
  deep = 'deep_dive',
  training = 'training',
  consultation = 'consultation',
}

export enum ScheduleTimeRangeEnum {
  elm_debrief = 30,
  equity_audit_debrief = 60,
  ea_elm_debrief = 90,
  deep_dive = 60,
  training = 60,
  consultation = 60,
}
