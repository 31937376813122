import { Button } from '../../../../../controls';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EwpPreparationStepEnum, IRouteParamsBase } from '../../../../../../models';
import Logo from '../../../../../../assets/images/group.png';
import styles from '../../../HomeOrg/HomeOrg.module.scss';
import { ROOTS } from '../../../../../../routes/routes';
import { useHistory, useParams } from 'react-router';

interface IProps {
  setCurrentStep: (step: EwpPreparationStepEnum) => void;
}

export const PreparationEWPFinish = ({ setCurrentStep }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { orgId } = useParams<IRouteParamsBase>();

  const navToCreate = () => {
    history.push(ROOTS.EWP_CREATE.replace(':orgId', `${orgId}`));
  };

  return (
    <div className={'b-ewp__finish'}>
      <img src={Logo} className={styles.homeContent_ill} alt={t('awa:AT3')} />
      <h1 className={'b-step__bigTitle'}>{t('main:ewp-create.finish.title')}</h1>
      <p className={'paragraph'}>
        {t('main:ewp-create.finish.msg.p1')}
        <b>{t('main:ewp-create.finish.msg.p2')}</b>
        {t('main:ewp-create.finish.msg.p3')}
      </p>
      <div className={`b-step__bottom`}>
        <div className={'flex-center w-100'}>
          <Button
            className={'btn -type_form'}
            type={'orange-light'}
            onPress={navToCreate}
            title={t('common:btn.next')}
            enableEnter
          />
        </div>
      </div>
    </div>
  );
};
