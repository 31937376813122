import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { ScheduleAlias, ScheduleTimeRangeEnum } from '../constants';

export function getTimeRanges(interval: number, language = window.navigator.language) {
  const ranges = [];
  const date = new Date();
  const format: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };

  for (let minutes = 0; minutes < 24 * 60; minutes = minutes + interval) {
    date.setHours(0);
    date.setMinutes(minutes);
    ranges.push({
      label: date.toLocaleTimeString(language, format),
      value: dayjs(date).format('HH:mm:00'),
    });
  }

  return ranges;
}

export const debriefFormat = (date: string) => {
  const userTimezone = dayjs.tz.guess();
  return dayjs(new Date(date))
    .format(`L [at] LT [${moment().tz(userTimezone).zoneAbbr()}]`)
    .replaceAll('/', '.');
};

export const dateWithTime = (date: string) => {
  return dayjs(new Date(date)).format(`L [at] LT`).replaceAll('/', '.');
};

export const getZoneAbbr = (date: string | Date) => {
  if (!date) {
    return '';
  }
  const userTimezone = dayjs.tz.guess();
  return dayjs(new Date(date)).format(`[${moment().tz(userTimezone).zoneAbbr()}]`);
};

export const basicDateFormat = (date: string | Date) => {
  return dayjs(new Date(date)).format(`L`).replaceAll('/', '.');
};

export const getBasicDateFormatCode = () => {
  const locale = navigator.language.split('-')[0];
  return dayjs.Ls[locale].formats.L;
};

export const getBasicDateHourFormatCode = () => {
  const locale = navigator.language.split('-')[0];
  return `${dayjs.Ls[locale].formats.L} 'at' ${dayjs.Ls[locale].formats.LT}`;
};

export const getBasicDateFormatCodeForDatePicker = () => {
  let code = getBasicDateFormatCode()?.toLocaleLowerCase();
  return (code || '').replace('mm', 'MM').replaceAll('/', '.');
};

export const getBasicDateHourFormatCodeForDatePicker = () => {
  let code = getBasicDateHourFormatCode()?.toLocaleLowerCase();
  return (code || '').replace('mm', 'MM').replaceAll('/', '.');
};

export const getBasicDateHourFormatCodeTzForDatePicker = () => {
  const userTimezone = dayjs.tz.guess();
  let code = `${getBasicDateHourFormatCode()}`.toLocaleLowerCase();
  code = `${code} '${moment().tz(userTimezone).zoneAbbr()}'`;
  return (code || '').replace('mm', 'MM').replaceAll('/', '.');
};

export const getCurrentYear = () => {
  return dayjs().format('YYYY');
};

export const getLocaleUtcDateTime = (date: string) => {
  // use only for dates from server in utc (2022-04-27T13:27:17.113213Z)
  return dayjs(new Date(date)).format(`L LT`).replaceAll('/', '.');
};

export const convertDateToIsoWithTzOffset = (date: Date) => {
  return dayjs(date)
    .add(date.getTimezoneOffset() * -1, 'm')
    .toISOString();
};

export const convertDateToIso = (date: Date) => {
  return dayjs(date).toISOString();
};

export const getDebriefDateTime = (date: string | Date, alias: ScheduleAlias) => {
  return `${basicDateFormat(date)} - ${dayjs(date).format('h:mma')} - ${dayjs(date)
    .add(ScheduleTimeRangeEnum[alias], 'm')
    .format(`hh[:]mma ${getZoneAbbr(date)}`)}`;
};
