import React, { useEffect, useState } from 'react';
import ProfileView from './ProfileView';
import { ISelectOptions } from '../../../../models';
import { validate } from '../../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IFields } from '../Profile/CreateProfileContainer';
import { IRoleFields } from '../YourRole/RoleContainer';
import {
  createEducation,
  createEthnicity,
  createGender,
  createPronouns,
  createRace,
} from '../../../../store/utils/utilsActions';
import { modalNames } from '../../../../constants';
import { useTranslation } from 'react-i18next';

interface IProps {
  fields: IFields;
  onChange: (field: string) => (value: string | IRoleFields | IProfileFields | any) => void;
}

export interface IProfileFields {
  language: ISelectOptions | null;
  gender: ISelectOptions[] | null;
  pronouns?: ISelectOptions | null;
  race: ISelectOptions[] | null;
  ethnicity: ISelectOptions[] | null;
  education: ISelectOptions | null;
}

const initProfileFields: IProfileFields = {
  language: null,
  gender: [],
  pronouns: null,
  race: [],
  ethnicity: [],
  education: null,
};

const validatorLanguage = {
  required: ['language'],
};

const validatorGender = {
  required: ['gender'],
};

const validatorRace = {
  required: ['race'],
};

const validatorEthnicity = {
  required: ['ethnicity'],
};

const validatorPronouns = {
  required: ['pronouns'],
};

const validatorEducation = {
  required: ['education'],
};

const ProfileContainer = ({ fields, onChange }: IProps) => {
  const validator = {
    required: ['language', 'gender', 'race', 'ethnicity', 'pronouns', 'education'],
  };
  const history = useHistory();
  const dispatch = useDispatch();
  let { path } = useRouteMatch();
  const languagesList = useSelector((state: StateType) => state.utils.data.languages);
  const gendersList = useSelector((state: StateType) => state.utils.data.genders);
  const pronounsList = useSelector((state: StateType) => state.utils.data.pronounsTypes);
  const racesList = useSelector((state: StateType) => state.utils.data.racesTypes);
  const ethnicityList = useSelector((state: StateType) => state.utils.data.ethnicityTypes);
  const educationsList = useSelector((state: StateType) => state.utils.data.educationsType);
  const [error, setErrors] = useState<any>({});
  const [profileFields, setProfileFields] = useState<IProfileFields>(initProfileFields);
  const [modal, setModal] = useState<string | false>(false);
  const [newField, setNewField] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    setProfileFields({
      language: languagesList.find(f => f.value === fields.language) || null,
      gender: gendersList.filter(f => fields.gender && fields.gender.includes(f.value)),
      pronouns: pronounsList.find(f => f.value === fields.pronouns_id) || null,
      race: racesList.filter(f => fields.race && fields.race.includes(f.value)),
      ethnicity: ethnicityList.filter(f => fields.ethnicity && fields.ethnicity.includes(f.value)),
      education: educationsList.find(f => f.value === fields.education) || null,
    });
  }, []);

  useEffect(() => {
    setErrors({});
  }, [profileFields]);

  const handleSubmit = () => {
    let { errors } = validate(validator, profileFields);
    setErrors(errors);
    if (!Object.keys(errors || {}).length) {
      onChange('profile')({
        race: profileFields.race?.map(r => r.value),
        pronouns_id: profileFields.pronouns?.value,
        gender: profileFields.gender?.map(r => r.value),
        ethnicity: profileFields.ethnicity?.map(r => r.value),
        language: profileFields.language?.value,
        education: profileFields.education?.value,
      });
      history.push(`${path.replace(':step', 'address')}`);
    }
  };

  const handleOnBlur = (field: string) => () => {
    let errorCase;
    switch (field) {
      case 'language':
        errorCase = validate(validatorLanguage, profileFields);
        break;
      case 'gender':
        errorCase = validate(validatorGender, profileFields);
        break;
      case 'race':
        errorCase = validate(validatorRace, profileFields);
        break;
      case 'ethnicity':
        errorCase = validate(validatorEthnicity, profileFields);
        break;
      case 'pronouns':
        errorCase = validate(validatorPronouns, profileFields);
        break;
      case 'education':
        errorCase = validate(validatorEducation, profileFields);
        break;
      default:
        errorCase = { errors: error };
        break;
    }
    setErrors(errorCase.errors);
  };

  const handleOnChange = (field: string) => (value: { value: string; label: string }) => {
    if (
      value &&
      ((Array.isArray(value) && value?.find(f => f.value === 'create')) || value.value === 'create')
    ) {
      setModal(field);
      return;
    }
    setProfileFields({ ...profileFields, [field]: value });
  };

  const closeModal = () => {
    setModal(false);
    if (error.newField) {
      delete error.newField;
    }
    setErrors(error);
    setNewField('');
  };

  const handleChangeNewField = (value: string) => {
    setNewField(value);
    if (error.newField) {
      delete error.newField;
    }
    setErrors(() => ({
      ...error,
    }));
  };

  const checkUniqSelfDescribe = (list: ISelectOptions[]): boolean => {
    if (list.map(m => m.label).includes(newField)) {
      setErrors((prevState: any) => ({
        ...prevState,
        // @ts-ignore
        newField: `${modalNames()[modal]} ${t('common:errors.already-exist')}`,
      }));
      return false;
    }
    return true;
  };

  const handleCreateField = () => {
    if (!newField.trim().length) {
      setErrors((prevState: any) => ({
        ...prevState,
        // @ts-ignore
        newField: `${modalNames()[modal]} ${t('common:errors.required')}`,
      }));
      return;
    }

    const multiRequest = {
      callback: (res: ISelectOptions) => {
        // @ts-ignore
        let values = profileFields[modal] || [];
        values.push(res);
        setProfileFields({ ...profileFields, [modal as string]: values });
        closeModal();
      },
      data: {
        name: newField,
      },
    };

    const singleRequest = {
      callback: (res: any) => {
        setProfileFields({ ...profileFields, [modal as string]: res });
        closeModal();
      },
      data: {
        name: newField,
      },
    };

    if (modal === 'gender') {
      checkUniqSelfDescribe(gendersList as ISelectOptions[]) && dispatch(createGender(multiRequest));
    } else if (modal === 'race') {
      checkUniqSelfDescribe(racesList as ISelectOptions[]) && dispatch(createRace(multiRequest));
    } else if (modal === 'ethnicity') {
      checkUniqSelfDescribe(ethnicityList as ISelectOptions[]) && dispatch(createEthnicity(multiRequest));
    } else if (modal === 'education') {
      checkUniqSelfDescribe(educationsList as ISelectOptions[]) && dispatch(createEducation(singleRequest));
    } else if (modal === 'pronouns') {
      checkUniqSelfDescribe(pronounsList as ISelectOptions[]) && dispatch(createPronouns(singleRequest));
    }
  };

  return (
    <ProfileView
      onSubmit={handleSubmit}
      handleOnBlur={handleOnBlur}
      languages={languagesList}
      genders={gendersList}
      pronouns={pronounsList}
      races={racesList}
      ethnicity={ethnicityList}
      educations={educationsList}
      fields={profileFields}
      onChange={handleOnChange}
      modal={modal}
      closeModal={closeModal}
      newField={newField}
      handleChangeNewField={handleChangeNewField}
      handleCreateField={handleCreateField}
      errors={error}
    />
  );
};

export default ProfileContainer;
