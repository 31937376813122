import React, { FC } from 'react';
import { t } from 'i18next';
import { CohortStatuses } from '../../../constants';

interface IProps {
  status: CohortStatuses;
}

const CohortStatusLabel: FC<IProps> = ({ status }) => {
  switch (status) {
    case CohortStatuses.LAUNCH:
      return (
        <div className="b-status -inProgress">
          <b>{t('common:status.launched')}</b>
        </div>
      );
    case CohortStatuses.IN_PREPARATION:
      return (
        <div className="b-status -inPrep">
          <b>{t('common:status.in-preparation')}</b>
        </div>
      );
    case CohortStatuses.IN_PROGRESS:
      return (
        <div className="b-status -completed">
          <b>{t('common:status.in-progress')}</b>
        </div>
      );
    case CohortStatuses.COMPLETE:
      return (
        <div className="b-status -prepCompl">
          <b>{t('common:status.completed')}</b>
        </div>
      );
    default:
      return null;
  }
};

export default CohortStatusLabel;
