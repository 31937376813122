import {
  BEL_AVAILABLE_RESOURCE_SUCCESS,
  BEL_GET_COHORT,
  BEL_GET_COHORT_PARTICIPANT_SUCCESS,
  BEL_GET_COHORT_SUCCESS,
  BEL_RESET_COHORT,
  BEL_SET_GENERAL_RESOURCE_FIELDS,
  BEL_SET_SESSION_FIELDS,
  CohortActionsTypes,
  GET_COHORT_ORGANIZATIONS_SUCCESS,
  SET_QUERY,
  SET_SELECTED_COHORT_RESOURCE_IDS,
} from './cohortActions';
import {
  BelCohortModel,
  BelResourceModel,
  BelSessionFormModel,
  CheckedOptionsModel,
  CohortOrganizationModel,
  CohortUserModel,
  PaginationModel,
} from '../../models';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';
import { QueryParamsModel } from '../../hooks/common/use-table-data';

export type BelCohortState = {
  cohortResourceList: PaginationModel<BelResourceModel[]> | null;
  cohortParticipantList: PaginationModel<CohortUserModel[]> | null;
  cohortOrganizationalList: PaginationModel<CohortOrganizationModel[]> | null;
  selectedResourceIds: number[];
  savedSessionField: BelSessionFormModel | null;
  savedGeneralResourceField: CheckedOptionsModel[] | null;
  currentCohort: BelCohortModel | null;
  query: QueryParamsModel;
};

const initialState: BelCohortState = {
  cohortResourceList: null,
  cohortParticipantList: null,
  cohortOrganizationalList: null,
  selectedResourceIds: [],
  savedSessionField: null,
  savedGeneralResourceField: null,
  currentCohort: null,
  query: {
    sorting: null,
    search: '',
    page_size: 15,
    page: 1,
  },
};

const cohortReducer = (state: BelCohortState = initialState, action: CohortActionsTypes): BelCohortState => {
  switch (action.type) {
    case BEL_AVAILABLE_RESOURCE_SUCCESS: {
      return {
        ...state,
        cohortResourceList: action.payload,
        query: {
          ...state.query,
          page: action.payload.current_page,
        },
      };
    }
    case BEL_RESET_COHORT: {
      return {
        ...state,
        currentCohort: null,
      };
    }
    case BEL_GET_COHORT_SUCCESS: {
      return {
        ...state,
        currentCohort: action.payload,
      };
    }
    case BEL_GET_COHORT_PARTICIPANT_SUCCESS: {
      return {
        ...state,
        cohortParticipantList: action.payload,
        query: {
          ...state.query,
          page: action.payload.current_page,
        },
      };
    }
    case GET_COHORT_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        cohortOrganizationalList: action.payload,
        query: {
          ...state.query,
          page: action.payload.current_page,
        },
      };
    }
    case BEL_SET_SESSION_FIELDS: {
      return {
        ...state,
        savedSessionField: action.payload.data,
      };
    }
    case BEL_SET_GENERAL_RESOURCE_FIELDS: {
      return {
        ...state,
        savedGeneralResourceField: action.payload.data,
      };
    }
    case SET_SELECTED_COHORT_RESOURCE_IDS: {
      return {
        ...state,
        selectedResourceIds: action.payload,
      };
    }
    case SET_QUERY: {
      return {
        ...state,
        query: action.payload,
      };
    }
    case LOGOUT_SUCCESS:
      return { ...initialState };
    default:
      return state;
  }
};

export default cohortReducer;
