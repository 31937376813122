import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../store/reducers';
import { getElementsFilterOptions } from '../../store/ewp/ewpActions';

export const useFilterOptions = (orgId?: number) => {
  const filterOptions = useSelector((state: StateType) => state.ewp.ewpElementsFilterOptions);
  const dispatch = useDispatch();
  useEffect(() => {
    if (orgId && !filterOptions) {
      dispatch(getElementsFilterOptions({ orgId }));
    }
  }, [orgId]);

  return {
    filterOptions,
  };
};
