import React from 'react';
import { Footer, Header } from '../../../common';
import { Button, Image } from '../../../controls';
import Logo from '../../../../assets/images/icons/group.png';
import styles from '../AssociationOrganizationPreview/AssociationOrganizationPreviewStyle.module.scss';
import { useHistory } from 'react-router-dom';
import { ROOTS } from '../../../../routes/routes';
import { useTranslation } from 'react-i18next';

const RequestSubmittedContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onNext = () => {
    history.push(ROOTS.REQUEST_SUBMIT_PENDING);
  };

  return (
    <>
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        <main className={'white-container content-center-wrapper'}>
          <div className={'container-page-center'}>
            <div className={'container-row'}>
              <Image width={'190px'} height={'190px'} alt={t('awa:AT37')} source={Logo} />
            </div>
            <div className={'container-row'}>
              <span className={`sub-title ${styles.sub_title}`}>{t('awa:M6.msg.p1')}</span>
            </div>
            <div className={'container-row'}>
              <span className={`sub-title ${styles.sub_title}`}>{t('awa:M6.msg.p2')}</span>
            </div>
            <div className={'container-row'}>
              <Button
                type={'orange-light'}
                onPress={onNext}
                title={t('common:btn.next')}
                className={styles.button}
                enableEnter
              />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default RequestSubmittedContainer;
