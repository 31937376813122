import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GenericNoteModel, ISelectOptions } from '../../../../../models';
import { AsyncSelect, Button, Select } from '../../../../controls';
import { GenericNotes, Modal } from '../../../../common';
import { StateType } from '../../../../../store/reducers';
import { createCredit, getCreditsOptions } from '../../../../../store/credit/creditActions';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { getBasicDateFormatCodeForDatePicker } from '../../../../../helpers';
import useCredits from '../../../../../hooks/beloved/credits/use-credits';
import { toast } from 'react-toastify';
import InputMask from '../../../../controls/Inputs/InputMask';

type FormType = {
  type: ISelectOptions;
  method: ISelectOptions;
  org: ISelectOptions | null;
  fee?: string;
  payment_date?: string | null;
} | null;

interface IProps {
  setCreateModalOpen: (status: boolean) => void;
  selectCustomOrgId?: boolean;
  onSuccessCreate?: () => void;
}

const mask = [
  { mask: '' },
  {
    mask: '$num',
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        thousandsSeparator: ',',
        padFractionalZeros: true,
        radix: '.',
        mapToRadix: [','],
      },
    },
  },
];

const CreateCredit: FC<IProps> = ({ setCreateModalOpen, selectCustomOrgId, onSuccessCreate }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const currentBelOrg = useSelector((state: StateType) => state.belOrganizations.currentBelOrg);
  const creditOptions = useSelector((state: StateType) => state.credit.creditOptions);
  let [form, setForm] = useState<FormType>(null);
  let [notesList, setNotesList] = useState<GenericNoteModel[] | null>(null);
  const [organizationSelected, setOrganizationSelected] = useState<ISelectOptions<number> | null>();
  const [defaultOrganizationOptions, setDefaultOrganizationOptions] = useState<ISelectOptions[]>([]);
  const { getOrganizations } = useCredits();

  useEffect(() => {
    if (!creditOptions?.credit_types) {
      dispatch(getCreditsOptions());
    }
  }, []);

  useEffect(() => {
    if (creditOptions && !form) {
      setForm({
        type: creditOptions.credit_types[0],
        method: creditOptions.acquisition_method[0],
        fee: '',
        payment_date: null,
        org: null,
      });
    }
  }, [creditOptions]);

  const handleCancelForm = () => {
    setCreateModalOpen(false);
  };

  const handleChangeForm = (field: string) => (val: ISelectOptions | string) => {
    if (form) {
      setForm({ ...form, [field]: val });
    }
  };

  const handleCreateCredit = () => {
    if (!form) return null;
    const body = {
      organization_id: (organizationSelected && organizationSelected.value) || (currentBelOrg?.id as number),
      acquisition_method_id: form.method.value as number,
      credit_type_id: form.type.value as number,
      fee: form.fee?.replace('$', '') || undefined,
      payment_date: (form.payment_date && new Date(form.payment_date)) || undefined,
      notes: notesList?.map(n => n.body) || [],
    };

    dispatch(
      createCredit({
        data: body,
        callback: () => {
          toast.success(t('common:toast.credit-added'));
          setCreateModalOpen(false);
          onSuccessCreate && onSuccessCreate();
        },
      }),
    );
  };

  const handleChangeDate = (newDate: Date | null) => {
    if (form && newDate) {
      setForm({
        ...form,
        payment_date: newDate.toString(),
      });
    }
  };

  const onChangeNoteList = (notes: GenericNoteModel[]) => {
    setNotesList(notes);
  };

  const loadOrganizationOptions = (inputValue?: string, callback?: (options: ISelectOptions[]) => void) => {
    getOrganizations(inputValue || '', data => {
      setDefaultOrganizationOptions(data);
      callback && callback(data);
    });
  };

  return (
    <>
      <Modal
        width={650}
        className="b-credits__formModal"
        title={t('common:headlines.beloved-credit')}
        onClose={handleCancelForm}
      >
        <div className="b-credits__form">
          {creditOptions && form ? (
            <>
              <div className="b-credits__formContainer">
                {selectCustomOrgId ? (
                  <AsyncSelect
                    wrapperStyles={{ width: '100%' }}
                    placeholder={t('common:label.organization')}
                    handleChange={value => {
                      setOrganizationSelected(value);
                      loadOrganizationOptions();
                    }}
                    value={organizationSelected}
                    defaultOptions={defaultOrganizationOptions}
                    isSearchable
                    onMenuOpen={loadOrganizationOptions}
                    loadOptions={(inputValue, callback) => loadOrganizationOptions(inputValue, callback)}
                    ariaLabel={`credit-organization`}
                  />
                ) : null}
                <Select
                  wrapperStyles={{ width: '100%' }}
                  placeholder={t('main:credits.credit-type')}
                  handleChange={handleChangeForm('type')}
                  value={form.type}
                  options={creditOptions.credit_types}
                  ariaLabel="Type"
                />
                <Select
                  wrapperStyles={{ width: '100%' }}
                  placeholder={t('main:credits.acquisition-method')}
                  handleChange={handleChangeForm('method')}
                  value={form.method}
                  options={creditOptions.acquisition_method}
                  ariaLabel="Method"
                />
                <div className="b-credits__formContainer-item mb-4">
                  <div className="b-credits__formContainer-title">{t('common:label.fee')}:</div>
                  <InputMask
                    className="b-credits__formContainer-input"
                    name={'fee'}
                    value={form.fee || ''}
                    ariaLabel={t('common:label.fee')}
                    placeholder={t('common:label.amount-paid-client')}
                    handleChange={handleChangeForm('fee')}
                    hideErrorBlock
                    mask={mask}
                  />
                </div>
                <div className="b-credits__formContainer-item datepicker-no-panel mb-4">
                  <div className="b-credits__formContainer-title">{t('common:label.payment-date')}:</div>
                  <DatePicker
                    dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                    selected={(form.payment_date && new Date(form.payment_date)) || null}
                    onChange={date => handleChangeDate(date)}
                    ariaLabelledBy="payment_date"
                    placeholderText={t('common:label.date-payment-received')}
                  />
                </div>
              </div>
              <div className="b-credits__notes mb-4">
                <GenericNotes
                  renderLink={() => (
                    <div className="d-flex align-items-center ">
                      <div className="b-button-icon -colorNavy -noHover -link_res b-ewpElementsTree__icon" />
                      <div className="font-s ml-2 mr-3 font-grey">{t('common:btn.notes')}</div>
                    </div>
                  )}
                  isAllowToCreate={true}
                  entityType={'credit'}
                  entityId={null}
                  onChangeNoteList={onChangeNoteList}
                />
              </div>
              <div className="b-credits__formBtns">
                <Button
                  className="button-mh"
                  size={'large'}
                  type={'transparency'}
                  title={t('common:btn.cancel')}
                  onPress={handleCancelForm}
                />
                <Button
                  className="button-mh"
                  size={'large'}
                  title={t('common:btn.add-credit')}
                  onPress={handleCreateCredit}
                />
              </div>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

export default CreateCredit;
