import * as actionTypes from './resourcesActions';
import {
  BelResourceOptionsModel,
  BelResourceShortModel,
  LinkResourceSelectedItemModel,
  PaginationModel,
} from '../../../models';
import { LOGOUT_SUCCESS } from '../../authData/authDataActions';
import { QueryParamsModel } from '../../../hooks/common/use-table-data';

export type BelResourceState = {
  options: BelResourceOptionsModel | null;
  resourceList: PaginationModel<BelResourceShortModel[]> | null;
  resourcesQuery: QueryParamsModel;
  savedResources: {
    resources: LinkResourceSelectedItemModel[];
    uniqId: string | null;
  };
  linkResource: {
    linkResourceList: PaginationModel<BelResourceShortModel[]> | null;
    linkResourceQuery: QueryParamsModel;
    selectedLinkResource: LinkResourceSelectedItemModel[];
  };
};

const initialState: BelResourceState = {
  options: null,
  resourceList: null,
  resourcesQuery: {
    sorting: null,
    search: '',
    page_size: 15,
    page: 1,
    filters: {},
    dateFilters: {},
  },
  savedResources: {
    resources: [],
    uniqId: null,
  },
  linkResource: {
    linkResourceList: null,
    linkResourceQuery: {
      sorting: null,
      search: '',
      page_size: 15,
      page: 1,
      filters: {},
      dateFilters: {},
    },
    selectedLinkResource: [],
  },
};

const belResourceReducer = (
  state: BelResourceState = initialState,
  action: actionTypes.BelResourceActionsTypes,
): BelResourceState => {
  switch (action.type) {
    case actionTypes.BEL_GET_RESOURCE_OPTIONS_SUCCESS:
      return {
        ...state,
        options: action.payload,
      };
    case actionTypes.BEL_GET_RESOURCE_LIST_SUCCESS: {
      return {
        ...state,
        resourceList: action.payload,
        resourcesQuery: {
          ...state.resourcesQuery,
          page: action.payload.current_page,
        },
      };
    }
    case actionTypes.SET_RESOURCES_QUERY:
      return {
        ...state,
        resourcesQuery: action.payload,
      };
    case actionTypes.SAVE_TEMP_RESOURCE_LIST: {
      return {
        ...state,
        savedResources: action.payload,
      };
    }
    case actionTypes.GET_LINK_RESOURCE_LIST_SUCCESS: {
      return {
        ...state,
        linkResource: {
          ...state.linkResource,
          linkResourceList: action.payload,
        },
      };
    }
    case actionTypes.SET_LINK_RESOURCE_LIST_QUERY: {
      return {
        ...state,
        linkResource: {
          ...state.linkResource,
          linkResourceQuery: action.payload,
        },
      };
    }
    case actionTypes.SET_SELECTED_LINK_RESOURCE: {
      return {
        ...state,
        linkResource: {
          ...state.linkResource,
          selectedLinkResource: action.payload,
        },
      };
    }
    case LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default belResourceReducer;
