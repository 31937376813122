import React from 'react';
import styles from './CreateOrganizationExistingStyle.module.scss';
import { ISelectOptions } from '../../../../models';
import OpenedSelect from '../../../controls/OpenedSelect/OpenedSelect';
import { ROOTS } from '../../../../routes/routes';
import { Button } from '../../../controls';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClose: () => void;
}

const options: ISelectOptions[] = [
  {
    label: 'A. Request to Join the Organization',
    value: ROOTS.ORGANIZATION_PREVIEW_JOIN,
  },
  {
    label: 'B. Claim Organization Account',
    value: ROOTS.ORGANIZATION_PREVIEW_JOIN,
  },
  {
    label: 'C. Create a New Organization Account',
    value: ROOTS.ORGANIZATION_PREVIEW_CREATE,
  },
];

const CreateOrganizationExisting = ({ onClose }: IProps) => {
  const { t } = useTranslation();

  const history = useHistory();

  const selectedButton = (url: string) => {
    history.push(url);
    onClose();
  };

  const onBack = () => {
    history.push(ROOTS.ORGANIZATION_PREVIEW_CREATE);
    onClose();
  };

  return (
    <>
      <div className={`white-container ${styles.container}`}>
        <div className={'container-page-center'}>
          <div className={'container-row m-0'}>
            <span className={`b-title ${styles.title}`}>{t('common:headlines.your-organization')}</span>
          </div>
          <div className={'container-row m-0'}>
            <span className={`sub-title ${styles.sub_title}`}>{t('auth:organization-exist')}</span>
          </div>
          <OpenedSelect values={options} onClick={selectedButton} />
          <div className={'container-row m-0'}>
            <Button
              onPress={onBack}
              title={t('common:btn.cancel')}
              type={'transparency'}
              disabled={false}
              size={'small'}
              width={'50%'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrganizationExisting;
