import React, { useState, FC } from 'react';
import { Button, Loader, Select } from '../../../../../controls';
import { IRouteParamsBase, ISelectOptions } from '../../../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { createEA, updateEA } from '../../../../../../store/equityAudit/equityAuditActions';
import { useParams, useHistory } from 'react-router-dom';
import { ROOTS } from '../../../../../../routes/routes';
import { StateType } from '../../../../../../store/reducers';
import { useTranslation } from 'react-i18next';

interface IProps {
  orgList: ISelectOptions[];
}

interface IState {
  org: ISelectOptions;
  isLoading: boolean;
}

const CreateEAStep1: FC<IProps> = ({ orgList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history: any = useHistory();
  const createEAData = useSelector((state: StateType) => state.equityAudit.createEAData);

  const { orgId } = useParams<IRouteParamsBase>();

  const initialState = {
    isLoading: false,
    org: orgList.find(item => item.value === Number(orgId)) || orgList[0],
  };

  let [state, setState] = useState<IState>(initialState);

  const handleChangeOrg = (value: ISelectOptions) => {
    history.replace(ROOTS.CREATE_EA.replace(':orgId', String(value.value)));
    setState({ ...state, org: value });
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleNext = () => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    if (!createEAData) {
      dispatch(
        createEA({
          orgId: Number(state.org.value),
          callback: () => {
            setState(prevState => ({ ...prevState, isLoading: false }));
          },
        }),
      );
    } else {
      dispatch(
        updateEA({
          eaId: createEAData.id,
          data: {
            ea_step: 'set_ea_team',
            organization_id: Number(state.org.value),
          },
          callback: () => {
            setState(prevState => ({ ...prevState, isLoading: true }));
          },
        }),
      );
    }
  };

  return (
    <>
      {state.isLoading && <Loader fitParent />}
      <h1 className={'pageContainer__title b-EATypo__pageTitle'}>{t('common:headlines.new-ea')}</h1>
      <div>
        <Select
          name={'organization'}
          value={state.org}
          placeholder={t('common:label.select-organization')}
          ariaLabel="Select Organization"
          options={orgList}
          error={''}
          handleChange={handleChangeOrg}
          // customClasses={styles.select}
          height={52}
          autoFocus
        />
        <div className="b-EACreate__2btnsWrapper">
          <Button
            className={'btn -type_form'}
            type={'transparency'}
            onPress={handleCancel}
            title={t('common:btn.cancel')}
          />
          <Button
            className={'btn -type_form'}
            type={'orange-light'}
            onPress={handleNext}
            title={t('common:btn.next')}
            enableEnter
          />
        </div>
      </div>
    </>
  );
};

export default CreateEAStep1;
