import React, { useState } from 'react';
import { ElmGuide, HeadlineOrganization, OrgBreadcrumbs } from '../../../../common';
import { Button, Loader } from '../../../../controls';
import { IRouteParamsBase, OrganizationDetailsModel } from '../../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { createElm } from '../../../../../store/elm/elmActions';
import { useHistory, useParams } from 'react-router';
import { ROOTS } from '../../../../../routes/routes';
import { useTranslation } from 'react-i18next';

const CreateElmIntro = () => {
  const { t } = useTranslation();
  const currentOrg: OrganizationDetailsModel | null = useSelector(
    (state: StateType) => state.organization.currentOrg,
  );
  const { orgId } = useParams<IRouteParamsBase>();
  const dispatch = useDispatch();
  const history = useHistory();
  let [loading, setLoading] = useState(false);
  let [guideVisible, setGuideVisible] = useState(false);

  const handleNext = () => {
    setLoading(true);
    dispatch(
      createElm({
        callback: () => {
          history.push(ROOTS.CREATE_ELM.replace(':orgId', orgId));
        },
      }),
    );
  };

  return (
    <>
      <div className={'pageContainer pageContainer__content b-elm'}>
        {loading && <Loader fitParent />}
        <HeadlineOrganization
          orgName={currentOrg?.name || ''}
          orgId={currentOrg?.id || ''}
          headline={t('common:headlines.equity-lens-map')}
          customClasses={'-desktop'}
        />
        <div className="pageContainer__title b-equityAudit__pageTitle -mobile">
          {t('common:headlines.equity-lens-map')}
        </div>
        <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
        <div>
          <div className="b-elmBox">
            <h2 className="b-elmBox__title">{t('awa:M70.title')}</h2>
            <p>{t('awa:M70.msg.p1')}</p>
            <h3 className="b-elmBox__subTitle">{t('awa:M70.msg.p2')}</h3>
            <p>{t('awa:M70.msg.p3')} </p>
            <h3 className="b-elmBox__subTitle">{t('awa:M70.msg.p4')}</h3>
            <p>{t('awa:M70.msg.p5')}</p>

            <div className="b-elmTable">
              <table>
                <thead>
                  <tr>
                    <th>{t('main:elm-create.number-employees')}</th>
                    <th>{t('main:elm-create.administration-phase')}</th>
                    <th>{t('main:elm-create.sample-timeline')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th rowSpan={2}>{t('main:elm-create.less-10')}</th>
                    <td>
                      {t('main:elm-create.leadership-administration')} &amp;{' '}
                      {t('main:elm-create.leadership-experience-debrief')}
                    </td>
                    <td>{t('main:elm-create.week')} 1</td>
                  </tr>
                  <tr>
                    <td>{t('main:elm-create.full-staff-administration')}</td>
                    <td>{t('main:elm-create.week')} 2</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th rowSpan={3}>11-49</th>
                    <td>{t('main:elm-create.leadership-administration')}</td>
                    <td>{t('main:elm-create.week')} 1</td>
                  </tr>
                  <tr>
                    <td>{t('main:elm-create.leadership-experience-debrief')}</td>
                    <td>{t('main:elm-create.week')} 2</td>
                  </tr>
                  <tr>
                    <td>{t('main:elm-create.full-staff-administration')}</td>
                    <td>{t('main:elm-create.week')} 3-4</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th rowSpan={3}>{t('main:elm-create.more-50')}</th>
                    <td>{t('main:elm-create.leadership-administration')}</td>
                    <td>{t('main:elm-create.week')} 1</td>
                  </tr>
                  <tr>
                    <td>{t('main:elm-create.leadership-experience-debrief')}</td>
                    <td>{t('main:elm-create.week')} 2</td>
                  </tr>
                  <tr>
                    <td>{t('main:elm-create.full-staff-administration')}</td>
                    <td>{t('main:elm-create.week')} 3-5</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <strong>Upload Users</strong>
            <p>{t('main:upload-users.msg.p1')}</p>
            <ul>
              <li>{t('common:label.first-name')}</li>
              <li>{t('common:label.last-name')}</li>
              <li>{t('common:label.email-address')}</li>
            </ul>
            <p>{t('main:upload-users.msg.p2')}</p>
            <p>
              {t('main:upload-users.msg.p3')}&nbsp;
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setGuideVisible(true);
                }}
              >
                {t('main:upload-users.msg.p4')}
              </a>
              .
            </p>
          </div>
          <div className="b-elm__bottomBtns">
            <Button
              className={'btn -type_form b-step__mr8'}
              type={'transparency'}
              onPress={() => history.go(-1)}
              title={t('common:btn.cancel')}
            />
            <Button
              className={'btn -type_form '}
              type={'orange-light'}
              onPress={handleNext}
              title={t('common:btn.next')}
            />
          </div>
        </div>
      </div>
      {guideVisible ? <ElmGuide noBtn onClose={() => setGuideVisible(false)} /> : null}
    </>
  );
};

export default CreateElmIntro;
