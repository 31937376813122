import React from 'react';
import { Footer, Header } from '../../../common';
import { Image } from '../../../controls';
import Logo from '../../../../assets/images/icons/password.png';
import { useTranslation } from 'react-i18next';

const ForgotSubmitPasswordContainer = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        <main className={'white-container content-center-wrapper'}>
          <div className={'container-page-center'}>
            <div className={`text-container container-column`}>
              <div className={'container-row'}>
                <Image width={'190px'} height={'190px'} source={Logo} alt={t('awa:AT25')} />
              </div>
              <div className={'container-row'}>
                <p className={'text-description gray-text'}>{t('awa:M99.msg.p1')}</p>
              </div>
              <div className={'container-row'}>
                <p className={'text-description gray-text'}>{t('awa:M99.msg.p2')}</p>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default ForgotSubmitPasswordContainer;
