import React, { FC, useEffect } from 'react';
import styles from './MyAccountTabs.module.scss';
import { useHistory } from 'react-router-dom';
import { ROOTS } from '../../../routes/routes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StateType } from '../../../store/reducers';

type activeItemAliasType =
  | 'my-profile'
  | 'my-settings'
  | 'terms-conditions'
  | 'privacy-policy'
  | 'support'
  | 'bio';

interface IProps {
  activeItemAlias: activeItemAliasType;
}

const MyAccountTabs: FC<IProps> = ({ activeItemAlias }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const profileRef: React.MutableRefObject<any> = React.useRef(null);
  const bioRef: React.MutableRefObject<any> = React.useRef(null);
  const TCRef: React.MutableRefObject<any> = React.useRef(null);
  const settingsRef: React.MutableRefObject<any> = React.useRef(null);
  const PPRef: React.MutableRefObject<any> = React.useRef(null);
  const supportRef: React.MutableRefObject<any> = React.useRef(null);
  const user = useSelector((state: StateType) => state.profile.authUser);
  const isBeloved = user?.beloved_user;

  useEffect(() => {
    switch (activeItemAlias) {
      case 'my-profile':
        profileRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
        break;
      case 'bio':
        bioRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
        break;
      case 'my-settings':
        settingsRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
        break;
      case 'terms-conditions': {
        TCRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
        break;
      }
      case 'privacy-policy':
        PPRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
        break;
      case 'support': {
        supportRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
        break;
      }
    }
  }, []);

  const handleClick = (alias: activeItemAliasType) => (e: any) => {
    e.preventDefault();
    switch (alias) {
      case 'my-profile':
        history.push(ROOTS.MY_PROFILE);
        break;
      case 'bio':
        history.push(ROOTS.BELOVED_MY_BIO);
        break;
      case 'my-settings':
        history.push(ROOTS.MY_SETTINGS);
        break;
      case 'terms-conditions': {
        history.push(ROOTS.TERMS_CONDITIONS);
        break;
      }
      case 'privacy-policy':
        history.push(ROOTS.PRIVACY_POLICY);
        break;
      case 'support': {
        // @ts-ignore
        FreshworksWidget('open');
        break;
      }
    }
  };

  return (
    <div className={styles.tabs}>
      <a
        href="#"
        className={`${styles.tabs__item} ${activeItemAlias === 'my-profile' ? styles.tabs__item_active : ''}`}
        onClick={handleClick('my-profile')}
        ref={profileRef}
      >
        <div className={`${styles.tabs__icon} ${styles.tabs__icon_profile}`} />
        <span className={styles.tabs__text}>{t('common:tabs.my-profile')}</span>
      </a>
      {isBeloved ? (
        <a
          href="#"
          className={`${styles.tabs__item} ${activeItemAlias === 'bio' ? styles.tabs__item_active : ''}`}
          onClick={handleClick('bio')}
          ref={bioRef}
        >
          <div className={`${styles.tabs__icon} ${styles.tabs__icon_biography}`} />
          <span className={styles.tabs__text}>{t('common:tabs.biography')}</span>
        </a>
      ) : null}
      <a
        href="#"
        className={`${styles.tabs__item} ${
          activeItemAlias === 'my-settings' ? styles.tabs__item_active : ''
        }`}
        onClick={handleClick('my-settings')}
        ref={settingsRef}
      >
        <div className={`${styles.tabs__icon} ${styles.tabs__icon_settings}`} />
        <span className={styles.tabs__text}>{t('common:tabs.settings')}</span>
      </a>
      <a
        href="#"
        className={`${styles.tabs__item} ${
          activeItemAlias === 'terms-conditions' ? styles.tabs__item_active : ''
        }`}
        onClick={handleClick('terms-conditions')}
        ref={TCRef}
      >
        <div className={`${styles.tabs__icon} ${styles.tabs__icon_termsConditions}`} />
        <span className={styles.tabs__text}>{t('common:tabs.terms-conditions')}</span>
      </a>
      <a
        href="#"
        className={`${styles.tabs__item} ${
          activeItemAlias === 'privacy-policy' ? styles.tabs__item_active : ''
        }`}
        onClick={handleClick('privacy-policy')}
        ref={PPRef}
      >
        <div className={`${styles.tabs__icon} ${styles.tabs__icon_privacyPolicy}`} />
        <span className={styles.tabs__text}>{t('common:tabs.privacy-policy')}</span>
      </a>
      <a
        href="#"
        className={`${styles.tabs__item} ${activeItemAlias === 'support' ? styles.tabs__item_active : ''}`}
        onClick={handleClick('support')}
        ref={supportRef}
      >
        <div className={`${styles.tabs__icon} ${styles.tabs__icon_support}`} />
        <span className={styles.tabs__text}>{t('common:tabs.support')}</span>
      </a>
    </div>
  );
};

export default MyAccountTabs;
