import {
  AuthDataActionsTypes,
  IS_LOGIN,
  LOGIN,
  LOGOUT_SUCCESS,
  SET_REDIRECT_LOGIN_URL,
  SET_SIGN_UP_FIELDS,
  SetSignUpFieldsPayload,
} from './authDataActions';

export type authDataState = {
  isLoading: boolean;
  isAuthenticated: boolean;
  error: object[];
  redirectAfterLogin: string | null;
  signUpFields: SetSignUpFieldsPayload;
};

const initialState: authDataState = {
  isLoading: false,
  isAuthenticated: false,
  error: [],
  redirectAfterLogin: null,
  signUpFields: {
    username: '',
    first_name: '',
    last_name: '',
  },
};

const userDataReducer = (
  state: authDataState = initialState,
  action: AuthDataActionsTypes,
): authDataState => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case IS_LOGIN: {
      return {
        ...state,
        isAuthenticated: action.payload.data.isAuthenticated,
      };
    }
    case SET_REDIRECT_LOGIN_URL:
      return {
        ...state,
        redirectAfterLogin: action.payload,
      };
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        isAuthenticated: false,
      };
    }
    case SET_SIGN_UP_FIELDS: {
      return {
        ...state,
        signUpFields: action.payload,
      };
    }
    default:
      return state;
  }
};

export default userDataReducer;
