import { Button, Link } from '../../../../../controls';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EwpPreparationStepEnum } from '../../../../../../models';
import { Stepper } from '../../../../../common';

interface IProps {
  setCurrentStep: (step: EwpPreparationStepEnum) => void;
  cancelStep: () => void;
}

export const PreparationEWPStep3 = ({ setCurrentStep, cancelStep }: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="b-step__container -centerContent">
        <div className={'flex-center -column'}>
          <span className={'b-step__subTitle mb-3'}>{t('common:headlines.ewp-preparation')}</span>
          <h1 className={'b-step__bigTitle'}>{t('awa:M128.title')}</h1>
        </div>
        <Stepper totalSteps={4} currentStep={3} />
        <div className="b-step__p mt-4 mb-5">{t('awa:M128.msg.p1')}</div>
        <ul className="b-step__list -dots mb-4">
          <li>{t('awa:M128.msg.p2')}</li>
          <li>{t('awa:M128.msg.p3')}</li>
          <li>{t('awa:M128.msg.p4')}</li>
          <li>{t('awa:M128.msg.p5')}</li>
          <li>{t('awa:M128.msg.p6')}</li>
        </ul>
        <div className="b-step__wBox w-100">
          <div className={'b-step__orangeBold flex-center mb-3'}>{t('main:ewp-create.task')}</div>
          <div className="b-step__task">
            {t('awa:M128.msg.p7')}&nbsp;
            <Link onClick={() => setCurrentStep(EwpPreparationStepEnum.introDetails)} className="b-link">
              {t('awa:M128.msg.p8')}
            </Link>
            <div>{t('awa:M128.msg.p9')}</div>
          </div>
        </div>
        <div className={`b-step__bottom`}>
          <div className={`b-step__bottom-left`}>
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={cancelStep}
              title={t('common:btn.cancel')}
            />
          </div>
          <div className={'flex-center'}>
            <Button
              className={'btn -type_form mr-2'}
              type={'bordered'}
              onPress={() => setCurrentStep(EwpPreparationStepEnum.step2)}
              title={t('common:btn.back')}
            />
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={() => setCurrentStep(EwpPreparationStepEnum.step4)}
              title={t('common:btn.next')}
              enableEnter
            />
          </div>
        </div>
      </div>
    </>
  );
};
