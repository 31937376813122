import React from 'react';
import Illus from '../../../../../../assets/images/icons/illus_next_steps.png';
import { useTranslation } from 'react-i18next';

const MarketingPanel = () => {
  const { t } = useTranslation();

  return (
    <div className="b-nextSteps">
      <img className="b-nextSteps__illustration" width={122} height={122} src={Illus} alt={t('awa:AT25')} />
      <div className="b-nextSteps__holder">
        <span className="b-nextSteps__subtitle">{t('awa:M143.title')}</span>
        <p>{t('awa:M143.msg.p1')}</p>
        <p>{t('awa:M143.msg.p2')}</p>
        <a
          rel="noreferrer"
          target="_blank"
          className="b-nextSteps__linkBtn"
          href="mailto:support@awabybeloved.freshdesk.com?subject=Request for EWP Information&body=Dear Beloved Team,%0d%0aI'm interested in learning about the Equity Work Plan program.  Please, contact me via phone <please add your phone number> or email.%0d%0a%0d%0aThank you!"
        >
          {t('common:btn.contact-beloved')}
        </a>
      </div>
    </div>
  );
};

export default MarketingPanel;
