import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterModalButton from '../../../../common/FilterModalButton/FilterModalButton';
import { useTableDataV2 } from '../../../../../hooks/common/use-table-data-v2';
import { TableDataEntities, MessageModel, BaseNameModel, QueryParamsModel } from '../../../../../models';
import { Checkbox } from '../../../../controls';
import { basicDateFormat, getEntitiesByIds } from '../../../../../helpers';
import { TableDateFilter, SendEmailModal } from '../../../../common';
import { useRowSelection } from 'react-data-grid';
import { DataTable } from '../../../../common/DataTable/DataTable';
import Api from '../../../../../services/api';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router';
import { ROOTS } from '../../../../../routes/routes';
import { showAlert } from '../../../../common/Alert/RemoteAlert';
import { useFilters } from '../../../../../hooks/filters/use-filters';
import useHasAccess from '../../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../../constants';

const MessageList = () => {
  const { t } = useTranslation();
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const { filterOptions } = useFilters();
  const history = useHistory();
  const location = useLocation();
  const orgIdsToAdd: number[] | undefined = location.state?.orgIds;
  const hasAccessToMarketingComRemove = useHasAccess(BelovedPermissions.MARKETING_COMMUNICATION_REMOVE);
  const hasAccessToMarketingComCreate = useHasAccess(BelovedPermissions.MARKETING_COMMUNICATION_CREATE);

  const {
    query,
    fetchData,
    handleSearch,
    handleSelectDateFilter,
    handleSelectFilter,
    handleApplyFilter,
    data,
    filterData,
    isFiltersChanged,
    selectedEntitiesIds,
    setSelectedEntitiesIds,
    handleSelectOne,
  } = useTableDataV2<MessageModel>({
    baseUrl: 'beloved/marketing_communications/',
    entityName: TableDataEntities.BELOVED_MESSAGES,
  });

  useEffect(() => {
    let newQuery: QueryParamsModel = { ...query };
    if (orgIdsToAdd && newQuery.filters) {
      newQuery.filters.is_sent = [false];
    }
    fetchData(newQuery);
  }, [location.key]);

  const handleDuplicate = async () => {
    await Api.post('beloved/marketing_communications/duplicate/', {
      ids: selectedEntitiesIds,
    });
    setSelectedEntitiesIds([]);
    fetchData(query);
  };

  const handleDelete = async () => {
    showAlert({
      title: t('awa:N35.title'),
      text: (
        <>
          <p>{t('awa:N35.msg')}</p>
        </>
      ),
      buttons: {
        left: {
          title: t('common:btn.cancel'),
          type: 'transparency',
        },
        right: {
          title: t('common:btn.yes'),
          onClick: async () => {
            await Api.delete(`beloved/marketing_communications/remove/`, undefined, {
              ids: selectedEntitiesIds,
            });
            toast.success(t('common:toast.message-was-removed'));
            setSelectedEntitiesIds([]);
            fetchData(query);
          },
        },
      },
    });
  };

  const handleSendTest = async (emails: string[]) => {
    const data = {
      ids: selectedEntitiesIds,
      emails,
    };
    await Api.post('beloved/marketing_communications/test_message_bulk/', data);
    setEmailModalOpen(false);
    toast.success(t('awa:S23.msg'));
    setSelectedEntitiesIds([]);
  };

  const addOrgs = async (message: MessageModel) => {
    if (!orgIdsToAdd) return;
    const orgIds = message.organizations.map((item: BaseNameModel) => item.id);
    await Api.patch(`beloved/marketing_communications/${message.id}/`, undefined, {
      organization_ids: [...orgIds, ...orgIdsToAdd],
    });
    window.history.replaceState({}, document.title);
    toast.success(t('awa:S22.msg'));
    history.push(ROOTS.BELOVED_MESSAGE_DETAILS.replace(':id', String(message.id)), { message });
  };

  const onRowClick = (row: MessageModel) => {
    if (orgIdsToAdd) {
      showAlert({
        title: t('awa:N82.title'),
        text: t('awa:N82.msg'),
        buttons: {
          left: {
            type: 'transparency',
            title: t('common:btn.cancel'),
          },
          right: {
            title: t('common:btn.add'),
            onClick: () => addOrgs(row),
          },
        },
      });
    } else {
      history.push(ROOTS.BELOVED_MESSAGE_DETAILS.replace(':id', String(row.id)), { message: row });
    }
  };

  const handleNewMessage = async () => {
    const response = await Api.post('beloved/marketing_communications/', {});
    history.push(ROOTS.BELOVED_MESSAGE_CREATE, {
      messageId: response.id,
    });
  };

  const _deleteDisabled = () => {
    const selectedEntities = getEntitiesByIds(selectedEntitiesIds, data);
    const notSentMessages = selectedEntities.filter(item => !item.is_delivered);
    return !notSentMessages.length;
  };

  return (
    <>
      <main className={'b-page'}>
        <h1 className={'b-page__title'}>
          {t('common:label.marketing-communication')}:&nbsp;
          <span className="mark">{t('common:headlines.messages')}</span>
        </h1>
        <div className="b-page__controls">
          <div className="b-tableToolContainer">
            <button
              disabled={!selectedEntitiesIds.length}
              onClick={handleDuplicate}
              className="b-tableTool -copy -mobView -mr"
            >
              {t('common:btn.duplicate')}
            </button>
            <button
              onClick={() => setEmailModalOpen(true)}
              disabled={!selectedEntitiesIds.length}
              className="b-tableTool -send -mobView -mr"
            >
              {t('common:btn.send-test')}
            </button>
            {hasAccessToMarketingComRemove ? (
              <button
                onClick={handleDelete}
                disabled={_deleteDisabled()}
                className="b-tableTool -delete -mobView -mr"
              >
                {t('common:btn.delete')}
              </button>
            ) : null}
            {filterOptions ? (
              <FilterModalButton
                query={query}
                handleSelectFilter={handleSelectFilter}
                handleApplyFilter={handleApplyFilter}
                filterData={filterData}
                isFiltersChanged={isFiltersChanged}
                filters={filterOptions.marketing_messages}
              />
            ) : null}
            {hasAccessToMarketingComCreate ? (
              <button onClick={handleNewMessage} className="b-tableTool -addPlus -mobView -mr">
                {t('common:headlines.new-message')}
              </button>
            ) : null}
          </div>
          <div className="d-flex align-items-center">
            <div className="b-page__search">
              <form action="#" className="searchForm">
                <input
                  type="search"
                  value={query.search}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder={t('common:label.search')}
                  aria-label="Search field"
                />
                <span className="searchForm__icon" />
              </form>
            </div>
          </div>
        </div>
        <DataTable<MessageModel>
          baseUrl="beloved/marketing_communications/"
          entityName={TableDataEntities.BELOVED_MESSAGES}
          selectedEntitiesIds={selectedEntitiesIds}
          handleSelectOne={handleSelectOne}
          onRowClick={onRowClick}
          cols={[
            {
              key: 'subject',
              name: t('common:label.message-subject'),
              sortable: true,
              editable: false,
              resizable: true,
            },
            {
              key: 'delivery_date_local',
              name: t('common:label.delivery-date'),
              sortable: true,
              editable: false,
              resizable: true,
              formatter(props) {
                return (
                  <>
                    {props.row.delivery_date_local
                      ? basicDateFormat(new Date(props.row.delivery_date_local))
                      : t('common:column.n-a')}
                  </>
                );
              },
              headerRenderer(props) {
                if (query.dateFilters) {
                  return (
                    <TableDateFilter
                      dateFilterId={'delivery_date_local'}
                      selectedFilters={query.dateFilters['delivery_date_local']}
                      periodFieldName={'delivery_date_local_period'}
                      dateRangeFieldName={'delivery_date_local_date_range'}
                      onDateFilterChanged={handleSelectDateFilter}
                      rdgProps={props}
                      fiscalOption={true}
                    />
                  );
                }
              },
            },
            {
              key: 'created_by_name',
              name: t('common:label.author'),
              sortable: true,
              editable: false,
              resizable: true,
            },
            {
              key: 'created_at',
              name: t('common:label.created-on'),
              sortable: true,
              editable: false,
              resizable: true,
              formatter(props) {
                return (
                  <>
                    {props.row.created_at
                      ? basicDateFormat(new Date(props.row.created_at))
                      : t('common:column.n-a')}
                  </>
                );
              },
              headerRenderer(props) {
                if (query.dateFilters) {
                  return (
                    <TableDateFilter
                      dateFilterId={'created_at'}
                      selectedFilters={query.dateFilters['created_at']}
                      periodFieldName={'created_at_period'}
                      dateRangeFieldName={'created_at_date_range'}
                      onDateFilterChanged={handleSelectDateFilter}
                      rdgProps={props}
                      fiscalOption={true}
                    />
                  );
                }
              },
            },
            {
              key: 'updated_by_name',
              name: t('common:column.last-edited-by'),
              sortable: true,
              editable: false,
              resizable: true,
              formatter(props) {
                return <>{props.row.updated_by_id ? props.row.updated_by_name : t('common:column.n-a')}</>;
              },
            },
            {
              key: 'updated_at',
              name: t('common:column.last-edited-on'),
              sortable: true,
              editable: false,
              resizable: true,
              formatter(props) {
                return (
                  <>
                    {props.row.updated_at
                      ? basicDateFormat(new Date(props.row.updated_at))
                      : t('common:column.n-a')}
                  </>
                );
              },
              headerRenderer(props) {
                if (query.dateFilters) {
                  return (
                    <TableDateFilter
                      dateFilterId={'updated_at'}
                      selectedFilters={query.dateFilters['updated_at']}
                      periodFieldName={'updated_at_period'}
                      dateRangeFieldName={'updated_at_date_range'}
                      onDateFilterChanged={handleSelectDateFilter}
                      rdgProps={props}
                      fiscalOption={true}
                    />
                  );
                }
              },
            },
            {
              key: 'tools',
              name: '',
              disableDrag: true,
              width: 40,
              maxWidth: 40,
            },
            {
              key: 'select',
              name: '',
              width: 40,
              maxWidth: 40,
              disableDrag: true,
              formatter: props => {
                const [isRowSelected, onRowSelectionChange] = useRowSelection();

                return (
                  <div className="b-rdgTable__cellCenter">
                    <div className={'checkBox'} onClick={e => e.stopPropagation()}>
                      <Checkbox
                        checked={isRowSelected}
                        onChange={e => {
                          onRowSelectionChange({
                            row: props.row,
                            checked: e.target.checked,
                            isShiftClick: false,
                          });
                        }}
                      />
                    </div>
                  </div>
                );
              },
            },
          ]}
        />
      </main>
      {emailModalOpen ? (
        <SendEmailModal onSubmit={handleSendTest} onClose={() => setEmailModalOpen(false)} />
      ) : null}
    </>
  );
};

export default MessageList;
