import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../../store/reducers';
import useTableData from '../../../../../../hooks/common/use-table-data';
import Modal from '../../../../../common/Modal/Modal';
import { statusValues } from '../../../../../../constants';
import { isMobile } from 'react-device-detect';
import { Button } from '../../../../../controls';
import { TableDataEntities } from '../../../../../../models';

interface IProps {
  onClose: () => void;
  elmId: string;
}

const FilterModal: FC<IProps> = ({ onClose, elmId }) => {
  const { t } = useTranslation();
  const userRoles = useSelector((state: StateType) => state.utils.data.userRoles);
  const { filterData, handleSelectFilter, handleApplyFilter } = useTableData(
    TableDataEntities.ELM_USER_LIST,
    { elmId: Number(elmId) },
  );

  const handleSaveFilter = () => {
    handleApplyFilter();
    onClose();
  };

  return (
    <>
      <Modal
        width={450}
        onClose={onClose}
        className={!isMobile ? 'b-filtersModal__mobileModal' : ''}
        title={t('common:btn.filter')}
        classNameTitle="b-filtersModal__modalTitle"
      >
        <div className="b-filtersModal">
          <div className="b-filtersModal__list">
            <div className="b-filtersModal__title">{t('common:filter.by-role')}</div>
            <ul>
              {userRoles &&
                userRoles.map(o => {
                  const isActive = filterData.roles?.includes(o.id) || false;
                  if ((isMobile && o.id === 12) || o.app_name !== 'organisation') {
                    return null;
                  }
                  return (
                    <li key={o.id}>
                      <a
                        href="#"
                        className={isActive ? '-active' : ''}
                        onClick={event => {
                          event.preventDefault();
                          handleSelectFilter(o.id, 'roles');
                        }}
                      >
                        {o.name}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="b-filtersModal__list">
            <div className="b-filtersModal__title">{t('common:filter.by-status')}</div>
            <ul>
              {statusValues().map(o => {
                const isActive = filterData.statuses?.includes(o.value) || false;
                return (
                  <li key={o.value}>
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        handleSelectFilter(o.value, 'statuses');
                      }}
                      className={isActive ? '-active' : ''}
                    >
                      {o.label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="b-filtersModal__buttons">
          <Button
            onPress={handleSaveFilter}
            title={t('common:btn.filter')}
            size={'large'}
            type={'orange-light'}
          />
        </div>
      </Modal>
    </>
  );
};

export default FilterModal;
