import { useEffect, useState } from 'react';
import Api from '../../services/api';
import { useParams } from 'react-router';
import { IRouteParamsBase, ISelectOptions } from '../../models';

export const useGetKaOptions = () => {
  const { orgId, id } = useParams<IRouteParamsBase>();

  let [keyTypes, setKeyTypes] = useState<ISelectOptions[] | null>(null);
  let [strategies, setStrategies] = useState<ISelectOptions[] | null>(null);
  let [owners, setOwners] = useState<ISelectOptions[] | null>(null);

  useEffect(() => {
    Api.get(`organizations/${orgId}/ewp/${id}/key_actions/get_available_key_types/`).then(res => {
      if (res) {
        setKeyTypes(res);
      }
    });
    Api.get(`organizations/${orgId}/ewp/${id}/key_actions/get_available_strategies/`).then(res => {
      if (res) {
        setStrategies(res);
      }
    });
    fetchOwners();
  }, []);

  const fetchOwners = (callback?: () => void) => {
    Api.get(`organizations/${orgId}/ewp/${id}/key_actions/get_available_owners/`).then(res => {
      if (res) {
        setOwners(res);
        callback && callback();
      }
    });
  };

  return {
    owners,
    keyTypes,
    strategies,
    fetchOwners,
  };
};
