import React, { useEffect, useState } from 'react';
import { CreateOrganizationModel, ApiAddressModel, ISelectOptions } from '../../../../models';
import { CreateOrganizationContainer } from '../../index';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import { useLocation } from 'react-router-dom';

interface IProps {
  loader?: string;
  formData: CreateOrganizationModel;
  handleChangeInput: (name: string, value: string) => void;
  handleChangeSelect: (name: string, value: ISelectOptions) => void;
  types: ISelectOptions[];
  errors: any;
  onBack: () => void;
  onSubmit: () => void;
  handleAddressChange: (name: string, data: ApiAddressModel) => void;
  step: number;
  setStep: (step: number) => void;
}

const CreateSubOrganization = ({ loader }: IProps) => {
  const location = useLocation<any>();
  const user = useSelector((state: StateType) => state.profile.authUser);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [pageTitle, setPageTitle] = useState<any>(null);
  useEffect(() => {
    let { title } = location.state;
    if (user !== null) {
      setCurrentUser(user);
    }
    if (title) {
      setPageTitle(title);
    }
  }, []);

  return (
    <>
      {loader}
      <CreateOrganizationContainer
        title={pageTitle !== null ? pageTitle : (currentUser && currentUser.next_step_description) || ''}
        showSubTitle={pageTitle !== null}
      />
    </>
  );
};

export default CreateSubOrganization;
