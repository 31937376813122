import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ROOTS } from '../../../routes/routes';
import { useTranslation } from 'react-i18next';

interface IProps {
  activeItemAlias: 'map' | 'equity-audit' | 'ELM';
}

const BelovedDashboardTabs: FC<IProps> = ({ activeItemAlias }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = (alias: IProps['activeItemAlias']) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    switch (alias) {
      case 'equity-audit':
        history.push(ROOTS.BELOVED_DASHBOARD_EA);
        break;
      case 'ELM':
        history.push(ROOTS.BELOVED_DASHBOARD_ELM);
        break;
      case 'map':
        history.push(ROOTS.BELOVED_DASHBOARD_MAP);
        break;
    }
  };

  return (
    <div className="b-tabs">
      <a
        href="#"
        className={`b-tabs__item ${activeItemAlias === 'equity-audit' ? 'b-tabs__item_active' : ''}`}
        onClick={handleClick('equity-audit')}
      >
        <div className={`b-tabs__icon b-tabs__icon_equility`} />
        <span className="b-tabs__text">{t('common:tabs.ea')}</span>
      </a>
      <a
        href="#"
        className={`
            b-tabs__item
            ${activeItemAlias === 'ELM' ? 'b-tabs__item_active' : ''}
            `}
        onClick={handleClick('ELM')}
      >
        <div className="b-tabs__icon b-tabs__icon_elm" />
        <span className="b-tabs__text">{t('common:tabs.elm')}</span>
      </a>
      <a
        href="#"
        className={`
            b-tabs__item
            ${activeItemAlias === 'map' ? 'b-tabs__item_active' : ''}
            `}
        onClick={handleClick('map')}
      >
        <div className="b-tabs__icon b-tabs__icon_map" />
        <span className="b-tabs__text">{t('common:tabs.map')}</span>
      </a>
    </div>
  );
};

export default BelovedDashboardTabs;
