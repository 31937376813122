import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import DataGrid from 'react-data-grid';
import { Alert, BelovedAdminToolsTabs, Pagination } from '../../../common';
import { AdminToolOrgUserModel, IsDeactivateBtnDisabledType, TableDataEntities } from '../../../../models';
import { getColumns } from './parts/columns';
import { isMobile } from 'react-device-detect';
import { convertUserStatus, isEmptyObject } from '../../../../helpers';
import { Modals, ModalsModel } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import useTableData from '../../../../hooks/common/use-table-data';
import { useDispatch } from 'react-redux';
import { belAtDeactivateUser, belAtDeleteUser } from '../../../../store/beloved/adminTools/adminToolActions';
import { useHistory } from 'react-router-dom';
import { ROOTS } from '../../../../routes/routes';
import SortModalButton from '../../../common/SortModalButton/SortModalButton';
import { useIsDarkMode } from '../../../../hooks/common/use-is-dark-mode';
import FilterModal from './parts/FilterModal';
import useTableConfig from '../../../../hooks/common/use-table-config';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Api from '../../../../services/api';

interface IState {
  modals: ModalsModel;
}

const AdminToolOrgUsers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isDarkMode } = useIsDarkMode();

  const {
    query,
    data,
    fetchData,
    handleChangePage,
    handleChangePageSize,
    handleSearch,
    handleSort,
    setSortingData,
    sortingData,
    handleChangeDirection,
    handleChangeSortField,
    fetchMoreMobile,
    setSelectedEntitiesIds,
    selectedEntitiesIds,
    handleSelectOne,
    handleSelectOneMob,
    infiniteList,
    filterData,
    handleApplyFilter,
    handleSelectFilter,
  } = useTableData(TableDataEntities.AT_ORG_USERS);

  const { columns, onColumnResize } = useTableConfig<AdminToolOrgUserModel>(
    TableDataEntities.AT_ORG_USERS,
    getColumns(),
  );

  let [removeAlert, setRemoveAlert] = useState<'user' | 'affiliation' | null>(null);

  const initialState: IState = {
    modals: {},
  };

  let [state, setState] = useState<IState>(initialState);

  const manipulateModal = (modalName: string, open: boolean) => {
    setState(prevState => ({
      ...prevState,
      modals: {
        ...state.modals,
        [modalName]: open,
      },
    }));
  };

  const handleClickUser = (user: AdminToolOrgUserModel) => {
    history.push(ROOTS.BELOVED_ADMIN_TOOL_ORG_USER_PROFILE.replace(':id', String(user.id)));
  };

  const handleDeleteAffiliation = () => {
    dispatch(
      belAtDeleteUser({
        usersIds: selectedEntitiesIds,
        isOrgUser: true,
        callback: () => {
          fetchData(query);
          setSelectedEntitiesIds([]);
          setRemoveAlert(null);
        },
      }),
    );
  };

  const handleDeleteUser = () => {
    Api.delete('beloved/admin_tool/organization_users/remove_user_obj/', undefined, {
      team_ids: selectedEntitiesIds,
    }).then(res => {
      if (res) {
        fetchData(query);
        setSelectedEntitiesIds([]);
        setRemoveAlert(null);
      }
    });
  };

  const handleChangeAffiliationStatus = (is_active: boolean) => {
    dispatch(
      belAtDeactivateUser({
        usersIds: selectedEntitiesIds,
        isOrgUser: true,
        is_active,
        callback: () => {
          setSelectedEntitiesIds([]);
          fetchData(query);
        },
      }),
    );
  };

  const handleChangeUserStatus = (is_active: boolean) => {
    Api.put('beloved/admin_tool/organization_users/deactivate_user_obj/', {
      team_ids: selectedEntitiesIds,
      is_active,
    }).then(res => {
      if (res) {
        setSelectedEntitiesIds([]);
        fetchData(query);
      }
    });
  };

  const _isDeactivateBtnDisabled = (type: IsDeactivateBtnDisabledType) => {
    const findTarget = (user: AdminToolOrgUserModel, type: IsDeactivateBtnDisabledType) => {
      if (type === 'activate_account') {
        return user.user_id && user.user_obj_is_active === false && user.user_obj_is_removed === false;
      } else if (type === 'deactivate_account') {
        return user.user_id && user.user_obj_is_active === true && user.user_obj_is_removed == false;
      } else if (type === 'activate_association') {
        return !user.is_active && !user.is_removed;
      } else if (type === 'deactivate_association') {
        return user.is_active && !user.is_removed;
      } else if (type === 'delete_account') {
        return user.user_id && user.user_obj_is_removed === false;
      } else if (type === 'delete_association') {
        return !user.is_removed;
      }
    };
    if (data) {
      const selectedUsers = selectedEntitiesIds.map(id => {
        let user = data.result.find(user => user.id === id);
        if (user) return user;
      });
      const has_deactivated = !!selectedUsers.find(user => findTarget(user, type));
      return !(selectedEntitiesIds.length && has_deactivated);
    }
    return true;
  };

  const renderContent = () => {
    return (
      <>
        <div className="b-page__controls">
          <div className="b-tableToolContainer">
            <div className="-desktop">
              <button
                onClick={() => manipulateModal(Modals.isFilter, true)}
                className={`
                    b-tableTool
                    -mr
                    -filter
                    -colorGrey
                    ${!isEmptyObject(query.filters) ? '-active' : ''}
                  `}
              >
                {t('common:btn.filter')}
              </button>
            </div>
          </div>
          <div className="-mobile b-tableToolContainer -mobMb">
            <SortModalButton
              setSortingData={setSortingData}
              sortingData={sortingData}
              columns={columns}
              query={query}
              handleChangeSortField={handleChangeSortField}
              handleSort={handleSort}
            />
            <button
              onClick={handleChangeDirection}
              type="button"
              className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                    ${query.sorting?.direction === 'DESC' && '-active'}
                  `}
            >
              {t('common:btn.a-z-order')}
            </button>
            <button
              onClick={() => manipulateModal(Modals.isFilter, true)}
              className={`
                    b-tableTool
                    -mr
                    -filter
                    -colorGrey
                    ${!isEmptyObject(query.filters) ? '-active' : ''}
                  `}
            >
              {t('common:btn.filter')}
            </button>
          </div>
          <div className="b-page__search">
            <form action="#" className="searchForm">
              <input
                type="search"
                value={query.search}
                onChange={e => handleSearch(e.target.value)}
                placeholder={t('common:label.search')}
              />
              <span className="searchForm__icon" />
            </form>
          </div>
        </div>
        <div className="d-flex mb-3">
          <button
            disabled={_isDeactivateBtnDisabled('activate_account')}
            onClick={() => handleChangeUserStatus(true)}
            className="b-tableTool -userOn -mobView -mr"
          >
            {t('common:btn.activate-account')}
          </button>
          <button
            disabled={_isDeactivateBtnDisabled('activate_association')}
            onClick={() => handleChangeAffiliationStatus(true)}
            className="b-tableTool -userOn -mobView -mr"
          >
            {t('common:btn.activate-association')}
          </button>
          <button
            disabled={_isDeactivateBtnDisabled('deactivate_account')}
            onClick={() => handleChangeUserStatus(false)}
            className="b-tableTool -userOff -mobView -mr"
          >
            {t('common:btn.deactivate-account')}
          </button>
          <button
            disabled={_isDeactivateBtnDisabled('deactivate_association')}
            onClick={() => handleChangeAffiliationStatus(false)}
            className="b-tableTool -userOff -mobView -mr"
          >
            {t('common:btn.deactivate-association')}
          </button>
          <button
            onClick={() => setRemoveAlert('user')}
            disabled={_isDeactivateBtnDisabled('delete_account')}
            className="b-tableTool -delete -mobView -mr"
          >
            {t('common:btn.delete-account')}
          </button>
          <button
            onClick={() => setRemoveAlert('affiliation')}
            disabled={_isDeactivateBtnDisabled('delete_association')}
            className="b-tableTool -delete -mobView -mr"
          >
            {t('common:btn.delete-association')}
          </button>
        </div>
        {data ? (
          <div className="-desktop">
            <DndProvider backend={HTML5Backend}>
              <DataGrid
                onRowClick={handleClickUser}
                onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
                columns={columns}
                onColumnResize={onColumnResize}
                sortColumns={query.sorting ? [query.sorting] : []}
                rows={data.result}
                onSelectedRowsChange={handleSelectOne}
                className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
                style={{
                  height: ((data.result.length || 0) + 1) * 60,
                }}
                rowHeight={60}
                selectedRows={new Set(selectedEntitiesIds)}
                rowKeyGetter={(row: AdminToolOrgUserModel) => row.id}
              />
            </DndProvider>
            <Pagination
              data={data}
              handleChangePageSize={handleChangePageSize}
              changePage={handleChangePage}
            />
          </div>
        ) : null}

        {isMobile && data && (
          <div className="-mobile b-adminToolUsers__mobileList">
            <InfiniteScroll
              dataLength={infiniteList.length}
              next={fetchMoreMobile}
              hasMore={!(data.current_page === data.total_pages)}
              loader={<h4>{t('common:label.loading')}...</h4>}
            >
              {infiniteList.map((user: AdminToolOrgUserModel) => {
                const isChecked = !!selectedEntitiesIds.find(f => f === user.id);
                return (
                  <div
                    key={`user-${user.id}`}
                    className="b-at-user b-panel"
                    onClick={() => handleClickUser(user)}
                  >
                    <label className="checkbox b-at-user__check" onClick={e => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={e => handleSelectOneMob(e, user.id)}
                      />
                      <span className="checkbox_fakeInput" />
                    </label>
                    <span className="b-at-user__userName">
                      {user.first_name} {user.last_name}
                    </span>
                    <ul className="b-at-user__list">
                      <li>{user.email}</li>
                      <li>{user.user_role_name}</li>
                      <li>{user.user_type_name}</li>
                      <li>{convertUserStatus(user.status, user.status_date)}</li>
                    </ul>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <h1 className={'b-page__title'}>{t('common:label.admin-tool')}</h1>
        <BelovedAdminToolsTabs activeItemAlias={'org_users'} />
        {renderContent()}
      </main>
      {state.modals.isFilter ? (
        <FilterModal
          filterData={filterData}
          handleSelectFilter={handleSelectFilter}
          handleApplyFilter={handleApplyFilter}
          onClose={() => manipulateModal(Modals.isFilter, false)}
        />
      ) : null}
      {removeAlert === 'affiliation' ? (
        <Alert
          isOpen={true}
          onRequestClose={() => setRemoveAlert(null)}
          title={t('awa:N6.msg')}
          text={<p />}
          buttons={{
            left: {
              title: t('common:btn.cancel'),
              type: 'white',
              onClick: () => setRemoveAlert(null),
            },
            right: {
              title: t('common:btn.delete'),
              type: 'orange-light',
              onClick: handleDeleteAffiliation,
            },
          }}
        />
      ) : null}
      {removeAlert === 'user' ? (
        <Alert
          isOpen={true}
          onRequestClose={() => setRemoveAlert(null)}
          title={t('awa:N6.msg2')}
          text={<p />}
          buttons={{
            left: {
              title: t('common:btn.cancel'),
              type: 'white',
              onClick: () => setRemoveAlert(null),
            },
            right: {
              title: t('common:btn.delete'),
              type: 'orange-light',
              onClick: handleDeleteUser,
            },
          }}
        />
      ) : null}
    </>
  );
};

export default AdminToolOrgUsers;
