import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import {
  GenericNotes,
  HeadlineOrganization,
  OrgBreadcrumbs,
  OrgProfileTabs,
  SelectValuesModal,
} from '../../../../common';
import EwpTabs from '../parts/EwpTabs';
import Illus from '../../../../../assets/images/icons/illus_next_steps.png';
import { useHistory } from 'react-router';
import { ROOTS } from '../../../../../routes/routes';
import { useParams } from 'react-router-dom';
import MarketingPanel from './parts/MarketingPanel';
import {
  EwpCreateStepEnum,
  EWPModel,
  EwpPreparationStepEnum,
  EWPSendEmailPayload,
  EwpTeamType,
  EwpTypes,
  EWPUpdateData,
  IRouteParamsBase,
  ISelectOptions,
} from '../../../../../models';
import {
  setEwpDetails,
  getEWPDetails,
  updateEWP,
  UpdateEWPPayload,
} from '../../../../../store/ewp/ewpActions';
import { Checkbox, Link, Loader } from '../../../../controls';
import { basicDateFormat, filterByArray, getBasicDateFormatCodeForDatePicker } from '../../../../../helpers';
import EwpStatusLabel from '../parts/EwpStatusLabel';
import { EwpHelpers } from '../../../../../helpers/ewpHelpers';
import { toast } from 'react-toastify';
import AddElementPanel from '../parts/AddElementPanel';
import BackTitleButton from '../parts/BackTitleButton';
import Api from '../../../../../services/api';
import { useRole } from '../../../../../helpers/roles';
import useHasAccess from '../../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../../constants/roles';
import { useEwpDetails } from '../../../../../hooks/ewp/use-ewp-details';
import DatePicker from 'react-datepicker';
import { useEwpListApi } from '../../../../../hooks/ewp/use-ewp-list-api';
import VisionForm from './parts/VisionForm';
import { useEwpAvailableUsers } from '../../../../../hooks/ewp/use-ewp-available-users';
import { Modals } from '../../../../../constants';
import { showAlert } from '../../../../common/Alert/RemoteAlert';
import { useSelectCurrentOrg } from '../../../../../hooks/org/use-select-current-org';
import EmptyButton from '../../../../controls/Button/EmptyButton';

interface IState {
  selectedManagers: number[];
  selectedConsultants: number[];
  selectedTeam: number[];
  selectedPM: number[];
  modalType: EwpTeamType | null;
  tempUsers: number[];
}

const EwpDetails = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { orgId } = useParams<IRouteParamsBase>();
  const { ewpDetails, loading } = useEwpDetails();
  const currentOrg = useSelectCurrentOrg();

  const ewp = ewpDetails;
  const dispatch = useDispatch();
  const isBeloved = useSelector((state: StateType) => state.profile.authUser?.beloved_user);
  const access = useRole();
  const isAllowEditManagers = ewp?.ewp_permissions.ewp_obj.edit;
  const hasAccessToEwpCRUD = useHasAccess(BelovedPermissions.EWP_CRUD);
  const canContinueEwp = access(['project_manager']) || (isBeloved && hasAccessToEwpCRUD);
  const {
    availableUsers,
    setAvailableUsers,
    getAvailableProjectPM,
    getAvailableTeam,
    getAvailableManagers,
    getAvailableConsultant,
  } = useEwpAvailableUsers(Number(orgId), Number(ewp?.id));
  const hasEwpCredit = currentOrg?.credits.ewp;

  const hasConsultants =
    ewp &&
    (ewp.ewp_type_id === EwpTypes.org_with_consultant_active_role ||
      ewp.ewp_type_id === EwpTypes.org_with_consultant_reviewer_role);
  const hasAccessToEwpEditLeader =
    useHasAccess(BelovedPermissions.EWP_EDIT_LEADER) &&
    ewp &&
    (ewp.ewp_type_id === EwpTypes.beloved_deep_dive_clients ||
      ewp.ewp_type_id === EwpTypes.beloved_cohort_clients);

  const { getAvailableLeaders, editLeadersFromOrg } = useEwpListApi();
  let [editLeaderEwp, setEditLeaderEwp] = useState<boolean | null>(null);
  let [leaders, setLeaders] = useState<ISelectOptions[] | null>(null);

  const initialState: IState = {
    selectedManagers: [],
    selectedTeam: [],
    selectedPM: [],
    selectedConsultants: [],
    modalType: null,
    tempUsers: [],
  };

  let [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (isBeloved && hasAccessToEwpEditLeader) {
      getAvailableLeaders(leaders => {
        setLeaders(leaders);
      });
    }
  }, [isBeloved, hasAccessToEwpEditLeader]);

  const handleSelectUser = (userId: number, teamType: EwpTeamType) => {
    if (teamType === 'team') {
      if (state.selectedTeam.includes(userId)) {
        const result = [...state.selectedTeam].filter(id => id !== userId);
        setState({ ...state, selectedTeam: result });
      } else {
        setState({ ...state, selectedTeam: [...state.selectedTeam, userId] });
      }
    } else if (teamType === 'ewp_pm_users') {
      if (state.selectedPM.includes(userId)) {
        const result = [...state.selectedPM].filter(id => id !== userId);
        setState({ ...state, selectedPM: result });
      } else {
        setState({ ...state, selectedPM: [...state.selectedPM, userId] });
      }
    } else if (teamType === 'managers') {
      if (state.selectedManagers.includes(userId)) {
        const result = [...state.selectedManagers].filter(id => id !== userId);
        setState({ ...state, selectedManagers: result });
      } else {
        setState({ ...state, selectedManagers: [...state.selectedManagers, userId] });
      }
    } else if (teamType === 'consultants') {
      if (state.selectedConsultants.includes(userId)) {
        const result = [...state.selectedConsultants].filter(id => id !== userId);
        setState({ ...state, selectedConsultants: result });
      } else {
        setState({ ...state, selectedConsultants: [...state.selectedConsultants, userId] });
      }
    }
  };

  const handleCloseModal = () => {
    setState({ ...state, modalType: null, tempUsers: [] });
  };

  const handleOpenModal = (modalType: EwpTeamType) => {
    if (modalType === 'ewp_pm_users') {
      getAvailableProjectPM();
    }
    if (modalType === 'team') {
      getAvailableTeam();
    }
    if (modalType === 'managers') {
      getAvailableManagers();
    }
    if (modalType === 'consultants') {
      getAvailableConsultant();
    }
    setState({
      ...state,
      modalType: modalType,
    });
  };

  const handleSaveUserList = (tempUserIds: number[]) => {
    if (ewp) {
      const data: UpdateEWPPayload['data'] = {
        ewp_screen: EwpCreateStepEnum.finish,
      };
      if (state.modalType === 'team') {
        data.team_ids = tempUserIds;
      } else if (state.modalType === 'managers') {
        data.manager_ids = tempUserIds;
      } else if (state.modalType === 'consultants') {
        data.consultant_ids = tempUserIds;
      } else if (state.modalType === 'ewp_pm_users') {
        data.pm_ids = tempUserIds;
      }
      dispatch(
        updateEWP({
          ewpId: ewp.id,
          orgId: ewp.organization_id,
          isDetails: true,
          data,
          callback: data => {
            if (data) {
              setState({ ...state, modalType: null });
              setAvailableUsers([]);
            }
          },
        }),
      );
    }
  };

  const handleDeleteUser = (teamType: EwpTeamType, ewp: EWPModel) => {
    if (!ewp) return null;
    let data: UpdateEWPPayload['data'] = { ewp_screen: EwpCreateStepEnum.finish };
    if (teamType === 'team') {
      data.team_ids = filterByArray(
        [...ewp.team].map(user => user.id),
        state.selectedTeam,
      ) as number[];
    } else if (teamType === 'managers') {
      data.manager_ids = filterByArray(
        [...ewp.managers].map(user => user.id),
        state.selectedManagers,
      ) as number[];
    } else if (teamType === 'ewp_pm_users') {
      data.pm_ids = filterByArray(
        [...ewp.ewp_pm_users].map(user => user.id),
        state.selectedPM,
      ) as number[];
    } else if (teamType === 'consultants') {
      data.consultant_ids = filterByArray(
        [...ewp.consultants].map(user => user.id),
        state.selectedConsultants,
      ) as number[];
    }

    dispatch(
      updateEWP({
        ewpId: Number(ewp.id),
        orgId: ewp.organization_id,
        data,
        isDetails: true,
        callback: data => {
          if (data) {
            setState(prevState => {
              const newState = { ...prevState };
              if (teamType === 'team') {
                newState.selectedTeam = [];
              } else if (teamType === 'managers') {
                newState.selectedManagers = [];
              } else if (teamType === 'consultants') {
                newState.selectedConsultants = [];
              } else if (teamType === 'ewp_pm_users') {
                newState.selectedPM = [];
              }
              return newState;
            });
          }
        },
      }),
    );
  };

  const handleSendEmail = async (teamType: EwpTeamType) => {
    if (!ewp) return null;
    const data: EWPSendEmailPayload = {
      target: teamType,
      ewp_id: ewp.id,
      team_ids: [],
    };

    if (teamType === 'team') {
      data.team_ids = state.selectedTeam;
    } else if (teamType === 'managers') {
      data.team_ids = state.selectedManagers;
    } else if (teamType === 'consultants') {
      data.team_ids = state.selectedConsultants;
    } else if (teamType === 'ewp_pm_users') {
      data.team_ids = state.selectedPM;
    }

    const res = await Api.post(`organizations/${ewp.organization_id}/ewp/send_notification/`, data);
    if (res) {
      toast.success(t('common:toast.email-sent'));
    }
  };

  const handlePreparationEWP = (e: any) => {
    e.preventDefault();
    if (isBeloved) {
      history.push(ROOTS.BELOVED_EWP_PREPARATION.replace(':orgId', `${orgId}`));
    } else {
      history.push(ROOTS.EWP_PREPARATION.replace(':orgId', `${orgId}`));
    }
  };

  const handleContinueEWP = (e: any) => {
    e.preventDefault();
    history.push(ROOTS.EWP_CREATE.replace(':orgId', `${orgId}`));
  };

  const handleChangeDate = (date: Date, field: string) => {
    if (!ewp) return;
    const data: EWPUpdateData = {
      [field]: date,
      ewp_screen: EwpCreateStepEnum.finish,
    };
    dispatch(
      updateEWP({
        ewpId: ewp.id,
        orgId: ewp.organization_id,
        isDetails: true,
        data,
        callback: (data?: EWPModel) => {
          if (data) {
            setEwpDetails(data);
          }
        },
      }),
    );
  };

  const handleOpenEditLeaderEwp = () => {
    setEditLeaderEwp(true);
  };

  const handleCloseEditLeader = () => {
    setEditLeaderEwp(null);
  };

  const handleSaveEditLeader = (tempUsers: number[]) => {
    if (!editLeaderEwp) return;
    editLeadersFromOrg({ organization_id: Number(orgId), id: Number(ewp?.id) }, tempUsers, () => {
      setEditLeaderEwp(null);
      dispatch(getEWPDetails({ orgId: Number(orgId) }));
    });
  };

  const handleOpenEwpType = () => {
    if (!ewp) {
      return;
    }

    showAlert({
      title: ewpDetails?.ewp_type_details_title || t('common:column.ewp-type'),
      text: (
        <>
          {ewp.ewp_type_details.map((detail, detailIndex) => (
            <p key={`detail-${detailIndex}`}>{detail}</p>
          ))}
        </>
      ),
      buttons: {
        right: {
          title: t('common:btn.ok').toUpperCase(),
        },
      },
      contentStyles: {
        width: '550px',
      },
      textStyles: {
        textAlign: 'left',
      },
    });
  };

  const renderContent = () => {
    if (loading) {
      return <Loader fitParent />;
    }
    if (ewp) {
      if (ewp.ewp_screen !== EwpCreateStepEnum.finish) {
        return (
          <div className="b-nextSteps">
            <img
              className="b-nextSteps__illustration"
              width={122}
              height={122}
              src={Illus}
              alt={t('awa:AT25')}
            />
            <div className="b-nextSteps__holder -center">
              <h2 className="b-nextSteps__title -center">{t('awa:M135.title')}</h2>
              <div className="mt-4">
                {access(['project_manager']) ? t('awa:M135.msg') : t('awa:M233.msg.p1')}
              </div>
              {canContinueEwp ? (
                <a className="b-nextSteps__linkBtn mt-5" href="#" onClick={handleContinueEWP}>
                  {t('common:btn.continue')}
                </a>
              ) : null}
            </div>
          </div>
        );
      }

      const navToPreparation = (step: string) => {
        const locationState = {
          ewpId: ewp?.id,
          step: step,
        };
        if (isBeloved) {
          history.push(ROOTS.BELOVED_EWP_PREPARATION.replace(':orgId', `${orgId}`), locationState);
        } else {
          history.push(ROOTS.EWP_PREPARATION.replace(':orgId', `${orgId}`), locationState);
        }
      };

      return (
        <>
          <AddElementPanel />
          <div className="d-flex mb-3">
            <Link
              onClick={() => navToPreparation(EwpPreparationStepEnum.introDetails)}
              className="b-link -no-underline mr-5"
            >
              {t('common:btn.introduction-ewp')}
            </Link>
            <Link
              onClick={() => navToPreparation(EwpPreparationStepEnum.step1)}
              className="b-link -no-underline"
            >
              {t('common:btn.ewp-preparation-steps')}
            </Link>
          </div>

          <div className="b-panelData position-relative pt-5">
            <div className="position-absolute" style={{ top: 10, right: 10 }}>
              <EwpStatusLabel status={ewp.status_id} />
            </div>
            <dl className="b-panelData__row">
              <dt>
                <div className="b-panelData__fieldTitle">{t('common:column.start-date')}</div>
              </dt>
              <dd>
                <div id={'start_date'} className="b-panelData__fieldValue -primary-60">
                  <DatePicker
                    dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                    selected={ewp.start_date ? new Date(ewp.start_date) : null}
                    onChange={(date: Date) => handleChangeDate(date, 'start_date')}
                    ariaLabelledBy="start_date"
                    placeholderText={t('common:label.please-set-start-date')}
                    maxDate={ewp.end_date ? new Date(ewp.end_date) : null}
                  />
                </div>
              </dd>
              <dt>
                <div className="b-panelData__fieldTitle">{t('common:column.end-date')}</div>
              </dt>
              <dd>
                <div id={'end_date'} className="b-panelData__fieldValue -primary-60">
                  <DatePicker
                    dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                    selected={ewp.end_date ? new Date(ewp.end_date) : null}
                    onChange={(date: Date) => handleChangeDate(date, 'end_date')}
                    ariaLabelledBy="end_date"
                    placeholderText={t('common:label.please-set-end-date')}
                    minDate={ewp.start_date ? new Date(ewp.start_date) : null}
                  />
                </div>
              </dd>
            </dl>
            <dl className="b-panelData__row">
              <dt>
                <div className="b-panelData__fieldTitle">{t('common:column.last-update')}</div>
              </dt>
              <dd>
                <div className="b-panelData__fieldValue">
                  {ewp.last_update ? basicDateFormat(ewp.last_update) : t('common:column.n-a')}
                </div>
              </dd>
              <dt>
                <div className="b-panelData__fieldTitle">{t('common:column.type')}</div>
              </dt>
              <dd>
                <b>{ewp.ewp_type_name}</b>
                <EmptyButton className="b-report__btnInfo b-ewp__infoBtn" onClick={handleOpenEwpType} />
              </dd>
            </dl>
            {ewp.beloved_team.length ? (
              <dl className="b-panelData__row">
                <dt>
                  <div className="b-panelData__fieldTitle">{t('common:column.beloved-team')}</div>
                </dt>
                <dd>
                  <div className="b-panelData__fieldValue">
                    {ewp.beloved_team.map(user => {
                      return <div key={`bel-user-${user.id}`}>{user.name}</div>;
                    })}
                  </div>
                </dd>
                <dt />
                <dd />
              </dl>
            ) : null}
            {isBeloved && hasAccessToEwpEditLeader ? (
              <a onClick={handleOpenEditLeaderEwp} href="#" className="b-link-icon -edit">
                {t('common:btn.edit-beloved-team')}
              </a>
            ) : null}

            <div className="d-lg-flex justify-content-between mt-5">
              <div className="b-userManager mb-0">
                <div className="b-userManager__table mb-0">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className="font-m font-weight-bold font-navy">{t('main:ewp-create.pm.title')}</div>
                    {isAllowEditManagers ? (
                      <div className="d-flex justify-content-end">
                        <button
                          disabled={!state.selectedPM.length}
                          className="b-button-icon -send -colorNavy"
                          onClick={() => handleSendEmail('ewp_pm_users')}
                        >
                          <span className="sr-only">{t('common:btn.send')}</span>
                        </button>
                        <button
                          disabled={!state.selectedPM.length}
                          onClick={() => handleDeleteUser('ewp_pm_users', ewp)}
                          className="b-button-icon ml-3 -delete -colorNavy"
                        >
                          <span className="sr-only">{t('common:btn.delete')}</span>
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-4">
                    {ewp.ewp_pm_users.map(user => {
                      const isChecked = state.selectedPM.includes(user.id);
                      return (
                        <div
                          key={`ewp_pm_user-${user.id}`}
                          className="d-flex justify-content-between align-items-center mb-3"
                        >
                          <div className="font-m font-grey font-weight-bold">{user.name}</div>
                          <div className="d-flex align-items-center">
                            <div className="font-m font-grey mr-5">
                              {user.status} {user.status_date ? basicDateFormat(user.status_date) : ''}
                            </div>
                            {isAllowEditManagers ? (
                              <Checkbox
                                noText
                                checked={isChecked}
                                onChange={() => handleSelectUser(user.id, 'ewp_pm_users')}
                              />
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {isAllowEditManagers ? (
                    <Link onClick={() => handleOpenModal('ewp_pm_users')} className="b-link-add">
                      {t('common:btn.add-new-member')}
                    </Link>
                  ) : null}
                </div>
              </div>
              {hasConsultants ? (
                <div className="b-userManager mb-0">
                  <div className="b-userManager__table">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div className="font-m font-weight-bold font-navy">
                        {t('main:ewp-create.consultants.title')}
                      </div>
                      {isAllowEditManagers ? (
                        <div className="d-flex justify-content-end">
                          <button
                            disabled={!state.selectedConsultants.length}
                            className="b-button-icon -send -colorNavy"
                            onClick={() => handleSendEmail('consultants')}
                          >
                            <span className="sr-only">{t('common:btn.send')}</span>
                          </button>
                          <button
                            disabled={!state.selectedConsultants.length}
                            onClick={() => handleDeleteUser('consultants', ewp)}
                            className="b-button-icon ml-3 -delete -colorNavy"
                          >
                            <span className="sr-only">{t('common:btn.delete')}</span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      {ewp.consultants.map(user => {
                        const isChecked = state.selectedConsultants.includes(user.id);

                        return (
                          <div
                            key={`cons-${user.id}`}
                            className="d-flex justify-content-between align-items-center mb-3"
                          >
                            <div className="font-m font-grey font-weight-bold">{user.name}</div>
                            <div className="d-flex align-items-center">
                              <div className="font-m font-grey mr-5">
                                {user.status} {user.status_date ? basicDateFormat(user.status_date) : ''}
                              </div>
                              {isAllowEditManagers ? (
                                <Checkbox
                                  noText
                                  checked={isChecked}
                                  onChange={() => handleSelectUser(user.id, 'consultants')}
                                />
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {isAllowEditManagers ? (
                      <Link onClick={() => handleOpenModal('consultants')} className="b-link-add">
                        {t('common:btn.add-new-member')}
                      </Link>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="d-lg-flex justify-content-between mt-5">
              <div className="b-userManager">
                <div className="b-userManager__table">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className="font-m font-weight-bold font-navy">{t('main:ewp-create.team.title')}</div>
                    {isAllowEditManagers ? (
                      <div className="d-flex justify-content-end">
                        <button
                          disabled={!state.selectedTeam.length}
                          className="b-button-icon -send -colorNavy"
                          onClick={() => handleSendEmail('team')}
                        >
                          <span className="sr-only">{t('common:btn.send')}</span>
                        </button>
                        <button
                          disabled={!state.selectedTeam.length}
                          onClick={() => handleDeleteUser('team', ewp)}
                          className="b-button-icon ml-3 -delete -colorNavy"
                        >
                          <span className="sr-only">{t('common:btn.delete')}</span>
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-4">
                    {ewp.team.map(user => {
                      const isChecked = state.selectedTeam.includes(user.id);
                      return (
                        <div
                          key={`team-${user.id}`}
                          className="d-flex justify-content-between align-items-center mb-3"
                        >
                          <div className="font-m font-grey font-weight-bold">{user.name}</div>
                          <div className="d-flex align-items-center">
                            <div className="font-m font-grey mr-5">
                              {user.status} {user.status_date ? basicDateFormat(user.status_date) : ''}
                            </div>
                            {isAllowEditManagers ? (
                              <Checkbox
                                noText
                                checked={isChecked}
                                onChange={() => handleSelectUser(user.id, 'team')}
                              />
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {isAllowEditManagers ? (
                    <Link onClick={() => handleOpenModal('team')} className="b-link-add">
                      {t('common:btn.add-new-member')}
                    </Link>
                  ) : null}
                </div>
              </div>
              <div className="b-userManager">
                <div className="b-userManager__table">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className="font-m font-weight-bold font-navy">
                      {t('main:ewp-create.manager.title')}
                    </div>
                    {isAllowEditManagers ? (
                      <div className="d-flex justify-content-end">
                        <button
                          disabled={!state.selectedManagers.length}
                          className="b-button-icon -send -colorNavy"
                          onClick={() => handleSendEmail('managers')}
                        >
                          <span className="sr-only">{t('common:btn.send')}</span>
                        </button>
                        <button
                          disabled={!state.selectedManagers.length}
                          onClick={() => handleDeleteUser('managers', ewp)}
                          className="b-button-icon ml-3 -delete -colorNavy"
                        >
                          <span className="sr-only">{t('common:btn.delete')}</span>
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-4">
                    {ewp.managers.map(user => {
                      const isChecked = state.selectedManagers.includes(user.id);
                      return (
                        <div
                          key={`team-${user.id}`}
                          className="d-flex justify-content-between align-items-center mb-3"
                        >
                          <div className="font-m font-grey font-weight-bold">{user.name}</div>
                          <div className="d-flex align-items-center">
                            <div className="font-m font-grey mr-5">
                              {user.status} {user.status_date ? basicDateFormat(user.status_date) : ''}
                            </div>
                            {isAllowEditManagers ? (
                              <Checkbox
                                noText
                                checked={isChecked}
                                onChange={() => handleSelectUser(user.id, 'managers')}
                              />
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {isAllowEditManagers ? (
                    <Link onClick={() => handleOpenModal('managers')} className="b-link-add">
                      {t('common:btn.add-new-member')}
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <VisionForm ewp={ewp} />
          <GenericNotes
            isAllowToCreate={ewp.ewp_permissions.notes.create}
            entityType={'ewp'}
            entityId={ewp.id}
          />
        </>
      );
    }

    if (isBeloved) {
      return <div className="b-no-content">{t('common:label.no-ewp')}</div>;
    }

    if (!hasEwpCredit) {
      return <MarketingPanel />;
    }

    if (hasEwpCredit) {
      return (
        <div className="b-nextSteps">
          <img
            className="b-nextSteps__illustration"
            width={122}
            height={122}
            src={Illus}
            alt={t('awa:AT25')}
          />
          <div className="b-nextSteps__holder -center">
            <h2 className="b-nextSteps__title -center">{t('awa:M135.title')}</h2>
            <div className="mt-4">
              {access(['project_manager']) ? t('awa:M135.msg') : t('awa:M233.msg.p1')}
            </div>
            {access(['project_manager']) ? (
              <a className="b-nextSteps__linkBtn mt-5" href="#" onClick={handlePreparationEWP}>
                {t('common:btn.create-new-ewp')}
              </a>
            ) : null}
          </div>
        </div>
      );
    }
  };

  const renderTabs = () => {
    if (ewp) {
      return <EwpTabs activeItemAlias={'details'} />;
    }
    if (isBeloved) {
      return null;
    }
    return <OrgProfileTabs activeItemAlias={'EWP'} />;
  };

  return (
    <>
      <main className={'b-page'}>
        <div className="b-page__header -desktop">
          <HeadlineOrganization
            orgName={currentOrg?.name || ewp?.organization_name || ''}
            orgId={currentOrg?.id || ewpDetails?.organization_id || ''}
            headline={t('common:headlines.equity-work-plan')}
            customClasses={'-noMb -desktop'}
            backBtn={<BackTitleButton />}
          />
        </div>
        <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
        {renderTabs()}
        <div className="contentContainer">
          <div className="mt-4">{renderContent()}</div>
        </div>
      </main>
      {state.modalType === 'team' && ewp ? (
        <SelectValuesModal
          title={t('common:headlines.add-member')}
          subTitle={t('common:headlines.organization-users')}
          bottomInfo={`*${t('main:add-member.received')}.`}
          onClose={handleCloseModal}
          value={ewp.team_ids}
          values={EwpHelpers.getAvailableUsers(ewp.team, availableUsers)}
          handleSave={tempUsers => handleSaveUserList(tempUsers)}
          noOptionsMessage={t('awa:N71.msg')}
        />
      ) : null}
      {state.modalType === 'managers' && ewp ? (
        <SelectValuesModal
          title={t('common:headlines.add-managers')}
          subTitle={t('common:headlines.organization-users')}
          bottomInfo={`*${t('main:add-member.received')}.`}
          onClose={handleCloseModal}
          value={ewp.manager_ids}
          values={EwpHelpers.getAvailableUsers(ewp.managers, availableUsers)}
          handleSave={tempUsers => handleSaveUserList(tempUsers)}
          noOptionsMessage={t('awa:N71.msg')}
        />
      ) : null}
      {state.modalType === 'ewp_pm_users' && ewp ? (
        <SelectValuesModal
          title={t('common:headlines.add-ewp-pm')}
          subTitle={t('common:headlines.organization-users')}
          bottomInfo={`*${t('main:add-member.received')}.`}
          onClose={handleCloseModal}
          value={ewp.ewp_pm_user_ids}
          values={EwpHelpers.getAvailableUsers(ewp.ewp_pm_users, availableUsers)}
          handleSave={tempUsers => handleSaveUserList(tempUsers)}
          noOptionsMessage={t('awa:N71.msg')}
        />
      ) : null}
      {state.modalType === 'consultants' && ewp ? (
        <SelectValuesModal
          title={t('common:headlines.add-EWP-consultants')}
          subTitle={t('common:headlines.consultants-users')}
          bottomInfo={`*${t('main:add-member.received')}.`}
          onClose={handleCloseModal}
          value={ewp.consultant_ids}
          values={EwpHelpers.getAvailableUsers(ewp.consultants, availableUsers)}
          handleSave={tempUsers => handleSaveUserList(tempUsers)}
          noOptionsMessage={t('awa:N71.msg')}
        />
      ) : null}
      {leaders && editLeaderEwp ? (
        <SelectValuesModal
          title={t('common:headlines.Add-EWP-Leaders')}
          subTitle={t('common:column.beloved-leaders')}
          bottomInfo={`*${t('main:add-member.received')}.`}
          onClose={handleCloseEditLeader}
          value={ewp?.beloved_team_ids || []}
          values={leaders}
          handleSave={handleSaveEditLeader}
          noOptionsMessage={t('awa:N71.msg')}
        />
      ) : null}
    </>
  );
};

export default EwpDetails;
