import React, { FC } from 'react';
import { Button } from '../../../../../controls';
import Illustration from '../../../../../../assets/images/icons/password@2x.png';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import { ElmTakingModel, HandleSaveType } from '../../../../../../models';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSave: HandleSaveType;
  data: ElmTakingModel;
}

const DQIntro: FC<IProps> = ({ onSave, data }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="-desktop">
        <Button
          iconLeft={<img src={SaveIc} alt={t('common:alt.saveIc')} />}
          className="b-elmTake__saveBtn -desktop"
          type={'bordered'}
          onPress={() => onSave({ next: false, isIntro: true })}
          title={t('common:btn.save-exit')}
        />
      </div>
      <div className="b-EATaking__box">
        <img className="b-elmBox__illus" src={Illustration} alt={t('awa:AT25')} />
        <h1 className="b-EATaking__boxTitle">{t('awa:M66.title')}</h1>
        <div className="b-elmTake__descBox">
          <p className="b-elm__p">{t('awa:M66.msg.p1')}</p>
          <p className="b-elm__p">{t('awa:M66.msg.p2')} </p>
          <p className="b-elm__p">{t('awa:M66.msg.p3')}</p>
          <p className="b-elm__p">
            {t('awa:M66.msg.p4')}:{' '}
            <a rel="noreferrer" target="_blank" href={data.description?.link} className="b-link">
              {t('awa:M66.msg.p5')}
            </a>{' '}
            {t('awa:M66.msg.p6')}
          </p>
        </div>
        <Button
          className={'btn -type_form -desktop'}
          type={'orange-light'}
          onPress={() => onSave({ next: true, isIntro: true })}
          title={t('common:btn.next')}
        />
      </div>
      <div className="-mobile">
        <div className="b-EATaking__buttons">
          <Button
            iconLeft={<img src={SaveIc} alt={t('common:alt.saveIc')} />}
            className="b-elmTake__saveBtn "
            type={'bordered'}
            onPress={() => onSave({ next: false, isIntro: true })}
            title={t('common:btn.save-exit')}
          />
          <Button
            className={'btn'}
            type={'orange-light'}
            onPress={() => onSave({ next: true, isIntro: true })}
            title={t('common:btn.next')}
          />
        </div>
      </div>
    </>
  );
};

export default DQIntro;
