import {
  ActionModel,
  BelEwpModel,
  EWPElementsFilterOptions,
  EWPGoalModel,
  EWPKeyActionModel,
  EWPModel,
  EWPPriorityModel,
  EwpReviewItem,
  EWPStrategyModel,
  EWPUpdateData,
  EwpUserTableItem,
  ISelectOptions,
  PaginationModel,
} from '../../models';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';
import { actionCreator } from '../actionCreator';
import { QueryParamsModel, ReqParamsModel } from '../../hooks/common/use-table-data';
import { ORG_CHANGED } from '../organization/organizationActions';

//-----GET_EWP_USERS----//
export const GET_EWP_USERS = 'ewp/GET_EWP_USERS';
export interface GetEwpUsersPayload {
  extraData?: {
    ewp_id?: string;
  };
  params: ReqParamsModel;
  callback?: () => void;
}

export const getEwpUsers = actionCreator<GetEwpUsersPayload>(GET_EWP_USERS);

export const GET_EWP_USERS_SUCCESS = 'ewp/GET_EWP_USERS_SUCCESS';
export const getEwpUsersSuccess = actionCreator<PaginationModel<EwpUserTableItem[]>>(GET_EWP_USERS_SUCCESS);

export const SET_EWP_USERS_QUERY = 'ewp/SET_EWP_USERS_QUERY';
export const setEwpUsersQuery = actionCreator<QueryParamsModel>(SET_EWP_USERS_QUERY);

//-----GET_EWP_REVIEW_ITEMS----//
export const GET_EWP_REVIEW_ITEMS = 'ewp/GET_EWP_REVIEW_ITEMS';
export interface GetEwpReviewItemsPayload {
  extraData?: {
    ewp_id?: string;
  };
  params: ReqParamsModel;
  callback?: () => void;
}

export const getEwpReviewItems = actionCreator<GetEwpReviewItemsPayload>(GET_EWP_REVIEW_ITEMS);

export const GET_EWP_REVIEW_ITEMS_SUCCESS = 'ewp/GET_EWP_REVIEW_ITEMS_SUCCESS';
export const getEwpReviewItemsSuccess = actionCreator<PaginationModel<EwpReviewItem[]>>(
  GET_EWP_REVIEW_ITEMS_SUCCESS,
);

export const SET_EWP_REVIEW_ITEMS_QUERY = 'ewp/SET_EWP_REVIEW_ITEMS_QUERY';
export const setEwpReviewItemsQuery = actionCreator<QueryParamsModel>(SET_EWP_REVIEW_ITEMS_QUERY);

//-----GET_PRIORITY_LIST----//
export const GET_PRIORITY_LIST = 'ewp/GET_PRIORITY_LIST';
export interface GetPriorityListPayload {
  extraData?: {
    ewp_id?: string;
  };
  params: ReqParamsModel;
  callback?: () => void;
}

export const getPriorityList = actionCreator<GetPriorityListPayload>(GET_PRIORITY_LIST);

export const GET_PRIORITY_LIST_SUCCESS = 'ewp/GET_PRIORITY_LIST_SUCCESS';
export const getPriorityListSuccess =
  actionCreator<PaginationModel<EWPPriorityModel[]>>(GET_PRIORITY_LIST_SUCCESS);

export const SET_PRIORITY_LIST_QUERY = 'ewp/SET_PRIORITY_LIST_QUERY';
export const setPriorityListQuery = actionCreator<QueryParamsModel>(SET_PRIORITY_LIST_QUERY);

//-----GET_GOAL_LIST----//
export const GET_GOAL_LIST = 'ewp/GET_GOAL_LIST';
export interface GetGoalListPayload {
  extraData?: {
    ewp_id?: string;
  };
  params: ReqParamsModel;
  callback?: () => void;
}

export const getGoalList = actionCreator<GetGoalListPayload>(GET_GOAL_LIST);

export const GET_GOAL_LIST_SUCCESS = 'ewp/GET_GOAL_LIST_SUCCESS';
export const getGoalListSuccess = actionCreator<PaginationModel<EWPGoalModel[]>>(GET_GOAL_LIST_SUCCESS);

export const SET_GOAL_LIST_QUERY = 'ewp/SET_GOAL_LIST_QUERY';
export const setGoalListQuery = actionCreator<QueryParamsModel>(SET_GOAL_LIST_QUERY);

//-----GET_STRATS_LIST----//
export const GET_STRATS_LIST = 'ewp/GET_STRATS_LIST';
export interface GetStratsListPayload {
  extraData?: {
    ewp_id?: string;
  };
  params: ReqParamsModel;
  callback?: () => void;
}

export const getStratsList = actionCreator<GetStratsListPayload>(GET_STRATS_LIST);

export const GET_STRATS_LIST_SUCCESS = 'ewp/GET_STRATS_LIST_SUCCESS';
export const getStratsListSuccess =
  actionCreator<PaginationModel<EWPStrategyModel[]>>(GET_STRATS_LIST_SUCCESS);

export const SET_STRATS_LIST_QUERY = 'ewp/SET_STRATS_LIST_QUERY';
export const setStratsListQuery = actionCreator<QueryParamsModel>(SET_STRATS_LIST_QUERY);

//-----GET_KA_LIST----//
export const GET_KA_LIST = 'ewp/GET_KA_LIST';
export interface GetKaListPayload {
  extraData?: {
    ewp_id?: string;
  };
  params: ReqParamsModel;
  callback?: () => void;
}

export const getKaList = actionCreator<GetKaListPayload>(GET_KA_LIST);

export const GET_KA_LIST_SUCCESS = 'ewp/GET_KA_LIST_SUCCESS';
export const getKaListSuccess = actionCreator<PaginationModel<EWPKeyActionModel[]>>(GET_KA_LIST_SUCCESS);

export const SET_KA_LIST_QUERY = 'ewp/SET_KA_LIST_QUERY';
export const setKaListQuery = actionCreator<QueryParamsModel>(SET_KA_LIST_QUERY);

//-----BEL_GET_EWP_LIST----//
export const BEL_GET_EWP_LIST = 'ewp/BEL_GET_EWP_LIST';
export interface BelGetKaListPayload {
  params: ReqParamsModel;
  callback?: () => void;
}

export const belGetEwpList = actionCreator<BelGetKaListPayload>(BEL_GET_EWP_LIST);

export const BEL_GET_EWP_LIST_SUCCESS = 'ewp/BEL_GET_EWP_LIST_SUCCESS';
export const belGetEwpListSuccess =
  actionCreator<PaginationModel<EWPKeyActionModel[]>>(BEL_GET_EWP_LIST_SUCCESS);

export const BEL_SET_EWP_LIST_QUERY = 'ewp/BEL_SET_EWP_LIST_QUERY';
export const belSetEwpListQuery = actionCreator<QueryParamsModel>(BEL_SET_EWP_LIST_QUERY);

//-----CREATE_EWP----//
export const CREATE_EWP = 'CREATE_EWP';
export interface CreateEWPPayload {
  callback?: (res?: EWPModel | null, err?: any) => void;
}
export const createEWP = actionCreator<CreateEWPPayload>(CREATE_EWP);

//-----CREATE_EWP_SUCCESS----//
export const CREATE_EWP_SUCCESS = 'CREATE_EWP_SUCCESS';
export const createEWPSuccess = actionCreator<EWPModel>(CREATE_EWP_SUCCESS);

//-----GET_EWP_DETAILS----//
export const GET_EWP_DETAILS = 'GET_EWP_DETAILS';
export interface GetEWPDetailsPayload {
  orgId: number;
  isCreate?: boolean;
  callback?: (data?: EWPModel) => void;
}
export const getEWPDetails = actionCreator<GetEWPDetailsPayload>(GET_EWP_DETAILS);

//-----SET_EWP_DETAILS----//
export const SET_EWP_DETAILS = 'SET_EWP_DETAILS';
export const setEwpDetails = actionCreator<EWPModel | null>(SET_EWP_DETAILS);

//-----GET_EA_DETAILS_SUCCESS----//
export const SET_CREATE_EWP_DATA = 'SET_CREATE_EWP_DATA';
export const setCreateEwpData = actionCreator<EWPModel | null>(SET_CREATE_EWP_DATA);

//-----UPDATE_EWP----//
export const UPDATE_EWP = 'UPDATE_EWP';
export interface UpdateEWPPayload {
  ewpId: number;
  orgId: number;
  isDetails?: boolean;
  data: EWPUpdateData;
  callback?: (data?: EWPModel) => void;
}
export const updateEWP = actionCreator<UpdateEWPPayload>(UPDATE_EWP);

//-----UPDATE_EA_SUCCESS----//
export const UPDATE_EWP_SUCCESS = 'UPDATE_EWP_SUCCESS';
export const updateEWPSuccess = actionCreator<EWPModel>(UPDATE_EWP_SUCCESS);

//-----CANCEL_EWP----//
export const CANCEL_EWP = 'CANCEL_EWP';
export interface CancelEWPPayload {
  ewpId: number;
  callback?: () => void;
}
export const cancelEWP = actionCreator<CancelEWPPayload>(CANCEL_EWP);

//-----GET_EWP_AVAILABLE_MANAGERS----//
export const GET_EWP_AVAILABLE_MANAGERS = 'ewp/GET_EWP_AVAILABLE_MANAGERS';
export interface GetAvailableManagersPayload {
  orgId: number;
  callback?: (res: ISelectOptions[]) => void;
}
export const getEwpAvailableManagers = actionCreator<GetAvailableManagersPayload>(GET_EWP_AVAILABLE_MANAGERS);

//-----BEL_EWP_CHANGED----//
export const BEL_EWP_CHANGED = 'ewp/BEL_EWP_CHANGED';
export const belEwpChanged = actionCreator(BEL_EWP_CHANGED);

//-----BEL_EWP_CHANGED----//
export const SET_NAV_DETAILS_SOURCE = 'ewp/SET_NAV_DETAILS_SOURCE';
export const setNavDetailsSource = actionCreator<string>(SET_NAV_DETAILS_SOURCE);

//-----BEL_EWP_CHANGED----//
export const GET_ELEMENTS_FILTER_OPTIONS = 'ewp/GET_ELEMENTS_FILTER_OPTIONS';
export const getElementsFilterOptions = actionCreator<{ orgId: number }>(GET_ELEMENTS_FILTER_OPTIONS);

export const GET_ELEMENTS_FILTER_OPTIONS_SUCCESS = 'ewp/GET_ELEMENTS_FILTER_OPTIONS_SUCCESS';
export const getElementsFilterOptionsSuccess = actionCreator<EWPElementsFilterOptions>(
  GET_ELEMENTS_FILTER_OPTIONS_SUCCESS,
);

export type EwpActionsTypes =
  | ActionModel<typeof LOGOUT_SUCCESS>
  | ActionModel<typeof BEL_EWP_CHANGED>
  | ActionModel<typeof SET_NAV_DETAILS_SOURCE, string>
  | ActionModel<typeof GET_ELEMENTS_FILTER_OPTIONS_SUCCESS, EWPElementsFilterOptions>
  | ActionModel<typeof CREATE_EWP, CreateEWPPayload>
  | ActionModel<typeof SET_EWP_DETAILS, EWPModel | null>
  | ActionModel<typeof CREATE_EWP_SUCCESS, EWPModel>
  | ActionModel<typeof UPDATE_EWP, UpdateEWPPayload>
  | ActionModel<typeof UPDATE_EWP_SUCCESS, EWPModel>
  | ActionModel<typeof GET_EWP_DETAILS, GetEWPDetailsPayload>
  | ActionModel<typeof SET_CREATE_EWP_DATA, EWPModel | null>
  | ActionModel<typeof GET_EWP_AVAILABLE_MANAGERS, GetAvailableManagersPayload>
  | ActionModel<typeof ORG_CHANGED>
  | ActionModel<typeof CANCEL_EWP, CancelEWPPayload>
  | ActionModel<typeof GET_PRIORITY_LIST_SUCCESS, PaginationModel<EWPPriorityModel[]>>
  | ActionModel<typeof SET_PRIORITY_LIST_QUERY, QueryParamsModel>
  | ActionModel<typeof GET_GOAL_LIST_SUCCESS, PaginationModel<EWPGoalModel[]>>
  | ActionModel<typeof SET_GOAL_LIST_QUERY, QueryParamsModel>
  | ActionModel<typeof GET_STRATS_LIST_SUCCESS, PaginationModel<EWPStrategyModel[]>>
  | ActionModel<typeof SET_STRATS_LIST_QUERY, QueryParamsModel>
  | ActionModel<typeof GET_KA_LIST_SUCCESS, PaginationModel<EWPKeyActionModel[]>>
  | ActionModel<typeof SET_KA_LIST_QUERY, QueryParamsModel>
  | ActionModel<typeof GET_EWP_REVIEW_ITEMS_SUCCESS, PaginationModel<EwpReviewItem[]>>
  | ActionModel<typeof SET_EWP_REVIEW_ITEMS_QUERY, QueryParamsModel>
  | ActionModel<typeof GET_EWP_USERS_SUCCESS, PaginationModel<EwpUserTableItem[]>>
  | ActionModel<typeof SET_EWP_USERS_QUERY, QueryParamsModel>
  | ActionModel<typeof BEL_GET_EWP_LIST_SUCCESS, PaginationModel<BelEwpModel[]>>
  | ActionModel<typeof BEL_SET_EWP_LIST_QUERY, QueryParamsModel>;
