import {
  ActionModel,
  EAUser,
  ElmModel,
  ElmOptionsModel,
  ElmUpdateModel,
  SaveAnswerElmModel,
  ElmTakingModel,
  ElmUserReportItemModel,
  ElmOrgReportModel,
  ElmUserModel,
  ElmAvailableUserModel,
  ElmShortInfoModel,
  ElmUserPaginationModel,
  PaginationModel,
} from '../../models';
import { actionCreator } from '../actionCreator';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';
import { QueryParamsModel, ReqParamsModel } from '../../hooks/common/use-table-data';
import { ORG_CHANGED } from '../organization/organizationActions';
import { BelDebriefsState } from '../beloved/debriefs/debriefsReducer';
import { ElmState } from './elmReducer';

//-----GET_ELM_LIST----//
export const GET_ELM_LIST = 'GET_ELM_LIST';
export interface GetELMListPayload {
  params: QueryParamsModel;
  callback?: (data?: PaginationModel<ElmModel[]>) => void;
}
export const getElmList = actionCreator<GetELMListPayload>(GET_ELM_LIST);

//-----GET_ELM_DETAILS----//
export const GET_ELM_DETAILS = 'GET_ELM_DETAILS';
export interface GetElmDetailsPayload {
  elmId: number;
  callback?: (elm?: ElmModel) => void;
}

export const getElmDetails = actionCreator<GetElmDetailsPayload>(GET_ELM_DETAILS);

//-----CREATE_ELM----//
export const CREATE_ELM = 'CREATE_ELM';
export interface CreateElmPayload {
  callback?: () => void;
}
export const createElm = actionCreator<CreateElmPayload>(CREATE_ELM);

export const CREATE_ELM_SUCCESS = 'CREATE_ELM_SUCCESS';
export const createElmSuccess = actionCreator<ElmModel>(CREATE_ELM_SUCCESS);

//-----UPDATE_ELM----//
export const UPDATE_ELM = 'UPDATE_ELM';
export interface UpdateElmPayload {
  elmId: number;
  isDetails?: boolean;
  data: ElmUpdateModel;
  callback: (isError: boolean, data?: ElmModel) => void;
}
export const updateElm = actionCreator<UpdateElmPayload>(UPDATE_ELM);

//-----CANCEL_ELM----//
export const CANCEL_ELM = 'CANCEL_ELM';
export interface CancelElmPayload {
  elmId: number;
  callback?: () => void;
}
export const cancelElm = actionCreator<CancelElmPayload>(CANCEL_ELM);

//-----GET_ELM_OPTION----//
export const GET_ELM_OPTIONS = 'GET_ELM_OPTIONS';
export const getElmOptions = actionCreator(GET_ELM_OPTIONS);

export const GET_ELM_OPTIONS_SUCCESS = 'GET_ELM_OPTIONS_SUCCESS';
export const getElmOptionsSuccess = actionCreator<ElmOptionsModel>(GET_ELM_OPTIONS_SUCCESS);

//-----GET_ELM_A_MANAGERS----//
export const GET_ELM_A_MANAGERS = 'GET_ELM_A_MANAGERS';
export const getElmAManagers = actionCreator(GET_ELM_A_MANAGERS);

export const GET_ELM_A_MANAGERS_SUCCESS = 'GET_ELM_A_MANAGERS_SUCCESS';
export const getElmAManagersSuccess = actionCreator<EAUser[]>(GET_ELM_A_MANAGERS_SUCCESS);

//-----UPLOAD_USER_LIST----//
export const UPLOAD_USER_LIST_FILE = 'UPLOAD_USER_LIST_FILE';
export interface UploadUserListFilePayload {
  elmId: number;
  file: File;
  callback: (data: ElmModel | null, error?: any) => void;
}
export const uploadUserListFile = actionCreator<UploadUserListFilePayload>(UPLOAD_USER_LIST_FILE);

//-----GET_ELM_USER_LIST----//
export const GET_ELM_USER_LIST = 'elm/GET_ELM_USER_LIST';
export interface GetElmUserListPayload {
  extraData?: {
    elmId?: string;
  };
  params: ReqParamsModel;
  callback?: () => void;
}
export const getElmUserList = actionCreator<GetElmUserListPayload>(GET_ELM_USER_LIST);

export const GET_ELM_USER_LIST_SUCCESS = 'elm/GET_ELM_USER_LIST_SUCCESS';
export const getElmUserListSuccess =
  actionCreator<ElmUserPaginationModel<ElmUserModel[]>>(GET_ELM_USER_LIST_SUCCESS);

//-----SEND_NOTIFICATION----//
export const SEND_NOTIFICATION = 'elm/SEND_NOTIFICATION';
export interface SendNotificationPayload {
  elmId: number;
  userIds?: number[];
  callback?: () => void;
}
export const sendNotification = actionCreator<SendNotificationPayload>(SEND_NOTIFICATION);

export const SEND_NOTIFICATION_SUCCESS = 'elm/SEND_NOTIFICATION_SUCCESS';
export const sendNotificationSuccess = actionCreator<ElmModel>(SEND_NOTIFICATION_SUCCESS);

//-----TAKE_ELM----//
export const TAKE_ELM = 'elm/TAKE_ELM';
export interface TakeElmPayload {
  elmId: number;
  callback: (payload: { data?: ElmTakingModel; error?: boolean }) => void;
}
export const takeElm = actionCreator<TakeElmPayload>(TAKE_ELM);

//-----SAVE_ANSWER_ELM----//
export const SAVE_ANSWER_ELM = 'elm/SAVE_ANSWER_ELM';
export interface SaveAnswerElmPayload {
  elmId: number;
  data: SaveAnswerElmModel;
  callback: (payload: { data?: ElmTakingModel; error?: boolean }) => void;
}
export const saveAnswerElm = actionCreator<SaveAnswerElmPayload>(SAVE_ANSWER_ELM);

//-----DELETE_ELM_USER----//
export const DELETE_ELM_USER = 'elm/DELETE_ELM_USER';
export interface DeleteElmUserPayload {
  data: {
    elmId: number;
    is_elm_org_team: boolean;
    elm_user_id: number[];
  };
  callback: () => void;
}
export const deleteElmUser = actionCreator<DeleteElmUserPayload>(DELETE_ELM_USER);

//-----DEACTIVATE_ELM_USER----//
export const DEACTIVATE_ELM_USER = 'elm/DEACTIVATE_ELM_USER';
export interface DeactivateElmUserPayload {
  data: {
    is_active: boolean;
    elmId: number;
    is_elm_org_team: boolean;
    elm_user_ids: number[];
  };
  callback: () => void;
}
export const deactivateElmUser = actionCreator<DeactivateElmUserPayload>(DEACTIVATE_ELM_USER);

//-----SET_USER_ROLE----//
export const SET_USER_ROLE = 'elm/SET_USER_ROLE';
export interface SetUserRolePayload {
  elmId: number;
  data: {
    users_ids: number[];
    elm_role_type: number;
  };
  callback?: () => void;
}
export const setUserRole = actionCreator<SetUserRolePayload>(SET_USER_ROLE);

//-----DEACTIVATE_ELM_USER----//
export const CREATE_ELM_USER = 'elm/CREATE_ELM_USER';
export interface CreateElmUserPayload {
  data: {
    first_name: string;
    last_name: string;
    email: string;
    division: string;
    user_role_id: number;
    elm_role_type: number;
  };
  elmId: number;
  callback: (res?: null, err?: any) => void;
}
export const createElmUser = actionCreator<CreateElmUserPayload>(CREATE_ELM_USER);

//-----GET_AVAILABLE_ELM_USERs----//
export const GET_AVAILABLE_ELM_USERS = 'elm/GET_AVAILABLE_ELM_USERS';
export interface GetAvailableElmUsersPayload {
  elmId: number;
  callback: (data: ElmAvailableUserModel[]) => void;
}
export const getAvailableElmUsers = actionCreator<GetAvailableElmUsersPayload>(GET_AVAILABLE_ELM_USERS);

//-----ADD_USER_TO_ELM----//
export const ADD_USER_TO_ELM = 'elm/ADD_USER_TO_ELM';
export interface AddUserToElmPayload {
  elmId: number;
  users_ids: number[];
  elm_role_type: number;
  callback: () => void;
}
export const addUserToElm = actionCreator<AddUserToElmPayload>(ADD_USER_TO_ELM);

//-----SUBMIT_ELM----//
export const SUBMIT_ELM = 'elm/SUBMIT_ELM';
export interface SubmitElmPayload {
  elmId: number;
  callback: (error: boolean) => void;
}
export const submitElm = actionCreator<SubmitElmPayload>(SUBMIT_ELM);

//-----CLOSE_ADMIN_ELM----//
export const CLOSE_ADMIN_ELM = 'elm/CLOSE_ADMIN_ELM';
export interface CloseAdminElmPayload {
  elmId: number;
  callback?: (data: ElmModel) => void;
}
export const closeAdminElm = actionCreator<CloseAdminElmPayload>(CLOSE_ADMIN_ELM);

//-----GET_ELM_REPORT----//
export const GET_ELM_REPORT = 'elm/GET_ELM_REPORT';
export interface GetElmReportPayload {
  data: {
    type: 'org' | 'user';
    elmId: number;
  };
  callback?: (payload: {
    report?: ElmUserReportItemModel[] | ElmOrgReportModel;
    org_name?: string;
    no_submitted?: boolean;
  }) => void;
}
export const getElmReport = actionCreator<GetElmReportPayload>(GET_ELM_REPORT);

//-----GET_ELM_REPORT----//
export const GET_ELM_ORG_REPORT_SUCCESS = 'elm/GET_ELM_ORG_REPORT_SUCCESS';
export const getElmOrgReportSuccess = actionCreator<ElmOrgReportModel>(GET_ELM_ORG_REPORT_SUCCESS);

//-----PUBLISH_ELM_REPORT----//
export const PUBLISH_ELM_REPORT = 'elm/PUBLISH_ELM_REPORT';
export interface PublishElmReportPayload {
  elmId: number;
  callback?: (data: ElmModel) => void;
}
export const publishElmReport = actionCreator<PublishElmReportPayload>(PUBLISH_ELM_REPORT);

//-----SET_QUERY---//
export const SET_QUERY = 'elm/SET_QUERY';
export const setQuery = actionCreator<QueryParamsModel>(SET_QUERY);

export type ElmActionsTypes =
  | ActionModel<typeof GET_ELM_LIST>
  | ActionModel<typeof ORG_CHANGED>
  | ActionModel<typeof LOGOUT_SUCCESS>
  | ActionModel<typeof CREATE_ELM_SUCCESS, ElmModel>
  | ActionModel<typeof GET_ELM_OPTIONS_SUCCESS, ElmOptionsModel>
  | ActionModel<typeof GET_ELM_A_MANAGERS_SUCCESS, EAUser[]>
  | ActionModel<typeof SEND_NOTIFICATION_SUCCESS, ElmModel>
  | ActionModel<typeof TAKE_ELM, TakeElmPayload>
  | ActionModel<typeof SAVE_ANSWER_ELM, SaveAnswerElmPayload>
  | ActionModel<typeof SUBMIT_ELM, SubmitElmPayload>
  | ActionModel<typeof CLOSE_ADMIN_ELM, CloseAdminElmPayload>
  | ActionModel<typeof PUBLISH_ELM_REPORT, PublishElmReportPayload>
  | ActionModel<typeof GET_ELM_REPORT>
  | ActionModel<typeof GET_ELM_ORG_REPORT_SUCCESS, ElmOrgReportModel>
  | ActionModel<typeof SET_QUERY, ElmState['query']>
  | ActionModel<typeof GET_ELM_USER_LIST_SUCCESS, ElmUserPaginationModel<ElmUserModel[]>>
  | ActionModel<typeof GET_AVAILABLE_ELM_USERS, GetAvailableElmUsersPayload>;
