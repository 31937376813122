import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../store/reducers';
import { useEffect, useState } from 'react';
import { getEWPDetails } from '../../store/ewp/ewpActions';
import { useParams } from 'react-router';
import { IRouteParamsBase } from '../../models';

export const useEwpDetails = () => {
  const dispatch = useDispatch();
  const { orgId } = useParams<IRouteParamsBase>();
  const ewpDetails = useSelector((state: StateType) => state.ewp.ewpDetails);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (orgId && (!ewpDetails || (ewpDetails && ewpDetails.organization_id !== Number(orgId)))) {
      setLoading(true);
      dispatch(
        getEWPDetails({
          orgId: Number(orgId),
          callback: () => {
            setLoading(false);
          },
        }),
      );
    } else if (!orgId) {
      console.error('No orgId url parameter');
    }
  }, []);

  return {
    ewpDetails,
    loading,
  };
};
