import React, { useState, FC, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Loader } from '../../../../../controls';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import AddIc from '../../../../../../assets/images/icons/round-content-add.svg';
import EditIc from '../../../../../../assets/images/icons/round-image-edit.svg';
import { Modal, Alert } from '../../../../../common';
import InfoIc from '../../../../../../assets/images/icons/round-action-info.svg';
import { CreateEAStep, EAUpdateData, EAUser, TeamType } from '../../../../../../models';
import { StateType } from '../../../../../../store/reducers';
import { getUserList, convertUserStatus } from '../../../../../../helpers';
import { useTranslation } from 'react-i18next';

interface IProps {
  onChangeStep: (step: CreateEAStep) => void;
  handleCancel: () => void;
  saveData: (data: EAUpdateData, isNext: boolean) => void;
}

interface IState {
  teamUsers: number[];
  managerUsers: number[];
  tempUsers: number[];
  modalType: TeamType | null;
  tipType: TeamType | null;
}

const CreateEAStep2: FC<IProps> = ({ onChangeStep, handleCancel, saveData }) => {
  const { t } = useTranslation();
  const createEAData = useSelector((state: StateType) => state.equityAudit.createEAData);
  const usersList = useSelector((state: StateType) => state.equityAudit.usersList);

  const initialState = {
    teamUsers: [],
    managerUsers: [],
    tempUsers: [],
    modalType: null,
    tipType: null,
  };
  let [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (createEAData) {
      setState(prevState => {
        return {
          ...prevState,
          teamUsers: createEAData.audit_equity_team.map((user: EAUser) => user.id),
          managerUsers: createEAData.audit_managers.map((user: EAUser) => user.id),
        };
      });
    }
  }, [createEAData]);

  const handleEditTeamList = (modalType: TeamType) => (e: any) => {
    e.preventDefault();
    if (modalType === 'team' && usersList && !usersList.length) {
      setState({
        ...state,
        tipType: 'team_empty',
      });
      return;
    }
    if (modalType === 'managers' && usersList && !usersList.length) {
      setState({
        ...state,
        tipType: 'managers_empty',
      });
      return;
    }
    setState(prevState => {
      const newState = { ...prevState, modalType };
      if (modalType === 'managers') {
        newState.tempUsers = prevState.managerUsers;
      } else if (modalType === 'team') {
        newState.tempUsers = prevState.teamUsers;
      }
      return newState;
    });
  };

  const handleCloseModal = () => {
    setState({ ...state, modalType: null, tempUsers: [] });
  };

  const handleSaveUserList = () => {
    setState(prevState => {
      const newState = { ...prevState };
      if (prevState.modalType === 'team') {
        newState.teamUsers = prevState.tempUsers;
      } else if (prevState.modalType === 'managers') {
        newState.managerUsers = prevState.tempUsers;
      }
      newState.tempUsers = [];
      newState.modalType = null;
      return newState;
    });
  };

  const handleSelectUser = (dest: 'team' | 'manager', userId: number) => {
    setState(prevState => {
      let newTeam = [...prevState.tempUsers];
      if (newTeam.includes(userId)) {
        newTeam = newTeam.filter(item => item !== userId);
      } else {
        newTeam.push(userId);
      }
      return {
        ...prevState,
        tempUsers: newTeam,
      };
    });
  };

  const handleNext = () => {
    saveData(
      {
        audit_equity_team_ids: state.teamUsers,
        audit_managers_ids: state.managerUsers,
        ea_step: 'set_date',
      },
      true,
    );
  };

  const handleSaveExit = (e: any) => {
    e.preventDefault();
    saveData(
      {
        audit_equity_team_ids: state.teamUsers,
        audit_managers_ids: state.managerUsers,
        ea_step: 'set_ea_team',
      },
      false,
    );
  };

  const showTip = (tipType: TeamType) => (e: any) => {
    e.preventDefault();
    setState({ ...state, tipType });
  };

  const hideTip = () => {
    setState({ ...state, tipType: null });
  };

  if (!createEAData) return <Loader fitParent />;

  return (
    <>
      <div className={'b-EACreate__step'}>
        <div className="b-EACreate__header">
          <h1 className={'pageContainer__title b-EATypo__pageTitle'}>{t('common:headlines.new-ea')}</h1>
          {!isMobileOnly && (
            <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
              <img src={SaveIc} alt={t('common:alt.saveIc')} />
              {t('common:btn.save-exit')}
            </a>
          )}
        </div>
        <div className={'b-step__orgWrapper'}>
          <div className="b-equityAudit__text16">{t('common:headlines.organization')}</div>
          <div className={'b-step__orgText'}>{createEAData.organization_name}</div>
        </div>
        <div className={'b-step__info'}>
          <div className={'b-step__titleWrapper'}>
            <div className={'b-step__title'}>{t('awa:M40.title')}</div>
            <a href="#" onClick={showTip('team')}>
              <img src={InfoIc} alt={t('common:alt.infoIc')} />
            </a>
          </div>
          <div className={`b-equityAudit__text12 ${'b-step__textWrapper'}`}>
            {t('main:ea-create.active-participants')}
          </div>
          {state.teamUsers.length ? (
            <div className={'b-EACreate__users'}>
              {state.teamUsers.map(userId => {
                const user = usersList?.find(user => user.id === userId);

                if (user) {
                  return (
                    <div className={'b-EACreate__user'} key={`team-${userId}`}>
                      <div className="b-EACreate__userName">{user.name}</div>
                      <div className="b-equityAudit__text16">
                        {convertUserStatus(user.status, user.status_date)}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          ) : null}
          <a href="#" className={'b-step__add'} onClick={handleEditTeamList('team')}>
            {state.teamUsers.length ? (
              <>
                <img style={{ paddingRight: 5 }} src={EditIc} alt={t('common:alt.editIc')} />
                {t('common:btn.edit-equity-team')}
              </>
            ) : (
              <>
                <div className={'b-step__add__ic'}>
                  <img src={AddIc} alt={t('common:alt.addIc')} />
                </div>
                {t('common:btn.add-new-member')}
              </>
            )}
          </a>
        </div>
        <div className={'b-step__info'}>
          <div className={'b-step__titleWrapper'}>
            <div className={'b-step__title'}>{t('common:headlines.managers')}</div>
            <a href="#" onClick={showTip('managers')}>
              <img src={InfoIc} alt={t('common:alt.infoIc')} />
            </a>
          </div>
          <div className={`b-equityAudit__text12 ${'b-step__textWrapper'}`}>
            {t('main:ea-create.executives')}
          </div>
          {state.managerUsers.length ? (
            <div className={'b-EACreate__users'}>
              {state.managerUsers.map(userId => {
                const user = usersList?.find(user => user.id === userId);
                if (user) {
                  return (
                    <div className={'b-EACreate__user'} key={`manager-${userId}`}>
                      <div className="b-EACreate__userName">{user.name}</div>
                      <div className="b-equityAudit__text16">
                        {convertUserStatus(user.status, user.status_date)}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          ) : null}
          <a href="#" className={'b-step__add'} onClick={handleEditTeamList('managers')}>
            {state.managerUsers.length ? (
              <>
                <img style={{ paddingRight: 5 }} src={EditIc} alt={t('common:alt.editIc')} />
                {t('common:btn.edit-managers')}
              </>
            ) : (
              <>
                <div className={'b-step__add__ic'}>
                  <img src={AddIc} alt={t('common:alt.addIc')} />
                </div>
                {t('common:btn.add-new-member')}
              </>
            )}
          </a>
        </div>
        <div className={`b-step__bottom`}>
          <div className={`b-step__bottom-left`}>
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={() => onChangeStep('0')}
              title={t('common:btn.back')}
            />
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={handleCancel}
              title={t('common:btn.cancel')}
            />
          </div>
          {isMobileOnly ? (
            <div className={`b-step__bottom-left`}>
              <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
                <img src={SaveIc} alt={t('common:alt.saveIc')} />
                {t('common:btn.save-exit')}
              </a>
              <Button
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleNext}
                title={t('common:btn.next')}
                enableEnter
              />
            </div>
          ) : (
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={handleNext}
              title={t('common:btn.next')}
              enableEnter
            />
          )}
        </div>
      </div>
      {state.tipType === 'team_empty' || state.tipType === 'managers_empty' ? (
        <Alert
          isOpen={true}
          onRequestClose={hideTip}
          title={t('awa:M100.title')}
          text={state.tipType === 'team_empty' ? t('awa:M100.msg') : t('awa:M101.msg')}
        />
      ) : null}
      {(state.modalType === 'team' || state.modalType === 'managers') && (
        <Modal
          title={
            state.modalType === 'team' ? t('common:headlines.add-member') : t('common:headlines.add-managers')
          }
          onClose={handleCloseModal}
        >
          <div className={'b-EAModal'}>
            <div className={'b-step__title'}>{t('common:headlines.organization-users')}</div>
            <div className={'b-step__teamModal__list'}>
              {usersList &&
                getUserList(state.teamUsers, state.managerUsers, usersList, state.modalType).map(
                  (item: EAUser) => {
                    return (
                      <div key={`user-${item.id}`} className={'b-step__teamModal__item'}>
                        <div className={'b-equityAudit__text16'}>{item.name}</div>
                        <Checkbox
                          noText
                          onChange={() => handleSelectUser('team', item.id)}
                          checked={state.tempUsers.includes(item.id)}
                        />
                      </div>
                    );
                  },
                )}
            </div>
            <div className={'b-step__teamModal__tip'}>
              <div className={'b-equityAudit__text14'}>*{t('main:add-member.received')}.</div>
            </div>
            <div className="b-EAModal__buttons">
              <Button
                className={'btn -type_form'}
                type={'transparency'}
                onPress={handleCloseModal}
                title={t('common:btn.cancel')}
              />
              <Button
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleSaveUserList}
                title={t('common:btn.save')}
                enableEnter
              />
            </div>
          </div>
        </Modal>
      )}
      {state.tipType === 'managers' || state.tipType === 'team' ? (
        <Alert
          isOpen={!!state.tipType}
          onRequestClose={hideTip}
          title={state.tipType === 'managers' ? t('awa:M41.title') : t('awa:M40.title')}
          text={state.tipType === 'managers' ? t('awa:M41.msg') : t('awa:M40.msg')}
        />
      ) : null}
    </>
  );
};

export default CreateEAStep2;
