import React, { FC, useState } from 'react';
import { DOMAIN_URI } from '../../../constants';
import { Modal, PopupPortal } from '..';
import { ImageSecured, Link } from '../../controls';
import { useTranslation } from 'react-i18next';

interface IProps {
  avatar?: string | null;
  first_name: string;
  last_name: string;
  className?: string;
}

const AvatarZoomed: FC<IProps> = ({ avatar, first_name, last_name, className }) => {
  let [zoomed, setZoomed] = useState(false);
  const { t } = useTranslation();

  const handleClick = (e: any) => {
    e.preventDefault();
    setZoomed(true);
  };

  return (
    <>
      {avatar ? (
        <Link onClick={handleClick} className={`b-avatar ${className || ''}`}>
          <ImageSecured
            src={`${DOMAIN_URI}${avatar}`}
            alt={`${first_name} ${first_name} - ${t('awa:AT26')}`}
          />
        </Link>
      ) : (
        <div className={`b-avatar ${className || ''}`}>
          {first_name[0]}
          {last_name[0]}
        </div>
      )}
      {zoomed ? (
        <PopupPortal portalElementId={'user-avatar-portal'}>
          <Modal
            minHeight={400}
            width={480}
            title={`${first_name} ${last_name}`}
            onClose={() => setZoomed(false)}
          >
            <div className="b-avatarZoomedModal">
              <div className="b-avatarZoomedModal__photo">
                <ImageSecured src={`${DOMAIN_URI}${avatar}`} alt={t('awa:AT46')} />
              </div>
            </div>
          </Modal>
        </PopupPortal>
      ) : null}
    </>
  );
};

export default AvatarZoomed;
