import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { useHistory, useParams } from 'react-router';
import { useEwpApi } from '../../../../../hooks/ewp/use-ewp-api';
import {
  EwpElementApprovalStatuses,
  EwpElementTypes,
  EWPGoalModel,
  EwpStatuses,
  IRouteParamsEwp,
} from '../../../../../models';
import { useGetEwpRoute } from '../../../../../hooks/ewp/use-get-ewp-route';
import { ROOTS } from '../../../../../routes/routes';
import { Button, ComaList, Link, Loader, SimpleTooltip } from '../../../../controls';
import { basicDateFormat } from '../../../../../helpers';
import ElementTree from '../parts/ElementTree';
import ElementStatusController from '../parts/ElementStatusController';
import { GenericNotes, HeadlineOrganization } from '../../../../common';
import { useEwpDetails } from '../../../../../hooks/ewp/use-ewp-details';
import { EwpHelpers } from '../../../../../helpers/ewpHelpers';
import { showAlert } from '../../../../common/Alert/RemoteAlert';
import { toast } from 'react-toastify';

const EwpGoalDetails = () => {
  const { t } = useTranslation();
  const { ewpDetails } = useEwpDetails();

  const { navBack } = useNavBack();
  const history = useHistory();
  const { getGoalDetails, deleteGoal } = useEwpApi();
  const { elId, orgId, id } = useParams<IRouteParamsEwp>();
  const EWP_GOAL_CREATE = useGetEwpRoute(ROOTS.EWP_GOAL_CREATE);
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState<EWPGoalModel | null>(null);
  const isAllowToEdit = data?.permissions.edit;
  const elementCanBeStarted =
    data &&
    data.approval_status === EwpElementApprovalStatuses.APPROVED &&
    data.ewp_status_id >= EwpStatuses.IN_PROGRESS &&
    data.has_approved_parents;

  useEffect(() => {
    setLoading(true);
    getGoalDetails(Number(elId), data => {
      setData(data);
      setLoading(false);
    });
  }, [elId]);

  const handleDelete = () => {
    showAlert({
      title: t('awa:M170.title'),
      text: (
        <>
          <p>{t('awa:M170.msg.p1')}</p>
          <p>{t('awa:M170.msg.p2')}</p>
        </>
      ),
      buttons: {
        left: {
          title: t('common:btn.cancel'),
          type: 'transparency',
        },
        right: {
          title: t('common:btn.yes'),
          onClick: () => {
            deleteGoal(Number(elId), () => {
              toast.success(t('awa:S19.msg'));
              history.replace(ROOTS.EWP_GOAL_LIST.replace(':orgId', orgId).replace(':id', id));
            });
          },
        },
      },
    });
  };

  if (!data) return <Loader />;

  const deleteDisabledText = EwpHelpers.getElDeleteDisabledText(data);

  return (
    <main className="b-page">
      {loading ? <Loader /> : null}
      <div className="b-page__header">
        <HeadlineOrganization
          orgName={ewpDetails?.organization_name || ''}
          orgId={ewpDetails?.organization_id || ''}
          headline={t('common:headlines.equity-work-plan')}
          customClasses={'-noMb -desktop'}
        />
        <div className="d-flex">
          {!elementCanBeStarted ? (
            <SimpleTooltip title={deleteDisabledText} id={'del-element'}>
              <Button
                disabled={!data.permissions.has_delete_right}
                type="transparency"
                className="button-mh"
                size={'small'}
                onPress={handleDelete}
                title={t('common:btn.delete')}
              />
            </SimpleTooltip>
          ) : null}
          {isAllowToEdit ? (
            <Button
              size={'small'}
              onPress={() => history.push(EWP_GOAL_CREATE, data)}
              title={t('common:btn.edit')}
            />
          ) : null}
        </div>
      </div>
      <div className="d-flex mb-5">
        <Link onClick={navBack} className="b-page-back-link mb-0">
          {t('common:tabs.goal')}
        </Link>
        <ElementStatusController
          addNoteOnAStatusChanged
          onStatusChange={(newData: EWPGoalModel) => setData(newData)}
          onApproveStatusChange={(newData: EWPGoalModel) => setData(newData)}
          data={{
            orgId: data.organization_id,
            entityId: data.id,
            status: data.status,
            approval_status: data.approval_status,
            ewp_status_id: data.ewp_status_id,
            ewp_id: data.ewp_id,
            ewp_pms: ewpDetails?.ewp_pm_users,
            has_approved_parents: data.has_approved_parents,
            status_history: data.status_history,
          }}
          type={EwpElementTypes.goal}
        />
      </div>
      <div className="contentContainer">
        <div className="font-grey mb-4">{data.title}</div>
        <div className="d-flex mb-4">
          <div className="w200px flex-shrink-0 font-navy font-m font-weight-bold">
            {t('common:label.description')}:
          </div>
          <div className="font-grey">{data.description}</div>
        </div>
        <div className="d-lg-flex mb-4">
          <div className="d-flex mr-5 mb-4 mb-xl-0">
            <div className="w200px flex-shrink-0 font-navy font-m font-weight-bold">
              {t('common:label.created-on')}:
            </div>
            <div className="font-grey">
              {data.created_at ? basicDateFormat(data.created_at) : t('common:column.n-a')}
            </div>
          </div>
          <div className="d-flex">
            <div className="flex-shrink-0 mr-3 font-navy font-m font-weight-bold">
              {t('common:label.author')}:
            </div>
            <div className="font-grey">{data.user_create_name || t('common:column.n-a')}</div>
          </div>
        </div>
        <div className="d-lg-flex mb-4">
          <div className="d-flex mr-5 mb-4 mb-xl-0">
            <div className="w200px flex-shrink-0 font-navy font-m font-weight-bold">
              {t('common:label.last-update')}:
            </div>
            <div className="font-grey">
              {data.updated_at ? basicDateFormat(data.updated_at) : t('common:column.n-a')}
            </div>
          </div>
          <div className="d-flex">
            <div className="flex-shrink-0 mr-3 font-navy font-m font-weight-bold">
              {t('common:label.editor')}:
            </div>
            <div className="font-grey">{data.user_update_name || t('common:column.n-a')}</div>
          </div>
        </div>
        <div className="d-flex mb-4">
          <div className="w200px flex-shrink-0 font-navy font-m font-weight-bold">
            {t('common:label.years')}:
          </div>
          <div className="font-grey">
            <ComaList arr={data.year_list_data.map(item => ({ title: item.label }))} uKey={'years-'} />
          </div>
        </div>
        {!loading ? (
          <GenericNotes
            isAllowToCreate={ewpDetails?.ewp_permissions.notes.create}
            entityType={'goal'}
            entityId={data.id}
          />
        ) : null}
        <ElementTree entityId={data.id} type="goal" tree={data.elements_tree} />
      </div>
    </main>
  );
};

export default EwpGoalDetails;
