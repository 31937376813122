import React, { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AvgAuditsTreeModel, AvgEAOrgTreeModel, IRouteParamsBase } from '../../../../../models';
import { basicDateFormat, isEmptyObject } from '../../../../../helpers';
import LineAveragesChart from './LineAveragesChart';
import dayjs from 'dayjs';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';

interface IProps {
  isCompare: boolean;
  chart: AvgAuditsTreeModel[];
}

const GroupAveragesChartBar = ({ isCompare, chart }: IProps) => {
  const { orgId } = useParams<IRouteParamsBase>();
  const [orgTree, setOrgTree] = useState<AvgAuditsTreeModel[]>();
  const [orgScores, setOrgScores] = useState<AvgAuditsTreeModel[]>([]);
  const { navBack } = useNavBack();

  const deepOrgs = (orgs: AvgEAOrgTreeModel[]): AvgEAOrgTreeModel[] => {
    const orgsArray: AvgEAOrgTreeModel[] = [];
    const func = (temp: AvgEAOrgTreeModel[]) => {
      if (!temp) {
        return;
      }
      temp.map(org => {
        orgsArray.push(org);
        if (org.sub_orgs.length) {
          func(org.sub_orgs);
        }
      });
    };
    func(orgs);
    return orgsArray;
  };

  useEffect(() => {
    if (isEmptyObject(chart)) {
      return;
    }
    const scores = chart.map(c => {
      return {
        ...c,
        isOpen: true,
        orgs_tree: deepOrgs(c.orgs_tree),
      };
    });
    const orTree = chart.map(c => {
      return {
        ...c,
        isOpen: true,
        orgs_tree: c.orgs_tree,
      };
    });

    setOrgTree(orTree);
    setOrgScores(scores);
  }, [chart, orgId]);

  if (!orgTree) {
    return null;
  }
  const renderTree = (orgs: AvgEAOrgTreeModel[], padding: number): ReactNode => {
    return orgs.map((org, i) => {
      return (
        <div
          key={`org-tree-avg-${org.ea_id}-${i}-${padding}`}
          className={`list ${padding > 0 ? `b-customChart__labels__children` : ''}`}
        >
          <div className="elem" style={{ paddingLeft: padding }}>
            <span>{org.org_name}</span>
            <span>
              {dayjs(org.submit_date).isValid() ? basicDateFormat(org.submit_date) : org.submit_date}
            </span>
          </div>
          {org.sub_orgs.length ? renderTree(org.sub_orgs, padding + 20) : null}
        </div>
      );
    });
  };

  const renderAverageTree = (data: AvgAuditsTreeModel[]) => {
    return data.map(d => {
      return (
        <React.Fragment key={`org-tree-${d.id}`}>
          <li onClick={navBack}>
            <span className={`arrow ${!d.isOpen ? '-close' : ''}`} />
            <div className="elem" style={{ paddingLeft: 0 }}>
              <span>{d.title}</span>
            </div>
          </li>
          {d.isOpen ? renderTree(d.orgs_tree, 0) : null}
        </React.Fragment>
      );
    });
  };

  const renderChart = () => {
    if (!orgTree) {
      return null;
    }

    return (
      <div className="w-100">
        <div className={'b-customChart__chart'}>
          <div className={'b-customChart__labels'}>
            <ul>{renderAverageTree(orgTree)}</ul>
          </div>

          <LineAveragesChart isCompare={isCompare} data={orgScores} />
        </div>
      </div>
    );
  };

  return renderChart();
};

export default GroupAveragesChartBar;
