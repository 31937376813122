import Api from '../../services/api';
import { toast } from 'react-toastify';
import { t } from 'i18next';

export enum BulkRemoveEntities {
  ORG = 'org',
  ELM = 'elm',
  EA = 'ea',
  CREDITS = 'credits',
}

const useBulkRemoveRequest = () => {
  const _getUrl = (entityType: BulkRemoveEntities) => {
    switch (entityType) {
      case BulkRemoveEntities.EA:
        return 'beloved/equity_audit/delete_equity_audit/';
      case BulkRemoveEntities.ORG:
        return 'beloved/organizations/delete_organizations/';
      case BulkRemoveEntities.CREDITS:
        return 'beloved/organization_credits/delete/';
      case BulkRemoveEntities.ELM:
        return 'beloved/elm/delete_elm/';
    }
  };

  const _getData = (entityType: BulkRemoveEntities, ids: number[]) => {
    switch (entityType) {
      case BulkRemoveEntities.EA:
        return { equity_audit_ids: ids };
      case BulkRemoveEntities.ORG:
        return { org_ids: ids };
      case BulkRemoveEntities.CREDITS:
        return { credit_ids: ids };
      case BulkRemoveEntities.ELM:
        return { elm_ids: ids };
    }
  };

  const removeEntities = async (entityType: BulkRemoveEntities, ids: number[], cb: () => void) => {
    const url = _getUrl(entityType);
    const data = _getData(entityType, ids);
    try {
      const response = await Api.delete(url, undefined, data);
      if (response) {
        cb();
      }
    } catch (e: any) {
      if (e.response.status === 400) {
        toast.error(e.response.data.msg);
      } else {
        toast.error(t('common:toast.error'));
      }
    }
  };

  return {
    removeEntities,
  };
};

export default useBulkRemoveRequest;
