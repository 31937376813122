import React from 'react';
import { SortDirection, useRowSelection } from 'react-data-grid';
import { BelEaModel, ColumnType, EAStatuses, TableDataEntities } from '../../../../../../models';
import { basicDateFormat, getBelovedEaStatusClass } from '../../../../../../helpers';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../../../../controls';
import { useHistory } from 'react-router';
import { ROOTS } from '../../../../../../routes/routes';
import { TableDateFilter } from '../../../../../common';
import { useTableDataV2 } from '../../../../../../hooks/common/use-table-data-v2';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

interface ColumnsHandlers {
  baseUrl: string;
  isList: boolean;
  avg_id: string;
}

export const getColumns = ({ baseUrl, isList, avg_id }: ColumnsHandlers): ColumnType<BelEaModel>[] => {
  const { t } = useTranslation();
  return [
    {
      key: 'organization_name',
      name: t('common:column.organization-name'),
      width: 170,
      sortable: true,
      editable: false,
      resizable: true,
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'affiliation',
      name: t('common:column.affiliation'),
      sortable: true,
      editable: false,
      resizable: true,
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'status_name',
      name: t('common:column.status'),
      sortable: true,
      editable: false,
      resizable: true,
      formatter(props) {
        return (
          <span className={`b-tableStatusMark ${getBelovedEaStatusClass(props.row.status_id)}`}>
            {props.row.status_name}
          </span>
        );
      },
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'submit_date',
      name: t('common:label.submission-date'),
      width: 90,
      sortable: true,
      editable: false,
      resizable: true,
      formatter(props) {
        return (
          <>
            {props.row.submit_date
              ? basicDateFormat(new Date(props.row.submit_date))
              : t('common:column.n-a')}
          </>
        );
      },
      headerRenderer(props) {
        const { handleSelectDateFilter, query } = useTableDataV2<BelEaModel>({
          entityName: TableDataEntities.AVG,
          baseUrl,
          enableSelectedEntities: true,
          extraData: !isList ? { average_id: avg_id, status: EAStatuses.COMPLETED } : undefined,
        });
        if (query.dateFilters) {
          return (
            <TableDateFilter
              dateFilterId={'submit_date'}
              selectedFilters={query.dateFilters['submit_date']}
              periodFieldName={'submit_date_period'}
              dateRangeFieldName={'submit_date_range'}
              onDateFilterChanged={handleSelectDateFilter}
              rdgProps={props}
              fiscalOption={true}
            />
          );
        }
      },
    },
    {
      key: 'org_type',
      name: t('common:column.org-type'),
      sortable: true,
      editable: false,
      resizable: true,
      isShow: false,
      headerCellClass: 'b-rdgTable__headerCell',
      formatter(props) {
        return <>{props.row.type}</>;
      },
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'last_update',
      name: t('common:column.last-update'),
      width: 90,
      sortable: true,
      editable: false,
      resizable: true,
      isShow: false,
      formatter(props) {
        return (
          <>
            {props.row.last_update
              ? basicDateFormat(new Date(props.row.last_update))
              : t('common:column.n-a')}
          </>
        );
      },
      headerRenderer(props) {
        const { handleSelectDateFilter, query } = useTableDataV2<BelEaModel>({
          entityName: TableDataEntities.AVG,
          baseUrl,
          enableSelectedEntities: true,
          extraData: !isList ? { average_id: avg_id, status: EAStatuses.COMPLETED } : undefined,
        });
        if (query.dateFilters) {
          return (
            <TableDateFilter
              dateFilterId={'last_update'}
              selectedFilters={query.dateFilters['last_update']}
              periodFieldName={'last_update_period'}
              dateRangeFieldName={'last_update_date_range'}
              onDateFilterChanged={handleSelectDateFilter}
              rdgProps={props}
              fiscalOption={true}
            />
          );
        }
      },
    },
    {
      key: 'debrief_date',
      name: t('common:column.debrief'),
      width: 90,
      sortable: true,
      editable: false,
      resizable: true,
      isShow: false,
      formatter(props) {
        return (
          <>
            {props.row.debrief_date
              ? basicDateFormat(new Date(props.row.debrief_date))
              : t('common:column.n-a')}
          </>
        );
      },
      headerRenderer(props) {
        const { handleSelectDateFilter, query } = useTableDataV2<BelEaModel>({
          entityName: TableDataEntities.AVG,
          baseUrl,
          enableSelectedEntities: true,
          extraData: !isList ? { average_id: avg_id, status: EAStatuses.COMPLETED } : undefined,
        });
        if (query.dateFilters) {
          return (
            <TableDateFilter
              dateFilterId={'debrief_date'}
              selectedFilters={query.dateFilters['debrief_date']}
              periodFieldName={'debrief_date_period'}
              dateRangeFieldName={'debrief_date_range'}
              onDateFilterChanged={handleSelectDateFilter}
              rdgProps={props}
              fiscalOption={true}
            />
          );
        }
      },
    },
    {
      key: 'created_at',
      name: t('common:column.create-date'),
      width: 90,
      isShow: false,
      sortable: true,
      editable: false,
      resizable: true,
      formatter(props) {
        return (
          <>
            {props.row.created_at ? basicDateFormat(new Date(props.row.created_at)) : t('common:column.n-a')}
          </>
        );
      },
      headerRenderer(props) {
        const { handleSelectDateFilter, query } = useTableDataV2<BelEaModel>({
          entityName: TableDataEntities.AVG,
          baseUrl,
          enableSelectedEntities: true,
          extraData: !isList ? { average_id: avg_id, status: EAStatuses.COMPLETED } : undefined,
        });
        if (query.dateFilters) {
          return (
            <TableDateFilter
              dateFilterId={'created_at'}
              selectedFilters={query.dateFilters['created_at']}
              periodFieldName={'created_at_period'}
              dateRangeFieldName={'created_at_date_range'}
              onDateFilterChanged={handleSelectDateFilter}
              rdgProps={props}
              fiscalOption={true}
            />
          );
        }
      },
    },
    {
      key: 'project_manager',
      name: t('common:label.organization-pm'),
      width: 90,
      sortable: true,
      editable: false,
      isShow: false,
      resizable: true,
      formatter(props) {
        if (!props.row.project_manager) {
          return <>{t('common:column.n-a')}</>;
        }
        const history = useHistory();
        const handleClick = (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          history.push(ROOTS.BELOVED_PM_PROFILE.replace(':orgId', `${props.row.organization_id}`));
        };
        return (
          <a className="b-link" href="#" onClick={handleClick}>
            {props.row.project_manager.name}
          </a>
        );
      },
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'tools',
      name: '',
      disableDrag: true,
      width: 70,
      maxWidth: 70,
    },
    {
      key: 'select',
      name: '',
      width: 40,
      maxWidth: 40,
      disableDrag: true,
      headerRenderer(props) {
        return (
          <Checkbox
            checked={props.allRowsSelected}
            className="b-equityAudits__checkbox-header"
            onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
          />
        );
      },
      formatter: props => {
        const [isRowSelected, onRowSelectionChange] = useRowSelection();

        return (
          <div className="b-rdgTable__cellEnd">
            <div className={'checkBox b-equityAudits__checkbox'} onClick={e => e.stopPropagation()}>
              <Checkbox
                checked={isRowSelected}
                onChange={e => {
                  onRowSelectionChange({
                    row: props.row,
                    checked: e.target.checked,
                    isShiftClick: false,
                  });
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];
};
