import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminToolSettingsPriceModel } from '../../../../../models';
import { basicDateFormat } from '../../../../../helpers';
import InputMask from '../../../../controls/Inputs/InputMask';

interface IProps {
  price: AdminToolSettingsPriceModel[];
  onChangePrice: (index: number, value: string) => void;
}

const PriceCard: FC<IProps> = ({ price, onChangePrice }) => {
  const { t } = useTranslation();

  const handleChangeForm = (price: number, value: string) => {
    onChangePrice(price, value);
  };

  return (
    <div className="b-resourceCard font-grey font-s pr-4">
      <div className="font-weight-bold pb-2">{t('common:headlines.debrief-pricing')}</div>
      <div className="pb-3 d-flex justify-content-between">
        <u>{t('common:label.organization-type')}</u>
        <u>{t('common:label.price')}</u>
      </div>

      {price.map((p, pIndex) => {
        return (
          <div key={`price-${p.id}`} className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>{p.org_type}</div>
              <div className="d-flex align-items-center b-credits__simpleInputWrap">
                $
                <InputMask
                  className="b-credits__simpleInput"
                  value={p.fee}
                  ariaLabel={p.name}
                  handleChange={(value: string) => handleChangeForm(pIndex, value)}
                  hideErrorBlock
                />
              </div>
            </div>
            <div className="b-credits__historyLastUpdate">
              {p.history.length
                ? t('common:headlines.change-user-date', {
                    user_name: p.history[0].user_name,
                    date: basicDateFormat(p.history[0].date),
                  })
                : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PriceCard;
