import { KeyValueModel } from '../../key-value.model';
import { ISelectOptions, ISelectGroup, BasicFileModel, BaseNameModel } from '../../global';
import { ResourceStatuses } from '../../../constants';

export interface BelResourceOptionModel {
  id: number;
  title: string;
}

export interface BelResourceStandartOptionModel {
  alias: string;
  title: string;
  children: BelResourceOptionModel[];
}

export interface BelResourceOptionsModel {
  types: ISelectOptions[];
  categories: ISelectGroup[];
  tags: ISelectOptions[];
  statuses: KeyValueModel<{
    alias: string;
    title: string;
    dependent: ISelectOptions[];
  }>;
  ownerships: ISelectOptions[];
  distributions: ISelectOptions[];
  sectors: ISelectOptions[];
  access_types: ISelectOptions[];
  elm_domains: BelResourceOptionModel[];
  standards: BelResourceStandartOptionModel[];
}

export interface BelCreateResourceModel {
  title: string;
  description: string;
  notes: string;
  time_to_read: string;
  authors: string;
  published_date?: string;
  status?: string;
  type: number;
  access_type: number;
  ownership: number;
  distribution: number;
  resource_categories: number[];
  resource_tags: number[];
  resource_sectors: number[];
  thumbnail: number[];
  docs: number[];
  resource_comments: number[];
  resource_referenced_links: string[];
}

export interface BelResourceCommentModel {
  id: number;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  description: string;
  author: BaseNameModel;
}

export interface BelResourceNavModel {
  id: number;
  referenced_links: string[];
}

interface ResourceAuthor {
  id: number;
  name: string;
  enabled_view: boolean;
}

export interface BelResourceModel extends BelResourceShortModel {
  time_to_read: string;
  thumbnail: BasicFileModel[];
}

export interface BelResourceShortModel {
  id: number;
  guid: string;
  type: BaseNameModel;
  created_at: string;
  updated_at: string;
  title: string;
  description: string;
  authors: string;
  published_date: string | null;
  ownership: BaseNameModel;
  distribution: BaseNameModel;
  access_type: BaseNameModel;
  is_favorite: boolean;
  status: ResourceStatuses;
  submitter: ResourceAuthor | null;
  categories: BelResourceOptionModel[];
  sectors: BelResourceOptionModel[];
  docs: BasicFileModel[];
  comments: BelResourceCommentModel[];
  notes: string | null;
  tags: BelResourceOptionModel[];
  referenced_links: string[];
  reviewer: ResourceAuthor | null;
}

export enum ResourceFileType {
  PDF = 'PDF',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DEFAULT = 'DEFAULT',
}

export type NewResourceOptionType = 'categories' | 'tags' | 'sectors' | 'access_type';

export enum ResourceShareEnum {
  ORGANIZATION_PM = 'PM',
  ORGANIZATION_ALL = 'ALL',
  COHORT_GENERAL = 'cohort-general',
  COHORT_SESSION = 'cohort-session',
}

export enum ResourceShareOrgType {
  PM = 1,
  ALL = 2,
}

export type LinkResourceEntityType = 'key_action' | 'equity_audit';

export interface LinkResourceSelectedItemModel {
  id: number;
  title: string;
  referenced_link: string[];
}

export interface LinkResourceDataModel {
  entity_title: string;
  organization_id: number;
  organization_name: string;
  resources: LinkResourceSelectedItemModel[];
}
