import { Button, Input, Select, Autocomplete } from '../../../../controls';
import React from 'react';
import { ISelectOptions } from '../../../../../models';
import { IAddressFields, IErrors } from '../OfficeAddressContainer';
import styles from '../OfficeAddressStyle.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  fields: IAddressFields;
  errors: IErrors;
  countries: Array<ISelectOptions>;
  handleOnBluer: (field: string) => () => void;
  onChange: (field: string) => (value: string) => void;
  onSubmit: () => void;
  onBackNavigation: () => void;
  onHandleChange: (place: any, ref: any) => void;
  officeType: string;
  isInternation: boolean;
}

const SameAsHeadquarterView = ({
  countries,
  fields,
  errors,
  handleOnBluer,
  onChange,
  onSubmit,
  onBackNavigation,
  officeType,
  onHandleChange,
  isInternation,
}: IProps) => {
  const { t } = useTranslation();

  const blockSubmit = () => {
    if (officeType === '1') {
      return false;
    }
    if (officeType === '2') {
      return (
        fields.state.length === 0 ||
        fields.country.length === 0 ||
        fields.postal.length === 0 ||
        fields.city.length === 0 ||
        fields.address === undefined
      );
    }
    if (officeType === '3') {
      return (
        fields.state.length === 0 ||
        fields.country.length === 0 ||
        fields.postal.length === 0 ||
        fields.city.length === 0
      );
    }
  };

  return (
    <>
      <div className={'container-row'}>
        <Select
          value={countries.find(f => f.value === fields.country.toLowerCase()) || null}
          placeholder={t('common:label.country')}
          options={countries}
          error={errors.country}
          handleChange={onChange('country')}
          handleUnselect={handleOnBluer('country')}
          disabled={officeType === '1'}
          autoFocus
        />
      </div>
      {officeType !== '3' && (
        <div className={'container-row m-0'}>
          <Autocomplete
            placeholder={t('common:label.address')}
            onChange={onChange}
            errors={errors}
            fields={fields}
            ariaLabel="Address"
            disabled={officeType === '1'}
            onChangeLocation={onHandleChange}
          />
        </div>
      )}
      {officeType !== '3' && (
        <div className={'container-row m-0'}>
          <Input
            className={styles.input}
            value={fields.suite}
            placeholder={isInternation ? t('common:label.international.suite') : t('common:label.us.suite')}
            error={errors.suite}
            handleChange={onChange('suite')}
            handleUnselect={handleOnBluer('suite')}
            type={'text'}
            disabled={officeType === '1'}
          />
        </div>
      )}
      <div className={'container-row m-0'}>
        <Input
          className={styles.input}
          value={fields.city}
          placeholder={isInternation ? t('common:label.international.city') : t('common:label.us.city')}
          error={errors.city}
          disabled={officeType === '1'}
          handleChange={onChange('city')}
          handleUnselect={handleOnBluer('city')}
          type={'text'}
        />
      </div>
      <div className={'container-row m-0'}>
        <div className={'container-row m-0'}>
          <Input
            className={styles.input}
            value={fields.state}
            placeholder={isInternation ? t('common:label.international.state') : t('common:label.us.state')}
            error={errors.state}
            handleChange={onChange('state')}
            disabled={officeType === '1'}
            handleUnselect={handleOnBluer('state')}
            type={'text'}
          />
        </div>
      </div>
      <div className={'container-row m-0'}>
        <Input
          className={styles.input}
          value={fields.postal}
          placeholder={isInternation ? t('common:label.international.postal') : t('common:label.us.postal')}
          error={errors.postal}
          // disable={(officeType === '1' || !(fields.state && !fields.postal && officeType !== '3') && officeType !== '3')}
          disabled={officeType === '1'}
          handleChange={onChange('postal')}
          handleUnselect={handleOnBluer('postal')}
          type={'text'}
        />
      </div>
      <div className={'container-row m-0'}>
        <Button
          onPress={onBackNavigation}
          size={'middle'}
          title={t('common:btn.back')}
          type={'transparency'}
          disabled={false}
        />
        <Button
          size={'middle'}
          onPress={onSubmit}
          title={t('common:btn.next')}
          type={'orange-light'}
          disabled={blockSubmit()}
          enableEnter
        />
      </div>
    </>
  );
};

export default SameAsHeadquarterView;
