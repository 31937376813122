import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { OrgProfileTabs, OrgBreadcrumbs, HeadlineOrganization } from '../../../common';
import { Loader, Select, Input, Button, Collapse, Autocomplete } from '../../../controls';
import styles from './OrgProfile.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrganizationDetails,
  updateOrganizationDetails,
} from '../../../../store/organization/organizationActions';
import {
  OrganizationDetailsModel,
  CreateOrganizationModel,
  KeyValueModel,
  IRouteParamsBase,
  SubOrganizationModel,
  ApiAddressModel,
  ISelectOptions,
} from '../../../../models';
import { StateType } from '../../../../store/reducers';
import { ROOTS } from '../../../../routes/routes';
import { countries } from '../../../../constants';
import { getLocation, httpUrl, phone, validate } from '../../../../helpers';
import { useRole } from '../../../../helpers/roles';
import { IAddressFields } from '../../Profile/YourOfficeAddress/OfficeAddressContainer';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getUser } from '../../../../store/profile/profileActions';
import PhoneInput from '../../../controls/Inputs/PhoneInput';
import { useValidationPhoneNumber } from '../../../../hooks/common/use-validation-phone-number';

interface IState {
  formData: CreateOrganizationModel | null;
  errors: KeyValueModel<string> | null;
  wasEdit: boolean;
  currentTab: number | null;
  userName: string;
  showCreteSubOrg: boolean;
}

const website = {
  required: ['website'],
  custom: [httpUrl(['website'])],
};

const OrgProfile = () => {
  const { t } = useTranslation();
  const { orgId } = useParams<IRouteParamsBase>();
  const dispatch = useDispatch();
  const access = useRole();
  const initialState: IState = {
    formData: null,
    errors: {},
    userName: '',
    wasEdit: false,
    showCreteSubOrg: false,
    currentTab: null,
  };
  const [state, setState] = useState<IState>(initialState);
  const currentOrg: OrganizationDetailsModel | null = useSelector((state: StateType) => {
    return state.organization.currentOrg;
  });

  // const currentUser: UserModel | undefined = useSelector((state: StateType) => {
  //   return state.profile.authUser?.user;
  // });

  const currentUser = useSelector((state: StateType) => state.profile.authUser);
  const { errorPhoneNumber, setErrorPhoneNumber, onBlurPhoneValidation } = useValidationPhoneNumber();

  const orgTypes: ISelectOptions[] | null = useSelector(
    (state: StateType) => state.utils.data.organizationsTypes || null,
  );

  const countryValue =
    countries.find(
      f =>
        f.value ===
        (state.formData &&
          state.formData.address &&
          state.formData.address.country &&
          state.formData.address.country.toLowerCase()),
    ) || countries.find(f => f.value === 'us');
  const isInternation = countryValue && !(countryValue.value && countryValue.value.toLowerCase() === 'us');

  const validator = {
    required: ['website', 'type', 'address', 'city', 'postal', 'name', 'country', 'state', 'phone_number'],
    custom: [httpUrl(['website']), phone(['phone_number'])],
    requiredFieldName: {
      postal: isInternation ? t('common:label.international.postal') : t('common:label.us.postal'),
      state: isInternation ? t('common:label.international.state') : t('common:label.us.state'),
      city: isInternation ? t('common:label.international.city') : t('common:label.us.city'),
    },
  };

  const history = useHistory();

  useEffect(() => {
    if (currentOrg && orgTypes && currentUser) {
      setState(prevState => {
        return {
          ...prevState,
          userName: `${currentUser.user.first_name} ${currentUser.user.last_name}`,
          errors: {},
          formData: {
            name: currentOrg.name || '',
            website: currentOrg.website?.toLowerCase() || '',
            type: Number(orgTypes.find(type => type.label === currentOrg.type_name)?.value) || 0,
            division: currentOrg.division || '',
            org_pm: currentOrg.org_pm || '',
            pm_phone_number: currentOrg.pm_phone_number,
            phone_number: currentOrg.phone_number,
            address: {
              // @ts-ignore
              country: 'us',
              ...currentOrg.address,
            },
          },
        };
      });
    }
  }, [currentOrg, orgTypes, currentUser]);

  const handleChangeSelect = (name: string, data: ISelectOptions) => {
    if (!state.formData) return null;
    switch (name) {
      case 'type':
        setState({
          ...state,
          formData: {
            ...state.formData,
            [name]: data.value,
          },
          wasEdit: true,
          errors: {},
        });
        break;
      case 'country':
        setState({
          ...state,
          formData: {
            ...state.formData,
            address: {
              ...state.formData.address,
              [name as string]: data.value,
            } as ApiAddressModel,
          },
          wasEdit: true,
          errors: {},
        });
        break;
    }
  };

  const handleChangeInput = (name: string, value: string) => {
    if (!state.formData) return null;
    switch (name) {
      case 'phone_number': {
        setErrorPhoneNumber('');
        setState({
          ...state,
          formData: {
            ...state.formData,
            [name]: value,
          },
          wasEdit: true,
          errors: {},
        });
        break;
      }
      case 'website':
        setState({
          ...state,
          formData: {
            ...state.formData,
            [name]: value,
          },
          wasEdit: true,
          errors: {},
        });
        break;
      case 'name':
        setState({
          ...state,
          userName: value,
          wasEdit: true,
          errors: {},
        });
        break;
      default:
        setState({
          ...state,
          formData: {
            ...state.formData,
            address: {
              ...state.formData.address,
              [name]: value,
            } as ApiAddressModel,
          },
          wasEdit: true,
          errors: {},
        });
        break;
    }
  };

  const updateOrganizationDetailsSuccess = () => {
    setState({
      ...state,
      wasEdit: false,
    });
    dispatch(getOrganizationDetails({ orgId: Number(orgId) }));
    toast.success(t('awa:N13.msg'));
  };

  const handleSubmit = () => {
    const validateData = {
      ...state.formData,
      ...state.formData?.address,
      name: state.userName,
    };
    const { errors } = validate(validator, validateData);
    setState({ ...state, errors: errors });

    if (!Object.keys(errors || {}).length && !errorPhoneNumber) {
      const formData: any = {
        ...state.formData,
      };

      formData.website = (formData.website || '').toLowerCase();

      dispatch(
        updateOrganizationDetails({
          data: formData,
          orgId: Number(orgId),
          callback: updateOrganizationDetailsSuccess,
        }),
      );
    }
  };

  const handleCancel = () => {
    dispatch(getUser({}));
    setState({
      ...state,
      wasEdit: false,
    });
  };

  const BlockButton = () => {
    return Object.keys(state.errors || {}).length || !state.wasEdit;
  };

  const handleOnBlur = (field: string) => () => {
    let errorCase;
    switch (field) {
      case 'name':
        errorCase = validate({ required: ['name'] }, { name: state.userName });
        break;
      case 'website':
        errorCase = validate(website, { website: state.formData?.website });
        break;
      case 'type':
        errorCase = validate({ required: ['type'] }, { type: state.formData?.type });
        break;
      case 'city':
        errorCase = validate(
          {
            required: ['city'],
            requiredFieldName: {
              city: isInternation ? t('common:label.international.city') : t('common:label.us.city'),
            },
          },
          { city: state.formData?.address?.city },
        );
        break;
      case 'postal':
        errorCase = validate(
          {
            required: ['postal'],
            requiredFieldName: {
              postal: isInternation ? t('common:label.international.postal') : t('common:label.us.postal'),
            },
          },
          { postal: state.formData?.address?.postal },
        );
        break;
      case 'country':
        errorCase = validate({ required: ['country'] }, { country: state.formData?.address?.country });
        break;
      case 'state':
        errorCase = validate(
          {
            required: ['state'],
            requiredFieldName: {
              state: isInternation ? t('common:label.international.state') : t('common:label.us.state'),
            },
          },
          { state: state.formData?.address?.state },
        );
        break;
      case 'address':
        errorCase = validate({ required: ['address'] }, { address: state.formData?.address?.address });
        break;
      default:
        errorCase = { errors: state.errors };
    }
    setState({ ...state, errors: errorCase.errors });
  };

  const handleChangeOrganization = (sub_org: SubOrganizationModel) => {
    if (access(['project_manager', 'project_admin'])) {
      history.push(ROOTS.ORG_PROFILE.replace(':orgId', `${sub_org.id}`));
    }
  };

  const handleAddressChange =
    (name: string) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      if (state.errors?.[name]) {
        delete state.errors[name];
      }
      setState(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          address: {
            ...prevState.formData?.address,
            address: target.value,
          },
        } as any,
        errors: state.errors,
      }));
    };

  const locationChangeHandler = async (place: any, ref: any) => {
    const address_details = await getLocation(place, ref);
    // @ts-ignore
    setState((prevState: IState) => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          address: address_details,
        },
        errors: state.errors,
        wasEdit: true,
      };
    });
  };

  const isEdit = access(['project_admin', 'project_manager']);

  return (
    <>
      {!state.showCreteSubOrg && (
        <main className={'b-page'}>
          {state.formData && orgTypes ? (
            <>
              <HeadlineOrganization
                orgName={currentOrg?.name || ''}
                orgId={orgId}
                headline={t('common:tabs.profile')}
                customClasses={'-desktop'}
                showActions
              />
              <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
              <OrgProfileTabs activeItemAlias={'org-profile'} />
              <div>
                <div className={`${styles.form} mb-0`}>
                  <div className={styles.form__part}>
                    <Select
                      name={'type'}
                      value={orgTypes.find(type => Number(type.value) === state.formData?.type)}
                      placeholder={t('common:label.type')}
                      ariaLabel="Type"
                      options={orgTypes}
                      error={(state.errors && state.errors.type) || ''}
                      handleChange={(value: ISelectOptions) => handleChangeSelect('type', value)}
                      height={52}
                      disabled={!isEdit}
                    />
                  </div>
                  <div className={styles.form__part}>
                    <Input
                      name={'name'}
                      error={(state.errors && state.errors.name) || ''}
                      value={state.formData.org_pm || ''}
                      type={'text'}
                      disabled={true}
                      placeholder={t('common:label.pm')}
                      ariaLabel="PM"
                      handleUnselect={handleOnBlur('name')}
                      handleChange={(value: string) => handleChangeInput('name', value)}
                    />
                  </div>
                </div>
                <div className={`${styles.form} m-0`}>
                  <div className={styles.form__part}>
                    <Input
                      name={'website'}
                      error={(state.errors && state.errors.website) || ''}
                      value={state.formData.website}
                      type={'text'}
                      placeholder={t('common:label.website')}
                      ariaLabel="Website"
                      handleUnselect={handleOnBlur('website')}
                      disabled={!isEdit}
                      handleChange={(value: string) => handleChangeInput('website', value)}
                    />
                  </div>
                  <div className={styles.form__part}>
                    <Select
                      name={'country'}
                      value={countryValue}
                      placeholder={t('common:label.country')}
                      ariaLabel="Country"
                      options={countries}
                      disabled={!isEdit}
                      error={(state.errors && state.errors.country) || ''}
                      handleUnselect={handleOnBlur('country')}
                      handleChange={(value: ISelectOptions) => handleChangeSelect('country', value)}
                      height={52}
                    />
                  </div>
                </div>
                <div className={`${styles.form} m-0`}>
                  <div className={styles.form__part}>
                    <Autocomplete
                      placeholder={t('common:label.address')}
                      ariaLabel="Address"
                      onChange={handleAddressChange}
                      errors={state.errors as any}
                      fields={(state.formData.address || {}) as IAddressFields}
                      disabled={!isEdit}
                      onChangeLocation={locationChangeHandler}
                    />
                  </div>
                  <div className={styles.form__part}>
                    <Input
                      name={'suite'}
                      error={(state.errors && state.errors.suite) || ''}
                      value={state.formData.address?.suite || ''}
                      type={'text'}
                      ariaLabel="Suite/Office/Floor"
                      disabled={!isEdit}
                      handleUnselect={handleOnBlur('suite')}
                      placeholder={
                        isInternation ? t('common:label.international.suite') : t('common:label.us.suite')
                      }
                      handleChange={(value: string) => handleChangeInput('suite', value)}
                    />
                  </div>
                </div>
                <div className={`${styles.form} m-0`}>
                  <div className={styles.form__part}>
                    <Input
                      name={'city'}
                      error={(state.errors && state.errors.city) || ''}
                      value={state.formData.address?.city || ''}
                      type={'text'}
                      placeholder={
                        isInternation ? t('common:label.international.city') : t('common:label.us.city')
                      }
                      disabled={!isEdit}
                      handleUnselect={handleOnBlur('city')}
                      handleChange={(value: string) => handleChangeInput('city', value)}
                    />
                  </div>
                  <div className={styles.form__part}>
                    <Input
                      name={'state'}
                      error={(state.errors && state.errors.state) || ''}
                      value={state.formData.address?.state || ''}
                      type={'text'}
                      ariaLabel="State"
                      disabled={!isEdit}
                      handleUnselect={handleOnBlur('state')}
                      placeholder={
                        isInternation ? t('common:label.international.state') : t('common:label.us.state')
                      }
                      handleChange={value => handleChangeInput('state', value)}
                    />
                  </div>
                </div>
                <div className={`${styles.form} m-0`}>
                  <div className={styles.form__part}>
                    <Input
                      name={'postal'}
                      error={(state.errors && state.errors.postal) || ''}
                      value={state.formData.address?.postal || ''}
                      type={'text'}
                      ariaLabel="Zip"
                      disabled={!isEdit}
                      placeholder={
                        isInternation ? t('common:label.international.postal') : t('common:label.us.postal')
                      }
                      handleUnselect={handleOnBlur('postal')}
                      handleChange={(value: string) => handleChangeInput('postal', value)}
                    />
                  </div>
                  <div className={styles.form__part}>
                    <PhoneInput
                      error={errorPhoneNumber || ''}
                      value={state.formData.phone_number || ''}
                      handleChange={(value: string) => handleChangeInput('phone_number', value)}
                      placeholder={t('common:label.phone-number')}
                      handleUnselect={handleOnBlur('phone_number')}
                      onBlurPhoneValidation={onBlurPhoneValidation}
                    />
                  </div>
                </div>
                <div className={styles.form__additional}>
                  <div className={styles.form__additionalCol}>
                    {!!currentOrg?.sub_orgs.length && isEdit && (
                      <Collapse
                        title={'Sub-Organizations'}
                        hasChildren={!!currentOrg.sub_orgs.length && isEdit}
                      >
                        <ul className={styles.form__suborganization_list}>
                          {isEdit &&
                            currentOrg?.sub_orgs.map((sub_org, index) => (
                              <li key={index}>
                                <a href="#" onClick={() => handleChangeOrganization(sub_org)}>
                                  {sub_org.name}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </Collapse>
                    )}
                  </div>
                  <div className={styles.form__additionalCol}>
                    {isEdit && (
                      <button
                        className={styles.form__addLink}
                        onClick={() => {
                          history.push(ROOTS.CREATE_SUB_ORG.replace(':orgId', `${orgId}`));
                        }}
                      >
                        {t('common:btn.add-sub-organization')}
                      </button>
                    )}
                    {isEdit && (
                      <div className={styles.form__buttons}>
                        <Button
                          onPress={handleCancel}
                          title={t('common:btn.cancel')}
                          size={'large'}
                          disabled={!state.wasEdit}
                          type={'transparency'}
                        />
                        <Button
                          onPress={handleSubmit}
                          title={t('common:btn.save')}
                          size={'large'}
                          disabled={!!BlockButton()}
                          type={'orange-light'}
                          enableEnter
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loader fitParent />
          )}
        </main>
      )}
    </>
  );
};

export default OrgProfile;
