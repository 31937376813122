import { useEffect, useState } from 'react';
import Api from '../../services/api';
import { useParams } from 'react-router';
import { IRouteParamsBase, ISelectOptions } from '../../models';

export const useGetStrategyOptions = () => {
  const { orgId, id } = useParams<IRouteParamsBase>();

  let [goals, setGoals] = useState<ISelectOptions[] | null>(null);
  let [owners, setOwners] = useState<ISelectOptions[] | null>(null);

  useEffect(() => {
    Api.get(`organizations/${orgId}/ewp/${id}/strategies/get_available_goals/`).then(res => {
      if (res) {
        setGoals(res);
      }
    });

    fetchOwners();
  }, []);

  const fetchOwners = (callback?: () => void) => {
    Api.get(`organizations/${orgId}/ewp/${id}/strategies/get_available_owners/`).then(res => {
      if (res) {
        setOwners(res);
        callback && callback();
      }
    });
  };

  return {
    goals,
    owners,
    fetchOwners,
  };
};
