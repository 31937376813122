import React, { useEffect, useState } from 'react';
import { IRouteParamsBase, CohortMessageModel, BelCreateMessageModel } from '../../../../../models';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Loader } from '../../../../controls';
import { basicDateFormat, isEmptyObject, isValidFields, validate } from '../../../../../helpers';
import { ROOTS } from '../../../../../routes/routes';
import { useTranslation } from 'react-i18next';
import {
  createCohortMessage,
  deleteCohortMessage,
  getCohortMessage,
  updateCohortMessage,
} from '../../../../../store/cohort/cohortActions';
import { toast } from 'react-toastify';
import { useGetCohortDetails } from '../../../../../hooks/beloved/cohorts/use-get-cohort-details';
import { Modals, ModalsModel } from '../../../../../constants';
import { Alert } from '../../../../common';
import { useIsAccessEditCohort } from '../../../../../hooks/beloved/cohorts/use-edit-access';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';

interface IRouteParams extends IRouteParamsBase {
  id: string;
  messageId: string;
}

interface BelMessageFormModel {
  id?: number;
  created_at?: string | Date;
  submitter?: {
    id: number;
    name: string;
  };
  cohort_id?: number;
  subject: string;
  description: string;
}

const BelCohortMessageForm = () => {
  const validator = {
    required: ['subject', 'description'],
  };
  const { t } = useTranslation();
  const { id, messageId } = useParams<IRouteParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [message, setMessage] = useState<BelMessageFormModel>({
    subject: '',
    description: '',
  });
  const [modals, setModals] = useState<ModalsModel>({});
  const isCreate = messageId === 'create';
  let [loading, setLoading] = useState(false);
  const cohort = useGetCohortDetails();
  const isAccess = useIsAccessEditCohort(cohort);

  useEffect(() => {
    if (isCreate) {
      return;
    }
    dispatch(
      getCohortMessage({
        data: {
          id: Number(messageId),
        },
        callback: (res: CohortMessageModel) => {
          setMessage(res);
        },
      }),
    );
  }, []);

  const navBack = (e?: any) => {
    e && e.preventDefault();
    history.go(-1);
  };

  const handleSave = () => {
    const errors = validate(validator, message).errors;

    if (!isEmptyObject(errors)) {
      return;
    }
    if (!cohort) {
      return;
    }
    setLoading(true);
    const data: BelCreateMessageModel = {
      subject: message.subject,
      description: message.description,
      cohort_id: cohort.id,
    };
    if (!isCreate) {
      data.id = Number(messageId);
      dispatch(
        updateCohortMessage({
          data: data,
          callback: (res: CohortMessageModel | null) => {
            if (res) {
              setMessage(res);
              toast.success(t('common:toast.message-was-updated'));
            }
            setLoading(false);
          },
        }),
      );
      return;
    }
    dispatch(
      createCohortMessage({
        data: data,
        callback: (res: CohortMessageModel) => {
          if (res) {
            toast.success(t('common:toast.message-was-created'));
            history.push(ROOTS.BELOVED_COHORT_DETAILS.replace(':id', `${res.cohort_id}`));
          }
          setLoading(false);
        },
      }),
    );
  };

  const handleChangeInput = (name: string, value: string) => {
    setMessage({
      ...message,
      [name]: value,
    });
  };

  const handleRemoveMessage = () => {
    if (!id) {
      return;
    }
    dispatch(
      deleteCohortMessage({
        data: {
          id: Number(message.id),
        },
        callback: () => {
          toast.success(t('common:toast.message-was-removed'));
          history.push(ROOTS.BELOVED_COHORT_DETAILS.replace(':id', `${id}`));
        },
      }),
    );
  };

  const openModal = (modal: string) => {
    setModals({
      ...modals,
      [modal]: true,
    });
  };

  const closeModal = (modal: string) => {
    setModals({
      ...modals,
      [modal]: false,
    });
  };

  const isDisableButton = isValidFields(validator, message || {});
  const renderContent = () => {
    if (loading && message) {
      return <Loader fitParent />;
    }

    return (
      <div className={`b-EADetails b-elm b-cohortBel`}>
        <div className="b-EADetails__header">
          {isCreate ? (
            <h1 className="pageContainer__title b-EADetails__pageTitle">
              {t('common:headlines.new-message')}
            </h1>
          ) : (
            <h1 className="pageContainer__title b-EADetails__pageTitle">
              {t('common:headlines.edit-message')}
            </h1>
          )}
        </div>
        <div className={`b-elmDetails__panel`}>
          <div className={` b-cohortBel__msg-description`}>
            <div className="b-elmDetails__panelRow">
              <div className="b-elmDetails__panelParam -w50">
                <div className={`b-elmDetails__title label`}>{t('common:label.date')}:</div>
                <div className="b-elmDetails__val">
                  <span>{basicDateFormat(message.created_at || new Date())}</span>
                </div>
              </div>
            </div>
            <div className="b-elmDetails__panelParam mb-10">
              <div className={`b-elmDetails__title label`}>{t('common:label.subject')}:</div>
              <input
                autoFocus
                name={'subject'}
                aria-label="Title"
                placeholder={t('common:label.cohort.subject-title')}
                className="b-resourceForm__fieldInput"
                type="text"
                value={message.subject}
                onChange={e => handleChangeInput('subject', e.target.value)}
              />
            </div>
            <div className="b-elmDetails__panelParam mb-10">
              <div className={`b-elmDetails__title label`}>{t('common:label.description')}:</div>
              <textarea
                name={'description'}
                aria-label="Description"
                placeholder={t('common:label.cohort.subject-desc')}
                className="b-resourceForm__fieldInput"
                rows={15}
                value={message.description}
                onChange={e => handleChangeInput('description', e.target.value)}
              />
            </div>
          </div>
          {isAccess ? (
            <div className="b-EAModal__buttons mt-5">
              <Button
                className={'btn -type_form'}
                type={'transparency'}
                onPress={navBack}
                title={t('common:btn.cancel')}
              />
              {message.id ? (
                <Button
                  width={'128px'}
                  onPress={() => openModal(Modals.isDelete)}
                  title={t('common:btn.delete')}
                  size={'large'}
                  type={'orange-light'}
                />
              ) : null}
              <Button
                disabled={isDisableButton}
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={() => handleSave()}
                title={t('common:btn.save')}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <main className="b-page">
      <h1 className="b-page__title">
        <EmptyLink onClick={navBack} className="b-button-icon-back" />
        {t('common:headlines.cohort-program')}: <span className="mark">{cohort?.name}</span>
      </h1>
      {renderContent()}
      {modals.isDelete && (
        <Alert
          isOpen={modals.isDelete}
          onRequestClose={() => closeModal(Modals.isDelete)}
          title={t('awa:N35.title')}
          text={
            <div>
              <p>{t('awa:N35.msg')}</p>
            </div>
          }
          buttons={{
            left: {
              title: t('common:btn.cancel'),
              type: 'transparency',
              onClick: () => closeModal(Modals.isDeleteTask),
            },
            right: {
              type: 'orange-light',
              onClick: handleRemoveMessage,
              title: t('common:btn.delete'),
              enableEnter: true,
            },
          }}
        />
      )}
      {loading && <Loader fitParent />}
    </main>
  );
};

export default BelCohortMessageForm;
