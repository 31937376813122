import { ROOTS } from '../routes/routes';

export default class AuthNavigationHelper {
  public static getUrl = (step: number) => {
    switch (step) {
      case 4: //Join To Organization
        return ROOTS.ORGANIZATION_PREVIEW;
      case 1: //Confirm Email
        return '';
      case 3: //Create Sub Organisation
        return ROOTS.SUB_ORGANIZATION_PREVIEW;
      case 5: //Waiting for approve
        return ROOTS.REQUEST_SUBMIT_PENDING;
      case 6: //Create profile
        return ROOTS.CREATE_PROFILE.replace(':step', `role`);
      case 7: //Associate organization
        return ROOTS.ASSOCIATE_ORGANIZATION_PREVIEW;
      default:
        return '';
    }
  };
}
