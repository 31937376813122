import React, { FC, ReactElement, useState } from 'react';
import Button, { IProps as ButtonProps } from '../../controls/Button/Button';
import { Link, ModalView } from '../../controls';
import styles from './Alert.module.scss';
import CloseIc from '../../../assets/images/icons/round-navigation-close.svg';
import { useTranslation } from 'react-i18next';

interface IConfig {
  title?: string;
  text?: string | ReactElement;
  buttons?: {
    left?: {
      title: string;
      onClick?: () => void;
      type?: ButtonProps['type'];
      enableEnter?: boolean;
    };
    right?: {
      title: string;
      onClick?: () => void;
      type?: ButtonProps['type'];
      enableEnter?: boolean;
    };
  };
  contentStyles?: any;
  textStyles?: any;
}

let showAlert: (config: IConfig) => void;

const TestAlertV2: FC = () => {
  const [config, setConfig] = useState<IConfig | null>(null);
  const { t } = useTranslation();

  showAlert = (newConfig: IConfig) => {
    setConfig(newConfig);
  };

  const handleClick = (handler?: () => void) => () => {
    setConfig(null);
    handler && handler();
  };

  if (!config) return null;
  const { title, text, buttons, contentStyles } = config;

  const divTextStyles = {
    marginBottom: !buttons ? 0 : undefined,
    ...(config.textStyles || {}),
  };

  return (
    <ModalView
      contentStyles={contentStyles ? contentStyles : { width: 350 }}
      isOpen={true}
      onRequestClose={() => setConfig(null)}
    >
      <div className={styles.container}>
        <Link onClick={() => setConfig(null)} className={styles.close}>
          <img src={CloseIc} alt={t('common:alt.closeIc')} />
        </Link>
        {title ? <div className={styles.title}>{title}</div> : null}
        {text ? (
          <div className={styles.text} style={divTextStyles}>
            {text}
          </div>
        ) : null}
        {buttons ? (
          <div className={styles.btns}>
            {buttons.left ? (
              <Button
                className={styles.btn}
                title={buttons.left.title}
                onPress={handleClick(buttons.left.onClick)}
                type={buttons.left.type}
                enableEnter={buttons.left.enableEnter}
              />
            ) : null}
            {buttons.right ? (
              <Button
                className={`${styles.btn} ${styles.btn__right}`}
                title={buttons.right.title}
                onPress={handleClick(buttons.right.onClick)}
                type={buttons.right.type}
                enableEnter={buttons.right.enableEnter}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </ModalView>
  );
};

export default TestAlertV2;
export { showAlert };
