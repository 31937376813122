import React, { FC, ReactElement } from 'react';
import { ModalView } from '../../controls';
import Button, { IProps as ButtonProps } from '../../controls/Button/Button';
import styles from './Alert.module.scss';
import CloseIc from '../../../assets/images/icons/round-navigation-close.svg';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  title?: string;
  text?: string | ReactElement;
  buttons?: {
    left?: {
      title: string;
      onClick: () => void;
      type?: ButtonProps['type'];
      enableEnter?: boolean;
    };
    right?: {
      title: string;
      onClick: () => void;
      type?: ButtonProps['type'];
      enableEnter?: boolean;
    };
  };
  contentStyles?: any;
}

const Alert: FC<IProps> = ({ isOpen, onRequestClose, title, text, buttons, contentStyles }) => {
  const { t } = useTranslation();
  const handleClose = (e: any) => {
    e.preventDefault();
    onRequestClose();
  };

  return (
    <ModalView
      contentStyles={contentStyles ? contentStyles : { width: 350 }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div className={styles.container}>
        <a onClick={handleClose} href="#" className={styles.close}>
          <img src={CloseIc} alt={t('common:alt.closeIc')} />
        </a>
        {title ? <div className={styles.title}>{title}</div> : null}
        {text ? (
          <div className={styles.text} style={!buttons ? { marginBottom: 0 } : undefined}>
            {text}
          </div>
        ) : null}
        {buttons ? (
          <div className={styles.btns}>
            {buttons.left ? (
              <Button
                className={styles.btn}
                title={buttons.left.title}
                onPress={buttons.left.onClick}
                type={buttons.left.type}
                enableEnter={buttons.left.enableEnter}
              />
            ) : null}
            {buttons.right ? (
              <Button
                className={`${styles.btn} ${styles.btn__right}`}
                title={buttons.right.title}
                onPress={buttons.right.onClick}
                type={buttons.right.type}
                enableEnter={buttons.right.enableEnter}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </ModalView>
  );
};

export default Alert;
