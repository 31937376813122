import Api from '../../services/api';
import { useSelectCurrentOrg } from '../org/use-select-current-org';
import { useEffect, useState } from 'react';
import { BelSessionModel, IRouteParamsBase } from '../../models';
import { useParams } from 'react-router';

export const useGetOrgCohortSessions = () => {
  const currentOrg = useSelectCurrentOrg();
  const { id } = useParams<IRouteParamsBase>();
  let [data, setData] = useState<BelSessionModel[] | null>(null);

  const fetchData = async () => {
    if (currentOrg) {
      const response = await Api.get(`beloved/cohort_session/`, {
        cohort_id: id,
        page_size: 1000,
      });
      if (response) {
        return response;
      }
    }
  };

  useEffect(() => {
    if (currentOrg) {
      fetchData().then(res => {
        if (res) {
          setData(res);
        }
      });
    }
  }, [currentOrg]);

  return data;
};
