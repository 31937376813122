import React, { useState } from 'react';
import EmailNotVerifiedView from './EmailNotVerifiedView';
import { useDispatch, useSelector } from 'react-redux';
import { resentEmailConfirmRequest } from '../../../../store/authData/authDataActions';
import { StateType } from '../../../../store/reducers';
import { PopupSignInModel } from '../SignIn/SignInContainer';
import { useHistory, useLocation } from 'react-router-dom';
import { resendSecondaryEmail } from '../../../../store/profile/profileActions';

interface IProps {
  handleShowNotification: (status: any) => void;
  showNotification: PopupSignInModel | null;
}

const EmailNotVerifiedContainer = ({ handleShowNotification, showNotification }: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state: StateType) => state.profile.authUser?.user);
  const [successMsg, setSuccessMsg] = useState<string>('');

  const resendWasSuccess = (response: any) => {
    if (response.status) {
      setSuccessMsg(response.msg);
      setTimeout(() => {
        setSuccessMsg('');
        handleShowNotification(null);
      }, 3000);
    } else {
      setSuccessMsg(response.msg);
    }
  };

  const handleResendConfirmEmail = () => {
    if (
      location.state &&
      location.state.search &&
      location.state.search.includes('secondary_verification_hash=')
    ) {
      const secondaryHash = new URLSearchParams(location.state.search).get('secondary_verification_hash');
      dispatch(
        resendSecondaryEmail({
          data: {
            hash: secondaryHash,
          },
          callback: resendWasSuccess,
        }),
      );
      return;
    }
    const hash = new URLSearchParams(history.location.search).get('verification_hash');
    if (user) {
      let requestBody: any = {
        username: user.email,
      };
      if (hash && !user.email) {
        requestBody = {
          hash: hash,
        };
      }
      dispatch(
        resentEmailConfirmRequest({
          data: requestBody,
          callback: resendWasSuccess,
        }),
      );
    }
  };
  return (
    <EmailNotVerifiedView
      onResendConfirmEmail={handleResendConfirmEmail}
      showNotification={showNotification}
      successMsg={successMsg}
    />
  );
};

export default EmailNotVerifiedContainer;
