import React, { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Button, Loader } from '../../../../../controls';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import { EwpCreateStepEnum, EWPModel, EWPUpdateData } from '../../../../../../models';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../../store/reducers';
import { basicDateFormat } from '../../../../../../helpers';
import { useTranslation } from 'react-i18next';

interface IProps {
  onChangeStep: (step: EwpCreateStepEnum) => void;
  handleCancel: () => void;
  saveData: (data: EWPUpdateData, isNext: boolean) => void;
}

export const EWPDateResult: FC<IProps> = ({ onChangeStep, handleCancel, saveData }) => {
  const { t } = useTranslation();
  const createEWPData: EWPModel | null = useSelector((state: StateType) => state.ewp.createEWPData);

  const handleSaveExit = (e: any) => {
    e.preventDefault();
    saveData(
      {
        ewp_screen: EwpCreateStepEnum.dateResult,
      },
      false,
    );
  };

  const handleNext = () => {
    saveData(
      {
        ewp_screen: EwpCreateStepEnum.vision,
      },
      true,
    );
  };

  if (!createEWPData) return <Loader fitParent />;

  return (
    <>
      <div className={'b-EACreate__step'}>
        <div className="b-EACreate__header">
          <h1 className={'pageContainer__title b-EATypo__pageTitle'}>{t('common:headlines.new-ewp')}</h1>
          {!isMobileOnly && (
            <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
              <img src={SaveIc} alt={t('common:alt.saveIc')} />
              {t('common:btn.save-exit')}
            </a>
          )}
        </div>
        <div className={'b-step__orgWrapper'}>
          <div className="b-equityAudit__text16">{t('common:headlines.organization')}</div>
          <div className={'b-step__orgText'}>{createEWPData.organization_name}</div>
        </div>
        <div className={'b-step__orgWrapper'}>
          <div className="b-equityAudit__text16">{t('common:headlines.tentative-start-date')}</div>
          <div className={'b-step__orgText'}>
            {(createEWPData.start_date && basicDateFormat(createEWPData.start_date || '')) ||
              t('common:column.n-a')}
          </div>
        </div>
        <div className={'b-step__info'}>
          <div className={`b-equityAudit__text16 b-step__step4Text`}>{t('main:ewp-create.date-result')}</div>
        </div>
        <div className={`b-step__bottom`}>
          <div className={`b-step__bottom-left`}>
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={() => onChangeStep(EwpCreateStepEnum.date)}
              title={t('common:btn.back')}
            />
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={handleCancel}
              title={t('common:btn.cancel')}
            />
          </div>
          {isMobileOnly ? (
            <div className={`b-step__bottom-left`}>
              <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
                <img src={SaveIc} alt={t('common:alt.saveIc')} />
                {t('common:btn.save-exit')}
              </a>
              <Button
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleNext}
                title={t('common:btn.next')}
                enableEnter
              />
            </div>
          ) : (
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={handleNext}
              title={t('common:btn.next')}
              enableEnter
            />
          )}
        </div>
      </div>
    </>
  );
};
