import { Button } from '../../../../../controls';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EwpPreparationStepEnum } from '../../../../../../models';
import { Stepper } from '../../../../../common';
import { useHistory } from 'react-router';

interface IProps {
  setCurrentStep: (step: EwpPreparationStepEnum) => void;
  cancelStep: () => void;
}

export const PreparationEWPStep1 = ({ setCurrentStep, cancelStep }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <div className="b-step__container -centerContent">
        <div className={'flex-center -column'}>
          <span className={'b-step__subTitle mb-3'}>{t('common:headlines.ewp-preparation')}</span>
          <h1 className={'b-step__bigTitle'}>{t('main:ewp-create.intro-2.msg.p4')}</h1>
        </div>
        <Stepper totalSteps={4} currentStep={1} />
        <div className="b-step__p mt-4 mb-5">{t('awa:M125.msg.p1')}</div>
        <div className="b-step__wBox w-100">
          <div className={'b-step__orangeBold flex-center mb-3'}>{t('main:ewp-create.task')}</div>
          <div className="b-step__numItem font-grey">{t('awa:M125.msg.p2')}</div>
        </div>
        <div className={`b-step__bottom`}>
          <div className={`b-step__bottom-left`}>
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={cancelStep}
              title={t('common:btn.cancel')}
            />
          </div>
          <div className={'flex-center'}>
            {!history.location.state?.ewpId ? (
              <Button
                className={'btn -type_form mr-2'}
                type={'bordered'}
                onPress={() => setCurrentStep(EwpPreparationStepEnum.intro2)}
                title={t('common:btn.back')}
              />
            ) : null}
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={() => setCurrentStep(EwpPreparationStepEnum.step2)}
              title={t('common:btn.next')}
              enableEnter
            />
          </div>
        </div>
      </div>
    </>
  );
};
