import React from 'react';
import { Footer, Header } from '../../../common';
import Logo from '../../../../assets/images/icons/group.png';
import styles from '../AssociationOrganizationPreview/AssociationOrganizationPreviewStyle.module.scss';
import { Button, Image } from '../../../controls';
import { useHistory } from 'react-router-dom';
import { ROOTS } from '../../../../routes/routes';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import { useTranslation } from 'react-i18next';

interface IProps {
  loader?: string;
}

const AssociationSendPreview = ({ loader }: IProps) => {
  const { t } = useTranslation();

  const history = useHistory();
  const user = useSelector((state: StateType) => state.profile.authUser);

  const onNext = () => {
    history.push(ROOTS.CREATE_PROFILE.replace(':step', `role`));
  };

  return (
    <>
      {loader}
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        <main className={'white-container content-center-wrapper'}>
          <div className={'container-page-center'}>
            <div className={'container-row'}>
              <Image width={'190px'} height={'190px'} source={Logo} alt={t('awa:AT37')} />
            </div>
            <div className={'container-row m-0'}>
              <span className={`sub-title ${styles.sub_title}`}>{t('awa:M6.msg.p1')}</span>
            </div>
            <div className={'container-row m-0'}>
              <span className={`sub-title ${styles.sub_title}`}>{t('awa:M6.msg.p2')}</span>
            </div>
            <div className={'container-row'}>
              {user && user.next_step !== 5 && (
                <Button
                  type={'orange-light'}
                  onPress={onNext}
                  title={t('common:btn.next')}
                  className={styles.button}
                  enableEnter
                />
              )}
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default AssociationSendPreview;
