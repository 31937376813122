import React from 'react';
import Api from '../../../services/api';
import { getOrgIdFormRoute } from '../../../helpers';
import { useTranslation } from 'react-i18next';
const fileDownload = require('js-file-download');

const DownloadElmTemplate = () => {
  const orgId = getOrgIdFormRoute();
  const { t } = useTranslation();

  const handleClick = (e: any) => {
    e.preventDefault();
    Api.get(`organizations/${orgId}/elm/file_actions/get_csv_template/`).then(resp => {
      fileDownload(resp, 'import_users_template.csv');
    });
  };

  return (
    <>
      <a href="#" onClick={handleClick} className="b-elmCreate__downloadLink">
        {t('common:label.download-template-file')}
      </a>
    </>
  );
};

export default DownloadElmTemplate;
