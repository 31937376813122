import React from 'react';
import Logo from '../../../../../assets/images/user_created.png';
import styles from './AddedNewUserSuccessStyles.module.scss';
import { isMobileOnly } from 'react-device-detect';
import { Image } from '../../../../controls';
import { useTranslation } from 'react-i18next';

const AddedNewUserSuccess = () => {
  const { t } = useTranslation();

  return (
    <>
      <main className={`${styles.container} ${isMobileOnly ? styles.mobileContainer : ''}`}>
        <div className={`text-container`}>
          <div className={'container-row'}>
            <Image width={'190px'} height={'190px'} source={Logo} alt={t('awa:AT39')} />
          </div>
          <div className={'container-row'}>
            <h1 className={styles.title}>{t('main:user-created.title')}</h1>
          </div>
          <div className={'container-row'}>
            <p className={styles.text}>{t('main:user-created.msg')}</p>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddedNewUserSuccess;
