import { FormatterProps, SortDirection, useRowSelection } from 'react-data-grid';
import { Checkbox } from '../../../../controls';
import { ColumnType, OrganizationUserModel } from '../../../../../models';
import React from 'react';
import { convertUserStatus } from '../../../../../helpers';
import { t } from 'i18next';

const renderSelectFormatter = (props: FormatterProps<any>) => {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();
  return (
    <div className={'checkBox'} onClick={e => e.stopPropagation()}>
      <Checkbox
        checked={isRowSelected}
        onChange={e => {
          onRowSelectionChange({
            row: props.row,
            checked: e.target.checked,
            isShiftClick: false,
          });
        }}
      />
    </div>
  );
};

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

export const getColumns = (): ColumnType<OrganizationUserModel>[] => [
  {
    key: 'first_name',
    name: t('common:column.first'),
    width: 130,
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'last_name',
    width: 130,
    name: t('common:column.last'),
    sortable: false,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'user_division',
    name: t('common:column.div-dept-unit'),
    sortable: false,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'user_title',
    name: t('common:column.title'),
    sortable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'user_role_name',
    name: t('common:column.awa-role'),
    sortable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'status',
    name: t('common:column.status'),
    width: 200,
    sortable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter: props => {
      return <>{convertUserStatus(props.row.status, props.row.status_date)}</>;
    },
  },
  {
    key: 'tools',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
  },
  {
    key: 'select',
    name: '',
    width: 60,
    maxWidth: 60,
    disableDrag: true,
    headerRenderer(props) {
      return (
        <Checkbox
          checked={props.allRowsSelected}
          onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
        />
      );
    },
    formatter: renderSelectFormatter,
  },
];
