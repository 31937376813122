import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { ChartBar, CompareAgainstGlobalModal, HeadlineOrganization } from '../../../../common';
import { useHistory, useParams } from 'react-router-dom';
import { Checkbox, Link, Loader, SimpleTooltip } from '../../../../controls';
import { AvgSubstandardModel, ChartLabelModel, IRouteParamsBase } from '../../../../../models';
import { Modals, ModalsModel } from '../../../../../constants';
import { useTranslation } from 'react-i18next';
import { ROOTS } from '../../../../../routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { useSelectCurrentOrg } from '../../../../../hooks/org/use-select-current-org';
import { getAvgChart } from '../../../../../store/avg/avgActions';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';

interface IState {
  isCompare: boolean;
}

const GroupAveragesChart = () => {
  const { t } = useTranslation();
  const [state, setState] = useState<IState>({
    isCompare: false,
  });
  const [modals, setModals] = useState<ModalsModel>({});
  const { isCompare } = state;
  const history = useHistory();
  const { orgId, id } = useParams<IRouteParamsBase>();
  const [chart, setChart] = useState<AvgSubstandardModel | null>(null);
  const isBeloved = !!location.pathname.match('beloved');
  const currentOrg = useSelectCurrentOrg();
  const dispatch = useDispatch();
  const activeChart = useSelector((state: StateType) => state.avg.activeChart);
  const { navBack } = useNavBack();

  useEffect(() => {
    if (activeChart && activeChart.id === Number(id)) {
      setChart(activeChart.avgChart);
      return;
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    // Data by ID
    dispatch(
      getAvgChart({
        org_id: orgId,
        id: Number(id),
        isBeloved: isBeloved,
        callback: res => {
          if (res) {
            setChart(res.substandard_chart);
          }
        },
      }),
    );
  };

  const switchCheckbox = (field: 'isCompare') => {
    setState(prevState => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const openModal = (modal: Modals) => {
    setModals(prevState => ({
      ...prevState,
      [modal]: true,
    }));
  };

  const closeModal = (modal: Modals) => {
    setModals(prevState => ({
      ...prevState,
      [modal]: false,
    }));
  };

  const handleRedirectToSelectedEas = () => {
    history.replace(
      isBeloved
        ? ROOTS.BEL_GROUP_AVERAGES.replace(':avg_id', id)
        : ROOTS.GROUP_AVERAGES.replace(':orgId', `${orgId}`).replace(':avg_id', id),
    );
  };

  const handleRowClick = (row: ChartLabelModel) => {
    history.push(
      isBeloved
        ? ROOTS.BEL_GROUP_AVERAGES_CHART_ORG.replace(':avg_id', id).replace(':id', `${row.id}`)
        : ROOTS.GROUP_AVERAGES_CHART_ORG.replace(':orgId', `${orgId}`)
            .replace(':avg_id', id)
            .replace(':id', `${row.id}`),
      {
        audit_tree: [chart?.audits_tree_data.find(f => f.id === row.id)],
      },
    );
  };

  const renderContent = () => {
    if (!chart) {
      return <Loader fitParent />;
    }
    if (chart) {
      return (
        <main className={'pageContainer pageContainer__content'}>
          <div className="b-report">
            {isMobileOnly && (
              <div className="b-report__notification">
                <b className="b-report__notificationTitle">{t('main:reports.ea-completed.title')}</b>
                <span>{t('main:reports.ea-completed.msg')}</span>
              </div>
            )}

            <div className="b-report__header">
              {isMobileOnly && <div className="b-report__arrow" />}
              <HeadlineOrganization
                headline={t('common:btn.equity-audits')}
                orgName={t('common:headlines.org-and-sub-organizations', {
                  organization_name: currentOrg?.name,
                })}
                orgId={orgId}
                customClasses={'b-report__pageTitle'}
              />
              <div className="b-report__pageSubTitle">
                <h2 className="b-page__title2 font-navy d-flex">
                  <Link className="b-report__backBtn" onClick={navBack}>
                    <div className="back-icon" />
                  </Link>
                  <span>{t('common:tabs.group-average')}: &nbsp;</span>

                  <SimpleTooltip title={t('common:tooltip.ea-avg-btn')} id={'active-view-averages'}>
                    <Link
                      onClick={handleRedirectToSelectedEas}
                      className="b-report__avg-subTitle orange-light-text ellipse b-link-icon -right"
                    >
                      {activeChart && activeChart.name}
                    </Link>
                  </SimpleTooltip>
                </h2>
              </div>
            </div>
            <div className={'b-chart__chartAveragesWrap'}>
              <div className="b-report__holder">
                <ChartBar chart={chart} isCompare={isCompare} rowClick={handleRowClick} />
              </div>
              <div className={'b-chart__compareBlock'}>
                <Checkbox
                  onChange={() => switchCheckbox('isCompare')}
                  checked={state.isCompare}
                  id="compareCheck"
                  className={'b-chart__checkbox'}
                >
                  <span className={'b-chart__compareBlock__text'}>
                    {t('common:checkbox.compare-against-global')}
                  </span>
                  <button
                    className="b-report__btnInfo mt-1"
                    onClick={() => openModal(Modals.isGlobalResultInfo)}
                  >
                    <span className="sr-only">{t('common:btn.information')}</span>
                  </button>
                </Checkbox>
              </div>
            </div>
          </div>
          <div className={'b-chart__standards'}>
            <span>{t('main:reports.click-for-sub-org')}</span>
          </div>
        </main>
      );
    }
  };

  return (
    <>
      {renderContent()}
      {modals.isGlobalResultInfo ? (
        <CompareAgainstGlobalModal onClose={() => closeModal(Modals.isGlobalResultInfo)} />
      ) : null}
    </>
  );
};

export default GroupAveragesChart;
