import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { HeaderRendererProps, SortDirection } from 'react-data-grid';

interface DraggableHeaderRendererProps<T> {
  onColumnsReorder: (sourceKey: string, targetKey: string) => void;
  column: any;
  mainContent: React.ComponentType<HeaderRendererProps<T, unknown>> | null;
}

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

const getStyles = (isOver: boolean, isDragging: boolean) => {
  const styles: any = {
    opacity: isDragging ? 0.5 : 1,
    backgroundColor: isOver ? '#ececec' : undefined,
    cursor: 'move',
    width: 'calc(100% - 15px)',
  };
  return styles;
};

export default function headerRenderer<R>(props: any, mainContent: any) {
  const { column } = props;

  if (mainContent) {
    return mainContent;
  }

  if (!column.sortable) {
    return <div className="b-rdgTable__headerCell">{props.column.name}</div>;
  }

  return (
    <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
      {props.column.name}
      <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
    </div>
  );
}

export function DraggableHeaderRenderer<R>({
  onColumnsReorder,
  column,
  mainContent,
  ...props
}: DraggableHeaderRendererProps<R>) {
  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN_DRAG',
    item: { key: column.key },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'COLUMN_DRAG',
    drop({ key }: { key: string }) {
      onColumnsReorder(key, column.key);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <>
      <div
        ref={ref => {
          drag(ref);
          drop(ref);
        }}
        className={'b-rdgTable__headerCell'}
        style={getStyles(isOver, isDragging)}
      >
        {headerRenderer({ column, ...props }, mainContent)}
      </div>
    </>
  );
}
