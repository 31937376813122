import { useState } from 'react';
import { Cropper } from 'react-cropper';

export const useImageCropper = () => {
  const [imgToCrop, setImgToCrop] = useState('');
  const [cropper, setCropper] = useState<Cropper>();

  const getCroppedFile = (): File | undefined => {
    if (!cropper) return;
    const base64 = cropper.getCroppedCanvas().toDataURL();
    function dataURLtoFile(dataurl: string, filename: string) {
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)?.[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    return dataURLtoFile(base64, 'test');
  };

  const moveImageToCrop = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImgToCrop(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  return {
    moveImageToCrop,
    imgToCrop,
    setImgToCrop,
    setCropper,
    getCroppedFile,
  };
};
