import { BelEaActionsTypes, BEL_GET_EA_DETAILS_SUCCESS } from './equityAuditActions';
import { BelEaDetailsModel } from '../../../models';
import { LOGOUT_SUCCESS } from '../../authData/authDataActions';

export type BelEaState = {
  currentEa: BelEaDetailsModel | null;
};

const initialState: BelEaState = {
  currentEa: null,
};

const belOrganizationsReducer = (state: BelEaState = initialState, action: BelEaActionsTypes): BelEaState => {
  switch (action.type) {
    case BEL_GET_EA_DETAILS_SUCCESS: {
      return {
        ...state,
        currentEa: action.payload,
      };
    }
    case LOGOUT_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default belOrganizationsReducer;
