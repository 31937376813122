import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, PopupPortal } from '..';
import { Button } from '../../controls';
import { FilterItemModel } from '../../../models';
import { DataTableFilterModel, QueryParamsModel } from '../../../hooks/common/use-table-data';
import { isEmptyObject } from '../../../helpers';

interface IProps {
  query: QueryParamsModel;
  isFiltersChanged: boolean;
  handleSelectFilter: (val: number | string, fieldName: string) => void;
  handleApplyFilter: () => void;
  filterData: DataTableFilterModel;
  filters: FilterItemModel[];
  additionalFilter?: JSX.Element;
}

const FilterModalButton: FC<IProps> = ({
  filters,
  filterData,
  handleSelectFilter,
  handleApplyFilter,
  isFiltersChanged,
  query,
  additionalFilter,
}) => {
  const { t } = useTranslation();
  let [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveFilter = () => {
    handleApplyFilter();
    setOpen(false);
  };
  return (
    <>
      <button
        aria-label="Click to Filter"
        onClick={() => setOpen(true)}
        className={`
                    b-tableTool
                    -mr
                    -filter
                    -colorGrey
                    ${!isEmptyObject(query.filters) ? '-active' : ''}
                  `}
      >
        {t('common:btn.filter')}
      </button>
      {open ? (
        <PopupPortal portalElementId={'table-filter-portal'}>
          <Modal
            width={650}
            title={t('common:btn.filter')}
            onClose={handleClose}
            classNameTitle="b-filtersModal__modalTitle"
          >
            <div className="b-filtersModal">
              <div className="b-filtersModal__list b-filtersModal__list_fullWidth">
                <div className="b-filtersModal__title">{t('common:filter.by')}</div>
                <ul>
                  {filters.map(filter => {
                    return (
                      <React.Fragment key={`filter-name-${filter.fieldName}`}>
                        <li>
                          <span>{filter.title}:</span>
                        </li>
                        <li>
                          <ul>
                            {filter.items.map(item => {
                              const isActive = filterData[filter.fieldName]?.includes(item.value);
                              return (
                                <li key={`filter-i-${filter.fieldName}-${item.value}`}>
                                  <a
                                    href="#"
                                    className={isActive ? '-active' : ''}
                                    onClick={e => {
                                      e.preventDefault();
                                      handleSelectFilter(item.value, filter.fieldName);
                                    }}
                                  >
                                    {item.label}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      </React.Fragment>
                    );
                  })}
                  {additionalFilter}
                </ul>
              </div>
            </div>
            <div className="b-filtersModal__buttons">
              <Button
                disabled={!isFiltersChanged}
                onPress={handleSaveFilter}
                title={t('common:btn.filter')}
                size={'large'}
                type={'orange-light'}
              />
            </div>
          </Modal>
        </PopupPortal>
      ) : null}
    </>
  );
};

export default FilterModalButton;
