import React from 'react';
import { Button, Image } from '../../../controls';
import Logo from '../../../../assets/images/icons/create.png';
import './AccountCreatebStyle.scss';
import { Footer, Header } from '../../../common';
import { useTranslation } from 'react-i18next';

interface IProps {
  loader?: string;
  onNavigate: () => void;
}

const AccountCreatedView = ({ loader, onNavigate }: IProps) => {
  const { t } = useTranslation();
  return (
    <>
      {loader}
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        <main className={'white-container content-center-wrapper'}>
          <div className={'container-page-center'}>
            <div className={'container-row'}>
              <Image width={'190px'} height={'190px'} source={Logo} alt={t('awa:AT1')} />
            </div>
            <div className={'container-row'}>
              <h1 className={'b-title blue-text font-l'}>{t('auth:account-created.title')}</h1>
            </div>
            <div className={'container-row'}>
              <span className={'text-description gray-text text-center'}>
                {t('auth:account-created.msg')}
              </span>
            </div>
            <div className={'container-row container-button-wrapper'}>
              <Button
                onPress={onNavigate}
                title={t('common:btn.login')}
                type={'orange-light'}
                size={'small'}
                disabled={false}
              />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default AccountCreatedView;
