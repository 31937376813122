import React from 'react';
import { SortDirection, useRowSelection } from 'react-data-grid';
import { ColumnType, TableDataEntities, CreditModel } from '../../../../../models';
import { basicDateFormat } from '../../../../../helpers';
import { Checkbox } from '../../../../controls';
import { t } from 'i18next';
import { TableDateFilter } from '../../../../common';
import { useTableDataV2 } from '../../../../../hooks/common/use-table-data-v2';
import { useHistory } from 'react-router';
import { ROOTS } from '../../../../../routes/routes';
import { belGetOrganizationDetails } from '../../../../../store/beloved/organizations/organizationsActions';
import { useDispatch } from 'react-redux';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

export const getColumns = (): ColumnType<CreditModel>[] => [
  {
    key: 'organization_name',
    name: t('common:column.organization-name'),
    width: 160,
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      const history = useHistory();
      const dispatch = useDispatch();
      const handleClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(
          belGetOrganizationDetails({
            orgId: Number(props.row.organization_id),
            callback: () => {
              history.push(ROOTS.BELOVED_ORG_CREDITS.replace(':orgId', String(props.row.organization_id)));
            },
          }),
        );
      };
      return (
        <a className="b-link" href="#" onClick={handleClick}>
          {props.row.organization_name}
        </a>
      );
    },
  },
  {
    key: 'affiliation',
    name: t('common:column.affiliation'),
    width: 130,
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'org_type',
    name: t('common:column.org-type'),
    sortable: true,
    editable: false,
    resizable: true,
    headerCellClass: 'b-rdgTable__headerCell',
    formatter(props) {
      return <>{props.row.org_type}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'name_pm',
    name: t('common:column.project-manager'),
    width: 140,
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'credit_name',
    name: t('main:credits.credit-type'),
    width: 90,
    sortable: true,
    editable: false,
    resizable: true,
    formatter(props) {
      return <>{props.row.credit_name || t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'status_name',
    name: t('common:label.status-name'),
    width: 90,
    sortable: true,
    editable: false,
    resizable: true,
    formatter(props) {
      return <>{props.row.status_name || t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'created_at',
    name: t('common:column.create-date'),
    width: 90,
    sortable: true,
    editable: false,
    resizable: true,
    formatter(props) {
      return (
        <>{props.row.created_at ? basicDateFormat(new Date(props.row.created_at)) : t('common:column.n-a')}</>
      );
    },
    headerRenderer(props) {
      const { handleSelectDateFilter, query } = useTableDataV2<CreditModel>({
        baseUrl: 'beloved/organization_credits/',
        entityName: TableDataEntities.CREDIT_LIST,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'created_at'}
            selectedFilters={query.dateFilters['created_at']}
            periodFieldName={'created_at_period'}
            dateRangeFieldName={'created_at_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
            fiscalOption={true}
          />
        );
      }
    },
  },
  {
    key: 'fee',
    name: t('common:label.fee'),
    width: 90,
    sortable: true,
    editable: false,
    isShow: false,
    resizable: true,
    formatter(props) {
      return <>{props.row.fee || t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'payment_date',
    name: t('common:label.payment-date'),
    width: 90,
    sortable: true,
    editable: false,
    isShow: false,
    resizable: true,
    formatter(props) {
      return (
        <>
          {props.row.payment_date
            ? basicDateFormat(new Date(props.row.payment_date))
            : t('common:column.n-a')}
        </>
      );
    },
    headerRenderer(props) {
      const { handleSelectDateFilter, query } = useTableDataV2<CreditModel>({
        baseUrl: 'beloved/organization_credits/',
        entityName: TableDataEntities.CREDIT_LIST,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'payment_date'}
            selectedFilters={query.dateFilters['payment_date']}
            periodFieldName={'payment_date_period'}
            dateRangeFieldName={'payment_date_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
            fiscalOption={true}
          />
        );
      }
    },
  },
  {
    key: 'beloved_leader',
    name: t('common:label.beloved-user'),
    width: 90,
    sortable: true,
    editable: false,
    isShow: false,
    resizable: true,
    formatter(props) {
      return (
        <>
          {props.row.beloved_leader?.first_name} {props.row.beloved_leader?.last_name}
        </>
      );
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'updated_at',
    name: t('common:label.last-update'),
    width: 90,
    sortable: true,
    editable: false,
    isShow: false,
    resizable: true,
    formatter(props) {
      return (
        <>{props.row.updated_at ? basicDateFormat(new Date(props.row.updated_at)) : t('common:column.n-a')}</>
      );
    },
    headerRenderer(props) {
      const { handleSelectDateFilter, query } = useTableDataV2<CreditModel>({
        baseUrl: 'beloved/organization_credits/',
        entityName: TableDataEntities.CREDIT_LIST,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'updated_at'}
            selectedFilters={query.dateFilters['updated_at']}
            periodFieldName={'updated_at_period'}
            dateRangeFieldName={'updated_at_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
            fiscalOption={true}
          />
        );
      }
    },
  },
  {
    key: 'acquisition_method',
    name: t('main:credits.acquisition-method'),
    width: 90,
    sortable: true,
    editable: false,
    isShow: false,
    resizable: true,
    formatter(props) {
      return <>{props.row.acquisition_method_name || t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'tools',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
  },
  {
    key: 'actions',
    name: '',
    width: 40,
    maxWidth: 40,
    disableDrag: true,
    headerRenderer(props) {
      return (
        <Checkbox
          className={`b-equityAudits__checkbox-header`}
          checked={props.allRowsSelected}
          onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
        />
      );
    },
    formatter: props => {
      const [isRowSelected, onRowSelectionChange] = useRowSelection();

      return (
        <div className="b-rdgTable__cellEnd">
          <div className={'checkBox b-equityAudits__checkbox'} onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={isRowSelected}
              onChange={e => {
                onRowSelectionChange({
                  row: props.row,
                  checked: e.target.checked,
                  isShiftClick: false,
                });
              }}
            />
          </div>
        </div>
      );
    },
  },
];
