import React from 'react';

import MyAccountTabs from '../../../common/MyAccountTabs/MyAccountTabs';
import { useTranslation } from 'react-i18next';

const TermsConditions = () => {
  const { t } = useTranslation();

  return (
    <>
      <main className={'pageContainer pageContainer__content'}>
        <h1 className={'pageContainer__title'}>{t('common:headlines.my-account')}</h1>
        <MyAccountTabs activeItemAlias={'terms-conditions'} />
        <div className={'b-formAccount'}>
          <h2>{t('pp:personal.p1')}</h2>
          <p>{t('pp:personal.p2')}</p>
          <p>{t('pp:personal.p3')}</p>
          <h3>{t('pp:personal.p4')}</h3>
          <p>
            {t('pp:personal.p5')} (“<b>{t('pp:personal.p6')}</b>“, “<b>{t('pp:personal.p7')}</b>“,{' '}
            {t('pp:personal.p8')} “<b>{t('pp:personal.p9')}</b>“). {t('pp:personal.p10')} “
            <b>{t('pp:personal.p11')}</b>“), {t('pp:personal.p12')} “<b>Website</b>”). {t('pp:personal.p14')}{' '}
            “<b>{t('pp:personal.p15')}</b>”). {t('pp:personal.p16')} “<b></b>” {t('pp:personal.p17')}
            {t('pp:personal.p18')}
          </p>
          <h3>{t('pp:personal.p19')}</h3>
          <p>{t('pp:personal.p20')}</p>
          <h3>{t('pp:personal.p21')}</h3>
          <p>{t('pp:personal.p22')}</p>
          <h3>{t('pp:personal.p23')}</h3>
          <p>{t('pp:personal.p24')}</p>
          <h3>{t('pp:personal.p25')}</h3>
          <p>{t('pp:personal.p26')}</p>
          <ul>
            <li>{t('pp:personal.p27')}</li>
            <li>{t('pp:personal.p28')}</li>
            <li>{t('pp:personal.p29')}</li>
            <li>{t('pp:personal.p30')}</li>
            <li>{t('pp:personal.p31')}</li>
            <li>{t('pp:personal.p32')}</li>
            <li>{t('pp:personal.p33')}</li>
            <li>{t('pp:personal.p34')}</li>
            <li>{t('pp:personal.p35')}</li>
          </ul>
          <h3>{t('pp:personal.p36')}</h3>
          <p>
            {t('pp:personal.p37')} “<b>{t('pp:personal.p38')}</b>”) {t('pp:personal.p39')}
          </p>
          <h3>{t('pp:personal.p40')}</h3>
          <p>{t('pp:personal.p41')}</p>
          <h3>{t('pp:personal.p42')}</h3>
          <p>{t('pp:personal.p43')}</p>
          <p>{t('pp:personal.p44')}</p>
          <h3>{t('pp:personal.p45')}</h3>
          <p>{t('pp:personal.p46')}</p>
          <h3>{t('pp:personal.p47')}</h3>
          <p>{t('pp:personal.p48')}</p>
          <h3>{t('pp:personal.p49')}</h3>
          <p>{t('pp:personal.p50')}</p>
          <h3>{t('pp:personal.p51')}</h3>
          <p>{t('pp:personal.p52')}</p>
          <h3>{t('pp:personal.p53')}</h3>
          <p>
            {t('pp:personal.p54')} (“<b>{t('pp:personal.p55')}</b>”). {t('pp:personal.p56')}
          </p>
          <h3>{t('pp:personal.p57')}</h3>
          <p>{t('pp:personal.p58')}</p>
          <h3>{t('pp:personal.p59')}</h3>
          <p>{t('pp:personal.p60')}</p>
          <h3>{t('pp:personal.p61')}</h3>
          <p>{t('pp:personal.p62')}</p>
          <h3>{t('pp:personal.p63')}</h3>
          <p>{t('pp:personal.p64')}</p>
          <h3>{t('pp:personal.p65')}</h3>
          <p>{t('pp:personal.p66')}</p>
          <h3>{t('pp:personal.p67')}</h3>
          <p>{t('pp:personal.p68')}</p>
          <h3>{t('pp:personal.p69')}</h3>
          <p>{t('pp:personal.p70')}</p>
          <h3>{t('pp:personal.p71')}</h3>
          <p>{t('pp:personal.p72')}</p>
          <h3>{t('pp:personal.p73')}</h3>
          <p>{t('pp:personal.p74')}</p>
          <h3>{t('pp:personal.p75')}</h3>
          <p>
            {t('pp:personal.p76')} <a href="mailto:privacy@wearebeloved.org">privacy@wearebeloved.org</a>.
          </p>
        </div>
      </main>
    </>
  );
};

export default TermsConditions;
