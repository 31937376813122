import React, { FC } from 'react';
import { EAStatuses, EquityAuditModel } from '../../../models';
import { t } from 'i18next';

interface IProps {
  ea: EquityAuditModel;
}

const EAStatusLabel: FC<IProps> = ({ ea }) => {
  switch (ea.ea_status_id) {
    case EAStatuses.IN_PREPARATION:
      return <div className="b-status -inPrep">{t('common:status.in-preparation')}</div>;
    case EAStatuses.PREP_COMPLETED:
      return <div className="b-status -prepCompl">{t('common:status.preparation-completed')}</div>;
    case EAStatuses.IN_PROGRESS:
      return <div className="b-status -inProgress">{t('common:status.in-progress')}</div>;
    case EAStatuses.COMPLETED:
      return <div className="b-status -completed">{t('common:status.completed')}</div>;
    default:
      return null;
  }
};

export default EAStatusLabel;
