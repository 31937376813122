import React from 'react';
import JoinOrganizationPreviewView from './JoinOrganizationPreviewView';

interface IProps {
  history: any;
}

const JoinOrganizationPreviewContainer = ({ history }: IProps) => {
  const handleChangeStep = (route: string) => {
    history.push(route);
  };

  return <JoinOrganizationPreviewView disable={false} handleChangeStep={handleChangeStep} />;
};

export default JoinOrganizationPreviewContainer;
