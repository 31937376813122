import React, { FC, useEffect, useState } from 'react';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import { Button, Checkbox, Select } from '../../../../../controls';
import {
  ElmQuestionModel,
  ElmQuestionOptionModel,
  ElmTakingModel,
  HandleSaveType,
  ISelectOptions,
} from '../../../../../../models';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSave: HandleSaveType;
  question: ElmQuestionModel;
  counter?: ElmTakingModel['counter'];
}

const Question: FC<IProps> = ({ onSave, question, counter }) => {
  const { t } = useTranslation();
  let [answers, setAnswers] = useState<ElmQuestionOptionModel[]>(question.user_answer || []);
  let [text, setText] = useState('');

  const keyPressListener = (event: any) => {
    const keyName = event.key;
    const i = keyName - 1;
    if (keyName === 'Enter') {
      if (answers.length) {
        handleSave(true);
      }
    } else if (!isNaN(i)) {
      if (question.options[i]) {
        if (question.q_type === 'radio') {
          handleAnswerSingle(question.options[i]);
        } else if (question.q_type === 'checkbox') {
          if (question.options[i].id === 'input' && !text.length) {
            return;
          }
          handleAnswerMulti(question.options[i]);
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', keyPressListener);
    return () => {
      document.removeEventListener('keypress', keyPressListener);
    };
  }, [keyPressListener]);

  useEffect(() => {
    setAnswers(question.user_answer || []);
  }, [question.id]);

  const answersIds = answers.map(answer => answer.id);

  useEffect(() => {
    if (!text.length) {
      setAnswers(prevState => prevState.filter(item => item.id !== 'input'));
    }
  }, [text]);

  const handleAnswerSingle = (option: ElmQuestionOptionModel) => {
    setAnswers([option]);
  };

  const handleAnswerMulti = (option: ElmQuestionOptionModel) => {
    let answer = { ...option };
    if (option.id === 'input') {
      answer.text = text;
    }
    if (answers.find(item => item.id === answer.id)) {
      const newAnswers = answers.filter(item => item.id !== answer.id);
      setAnswers(newAnswers);
    } else {
      const newAnswers = [...answers, answer];
      setAnswers(newAnswers);
    }
  };

  const handleSave = (next: boolean) => {
    onSave({
      next,
      answer: answers,
    });
  };

  const renderOptions = () => {
    if (question.q_type === 'radio') {
      return (
        <ul className="b-EATaking__questionsList">
          {question.options.map((opt: ElmQuestionOptionModel) => {
            return (
              <li key={`ea-opt-${opt.id}`} className={`${answersIds.includes(opt.id) ? 'active' : ''}`}>
                <button onClick={() => handleAnswerSingle(opt)}>{opt.text}</button>
              </li>
            );
          })}
        </ul>
      );
    }
    if (question.q_type === 'checkbox') {
      return (
        <ul className="b-elmTake__checboxes">
          {question.options.map((opt: ElmQuestionOptionModel) => {
            const active = answersIds.includes(opt.id);
            if (opt.id === 'input') {
              return (
                <li className={'active'}>
                  <Checkbox
                    noText
                    disabled={!text.length}
                    checked={active}
                    onChange={() => handleAnswerMulti(opt)}
                  />
                  <span>{t('common:headlines.other')}:</span>
                  <input onChange={e => setText(e.target.value)} type="text" />
                </li>
              );
            }
            return (
              <li key={`ea-opt-${opt.id}`} className={`${active ? 'active' : ''}`}>
                <Checkbox noText checked={active} onChange={() => handleAnswerMulti(opt)} />
                <span>{opt.text}</span>
              </li>
            );
          })}
        </ul>
      );
    }
    if (question.q_type === 'multi_select') {
      const opts: ISelectOptions<string>[] = question.options.map(opt => ({
        label: opt.text,
        value: opt.id,
      }));
      const values: ISelectOptions<string>[] = answers.map(opt => ({
        label: opt.text,
        value: opt.id,
      }));
      const handleChange = (values: ISelectOptions<string>[]) => {
        const answers = values.map(opt => ({ id: opt.value as string, text: opt.label }));
        setAnswers(answers);
      };

      return (
        <div className="b-elmTake__select">
          <Select handleChange={handleChange} isMulti value={values} options={opts} />
        </div>
      );
    }
  };

  return (
    <>
      <div className="-desktop">
        <Button
          iconLeft={<img src={SaveIc} alt={t('common:alt.saveIc')} />}
          className="b-elmTake__saveBtn -desktop"
          type={'bordered'}
          onPress={() => handleSave(false)}
          title={t('common:btn.save-exit')}
        />
      </div>
      <div className="b-EATaking__box">
        <div className="b-EATaking__questions">
          <div className="b-EATaking__counter">
            {!counter ? t('awa:M65.title') : `${counter.current}/${counter.total}`}
          </div>
          <p>{question.text}</p>
          {renderOptions()}
          <div className="b-EATaking__buttons -desktop">
            <Button
              disabled={!answers.length}
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={() => handleSave(true)}
              title={t('common:btn.next')}
            />
          </div>
        </div>
      </div>
      <div className="-mobile">
        <div className="b-EATaking__buttons">
          <Button
            iconLeft={<img src={SaveIc} alt={t('common:alt.saveIc')} />}
            className="b-elmTake__saveBtn "
            type={'bordered'}
            onPress={() => handleSave(false)}
            title={t('common:btn.save-exit')}
          />
          <Button
            className={'btn'}
            type={'orange-light'}
            onPress={() => handleSave(true)}
            title={t('common:btn.next')}
            disabled={!answers.length}
          />
        </div>
      </div>
    </>
  );
};

export default Question;
