import React, { FC } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { ResourceFileType } from '../../../models';
import { DOMAIN_URI } from '../../../constants';
import { getResourceType, urlWithToken } from '../../../helpers';
import { ImageSecured } from '../../controls';
import { useTranslation } from 'react-i18next';

interface IProps {
  filepath: string;
}

const ResourceFileViewer: FC<IProps> = ({ filepath }) => {
  let fileType = getResourceType(filepath);
  const { t } = useTranslation();

  if (fileType === ResourceFileType.PDF) {
    return <Viewer fileUrl={urlWithToken(`${DOMAIN_URI}${filepath}`)} />;
  } else if (fileType === ResourceFileType.IMAGE) {
    return (
      <ImageSecured
        className="b-resourceView__illustration"
        src={`${DOMAIN_URI}${filepath}`}
        alt={t('common:alt.article-image')}
      />
    );
  }
  return null;
};

export default ResourceFileViewer;
