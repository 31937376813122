import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import { BelResourceModel, BelResourceOptionModel, IRouteParamsBase } from '../../../../../models';
import { useDispatch } from 'react-redux';
import { belGetResource } from '../../../../../store/beloved/resources/resourcesActions';
import { ComaList, Link, Loader, ResourceStatusLabel, SimpleTooltip } from '../../../../controls';
import { ResourceFileViewer } from '../../../../common';
import BelovedLogoIc from '../../../../../assets/images/icons/beloved.svg';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { basicDateFormat } from '../../../../../helpers';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';
import ResourceTools from '../parts/ResourceTools';
import { ROOTS } from '../../../../../routes/routes';

const ResourceView: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams<IRouteParamsBase>();
  let [data, setData] = useState<BelResourceModel | null>(null);
  let [loading, setLoading] = useState(false);
  const { navBack } = useNavBack();
  const isBeloved = location.pathname.match('beloved');
  const isPublic = data?.distribution.id === 2;
  const isOwnerBeloved = data?.ownership.id === 1;

  useEffect(() => {
    setLoading(true);
    dispatch(
      belGetResource({
        id: Number(id),
        callback: resource => {
          setLoading(false);
          setData(resource);
        },
      }),
    );
  }, [id]);

  if (!data || loading) return <Loader fitParent />;

  const handleTag = (tag: BelResourceOptionModel) => {
    if (isBeloved) {
      history.push(ROOTS.BELOVED_RESOURCE_LIST_CARDS.replace(':action', 'general'), {
        search: `#${tag.title}`,
      });
      return;
    }
    history.push(ROOTS.RESOURCE_LIST_CARDS.replace(':action', 'general'), { search: `#${tag.title}` });
  };

  const renderHeader = () => {
    if (!data) return;
    return (
      <>
        <div className="b-resourceView__header">
          <h2 className="b-page__title2 font-navy -backIcon">
            <EmptyLink onClick={navBack} className="b-button-icon-back" />
            {data.title}
          </h2>
          {isBeloved && isPublic ? (
            <SimpleTooltip id={`globe-${data.id}`} title={t('common:tooltip.resource-public')}>
              <span className="b-resourceView__globeIcon" />
            </SimpleTooltip>
          ) : null}
        </div>
        {data.description ? (
          <div className="b-resourceView__description -desktop">
            <p>{data.description}</p>
          </div>
        ) : null}

        <div className="b-resourceView__heading">
          {data.description ? (
            <div className="b-resourceView__description -mobile">
              <p>{data.description}</p>
            </div>
          ) : null}
          <div className="b-resourceView__headingleft">
            {isOwnerBeloved ? (
              <img className="b-resourceView__logo" src={BelovedLogoIc} alt="Beloved logo" />
            ) : (
              <div />
            )}
            <ResourceTools data={data} />
          </div>

          <div className="b-resourceView__headingRight">
            <ResourceStatusLabel status={data.status} />
            <span className="b-resourceView__date">
              {data.published_date
                ? basicDateFormat(data.published_date)
                : t('main:resources.no-publish-date')}
            </span>
          </div>
        </div>

        <dl className="b-resourceView__dList">
          <dt>{t('common:column.authors')}:</dt>
          <dd>{data.authors || t('common:column.n-a')}</dd>
          <dt>{t('common:label.categories')}:</dt>
          <dd>
            <ComaList uKey={'category'} arr={data.categories} nullText={t('common:column.n-a')} />
          </dd>
          <dt>{t('common:label.target-sectors')}:</dt>
          <dd>
            <ComaList uKey={'sectors'} arr={data.sectors} nullText={t('common:column.n-a')} />
          </dd>
          {data.notes ? (
            <>
              <dt>{t('common:btn.notes')}:</dt>
              <dd>{data.notes}</dd>
            </>
          ) : null}
          <dt>{t('common:label.access')}:</dt>
          <dd>{data.access_type.name}</dd>
        </dl>
      </>
    );
  };

  const renderBody = () => {
    if (data) {
      if (data.docs.length) {
        return (
          <div className="b-resourceView__box">
            <ResourceFileViewer filepath={data.docs[0].file} />
          </div>
        );
      } else if (data.referenced_links.length) {
        return (
          <div className="b-resourceView__openResource">
            <a
              rel="noreferrer"
              target="_blank"
              href={data.referenced_links[0]}
              className="b-link-icon -openResource -right"
            >
              {t('common:label.open-resource')}
            </a>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <>
      <main className="b-page">
        <div className="b-page__header">
          <h1 className="b-page__title -noMb">{t('common:tabs.resource-library')}</h1>
        </div>
        <div className="b-resourceView__container">
          {renderHeader()}
          <ul className="tagList">
            {data.tags.map(item => {
              return (
                <li key={`tag-${item.id}`} className="tagList__item">
                  <Link href="#" onClick={() => handleTag(item)} className="tagList__link">
                    #{item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
          {renderBody()}
        </div>
      </main>
    </>
  );
};

export default ResourceView;
