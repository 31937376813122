import React, { FC, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Button, Loader, Select } from '../../../../../controls';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import { CreateEAStep, EAUpdateData, ISelectOptions } from '../../../../../../models';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../../store/reducers';
import { useTranslation } from 'react-i18next';

interface IProps {
  onChangeStep: (step: CreateEAStep) => void;
  handleCancel: () => void;
  saveData: (data: EAUpdateData, isNext: boolean) => void;
}

interface IState {
  sector_type: ISelectOptions | null;
  primary_type: ISelectOptions | null;
}

const CreateEAStep7: FC<IProps> = ({ onChangeStep, handleCancel, saveData }) => {
  const { t } = useTranslation();
  const createEAData = useSelector((state: StateType) => state.equityAudit.createEAData);
  const EAOptions = useSelector((state: StateType) => state.equityAudit.EAOptions);

  const initialState = {
    sector_type: null,
    primary_type: null,
  };

  let [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (createEAData && EAOptions) {
      setState(prevState => ({
        ...prevState,
        sector_type: EAOptions.sector_type.find(item => item.value === createEAData.sector_type) || null,

        primary_type: EAOptions.primary_type.find(item => item.value === createEAData.primary_type) || null,
      }));
    }
  }, [createEAData, EAOptions]);

  const handleSelect = (fieldName: string, value: ISelectOptions) => {
    setState({ ...state, [fieldName]: value });
  };

  const handleSaveExit = (e: any) => {
    e.preventDefault();
    saveData(
      {
        sector_type: state.sector_type?.value as number,
        primary_type: state.primary_type?.value as number,
        ea_step: 'set_sector',
      },
      false,
    );
  };

  const handleNext = () => {
    saveData(
      {
        sector_type: state.sector_type?.value as number,
        primary_type: state.primary_type?.value as number,
        ea_step: 'finish',
      },
      true,
    );
  };

  const _isNextBtnDisabled = () => {
    return !(state.sector_type && state.primary_type);
  };

  if (!(createEAData && EAOptions)) return <Loader fitParent />;

  return (
    <>
      <div className={'b-EACreate__step'}>
        <div className="b-EACreate__header">
          <h1 className={'pageContainer__title b-EATypo__pageTitle'}>
            {t('common:headlines.your-organization')}
          </h1>
          {!isMobileOnly && (
            <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
              <img src={SaveIc} alt={t('common:alt.saveIc')} />
              {t('common:btn.save-exit')}
            </a>
          )}
        </div>
        <div className={'b-step__orgWrapper'}>
          <div className="b-equityAudit__text16">{t('common:headlines.organization')}</div>
          <div className={'b-step__orgText'}>{createEAData.organization_name}</div>
        </div>
        <Select
          value={state.sector_type}
          placeholder={t('common:label.sector-type')}
          ariaLabel="Sector Type"
          options={EAOptions.sector_type}
          error={''}
          handleChange={(value: ISelectOptions) => handleSelect('sector_type', value)}
          height={52}
          autoFocus
        />
        <Select
          value={state.primary_type}
          placeholder={t('common:label.primary-type')}
          ariaLabel="Primary Type of Service Provided"
          options={EAOptions.primary_type}
          error={''}
          handleChange={(value: ISelectOptions) => handleSelect('primary_type', value)}
          height={52}
        />

        <div className={`b-step__bottom`}>
          <div className={`b-step__bottom-left`}>
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={() => onChangeStep('set_geo')}
              title={t('common:btn.back')}
            />
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={handleCancel}
              title={t('common:btn.cancel')}
            />
          </div>
          {isMobileOnly ? (
            <div className={`b-step__bottom-left`}>
              <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
                <img src={SaveIc} alt={t('common:alt.saveIc')} />
                {t('common:btn.save-exit')}
              </a>
              <Button
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleNext}
                disabled={_isNextBtnDisabled()}
                title={t('common:btn.next')}
                enableEnter
              />
            </div>
          ) : (
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={handleNext}
              disabled={_isNextBtnDisabled()}
              title={t('common:btn.next')}
              enableEnter
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CreateEAStep7;
