import React from 'react';
import styles from './EmailNotVerifiedStyle.module.scss';
import { PopupSignInModel } from '../SignIn/SignInContainer';
import Logo from '../../../../assets/images/icons/verified.png';
import { Image } from '../../../controls';
import { useTranslation } from 'react-i18next';

interface IProps {
  onResendConfirmEmail: () => void;
  successMsg: string;
  showNotification: PopupSignInModel | null;
}

const EmailNotVerifiedView = ({ onResendConfirmEmail, successMsg, showNotification }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.notification_block}>
      <div className={`${styles.notification_wrapper}`}>
        <div className={`${styles.border}`}>
          {showNotification?.showImage && (
            <div className={'container-row m-0'}>
              <Image width={'150px'} height={'150px'} source={Logo} alt={t('awa:AT43')} />
            </div>
          )}
          <div className={`container-row ${styles.title}`}>
            <span className={'text-description blue-text text-center bold'}>{showNotification?.title}</span>
          </div>
          <div className={`container-row ${styles.text}`}>
            <span className={'text-description gray-text text-center'}>{showNotification?.msg}</span>
          </div>
          {showNotification?.resend && (
            <div className={`container-row ${styles.resendBtn}`}>
              <span className={'text-description gray-text text-center'}>
                <a
                  href={'#'}
                  className={'links links_underline_text'}
                  tabIndex={0}
                  role={'link'}
                  onClick={onResendConfirmEmail}
                >
                  {t('common:btn.resend-email-confirmation')}
                </a>
              </span>
            </div>
          )}
          {successMsg && (
            <div className={'container-row m-0'}>
              <span className={'text-description gray-text text-center'}>{successMsg}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailNotVerifiedView;
