import React from 'react';
import styles from './JoinOrganizationStyle.module.scss';
import { Footer, Header } from '../../../common';
import Input from '../../../controls/Inputs/Input';
import { JoinOrganizationModel } from '../../../../models';
import { Button } from '../../../controls';
import { useTranslation } from 'react-i18next';

interface IProps {
  loader?: string;
  formData: JoinOrganizationModel;
  handleChangeInput: (name: string, value: string) => void;
  errors: any;
  onBack: () => void;
  onSubmit: () => void;
  title: string;
  mode: string;
  serverError: string;
  isDisableButton: boolean;
}

const JoinOrganizationView = ({
  loader,
  formData,
  handleChangeInput,
  errors,
  onBack,
  onSubmit,
  title,
  mode,
  serverError,
  isDisableButton,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      {loader}
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        <main className={`white-container ${styles.container}`}>
          <div className={'container-page-center'}>
            <div className={'container-row m-0'}>
              <span className={`b-title ${styles.title}`} style={{ maxWidth: '324px' }}>
                {title || 'Request to Join An Organization'}
              </span>
            </div>
            <div className={`container-row ${styles.errorContainer}`}>
              <div className={'error-notification'}>
                <span className={'error-notification-text'}>{typeof errors === 'string' ? errors : ''}</span>
              </div>
            </div>
            <div className={'container-row mb-0 mt-0'}>
              <Input
                name={'name'}
                error={errors.name}
                value={formData.name || ''}
                type={'text'}
                placeholder={t('common:label.organization-official-name')}
                handleChange={value => handleChangeInput('name', value)}
                autoFocus
              />
            </div>
            {!(mode === 'sub' || mode === 'parent') && (
              <div className={'container-row mt-0 mb-0'}>
                <Input
                  name={'division'}
                  error={errors.division}
                  value={formData.division || ''}
                  type={'text'}
                  placeholder={t('common:label.division/department/unit')}
                  handleChange={value => handleChangeInput('division', value)}
                />
              </div>
            )}
            <div className={'container-row mt-0 mb-0'}>
              <Input
                name={'email'}
                error={errors.email}
                value={formData.email || ''}
                type={'text'}
                placeholder={t('common:label.pm-email')}
                handleChange={value => handleChangeInput('email', value)}
              />
            </div>
            <div className={`error-notification ${styles.wrapError}`}>
              <span className={`error-notification-text ${styles.error}`}>{serverError}</span>
            </div>
            <div className={'container-row m-0 d-flex'}>
              <Button
                onPress={onBack}
                title={t('common:btn.back')}
                type={'transparency'}
                disabled={false}
                size={'middle'}
              />
              <Button
                onPress={onSubmit}
                title={t('common:btn.submit')}
                type={'orange-light'}
                disabled={isDisableButton}
                size={'middle'}
                enableEnter
              />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default JoinOrganizationView;
