import { CreditActionsTypes, GetCreditsListPayload, SET_ACTIVE_AVG_CHART } from './avgActions';
import { AvgSubstandardModel } from '../../models';

export type AvgState = {
  activeChart: GetCreditsListPayload | null;
};

const initialState: AvgState = {
  activeChart: null,
};

const avgReducer = (state: AvgState = initialState, action: CreditActionsTypes): AvgState => {
  switch (action.type) {
    case SET_ACTIVE_AVG_CHART: {
      return {
        ...state,
        activeChart: action.payload,
      };
    }
    default:
      return state;
  }
};

export default avgReducer;
