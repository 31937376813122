import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  BelCohortFormModel,
  CheckedOptionsModel,
  BelCohortCreateModel,
  BelCohortModel,
  CohortUtilsModel,
  IRouteParamsBase,
  ISelectOptions,
  KeyValueModel,
  PaginationModel,
  AsyncSelectResultModal,
  UploadFileModel,
} from '../../../../../models';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { ImageUploader, Modal } from '../../../../common';
import {
  AsyncPaginateSelect,
  Button,
  Checkbox,
  Input,
  Loader,
  RadioButton,
  Select,
} from '../../../../controls';
import {
  excludeFromArray,
  getBasicDateFormatCodeForDatePicker,
  httpUrl,
  isEmptyObject,
  validate,
  cohortConvertToRequest,
} from '../../../../../helpers';
import { ROOTS } from '../../../../../routes/routes';
import { useTranslation } from 'react-i18next';
import {
  belCreateCohort,
  belUpdateCohort,
  getCohort,
  belGetCohortOrganizations,
  belGetCohortTeam,
} from '../../../../../store/cohort/cohortActions';
import { getCohortUtils } from '../../../../../store/utils/utilsActions';
import { toast } from 'react-toastify';
import { useIsAccessEditCohort } from '../../../../../hooks/beloved/cohorts/use-edit-access';
import { useGetCohortDetails } from '../../../../../hooks/beloved/cohorts/use-get-cohort-details';
import { EmptyLink, Link } from '../../../../controls';
import { Modals } from '../../../../../constants';

interface IRouteParams extends IRouteParamsBase {
  id: string | 'create';
}

const BelCohortDetailsForm = () => {
  const { t } = useTranslation();
  const { id } = useParams<IRouteParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [modals, setModals] = useState<KeyValueModel<boolean | number>>({});
  const [errors, setErrors] = useState<KeyValueModel<string>>({});
  const cohortOptions: CohortUtilsModel | null = useSelector(
    (state: StateType) => state.utils.cohort || null,
  );
  const [cohort, setCohort] = useState<BelCohortFormModel>({} as BelCohortFormModel);
  const [initialCohort, setInitialCohort] = useState<BelCohortFormModel>({} as BelCohortFormModel);
  const [defaultOrganizationOptions, setDefaultOrganizationOptions] = useState<ISelectOptions[]>([]);
  const [tempSelect, setTempSelect] = useState<CheckedOptionsModel[]>([]);
  const [cohortTeams, setCohortTeams] = useState<ISelectOptions[]>([]);
  const currentCohort = useGetCohortDetails();
  const isAccess = useIsAccessEditCohort(currentCohort);
  const [cohortStatuses, setCohortStatuses] = useState<ISelectOptions[]>([]);
  const [tempCoachLeaderId, setTempCoachLeaderId] = useState<number | null>(null);

  const isCreate = id === 'create';

  let [loading, setLoading] = useState(false);

  const validator = {
    required: ['name'],
    custom: [httpUrl(['activity_deck'])],
  };

  useEffect(() => {
    if (cohortOptions && !isCreate) {
      initCohort(cohortOptions);
    }
    dispatch(
      belGetCohortTeam({
        callback: (res: ISelectOptions[]) => {
          setCohortTeams(res);
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (cohortOptions && cohort.start_date && cohort.end_date) {
      setCohortStatuses(cohortOptions.statuses);
      return;
    }
    const array: ISelectOptions[] = [];
    if (cohortOptions && !(cohort.start_date && cohort.end_date)) {
      cohortOptions.statuses.map((status, i) => {
        if (i > 0) {
          array.push({ ...status, disabled: true });
        } else {
          array.push({ ...status });
        }
      });
    }
    setCohortStatuses(array);
  }, [cohort.start_date, cohort.end_date, cohortOptions]);

  useEffect(() => {
    if (isCreate && cohortOptions) {
      setCohort({
        ...cohort,
        status: cohortOptions.statuses.find(f => f.value === 'in_preparation') || null,
      });
    }
  }, [cohortOptions]);

  const convertToCohortForm = (
    cohortOptions: CohortUtilsModel,
    cohort: BelCohortModel,
  ): BelCohortFormModel => {
    return {
      activity_deck: cohort.activity_deck,
      beloved_team: cohort.beloved_team.map(b => {
        return { label: `${b.first_name} ${b.last_name}`, value: b.beloved_team_id };
      }),
      end_date: cohort.end_date,
      name: cohort.name,
      organizations: cohort.organizations.map(b => {
        return { label: b.name, value: b.id, beloved_team_id: b.beloved_team_id };
      }),
      resources: cohort.resources,
      start_date: cohort.start_date,
      type: cohortOptions.types.find(type => type.value === cohort.type) || null,
      status: cohortOptions.statuses.find(type => type.value === cohort.status) || null,
      id: cohort.id,
      image: cohort.image?.file,
    };
  };

  const initCohort = (cohortOptions: CohortUtilsModel) => {
    if (id === 'create') {
      return;
    }
    dispatch(
      getCohort({
        data: {
          id: Number(id),
        },
        callback: (res: BelCohortModel) => {
          const convertedCohortForm = convertToCohortForm(cohortOptions, res);
          setCohort(convertedCohortForm);
          setInitialCohort(convertedCohortForm);
        },
      }),
    );
  };

  useEffect(() => {
    if (!cohortOptions) {
      dispatch(
        getCohortUtils({
          callback: res => {
            if (!isCreate) {
              initCohort(res);
            }
          },
        }),
      );
    }
  }, [cohortOptions]);

  const onChangeDate = (date: Date, fieldName: 'start_date' | 'end_date') => {
    if (!cohort) return null;
    const dates: Pick<BelCohortFormModel, 'end_date' | 'start_date'> = {
      start_date: cohort.start_date ? new Date(cohort.start_date) : new Date(date),
      end_date: cohort.end_date ? new Date(cohort.end_date) : new Date(date),
    };
    dates[fieldName] = date;
    if (fieldName === 'start_date' && date.getTime() > new Date(cohort.end_date).getTime()) {
      dates.end_date = date;
    }
    delete errors[fieldName];
    setErrors(errors);
    setCohort({
      ...cohort,
      ...dates,
    });
  };

  const navBack = (e?: any) => {
    e && e.preventDefault();
    history.go(-1);
  };

  const _getEndMinDate = (start_date: string | Date) => {
    const today = new Date();
    if (today.getTime() > new Date(start_date).getTime()) {
      return today;
    }
    return new Date(start_date);
  };

  const handleChangeSelect = (name: keyof BelCohortFormModel, value: ISelectOptions[]) => {
    delete errors[name];
    setErrors(errors);
    setCohort({
      ...cohort,
      [name]: value,
    });
  };

  const handleCheck = (field: 'organizations' | 'beloved_team', item: ISelectOptions) => {
    const array = [...(cohort[field] || [])];
    const org = array.find(f => f.value === item.value);
    if (!org) {
      return;
    }
    org.isCheck = !org.isCheck;
    setCohort({
      ...cohort,
      [field]: array,
    });
  };

  const handleOpenModal = (e: any, modal: string) => {
    e.preventDefault();
    if (modal === 'add-organizations') {
      dispatch(
        belGetCohortOrganizations({
          data: {
            organization_ids: (cohort.organizations && cohort.organizations.map(o => Number(o.value))) || [],
          },
          callback: (res: PaginationModel<ISelectOptions[]>) => {
            setDefaultOrganizationOptions(res.result);
          },
        }),
      );
    }
    setModals({
      ...modals,
      [modal]: true,
    });
  };

  const handleCloseModal = (modal: string) => {
    setTempSelect([]);
    setModals({
      ...modals,
      [modal]: false,
    });
  };

  const handleRemove = (field: 'organizations' | 'beloved_team') => {
    const array = [...(cohort[field] || [])].filter(f => !f.isCheck);
    setCohort({
      ...cohort,
      [field]: array,
    });
  };

  const handleSave = () => {
    const errors = validate(validator, cohort).errors;
    if (!isEmptyObject(errors)) {
      window.scrollTo({ top: 0 });
      setErrors({
        ...errors,
      });
      return;
    }
    setLoading(true);
    const data: BelCohortCreateModel = cohortConvertToRequest(cohort, id);
    if (!isCreate) {
      dispatch(
        belUpdateCohort({
          data: data,
          callback: (res: BelCohortModel | null, errors) => {
            if (res && cohortOptions) {
              const convertedCohortForm = convertToCohortForm(cohortOptions, res);
              setCohort(convertedCohortForm);
              setInitialCohort(convertedCohortForm);
              toast.success(t('common:toast.cohort-was-updated'));
            }
            if (errors) {
              setErrors(errors);
            }
            setLoading(false);
          },
        }),
      );
      return;
    }
    dispatch(
      belCreateCohort({
        data: data,
        callback: (res, errors) => {
          if (res) {
            toast.success(t('common:toast.cohort-was-created'));
            history.push(ROOTS.BELOVED_COHORT_DETAILS.replace(':id', `${res.id}`));
          }
          if (errors) {
            setErrors(errors);
          }
          setLoading(false);
        },
      }),
    );
  };

  const handleAddOrganization = () => {
    setCohort({
      ...cohort,
      organizations: [...(cohort.organizations || []), ...tempSelect],
    });
    setTempSelect([]);
    setModals({});
  };

  const handleAddTeam = () => {
    setCohort({
      ...cohort,
      beloved_team: [...(cohort.beloved_team || []), ...tempSelect],
    });
    setTempSelect([]);
    setModals({});
  };

  async function loadOptions(
    search: string,
    loadedOptions: ISelectOptions[],
    additional?: { page: number },
  ): Promise<AsyncSelectResultModal | undefined> {
    const res: AsyncSelectResultModal | null = await new Promise(
      (
        resolve: (value: AsyncSelectResultModal | PromiseLike<AsyncSelectResultModal | null> | null) => void,
      ) => {
        dispatch(
          belGetCohortOrganizations({
            data: {
              organization_name: search,
              organization_ids:
                (cohort.organizations && cohort.organizations.map(o => Number(o.value))) || [],
              page: additional?.page || 1,
            },
            callback: async (res: PaginationModel<ISelectOptions[]>) => {
              setDefaultOrganizationOptions(res.result);
              resolve({
                options: res.result,
                hasMore: res.current_page < res.total_pages,
                additional: {
                  page: res.current_page + 1,
                },
              });
            },
          }),
        );
      },
    );
    if (res) {
      return res;
    }
  }

  const handleChangeInput = (name: string, value: string) => {
    setCohort({
      ...cohort,
      [name]: value,
    });
    delete errors[name];
    setErrors({
      ...errors,
    });
  };

  const handleChangeTempSelect = (value: ISelectOptions[]) => {
    setTempSelect(value);
  };

  const isBlockSaveButton = () => {
    if (isCreate) {
      return false;
    }
    return JSON.stringify(initialCohort) === JSON.stringify(cohort);
  };

  const handleAvatarChange = (file: UploadFileModel | null) => {
    setCohort({
      ...cohort,
      image: file ? file.file : null,
      image_id: file ? file.id : null,
    });
  };

  const handleSelectCoach = (index: number) => {
    if (!isAccess) {
      return;
    }
    const organizations = [...cohort.organizations];
    if (typeof organizations[index]?.beloved_team_id === 'number') {
      setTempCoachLeaderId(Number(organizations[index]?.beloved_team_id));
    }
    setModals({
      [Modals.coachLeader]: index,
    });
  };

  const onSelectCoach = () => {
    const organizations = [...cohort.organizations];
    let org = { ...organizations[Number(modals[Modals.coachLeader])] };
    if (org) {
      org = {
        ...org,
        beloved_team_id: Number(tempCoachLeaderId),
      };
    }

    organizations[Number(modals[Modals.coachLeader])] = org;

    setCohort({
      ...cohort,
      organizations: organizations,
    });
    setModals({});
    setTempCoachLeaderId(null);
  };

  const renderContent = () => {
    if (!cohort) {
      return <Loader fitParent />;
    }

    return (
      <div className="b-report b-cohortBel">
        <div className={`b-elmDetails__panel b-cohortBel__panel`}>
          <div className={'b-cohortBel__panel-top'}>
            <div>
              <div className="b-elmDetails__panelRow">
                <div className="b-elmDetails__panelParam -w50 mb-0">
                  <div className={`b-elmDetails__title pt-3 label`}>{t('common:label.name')}:</div>
                  <div className={`b-elmDetails__val link`}>
                    <div className={'input-wrapper'}>
                      <Input
                        autoFocus
                        name={'name'}
                        error={errors.name}
                        value={cohort.name}
                        type={'text'}
                        placeholder={t('common:label.cohort.name-cohort')}
                        handleChange={value => handleChangeInput('name', value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="b-elmDetails__panelParam mb-0">
                <div className={`b-elmDetails__title pt-3 label`}>{t('common:label.type')}:</div>
                <div className={`b-elmDetails__val`}>
                  <Select
                    handleChange={(value: ISelectOptions[]) => handleChangeSelect('type', value)}
                    value={cohort.type}
                    options={(cohortOptions && cohortOptions.types) || []}
                    ariaLabel="Type"
                    error={errors.type}
                  />
                </div>
              </div>
              <div className="b-elmDetails__panelRow">
                <div className="b-elmDetails__panelParam -w50 mb-0">
                  <div className={`b-elmDetails__title label`}>{t('common:headlines.start-date')}:</div>
                  <div className={`b-elmDetails__val`}>
                    <DatePicker
                      disabled={false}
                      minDate={new Date()}
                      locale="pt-BR"
                      timeIntervals={15}
                      dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                      selected={cohort.start_date ? new Date(cohort.start_date) : null}
                      customInput={<input type="text" aria-label={'start_date'} />}
                      onChange={(date: Date) => onChangeDate(date, 'start_date')}
                      placeholderText={t('common:column.n-a')}
                    />
                    <div className={'error-notification'}>
                      {errors.start_date && (
                        <span className={'error-notification-text'}>{errors.start_date}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="b-elmDetails__panelRow">
                <div className="b-elmDetails__panelParam -w50">
                  <div className={`b-elmDetails__title label`}>{t('common:headlines.end-date')}:</div>
                  <div className="b-elmDetails__val">
                    <DatePicker
                      disabled={false}
                      minDate={_getEndMinDate(cohort.start_date)}
                      dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                      selected={cohort.end_date ? new Date(cohort.end_date) : null}
                      customInput={<input type="text" aria-label={'end_date'} />}
                      onChange={(date: Date) => onChangeDate(date, 'end_date')}
                      placeholderText={t('common:column.n-a')}
                    />
                    <div className={'error-notification'}>
                      {errors.end_date && (
                        <span className={'error-notification-text'}>{errors.end_date}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="b-elmDetails__panelParam mb-0">
                <div className={`b-elmDetails__title pt-3 label`}>{t('common:label.status')}:</div>
                <div className="b-elmDetails__val">
                  <Select
                    handleChange={value => handleChangeSelect('status', value)}
                    value={cohort.status}
                    options={cohortStatuses}
                    ariaLabel="Status"
                    error={errors.status}
                  />
                </div>
              </div>
              <div className="b-elmDetails__panelParam mb-0">
                <div className={`b-elmDetails__title pt-3 label`}>{t('common:label.activity-deck')}:</div>
                <div className={`b-elmDetails__val link`}>
                  <div className={'input-wrapper'}>
                    <Input
                      name={'activity_deck'}
                      error={errors.activity_deck}
                      value={cohort.activity_deck}
                      type={'text'}
                      placeholder={t('common:label.cohort.link-slide-deck')}
                      handleChange={value => handleChangeInput('activity_deck', value)}
                    />
                  </div>
                </div>
              </div>
              <div className={`b-elmDetails__panelRow panelRow`}>
                <div className={`b-elmDetails__panelParam w-100`}>
                  <div className={`b-elmDetails__title b-cohortBel__title__remove`}>
                    {t('common:column.beloved-team')}
                    {(cohort.beloved_team || []).length ? (
                      <button
                        style={{ width: 24, height: 24 }}
                        onClick={() => handleRemove('beloved_team')}
                        className="b-button-icon -delete"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={`b-elmDetails__panelRow panelRow`}>
                <div className={`b-elmDetails__panelParam w-100 list`}>
                  {((cohort.beloved_team as CheckedOptionsModel[]) || []).map((user, userIndex) => {
                    return (
                      <div key={`user-panel-${userIndex}`} className={`list__item`}>
                        <span>{user.label}</span>
                        <Checkbox
                          noText
                          checked={!!user.isCheck}
                          onChange={() => handleCheck('beloved_team', user)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="b-elmDetails__panelRow">
                <div className="b-elmDetails__panelParam">
                  <div className={`b-elmDetails__title b-cohortBel__title__remove`}>
                    <a onClick={e => handleOpenModal(e, 'add-team')} href="#" className="b-link-add">
                      {t('common:btn.add-new-leader')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="b-elmDetails__panelParam mb-0 b-cohortBel__image">
              <ImageUploader
                placeholderText={t('common:label.cohort-image')}
                photo={cohort.image || null}
                onChange={handleAvatarChange}
                type={'cohort'}
              />
            </div>
          </div>
          <div className={`panelRow mt-4`}>
            <div className="d-flex">
              <div className={`b-elmDetails__panelParam w-100`}>
                <div className={`b-cohortBel__title__remove`}>
                  <span>{t('common:column.organizations')}</span>
                </div>
                <div className={`b-cohortBel__title__remove`}>
                  {t('common:column.beloved-coach')}
                  {(cohort.organizations || []).length ? (
                    <button
                      style={{ width: 24, height: 24 }}
                      onClick={() => handleRemove('organizations')}
                      className="b-button-icon -delete"
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className={`panelRow`}>
            {(cohort.organizations || []).map((org, orgIndex) => {
              return (
                <div key={`org-panel-${orgIndex}`} className="d-flex">
                  <div className={`b-elmDetails__panelParam w-100 list`}>
                    <div className={`list__item`}>
                      <span>{org.label}</span>
                    </div>
                  </div>
                  <div className={`list__item`}>
                    <Link className="b-link-icon-left" onClick={() => handleSelectCoach(orgIndex)}>
                      {cohort.beloved_team.find(f => f.value === org.beloved_team_id)?.label ||
                        (isAccess ? t('common:btn.assign-coach') : '')}
                    </Link>
                    <Checkbox
                      noText
                      checked={!!org.isCheck}
                      onChange={() => handleCheck('organizations', org)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="b-elmDetails__panelRow">
            <div className="b-elmDetails__panelParam">
              <div className={`b-elmDetails__title b-cohortBel__title__remove`}>
                <a onClick={e => handleOpenModal(e, 'add-organizations')} href="#" className="b-link-add">
                  {t('common:btn.add-new-organization')}
                </a>
              </div>
            </div>
          </div>
          {isAccess ? (
            <div className="b-EAModal__buttons mt-5">
              <Button
                className={'btn -type_form'}
                type={'transparency'}
                onPress={navBack}
                title={t('common:btn.cancel')}
              />
              <Button
                disabled={isBlockSaveButton()}
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={() => handleSave()}
                title={t('common:btn.save')}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <main className="b-page">
      <h1 className="b-page__title">
        <EmptyLink onClick={navBack} className="b-button-icon-back" />
        {isCreate ? t('common:btn.new-cohort') : t('common:headlines.edit-cohort')}
      </h1>
      {renderContent()}
      {modals['add-organizations'] ? (
        <Modal
          showCloseBtn
          onClose={() => handleCloseModal('add-organizations')}
          title={t('common:label.Add-Organizations-to-Cohort')}
        >
          <div className={`b-modal`}>
            <div className={`b-elmAddUserModal__list modal__content`}>
              <div className="b-elmAddUserModal__inputs">
                <AsyncPaginateSelect
                  handleChange={(value: ISelectOptions[]) => handleChangeTempSelect(value)}
                  value={tempSelect}
                  defaultOptions={defaultOrganizationOptions}
                  isMulti
                  isSearchable
                  loadOptions={loadOptions}
                  placeholder={t('common:label.search')}
                  noOptionsMessage={t('awa:N70.msg')}
                  ariaLabel="organizations"
                />
              </div>
            </div>
            <div className="b-modal__buttons">
              <Button
                onPress={() => handleCloseModal('add-organizations')}
                title={t('common:btn.cancel')}
                size={'large'}
                type={'transparency'}
              />
              <Button
                onPress={handleAddOrganization}
                title={t('common:btn.save')}
                size={'large'}
                type={'orange-light'}
              />
            </div>
          </div>
        </Modal>
      ) : null}
      {modals['add-team'] ? (
        <Modal
          showCloseBtn
          onClose={() => handleCloseModal('add-team')}
          title={t('common:label.Add-Cohort-Leader')}
        >
          <div className={`b-modal`}>
            <div className={`b-elmAddUserModal__list modal__content`}>
              <div className="b-elmAddUserModal__inputs">
                <Select
                  isMulti
                  isSearchable
                  handleChange={value => handleChangeTempSelect(value)}
                  value={tempSelect}
                  options={excludeFromArray<ISelectOptions>('value', cohortTeams, cohort.beloved_team) || []}
                  ariaLabel="beloved_team"
                />
              </div>
            </div>
            <div className="b-modal__buttons">
              <Button
                onPress={() => handleCloseModal('add-team')}
                title={t('common:btn.cancel')}
                size={'large'}
                type={'transparency'}
              />
              <Button
                onPress={handleAddTeam}
                title={t('common:btn.save')}
                size={'large'}
                type={'orange-light'}
              />
            </div>
          </div>
        </Modal>
      ) : null}
      {typeof modals[Modals.coachLeader] === 'number' ? (
        <Modal
          modalStyles={{ minHeight: 400 }}
          onClose={() => handleCloseModal(Modals.coachLeader)}
          title={t('common:label.beloved-users')}
        >
          <>
            <div className={`b-modal`}>
              <div className={`b-elmAddUserModal__list modal__content`}>
                <div className="b-elmAddUserModal__inputs">
                  <ul className="selectLeadForm__userList">
                    {(cohort.beloved_team || []).map(user => {
                      return (
                        <li key={`bel-users-${user.value}`}>
                          <RadioButton
                            selectedValue={tempCoachLeaderId || ''}
                            value={user.value}
                            title={user.label}
                            onChangeValue={() => setTempCoachLeaderId(Number(user.value))}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="b-modal__buttons">
                <Button
                  onPress={() => handleCloseModal(Modals.coachLeader)}
                  title={t('common:btn.cancel')}
                  size={'large'}
                  type={'transparency'}
                />
                <Button
                  onPress={onSelectCoach}
                  title={t('common:btn.assign-coach')}
                  size={'large'}
                  type={'orange-light'}
                />
              </div>
            </div>
          </>
        </Modal>
      ) : null}
      {loading && <Loader fitParent />}
    </main>
  );
};

export default BelCohortDetailsForm;
