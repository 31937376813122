import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelectOptions } from '../../../../../models';
import { Select } from '../../../../controls';

interface IProps {
  debriefLeaderId: number | null;
  setDebriefLeader: (debriefLeader: number) => void;
  availableBelovedUsers: ISelectOptions[];
}

const DebriefCard: FC<IProps> = ({ debriefLeaderId, setDebriefLeader, availableBelovedUsers }: IProps) => {
  const { t } = useTranslation();

  const handleChangeSelect = (value: ISelectOptions) => {
    setDebriefLeader(value.value as number);
  };

  const selectedUsers = availableBelovedUsers.find(f => Number(f.value) === debriefLeaderId);

  return (
    <div className="b-resourceCard font-grey font-s pr-4">
      <div className="font-weight-bold pb-2">{t('common:label.debrief')}</div>
      <Select
        wrapperStyles={{
          width: '80%',
        }}
        placeholder={t('common:column.default-leader')}
        handleChange={value => handleChangeSelect(value)}
        value={selectedUsers}
        options={availableBelovedUsers}
      />
    </div>
  );
};

export default DebriefCard;
