import React from 'react';
import './Button.scss';

export interface IProps {
  onClick?: (e: any) => void;
  className?: string;
  ariaTitleLabel?: string;
}

const EmptyButton = ({ onClick, className, ariaTitleLabel }: IProps) => {
  return (
    <button
      title={ariaTitleLabel || 'Button'}
      aria-label={ariaTitleLabel || 'Button'}
      onClick={onClick}
      className={`b-button-empty ${className}`}
    >
      <span aria-hidden="true"></span>
    </button>
  );
};

export default EmptyButton;
