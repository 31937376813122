import { useState } from 'react';
import { PaginationModel } from '../../models';
import Api from '../../services/api';

export function useInfiniteList<EntityModel>(url: string) {
  const [data, setData] = useState<PaginationModel<EntityModel[]> | null>(null);

  const fetchData = () => {
    Api.get(url).then(response => {
      if (response) {
        setData(response);
      }
    });
  };

  const fetchMoreData = () => {
    if (!data) return;
    const params = { page: data.current_page + 1 };
    Api.get(url, params).then(response => {
      if (response) {
        setData(prevState => ({
          ...response,
          result: prevState ? [...prevState.result, ...response.result] : response.result,
        }));
      }
    });
  };

  return {
    data,
    fetchData,
    fetchMoreData,
  };
}
