import React, { FC } from 'react';
import styles from './StepIndicator.module.scss';

interface IProps {
  step: number;
}

const StepIndicator: FC<IProps> = ({ step }) => {
  return (
    <div className={styles.container}>
      <div className={styles.steps}>
        <div className={`${styles.round} ${step >= 1 ? styles.round__active : ''}`}>1</div>
        <div className={`${styles.round} ${step >= 2 ? styles.round__active : ''}`}>2</div>
        <div className={`${styles.round} ${step >= 3 ? styles.round__active : ''}`}>3</div>
        <div className={`${styles.round} ${step >= 4 ? styles.round__active : ''}`}>4</div>
        <div className={`${styles.round} ${step >= 5 ? styles.round__active : ''}`}>5</div>
      </div>
    </div>
  );
};

export default StepIndicator;
