import React from 'react';
import styles from './CreateOrganizationStyle.module.scss';
import { Footer, Header } from '../../../common';
import Input from '../../../controls/Inputs/Input';
import {
  AuthUserModel,
  CreateOrganizationModel,
  ApiAddressModel,
  ISelectOptions,
  OrganizationTypeInfoModel,
} from '../../../../models';
import { Button, EmptyLink, Select } from '../../../controls';
import AddressAutocomplete from '../../../common/AddressAutocomplete/AddressAutocomplete';
import CreateOrganizationExisting from '../CreateOrganizationExisting/CreateOrganizationExisting';
import { Modals, ModalsModel } from '../../../../constants';
import ModalView from '../../../controls/ModalView/ModalView';
import { useTranslation } from 'react-i18next';

interface IProps {
  loader?: string;
  formData: CreateOrganizationModel;
  handleChangeInput: (name: string, value: string) => void;
  handleChangeSelect: (name: string, value: ISelectOptions) => void;
  types: ISelectOptions[] | null;
  errors: any;
  onBack: () => void;
  onSubmit: () => void;
  handleAddressChange: (name: string, data: ApiAddressModel) => void;
  step: number;
  setStep: (step: number) => void;
  user: AuthUserModel | null;
  title: string;
  showExistingOrg: boolean;
  showSubTitle: boolean;
  handleCloseOrganizationExisting: () => void;
  isDisableStep1Button: boolean;
  isDisableStep2Button: boolean;
  setModal: (modal: keyof ModalsModel) => (e: any) => void;
  closeModal: (modal: keyof ModalsModel) => void;
  modals: ModalsModel;
  infoList: OrganizationTypeInfoModel[];
}

const CreateOrganizationView = ({
  loader,
  formData,
  handleChangeInput,
  errors,
  types,
  handleChangeSelect,
  onBack,
  onSubmit,
  handleAddressChange,
  step,
  setStep,
  user,
  title,
  showExistingOrg,
  showSubTitle,
  handleCloseOrganizationExisting,
  isDisableStep1Button,
  isDisableStep2Button,
  setModal,
  closeModal,
  modals,
  infoList,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      {loader}
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        {!showExistingOrg && (
          <div className={`white-container ${styles.container}`}>
            <div className={'container-page-center'}>
              <div className={'container-row m-0'}>
                <span className={`b-title ${styles.title}`}>
                  {title || `${t('awa:M16.title.p1')} ${step === 2 ? t('awa:M16.title.p2') : ''}`}
                </span>
              </div>
              <div className={`container-row ${styles.info_wrap}`}>
                {step === 1 && (
                  <span className={`sub-title ${styles.title_info}`}>
                    {t('awa:M16.msg.p1')},{' '}
                    <a className={styles.info} onClick={setModal(Modals.isOrgSetupInfo)} href="">
                      {t('awa:M16.msg.p2')}
                    </a>
                    .
                  </span>
                )}
              </div>
              {user?.next_step === 3 && showSubTitle ? (
                <div className={`${styles.sub_title} container-row`}>
                  <span>{t('awa:M16.msg.p3')}</span>
                </div>
              ) : null}
              {step === 1 && (
                <>
                  <div className={'container-row mb-0 mt-0'}>
                    <Input
                      name={'name'}
                      error={errors.name}
                      value={formData.name || ''}
                      type={'text'}
                      disabled={user?.next_step === 3}
                      placeholder={t('common:label.organization-official-name')}
                      handleChange={value => handleChangeInput('name', value)}
                      autoFocus
                    />
                  </div>
                  <div className={'container-row mt-0 mb-0'}>
                    <Input
                      name={'division'}
                      error={errors.division}
                      value={formData.division || ''}
                      type={'text'}
                      disabled={user?.next_step === 3}
                      placeholder={t('common:label.division/department/unit')}
                      handleChange={value => handleChangeInput('division', value)}
                    />
                  </div>
                  <div className={`container-row mb-0 mt-0`}>
                    <Select
                      name={'type'}
                      value={
                        types && types.length > 0 && formData.type
                          ? types.find((type: any) => type.value === (formData.type.value || formData.type))
                          : ''
                      }
                      placeholder={t('common:label.type')}
                      options={types || []}
                      error={errors.type}
                      disabled={user?.next_step === 3}
                      handleChange={(value: ISelectOptions) => handleChangeSelect('type', value)}
                      customClasses={styles.select}
                      height={52}
                    />
                  </div>
                  <div className={'container-row mb-0 mt-0'}>
                    <Input
                      name={'website'}
                      error={errors.website}
                      value={formData.website || ''}
                      type={'text'}
                      placeholder={t('common:label.website')}
                      disabled={user?.next_step === 3}
                      handleChange={value => handleChangeInput('website', value)}
                    />
                  </div>
                  {user && user.user && user.next_step === 3 ? (
                    <>
                      <div className={'container-row mb-4'}>
                        <span className={styles.title}>Project Manager</span>
                      </div>
                      <div className={'container-row mb-0 mt-0'}>
                        <Input
                          value={`${user.user.first_name} ${user.user.last_name}`}
                          type={'text'}
                          placeholder={t('common:label.project-manager')}
                          disabled={true}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              )}

              {step === 2 && (
                <AddressAutocomplete
                  address={(formData.address as ApiAddressModel) || {}}
                  onChange={handleAddressChange}
                  errors={errors}
                />
              )}

              <div
                className={`container-row mb-0 mt-0 d-flex ${
                  user && user.user && user.next_step === 3 ? '' : 'justify-content-between'
                }`}
              >
                {user && user.user && user.next_step === 3 && step !== 2 ? (
                  ''
                ) : (
                  <Button
                    onPress={() => (step === 2 ? setStep(1) : onBack())}
                    title={step === 2 ? t('common:btn.back') : t('common:btn.cancel')}
                    type={'transparency'}
                    disabled={false}
                    size={'small'}
                    width={'50%'}
                  />
                )}
                <Button
                  onPress={() => (step === 2 ? onSubmit() : setStep(2))}
                  title={step === 2 ? t('common:btn.submit') : t('common:btn.next')}
                  type={'orange-light'}
                  disabled={step === 2 ? isDisableStep2Button : isDisableStep1Button}
                  size={'small'}
                  width={'50%'}
                  enableEnter
                />
              </div>
            </div>
          </div>
        )}
        <ModalView isOpen={!!modals.isOrgSetupInfo} onRequestClose={() => closeModal(Modals.isOrgSetupInfo)}>
          <div className={styles.modal}>
            <EmptyLink className={'b-modal__cross'} onClick={() => closeModal(Modals.isOrgSetupInfo)} />
            <h2>{t('auth:organization-setup')}</h2>
            {infoList.map(info => {
              return info.content;
            })}
          </div>
        </ModalView>
        {showExistingOrg && <CreateOrganizationExisting onClose={handleCloseOrganizationExisting} />}
        <Footer />
      </div>
    </>
  );
};

export default CreateOrganizationView;
