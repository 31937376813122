import React, { useEffect } from 'react';
import { HeadlineOrganization, Pagination } from '../../../../common';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../controls';
import DataGrid from 'react-data-grid';
import { CohortOrganizationModel, IRouteParamsBase, TableDataEntities } from '../../../../../models';
import useTableData from '../../../../../hooks/common/use-table-data';
import { getColumns } from './parts/columns';
import { useIsDarkMode } from '../../../../../hooks/common/use-is-dark-mode';
import { useGetOrgCohortDetails } from '../../../../../hooks/cohorts/use-get-org-cohort-details';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import useTableConfig from '../../../../../hooks/common/use-table-config';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';

interface IRouteParams extends IRouteParamsBase {
  id: string;
  orgId: string;
  organizationId: string;
}

const CohortOrgs = () => {
  const { t } = useTranslation();
  const { id, organizationId, orgId } = useParams<IRouteParams>();
  const cohort = useGetOrgCohortDetails(organizationId || orgId);
  const { isDarkMode } = useIsDarkMode();

  const {
    data,
    query,
    fetchData,
    handleChangePage,
    handleChangePageSize,
    handleSearch,
    selectedEntitiesIds,
    handleSort,
  } = useTableData(TableDataEntities.COHORT_ORGANIZATIONS, { cohort_id: id });
  useEffect(() => {
    fetchData(query);
  }, []);

  const { columns, onColumnResize } = useTableConfig<CohortOrganizationModel>(
    TableDataEntities.COHORT_ORGANIZATIONS,
    getColumns(),
  );

  const navBack = (e?: any) => {
    e && e.preventDefault();
    history.go(-1);
  };

  const renderContent = () => {
    if (!data) return <Loader fitParent />;

    return (
      <div>
        <DndProvider backend={HTML5Backend}>
          <DataGrid
            onRowClick={() => {}}
            onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
            columns={columns}
            onColumnResize={onColumnResize}
            sortColumns={query.sorting ? [query.sorting] : []}
            rows={data.result}
            className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
            style={{
              height: ((data.result.length || 0) + 1) * 60,
            }}
            rowHeight={60}
            selectedRows={new Set(selectedEntitiesIds)}
            rowKeyGetter={(row: CohortOrganizationModel) => row.id}
          />
        </DndProvider>
        <Pagination data={data} handleChangePageSize={handleChangePageSize} changePage={handleChangePage} />
      </div>
    );
  };

  return (
    <main className="b-page">
      {!data && <Loader fitParent />}
      <div className="b-page__header -desktop">
        <HeadlineOrganization
          orgName={cohort?.name || ''}
          orgId={cohort?.id || ''}
          headline={t('common:headlines.cohort-program')}
          customClasses={'-noDesktopMb mr-4'}
          backBtn={<EmptyLink onClick={navBack} className="b-button-icon-back" />}
          noOrgLogo
        />
      </div>

      <div className="b-page__controls">
        <div className="b-page__search">
          <form action="#" className="searchForm">
            <input
              type="search"
              value={query.search}
              onChange={e => handleSearch(e.target.value)}
              placeholder={t('common:label.search')}
              aria-label="Search field"
            />
            <span className="searchForm__icon" />
          </form>
        </div>
      </div>
      {renderContent()}
    </main>
  );
};

export default CohortOrgs;
