import React, { ReactNode, useLayoutEffect } from 'react';
import './Button.scss';

export interface IProps {
  title: string | ReactNode;
  style?: Object;
  disabled?: boolean;
  onPress: () => void;
  type?: 'orange' | 'orange-light' | 'transparency' | 'white' | 'bordered' | 'primary' | undefined;
  customProps?: any;
  className?: string;
  size?: 'small' | 'large' | 'middle' | 'x';
  width?: string | number;
  iconLeft?: ReactNode;
  enableEnter?: boolean;
  id?: string;
  nativeType?: 'submit' | 'reset' | 'button' | undefined;
  tabIndex?: number | undefined;
}

const Button = ({
  id,
  disabled,
  title,
  onPress,
  type,
  customProps,
  className,
  size,
  width,
  iconLeft,
  enableEnter,
  nativeType,
  tabIndex,
}: IProps) => {
  const renderContent = () => {
    return (
      <>
        {iconLeft}
        {title}
      </>
    );
  };

  const mouseListener = () => {
    document.addEventListener('keydown', listener);
    document.removeEventListener('click', mouseListener);
  };

  const listener = (event: any) => {
    if (event.code === 'Tab') {
      document.removeEventListener('keydown', listener);
      document.addEventListener('click', mouseListener);
    }
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      !disabled && onPress();
    }
  };

  useLayoutEffect(() => {
    return () => {
      document.removeEventListener('keydown', listener);
      document.removeEventListener('click', mouseListener);
    };
  }, []);

  useLayoutEffect(() => {
    if (enableEnter) {
      document.addEventListener('keydown', listener);
    }
    return () => {
      if (enableEnter) {
        document.removeEventListener('keydown', listener);
        document.removeEventListener('click', mouseListener);
      }
    };
  }, [onPress, disabled, enableEnter]);

  return (
    <button
      type={nativeType}
      tabIndex={tabIndex}
      id={id}
      disabled={disabled}
      onClick={onPress}
      className={`
            button
            ${type}
            ${className || ''}
            ${size ? `button-${size}` : ''}
            ${iconLeft ? `button-icon` : ''}
            ${disabled ? `button-disabled` : ''}
            `}
      style={{
        width: width,
      }}
      {...((customProps && customProps) || {})}
    >
      {renderContent()}
    </button>
  );
};

Button.defaultProps = {
  type: 'primary',
};

export default Button;
