import { ApiAddressModel } from '../models';
import { Client } from '@googlemaps/google-maps-services-js';
import { GOOGLE_CONSOLE_KEY } from '../constants';

export const getLocation = async (place: any, ref?: any): Promise<ApiAddressModel> => {
  let address_details: ApiAddressModel = {
    full_address: place.formatted_address,
    address: '',
    country: '',
    state: '',
    city: '',
    street: '',
    postal: '',
    place_id: place.place_id,
    number: null,
    suite: '',
  };

  place.address_components.reverse().map((value: any) => {
    if (value.types.find((f: string) => f === 'street_number')) {
      address_details.number = value.long_name;
    }
    if (value.types.find((f: string) => f === 'route')) {
      address_details.street = value.long_name;
    }
    if (value.types.find((f: string) => f === 'country')) {
      address_details.country = value.short_name;
    }
    if (value.types.find((f: string) => f === 'postal_code')) {
      address_details.postal = value.long_name;
    }
    if (value.types.find((f: string) => f === 'locality' || f === 'postal_town')) {
      address_details.city = value.long_name;
    }
    if (value.types.find((f: string) => f === 'administrative_area_level_1')) {
      address_details.city = value.long_name;
    }
    if (value.types.find((f: string) => f === 'administrative_area_level_1')) {
      address_details.state = value.long_name;
    }
  });

  address_details.address = address_details.number
    ? `${address_details.number} ${address_details.street}`
    : `${address_details.street}`;

  const latLng = await getLatLnd(address_details);
  address_details = {
    ...address_details,
    ...(latLng || {}),
  };
  if (ref) {
    ref.value = address_details.address;
  }

  return address_details;
};

export const getLatLnd = async (address_details: any) => {
  const client = new Client({});
  try {
    if (
      address_details.address &&
      address_details.country &&
      address_details.city &&
      address_details.postal
    ) {
      const {
        data: { results },
      } = await client.geocode({
        params: {
          key: GOOGLE_CONSOLE_KEY,
          address: `${address_details.address}, ${address_details.country}, ${address_details.city}, ${address_details.postal}`,
        },
        timeout: 400,
      });
      return {
        lat: results[0].geometry.location.lat,
        lng: results[0].geometry.location.lng,
      };
    }
  } catch (e) {
    console.log('e', e);
  }
  try {
    if (address_details.country && address_details.city && address_details.postal) {
      const {
        data: { results },
      } = await client.geocode({
        params: {
          key: GOOGLE_CONSOLE_KEY,
          address: `${address_details.country}, ${address_details.city}, ${address_details.postal}`,
        },
        timeout: 400,
      });
      return {
        lat: results[0].geometry.location.lat,
        lng: results[0].geometry.location.lng,
      };
    }
  } catch (e) {
    console.log('e', e);
  }
  try {
    if (address_details.city && address_details.postal) {
      const {
        data: { results },
      } = await client.geocode({
        params: {
          key: GOOGLE_CONSOLE_KEY,
          address: `${address_details.city}, ${address_details.postal}`,
        },
        timeout: 400,
      });
      return {
        lat: results[0].geometry.location.lat,
        lng: results[0].geometry.location.lng,
      };
    }
  } catch (e) {
    console.log('e', e);
  }
  try {
    if (address_details.city) {
      const {
        data: { results },
      } = await client.geocode({
        params: {
          key: GOOGLE_CONSOLE_KEY,
          address: `${address_details.city}`,
        },
        timeout: 400,
      });
      return {
        lat: results[0].geometry.location.lat,
        lng: results[0].geometry.location.lng,
      };
    }
  } catch (e) {
    console.log('e', e);
  }
  try {
    if (address_details.postal) {
      const {
        data: { results },
      } = await client.geocode({
        params: {
          key: GOOGLE_CONSOLE_KEY,
          address: `${address_details.postal}`,
        },
      });
      return {
        lat: results[0].geometry.location.lat,
        lng: results[0].geometry.location.lng,
      };
    }
  } catch (e) {
    console.log('e', e);
  }
};
