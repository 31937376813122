import {
  CREATE_EDUCATION_SUCCESS,
  CREATE_ETHNICITY_SUCCESS,
  CREATE_GENDER_SUCCESS,
  CREATE_PRONOUNS_SUCCESS,
  CREATE_RACE_SUCCESS,
  GET_APP_OPTIONS_SUCCESS,
  GET_COHORT_UTILS_SUCCESS,
  GET_FILTERS_SUCCESS,
  GET_INFO_SUCCESS,
  UtilsActionsTypes,
} from './utilsActions';
import {
  CohortUtilsModel,
  ISelectOptions,
  InfoModel,
  UserRolesModel,
  UserTypeRoleModel,
  EthnicityTypeModel,
  UtilsFilterModel,
} from '../../models';
import { t } from 'i18next';

export type UtilsState = {
  data: IUtils;
  organizationTypes: UtilsActionsTypes;
  info: InfoModel;
  cohort: CohortUtilsModel | null;
  filters: UtilsFilterModel | null;
};

interface IUtils {
  genders: ISelectOptions[];
  languages: ISelectOptions[];
  officeTypes: ISelectOptions[];
  organizationsTypes: ISelectOptions[];
  userTypesRoles: UserTypeRoleModel[];
  pronounsTypes: ISelectOptions[];
  racesTypes: ISelectOptions[];
  ethnicityTypes: EthnicityTypeModel[];
  educationsType: ISelectOptions[];
  userRoles: UserRolesModel[];
  elmRoleTypes: ISelectOptions[];
}

const initialState: UtilsState = {
  data: {
    genders: [],
    languages: [],
    officeTypes: [],
    organizationsTypes: [],
    userTypesRoles: [],
    userRoles: [],
    pronounsTypes: [],
    racesTypes: [],
    ethnicityTypes: [],
    educationsType: [],
    elmRoleTypes: [],
  },
  info: {
    app_version: '',
  },
  organizationTypes: {} as UtilsActionsTypes,
  cohort: null,
  filters: null,
};

const utilsReducer = (state: UtilsState = initialState, action: UtilsActionsTypes): UtilsState => {
  switch (action.type) {
    case CREATE_ETHNICITY_SUCCESS: {
      const ethnicityTypes = [...(state.data.ethnicityTypes || [])];
      ethnicityTypes.unshift(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          ethnicityTypes: ethnicityTypes,
        },
      };
    }
    case CREATE_GENDER_SUCCESS: {
      const genders = [...(state.data.genders || [])];
      genders.unshift(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          genders: genders,
        },
      };
    }
    case CREATE_PRONOUNS_SUCCESS: {
      const pronounsTypes = [...(state.data.pronounsTypes || [])];
      pronounsTypes.unshift(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          pronounsTypes: pronounsTypes,
        },
      };
    }
    case CREATE_RACE_SUCCESS: {
      const racesTypes = [...(state.data.racesTypes || [])];
      racesTypes.unshift(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          racesTypes: racesTypes,
        },
      };
    }
    case CREATE_EDUCATION_SUCCESS: {
      const educationTypes = [...(state.data.educationsType || [])];
      educationTypes.unshift(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          educationsType: educationTypes,
        },
      };
    }
    case GET_INFO_SUCCESS: {
      return {
        ...state,
        info: action.payload,
      };
    }
    case GET_COHORT_UTILS_SUCCESS: {
      return {
        ...state,
        cohort: action.payload,
      };
    }
    case GET_FILTERS_SUCCESS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case GET_APP_OPTIONS_SUCCESS: {
      action.payload.ethnicity &&
        action.payload.ethnicity.push(
          {
            value: 'ethnicityTypes-disabled',
            label: '---',
            disabled: true,
          },
          {
            value: 'create',
            label: t('common:label.prefer-self-describe'),
          },
        );
      action.payload.genders &&
        action.payload.genders.push(
          {
            value: 'genders-disabled',
            label: '---',
            disabled: true,
          },
          {
            value: 'create',
            label: t('common:label.prefer-self-describe'),
          },
        );
      action.payload.pronouns &&
        action.payload.pronouns.push(
          {
            value: 'pronouns-disabled',
            label: '---',
            disabled: true,
          },
          {
            value: 'create',
            label: t('common:label.prefer-self-describe'),
          },
        );
      action.payload.race &&
        action.payload.race.push(
          {
            value: 'race-disabled',
            label: '---',
            disabled: true,
          },
          {
            value: 'create',
            label: t('common:label.prefer-self-describe'),
          },
        );
      action.payload.education &&
        action.payload.education.push(
          {
            value: 'race-disabled',
            label: '---',
            disabled: true,
          },
          {
            value: 'create',
            label: t('common:label.other'),
          },
        );
      return {
        ...state,
        data: {
          ...state.data,
          ethnicityTypes: action.payload.ethnicity,
          genders: action.payload.genders,
          languages: action.payload.languages,
          officeTypes: action.payload.office_types,
          organizationsTypes: action.payload.organisation_types,
          userTypesRoles: action.payload.user_type_roles,
          userRoles: action.payload.user_roles,
          pronounsTypes: action.payload.pronouns,
          racesTypes: action.payload.race,
          educationsType: action.payload.education,
          elmRoleTypes: action.payload.elm_role_types,
        },
      };
    }
    default:
      return state;
  }
};

export default utilsReducer;
