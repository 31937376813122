import React, { FC, useEffect, useState } from 'react';
import { Modal } from '../index';
import { IRouteParamsBase, ISelectOptions } from '../../../models';
import { Button, Select } from '../../controls';
import { useTranslation } from 'react-i18next';
import { Modals } from '../../../constants';
import { ROOTS } from '../../../routes/routes';
import { useHistory, useParams } from 'react-router';

interface IProps {
  onClose: () => void;
  title: string;
  value: number[];
  values: ISelectOptions[];
  handleSave: (tempUsers: number[]) => void;
  subTitle?: string;
  bottomInfo?: string;
  noOptionsMessage?: string;
}

const SelectValuesModal: FC<IProps> = ({
  onClose,
  title,
  subTitle,
  values,
  bottomInfo,
  handleSave,
  value,
  noOptionsMessage,
}) => {
  const { t } = useTranslation();
  const [tempUsers, setTempUsers] = useState<ISelectOptions[]>([]);

  useEffect(() => {
    if (value && values) {
      const collectValues = values.filter(f => value.includes(Number(f.value)));
      setTempUsers(collectValues);
    }
  }, [value, values]);

  const handleSelect = (selectedValues: ISelectOptions[]) => {
    setTempUsers(selectedValues);
  };

  return (
    <Modal title={title} onClose={onClose}>
      <div className={'b-EAModal'}>
        <div className={'b-step__teamModal__list'}>
          <Select
            value={tempUsers}
            placeholder={subTitle}
            ariaLabel="Ethnicity"
            options={values || []}
            handleChange={value => handleSelect(value)}
            noOptionsMessage={noOptionsMessage}
            isMulti
          />
        </div>
        {bottomInfo ? (
          <div className={'b-step__teamModal__tip'}>
            <div className={'d-flex justify-content-center b-equityAudit__text14'}>{bottomInfo}</div>
          </div>
        ) : null}
        <div className="b-EAModal__buttons">
          <Button
            size="middle"
            className="button-mh"
            type={'transparency'}
            onPress={onClose}
            title={t('common:btn.cancel')}
          />
          <Button
            size="middle"
            className="button-mh"
            type={'orange-light'}
            onPress={() => handleSave(tempUsers.map(user => Number(user.value)))}
            title={t('common:btn.save')}
            enableEnter
          />
        </div>
      </div>
    </Modal>
  );
};

export default SelectValuesModal;
