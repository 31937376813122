import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useEwpApi } from '../../../../../hooks/ewp/use-ewp-api';
import { ChartEwpDashboardModel, IRouteParamsEwp } from '../../../../../models';
import { Loader } from '../../../../controls';
import { EwpChartBar, HeadlineOrganization, OrgBreadcrumbs } from '../../../../common';
import { useEwpDetails } from '../../../../../hooks/ewp/use-ewp-details';
import BackTitleButton from '../parts/BackTitleButton';
import EwpTabs from '../parts/EwpTabs';
import { useGetEwpRoute } from '../../../../../hooks/ewp/use-get-ewp-route';
import { ROOTS } from '../../../../../routes/routes';

const EwpDashboard = () => {
  const { t } = useTranslation();
  const { ewpDetails } = useEwpDetails();
  const { getDashboard } = useEwpApi();
  const { elId } = useParams<IRouteParamsEwp>();
  const [chartStrategy, setChartStrategy] = useState<ChartEwpDashboardModel | null>(null);
  const EWP_KA_LIST = useGetEwpRoute(ROOTS.EWP_KA_LIST);
  const EWP_STRATEGY_LIST = useGetEwpRoute(ROOTS.EWP_STRATEGY_LIST);
  const history = useHistory();

  useEffect(() => {
    getDashboard((data: ChartEwpDashboardModel) => {
      setChartStrategy(data);
    });
  }, [elId]);

  const redirectToFilteredTable = (status: number, route: string) => {
    history.push(route, { status });
  };

  if (!chartStrategy) return <Loader />;

  return (
    <main className={'b-ewpDashboard b-page'}>
      <div className="b-page__header -desktop">
        <HeadlineOrganization
          orgName={ewpDetails?.organization_name || ''}
          orgId={ewpDetails?.organization_id || ''}
          headline={t('common:headlines.equity-work-plan')}
          customClasses={'-noMb -desktop'}
          backBtn={<BackTitleButton />}
        />
      </div>
      <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
      <EwpTabs activeItemAlias={'dashboard'} />
      <div className="contentContainer">
        <div className={'b-ewpDashboard__chart'}>
          <EwpChartBar
            chart={chartStrategy.strategy}
            title={t('common:headlines.priorities-strategies-status')}
            legendTitle={t('common:headlines.strategies-status')}
            redirectToFilteredTable={(status: number) => redirectToFilteredTable(status, EWP_STRATEGY_LIST)}
          />
        </div>
        <div className={'b-ewpDashboard__chart mt-4'}>
          <EwpChartBar
            chart={chartStrategy.key_action}
            title={t('common:headlines.priorities-complete-strategies')}
            legendTitle={t('common:headlines.key-actions-status')}
            redirectToFilteredTable={(status: number) => redirectToFilteredTable(status, EWP_KA_LIST)}
          />
        </div>
      </div>
    </main>
  );
};

export default EwpDashboard;
