import { ActionModel, DebriefBelovedUserModel, PaginationModel } from '../../../models';
import { actionCreator } from '../../actionCreator';
import { BelDebriefsState } from './debriefsReducer';
import { BelDebriefModel } from '../../../models';
import { LOGOUT_SUCCESS } from '../../authData/authDataActions';

//-----BEL_GET_DEBRIEFS_LIST----//
export const BEL_GET_DEBRIEFS_LIST = 'beloved/BEL_GET_DEBRIEFS_LIST';
export const BEL_GET_DEBRIEFS_LIST_SUCCESS = 'beloved/BEL_GET_DEBRIEFS_LIST_SUCCESS';
export interface BelGetDebriefsListPayload {
  params: {
    page?: number;
    page_size?: number;
    ordering?: string;
    search?: string;
    organization_id?: number;
  };
  callback?: () => void;
}
export const belGetDebriefsList = actionCreator<BelGetDebriefsListPayload>(BEL_GET_DEBRIEFS_LIST);
export const belGetDebriefsListSuccess = actionCreator<PaginationModel<BelDebriefModel[]>>(
  BEL_GET_DEBRIEFS_LIST_SUCCESS,
);

//-----GET_BELOVED_USERS----//
export const GET_BELOVED_USERS = 'beloved/GET_BELOVED_USERS';
export interface GetBelovedUsersPayload {
  callback: (users: DebriefBelovedUserModel[]) => void;
}
export const getBelovedUsers = actionCreator<GetBelovedUsersPayload>(GET_BELOVED_USERS);

//-----SET_DEBRIEF_LEADER----//
export const SET_DEBRIEF_LEADER = 'beloved/SET_DEBRIEF_LEADER';
export interface SetDebriefLeaderPayload {
  debrief_id: number;
  user_id: number;
  callback: () => void;
}
export const setDebriefLeader = actionCreator<SetDebriefLeaderPayload>(SET_DEBRIEF_LEADER);

//-----SET_QUERY---//
export const SET_QUERY = 'beloved-debrief/SET_QUERY';
export const setQuery = actionCreator<BelDebriefsState['query']>(SET_QUERY);

export type BelDebriefsActionsTypes =
  | ActionModel<typeof BEL_GET_DEBRIEFS_LIST, BelGetDebriefsListPayload>
  | ActionModel<typeof LOGOUT_SUCCESS>
  | ActionModel<typeof BEL_GET_DEBRIEFS_LIST_SUCCESS, PaginationModel<BelDebriefModel[]>>
  | ActionModel<typeof SET_QUERY, BelDebriefsState['query']>;
