import React, { FC, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ROOTS } from '../../../routes/routes';
import Api from '../../../services/api';

interface IProps {
  orgIds: number[];
}

const McTableTool: FC<IProps> = ({ orgIds }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showPopover, setShowPopover] = useState(false);

  const handleNewMessage = async () => {
    const response = await Api.post('beloved/marketing_communications/', {
      organization_ids: orgIds,
    });
    history.push(ROOTS.BELOVED_MESSAGE_CREATE, {
      messageId: response.id,
    });
  };

  const handleExistingMessage = () => {
    history.push(ROOTS.BELOVED_MESSAGE_LIST, { orgIds });
  };

  const renderContent = () => {
    return (
      <div className="b-dropDownMenu">
        <ul>
          <li onClick={handleNewMessage}>New Message</li>
          <li onClick={handleExistingMessage}>Existing Message</li>
        </ul>
      </div>
    );
  };

  return (
    <>
      <Popover
        isOpen={showPopover}
        positions={['bottom']} // preferred positions by priority
        content={renderContent}
        reposition={false}
        containerClassName={'popover'}
        align={'end'}
        onClickOutside={() => setShowPopover(false)}
      >
        <button
          onClick={() => setShowPopover(true)}
          disabled={!orgIds.length}
          className="b-tableTool -message -mobView -mr"
        >
          {t('common:label.marketing-communication')}
        </button>
      </Popover>
    </>
  );
};

export default McTableTool;
