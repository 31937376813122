import { call, all, take, StrictEffect, put } from 'redux-saga/effects';
import {
  BEL_CREATE_COHORT,
  BEL_GET_COHORT,
  BEL_GET_COHORT_ORG,
  BEL_GET_COHORT_TEAM,
  BEL_UPDATE_COHORT,
  BelCreateCohortPayload,
  BelGetCohortPayload,
  BelGetCohortSessionPayload,
  BelGetCohortTeamPayload,
  BelUpdateCohortPayload,
  BEL_GET_COHORT_LIST,
  BelGetCohortListPayload,
  BelGetCohortOrganizationsPayload,
  BEL_GET_COHORT_SESSION,
  BelCreateCohortSessionPayload,
  BEL_CREATE_COHORT_SESSION,
  BelUpdateCohortSessionPayload,
  BEL_UPDATE_COHORT_SESSION,
  BelAvailableResourcePayload,
  BEL_AVAILABLE_RESOURCE,
  BelDeleteCohortSessionPayload,
  BEL_DELETE_COHORT_SESSION,
  BEL_GET_COHORT_MESSAGE,
  BelGetCohortMessagePayload,
  BEL_CREATE_COHORT_MESSAGE,
  BelCreateCohortMessagePayload,
  BEL_UPDATE_COHORT_MESSAGE,
  BelUpdateCohortMessagePayload,
  BelDeleteCohortMessagePayload,
  BEL_DELETE_COHORT_MESSAGE,
  BEL_GET_COHORT_GENERAL_RESOURCE,
  BelGetCohortGeneralResourcePayload,
  belAvailableResourceSuccess,
  GET_SELECTED_COHORT_RESOURCE_IDS,
  GetSelectedCohortResourceIdsPayload,
  BelUpdateCohortFieldPayload,
  BEL_UPDATE_COHORT_FIELD,
  BelGetCohortMessageListPayload,
  BEL_GET_COHORT_MESSAGE_LIST,
  BelGetCohortSessionListPayload,
  BEL_GET_COHORT_SESSION_LIST,
  BEL_GET_COHORT_RESOURCES,
  BelGetCohortResourcesPayload,
  BEL_GET_COHORT_PARTICIPANT,
  BelCohortParticipantPayload,
  betGetCohortParticipantSuccess,
  AVAILABLE_PARTICIPANT,
  AvailableParticipantResourcePayload,
  AddParticipantPayload,
  ADD_PARTICIPANT,
  DeleteParticipantPayload,
  DELETE_PARTICIPANT,
  GET_COHORT_ORGANIZATIONS,
  GetCohortOrganizationPayload,
  getCohortOrganizationSuccess,
  getCohortSuccess,
  CHANGE_VISIBILITY_PARTICIPANT,
} from './cohortActions';
import Api from '../../services/api';
import { getOrgIdFormRoute } from '../../helpers';

function* getCohortListSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback },
    }: { payload: BelGetCohortListPayload } = yield take(BEL_GET_COHORT_LIST);

    try {
      const response = yield call(Api.get, `beloved/cohort/`, {
        page: 1,
        page_size: 500,
      });

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* getCohortOrganizationSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelGetCohortOrganizationsPayload } = yield take(BEL_GET_COHORT_ORG);

    try {
      const response = yield call(Api.post, `beloved/cohort/available_org/?page=${data?.page || 1}`, data);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* getCohortResourcesSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelGetCohortResourcesPayload } = yield take(BEL_GET_COHORT_RESOURCES);

    try {
      const response = yield call(Api.get, `beloved/cohort/${data.cohort_id}/list_of_linked_resources/`);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* createCohortSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelCreateCohortPayload } = yield take(BEL_CREATE_COHORT);
    try {
      const response = yield call(Api.post, `beloved/cohort/`, data);

      if (response) {
        callback && callback(response);
      }
    } catch (e: any) {
      callback && callback(null, e.response.data);
      console.log('error message', e);
    }
  }
}

function* updateCohortSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelUpdateCohortPayload } = yield take(BEL_UPDATE_COHORT);
    try {
      const response = yield call(Api.put, `beloved/cohort/${data.id}/`, data);

      if (response) {
        callback && callback(response);
        yield put(getCohortSuccess(response));
      }
    } catch (e: any) {
      callback && callback(null, e.response.data);
      console.log('error message', e);
    }
  }
}

function* updateCohortFieldSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelUpdateCohortFieldPayload } = yield take(BEL_UPDATE_COHORT_FIELD);
    try {
      const response = yield call(Api.patch, `beloved/cohort/${data.id}/`, undefined, data);

      if (response) {
        callback && callback(response);
        yield put(getCohortSuccess(response));
      }
    } catch (e: any) {
      callback && callback(null, e.response.data);
      console.log('error message', e);
    }
  }
}

function* belGetTeamSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback },
    }: { payload: BelGetCohortTeamPayload } = yield take(BEL_GET_COHORT_TEAM);

    try {
      const response = yield call(Api.get, `beloved/cohort/get_beloved_team/`, {});

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* belGetGeneralResourceSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelGetCohortGeneralResourcePayload } = yield take(BEL_GET_COHORT_GENERAL_RESOURCE);

    try {
      const response = yield call(Api.get, `beloved/cohort/${data.id}/list_of_linked_resources/`);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* getCohortSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelGetCohortPayload } = yield take(BEL_GET_COHORT);

    try {
      const response = yield call(Api.get, `beloved/cohort/${data.id}/`);

      if (response) {
        callback && callback(response);
        yield put(getCohortSuccess(response));
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* getCohortSessionSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelGetCohortSessionPayload } = yield take(BEL_GET_COHORT_SESSION);

    try {
      const response = yield call(Api.get, `beloved/cohort_session/${data.id}/`);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* getCohortSessionListSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelGetCohortSessionListPayload } = yield take(BEL_GET_COHORT_SESSION_LIST);

    try {
      const response = yield call(Api.get, `beloved/cohort_session/`, data);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* getCohortMessageSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelGetCohortMessagePayload } = yield take(BEL_GET_COHORT_MESSAGE);

    try {
      const response = yield call(Api.get, `beloved/cohort_message/${data.id}/details/`);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* getCohortMessageListSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelGetCohortMessageListPayload } = yield take(BEL_GET_COHORT_MESSAGE_LIST);

    try {
      const response = yield call(Api.get, `beloved/cohort_message/`, data);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* createCohortMessageSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelCreateCohortMessagePayload } = yield take(BEL_CREATE_COHORT_MESSAGE);

    try {
      const response = yield call(Api.post, `beloved/cohort_message/`, data);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* updateCohortMessageSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelUpdateCohortMessagePayload } = yield take(BEL_UPDATE_COHORT_MESSAGE);

    try {
      const response = yield call(Api.put, `beloved/cohort_message/${data.id}/`, data);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* deleteCohortMessageSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelDeleteCohortMessagePayload } = yield take(BEL_DELETE_COHORT_MESSAGE);

    try {
      const response = yield call(Api.delete, `beloved/cohort_message/${data.id}/remove/`);
      callback && callback(response);
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* createCohortSessionSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelCreateCohortSessionPayload } = yield take(BEL_CREATE_COHORT_SESSION);

    try {
      const response = yield call(Api.post, `beloved/cohort_session/`, data);

      if (response) {
        callback && callback(response);
      }
    } catch (e: any) {
      callback && callback(null, e.response.data);
      console.log('error message', e);
    }
  }
}

function* createDeleteSessionSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelDeleteCohortSessionPayload } = yield take(BEL_DELETE_COHORT_SESSION);

    try {
      const response = yield call(Api.delete, `beloved/cohort_session/${data.id}/remove/`);
      callback && callback(response);
    } catch (e: any) {
      callback && callback(null, e.response.data);
      console.log('error message', e);
    }
  }
}

function* updateCohortSessionSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: BelUpdateCohortSessionPayload } = yield take(BEL_UPDATE_COHORT_SESSION);
    try {
      const response = yield call(Api.put, `beloved/cohort_session/${data.id}/`, data);

      if (response) {
        callback && callback(response);
      }
    } catch (e: any) {
      callback && callback(null, e.response.data);
      console.log('error message', e);
    }
  }
}

function* getAvailableResourceSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, extraData, params },
    }: { payload: BelAvailableResourcePayload } = yield take(BEL_AVAILABLE_RESOURCE);
    try {
      let response;
      if (extraData && extraData.session_id) {
        response = yield call(
          Api.get,
          `beloved/cohort_session/available_resource_list/${extraData.session_id}/`,
          params,
        );
      } else if (extraData && extraData.cohort_id) {
        response = yield call(
          Api.get,
          `beloved/cohort/available_resource_list/${extraData.cohort_id}/`,
          params,
        );
      }

      if (response) {
        callback && callback(response);
        yield put(belAvailableResourceSuccess(response));
      }
    } catch (e: any) {
      callback && callback(null, e.response.data);
      console.log('error message', e);
    }
  }
}

function* getAvailableParticipantSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: AvailableParticipantResourcePayload } = yield take(AVAILABLE_PARTICIPANT);
    try {
      let response = yield call(
        Api.get,
        `organizations/${data.organization_id}/cohort_participants/${data.cohort_id}/available_participants/`,
      );

      if (response) {
        callback && callback(response);
      }
    } catch (e: any) {
      callback && callback(null, e.response.data);
      console.log('error message', e);
    }
  }
}

function* addAvailableParticipantSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data, params },
    }: { payload: AddParticipantPayload } = yield take(ADD_PARTICIPANT);
    try {
      const response = yield call(
        Api.post,
        `organizations/${data.organization_id}/cohort_participants/${data.cohort_id}/add_users/`,
        params,
      );

      if (response) {
        callback && callback(response);
      }
    } catch (e: any) {
      callback && callback(null, e.response.data);
      console.log('error message', e);
    }
  }
}

function* deleteAvailableParticipantSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data, params },
    }: { payload: DeleteParticipantPayload } = yield take(DELETE_PARTICIPANT);
    try {
      const response = yield call(
        Api.post,
        `organizations/${data.organization_id}/cohort_participants/${data.cohort_id}/delete_users/`,
        params,
      );

      if (response) {
        callback && callback(response);
      }
    } catch (e: any) {
      callback && callback(null, e.response.data);
      console.log('error message', e);
    }
  }
}

function* changeVisibilityParticipantSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data, params },
    }: { payload: DeleteParticipantPayload } = yield take(CHANGE_VISIBILITY_PARTICIPANT);
    try {
      const response = yield call(
        Api.post,
        `organizations/${data.organization_id}/cohort_participants/${data.cohort_id}/invisible/`,
        params,
      );

      if (response) {
        callback && callback(response);
      }
    } catch (e: any) {
      callback && callback(null, e.response.data);
      console.log('error message', e);
    }
  }
}

function* getCohortParticipantSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, extraData, params },
    }: { payload: BelCohortParticipantPayload } = yield take(BEL_GET_COHORT_PARTICIPANT);
    try {
      let response;
      if (extraData) {
        response = yield call(
          Api.get,
          `organizations/${extraData.orgId}/cohort_participants/${extraData.cohort_id}/`,
          params,
        );
      }

      if (response) {
        callback && callback(response);
        yield put(betGetCohortParticipantSuccess(response));
      }
    } catch (e: any) {
      callback && callback(null, e.response.data);
      console.log('error message', e);
    }
  }
}

function* getCohortOrganizationsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, extraData, params },
    }: { payload: GetCohortOrganizationPayload } = yield take(GET_COHORT_ORGANIZATIONS);
    const orgId = getOrgIdFormRoute();
    try {
      let response;
      if (extraData) {
        response = yield call(Api.get, `organizations/${orgId}/cohort_org/${extraData.cohort_id}/`, params);
      }

      if (response) {
        callback && callback(response);
        yield put(getCohortOrganizationSuccess(response));
      }
    } catch (e: any) {
      callback && callback(null, e.response.data);
      console.log('error message', e);
    }
  }
}

function* getSelectedCohortResourceSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: GetSelectedCohortResourceIdsPayload } = yield take(GET_SELECTED_COHORT_RESOURCE_IDS);
    try {
      const response = yield call(Api.post, `beloved/cohort/get_resources_by_ids/`, data);

      if (response) {
        callback && callback(response);
      }
    } catch (e: any) {
      callback && callback(null, e.response.data);
      console.log('error message', e);
    }
  }
}

export function* saga() {
  yield all([
    belGetGeneralResourceSaga(),
    getCohortMessageSaga(),
    getCohortMessageListSaga(),
    createCohortMessageSaga(),
    getCohortResourcesSaga(),
    updateCohortMessageSaga(),
    deleteCohortMessageSaga(),
    getCohortListSaga(),
    getCohortOrganizationSaga(),
    createCohortSaga(),
    belGetTeamSaga(),
    getCohortSaga(),
    updateCohortSaga(),
    getCohortSessionSaga(),
    getCohortSessionListSaga(),
    createCohortSessionSaga(),
    updateCohortSessionSaga(),
    getAvailableResourceSaga(),
    createDeleteSessionSaga(),
    getSelectedCohortResourceSaga(),
    updateCohortFieldSaga(),
    getCohortParticipantSaga(),
    getAvailableParticipantSaga(),
    addAvailableParticipantSaga(),
    deleteAvailableParticipantSaga(),
    getCohortOrganizationsSaga(),
    changeVisibilityParticipantSaga(),
  ]);
}
