import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StateType } from '../../../store/reducers';
import { ThemeClassName, ThemeEnum } from '../../../models';
import { DARK_MODE_ENABLED } from '../../../constants';

const ThemeController = () => {
  const user = useSelector((state: StateType) => state.profile.authUser);

  useEffect(() => {
    if (DARK_MODE_ENABLED) {
      let theme;
      if (user?.user.settings.theme) {
        localStorage.setItem('theme', String(user.user.settings.theme));
        theme = ThemeEnum[user.user.settings.theme];
      } else {
        const localStorageTheme = ThemeEnum[Number(localStorage.getItem('theme'))];
        if (localStorageTheme) {
          theme = localStorageTheme;
        } else {
          theme = ThemeClassName.light;
        }
      }

      theme = ThemeController.checkSystemSettings(theme as ThemeClassName);
      document.documentElement.className = theme;
    } else {
      document.documentElement.className = 'light';
    }
  }, [user?.user.settings.theme]);
  return null;
};

export default ThemeController;

ThemeController.checkSystemSettings = (theme: ThemeClassName) => {
  if (theme === ThemeClassName.default) {
    const isDarkModeSystem = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (isDarkModeSystem) {
      theme = ThemeClassName.dark;
    } else {
      theme = ThemeClassName.light;
    }
  }
  return theme;
};
