import React from 'react';
import { Button, Image } from '../../../controls';
import Input from '../../../controls/Inputs/Input';
import styles from './SignInStyle.module.scss';
import Logo from '../../../../assets/images/icons/logo-awa.svg';
import { Footer } from '../../../common';
import EmailNotVerifiedContainer from '../EmailNotVerified/EmailNotVerifiedContainer';
import ModalView from '../../../controls/ModalView/ModalView';
import { PopupSignInModel } from './SignInContainer';
import { ROOTS } from '../../../../routes/routes';
import { useTranslation } from 'react-i18next';

interface IProps {
  handleShowNotification: (show: any) => void;
  errors: IErrors;
  showNotification: PopupSignInModel | null;
  handleChangeInput: (name: string, value: string) => void;
  formData: {
    username: string;
    password: string;
  };
  isDisableButton: boolean;
  onSubmit: () => void;
  loader?: string;
  navTo: (router: ROOTS) => (e: any) => void;
}

interface IErrors {
  username: string;
  password: string;
}

const SignInView = ({
  loader,
  handleShowNotification,
  onSubmit,
  showNotification,
  errors,
  handleChangeInput,
  formData,
  isDisableButton,
  navTo,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={'container'} style={{ display: 'flex' }}>
      {loader}
      <ModalView
        isOpen={!!showNotification}
        onRequestClose={handleShowNotification}
        contentStyles={{
          transform: window.matchMedia('(max-width: 824px)').matches
            ? 'translate(-50%, -45%)'
            : 'translate(-50%, -58%)',
        }}
      >
        <EmailNotVerifiedContainer
          showNotification={showNotification}
          handleShowNotification={handleShowNotification}
        />
      </ModalView>
      <main className={'white-container content-center-wrapper mb-3'}>
        <div className={'container-page-center'}>
          <div className={'container-row'}>
            <Image width={'84px'} height={'84px'} source={Logo} alt={t('awa:AT9')} />
          </div>
          <div className={`container-row ${styles.title_wrapper}`}>
            <h1 className={'b-title blue-text text_spacing'}>{t('auth:login')}</h1>
          </div>
          <div className={'container-row'}>
            <Input
              error={errors.username}
              value={formData.username}
              handleChange={value => handleChangeInput('username', value)}
              type={'email'}
              placeholder={t('common:label.email-address')}
              ariaLabel="Email Address"
              autoFocus
            />
          </div>
          <div className={'container-row'}>
            <Input
              // error={errors.password}
              value={formData.password}
              handleChange={value => handleChangeInput('password', value)}
              type={'password'}
              ariaLabel="Password"
              placeholder={t('common:label.password')}
            />
          </div>
          <div className={`error-notification ${styles.errorWrap}`}>
            <span className={'error-notification-text'}>{errors.password}</span>
          </div>
          <div className={`container-row ${styles.forgot_password}`}>
            <a
              className={'links font-s links_underline_text'}
              href={'#'}
              onClick={navTo(ROOTS.FORGOT_PASSWORD)}
            >
              {t('auth:forgot-password')}
            </a>
          </div>
          <div className={'container-row container-button-wrapper'}>
            <Button
              size={'small'}
              onPress={onSubmit}
              title={t('common:btn.sign-in')}
              type={'orange-light'}
              disabled={isDisableButton}
              enableEnter
            />
          </div>
          <div className={'container-row'}>
            <span className={'text-description gray-text font-s'}>
              {t('auth:no-account')}{' '}
              <a className={'links links_underline_text'} href={'#'} onClick={navTo(ROOTS.SIGNUP)}>
                {t('common:btn.sign-up')}
              </a>
            </span>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default SignInView;
