import React, { ChangeEvent, FC, useState } from 'react';
import Cropper, { ReactCropperProps } from 'react-cropper';
import RemoveIc from '../../../assets/images/icons/round-content-add-circle.svg';
import Api from '../../../services/api';
import { UploadFileModel } from '../../../models';
import { DOMAIN_URI } from '../../../constants';
import { ImageSecured, Loader, Button } from '../../controls';
import { Modal, PopupPortal } from '../../common';
import { useTranslation } from 'react-i18next';
import { useImageCropper } from '../../../hooks/common/use-image-cropper';

interface IProps {
  photo: string | null;
  placeholderText: string;
  onChange: (file: UploadFileModel | null) => void;
  type?: 'profile' | 'cohort' | 'org-profile';
  cropProps?: ReactCropperProps;
  disabled?: boolean;
}

const ImageUploader: FC<IProps> = ({ placeholderText, photo, onChange, type, cropProps, disabled }) => {
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState('');
  const { moveImageToCrop, imgToCrop, setImgToCrop, setCropper, getCroppedFile } = useImageCropper();

  const { t } = useTranslation();

  const onSubmitCropping = async () => {
    const file = getCroppedFile();
    if (!file) return;
    const form = new FormData();

    form.append('file', file);
    setLoading(true);
    setImgToCrop('');
    const response = await Api.post('files/', form, {
      'Content-Type': 'multipart/form-data',
    });
    setLoading(false);
    onChange(response.result.resource);
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    setError('');
    if (files) {
      if (files[0].size > 2 * 1024 * 1024) {
        setError('file_size');
        return;
      }
      moveImageToCrop(files[0]);
      event.target.value = '';
    }
  };

  const handleDelete = (event: any) => {
    event.preventDefault();
    onChange(null);
  };

  const renderPhoto = () => {
    if (loading) {
      return <Loader fitParent />;
    }
    if (photo) {
      return <ImageSecured src={`${DOMAIN_URI}${photo}`} alt={t('awa:AT46')} />;
    }
    return placeholderText;
  };

  const renderUpdateImageText = () => {
    if (type === 'profile') {
      return t('common:label.upload-photo');
    } else if (type === 'org-profile') {
      return t('common:label.upload-org-logo');
    } else {
      return t('common:label.upload-image');
    }
  };

  const renderDeleteImageText = () => {
    if (type === 'profile') {
      return t('common:label.delete-photo');
    } else if (type === 'org-profile') {
      return t('common:label.delete-logo');
    } else {
      return t('common:label.delete-image');
    }
  };

  return (
    <>
      <div className={`b-imageUploader ${type ? `-${type}` : ''}`}>
        {!(type === 'org-profile' && !photo) ? (
          <div className={`b-avatar ${type ? `-${type}` : ''}`}>{renderPhoto()}</div>
        ) : null}
        {!disabled ? (
          <>
            {photo ? (
              <a className="b-link-icon-left" onClick={handleDelete} href="#">
                <img style={{ transform: 'rotate(45deg) translate(2px)' }} src={RemoveIc} alt="close" />
                {renderDeleteImageText()}
              </a>
            ) : (
              <div>
                <label className="b-uploadFileLink__label">
                  {renderUpdateImageText()}
                  <input
                    accept="image/jpeg, image/png"
                    className="b-uploadFileLink__input"
                    type="file"
                    onChange={handleChange}
                  />
                </label>
                {error ? <div className="error-notification-text">{t('awa:ER11.msg')}</div> : null}
              </div>
            )}
          </>
        ) : null}
      </div>
      {imgToCrop ? (
        <PopupPortal portalElementId={'user-avatar-portal'}>
          <Modal minHeight={520} onClose={() => setImgToCrop('')}>
            <div>
              <div className="pt-4 d-flex mb-3 justify-content-center">
                <Cropper
                  style={{ height: 400, width: 400 }}
                  initialAspectRatio={1}
                  src={imgToCrop}
                  minCropBoxHeight={150}
                  minCropBoxWidth={150}
                  aspectRatio={1}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={instance => {
                    setCropper(instance);
                  }}
                  guides={true}
                  {...(cropProps || {})}
                />
              </div>
              <div className="d-flex justify-content-center">
                <Button size="middle" title={t('common:label.crop-image')} onPress={onSubmitCropping} />
              </div>
            </div>
          </Modal>
        </PopupPortal>
      ) : null}
    </>
  );
};

export default ImageUploader;
