import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Alert } from '../../../../common';
import { schedulePaymentConfirm } from '../../../../../store/payments/paymentsActions';
import { PaymentIntentResult } from '@stripe/stripe-js/types/stripe-js/stripe';
import { DOMAIN_URI } from '../../../../../constants';
import { getEAList } from '../../../../../store/equityAudit/equityAuditActions';
import { clearReports } from '../../../../../store/report/reportActions';
import { Button, Checkbox, Loader } from '../../../../controls';
import * as api from '@stripe/stripe-js/types/api';
import { ROOTS } from '../../../../../routes/routes';
import { useTranslation } from 'react-i18next';
import { useIsDarkMode } from '../../../../../hooks/common/use-is-dark-mode';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';

interface IProps {
  amount: number | undefined;
  clientSecret: any;
}

export default function CheckoutForm({ amount, clientSecret }: IProps) {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { isDarkMode } = useIsDarkMode();
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [price, setPrice] = useState<number | null>(null);
  const [isLoadedCache, setIsLoadedCache] = useState<boolean | null>(null);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { navBack } = useNavBack();

  const handlePaymentChange = (paymentIntent: api.PaymentIntent) => {
    switch (paymentIntent.status) {
      case 'succeeded': {
        setModal(true);
        setIsLoadedCache(false);
        dispatch(
          schedulePaymentConfirm({
            callback: () => {
              dispatch(
                getEAList({
                  params: {
                    sorting: null,
                    search: '',
                    page: 1,
                    page_size: 15,
                  },
                  callback: () => {
                    setIsLoadedCache(true);
                  },
                }),
              );
              dispatch(clearReports());
            },
            data: {
              front_response_data: paymentIntent,
            },
          }),
        );
        break;
      }
      case 'processing':
        setMessage(t('main:payment.processing'));
        break;
      case 'requires_payment_method':
        // setMessage("Your payment was not successful, please try again.");
        break;
      default:
        setMessage(t('main:payment.something-went-wrong'));
        break;
    }
  };

  useEffect(() => {
    if (elements) {
      elements.update({ appearance: { theme: isDarkMode ? 'night' : 'stripe' } });
    }
  }, [elements, isDarkMode]);

  // @ts-ignore
  useEffect(async () => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }: PaymentIntentResult) => {
      if (!paymentIntent) {
        return;
      }
      setPrice(paymentIntent.amount);
      handlePaymentChange(paymentIntent);
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    const { error, paymentIntent }: PaymentIntentResult = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${DOMAIN_URI}${history.location.pathname}`,
      },
      redirect: 'if_required',
    });

    if (paymentIntent) {
      handlePaymentChange(paymentIntent);
    }

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message as string);
      } else {
        setMessage(t('main:payment.unexpected-error'));
      }
    }

    setIsLoading(false);
  };

  const closeModal = () => {
    setModal(false);
    if (isLoadedCache) {
      history.go(-1);
    }
  };

  const numberPrice = Number(price || amount) / 100;
  const navToTerms = (e: any) => {
    e.preventDefault();
    history.push(ROOTS.TERMS_CONDITIONS_PAYMENT);
  };

  return (
    <div>
      {isLoadedCache === false ? <Loader fitParent /> : null}
      <form id="payment-form" onSubmit={handleSubmit}>
        {!(!price && !amount) && (
          <div className={'payment-amount'}>
            <h1 className={'title'}>{t('awa:M38.title')}</h1>
            <p>{t('awa:M38.msg')}</p>
            <span className={'amount'}>${numberPrice.toLocaleString()}</span>
          </div>
        )}
        <PaymentElement id="payment-element" />
        <Checkbox size={'lg'} checked={termsAccepted} onChange={e => setTermsAccepted(e.target.checked)}>
          <span className={'text-long-description gray-text'}>{t('common:checkbox.agreement.agree-to')}</span>
          <a href={'#'} className={`checkbox-text links gray-text links_underline_text`} onClick={navToTerms}>
            {t('common:checkbox.agreement.customer-terms')}
          </a>
        </Checkbox>
        <div className="b-modal__buttons">
          <Button
            className={'btn -type_form'}
            type={'transparency'}
            width={'100%'}
            onPress={() => navBack()}
            title={t('common:btn.cancel')}
            size={'large'}
          />
          <Button
            type={'orange-light'}
            disabled={isLoading || !stripe || !elements || !termsAccepted}
            id="submit"
            width={'100%'}
            onPress={() => {}}
            size={'large'}
            title={t('common:btn.pay-now')}
          />
        </div>
        {message && <div id="payment-message">{message}</div>}
      </form>
      {modal && isLoadedCache !== false ? (
        <Alert
          isOpen={modal}
          onRequestClose={closeModal}
          title={t('awa:N26.title')}
          text={
            <>
              <p>{t('awa:N26.msg')}</p>
            </>
          }
          buttons={{
            left: {
              title: t('common:btn.ok'),
              type: 'orange-light',
              onClick: closeModal,
            },
          }}
        />
      ) : null}
    </div>
  );
}
