import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTableDataV2 } from '../../../../hooks/common/use-table-data-v2';
import {
  BelOrgAssignmentModel,
  ISelectOptions,
  QueryParamsModel,
  TableDataEntities,
} from '../../../../models';
import { useHistory, useLocation } from 'react-router';
import { TableDateFilter } from '../../../common';
import { basicDateFormat, cutString } from '../../../../helpers';
import Api from '../../../../services/api';
import FilterModalButton from '../../../common/FilterModalButton/FilterModalButton';
import { DataTable } from '../../../common/DataTable/DataTable';
import { useSelector } from 'react-redux';
import { ROOTS } from '../../../../routes/routes';
import { Checkbox, EmptyLink, Link } from '../../../controls';
import { showAlert } from '../../../common/Alert/RemoteAlert';
import { useRowSelection } from 'react-data-grid';
import { useFilters } from '../../../../hooks/filters/use-filters';
import { BelovedPermissions } from '../../../../constants';
import { StateType } from '../../../../store/reducers';
import BelovedTeamAssignmentModal from '../../../common/BelovedTeamAssignmentModal/BelovedTeamAssignmentModal';

interface ILocationState {
  name?: string;
}

const BelOrgAssignmentList = () => {
  const { t } = useTranslation();
  const location = useLocation<ILocationState | undefined>();
  const history = useHistory();
  const { filterOptions } = useFilters();
  const user = useSelector((state: StateType) => state.profile.authUser);
  const isAllowBelovedAssignment = user?.beloved_user?.permissions.includes(
    BelovedPermissions.BELOVED_ORG_END_ASSIGNMENT,
  );
  const [selectedTeamMember, setSelectedTeamMember] = useState<BelOrgAssignmentModel | null>(null);
  const [availableTeam, setAvailableTeam] = useState<ISelectOptions[] | null>(null);

  const {
    query,
    fetchData,
    handleSearch,
    handleSelectDateFilter,
    handleSelectFilter,
    handleApplyFilter,
    filterData,
    isFiltersChanged,
    selectedEntitiesIds,
    setSelectedEntitiesIds,
    handleSelectOne,
    selectedEntities,
    setSelectedEntities,
  } = useTableDataV2<BelOrgAssignmentModel>({
    baseUrl: 'beloved/org_assignments/',
    entityName: TableDataEntities.BEL_ORG_ASSIGNMENT_LIST,
    enableSelectedEntities: true,
  });

  useEffect(() => {
    let newQuery: QueryParamsModel = { ...query };
    if (location.state) {
      if (location.state.name) {
        newQuery.search = location.state.name;
        newQuery.page = 1;
      }
    }
    fetchData(newQuery);
  }, [location.key]);

  const onEndAssignment = async () => {
    const response = await Api.delete('beloved/org_assignments/end_assignments/', undefined, {
      ids: selectedEntitiesIds,
    });
    if (response) {
      setSelectedEntitiesIds([]);
      setSelectedEntities([]);
      fetchData(query);
    }
  };

  const handleEndAssignment = () => {
    showAlert({
      title: t('awa:N79.title'),
      text: t('awa:N79.msg'),
      buttons: {
        left: {
          title: t('common:btn.cancel'),
        },
        right: {
          title: t('common:btn.yes'),
          onClick: onEndAssignment,
        },
      },
    });
  };

  const handleSaveTeam = async (id: number, role_type_ids: number[]) => {
    let response = await Api.post(
      `beloved/organizations/${selectedTeamMember?.organization_id}/update_organization_roles/`,
      {
        role_type_ids,
        org_assignment_id: id,
      },
    );
    if (response) {
      fetchData(query);
      setSelectedTeamMember(null);
    }
  };

  const handleCloseEditModal = (isRefresh?: boolean) => {
    if (isRefresh) {
      fetchData(query);
    }
    setSelectedTeamMember(null);
  };

  const checkIsEndAssignment = () => {
    return !selectedEntities.filter(f => !f.end_date).length;
  };
  const handleEditRole = (user: BelOrgAssignmentModel) => {
    Api.get(`beloved/organizations/${user.organization_id}/available_beloved_team/`).then(res => {
      if (res) {
        setAvailableTeam(res);
      }
    });
    setSelectedTeamMember(user);
  };

  return (
    <main className={'b-page'}>
      <h1 className={'b-page__title'}>
        {t('common:tabs.beloved-community-team')}:&nbsp;
        <span className="mark">{t('common:label.organization-assignments')}</span>
      </h1>
      <div className="b-page__controls">
        <div className="b-tableToolContainer">
          {isAllowBelovedAssignment ? (
            <button
              onClick={handleEndAssignment}
              disabled={checkIsEndAssignment()}
              className="b-tableTool -assignmentEnd -mobView -mr"
            >
              {t('common:btn.end-assignment')}
            </button>
          ) : null}
          {filterOptions ? (
            <FilterModalButton
              query={query}
              handleSelectFilter={handleSelectFilter}
              handleApplyFilter={handleApplyFilter}
              filterData={filterData}
              isFiltersChanged={isFiltersChanged}
              filters={filterOptions.assignments}
            />
          ) : null}
        </div>
        <div className="b-page__search">
          <form action="#" className="searchForm">
            <input
              type="search"
              value={query.search}
              onChange={e => handleSearch(e.target.value)}
              placeholder={t('common:label.search')}
              aria-label="Search field"
            />
            <span className="searchForm__icon" />
          </form>
        </div>
      </div>
      <DataTable<BelOrgAssignmentModel>
        baseUrl="beloved/org_assignments/"
        entityName={TableDataEntities.BEL_ORG_ASSIGNMENT_LIST}
        selectedEntitiesIds={selectedEntitiesIds}
        handleSelectOne={handleSelectOne}
        cols={[
          {
            key: 'user_name',
            name: t('common:column.beloved-team'),
            sortable: true,
            editable: false,
            resizable: true,
          },
          {
            key: 'org_roles',
            name: t('main:belovedTeam.organization-roles'),
            sortable: true,
            cellClass: 'b-rdgTable__cellTextWrap',
            editable: false,
            resizable: true,
            formatter(props) {
              return <div>{cutString(props.row.org_roles.map(cat => cat.label).join(', '), 33)}</div>;
            },
          },
          {
            key: 'organization_name',
            name: t('common:column.organization-name'),
            sortable: true,
            editable: false,
            resizable: true,
            formatter(props) {
              const handleClick = () => {
                history.push(
                  ROOTS.BELOVED_ORGANIZATION_DETAILS.replace(':orgId', String(props.row.organization_id)),
                );
              };
              return (
                <Link className="b-link" onClick={handleClick}>
                  {props.row.organization_name}
                </Link>
              );
            },
          },
          {
            key: 'affiliation',
            name: t('common:column.affiliation'),
            sortable: true,
            editable: false,
            resizable: true,
          },
          {
            key: 'org_type',
            name: t('common:column.type'),
            sortable: true,
            editable: false,
            resizable: true,
          },
          {
            key: 'start_date',
            name: t('common:column.start-date'),
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            formatter(props) {
              return (
                <>
                  {props.row.start_date
                    ? basicDateFormat(new Date(props.row.start_date))
                    : t('common:column.present')}
                </>
              );
            },
            headerRenderer(props) {
              if (query.dateFilters) {
                return (
                  <TableDateFilter
                    dateFilterId={'start_date'}
                    selectedFilters={query.dateFilters['start_date']}
                    periodFieldName={'start_date_period'}
                    dateRangeFieldName={'start_date_date_range'}
                    onDateFilterChanged={handleSelectDateFilter}
                    rdgProps={props}
                    fiscalOption={true}
                  />
                );
              }
            },
          },
          {
            key: 'end_date',
            name: t('common:column.end-date'),
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            formatter(props) {
              return (
                <>
                  {props.row.end_date
                    ? basicDateFormat(new Date(props.row.end_date))
                    : t('common:column.present')}
                </>
              );
            },
            headerRenderer(props) {
              if (query.dateFilters) {
                return (
                  <TableDateFilter
                    dateFilterId={'end_date'}
                    selectedFilters={query.dateFilters['end_date']}
                    periodFieldName={'end_date_period'}
                    dateRangeFieldName={'end_date_date_range'}
                    onDateFilterChanged={handleSelectDateFilter}
                    rdgProps={props}
                    fiscalOption={true}
                  />
                );
              }
            },
          },
          {
            key: 'tools',
            name: '',
            disableDrag: true,
            width: 40,
            maxWidth: 40,
            formatter: props => {
              return (
                <div className="b-rdgTable__cellCenter">
                  {!props.row.end_date ? (
                    <EmptyLink onClick={() => handleEditRole(props.row)} className="b-link-icon -edit mb-3" />
                  ) : null}
                </div>
              );
            },
          },
          {
            key: 'select',
            name: '',
            width: 40,
            maxWidth: 40,
            disableDrag: true,
            headerRenderer(props) {
              return (
                <Checkbox
                  checked={props.allRowsSelected}
                  onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
                />
              );
            },
            formatter: props => {
              const [isRowSelected, onRowSelectionChange] = useRowSelection();

              return (
                <div className="b-rdgTable__cellCenter">
                  <div className={'checkBox'} onClick={e => e.stopPropagation()}>
                    <Checkbox
                      checked={isRowSelected}
                      onChange={e => {
                        onRowSelectionChange({
                          row: props.row,
                          checked: e.target.checked,
                          isShiftClick: false,
                        });
                      }}
                    />
                  </div>
                </div>
              );
            },
          },
        ]}
      />
      {selectedTeamMember ? (
        <BelovedTeamAssignmentModal
          value={selectedTeamMember}
          values={availableTeam || []}
          onClose={handleCloseEditModal}
          title={t('main:belovedTeam.edit-member')}
          handleSave={handleSaveTeam}
          isEdit={true}
        />
      ) : null}
    </main>
  );
};

export default BelOrgAssignmentList;
