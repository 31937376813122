import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link, Loader } from '../../../../controls';
import { BelEaSavedAvgModel, IRouteParamsBase, TableDataEntities } from '../../../../../models';
import { HeadlineOrganization, OrgBreadcrumbs } from '../../../../common';
import { basicDateFormat } from '../../../../../helpers';
import { useTranslation } from 'react-i18next';
import SortModalButton from '../../../../common/SortModalButton/SortModalButton';
import CheckedItems from '../../../../common/CheckedItems/CheckedItems';
import { useSelectCurrentOrg } from '../../../../../hooks/org/use-select-current-org';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { useTableDataV2 } from '../../../../../hooks/common/use-table-data-v2';
import { DataTable } from '../../../../common/DataTable/DataTable';
import { eaAVgListType } from '../../../../../constants/ea';
import { getColumns } from './columns';
import { ROOTS } from '../../../../../routes/routes';
import { showAlert } from '../../../../common/Alert/RemoteAlert';
import { toast } from 'react-toastify';
import Api from '../../../../../services/api';
import { useRole } from '../../../../../helpers/roles';
interface IRouteParams extends IRouteParamsBase {
  type: eaAVgListType;
}

interface ILocationState {
  org?: { id: number; name: string };
}

const GroupAveragesSaved = () => {
  const { t } = useTranslation();
  const location = useLocation<ILocationState | undefined>();
  const { navBack } = useNavBack();
  const currentOrg = useSelectCurrentOrg();
  const { orgId } = useParams<IRouteParams>();
  const history = useHistory();
  const access = useRole();
  const showDeleteBtn = access(['project_manager']);

  const isBeloved = location.pathname.match('beloved');
  const baseUrl = isBeloved
    ? `beloved/equity_audit/get_group_avg_list/`
    : `organizations/${orgId}/equity_audit/group_average/get_groups_list/`;

  const {
    query,
    data,
    fetchData,
    handleSort,
    setSortingData,
    sortingData,
    handleChangeDirection,
    handleChangeSortField,
    fetchMoreMobile,
    handleSelectOne,
    selectedEntitiesIds,
    handleSearch,
    setSelectedEntitiesIds,
    infiniteList,
  } = useTableDataV2<BelEaSavedAvgModel>({
    entityName: TableDataEntities.AVG_SAVED,
    baseUrl,
    enableSelectedEntities: true,
  });

  const onCellClick = (row: BelEaSavedAvgModel) => {
    const route = isBeloved
      ? ROOTS.BEL_GROUP_AVERAGES.replace(':avg_id', `${row.id}`)
      : ROOTS.GROUP_AVERAGES.replace(':orgId', orgId).replace(':avg_id', `${row.id}`);
    history.push(route);
  };

  const columns = getColumns({ baseUrl, onCellClick });

  useEffect(() => {
    fetchData(query);
  }, [location.key]);

  const onRowClick = (row: BelEaSavedAvgModel) => {
    history.push(
      isBeloved
        ? ROOTS.BEL_GROUP_AVERAGES_CHART.replace(':id', `${row.id}`)
        : ROOTS.GROUP_AVERAGES_CHART.replace(':orgId', `${orgId}`).replace(':id', `${row.id}`),
    );
  };

  const handleDelete = () => {
    showAlert({
      title: t('main:ewp-create.cancel.title'),
      text: (
        <>
          <p>{t('awa:N84.msg')}</p>
        </>
      ),
      buttons: {
        left: {
          title: t('common:btn.cancel'),
          type: 'transparency',
        },
        right: {
          title: t('common:btn.delete'),
          onClick: async () => {
            const route = isBeloved
              ? `beloved/equity_audit/delete_groups_avg/`
              : `organizations/${orgId}/equity_audit/group_average/delete_groups/`;
            const res = await Api.delete(
              route,
              {},
              {
                ids: selectedEntitiesIds,
              },
            );
            if (res) {
              fetchData(query);
              setSelectedEntitiesIds([]);
              toast.success(t('main:avg.delete-avg-success'));
            }
          },
        },
      },
    });
  };

  const handleNavAddAvg = () => {
    const route = isBeloved
      ? ROOTS.BEL_GROUP_AVERAGES.replace(':avg_id', 'list')
      : ROOTS.GROUP_AVERAGES.replace(':orgId', orgId).replace(':avg_id', 'list');
    history.push(route);
  };

  const renderContent = () => {
    if (!data) return <Loader fitParent />;

    return (
      <>
        <div className="-desktop position-relative">
          <CheckedItems value={selectedEntitiesIds.length} total={data.count} />
          <DataTable<BelEaSavedAvgModel>
            baseUrl={baseUrl}
            entityName={TableDataEntities.AVG_SAVED}
            selectedEntitiesIds={selectedEntitiesIds}
            handleSelectOne={handleSelectOne}
            cols={columns}
            onRowClick={onRowClick}
          />
        </div>
        {isMobile && (
          <div className="-mobile b-panelTitleOutside__list">
            <InfiniteScroll
              dataLength={infiniteList.length}
              next={fetchMoreMobile}
              hasMore={!(data.current_page === data.total_pages)}
              loader={<h4>{t('common:label.loading')}...</h4>}
            >
              {infiniteList.map((ea: BelEaSavedAvgModel) => {
                return (
                  <div key={`mob-item-${ea.id}`} className="b-panelTitleOutside__container">
                    <div className="b-panelTitleOutside">
                      <table>
                        <tr>
                          <th>{t('common:column.title')}</th>
                          <td>{ea.name}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.create-by')}</th>
                          <td>{ea.created_by_name}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.create-date')}</th>
                          <td>
                            {ea.created_at
                              ? basicDateFormat(new Date(ea.created_at))
                              : t('common:column.n-a')}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <div>
          <div className="b-page__header -desktop mb-0 align-items-baseline">
            <HeadlineOrganization
              orgName={currentOrg?.name || ''}
              orgId={currentOrg?.id || ''}
              headline={t('main:ea.ea')}
            />
          </div>
          <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />

          <div className="b-page__title2 font-navy">
            <Link className="b-report__backBtn" onClick={navBack}>
              <div className="back-icon" />
            </Link>
            {t('common:tabs.group-averages')}
          </div>

          <div className="b-page__controls">
            <div className="b-tableToolContainer">
              {isBeloved || showDeleteBtn ? (
                <button
                  onClick={handleDelete}
                  disabled={!selectedEntitiesIds.length}
                  className="b-tableTool -delete -mobView -mr"
                >
                  {t('common:btn.delete')}
                </button>
              ) : null}
              <button onClick={handleNavAddAvg} className="b-tableTool -addPlus -mobView -mr">
                {t('common:tabs.group-average')}
              </button>
            </div>
            <div className="b-page__search">
              <form action="#" className="searchForm">
                <input
                  type="search"
                  value={query.search}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder={t('common:label.search')}
                  aria-label="Search field"
                />
                <span className="searchForm__icon" />
              </form>
            </div>
          </div>
          <div className="-mobile">
            <div className="b-tableToolContainer -centered">
              <SortModalButton
                sortingData={sortingData}
                setSortingData={setSortingData}
                columns={columns}
                query={query}
                handleChangeSortField={handleChangeSortField}
                handleSort={handleSort}
              />
              <button
                onClick={handleChangeDirection}
                type="button"
                className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                    ${query.sorting?.direction === 'DESC' && '-active'}
                  `}
              >
                {t('common:btn.a-z-order')}
              </button>
            </div>
          </div>
        </div>
        {renderContent()}
      </main>
    </>
  );
};

export default GroupAveragesSaved;
