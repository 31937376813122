import React from 'react';
import StartSignUpView from './StartSignUpView';
import { useLocation } from 'react-router';
import { ROOTS } from '../../../../routes/routes';

interface IProps {
  history: any;
}

const StartSignUpContainer = ({ history }: IProps) => {
  const location = useLocation();
  const email = location.search.split('=')[1];
  const handleSubmit = () => {
    history.push(ROOTS.SIGNUP, { email });
  };
  return <StartSignUpView onSubmit={handleSubmit} />;
};

export default StartSignUpContainer;
