import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '../../../../common';
import Intro from './Intro/Intro';
import GroupDescription from './GroupDescription/GroupDescription';
import Quiz from './Quiz/Quiz';
import Submission from './Submission/Submission';
import Indicator from './Indicator/Indicator';
import { StateType } from '../../../../../store/reducers';
import { Question, IRouteParamsBase } from '../../../../../models';
import { Loader } from '../../../../controls';
import {
  takeEA,
  saveAnswer,
  SaveAnswerPayload,
  clearTakeEAData,
  skipFGroup,
  deleteFGroup,
  submitEA,
} from '../../../../../store/equityAudit/equityAuditActions';
import { useHistory, useLocation, useParams } from 'react-router';
import { ROOTS } from '../../../../../routes/routes';
import { useTranslation } from 'react-i18next';
import HeadlineOrganization from '../../../../common/HeadlineOrganization/HeadlineOrganization';

interface ILocationState {
  eaId: number;
}

interface IState {
  currentGroupId: string | null;
  currentQuestionId: number | null;
  groupQuestions: Question[] | null;
  showSubmission: boolean;
  submitModal: boolean;
}

const TakingAudit = () => {
  const { t } = useTranslation();
  const history: any = useHistory();
  const dispatch = useDispatch();
  const { orgId } = useParams<IRouteParamsBase>();
  const location = useLocation<ILocationState>();
  const takingEA = useSelector((state: StateType) => state.equityAudit.takingEA);
  let [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state: StateType) => state.profile.authUser);

  const initialState: IState = {
    currentGroupId: null,
    currentQuestionId: null,
    groupQuestions: null,
    showSubmission: false,
    submitModal: false,
  };

  const [state, setState] = useState<IState>(initialState);

  const _getQuestionByGroupId = (gId: string): Question[] => {
    return takingEA?.questions
      .filter(q => q.function_group_id === gId)
      .sort((a, b) => a.position - b.position) as Question[];
  };

  useEffect(() => {
    if (location.state.eaId) {
      dispatch(
        takeEA({
          eaId: location.state.eaId,
        }),
      );
    }
    return () => {
      dispatch(clearTakeEAData());
    };
  }, []);

  useEffect(() => {
    if (takingEA) {
      setState(prevState => {
        const newState = { ...prevState };
        newState.currentQuestionId = takingEA.current_question_id;
        newState.currentGroupId = takingEA.current_function_group_id;
        if (takingEA.current_function_group_id) {
          newState.groupQuestions = _getQuestionByGroupId(takingEA.current_function_group_id);
        }
        return newState;
      });
    }
  }, [takingEA]);

  const setCurrentFunctionGroupId = (id: string) => {
    const questions = _getQuestionByGroupId(id);
    setState({
      ...state,
      currentGroupId: id,
      currentQuestionId: null,
      showSubmission: false,
      groupQuestions: questions,
    });
  };

  const onPressNextIntro = () => {
    const groupId = takingEA?.function_groups[0].id as string;
    dispatch(
      saveAnswer({
        eaId: location.state.eaId,
        data: {
          current_function_group_id: groupId,
        },
        callback: () => {
          setCurrentFunctionGroupId(groupId);
        },
      }),
    );
  };

  const navToGroup = (isNext: boolean) => {
    if (takingEA && state.currentGroupId) {
      const currentGroupIndex = takingEA.function_groups.findIndex(g => g.id === state.currentGroupId);

      if (isNext) {
        const nextGroup = takingEA.function_groups[currentGroupIndex + 1];

        if (nextGroup) {
          setCurrentFunctionGroupId(nextGroup.id);
        } else {
          setState({
            ...state,
            showSubmission: true,
          });
        }
      }
    }
  };

  const skipGroup = () => {
    if (state.currentGroupId) {
      setIsLoading(true);
      dispatch(
        skipFGroup({
          eaId: location.state.eaId,
          fGroupId: state.currentGroupId,
          callback: () => {
            setIsLoading(false);
            navToGroup(true);
          },
        }),
      );
    } else {
      console.error('skipGroup');
    }
  };

  const deleteGroup = () => {
    if (state.currentGroupId) {
      setIsLoading(true);
      dispatch(
        deleteFGroup({
          eaId: location.state.eaId,
          fGroupId: state.currentGroupId,
          callback: () => {
            setIsLoading(false);
            navToGroup(true);
            // setState({...state, currentQuestionId: null})
          },
        }),
      );
    } else {
      console.error('deleteGroup');
    }
  };

  const navBack = () => {
    const { groupQuestions, currentQuestionId, currentGroupId } = state;
    if (groupQuestions && takingEA) {
      let prevQuestion: Question | null = null;
      if (currentQuestionId) {
        const currentQIndex = groupQuestions.findIndex(q => +q.id === +currentQuestionId);
        prevQuestion = groupQuestions[currentQIndex - 1];
      }

      if (prevQuestion) {
        setState({
          ...state,
          currentQuestionId: prevQuestion.id,
        });
      } else {
        if (!currentQuestionId && currentGroupId) {
          // desc screen
          const currentGIndex = takingEA.function_groups.findIndex(g => +g.id === +currentGroupId) as number;
          const prevGroup = takingEA.function_groups[currentGIndex - 1];

          if (prevGroup) {
            //
            const questions = _getQuestionByGroupId(prevGroup.id);
            const currentQuestionId = questions[questions.length - 1].id; // last question in group
            setState({
              ...state,
              currentGroupId: prevGroup.id,
              currentQuestionId,
              groupQuestions: questions,
            });
          } else {
            setState({
              ...state,
              currentQuestionId: null,
              currentGroupId: null,
              groupQuestions: null,
            });
          }
        } else {
          setState({
            ...state,
            currentQuestionId: null,
          });
        }
      }
    } else {
      console.error('navBack error');
    }
  };

  const navNext = () => {
    const { groupQuestions, currentQuestionId, currentGroupId } = state;
    if (groupQuestions && currentGroupId && takingEA) {
      if (!currentQuestionId) {
        setState({
          ...state,
          currentQuestionId: groupQuestions[0].id,
        });
        return;
      }
      const currentQIndex = groupQuestions.findIndex(q => +q.id === +currentQuestionId) as number;

      const nextQuestion = groupQuestions[currentQIndex + 1];

      if (nextQuestion) {
        setState({
          ...state,
          currentQuestionId: nextQuestion.id,
        });
      } else {
        const currentGIndex = takingEA.function_groups.findIndex(g => +g.id === +currentGroupId) as number;
        const nextGroup = takingEA.function_groups[currentGIndex + 1];

        if (nextGroup) {
          const questions = _getQuestionByGroupId(nextGroup.id);

          setState({
            ...state,
            currentGroupId: nextGroup.id,
            groupQuestions: questions,
            currentQuestionId: null,
          });
        } else {
          setState({
            ...state,
            showSubmission: true,
          });
        }
      }
    } else {
      console.error('navNext error', state);
    }
  };

  const handleSaveAnswer = (answerId: string | null, isNext: boolean) => {
    let data: SaveAnswerPayload['data'] = {};

    if (state.currentGroupId) {
      data.current_function_group_id = state.currentGroupId;
    }
    if (state.currentQuestionId) {
      data.current_question_id = state.currentQuestionId;
    }
    if (answerId) {
      data.answer_id = answerId;
    }

    setIsLoading(true);
    dispatch(
      saveAnswer({
        eaId: location.state.eaId,
        data,
        callback: () => {
          setIsLoading(false);
          if (isNext) {
            navNext();
          } else {
            history.push(ROOTS.EQUITY_AUDIT_LIST.replace(':orgId', `${orgId}`));
          }
        },
      }),
    );
  };

  const submitAudit = () => {
    setIsLoading(true);
    dispatch(
      submitEA({
        ea_id: location.state.eaId,
        callback: () => {
          setIsLoading(false);
          setState({ ...state, submitModal: true });
        },
      }),
    );
  };

  const handlePressSubmitModal = () => {
    history.push(ROOTS.EQUITY_AUDIT_LIST.replace(':orgId', `${orgId}`));
  };

  const renderContent = () => {
    const { currentGroupId, currentQuestionId, showSubmission, groupQuestions } = state;
    if (!takingEA) {
      return <Loader fitParent />;
    }
    let content = (content: ReactElement) => {
      return (
        <div className="b-EATaking">
          <div className="b-EATaking__header">
            <HeadlineOrganization
              orgName={takingEA.organization_name}
              orgId={orgId}
              headline={t('main:ea.ea')}
            />
          </div>
          {content}
        </div>
      );
    };
    if (showSubmission && currentGroupId) {
      return content(
        <>
          <Indicator
            isSubmit
            currentGroupId={currentGroupId}
            setCurrentGroupId={setCurrentFunctionGroupId}
            function_groups={takingEA.function_groups}
          />
          <Submission
            onSubmit={submitAudit}
            navBack={() => setState({ ...state, showSubmission: false })}
            enableEnter={!state.submitModal}
          />
        </>,
      );
    }
    if (!currentGroupId) {
      return content(<Intro takingEA={takingEA} onPressNext={onPressNextIntro} />);
    }
    if (currentGroupId && !currentQuestionId) {
      return content(
        <>
          <Indicator
            currentGroupId={currentGroupId}
            setCurrentGroupId={setCurrentFunctionGroupId}
            function_groups={takingEA.function_groups}
          />
          <GroupDescription
            skipGroup={skipGroup}
            handleBack={navBack}
            handleSaveAnswer={handleSaveAnswer}
            currentGroupId={currentGroupId}
            function_groups={takingEA.function_groups}
          />
        </>,
      );
    }
    const question = takingEA.questions.find(q => q.id === state.currentQuestionId);
    if (currentGroupId && currentQuestionId && question && groupQuestions) {
      return content(
        <>
          <Indicator
            currentGroupId={currentGroupId}
            setCurrentGroupId={setCurrentFunctionGroupId}
            function_groups={takingEA.function_groups}
          />
          <Quiz
            deleteGroup={deleteGroup}
            handleBack={navBack}
            handleSaveAnswer={handleSaveAnswer}
            question={question}
            groupQuestions={groupQuestions}
          />
        </>,
      );
    }
    return <Loader fitParent />;
  };

  return (
    <>
      <main className={'pageContainer -noRelative pageContainer__content'}>
        {isLoading && <Loader fitParent />}

        {renderContent()}
      </main>
      {state.submitModal && !takingEA?.first_submit_ea && (
        <Alert
          isOpen={state.submitModal}
          onRequestClose={handlePressSubmitModal}
          title={t('main:ea-submitted.title')}
          text={
            <>
              <p>{t('main:ea-submitted.msg.p1')}</p>
              <p>{t('main:ea-submitted.msg.p2')}</p>
            </>
          }
          buttons={{
            left: {
              title: t('common:btn.ok'),
              type: 'orange-light',
              onClick: handlePressSubmitModal,
              enableEnter: true,
            },
          }}
        />
      )}
      {state.submitModal && takingEA?.first_submit_ea && (
        <Alert
          isOpen={state.submitModal}
          onRequestClose={handlePressSubmitModal}
          title={t('awa:N39.title')}
          text={
            <>
              <p>{t('awa:N39.msg', { days: user?.user.res_lid_ea_days || 90 })}</p>
            </>
          }
          buttons={{
            left: {
              title: t('common:btn.ok'),
              type: 'orange-light',
              onClick: handlePressSubmitModal,
              enableEnter: true,
            },
          }}
        />
      )}
    </>
  );
};

export default TakingAudit;
