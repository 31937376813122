import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Modal } from '../../../../common';
import { ElmUserReportItemModel, IRouteParamsBase } from '../../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { useParams } from 'react-router';
import { getElmReport } from '../../../../../store/elm/elmActions';
import { Button, Loader } from '../../../../controls';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { useTranslation } from 'react-i18next';
import EmptyButton from '../../../../controls/Button/EmptyButton';

interface IRouteParams extends IRouteParamsBase {
  elmId: string;
}

const ElmMyReport = () => {
  const user = useSelector((state: StateType) => state.profile.authUser?.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { elmId } = useParams<IRouteParams>();
  const { navBack } = useNavBack();

  const [guideModal, setGuideModal] = useState(false);
  const [data, setData] = useState<ElmUserReportItemModel[] | null>(null);

  useEffect(() => {
    dispatch(
      getElmReport({
        data: {
          elmId: Number(elmId),
          type: 'user',
        },
        callback: ({ report }) => {
          if (report) {
            setData(report as ElmUserReportItemModel[]);
          }
        },
      }),
    );
  }, []);

  const getBandStyles = (val: string) => {
    switch (val) {
      case 'Mid':
        return '-mid';
      case 'High':
        return '-high';
      case 'Developing':
        return '-dev';
      default:
        return '-dev';
    }
  };

  const renderContent = () => {
    if (!data) return <Loader fitParent />;
    if (!data.length) return null;

    return (
      <div>
        {data.map((item: any, index: number) => {
          return (
            <div key={item.id} className="b-elmMyReportItem">
              <div className="b-elmMyReportItem__info">
                <div className="b-elmMyReportItem__nameWrapper">
                  <div className="b-elmMyReportItem__count">{index + 1}</div>
                  <div className="b-elmMyReportItem__name">{item.name}</div>
                </div>
                <div className={`b-elmMyReportItem__sband ${getBandStyles(item.score_band)}`}>
                  {item.score_band}
                </div>
              </div>
              <div className="b-elmMyReportItem__text">
                <div>{item.description}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className={'pageContainer pageContainer__content'}>
        <div className="b-report__header">
          {isMobileOnly && <div className="b-report__arrow" />}
          <h1 className={'pageContainer__title b-report__pageTitle'}>
            <span className="b-report__pageTitleLabel">{t('common:headlines.equity-lens-map')}:</span>
            <span className="b-report__orgName">
              {user?.first_name} {user?.last_name}
            </span>
          </h1>
          <div className="b-report__pageSubTitle">
            <a href="#" className="b-report__backBtn" onClick={navBack}>
              <div className="back-icon" />
            </a>
            <span>{t('common:headlines.Individual-Report')}</span>
          </div>
        </div>
        <div className="b-reportElm__myReportInfo">
          <p>{t('pp:elm-report.p1')}</p>
          <p>
            {t('pp:elm-report.p2')}{' '}
            <a
              className="orange-light-text"
              rel="noreferrer"
              target="_blank"
              href="https://sites.google.com/wearebeloved.org/elmresourceguide"
            >
              {t('pp:elm-report.p3')}
            </a>
            .
          </p>
          <b>{t('pp:elm-report.p4')}</b> {t('pp:elm-report.p5')} &quot;{t('pp:elm-report.p6')}&quot;{' '}
          {t('pp:elm-report.p7')}
        </div>
        <div className="b-report__heading">
          <strong className="b-report__headingTitle">{t('common:headlines.Your-Scores')}</strong>
          <a
            href="#"
            className="b-report__headingLink"
            onClick={e => {
              e.preventDefault();
              setGuideModal(true);
            }}
          >
            {t('common:btn.score-guide')}
          </a>
          <EmptyButton className="b-report__btnInfo" onClick={() => setGuideModal(true)} />
        </div>
        {renderContent()}
      </div>

      {guideModal && (
        <Modal width={650} title="Equity Lens Map Score Guide" onClose={() => setGuideModal(false)}>
          <div className="b-modal">
            <div className="b-typography b-modal__content">
              <p>{t('pp:elm-report.p8')}:</p>
              <ul className="mb-3">
                <li>{t('pp:elm-report.p9')}</li>
                <li>{t('pp:elm-report.p10')}</li>
                <li>{t('pp:elm-report.p11')}</li>
                <li>{t('pp:elm-report.p12')}</li>
                <li>{t('pp:elm-report.p13')}</li>
              </ul>
              <p>{t('pp:elm-report.p14')}:</p>
              <ul className="mb-3">
                <li>{t('pp:elm-report.p15')}</li>
                <li>{t('pp:elm-report.p16')}</li>
              </ul>
              <p>{t('pp:elm-report.p17')}:</p>
              <div className="b-elmReport2Guide">
                <div>
                  <div className="b-elmReport2Guide__item -yellow">
                    <ul>
                      <li>{t('common:label.developing')}</li>
                    </ul>
                    <div>{'< 2.0'}</div>
                  </div>
                  <div className="b-elmReport2Guide__item -orange">
                    <ul>
                      <li>{t('common:label.mid')}</li>
                    </ul>
                    <div>{'2.0 - 2.99'}</div>
                  </div>
                  <div className="b-elmReport2Guide__item -green">
                    <ul>
                      <li>{t('common:label.high')}</li>
                    </ul>
                    <div>{'3.0 - 4.0'}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="b-EAModal__buttons">
              <Button width={130} title={t('common:btn.ok')} onPress={() => setGuideModal(false)} />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ElmMyReport;
