import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  basicDateFormat,
  getBasicDateHourFormatCodeForDatePicker,
  sanitizedHTML,
  validateEmail,
} from '../../../../../helpers';
import DatePicker from 'react-datepicker';
import TimeZoneSelectView from '../../../../common/TimeZoneSelect/TimeZoneSelect';
import {
  MessageCreateModel,
  MessageModel,
  TestMessageSendModel,
  TimezoneSelectOption,
} from '../../../../../models';
import { Button, Editor, Link } from '../../../../controls';
import { GenericNotes, SendEmailModal } from '../../../../common';
import Api from '../../../../../services/api';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { ROOTS } from '../../../../../routes/routes';
import { genericNotesService } from '../../../../common/GenericNotes/GenericNotes';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import useHasAccess from '../../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../../constants';
import dayjs from 'dayjs';
import { showAlert } from '../../../../common/Alert/RemoteAlert';

const defaultBody = `
    <p><br></p>
    <p><br></p>
    <p><br></p>
    <p><br></p>
    <p>With Love,</p>
    <p>Awa by Beloved Team</p>
    <p>www.awabybeloved.com</p>
    <p><br></p>
    <p>We're a Family of Experts</p>
    <p>Our team is always available. If you need assistance, please contact us at <a rel="noreferrer" target="_blank" href="https://awabybeloved.freshdesk.com">https://awabybeloved.freshdesk.com</a></p>
  `;

const MessageForm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { navBack } = useNavBack();
  const user = useSelector((state: StateType) => state.profile.authUser?.user);
  const hasAccessToMarketingComUpdate = useHasAccess(BelovedPermissions.MARKETING_COMMUNICATION_UPDATE);
  const hasAccessToMarketingComRemove = useHasAccess(BelovedPermissions.MARKETING_COMMUNICATION_REMOVE);

  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [tz, setTz] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [date, setDate] = useState<Date | undefined>();
  const [body, setBody] = useState(defaultBody);
  const [subject, setSubject] = useState('');
  const [bcc, setBcc] = useState('');
  const [error, setError] = useState<any>({});

  const editData: MessageModel | undefined = location.state?.message;
  const lockedMode = editData?.is_delivered;
  const messageId: number | undefined = location.state?.messageId
    ? Number(location.state.messageId)
    : undefined;

  useEffect(() => {
    if (editData) {
      if (editData.delivery_date_timezone) {
        setTz(editData.delivery_date_timezone);
      }
      if (editData.delivery_date_local) {
        setDate(new Date(editData.delivery_date_local));
      }
      setBody(editData.body);
      setSubject(editData.subject);
      setBcc(editData.bcc.join(', '));
    }
  }, []);

  const handleChangeTz = (tz: TimezoneSelectOption) => {
    setTz(tz.value);
  };

  const handleChangeDate = (date: Date) => {
    setDate(date);
  };

  const handleSave = async () => {
    const data: MessageCreateModel = { subject };

    if (body) {
      data.body = body;
    }

    if (date) {
      data.delivery_date_local = dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
    }

    if (tz) {
      data.delivery_date_timezone = tz;
    }

    if (bcc) {
      const emails = bcc.split(',').map(str => str.trim());
      const invalidEmails = emails.filter(email => !validateEmail(email));
      if (invalidEmails.length) {
        setError({ bcc: true });
        return null;
      }
      data.bcc = emails;
    }

    if (editData) {
      await Api.patch(`beloved/marketing_communications/${editData.id}/`, undefined, data);
    } else {
      await Api.patch(`beloved/marketing_communications/${messageId}/`, undefined, data);
    }

    toast.success(t('awa:S24.msg'));
    if (editData) {
      if (genericNotesService.createNote && user) {
        genericNotesService.createNote(
          t('common:headlines.edited-by', { username: `${user.first_name} ${user.last_name}` }),
          true,
        );
      }
      history.go(-1);
    } else {
      history.push(ROOTS.BELOVED_MESSAGE_LIST);
    }
  };

  const deleteMessage = async () => {
    if (editData) {
      await Api.delete(`beloved/marketing_communications/remove/`, undefined, {
        ids: [editData.id],
      });
      toast.success(t('common:toast.message-was-deleted'));
      history.go(-1);
    }
  };

  const handleDelete = () => {
    showAlert({
      title: t('awa:N35.title'),
      text: t('awa:N35.msg'),
      buttons: {
        left: {
          title: t('common:btn.cancel'),
        },
        right: {
          title: t('common:btn.ok'),
          onClick: deleteMessage,
        },
      },
    });
  };

  const handleCancel = () => {
    history.go(-1);
  };

  const navToOrgs = () => {
    const message = editData || {
      id: messageId,
      subject,
      is_delivered: false,
    };
    history.push(ROOTS.BELOVED_MESSAGE_ORGANIZATIONS.replace(':messageId', `${editData?.id || messageId}`), {
      message,
    });
  };

  const handleSendTest = async (emails: string[]) => {
    const data: TestMessageSendModel = { body, subject, emails };
    await Api.post('beloved/marketing_communications/test_message/', data);
    setEmailModalOpen(false);
    toast.success(t('awa:S23.msg'));
  };

  const _submitDisabled = () => {
    return !subject.length;
  };

  return (
    <>
      <main className={'b-page'}>
        <h1 className={'b-page__title'}>{t('common:label.marketing-communication')}</h1>
        {editData ? (
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h2 className="b-page__title2 -backIcon font-navy mb-0">
              <Link onClick={navBack} className="b-button-icon-back" />
              <span>{editData.subject}</span>
            </h2>
            <Button onPress={() => setEmailModalOpen(true)} title={t(t('common:btn.send-test'))} />
          </div>
        ) : null}
        <div className="contentContainer">
          {editData ? (
            <div className="d-flex align-items-center mb-4">
              <div className="d-flex align-items-center mr-5">
                <div className="b-elmDetails__title font-navy font-weight-bold font-m">
                  {t('common:label.created-by')}:
                </div>
                <div className="font-grey font-s font-weight-bold">{editData.created_by_name}</div>
              </div>
              <div className="d-flex align-items-center ">
                <div className="b-elmDetails__title font-navy font-weight-bold font-m">
                  {t('common:label.created-on')}:
                </div>
                <div className="font-grey font-s font-weight-bold">
                  {basicDateFormat(editData.created_at)}
                </div>
              </div>
            </div>
          ) : null}

          <div className="d-flex align-items-center mb-4">
            <div className="b-elmDetails__title font-navy font-weight-bold font-m">
              {editData ? t('common:label.delivery-date') : t('common:label.deliver-on')}:
            </div>
            <div className="w200px mr-3 datepicker-no-panel">
              <DatePicker
                disabled={lockedMode || !hasAccessToMarketingComUpdate}
                minDate={new Date()}
                minTime={new Date()}
                maxTime={new Date(dayjs(new Date()).add(1, 'd').format('YYYY-MM-DD 23:59:59'))}
                dateFormat={getBasicDateHourFormatCodeForDatePicker()}
                placeholderText={t('common:label.select-date-time')}
                selected={date}
                showTimeSelect
                customInput={<input type="text" aria-label={'start_date'} />}
                onChange={handleChangeDate}
              />
            </div>
            {lockedMode || !hasAccessToMarketingComUpdate ? (
              <div className="font-weight-bold font-grey font-s">{tz}</div>
            ) : (
              <TimeZoneSelectView
                value={tz}
                onChange={handleChangeTz}
                height={40}
                noError
                wrapperStyles={{
                  width: '300px',
                }}
                placeholder={t('common:label.time-zone')}
                ariaLabel="Time Zone"
              />
            )}
          </div>
          <div className="d-flex mb-4">
            <div className="b-elmDetails__title font-navy font-weight-bold font-m">
              {t('common:column.to')}:
            </div>
            <Link onClick={navToOrgs} className="b-link-icon -group">
              {t('common:tabs.organizations')}
            </Link>
          </div>
          <div className="d-flex mb-4">
            <div className="b-elmDetails__title font-navy font-weight-bold font-m">
              {t('common:label.cc')}:
            </div>
            <div className="w-100">
              {lockedMode || !hasAccessToMarketingComUpdate ? (
                <div className="font-weight-bold font-grey font-s">{bcc}</div>
              ) : (
                <input
                  placeholder={t('common:label.type-email-messages')}
                  type="text"
                  className="b-resourceForm__fieldInput"
                  value={bcc}
                  onChange={event => {
                    setBcc(event.target.value);
                    setError({});
                  }}
                />
              )}

              {error.bcc ? (
                <div className="error-notification-text">
                  {t('common:errors.incorrect-email-format-coma')}
                </div>
              ) : null}
            </div>
          </div>
          <div className="d-flex align-items-center mb-4">
            <div className="b-elmDetails__title font-navy font-weight-bold font-m">
              {t('common:label.subject')}:
            </div>
            <div className="w-100">
              {lockedMode || !hasAccessToMarketingComUpdate ? (
                <div className="font-weight-bold font-grey font-s">{subject}</div>
              ) : (
                <input
                  placeholder={t('common:label.type-subject-message')}
                  type="text"
                  className="b-resourceForm__fieldInput"
                  value={subject}
                  onChange={event => setSubject(event.target.value)}
                />
              )}
            </div>
          </div>
          <div className="d-flex mb-4">
            <div className="b-elmDetails__title font-navy font-weight-bold font-m">
              {t('common:label.body')}:
            </div>
            <div className="w-100">
              <Editor
                withoutImage
                disabled={lockedMode || !hasAccessToMarketingComUpdate}
                onChange={setBody}
                defaultValue={sanitizedHTML(body)}
                placeholder={t('common:label.ewp.vision')}
              />
            </div>
          </div>
          {editData ? (
            <GenericNotes isAllowToCreate={true} entityType={'marketing_messages'} entityId={editData.id} />
          ) : null}
          {!lockedMode ? (
            <div className="d-flex justify-content-center">
              {hasAccessToMarketingComUpdate ? (
                <Button
                  type={'transparency'}
                  className="button-mh"
                  size="middle"
                  onPress={handleCancel}
                  title={t(t('common:btn.cancel'))}
                />
              ) : null}
              {editData && hasAccessToMarketingComRemove ? (
                <Button
                  className="button-mh"
                  size="middle"
                  onPress={handleDelete}
                  title={t(t('common:btn.delete'))}
                />
              ) : null}
              {hasAccessToMarketingComUpdate ? (
                <Button
                  className="button-mh"
                  size="middle"
                  disabled={_submitDisabled()}
                  onPress={handleSave}
                  title={t(t('common:btn.save'))}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </main>
      {emailModalOpen ? (
        <SendEmailModal onSubmit={handleSendTest} onClose={() => setEmailModalOpen(false)} />
      ) : null}
    </>
  );
};

export default MessageForm;
