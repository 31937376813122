import { BelResourceModel, BelResourceShortModel } from '../../models';
import { useSelector } from 'react-redux';
import { StateType } from '../../store/reducers';
import { useEffect, useState } from 'react';

export const useIsMyResource = (data: BelResourceShortModel | BelResourceModel | null) => {
  const user = useSelector((state: StateType) => state.profile.authUser?.user);

  const [res, setRes] = useState(false);

  useEffect(() => {
    if (data && user) {
      if (user.id === data.submitter?.id) {
        setRes(true);
      }
    }
  }, [data, user]);

  return res;
};
