import React, { useEffect, useState } from 'react';
import { Collapse, EmptyLink, Input, Link, Loader } from '../../../controls';
import { BelSubOrgModel, IRouteParamsBase } from '../../../../models';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  belGetOrganizationDetails,
  belRemoveSubOrg,
} from '../../../../store/beloved/organizations/organizationsActions';
import { ROOTS } from '../../../../routes/routes';
import { Alert, BelovedOrgTabs, HeadlineOrganization } from '../../../common';
import { StateType } from '../../../../store/reducers';
import { basicDateFormat, cutString } from '../../../../helpers';
import useHasAccess from '../../../../hooks/beloved/adminTool/user-has-access';
import PmControl from './parts/PmControl';
import { countries, BelovedPermissions } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { useNavBack } from '../../../../hooks/common/use-nav-back';

const OrganizationProfile = () => {
  const { t } = useTranslation();
  const { orgId } = useParams<IRouteParamsBase>();
  const dispatch = useDispatch();
  const { navBack } = useNavBack();
  const history = useHistory();
  const data = useSelector((state: StateType) => state.belOrganizations.currentBelOrg);
  const [dataLoading, setDataLoading] = useState(false);

  let [rmSubOrg, setRmSubOrg] = useState<BelSubOrgModel | null>(null);
  let [isPmModalVisible, setIsPmModalVisible] = useState(false);

  const hasAccessPmProfile = useHasAccess(BelovedPermissions.ORGANIZATION_PM_PROFILE);
  const hasAccessEditPm = useHasAccess(BelovedPermissions.ORGANIZATION_CHANGE_PM);
  const hasAccessRemoveOrg = useHasAccess(BelovedPermissions.ORGANIZATION_REMOVE_SUG_ORG);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    setDataLoading(true);
    dispatch(
      belGetOrganizationDetails({
        orgId: Number(orgId),
        callback: () => {
          setDataLoading(false);
        },
      }),
    );
  }, [orgId]);

  const navToOrg = (orgId: number) => (e: any) => {
    e.preventDefault();
    history.push(ROOTS.BELOVED_ORGANIZATION_DETAILS.replace(':orgId', `${orgId}`));
  };

  const handleRemoveSubOrg = (subOrg: BelSubOrgModel) => (e: any) => {
    e.preventDefault();
    setRmSubOrg(subOrg);
  };

  const removeSubOrg = () => {
    if (!rmSubOrg) return;
    dispatch(
      belRemoveSubOrg({
        orgId: rmSubOrg.id,
        callback: () => {
          setRmSubOrg(null);
          dispatch(
            belGetOrganizationDetails({
              orgId: Number(orgId),
            }),
          );
        },
      }),
    );
  };

  const navToPmProfile = (orgId: number) => {
    history.push(ROOTS.BELOVED_PM_PROFILE.replace(':orgId', `${orgId}`));
  };

  if (!data || dataLoading) return <Loader />;

  const countryValue =
    countries.find(f => f.value === (data?.address.country && data.address.country.toLowerCase())) ||
    countries.find(f => f.value === 'us');

  const isInternation = !(data.address.country && data.address.country.toLowerCase() === 'us');

  return (
    <>
      <main className={'b-page'}>
        <div className="b-page__header -desktop">
          <HeadlineOrganization
            orgName={data?.name || ''}
            orgId={data?.id || ''}
            headline={t('common:label.organization')}
            customClasses={'-noDesktopMb'}
            backBtn={<EmptyLink onClick={navBack} className="b-button-icon-back" />}
          />
        </div>
        <BelovedOrgTabs activeItemAlias={'profile'} />
        <form action="#" className="b-page__730 pt">
          <div className="b-equityAuditsDetails__formRow">
            <Input
              value={data.affiliation}
              disabled
              type="text"
              placeholder={t('common:label.affiliation')}
              ariaLabel="Affiliation"
            />
            <Input
              value={data.type_name}
              disabled
              type="text"
              placeholder={t('common:label.type')}
              ariaLabel="Type"
            />
            <div className="b-equityAuditsDetails__pmInput">
              <div className="b-equityAuditsDetails__pmInputControls">
                {hasAccessEditPm ? (
                  <button
                    aria-label="Click to edit PMs list"
                    onClick={() => setIsPmModalVisible(true)}
                    type="button"
                    className="b-equityAuditsDetails__pmInputControl -edit"
                  />
                ) : null}
                {hasAccessPmProfile ? (
                  <button
                    onClick={() => navToPmProfile(data.id)}
                    type="button"
                    aria-label="Click to go to pm profile"
                    className="b-equityAuditsDetails__pmInputControl -user"
                  />
                ) : null}
                {hasAccessPmProfile ? (
                  <Link className="orange-light-text" onClick={() => setIsPmModalVisible(true)}>
                    {t('common:btn.view-all-PMs')}
                  </Link>
                ) : null}
              </div>
              <Input
                value={data.org_pm}
                disabled
                ariaLabel="PM"
                type="text"
                placeholder={t('common:column.pm')}
              />
            </div>
            <Input
              value={data.pm_email}
              disabled
              type="text"
              placeholder={t('common:label.pm-email')}
              ariaLabel="PM Email"
            />
            <Input
              value={data.joined ? basicDateFormat(new Date(data.joined)) : t('common:column.n-a')}
              disabled
              type="text"
              placeholder={t('common:label.joined')}
              ariaLabel="Joined"
            />
            <Input
              value={data.last_audit ? basicDateFormat(new Date(data.last_audit)) : t('common:column.n-a')}
              disabled
              type="text"
              ariaLabel="Last Audit"
              placeholder={t('common:label.last-audit')}
            />
            <Input
              value={data.website}
              disabled
              type="email"
              placeholder={t('common:label.website')}
              ariaLabel="Website"
            />
          </div>
          <h2 className="b-equityAuditsDetails__formTitle">{t('common:headlines.headquarter-address')}</h2>
          <div className="b-equityAuditsDetails__formRow">
            <Input
              value={data.address.address}
              disabled
              type="text"
              placeholder={t('common:label.address')}
              ariaLabel="Address"
            />
            <Input
              value={data.address.suite}
              disabled
              type="text"
              ariaLabel="Suite/Office/Floor"
              placeholder={isInternation ? t('common:label.international.suite') : t('common:label.us.suite')}
            />
            <Input
              value={data.address.city}
              disabled
              type="text"
              ariaLabel="City/Town/Principal Subdivision"
              placeholder={isInternation ? t('common:label.international.city') : t('common:label.us.city')}
            />
            <Input
              value={data.address.state}
              disabled
              type="text"
              ariaLabel="State/Province/County"
              placeholder={isInternation ? t('common:label.international.state') : t('common:label.us.state')}
            />
            <Input
              value={data.address.postal}
              disabled
              type="text"
              ariaLabel="Postal code"
              placeholder={
                isInternation ? t('common:label.international.postal') : t('common:label.us.postal')
              }
            />
            <Input
              value={(countryValue && countryValue.label) || ''}
              disabled
              type="text"
              placeholder={t('common:label.country')}
              ariaLabel="Country"
            />
          </div>
        </form>
        <div className="b-equityAuditsDetails__additional">
          <div className="b-equityAuditsDetails__additionalCol">
            <Collapse title={'Sub-Organizations'} hasChildren={!!data.sub_orgs.length}>
              <ul className="b-equityAuditsDetails__suborganization_list">
                {data.sub_orgs.map(item => {
                  return (
                    <li key={`sub-${item.id}`}>
                      <a onClick={navToOrg(item.id)} href="#">
                        {cutString(item.name, 20)}
                      </a>
                      {hasAccessRemoveOrg ? (
                        <a onClick={handleRemoveSubOrg(item)} className="-deleteBtn" href="#" />
                      ) : null}
                    </li>
                  );
                })}
              </ul>
            </Collapse>
          </div>
          <div className="b-equityAuditsDetails__additionalCol">
            <Collapse title={'Sibling Organizations'} hasChildren={!!data.sibling_orgs.length}>
              <ul className="b-equityAuditsDetails__suborganization_list">
                {data.sibling_orgs.map(item => {
                  return (
                    <li key={`sib-${item.id}`}>
                      <a onClick={navToOrg(item.id)} href="#">
                        {cutString(item.name, 20)}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Collapse>
          </div>
        </div>
      </main>
      {isPmModalVisible ? <PmControl orgData={data} onClose={() => setIsPmModalVisible(false)} /> : null}
      {rmSubOrg && (
        <Alert
          isOpen={!!rmSubOrg}
          onRequestClose={() => setRmSubOrg(null)}
          title={'Delete the affiliation'}
          text={
            <div>
              <p>
                {t('main:alert1.m1')} {data.name} {t('main:alert1.m2')} {rmSubOrg.name}.
              </p>
              <p>{t('main:alert1.m3')}</p>
            </div>
          }
          buttons={{
            left: {
              title: t('common:btn.cancel'),
              type: 'transparency',
              onClick: () => setRmSubOrg(null),
            },
            right: {
              title: t('common:btn.delete'),
              type: 'primary',
              onClick: removeSubOrg,
            },
          }}
        />
      )}
    </>
  );
};

export default OrganizationProfile;
