import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ROOTS } from '../../../routes/routes';
import useHasAccess from '../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../constants/roles';
import { useTranslation } from 'react-i18next';

interface IProps {
  activeItemAlias: 'users' | 'roles' | 'org_users' | 'settings';
}

const BelovedAdminToolsTabs: FC<IProps> = ({ activeItemAlias }) => {
  const { t } = useTranslation();
  const hasAccessToRoles = useHasAccess(BelovedPermissions.ROLES_AND_FUNCTIONALITY);
  const hasAccessToUsers = useHasAccess(BelovedPermissions.AD_BELOVED_USERS);

  return (
    <div className="b-tabs">
      {hasAccessToUsers ? (
        <Link
          to={ROOTS.BELOVED_ADMIN_TOOL_USERS}
          className={`b-tabs__item ${activeItemAlias === 'users' ? 'b-tabs__item_active' : ''}`}
        >
          <div className={`b-tabs__icon b-tabs__icon_beloved`} />
          <span className="b-tabs__text">{t('common:label.beloved-users')}</span>
        </Link>
      ) : null}
      {hasAccessToRoles ? (
        <Link
          to={ROOTS.BELOVED_ADMIN_TOOL_ROLES}
          className={`
            b-tabs__item
            ${activeItemAlias === 'roles' ? 'b-tabs__item_active' : ''}
            `}
        >
          <div className="b-tabs__icon b-tabs__icon_map" />
          <span className="b-tabs__text">{t('common:label.roles-functionality')}</span>
        </Link>
      ) : null}
      <Link
        to={ROOTS.BELOVED_ADMIN_TOOL_ORG_USERS}
        className={`
            b-tabs__item
            ${activeItemAlias === 'org_users' ? 'b-tabs__item_active' : ''}
            `}
      >
        <div className="b-tabs__icon b-tabs__icon_users" />
        <span className="b-tabs__text">{t('common:headlines.organization-users')}</span>
      </Link>
      <Link
        to={ROOTS.BELOVED_ADMIN_TOOL_SETTINGS}
        className={`
            b-tabs__item
            ${activeItemAlias === 'settings' ? 'b-tabs__item_active' : ''}
            `}
      >
        <div className="b-tabs__icon b-tabs__icon_settings" />
        <span className="b-tabs__text">{t('common:tabs.settings')}</span>
      </Link>
    </div>
  );
};

export default BelovedAdminToolsTabs;
