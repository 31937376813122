import React, { FC, MouseEventHandler } from 'react';

const Link: FC<React.HTMLProps<HTMLAnchorElement>> = ({ children, onClick, ...props }) => {
  const handleClick: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <a href="#" {...props} onClick={handleClick}>
      {children}
    </a>
  );
};

export default Link;
