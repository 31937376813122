import React, { useEffect, useState } from 'react';
import SignUpView from './SignUpView';
import { createAccountRequest, setSignUpFields } from '../../../../store/authData/authDataActions';
import { useDispatch, useSelector } from 'react-redux';

import { email, password as passwordValidate, validate } from '../../../../helpers';
import { StateType } from '../../../../store/reducers';
import { useLocation } from 'react-router';

interface IProps {
  history: any;
}

const SignUpContainer = ({ history }: IProps) => {
  const location = useLocation();
  const validator = {
    required: ['username', 'password', 'first_name', 'last_name'],
    custom: [email(['username']), passwordValidate(['password'])],
  };

  const emailValidator = {
    required: ['username'],
    custom: [email(['username'])],
  };

  const passwordValidator = {
    required: ['password'],
    custom: [passwordValidate(['password'])],
  };

  const firstNameValidator = {
    required: ['first_name'],
  };

  const lastNameValidator = {
    required: ['last_name'],
  };

  const dispatch = useDispatch();
  const signUpFields = useSelector((state: StateType) => state.authData.signUpFields);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLastName] = useState('');
  const [checked, setChecked] = useState(true);
  let [error, setErrors] = useState<any>({});
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    setErrors({});
  }, [password, username, first_name, last_name]);

  useEffect(() => {
    setFirst_name(signUpFields.first_name);
    setLastName(signUpFields.last_name);
    if (location.state?.email) {
      setUsername(location.state.email);
    } else {
      setUsername(signUpFields.username);
    }
    const email = params.get('email');
    if (email) {
      setUsername(email);
    }
  }, []);

  const handleChange = (field: string) => (value: string) => {
    dispatch(
      setSignUpFields({
        username: username,
        first_name: first_name,
        last_name: last_name,
        [field]: value,
      }),
    );
    switch (field) {
      case 'username':
        setUsername(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'first_name':
        setFirst_name(value);
        break;
      case 'last_name':
        setLastName(value);
        break;
      default:
        return '';
    }
  };

  const handleAgree = () => {
    let { errors } = validate(validator, { username, password, first_name, last_name });
    setErrors(errors);
    if (!Object.keys(errors || {}).length) {
      setChecked(!checked);
    }
  };

  const handleNavigate = (response: any) => {
    if (response.error) {
      history.push('/email-already-exist');
    } else {
      history.push('account-created');
    }
  };

  const validateContent = () => {
    let { errors } = validate(validator, { username, password, first_name, last_name });
    setErrors(errors);
    return errors;
  };

  const handleSubmit = () => {
    validateContent();
    if (!Object.keys(error).length) {
      dispatch(
        createAccountRequest({
          callback: handleNavigate,
          data: { username, password, first_name, last_name },
        }),
      );
    }
  };

  const handleOnBlur = (field: string) => () => {
    let errorCase;
    switch (field) {
      case 'username':
        errorCase = validate(emailValidator, { username });
        break;
      case 'password':
        errorCase = validate(passwordValidator, { password });
        break;
      case 'first_name':
        errorCase = validate(firstNameValidator, { first_name });
        break;
      case 'last_name':
        errorCase = validate(lastNameValidator, { last_name });
        break;
      default:
        errorCase = { errors: error };
    }
    setErrors(errorCase.errors);
  };

  return (
    <SignUpView
      errors={error}
      handleOnBlur={handleOnBlur}
      onChangeField={handleChange}
      first_name={first_name}
      last_name={last_name}
      password={password}
      username={username}
      onChecked={checked}
      onValidate={validateContent}
      onAgree={handleAgree}
      onSubmit={handleSubmit}
    />
  );
};

export default SignUpContainer;
