import Api from '../../services/api';
import { useSelectCurrentOrg } from '../org/use-select-current-org';
import { useEffect, useState } from 'react';
import { CohortOrgListItemModel, PaginationModel } from '../../models';

export const useGetOrgCohortList = () => {
  const currentOrg = useSelectCurrentOrg();
  let [data, setData] = useState<PaginationModel<CohortOrgListItemModel[]> | null>(null);

  const fetchData = async () => {
    if (currentOrg) {
      const response = await Api.get(`organizations/${currentOrg.id}/cohort/?page_size=100`);
      if (response) {
        return response;
      }
    }
  };

  useEffect(() => {
    if (currentOrg) {
      fetchData().then(res => {
        if (res) {
          setData(res);
        }
      });
    }
  }, [currentOrg]);

  return data;
};
