import React, { FC, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Button, Loader } from '../../../../../controls';
import DatePicker from 'react-datepicker';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import { EwpCreateStepEnum, EWPUpdateData } from '../../../../../../models';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../../store/reducers';
import { getBasicDateFormatCodeForDatePicker } from '../../../../../../helpers';
import { useTranslation } from 'react-i18next';

interface IProps {
  onChangeStep: (step: EwpCreateStepEnum) => void;
  handleCancel: () => void;
  saveData: (data: EWPUpdateData, isNext: boolean) => void;
}

interface IState {
  currentDate: Date | null;
}

export const EWPDate: FC<IProps> = ({ onChangeStep, handleCancel, saveData }) => {
  const { t } = useTranslation();

  const createEWPData = useSelector((state: StateType) => state.ewp.createEWPData);

  const initialState = {
    currentDate: null,
  };

  let [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (createEWPData && createEWPData.start_date) {
      setState(prevState => ({
        ...prevState,
        currentDate: new Date(createEWPData.start_date as string),
      }));
    }
  }, [createEWPData]);

  const handleChangeDate = (date: Date) => {
    setState({ ...state, currentDate: date });
  };

  const handleSaveExit = (e: any) => {
    e.preventDefault();
    saveData(
      {
        start_date: state.currentDate,
        ewp_screen: EwpCreateStepEnum.date,
      },
      false,
    );
  };

  const handleNext = () => {
    saveData(
      {
        start_date: state.currentDate,
        ewp_screen: EwpCreateStepEnum.vision,
      },
      true,
    );
  };

  if (!createEWPData) return <Loader fitParent />;

  return (
    <>
      <div className={'b-EACreate__step'}>
        <div className="b-EACreate__header">
          <h1 className={'pageContainer__title b-EATypo__pageTitle'}>{t('common:headlines.new-ewp')}</h1>
          {!isMobileOnly && (
            <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
              <img src={SaveIc} alt={t('common:alt.saveIc')} />
              {t('common:btn.save-exit')}
            </a>
          )}
        </div>
        <div className={'b-step__orgWrapper'}>
          <div className="b-equityAudit__text16">{t('common:headlines.organization')}</div>
          <div className={'b-step__orgText'}>{createEWPData.organization_name}</div>
        </div>
        <div className={'b-step__info'}>
          <div className={'b-step__titleWrapper'}>
            <div className={'b-step__title'}>{t('common:label.ewp-start-date')}</div>
          </div>
          <div id="start_date" className={`b-equityAudit__text12 b-step__textWrapper`}>
            {t('common:headlines.tentative-start-date-ewp')}
          </div>
          <div className={'b-EACreate__dateArea -w-100 datepicker-no-panel'}>
            <DatePicker
              minDate={new Date()}
              dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
              selected={state.currentDate}
              onChange={handleChangeDate}
              placeholderText={t('common:label.click-here-date')}
              ariaLabelledBy="start_date"
              className={'w-100'}
            />
          </div>
        </div>
        <div className={`b-step__bottom`}>
          <div className={`b-step__bottom-left`}>
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={() => onChangeStep(EwpCreateStepEnum.team)}
              title={t('common:btn.back')}
            />
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={handleCancel}
              title={t('common:btn.cancel')}
            />
          </div>
          {isMobileOnly ? (
            <div className={`b-step__bottom-left`}>
              <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
                <img src={SaveIc} alt={t('common:alt.saveIc')} />
                {t('common:btn.save-exit')}
              </a>
              <Button
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleNext}
                title={t('common:btn.next')}
                enableEnter
              />
            </div>
          ) : (
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={handleNext}
              title={t('common:btn.next')}
              enableEnter
            />
          )}
        </div>
      </div>
    </>
  );
};
