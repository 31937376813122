import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Router } from './routes/Router';
import { getAppOptions } from './store/utils/utilsActions';
import { Slide, ToastContainer } from 'react-toastify';
import { FreshDesk } from './components/common';
import dayjs from 'dayjs';
import Api from './services/api';
import RemoteAlert from './components/common/Alert/RemoteAlert';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

function App() {
  const dispatch = useDispatch();

  const locale = navigator.language.split('-')[0];
  require(`dayjs/locale/${locale}`);
  dayjs.locale(locale);

  const errorHandler = () => {
    let errorContainer: any = null;
    const listener = (event: any) => {
      errorContainer = {
        error: event.error,
        message: event.message,
      };
      setTimeout(() => {
        if (errorContainer) {
          Api.handleError(errorContainer);
        }
        errorContainer = null;
      }, 500);
    };
    window.addEventListener('error', listener);
  };

  useEffect(() => {
    dispatch(getAppOptions());
    errorHandler();
  }, []);

  return (
    <div>
      <Router />
      <RemoteAlert />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        closeOnClick
        pauseOnHover={false}
        transition={Slide}
      />
      <FreshDesk />
    </div>
  );
}

export default App;
