import React, { useEffect, useState } from 'react';
import { HeadlineOrganization, OrgBreadcrumbs } from '../../../common';
import { EmptyLink, Link, Loader } from '../../../controls';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import { useTranslation } from 'react-i18next';
import { useNavBack } from '../../../../hooks/common/use-nav-back';
import Api from '../../../../services/api';
import { useParams } from 'react-router';
import { IRouteParamsBase, OrgAnalysisItemModel } from '../../../../models';
import LineChart from '../../../common/LineChart/LineChart';
import { basicDateFormat } from '../../../../helpers';

const OrgAnalysis = () => {
  const { t } = useTranslation();
  const { navBack } = useNavBack();
  const { orgId } = useParams<IRouteParamsBase>();
  const currentOrg = useSelector((state: StateType) => state.organization.currentOrg);

  const [data, setData] = useState<OrgAnalysisItemModel[] | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response: OrgAnalysisItemModel[] = await Api.get(
      `organizations/${orgId}/equity_audit/organization_analysis/`,
    );
    if (response) {
      setData(response);
    }
  };

  const renderContent = () => {
    if (!data) return <Loader />;

    return (
      <div className={'b-customChart'}>
        <div className={'b-customChart__chart'}>
          <div className={'b-customChart__labels'}>
            <ul>
              {data.map(item => {
                return (
                  <li className="b-customChart__labelContainer" key={`ga-${item.ea_id}`}>
                    <div className="font-weight-bold font-navy">{basicDateFormat(item.submit_date)}</div>
                  </li>
                );
              })}
            </ul>
          </div>
          <LineChart
            data={data.map(item => ({ id: item.ea_id, standards_score_report: item.standards_score_report }))}
          />
        </div>
        <div className={'b-customChart__legend'}>
          <div className={'b-customChart__legendTitle'}>
            <span className={`b-customChart__legendDot Diversity`} />
            <span>{t('awa:M35.msg.p9')}</span>
          </div>
          <div className={'b-customChart__legendTitle'}>
            <span className={`b-customChart__legendDot Equity`} />
            <span>{t('awa:M35.msg.p14')}</span>
          </div>
          <div className={'b-customChart__legendTitle'}>
            <span className={`b-customChart__legendDot Inclusion`} />
            <span>{t('awa:M35.msg.p20')}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <main className="b-page">
      <div>
        <HeadlineOrganization
          orgName={currentOrg?.name || ''}
          orgId={currentOrg?.id || ''}
          headline={t('main:ea.ea')}
        />
        <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
        <h2 className="b-page__title2 font-navy -backIcon">
          <EmptyLink onClick={navBack} className="b-button-icon-back" />
          {t('common:tabs.organization-analysis-ea-over-time')}
        </h2>
        <div className="b-report__holder">{renderContent()}</div>
      </div>
    </main>
  );
};

export default OrgAnalysis;
