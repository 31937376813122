import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  IRouteParamsBase,
  ISelectOptions,
  KeyValueModel,
  BelSessionCreateModel,
  BelSessionModel,
  BelSessionFormModel,
  BelSessionResourceModel,
  BelSessionFormResourceModel,
} from '../../../../../models';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Input, Loader, Textarea } from '../../../../controls';
import {
  arrayUniqueByKey,
  copyLinkToClipboard,
  getBasicDateFormatCodeForDatePicker,
  getZoneAbbr,
  httpUrl,
  isEmptyObject,
  sanitizedHTML,
  validate,
} from '../../../../../helpers';
import { ROOTS } from '../../../../../routes/routes';
import { useTranslation } from 'react-i18next';

import {
  belCreateCohortSession,
  belDeleteCohortSession,
  belSetSessionFields,
  belUpdateCohortSession,
  getCohortSession,
  getSelectedCohortResourceIds,
  setSelectedCohortResourceIds,
} from '../../../../../store/cohort/cohortActions';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { StateType } from '../../../../../store/reducers';
import { useGetCohortDetails } from '../../../../../hooks/beloved/cohorts/use-get-cohort-details';
import { Alert } from '../../../../common';
import { DOMAIN_URI, Modals, ModalsModel } from '../../../../../constants';
import { useIsAccessEditCohort } from '../../../../../hooks/beloved/cohorts/use-edit-access';
import { useNavToViewResource } from '../../../../../hooks/resources/use-nav-to-view-resource';
import Editor from '../../../../controls/Inputs/Editor';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';

interface IRouteParams extends IRouteParamsBase {
  id: string;
  sessionId: string;
}

const initSessionModel: BelSessionFormModel = {
  deck_link: '',
  description: '',
  end_date: '',
  meeting_link: '',
  post_work: '',
  pre_work: '',
  reflection_prompts: '',
  start_date: new Date(),
  title: '',
  unlock_date: new Date(),
  resources: [],
};

const BelCohortSessionForm = () => {
  const { t } = useTranslation();
  const { id, sessionId } = useParams<IRouteParams>();
  const navToResourceView = useNavToViewResource();
  const dispatch = useDispatch();
  const history = useHistory();
  const [errors, setErrors] = useState<KeyValueModel<string>>({});
  const [session, setSession] = useState<BelSessionFormModel>(initSessionModel);
  const [initSession, setInitSession] = useState<BelSessionFormModel>(initSessionModel);
  const [resources, setResources] = useState<BelSessionFormResourceModel[]>([]);
  const isCreate = sessionId === 'create';
  const selectedResourceIds = useSelector((state: StateType) => state.cohort.selectedResourceIds);
  const savedSessionField = useSelector((state: StateType) => state.cohort.savedSessionField);
  let [loading, setLoading] = useState(false);
  const [modals, setModals] = useState<ModalsModel>({});
  const cohort = useGetCohortDetails();
  const isAccess = useIsAccessEditCohort(cohort);

  const validator = {
    required: ['title', 'start_date'],
    custom: [httpUrl(['meeting_link']), httpUrl(['deck_link'])],
    requiredFieldName: {
      title: t('common:label.name'),
    },
  };

  useEffect(() => {
    if (selectedResourceIds.length) {
      setLoading(true);
      dispatch(
        getSelectedCohortResourceIds({
          data: {
            resource_ids: selectedResourceIds,
          },
          callback: res => {
            if (res && savedSessionField) {
              setResources(arrayUniqueByKey([...savedSessionField.resources, ...res], 'value'));
              setSession(prevState => {
                return {
                  ...prevState,
                  resources: arrayUniqueByKey([...savedSessionField.resources, ...res], 'value'),
                };
              });
              setLoading(false);
            }
          },
        }),
      );
      dispatch(setSelectedCohortResourceIds([]));
    }
    if (savedSessionField) {
      setSession(savedSessionField);
      dispatch(
        belSetSessionFields({
          data: null,
        }),
      );
      return;
    }

    if (isCreate) {
      return;
    }

    setLoading(true);
    dispatch(
      getCohortSession({
        data: {
          id: Number(sessionId),
        },
        callback: (res: BelSessionModel) => {
          const data = convertToSessionModel(res);
          const resources = convertToResourceForm(res.resources);
          setResources(resources);
          setInitSession(data);
          setSession(data);
          setLoading(false);
        },
      }),
    );
  }, []);

  const convertToSessionModel = (session: BelSessionModel): BelSessionFormModel => {
    return {
      id: session.id || null,
      deck_link: session.deck_link,
      description: session.description,
      end_date: session.end_date,
      meeting_link: session.meeting_link,
      post_work: session.post_work,
      pre_work: session.pre_work,
      reflection_prompts: session.reflection_prompts,
      start_date: session.start_date,
      title: session.title,
      unlock_date: session.unlock_date,
      resources: convertToResourceForm(session.resources),
    };
  };

  const convertToResourceForm = (resources: BelSessionResourceModel[]): BelSessionFormResourceModel[] => {
    return resources.map(r => {
      return { label: r.title, value: r.id, referenced_link: r.referenced_link };
    });
  };

  const onChangeDate = (date: Date, fieldName: 'start_date' | 'unlock_date' | 'end_date') => {
    if (!session) return null;
    delete errors[fieldName];
    setErrors(errors);
    const newDate = date;
    const newDates = {
      [fieldName]: newDate,
    };
    if (fieldName === 'start_date') {
      newDates.unlock_date = newDate;
      newDates.end_date = new Date(dayjs(newDate).add(2, 'h').format('YYYY-MM-DD HH:00:00'));
    }
    setSession({
      ...session,
      ...newDates,
    });
  };

  const navBack = (e?: any) => {
    e && e.preventDefault();
    history.go(-1);
  };

  const handleCheckResource = (item: ISelectOptions) => {
    const resourceIndex = resources.findIndex(f => f.value === item.value);
    if (resourceIndex < 0) {
      return;
    }
    const res = [...resources];
    res[resourceIndex].isCheck = !res[resourceIndex].isCheck;
    setResources(res);
  };

  const handleRemoveResource = () => {
    const array: BelSessionFormResourceModel[] = [...(resources || [])].filter(f => !f.isCheck);
    setSession({
      ...session,
      resources: array,
    });
    setResources(array);
  };

  const handleSave = () => {
    const errors = validate(validator, session).errors;

    if (!isEmptyObject(errors)) {
      window.scrollTo({ top: 0 });
      setErrors({
        ...errors,
      });
      return;
    }
    setLoading(true);
    let unlockDate;
    if (session.unlock_date) {
      unlockDate = new Date(session.unlock_date);
    } else if (session.start_date) {
      unlockDate = new Date(session.start_date);
    }
    const data: BelSessionCreateModel = {
      start_date: session.start_date ? new Date(session.start_date) : undefined,
      unlock_date: unlockDate || undefined,
      deck_link: session.deck_link,
      description: session.description,
      meeting_link: session.meeting_link,
      post_work: session.post_work,
      pre_work: session.pre_work,
      reflection_prompts: session.reflection_prompts,
      title: session.title,
      resource_ids: resources.map(r => Number(r.value)),
      cohort_id: Number(id),
      end_date: session.end_date
        ? session.end_date
        : new Date(dayjs(session.start_date).add(2, 'h').format('YYYY-MM-DD HH:00:00')),
    };
    if (!isCreate) {
      data.id = Number(sessionId);
      dispatch(
        belUpdateCohortSession({
          data: data,
          callback: (res: BelSessionModel | null, errors) => {
            if (res) {
              const data = convertToSessionModel(res);
              setSession(data);
              toast.success(t('common:toast.session-was-updated'));
            }
            if (errors) {
              setErrors(errors);
            }
            setLoading(false);
          },
        }),
      );
      return;
    }
    dispatch(
      belCreateCohortSession({
        data: data,
        callback: (res, errors) => {
          if (res) {
            toast.success(t('common:toast.session-was-created'));
            history.push(ROOTS.BELOVED_COHORT_DETAILS.replace(':id', `${res.cohort_id}`));
          }
          if (errors) {
            setErrors(errors);
          }
          setLoading(false);
        },
      }),
    );
  };

  const handleChangeInput = (name: string, value: string) => {
    delete errors[name];
    setErrors(errors);
    setSession(state => {
      return {
        ...state,
        [name]: value,
      };
    });
  };

  const handleCopyResourceLink = (e: any, resource: BelSessionFormResourceModel) => {
    e.preventDefault();
    let href = `${DOMAIN_URI}${ROOTS.RESOURCE_VIEW.replace(':id', `${resource.value}`)}`;
    copyLinkToClipboard(href, resource.label, true, t('common:toast.resource-title-copied'));
  };

  const handleRemoveSession = () => {
    if (session.id) {
      dispatch(
        belDeleteCohortSession({
          data: {
            id: session.id,
          },
          callback: () => {
            toast.success(t('common:toast.session-was-deleted'));
            history.push(ROOTS.BELOVED_COHORT_DETAILS.replace(':id', `${id}`));
          },
        }),
      );
    }
  };

  const handleNavToResourceLib = (e: any) => {
    e.preventDefault();
    if (!cohort) {
      return;
    }
    dispatch(
      belSetSessionFields({
        data: session,
      }),
    );
    history.push(
      ROOTS.BELOVED_COHORT_RESOURCE_LIB.replace(':id', `${cohort.id}`).replace(
        ':sessionId',
        `${session.id || 0}`,
      ),
    );
  };

  const handleNavToViewResource = (e: any, resource: BelSessionFormResourceModel) => {
    e.preventDefault();
    navToResourceView({
      id: resource.value,
      referenced_links: resource.referenced_link,
    });
  };

  const isBlockSaveButton = () => {
    if (isCreate) {
      return false;
    }
    return JSON.stringify(session) === JSON.stringify(initSession);
  };

  const openModal = (modal: string) => {
    setModals({
      ...modals,
      [modal]: true,
    });
  };

  const closeModal = (modal: string) => {
    setModals({
      ...modals,
      [modal]: false,
    });
  };

  const renderContent = () => {
    if (loading) {
      return <Loader fitParent />;
    }

    return (
      <div className={`b-EADetails b-elm b-cohortBel -editSession`}>
        <div className="b-EADetails__header">
          {isCreate ? (
            <h1 className="pageContainer__title b-EADetails__pageTitle">
              {t('common:headlines.new-session')}
            </h1>
          ) : (
            <h1 className="pageContainer__title b-EADetails__pageTitle">
              {t('common:headlines.edit-session')}
            </h1>
          )}
        </div>
        <div className={`b-elmDetails__panel`}>
          <div className="b-elmDetails__panelRow">
            <div className="b-elmDetails__panelParam mb-0">
              <div className={`b-elmDetails__title pt-3 label`}>{t('common:label.name')}:</div>
              <div className={`b-elmDetails__val`}>
                <div className={'input-wrapper'}>
                  <Input
                    autoFocus
                    name={'title'}
                    error={errors.title}
                    value={session.title}
                    type={'text'}
                    // disabled={true}
                    placeholder={t('common:label.cohort.session-title')}
                    handleChange={value => handleChangeInput('title', value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="b-elmDetails__panelRow">
            <div className={`b-elmDetails__panelParam mb-0`}>
              <div className={`b-elmDetails__title label`}>{t('common:column.date')}:</div>
              <div className={`b-elmDetails__val date`}>
                <div className={`picker`}>
                  <DatePicker
                    disabled={false}
                    minDate={new Date()}
                    locale="pt-BR"
                    showTimeSelect
                    timeCaption="Time"
                    timeIntervals={60}
                    dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                    selected={session.start_date ? new Date(session.start_date) : null}
                    customInput={<input type="text" aria-label={'start_date'} />}
                    onChange={(date: Date) => onChangeDate(date, 'start_date')}
                    placeholderText={t('common:column.n-a')}
                  />
                  <div className={'error-notification'}>
                    {errors.start_date && (
                      <span className={'error-notification-text'}>{errors.start_date}</span>
                    )}
                  </div>
                </div>
                <div className={`b-elmDetails__title label time`}>{t('common:column.time')}:</div>
                <div className={'b-cohortBel__timePickers'}>
                  <div className={'b-cohortBel__timePickers-time'}>
                    <DatePicker
                      disabled={!session.start_date}
                      selected={session.start_date ? new Date(session.start_date) : null}
                      onChange={(date: Date) => onChangeDate(date, 'start_date')}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText={t('common:column.n-a')}
                    />
                  </div>
                  <span className={'b-cohortBel__timePickers -additional'}>{' - '}</span>
                  <div className={'b-cohortBel__timePickers-time'}>
                    <DatePicker
                      disabled={!session.start_date}
                      selected={
                        session.end_date
                          ? new Date(session.end_date)
                          : new Date(dayjs(session.start_date).add(2, 'h').format('YYYY-MM-DD HH:mm:00'))
                      }
                      onChange={(date: Date) => onChangeDate(date, 'end_date')}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      customInput={<input type="text" aria-label={'end_date'} />}
                      placeholderText={t('common:column.n-a')}
                      minTime={new Date(session.start_date)}
                      maxTime={new Date(dayjs(session.start_date).add(1, 'd').format('YYYY-MM-DD 23:59:59'))}
                    />
                  </div>
                  <span className={`b-cohortBel__timePickers -additional`}>
                    {getZoneAbbr(session.start_date || '')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="b-elmDetails__panelRow">
            <div className="b-elmDetails__panelParam mb-4">
              <div className={`b-elmDetails__title label`}>{t('common:label.unlock-session-date')}:</div>
              <div className="b-elmDetails__val -alignEnd">
                <DatePicker
                  disabled={false}
                  minDate={new Date()}
                  dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                  selected={session.unlock_date ? new Date(session.unlock_date) : undefined}
                  customInput={<input type="text" aria-label={'unlock_date'} />}
                  onChange={(date: Date) => onChangeDate(date, 'unlock_date')}
                  placeholderText={t('common:column.n-a')}
                />
                <div className={'error-notification'}>
                  {errors.unlock_date && (
                    <span className={'error-notification-text'}>{errors.unlock_date}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="b-elmDetails__panelRow">
            <div className="b-elmDetails__panelParam mb-0">
              <div className={`b-elmDetails__title pt-3 label`}>{t('common:label.meeting-link')}:</div>
              <div className={`b-elmDetails__val`}>
                <div className={'input-wrapper'}>
                  <Input
                    name={'meeting_link'}
                    error={errors.meeting_link}
                    value={session.meeting_link}
                    type={'text'}
                    // disabled={true}
                    placeholder={t('common:label.cohort.link-meeting-room')}
                    handleChange={value => handleChangeInput('meeting_link', value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="b-elmDetails__panelRow">
            <div className="b-elmDetails__panelParam mb-0">
              <div className={`b-elmDetails__title pt-3 label`}>{t('common:label.session-deck')}:</div>
              <div className={`b-elmDetails__val`}>
                <div className={'input-wrapper'}>
                  <Input
                    name={'deck_link'}
                    error={errors.deck_link}
                    value={session.deck_link}
                    type={'text'}
                    // disabled={true}
                    placeholder={t('common:label.cohort.link-slide-deck')}
                    handleChange={value => handleChangeInput('deck_link', value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="b-elmDetails__panelRow">
            <div className="b-elmDetails__panelParam mb-0">
              <div className={`b-elmDetails__title pt-3 label`}>{t('common:label.description')}:</div>
              <div className={`b-elmDetails__val`}>
                <div className={'input-wrapper'}>
                  <Textarea
                    name={'description'}
                    error={errors.description}
                    value={session.description}
                    placeholder={t('common:label.cohort.description-session')}
                    handleChange={value => handleChangeInput('description', value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="b-elmDetails__panelRow">
            <div className="b-elmDetails__panelParam mb-10">
              <div className={`b-elmDetails__title pt-3 label`}>{t('common:label.pre-work')}:</div>
              <div className={`b-elmDetails__val -wysiwyg`}>
                <div className={'input-wrapper'}>
                  <Editor
                    onChange={value => handleChangeInput('pre_work', value)}
                    defaultValue={sanitizedHTML(session?.pre_work || '')}
                    placeholder={t('common:label.cohort.description-pre-work')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="b-elmDetails__panelRow">
            <div className="b-elmDetails__panelParam mb-10">
              <div className={`b-elmDetails__title pt-3 label`}>{t('common:label.post-work')}:</div>
              <div className="b-elmDetails__val -wysiwyg">
                <div className={'input-wrapper'}>
                  <Editor
                    onChange={value => handleChangeInput('post_work', value)}
                    defaultValue={sanitizedHTML(session.post_work || '')}
                    placeholder={t('common:label.cohort.description-post-work')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="b-elmDetails__panelRow">
            <div className="b-elmDetails__panelParam mb-0">
              <div className={`b-elmDetails__title pt-3 label`}>{t('common:label.reflection-prompts')}:</div>
              <div className={`b-elmDetails__val`}>
                <div className={'input-wrapper'}>
                  <Textarea
                    name={'reflection_prompts'}
                    error={errors.reflection_prompts}
                    value={session.reflection_prompts}
                    placeholder={t('common:label.cohort.list-reflection-prompts')}
                    handleChange={value => handleChangeInput('reflection_prompts', value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`b-elmDetails__panelRow panelRow`}>
            <div className={`b-elmDetails__panelParam w-100`}>
              <div className={`b-elmDetails__title b-cohortBel__title__remove`}>
                {t('common:column.resources')}
                {(resources || []).length ? (
                  <button className="b-button-icon -delete" onClick={handleRemoveResource} />
                ) : null}
              </div>
            </div>
          </div>
          <div className={`b-elmDetails__panelRow panelRow`}>
            <div className={`b-elmDetails__panelParam w-100 list`}>
              {(resources || []).map((resource: BelSessionFormResourceModel, resourceIndex) => {
                return (
                  <div key={`resources-panel-${resourceIndex}`} className={`list__item`}>
                    <span>{resource.label}</span>
                    <div className={'resource'}>
                      <a
                        className="resource__btnIcon -copy"
                        href="#"
                        onClick={e => handleCopyResourceLink(e, resource)}
                      />
                      <a
                        className="resource__btnIcon -view"
                        href="#"
                        onClick={e => handleNavToViewResource(e, resource)}
                      />
                      <Checkbox
                        noText
                        checked={!!resource.isCheck}
                        onChange={() => handleCheckResource(resource)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="b-elmDetails__panelRow">
            <div className="b-elmDetails__panelParam">
              <div className={`b-elmDetails__title b-cohortBel__title__remove`}>
                <a onClick={handleNavToResourceLib} href="#" className="b-link-add">
                  {t('common:btn.add-new-resource')}
                </a>
              </div>
            </div>
          </div>
          {isAccess ? (
            <div className="b-EAModal__buttons mt-5">
              <Button
                className={'btn -type_form'}
                type={'transparency'}
                onPress={navBack}
                title={t('common:btn.cancel')}
              />
              {session.id ? (
                <Button
                  width={'128px'}
                  onPress={() => openModal(Modals.isDelete)}
                  title={t('common:btn.delete')}
                  size={'large'}
                  type={'transparency'}
                />
              ) : null}
              <Button
                disabled={isBlockSaveButton()}
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleSave}
                title={t('common:btn.save')}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <main className="b-page">
      <h1 className="b-page__title">
        <EmptyLink onClick={navBack} className="b-button-icon-back" />
        {t('common:headlines.cohort-program')}: <span className="mark">{cohort?.name}</span>
      </h1>
      {renderContent()}
      {modals.isDelete && (
        <Alert
          isOpen={modals.isDelete}
          onRequestClose={() => closeModal(Modals.isDelete)}
          title={t('awa:N34.title')}
          text={
            <div>
              <p>{t('awa:N34.msg')}</p>
            </div>
          }
          buttons={{
            left: {
              title: t('common:btn.cancel'),
              type: 'white',
              onClick: () => closeModal(Modals.isDelete),
            },
            right: {
              type: 'orange-light',
              onClick: handleRemoveSession,
              title: t('common:btn.delete'),
              enableEnter: true,
            },
          }}
        />
      )}
      {loading && <Loader fitParent />}
    </main>
  );
};

export default BelCohortSessionForm;
