import React, { FC, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { TableToolSelectOptionModel } from '../../../models';

interface IProps {
  disabled?: boolean;
  buttonClassName?: string;
  buttonTitle: string;
  options: TableToolSelectOptionModel[];
}

const TableToolSelect: FC<IProps> = ({ buttonClassName, buttonTitle, options, disabled }) => {
  let [open, setOpen] = useState(false);

  const renderContent = () => {
    return (
      <div className="b-dropDownMenu">
        <ul>
          {options.map(item => {
            return (
              <li
                key={`tabletoolselect${item.alias}`}
                onClick={() => {
                  setOpen(false);
                  item.onClick(item.alias);
                }}
              >
                {item.title}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  return (
    <>
      <Popover
        isOpen={open}
        positions={['bottom']} // preferred positions by priority
        content={renderContent}
        reposition={false}
        containerClassName={'popover'}
        align={'start'}
        onClickOutside={() => setOpen(false)}
      >
        <button
          disabled={disabled}
          aria-label="Click to Actions"
          onClick={() => setOpen(!open)}
          className={`${buttonClassName}`}
        >
          {buttonTitle}
        </button>
      </Popover>
    </>
  );
};

TableToolSelect.defaultProps = {
  buttonClassName: '',
  disabled: false,
};

export default TableToolSelect;
