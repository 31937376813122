import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import DatePicker from 'react-datepicker';
import { OrgBreadcrumbs, AddToCalendar, Alert, AddMember, HeadlineOrganization } from '../../../../common';
import { Button, Select, ElmFileUploader, Loader, DownloadElmTemplate, Link } from '../../../../controls';
import {
  ElmUpdateModel,
  IRouteParamsBase,
  ISelectOptions,
  OrganizationDetailsModel,
  ScheduleTimeZoneModel,
} from '../../../../../models';
import { StateType } from '../../../../../store/reducers';
import { cancelElm, getElmAManagers, getElmOptions, updateElm } from '../../../../../store/elm/elmActions';
import { ROOTS } from '../../../../../routes/routes';
import styles from '../../EquityAudit/CreateEA/CreateEA.module.scss';
import InfoIc from '../../../../../assets/images/icons/round-action-info.svg';
import Ill from '../../../../../assets/images/group@2x.png';
import {
  getBasicDateFormatCodeForDatePicker,
  getBasicDateHourFormatCodeForDatePicker,
} from '../../../../../helpers';
import { useTranslation } from 'react-i18next';
import TimeZoneSelectView from '../../../../common/TimeZoneSelect/TimeZoneSelect';
import dayjs from 'dayjs';

const CreateElm = () => {
  const { t } = useTranslation();
  const currentOrg: OrganizationDetailsModel | null = useSelector(
    (state: StateType) => state.organization.currentOrg,
  );
  const elmOptions = useSelector((state: StateType) => state.elm.elmOptions);
  const managersList = useSelector((state: StateType) => state.elm.managersList);
  const createElmData = useSelector((state: StateType) => state.elm.createElmData);
  const { orgId } = useParams<IRouteParamsBase>();
  const dispatch = useDispatch();
  const history = useHistory();

  let [managerUsers, setManagerUsers] = useState<number[]>([]);
  let [startDate, setStartDate] = useState(new Date());
  let [endDate, setEndDate] = useState(new Date(dayjs().format('YYYY-MM-DD 18:00:00')));
  let [reminder, setReminder] = useState(5);
  let [adminType, setAdminType] = useState<ISelectOptions | undefined>(undefined);
  let [equityAudit, setEquityAudit] = useState<ISelectOptions | undefined>(undefined);
  let [showManagerTip, setShowManagerTip] = useState(false);
  let [showUserTip, setShowUserTip] = useState(false);
  let [loading, setLoading] = useState(false);
  let [showThank, setShowThank] = useState(false);
  let [cancelConfirmModal, setCancelConfirmModal] = useState(false);
  const [tz, setTz] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);

  useEffect(() => {
    dispatch(getElmOptions());
    dispatch(getElmAManagers());
  }, []);

  useEffect(() => {
    if (elmOptions && !adminType) {
      setAdminType(elmOptions.administration_types[0]);
      // setEquityAudit(elmOptions.equity_audits[0]);
    }
  }, [elmOptions]);

  const _getReminderOptions = () => {
    const opts = [];
    for (let i = 1; i <= 10; i++) {
      opts.push(i);
    }
    return opts;
  };

  const handleChangeReminder = (e: any) => {
    setReminder(e.target.value);
  };

  const handleChangeStartDate = (data: Date) => {
    setStartDate(data);
    if (data.getTime() > endDate.getTime()) {
      setEndDate(data);
    }
  };

  const handleAddManagers = (ids: number[]) => {
    setManagerUsers(ids);
  };

  const navToList = () => {
    history.push(ROOTS.ELM_LIST.replace(':orgId', `${orgId}`));
  };

  const handleCancel = () => {
    if (createElmData) {
      dispatch(
        cancelElm({
          elmId: createElmData.id,
          callback: () => {
            history.push(ROOTS.ELM_LIST.replace(':orgId', `${orgId}`));
          },
        }),
      );
    }
  };

  const handleChangeTimeZone = (timeZone: ScheduleTimeZoneModel) => {
    setEndDate(dayjs(endDate).tz(timeZone.value).$d);
    setTz(timeZone.value);
  };

  const handleSave = () => {
    // @ts-ignore
    const timeZoneGmt = dayjs().tz(tz).format('Z', { tz });
    const endAt = dayjs(endDate).format(`YYYY-MM-DD HH:mm:00 ${timeZoneGmt}`);
    const data: ElmUpdateModel = {
      start_date: startDate,
      end_date: dayjs(endAt).$d,
      reminder,
      manager_ids: managerUsers,
      administration_type: adminType?.value as number,
      equity_audit_id: equityAudit?.value as number,
    };
    setLoading(true);
    dispatch(
      updateElm({
        elmId: createElmData?.id as number,
        data,
        callback: isError => {
          if (isError) {
            setLoading(false);
          } else {
            setShowThank(true);
          }
        },
      }),
    );
  };

  const renderContent = () => {
    if (!(elmOptions && createElmData)) return <Loader />;

    if (showThank) {
      return (
        <main className={'pageContainer pageContainer__content b-elm'}>
          <div className="b-elmCreate__finish">
            <img src={Ill} className="b-elmCreate__finishIll" alt={t('awa:AT3')} />
            <h2 className="b-elmCreate__finishTitle">{t('awa:M75.title')}</h2>
            <div className="b-elmCreate__finishText">{t('awa:M75.msg')}</div>
          </div>
          <Button
            className={`btn  ${styles.finishStep__nextBtn}`}
            type={'orange-light'}
            onPress={navToList}
            title={t('common:btn.next')}
          />
        </main>
      );
    }

    return (
      <div className="pageContainer pageContainer__content b-elm contentContainer">
        {loading && <Loader fitParent />}
        <HeadlineOrganization
          orgName={currentOrg?.name || ''}
          orgId={currentOrg?.id || ''}
          headline={t('common:headlines.equity-lens-map')}
          customClasses={'-desktop'}
        />
        <h1 className="pageContainer__title b-equityAudit__pageTitle -mobile">
          {t('common:headlines.equity-lens-map')}
        </h1>
        <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
        <div>
          <div className="b-elmCreate__row b-elmCreate__select -mb30">
            <Select
              name={'organization'}
              value={equityAudit}
              placeholder={t('common:label.associated-ea')}
              options={elmOptions.equity_audits}
              error={''}
              handleChange={(value: ISelectOptions) => setEquityAudit(value)}
              height={52}
              isClearable
            />
          </div>
          {/*<div className="b-elmCreate__row b-elmCreate__select -mb30">*/}
          {/*  <Select*/}
          {/*    name={'organization'}*/}
          {/*    value={adminType}*/}
          {/*    placeholder={t('common:label.administration-type')}*/}
          {/*    options={elmOptions.administration_types}*/}
          {/*    error={''}*/}
          {/*    handleChange={(value: ISelectOptions) => setAdminType(value)}*/}
          {/*    height={52}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="b-elmCreate__row -alignStretchMob -mb30">
            <div className="b-elmCreate__title">{t('common:headlines.start-date')}:</div>
            <div className="b-elmCreate__startDate">
              <div className="b-elmCreate__date datepicker-no-panel" id={'start_date'}>
                <DatePicker
                  minDate={new Date()}
                  dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                  selected={startDate}
                  onChange={(date: Date) => handleChangeStartDate(date)}
                  ariaLabelledBy="start_date"
                />
              </div>
              <AddToCalendar type={'elm'} startDate={startDate} endDate={endDate} />
            </div>
          </div>
          <div className="b-elmCreate__row">
            <div className="b-elmCreate__title">{t('common:headlines.end-date')}:</div>
            <div className="b-elmCreate__date datepicker-no-panel" id={'end_date'}>
              <DatePicker
                minDate={startDate}
                dateFormat={`${getBasicDateHourFormatCodeForDatePicker()}`}
                selected={endDate}
                showTimeSelect
                onChange={(date: Date) => setEndDate(date)}
                ariaLabelledBy="end_date"
              />
              <TimeZoneSelectView
                value={tz}
                onChange={handleChangeTimeZone}
                height={40}
                noError
                wrapperStyles={{
                  width: '200px',
                }}
                placeholder={t('common:label.time-zone')}
                ariaLabel="Time Zone"
              />
            </div>
          </div>
          <div className="b-elmCreate__row -mb30">
            <div className="b-equityAudit__text12">{t('common:label.click-date')}</div>
          </div>
          <div className="b-elmCreate__row -mb30">
            <div className="b-elmCreate__title">{t('main:elm-reminder.title')}:</div>
            <select
              onChange={handleChangeReminder}
              value={reminder}
              aria-label={'reminder-select'}
              className="b-elmCreate__reminderSelect"
              name="reminder"
            >
              {_getReminderOptions().map(opt => {
                return (
                  <option key={`reminder-opt-${opt}`} value={opt}>
                    {opt}
                  </option>
                );
              })}
            </select>
            <div className="b-elmCreate__reminderText">{t('main:elm-reminder.msg')}</div>
          </div>
          <div className="b-elmCreate__users">
            <div className="b-elmCreate__row -between">
              <div className="b-elmCreate__row -alignStretch">
                <div className="b-elmCreate__title">{t('common:headlines.user-list')}:</div>
                <ElmFileUploader elmId={createElmData?.id} value={null} />
                <Link className="ml-3" onClick={() => setShowUserTip(true)}>
                  <img src={InfoIc} alt={t('common:alt.infoIc')} />
                </Link>
              </div>
            </div>
            <DownloadElmTemplate />
          </div>
          {!createElmData.is_no_org_report ? (
            <div className="b-elmCreate__users">
              <div className="d-flex">
                <div className="b-elmCreate__title">{t('common:column.managers')}:</div>
                <Link onClick={() => setShowManagerTip(true)}>
                  <img src={InfoIc} alt={t('common:alt.infoIc')} />
                </Link>
              </div>
              <div className={`b-equityAudit__text12 ${styles.textWrapper}`}>{t('main:texts.t2')}</div>
              {managerUsers.length ? (
                <div className={'b-EACreate__users'}>
                  {managerUsers.map(userId => {
                    const user = managersList?.find(user => user.id === userId);
                    if (user) {
                      return (
                        <div className={'b-EACreate__user'} key={`manager-${userId}`}>
                          <div className="b-EACreate__userName">{user.name}</div>
                          <div className="b-equityAudit__text16">07.10.2021</div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              ) : null}
              {managersList ? (
                <AddMember
                  usersList={managersList}
                  type={'managers'}
                  selectedUsers={managerUsers}
                  onSave={handleAddManagers}
                />
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="b-elm__bottomBtns">
          <Button
            className={'btn -type_form b-step__mr8'}
            type={'transparency'}
            onPress={() => setCancelConfirmModal(true)}
            title={t('common:btn.cancel')}
          />
          <Button
            className={'btn -type_form '}
            type={'orange-light'}
            onPress={handleSave}
            title={t('common:btn.save')}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {renderContent()}
      {showManagerTip ? (
        <Alert
          isOpen={showManagerTip}
          onRequestClose={() => setShowManagerTip(false)}
          title={'Managers'}
          text={t('awa:M41.msg')}
        />
      ) : null}
      {showUserTip ? (
        <Alert
          isOpen={showUserTip}
          onRequestClose={() => setShowUserTip(false)}
          title={t('awa:M88.title')}
          text={t('awa:M88.msg')}
        />
      ) : null}
      {cancelConfirmModal ? (
        <Alert
          isOpen={cancelConfirmModal}
          onRequestClose={() => setCancelConfirmModal(false)}
          title={t('awa:M83.title')}
          text={
            <>
              <p>{t('awa:M83.msg.p1')}</p>
              <p>{t('awa:M83.msg.p2')}</p>
            </>
          }
          buttons={{
            left: {
              title: t('common:btn.no'),
              type: 'white',
              onClick: () => setCancelConfirmModal(false),
            },
            right: {
              title: t('common:btn.yes'),
              type: 'orange-light',
              onClick: handleCancel,
            },
          }}
        />
      ) : null}
    </>
  );
};

export default CreateElm;
