import { SortDirection, useRowSelection } from 'react-data-grid';
import { BelParticipantModel, ColumnType } from '../../../../../../models';
import { cutString } from '../../../../../../helpers';
import { Checkbox } from '../../../../../controls';
import React from 'react';
import { t } from 'i18next';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

interface ColumnProps {
  isAccess: boolean;
}

export const getColumns = ({ isAccess }: ColumnProps): ColumnType<BelParticipantModel>[] => {
  const data: ColumnType<BelParticipantModel>[] = [
    {
      key: 'name',
      name: t('common:column.name'),
      width: 270,
      sortable: true,
      cellClass: 'b-rdgTable__cellTextWrap',
      editable: false,
      resizable: true,
      headerRenderer(props: any) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
      formatter(props) {
        return <>{cutString(`${props.row.first_name} ${props.row.last_name}`, 120)}</>;
      },
    },
    {
      key: 'role',
      name: t('common:column.role'),
      sortable: true,
      cellClass: 'b-rdgTable__cellTextWrap',
      editable: false,
      resizable: true,
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
      formatter(props) {
        return <>{props.row.user_role}</>;
      },
    },
    {
      key: 'user_title',
      name: t('common:column.title'),
      sortable: true,
      editable: false,
      resizable: true,
      headerCellClass: 'b-rdgTable__headerCell',
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
      formatter(props) {
        return <>{props.row.user_title}</>;
      },
    },
    {
      key: 'email',
      name: t('common:label.email'),
      sortable: false,
      cellClass: 'b-rdgTable__cellTextWrap',
      editable: false,
      resizable: true,
      headerCellClass: 'b-rdgTable__headerCell',
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
      formatter(props) {
        return <>{props.row.email}</>;
      },
    },
    {
      key: 'is_visible',
      name: t('common:label.cohort.cohort-visibility'),
      sortable: false,
      cellClass: 'b-rdgTable__cellTextWrap',
      editable: false,
      resizable: true,
      headerCellClass: 'b-rdgTable__headerCell',
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
      formatter(props) {
        return <>{props.row.is_visible ? 'On' : 'Off'}</>;
      },
    },
    {
      key: 'tools',
      name: '',
      disableDrag: true,
      width: 40,
      maxWidth: 40,
    },
  ];
  if (isAccess) {
    data.push({
      key: 'select',
      name: '',
      width: 60,
      maxWidth: 60,
      disableDrag: true,
      headerRenderer(props) {
        return (
          <Checkbox
            checked={props.allRowsSelected}
            onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
          />
        );
      },
      formatter: props => {
        const [isRowSelected, onRowSelectionChange] = useRowSelection();

        return (
          <div className="b-rdgTable__cellCenter">
            <div className={'checkBox'} onClick={e => e.stopPropagation()}>
              <Checkbox
                checked={isRowSelected}
                onChange={e => {
                  onRowSelectionChange({
                    row: props.row,
                    checked: e.target.checked,
                    isShiftClick: false,
                  });
                }}
              />
            </div>
          </div>
        );
      },
    });
  }
  return data;
};
