import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ROOTS } from '../../../routes/routes';
import { useSelector } from 'react-redux';
import { StateType } from '../../../store/reducers';
import useHasAccess from '../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../constants/roles';
import { useTranslation } from 'react-i18next';

interface IProps {
  activeItemAlias: 'details' | 'report';
}

const BelovedElmTabs: FC<IProps> = ({ activeItemAlias }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const currentElm = useSelector((state: StateType) => state.belElm.currentElm);

  const hasAccessToElmReport = useHasAccess(BelovedPermissions.ELM_VIEW_REPORT);

  if (!currentElm) return null;

  const handleClick = (alias: IProps['activeItemAlias']) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    switch (alias) {
      case 'details':
        history.push(ROOTS.BELOVED_ELM_DETAILS.replace(':elmId', `${currentElm.id}`));
        break;
      case 'report':
        navToReport();
        break;
    }
  };

  const navToReport = () => {
    history.push(
      ROOTS.ELM_ORG_REPORT_RESP.replace(':orgId', `${currentElm.organization_id}`).replace(
        ':elmId',
        `${currentElm.id}`,
      ),
      { isBelovedApp: true },
    );
  };

  return (
    <>
      <div className="b-tabs">
        <a
          href="#"
          className={`b-tabs__item ${activeItemAlias === 'details' ? 'b-tabs__item_active' : ''}`}
          onClick={handleClick('details')}
        >
          <div className={`b-tabs__icon b-tabs__icon_equility`} />
          <span className="b-tabs__text">{t('common:btn.details')}</span>
        </a>
        {currentElm.has_org_report && hasAccessToElmReport ? (
          <a
            href="#"
            className={`
                b-tabs__item
                ${activeItemAlias === 'report' ? 'b-tabs__item_active' : ''}
              `}
            onClick={handleClick('report')}
          >
            <div className="b-tabs__icon b-tabs__icon_elm_reports" />
            <span className="b-tabs__text">ELM Organization Report</span>
          </a>
        ) : null}
      </div>
    </>
  );
};

export default BelovedElmTabs;
