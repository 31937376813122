import React, { FC, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Button, Editor, Link, Loader } from '../../../../../controls';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import { BasicFileModel, EwpCreateStepEnum, EWPUpdateData } from '../../../../../../models';
import { StateType } from '../../../../../../store/reducers';
import { useTranslation } from 'react-i18next';
import Api from '../../../../../../services/api';
import { useError } from '../../../../../../hooks/common/use-error';
import { sanitizedHTML, validateURL } from '../../../../../../helpers';

interface IProps {
  onChangeStep: (step: EwpCreateStepEnum) => void;
  handleCancel: () => void;
  saveData: (data: EWPUpdateData, isNext: boolean) => void;
}

interface IForm {
  vision: string;
  introduction: string;
  dei_statement: string;
  plan_document_link: string;
  plan_document_id?: number | null;
}

const initialFrom: IForm = {
  vision: '',
  introduction: '',
  dei_statement: '',
  plan_document_link: '',
  plan_document_id: undefined,
};

export const EWPVision: FC<IProps> = ({ onChangeStep, handleCancel, saveData }) => {
  const { t } = useTranslation();
  const createEWPData = useSelector((state: StateType) => state.ewp.createEWPData);
  const [form, setForm] = useState<IForm>(initialFrom);
  let [file, setFile] = useState<BasicFileModel | null>(null);
  let [loading, setLoading] = useState(false);
  let { error, setError } = useError<any>({});

  useEffect(() => {
    if (createEWPData) {
      const newForm = {
        ...form,
        vision: createEWPData.vision,
        dei_statement: createEWPData.dei_statement,
        introduction: createEWPData.introduction,
        plan_document_link: createEWPData.plan_document_link || '',
      };
      if (createEWPData.plan_document) {
        setFile(createEWPData.plan_document);
        newForm.plan_document_id = createEWPData.plan_document.id;
      }
      setForm(newForm);
    }
  }, [createEWPData]);

  const handleSaveExit = () => {
    if (form.plan_document_link && !validateURL(form.plan_document_link)) {
      setError({ docLink: t('common:errors.invalid-URL') });
      return;
    }
    saveData(
      {
        ...form,
        ewp_screen: EwpCreateStepEnum.vision,
      },
      false,
    );
  };

  const handleChangeInput = (name: string, value: string) => {
    setError({});
    setForm(form => {
      return {
        ...form,
        [name]: value,
      };
    });
  };

  const validateFile = ({ type }: File): boolean => {
    let isValid = true;
    const isValidType = type.match(/(pdf)$/);
    if (!isValidType) {
      setError({ ...error, file: t('common:errors.pdf-file-type') });
      isValid = false;
    }
    return isValid;
  };

  const handleAddFile = async (e: any) => {
    const isValidType = validateFile(e.target.files[0]);
    if (!isValidType) return null;

    setError({});
    setLoading(true);
    const response = await Api.uploadFile(e.target.files[0], 0);
    setLoading(false);
    setFile(response.data.result.resource);
    setForm(form => ({
      ...form,
      plan_document_id: response.data.result.resource.id,
    }));
  };

  const handleRemoveFile = async () => {
    if (!file) return;
    setFile(null);
    setForm(form => ({
      ...form,
      plan_document_id: null,
    }));
  };

  const handleNext = () => {
    if (form.plan_document_link && !validateURL(form.plan_document_link)) {
      setError({ docLink: t('common:errors.invalid-URL') });
      return;
    }
    saveData(
      {
        ...form,
        ewp_screen: EwpCreateStepEnum.finish,
      },
      false,
    );
  };

  if (!createEWPData) return <Loader fitParent />;

  return (
    <>
      {loading && <Loader />}
      <div className={'b-EACreate__step'}>
        <div className="b-EACreate__header">
          <h1 className={'b-page__title mb-0'}>
            {t('common:headlines.new-ewp')}
            <span className={'mark ml-3'}>{createEWPData.organization_name}</span>
          </h1>
          {!isMobileOnly && (
            <Link className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
              <img src={SaveIc} alt={t('common:alt.saveIc')} />
              {t('common:btn.save-exit')}
            </Link>
          )}
        </div>
        <div className="font-grey mb-5">{t('main:ewp.create-vision.m1')}</div>
        <div className="b-resourceForm__field -file mb-5">
          <div>
            <div className="b-panelData__fieldTitle font-weight-bold">
              {t('common:label.strategic-plan-document')}:
            </div>
            {error.docLink ? <div className="error">{error.docLink}</div> : null}
          </div>
          <div>
            <input
              disabled={Boolean(form.plan_document_id)}
              aria-label="Link"
              placeholder={t('common:label.url-of-the-document')}
              className="b-resourceForm__fieldInput"
              type="text"
              value={form.plan_document_link}
              onChange={e => handleChangeInput('plan_document_link', e.target.value)}
            />
          </div>
          <div>
            {file ? (
              <div className="b-resourceForm__fileName">
                <div>{file.original_file_name}</div>
                <button onClick={handleRemoveFile} className="b-button-icon -deleteFile" />
              </div>
            ) : (
              <div>
                <label
                  className={`b-uploadInput__label ${form.plan_document_link.length ? '-disabled' : ''}`}
                >
                  {t('common:label.upload-document')}
                  <input
                    disabled={Boolean(form.plan_document_link.length)}
                    id="doc-input"
                    accept="application/pdf"
                    className="b-uploadInput"
                    type="file"
                    onChange={e => handleAddFile(e)}
                  />
                </label>
                {error.file ? <div className="error">{t('common:errors.pdf-file-type')}</div> : null}
              </div>
            )}
          </div>
        </div>
        <div className={'b-step__info mt-5'}>
          <div className={'b-step__titleWrapper mb-4'}>
            <div className={'b-step__title'}>{t('common:label.dei-statement')}</div>
          </div>
          <Editor
            onChange={value => handleChangeInput('dei_statement', value)}
            defaultValue={sanitizedHTML(form?.dei_statement || '')}
            placeholder={t('common:label.ewp.dei_statement')}
            type={'ewp-vision'}
          />
        </div>
        <div className={'b-step__info'}>
          <div className={'b-step__titleWrapper mb-4'}>
            <div className={'b-step__title'}>{t('common:label.organization-introduction')}</div>
          </div>
          <Editor
            onChange={value => handleChangeInput('introduction', value)}
            defaultValue={sanitizedHTML(form.introduction)}
            placeholder={t('common:label.ewp.introduction')}
          />
        </div>
        <div className={'b-step__info mt-5'}>
          <div className={'b-step__titleWrapper mb-4'}>
            <div className={'b-step__title'}>{t('common:label.vision')}</div>
          </div>
          <Editor
            onChange={value => handleChangeInput('vision', value)}
            defaultValue={sanitizedHTML(form.vision)}
            placeholder={t('common:label.ewp.vision')}
          />
        </div>
        <div className={`b-step__bottom`}>
          <div className={`b-step__bottom-left`}>
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={() => onChangeStep(EwpCreateStepEnum.date)}
              title={t('common:btn.back')}
            />
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={handleCancel}
              title={t('common:btn.cancel')}
            />
          </div>
          {isMobileOnly ? (
            <div className={`b-step__bottom-left`}>
              <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
                <img src={SaveIc} alt={t('common:alt.saveIc')} />
                {t('common:btn.save-exit')}
              </a>
              <Button
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleNext}
                title={t('common:btn.next')}
              />
            </div>
          ) : (
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={handleNext}
              title={t('common:btn.next')}
            />
          )}
        </div>
      </div>
    </>
  );
};
