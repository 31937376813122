import React from 'react';
import { useTranslation } from 'react-i18next';
import { Footer, Header } from '../../../common';
import { Image } from '../../../controls';
import Logo from '../../../../assets/images/icons/notification.png';
import styles from './EmailAlreadyExistStyle.module.scss';

interface IProps {
  loader?: string;
}

const EmailAlreadyExistContainer = ({ loader }: IProps) => {
  const { t } = useTranslation();
  return (
    <>
      {loader}
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        <main className={'white-container content-center-wrapper'}>
          <div className={'container-page-center'}>
            <div className={'container-row'}>
              <Image width={'190px'} height={'190px'} alt={t('awa:AT24')} role="presentation" source={Logo} />
            </div>
            <div className={'container-row mt-4'}>
              <h1 className={`b-title blue-text ${styles.title}`}>{t('auth:email-exist.title')}</h1>
            </div>
            <div className={`text-container container-column`}>
              <div className={'container-row'}>
                <p className={`text-description gray-text ${styles.text}`}>
                  {t('auth:email-exist.msg.p1')}{' '}
                  <a className={'links font-m'} href={'/sign-in'}>
                    {t('auth:email-exist.msg.p2')}
                  </a>{' '}
                  {t('auth:email-exist.msg.p3')}
                </p>
              </div>
              <div className={'container-row mt-3'}>
                <p className={`text-description gray-text ${styles.text}`}>{t('auth:email-exist.msg.p4')} </p>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default EmailAlreadyExistContainer;
