import React, { FC, useState } from 'react';
import { EWPElementsTreeModel } from '../../../../../models';
import { Link, SimpleTooltip } from '../../../../controls';
import { useGetEwpRoute } from '../../../../../hooks/ewp/use-get-ewp-route';
import { ROOTS } from '../../../../../routes/routes';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEwpDetails } from '../../../../../hooks/ewp/use-ewp-details';
import EmptyButton from '../../../../controls/Button/EmptyButton';

interface IProps {
  tree: EWPElementsTreeModel;
  type: 'priority' | 'goal' | 'strategy' | 'ka';
  entityId: number;
}

const ElementTree: FC<IProps> = ({ tree, type, entityId }) => {
  const EWP_GOAL_DETAILS = useGetEwpRoute(ROOTS.EWP_GOAL_DETAILS);
  const EWP_GOAL_CREATE = useGetEwpRoute(ROOTS.EWP_GOAL_CREATE);
  const EWP_PRIORITY_DETAILS = useGetEwpRoute(ROOTS.EWP_PRIORITY_DETAILS);
  const EWP_STRATEGY_DETAILS = useGetEwpRoute(ROOTS.EWP_STRATEGY_DETAILS);
  const EWP_STRATEGY_CREATE = useGetEwpRoute(ROOTS.EWP_STRATEGY_CREATE);
  const EWP_KA_DETAILS = useGetEwpRoute(ROOTS.EWP_KA_DETAILS);
  const EWP_KA_CREATE = useGetEwpRoute(ROOTS.EWP_KA_CREATE);
  const history = useHistory();
  const { t } = useTranslation();
  const { ewpDetails } = useEwpDetails();
  const isAllowToCreateGoal = ewpDetails?.ewp_permissions.ewp_goal.create;
  const isAllowToCreateStrategy = ewpDetails?.ewp_permissions.ewp_strategy.create;
  const isAllowToCreateKA = ewpDetails?.ewp_permissions.ewp_key_action.create;

  const initialState = {
    priorityHidden: false,
    goalHidden: false,
    strategyHidden: false,
    kaHidden: false,
  };

  let [state, setState] = useState(initialState);

  const navigate = (id: number, type: IProps['type']) => {
    switch (type) {
      case 'priority':
        history.push(EWP_PRIORITY_DETAILS.replace(':elId', `${id}`));
        return;
      case 'goal':
        history.push(EWP_GOAL_DETAILS.replace(':elId', `${id}`));
        return;
      case 'strategy':
        history.push(EWP_STRATEGY_DETAILS.replace(':elId', `${id}`));
        return;
      case 'ka':
        history.push(EWP_KA_DETAILS.replace(':elId', `${id}`));
        return;
    }
  };

  const handleCreateNew = (type: IProps['type']) => {
    switch (type) {
      case 'goal':
        history.push(EWP_GOAL_CREATE);
        return;
      case 'strategy':
        history.push(EWP_STRATEGY_CREATE);
        return;
      case 'ka':
        history.push(EWP_KA_CREATE);
        return;
    }
  };

  return (
    <div>
      <div className="position-relative">
        <div className="d-flex align-items-center">
          <div className="b-ewpElementsTree__arrow">
            <div className="b-ewpElementsTree__arrowHelpers" />
          </div>
          <div className="b-button-icon -colorNavy -noHover -ewp_priority b-ewpElementsTree__icon" />
          <div className="font-m font-navy ml-2 mr-3 font-weight-bold">
            {t('main:ewp-create.intro-details.priorities.title')}
          </div>
          <EmptyButton
            onClick={() => setState({ ...state, priorityHidden: !state.priorityHidden })}
            className={`b-ewpElementsTree__arrowBtn ${state.priorityHidden ? '-closed' : ''}`}
          />
        </div>
        {!state.priorityHidden ? (
          <ul className="mt-2 ml-5 b-ewpElementsTree__list">
            {tree.priorities.map(item => {
              const isActive = type === 'priority' && item.id === entityId;
              return (
                <li key={`tree-pri-${item.id}`} className="font-s font-grey">
                  <div className="d-flex align-items-center">
                    {item.is_broken && (
                      <SimpleTooltip id={`broken-prio-${item.id}`} title={t('awa:M148.msg')}>
                        <EmptyButton className="b-button-icon -info -colorRed -w16 mr-1" />
                      </SimpleTooltip>
                    )}
                    {isActive ? (
                      item.title
                    ) : (
                      <Link onClick={() => navigate(item.id, 'priority')} className="b-link">
                        {item.title}
                      </Link>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
      <div className="b-ewpElementsTree__leftMargin mt-3">
        <div className="position-relative">
          <div className="d-flex align-items-center">
            {tree.goals.length ? (
              <div className="b-ewpElementsTree__arrow">
                <div className="b-ewpElementsTree__arrowHelpers" />
              </div>
            ) : null}
            <div className="b-button-icon -colorNavy -noHover -ewp_goals b-ewpElementsTree__icon" />
            <div className="font-m font-navy ml-2 mr-3 font-weight-bold">
              {t('main:ewp-create.intro-details.goals.title')}
            </div>
            <EmptyButton
              onClick={() => setState({ ...state, goalHidden: !state.goalHidden })}
              className={`b-ewpElementsTree__arrowBtn ${state.goalHidden ? '-closed' : ''}`}
            />
            {isAllowToCreateGoal ? (
              <div className="ml-5">
                <button onClick={() => handleCreateNew('goal')} className="b-tableTool -addPlus -mobView -mr">
                  {t('common:tabs.goal')}
                </button>
              </div>
            ) : null}
          </div>
          {!state.goalHidden ? (
            <ul className="mt-2 ml-5 b-ewpElementsTree__list">
              {tree.goals.map(item => {
                const isActive = type === 'goal' && item.id === entityId;
                return (
                  <li key={`tree-gola-${item.id}`} className="font-s font-grey">
                    <div className="d-flex align-items-center">
                      {item.is_broken && (
                        <SimpleTooltip id={`broken-goal-${item.id}`} title={t('awa:M149.msg')}>
                          <EmptyButton className="b-button-icon -info -colorRed -w16 mr-1" />
                        </SimpleTooltip>
                      )}
                      {isActive ? (
                        item.title
                      ) : (
                        <Link onClick={() => navigate(item.id, 'goal')} className="b-link">
                          {item.title}
                        </Link>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>
        {tree.goals.length ? (
          <div className="b-ewpElementsTree__leftMargin mt-3">
            <div className="position-relative">
              <div className="d-flex align-items-center">
                {tree.strategies.length ? (
                  <div className="b-ewpElementsTree__arrow">
                    <div className="b-ewpElementsTree__arrowHelpers" />
                  </div>
                ) : null}
                <div className="b-button-icon -colorNavy -noHover -ewp_strats b-ewpElementsTree__icon" />
                <div className="font-m font-navy ml-2 mr-3 font-weight-bold">
                  {t('main:ewp-create.intro-details.strategies.title')}
                </div>
                <EmptyButton
                  onClick={() => setState({ ...state, strategyHidden: !state.strategyHidden })}
                  className={`b-ewpElementsTree__arrowBtn ${state.strategyHidden ? '-closed' : ''}`}
                />
                {isAllowToCreateStrategy ? (
                  <div className="ml-5">
                    <button
                      onClick={() => handleCreateNew('strategy')}
                      className="b-tableTool -addPlus -mobView -mr"
                    >
                      {t('common:label.strategy')}
                    </button>
                  </div>
                ) : null}
              </div>
              {!state.strategyHidden ? (
                <ul className="mt-2 ml-5 b-ewpElementsTree__list">
                  {tree.strategies.map(item => {
                    const isActive = type === 'strategy' && item.id === entityId;
                    return (
                      <li key={`tree-strat-${item.id}`} className="font-s font-grey">
                        <div className="d-flex align-items-center text-break w-100">
                          {item.is_broken && (
                            <SimpleTooltip id={`broken-strategy-${item.id}`} title={t('awa:M150.msg')}>
                              <EmptyButton className="b-button-icon -info -colorRed -w16 mr-1" />
                            </SimpleTooltip>
                          )}
                          {isActive ? (
                            item.title
                          ) : (
                            <Link onClick={() => navigate(item.id, 'strategy')} className="b-link">
                              {item.title}
                            </Link>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
            {tree.strategies.length ? (
              <div className="b-ewpElementsTree__leftMargin mt-3">
                <div className="position-relative">
                  <div className="d-flex align-items-center">
                    <div className="b-button-icon -colorNavy -noHover -ewp_ka b-ewpElementsTree__icon" />
                    <div className="font-m font-navy ml-2 mr-3 font-weight-bold">
                      {t('main:ewp-create.intro-details.key-actions.title')}
                    </div>
                    <EmptyButton
                      onClick={() => setState({ ...state, kaHidden: !state.kaHidden })}
                      className={`b-ewpElementsTree__arrowBtn ${state.kaHidden ? '-closed' : ''}`}
                    />
                    {isAllowToCreateKA ? (
                      <div className="ml-5">
                        <button
                          onClick={() => handleCreateNew('ka')}
                          className="b-tableTool -addPlus -mobView -mr"
                        >
                          {t('common:label.key-action')}
                        </button>
                      </div>
                    ) : null}
                  </div>
                  {!state.kaHidden ? (
                    <ul className="mt-2 ml-5 b-ewpElementsTree__list">
                      {tree.key_actions.map(item => {
                        const isActive = type === 'ka' && item.id === entityId;
                        return (
                          <li key={`tree-ka-${item.id}`} className="font-s font-grey">
                            {isActive ? (
                              item.title
                            ) : (
                              <Link onClick={() => navigate(item.id, 'ka')} className="b-link">
                                {item.title}
                              </Link>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ElementTree;
