import React, { FC, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Button, Loader, Select } from '../../../../../controls';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import { CreateEAStep, EAUpdateData, ISelectOptions } from '../../../../../../models';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../../store/reducers';
import { useTranslation } from 'react-i18next';

interface IProps {
  onChangeStep: (step: CreateEAStep) => void;
  handleCancel: () => void;
  saveData: (data: EAUpdateData, isNext: boolean) => void;
}

interface IState {
  employees_number: ISelectOptions | null;
  org_age: ISelectOptions | null;
}

const CreateEAStep5: FC<IProps> = ({ onChangeStep, handleCancel, saveData }) => {
  const { t } = useTranslation();
  const createEAData = useSelector((state: StateType) => state.equityAudit.createEAData);
  const EAOptions = useSelector((state: StateType) => state.equityAudit.EAOptions);

  const initialState: IState = {
    employees_number: null,
    org_age: null,
  };

  let [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (createEAData && EAOptions) {
      setState(prevState => ({
        ...prevState,
        employees_number:
          EAOptions.employees_number.find(item => item.value === createEAData.employees_number) || null,

        org_age: EAOptions.org_age.find(item => item.value === createEAData.org_age) || null,
      }));
    }
  }, [createEAData, EAOptions]);

  const handleSelect = (fieldName: string, value: ISelectOptions) => {
    setState({ ...state, [fieldName]: value });
  };

  const handleSaveExit = (e: any) => {
    e.preventDefault();
    saveData(
      {
        employees_number: state.employees_number?.value as number,
        org_age: state.org_age?.value as number,
        ea_step: 'set_org_details',
      },
      false,
    );
  };

  const handleNext = () => {
    saveData(
      {
        employees_number: state.employees_number?.value as number,
        org_age: state.org_age?.value as number,
        ea_step: 'set_geo',
      },
      true,
    );
  };

  const _isNextBtnDisabled = () => {
    return !(state.employees_number && state.org_age);
  };

  if (!(createEAData && EAOptions)) return <Loader fitParent />;

  return (
    <>
      <div className={'b-EACreate__step'}>
        <div className="b-EACreate__header">
          <h1 className={'pageContainer__title b-EATypo__pageTitle'}>
            {t('common:headlines.your-organization')}
          </h1>
          {!isMobileOnly && (
            <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
              <img src={SaveIc} alt={t('common:alt.saveIc')} />
              {t('common:btn.save-exit')}
            </a>
          )}
        </div>
        <div className={'b-step__orgWrapper'}>
          <div className="b-equityAudit__text16">{t('common:headlines.organization')}</div>
          <div className={'b-step__orgText'}>{createEAData.organization_name}</div>
        </div>
        <Select
          name={'organization'}
          value={state.employees_number}
          placeholder={t('common:label.number-of-employees')}
          ariaLabel={'Number of Employees'}
          options={EAOptions.employees_number}
          error={''}
          handleChange={(value: ISelectOptions) => handleSelect('employees_number', value)}
          height={52}
          autoFocus
        />
        <Select
          name={'organization'}
          value={state.org_age}
          placeholder={t('common:label.age-organization')}
          ariaLabel={'Age of Your Organization'}
          options={EAOptions.org_age}
          error={''}
          handleChange={(value: ISelectOptions) => handleSelect('org_age', value)}
          height={52}
        />

        <div className={`b-step__bottom`}>
          <div className={`b-step__bottom-left`}>
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={() => onChangeStep('2')}
              title={t('common:btn.back')}
            />
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={handleCancel}
              title={t('common:btn.cancel')}
            />
          </div>
          {isMobileOnly ? (
            <div className={`b-step__bottom-left`}>
              <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
                <img src={SaveIc} alt={t('common:alt.saveIc')} />
                {t('common:btn.save-exit')}
              </a>
              <Button
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleNext}
                disabled={_isNextBtnDisabled()}
                title={t('common:btn.next')}
                enableEnter
              />
            </div>
          ) : (
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={handleNext}
              disabled={_isNextBtnDisabled()}
              title={t('common:btn.next')}
              enableEnter
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CreateEAStep5;
