import React, { FC, useEffect, useState } from 'react';
import ForgotPasswordView from './ForgotPasswordView';
import { useDispatch } from 'react-redux';
import { forgotPasswordRequest } from '../../../../store/authData/authDataActions';
import { email, validate } from '../../../../helpers';
import { ROOTS } from '../../../../routes/routes';
import { useHistory } from 'react-router-dom';

interface IProps {
  history: any;
}

const ForgotPasswordContainer: FC<IProps> = (): JSX.Element => {
  const validator = {
    required: ['username'],
    custom: [email(['username'])],
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [error, setErrors] = useState<any>({});
  const [isEmail, setIsEmail] = useState<any>(true);

  useEffect(() => {
    setErrors({});
    let { errors } = validate(validator, { username });
    setIsEmail(!Object.keys(errors || {}).length);
  }, [username]);

  const handleNavigate = (response: any) => {
    if (response) {
      history.push(ROOTS.PASSWORD_SUBMITTED);
    }
  };

  const checkErrors = (field: string) => {
    let errorCase;
    switch (field) {
      case 'username':
        errorCase = validate(validator, { username });
        break;
      default:
        errorCase = { errors: error };
    }
    setErrors(errorCase.errors);
  };

  const handleOnBlur = (field: string) => () => {
    checkErrors(field);
  };

  const handleSubmit = () => {
    let { errors } = validate(validator, { username });
    setErrors(errors);
    if (!Object.keys(error).length) {
      dispatch(
        forgotPasswordRequest({
          data: { username: username },
          callback: handleNavigate,
        }),
      );
    }
  };

  const onInputChange = (name: string, value: string) => {
    setUsername(value);
  };

  return (
    <ForgotPasswordView
      handleOnBlur={handleOnBlur}
      errors={error}
      isEmail={isEmail}
      username={username}
      onChange={onInputChange}
      onSubmit={handleSubmit}
    />
  );
};

export default ForgotPasswordContainer;
