import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { _getSortingFields } from '../../../helpers';
import { Button } from '../../controls';
import { Modal } from '../index';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-data-grid';
import { QueryParamsModel } from '../../../hooks/common/use-table-data';

interface IProps {
  columns: Column<any>[];
  sortingData: QueryParamsModel['sorting'];
  handleChangeSortField: (field: string) => void;
  handleSaveSorting: () => void;
  onClose: () => void;
}

const SortModal: FC<IProps> = ({
  columns,
  sortingData,
  handleChangeSortField,
  handleSaveSorting,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        showCloseBtn={false}
        width={350}
        onClose={onClose}
        className={!isMobile ? 'b-filtersModal__mobileModal' : ''}
        title={t('common:btn.sort')}
        classNameTitle="b-filtersModal__modalTitle"
      >
        <div className="b-filtersModal">
          <div className="b-filtersModal__list">
            <div className="b-filtersModal__title">{t('common:filter.by')}</div>
            <ul>
              {_getSortingFields(columns).map(col => {
                const isActive = col.key === sortingData?.columnKey;
                return (
                  <li key={`sortFilesMob-${col.key}`}>
                    <a
                      className={isActive ? '-active' : ''}
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        handleChangeSortField(col.key);
                      }}
                    >
                      {col.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="b-filtersModal__buttons">
          <Button
            onPress={handleSaveSorting}
            title={t('common:btn.sort')}
            size={'large'}
            type={'orange-light'}
          />
        </div>
      </Modal>
    </>
  );
};

export default SortModal;
