import React, { FC, useState } from 'react';

import { Button, Checkbox, Input, RadioButton, RadioSelect } from '../../../../controls';
import { Modal } from '../../../../common';
import {
  AvailableCount,
  ElmAvailableUserModel,
  IRouteParamsBase,
  ISelectOptions,
} from '../../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { addUserToElm, createElmUser } from '../../../../../store/elm/elmActions';
import { useParams } from 'react-router';
import { email, getUserRoleList, isEmptyObject, validate } from '../../../../../helpers';
import { Modals, ModalsModel } from '../../../../../constants';
import { useTranslation } from 'react-i18next';

type IProps = {
  onClose: () => void;
  availableUsers: ElmAvailableUserModel[] | null;
  onAddUser: () => void;
  availableUsersCount: AvailableCount;
};

type IForm = {
  first_name: string;
  last_name: string;
  email: string;
  division: string;
  user_role: ISelectOptions;
};

interface IRouteParams extends IRouteParamsBase {
  elmId: string;
}

const AddUser: FC<IProps> = ({ onClose, availableUsers, onAddUser, availableUsersCount }) => {
  const validator = {
    required: ['first_name', 'last_name', 'email', 'division'],
    custom: [email(['email'])],
  };

  const { t } = useTranslation();
  const { elmId } = useParams<IRouteParams>();
  const [modals, setModals] = useState<ModalsModel>({
    isMaxUsers: false,
  });

  const userTypesSelector = useSelector((state: StateType) => state.utils.data.userTypesRoles);
  const dispatch = useDispatch();

  const userRoleList = getUserRoleList(userTypesSelector, 2);

  const initForm = {
    first_name: '',
    last_name: '',
    email: '',
    division: '',
    user_role: userRoleList.find(role => role.value === 12) || userRoleList[0],
  };

  let [isNewUserModal, setIsNewUserModal] = useState(false);
  let [errors, setErrors] = useState<any>({});
  let [form, setForm] = useState<IForm>(initForm);
  let [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  // Staff by default
  const [roleType, setRoleType] = useState<number>(2);

  const elmRoleTypeList = useSelector((state: StateType) => state.utils.data.elmRoleTypes);
  const elmRoleTypes = elmRoleTypeList.filter(f => f.value);

  const openNewUserModal = () => {
    setIsNewUserModal(true);
  };

  const closeNewUserModal = () => {
    setIsNewUserModal(false);
  };

  const handleChangeInput = (name: string, value: string) => {
    setForm(prevState => ({
      ...prevState,
      [name]: value,
    }));
    setErrors({});
  };

  const handleChangeSelect = (value: ISelectOptions) => {
    setForm(prevState => ({
      ...prevState,
      user_role: value,
    }));
    setErrors({});
  };

  const selectUser = (userId: number) => {
    if (!availableUsers) return null;
    if (availableUsersCount.available_users <= selectedUsers.length) {
      setModals(prevState => ({
        ...prevState,
        [Modals.isMaxUsers]: true,
      }));
      return;
    }
    if (userId === 0) {
      if (availableUsers?.length === selectedUsers.length) {
        setSelectedUsers([]);
      } else {
        setSelectedUsers(availableUsers.map(user => user.user_id));
      }
      return null;
    }
    if (selectedUsers.includes(userId)) {
      setSelectedUsers([...selectedUsers].filter(id => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleCreateUser = () => {
    let { errors } = validate(validator, form);
    setErrors(errors);
    if (errors && isEmptyObject(errors)) {
      dispatch(
        createElmUser({
          data: {
            first_name: form.first_name,
            last_name: form.last_name,
            email: form.email,
            division: form.division,
            user_role_id: Number(form.user_role.value),
            elm_role_type: roleType,
          },
          elmId: Number(elmId),
          callback: (res, err) => {
            if (err) {
              setModals(prevState => ({
                ...prevState,
                [Modals.isMaxUsers]: true,
              }));
              return;
            }
            onAddUser();
          },
        }),
      );
    }
  };

  const addUsers = () => {
    dispatch(
      addUserToElm({
        elmId: Number(elmId),
        users_ids: selectedUsers,
        elm_role_type: roleType,
        callback: () => {
          onAddUser();
        },
      }),
    );
  };

  const handleCloseModel = (modal: string) => {
    setModals({
      [modal]: false,
    });
  };

  return (
    <>
      {isNewUserModal ? (
        <Modal showCloseBtn={false} width={550} onClose={closeNewUserModal} title={'Add a New User'}>
          <div className="b-modal">
            <div className="b-elmAddUserModal__list">
              <div className="b-elmAddUserModal__inputs">
                <Input
                  name={'first_name'}
                  error={errors.first_name}
                  value={form.first_name}
                  type={'text'}
                  placeholder={t('common:label.first-name')}
                  handleChange={(value: string) => handleChangeInput('first_name', value)}
                />
                <Input
                  name={'last_name'}
                  error={errors.last_name}
                  value={form.last_name}
                  type={'text'}
                  placeholder={t('common:label.last-name')}
                  handleChange={(value: string) => handleChangeInput('last_name', value)}
                />
                <Input
                  name={'email'}
                  error={errors.email}
                  value={form.email}
                  type={'text'}
                  placeholder={t('common:label.email')}
                  handleChange={(value: string) => handleChangeInput('email', value)}
                />
                <Input
                  name={'division'}
                  error={errors.division}
                  value={form.division}
                  type={'text'}
                  placeholder={t('common:label.division/department/unit')}
                  handleChange={(value: string) => handleChangeInput('division', value)}
                />
                <Input
                  disabled={true}
                  name={'user_type'}
                  error={errors.user_type}
                  value={'Internal User'}
                  type={'text'}
                  placeholder={t('common:label.user-type')}
                />
              </div>
              <div className="b-elmAddUserModal__roles">
                <div className="b-elmAddUserModal__roleTitle">{t('common:label.user-role')}</div>
                {userRoleList.length ? (
                  <div className="b-elmAddUserModal__rGroup">
                    <RadioSelect
                      value={form.user_role}
                      handleChange={(value: ISelectOptions) => handleChangeSelect(value)}
                      values={userRoleList}
                      hideDescription={true}
                    />
                  </div>
                ) : null}
              </div>
              <h4 className="b-modal__title">{t('common:column.roles')}</h4>
              {elmRoleTypes.map(type => {
                return (
                  <div key={`elm-role-${type.value}`} className="b-elmAddUserModal__item mb-0">
                    <RadioButton
                      title={type.label}
                      value={type.value}
                      onChangeValue={e => {
                        setRoleType(Number(e.target.value));
                      }}
                      labelClasses="ml-0"
                      selectedValue={roleType}
                    />
                  </div>
                );
              })}
            </div>
            <div className="b-modal__buttons">
              <Button
                onPress={closeNewUserModal}
                title={t('common:btn.cancel')}
                size={'large'}
                type={'transparency'}
              />
              <Button
                onPress={handleCreateUser}
                title={t('common:btn.save')}
                size={'large'}
                type={'orange-light'}
              />
            </div>
          </div>
        </Modal>
      ) : (
        <Modal showCloseBtn={false} width={550} onClose={onClose} title={'Add Users to ELM'}>
          <div className="b-modal">
            <div className="b-elmAddUserModal__list">
              <h4 className="b-modal__title">{t('common:column.roles')}</h4>
              {elmRoleTypes.map(type => {
                return (
                  <div key={`elm-role-${type.value}`} className="b-elmAddUserModal__item mb-0">
                    <RadioButton
                      title={type.label}
                      value={type.value}
                      onChangeValue={e => {
                        setRoleType(Number(e.target.value));
                      }}
                      labelClasses="ml-0"
                      selectedValue={roleType}
                    />
                  </div>
                );
              })}
            </div>
            {availableUsers && availableUsers.length ? (
              <div className="b-elmAddUserModal__list">
                <div className="b-elmAddUserModal__item">
                  <div className="b-elmAddUserModal__title">{t('common:headlines.organization-users')}</div>
                  <Checkbox
                    noText
                    onChange={() => selectUser(0)}
                    checked={availableUsers?.length === selectedUsers.length}
                  />
                </div>
                {availableUsers.map(item => {
                  return (
                    <div key={`a-user-${item.user_id}`} className="b-elmAddUserModal__item">
                      <div className="b-equityAudit__text16">
                        {item.first_name} {item.last_name}
                      </div>
                      <Checkbox
                        noText
                        onChange={() => selectUser(item.user_id)}
                        checked={selectedUsers.includes(item.user_id)}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="b-elmAddUserModal__list">{t('common:label.there-no-available-users')}</div>
            )}

            <div className="b-elmAddUserModal__addBtn">
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  openNewUserModal();
                }}
                className="b-link-add"
              >
                {t('common:btn.add-new-user')}
              </a>
            </div>
            <div className="b-modal__buttons">
              <Button onPress={onClose} title={t('common:btn.cancel')} size={'large'} type={'transparency'} />
              <Button
                disabled={!selectedUsers.length}
                onPress={addUsers}
                title={t('common:btn.add')}
                size={'large'}
                type={'orange-light'}
              />
            </div>
          </div>
        </Modal>
      )}
      {!!modals.isMaxUsers && (
        <Modal
          showCloseBtn={true}
          width={450}
          className={`b-modal__add-user`}
          classNameBack={`b-modal__add-user_back`}
          onClose={() => handleCloseModel(Modals.isMaxUsers)}
        >
          <div>{t('awa:ER9.msg', { x: availableUsersCount.credit_max_users })}</div>
        </Modal>
      )}
    </>
  );
};

export default AddUser;
