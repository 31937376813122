import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Worker } from '@react-pdf-viewer/core';
import store from './store/configureStore';
import App from './App';
import { ThemeController } from './components/common';
import 'react-datepicker/dist/react-datepicker.css';
import 'cropperjs/dist/cropper.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/styles/styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import configureI18n from './services/i18n';

configureI18n();
// Add this line to fix the compiler!
window.process = {} as any;

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <Suspense fallback="">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <App />
          </Worker>
        </Suspense>
      </React.StrictMode>
    </BrowserRouter>
    <ThemeController />
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
