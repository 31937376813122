import { BelResourceModel, BelResourceShortModel } from '../../models';
import { useEffect, useState } from 'react';
import Api from '../../services/api';

export const useAddToFav = (data: BelResourceShortModel | BelResourceModel | null) => {
  const [is_favorite, setFav] = useState(data?.is_favorite || false);
  const addToFav = async (id: number) => {
    const response = await Api.post('resource/add_to_favorite/', { resource_id: id });
    if (response) {
      setFav(!is_favorite);
    }
  };
  useEffect(() => {
    if (data) {
      setFav(data.is_favorite);
    }
  }, [data]);
  return {
    addToFav,
    is_favorite,
  };
};
