import { useHistory } from 'react-router';

export const useNavBack = () => {
  const history = useHistory();

  const navBack = (event?: any) => {
    if (event) {
      event.preventDefault();
    }
    history.go(-1);
  };

  return {
    navBack,
  };
};
