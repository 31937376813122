import React from 'react';
import GoogleLinkCalendar from './GoogleLinkCalendar';
import { GoogleOAuthProvider } from '@react-oauth/google';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

interface IProps {
  type: 'calendar';
}

const GoogleAuth = ({ type }: IProps) => {
  const renderBlock = () => {
    switch (type) {
      case 'calendar': {
        return <GoogleLinkCalendar />;
      }
    }
  };

  return <GoogleOAuthProvider clientId={CLIENT_ID}>{renderBlock()}</GoogleOAuthProvider>;
};

export default GoogleAuth;
