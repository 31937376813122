import React, { useEffect, useState } from 'react';
import { getCohortSession } from '../../../../../store/cohort/cohortActions';
import { BaseModel, BelSessionModel, IRouteParamsBase } from '../../../../../models';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../controls';
import { ROOTS } from '../../../../../routes/routes';
import { basicDateFormat, convertToURL, getZoneAbbr, sanitizedHTML } from '../../../../../helpers';
import dayjs from 'dayjs';
import ActivityDeck from '../../../../../assets/images/icons/gslides.svg';
import MeetingLink from '../../../../../assets/images/icons/icons-meeting-link-enabled.svg';
import { useIsAccessEditCohort } from '../../../../../hooks/beloved/cohorts/use-edit-access';
import { useGetCohortDetails } from '../../../../../hooks/beloved/cohorts/use-get-cohort-details';
import { useNavToViewResource } from '../../../../../hooks/resources/use-nav-to-view-resource';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';
import { HeadlineOrganization } from '../../../../common';

interface IRouteParams extends IRouteParamsBase {
  id: string;
  sessionId: string;
}

const CohortSessionDetails = () => {
  const { t } = useTranslation();
  const { id, sessionId } = useParams<IRouteParams>();
  const [session, setSession] = useState<BelSessionModel | null>();
  const dispatch = useDispatch();
  const history = useHistory();
  const isBeloved = location.pathname.match('beloved');
  const isUnlock = dayjs(session?.unlock_date).isBefore(new Date());
  const isShow = isBeloved || isUnlock;
  const cohort = useGetCohortDetails();
  const isAccess = useIsAccessEditCohort(cohort);
  const navToResourceView = useNavToViewResource();
  const { navBack } = useNavBack();

  useEffect(() => {
    dispatch(
      getCohortSession({
        data: {
          id: Number(sessionId),
        },
        callback: (res: BelSessionModel) => {
          setSession(res);
        },
      }),
    );
  }, []);

  const handleNavToViewResource = (e: any, resource: BaseModel) => {
    e.preventDefault();
    navToResourceView({
      id: resource.id,
      referenced_links: [],
    });
  };

  const navToEditSession = (e: any) => {
    e.preventDefault();
    history.push(ROOTS.BELOVED_COHORT_SESSION.replace(':id', `${id}`).replace(':sessionId', `${sessionId}`));
  };

  const renderLock = () => {
    return <span className="b-button-icon -lock -noHover" />;
  };

  const renderContent = () => {
    if (!session) return <Loader />;
    return (
      <>
        <div className="b-cohortSession">
          <div className={`b-cohortSession__subTitleBlock`}>
            <h2 className={`b-cohortSession__subTitle`}>{session.title}</h2>
            {isAccess ? (
              <a href="#" className="b-link-icon -edit" onClick={navToEditSession}>
                {t('common:btn.edit-session')}
              </a>
            ) : null}
          </div>

          <dl className="b-panelData__row -rw4">
            <dt>
              <div className="b-cohortSession__fieldTitle">{t('common:column.start-date')}:</div>
            </dt>
            <dd className={'val'}>
              {session.start_date ? (
                <div className="b-cohortSession__fieldValue">{basicDateFormat(session.start_date)}</div>
              ) : (
                renderLock()
              )}
            </dd>
            <dt>
              <div className="b-cohortSession__fieldTitle">{t('common:column.time')}:</div>
            </dt>
            <dd>
              {session.start_date ? (
                <div className="b-cohortSession__fieldValue">
                  <span>
                    {dayjs(session.start_date || '')
                      .format('LT')
                      .split(' ')
                      .join('')
                      .toLowerCase()}
                    -
                    {dayjs(session.end_date || '')
                      .format('LT')
                      .split(' ')
                      .join('')
                      .toLowerCase()}
                    &nbsp;
                  </span>
                  <span className={`ml-1`}>{getZoneAbbr(session.start_date || '')}</span>
                </div>
              ) : (
                renderLock()
              )}
            </dd>
          </dl>
          <dl className="b-panelData__row">
            <dt>
              <div className="b-cohortSession__fieldTitle">{t('common:label.unlock-session-date')}:</div>
            </dt>
            <dd className="d-flex align-items-end mb-0">
              {session.unlock_date ? (
                <div className="b-cohortSession__fieldValue">{basicDateFormat(session.unlock_date)}</div>
              ) : (
                renderLock()
              )}
            </dd>
          </dl>
          <dl className="b-panelData__row">
            <dt>
              <div className="b-cohortSession__fieldTitle">{t('common:label.meeting-link')}:</div>
            </dt>
            <dd>
              {session.meeting_link ? (
                <div className="b-cohortSession__fieldValue">
                  {isShow ? (
                    <a href={convertToURL(session.meeting_link)} target="_blank" rel="noreferrer">
                      <img
                        className={'b-cohortSession__img'}
                        src={MeetingLink}
                        alt={t('common:alt.meeting-link')}
                      />
                    </a>
                  ) : (
                    renderLock()
                  )}
                </div>
              ) : (
                t('common:status.not-available-yet')
              )}
            </dd>
          </dl>
          <dl className="b-panelData__row -activity">
            <dt>
              <div className="b-cohortSession__fieldTitle">{t('common:label.session-deck')}:</div>
            </dt>
            <dd className={'val'}>
              {session.deck_link ? (
                <div className="b-cohortSession__fieldValue">
                  {isShow ? (
                    <a href={convertToURL(session.deck_link)} target="_blank" rel="noreferrer">
                      <img
                        className={'b-cohortSession__img'}
                        src={ActivityDeck}
                        alt={t('common:alt.activity-deck')}
                      />
                    </a>
                  ) : (
                    renderLock()
                  )}
                </div>
              ) : (
                t('common:status.not-available-yet')
              )}
            </dd>
            <dt>
              <div className="b-cohortSession__fieldTitle">{t('common:column.activity-deck')}:</div>
            </dt>
            <dd>
              {cohort?.activity_deck ? (
                <div className="b-cohortSession__fieldValue">
                  <a href={convertToURL(cohort?.activity_deck)} target="_blank" rel="noreferrer">
                    <img
                      className={'b-cohortSession__img'}
                      src={ActivityDeck}
                      alt={t('common:alt.activity-deck')}
                    />
                  </a>
                </div>
              ) : (
                t('common:status.not-available-yet')
              )}
            </dd>
          </dl>
          <dl className="b-panelData__row">
            <dt>
              <div className="b-cohortSession__fieldTitle">{t('common:label.description')}:</div>
            </dt>
            <dd>
              <div className="b-cohortSession__fieldValue">
                <p>{session.description}</p>
              </div>
            </dd>
          </dl>
          <dl className="b-panelData__row">
            <dt>
              <div className="b-cohortSession__fieldTitle">{t('common:label.pre-work')}:</div>
            </dt>
            <dd>
              <div className="b-cohortSession__fieldValue">
                {isShow ? (
                  <p dangerouslySetInnerHTML={{ __html: sanitizedHTML(session.pre_work) }} />
                ) : (
                  renderLock()
                )}
              </div>
            </dd>
          </dl>
          <dl className="b-panelData__row">
            <dt>
              <div className="b-cohortSession__fieldTitle">{t('common:label.post-work')}:</div>
            </dt>
            <dd>
              <div className="b-cohortSession__fieldValue">
                {isShow ? (
                  <p dangerouslySetInnerHTML={{ __html: sanitizedHTML(session.post_work) }} />
                ) : (
                  renderLock()
                )}
              </div>
            </dd>
          </dl>
          <dl className="b-panelData__row">
            <dt>
              <div className="b-cohortSession__fieldTitle">{t('common:label.reflection-prompts')}:</div>
            </dt>
            <dd>
              <div className="b-cohortSession__fieldValue">
                {isShow ? <p>{session.reflection_prompts}</p> : renderLock()}
              </div>
            </dd>
          </dl>
          {!isShow ? (
            <dl className="b-panelData__row">
              <dt>
                <div className="b-cohortSession__fieldTitle">{t('common:label.resources')}:</div>
              </dt>
              <dd>
                <div className="b-cohortSession__fieldValue">{renderLock()}</div>
              </dd>
            </dl>
          ) : null}
          {isShow ? (
            <>
              <div className={`b-cohortSession__fieldTitle`}>{t('common:label.resources')}</div>
              <div className={`b-cohortSession__resources`}>
                {session.resources.map((resource: BaseModel, resourceIndex) => {
                  return (
                    <div
                      key={`resources-panel-${resourceIndex}`}
                      className={`b-cohortSession__resources__item`}
                    >
                      <span>{resource.title}</span>
                      <div className={`resource`}>
                        <button
                          className="b-button-icon -viewResource"
                          onClick={e => handleNavToViewResource(e, resource)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <main className="b-page">
      <div className="b-page__header -desktop">
        <HeadlineOrganization
          orgName={cohort?.name || ''}
          orgId={cohort?.id || ''}
          headline={t('common:headlines.cohort-program')}
          customClasses={'-noDesktopMb mr-4'}
          backBtn={<EmptyLink onClick={navBack} className="b-button-icon-back" />}
          noOrgLogo
        />
      </div>
      <div className="contentContainer">{renderContent()}</div>
    </main>
  );
};

export default CohortSessionDetails;
