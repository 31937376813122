import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import { useFilters } from '../../../../hooks/filters/use-filters';
import useHasAccess from '../../../../hooks/beloved/adminTool/user-has-access';
import { useTableDataV2 } from '../../../../hooks/common/use-table-data-v2';
import {
  BelCertifiedConsultantModel,
  BelCertifiedNewUserModel,
  KeyValueModel,
  QueryParamsModel,
  TableDataEntities,
} from '../../../../models';
import Api from '../../../../services/api';
import { showAlert } from '../../../common/Alert/RemoteAlert';
import { getEntitiesByIds } from '../../../../helpers';
import { FilterModalButton } from '../../../common';
import { DataTable } from '../../../common/DataTable/DataTable';
import { BelovedPermissions } from '../../../../constants';
import UserForm from './parts/UserForm';
import {
  certificationConsultantCreate,
  certificationConsultantUpdate,
} from '../../../../store/cetrificationConsultant/certificationConsultantActions';
import { useDispatch } from 'react-redux';
import { getColumns } from './parts/columns';

const BelovedCertifiedConsultant = () => {
  const { t } = useTranslation();
  let [isUserFormOpen, setIsUserFormOpen] = useState(false);
  let [currentUser, setCurrentUser] = useState<BelCertifiedConsultantModel | undefined>(undefined);
  const [errors, setErrors] = useState<KeyValueModel<string>>({});
  const { filterOptions } = useFilters();
  const location = useLocation();
  const dispatch = useDispatch();
  const orgIdsToAdd: number[] | undefined = location.state?.orgIds;
  const hasAccessConsultantCreate = useHasAccess(BelovedPermissions.CERTIFIED_CONSULTANT_ADD);
  const hasAccessConsultantDelete = useHasAccess(BelovedPermissions.CERTIFIED_CONSULTANT_DELETE);

  const {
    query,
    fetchData,
    handleSearch,
    handleSelectFilter,
    handleApplyFilter,
    data,
    filterData,
    isFiltersChanged,
    selectedEntitiesIds,
    setSelectedEntitiesIds,
    handleSelectOne,
  } = useTableDataV2<BelCertifiedConsultantModel>({
    baseUrl: 'beloved/beloved_certified_consultant/',
    entityName: TableDataEntities.BELOVED_CERTIFIED_CONSULTANTS,
  });

  const columns = getColumns();

  useEffect(() => {
    let newQuery: QueryParamsModel = { ...query };
    if (orgIdsToAdd && newQuery.filters) {
      newQuery.filters.is_sent = [false];
    }
    fetchData(newQuery);
  }, [location.key]);

  const handleClickUser = (user: BelCertifiedConsultantModel) => {
    if (!hasAccessConsultantCreate) {
      return;
    }
    setIsUserFormOpen(true);
    setCurrentUser(user);
  };

  const saveUserCallback = () => {
    setIsUserFormOpen(false);
    setCurrentUser(undefined);
    fetchData(query);
  };

  const handleSaveUserForm = async (
    userData: BelCertifiedNewUserModel,
    callback: (error?: KeyValueModel<string>) => void,
  ) => {
    if (currentUser) {
      dispatch(
        certificationConsultantUpdate({
          data: userData,
          userId: currentUser.id,
          callback: saveUserCallback,
        }),
      );
    } else {
      dispatch(
        certificationConsultantCreate({
          data: userData,
          callback: (error?: KeyValueModel<string>) => {
            console.log('error', error);

            if (error) {
              setErrors({ email: error as unknown as string });
              callback(error);
              return;
            }
            saveUserCallback();
          },
        }),
      );
    }
  };

  const handleDelete = async () => {
    showAlert({
      title: t('awa:N87.title'),
      text: (
        <>
          <p>{t('awa:N87.msg')}</p>
        </>
      ),
      buttons: {
        left: {
          title: t('common:btn.cancel'),
          type: 'transparency',
        },
        right: {
          title: t('common:btn.yes'),
          onClick: async () => {
            await Api.delete(`beloved/beloved_certified_consultant/remove/`, undefined, {
              ids: selectedEntitiesIds,
            });
            toast.success(t('main:certification-consultant.success-removed'));
            setSelectedEntitiesIds([]);
            fetchData(query);
          },
        },
      },
    });
  };

  const _deleteDisabled = () => {
    const selectedEntities = getEntitiesByIds(selectedEntitiesIds, data);
    return !selectedEntities.length;
  };

  const handleNewUser = () => {
    setCurrentUser(undefined);
    setIsUserFormOpen(true);
  };

  const handleCloseForm = () => {
    setErrors({});
    setIsUserFormOpen(false);
  };

  return (
    <>
      <main className={'b-page'}>
        <h1 className={'b-page__title'}>{t('common:column.beloved-certified-consultants')}</h1>
        <div className="b-page__controls">
          <div className="b-tableToolContainer">
            {hasAccessConsultantDelete ? (
              <button
                onClick={handleDelete}
                disabled={_deleteDisabled()}
                className="b-tableTool -delete -mobView -mr"
              >
                {t('common:btn.delete')}
              </button>
            ) : null}
            {hasAccessConsultantCreate ? (
              <button onClick={handleNewUser} className="b-tableTool -addPlus -mobView -mr">
                {t('common:btn.new-user')}
              </button>
            ) : null}
            {filterOptions ? (
              <FilterModalButton
                query={query}
                handleSelectFilter={handleSelectFilter}
                handleApplyFilter={handleApplyFilter}
                filterData={filterData}
                isFiltersChanged={isFiltersChanged}
                filters={filterOptions.beloved_certification_consultant}
              />
            ) : null}
          </div>

          <div className="d-flex align-items-center">
            <div className="b-page__search">
              <form action="#" className="searchForm">
                <input
                  type="search"
                  value={query.search}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder={t('common:label.search')}
                  aria-label="Search field"
                />
                <span className="searchForm__icon" />
              </form>
            </div>
          </div>
        </div>
        <DataTable<BelCertifiedConsultantModel>
          baseUrl="beloved/beloved_certified_consultant/"
          entityName={TableDataEntities.BELOVED_CERTIFIED_CONSULTANTS}
          selectedEntitiesIds={selectedEntitiesIds}
          handleSelectOne={handleSelectOne}
          onRowClick={handleClickUser}
          cols={columns}
        />
      </main>
      {isUserFormOpen && (
        <UserForm
          onClose={handleCloseForm}
          userData={currentUser}
          onSave={handleSaveUserForm}
          errors={errors}
        />
      )}
    </>
  );
};

export default BelovedCertifiedConsultant;
