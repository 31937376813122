import React, { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Button } from '../../../../../controls';
import { useHistory } from 'react-router';
import { EATaking } from '../../../../../../models';
import { useTranslation } from 'react-i18next';

interface IProps {
  onPressNext: () => void;
  takingEA: EATaking;
}

const Intro: FC<IProps> = ({ onPressNext }) => {
  const { t } = useTranslation();

  const history: any = useHistory();

  return (
    <>
      <div className="b-EATaking__box">
        <div className="b-EATaking__container">
          <h2 className="b-EATaking__boxTitle">{t('main:taking-audit.intro.title')}</h2>
          <p>{t('main:taking-audit.intro.msg.p1')}</p>
          <ul className="b-EATaking__list">
            <li>{t('main:taking-audit.intro.msg.p2')}</li>
            <li>{t('main:taking-audit.intro.msg.p3')}</li>
            <li>{t('main:taking-audit.intro.msg.p4')}</li>
            <li>{t('main:taking-audit.intro.msg.p5')}</li>
          </ul>
          {!isMobileOnly && (
            <div className="b-EATaking__buttons">
              <Button
                disabled
                className={'btn -type_form'}
                type={'transparency'}
                onPress={history.goBack}
                title={t('common:btn.back')}
              />
              <Button
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={onPressNext}
                title={t('common:btn.next')}
                enableEnter
              />
            </div>
          )}
        </div>
      </div>

      {isMobileOnly && (
        <React.Fragment>
          <div className="b-EATaking__buttons">
            <Button
              disabled
              className={'btn'}
              type={'transparency'}
              onPress={history.goBack}
              title={t('common:btn.back')}
            />
            <Button
              className={'btn'}
              type={'orange-light'}
              onPress={onPressNext}
              title={t('common:btn.next')}
              enableEnter
            />
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default Intro;
