import React, { useEffect, useState } from 'react';
import { Carousel } from '..';
import { useTranslation } from 'react-i18next';
import Api from '../../../services/api';
import { BelResourceModel } from '../../../models';
import { NewsBlockItem } from '..';

const NewsBlock = () => {
  const { t } = useTranslation();
  let [data, setData] = useState<BelResourceModel[] | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await Api.get('resource/news_block/');
    if (res) {
      setData(res);
    }
  };

  if (!data) return null;

  return (
    <div className="b-newsBlock b-newsBlock__container">
      <div className="b-newsBlock__title">{t('common:label.news')}</div>
      <div className="b-newsBlock__slider">
        <Carousel>
          {data.map(item => {
            return <NewsBlockItem key={`news-item-${item.id}`} data={item} />;
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default NewsBlock;
