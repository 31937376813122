import React, { useState, useEffect, FC } from 'react';
import { createPortal } from 'react-dom';
import styles from '../../pages/OrganizationApp/EquityAudit/CreateEA/CreateEA.module.scss';
import { Modal } from '../index';
import makeUrls from 'add-event-to-calendar';
import { OrganizationDetailsModel } from '../../../models';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { StateType } from '../../../store/reducers';
import { DOMAIN_URI } from '../../../constants';
import { basicDateFormat } from '../../../helpers';
import { useTranslation } from 'react-i18next';
const fileDownload = require('js-file-download');

type CalendarType = 'google' | 'apple' | 'yahoo' | 'outlook';

interface IProps {
  startDate: string | Date;
  endDate?: string | Date;
  type: 'elm' | 'ea' | 'cohort';
}

const AddToCalendar: FC<IProps> = ({ startDate, endDate, type }) => {
  const { t } = useTranslation();
  const currentOrg: OrganizationDetailsModel | null = useSelector(
    (state: StateType) => state.organization.currentOrg,
  );

  let [modalOpened, setModalOpened] = useState(false);

  const mountContainer = document.body;
  let popupContainer = document.getElementById('add-calendar-popup-portal');

  if (!popupContainer) {
    popupContainer = document.createElement('div');
    popupContainer.id = 'add-calendar-popup-portal';
  }

  useEffect(() => {
    mountContainer?.appendChild(popupContainer as HTMLElement);
    return () => {
      mountContainer?.removeChild(popupContainer as HTMLElement);
    };
  }, [mountContainer, popupContainer]);

  const openModal = (e: any) => {
    e.preventDefault();
    setModalOpened(true);
  };

  const addCalendar = (calType: CalendarType) => (e: any) => {
    const startsAt = new Date(startDate);
    startsAt.setHours(9);
    startsAt.setMinutes(0);
    startsAt.setSeconds(0);

    const endsAt = endDate ? new Date(endDate) : new Date(startDate);
    endsAt.setHours(12);
    endsAt.setMinutes(0);
    endsAt.setSeconds(0);

    e.preventDefault();
    const event = {
      name: '',
      location: '',
      details: '',
      startsAt: startsAt.toString(),
      endsAt: endsAt.toString(),
    };

    if (type === 'ea') {
      event.name = `Equity Audit - ${currentOrg?.name} ${t('main:tentative-date')}`;
      event.details = `Your Equity Audit is tentatively scheduled for ${basicDateFormat(
        startsAt,
      )}. Please, confirm date and time with your PM. ${DOMAIN_URI}`;
    }

    if (type === 'elm') {
      event.name = `Equity Lens Map - ${currentOrg?.name} - ${currentOrg?.type_name}`;
      event.details = `Your Equity Lens Map is scheduled to begin on ${basicDateFormat(
        startsAt,
      )} and end on ${dayjs(endsAt).format('MM.DD.YYYY')}. ${DOMAIN_URI}`;
    }

    if (type === 'cohort') {
      event.name = `Equity Lens Map - ${currentOrg?.name} - ${currentOrg?.type_name}`;
      event.details = `Your Equity Lens Map is scheduled to begin on ${basicDateFormat(
        startsAt,
      )} and end on ${dayjs(endsAt).format('MM.DD.YYYY')}. ${DOMAIN_URI}`;
    }

    const eventUrls = makeUrls(event);
    if (calType !== 'google') {
      const url = eventUrls.ics
        .replace('data:text/calendar;charset=utf8,', '')
        .replaceAll('%0A', '\n')
        .replaceAll('%20', ' ');

      const blob = new Blob([url]);
      fileDownload(blob, 'event.ics');
    } else {
      window.open(eventUrls[calType], '_blank');
    }
  };

  const renderModal = () => {
    const modal = (
      <Modal
        modalStyles={{ minHeight: 400 }}
        title={`${t('common:calendar.add-to-calendar')} (${t('common:headlines.optional')})`}
        onClose={() => setModalOpened(false)}
      >
        <div className={'b-EAModal'}>
          <div className="font-navy font-weight-bold mb-4">{t('common:headlines.options')}</div>
          <div className={styles.teamModal__list}>
            <a
              href="#"
              onClick={addCalendar('apple')}
              className={`b-equityAudit__text16 b-link ${styles.addCalendar__opt}`}
            >
              {t('common:calendar.apple-calendar')}
            </a>
            <a
              href="#"
              onClick={addCalendar('google')}
              className={`b-equityAudit__text16 b-link ${styles.addCalendar__opt}`}
            >
              {t('common:calendar.google-calendar')}
            </a>
            <a
              href="#"
              onClick={addCalendar('yahoo')}
              className={`b-equityAudit__text16 b-link ${styles.addCalendar__opt}`}
            >
              {t('common:calendar.yahoo-calendar')}
            </a>
            <a
              href="#"
              onClick={addCalendar('outlook')}
              className={`b-equityAudit__text16 b-link ${styles.addCalendar__opt}`}
            >
              {t('common:calendar.office-365')}
            </a>
          </div>
        </div>
      </Modal>
    );
    return createPortal(modal, popupContainer as HTMLElement);
  };

  return (
    <>
      <a href="#" className="b-link-add" onClick={openModal}>
        {t('common:calendar.add-to-calendar')}
      </a>
      {modalOpened && renderModal()}
    </>
  );
};

export default AddToCalendar;
