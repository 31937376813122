import React, { FC, useState } from 'react';
import { Button } from '../../../../../controls';
import { InstructionStep } from '../../../../../../models';
import { Modal, PopupPortal } from '../../../../../common';
import { useTranslation } from 'react-i18next';

interface IProps {
  setCurrentStep: (step: InstructionStep) => void;
}

const Intro1: FC<IProps> = ({ setCurrentStep }) => {
  const { t } = useTranslation();
  let [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (e: any) => {
    e.preventDefault();
    setModalOpen(true);
  };

  return (
    <>
      <div className={'b-step__container -centerContent'}>
        <h1 className={'b-step__bigTitle'}>{t('common:headlines.new-ea')}</h1>
        <p className={'b-step__p'}>
          {t('main:ea-create.intro.p1')} <b>{t('main:ea-create.intro.p2')}</b> {t('main:ea-create.intro.p3')}
        </p>
        <p className="b-step__p">{t('main:ea-create.intro.p4')}</p>
        <p className="b-step__p">
          {t('main:ea-create.intro.p5')}{' '}
          <a className="b-link" onClick={handleOpenModal} href="#">
            {t('main:ea-create.intro.p6')}.
          </a>
        </p>
        <div className="b-step__btns">
          <Button
            className={'btn -type_form'}
            type={'orange-light'}
            onPress={() => setCurrentStep('intro2')}
            title={t('common:btn.next')}
            enableEnter
          />
        </div>
      </div>
      {modalOpen && (
        <PopupPortal portalElementId={'user-guide-modal-2'}>
          <Modal title={t('awa:M35.title')} width={935} onClose={() => setModalOpen(false)}>
            <div className={'b-EAModal  b-step'}>
              <p className="b-step__p">
                {t('awa:M35.msg.p1')} <b>{t('awa:M35.msg.p2')}</b> {t('awa:M35.msg.p3')}
              </p>
              <p className="b-step__p">{t('awa:M35.msg.p4')}</p>
              <p className="b-step__p">{t('awa:M35.msg.p5')}</p>
              <p style={{ marginBottom: 8 }} className="b-step__smallTitle">
                {t('awa:M35.msg.p6')}
              </p>
              <p className="b-step__p">{t('awa:M35.msg.p7')}</p>
              <p className="b-step__p">{t('awa:M35.msg.p8')}</p>
              <div className="b-step__orangeBold b-step__textCenter -mb5 " style={{ marginBottom: 20 }}>
                {t('awa:M35.msg.p9')}
              </div>
              <div className="b-step__wBox -paddings -intro1Pop">
                <div>
                  <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                    {t('awa:M35.msg.p10.title')} <b>{t('awa:M35.msg.p10.msg')}</b>
                  </div>
                  <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                    {t('awa:M35.msg.p11.title')} <b>{t('awa:M35.msg.p11.msg')}</b>
                  </div>
                  <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                    {t('awa:M35.msg.p12.title')} <b>{t('awa:M35.msg.p12.msg')}</b>
                  </div>
                  <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                    {t('awa:M35.msg.p13.title')} <b>{t('awa:M35.msg.p13.msg')}</b>
                  </div>
                </div>
              </div>
              <div className="b-step__orangeBold b-step__textCenter -mb5 " style={{ marginBottom: 20 }}>
                {t('awa:M35.msg.p14')}
              </div>
              <div className="b-step__wBox -intro1Pop -paddings">
                <div>
                  <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                    {t('awa:M35.msg.p15.title')} <b>{t('awa:M35.msg.p15.msg')}</b>
                  </div>
                  <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                    {t('awa:M35.msg.p16.title')} <b>{t('awa:M35.msg.p16.msg')}</b>
                  </div>
                  <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                    {t('awa:M35.msg.p17.title')} <b>{t('awa:M35.msg.p17.msg')}</b>
                  </div>
                  <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                    {t('awa:M35.msg.p18.title')} <b>{t('awa:M35.msg.p18.msg')}</b>
                  </div>
                  <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                    {t('awa:M35.msg.p19.title')} <b>{t('awa:M35.msg.p19.msg')}</b>
                  </div>
                </div>
              </div>
              <div className="b-step__orangeBold b-step__textCenter -mb5 " style={{ marginBottom: 20 }}>
                {t('awa:M35.msg.p20')}
              </div>
              <div className="b-step__wBox -intro1Pop -paddings">
                <div>
                  <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                    {t('awa:M35.msg.p21.title')} <b>{t('awa:M35.msg.p21.msg')}</b>
                  </div>
                  <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                    {t('awa:M35.msg.p22.title')} <b>{t('awa:M35.msg.p22.msg')}</b>
                  </div>
                  <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                    {t('awa:M35.msg.p23.title')} <b>{t('awa:M35.msg.p23.msg')}</b>
                  </div>
                  <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                    {t('awa:M35.msg.p24.title')} <b>{t('awa:M35.msg.p24.msg')}</b>
                  </div>
                </div>
              </div>
              <div className="b-step__p">{t('awa:M35.msg.p25')}</div>
              <div className="b-step__p">
                {t('awa:M35.msg.p26')}{' '}
                <a
                  href="https://drive.google.com/file/d/1BN6xOJQ-3AqoLZtaHLXlNOD69tHgWXkm/view"
                  rel="noreferrer"
                  target="_blank"
                  className="b-link"
                >
                  {t('awa:M35.msg.p27')}
                </a>
              </div>
            </div>
          </Modal>
        </PopupPortal>
      )}
    </>
  );
};

export default Intro1;
