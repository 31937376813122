import React, { FC } from 'react';
import { ChartBar } from '../../../../common';
import { ChartLabelModel, ChartReportModel, IRouteParamsBase } from '../../../../../models';
import Slide11 from '../../../../../assets/images/debrief/11.png';
import Slide14 from '../../../../../assets/images/debrief/14.png';
import Slide17 from '../../../../../assets/images/debrief/17.png';
import Slide18 from '../../../../../assets/images/debrief/18.png';
import { ROOTS } from '../../../../../routes/routes';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

interface IProps {
  chart: ChartReportModel;
  type: string;
  is_legacy_audit: boolean;
  alt?: string;
}

const ChartSlide: FC<IProps> = ({ chart, type, is_legacy_audit, alt }) => {
  const history = useHistory();
  const { orgId, eaId } = useParams<IRouteParamsBase>();

  let src = Slide11;
  if (type === '14') {
    src = Slide14;
  } else if (type === '17') {
    src = Slide17;
  } else if (type === '18') {
    src = Slide18;
  }

  const handleRowClick = (row: ChartLabelModel) => {
    if (!is_legacy_audit) {
      history.push(
        ROOTS.REPORT_STANDARD_RESPONSES.replace(':orgId', `${orgId}`)
          .replace(':eaId', `${eaId}`)
          .replace(':standardId', `${row.id}`),
      );
      return;
    }
  };

  return (
    <div className="b-viewDebriefChartSlide">
      <img src={src} alt={alt || `Chart Slide ${type}`} />
      <div className="b-viewDebriefChartSlide__chart">
        <ChartBar isDebrief isCompare={false} chart={chart} rowClick={handleRowClick} />
      </div>
    </div>
  );
};

export default ChartSlide;
