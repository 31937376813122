import React, { useEffect, useState } from 'react';
import { BelovedAdminToolsTabs, Modal } from '../../../common';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import EditIc from '../../../../assets/images/icons/round-image-edit.svg';
import {
  belAtGetRoles,
  belAtSetCurrentRole,
  belAtUpdateRole,
  belAtCreateRole,
} from '../../../../store/beloved/adminTools/adminToolActions';
import { Button, Checkbox, Loader, Select, Input } from '../../../controls';
import { AdminToolPermissionModel, AdminToolRoleModel, ISelectOptions } from '../../../../models';
import { capitalize } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import { getUser } from '../../../../store/profile/profileActions';

interface IRoleForm {
  permissions: number[];
  isChanged: boolean;
}

const AdminToolRoles = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const roles = useSelector((state: StateType) => state.belAdminTool.roles);
  const permissions = useSelector((state: StateType) => state.belAdminTool.permissions);
  const currentRole = useSelector((state: StateType) => state.belAdminTool.currentRole);
  let [modalMode, setModalMode] = useState<'edit' | 'add' | null>(null);
  let [roleName, setRoleName] = useState('');
  const user = useSelector((state: StateType) => state.profile.authUser);
  let [roleForm, setRoleForm] = useState<IRoleForm>({
    permissions: [],
    isChanged: false,
  });

  useEffect(() => {
    dispatch(belAtGetRoles());
  }, []);

  useEffect(() => {
    if (currentRole) {
      setRoleForm(prevState => ({
        ...prevState,
        permissions: currentRole.permissions,
        isChanged: false,
      }));
    }
  }, [currentRole]);

  const handleChangeRole = (role: AdminToolRoleModel) => {
    if (role.value) {
      if (role.value === -1) {
        setModalMode('add');
        return null;
      }
      dispatch(belAtSetCurrentRole(role));
    }
  };

  const handleCheckPermission = (perm: ISelectOptions<number>) => {
    let newPermissions = [...roleForm.permissions];

    if (roleForm.permissions.includes(perm.value)) {
      newPermissions = roleForm.permissions.filter(id => id !== perm.value);
    } else {
      newPermissions.push(perm.value);
    }
    setRoleForm({ ...roleForm, isChanged: true, permissions: newPermissions });
  };

  const handleEditRoleName = (e: any) => {
    e.preventDefault();
    if (currentRole) {
      setModalMode('edit');
      setRoleName(currentRole.label);
    }
  };

  const updateRoleCb = () => {
    if (!currentRole) return;
    if (user?.beloved_user?.role_ids.find(id => id === currentRole.value)) {
      dispatch(getUser({}));
    }
  };

  const handleUpdateRole = () => {
    if (!currentRole) return;
    dispatch(
      belAtUpdateRole({
        roleId: currentRole.value,
        data: {
          permissions: roleForm.permissions,
        },
        callback: updateRoleCb,
      }),
    );
  };

  const handleSaveRole = () => {
    const cb = () => {
      setModalMode(null);
      setRoleName('');
    };

    if (modalMode === 'edit' && currentRole) {
      dispatch(
        belAtUpdateRole({
          roleId: currentRole.value,
          data: {
            label: roleName,
          },
          callback: cb,
        }),
      );
    } else {
      dispatch(
        belAtCreateRole({
          name: roleName,
          callback: cb,
        }),
      );
    }
  };

  const handleCancelModal = () => {
    setModalMode(null);
    setRoleName('');
  };

  const handleCancel = () => {
    if (currentRole && roleForm.isChanged) {
      setRoleForm({
        ...roleForm,
        isChanged: false,
        permissions: currentRole.permissions,
      });
    }
  };

  if (!roles || !permissions) return <Loader />;

  const options = [...roles, { value: 0, label: '---' }, { value: -1, label: 'Add New Role' }];

  return (
    <>
      <main className="b-page">
        <h1 className="b-page__title">{t('common:label.admin-tool')}</h1>
        <BelovedAdminToolsTabs activeItemAlias={'roles'} />
        <div className="b-adminToolRoles__top">
          <div className="b-adminToolRoles__roleName">
            <Select handleChange={handleChangeRole} value={currentRole} options={options} />
            <a
              href="#"
              onClick={handleEditRoleName}
              className="b-link-icon-left b-adminToolRoles__roleNameBtn"
            >
              <img src={EditIc} alt={t('common:alt.editIc')} />
              {t('common:label.Edit-Role-Name')}
            </a>
          </div>

          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              setModalMode('add');
            }}
            className="b-adminToolRoles__addBtn"
          >
            {t('common:label.New-Role')}
          </a>
        </div>
        <div className="b-adminToolRoles__permList">
          {permissions.map((perm: AdminToolPermissionModel, index: number) => {
            return (
              <div key={`perm-${perm.name}${index}`} className="b-panel b-adminToolRoles__perm">
                <div className="b-adminToolRoles__permName">{perm.name}</div>
                {perm.items.map(item => {
                  return (
                    <div key={`i-${item.value}`} className="b-adminToolRoles__permItem">
                      <span>{item.label}</span>
                      <Checkbox
                        noText
                        checked={roleForm.permissions.includes(item.value)}
                        onChange={() => handleCheckPermission(item)}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="b-modal__buttons">
          <Button
            className="button-mh"
            size={'large'}
            type={'transparency'}
            title={t('common:btn.cancel')}
            onPress={handleCancel}
          />
          <Button
            className="button-mh"
            disabled={!roleForm.isChanged}
            size={'large'}
            title={t('common:btn.save')}
            onPress={handleUpdateRole}
          />
        </div>
      </main>
      {modalMode && (
        <Modal
          className="b-adminToolRoles__newRoleModal"
          width={450}
          onClose={handleCancelModal}
          title={`${capitalize(modalMode)} Beloved Role`}
        >
          <div>
            <div className="b-adminToolRoles__newRoleInput">
              <Input
                type={'text'}
                handleChange={val => setRoleName(val)}
                placeholder={t('common:column.role')}
                value={roleName}
              />
            </div>

            <div className="b-modal__buttons">
              <Button
                className="button-mh"
                size={'large'}
                type={'transparency'}
                title={t('common:btn.cancel')}
                onPress={handleCancelModal}
              />
              <Button
                disabled={!roleName.length}
                className="button-mh"
                size={'large'}
                title={t('common:btn.save')}
                onPress={handleSaveRole}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AdminToolRoles;
