import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AvgAuditsTreeModel } from '../../../../../models';
const percent = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

interface IProps {
  isCompare: boolean;
  data: AvgAuditsTreeModel[];
}

const LineAveragesChart: FC<IProps> = ({ data, isCompare }) => {
  const { t } = useTranslation();
  const renderLines = (datas: AvgAuditsTreeModel[]) => {
    return datas.map(orgs => {
      return (
        <React.Fragment key={`avg-line-${orgs.id}`}>
          <div className={'b-customChart__doubleLine'}>
            <div
              style={{
                width: `${orgs.score === 'N/A' ? 0.5 : orgs.score}%`,
                left: `0%`,
              }}
              className={'b-customChart__score'}
            >
              <span className="b-customChart__score-val">{orgs.score === 'N/A' ? 0 : orgs.score}%</span>
            </div>
            <div
              style={{
                width: `${orgs.globalScore}%`,
                left: `${0}%`,
              }}
              className={`b-customChart__score -global ${isCompare ? '-isCompare' : ''}`}
            >
              <span className="b-customChart__score-val">{orgs.globalScore}%</span>
            </div>
          </div>
          {orgs.isOpen
            ? orgs.orgs_tree.map((org, i) => {
                return (
                  <div
                    key={`avg-org-lines-${org.ea_id}-${org.submit_date}-${i}`}
                    className={'b-customChart__doubleLine'}
                  >
                    <div
                      style={{
                        width: `${org.score === 'N/A' ? 0.5 : org.score}%`,
                        left: `${0}%`,
                      }}
                      className={'b-customChart__score'}
                    >
                      <span className="b-customChart__score-val">{org.score === 'N/A' ? 0 : org.score}%</span>
                    </div>
                    <div
                      style={{
                        width: `${org.globalScore}%`,
                        left: `${0}%`,
                      }}
                      className={`b-customChart__score -global ${isCompare ? '-isCompare' : ''}`}
                    >
                      <span className="b-customChart__score-val">{org.globalScore}%</span>
                    </div>
                  </div>
                );
              })
            : null}
        </React.Fragment>
      );
    });
  };

  return (
    <div className={'b-customChart__graph'}>
      <div className={'b-customChart__percentage'}>
        {percent.map((per, index) => {
          if (index === 0) {
            return (
              <span key={`per-number-${per}-${index}`} style={{ left: `calc(${index * 10}%)` }}>
                {t('common:column.n-a')}
              </span>
            );
          }
          return (
            <span key={`per-number-additional-${per}-${index}`} style={{ left: `calc(${index * 10}%)` }}>
              {per}%
            </span>
          );
        })}
      </div>
      <div className={'b-customChart__lines -heightAuto'}>{renderLines(data)}</div>
    </div>
  );
};

export default LineAveragesChart;
