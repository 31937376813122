import {
  OrganizationActionsTypes,
  GET_ORGANIZATION_DETAILS_SUCCESS,
  UPDATE_ORGANIZATION_DETAILS_SUCCESS,
  CREATE_PROFILE_SUB_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_USERS_SUCCESS,
  SET_DEFAULT_ORG_TAB,
  SET_QUERY,
  GET_ORGANIZATION_DETAILS,
} from './organizationActions';
import { KeyValueNumberModel, OrganizationDetailsModel, OrganizationUsersModel } from '../../models';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';
import { ROOTS } from '../../routes/routes';
import { QueryParamsModel } from '../../hooks/common/use-table-data';

export type OrganizationState = {
  currentOrg: OrganizationDetailsModel | null;
  currentOrgUsers: OrganizationUsersModel | null;
  defaultsOrgTab: KeyValueNumberModel<ROOTS[keyof ROOTS]>;
  query: QueryParamsModel;
  orgLoading: boolean;
};

const initialState: OrganizationState = {
  currentOrg: null,
  currentOrgUsers: null,
  defaultsOrgTab: {},
  query: {
    filters: {},
    sorting: null,
    search: '',
    page_size: 15,
    page: 1,
  },
  orgLoading: false,
};

const organizationReducer = (
  state: OrganizationState = initialState,
  action: OrganizationActionsTypes,
): OrganizationState => {
  switch (action.type) {
    case GET_ORGANIZATION_DETAILS: {
      return {
        ...state,
        orgLoading: true,
      };
    }
    case GET_ORGANIZATION_DETAILS_SUCCESS:
      return {
        ...state,
        orgLoading: false,
        currentOrg: action.payload,
      };
    case UPDATE_ORGANIZATION_DETAILS_SUCCESS:
      return state;
    case CREATE_PROFILE_SUB_ORGANIZATION_SUCCESS:
      return state;
    // case GET_ORGANIZATION_USERS: {
    //   return {
    //     ...state,
    //     query: action.payload.data,
    //   };
    // }
    case GET_ORGANIZATION_USERS_SUCCESS: {
      return {
        ...state,
        currentOrgUsers: action.payload as any,
      };
    }
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    case SET_DEFAULT_ORG_TAB: {
      return {
        ...state,
        defaultsOrgTab: {
          ...state.defaultsOrgTab,
          [action.payload.orgId]: action.payload.orgRoot,
        },
      };
    }
    case SET_QUERY: {
      return {
        ...state,
        query: action.payload,
      };
    }
    default:
      return state;
  }
};

export default organizationReducer;
