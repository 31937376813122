import React from 'react';
import { Footer, Header } from '../../../common';
import Ill from '../../../../assets/images/group@3x.png';
import { Button } from '../../../controls';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const NotFount = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div
      className="container-header position-absolute"
      style={{ top: 0, left: 0, right: 0, bottom: 0, background: 'var(--background)' }}
    >
      <Header type={'awa_text'} />
      <div className="d-flex flex-column h-100 justify-content-between align-items-center ">
        <div className="d-flex flex-column align-items-center">
          <img className="mt-5 mb-4" width={190} src={Ill} alt={t('awa:AT3')} />
          <div className="text-center font-l font-navy font-weight-bold mb-5">{t('awa:N1.title')}</div>
          <div className="text-center font-l font-navy font-weight-bold mb-5">{t('awa:N1.msg')}</div>
          <div>
            <Button
              size={'large'}
              onPress={() => history.push('to-the-home')}
              title={t('common:tabs.home')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFount;
