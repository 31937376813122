import React, { useEffect, useState } from 'react';
import OfficeAddressView from './OfficeAddressView';
import { getLatLnd, getLocation, validate } from '../../../../helpers';
import { useHistory } from 'react-router-dom';
import { IFields } from '../Profile/CreateProfileContainer';
import { countries } from '../../../../constants';
import { useTranslation } from 'react-i18next';

interface IProps {
  fields: IFields;
  onChange: (field: string) => (value: IFields) => void;
  onSubmit: (profile: IFields) => void;
}

export interface IAddressFields {
  country: string;
  state: string;
  city: string;
  postal: string;
  address: string;
  suite: string;
  office_type: string;
  lat?: number;
  lng?: number;
}

const initialFields: IAddressFields = {
  country: 'us',
  state: '',
  city: '',
  postal: '',
  address: '',
  suite: '',
  office_type: '',
};

export interface IErrors {
  country?: string;
  state?: string;
  city?: string;
  postal?: string;
  address?: string;
  suite?: string;
  office_type?: string;
}

const validatorCountry = {
  required: ['country'],
};

const validatorState = {
  required: ['state'],
};

const validatorAddress = {
  required: ['address'],
};

const validatorCity = {
  required: ['city'],
};

const validatorPostal = {
  required: ['postal'],
};

const OfficeAddressContainer = ({ fields, onSubmit }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState('1');
  const [addressFields, setAddressFields] = useState<IAddressFields>(initialFields);
  const [error, setErrors] = useState<any>({});

  const isInternation = !(addressFields.country && addressFields.country.toLowerCase() === 'us');
  const validator = {
    required: ['address', 'country', 'state', 'city', 'postal'],
    requiredFieldName: {
      postal: isInternation ? t('common:label.international.postal') : t('common:label.us.postal'),
      state: isInternation ? t('common:label.international.state') : t('common:label.us.state'),
      city: isInternation ? t('common:label.international.city') : t('common:label.us.city'),
    },
  };

  useEffect(() => {
    setAddressFields({
      ...addressFields,
      ...(fields.office_address || {}),
    });
  }, [fields]);

  const handleBackNavigation = () => {
    history.go(-1);
  };
  const handleSubmit = async () => {
    let { errors } = validate(validator, addressFields);
    setErrors(errors);
    if (!Object.keys(error).length) {
      const office_address = { ...addressFields };
      if (selectedOption === '3') {
        const results = await getLatLnd({ city: addressFields.city });
        if (results) {
          const { lat, lng } = results;
          office_address.lat = lat;
          office_address.lng = lng;
        }
      }
      onSubmit({ ...fields, office_type: selectedOption, office_address });
    }
  };

  const handleOnBlur = (field: string) => () => {
    let errorCase;
    switch (field) {
      case 'country':
        errorCase = validate(validatorCountry, addressFields);
        break;
      case 'address':
        errorCase = validate(validatorAddress, addressFields);
        break;
      case 'state':
        errorCase = validate(validatorState, addressFields);
        break;
      case 'city':
        errorCase = validate(validatorCity, addressFields);
        break;
      case 'postal':
        errorCase = validate(validatorPostal, addressFields);
        break;
      default:
        errorCase = { errors: error };
        break;
    }
    setErrors(errorCase.errors);
  };
  const handleOnChange = (field: string) => (value: string | { label: string; value: string }) => {
    let val;
    if (typeof value == 'object') {
      val = value.value;
    } else {
      val = value;
    }
    setAddressFields({
      ...addressFields,
      [field]: val,
    });
  };

  const locationChangeHandler = async (place: any, ref: any) => {
    const address_details = await getLocation(place, ref);
    setAddressFields({ ...addressFields, ...address_details });
  };

  const onSelectOffice = (option: string) => {
    if (option === '1' && fields) {
      setAddressFields({
        ...initialFields,
        ...(fields.office_address as any),
      });
    } else {
      setAddressFields(initialFields);
    }
    setErrors({});
    setSelectedOption(option);
  };

  return (
    <OfficeAddressView
      handleOnBlur={handleOnBlur}
      fields={addressFields}
      onChange={handleOnChange}
      onSelectOffice={onSelectOffice}
      officeType={selectedOption}
      onHandleChange={locationChangeHandler}
      errors={error}
      countries={countries}
      onSubmit={handleSubmit}
      isInternation={isInternation}
      onBackNavigation={handleBackNavigation}
    />
  );
};

export default OfficeAddressContainer;
