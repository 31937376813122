import React from 'react';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import styles from './HomeOrg.module.scss';
import Logo from '../../../../assets/images/group.png';
import { NewsBlock } from '../../../common';
import { useTranslation } from 'react-i18next';

const HomeOrgContainer = () => {
  const { t } = useTranslation();
  const user = useSelector((state: StateType) => state.profile.authUser);

  if (user) {
    return (
      <>
        <main className={'pageContainer pageContainer__content'}>
          <h1 className={'pageContainer__title'}>
            {t('awa:M1.title')}
            <span className={styles.userName}>{user.user.first_name}</span>!
          </h1>
          <div className={styles.homeContent}>
            <div className={styles.homeContent_wrapper}>
              <h2 className={styles.homeContent_smallTitle}>
                {t('main:home.msg.p1')} <br />
                {t('main:home.msg.p2')} <span className={'-orange'}>{t('main:home.msg.p3')}</span>.{' '}
                <span className={'-orange'}>{t('main:home.msg.p4')}</span>.{' '}
                <span className={'-orange'}>{t('main:home.msg.p5')}</span>.
              </h2>
              <img src={Logo} className={styles.homeContent_ill} alt={t('awa:AT3')} />
              <div className={styles.homeContent_text}>
                <div className={styles.homeContent_p}>
                  {user.home_page_msg?.map((msg: string, i) => {
                    return (
                      <div key={`home-smg-${i}`} className={styles.msg}>
                        {msg}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <NewsBlock />
        </main>
      </>
    );
  }
  return null;
};

export default HomeOrgContainer;
