import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../store/reducers';
import { useEffect } from 'react';
import { belGetResourceOptions } from '../../store/beloved/resources/resourcesActions';

export const useGetResourceOptions = () => {
  const options = useSelector((state: StateType) => state.belResource.options);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!options) {
      dispatch(belGetResourceOptions({}));
    }
  }, []);

  return options;
};
