import {
  OrganizationRoleTreeModel,
  AuthUserModel,
  UserTypeRoleModel,
  ISelectOptions,
  UserRolesModel,
} from '../models';

export const getOrgsFamily = (currentOrgId: number, user: AuthUserModel): OrganizationRoleTreeModel[] => {
  let relatives: OrganizationRoleTreeModel[] = [];
  let stop: boolean = false;

  const dive = (orgs: OrganizationRoleTreeModel[]) => {
    for (let i = 0; i < orgs.length; i++) {
      if (stop) {
        break;
      }
      if (orgs[i].org_id === currentOrgId) {
        relatives.push(orgs[i]);
        stop = true;
        break;
      }
      if (orgs[i].sub_orgs.length) {
        relatives.push(orgs[i]);
        dive(orgs[i].sub_orgs);
      } else {
        if (i === orgs.length - 1) {
          relatives.pop();
        }
      }
    }
  };
  if (user.orgs_tree) {
    dive(user.orgs_tree);
  }
  if (relatives.length > 3) {
    relatives = relatives.slice(relatives.length - 3, relatives.length);
  }
  return relatives.reverse();
};

export const getUserRoleList = (
  userTypesRoles?: UserTypeRoleModel[],
  type_id?: number | null,
): ISelectOptions[] => {
  if (type_id && userTypesRoles) {
    const target = userTypesRoles.find(f => f.id === type_id);
    if (target) {
      return target.groups.map((r: UserRolesModel) => {
        return {
          label: r.name,
          value: r.id,
          description: r.description || 'Some description',
        };
      });
    }
    return [];
  }
  return [];
};
