import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  CHANGE_PASSWORD,
  CREATE_ACCOUNT_REQUEST,
  FORGOT_PASSWORD,
  isLoginAction,
  LOGIN,
  LOGOUT,
  RESENT_EMAIL_CONFIRM,
  VERIFIED_ACCOUNT_REQUEST,
  setRedirectLoginUrl,
  CreateAccountPayload,
  logoutSuccess,
  SET_SEEN_DECLINE_POPUP,
  LoginPayload,
  ResendConfirmEmailPayload,
  ChangePasswordPayload,
  CHECK_FORGOT_PASS_ACTIVE,
  CheckForgotPassActivePayload,
  LogoutPayload,
} from './authDataActions';
import Api from '../../services/api';
import { OAUTH_CREDS } from '../../constants';
import { getUserSuccess } from '../profile/profileActions';
import { ActionModel } from '../../models';
import { getAppOptions } from '../utils/utilsActions';

function* loginSaga({ payload }: ActionModel<typeof LOGIN, LoginPayload>): Generator<any> {
  const formData = new FormData();
  formData.append('username', payload.data.username);
  formData.append('password', payload.data.password);
  formData.append('grant_type', OAUTH_CREDS.GRANT_TYPES.PASSWORD);
  formData.append('client_id', OAUTH_CREDS.CLIENT_ID);
  formData.append('client_secret', OAUTH_CREDS.CLIENT_SECRET);
  try {
    const response: any = yield call(Api.post, 'auth/token/', formData);
    if (response) {
      yield put(getAppOptions());
      response.access_token && localStorage.setItem('token', response.access_token);
      response.refresh_token && localStorage.setItem('refresh_token', response.refresh_token);
      if (response.next_step !== 1) {
        localStorage.setItem('next_step', response.next_step);
      }
      yield put(getUserSuccess({ ...response, ...{ next_step: response.next_step } }));
      if (response.login) {
        yield put(isLoginAction({ data: { isAuthenticated: response.login } }));
      }
      payload.callback(response);
      const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
      yield delay(3000);
      yield put(setRedirectLoginUrl(null));
    }
  } catch (e) {
    payload.callback({ error: e });
  }
}

function* logoutUserSaga({ payload }: ActionModel<typeof LOGOUT, LogoutPayload>): Generator<any> {
  const formData = new FormData();
  let token = localStorage.getItem('token');
  formData.append('token', token as string);
  formData.append('client_id', OAUTH_CREDS.CLIENT_ID);
  formData.append('client_secret', OAUTH_CREDS.CLIENT_SECRET);
  try {
    yield call(Api.post, 'auth/revoke_token/', formData);
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('authUser');
    localStorage.removeItem('next_step');
    yield put(logoutSuccess());
    payload.callback && payload.callback();
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
    yield delay(500);
    yield put(setRedirectLoginUrl(null));
  } catch (e) {
    console.log('message', e);
  }
}

function* verificationAccountSaga({ payload }: any): any {
  const formData = new FormData();
  formData.append('hash', payload.data.hash);
  formData.append('grant_type', OAUTH_CREDS.GRANT_TYPES.PASSWORD);
  formData.append('client_id', OAUTH_CREDS.CLIENT_ID);
  formData.append('client_secret', OAUTH_CREDS.CLIENT_SECRET);
  try {
    const response = yield call(Api.put, 'entry/email_verification/', formData);
    payload.callback(response);
  } catch (e) {
    console.log('error->', e);
  }
}

function* createAccountSaga({
  payload,
}: ActionModel<typeof CREATE_ACCOUNT_REQUEST, CreateAccountPayload>): Generator<any> {
  const formData = new FormData();
  formData.append('username', payload.data.username);
  formData.append('password', payload.data.password);
  formData.append('first_name', payload.data.first_name);
  formData.append('last_name', payload.data.last_name);
  formData.append('grant_type', OAUTH_CREDS.GRANT_TYPES.PASSWORD);
  formData.append('client_id', OAUTH_CREDS.CLIENT_ID);
  formData.append('client_secret', OAUTH_CREDS.CLIENT_SECRET);
  try {
    let response: any = yield call(Api.post, 'entry/user/', formData);
    payload.callback(response);
  } catch (e) {
    payload.callback({ error: e });
  }
}

function* setSeenJoinPopup(): Generator<any> {
  try {
    yield call(Api.post, 'entry/user/see_join_popup/', {});
  } catch (e) {
    console.log('e', e);
  }
}

function* resentEmailConfirmAccountSaga({
  payload,
}: ActionModel<typeof RESENT_EMAIL_CONFIRM, ResendConfirmEmailPayload>): Generator<any> {
  const formData = new FormData();
  payload.data.username && formData.append('email', payload.data.username);
  payload.data.hash && formData.append('hash', payload.data.hash);
  formData.append('grant_type', OAUTH_CREDS.GRANT_TYPES.PASSWORD);
  formData.append('client_id', OAUTH_CREDS.CLIENT_ID);
  formData.append('client_secret', OAUTH_CREDS.CLIENT_SECRET);
  try {
    let response: any = yield call(Api.post, 'entry/email_verification/', formData);
    payload.callback(response);
  } catch (e) {
    payload.callback({ error: e });
  }
}

function* forgotPasswordAccountSaga({
  payload,
}: ActionModel<typeof FORGOT_PASSWORD, ResendConfirmEmailPayload>): Generator<any> {
  const formData = new FormData();
  formData.append('email', payload.data.username);
  formData.append('grant_type', OAUTH_CREDS.GRANT_TYPES.PASSWORD);
  formData.append('client_id', OAUTH_CREDS.CLIENT_ID);
  formData.append('client_secret', OAUTH_CREDS.CLIENT_SECRET);
  try {
    let response: any = yield call(Api.post, 'entry/forgot_password/', formData);
    payload.callback(response);
  } catch (e) {
    // payload.callback({error: e});
    console.log('e', e);
  }
}

function* changePasswordAccountSaga({
  payload,
}: ActionModel<typeof CHANGE_PASSWORD, ChangePasswordPayload>): Generator<any> {
  const formData = new FormData();
  formData.append('hash', payload.data.hash);
  formData.append('password', payload.data.password);
  formData.append('grant_type', OAUTH_CREDS.GRANT_TYPES.PASSWORD);
  formData.append('client_id', OAUTH_CREDS.CLIENT_ID);
  formData.append('client_secret', OAUTH_CREDS.CLIENT_SECRET);
  try {
    let response: any = yield call(Api.put, 'entry/forgot_password/', formData);
    payload.callback(response);
  } catch (e: any) {
    payload.callback({ result: e.response.data.result });
  }
}

function* checkForgotPassActiveSaga({
  payload,
}: ActionModel<typeof CHECK_FORGOT_PASS_ACTIVE, CheckForgotPassActivePayload>): Generator<any> {
  try {
    const { data } = payload;
    let response = yield call(Api.get, `entry/forgot_password/${data.hash}`);
    payload.callback(response);
  } catch (e: any) {
    payload.callback(null, { result: e.response.data.result });
  }
}

export function* saga() {
  yield all([takeLatest(LOGIN, loginSaga)]);
  yield all([takeLatest(VERIFIED_ACCOUNT_REQUEST, verificationAccountSaga)]);
  yield all([takeLatest(CREATE_ACCOUNT_REQUEST, createAccountSaga)]);
  yield all([takeLatest(CHECK_FORGOT_PASS_ACTIVE, checkForgotPassActiveSaga)]);
  yield all([takeLatest(RESENT_EMAIL_CONFIRM, resentEmailConfirmAccountSaga)]);
  yield all([takeLatest(FORGOT_PASSWORD, forgotPasswordAccountSaga)]);
  yield all([takeLatest(CHANGE_PASSWORD, changePasswordAccountSaga)]);
  yield all([takeLatest(SET_SEEN_DECLINE_POPUP, setSeenJoinPopup)]);
  yield all([takeLatest(LOGOUT, logoutUserSaga)]);
}
