import React, { FC, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { useTranslation } from 'react-i18next';
import { Link } from '../../controls';
import DatePicker from 'react-datepicker';
import {
  basicDateFormat,
  convertDateToIsoWithTzOffset,
  getBasicDateFormatCodeForDatePicker,
} from '../../../helpers';
import { FilterByDatePayloadModel } from '../../../models';
import i18next from 'i18next';

interface IProps {
  onSubmit: (payload: FilterByDatePayloadModel) => void;
}

interface IState {
  from_date: Date | null;
  to_date: Date | null;
  selectedOption: string | null;
}

const DateFilter: FC<IProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const dateFilterOptions = [
    {
      value: 'this_month',
      label: i18next.t('common:label.this-month'),
    },
    {
      value: 'last_month',
      label: i18next.t('common:label.last-month'),
    },
    {
      value: 'this_fiscal_year',
      label: i18next.t('common:label.this-Fiscal-year'),
    },
    {
      value: 'last_fiscal_year',
      label: i18next.t('common:label.last-Fiscal-year'),
    },
  ];

  const initialForm = {
    from_date: null,
    to_date: null,
    selectedOption: null,
  };

  const [savedForm, setSavedForm] = useState<IState>(initialForm);
  const [draftForm, setDraftForm] = useState<IState>(initialForm);

  const [show, setShow] = useState(false);
  const [calendarOpened, setCalendarOpened] = useState(false);

  const getLabel = (): string => {
    if (savedForm.selectedOption) {
      return dateFilterOptions.find(item => item.value === savedForm.selectedOption)?.label || '';
    }
    if (savedForm.from_date && savedForm.to_date) {
      return `${basicDateFormat(savedForm.from_date)} - ${basicDateFormat(savedForm.to_date)}`;
    }
    if (savedForm.from_date) {
      return `${t('common:label.after')}: ${basicDateFormat(savedForm.from_date)}`;
    }
    if (savedForm.to_date) {
      return `${t('common:label.before')}: ${basicDateFormat(savedForm.to_date)}`;
    }
    return t('common:btn.filter-by-date');
  };

  const onClickOutsidePopover = () => {
    if (calendarOpened) {
      return;
    }
    setShow(false);
  };

  const handleCalendarOpen = () => {
    setTimeout(() => {
      setCalendarOpened(true);
    }, 100);
  };

  const handleCalendarClose = () => {
    setTimeout(() => {
      setCalendarOpened(false);
    }, 100);
  };

  const handleChangeDate = (newDate: Date | null, fieldName: string) => {
    console.log('newDate', newDate);
    setDraftForm({ ...draftForm, [fieldName]: newDate, selectedOption: null });
  };

  const handleChangeOption = (opt: string) => {
    setDraftForm(prevState => {
      return {
        ...prevState,
        to_date: null,
        from_date: null,
        selectedOption: prevState.selectedOption === opt ? null : opt,
      };
    });
  };

  const handleCancel = () => {
    setDraftForm(savedForm);
    setShow(false);
  };

  const handleSave = () => {
    onSubmit({
      from_date: draftForm.from_date,
      to_date: draftForm.to_date,
      period: draftForm.selectedOption || '',
      date_range: [
        draftForm.from_date ? convertDateToIsoWithTzOffset(draftForm.from_date) : '',
        draftForm.to_date ? convertDateToIsoWithTzOffset(draftForm.to_date) : '',
      ].join(','),
    });
    setSavedForm(draftForm);
    setShow(false);
  };

  const renderContent = () => {
    return (
      <div className="b-panel b-tableDateFilter__modal">
        {dateFilterOptions.map(item => {
          return (
            <Link
              onClick={() => handleChangeOption(item.value)}
              key={`${item.value}-table-filter-opt`}
              className={`b-tableDateFilter__opt ${item.value === draftForm.selectedOption ? '-active' : ''}`}
            >
              {item.label}
            </Link>
          );
        })}
        <div className="b-tableDateFilter__row d-flex justify-content-between">
          <div className="d-flex w-50">
            <div className="b-tableDateFilter__rowTitle">{t('common:label.after')}:</div>
            {draftForm.from_date ? (
              <button
                className="b-button-icon -close"
                onClick={e => {
                  e.stopPropagation();
                  handleChangeDate(null, 'from_date');
                }}
              />
            ) : null}
            <div className="b-tableDateFilter__date">
              <DatePicker
                onCalendarOpen={handleCalendarOpen}
                maxDate={draftForm.to_date}
                onCalendarClose={handleCalendarClose}
                dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                selected={draftForm.from_date}
                onChange={newDate => handleChangeDate(newDate, 'from_date')}
                placeholderText={'MM.DD.YYYY'}
                ariaLabelledBy="from_date"
              />
            </div>
          </div>
          <div className="d-flex w-50">
            <div className="b-tableDateFilter__rowTitle">{t('common:label.before')}:</div>
            {draftForm.to_date ? (
              <button
                className="b-button-icon -close"
                onClick={e => {
                  e.stopPropagation();
                  handleChangeDate(null, 'to_date');
                }}
              />
            ) : null}
            <div className="b-tableDateFilter__date">
              <DatePicker
                onCalendarOpen={handleCalendarOpen}
                onCalendarClose={handleCalendarClose}
                minDate={draftForm.from_date}
                dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                selected={draftForm.to_date}
                onChange={newDate => handleChangeDate(newDate, 'to_date')}
                placeholderText={'MM.DD.YYYY'}
                ariaLabelledBy="to_date"
              />
            </div>
          </div>
        </div>
        <div className="mt-2 mb-2 d-flex justify-content-center align-items-center">
          <Link onClick={handleCancel} className="orange-light-text pr-5">
            {t('common:btn.cancel')}
          </Link>
          <Link onClick={handleSave} className="orange-light-text">
            {t('common:btn.save')}
          </Link>
        </div>
      </div>
    );
  };

  return (
    <Popover
      isOpen={show}
      positions={['bottom']}
      content={renderContent}
      containerClassName={'popover'}
      align={'end'}
      onClickOutside={onClickOutsidePopover}
    >
      <button type="button" className="btn-datepicker" onClick={() => setShow(!show)}>
        {getLabel()}
      </button>
    </Popover>
  );
};

export default DateFilter;
