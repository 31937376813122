import React from 'react';
import { Button, Image } from '../../../controls';
import styles from './StartSignUpStyle.module.scss';
import { Footer, Header } from '../../../common';
import Logo from '../../../../assets/images/icons/logo-awa.svg';
import { useTranslation } from 'react-i18next';

interface IProps {
  loader?: string;
  onSubmit: () => void;
}

const StartSignUpView = ({ loader, onSubmit }: IProps) => {
  const { t } = useTranslation();
  return (
    <>
      {loader}
      <div className={'container container-header white-container'}>
        <Header />
        <main className={'white-container content-center-wrapper'}>
          <div className={'container-page-center'}>
            <div className={'container-row'}>
              <Image width={'84px'} height={'84px'} source={Logo} alt={t('awa:AT9')} />
            </div>
            <div className={'container-row'}>
              <h1 className={`b-title blue-text ${styles.text_spacing}`}>{t('auth:welcome')}</h1>
            </div>
            <div className={'text-container container-row'}></div>
            <div className={'text-container container-row'}>
              <p className={`text-long-description gray-text ${styles.text_line_height}`}>
                {t('awa:M1.msg.p1')}
              </p>
            </div>
            <div className={'text-container container-column '}>
              <span className={'text-description blue-text text-center'}>{t('awa:M1.msg.p2')}</span>
              <a className={'links bold'}> {t('awa:M1.msg.p3')}</a>
            </div>
            <div className={'container-row'}>
              <Button
                size={'small'}
                onPress={onSubmit}
                title={t('common:btn.next')}
                type={'orange-light'}
                enableEnter
                disabled={false}
              />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default StartSignUpView;
