import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import {
  BelResourceShortModel,
  IRouteParamsBase,
  LinkResourceEntityType,
  TableDataEntities,
} from '../../../../../models';
import { Button, Loader } from '../../../../controls';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { getColumns } from '../../../Beloved/Cohort/BelResourceLibrary/parts/columns';
import DataGrid from 'react-data-grid';
import { Pagination } from '../../../../common';
import useTableData from '../../../../../hooks/common/use-table-data';
import { useIsDarkMode } from '../../../../../hooks/common/use-is-dark-mode';
import { useNavToViewResource } from '../../../../../hooks/resources/use-nav-to-view-resource';
import {
  GetLinkResourceListPayload,
  setSelectedLinkResource,
} from '../../../../../store/beloved/resources/resourcesActions';
import { useDispatch } from 'react-redux';
import useTableConfig from '../../../../../hooks/common/use-table-config';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

interface IRouteParams extends IRouteParamsBase {
  entity: LinkResourceEntityType;
  entityId: string;
}

const LinkResourceList = () => {
  const { t } = useTranslation();
  const { entity, entityId } = useParams<IRouteParams>();
  const { navBack } = useNavBack();
  const { isDarkMode } = useIsDarkMode();
  const history = useHistory();
  const navToView = useNavToViewResource();
  const dispatch = useDispatch();

  const {
    data,
    query,
    fetchData,
    handleChangePage,
    handleChangePageSize,
    handleSearch,
    handleSelectOne,
    selectedEntitiesIds,
    handleSort,
  } = useTableData<GetLinkResourceListPayload['extraData']>(TableDataEntities.LINK_RESOURCE_LIST, {
    entity,
    entityId: Number(entityId),
  });

  useEffect(() => {
    fetchData(query);
  }, []);

  const handleLinkResource = () => {
    if (!data) return;
    const res = data.result
      .filter(item => {
        return selectedEntitiesIds.includes(item.id);
      })
      .map(item => ({
        id: item.id,
        title: item.title,
        referenced_link: item.referenced_link,
      }));
    dispatch(setSelectedLinkResource(res));
    history.go(-1);
  };

  const onCellClick = (fieldName: string, data: BelResourceShortModel) => {
    if (fieldName === 'navToView') {
      navToView(data);
    }
  };

  const { columns, onColumnResize } = useTableConfig<BelResourceShortModel>(
    TableDataEntities.LINK_RESOURCE_LIST,
    getColumns({ onCellClick }),
  );

  const renderContent = () => {
    if (!data) return <Loader fitParent />;

    return (
      <div>
        <DndProvider backend={HTML5Backend}>
          <DataGrid
            onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
            columns={columns}
            onColumnResize={onColumnResize}
            sortColumns={query.sorting ? [query.sorting] : []}
            rows={data.result}
            onSelectedRowsChange={handleSelectOne}
            className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
            style={{
              height: ((data.result.length || 0) + 1) * 60,
            }}
            rowHeight={60}
            selectedRows={new Set(selectedEntitiesIds)}
            rowKeyGetter={(row: BelResourceShortModel) => row.id}
          />
        </DndProvider>
        <Pagination data={data} handleChangePageSize={handleChangePageSize} changePage={handleChangePage} />
      </div>
    );
  };

  return (
    <main className="b-page">
      <h1 className="b-page__title">{t('common:tabs.resource-library')}</h1>
      <div className="b-page__controls">
        <div className="b-page__search">
          <form action="#" className="searchForm">
            <input
              type="search"
              value={query.search}
              onChange={e => handleSearch(e.target.value)}
              placeholder={t('common:label.search')}
              aria-label="Search field"
            />
            <span className="searchForm__icon" />
          </form>
        </div>
        <div className="b-tableToolContainer">
          <div className="b-modal__buttons">
            <Button onPress={navBack} title={t('common:btn.cancel')} size={'large'} type={'transparency'} />
            <Button
              width={'auto'}
              onPress={handleLinkResource}
              title={t('common:btn.assign')}
              size={'large'}
              disabled={!selectedEntitiesIds.length}
              type={'orange-light'}
            />
          </div>
        </div>
      </div>
      {renderContent()}
    </main>
  );
};

export default LinkResourceList;
