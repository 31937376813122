import React, { useEffect, useState } from 'react';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import GoogleCalendarIc from '../../../assets/images/icons/google_calendar_icon.svg';
import SyncIc from '../../../assets/images/icons/icons-sync-enabled.svg';
import DeSyncIc from '../../../assets/images/icons/icons-sync-disabled.svg';
import { Button, Loader, Select } from '../../controls';
import { Modal, PopupPortal } from '../index';
import { GoogleCalendarsModel, ISelectOptions, KeyValueModel } from '../../../models';
import { showAlert } from '../Alert/RemoteAlert';
import { toast } from 'react-toastify';
import Api from '../../../services/api';
import { getUser } from '../../../store/profile/profileActions';
import { useDispatch, useSelector } from 'react-redux';
import EditIc from '../../../assets/images/icons/round-image-edit.svg';
import { StateType } from '../../../store/reducers';
import {
  getCalendarListFetch,
  getUserInfoFetch,
  insertAccessToCalendar,
  removeRuleIds,
} from './parts/googleApiHelpers';

const SCOPES = process.env.REACT_APP_GOOGLE_SCOPES as string;

interface GoogleUserInfoModel {
  email: string;
  email_verified: boolean;
  given_name: string;
  locale: string;
  name: string;
  picture: string;
  sub: string;
}

const GoogleLinkCalendar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: StateType) => state.profile.authUser?.user);
  const userSettings = user?.settings;

  const [tokenClient, setTokenClient] = useState<TokenResponse | null>(null);
  const [googleUserInfo, setGoogleUserInfo] = useState<GoogleUserInfoModel | null>(null);
  const [googleCalendarList, setGoogleCalendarList] = useState<any>(null);
  const [isOpenGoogleCalendars, setIsOpenGoogleCalendars] = useState<boolean>(true);
  const [selectedCalendars, setSelectedCalendars] = useState<GoogleCalendarsModel>({
    busy: [],
    events: [],
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<KeyValueModel<string>>({});

  useEffect(() => {
    if (userSettings) {
      setSelectedCalendars({
        busy: userSettings.google_calendars_busy || [],
        events: userSettings.google_calendars_events || [],
      });
    }
    if (userSettings?.google_user_info) {
      setTokenClient({
        ...(tokenClient || {}),
        access_token: userSettings.google_user_info.access_token,
        expires_in: userSettings.google_user_info.expires_in,
      } as TokenResponse);
    }
  }, [userSettings]);

  if (!userSettings) {
    return null;
  }

  const onOAuth2Login = useGoogleLogin({
    scope: SCOPES,
    onSuccess: tokenResponse => onSuccess(tokenResponse),
    onError: err => toast.error(err.error_description),
    hint: userSettings.google_user_info?.email || undefined,
  });

  const onOAuth2Logout = useGoogleLogin({
    scope: SCOPES,
    onSuccess: tokenResponse => onLogout(tokenResponse),
    onError: err => toast.error(err.error_description),
    hint: userSettings.google_user_info?.email || undefined,
  });

  const isExpiredGoogleToken = () => {
    return (
      userSettings?.google_user_info?.expires_in && userSettings?.google_user_info?.expires_in < Date.now()
    );
  };

  const onLogout = async (tokenResponse: TokenResponse) => {
    const body = {
      google_calendars_busy: null,
      google_calendars_events: null,
      google_user_info: null,
    };
    if (!userSettings.google_user_info?.access_token) {
      return;
    }
    setLoading(true);

    await removeRuleIds(tokenResponse.access_token, selectedCalendars);
    const response = await Api.post(`entry/user/user_settings/`, body);
    if (response) {
      dispatch(getUser({}));
      toast.success(t('common:toast.calendar-unlinked'));
    }
    setSelectedCalendars({
      busy: [],
      events: [],
    });
    setErrors({});
    setGoogleCalendarList([]);
    setTokenClient(null);
    setLoading(false);
  };

  const onSuccess = async (tokenResponse: TokenResponse) => {
    const token = {
      ...tokenResponse,
      expires_in: Date.now() + tokenResponse.expires_in * 1000,
    };
    setTokenClient(token);
    setLoading(true);
    const googleInfo = await getUserInfoFetch(tokenResponse.access_token);
    if (userSettings.google_user_info?.email && userSettings.google_user_info?.email !== googleInfo.email) {
      setSelectedCalendars({
        busy: [],
        events: [],
      });
    }
    setGoogleUserInfo(googleInfo);
    const calendarList = await getCalendarListFetch(tokenResponse.access_token);
    setGoogleCalendarList(calendarList);
    setIsOpenGoogleCalendars(true);
    setLoading(false);
  };

  const handleLogoutFromOAuth2 = async () => {
    if (userSettings.google_user_info?.access_token && !isExpiredGoogleToken()) {
      await onLogout(tokenClient as TokenResponse);
    } else {
      onOAuth2Logout();
    }
  };

  const handleGoogleUnlink = () => {
    showAlert({
      title: t('awa:N85.title'),
      text: (
        <>
          <p>{t('awa:N85.msg')}</p>
        </>
      ),
      buttons: {
        left: {
          title: t('common:btn.cancel'),
          type: 'transparency',
        },
        right: {
          title: t('common:btn.disconnect'),
          onClick: () => handleLogoutFromOAuth2(),
        },
      },
    });
  };

  const handleCloseModal = () => {
    setErrors({});
    setIsOpenGoogleCalendars(false);
  };

  const handleSaveCalendars = async () => {
    if (!selectedCalendars?.busy.length || !selectedCalendars?.events.length) {
      setErrors({
        calendar: t('common:errors.google-calendar'),
      });
      return;
    }
    if (!tokenClient) {
      return;
    }

    if (isExpiredGoogleToken()) {
      onOAuth2Login();
      return;
    }
    try {
      setLoading(true);
      const responses = await insertAccessToCalendar(tokenClient.access_token, selectedCalendars);
      const fetchError = Array.isArray(responses) && responses.find(f => f.error);
      if (fetchError) {
        setLoading(false);
        setErrors({ calendar: fetchError?.error?.message || t('common:toast.error') });
        return;
      }

      const body = {
        google_user_info: {
          email: googleUserInfo?.email,
          name: googleUserInfo?.name,
          access_token: tokenClient.access_token,
          expires_in: tokenClient.expires_in,
        },
        google_calendars_busy: selectedCalendars.busy,
        google_calendars_events: selectedCalendars.events,
      };

      const response = await Api.post(`entry/user/user_settings/`, body);
      if (response) {
        toast.success(t('common:toast.calendar-linked'));
        setIsOpenGoogleCalendars(false);
      }
      dispatch(getUser({}));
      setErrors({});
      setLoading(false);
    } catch (err) {
      toast.error(t('common:toast.error'));
      setLoading(false);
      return;
    }
  };

  const handleChangeSelectedCalendars = (value: ISelectOptions[]) => {
    setErrors({});

    setSelectedCalendars({
      ...selectedCalendars,
      busy: value,
    });
  };

  const handleChangeSelectedEventCalendars = (value: ISelectOptions) => {
    setErrors({});

    setSelectedCalendars({
      ...selectedCalendars,
      events: [value],
    });
  };

  const handleEditCalendar = async () => {
    if (!userSettings.google_user_info) {
      return;
    }
    if (isExpiredGoogleToken()) {
      onOAuth2Login();
      return;
    }
    if (!googleCalendarList) {
      setLoading(true);
      const calendarList = await getCalendarListFetch(userSettings.google_user_info.access_token);
      const fetchError = Array.isArray(calendarList) && calendarList.find((f: any) => f.error);
      if (fetchError) {
        setLoading(false);
        toast.error({ calendar: fetchError?.error?.message || t('common:toast.error') });
        return;
      }
      setGoogleCalendarList(calendarList);
      setIsOpenGoogleCalendars(true);
      setLoading(false);
    }
    setIsOpenGoogleCalendars(true);
  };

  const renderSelectModal = () => {
    if (!googleCalendarList?.items || !isOpenGoogleCalendars) {
      return;
    }
    const options: ISelectOptions[] = googleCalendarList.items
      .filter((f: KeyValueModel<string>) => f.accessRole === 'owner')
      .map((calendar: KeyValueModel<string>) => ({
        label: calendar.summary,
        value: calendar.id,
      }));

    return (
      <PopupPortal portalElementId={'user-google-calendars'}>
        <Modal
          minHeight={300}
          width={400}
          title={t('common:headlines.google-calendars')}
          onClose={handleCloseModal}
        >
          <div className={`b-modal`}>
            <Select
              wrapperStyles={{
                width: '350px',
              }}
              isMulti
              placeholder={t('common:label.availability-calendars')}
              handleChange={value => handleChangeSelectedCalendars(value)}
              value={selectedCalendars?.busy}
              options={options}
            />
            <Select
              wrapperStyles={{
                width: '350px',
                height: '60px',
              }}
              placeholder={t('common:label.awa-events-calendar')}
              handleChange={value => handleChangeSelectedEventCalendars(value)}
              value={selectedCalendars?.events}
              options={options}
            />
            <div className={'error-notification'}>
              {errors.calendar ? <span className={'error-notification-text'}>{errors.calendar}</span> : null}
            </div>
            <div className="b-modal__buttons">
              <Button
                onPress={handleCloseModal}
                title={t('common:btn.cancel')}
                size={'large'}
                type={'transparency'}
              />
              <Button
                onPress={handleSaveCalendars}
                title={t('common:btn.save')}
                size={'large'}
                type={'primary'}
              />
            </div>
          </div>
        </Modal>
      </PopupPortal>
    );
  };

  return (
    <div className="b-formAccount__googleAuth">
      {loading ? (
        <PopupPortal portalElementId={'settings-loading'}>
          <Loader fitParent />
        </PopupPortal>
      ) : null}
      <a
        href="#"
        className="d-flex align-items-center"
        onClick={userSettings.google_user_info ? handleGoogleUnlink : () => onOAuth2Login()}
      >
        <img
          src={GoogleCalendarIc}
          alt={t('common:alt.googleCalendar')}
          className={'b-formAccount__googleCalendarIcon mr-2'}
        />
        <span className="b-formAccount__text">
          {userSettings.google_user_info?.email || t('common:calendar.google-calendar')}
        </span>
        <img
          src={userSettings.google_user_info ? DeSyncIc : SyncIc}
          alt={t('common:alt.sync')}
          className={'b-formAccount__googleCalendarIcon ml-3'}
        />
      </a>
      {userSettings.google_user_info ? (
        <div>
          <div className=" mt-3 mb-3 d-flex">
            {t('common:label.linked-calendars')}:
            <a href="#" onClick={handleEditCalendar} className="b-link-icon-left">
              <img className="ml-2" src={EditIc} alt={t('common:alt.editIc')} />
            </a>
          </div>
          <ul>
            {(userSettings.google_calendars_busy || []).map(calendar => {
              return <li key={`calendar-${calendar.value}`}>{calendar.label}</li>;
            })}
            {userSettings.google_calendars_events?.length ? <li>----</li> : null}
            {(userSettings.google_calendars_events || []).map(calendar => {
              return <li key={`calendar-${calendar.value}`}>{calendar.label}</li>;
            })}
          </ul>
        </div>
      ) : null}
      {renderSelectModal()}
    </div>
  );
};

export default GoogleLinkCalendar;
