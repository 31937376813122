import { useIsDarkMode } from './use-is-dark-mode';
import { ChartOptions, Scale, Tick } from 'chart.js';
import { getChartLabelColor } from '../../helpers';
import { ChartStandardModel } from '../../models';

export const useChart = (chart: ChartStandardModel) => {
  const { isDarkMode } = useIsDarkMode();
  const { labels } = chart;

  const backgroundPlugin = {
    beforeDraw: function (chart: any) {
      if (chart.config.options.chartArea && chart.config.options.chartArea.backgroundColor) {
        const { ctx } = chart;
        const chartArea = chart.chartArea;
        const lightColl = isDarkMode ? '#282828' : '#fcfcfc';
        const darkColl = isDarkMode ? '#373737' : '#f7f7f7';
        ctx.save();
        for (let i = 0; i <= 10; i++) {
          ctx.fillStyle = i % 2 ? darkColl : lightColl;
          ctx.fillRect(
            chartArea.left + (chartArea.width / 10) * i,
            chartArea.top,
            chartArea.width / 10,
            chartArea.bottom - chartArea.top,
          );
        }
        ctx.restore();
      }
    },
  };

  const options: ChartOptions<any> = {
    indexAxis: 'y',
    segmentShowStroke: false,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    layout: {
      padding: {
        right: 50,
      },
    },
    plugins: {
      legend: false,
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'right',
        color: isDarkMode ? '#b2b2b2' : '#666',
        formatter: (value: string) => {
          return `${value}%`;
        },
        font: {
          weight: 'bold',
          family: 'Rubik',
        },
        padding: {
          left: 20,
        },
      },
      responsive: true,
    },
    scales: {
      xAxis: {
        position: 'top',
        max: 100,
        min: 0,
        ticks: {
          color: () => (isDarkMode ? '#b2b2b2' : '#666'),
          max: 100,
          min: 0,
          callback: function (value: number) {
            return ((value / this.max) * 100).toFixed(0) + '%'; // convert it to percentage
          },
        },
      },
      y: {
        position: 'left',
        grid: {
          display: false,
        },
        ticks: {
          color: (scale: any) => {
            return getChartLabelColor(labels[scale.index].alias, isDarkMode || false);
          },
          callback: function (this: Scale, tickValue: number | string, index: number, ticks: Tick[]) {
            return labels[index].name;
          },
          textStrokeColor: 'red',
        },
      },
    },
    chartArea: {
      backgroundColor: 'rgba(251, 85, 85, 0.4)',
    },
  };

  return { chartOptions: options, backgroundPlugin, isDarkMode };
};
