import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  ElmDomainReportItemModel,
  IRouteParamsBase,
  ElmIdentitiesDetailsItemModel,
  KeyValueModel,
  ElmReportScoreBandModel,
} from '../../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { ROOTS } from '../../../../../routes/routes';
import { Button, Loader } from '../../../../controls';
import { getIdentityLabel } from '../../../../../helpers';
import { getElmReport } from '../../../../../store/elm/elmActions';
import { HeadlineOrganization, Modal } from '../../../../common';
import { useTranslation } from 'react-i18next';

interface IRouteParams extends IRouteParamsBase {
  elmId: string;
}

type ILocationState = ElmDomainReportItemModel | undefined;

const ElmOrgReportDomainResults = () => {
  const { orgId, elmId } = useParams<IRouteParams>();
  let [orgName, setOrgName] = useState<string | null>(null);
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation<ILocationState>();
  const dispatch = useDispatch();
  const elmOrgReport = useSelector((state: StateType) => state.elm.elmOrgReport);
  let [identitiesData, setIdentitiesData] = useState<ElmIdentitiesDetailsItemModel | null>(null);
  let [showGuide, setShowGuide] = useState(false);
  let [noSubmitted, setNoSubmitted] = useState<boolean | null>(null);

  useEffect(() => {
    dispatch(
      getElmReport({
        data: {
          elmId: Number(elmId),
          type: 'org',
        },
        callback: ({ org_name, no_submitted }) => {
          if (org_name) {
            setOrgName(org_name);
          }
          if (no_submitted) {
            setNoSubmitted(no_submitted);
          }
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (location.state) {
      if (elmOrgReport && elmOrgReport.identities_details) {
        setIdentitiesData(elmOrgReport.identities_details[location.state.name]);
      }
    } else {
      setIdentitiesData(null);
    }
  }, [location]);

  const goBack = () => {
    history.go(-1);
  };

  const handlePressTab = (key: 'resp' | 'scores') => {
    if (key === 'resp') {
      history.replace(ROOTS.ELM_ORG_REPORT_RESP.replace(':orgId', `${orgId}`).replace(':elmId', `${elmId}`));
    }
  };

  const onClickDomain = (item: ElmDomainReportItemModel) => {
    history.replace(
      ROOTS.ELM_ORG_REPORT_DOMAIN_RESULTS.replace(':orgId', `${orgId}`).replace(':elmId', `${elmId}`),
      item,
    );
  };

  const exitDomainDetails = () => {
    history.replace(
      ROOTS.ELM_ORG_REPORT_DOMAIN_RESULTS.replace(':orgId', `${orgId}`).replace(':elmId', `${elmId}`),
    );
  };

  const renderRow = (
    item: { name: string; by_score_band: ElmReportScoreBandModel },
    isLabelOrange?: boolean,
  ) => {
    return (
      <div className="stackedBarCanvas__row" key={`row-${item.name}`}>
        <div
          onClick={isLabelOrange ? exitDomainDetails : () => null}
          className={`stackedBarCanvas__rowLabel ${isLabelOrange ? '-orange' : ''}`}
        >
          {isLabelOrange ? <div className={`stackedBarCanvas__arrow`} /> : null}
          {getIdentityLabel(item.name)}
        </div>
        <div className="stackedBarCanvas__bar">
          <div
            className="stackedBarCanvas__data stackedBarCanvas__data--developing"
            style={{ width: `${item.by_score_band.developing}%` }}
          >
            {item.by_score_band.developing}%
          </div>
          <div
            className="stackedBarCanvas__data stackedBarCanvas__data--mid"
            style={{ width: `${item.by_score_band.mid}%` }}
          >
            {item.by_score_band.mid}%
          </div>
          <div
            className="stackedBarCanvas__data stackedBarCanvas__data--high"
            style={{ width: `${item.by_score_band.high}%` }}
          >
            {item.by_score_band.high}%
          </div>
        </div>
      </div>
    );
  };

  const renderEmptyRow = (rowLabel: string, alias: 'gender' | 'race' | 'division' | 'role-type') => {
    const getText = () => {
      if (alias === 'gender') {
        return t('awa:M198.msg');
      } else if (alias === 'race') {
        return t('awa:M197.msg');
      } else if (alias === 'division') {
        return t('awa:M199.msg');
      }
    };

    return (
      <div className="stackedBarCanvas__row">
        <div className={`stackedBarCanvas__rowLabel font-weight-bold`}>{rowLabel}</div>
        <div className="stackedBarCanvas__bar w-100 -empty">{getText()}</div>
      </div>
    );
  };

  const renderIdentity = (identity: KeyValueModel<ElmReportScoreBandModel>) => {
    let data = [];
    for (let item in identity) {
      data.push({
        name: item,
        by_score_band: identity[item],
      });
    }
    return data.map(item => renderRow(item));
  };

  if (!elmOrgReport) return <Loader />;
  const { domains_report } = elmOrgReport;

  const renderData = () => {
    if (!domains_report) return null;
    if (identitiesData) {
      return (
        <>
          {renderRow(identitiesData.domain_data, true)}
          {identitiesData.role_type_identities ? (
            <div className="stackedBarCanvas__rowGroup">
              <div className="stackedBarCanvas__rowGroupName">{t('common:label.role-type')}</div>
              {renderIdentity(identitiesData.role_type_identities)}
            </div>
          ) : (
            renderEmptyRow(t('common:label.role-type'), 'role-type')
          )}
          {identitiesData.race_identities ? (
            <div className="stackedBarCanvas__rowGroup">
              <div className="stackedBarCanvas__rowGroupName">{t('common:label.Race-Identities')}</div>
              {renderIdentity(identitiesData.race_identities)}
            </div>
          ) : (
            renderEmptyRow(t('common:label.Race-Identities'), 'race')
          )}
          {identitiesData.gender_identities ? (
            <div className="stackedBarCanvas__rowGroup">
              <div className="stackedBarCanvas__rowGroupName">{t('common:label.Gender-Identities')}</div>
              {renderIdentity(identitiesData.gender_identities)}
            </div>
          ) : (
            renderEmptyRow(t('common:label.Gender-Identities'), 'gender')
          )}
          {identitiesData.division_identities ? (
            <div className="stackedBarCanvas__rowGroup">
              <div className="stackedBarCanvas__rowGroupName">{t('common:label.Division-Identities')}</div>
              {renderIdentity(identitiesData.division_identities)}
            </div>
          ) : (
            renderEmptyRow(t('common:label.Division-Identities'), 'division')
          )}
        </>
      );
    } else {
      return domains_report.map(item => {
        return (
          <div
            className="stackedBarCanvas__row"
            onClick={() => onClickDomain(item)}
            key={`domain-${item.id}`}
          >
            <div className="stackedBarCanvas__rowLabel -orange">
              <div className="stackedBarCanvas__arrow -right" />
              {item.name}
            </div>
            <div className="stackedBarCanvas__bar">
              <div
                className="stackedBarCanvas__data stackedBarCanvas__data--developing"
                style={{ width: `${item.by_score_band.developing}%` }}
              >
                {item.by_score_band.developing}%
              </div>
              <div
                className="stackedBarCanvas__data stackedBarCanvas__data--mid"
                style={{ width: `${item.by_score_band.mid}%` }}
              >
                {item.by_score_band.mid}%
              </div>
              <div
                className="stackedBarCanvas__data stackedBarCanvas__data--high"
                style={{ width: `${item.by_score_band.high}%` }}
              >
                {item.by_score_band.high}%
              </div>
            </div>
          </div>
        );
      });
    }
  };

  const renderContent = () => {
    if (noSubmitted) {
      return (
        <div className="b-panel b-typography b-elmReport2__noContent">
          <p>{t('awa:M214.msg')}</p>
        </div>
      );
    }
    if (!domains_report) {
      return <div className="b-no-content">{t('common:label.No-Organization-Report')}</div>;
    }

    return (
      <>
        <div className="b-elmReport2__headMenu">
          <div className="b-elmReport2__tabs">
            <a onClick={() => handlePressTab('resp')} tabIndex={0}>
              {t('common:label.Response-Rate')}
            </a>
            <a tabIndex={0} className="-active">
              {t('common:label.ELM-Domain-Scores')}
            </a>
          </div>
        </div>

        <div className="b-report__heading">
          <a
            onClick={e => {
              e.preventDefault();
              setShowGuide(true);
            }}
            className="b-report__headingLink"
            href="#"
          >
            {t('common:btn.score-guide')}
          </a>
          <button onClick={() => setShowGuide(true)} className="b-report__btnInfo">
            <span className={'sr-only'}>{t('common:btn.information')}</span>
          </button>
        </div>

        <div className="stackedBarCanvas">
          <div className="stackedBarCanvas__holder">
            <div className="stackedBarCanvas__labels  stackedBarCanvas__labels--desktop">
              <span>0%</span>
              <span>10%</span>
              <span>20%</span>
              <span>30%</span>
              <span>40%</span>
              <span>50%</span>
              <span>60%</span>
              <span>70%</span>
              <span>80%</span>
              <span>90%</span>
              <span>100%</span>
            </div>
            <div className="stackedBarCanvas__labels stackedBarCanvas__labels--mobile">
              <span>0%</span>
              <span>25%</span>
              <span>50%</span>
              <span>75%</span>
              <span>100%</span>
            </div>
            {renderData()}
            <div className="stackedBarCanvas__legend">
              <div className="stackedBarCanvas__legendItem stackedBarCanvas__legendItem--developing">
                {t('common:label.developing')}
              </div>
              <div className="stackedBarCanvas__legendItem stackedBarCanvas__legendItem--mid">
                {t('common:label.mid')}
              </div>
              <div className="stackedBarCanvas__legendItem stackedBarCanvas__legendItem--high">
                {t('common:label.high')}
              </div>
            </div>
          </div>
        </div>
        <span className="stackedBarCanvas__note">
          {identitiesData ? t('awa:M200.msg') : t('pp:elm-report-2.p1')}
        </span>
      </>
    );
  };

  return (
    <>
      <div className={'pageContainer pageContainer__content'}>
        <HeadlineOrganization
          orgName={orgName || ''}
          orgId={orgId || ''}
          headline={t('common:headlines.equity-lens-map')}
          customClasses={'-desktop'}
        />
        <div className="b-report__pageSubTitle">
          <a tabIndex={0} className="b-report__backBtn" onClick={goBack}>
            <div className="back-icon" />
          </a>
          <span>{t('common:label.Organization-Report')}</span>
        </div>
        {renderContent()}
      </div>
      {showGuide && (
        <Modal width={650} title="Equity Lens Map Score Guide" onClose={() => setShowGuide(false)}>
          <div className="b-modal">
            <div className="b-typography b-modal__content">
              <p>{t('pp:elm-report-2.p2')}</p>
              <ul className="mb-3">
                <li>{t('pp:elm-report.p9')}</li>
                <li>{t('pp:elm-report.p10')}</li>
                <li>{t('pp:elm-report.p11')}</li>
                <li>{t('pp:elm-report.p12')}</li>
                <li>{t('pp:elm-report.p12')}</li>
              </ul>
              <p>{t('pp:elm-report-2.p3')}</p>
              <ul className="mb-3">
                <li>{t('pp:elm-report.p15')}</li>
                <li>{t('pp:elm-report.p16')}</li>
              </ul>
              <p>{t('pp:elm-report.p17')}:</p>
              <div className="b-elmReport2Guide">
                <div>
                  <div className="b-elmReport2Guide__item -yellow">
                    <ul>
                      <li>{t('common:label.developing')}</li>
                    </ul>
                    <div>{'< 2.0'}</div>
                  </div>
                  <div className="b-elmReport2Guide__item -orange">
                    <ul>
                      <li>{t('common:label.mid')}</li>
                    </ul>
                    <div>{'2.0 - 2.99'}</div>
                  </div>
                  <div className="b-elmReport2Guide__item -green">
                    <ul>
                      <li>{t('common:label.high')}</li>
                    </ul>
                    <div>{'3.0 - 4.0'}</div>
                  </div>
                </div>
              </div>
              <p>{t('pp:elm-report-2.p4')}</p>
              <p>- {t('pp:elm-report-2.p5')}</p>
              <p>- {t('pp:elm-report-2.p6')}</p>
              <p>- {t('pp:elm-report-2.p7')}</p>
              <p>- {t('pp:elm-report-2.p8')}</p>
              <p>{t('pp:elm-report-2.p9')}</p>
            </div>
            <div className="b-EAModal__buttons">
              <Button width={130} title={t('common:btn.ok')} onPress={() => setShowGuide(false)} />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ElmOrgReportDomainResults;
