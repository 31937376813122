import {
  ActionModel,
  PaginationModel,
  TableItemModel,
  QueryParamsModel,
  TableDataEntities,
} from '../../models';
import { actionCreator } from '../actionCreator';

export const SET_TABLE_ITEM = 'tables/SET_TABLE_ITEM';
export interface SetTableItemPayload {
  id: TableDataEntities;
  tableItem: {
    list?: PaginationModel<any[]> | null;
    query?: QueryParamsModel;
  };
}
export const setTableItem = actionCreator<SetTableItemPayload>(SET_TABLE_ITEM);
export type TableActionsTypes = ActionModel<typeof SET_TABLE_ITEM, SetTableItemPayload>;

export interface TablesState {
  [prop: string]: TableItemModel;
}

const initialState: TablesState = {};

const tablesReducer = (state: TablesState = initialState, action: TableActionsTypes): TablesState => {
  switch (action.type) {
    case SET_TABLE_ITEM: {
      const tableItem: TableItemModel = {
        ...state[action.payload.id],
      };
      const { list, query } = action.payload.tableItem;
      if (list) {
        tableItem.list = list;
      }
      if (query) {
        tableItem.query = query;
      }
      return {
        ...state,
        [action.payload.id]: tableItem,
      };
    }
    default:
      return state;
  }
};

export default tablesReducer;
