import React, { FC, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Input, Loader } from '../../../../../controls';
import { Modal } from '../../../../../common';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Api from '../../../../../../services/api';
import { useHistory, useParams } from 'react-router';
import { IRouteParamsBase, KeyValueModel } from '../../../../../../models';
import { ROOTS } from '../../../../../../routes/routes';
import { toast } from 'react-toastify';

interface IProps {
  onClose: () => void;
  ea_ids: number[];
}

const SaveGroupAverage: FC<IProps> = ({ onClose, ea_ids }) => {
  const { t } = useTranslation();
  const isBeloved = location.pathname.match('beloved');
  const { orgId } = useParams<IRouteParamsBase>();
  const [errors, setErrors] = useState<KeyValueModel<string>>({});
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const baseUrl = isBeloved
    ? `beloved/equity_audit/create_group_avg/`
    : `organizations/${orgId}/equity_audit/group_average/create_group/`;

  const [value, setValue] = useState<string>(
    t('main:ea.group-average-date', { date: dayjs().format('MM.DD.YYYY') }),
  );

  const handleSave = async () => {
    try {
      setLoading(true);
      const res = await Api.post(baseUrl, { ea_ids, name: value });
      setLoading(false);
      if (res) {
        toast.success(t('common:toast.avg-created'));
        const route = isBeloved
          ? ROOTS.BEL_GROUP_AVERAGES_CHART.replace(':id', res.id)
          : ROOTS.GROUP_AVERAGES_CHART.replace(':orgId', `${orgId}`).replace(':id', res.id);

        history.push(route, {
          ea_ids: ea_ids,
          name: value,
        });
      }
    } catch (err: any) {
      setLoading(false);
      if (err.response?.data) {
        setErrors(err.response.data);
      }
    }
  };

  const onChangeValue = (val: string) => {
    setErrors({});
    setValue(val);
  };

  if (loading) {
    return <Loader fitParent />;
  }

  return (
    <>
      <Modal
        width={450}
        className={!isMobile ? 'b-filtersModal__mobileModal' : ''}
        title={t('common:headlines.add-group-avg')}
        onClose={onClose}
        classNameTitle="b-filtersModal__modalTitle"
      >
        <div className={'b-filtersModal'}>
          <div className={'b-step__teamModal__list'}>
            <Input
              value={value}
              type="text"
              handleChange={onChangeValue}
              placeholder={t('common:label.name')}
              ariaLabel={t('common:label.name')}
              error={errors.name}
            />
          </div>
        </div>
        <div className="b-EAModal__buttons pb-5">
          <Button
            size="middle"
            className="button-mh"
            type={'transparency'}
            onPress={onClose}
            title={t('common:btn.cancel')}
          />
          <Button
            size="middle"
            className="button-mh"
            type={'orange-light'}
            onPress={handleSave}
            title={t('common:btn.continue')}
            enableEnter
          />
        </div>
      </Modal>
    </>
  );
};

export default SaveGroupAverage;
