import React, { useEffect } from 'react';
import BackTitleButton from '../parts/BackTitleButton';
import { HeadlineOrganization, OrgBreadcrumbs, Pagination } from '../../../../common';
import EwpTabs from '../parts/EwpTabs';
import { useEwpDetails } from '../../../../../hooks/ewp/use-ewp-details';
import { useTranslation } from 'react-i18next';
import useTableData from '../../../../../hooks/common/use-table-data';
import { useHistory, useParams } from 'react-router';
import { EwpUserTableItem, IRouteParamsBase, TableDataEntities } from '../../../../../models';
import { Loader } from '../../../../controls';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DataGrid from 'react-data-grid';
import useTableConfig from '../../../../../hooks/common/use-table-config';
import { getColumns } from './parts/columns';
import { useIsDarkMode } from '../../../../../hooks/common/use-is-dark-mode';
import { useFilterOptions } from '../../../../../hooks/ewp/use-filter-options';
import FilterModalButton from '../../../../common/FilterModalButton/FilterModalButton';
import { ROOTS } from '../../../../../routes/routes';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';

const EwpUsers = () => {
  const { isDarkMode } = useIsDarkMode();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<IRouteParamsBase>();
  const { ewpDetails } = useEwpDetails();
  const { filterOptions } = useFilterOptions(ewpDetails?.organization_id);
  const user = useSelector((state: StateType) => state.profile.authUser);
  const isBeloved = user?.beloved_user;

  const {
    data,
    query,
    fetchData,
    handleChangePage,
    handleSelectOne,
    handleSelectFilter,
    handleApplyFilter,
    isFiltersChanged,
    filterData,
    handleChangePageSize,
    handleSearch,
    selectedEntitiesIds,
    handleSort,
  } = useTableData(TableDataEntities.EWP_USERS, { ewp_id: id });

  const { columns, onColumnResize } = useTableConfig<EwpUserTableItem>(
    TableDataEntities.EWP_USERS,
    getColumns(),
  );

  const handleClickRow = (row: EwpUserTableItem) => {
    if (isBeloved) {
      history.push(ROOTS.BELOVED_ADMIN_TOOL_ORG_USER_PROFILE.replace(':id', String(row.id)));
    }
  };

  useEffect(() => {
    fetchData(query);
  }, []);

  const renderContent = () => {
    if (!data) return <Loader />;

    return (
      <div>
        <DndProvider backend={HTML5Backend}>
          <DataGrid
            onRowClick={handleClickRow}
            onColumnResize={onColumnResize}
            onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
            columns={columns}
            sortColumns={query.sorting ? [query.sorting] : []}
            rows={data.result}
            onSelectedRowsChange={handleSelectOne}
            className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
            style={{
              height: ((data.result.length || 0) + 1) * 60,
            }}
            rowHeight={60}
            selectedRows={new Set(selectedEntitiesIds)}
            rowKeyGetter={(row: EwpUserTableItem) => row.user_id}
          />
        </DndProvider>
        <Pagination data={data} handleChangePageSize={handleChangePageSize} changePage={handleChangePage} />
      </div>
    );
  };

  return (
    <main className={'b-page'}>
      <div className="b-page__header -desktop">
        <HeadlineOrganization
          orgName={ewpDetails?.organization_name || ''}
          orgId={ewpDetails?.id || ''}
          headline={t('common:headlines.equity-work-plan')}
          customClasses={'-noDesktopMb mr-4'}
          backBtn={<BackTitleButton />}
        />
      </div>
      <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
      <EwpTabs activeItemAlias={'users'} />
      <div className="b-page__controls mb-5">
        <div className="b-tableToolContainer">
          {filterOptions ? (
            <FilterModalButton
              query={query}
              handleSelectFilter={handleSelectFilter}
              handleApplyFilter={handleApplyFilter}
              filterData={filterData}
              isFiltersChanged={isFiltersChanged}
              filters={filterOptions.users_list}
            />
          ) : null}
        </div>
        <div className="b-page__search">
          <form action="#" className="searchForm">
            <input
              type="search"
              value={query.search}
              onChange={e => handleSearch(e.target.value)}
              placeholder={t('common:label.search')}
              aria-label="Search field"
            />
            <span className="searchForm__icon" />
          </form>
        </div>
      </div>
      {renderContent()}
    </main>
  );
};

export default EwpUsers;
