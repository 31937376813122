import React, { useEffect, useState } from 'react';
import SignInView from './SignInView';
import { useDispatch, useSelector } from 'react-redux';
import {
  login,
  verificationAccountRequest,
  setRedirectLoginUrl,
} from '../../../../store/authData/authDataActions';
import { validate, email, isValidFields } from '../../../../helpers';
import { ROOTS } from '../../../../routes/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { StateType } from '../../../../store/reducers';
import { useTranslation } from 'react-i18next';
import { confirmSecondaryEmail } from '../../../../store/profile/profileActions';

export interface PopupSignInModel {
  title: string;
  msg: string;
  resend: boolean;
  showImage?: boolean;
}

const SignInContainer = () => {
  const validator = {
    required: ['username', 'password'],
    custom: [email(['username'])],
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let [error, setErrors] = useState<any>({});
  const redirectAfterLogin = useSelector((state: StateType) => state.authData.redirectAfterLogin);
  let [showNotification, setShowNotification] = useState<PopupSignInModel | null>(null);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  useEffect(() => {
    const hash = new URLSearchParams(history.location.search).get('verification_hash');
    if (hash) {
      handleVerificationAccount(hash);
    }
  }, []);

  useEffect(() => {
    if (location.state) {
      dispatch(setRedirectLoginUrl(location.state.pathname));
    }
    if (
      location.state &&
      location.state.search &&
      location.state.search.includes('secondary_verification_hash=')
    ) {
      const secondaryHash = new URLSearchParams(location.state.search).get('secondary_verification_hash');
      handleVerificationSecondaryEmail(String(secondaryHash));
    }
  }, [location]);

  const handleVerificationSecondaryEmail = (hash: string) => {
    dispatch(
      confirmSecondaryEmail({
        data: { hash: hash },
        callback: (response: any) => verificationResult(response),
      }),
    );
  };

  const handleVerificationAccount = (hash: string) => {
    dispatch(
      verificationAccountRequest({
        data: { hash: hash },
        callback: (response: any) => verificationResult(response),
      }),
    );
  };

  const verificationResult = (response: any) => {
    if (response && response.popup) {
      const popup = { ...response.popup };
      popup.showImage = true;
      setShowNotification(popup as PopupSignInModel);
    }
  };

  const handleNavigation = (response: any) => {
    if (response.error) {
      setErrors({
        password: t('common:errors.sign-in'),
      });
      return;
    }
    if (response.next_step) {
      switch (response.next_step) {
        case 4: //Join To Organization
          history.push(ROOTS.ORGANIZATION_PREVIEW);
          break;
        case 1: {
          setShowNotification({
            title: t('common:errors.unconfirmed.title'),
            msg: t('common:errors.unconfirmed.msg'),
            resend: true,
          });
          break;
        }
        case 2:
          setShowNotification({
            title: t('common:errors.unconfirmed.title'),
            msg: t('common:errors.unconfirmed.msg'),
            resend: true,
          });
          break;
        default:
          setErrors({ password: t('common:errors.sign-in') });
          break;
      }
    } else {
      if (redirectAfterLogin !== null) {
        history.push(redirectAfterLogin);
      }
    }
  };

  const handleSubmit = () => {
    let { errors } = validate(validator, formData);
    setErrors(errors);
    if (!Object.keys(error).length) {
      dispatch(
        login({
          data: formData,
          callback: handleNavigation,
        }),
      );
    }
  };

  const handleChangeInput = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    if (error[name]) {
      delete error[name];
      setErrors(error);
    }
  };

  const navTo = (route: ROOTS) => (e: any) => {
    e.preventDefault();
    history.push(route);
  };

  const isDisableButton = isValidFields(validator, formData);

  return (
    <SignInView
      handleShowNotification={setShowNotification}
      errors={error}
      showNotification={showNotification}
      handleChangeInput={handleChangeInput}
      formData={formData}
      isDisableButton={isDisableButton}
      navTo={navTo}
      onSubmit={handleSubmit}
    />
  );
};

export default SignInContainer;
