import React from 'react';
import styles from './TermsConditionsPaymentStyles.module.scss';
import { useTranslation } from 'react-i18next';

const TermsConditionsPayment = () => {
  const { t } = useTranslation();

  return (
    <div className={`container container-header white-container ${styles.wrapContainer}`}>
      <div className={`white-container content-center-wrapper ${styles.container}`}>
        <div className={'pageContainer pageContainer__content'}>
          <div className={'b-formAccount -center'}>
            <h2>{t('pp:payment.p1')}</h2>
            <h3>{t('pp:payment.p2')}</h3>
            <p>{t('pp:payment.p3')}</p>
            <h3>{t('pp:payment.p4')}</h3>
            <p>{t('pp:payment.p5')}</p>
            <p>{t('pp:payment.p6')}</p>
            <p>{t('pp:payment.p7')}</p>
            <p>{t('pp:payment.p8')}</p>
            <ul>
              <li>
                <p>
                  1. <u>{t('pp:payment.p9.title')}.</u> {t('pp:payment.p9.msg.p1')}
                </p>
                <p>{t('pp:payment.p9.msg.p2')}</p>
                <p>{t('pp:payment.p9.msg.p3')}</p>
                <p>{t('pp:payment.p9.msg.p4')}</p>
                <p>{t('pp:payment.p9.msg.p5')}</p>
                <p>{t('pp:payment.p9.msg.p6')}</p>
                <p>{t('pp:payment.p9.msg.p7')}</p>
                <p>{t('pp:payment.p9.msg.p8')}</p>
                <p>{t('pp:payment.p9.msg.p9')}</p>
                <p>{t('pp:payment.p9.msg.p10')}</p>
                <p>{t('pp:payment.p9.msg.p11')}</p>
                <p>{t('pp:payment.p9.msg.p12')}</p>
                <p>{t('pp:payment.p9.msg.p13')}</p>
              </li>
              <li>
                <p>
                  2. <u>{t('pp:payment.p10.title')}.</u>
                </p>
                <ul>
                  <li>
                    <p>
                      2.1 <u>{t('pp:payment.p10.msg.p1.title')}.</u> {t('pp:payment.p10.msg.p1.msg')}
                    </p>
                  </li>
                  <li>
                    <p>
                      2.2 <u>{t('pp:payment.p10.msg.p2.title')}.</u> {t('pp:payment.p10.msg.p2.msg')}
                    </p>
                  </li>
                  <li>
                    <p>
                      2.3 <u>{t('pp:payment.p10.msg.p3.title')}.</u> {t('pp:payment.p10.msg.p3.msg')}
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  3. <u>{t('pp:payment.p11.title')}.</u>
                  <ul>
                    <li>
                      <p>
                        3.1 <u>{t('pp:payment.p11.msg.p1.title')}.</u> {t('pp:payment.p11.msg.p1.msg')}
                      </p>
                    </li>
                    <li>
                      <p>
                        3.2 <u>{t('pp:payment.p11.msg.p2.title')}.</u> {t('pp:payment.p11.msg.p2.msg')}
                      </p>
                    </li>
                    <li>
                      <p>
                        3.3 <u>{t('pp:payment.p11.msg.p3.title')}.</u> {t('pp:payment.p11.msg.p3.msg')}
                      </p>
                    </li>
                    <li>
                      <p>
                        3.4 <u>{t('pp:payment.p11.msg.p4.title')}.</u> {t('pp:payment.p11.msg.p4.msg')}
                      </p>
                    </li>
                  </ul>
                </p>
              </li>
              <li>
                <p>
                  4. <u>{t('pp:payment.p12.title')}.</u> {t('pp:payment.p12.msg')}
                </p>
                <p>
                  5. <u>{t('pp:payment.p13.title')}</u>
                </p>
                <ul>
                  <li>
                    <p>
                      5.1 <u>{t('pp:payment.p13.msg.p1.title')}.</u> {t('pp:payment.p13.msg.p1.msg.p1')}
                    </p>
                    <p>{t('pp:payment.p13.msg.p1.msg.p2')}</p>
                    <p>{t('pp:payment.p13.msg.p1.msg.p3')}</p>
                  </li>
                  <li>
                    <p>
                      5.2 <u>{t('pp:payment.p13.msg.p2.title')}.</u> {t('pp:payment.p13.msg.p2.msg')}
                    </p>
                  </li>
                  <li>
                    <p>
                      5.3 <u>{t('pp:payment.p13.msg.p3.title')}.</u> {t('pp:payment.p13.msg.p2.msg')}
                    </p>
                  </li>
                  <li>
                    <p>
                      5.4 <u>{t('pp:payment.p13.msg.p4.title')}.</u> {t('pp:payment.p13.msg.p4.msg')}
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  6. <u>{t('pp:payment.p14.title')}</u>
                </p>
                <p>
                  {t('pp:payment.p14.msg.p1')}
                  <ul>
                    <li>
                      <p>
                        <span className={styles.chapter}>(a)</span> {t('pp:payment.p14.msg.p2')}
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className={styles.chapter}>(b)</span> {t('pp:payment.p14.msg.p3')}
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className={styles.chapter}>(c)</span> {t('pp:payment.p14.msg.p4')}
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className={styles.chapter}>(d)</span> {t('pp:payment.p14.msg.p5')}
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className={styles.chapter}>(e)</span> {t('pp:payment.p14.msg.p6')}
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className={styles.chapter}>(f)</span> {t('pp:payment.p14.msg.p7')}
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className={styles.chapter}>(g)</span> {t('pp:payment.p14.msg.p8')}
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className={styles.chapter}>(h)</span> {t('pp:payment.p14.msg.p9')}
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className={styles.chapter}>(i)</span> {t('pp:payment.p14.msg.p10')}
                      </p>
                    </li>
                  </ul>
                </p>
              </li>
              <li>
                <p>
                  7. <u>{t('pp:payment.p14.title')}.</u>
                </p>
                <p>{t('pp:payment.p14.msg.p1')}</p>
                <ul>
                  <li>
                    <p>
                      <span className={styles.chapter}>(a)</span> {t('pp:payment.p14.msg.p2')}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={styles.chapter}>(b)</span> {t('pp:payment.p14.msg.p3')}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={styles.chapter}>(c)</span> {t('pp:payment.p14.msg.p4')}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={styles.chapter}>(d)</span> {t('pp:payment.p14.msg.p5')}
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  8. <u>{t('pp:payment.p28')}.</u> {t('pp:payment.p29')}.
                </p>
              </li>
              <li>
                <p>
                  9. <u>{t('pp:payment.p30')}.</u>
                  <ul>
                    <li>
                      <p>
                        9.1 <u>{t('pp:payment.p17.msg.p1.title')}.</u> {t('pp:payment.p17.msg.p1.msg.p1')}
                      </p>
                      <ul>
                        <li>
                          <p>
                            <span className={styles.chapter}>(a)</span> {t('pp:payment.p17.msg.p1.msg.p2')}
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className={styles.chapter}>(b)</span> {t('pp:payment.p17.msg.p1.msg.p3')}
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className={styles.chapter}>(c)</span> {t('pp:payment.p17.msg.p1.msg.p4')}
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className={styles.chapter}>(d)</span> {t('pp:payment.p17.msg.p1.msg.p5')}
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        9.2 <u>{t('pp:payment.p17.msg.p2.title')}.</u> {t('pp:payment.p17.msg.p2.msg')}
                      </p>
                    </li>
                    <li>
                      <p>
                        9.3 <u>{t('pp:payment.p17.msg.p3.title')}.</u> {t('pp:payment.p17.msg.p3.msg')}
                      </p>
                    </li>
                  </ul>
                </p>
              </li>
              <li>
                <p>
                  10. <u>{t('pp:payment.p18.title')}.</u>
                </p>
                <ul>
                  <li>
                    <p>
                      10.1 <u>{t('pp:payment.p18.msg.p1.title')}.</u> {t('pp:payment.p18.msg.p1.msg')}
                    </p>
                  </li>
                  <li>
                    <p>
                      10.2 <u>{t('pp:payment.p18.msg.p2.title')}.</u> {t('pp:payment.p18.msg.p2.msg')}
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  11. <u>{t('pp:payment.p19.msg.p1.title')}.</u>
                </p>
                <ul>
                  <li>
                    <p>
                      11.1 <u>{t('pp:payment.p19.msg.p1.title')}.</u> {t('pp:payment.p19.msg.p1.msg')}
                    </p>
                  </li>
                  <li>
                    <p>
                      11.2 <u>{t('pp:payment.p19.msg.p2.title')}.</u> {t('pp:payment.p19.msg.p2.msg')}
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  12. <u>{t('pp:payment.p20.title')}.</u> {t('pp:payment.p20.msg')}
                </p>
              </li>
              <li>
                <p>
                  13. <u>{t('pp:payment.p21.title')}.</u> {t('pp:payment.p21.msg')}
                </p>
              </li>
              <li>
                <p>
                  14. <u>{t('pp:payment.p22.title')}.</u> {t('pp:payment.p22.title')}
                </p>
              </li>
              <li>
                <p>
                  15. <u>{t('pp:payment.p23.title')}.</u> {t('pp:payment.p23.msg')}
                </p>
              </li>
              <li>
                <p>
                  16. <u>{t('pp:payment.p24.title')}.</u> {t('pp:payment.p24.msg')}
                </p>
              </li>
              <li>
                <p>
                  17. <u>{t('pp:payment.p25.title')}.</u> {t('pp:payment.p25.msg')}
                </p>
              </li>
              <li>
                <p>
                  18. <u>{t('pp:payment.p26.title')}.</u> {t('pp:payment.p26.msg')}
                </p>
              </li>
              <li>
                <p>
                  19. <u>{t('pp:payment.p27.title')}.</u>
                </p>
                <ul>
                  <li>
                    <p>
                      19.1. <u>{t('pp:payment.p27.msg.p1.title')}.</u> {t('pp:payment.p27.msg.p1.msg')}
                    </p>
                  </li>
                  <li>
                    <p>
                      19.2 <u>{t('pp:payment.p27.msg.p2.title')}.</u> {t('pp:payment.p27.msg.p2.msg')}
                    </p>
                  </li>
                  <li>
                    <p>
                      19.3 <u>{t('pp:payment.p27.msg.p3.title')}.</u> {t('pp:payment.p27.msg.p3.msg')}
                    </p>
                  </li>
                  <li>
                    <p>
                      19.4 <u>{t('pp:payment.p27.msg.p4.title')}.</u> {t('pp:payment.p27.msg.p4.msg')}
                    </p>
                  </li>
                  <li>
                    <p>
                      19.5 <u>{t('pp:payment.p27.msg.p5.title')}.</u> {t('pp:payment.p27.msg.p5.msg')}
                    </p>
                  </li>
                  <li>
                    <p>
                      19.6 <u>{t('pp:payment.p27.msg.p6.title')}.</u> {t('pp:payment.p27.msg.p6.msg')}
                    </p>
                  </li>
                  <li>
                    <p>
                      19.7 <u>{t('pp:payment.p27.msg.p7.title')}.</u> {t('pp:payment.p27.msg.p7.msg')}
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditionsPayment;
