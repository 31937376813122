import { useState } from 'react';
import Api from '../../services/api';
import { Method } from 'axios';
const fileDownload = require('js-file-download');

function useFileDownload() {
  const [isDownloadingFile, setIsDownloadingFile] = useState<boolean>(false);

  const downloadFile = <T>(route: string, method: Method, data: T) => {
    setIsDownloadingFile(true);
    Api.downloadFile(route, method, data).then(resp => {
      setIsDownloadingFile(false);
      fileDownload(resp.blob, resp.fileName);
    });
  };

  return { isDownloadingFile, downloadFile };
}

export default useFileDownload;
