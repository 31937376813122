import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { AsyncSelectResultModal, ISelectOptions } from '../../../models';
import styles from './SelectStyle.module.scss';
import { OptionType } from 'dayjs';
import { AsyncPaginate } from 'react-select-async-paginate';
import { NoOptions } from './parts/NoOptions';

interface IInput {
  error?: string;
  placeholder?: string;
  type?: string;
  value: any;
  handleChange?: (value: any) => void;
  handleUnselect?: () => void;
  customClasses?: any;
  height?: string | number;
  name?: string;
  wrapperStyles?: CSSProperties;
  disabled?: boolean;
  isMulti?: boolean;
  autoFocus?: boolean;
  tabIndex?: number;
  ariaLabel?: string;
  isSearchable?: boolean;
  loadOptions: (
    search: string,
    loadedOptions: ISelectOptions[],
    additional?: { page: number },
  ) => Promise<AsyncSelectResultModal | undefined>;
  defaultOptions?: ReadonlyArray<OptionType | ISelectOptions> | undefined;
  onMenuOpen?: (() => void) | undefined;
  debounceTimeout?: number;
  noOptionsMessage?: string;
  cacheUniqs?: ReadonlyArray<any>;
}

const AsyncSelectBlock = (props: IInput) => {
  const {
    error,
    placeholder,
    handleChange,
    value,
    customClasses,
    height,
    name,
    wrapperStyles,
    disabled,
    isMulti,
    autoFocus,
    tabIndex,
    ariaLabel,
    isSearchable,
    loadOptions,
    defaultOptions,
    onMenuOpen,
    debounceTimeout,
    noOptionsMessage,
    cacheUniqs,
  } = props;

  const ref = useRef<any>(null);
  const [isFocus, setFocus] = useState(false);

  useEffect(() => {
    if (autoFocus) {
      ref.current.focus();
    }
  }, []);

  const getBorderColor = (isFocused: boolean) => {
    if (error) {
      return 'red';
    }
    if (isFocused) {
      return 'var(--primary-60)';
    }
    return 'var(--gray-30)';
  };

  const customStyles = {
    control: (base: any, { isFocused }: any) => ({
      ...base,
      minHeight: height || '52px',
      color: 'var(--gray-60)',
      background: 'inherit',
      opacity: '1',
      borderColor: getBorderColor(isFocused),
      boxShadow: isFocused ? ' 0 0 0 1px var(--primary-60)' : 0,
    }),
    singleValue: (base: any) => ({
      ...base,
      color: 'var(--gray-60)',
      fontFamily: 'Rubik',
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 9999,
      color: 'var(--primary-navy)',
      background: 'var(--background)',
    }),
    input: (base: any) => ({
      ...base,
      color: 'var(--gray-60)',
    }),
    placeholder: (base: any) => ({
      ...base,
      color: 'var(--gray-60)',
    }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor: 'var(--gray-10)',
      color: 'var(--gray-60)',
      '& div': {
        color: 'var(--gray-60)',
      },
    }),
  };

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  return (
    <div style={wrapperStyles} className={styles.select_wrapper}>
      <div
        className={`${styles.input_placeholder_block} ${error ? styles.select_wrapper_error : ''} ${
          isFocus ? styles.labelFocus : ''
        }`}
        data-content={value ? placeholder : ''}
      >
        <AsyncPaginate
          loadOptions={loadOptions as any}
          name={name}
          placeholder={placeholder}
          isSearchable={isSearchable}
          value={value}
          components={{
            IndicatorSeparator: () => null,
            NoOptionsMessage: props => NoOptions(props, noOptionsMessage, defaultOptions?.length),
          }}
          defaultOptions={defaultOptions}
          onFocus={onFocus}
          onBlur={onBlur}
          isDisabled={disabled}
          onMenuOpen={onMenuOpen}
          onChange={handleChange}
          styles={customStyles}
          isMulti={isMulti}
          isOptionDisabled={option => option.disabled}
          menuPlacement={'auto'}
          className={`${(customClasses && customClasses) || ''} ${error ? styles.select_error : ''}`}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: 'var(--primary-60)',
              primary25: 'var(--gray-10)',
              primary50: 'var(--primary-60)',
              neutral20: error ? 'red' : 'var(--gray-30)',
            },
          })}
          // @ts-ignore
          tabIndex={tabIndex}
          aria-label={ariaLabel || placeholder}
          debounceTimeout={debounceTimeout || 300}
          cacheUniqs={cacheUniqs}
        />
        <div className={'error-notification'}>
          <span className={'error-notification-text'}>{error}</span>
        </div>
      </div>
    </div>
  );
};

export default AsyncSelectBlock;
