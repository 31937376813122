import React, { useEffect, useState } from 'react';
import EmailVerifiedView from './EmailVerifiedView';
import { useDispatch } from 'react-redux';
import {
  resentEmailConfirmRequest,
  verificationAccountRequest,
} from '../../../../store/authData/authDataActions';
import { ROOTS } from '../../../../routes/routes';
import { email, validate } from '../../../../helpers';

interface IProps {
  history: any;
}

const emailValidator = {
  required: ['username'],
  custom: [email(['username'])],
};

const EmailVerifiedContainer = ({ history }: IProps) => {
  const [disable, setDisable] = useState(true);
  const [status, setStatus] = useState<boolean | null>(null);
  const [username, setUsername] = useState<string>('');
  const [errors, setErrors] = useState<any>({});
  const [subTitle, setSubTitle] = useState<string>('');
  const dispatch = useDispatch();
  useEffect(() => {
    handleVerificationAccount(history.location.pathname.replace(/^.+\//gm, ''));
  }, []);

  const verificationResult = (response: any) => {
    if (!response.status) {
      setStatus(false);
      setSubTitle(response.msg);
    } else {
      setStatus(true);
    }
    setDisable(!disable);
  };

  const handleVerificationAccount = (hash: string) => {
    dispatch(
      verificationAccountRequest({
        data: { hash: hash },
        callback: (response: any) => verificationResult(response),
      }),
    );
  };

  const handleSubmit = () => {
    history.push('/sign-in');
  };

  const onResendConfirmEmail = () => {
    const { errors } = validate(emailValidator, { username });
    if (Object.values(errors || {}).length) {
      setErrors(errors);
      return;
    }
    dispatch(
      resentEmailConfirmRequest({
        data: { username: username },
        callback: (response: any) => {
          if (response.status) {
            history.push(ROOTS.SIGNIN);
          } else {
            setSubTitle(response.msg);
          }
        },
      }),
    );
  };

  const handleChangeInput = (name: string, value: string) => {
    setErrors({});
    setUsername(value);
  };

  return (
    <EmailVerifiedView
      onSubmit={handleSubmit}
      status={status}
      onResendConfirmEmail={onResendConfirmEmail}
      handleChangeInput={handleChangeInput}
      errors={errors}
      username={username}
      subTitle={subTitle}
      disable={disable}
    />
  );
};

export default EmailVerifiedContainer;
