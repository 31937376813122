import { PaymentActionsTypes, GET_SCHEDULE_OPTIONS_SUCCESS } from './paymentsActions';
import { PaymentDetailsModel } from '../../models';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';

export type PaymentState = {
  scheduleOptions: PaymentDetailsModel | null;
};

const initialState: PaymentState = {
  scheduleOptions: null,
};

const paymentsReducer = (state: PaymentState = initialState, action: PaymentActionsTypes): PaymentState => {
  switch (action.type) {
    case GET_SCHEDULE_OPTIONS_SUCCESS: {
      return {
        ...state,
        scheduleOptions: action.payload,
      };
    }
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default paymentsReducer;
