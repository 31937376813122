import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { CreditModel, IRouteParamsBase } from '../../../../models';
import { Button, CreditStatusLabel, EmptyLink, Link, Loader } from '../../../controls';
import { BelovedOrgTabs, HeadlineOrganization } from '../../../common';
import { StateType } from '../../../../store/reducers';
import { getCreditsList, updateCredit } from '../../../../store/credit/creditActions';
import { belGetOrganizationDetails } from '../../../../store/beloved/organizations/organizationsActions';
import useHasAccess from '../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions, CreditActions, CreditStatuses } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import CreateCredit from './parts/CreateCredit';
import UpdateCredit from './parts/UpdateCredit';

const OrgCredits = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { orgId } = useParams<IRouteParamsBase>();

  const currentBelOrg = useSelector((state: StateType) => state.belOrganizations.currentBelOrg);
  const creditList = useSelector((state: StateType) => state.credit.creditList);
  const canCreateCredit = useHasAccess(BelovedPermissions.CREDITS_CREATE);
  const canUpdagteCredit = useHasAccess(BelovedPermissions.CREDITS_UPDATE);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState<CreditModel | null>(null);
  const [updateCreditNotesOpen, setUpdateCreditNotesOpen] = useState(false);

  useEffect(() => {
    dispatch(
      getCreditsList({
        orgId: Number(orgId),
      }),
    );
    if (!currentBelOrg || (currentBelOrg && currentBelOrg.id !== Number(orgId))) {
      dispatch(
        belGetOrganizationDetails({
          orgId: Number(orgId),
        }),
      );
    }
  }, [orgId]);

  const handleOpenForm = () => {
    setCreateModalOpen(true);
  };

  const handleActions = (actionName: CreditActions, creditId: number) => {
    dispatch(
      updateCredit({
        creditId,
        data: {
          organization_id: Number(orgId),
          action: actionName,
        },
      }),
    );
  };

  const renderButton = (credit: CreditModel) => {
    if (!canUpdagteCredit) return null;
    if (credit.status === CreditStatuses.AVAILABLE) {
      if (credit.acquisition_method_id === 1 || credit.acquisition_method_id === 2) {
        return (
          <a
            onClick={() => handleActions(CreditActions.REIMBURSE, credit.id)}
            className={`b-panelItem__link`}
          >
            {t('common:btn.reimburse')}
          </a>
        );
      }
      return (
        <a onClick={() => handleActions(CreditActions.REVOKE, credit.id)} className={`b-panelItem__link`}>
          {t('common:btn.revoke')}
        </a>
      );
    }
    if (credit.status === CreditStatuses.REIMBURSED || credit.status === CreditStatuses.REVOKED) {
      return (
        <a onClick={() => handleActions(CreditActions.RE_ISSUE, credit.id)} className={`b-panelItem__link`}>
          {t('common:btn.re-issue')}
        </a>
      );
    }
    return null;
  };

  const handleEditCredit = (credit: CreditModel, notesOpen?: boolean) => {
    setUpdateModalOpen(credit);
    if (notesOpen) {
      setUpdateCreditNotesOpen(notesOpen);
    }
  };

  const handleCloseEditCredit = () => {
    setUpdateModalOpen(null);
    setUpdateCreditNotesOpen(false);
  };

  const onSuccessCreditAction = () => {
    dispatch(
      getCreditsList({
        orgId: Number(orgId),
      }),
    );
  };

  const renderContent = () => {
    if (!creditList) return <Loader fitParent />;

    if (creditList.length) {
      return (
        <>
          <div className="b-page__cardsList">
            {creditList.map(item => {
              return (
                <div key={`elm-${item.id}`} className="b-panelItem b-page__cardItem ">
                  <div className="flex-column justify-content-between d-flex h-100">
                    <div>
                      <h2 className="b-panelItem__statusContainer">
                        <div className="b-credits__statusCont">
                          <CreditStatusLabel credit={item} />
                          {item.status === CreditStatuses.REIMBURSED ||
                          item.status === CreditStatuses.REVOKED ? (
                            <div
                              className={`b-credits__statusDate ${
                                item.status === CreditStatuses.REIMBURSED ? '-orange' : ''
                              }`}
                            >
                              {t('common:label.on')}{' '}
                              {dayjs(new Date(item.status_date || '')).format('MM.DD.YYYY')}
                            </div>
                          ) : null}
                        </div>
                        <Link className={`b-panelItem__link`} onClick={() => handleEditCredit(item)}>
                          {t('common:btn.edit')}
                        </Link>
                      </h2>
                      <div className="b-elmItem__row">
                        <div className="b-elmItem__title">{t('main:credits.credit-type')}</div>
                        <div className="b-elmItem__val">{item.credit_name}</div>
                      </div>
                      <div className="b-elmItem__row">
                        <div className="b-elmItem__title">{t('main:credits.acquisition-date')}</div>
                        <div className="b-elmItem__val">
                          {dayjs(new Date(item.acquisition_date)).format('MM.DD.YYYY')}
                        </div>
                      </div>
                      <div className="b-elmItem__row">
                        <div className="b-elmItem__title">{t('main:credits.acquisition-method')}</div>
                        <div className="b-elmItem__val">{item.acquisition_method_name}</div>
                      </div>
                      {item.beloved_leader ? (
                        <div className="b-elmItem__row">
                          <div className="b-elmItem__title">{t('main:credits.beloved-leader')}</div>
                          <div className="b-elmItem__val">
                            {item.beloved_leader.first_name} {item.beloved_leader.last_name}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <Link
                        onClick={() => handleEditCredit(item, true)}
                        className="d-flex align-items-center "
                      >
                        <div className="b-button-icon -colorNavy -noHover -link_res b-ewpElementsTree__icon" />
                        <div className="font-m ml-2 mr-3 font-weight-bold b-link">
                          {t('common:btn.notes')}
                        </div>
                      </Link>
                      {renderButton(item)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {canCreateCredit ? (
            <div className="b-credits__createBtnCont">
              <Button title={t('common:btn.add-beloved-credit')} onPress={handleOpenForm} />
            </div>
          ) : null}
        </>
      );
    }

    return (
      <div className="b-credits__noContent">
        <div className="b-credits__noContentText">{t('main:credits.no-credits')}</div>
        {canCreateCredit ? (
          <Button title={t('common:btn.add-beloved-credit')} onPress={handleOpenForm} />
        ) : null}
      </div>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <div className="b-page__header -desktop">
          <HeadlineOrganization
            orgName={currentBelOrg?.name || ''}
            orgId={currentBelOrg?.id || ''}
            headline={t('common:tabs.beloved-credits')}
            customClasses={'-noDesktopMb'}
          />
        </div>
        <BelovedOrgTabs activeItemAlias={'credits'} />
        <div className="b-page__cards">{renderContent()}</div>
      </main>
      {createModalOpen ? <CreateCredit setCreateModalOpen={setCreateModalOpen} /> : null}
      {updateModalOpen ? (
        <UpdateCredit
          onClose={handleCloseEditCredit}
          notesOpen={updateCreditNotesOpen}
          credit={updateModalOpen}
          onSuccessUpdate={onSuccessCreditAction}
        />
      ) : null}
    </>
  );
};

export default OrgCredits;
