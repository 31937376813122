import { validate, Validator } from './validatior';
import { basicDateFormat } from './dateHelper';
import { BelResourceOptionModel, ResourceFileType, ISelectOptions, PaginationModel } from '../models';
import { Column } from 'react-data-grid';
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';
import i18n from 'i18next';

export const roundNumber = (val: string | number, multiplier: number = 100): number => {
  return Math.round(Number(val) * multiplier) / multiplier;
};

export const isEmptyObject = (obj: object | null | undefined) => {
  if (!obj) return true;
  return !Object.values(obj).length;
};

export const generateHash = () =>
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export const arrToObject = (arr: any[]) => {
  let rv: any = {};
  for (let i = 0; i < arr.length; ++i) {
    if (arr[i] !== undefined) {
      rv[arr[i].id] = arr[i];
    }
  }
  return rv;
};

export const cutString = (str: string, length: number) => {
  if (str.length <= length) {
    return str;
  }
  return `${str.slice(0, length)}...`;
};

export const capitalize = (str: string): string => {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
};

export function convertForSelect(list: any[], fieldNames: string[]): ISelectOptions[] {
  return list.map((item: any) => ({
    value: item[fieldNames[0]],
    label: item[fieldNames[1]],
  }));
}

export const getOrgIdFormRoute = (): number | undefined => {
  const { pathname } = window.location;
  const comps = pathname.split('/');
  const filtered = comps.filter(item => !!item);
  return Number(filtered[1]);
};

type SimpleArr = (string | number)[];
export const filterByArray = (sourceArr: SimpleArr, filterArr: SimpleArr): SimpleArr => {
  return sourceArr.filter(item => !filterArr.includes(item));
};

export const compareArrays = (initialArray: any[], newArray: any[]) => {
  return initialArray.every(value => {
    return newArray.indexOf(value) !== -1;
  });
};

export const arrayUniqueByKey = (array: any[], key: string) => {
  // @ts-ignore
  const newArray = [...new Map(array.map(item => [item[key], item])).values()] || [];
  return newArray;
};

export const isValidFields = (validator: Validator, fields: any): boolean => {
  const { errors } = validate(validator, fields);
  return !!Object.values(errors || {}).length;
};

export const convertUserStatus = (status: string | null, status_date: string | null): string => {
  const date = status_date ? basicDateFormat(new Date(status_date)) : '';
  return `${status || ''} ${status_date ? 'on' : ''} ${date}`;
};

export const onlyUnique = (value: any, index: number, self: any) => {
  return self.indexOf(value) === index;
};

export const createArrayFrom = (inputArray: any[], length: number): any[] => {
  let res: any[] = [];
  let counter = 0;
  const run = () => {
    for (let i = 0; i < inputArray.length; i++) {
      res.push(inputArray[i]);
      counter++;
      if (i === length - 1) {
        break;
      }
      if (counter === length) {
        break;
      }
      if (i === inputArray.length - 1) {
        run();
      }
    }
  };
  run();
  return res;
};

export const convertResourceOptions = (options: BelResourceOptionModel[]): ISelectOptions[] => {
  return options.map(opt => ({ label: opt.title, value: opt.id }));
};

export const _getSortingFields = (columns: Column<any>[]) => {
  return columns.filter(col => {
    return col.sortable;
  });
};

export const getResourceType = (path: string): ResourceFileType => {
  let fileType: ResourceFileType = ResourceFileType.DEFAULT;

  if (path.match('pdf')) {
    fileType = ResourceFileType.PDF;
  } else if (path.match(/.*\.(gif|jpe?g|bmp|png)$/gim)) {
    fileType = ResourceFileType.IMAGE;
  } else if (path.match(/(youtube|youtu)/)) {
    fileType = ResourceFileType.VIDEO;
  }
  return fileType;
};

export const copyStringToClipboard = async (str: string) => {
  await navigator.clipboard.writeText(str);
  toast.success(i18n.t('common:label.copied'));
};

export const copyLinkToClipboard = (
  href: string,
  title: string,
  isCurrentPage?: boolean,
  toastText?: string,
) => {
  toastText = toastText || i18n.t('common:label.copied');
  if (!window.ClipboardItem) {
    navigator.clipboard.writeText(href).then(() => {
      toast.success(toastText);
    });
    return;
  }

  const type = 'text/html';
  const blob = new Blob(
    [`<a ${isCurrentPage ? '' : 'target="_blank" rel="noreferrer"'} href="${href}">${title}</a>`],
    { type },
  );
  const data = [new ClipboardItem({ [type]: blob })];

  navigator.clipboard.write(data).then(() => {
    toast.success(toastText);
  });
};

export const excludeFromArray = <T>(key: string, sourceArr: T[], filterArr: T[]): T[] => {
  const newArray: T[] = [];
  sourceArr.map(s => {
    // @ts-ignore
    if ((filterArr || []).find((f: T) => f[key] === s[key])) {
      return null;
    }
    newArray.push(s);
  });
  return newArray;
};

export const urlWithToken = (url: string) => {
  const token = localStorage.getItem('token');
  return `${url}?token=${token}`;
};

export const convertToURL = (url: string = ''): string => {
  let newUrl = '';
  if (!url.match(/^https?:\/\//i)) {
    newUrl = 'http://' + url;
  } else {
    newUrl = url;
  }
  return newUrl || '';
};

export const sanitizedHTML = (html: string | null) => {
  return DOMPurify.sanitize(html || '');
};

export function getOrgIdsFromEntity(
  selectedEntitiesIds: number[],
  data: PaginationModel<any[]> | null,
): number[] {
  if (!data) return [];
  let result: number[] = [];
  selectedEntitiesIds.forEach(id => {
    const entity = data.result.find(item => item.id === id);
    if (entity) {
      result.push(entity.organization_id);
    }
  });
  return result;
}

export function getEntitiesByIds<T extends { id: number }>(
  selectedEntitiesIds: number[],
  data: PaginationModel<T[]> | null,
): T[] {
  if (!data) return [];
  let result: T[] = [];
  selectedEntitiesIds.forEach(id => {
    const entity = data.result.find(item => item.id === id);
    if (entity) {
      result.push(entity);
    }
  });
  return result;
}

export const convertToNum = (val: string | number): number => {
  return typeof val !== 'number' ? Number(val.replace(/\D/g, '')) : val;
};
