import React, { FC, useState } from 'react';
import { Modal } from '../../../../common';
import { Button, Input, Loader } from '../../../../controls';
import { ISelectOptions, NewResourceOptionType } from '../../../../../models';
import Api from '../../../../../services/api';
import { belGetResourceOptions } from '../../../../../store/beloved/resources/resourcesActions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface IProps {
  optionType: NewResourceOptionType;
  onClose: () => void;
  onSubmit: (optionValue: ISelectOptions, fieldName: NewResourceOptionType) => void;
}

const CreateOptionForm: FC<IProps> = ({ optionType, onClose, onSubmit }) => {
  const [value, setValue] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState('');

  const getTitle = () => {
    switch (optionType) {
      case 'categories':
        return `${t('common:label.new')} ${t('common:label.category')}`;
      case 'sectors':
        return `${t('common:label.new')} ${t('common:label.target-sector')}`;
      case 'access_type':
        return `${t('common:label.new')} ${t('common:label.access-type')}`;
      case 'tags':
        return `${t('common:label.new')} ${t('common:label.tag')}`;
    }
  };

  const handleChangeInput = (value: string) => {
    setValue(value);
    setError('');
  };

  const _getUrl = () => {
    return optionType === 'access_type' ? 'access_types' : optionType;
  };

  const handleError = (e: any) => {
    setLoading(false);
    if (e.response.status === 400) {
      let error = e.response.data?.title;
      if (error.length) {
        error = error[0];
      }
      setError(error);
    } else {
      toast.error(t('common:toast.error'));
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await Api.post(`utils/resource/${_getUrl()}`, { title: value });
      setLoading(false);
      if (res) {
        dispatch(
          belGetResourceOptions({
            callback: () => {
              onSubmit({ value: res.id, label: res.title }, optionType);
              onClose();
            },
          }),
        );
      }
    } catch (e: any) {
      handleError(e);
    }
  };

  return (
    <Modal className="b-adminToolRoles__newRoleModal" width={450} onClose={onClose} title={getTitle()}>
      {loading ? <Loader fitParent /> : null}
      <div>
        <div className="b-adminToolRoles__newRoleInput">
          <Input
            error={error}
            autoFocus
            type={'text'}
            handleChange={handleChangeInput}
            placeholder={t('common:label.type-here')}
            value={value}
          />
        </div>

        <div className="b-modal__buttons">
          <Button
            className="button-mh"
            size={'large'}
            type={'transparency'}
            title={t('common:btn.cancel')}
            onPress={onClose}
          />
          <Button
            disabled={!value.length}
            className="button-mh"
            size={'large'}
            title={t('common:btn.save')}
            onPress={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateOptionForm;
