import React, { useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

interface IInput {
  error?: string;
  placeholder?: string;
  value: string;
  handleChange?: (value: string) => void;
  handleUnselect?: () => void;
  onFocus?: (name: string) => void;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  onBlurPhoneValidation?: (isValid: boolean) => void;
}

const PhoneInputComponent = ({
  error,
  placeholder,
  handleChange,
  handleUnselect,
  onFocus,
  disabled,
  value,
  onBlurPhoneValidation,
}: IInput) => {
  const [isFocus, setFocus] = useState(false);
  const [isValid, setIsValid] = useState<boolean | null>(null);

  const onInputFocus = (e: any) => {
    setFocus(true);
    onFocus && onFocus(e.target.name);
  };

  const onBlur = () => {
    setFocus(false);
    handleUnselect && handleUnselect();
    if (typeof isValid === 'boolean' && onBlurPhoneValidation) {
      onBlurPhoneValidation(isValid);
    }
  };

  const onChange = (
    phone: string,
    data: CountryData,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string,
  ) => {
    const maxLength = data.format.length <= 20 ? data.format.length : 20;
    if (data.countryCode === 'it') {
      setIsValid(formattedValue.length >= 14);
    } else {
      setIsValid(formattedValue.length >= maxLength);
    }
    if (phone) {
      handleChange && handleChange(`+${phone}`);
    } else {
      handleChange && handleChange(``);
    }
  };

  return (
    <div className={`b-phoneNumber ${isFocus ? '-focus' : ''} ${error ? '-error' : ''}`}>
      <PhoneInput
        disabled={disabled}
        country={'us'}
        value={value}
        onChange={onChange}
        specialLabel={placeholder}
        onFocus={onInputFocus}
        onBlur={onBlur}
        preferredCountries={['us']}
        countryCodeEditable
        inputProps={{
          'aria-label': 'phone-number',
        }}
      />
      <div className={'error-notification'}>
        <span className={'error-notification-text'}>{error}</span>
      </div>
    </div>
  );
};

export default PhoneInputComponent;
