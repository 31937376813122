import { BelovedDashboardTabs } from '../../../../common';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ReactMapGL, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
  ScaleControl,
} from 'react-map-gl';
import Pin from './pin';
import './mapbox-gl.css';
import {
  CoordinatesAddressModel,
  DashboardMapPointType,
  KeyValueModel,
  OrgAddressModel,
  PmAddressModel,
} from '../../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { getDashboardMap, getDashboardMapPointData } from '../../../../../store/dashboard/dashboardActions';
import { Loader } from '../../../../controls';
import { MAP_GL_KEY } from '../../../../../constants';
import styles from '../../../../common/Alert/Alert.module.scss';
import CloseIc from '../../../../../assets/images/icons/round-navigation-close.svg';
import { useIsDarkMode } from '../../../../../hooks/common/use-is-dark-mode';

const DashboardMap = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dashboardMap = useSelector((state: StateType) => state.dashboard.dashboardMap);
  const [pmInfo, setPmInfo] = useState<PmAddressModel | null>(null);
  const [orgInfo, setOrgInfo] = useState<OrgAddressModel | null>(null);
  const [latestOpened, setLatestOpened] = useState<DashboardMapPointType | null>(null);
  const [indicators, setIndicators] = useState<KeyValueModel<boolean>>({
    org: true,
    pm: true,
  });
  const [currentPagePopup, setCurrentPagePopup] = useState<KeyValueModel<number>>({
    org: 0,
    pm: 0,
  });
  const [addressesPoint, setAddressesPoint] = useState<KeyValueModel<PmAddressModel[] | OrgAddressModel[]>>({
    org: [],
    pm: [],
  });
  const { isDarkMode } = useIsDarkMode();

  useEffect(() => {
    setLatestOpened('pm');
  }, [addressesPoint.pm]);
  useEffect(() => {
    setLatestOpened('org');
  }, [addressesPoint.org]);

  useEffect(() => {
    dispatch(getDashboardMap({}));
  }, []);

  const toggleIndicator = (name: string) => {
    setIndicators({
      ...indicators,
      [name]: !indicators[name],
    });
  };

  const handlePopup = (item: CoordinatesAddressModel, type: DashboardMapPointType) => {
    setAddressesPoint(prevState => ({
      ...prevState,
      [type]: [],
    }));
    setCurrentPagePopup(prevState => ({
      ...prevState,
      [type]: 0,
    }));
    let allAddressesPoint: any[] = [];
    if (type === 'org') {
      allAddressesPoint = dashboardMap.organizations.filter(f => f.lat === item.lat && f.lng === item.lng);
    } else if (type === 'pm') {
      allAddressesPoint = dashboardMap.organization_teams.filter(
        f => f.lat === item.lat && f.lng === item.lng,
      );
    }
    if (allAddressesPoint.length > 1) {
      setAddressesPoint(prevState => ({
        ...prevState,
        [type]: allAddressesPoint,
      }));
    }
    dispatch(
      getDashboardMapPointData({
        data: {
          id: Number(allAddressesPoint[0].id) || Number(allAddressesPoint[0].user_id),
          type: type,
        },
        callback: res => {
          if (type === 'org') {
            setOrgInfo(res as OrgAddressModel);
          } else if (type === 'pm') {
            setPmInfo(res as PmAddressModel);
          }
        },
      }),
    );
  };

  const renderMarkers = (
    addresses: CoordinatesAddressModel[],
    color: string,
    type: DashboardMapPointType,
    size?: number,
    zIndex?: number,
  ) => {
    return addresses
      .map((item, index) => {
        if (!(item.lng && item.lat)) {
          return null;
        }
        return (
          <Marker
            key={`marker-${index}`}
            longitude={item.lng}
            latitude={item.lat}
            anchor="center"
            style={{
              zIndex: zIndex,
            }}
          >
            <Pin size={size} onClick={() => handlePopup(item, type)} color={color} />
          </Marker>
        );
      })
      .filter(f => !!f);
  };

  if (!Object.values(dashboardMap)) {
    return <Loader />;
  }

  const getNextAddress = (item: PmAddressModel | OrgAddressModel, type: DashboardMapPointType) => {
    dispatch(
      getDashboardMapPointData({
        data: {
          // @ts-ignore
          id: Number(item.id || item.user_id),
          type: type,
        },
        callback: res => {
          if (type === 'org') {
            setOrgInfo(res as OrgAddressModel);
          } else if (type === 'pm') {
            setPmInfo(res as PmAddressModel);
          }
        },
      }),
    );
  };

  const handlePrevPag = (type: DashboardMapPointType) => {
    const currentPage = currentPagePopup[type];
    const address = addressesPoint[type];
    if (currentPage <= 0) {
      return;
    }
    getNextAddress(address[currentPage - 1], type);
    setCurrentPagePopup(prevState => ({
      ...prevState,
      [type]: currentPage - 1,
    }));
  };

  const handleNextPag = (type: DashboardMapPointType) => {
    const currentPage = currentPagePopup[type];
    const address = addressesPoint[type];

    if (currentPage + 1 >= address.length) {
      return;
    }
    getNextAddress(address[currentPage + 1], type);
    setCurrentPagePopup(prevState => ({
      ...prevState,
      [type]: currentPage + 1,
    }));
  };

  const closePopup = (popupMethod: Function, type: DashboardMapPointType) => {
    popupMethod(null);
    setAddressesPoint(prevState => ({
      ...prevState,
      [type]: [],
    }));
    setCurrentPagePopup(prevState => ({
      ...prevState,
      [type]: 0,
    }));
  };

  const renderPopup = (
    item: OrgAddressModel | PmAddressModel,
    popupMethod: Function,
    type: DashboardMapPointType,
  ) => {
    const isInternation = !(item.address.country && item.address.country.toLowerCase() === 'us');
    const currentPage = currentPagePopup[type];
    const address = addressesPoint[type];

    return (
      <div className={`b-mapBox__popup`}>
        <div className={`b-mapBox__popup_header`}>
          {'name' in item && <h2>{item.name}</h2>}
          {'first_name' in item && (
            <h2>
              {item.first_name} {item.last_name}
            </h2>
          )}
          <a onClick={() => closePopup(popupMethod, type)} href="#" className={styles.close}>
            <img src={CloseIc} alt={t('common:alt.closeIc')} />
          </a>
        </div>
        <div className={`b-mapBox__popup_info ${isInternation ? '-internal' : ''}`}>
          <div className="b-mapBox__popup_block">
            <div className={'b-mapBox__popup_left'}>{t('common:label.address')}</div>
            <div className={'b-mapBox__popup_right'}>{item.address.address}</div>
          </div>
          <div className="b-mapBox__popup_block">
            <div className={'b-mapBox__popup_left'}>
              {isInternation ? t('common:label.international.city') : t('common:label.us.city')}
            </div>
            <div className={'b-mapBox__popup_right'}>{item.address.city}</div>
          </div>
          <div className="b-mapBox__popup_block">
            <div className={'b-mapBox__popup_left'}>
              {isInternation ? t('common:label.international.state') : t('common:label.us.state')}
            </div>
            <div className={'b-mapBox__popup_right'}>{item.address.state}</div>
          </div>
          <div className="b-mapBox__popup_block">
            <div className={'b-mapBox__popup_left'}>
              {isInternation ? t('common:label.international.postal') : t('common:label.us.postal')}
            </div>
            <div className={'b-mapBox__popup_right'}>{item.address.postal}</div>
          </div>
          {'organizations' in item && (
            <div className="b-mapBox__popup_block mt-4">
              <div className={'b-mapBox__popup_left'}>{t('common:label.org')}</div>
              <div className={'b-mapBox__popup_right'}>
                {item.organizations.split(',').map((org, i) => {
                  return <div key={`${org}-${i}`}>{org}</div>;
                })}
              </div>
            </div>
          )}
          {'org_pm' in item && (
            <div className="b-mapBox__popup_block mt-4">
              <div className={'b-mapBox__popup_left'}>{t('common:label.pm')}</div>
              <div className={'b-mapBox__popup_right'}>{item.org_pm}</div>
            </div>
          )}
        </div>
        <div className={`b-mapBox__popup_footer`}>
          <div>
            {item.address.lat}, {item.address.lng}
          </div>
          {address.length ? (
            <div className={`slider`}>
              <div
                className={`prev arrow ${(currentPage === 0 && '-disabled') || ''}`}
                onClick={() => handlePrevPag(type)}
              />
              <span>
                {currentPage + 1}/{address.length}
              </span>
              <div
                className={`next arrow ${(address.length === currentPage + 1 && '-disabled') || ''}`}
                onClick={() => handleNextPag(type)}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <main className={'b-page'}>
      <h1 className={'b-page__title'}>{t('main:dashboard.dashboard')}</h1>
      <div className="b-heading">
        <BelovedDashboardTabs activeItemAlias={'map'} />
        <button type="button" className="btn-datepicker">
          2022 {t('common:btn.fiscal-year')}
        </button>
      </div>
      <div className="b-mapBox">
        <h2 className="b-mapBox__title">{t('main:dashboard.ea-globe')}</h2>
        <div className="b-mapBox__holder">
          <ReactMapGL
            mapboxAccessToken={MAP_GL_KEY}
            trackResize
            initialViewState={{
              latitude: 40,
              longitude: -100,
              zoom: 3.5,
              bearing: 0,
              pitch: 0,
            }}
            // isDarkMode
            mapStyle={`mapbox://styles/mapbox/${isDarkMode ? 'dark' : 'light'}-v10`}
          >
            <GeolocateControl position="top-left" />
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
            <ScaleControl />
            {indicators.org && renderMarkers(dashboardMap.organizations, '#cb2527', 'org', 11, 1)}
            {indicators.pm && renderMarkers(dashboardMap.organization_teams, '#fb9312', 'pm', 7, 2)}
            {orgInfo && (
              <Popup
                // closeOnMove={true}
                closeButton={false}
                longitude={Number(orgInfo.address.lng)}
                latitude={Number(orgInfo.address.lat)}
                closeOnClick={false}
                // offset={markerOffsetObject}
                anchor={'bottom'}
                onClose={() => setOrgInfo(null)}
                maxWidth={'300px'}
                style={{
                  zIndex: latestOpened === 'org' ? 4 : 3,
                }}
              >
                {renderPopup(orgInfo, setOrgInfo, 'org')}
              </Popup>
            )}
            {pmInfo && (
              <Popup
                // closeOnMove={true}
                closeButton={false}
                // offset={markerOffsetObject}
                anchor={'bottom'}
                longitude={Number(pmInfo.address.lng)}
                latitude={Number(pmInfo.address.lat)}
                closeOnClick={false}
                onClose={() => setPmInfo(null)}
                maxWidth={'300px'}
                style={{
                  zIndex: latestOpened === 'pm' ? 4 : 3,
                }}
              >
                {renderPopup(pmInfo, setPmInfo, 'pm')}
              </Popup>
            )}
          </ReactMapGL>
        </div>
        <div className={'b-mapBox__buttons'}>
          <div
            className={`b-mapBox__button ${(!indicators.org && '-disabled') || ''}`}
            onClick={() => toggleIndicator('org')}
          >
            <span className={`b-mapBox__indicator b-mapBox__indicator_org`} />
            <span className={`b-mapBox__indicator_text`}>
              {t('common:headlines.organization-headquarters')}
            </span>
          </div>
          <div
            className={`b-mapBox__button ${(!indicators.pm && '-disabled') || ''}`}
            onClick={() => toggleIndicator('pm')}
          >
            <span className={`b-mapBox__indicator b-mapBox__indicator_pm`} />
            <span className={`b-mapBox__indicator_text`}>{t('common:headlines.pm-locations')}</span>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DashboardMap;
