import { Button } from '../../../../../controls';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EwpPreparationStepEnum, IRouteParamsBase } from '../../../../../../models';
import { Stepper } from '../../../../../common';
import { useHistory } from 'react-router';
import { ROOTS } from '../../../../../../routes/routes';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../../store/reducers';

interface IProps {
  setCurrentStep: (step: EwpPreparationStepEnum) => void;
  cancelStep: () => void;
}

export const PreparationEWPStep4 = ({ setCurrentStep, cancelStep }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { orgId } = useParams<IRouteParamsBase>();
  const user = useSelector((state: StateType) => state.profile.authUser);
  const isBelovedUser = user?.beloved_user;

  const handleNextStep = () => {
    if (history.location.state?.ewpId) {
      if (isBelovedUser) {
        history.push(ROOTS.BELOVED_EWP_DETAILS.replace(':orgId', `${orgId}`));
      } else {
        history.push(ROOTS.EWP_DETAILS.replace(':orgId', `${orgId}`));
      }
    } else {
      setCurrentStep(EwpPreparationStepEnum.finish);
    }
  };

  return (
    <>
      <div className="b-step__container -centerContent">
        <div className={'flex-center -column'}>
          <span className={'b-step__subTitle mb-3'}>{t('common:headlines.ewp-preparation')}</span>
          <h1 className={'b-step__bigTitle'}>{t('awa:M129.title')}</h1>
        </div>
        <Stepper totalSteps={4} currentStep={4} />
        <div className="b-step__p mt-4 mb-5">{t('awa:M129.msg.p1')}</div>
        <div className="b-step__p mt-4 mb-5">{t('awa:M129.msg.p2')}</div>
        <div className="b-step__wBox w-100">
          <div className={'b-step__orangeBold flex-center mb-3'}>{t('main:ewp-create.task')}</div>
          <div className="b-step__task">{t('awa:M129.msg.p3')}</div>
        </div>
        <div className={`b-step__bottom`}>
          <div className={`b-step__bottom-left`}>
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={cancelStep}
              title={t('common:btn.cancel')}
            />
          </div>
          <div className={'flex-center'}>
            <Button
              className={'btn -type_form mr-2'}
              type={'bordered'}
              onPress={() => setCurrentStep(EwpPreparationStepEnum.step3)}
              title={t('common:btn.back')}
            />
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={() => handleNextStep()}
              title={t('common:btn.next')}
              enableEnter
            />
          </div>
        </div>
      </div>
    </>
  );
};
