import React, { FC, useEffect, ReactNode } from 'react';
import styles from './Modal.module.scss';
import Logo from '../../../assets/images/icons/arrow-up.svg';
import Image from '../../controls/Image/Image';
import { isMobileOnly } from 'react-device-detect';
import { disableScroll, enableScroll } from '../../../helpers/scroll-lock';
import CloseIc from '../../../assets/images/icons/round-navigation-close.svg';
import FocusTrap from 'focus-trap-react';
import { useTranslation } from 'react-i18next';

interface IProps {
  width?: number;
  minHeight?: number;
  className?: string;
  onClose: () => void;
  title?: string | ReactNode;
  classNameTitle?: string;
  classNameBack?: string;
  showCloseBtn?: boolean;
  modalStyles?: any;
}

const Modal: FC<IProps> = ({
  children,
  width,
  className,
  onClose,
  minHeight,
  title,
  classNameTitle,
  classNameBack,
  showCloseBtn,
  modalStyles,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
    disableScroll();
    return () => {
      enableScroll();
    };
  }, []);

  let customStyles: any = modalStyles || {};
  if (width) customStyles.maxWidth = width;
  if (minHeight) customStyles.minHeight = minHeight;

  return (
    <>
      <FocusTrap active={true}>
        <div className={`${styles.back} ${classNameBack || ''}`} onClick={onClose}>
          <div
            onClick={e => e.stopPropagation()}
            className={`
                  ${styles.modal}
                  ${className || ''}
                `}
            style={customStyles}
          >
            {showCloseBtn && (
              <a
                onClick={e => {
                  e.preventDefault();
                  onClose();
                }}
                href="#"
                className={styles.modal__close}
              >
                <img src={CloseIc} alt={t('common:alt.closeIc')} />
              </a>
            )}

            {title && (
              <div
                className={`${styles.wrapTitle} ${isMobileOnly ? styles.wrapMobileTitle : ''} ${
                  classNameTitle || ''
                }`}
              >
                {isMobileOnly && (
                  <div className={styles.backBtn} onClick={onClose}>
                    <Image
                      className={styles.icon}
                      width={'24px'}
                      height={'24px'}
                      source={Logo}
                      alt={t('common:alt.arrow')}
                    />
                  </div>
                )}
                <span className={styles.title}>{title}</span>
              </div>
            )}
            {children}
          </div>
        </div>
      </FocusTrap>
    </>
  );
};

Modal.defaultProps = {
  width: 545,
  showCloseBtn: true,
};

export default Modal;
