import React, { useEffect, useState } from 'react';
import { getCohortMessage } from '../../../../../store/cohort/cohortActions';
import { IRouteParamsBase, CohortMessageModel } from '../../../../../models';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../controls';
import { ROOTS } from '../../../../../routes/routes';
import { basicDateFormat } from '../../../../../helpers';
import { useIsAccessEditCohort } from '../../../../../hooks/beloved/cohorts/use-edit-access';
import { useGetCohortDetails } from '../../../../../hooks/beloved/cohorts/use-get-cohort-details';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';
import { HeadlineOrganization } from '../../../../common';

interface IRouteParams extends IRouteParamsBase {
  id: string;
  messageId: string;
}

const CohortSessionDetails = () => {
  const { t } = useTranslation();
  const { id, messageId } = useParams<IRouteParams>();
  const [message, setMessage] = useState<CohortMessageModel | null>();
  const dispatch = useDispatch();
  const history = useHistory();
  const cohort = useGetCohortDetails();
  const isAccess = useIsAccessEditCohort(cohort);

  useEffect(() => {
    dispatch(
      getCohortMessage({
        data: {
          id: Number(messageId),
        },
        callback: (res: CohortMessageModel) => {
          setMessage(res);
        },
      }),
    );
  }, []);

  const navBack = (e?: any) => {
    e && e.preventDefault();
    history.go(-1);
  };

  const navToEditMessage = (e: any) => {
    e.preventDefault();
    history.push(ROOTS.BELOVED_COHORT_MESSAGE.replace(':id', `${id}`).replace(':messageId', `${messageId}`));
  };

  const renderContent = () => {
    if (!message) return <Loader />;
    return (
      <>
        <div className="b-cohortSession">
          <div className={`b-cohortSession__subTitleBlock`}>
            <h2 className={`b-cohortSession__subTitle`}>{message.subject}</h2>
            {isAccess ? (
              <a href="#" className="b-link-icon -edit" onClick={navToEditMessage}>
                {t('common:btn.edit-message')}
              </a>
            ) : null}
          </div>
          <dl className="b-panelData__row">
            <dt>
              <div className="b-cohortSession__fieldTitle">{t('common:label.date')}:</div>
            </dt>
            <dd>
              <div className="b-cohortSession__fieldValue">{basicDateFormat(message.created_at)}</div>
            </dd>
          </dl>
          <dl className="b-panelData__row">
            <dt>
              <div className="b-cohortSession__fieldTitle">{t('common:label.description')}:</div>
            </dt>
            <dd>
              <div className="b-cohortSession__fieldValue -preLine">
                <p>{message.description}</p>
              </div>
            </dd>
          </dl>
        </div>
      </>
    );
  };

  return (
    <main className="b-page">
      <div className="b-page__header -desktop">
        <HeadlineOrganization
          orgName={cohort?.name || ''}
          orgId={cohort?.id || ''}
          headline={t('common:headlines.cohort-program')}
          customClasses={'-noDesktopMb mr-4'}
          backBtn={<EmptyLink onClick={navBack} className="b-button-icon-back" />}
          noOrgLogo
        />
      </div>
      <div className="contentContainer">{renderContent()}</div>
    </main>
  );
};

export default CohortSessionDetails;
