import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import {
  EWPKeyActionCreateData,
  EWPKeyActionModel,
  ISelectOptions,
  OrganizationUserModel,
} from '../../../../../models';
import { useEwpApi } from '../../../../../hooks/ewp/use-ewp-api';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { useGetKaOptions } from '../../../../../hooks/ewp/use-get-ka-options';
import { CreateOrgUserModal, OrgBreadcrumbs } from '../../../../common';
import { Button, Editor, Link, Select } from '../../../../controls';
import DatePicker from 'react-datepicker';
import { getBasicDateFormatCodeForDatePicker, sanitizedHTML } from '../../../../../helpers';
import { useEwpDetails } from '../../../../../hooks/ewp/use-ewp-details';
import { useRole } from '../../../../../helpers/roles';

interface IForm {
  title: string;
  description: string;
  owners: ISelectOptions[];
  start_date: Date;
  estimated_completion_date: Date;
  data: string;
  keyTypes: ISelectOptions[];
  strategies: ISelectOptions[];
}

const EwpKaCreate = () => {
  const { t } = useTranslation();
  const { ewpDetails } = useEwpDetails();
  const { owners, strategies, keyTypes, fetchOwners } = useGetKaOptions();
  const location = useLocation<EWPKeyActionModel | undefined>();
  const { createKeyAction, updateKeyAction } = useEwpApi();
  const { navBack } = useNavBack();
  const editData: EWPKeyActionModel | undefined = location.state;
  const access = useRole();
  const isPm = access(['project_manager']);

  const initialForm = {
    title: '',
    description: '',
    owners: [],
    start_date: new Date(),
    estimated_completion_date: new Date(),
    data: '',
    keyTypes: [],
    strategies: [],
  };
  const [form, setForm] = useState<IForm>(initialForm);
  const [showCreateOrgUser, setShowCreateOrgUser] = useState(false);

  useEffect(() => {
    if (editData) {
      setForm({
        title: editData.title,
        description: editData.description,
        owners: editData.owners.map(item => ({ value: item.id, label: item.name })),
        start_date: new Date(editData.start_date),
        estimated_completion_date: new Date(editData.estimated_completion_date),
        data: editData.data || '',
        keyTypes: editData.key_types,
        strategies: editData.strategies,
      });
    }
  }, []);

  const handleChangeInput = (val: string, fieldName: string) => {
    setForm(prevState => ({ ...prevState, [fieldName]: val }));
  };

  const handleChangeSelect = (val: ISelectOptions[], fieldName: string) => {
    if (val.find(item => item.value === -1)) {
      return;
    }
    if (val.find(item => item.value === -2)) {
      setShowCreateOrgUser(true);
      return;
    }
    setForm({ ...form, [fieldName]: val });
  };

  const handleOrgUserCreated = (user: OrganizationUserModel) => {
    fetchOwners(() => {
      setForm(form => ({
        ...form,
        owners: [
          ...form.owners,
          {
            value: user.id,
            label: `${user.first_name} ${user.last_name}`,
          },
        ],
      }));
    });
  };

  const handleChangeDate = (val: Date, fieldName: string) => {
    setForm({ ...form, [fieldName]: val });
  };

  const handleSave = () => {
    const data: EWPKeyActionCreateData = {
      title: form.title,
      description: form.description,
      owners: form.owners.map(item => item.value as number),
      start_date: form.start_date,
      estimated_completion_date: form.estimated_completion_date,
      data: form.data,
      key_types_ids: form.keyTypes.map(item => item.value as number),
      strategies_ids: form.strategies.map(item => item.value as number),
    };
    const callback = () => {
      navBack();
    };
    if (editData) {
      updateKeyAction(data, editData.id, callback);
    } else {
      createKeyAction(data, callback);
    }
  };

  const _submitDisabled = () => {
    return !(form.title && form.keyTypes.length && form.strategies.length);
  };

  return (
    <>
      <main className="b-page contentContainer">
        <h1 className="b-page__title -desktop">
          {t('common:headlines.equity-work-plan')}:&nbsp;
          <span className="mark">{ewpDetails?.organization_name}</span>
        </h1>
        <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
        <Link onClick={navBack} className="b-page-back-link mb-5">
          {editData ? t('common:btn.edit') : t('common:btn.create')}&nbsp;
          {t('common:label.key-action')}
        </Link>
        <div className="b-elmDetails__panelParam mb-4">
          <div className="b-elmDetails__title font-navy font-m font-weight-bold required-field-modifier">
            {t('common:label.key-action')}:
          </div>
          <div className="flex-grow-1 d-flex align-items-end">
            <input
              disabled={editData && !editData.permissions.edit}
              value={form.title}
              onChange={event => handleChangeInput(event.target.value, 'title')}
              type="text"
              placeholder={t('main:ewp.title-ka')}
              className="b-resourceForm__fieldInput"
              aria-label={'KA Title'}
            />
          </div>
        </div>
        <div className="b-elmDetails__panelParam mb-4">
          <div className="b-elmDetails__title font-navy font-m font-weight-bold">
            {t('common:label.description')}:
          </div>
          <div className="flex-grow-1 d-flex align-items-end">
            <textarea
              disabled={editData && !editData.permissions.edit}
              value={form.description}
              onChange={event => handleChangeInput(event.target.value, 'description')}
              name={'description'}
              aria-label="Description"
              placeholder={t('main:ewp.description-ka')}
              className="b-resourceForm__fieldInput"
              rows={2}
            />
          </div>
        </div>
        <div className="mb-2">
          {keyTypes ? (
            <Select
              disabled={editData && !editData.permissions.edit}
              customClasses="required-field-modifier -type_select"
              isMulti
              handleChange={value => handleChangeSelect(value, 'keyTypes')}
              wrapperStyles={{ maxWidth: 550, width: '100%' }}
              placeholder={t('common:label.key-action-type')}
              value={form.keyTypes}
              options={keyTypes}
              noOptionsMessage={t('awa:N75.msg')}
            />
          ) : null}
        </div>
        <div className="mb-4">
          {owners ? (
            <Select
              disabled={editData && !editData.permissions.assign_owner}
              isMulti
              wrapperStyles={{ maxWidth: 550, width: '100%' }}
              placeholder={t('common:label.owners')}
              handleChange={value => handleChangeSelect(value, 'owners')}
              value={form.owners}
              options={
                isPm
                  ? [
                      ...owners,
                      { value: -1, label: '---' },
                      { value: -2, label: t('common:headlines.add-new-user') },
                    ]
                  : owners
              }
            />
          ) : null}
        </div>
        <div className="b-elmDetails__panelParam mb-4 align-items-end">
          <div className="b-elmDetails__title font-navy font-m font-weight-bold">
            {t('common:label.start')}:
          </div>
          <div className="d-flex align-items-center">
            <div style={{ width: 120 }} className="datepicker-no-panel">
              <DatePicker
                disabled={editData && !(editData.permissions.edit || editData.permissions.partial_edit)}
                minDate={new Date()}
                dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                selected={form.start_date}
                onChange={(date: Date) => handleChangeDate(date, 'start_date')}
                ariaLabelledBy="start_date"
              />
            </div>
          </div>
        </div>
        <div className="b-elmDetails__panelParam mb-4 align-items-end">
          <div className="b-elmDetails__title font-navy font-m font-weight-bold">
            {t('common:label.estimated-completion')}:
          </div>
          <div className="d-flex align-items-center">
            <div style={{ width: 120 }} className="datepicker-no-panel">
              <DatePicker
                disabled={editData && !(editData.permissions.edit || editData.permissions.partial_edit)}
                minDate={new Date()}
                dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                selected={form.estimated_completion_date}
                onChange={(date: Date) => handleChangeDate(date, 'estimated_completion_date')}
                ariaLabelledBy="start_date"
              />
            </div>
          </div>
        </div>
        <div className="b-elmDetails__panelParam mb-4">
          <div className="b-elmDetails__title font-navy font-m font-weight-bold">
            {t('common:label.data')}:
          </div>
          <div className="flex-grow-1 b-ewp__editorField">
            <Editor
              disabled={editData && !(editData.permissions.edit || editData.permissions.partial_edit)}
              onChange={value => handleChangeInput(value, 'data')}
              defaultValue={sanitizedHTML(form.data || '')}
              placeholder={'Description of the data necessary to complete this key action'}
              type={'ewp-ka'}
            />
          </div>
        </div>
        <div className="mb-2">
          {strategies ? (
            <Select
              disabled={editData && !editData.permissions.edit}
              customClasses="required-field-modifier -type_select"
              isMulti
              handleChange={value => handleChangeSelect(value, 'strategies')}
              wrapperStyles={{ maxWidth: 550, width: '100%' }}
              placeholder={t('common:label.associated-strategy')}
              value={form.strategies}
              options={strategies}
              noOptionsMessage={t('awa:N74.msg')}
            />
          ) : null}
        </div>
        <div className="mb-4 required-legend">*{t('common:label.required')}</div>
        <div className="contentContainer mt-5 d-lg-flex justify-content-center">
          <Button
            size={'large'}
            className="button-mh"
            type={'transparency'}
            onPress={navBack}
            title={t('common:btn.cancel')}
          />
          <Button
            disabled={_submitDisabled()}
            size={'large'}
            className="button-mh"
            onPress={handleSave}
            title={t('common:btn.save')}
          />
        </div>
      </main>
      {showCreateOrgUser && ewpDetails ? (
        <CreateOrgUserModal
          orgId={ewpDetails.organization_id}
          onClose={() => setShowCreateOrgUser(false)}
          onSubmit={handleOrgUserCreated}
        />
      ) : null}
    </>
  );
};

export default EwpKaCreate;
