import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreditModel, ISelectOptions } from '../../../../../models';
import { Button, Select } from '../../../../controls';
import { GenericNotes, Modal } from '../../../../common';
import { StateType } from '../../../../../store/reducers';
import { getCreditsOptions, updateCredit } from '../../../../../store/credit/creditActions';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { basicDateFormat, getBasicDateFormatCodeForDatePicker } from '../../../../../helpers';
import { toast } from 'react-toastify';
import { genericNotesService } from '../../../../common/GenericNotes/GenericNotes';
import InputMask from '../../../../controls/Inputs/InputMask';

type FormType = {
  type: ISelectOptions;
  method: ISelectOptions;
  fee?: string;
  payment_date?: string | null;
} | null;

interface IProps {
  credit: CreditModel;
  // setUpdateModalOpen: (credit: null) => void;
  onClose: () => void;
  onSuccessUpdate?: () => void;
  notesOpen?: boolean;
}

const mask = [
  { mask: '' },
  {
    mask: '$num',
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        thousandsSeparator: ',',
        padFractionalZeros: true,
        radix: '.',
        mapToRadix: [','],
      },
    },
  },
];

const UpdateCredit: FC<IProps> = ({ credit, onSuccessUpdate, notesOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const creditOptions = useSelector((state: StateType) => state.credit.creditOptions);
  let [form, setForm] = useState<FormType>(null);

  useEffect(() => {
    if (!creditOptions?.credit_types) {
      dispatch(getCreditsOptions());
    }
  }, []);

  useEffect(() => {
    if (credit && creditOptions) {
      setForm({
        type:
          creditOptions.credit_types.find(t => t.value === credit.credit_type_id) ||
          creditOptions.credit_types[0],
        method:
          creditOptions.acquisition_method.find(m => m.value === credit.acquisition_method_id) ||
          creditOptions.acquisition_method[0],
        fee: credit.fee || '',
        payment_date: credit.payment_date,
      });
    }
  }, [creditOptions]);

  const handleCancelForm = () => {
    // setUpdateModalOpen(null);
    onClose();
  };

  const handleChangeForm = (field: string) => (val: ISelectOptions | string) => {
    if (form) {
      setForm({ ...form, [field]: val });
    }
  };

  const onUpdateCallback = (res: CreditModel) => {
    if (genericNotesService.createNote && credit.fee !== res.fee) {
      genericNotesService.createNote(
        t('common:headlines.fee-changed', { from: credit.fee, to: res.fee }),
        true,
      );
    }

    if (genericNotesService.createNote && credit.payment_date !== res.payment_date) {
      if (!credit.payment_date) {
        genericNotesService.createNote(
          t('common:headlines.payment-date-change-one', {
            to: basicDateFormat(res.payment_date),
          }),
          true,
        );
      } else {
        genericNotesService.createNote(
          t('common:headlines.payment-date-change', {
            from: basicDateFormat(credit.payment_date),
            to: basicDateFormat(res.payment_date),
          }),
          true,
        );
      }
    }
    if (genericNotesService.createNote && credit.acquisition_method_id !== res.acquisition_method_id) {
      genericNotesService.createNote(
        t('common:headlines.payment-method-change', {
          from: credit.acquisition_method_name,
          to: res.acquisition_method_name,
        }),
        true,
      );
    }
    toast.success(t('common:toast.credit-updated'));
    // setUpdateModalOpen(null);
    onClose();
    onSuccessUpdate && onSuccessUpdate();
  };

  const handleUpdateCredit = () => {
    if (!form) return null;
    const body = {
      organization_id: credit.organization_id,
      acquisition_method_id: form.method.value as number,
      fee: (form.fee && String(form.fee)?.replace('$', '')) || undefined,
      payment_date: (form.payment_date && new Date(form.payment_date)) || undefined,
    };

    dispatch(
      updateCredit({
        creditId: credit.id,
        data: body,
        callback: onUpdateCallback,
      }),
    );
  };

  const handleChangeDate = (newDate: Date | null) => {
    if (form && newDate) {
      setForm({
        ...form,
        payment_date: newDate.toString(),
      });
    }
  };

  return (
    <>
      <Modal
        width={650}
        className="b-credits__formModal"
        title={t('common:headlines.beloved-credit')}
        onClose={handleCancelForm}
      >
        <div className="b-credits__form">
          <div className="mb-4">
            <span className="font-grey font-s pr-3">{t('common:label.organization')}:</span>
            <span className="font-weight-bold font-grey font-m">{credit.organization_name}</span>
          </div>
          {creditOptions && form ? (
            <>
              <Select
                wrapperStyles={{ width: '100%' }}
                placeholder={t('main:credits.credit-type')}
                value={{ label: credit.credit_name, value: credit.credit_type_id }}
                options={[{ label: credit.credit_name, value: credit.credit_type_id }]}
                ariaLabel="Type"
                disabled
              />
              <Select
                wrapperStyles={{ width: '100%' }}
                placeholder={t('main:credits.acquisition-method')}
                handleChange={handleChangeForm('method')}
                value={form.method}
                options={creditOptions.acquisition_method}
                ariaLabel="Method"
              />
              <div className="b-credits__formContainer-item mb-4">
                <div className="b-credits__formContainer-title">{t('common:label.fee')}:</div>
                <InputMask
                  className="b-credits__formContainer-input"
                  name={'fee'}
                  value={(form.fee && String(form.fee)) || ''}
                  ariaLabel={t('common:label.fee')}
                  placeholder={t('common:label.amount-paid-client')}
                  handleChange={handleChangeForm('fee')}
                  hideErrorBlock
                  mask={mask}
                />
              </div>
              <div className="b-credits__formContainer-item datepicker-no-panel mb-4">
                <div className="b-credits__formContainer-title">{t('common:label.payment-date')}:</div>
                <DatePicker
                  dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                  selected={(form.payment_date && new Date(form.payment_date)) || null}
                  onChange={date => handleChangeDate(date)}
                  ariaLabelledBy="payment_date"
                  placeholderText={t('common:label.date-payment-received')}
                />
              </div>
              <div className="b-credits__notes mb-4">
                <GenericNotes
                  renderLink={() => (
                    <div className="d-flex align-items-center ">
                      <div className="b-button-icon -colorNavy -noHover -link_res b-ewpElementsTree__icon" />
                      <div className="font-s ml-2 mr-3 font-grey">{t('common:btn.notes')}</div>
                    </div>
                  )}
                  isOpen={notesOpen}
                  isAllowToCreate={true}
                  entityType={'credit'}
                  entityId={credit.id}
                />
              </div>
              <div className="b-credits__formBtns">
                <Button
                  className="button-mh"
                  size={'large'}
                  type={'transparency'}
                  title={t('common:btn.cancel')}
                  onPress={handleCancelForm}
                />
                <Button
                  className="button-mh"
                  size={'large'}
                  title={t('common:btn.update-credit')}
                  onPress={handleUpdateCredit}
                />
              </div>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

export default UpdateCredit;
