import React from 'react';

function useStateCallback(initialState: any) {
  const [state, setState] = React.useState(initialState);
  const cbRef = React.useRef(null);

  const setStateCallback = React.useCallback((state, cb) => {
    cbRef.current = cb;
    setState(state);
  }, []);

  React.useEffect(() => {
    if (cbRef.current) {
      //@ts-ignore
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
}

export default useStateCallback;
