import React from 'react';
import { SortDirection, useRowSelection } from 'react-data-grid';
import { BelEwpModel, ColumnType, EwpTypes, TableDataEntities } from '../../../../../models';
import { basicDateFormat, cutString, getBelovedEwpStatusClass } from '../../../../../helpers';
import { useTranslation } from 'react-i18next';
import { Checkbox, ComaList } from '../../../../controls';
import useHasAccess from '../../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../../constants';
import { useHistory } from 'react-router';
import { ROOTS } from '../../../../../routes/routes';
import useTableData from '../../../../../hooks/common/use-table-data';
import { TableDateFilter } from '../../../../common';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

interface ColumnsHandlers {
  onCellClick: (fieldName: 'edit-leader', ewp: BelEwpModel) => void;
}

export const getColumns = ({ onCellClick }: ColumnsHandlers): ColumnType<BelEwpModel>[] => {
  const { t } = useTranslation();
  const isBeloved = useSelector((state: StateType) => state.profile.authUser)?.beloved_user;
  return [
    {
      key: 'project_manager',
      name: t('common:label.organization-pm'),
      sortable: true,
      editable: false,
      resizable: true,
      isShow: false,
      formatter(props) {
        const history = useHistory();
        const hasAccessPmProfile = useHasAccess(BelovedPermissions.ORGANIZATION_PM_PROFILE);

        if (!props.row.project_manager) return null;

        if (!hasAccessPmProfile) {
          return <>{props.row.project_manager.name}</>;
        }

        const navToPmProfile = (orgId: number) => (e: any) => {
          e.stopPropagation();
          e.preventDefault();
          history.push(ROOTS.BELOVED_PM_PROFILE.replace(':orgId', `${orgId}`));
        };

        return (
          <a href="#" onClick={navToPmProfile(props.row.organization_id)} className="b-link">
            {props.row.project_manager.name}
          </a>
        );
      },
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'created_by',
      name: t('common:label.created-by'),
      sortable: true,
      resizable: true,
      editable: false,
      isShow: false,
      formatter(props) {
        return <>{props.row.created_by?.name}</>;
      },
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'organization_name',
      name: t('common:column.organization-name'),
      sortable: true,
      resizable: true,
      editable: false,
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'affiliation',
      name: t('common:column.affiliation'),
      sortable: true,
      resizable: true,
      editable: false,
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
      formatter(props) {
        return <>{props.row.affiliation || t('common:column.n-a')}</>;
      },
    },
    {
      key: 'ewp_type_name',
      name: t('common:column.ewp-type'),
      sortable: true,
      resizable: true,
      editable: false,
      headerCellClass: 'b-rdgTable__headerCell',
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'status_name',
      name: t('common:column.status'),
      sortable: true,
      resizable: true,
      editable: false,
      formatter(props) {
        return (
          <span className={`b-tableStatusMark ${getBelovedEwpStatusClass(props.row.status)}`}>
            {props.row.status_name}
          </span>
        );
      },
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'start_date',
      name: t('common:column.start-date'),
      sortable: true,
      resizable: true,
      editable: false,
      formatter(props) {
        return (
          <>
            {props.row.start_date ? basicDateFormat(new Date(props.row.start_date)) : t('common:column.n-a')}
          </>
        );
      },
      headerRenderer(props) {
        const { handleSelectDateFilter, query } = useTableData(TableDataEntities.BEL_EWP_LIST);
        if (query.dateFilters) {
          return (
            <TableDateFilter
              dateFilterId={'start_date'}
              selectedFilters={query.dateFilters['start_date']}
              periodFieldName={'start_date_period'}
              dateRangeFieldName={'start_date_date_range'}
              onDateFilterChanged={handleSelectDateFilter}
              rdgProps={props}
              fiscalOption={true}
            />
          );
        }
      },
    },
    {
      key: 'updated_date',
      name: t('common:column.last-update'),
      sortable: true,
      resizable: true,
      editable: false,
      formatter(props) {
        return (
          <>
            {props.row.updated_date
              ? basicDateFormat(new Date(props.row.updated_date))
              : t('common:column.n-a')}
          </>
        );
      },
      headerRenderer(props) {
        const { handleSelectDateFilter, query } = useTableData(TableDataEntities.BEL_EWP_LIST);
        if (query.dateFilters) {
          return (
            <TableDateFilter
              dateFilterId={'updated_date'}
              selectedFilters={query.dateFilters['updated_date']}
              periodFieldName={'updated_date_period'}
              dateRangeFieldName={'updated_date_date_range'}
              onDateFilterChanged={handleSelectDateFilter}
              rdgProps={props}
              fiscalOption={true}
            />
          );
        }
      },
    },
    {
      key: 'beloved_leaders',
      name: t('common:column.beloved-team'),
      sortable: false,
      resizable: true,
      editable: false,
      width: 190,
      headerCellClass: 'b-rdgTable__headerCell',
      formatter(props) {
        const hasAccessToEwpEditLeader = useHasAccess(BelovedPermissions.EWP_EDIT_LEADER);
        if (
          props.row.ewp_type === EwpTypes.beloved_deep_dive_clients ||
          props.row.ewp_type === EwpTypes.beloved_cohort_clients
        ) {
          const handleEditLeader = (e: any) => {
            e.stopPropagation();
            onCellClick('edit-leader', props.row);
          };
          return (
            <div className="h-100 d-flex justify-content-between align-items-center">
              <div className="b-adminToolUsers__statusCell">
                <ComaList
                  tag={'div'}
                  uKey="b-l-"
                  nullText={t('common:column.n-a')}
                  arr={props.row.beloved_leaders
                    .slice(0, 3)
                    .map(user => ({ title: cutString(user.label, 20) }))}
                />
              </div>
              {hasAccessToEwpEditLeader ? (
                <div className="flex-shrink-0">
                  <button onClick={handleEditLeader} className="p-3 b-button-icon -colorNavy -edit" />
                </div>
              ) : null}
            </div>
          );
        }
        return <>{t('common:column.n-a')}</>;
      },
    },
    {
      key: 'tools',
      name: '',
      disableDrag: true,
      width: 40,
      maxWidth: 40,
    },
    {
      key: 'select',
      name: '',
      disableDrag: true,
      width: 40,
      maxWidth: 40,
      isShow: !!isBeloved,
      headerRenderer(props) {
        return (
          <Checkbox
            checked={props.allRowsSelected}
            onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
          />
        );
      },
      formatter: props => {
        const [isRowSelected, onRowSelectionChange] = useRowSelection();
        return (
          <div className="b-rdgTable__cellCenter">
            <div className={'checkBox'} onClick={e => e.stopPropagation()}>
              <Checkbox
                checked={isRowSelected}
                onChange={e => {
                  onRowSelectionChange({
                    row: props.row,
                    checked: e.target.checked,
                    isShiftClick: false,
                  });
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];
};
