import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import {
  IRouteParamsBase,
  LinkResourceDataModel,
  LinkResourceEntityType,
  LinkResourceSelectedItemModel,
} from '../../../../../models';
import { Button, Checkbox, Link, Loader } from '../../../../controls';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { ROOTS } from '../../../../../routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import {
  saveResourceList,
  setSelectedLinkResource,
} from '../../../../../store/beloved/resources/resourcesActions';
import Api from '../../../../../services/api';
import { toast } from 'react-toastify';
import { HeadlineOrganization } from '../../../../common';
import { showAlert } from '../../../../common/Alert/RemoteAlert';
import { arrayUniqueByKey } from '../../../../../helpers';

interface IRouteParams extends IRouteParamsBase {
  entity: LinkResourceEntityType;
  entityId: string;
}

const LinkResource = () => {
  const { t } = useTranslation();
  const { entity, entityId } = useParams<IRouteParams>();
  const { navBack } = useNavBack();
  const history = useHistory();
  const dispatch = useDispatch();
  const addResources = useSelector((state: StateType) => state.belResource.linkResource.selectedLinkResource);
  const savedResources = useSelector((state: StateType) => state.belResource.savedResources);
  let [selectedItems, setSelectedItems] = useState<number[]>([]);
  let [resourceList, setResourceList] = useState<LinkResourceSelectedItemModel[] | null>(null);
  let [pageData, setPageData] = useState<LinkResourceDataModel | null>(null);
  const belovedUser = useSelector((state: StateType) => state.profile.authUser?.beloved_user);
  const isAssign = belovedUser && belovedUser.permissions.find(f => f === 'assign_resources');

  const isChanges = () => {
    const array1 = pageData?.resources.map(res => res.id) || [];
    const array2 = resourceList?.map(res => res.id) || [];
    const array3 = array2.filter(obj => array1.indexOf(obj) == -1);
    const array4 = array1.filter(obj => array2.indexOf(obj) == -1);
    return !!array3.length || !!array4.length;
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (resourceList && addResources.length) {
      const totalResources = arrayUniqueByKey([...resourceList, ...addResources], 'id');
      setResourceList(totalResources);
      dispatch(setSelectedLinkResource([]));
      dispatch(
        saveResourceList({
          resources: totalResources,
          uniqId: entityId,
        }),
      );
    }
  }, [resourceList, addResources]);

  const fetchData = async () => {
    const res: LinkResourceDataModel = await Api.get(`resource/relation/${entity}/${entityId}/`);
    if (res) {
      setPageData(res);
      if (savedResources.uniqId !== entityId) {
        dispatch(
          saveResourceList({
            resources: res.resources,
            uniqId: entityId,
          }),
        );
        setResourceList(res.resources);
        return;
      }
      setResourceList(savedResources.resources);
    }
  };

  const getTitle = () => {
    switch (entity) {
      case 'key_action':
        return `${t('common:label.key-action')}: “${pageData?.entity_title}”`;
      case 'equity_audit':
        return t('common:btn.recommended-resources');
      default:
        return '';
    }
  };

  const handleSelect = (itemId: number) => {
    if (selectedItems.includes(itemId)) {
      const result = selectedItems.filter(id => id !== itemId);
      setSelectedItems(result);
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const handleDelete = () => {
    if (!resourceList) return;

    const res = resourceList.filter(item => {
      return !selectedItems.includes(item.id);
    });
    setSelectedItems([]);
    dispatch(
      saveResourceList({
        resources: res,
        uniqId: entityId,
      }),
    );
    setResourceList(res);
  };

  const handleSave = async () => {
    if (!resourceList) return;
    await Api.patch(`resource/relation/${entity}/${entityId}/`, undefined, {
      resource_ids: resourceList.map(item => item.id),
    });
    await fetchData();
    toast.success(t('common:toast.resources-successfully-saved'));
  };

  const navToViewResource = (id: number) => {
    const route = belovedUser ? ROOTS.BELOVED_RESOURCE_VIEW : ROOTS.RESOURCE_VIEW;
    history.push(route.replace(':id', `${id}`));
  };

  const saveAndBack = async () => {
    await handleSave();
    navBack();
  };

  const handleResetChanges = () => {
    dispatch(
      saveResourceList({
        resources: pageData?.resources || [],
        uniqId: entityId,
      }),
    );
    navBack();
  };

  const handleBack = () => {
    if (isChanges()) {
      showAlert({
        title: t('awa:M156.title'),
        text: (
          <>
            <p>{t('awa:M156.msg')}</p>
          </>
        ),
        buttons: {
          left: {
            title: t('common:btn.no'),
            type: 'transparency',
            onClick: handleResetChanges,
          },
          right: {
            title: t('common:btn.yes'),
            type: 'primary',
            onClick: saveAndBack,
          },
        },
      });
      return;
    }
    navBack();
  };

  if (!pageData) {
    return <Loader />;
  }

  const getHeadlinesTitle = () => {
    switch (entity) {
      case 'key_action':
        return `${t('common:headlines.equity-work-plan')}`;
      case 'equity_audit':
        return t('main:ea.ea');
      default:
        return '';
    }
  };

  const isAccess = () => {
    switch (entity) {
      case 'key_action':
        return true;
      case 'equity_audit':
        return isAssign;
      default:
        return '';
    }
  };

  const hasAccess = isAccess();

  return (
    <main className="b-page">
      <div className="b-page__header">
        <HeadlineOrganization
          orgName={pageData.organization_name}
          orgId={pageData.organization_id}
          headline={getHeadlinesTitle()}
          customClasses={'-noMb -desktop'}
        />
      </div>
      <div className="mb-5">
        <Link onClick={handleBack} className="b-page-back-link mb-0">
          {getTitle()}
        </Link>
      </div>
      <div className="contentContainer">
        <div className="d-flex justify-content-between mb-4">
          <div className="font-navy font-weight-bold font-m">{t('common:column.resources')}</div>
          {hasAccess && (pageData.resources.length || resourceList?.length) ? (
            <button
              disabled={!selectedItems.length}
              className="b-button-icon -delete -colorNavy"
              onClick={handleDelete}
            />
          ) : null}
        </div>
        {resourceList &&
          resourceList.map(item => {
            const isChecked = selectedItems.includes(item.id);
            return (
              <div key={`res-${item.id}`} className="d-flex justify-content-between mb-4">
                <div className="font-grey font-weight-bold font-m">{item.title}</div>
                <div className="d-flex align-items-center">
                  <button
                    onClick={() => navToViewResource(item.id)}
                    className="b-button-icon -viewResource mr-3"
                  />
                  {hasAccess ? (
                    <div className="position-relative" style={{ top: -2 }}>
                      <Checkbox noText onChange={() => handleSelect(item.id)} checked={isChecked} />
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        {hasAccess ? (
          <>
            <RouterLink
              to={ROOTS.LINK_RESOURCE_LIST.replace(':entity', entity).replace(':entityId', entityId)}
              className="b-link-add"
            >
              {t('common:btn.add-new-resource')}
            </RouterLink>
            <div className="mt-5 d-flex justify-content-center">
              <Button
                size={'large'}
                type={'transparency'}
                onPress={handleBack}
                className="button-mh"
                title={t('common:btn.cancel')}
              />
              <Button
                size={'large'}
                disabled={!isChanges()}
                onPress={handleSave}
                className="button-mh"
                title={t('common:btn.save')}
              />
            </div>
          </>
        ) : null}
      </div>
    </main>
  );
};

export default LinkResource;
