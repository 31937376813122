import { call, all, take, StrictEffect, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  belGetElmDetailsSuccess,
  BEL_GET_ELM_DETAILS,
  BelGetElmDetailsPayload,
  BEL_UPDATE_ELM,
  BelUpdateElmPayload,
  belUpdateElmSuccess,
} from './elmActions';
import Api from '../../../services/api';
import { t } from 'i18next';

function* belGetElmDetailsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, elmId },
    }: { payload: BelGetElmDetailsPayload } = yield take(BEL_GET_ELM_DETAILS);

    try {
      const response = yield call(Api.get, `beloved/elm/${elmId}/details/`);

      if (response) {
        yield put(belGetElmDetailsSuccess(response));
        callback && callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* belUpdateElmSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, elmId, data },
    }: { payload: BelUpdateElmPayload } = yield take(BEL_UPDATE_ELM);

    try {
      const response = yield call(Api.put, `beloved/elm/${elmId}/`, data);

      if (response) {
        yield put(belUpdateElmSuccess(response));
        callback && callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

export function* saga() {
  yield all([belGetElmDetailsSaga(), belUpdateElmSaga()]);
}
