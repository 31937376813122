import React, { useEffect, useState } from 'react';
import useTableData from '../../../../hooks/common/use-table-data';
import { useTranslation } from 'react-i18next';
import SortModalButton from '../../../common/SortModalButton/SortModalButton';
import { Loader } from '../../../controls';
import DataGrid from 'react-data-grid';
import { BelEwpModel, ISelectOptions, PaginationModel, TableDataEntities } from '../../../../models';
import { Pagination, SelectValuesModal } from '../../../common';
import { useIsDarkMode } from '../../../../hooks/common/use-is-dark-mode';
import { getColumns } from './parts/columns';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import { basicDateFormat, getBelovedEaStatusClass } from '../../../../helpers';
import { ROOTS } from '../../../../routes/routes';
import { useHistory } from 'react-router-dom';
import { belEwpChanged, setNavDetailsSource } from '../../../../store/ewp/ewpActions';
import { useLocation } from 'react-router';
import { useEwpListApi } from '../../../../hooks/ewp/use-ewp-list-api';
import useHasAccess from '../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../constants/roles';
import CreateEwpButton from './parts/CreateEwpButton';
import useTableConfig from '../../../../hooks/common/use-table-config';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { showAlert } from '../../../common/Alert/RemoteAlert';

const EwpList = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useIsDarkMode();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { getAvailableLeaders, deleteEwp, editLeaders, getAvailableOrgsForCreate } = useEwpListApi();
  let [leaders, setLeaders] = useState<ISelectOptions[] | null>(null);
  let [orgsForCreate, setOrgsForCreate] = useState<ISelectOptions[] | null>(null);
  let [editLeaderEwp, setEditLeaderEwp] = useState<BelEwpModel | null>(null);
  const hasAccessToEwpDetails = useHasAccess(BelovedPermissions.EWP_DETAILS);
  const hasAccessToEditEwp = useHasAccess(BelovedPermissions.EWP_EDIT_LEADER);
  const hasAccessToEwpCRUD = useHasAccess(BelovedPermissions.EWP_CRUD);

  const {
    query,
    data,
    fetchData,
    handleChangePage,
    handleChangePageSize,
    handleSearch,
    setSelectedEntitiesIds,
    handleSort,
    setSortingData,
    sortingData,
    handleChangeDirection,
    handleChangeSortField,
    fetchMoreMobile,
    handleSelectOne,
    selectedEntitiesIds,
    infiniteList,
  } = useTableData(TableDataEntities.BEL_EWP_LIST);

  const onCellClick = (fieldName: 'edit-leader', ewp: BelEwpModel) => {
    if (fieldName === 'edit-leader') {
      setEditLeaderEwp(ewp);
    }
  };

  const { columns, onColumnResize } = useTableConfig<BelEwpModel>(
    TableDataEntities.BEL_EWP_LIST,
    getColumns({ onCellClick }),
  );

  useEffect(() => {
    fetchData(query);
    dispatch(belEwpChanged());
    fetchAvailableOrgsForCreate();
  }, []);

  useEffect(() => {
    if (hasAccessToEditEwp) {
      getAvailableLeaders(leaders => {
        setLeaders(leaders);
      });
    }
  }, [hasAccessToEditEwp]);

  useEffect(() => {
    if (hasAccessToEwpCRUD) {
      getAvailableOrgsForCreate(orgs => {
        setOrgsForCreate(orgs);
      });
    }
  }, [hasAccessToEwpCRUD]);

  const fetchAvailableOrgsForCreate = () => {
    if (hasAccessToEwpCRUD) {
      getAvailableOrgsForCreate(orgs => {
        setOrgsForCreate(orgs);
      });
    }
  };

  const navToDetails = (ewp: BelEwpModel) => {
    if (hasAccessToEwpDetails) {
      dispatch(setNavDetailsSource(location.pathname));
      history.push(ROOTS.BELOVED_EWP_DETAILS.replace(':orgId', `${ewp.organization_id}`));
    } else {
      showAlert({
        title: '',
        text: t('awa:N78.msg'),
        buttons: {
          right: {
            title: 'OK',
          },
        },
      });
    }
  };

  const handleCloseEditLeader = () => {
    setEditLeaderEwp(null);
  };

  const handleSaveEditLeader = (tempUsers: number[]) => {
    if (!editLeaderEwp) return;
    editLeaders(editLeaderEwp, tempUsers, () => {
      fetchData(query);
      setEditLeaderEwp(null);
    });
  };

  const handleDelete = () => {
    deleteEwp(selectedEntitiesIds, () => {
      fetchData(query);
      fetchAvailableOrgsForCreate();
      setSelectedEntitiesIds([]);
    });
  };

  const renderContent = () => {
    if (!data) return <Loader />;

    return (
      <>
        <div className="-desktop">
          <DndProvider backend={HTML5Backend}>
            <DataGrid
              onRowClick={navToDetails}
              onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
              columns={columns}
              onColumnResize={onColumnResize}
              sortColumns={query.sorting ? [query.sorting] : []}
              rows={data.result}
              onSelectedRowsChange={handleSelectOne}
              className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
              style={{
                height: ((data.result.length || 0) + 1) * 60,
              }}
              rowHeight={60}
              selectedRows={new Set(selectedEntitiesIds)}
              rowKeyGetter={(row: BelEwpModel) => row.id}
            />
          </DndProvider>
          <Pagination
            data={data || ({} as PaginationModel)}
            handleChangePageSize={handleChangePageSize}
            changePage={handleChangePage}
          />
        </div>
        {isMobile && (
          <div className="-mobile b-panelTitleOutside__list">
            <InfiniteScroll
              dataLength={infiniteList.length}
              next={fetchMoreMobile}
              hasMore={!(data.current_page === data.total_pages)}
              loader={<h4>{t('common:label.loading')}...</h4>}
            >
              {infiniteList.map((ewp: BelEwpModel) => {
                return (
                  <div key={`mob-item-${ewp.id}`} className="b-panelTitleOutside__container">
                    <span className="b-panelTitleOutside__name">{ewp.organization_name}</span>
                    <div className="b-panelTitleOutside" onClick={() => navToDetails(ewp)}>
                      <table>
                        <tr>
                          <th>{t('common:column.type')}</th>
                          <td>{ewp.ewp_type_name}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.status')}</th>
                          <td>
                            <span className={`b-tableStatusMark ${getBelovedEaStatusClass(ewp.status)}`}>
                              {ewp.status_name}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:column.last-update')}</th>
                          <td>
                            {ewp.updated_date
                              ? basicDateFormat(new Date(ewp.updated_date))
                              : t('common:column.n-a')}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <div>
          <h1 className={'b-page__title'}>{t('common:tabs.equity-work-plans')}</h1>
          <div className="b-page__controls">
            {hasAccessToEwpCRUD ? (
              <div className="b-tableToolContainer">
                <button
                  onClick={handleDelete}
                  disabled={!selectedEntitiesIds.length}
                  className="b-tableTool -delete -mobView -mr"
                >
                  {t('common:btn.delete')}
                </button>
                {orgsForCreate ? <CreateEwpButton availableOrgs={orgsForCreate} /> : null}
              </div>
            ) : null}
            <div className="b-page__search">
              <form action="#" className="searchForm">
                <input
                  type="search"
                  value={query.search}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder={t('common:label.search')}
                  aria-label="Search field"
                />
                <span className="searchForm__icon" />
              </form>
            </div>
          </div>
          <div className="-mobile">
            <div className="b-tableToolContainer -centered">
              <SortModalButton
                sortingData={sortingData}
                setSortingData={setSortingData}
                columns={columns}
                query={query}
                handleChangeSortField={handleChangeSortField}
                handleSort={handleSort}
              />
              <button
                onClick={handleChangeDirection}
                type="button"
                className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                    ${query.sorting?.direction === 'DESC' && '-active'}
                  `}
              >
                {t('common:btn.a-z-order')}
              </button>
            </div>
          </div>
        </div>
        {renderContent()}
      </main>
      {leaders && editLeaderEwp ? (
        <SelectValuesModal
          title={t('common:headlines.Add-EWP-Leaders')}
          subTitle={t('common:column.beloved-leaders')}
          bottomInfo={`*${t('main:add-member.received')}.`}
          onClose={handleCloseEditLeader}
          value={editLeaderEwp.beloved_leaders.map(user => Number(user.value))}
          values={leaders}
          handleSave={handleSaveEditLeader}
          noOptionsMessage={'awa:N71.msg'}
        />
      ) : null}
    </>
  );
};

export default EwpList;
