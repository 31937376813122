import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IRouteParamsBase, ISelectOptions } from '../../models';
import Api from '../../services/api';

export const useGetPriorityOptions = () => {
  const { orgId, id } = useParams<IRouteParamsBase>();

  let [stakeholders, setStakeholders] = useState<ISelectOptions[] | null>(null);
  let [subStandards, setSubStandards] = useState<ISelectOptions[] | null>(null);

  useEffect(() => {
    Api.get(`organizations/${orgId}/ewp/${id}/priorities/get_available_stakeholders/`).then(res => {
      if (res) {
        setStakeholders(res);
      }
    });
    Api.get(`organizations/${orgId}/ewp/${id}/priorities/get_available_sub_standards/`).then(res => {
      if (res) {
        setSubStandards(res);
      }
    });
  }, []);

  return {
    stakeholders,
    subStandards,
  };
};
