import React, { FC } from 'react';
import { AvatarZoomed } from '../index';
import { BelovedUserShortModel, IRouteParamsBase } from '../../../models';
import { ROOTS } from '../../../routes/routes';
import { useHistory, useParams } from 'react-router';
import { EmptyLink, Link } from '../../controls';

interface IProps {
  user: BelovedUserShortModel;
  onEdit?: () => void;
}

const BelovedUserCard: FC<IProps> = ({ user, onEdit }) => {
  const history = useHistory();
  const { orgId } = useParams<IRouteParamsBase>();

  const handleClick = () => {
    history.push(
      ROOTS.BELOVED_USER_PROFILE.replace(':id', `${user.id}`).replace(':orgId', `${orgId || 'general'}`),
    );
  };

  const handleEdit = (e: any) => {
    e.stopPropagation();
    onEdit && onEdit();
  };

  return (
    <Link
      onClick={handleClick}
      className="b-belovedUserCard mb-4 mr-4 b-panel d-flex flex-column align-items-center position-relative"
    >
      {onEdit ? (
        <EmptyLink onClick={handleEdit} className="b-link-icon -edit b-belovedUserCard__editBtn" />
      ) : null}
      <div className="mb-4">
        <AvatarZoomed
          first_name={user.first_name}
          last_name={user.last_name}
          avatar={user.avatar}
          className="-w128"
        />
      </div>
      <div className="font-m font-weight-bold text-center mb-3 font-navy">
        {user.first_name} {user.last_name}
      </div>
      <div className="orange-light-text text-center font-s mb-3">{user.title}</div>
      <div className="font-grey text-center font-s">{user.pronouns}</div>
      {user.email ? (
        <a
          onClick={e => e.stopPropagation()}
          href={`mailto:${user.email}`}
          rel="noreferrer"
          target="_blank"
          className="orange-light-text d-block text-center font-s mt-3 mb-3"
        >
          {user.email}
        </a>
      ) : null}
      {user.org_roles?.length ? (
        <div className="font-grey text-center font-s mt-3">
          {user.org_roles.map(role => role.label).join(', ')}
        </div>
      ) : null}
    </Link>
  );
};

export default BelovedUserCard;
