import * as actions from './ewpActions';
import {
  BEL_EWP_CHANGED,
  BEL_GET_EWP_LIST_SUCCESS,
  BEL_SET_EWP_LIST_QUERY,
  CREATE_EWP_SUCCESS,
  GET_ELEMENTS_FILTER_OPTIONS_SUCCESS,
  GET_EWP_REVIEW_ITEMS_SUCCESS,
  GET_EWP_USERS_SUCCESS,
  GET_GOAL_LIST_SUCCESS,
  GET_KA_LIST_SUCCESS,
  GET_PRIORITY_LIST_SUCCESS,
  GET_STRATS_LIST_SUCCESS,
  SET_CREATE_EWP_DATA,
  SET_EWP_DETAILS,
  SET_EWP_REVIEW_ITEMS_QUERY,
  SET_EWP_USERS_QUERY,
  SET_GOAL_LIST_QUERY,
  SET_KA_LIST_QUERY,
  SET_NAV_DETAILS_SOURCE,
  SET_PRIORITY_LIST_QUERY,
  SET_STRATS_LIST_QUERY,
  UPDATE_EWP_SUCCESS,
} from './ewpActions';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';
import {
  BelEwpModel,
  EWPElementsFilterOptions,
  EWPGoalModel,
  EWPKeyActionModel,
  EWPModel,
  EWPPriorityModel,
  EwpReviewItem,
  EWPStrategyModel,
  EwpUserTableItem,
  PaginationModel,
} from '../../models';
import { QueryParamsModel } from '../../hooks/common/use-table-data';

export type EwpState = {
  navDetailsSource: string;
  createEWPData: EWPModel | null;
  ewpDetails: EWPModel | null;
  ewpReviewItems: PaginationModel<EwpReviewItem[]> | null;
  ewpReviewItemsQuery: QueryParamsModel;
  ewpUsers: PaginationModel<EwpUserTableItem[]> | null;
  ewpUsersQuery: QueryParamsModel;
  priorityList: PaginationModel<EWPPriorityModel[]> | null;
  priorityListQuery: QueryParamsModel;
  goalList: PaginationModel<EWPGoalModel[]> | null;
  goalListQuery: QueryParamsModel;
  stratsList: PaginationModel<EWPStrategyModel[]> | null;
  stratsListQuery: QueryParamsModel;
  kaList: PaginationModel<EWPKeyActionModel[]> | null;
  kaListQuery: QueryParamsModel;
  belEwpList: PaginationModel<BelEwpModel[]> | null;
  belEwpListQuery: QueryParamsModel;
  ewpElementsFilterOptions: EWPElementsFilterOptions | null;
};

const initialState: EwpState = {
  navDetailsSource: 'list',
  ewpElementsFilterOptions: null,
  createEWPData: null,
  ewpDetails: null,
  ewpReviewItems: null,
  ewpReviewItemsQuery: {
    sorting: null,
    search: '',
    page_size: 15,
    page: 1,
    filters: {},
    dateFilters: {},
  },
  ewpUsers: null,
  ewpUsersQuery: {
    sorting: null,
    search: '',
    page_size: 15,
    page: 1,
    filters: {},
  },
  priorityList: null,
  priorityListQuery: {
    sorting: null,
    search: '',
    page_size: 15,
    page: 1,
    filters: {},
    dateFilters: {},
  },
  goalList: null,
  goalListQuery: {
    sorting: null,
    search: '',
    page_size: 15,
    page: 1,
    filters: {},
    dateFilters: {},
  },
  stratsList: null,
  stratsListQuery: {
    sorting: null,
    search: '',
    page_size: 15,
    page: 1,
    filters: {},
    dateFilters: {},
  },
  kaList: null,
  kaListQuery: {
    sorting: null,
    search: '',
    page_size: 15,
    page: 1,
    filters: {},
    dateFilters: {},
  },
  belEwpList: null,
  belEwpListQuery: {
    sorting: null,
    dateFilters: {},
    search: '',
    page_size: 15,
    page: 1,
    filters: {},
  },
};

const ewpReducer = (state: EwpState = initialState, action: actions.EwpActionsTypes): EwpState => {
  switch (action.type) {
    case SET_CREATE_EWP_DATA:
    case UPDATE_EWP_SUCCESS:
    case CREATE_EWP_SUCCESS:
      return {
        ...state,
        createEWPData: action.payload,
      };
    case SET_EWP_DETAILS: {
      if (!action.payload || (state.ewpDetails && state.ewpDetails.id !== action.payload.id)) {
        return {
          ...initialState,
          ewpDetails: action.payload,
        };
      }
      return {
        ...state,
        ewpDetails: action.payload,
      };
    }
    case GET_EWP_REVIEW_ITEMS_SUCCESS: {
      return {
        ...state,
        ewpReviewItems: action.payload,
      };
    }
    case SET_EWP_REVIEW_ITEMS_QUERY: {
      return {
        ...state,
        ewpReviewItemsQuery: action.payload,
      };
    }
    case GET_EWP_USERS_SUCCESS: {
      return {
        ...state,
        ewpUsers: action.payload,
      };
    }
    case SET_EWP_USERS_QUERY: {
      return {
        ...state,
        ewpUsersQuery: action.payload,
      };
    }
    case GET_PRIORITY_LIST_SUCCESS: {
      return {
        ...state,
        priorityList: action.payload,
      };
    }
    case SET_PRIORITY_LIST_QUERY: {
      return {
        ...state,
        priorityListQuery: action.payload,
      };
    }
    case GET_GOAL_LIST_SUCCESS: {
      return {
        ...state,
        goalList: action.payload,
      };
    }
    case SET_GOAL_LIST_QUERY: {
      return {
        ...state,
        goalListQuery: action.payload,
      };
    }
    case GET_STRATS_LIST_SUCCESS: {
      return {
        ...state,
        stratsList: action.payload,
      };
    }
    case SET_STRATS_LIST_QUERY: {
      return {
        ...state,
        stratsListQuery: action.payload,
      };
    }
    case GET_KA_LIST_SUCCESS: {
      return {
        ...state,
        kaList: action.payload,
      };
    }
    case SET_KA_LIST_QUERY: {
      return {
        ...state,
        kaListQuery: action.payload,
      };
    }
    case BEL_GET_EWP_LIST_SUCCESS: {
      return {
        ...state,
        belEwpList: action.payload,
      };
    }
    case BEL_SET_EWP_LIST_QUERY: {
      return {
        ...state,
        belEwpListQuery: action.payload,
      };
    }
    case GET_ELEMENTS_FILTER_OPTIONS_SUCCESS: {
      return {
        ...state,
        ewpElementsFilterOptions: action.payload,
      };
    }
    case SET_NAV_DETAILS_SOURCE: {
      return {
        ...state,
        navDetailsSource: action.payload,
      };
    }
    case BEL_EWP_CHANGED:
      return {
        ...initialState,
        belEwpList: state.belEwpList,
        belEwpListQuery: state.belEwpListQuery,
      };
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default ewpReducer;
