import { TableDataEntities } from '../models';

export const getPageOptions = (action: string, isBeloved: boolean) => {
  if (action === 'favorite') {
    return {
      entity: TableDataEntities.RESOURCES_FAVORITE,
      url: 'resource/favorite_resources/',
    };
  } else if (action === 'recommend') {
    return {
      entity: TableDataEntities.RESOURCES_RECOMMEND,
      url: 'resource/recommended_resources/',
    };
  } else {
    return {
      entity: isBeloved ? TableDataEntities.RESOURCES : TableDataEntities.ORG_RESOURCES,
      url: isBeloved ? 'beloved/resource/list/' : 'resource/list/',
    };
  }
};
