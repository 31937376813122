import React, { FC, useState } from 'react';
import { Button, Input, RadioSelect } from '../../controls';
import { ISelectOptions, KeyValueModel, OrganizationUserModel } from '../../../models';
import { Modal } from '../index';
import { email, getUserRoleList, isEmptyObject, validate } from '../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../store/reducers';
import { useTranslation } from 'react-i18next';
import { addNewUser } from '../../../store/organization/organizationActions';

type IForm = {
  first_name: string;
  last_name: string;
  email: string;
  division: string;
  user_role: ISelectOptions;
};

interface IProps {
  onClose: () => void;
  onSubmit: (user: OrganizationUserModel) => void;
  orgId: number;
}

const CreateOrgUserModal: FC<IProps> = ({ onClose, onSubmit, orgId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userTypesSelector = useSelector((state: StateType) => state.utils.data.userTypesRoles);
  const userRoleList = getUserRoleList(userTypesSelector, 2);

  const validator = {
    required: ['first_name', 'last_name', 'email', 'division'],
    custom: [email(['email'])],
  };

  const initForm = {
    first_name: '',
    last_name: '',
    email: '',
    division: '',
    user_role: userRoleList.find(role => role.value === 12) || userRoleList[0],
  };

  let [errors, setErrors] = useState<KeyValueModel<string>>({});
  let [form, setForm] = useState<IForm>(initForm);

  const handleChangeInput = (name: string, value: string) => {
    setForm(prevState => ({
      ...prevState,
      [name]: value,
    }));
    setErrors({});
  };

  const handleChangeSelect = (value: ISelectOptions) => {
    setForm(prevState => ({
      ...prevState,
      user_role: value,
    }));
    setErrors({});
  };

  const handleCreateUser = () => {
    let { errors } = validate(validator, form);
    setErrors(errors || {});
    if (errors && isEmptyObject(errors)) {
      dispatch(
        addNewUser({
          organization_id: String(orgId),
          data: {
            email: form.email,
            first_name: form.first_name,
            last_name: form.last_name,
            organizations: [
              {
                organization_id: orgId,
                user_type_id: 2,
                user_role_id: Number(form.user_role.value),
                division: form.division,
              },
            ],
          },
          callback: (response, error) => {
            if (!error) {
              onSubmit(response[0]);
              onClose();
            }
          },
        }),
      );
    }
  };

  return (
    <>
      <Modal showCloseBtn={false} width={550} onClose={onClose} title={t('common:btn.add-new-user')}>
        <div className="b-modal">
          <div className="b-elmAddUserModal__list">
            <div className="b-elmAddUserModal__inputs">
              <Input
                name={'first_name'}
                error={errors.first_name}
                value={form.first_name}
                type={'text'}
                placeholder={t('common:label.first-name')}
                handleChange={(value: string) => handleChangeInput('first_name', value)}
              />
              <Input
                name={'last_name'}
                error={errors.last_name}
                value={form.last_name}
                type={'text'}
                placeholder={t('common:label.last-name')}
                handleChange={(value: string) => handleChangeInput('last_name', value)}
              />
              <Input
                name={'email'}
                error={errors.email}
                value={form.email}
                type={'text'}
                placeholder={t('common:label.email')}
                handleChange={(value: string) => handleChangeInput('email', value)}
              />
              <Input
                name={'division'}
                error={errors.division}
                value={form.division}
                type={'text'}
                placeholder={t('common:label.division/department/unit')}
                handleChange={(value: string) => handleChangeInput('division', value)}
              />

              <Input
                disabled={true}
                name={'user_type'}
                error={errors.user_type}
                value={'Internal User'}
                type={'text'}
                placeholder={t('common:label.user-type')}
              />
            </div>
            <div className="b-elmAddUserModal__roles">
              <div className="b-elmAddUserModal__roleTitle">{t('common:label.user-role')}</div>
              {userRoleList.length ? (
                <div className="b-elmAddUserModal__rGroup">
                  <RadioSelect
                    value={form.user_role}
                    handleChange={(value: ISelectOptions) => handleChangeSelect(value)}
                    values={userRoleList}
                    hideDescription={true}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="b-modal__buttons">
            <Button onPress={onClose} title={t('common:btn.cancel')} size={'large'} type={'transparency'} />
            <Button
              onPress={handleCreateUser}
              title={t('common:btn.save')}
              size={'large'}
              type={'orange-light'}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateOrgUserModal;
