import { SortDirection } from 'react-data-grid';
import { CohortOrganizationModel, ColumnType } from '../../../../../../models';
import { cutString } from '../../../../../../helpers';
import React from 'react';
import { t } from 'i18next';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

export const getColumns = (): ColumnType<CohortOrganizationModel>[] => {
  const data: ColumnType<CohortOrganizationModel>[] = [
    {
      key: 'organization',
      name: t('common:column.organization'),
      sortable: true,
      cellClass: 'b-rdgTable__cellTextWrap',
      editable: false,
      resizable: true,
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
      formatter(props) {
        return <b>{props.row.organization_name}</b>;
      },
    },
    {
      key: 'name',
      name: t('common:column.name'),
      width: 270,
      sortable: true,
      cellClass: 'b-rdgTable__cellTextWrap',
      editable: false,
      resizable: true,
      headerRenderer(props: any) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
      formatter(props) {
        return <>{cutString(`${props.row.first_name} ${props.row.last_name}`, 120)}</>;
      },
    },
    {
      key: 'role',
      name: t('common:column.role'),
      sortable: true,
      cellClass: 'b-rdgTable__cellTextWrap',
      editable: false,
      resizable: true,
      headerRenderer(props: any) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
      formatter(props) {
        return <>{cutString(props.row.user_role, 120)}</>;
      },
    },
    {
      key: 'email',
      name: t('common:label.email'),
      sortable: false,
      cellClass: 'b-rdgTable__cellTextWrap',
      editable: false,
      resizable: true,
      headerCellClass: 'b-rdgTable__headerCell',
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
      formatter(props) {
        return <>{props.row.email}</>;
      },
    },
    {
      key: 'tools',
      name: '',
      disableDrag: true,
      width: 40,
      maxWidth: 40,
    },
  ];
  return data;
};
