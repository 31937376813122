import useStateCallback from './use-state-callback';

interface IConfig {
  errEllClass: string;
  isScroll: boolean;
}

export function useError<S>(
  state: S,
  { errEllClass, isScroll }: IConfig = { errEllClass: 'error', isScroll: true },
) {
  let [error, _setError] = useStateCallback(state);

  const setError = (_state: S) => {
    _setError(_state, () => {
      if (isScroll) {
        const errorEls = document.getElementsByClassName(errEllClass);
        if (errorEls[0]) {
          errorEls[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }
    });
  };

  return { error, setError };
}
