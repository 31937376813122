import { useSelector } from 'react-redux';
import { StateType } from '../store/reducers';
import { OrganizationDetailsModel, UserRolesModel } from '../models';
import { useEffect, useState } from 'react';

const getAccess = (currentRoleAlias: string) => {
  const userRoles: UserRolesModel[] | null = useSelector(
    (state: StateType) => state.utils.data.userRoles || null,
  );

  return (accessRoles: string[], user_role_id?: number) => {
    if (user_role_id !== undefined) {
      const getRoleAlias = userRoles?.find(u => u.id === user_role_id);
      return !!accessRoles.find(a => a === getRoleAlias?.alias);
    }
    if (!currentRoleAlias) return null;
    const isAccess = accessRoles.find(a => a === currentRoleAlias);
    return !!isAccess;
  };
};

export const useRole = () => {
  const currentOrg: OrganizationDetailsModel | null = useSelector(
    (state: StateType) => state.organization.currentOrg,
  );
  const userRoles: UserRolesModel[] | null = useSelector(
    (state: StateType) => state.utils.data.userRoles || null,
  );

  let [currentRole, setCurrentRole] = useState<string>('');

  useEffect(() => {
    if (!userRoles) {
      return;
    }
    const getRoleAlias = userRoles.find(u => u.id === currentOrg?.user_role?.id);
    setCurrentRole(getRoleAlias?.alias || '');
  }, [currentOrg, userRoles]);

  return getAccess(currentRole);
};

export const getAccessByGlobal = (currentRoleAlias: string) => {
  const userRoles: UserRolesModel[] | null = useSelector(
    (state: StateType) => state.utils.data.userRoles || null,
  );
  const user = useSelector((state: StateType) => state.profile.authUser);

  if (!userRoles) {
    return;
  }
  const role = userRoles.find(f => f.alias === currentRoleAlias);
  return !!(user && user.orgs_role?.filter(r => r.user_role_id === role?.id).length);
};
