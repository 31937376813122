import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IRouteParamsBase } from '../../../../models';
import { Button, EmptyLink, Input, Loader } from '../../../controls';
import { belGetElmDetails, belUpdateElm } from '../../../../store/beloved/elm/elmActions';
import { BelovedElmTabs, HeadlineOrganization, Modal, PopupPortal } from '../../../common';
import { StateType } from '../../../../store/reducers';
import { basicDateFormat, debriefFormat } from '../../../../helpers';
import { countries } from '../../../../constants';
import { ElmStatuses } from '../../../../constants/elm';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useHasAccess from '../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../constants/roles';
import { useNavBack } from '../../../../hooks/common/use-nav-back';

interface IRouteParams extends IRouteParamsBase {
  elmId: string;
}

const ElmDetailsBel = () => {
  const { t } = useTranslation();
  const { navBack } = useNavBack();

  const { elmId } = useParams<IRouteParams>();
  const dispatch = useDispatch();
  const data = useSelector((state: StateType) => state.belElm.currentElm);
  const hasAccessToRmElm = useHasAccess(BelovedPermissions.ELM_REMOVE);
  const [modal, setModal] = useState<boolean>(false);
  useEffect(() => {
    dispatch(
      belGetElmDetails({
        elmId: Number(elmId),
      }),
    );
  }, [elmId]);

  if (!data) return <Loader />;

  const getMetric = (val?: number | null, isPercent?: boolean): string => {
    if (val === undefined || val === null) return t('common:column.n-a');
    if (isPercent) {
      return `${val}%`;
    }
    return String(val);
  };

  const countryValue =
    countries.find(f => f.value === (data?.address.country && data.address.country.toLowerCase())) ||
    countries.find(f => f.value === 'us');

  const isInternation = !(data.address.country && data.address.country.toLowerCase() === 'us');

  const handleLockModal = () => {
    setModal(true);
  };

  const handleUpdateElm = () => {
    dispatch(
      belUpdateElm({
        elmId: Number(elmId),
        data: {
          status_id: ElmStatuses.COMPLETED,
        },
        callback: () => {
          setModal(false);
          belGetElmDetails({
            elmId: Number(elmId),
          });
          toast.success(t('common:toast.elm-status-update'));
        },
      }),
    );
  };

  return (
    <>
      <main className="b-page">
        <HeadlineOrganization
          orgName={data?.org_name || ''}
          orgId={data.organization_id}
          headline={t('common:tabs.elm')}
          customClasses={'-withTabs'}
          backBtn={<EmptyLink onClick={navBack} className="b-button-icon-back" />}
        />
        <BelovedElmTabs activeItemAlias={'details'} />
        <form action="#" className="b-page__730 pt">
          <div className="b-equityAuditsDetails__formRow">
            <div className="b-equityAuditsDetails__formCol">
              <Input
                value={data.affiliation}
                disabled
                type="text"
                placeholder={t('common:column.affiliation')}
              />
              <div className={'b-equityAuditsDetails__lockWrap'}>
                <Input
                  value={data.status_name}
                  disabled
                  type="text"
                  placeholder={t('common:column.ELM-Status')}
                />
                {hasAccessToRmElm && data.status_id === ElmStatuses.COMPLETED_LOCKED ? (
                  <button
                    onClick={handleLockModal}
                    type="button"
                    aria-label="Click to open modal"
                    className="-lock"
                  />
                ) : null}
              </div>

              <Input
                value={data.start_date ? basicDateFormat(new Date(data.start_date)) : t('common:column.n-a')}
                disabled
                type="text"
                placeholder={t('common:column.start-date')}
              />
              <Input value={data.project_manager} disabled type="text" placeholder={t('common:column.pm')} />
              <Input
                value={data.joined ? basicDateFormat(new Date(data.joined)) : t('common:column.n-a')}
                disabled
                type="text"
                placeholder={t('common:column.joined')}
              />
            </div>
            <div className="b-equityAuditsDetails__formCol">
              <Input value={data.type} disabled type="text" placeholder={t('common:column.type')} />
              <Input
                value={data.administration_type_name}
                disabled
                type="text"
                placeholder={t('common:column.ELM-Type')}
              />
              <Input
                value={data.end_date ? debriefFormat(data.end_date) : t('common:column.n-a')}
                disabled
                type="text"
                placeholder={t('common:column.end-date')}
              />
              <Input
                value={data.project_manager_email}
                disabled
                type="text"
                placeholder={t('common:column.PM-Email')}
              />
              <Input value={data.website} disabled type="text" placeholder={t('common:label.website')} />
            </div>
          </div>
          <div className="b-equityAuditsDetails__formSubRow">
            {data.status_id >= ElmStatuses.IN_PROGRESS ? (
              <>
                <Input
                  inputClassName="b-equityAuditsDetails__elmMetricInput"
                  value={`${getMetric(data.display_information.submitted_percent, true)}`}
                  disabled
                  type="text"
                  placeholder={t('common:label.perc-Submitted')}
                />
                <Input
                  inputClassName="b-equityAuditsDetails__elmMetricInput"
                  value={getMetric(data.display_information.submitted)}
                  disabled
                  type="text"
                  placeholder={t('common:status.submitted')}
                />
                <Input
                  inputClassName="b-equityAuditsDetails__elmMetricInput"
                  value={getMetric(data.display_information.not_completed)}
                  disabled
                  type="text"
                  placeholder={t('common:status.not-completed')}
                />
                <Input
                  inputClassName="b-equityAuditsDetails__elmMetricInput"
                  value={getMetric(data.display_information.not_started)}
                  disabled
                  type="text"
                  placeholder={t('common:status.not-started')}
                />
              </>
            ) : (
              <>
                <Input
                  inputClassName="b-equityAuditsDetails__elmMetricInput"
                  value={getMetric(data.display_information.invited)}
                  disabled
                  type="text"
                  placeholder={t('common:status.invited')}
                />
                <Input
                  inputClassName="b-equityAuditsDetails__elmMetricInput"
                  value={getMetric(data.display_information.registered)}
                  disabled
                  type="text"
                  placeholder={t('common:status.registered')}
                />
              </>
            )}
          </div>
          <h2 className="b-equityAuditsDetails__formTitle">{t('common:label.organization-details')}</h2>
          <div className="b-equityAuditsDetails__formRow">
            <div className="b-equityAuditsDetails__formCol">
              <Input
                value={data.employees_number_name}
                disabled
                type="text"
                placeholder={t('common:label.number-of-employees')}
              />
              <Input
                value={data.geographic_scope_name}
                disabled
                type="text"
                placeholder={t('common:label.Geographic-Scope-of-Services-Provided')}
              />
              <Input
                value={data.sector_type_name}
                disabled
                type="text"
                placeholder={t('common:label.sector-type')}
              />
            </div>
            <div className="b-equityAuditsDetails__formCol">
              <Input
                value={data.org_age_name}
                disabled
                type="text"
                placeholder={t('common:label.age-organization')}
              />
              <Input
                value={data.geographic_area_name}
                disabled
                type="text"
                placeholder={t('common:label.geographic-areas')}
              />
              <Input
                value={data.primary_type_name}
                disabled
                type="text"
                placeholder={t('common:label.primary-type')}
              />
            </div>
          </div>
          <h2 className="b-equityAuditsDetails__formTitle">{t('common:headlines.headquarter-address')}</h2>
          <div className="b-equityAuditsDetails__formRow">
            <div className="b-equityAuditsDetails__formCol">
              <Input
                value={data.address.address}
                disabled
                type="text"
                placeholder={t('common:label.address')}
              />
              <Input value={data.address.city} disabled type="text" placeholder={t('common:label.us.city')} />
              <Input
                value={data.address.postal}
                disabled
                type="text"
                placeholder={
                  isInternation ? t('common:label.international.postal') : t('common:label.us.postal')
                }
              />
            </div>
            <div className="b-equityAuditsDetails__formCol">
              <Input
                value={data.address.suite}
                disabled
                type="text"
                placeholder={
                  isInternation ? t('common:label.international.suite') : t('common:label.us.suite')
                }
              />
              <Input
                value={data.address.state}
                disabled
                type="text"
                placeholder={t('common:label.us.state')}
              />
              <Input
                value={(countryValue && countryValue.label) || ''}
                disabled
                type="text"
                placeholder={t('common:label.country')}
              />
            </div>
          </div>
        </form>
      </main>
      {modal ? (
        <PopupPortal portalElementId={'create-ewp-modal'}>
          <Modal minHeight={380} width={545} title={t('awa:N76.title')} onClose={() => setModal(false)}>
            <div className={`b-modal b-equityAuditsDetails__modalLock`}>
              <p>{t('awa:N76.msg.p1')}</p>
              <p className={'mt-3 mb-5'}>{t('awa:N76.msg.p2')}</p>
              <div className="b-modal__buttons">
                <Button
                  onPress={() => setModal(false)}
                  title={t('common:btn.cancel')}
                  size={'large'}
                  type={'transparency'}
                  className="w-50"
                />
                <Button
                  onPress={handleUpdateElm}
                  title={t('common:btn.re-open-administration')}
                  size={'large'}
                  type={'primary'}
                  className="w-50"
                />
              </div>
            </div>
          </Modal>
        </PopupPortal>
      ) : null}
    </>
  );
};

export default ElmDetailsBel;
