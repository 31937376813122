import {
  ActionModel,
  ChartReportModel,
  FGDetailedModel,
  FunctionGroupGroupsModel,
  ScoreModel,
  KeyValueNumberModel,
  ReportsModel,
  ResponsesGroupModel,
  StandardResponsesModel,
  StandardScoreModel,
  GroupAnalysisModel,
} from '../../models';
import { actionCreator } from '../actionCreator';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';

interface GetReportsModel {
  eaId: number;
}

//-----GET_REPORTS----//
export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export interface GetReportsPayload {
  callback?: (
    res: ReportsModel<KeyValueNumberModel<ScoreModel>, KeyValueNumberModel<StandardScoreModel>>,
  ) => void;
  data: GetReportsModel;
}
export const getReports = actionCreator<GetReportsPayload>(GET_REPORTS);
export const getReportsSuccess =
  actionCreator<ReportsModel<KeyValueNumberModel<ScoreModel>, KeyValueNumberModel<StandardScoreModel>>>(
    GET_REPORTS_SUCCESS,
  );

interface StandardReportModel {
  eaId: number;
  standard_id: number;
}

//-----GET_STANDARD_REPORT----//
export const GET_STANDARD_REPORT = 'GET_STANDARD_REPORT';
export const GET_STANDARD_REPORT_SUCCESS = 'GET_STANDARD_REPORT_SUCCESS';
export interface GetStandardReportPayload {
  callback?: (res: StandardScoreModel) => void;
  data: StandardReportModel;
}
export const getStandardReport = actionCreator<GetStandardReportPayload>(GET_STANDARD_REPORT);
export const getStandardReportSuccess = actionCreator<StandardScoreModel>(GET_STANDARD_REPORT_SUCCESS);

//-----GET_FG_REPORT----//
export const GET_FG_REPORT = 'GET_FG_REPORT';
export const GET_FG_REPORT_SUCCESS = 'GET_FG_REPORT_SUCCESS';
export interface GetFGReportPayload {
  callback?: (res: FGDetailedModel<KeyValueNumberModel<FunctionGroupGroupsModel>>) => void;
  data: GetReportsModel;
}

export const getFGReport = actionCreator<GetFGReportPayload>(GET_FG_REPORT);
export const getFGReportSuccess = actionCreator<GetFGReportPayload>(GET_FG_REPORT_SUCCESS);

//-----GET_FG_CHART----//
export const GET_FG_CHART = 'GET_FG_CHART';
export const GET_FG_CHART_SUCCESS = 'GET_FG_CHART_SUCCESS';
export interface GetFGChartPayload {
  callback?: (res: ChartReportModel) => void;
  data: GetReportsModel;
}
export const getFGChart = actionCreator<GetFGChartPayload>(GET_FG_CHART);
export const getFGChartSuccess = actionCreator<ChartReportModel>(GET_FG_CHART_SUCCESS);

//-----GET_STANDARD_CHART----//
export const GET_STANDARD_CHART = 'GET_STANDARD_CHART';
export const GET_STANDARD_CHART_SUCCESS = 'GET_STANDARD_CHART_SUCCESS';
export interface GetStandardChartPayload {
  callback?: (res: ChartReportModel) => void;
  data: GetReportsModel;
}
export const getStandardChart = actionCreator<GetStandardChartPayload>(GET_STANDARD_CHART);
export const getStandardChartSuccess = actionCreator<ChartReportModel>(GET_STANDARD_CHART_SUCCESS);

//-----GET_STANDARD_RESPONSES----//
export const GET_STANDARD_RESPONSES = 'GET_STANDARD_RESPONSES';
export interface GetStandardResponsesPayload {
  callback?: (res: StandardResponsesModel<KeyValueNumberModel<ResponsesGroupModel>>) => void;
  data: StandardReportModel;
}
export const getStandardResponses = actionCreator<GetStandardResponsesPayload>(GET_STANDARD_RESPONSES);

//-----CLEAR_REPORTS----//
export const CLEAR_REPORTS = 'CLEAR_REPORTS';

export const clearReports = actionCreator(CLEAR_REPORTS);

//-----GET_STANDARD_RESPONSES----//
export const GET_GROUP_ANALYSIS_CHART = 'GET_GROUP_ANALYSIS_CHART';
export const GET_GROUP_ANALYSIS_CHART_SUCCESS = 'GET_GROUP_ANALYSIS_CHART_SUCCESS';
export interface GetGroupAnalysisChartPayload {
  callback?: (res: GroupAnalysisModel) => void;
  data: {};
}
export const getGroupAnalysisChart = actionCreator<GetGroupAnalysisChartPayload>(GET_GROUP_ANALYSIS_CHART);
export const getGroupAnalysisChartSuccess = actionCreator<GetGroupAnalysisChartPayload>(
  GET_GROUP_ANALYSIS_CHART_SUCCESS,
);

export type ReportActionsTypes =
  | ActionModel<typeof GET_REPORTS, GetReportsPayload>
  | ActionModel<typeof GET_GROUP_ANALYSIS_CHART, GetGroupAnalysisChartPayload>
  | ActionModel<typeof LOGOUT_SUCCESS>
  | ActionModel<typeof GET_GROUP_ANALYSIS_CHART_SUCCESS, GroupAnalysisModel>
  | ActionModel<
      typeof GET_REPORTS_SUCCESS,
      ReportsModel<KeyValueNumberModel<ScoreModel>, KeyValueNumberModel<StandardScoreModel>>
    >
  | ActionModel<typeof GET_STANDARD_REPORT, GetStandardReportPayload>
  | ActionModel<typeof GET_STANDARD_REPORT_SUCCESS, StandardScoreModel>
  | ActionModel<typeof GET_FG_REPORT, GetFGReportPayload>
  | ActionModel<typeof GET_FG_REPORT_SUCCESS, FGDetailedModel<KeyValueNumberModel<FunctionGroupGroupsModel>>>
  | ActionModel<typeof GET_FG_CHART, GetFGChartPayload>
  | ActionModel<typeof GET_FG_CHART_SUCCESS, ChartReportModel>
  | ActionModel<typeof GET_STANDARD_CHART, GetStandardChartPayload>
  | ActionModel<typeof GET_STANDARD_CHART_SUCCESS, ChartReportModel>
  | ActionModel<typeof CLEAR_REPORTS, null>
  | ActionModel<typeof GET_STANDARD_RESPONSES, GetStandardResponsesPayload>;
