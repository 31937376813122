import {
  ActionModel,
  BelResourceOptionsModel,
  BelCreateResourceModel,
  PaginationModel,
  BelResourceShortModel,
  BelResourceModel,
  ResourceShareOrgType,
  ISelectOptions,
  LinkResourceEntityType,
  LinkResourceSelectedItemModel,
} from '../../../models';
import { actionCreator } from '../../actionCreator';
import { LOGOUT_SUCCESS } from '../../authData/authDataActions';
import { BelResourceState } from './resourcesReducer';
import { ResourceStatuses } from '../../../constants';
import { QueryParamsModel, ReqParamsModel } from '../../../hooks/common/use-table-data';

//-----BEL_GET_RESOURCE_OPTIONS----//
export const BEL_GET_RESOURCE_OPTIONS = 'beloved/BEL_GET_RESOURCE_OPTIONS';
export const BEL_GET_RESOURCE_OPTIONS_SUCCESS = 'beloved/BEL_GET_RESOURCE_OPTIONS_SUCCESS';
export interface BelGetResourceOptionsPayload {
  callback?: () => void;
}
export const belGetResourceOptions = actionCreator<BelGetResourceOptionsPayload>(BEL_GET_RESOURCE_OPTIONS);
export const belGetResourceOptionsSuccess = actionCreator<BelResourceOptionsModel>(
  BEL_GET_RESOURCE_OPTIONS_SUCCESS,
);

//-----BEL_CREATE_RESOURCE----//
export const BEL_CREATE_RESOURCE = 'beloved/BEL_CREATE_RESOURCE';
export interface BelCreateResourcePayload {
  data: BelCreateResourceModel;
  callback?: () => void;
}
export const belCreateResource = actionCreator<BelCreateResourcePayload>(BEL_CREATE_RESOURCE);

//-----BEL_UPDATE_RESOURCE----//
export const BEL_UPDATE_RESOURCE = 'beloved/BEL_UPDATE_RESOURCE';
export interface BelUpdateResourcePayload {
  id: number;
  data: BelCreateResourceModel;
  callback?: () => void;
}
export const belUpdateResource = actionCreator<BelUpdateResourcePayload>(BEL_UPDATE_RESOURCE);

//-----BEL_GET_RESOURCE----//
export const BEL_GET_RESOURCE = 'beloved/BEL_GET_RESOURCE';
export interface BelGetResourcePayload {
  id: number;
  callback: (data: BelResourceModel) => void;
}
export const belGetResource = actionCreator<BelGetResourcePayload>(BEL_GET_RESOURCE);

//-----BEL_GET_RESOURCE_LIST----//
export const BEL_GET_RESOURCE_LIST = 'beloved/BEL_GET_RESOURCE_LIST';
export const BEL_GET_RESOURCE_LIST_SUCCESS = 'beloved/BEL_GET_RESOURCE_LIST_SUCCESS';
export interface BelGetResourceListPayload {
  extraData?: {
    url: string;
  };
  params: ReqParamsModel;
  callback?: () => void;
}
export const belGetResourceList = actionCreator<BelGetResourceListPayload>(BEL_GET_RESOURCE_LIST);
export const belGetResourceListSuccess = actionCreator<PaginationModel<BelResourceShortModel[]>>(
  BEL_GET_RESOURCE_LIST_SUCCESS,
);

//-----BEL_DELETE_RESOURCES----//
export const BEL_DELETE_RESOURCES = 'beloved/BEL_DELETE_RESOURCES';
export interface BelDeleteResourcePayload {
  ids: number[];
  callback?: () => void;
}
export const belDeleteResources = actionCreator<BelDeleteResourcePayload>(BEL_DELETE_RESOURCES);

//-----GET_RESOURCE_AVAILABLE_ORG----//
export const GET_AVAILABLE_ORG = 'beloved-resource/GET_AVAILABLE_ORG';
export interface GetAvailableOrgPayload {
  data: {
    search: string;
    type_id: ResourceShareOrgType;
    resource_ids?: number[];
  };
  callback?: (res: ISelectOptions[]) => void;
}
export const getAvailableOrg = actionCreator<GetAvailableOrgPayload>(GET_AVAILABLE_ORG);

//-----RESOURCE_LINK_ORG----//
export const LINK_AVAILABLE_ORG = 'beloved-resource/LINK_AVAILABLE_ORG';
export interface LinkAvailableOrgPayload {
  data: {
    organization_ids: number[];
    type_id: ResourceShareOrgType;
    resource_ids: number[];
  };
  callback?: () => void;
}
export const linkAvailableOrg = actionCreator<LinkAvailableOrgPayload>(LINK_AVAILABLE_ORG);

//-----GET_AVAILABLE_COHORT----//
export const GET_AVAILABLE_COHORT = 'beloved-resource/GET_AVAILABLE_COHORT';
export interface GetAvailableCohortPayload {
  data: {
    search: string;
    resource_ids?: number[];
  };
  callback?: (res: ISelectOptions[]) => void;
}
export const getAvailableCohort = actionCreator<GetAvailableCohortPayload>(GET_AVAILABLE_COHORT);

//-----LINK_AVAILABLE_COHORT----//
export const LINK_AVAILABLE_COHORT = 'beloved-resource/LINK_AVAILABLE_COHORT';
export interface LinkAvailableCohortPayload {
  data: {
    cohort_ids: number[];
    resource_ids: number[];
  };
  callback?: () => void;
}
export const linkAvailableCohort = actionCreator<LinkAvailableCohortPayload>(LINK_AVAILABLE_COHORT);

//-----GET_AVAILABLE_COHORT_SESSION----//
export const GET_AVAILABLE_COHORT_SESSION = 'beloved-resource/GET_AVAILABLE_COHORT_SESSION';
export interface GetAvailableCohortSessionPayload {
  data: {
    search: string;
    resource_ids?: number[];
  };
  cohort_id: number;
  callback?: (res: ISelectOptions[]) => void;
}
export const getAvailableCohortSession = actionCreator<GetAvailableCohortSessionPayload>(
  GET_AVAILABLE_COHORT_SESSION,
);

//-----LINK_AVAILABLE_COHORT_SESSION----//
export const LINK_AVAILABLE_COHORT_SESSION = 'beloved-resource/LINK_AVAILABLE_COHORT_SESSION';
export interface LinkAvailableCohortSessionPayload {
  data: {
    cohort_session_ids: number[];
    resource_ids?: number[];
  };
  callback?: () => void;
}
export const linkAvailableCohortSession = actionCreator<LinkAvailableCohortSessionPayload>(
  LINK_AVAILABLE_COHORT_SESSION,
);

//-----BEL_CHANGE_RESOURCES_STATUS----//
export const BEL_CHANGE_RESOURCE_STATUS = 'beloved/BEL_CHANGE_RESOURCE_STATUS';
export interface BelChangeResourceStatusPayload {
  ids: number[];
  status: ResourceStatuses;
  callback?: () => void;
}
export const belChangeResourceStatus =
  actionCreator<BelChangeResourceStatusPayload>(BEL_CHANGE_RESOURCE_STATUS);

//-----SET_QUERY---//
export const SET_RESOURCES_QUERY = 'beloved-resources/SET_USERS_QUERY';
export const setResourcesQuery = actionCreator<QueryParamsModel>(SET_RESOURCES_QUERY);

//-----SAVE_TEMP_RESOURCE_LIST----//
export const SAVE_TEMP_RESOURCE_LIST = 'resource/SAVE_TEMP_RESOURCE_LIST';
export interface SaveTempResourceListPayload {
  resources: LinkResourceSelectedItemModel[];
  uniqId: string;
}
export const saveResourceList = actionCreator<SaveTempResourceListPayload>(SAVE_TEMP_RESOURCE_LIST);

//-----GET_LINK_RESOURCE_LIST----//
export const GET_LINK_RESOURCE_LIST = 'resource/GET_LINK_RESOURCE_LIST';
export interface GetLinkResourceListPayload {
  extraData?: {
    entity: LinkResourceEntityType;
    entityId: number;
  };
  params: ReqParamsModel;
  callback?: () => void;
}

export const getLinkResourceList = actionCreator<GetLinkResourceListPayload>(GET_LINK_RESOURCE_LIST);

export const GET_LINK_RESOURCE_LIST_SUCCESS = 'resource/GET_LINK_RESOURCE_LIST_SUCCESS';
export const getLinkResourceListSuccess = actionCreator<PaginationModel<BelResourceShortModel[]>>(
  GET_LINK_RESOURCE_LIST_SUCCESS,
);

export const SET_LINK_RESOURCE_LIST_QUERY = 'resource/SET_LINK_RESOURCE_LIST_QUERY';
export const setLinkResourceListQuery = actionCreator<QueryParamsModel>(SET_LINK_RESOURCE_LIST_QUERY);

export const SET_SELECTED_LINK_RESOURCE = 'resource/SET_SELECTED_LINK_RESOURCE';
export const setSelectedLinkResource =
  actionCreator<LinkResourceSelectedItemModel[]>(SET_SELECTED_LINK_RESOURCE);

export type BelResourceActionsTypes =
  | ActionModel<typeof BEL_GET_RESOURCE_OPTIONS_SUCCESS, BelResourceOptionsModel>
  | ActionModel<typeof SET_SELECTED_LINK_RESOURCE, LinkResourceSelectedItemModel[]>
  | ActionModel<typeof BEL_CREATE_RESOURCE, BelCreateResourcePayload>
  | ActionModel<typeof BEL_GET_RESOURCE, BelGetResourcePayload>
  | ActionModel<typeof BEL_GET_RESOURCE_LIST_SUCCESS, PaginationModel<BelResourceShortModel[]>>
  | ActionModel<typeof SET_RESOURCES_QUERY, BelResourceState['resourcesQuery']>
  | ActionModel<typeof GET_AVAILABLE_ORG, GetAvailableOrgPayload>
  | ActionModel<typeof GET_AVAILABLE_COHORT, GetAvailableCohortPayload>
  | ActionModel<typeof GET_AVAILABLE_COHORT_SESSION, GetAvailableCohortSessionPayload>
  | ActionModel<typeof LINK_AVAILABLE_ORG, LinkAvailableOrgPayload>
  | ActionModel<typeof LINK_AVAILABLE_COHORT, LinkAvailableCohortPayload>
  | ActionModel<typeof LINK_AVAILABLE_COHORT_SESSION, LinkAvailableCohortSessionPayload>
  | ActionModel<typeof SAVE_TEMP_RESOURCE_LIST, SaveTempResourceListPayload>
  | ActionModel<typeof LOGOUT_SUCCESS>
  | ActionModel<typeof GET_LINK_RESOURCE_LIST_SUCCESS, PaginationModel<BelResourceShortModel[]>>
  | ActionModel<typeof SET_LINK_RESOURCE_LIST_QUERY, QueryParamsModel>;
