import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GoalStatuses } from '../../../../../models';

interface IProps {
  status: GoalStatuses;
}

const GoalStatusLabel: FC<IProps> = ({ status }) => {
  const { t } = useTranslation();
  switch (status) {
    case GoalStatuses.NOT_UPDATED_YET:
      return <div className="b-status -prepCompl">{t('common:status.Not-Updated-Yet')}</div>;
    case GoalStatuses.MET:
      return <div className="b-status -completed">{t('common:status.met')}</div>;
    case GoalStatuses.POSITIVE_GROWTH:
      return <div className="b-status -color3">{t('common:status.positive-growth')}</div>;
    case GoalStatuses.NO_CHANGE:
      return <div className="b-status -darkNavy">{t('common:status.no-change')}</div>;
    case GoalStatuses.NEGATIVE_GROWTH:
      return <div className="b-status -color5">{t('common:status.negative-growth')}</div>;
    case GoalStatuses.NO_LONGER_PURSUING:
      return <div className="b-status -color5">{t('common:label.No-Longer-Pursuing')}</div>;
    default:
      return null;
  }
};

export default GoalStatusLabel;
