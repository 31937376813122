import React, { useState, FC } from 'react';
import { Button, Checkbox } from '../../../../../controls';
import { HandleSaveType } from '../../../../../../models';
import { Modal } from '../../../../../common';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSave: HandleSaveType;
}

const Intro: FC<IProps> = ({ onSave }) => {
  const { t } = useTranslation();

  let [terms, setTerms] = useState(false);
  let [showTerms, setShowTerms] = useState(false);

  const handleNext = () => {
    onSave({
      next: true,
      answer: [{ id: 'terms', text: 'accept' }],
    });
  };

  return (
    <>
      <div className="b-EATaking__box">
        <h1 className="b-EATaking__boxTitle">{t('awa:M56.title')}</h1>
        <div className="b-elmTake__descBox">
          <p className="b-elm__p">
            {t('awa:M56.msg.p1')}
            <strong>{t('awa:M56.msg.p2')}</strong>
            {t('awa:M56.msg.p3')}
          </p>
          <p className="b-elm__p">
            {t('awa:M56.msg.p4')} <span className="b-elm__orangeBold">{t('awa:M56.msg.p5')}</span>{' '}
            {t('awa:M56.msg.p6')}
          </p>
        </div>
        <div className="b-elmTake__terms">
          <Checkbox noText size={'lg'} checked={terms} onChange={() => setTerms(!terms)} />
          <span className={'text-long-description gray-text'}>
            {t('common:checkbox.agreement.agree-to')} &nbsp;
            <a className="links gray-text links_underline_text" onClick={() => setShowTerms(true)}>
              {t('common:checkbox.agreement.terms-of-use')}
            </a>
          </span>
        </div>
        <Button
          disabled={!terms}
          className={'btn -type_form -desktop'}
          type={'orange-light'}
          onPress={handleNext}
          title={t('common:btn.next')}
        />
      </div>
      <div className="b-elm__mobBtn -mobile">
        <Button
          disabled={!terms}
          className={'btn -type_form'}
          type={'orange-light'}
          onPress={handleNext}
          title={t('common:btn.next')}
        />
      </div>
      {showTerms ? (
        <Modal width={900} title={t('pp:elm.title')} onClose={() => setShowTerms(false)}>
          <div className="b-elmTake__termsModal">
            <p>{t('pp:elm.p1')}</p>
            <p>
              <b>{t('pp:elm.p2')}</b>
            </p>
            <h4>{t('pp:elm.p3')}</h4>
            <p>
              {t('pp:elm.p4')} (&quot;<b>{t('pp:elm.p5')}</b>&quot;, &quot;
              <b>{t('pp:elm.p6')}</b>&quot;, {t('pp:elm.p7')} &quot;
              <b>{t('pp:elm.p8')}</b>&quot;). {t('pp:elm.p9')} &quot;
              <b>{t('pp:elm.p10')}</b>&quot;), {t('pp:elm.p11')}, “ “<b>{t('pp:elm.p12')}</b>”{' '}
              {t('pp:elm.p13')}
            </p>
            <p>{t('pp:elm.p14')}</p>
            <h4>{t('pp:elm.p15')}</h4>
            <p>{t('pp:elm.p16')}</p>
            <p>{t('pp:elm.p17')}</p>
            <h4>{t('pp:elm.p18')}</h4>
            <p>{t('pp:elm.p19')}</p>
            <ul>
              <li>{t('pp:elm.p20')}</li>
              <li>{t('pp:elm.p21')}</li>
              <li>{t('pp:elm.p22')}</li>
              <li>{t('pp:elm.p23')}</li>
              <li>{t('pp:elm.p24')}</li>
              <li>{t('pp:elm.p25')}</li>
              <li>{t('pp:elm.p26')}</li>
              <li>{t('pp:elm.p27')}</li>
              <li>{t('pp:elm.p28')}</li>
            </ul>
            <h4>{t('pp:elm.p29')}</h4>
            <p>{t('pp:elm.p30')}</p>
            <p>
              {t('pp:elm.p31')} “<b>{t('pp:elm.p32')}</b>”) {t('pp:elm.p33')}
            </p>
            <h4>{t('pp:elm.p49')}</h4>
            <p>{t('pp:elm.p35')}</p>
            <h4>{t('pp:elm.p36')}</h4>
            <p>{t('pp:elm.p37')}</p>
            <p>{t('pp:elm.p38')}</p>
            <p>{t('pp:elm.p39')}</p>
            <h4>{t('pp:elm.p40')}</h4>
            <p>{t('pp:elm.p41')}</p>
            <h4>{t('pp:elm.p42')}</h4>
            <p>{t('pp:elm.p43')}</p>
            <h4>{t('pp:elm.p44')}</h4>
            <p>{t('pp:elm.p45')}</p>
            <p>{t('pp:elm.p46')}</p>
            <p>{t('pp:elm.p47')}</p>
            <h4>{t('pp:elm.p48')}</h4>
            <p>{t('pp:elm.p49')}</p>
            <p>{t('pp:elm.p50')}</p>
            <h4>{t('pp:elm.p51')}</h4>
            <p>{t('pp:elm.p52')}</p>
            <h4>{t('pp:elm.p53')}</h4>
            <p>{t('pp:elm.p54')}</p>
            <h4>{t('pp:elm.p55')}</h4>
            <p>{t('pp:elm.p56')}</p>
            <h4>{t('pp:elm.p57')}</h4>
            <p>{t('pp:elm.p58')}</p>
            <h4>{t('pp:elm.p59')}</h4>
            <p>{t('pp:elm.p60')}</p>
            <h4>{t('pp:elm.p61')}</h4>
            <p>{t('pp:elm.p62')}</p>
            <p>{t('pp:elm.p63')}</p>
            <h4>{t('pp:elm.p64')}</h4>
            <p>{t('pp:elm.p65')}</p>
            <p>{t('pp:elm.p66')}</p>
            <p>{t('pp:elm.p67')}</p>
            <h4>{t('pp:elm.p68')}</h4>
            <p>
              {t('pp:elm.p69')}
              <a href="privacy@wearebeloved.org">privacy@wearebeloved.org</a>.
            </p>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default Intro;
