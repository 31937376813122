import { call, all, take, StrictEffect, put } from 'redux-saga/effects';
import {
  GET_ORG_LIST_FOR_CREATE,
  GetOrgListForCreatePayload,
  GET_USERS_LIST_FOR_CREATE,
  CREATE_EA,
  createEASuccess,
  CreateEAPayload,
  GET_EA_LIST,
  GetEAListPayload,
  GET_EA_DETAILS,
  GetEADetailsPayload,
  getEADetailsSuccess,
  UPDATE_EA,
  UpdateEAPayload,
  updateEASuccess,
  getUsersListForCreateSuccess,
  GET_EA_OPTIONS_FOR_CREATE,
  getEAOptionsForCreateSuccess,
  TAKE_EA,
  takeEASuccess,
  TakeEAPayload,
  SaveAnswerPayload,
  saveAnswerSuccess,
  SAVE_ANSWER,
  DELETE_F_GROUP,
  deleteFGroupSuccess,
  DeleteFGroupPayload,
  SEND_NOTIFICATION,
  SendNotificationPayload,
  CancelEAPayload,
  CANCEL_EA,
  SkipFGroupPayload,
  SKIP_F_GROUP,
  skipFGroupSuccess,
  SUBMIT_EA,
  SubmitEAPayload,
  ViewDebriefPayload,
  VIEW_DEBRIEF,
} from './equityAuditActions';
import Api from '../../services/api';
import { getOrgIdFormRoute } from '../../helpers';
import { toast } from 'react-toastify';
import { t } from 'i18next';

function* getOrgListForCreateSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload }: { payload: GetOrgListForCreatePayload } = yield take(GET_ORG_LIST_FOR_CREATE);
    const orgId = getOrgIdFormRoute();
    try {
      const response = yield call(Api.get, `organizations/${orgId}/equity_audit/organizations_list/`);

      if (response) {
        payload.callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* getUsersListForCreateSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    yield take(GET_USERS_LIST_FOR_CREATE);
    const orgId = getOrgIdFormRoute();
    try {
      const response = yield call(Api.get, `organizations/${orgId}/equity_audit/organization_users/`);

      if (response) {
        yield put(getUsersListForCreateSuccess(response));
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* getEAOptionsForCreateSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    yield take(GET_EA_OPTIONS_FOR_CREATE);
    const orgId = getOrgIdFormRoute();
    try {
      const response = yield call(Api.get, `organizations/${orgId}/equity_audit/options/`);

      if (response) {
        yield put(getEAOptionsForCreateSuccess(response));
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* createEASaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, orgId },
    }: { payload: CreateEAPayload } = yield take(CREATE_EA);
    try {
      const response = yield call(Api.post, `organizations/${orgId}/equity_audit/`);

      if (response) {
        callback && callback();
        yield put(createEASuccess(response));
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* getEAListSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, params },
    }: { payload: GetEAListPayload } = yield take(GET_EA_LIST);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.get, `organizations/${orgId}/equity_audit/`, params);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* getEADetailsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { eaId, isDetailsPage, callback },
    }: { payload: GetEADetailsPayload } = yield take(GET_EA_DETAILS);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.get, `organizations/${orgId}/equity_audit/${eaId}/`);

      if (response) {
        if (!isDetailsPage) {
          yield put(getEADetailsSuccess(response));
        }
        callback && callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* updateEASaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data, eaId, isDetails },
    }: { payload: UpdateEAPayload } = yield take(UPDATE_EA);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.patch, `organizations/${orgId}/equity_audit/${eaId}/`, undefined, data);
      if (response) {
        if (!isDetails) {
          yield put(updateEASuccess(response));
        }
        callback && callback(response);
      }
    } catch (e: any) {
      if (e.response.data.detail) {
        toast.error(e.response.data.detail);
      } else {
        toast.error(t('common:toast.error'));
      }
    }
  }
}

function* cancelEASaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, eaId },
    }: { payload: CancelEAPayload } = yield take(CANCEL_EA);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.delete, `organizations/${orgId}/equity_audit/${eaId}/cancel_audit/`);

      if (response) {
        callback && callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* takeEASaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, eaId },
    }: { payload: TakeEAPayload } = yield take(TAKE_EA);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.post, `organizations/${orgId}/equity_audit/${eaId}/take_audit/`);
      if (response) {
        callback && callback();
        yield put(takeEASuccess(response));
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* saveAnswerSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data, eaId },
    }: { payload: SaveAnswerPayload } = yield take(SAVE_ANSWER);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.post, `organizations/${orgId}/equity_audit/${eaId}/save_answer/`, data);
      if (response) {
        yield put(saveAnswerSuccess(data));
        callback && callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* deleteFGroupSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, eaId, fGroupId },
    }: { payload: DeleteFGroupPayload } = yield take(DELETE_F_GROUP);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.post, `organizations/${orgId}/equity_audit/${eaId}/delete_section/`, {
        function_group_id: fGroupId,
      });
      if (response) {
        yield put(deleteFGroupSuccess(response));
        callback && callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* skipFGroupSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, eaId, fGroupId },
    }: { payload: SkipFGroupPayload } = yield take(SKIP_F_GROUP);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.post, `organizations/${orgId}/equity_audit/${eaId}/skip_section/`, {
        function_group_id: fGroupId,
      });
      if (response) {
        yield put(skipFGroupSuccess({ fGroupId }));
        callback && callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* sendNotificationSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, ea_id, target, userIds },
    }: { payload: SendNotificationPayload } = yield take(SEND_NOTIFICATION);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.post, `organizations/${orgId}/equity_audit/send_notification/`, {
        target,
        ea_id,
        team_ids: userIds,
      });

      if (response) {
        toast.success(t('common:toast.email-sent'));
        callback && callback(target);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* submitEASaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, ea_id },
    }: { payload: SubmitEAPayload } = yield take(SUBMIT_EA);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.post, `organizations/${orgId}/equity_audit/${ea_id}/submit_audit/`);
      if (response) {
        toast.success(t('common:toast.ea-submitted'));
        callback && callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* viewDebriefSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, ea_id },
    }: { payload: ViewDebriefPayload } = yield take(VIEW_DEBRIEF);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.post, `organizations/${orgId}/equity_audit/${ea_id}/view_debrief/`);
      if (response) {
        callback && callback(response.resource);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

export function* saga() {
  yield all([
    getOrgListForCreateSaga(),
    getUsersListForCreateSaga(),
    createEASaga(),
    getEAListSaga(),
    getEADetailsSaga(),
    updateEASaga(),
    getEAOptionsForCreateSaga(),
    takeEASaga(),
    saveAnswerSaga(),
    deleteFGroupSaga(),
    sendNotificationSaga(),
    cancelEASaga(),
    skipFGroupSaga(),
    submitEASaga(),
    viewDebriefSaga(),
  ]);
}
