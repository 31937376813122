import i18next from 'i18next';

export const orderValues = () => [
  {
    label: i18next.t('common:column.first'),
    value: 'first_name',
  },
  {
    label: i18next.t('common:column.last'),
    value: 'last_name',
  },

  {
    label: i18next.t('common:column.div-dept-unit'),
    value: 'user_division',
  },
  {
    label: i18next.t('common:column.title'),
    value: 'user_title',
  },
  {
    label: i18next.t('common:column.awa-role'),
    value: 'user_role',
  },
  {
    label: i18next.t('common:column.status'),
    value: 'status',
  },
];

export const statusValues = () => [
  {
    label: i18next.t('common:status.active'),
    value: 'active',
  },
  {
    label: i18next.t('common:status.deactivated'),
    value: 'deactivated',
  },
  {
    label: i18next.t('common:status.pending'),
    value: 'pending',
  },
];
