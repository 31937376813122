import { call, all, takeLatest, StrictEffect, put } from 'redux-saga/effects';
import {
  GET_FG_REPORT,
  GET_REPORTS,
  GET_STANDARD_REPORT,
  GetFGReportPayload,
  GetReportsPayload,
  GetStandardReportPayload,
  GET_FG_CHART,
  GetFGChartPayload,
  GET_STANDARD_CHART,
  GetStandardChartPayload,
  GET_STANDARD_RESPONSES,
  GetStandardResponsesPayload,
  getReportsSuccess,
  getStandardReportSuccess,
  getFGReportSuccess,
  getFGChartSuccess,
  GET_GROUP_ANALYSIS_CHART,
  getGroupAnalysisChartSuccess,
  getStandardChartSuccess,
} from './reportActions';
import Api from '../../services/api';
import { ActionModel } from '../../models';
import { toast } from 'react-toastify';
import { getOrgIdFormRoute } from '../../helpers';
import { t } from 'i18next';

function* getReportsSaga({
  payload,
}: ActionModel<typeof GET_REPORTS, GetReportsPayload>): Generator<StrictEffect, void, any> {
  try {
    const { eaId } = payload.data;
    const organization_id = getOrgIdFormRoute();
    const response = yield call(Api.get, `organizations/${organization_id}/equity_audit/${eaId}/report/`);

    if (response) {
      response.eaId = eaId;
      payload.callback && payload.callback(response);
      yield put(getReportsSuccess(response));
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* getStandardReportSaga({
  payload,
}: ActionModel<typeof GET_STANDARD_REPORT, GetStandardReportPayload>): Generator<StrictEffect, void, any> {
  try {
    const { eaId, standard_id } = payload.data;
    const organization_id = getOrgIdFormRoute();
    const response = yield call(
      Api.get,
      `organizations/${organization_id}/equity_audit/${eaId}/report/standard/${standard_id}/`,
    );

    if (response) {
      response.eaId = eaId;
      yield put(getStandardReportSuccess(response));
      payload.callback && payload.callback(response);
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* getFGReportSaga({
  payload,
}: ActionModel<typeof GET_FG_REPORT, GetFGReportPayload>): Generator<StrictEffect, void, any> {
  try {
    const { eaId } = payload.data;
    const organization_id = getOrgIdFormRoute();
    const response = yield call(
      Api.get,
      `organizations/${organization_id}/equity_audit/${eaId}/report/function_group_details/`,
    );

    if (response) {
      response.eaId = eaId;
      payload.callback && payload.callback(response);
      yield put(getFGReportSuccess(response));
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* getFGChartSaga({
  payload,
}: ActionModel<typeof GET_FG_CHART, GetFGChartPayload>): Generator<StrictEffect, void, any> {
  try {
    const { eaId } = payload.data;
    const organization_id = getOrgIdFormRoute();
    const response = yield call(
      Api.get,
      `organizations/${organization_id}/equity_audit/${eaId}/report/function_group_chart/`,
    );

    if (response) {
      response.eaId = eaId;
      payload.callback && payload.callback(response);
      yield put(getFGChartSuccess(response));
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* getStandardChartSaga({
  payload,
}: ActionModel<typeof GET_STANDARD_CHART, GetStandardChartPayload>): Generator<StrictEffect, void, any> {
  try {
    const { eaId } = payload.data;
    const organization_id = getOrgIdFormRoute();
    const response = yield call(
      Api.get,
      `organizations/${organization_id}/equity_audit/${eaId}/report/standards_chart/`,
    );

    if (response) {
      response.eaId = eaId;
      payload.callback && payload.callback(response);
      yield put(getStandardChartSuccess(response));
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* getStandardResponsesSaga({
  payload,
}: ActionModel<typeof GET_STANDARD_RESPONSES, GetStandardResponsesPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  try {
    const { eaId, standard_id } = payload.data;
    const organization_id = getOrgIdFormRoute();
    const response = yield call(
      Api.get,
      `organizations/${organization_id}/equity_audit/${eaId}/report/sub_standard_responses/${standard_id}/`,
    );

    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* getGroupAnalysisChartSaga({ payload }: any): any {
  try {
    const response = yield call(Api.post, `organizations/${0}/equity_audit/group_analysis/`, payload.data);

    if (response) {
      payload.callback && payload.callback(response);
      yield put(getGroupAnalysisChartSuccess(response));
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

export function* saga() {
  yield all([takeLatest(GET_REPORTS, getReportsSaga)]);
  yield all([takeLatest(GET_GROUP_ANALYSIS_CHART, getGroupAnalysisChartSaga)]);
  yield all([takeLatest(GET_STANDARD_REPORT, getStandardReportSaga)]);
  yield all([takeLatest(GET_FG_REPORT, getFGReportSaga)]);
  yield all([takeLatest(GET_FG_CHART, getFGChartSaga)]);
  yield all([takeLatest(GET_STANDARD_CHART, getStandardChartSaga)]);
  yield all([takeLatest(GET_STANDARD_RESPONSES, getStandardResponsesSaga)]);
}
