import React, { useEffect, useState } from 'react';
import { Input, Loader, PhoneInput } from '../../../controls';
import { useParams } from 'react-router';
import { PmProfileModel, IRouteParamsBase, ISelectOptions } from '../../../../models';
import { useDispatch } from 'react-redux';
import { belGetPmProfile } from '../../../../store/beloved/organizations/organizationsActions';
import { useTranslation } from 'react-i18next';
import { AvatarZoomed } from '../../../common';

const PMProfile = () => {
  const { t } = useTranslation();
  const { orgId } = useParams<IRouteParamsBase>();
  const dispatch = useDispatch();

  let [data, setData] = useState<PmProfileModel | null>(null);

  useEffect(() => {
    dispatch(
      belGetPmProfile({
        orgId: Number(orgId),
        callback: data => {
          setData(data);
        },
      }),
    );
  }, []);

  const getMultipleTitle = (data: ISelectOptions[]) => {
    let res = '';
    data.forEach((item, index) => {
      if (data.length === index + 1) {
        res = res + item.label;
      } else {
        res = res + item.label + ', ';
      }
    });
    return res;
  };

  if (!data) return <Loader />;

  return (
    <main className="b-page">
      <div>
        <div className="b-page__header -mobReverse">
          <div>
            <h1 className="pageContainer__title -desktop b-pmProfile__pageTitle">
              {t('common:headlines.pm-profile')}:{' '}
              <span className="name">
                {data.first_name} {data.last_name}
              </span>
            </h1>
            <div className="pageContainer__title -mobile b-pmProfile__pageTitle">
              {t('main:pm-profile')}{' '}
              <span className="name">
                {data.first_name} {data.last_name}
              </span>
            </div>

            <ul className="b-pmProfile__infoList">
              <li>{data.email || t('common:column.n-a')}</li>
              <li>{data.pronouns || t('common:column.n-a')}</li>
            </ul>
          </div>
          <div className="b-avatar__profile">
            <AvatarZoomed avatar={data.avatar} first_name={data.first_name} last_name={data.last_name} />
          </div>
        </div>
        <form action="#" className="b-pmProfile__form">
          <div className="b-pmProfile__formRow">
            <Input
              value={data.organization_name}
              disabled
              type="text"
              placeholder={t('common:label.organization-name')}
              ariaLabel="Organization Name"
            />
            <Input
              disabled
              value={data.type}
              type="text"
              placeholder={t('common:label.organization-type')}
              ariaLabel="Organization Type"
            />
            <Input
              disabled
              value={data.role?.title || t('common:column.n-a')}
              type="text"
              placeholder={t('common:label.title')}
              ariaLabel="Title"
            />
            <Input
              disabled
              value={data.role?.division || t('common:column.n-a')}
              type="text"
              placeholder={t('common:label.division/department/unit')}
              ariaLabel="Division/Department/Unit"
            />
            {data?.phone_number ? (
              <PhoneInput disabled value={data?.phone_number} placeholder={t('common:label.phone-number')} />
            ) : (
              <Input
                disabled
                value={t('common:column.n-a')}
                type="text"
                placeholder={t('common:label.phone-number')}
                ariaLabel="PhoneNumber"
              />
            )}
          </div>
          <h2 className="b-pmProfile__formTitle">
            {t('common:headlines.Office-Address')}{' '}
            <span>{t('common:headlines.same-as-organization-headquarter')}</span>
          </h2>
          <div className="b-pmProfile__formRow">
            <Input
              disabled
              value={data.address.address}
              type="text"
              placeholder={t('common:label.address')}
              ariaLabel="Address"
            />
            <Input
              disabled
              value={data.address.suite}
              type="text"
              placeholder={t('common:label.us.suite')}
              ariaLabel="Suite/Office/Floor"
            />
            <Input
              disabled
              value={data.address.city}
              type="text"
              placeholder={t('common:label.us.city')}
              ariaLabel="City"
            />
            <Input
              disabled
              value={data.address.state}
              type="text"
              placeholder={t('common:label.us.state')}
              ariaLabel="State"
            />
            <Input
              disabled
              value={data.address.postal}
              type="text"
              placeholder={t('common:label.us.postal')}
              ariaLabel="Zip"
            />
            <Input
              disabled
              value={data.address.country}
              type="text"
              placeholder={t('common:label.country')}
              ariaLabel="Country"
            />
          </div>
          <h2 className="b-pmProfile__formTitle">{t('common:headlines.personal-details')}</h2>
          <div className="b-pmProfile__formRow">
            <Input
              disabled
              value={getMultipleTitle(data.gender)}
              type="text"
              placeholder={t('common:label.gender-identity')}
              ariaLabel="Gender Identity"
            />
            <Input
              disabled
              value={getMultipleTitle(data.race)}
              type="text"
              placeholder={t('common:label.race')}
              ariaLabel="Race"
            />
            <Input
              disabled
              value={getMultipleTitle(data.ethnicity)}
              type="text"
              placeholder={t('common:label.ethnicity')}
              ariaLabel="Ethnicity"
            />
            <Input
              disabled
              value={data.education || t('common:column.n-a')}
              type="text"
              placeholder={t('common:label.education')}
              ariaLabel="Education"
            />
          </div>
        </form>
      </div>
    </main>
  );
};

export default PMProfile;
