import React, { FC } from 'react';
import BG from '../../../../../assets/images/debrief/1.png';
import { DebriefModel } from '../../../../../models';
import { useTranslation } from 'react-i18next';

interface IProps {
  data: DebriefModel;
}

const Slide1: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="b-viewDebriefSlide1">
      <img src={BG} alt={t('common:alt.bg')} />
      <div className="b-viewDebriefSlide1__content">
        <h1>{t('awa:M38.title')}</h1>
        {data.organizations.parent_org ? <h3>{data.organizations.parent_org}</h3> : null}
        <h2>{data.organizations.org}</h2>
      </div>
    </div>
  );
};

export default Slide1;
