import React, { useState, useEffect, FC } from 'react';
import { createPortal } from 'react-dom';
import { isMobileOnly } from 'react-device-detect';
import styles from '../../pages/OrganizationApp/EquityAudit/CreateEA/CreateEA.module.scss';
import { Modal } from '../index';
import makeUrls from 'add-event-to-calendar';
import { OrganizationDetailsModel } from '../../../models';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { StateType } from '../../../store/reducers';
import { DOMAIN_URI, ScheduleAlias, ScheduleTimeRangeEnum } from '../../../constants';
import { useTranslation } from 'react-i18next';
const fileDownload = require('js-file-download');

type CalendarType = 'google' | 'apple' | 'yahoo' | 'outlook';

const timezone = require('dayjs/plugin/timezone');
const utc = require('dayjs/plugin/utc'); // dependent on utc plugin

dayjs.extend(timezone);
dayjs.extend(utc);

interface IProps {
  date: string | Date;
  customOpen?: boolean | null;
  customTitle?: React.ReactNode;
  callbackModal?: (modal: boolean | null) => void;
  alias: ScheduleAlias;
}

const AddToCalendarDebrief: FC<IProps> = ({ date, alias, customOpen, customTitle, callbackModal }) => {
  const { t } = useTranslation();

  const currentOrg: OrganizationDetailsModel | null = useSelector(
    (state: StateType) => state.organization.currentOrg,
  );

  let [modalOpened, setModalOpened] = useState<boolean | null>(null);

  const mountContainer = document.body;
  let popupContainer = document.getElementById('add-calendar-popup-portal');

  if (!popupContainer) {
    popupContainer = document.createElement('div');
    popupContainer.id = 'add-calendar-popup-portal';
  }

  useEffect(() => {
    setModalOpened(customOpen || null);
  }, [customOpen]);

  useEffect(() => {
    callbackModal && callbackModal(modalOpened);
  }, [modalOpened]);

  useEffect(() => {
    mountContainer?.appendChild(popupContainer as HTMLElement);
    return () => {
      mountContainer?.removeChild(popupContainer as HTMLElement);
    };
  }, [mountContainer, popupContainer]);

  const openModal = (e: any) => {
    e.preventDefault();
    setModalOpened(true);
  };

  const closeModal = (e: any) => {
    e.preventDefault();
    setModalOpened(false);
  };

  const addCalendar = (calType: CalendarType) => (e: any) => {
    if (!alias) {
      return;
    }
    const startsAt = dayjs(date).format(`YYYY-MM-DD HH:mm:00 Z`);
    const endsAt = dayjs(date).add(ScheduleTimeRangeEnum[alias], 'm').format(`YYYY-MM-DD HH:mm:00 Z`);
    e.preventDefault();

    const event = {
      name: `Debrief Session - ${currentOrg?.name}`,
      location: '',
      details: `<a href="${DOMAIN_URI}" __is_owner="true">${DOMAIN_URI}</a>`,
      startsAt: startsAt,
      endsAt: endsAt,
    };
    const eventUrls = makeUrls(event);
    if (calType !== 'google') {
      const url = eventUrls.ics
        .replace('data:text/calendar;charset=utf8,', '')
        .replaceAll('%0A', '\n')
        .replaceAll('%20', ' ');

      const blob = new Blob([url]);
      fileDownload(blob, 'event.ics');
    } else {
      window.open(eventUrls[calType], '_blank');
    }
  };

  const renderModal = () => {
    const modal = (
      <Modal modalStyles={{ minHeight: 400 }} title={''} onClose={() => setModalOpened(false)}>
        <div className={'b-EAModal'}>
          <div className={'b-EAModal__title'}>
            {isMobileOnly && <a onClick={closeModal} href="#" className={'b-EAModal__back'} />}
            {customTitle
              ? customTitle
              : `${t('common:calendar.add-to-calendar')} (${t('common:headlines.optional')})`}
          </div>
          <div className="font-navy font-weight-bold mb-4">
            {t('common:calendar.add-to-calendar')} ({t('common:headlines.optional')})
          </div>
          <div>
            <a
              href="#"
              onClick={addCalendar('apple')}
              className={`b-equityAudit__text16 b-link ${styles.addCalendar__opt}`}
            >
              {t('common:calendar.apple-calendar')}
            </a>
            <a
              href="#"
              onClick={addCalendar('google')}
              className={`b-equityAudit__text16 b-link ${styles.addCalendar__opt}`}
            >
              {t('common:calendar.google-calendar')}
            </a>
            <a
              href="#"
              onClick={addCalendar('yahoo')}
              className={`b-equityAudit__text16 b-link ${styles.addCalendar__opt}`}
            >
              {t('common:calendar.yahoo-calendar')}
            </a>
            <a
              href="#"
              onClick={addCalendar('outlook')}
              className={`b-equityAudit__text16 b-link ${styles.addCalendar__opt}`}
            >
              {t('common:calendar.office-365')}
            </a>
          </div>
        </div>
      </Modal>
    );
    return createPortal(modal, popupContainer as HTMLElement);
  };

  return (
    <>
      {typeof customOpen === 'undefined' ? (
        <a href="#" className="b-link-add" onClick={openModal}>
          {t('common:calendar.add-to-calendar')}
        </a>
      ) : null}
      {modalOpened && renderModal()}
    </>
  );
};

export default AddToCalendarDebrief;
