import React, { useState } from 'react';
import styles from './AssociationOrganizationPreviewStyle.module.scss';
import { Alert, Footer, Header } from '../../../common';
import { Button, Image } from '../../../controls';
import Logo from '../../../../assets/images/icons/organization.png';
import JoinOrganizationContainer from '../JoinOrganization/JoinOrganizationContainer';
import { KeyValueModel } from '../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { associationOrganization } from '../../../../store/organization/organizationActions';
import { StateType } from '../../../../store/reducers';
import { useHistory } from 'react-router-dom';
import { ROOTS } from '../../../../routes/routes';
import { changeAuthStepAction } from '../../../../store/profile/profileActions';
import { Modals, ModalsModel } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

interface IProps {
  loader?: string;
}

interface IState {
  mode: string;
  errors: KeyValueModel<string> | null;
}

const associationModeType = {
  sub: 'sub',
  parent: 'parent',
  noAssociation: 'noAssociation',
};

const associationMode: KeyValueModel<KeyValueModel<string>> = {
  sub: {
    title: t('common:label.Request-Sub-organization-Association'),
  },
  parent: {
    title: t('common:label.Request-Parent-Organization-Association'),
  },
};

const AssociationOrganizationPreview = ({ loader }: IProps) => {
  const { t } = useTranslation();

  const [state, setState] = useState<IState>({
    mode: '',
    errors: null,
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: StateType) => state.profile.authUser);
  const [modals, setModals] = useState<ModalsModel>({});

  const handleClick = (mode: string) => {
    setState({
      ...state,
      mode: mode,
    });
  };

  const onBack = () => {
    setState({
      ...state,
      mode: '',
      errors: {},
    });
  };

  const onSubmit = (formData: any) => {
    const data = { ...formData };
    if (state.mode === associationModeType.sub) {
      data.org_id = user && user.orgs_role && user.orgs_role[0].org_id;
    }
    if (state.mode === associationModeType.parent) {
      data.org_id = user && user.orgs_role && user.orgs_role[0].org_id;
      data.is_parent = true;
    }

    dispatch(
      associationOrganization({
        callback: response => associationOrganizationSuccess(false, response),
        data: data,
      }),
    );
  };

  const associationOrganizationSuccess = (skip: boolean, response: any) => {
    if (response.error?.response?.data?.alias === 'same_org') {
      setModals({
        ...modals,
        [Modals.isSameOrgAssign]: true,
      });
      return;
    }
    if (response?.error?.response?.data?.errors) {
      setState({
        ...state,
        errors: response.error.response.data.errors,
      });
      return;
    }
    dispatch(changeAuthStepAction(response.next_step));
    if (skip) {
      history.push(ROOTS.CREATE_PROFILE.replace(':step', `role`));
    } else {
      history.push(ROOTS.ASSOCIATION_SEND_PREVIEW);
    }
  };

  const handleSkipAssociation = () => {
    dispatch(
      associationOrganization({
        callback: (response: any) => associationOrganizationSuccess(true, response),
        data: {
          skip: true,
        },
      }),
    );
  };

  const closeModal = (modal: keyof ModalsModel) => {
    setModals(prevState => ({
      ...prevState,
      [modal]: false,
    }));
  };

  return (
    <>
      {loader}
      {!state.mode && (
        <div className={'container container-header white-container'}>
          <Header type={'awa'} />
          <main className={`white-container ${styles.container}`}>
            <div className={'container-page-center'}>
              <div className={'container-row m-0'}>
                <Image width={'190px'} height={'190px'} source={Logo} alt={t('awa:AT73')} />
              </div>
              <div className={'container-row m-0'}>
                <span className={`b-title ${styles.title}`}>{t('awa:M9.title')}</span>
              </div>
              <div className={'container-row m-0'}>
                <span className={`sub-title ${styles.sub_title}`}>{t('awa:M9.msg')}</span>
              </div>
              <div className={'container-row mb-3'}>
                <Button
                  type={'orange-light'}
                  onPress={handleSkipAssociation}
                  title={t('common:btn.no-association')}
                  className={styles.button}
                  enableEnter
                />
              </div>
              <div className={'container-row mb-3'}>
                <Button
                  onPress={() => handleClick(associationModeType.parent)}
                  title={t('common:btn.parent-organization')}
                  className={styles.button}
                  type={'bordered'}
                />
              </div>
              <div className={'container-row'}>
                <Button
                  onPress={() => handleClick(associationModeType.sub)}
                  title={t('common:btn.sub-organization')}
                  className={styles.button}
                  type={'bordered'}
                />
              </div>
            </div>
          </main>
          <Footer />
        </div>
      )}
      {modals.isSameOrgAssign && (
        <Alert
          isOpen={true}
          onRequestClose={() => closeModal(Modals.isSameOrgAssign)}
          title={t('awa:N30.title')}
          text={
            <>
              <p>{t('awa:N30.msg.p1')}</p>
              <p>{t('awa:N30.msg.p2')}</p>
            </>
          }
        />
      )}
      {state.mode && (
        <JoinOrganizationContainer
          title={associationMode[state.mode].title}
          mode={state.mode}
          onBackCallback={onBack}
          parentErrors={state.errors}
          onSubmitCallback={onSubmit}
        />
      )}
    </>
  );
};

export default AssociationOrganizationPreview;
