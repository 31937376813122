import React, { FC, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Button, Loader, Select } from '../../../../../controls';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import { CreateEAStep, EAUpdateData, ISelectOptions } from '../../../../../../models';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../../store/reducers';
import { useTranslation } from 'react-i18next';

interface IProps {
  onChangeStep: (step: CreateEAStep) => void;
  handleCancel: () => void;
  saveData: (data: EAUpdateData, isNext: boolean) => void;
}

interface IState {
  geographic_scope: ISelectOptions[];
  geographic_area: ISelectOptions[];
}

const CreateEAStep6: FC<IProps> = ({ onChangeStep, handleCancel, saveData }) => {
  const { t } = useTranslation();
  const createEAData = useSelector((state: StateType) => state.equityAudit.createEAData);
  const EAOptions = useSelector((state: StateType) => state.equityAudit.EAOptions);

  const initialState: IState = {
    geographic_scope: [],
    geographic_area: [],
  };

  let [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (createEAData && EAOptions) {
      setState(prevState => ({
        ...prevState,
        geographic_scope: EAOptions.geographic_scope.filter(item =>
          createEAData.geographic_scope.includes(item.value as number),
        ),
        geographic_area: EAOptions.geographic_area.filter(item =>
          createEAData.geographic_area.includes(item.value as number),
        ),
      }));
    }
  }, [createEAData, EAOptions]);

  const handleSelect = (fieldName: string, value: ISelectOptions) => {
    setState({ ...state, [fieldName]: value });
  };

  const handleSaveExit = (e: any) => {
    e.preventDefault();
    saveData(
      {
        geographic_scope: state.geographic_scope.map(item => item.value) as number[],
        geographic_area: state.geographic_area.map(item => item.value) as number[],
        ea_step: 'set_geo',
      },
      false,
    );
  };

  const handleNext = () => {
    saveData(
      {
        geographic_scope: state.geographic_scope.map(item => item.value) as number[],
        geographic_area: state.geographic_area.map(item => item.value) as number[],
        ea_step: 'set_sector',
      },
      true,
    );
  };

  const _isNextBtnDisabled = () => {
    return !(state.geographic_scope && state.geographic_area);
  };

  if (!(createEAData && EAOptions)) return <Loader fitParent />;

  return (
    <>
      <div className={'b-EACreate__step'}>
        <div className="b-EACreate__header">
          <h1 className={'pageContainer__title b-EATypo__pageTitle'}>
            {t('common:headlines.your-organization')}
          </h1>
          {!isMobileOnly && (
            <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
              <img src={SaveIc} alt={t('common:alt.saveIc')} />
              {t('common:btn.save-exit')}
            </a>
          )}
        </div>
        <div className={'b-step__orgWrapper'}>
          <div className="b-equityAudit__text16">{t('common:headlines.organization')}</div>
          <div className={'b-step__orgText'}>{createEAData.organization_name}</div>
        </div>
        <Select
          isMulti
          name={'organization'}
          value={state.geographic_scope}
          placeholder={t('common:label.geographic-scopes')}
          ariaLabel="Geographic Scopes of Services"
          options={EAOptions.geographic_scope}
          error={''}
          handleChange={(value: ISelectOptions) => handleSelect('geographic_scope', value)}
          height={52}
          autoFocus
        />
        <Select
          isMulti
          name={'organization'}
          value={state.geographic_area}
          placeholder={t('common:label.geographic-areas')}
          ariaLabel="Geographic Areas Served"
          options={EAOptions.geographic_area}
          error={''}
          handleChange={(value: ISelectOptions) => handleSelect('geographic_area', value)}
          height={52}
        />
        <div className="b-equityAudit__text12 -mtMinus10">{t('common:label.may-select-more-than-one')}</div>
        <div className={`b-step__bottom`}>
          <div className={`b-step__bottom-left`}>
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={() => onChangeStep('set_org_details')}
              title={t('common:btn.back')}
            />
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={handleCancel}
              title={t('common:btn.cancel')}
            />
          </div>
          {isMobileOnly ? (
            <div className={`b-step__bottom-left`}>
              <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
                <img src={SaveIc} alt={t('common:alt.saveIc')} />
                {t('common:btn.save-exit')}
              </a>
              <Button
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleNext}
                disabled={_isNextBtnDisabled()}
                title={t('common:btn.next')}
                enableEnter
              />
            </div>
          ) : (
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={handleNext}
              disabled={_isNextBtnDisabled()}
              title={t('common:btn.next')}
              enableEnter
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CreateEAStep6;
