import React from 'react';
import { ReactSVG } from 'react-svg';

interface IImage {
  width?: string;
  height: string;
  source: string;
  alt?: string;
  className?: string;
  role?: string;
}

const SVG = ({ width, height, source, className, alt, role }: IImage) => {
  return (
    <ReactSVG
      className={`svg ${className || ''}`}
      src={source}
      alt={alt || ''}
      role={role}
      style={{ width: width || 'auto', height: height }}
    />
  );
};

export default SVG;
