import React, { FC } from 'react';
import { Button } from '../../../../../controls';
import StepIndicator from '../StepIndicator/StepIndicator';
import { InstructionStep } from '../../../../../../models';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

interface IProps {
  setCurrentStep: (step: InstructionStep) => void;
}

const Step2: FC<IProps> = ({ setCurrentStep }) => {
  const { t } = useTranslation();
  const history: any = useHistory();

  return (
    <div className={'b-step b-step__container -w735'}>
      <div style={{ marginBottom: 8 }} className="b-equityAudit__text16 b-equityAudit__textCenter">
        {t('common:headlines.ea-preparation')}
      </div>
      <div style={{ marginBottom: 16 }} className={'b-step__bigTitle'}>
        {t('awa:M29.msg.p1')}
      </div>
      <div style={{ marginBottom: 36 }}>
        <StepIndicator step={2} />
      </div>
      <div className="b-step__p">{t('awa:M29.msg.p2')}</div>
      <ul style={{ marginBottom: 20 }}>
        <li>{t('awa:M29.msg.p3.part1')}</li>
        <li>{t('awa:M29.msg.p3.part2')}</li>
        <li>{t('awa:M29.msg.p3.part3')}</li>
        <li>{t('awa:M29.msg.p3.part4')}</li>
        <li>{t('awa:M29.msg.p3.part5')}</li>
        <li>{t('awa:M29.msg.p3.part6')}</li>
        <li>{t('awa:M29.msg.p3.part7')}</li>
        <li>{t('awa:M29.msg.p3.part8')}</li>
      </ul>

      <div className="b-step__wBox -paddings">
        <div className="b-step__orangeBold b-step__textCenter -mb5">{t('main:ea-create.task')}:</div>
        <div className="b-step__p b-step__textCenter" style={{ marginBottom: 0 }}>
          {t('main:ea-create.identify')}
        </div>
      </div>
      <div className={`b-step__bottom`}>
        <Button
          className={'btn -type_form'}
          type={'transparency'}
          onPress={() => history.goBack()}
          title={t('common:btn.cancel')}
        />
        <div className={`b-step__bottom-left`}>
          <Button
            className="btn -type_form b-step__mr8"
            type={'bordered'}
            onPress={() => setCurrentStep('step1')}
            title={t('common:btn.back')}
          />
          <Button
            className={'btn -type_form'}
            type={'orange-light'}
            onPress={() => setCurrentStep('step3')}
            title={t('common:btn.next')}
            enableEnter
          />
        </div>
      </div>
    </div>
  );
};

export default Step2;
