import styles from '../../common/AddressAutocomplete/AddressAutocompleteStyle.module.scss';
import React, { useState } from 'react';
import { IAddressFields, IErrors } from '../../pages/Profile/YourOfficeAddress/OfficeAddressContainer';
import Autocomplete from 'react-google-autocomplete';
import { ApiAddressModel } from '../../../models';
import { GOOGLE_CONSOLE_KEY } from '../../../constants';

interface IProps {
  onChange: (field: string) => (value: any) => void;
  fields: IAddressFields | ApiAddressModel;
  placeholder: string;
  errors: IErrors;
  onChangeLocation: (location: any, ref?: any) => void;
  disabled?: boolean;
  tabIndex?: number;
  ariaLabel?: string;
}

const AutocompleteAddress = ({
  onChange,
  fields,
  placeholder,
  errors,
  onChangeLocation,
  disabled,
  tabIndex,
  ariaLabel,
}: IProps) => {
  const [isFocusAddress, setFocusAddress] = useState(false);

  const onInputFocus = () => {
    setFocusAddress(true);
  };

  const onInputBlur = () => {
    setFocusAddress(false);
  };

  return (
    <div className={styles.input_wrapper}>
      <div
        className={`
                    ${styles.input_placeholder_block}
                    ${isFocusAddress ? styles.input_label_focus : ''}
                `}
        data-content={fields?.address ? placeholder : ''}
      >
        <Autocomplete
          apiKey={`${GOOGLE_CONSOLE_KEY}&callback=Function.prototype`}
          tabIndex={tabIndex}
          placeholder={placeholder}
          onChange={onChange('address')}
          defaultValue={fields.address}
          onPlaceSelected={onChangeLocation}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          aria-label={ariaLabel}
          value={fields.address}
          options={{
            types: ['address'],
            componentRestrictions: { country: fields.country || 'us' },
          }}
          disabled={disabled}
          className={`
                    ${styles.input} 
                    ${!fields.country ? styles.input_disable : ''} 
                    ${isFocusAddress ? styles.input_focus : ''}
                    ${errors.address ? styles.input_error : ''}
                    `}
        />
      </div>
      <div className={'error-notification'}>
        <span className={'error-notification-text'}>{errors.address}</span>
      </div>
    </div>
  );
};

export default AutocompleteAddress;
