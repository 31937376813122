import { RefObject, useEffect } from 'react';

export const useClickOutside = (elRef: RefObject<any>, cb: () => void) => {
  useEffect(() => {
    const handler = (e: any) => {
      if (!elRef?.current.contains(e.target)) {
        cb();
      }
    };

    document.addEventListener('click', handler, true);

    return () => {
      document.removeEventListener('click', handler, true);
    };
  }, []);
};
