import React, { FC, useEffect } from 'react';
import { FunctionGroup, FunctionGroupQ } from '../../../../../../models';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../../store/reducers';
import { useTranslation } from 'react-i18next';

interface IProps {
  function_groups: FunctionGroup[];
  setCurrentGroupId: (id: string) => void;
  currentGroupId: string;
  isSubmit?: boolean;
}

const Indicator: FC<IProps> = ({ function_groups, setCurrentGroupId, currentGroupId, isSubmit }) => {
  const { t } = useTranslation();
  const takingEA = useSelector((state: StateType) => state.equityAudit.takingEA);
  if (!takingEA) return null;

  useEffect(() => {
    doMagic();
  }, [setCurrentGroupId]);

  const doMagic = () => {
    const list = document.getElementById('step-list');
    const el = document.getElementById('active-step');
    if (list && el) {
      list.scrollLeft = el.offsetLeft - list.offsetWidth / 2 + el.offsetWidth / 2;
    }
  };

  const groups: FunctionGroupQ[] = function_groups.map(g => {
    const questions = takingEA.questions.filter(q => q.function_group_id === g.id);
    return {
      ...g,
      questions,
    };
  });

  const handlePress = (id: string) => (e: any) => {
    e.preventDefault();
    setCurrentGroupId(id);
  };

  const _getIndicatorInfo = (group: FunctionGroupQ): { classname: string; qCounter: number } => {
    const qLength: number = group.questions.length;
    let answeredQuestionsCount: number = 0;
    group.questions.forEach(q => {
      if (q.answer) {
        answeredQuestionsCount = answeredQuestionsCount + 1;
      }
    });

    const data = {
      qCounter: answeredQuestionsCount,
      classname: '',
    };
    if (group.is_skipped) {
      data.classname = 'pin-red';
      return data;
    }
    if (answeredQuestionsCount === 0) {
      data.classname = 'pin-gray';
      return data;
    }
    if (answeredQuestionsCount === qLength) {
      data.classname = 'pin-green';
      return data;
    }
    data.classname = 'pin-yellow';
    return data;
  };

  return (
    <div className="b-EATaking__stepsBlock">
      <ul className="b-EATaking__steps" id="step-list">
        {groups.map(group => {
          const isActive = !isSubmit && group.id === currentGroupId;
          const { classname, qCounter } = _getIndicatorInfo(group);
          return (
            <li
              id={isActive ? 'active-step' : ''}
              onClick={handlePress(group.id)}
              key={`fg-${group.alias}`}
              className={`${isActive ? 'active' : ''} ${classname}`}
            >
              <div className="b-EATaking__countingWrapper">
                <div className="b-EATaking__counting">
                  {t('main:taking-audit.indicator.answer', {
                    qCounter: qCounter,
                    questions: group.questions.length,
                  })}
                </div>
              </div>
              {group.name}
            </li>
          );
        })}
        <li className={`disabled ${isSubmit ? 'active pin-green' : 'pin-gray'}`}>
          {t('main:taking-audit.indicator.submission')}
        </li>
      </ul>
    </div>
  );
};

export default Indicator;
