import React, { FC } from 'react';
import styles from './SimpleTooltip.module.scss';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

interface IProps {
  title: JSX.Element | string;
  className?: string;
}

const SimpleTooltipPopover: FC<IProps> = ({ title, children, className }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement: 'top',
  });
  return (
    <>
      <div ref={setTriggerRef}>{children}</div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: `tooltip-container ${styles.body} ${className}` })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {title}
        </div>
      )}
    </>
  );
};

export default SimpleTooltipPopover;
