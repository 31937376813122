import React, { FC, useState } from 'react';
import { PopupPortal, SortModal } from '../index';
import { Column, SortColumn } from 'react-data-grid';
import { QueryParamsModel } from '../../../hooks/common/use-table-data';
import { useTranslation } from 'react-i18next';

interface IProps {
  columns: Column<any>[];
  query: QueryParamsModel;
  sortingData: QueryParamsModel['sorting'];
  setSortingData: (data: QueryParamsModel['sorting']) => void;
  handleChangeSortField: (field: string) => void;
  handleSort: (sortData: SortColumn, noSetState?: boolean) => void;
  disabled?: boolean;
}

const SortModalButton: FC<IProps> = ({
  columns,
  query,
  sortingData,
  handleChangeSortField,
  setSortingData,
  handleSort,
  disabled,
}) => {
  const { t } = useTranslation();
  let [open, setOpen] = useState(false);

  const handleSaveSorting = () => {
    handleSort(sortingData as SortColumn);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setSortingData(query.sorting);
  };

  return (
    <>
      <button
        aria-label="Click to Sort"
        onClick={() => setOpen(true)}
        type="button"
        className={`
                    b-tableTool -sort -colorGrey -mr
                    ${query.sorting && '-active'}
                  `}
        disabled={disabled}
      >
        {t('common:btn.sort')}
      </button>
      {open ? (
        <PopupPortal portalElementId={'table-sorting-portal'}>
          <SortModal
            onClose={handleClose}
            sortingData={sortingData}
            columns={columns}
            handleChangeSortField={handleChangeSortField}
            handleSaveSorting={handleSaveSorting}
          />
        </PopupPortal>
      ) : null}
    </>
  );
};

export default SortModalButton;
