import Api from '../../../services/api';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { ISelectOptions } from '../../../models';

const useCredits = () => {
  const getOrganizations = async (inputValue: string, callback?: (data: ISelectOptions[]) => void) => {
    try {
      const response = await Api.post(`beloved/organization_credits/get_available_organization/`, {
        organization_name: inputValue,
      });
      if (response) {
        callback && callback(response);
      }
    } catch (e: any) {
      if (e.response.status === 404) {
        toast.error(e.response.data.detail);
        history.go(-1);
      } else {
        toast.error(t('common:toast.error'));
      }
    }
  };

  const revokeCredits = async (ids: number[], callback?: () => void) => {
    try {
      const response = await Api.post(`beloved/organization_credits/revoke/`, { credit_ids: ids });
      if (response) {
        callback && callback();
      }
    } catch (e: any) {
      if (e.response.status === 404) {
        toast.error(e.response.data.detail);
        history.go(-1);
      } else {
        toast.error(t('common:toast.error'));
      }
    }
  };
  const reimburseCredits = async (ids: number[], callback?: () => void) => {
    try {
      const response = await Api.post(`beloved/organization_credits/reimburse/`, { credit_ids: ids });
      if (response) {
        callback && callback();
      }
    } catch (e: any) {
      if (e.response.status === 404) {
        toast.error(e.response.data.detail);
        history.go(-1);
      } else {
        toast.error(t('common:toast.error'));
      }
    }
  };

  const reissueCredits = async (ids: number[], callback?: () => void) => {
    try {
      const response = await Api.post(`beloved/organization_credits/re_issue/`, { credit_ids: ids });
      if (response) {
        callback && callback();
      }
    } catch (e: any) {
      if (e.response.status === 404) {
        toast.error(e.response.data.detail);
        history.go(-1);
      } else {
        toast.error(t('common:toast.error'));
      }
    }
  };

  return {
    getOrganizations,
    revokeCredits,
    reimburseCredits,
    reissueCredits,
  };
};

export default useCredits;
