import React, { FC, useState } from 'react';
import styles from './ElmFileUploader.module.scss';
import { useDispatch } from 'react-redux';
import { uploadUserListFile } from '../../../store/elm/elmActions';
import { ElmFileModel, ElmModel } from '../../../models';
import Api from '../../../services/api';
import { getOrgIdFormRoute } from '../../../helpers';
import { Alert } from '../../common';
import { Modals, ModalsModel } from '../../../constants';
import { useTranslation } from 'react-i18next';
import ModalView from '../ModalView/ModalView';
import { EmptyLink } from '../index';
const fileDownload = require('js-file-download');

interface IProps {
  elmId: number;
  value: ElmFileModel | null;
  import_errors?: boolean;
  onFileLoad?: (data: ElmModel) => void;
  disabled?: boolean;
}

const ElmFileUploader: FC<IProps> = ({ elmId, value, import_errors, onFileLoad, disabled }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orgId = getOrgIdFormRoute();
  const [modals, setModals] = useState<ModalsModel>({});

  let [file, setFile] = useState<ElmFileModel | null>(value);
  let [warning, setWarning] = useState({
    show: false,
    shown: false,
  });
  let [error, setError] = useState(import_errors);

  const handleChange = (e: any) => {
    dispatch(
      uploadUserListFile({
        elmId,
        file: e.target.files[0],
        callback: (data, err) => {
          if (data) {
            setFile(data.import_file);
            setError(data.import_errors);
            setWarning({
              show: false,
              shown: false,
            });
            onFileLoad && onFileLoad(data);
          }
          if (err && err.alias === 'users-exceeded') {
            setModals(prevState => ({
              ...prevState,
              [Modals.isMaxUsers]: err.credit_max_users,
            }));
          }
          e.target.value = '';
        },
      }),
    );
  };

  const handleClickInput = (e: any) => {
    if (warning.shown) {
      return;
    }
    if (file) {
      e.preventDefault();
      setWarning({ ...warning, show: true });
    }
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    Api.get(`organizations/${orgId}/elm/${elmId}/file_actions/validate/`).then(resp => {
      fileDownload(resp, 'import_users_errors.csv');
    });
  };

  const handleAcceptUpload = () => {
    const input = document.getElementById('user-list-input');
    setWarning({ show: false, shown: true });
    if (input) {
      setTimeout(() => {
        input.click();
      }, 100);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          {file && (
            <div className={styles.file}>
              <span>{file.name}</span>
            </div>
          )}
          <label className={`b-uploadFileLink__label ${disabled ? '-disabled' : ''}`}>
            {t('common:label.upload-new-file')}
            <input
              disabled={disabled}
              id="user-list-input"
              onClick={handleClickInput}
              accept=".csv"
              className="b-uploadFileLink__input"
              type="file"
              onChange={handleChange}
            />
          </label>
        </div>
        {error ? (
          <span className="error-notification-text">
            {t('common:label.file-upload-errors-please')}&nbsp;
            <a href="#" onClick={handleClick} className="b-elmCreate__downloadLink">
              {t('common:label.download-file')}
            </a>
          </span>
        ) : null}
      </div>
      {warning.show ? (
        <Alert
          isOpen={warning.show}
          onRequestClose={() => setWarning({ ...warning, show: false })}
          buttons={{
            left: {
              type: 'white',
              onClick: () => setWarning({ ...warning, show: false }),
              title: t('common:btn.cancel'),
            },
            right: {
              type: 'orange-light',
              onClick: handleAcceptUpload,
              title: t('common:btn.ok'),
            },
          }}
          text={t('awa:N12.msg.p1')}
        />
      ) : null}
      {modals.isMaxUsers && (
        <ModalView isOpen={true} onRequestClose={() => setModals({})}>
          <div className={'b-modal__add-user'}>
            <EmptyLink className={'b-modal__cross'} onClick={() => setModals({})} />
            {t('awa:ER8.msg', { x: modals.isMaxUsers })}
          </div>
        </ModalView>
      )}
    </>
  );
};

ElmFileUploader.defaultProps = {
  import_errors: false,
};

export default ElmFileUploader;
