import React, { FC, useEffect, useRef, useState } from 'react';
import { Modal } from '../index';
import { Button } from '../../controls';
import { useTranslation } from 'react-i18next';
import { BaseNameModel } from '../../../models';
import { validateEmail } from '../../../helpers';

interface IProps {
  onClose: () => void;
  onSubmit: (emails: string[]) => void;
}

const SendEmailModal: FC<IProps> = ({ onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [emails, setEmails] = useState<BaseNameModel[]>([]);
  const [error, setError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onPressKey = (event: KeyboardEvent) => {
    if (inputValue.length && (event.code === 'Space' || event.code === 'Enter' || event.code === 'Comma')) {
      const newEmail = inputValue.replace(',', '').trim();
      const isValid = validateEmail(newEmail);

      if (!isValid) return;
      setEmails([
        ...emails,
        {
          id: Math.random(),
          name: newEmail,
        },
      ]);
      setInputValue('');
      return;
    }
    if (!inputValue.length && event.code === 'Backspace') {
      setInputValue(emails[emails.length - 1].name);
      setEmails(prevState => {
        return [...prevState.slice(0, emails.length - 1)];
      });
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onPressKey);
    return () => {
      window.removeEventListener('keyup', onPressKey);
    };
  }, [inputValue, emails]);

  const handleClickBox = () => {
    inputRef.current?.focus();
  };

  const removeEmail = (id: number) => {
    setEmails(prevState => {
      return [...prevState.filter(item => item.id !== id)];
    });
  };

  const handleChangeInput = (value: string) => {
    setInputValue(value);
    setError(false);
  };

  const handleSubmit = () => {
    if (!emails.length) {
      const email: string = inputValue.trim();
      const isValid = validateEmail(email);
      if (isValid) {
        onSubmit([email]);
      } else {
        setError(true);
      }
      return;
    }
    onSubmit(emails.map(item => item.name));
  };

  return (
    <Modal minHeight={400} title="Test Email Addresses" onClose={onClose}>
      <div className="font-s text-center font-grey mt-2 mx-5">{t('main:marketing.m1')}</div>
      <div className="mt-4 b-sendEmailModal__boxWrapper">
        <div className="b-sendEmailModal__box" onClick={handleClickBox}>
          {emails.map(item => {
            return (
              <div onClick={() => removeEmail(item.id)} className="b-sendEmailModal__emailItem" key={item.id}>
                {item.name}
              </div>
            );
          })}
          <input
            ref={inputRef}
            value={inputValue}
            onChange={event => handleChangeInput(event.target.value)}
            type="text"
            className="b-sendEmailModal__input"
          />
        </div>
        {error ? (
          <div className="error-notification-text">{t('common:errors.incorrect-email-format')}</div>
        ) : null}
      </div>
      <div className="d-flex mt-4 justify-content-center">
        <Button
          size="middle"
          className="button-mh"
          title={t('common:btn.cancel')}
          type="transparency"
          onPress={onClose}
        />
        <Button size="middle" className="button-mh" title={t('common:btn.send')} onPress={handleSubmit} />
      </div>
    </Modal>
  );
};

export default SendEmailModal;
