import React, { FC } from 'react';
import { urlWithToken } from '../../../helpers';

interface IProps {
  src: string;
  alt: string;
  className?: string;
}

const ImageSecured: FC<IProps> = ({ src, alt, className }) => {
  return <img className={className} src={urlWithToken(src)} alt={alt} />;
};

export default ImageSecured;
