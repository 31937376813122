import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import * as pages from '../components/pages';
import { StateType } from '../store/reducers';
import { ROOTS } from './routes';
import AuthNavigationHelper from '../helpers/AuthNavigationHelper';
import { isMobileOnly } from 'react-device-detect';
import InternalAppWrapper from '../components/common/InternalAppWrapper/InternalAppWrapper';
import { SHOW_MAINTENANCE_PAGE } from '../constants';

export const Router = (): JSX.Element => {
  const { isAuthenticated, authUser } = useSelector(
    ({ authData: { isAuthenticated }, profile: { authUser } }: StateType) => ({
      isAuthenticated,
      authUser,
    }),
  );
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!authUser) {
      return;
    }
    if (authUser && authUser.login) {
      return;
    }

    const localStep = localStorage.getItem('next_step');
    if (authUser.next_step > 2 || Number(localStep) > 2) {
      const url = AuthNavigationHelper.getUrl(authUser.next_step || Number(localStep) || 0);
      history.push(url.length > 1 ? url : ROOTS.SIGNIN);
    }
  }, [authUser]);

  const step = (authUser && authUser.next_step) || Number(localStorage.getItem('next_step')) || 0;
  if (SHOW_MAINTENANCE_PAGE) {
    return (
      <Switch>
        <Route path={ROOTS.MAINTENANCE} exact component={pages.Maintenance} />
        <Redirect to={ROOTS.MAINTENANCE} />
      </Switch>
    );
  }
  if (isAuthenticated || authUser?.login) {
    const isBelovedApp = !!authUser?.beloved_user;
    const isCertifiedConsultant = !!authUser?.beloved_certified_consultant;

    const getDefaultRoute = (): string => {
      if (isBelovedApp) {
        return ROOTS.BELOVED_DASHBOARD_EA;
      } else {
        return ROOTS.HOME_ORG;
      }
    };

    let routes;

    if (isBelovedApp || isCertifiedConsultant) {
      routes = (
        <Switch>
          <Route path={ROOTS.BELOVED_ORGANIZATIONS} exact component={pages.Organizations} />
          <Route path={ROOTS.BELOVED_ORG_USER_LIST} exact component={pages.BelovedOrgUserList} />
          <Route path={ROOTS.BEL_ORG_ASSIGNMENT_LIST} exact component={pages.BelOrgAssignmentList} />
          <Route path={ROOTS.ORG_BELOVED_CONSULTANT} exact component={pages.OrgCertifiedUserList} />

          <Route path={ROOTS.BELOVED_ORGANIZATION_DETAILS} exact component={pages.OrganizationProfile} />
          <Route path={ROOTS.BELOVED_PM_PROFILE} component={pages.PMProfile} />
          <Route path={ROOTS.BELOVED_ORG_CREDITS} exact component={pages.OrgCredits} />
          <Route path={ROOTS.BELOVED_CREDIT_LIST} exact component={pages.CreditList} />
          <Route path={ROOTS.BELOVED_DASHBOARD_EA} component={pages.DashboardEa} />
          <Route path={ROOTS.BELOVED_DASHBOARD_ELM} component={pages.DashboardElm} />
          <Route path={ROOTS.BELOVED_DASHBOARD_MAP} component={pages.DashboardMap} />
          <Route path={ROOTS.BELOVED_EQUITY_AUDITS} exact component={pages.EquityAudits} />
          <Route path={ROOTS.BEL_GROUP_ANALYSIS} exact component={pages.BelGroupAnalysis} />
          <Route path={ROOTS.BELOVED_EQUITY_AUDIT_DETAILS} exact component={pages.EquityAuditDetails} />
          <Route path={ROOTS.BELOVED_ADMIN_TOOL_ROLES} component={pages.AdminToolRoles} />
          <Route path={ROOTS.BELOVED_ADMIN_TOOL_USERS} component={pages.AdminToolUsers} />
          <Route path={ROOTS.BELOVED_ADMIN_TOOL_ORG_USERS} component={pages.AdminToolOrgUsers} />
          <Route path={ROOTS.BELOVED_ADMIN_TOOL_ORG_USER_PROFILE} component={pages.AdminToolOrgUserProfile} />
          <Route path={ROOTS.BELOVED_ADMIN_TOOL_SETTINGS} component={pages.AdminToolSettings} />
          <Route path={ROOTS.BELOVED_DEBRIEFS} component={pages.Debriefs} />
          <Route path={ROOTS.BELOVED_ELM_LIST} exact component={pages.Elms} />
          <Route path={ROOTS.BELOVED_ELM_DETAILS} exact component={pages.ElmDetailsBel} />
          <Route path={ROOTS.BELOVED_MY_BIO} exact component={pages.MyBiography} />

          <Route path={ROOTS.BELOVED_MESSAGE_LIST} exact component={pages.MessageList} />
          <Route path={ROOTS.BELOVED_MESSAGE_CREATE} exact component={pages.MessageForm} />
          <Route path={ROOTS.BELOVED_MESSAGE_DETAILS} exact component={pages.MessageForm} />
          <Route path={ROOTS.BELOVED_MESSAGE_ORGANIZATIONS} exact component={pages.MessageOrganizations} />

          <Route path={ROOTS.GROUP_AVERAGES} component={pages.GroupAveragesEAs} />
          <Route path={ROOTS.GROUP_AVERAGES_CHART_ORG} component={pages.GroupAveragesChartOrg} />
          <Route path={ROOTS.GROUP_AVERAGES_SAVED} component={pages.GroupAveragesSaved} />
          <Route path={ROOTS.GROUP_AVERAGES_SAVED_FOR_EAs} component={pages.GroupAveragesSavedForEAs} />
          <Route path={ROOTS.GROUP_AVERAGES_CHART} component={pages.GroupAveragesChart} />

          <Route path={ROOTS.BEL_GROUP_AVERAGES} component={pages.GroupAveragesEAs} />
          <Route path={ROOTS.BEL_GROUP_AVERAGES_CHART_ORG} component={pages.GroupAveragesChartOrg} />
          <Route path={ROOTS.BEL_GROUP_AVERAGES_CHART} component={pages.GroupAveragesChart} />
          <Route path={ROOTS.BEL_GROUP_AVERAGES_SAVED} component={pages.GroupAveragesSaved} />
          <Route path={ROOTS.BEL_GROUP_AVERAGES_SAVED_FOR_EAs} component={pages.GroupAveragesSavedForEAs} />

          {!isMobileOnly ? (
            <Route path={ROOTS.BELOVED_CREATE_RESOURCE} exact component={pages.CreateResource} />
          ) : null}
          <Route path={ROOTS.BELOVED_RESOURCE_VIEW} exact component={pages.ResourceView} />
          <Redirect from={ROOTS.RESOURCE_VIEW} to={ROOTS.BELOVED_RESOURCE_VIEW} />

          {!isMobileOnly ? (
            <Route path={ROOTS.BELOVED_RESOURCE_DETAILS} exact component={pages.ResourceDetails} />
          ) : null}
          {!isMobileOnly ? (
            <Route path={ROOTS.BELOVED_RESOURCE_LIST_TABLE} exact component={pages.ResourceListTable} />
          ) : null}
          <Route path={ROOTS.BELOVED_RESOURCE_LIST_CARDS} exact component={pages.ResourceListCards} />

          <Route path={ROOTS.BELOVED_COHORT_DETAILS} component={pages.BelCohortDetails} />
          <Route path={ROOTS.BELOVED_COHORT_EDIT} component={pages.BelCohortDetailsForm} />
          <Route path={ROOTS.BELOVED_COHORT_SESSION_DETAILS} component={pages.CohortSessionDetails} />
          <Route
            path={ROOTS.BELOVED_COHORT_PARTICIPANT_DETAILS}
            component={pages.CohortParticipantsDetails}
          />
          <Route path={ROOTS.BELOVED_COHORT_MESSAGE_DETAILS} component={pages.CohortMessageDetails} />
          <Route path={ROOTS.BELOVED_COHORT_SESSION} component={pages.BelCohortSessionForm} />
          <Route path={ROOTS.BELOVED_COHORT_MESSAGE} component={pages.BelCohortMessageForm} />
          <Route path={ROOTS.BELOVED_COHORT_GENERAL_RESOURCES} component={pages.BelGeneralResources} />
          <Route path={ROOTS.BELOVED_COHORT_RESOURCE_LIB} component={pages.BelResourceLibrary} />
          <Route path={ROOTS.BELOVED_COHORT_CREATE} component={pages.BelCohortDetailsForm} />
          <Route path={ROOTS.BELOVED_COHORT_LIST} component={pages.BelCohortList} />

          <Route path={ROOTS.BELOVED_EWP_LIST} component={pages.EwpList} />
          <Route path={ROOTS.BELOVED_EWP_DETAILS} component={pages.EwpDetails} />
          <Route path={ROOTS.BELOVED_EWP_PREPARATION} component={pages.PreparationEWP} />
          {/*{sharedRoutes}*/}
          <Route path={ROOTS.HOME_ORG} component={pages.HomeOrgContainer} />
          <Route path={ROOTS.ORG_PROFILE} component={pages.OrgProfile} />
          <Route path={ROOTS.CREATE_SUB_ORG} component={pages.CreateSubOrg} />
          <Route
            path={ROOTS.CREATE_NEW_SUB_ORGANIZATION_SUCCESS}
            component={pages.SubOrganizationCreatedSuccess}
          />

          <Route path={ROOTS.ORG_USERS} exact component={pages.OrgUsers} />
          <Route path={ROOTS.ORG_USERS_CREATE} component={pages.AddNewUser} />
          <Route path={ROOTS.ADDED_NEW_USER_SUCCESS} component={pages.AddedNewUserSuccess} />
          <Route path={ROOTS.USER_PROFILE} component={pages.UserProfile} />

          <Route path={ROOTS.EQUITY_AUDIT_LIST} component={pages.EquityAuditList} />
          <Route path={ROOTS.CREATE_EA_PREP} component={pages.CreateEAPrep} />
          <Route path={ROOTS.CREATE_EA} component={pages.CreateEA} />
          <Route path={ROOTS.EA_DETAILS} component={pages.EADetails} />
          <Route path={ROOTS.TAKING_EA} component={pages.TakingAudit} />

          <Route path={ROOTS.ELM_LIST} component={pages.ElmList} />
          <Route path={ROOTS.ELM_USER_LIST} exact component={pages.ElmUserList} />
          <Route path={ROOTS.CREATE_ELM_INTRO} component={pages.CreateElmIntro} />
          <Route path={ROOTS.CREATE_ELM} component={pages.CreateElm} />
          <Route path={ROOTS.ELM_DETAILS} exact component={pages.ElmDetails} />
          <Route path={ROOTS.TAKE_ELM} component={pages.TakeElm} />

          <Route path={ROOTS.ELM_MY_REPORT} component={pages.ElmMyReport} />
          <Route path={ROOTS.ELM_ORG_REPORT_RESP} component={pages.ElmOrgReportResp} />
          <Route path={ROOTS.ELM_ORG_REPORT_DOMAIN_RESULTS} component={pages.ElmOrgReportDomainResults} />

          <Route path={ROOTS.MY_ACCOUNT} component={pages.MyAccount} />
          <Route path={ROOTS.MY_PROFILE} component={pages.MyProfile} />
          <Route path={ROOTS.MY_SETTINGS} component={pages.MySettings} />
          <Route path={ROOTS.TERMS_CONDITIONS} component={pages.TermsConditions} />
          <Route path={ROOTS.PRIVACY_POLICY} component={pages.PrivacyPolicy} />
          <Route path={ROOTS.SUPPORT} component={pages.Support} />

          <Route path={ROOTS.BELOVED_USER_LIST} exact component={pages.BelovedUserList} />
          <Route
            path={ROOTS.BELOVED_CERTIFIED_CONSULTANT}
            exact
            component={pages.BelovedCertifiedConsultant}
          />
          <Route
            path={ROOTS.BELOVED_CERTIFIED_CONSULTANT_ASSIGNMENTS}
            exact
            component={pages.BelovedCertifiedConsultantAssignment}
          />
          <Route
            path={ROOTS.BELOVED_ORG_CERTIFIED_CONSULTANT}
            exact
            component={pages.BelovedCertifiedUserList}
          />
          <Route path={ROOTS.BELOVED_USER_PROFILE} exact component={pages.BelovedUserProfile} />
          <Route path={ROOTS.ORG_BELOVED_USER_LIST} exact component={pages.OrgBelovedUserList} />

          <Route path={ROOTS.COHORT_LIST} component={pages.CohortList} />
          <Route path={ROOTS.COHORT_PARTICIPANT_DETAILS} component={pages.CohortParticipantsDetails} />
          <Route path={ROOTS.COHORT_DETAILS} component={pages.CohortDetails} />
          <Route path={ROOTS.COHORT_ORGS} component={pages.CohortOrgs} />
          <Route path={ROOTS.COHORT_SESSION_DETAILS} exact component={pages.CohortSessionDetails} />
          <Route path={ROOTS.COHORT_MESSAGE_DETAILS} exact component={pages.CohortMessageDetails} />

          <Route path={ROOTS.EWP_PREPARATION} component={pages.PreparationEWP} />
          <Route path={ROOTS.EWP_CREATE} component={pages.EWPCreate} />
          <Route path={ROOTS.EWP_DETAILS} exact component={pages.EwpDetails} />
          <Route path={ROOTS.EWP_PRIORITY_LIST} exact component={pages.EwpPriorityList} />
          <Route path={ROOTS.EWP_REVIEW_ITEMS} exact component={pages.EwpReviewItems} />
          <Route path={ROOTS.EWP_STRATEGY_LIST} exact component={pages.EwpStrategyList} />
          <Route path={ROOTS.EWP_GOAL_LIST} exact component={pages.EwpGoalList} />
          <Route path={ROOTS.EWP_KA_LIST} exact component={pages.EwpKaList} />
          <Route path={ROOTS.EWP_PRIORITY_CREATE} exact component={pages.EwpPriorityCreate} />
          <Route path={ROOTS.EWP_GOAL_CREATE} exact component={pages.EwpGoalCreate} />
          <Route path={ROOTS.EWP_STRATEGY_CREATE} exact component={pages.EwpStrategyCreate} />
          <Route path={ROOTS.EWP_KA_CREATE} exact component={pages.EwpKaCreate} />
          <Route path={ROOTS.EWP_PRIORITY_DETAILS} exact component={pages.EwpPriorityDetails} />
          <Route path={ROOTS.EWP_GOAL_DETAILS} exact component={pages.EwpGoalDetails} />
          <Route path={ROOTS.EWP_DASHBOARD} exact component={pages.EwpDashboard} />
          <Route path={ROOTS.EWP_USERS} exact component={pages.EwpUsers} />
          <Route path={ROOTS.EWP_STRATEGY_DETAILS} exact component={pages.EwpStrategyDetails} />
          <Route path={ROOTS.EWP_KA_DETAILS} exact component={pages.EwpKaDetails} />

          <Route path={ROOTS.RESOURCE_LIST_CARDS} exact component={pages.ResourceListCards} />

          <Route path={ROOTS.MY_TASKS} component={pages.MyTasks} />

          <Route path={ROOTS.REPORT_FUNCTION_GROUPS} component={pages.FunctionGroups} />
          <Route path={ROOTS.REPORT_CHART_BAR} component={pages.ReportChartBar} />
          <Route path={ROOTS.REPORT_STANDARD_RESPONSES} component={pages.StandardResponses} />
          <Route path={ROOTS.REPORT_SCORE} component={pages.ScoreReport} />
          <Route path={ROOTS.REPORTS} component={pages.Reports} />

          <Route path={ROOTS.TERMS_CONDITIONS_PAYMENT} component={pages.TermsConditionsPayment} />
          <Route path={ROOTS.STRIPE_SETUP} component={pages.StripeSetup} />
          <Route path={ROOTS.DEBRIEF_SCHEDULING} component={pages.DebriefScheduling} />

          <Route path={ROOTS.GROUP_ANALYSIS} component={pages.GroupAnalysis} />
          <Route path={ROOTS.ORG_ANALYSIS} component={pages.OrgAnalysis} />
          <Route path={ROOTS.VIEW_DEBRIEF} component={pages.ViewDebrief} />

          <Route path={ROOTS.LINK_RESOURCE_LIST} exact component={pages.LinkResourceList} />
          <Route path={ROOTS.LINK_RESOURCE} exact component={pages.LinkResource} />

          <Route path={ROOTS.NOT_FOUND} component={pages.NotFount} />

          <Redirect to={getDefaultRoute()} />
        </Switch>
      );
    } else {
      routes = (
        <Switch>
          <Route path={ROOTS.RESOURCE_VIEW} exact component={pages.ResourceView} />
          {/*{sharedRoutes}*/}
          <Route path={ROOTS.HOME_ORG} component={pages.HomeOrgContainer} />
          <Route path={ROOTS.ORG_PROFILE} component={pages.OrgProfile} />
          <Route path={ROOTS.CREATE_SUB_ORG} component={pages.CreateSubOrg} />
          <Route
            path={ROOTS.CREATE_NEW_SUB_ORGANIZATION_SUCCESS}
            component={pages.SubOrganizationCreatedSuccess}
          />
          <Route path={ROOTS.ORG_BELOVED_CONSULTANT} exact component={pages.OrgCertifiedUserList} />

          <Route path={ROOTS.ORG_USERS} exact component={pages.OrgUsers} />
          <Route path={ROOTS.ORG_USERS_CREATE} component={pages.AddNewUser} />
          <Route path={ROOTS.ADDED_NEW_USER_SUCCESS} component={pages.AddedNewUserSuccess} />
          <Route path={ROOTS.USER_PROFILE} component={pages.UserProfile} />

          <Route path={ROOTS.EQUITY_AUDIT_LIST} component={pages.EquityAuditList} />
          <Route path={ROOTS.CREATE_EA_PREP} component={pages.CreateEAPrep} />
          <Route path={ROOTS.CREATE_EA} component={pages.CreateEA} />
          <Route path={ROOTS.EA_DETAILS} component={pages.EADetails} />
          <Route path={ROOTS.TAKING_EA} component={pages.TakingAudit} />

          <Route path={ROOTS.ELM_LIST} component={pages.ElmList} />
          <Route path={ROOTS.ELM_USER_LIST} exact component={pages.ElmUserList} />
          <Route path={ROOTS.CREATE_ELM_INTRO} component={pages.CreateElmIntro} />
          <Route path={ROOTS.CREATE_ELM} component={pages.CreateElm} />
          <Route path={ROOTS.ELM_DETAILS} exact component={pages.ElmDetails} />
          <Route path={ROOTS.TAKE_ELM} component={pages.TakeElm} />

          <Route path={ROOTS.ELM_MY_REPORT} component={pages.ElmMyReport} />
          <Route path={ROOTS.ELM_ORG_REPORT_RESP} component={pages.ElmOrgReportResp} />
          <Route path={ROOTS.ELM_ORG_REPORT_DOMAIN_RESULTS} component={pages.ElmOrgReportDomainResults} />

          <Route path={ROOTS.MY_ACCOUNT} component={pages.MyAccount} />
          <Route path={ROOTS.MY_PROFILE} component={pages.MyProfile} />
          <Route path={ROOTS.MY_SETTINGS} component={pages.MySettings} />
          <Route path={ROOTS.TERMS_CONDITIONS} component={pages.TermsConditions} />
          <Route path={ROOTS.PRIVACY_POLICY} component={pages.PrivacyPolicy} />
          <Route path={ROOTS.SUPPORT} component={pages.Support} />

          <Route path={ROOTS.BELOVED_USER_LIST} exact component={pages.BelovedUserList} />
          <Route path={ROOTS.BELOVED_USER_PROFILE} exact component={pages.BelovedUserProfile} />
          <Route path={ROOTS.ORG_BELOVED_USER_LIST} exact component={pages.OrgBelovedUserList} />

          <Route path={ROOTS.COHORT_LIST} component={pages.CohortList} />
          <Route path={ROOTS.COHORT_PARTICIPANT_DETAILS} component={pages.CohortParticipantsDetails} />
          <Route path={ROOTS.COHORT_DETAILS} component={pages.CohortDetails} />
          <Route path={ROOTS.COHORT_ORGS} component={pages.CohortOrgs} />
          <Route path={ROOTS.COHORT_SESSION_DETAILS} exact component={pages.CohortSessionDetails} />
          <Route path={ROOTS.COHORT_MESSAGE_DETAILS} exact component={pages.CohortMessageDetails} />
          <Route path={ROOTS.GROUP_AVERAGES} component={pages.GroupAveragesEAs} />
          <Route path={ROOTS.GROUP_AVERAGES_CHART_ORG} component={pages.GroupAveragesChartOrg} />
          <Route path={ROOTS.GROUP_AVERAGES_SAVED} component={pages.GroupAveragesSaved} />
          <Route path={ROOTS.GROUP_AVERAGES_SAVED_FOR_EAs} component={pages.GroupAveragesSavedForEAs} />
          <Route path={ROOTS.GROUP_AVERAGES_CHART} component={pages.GroupAveragesChart} />

          <Route path={ROOTS.EWP_PREPARATION} component={pages.PreparationEWP} />
          <Route path={ROOTS.EWP_CREATE} component={pages.EWPCreate} />
          <Route path={ROOTS.EWP_DETAILS} exact component={pages.EwpDetails} />
          <Route path={ROOTS.EWP_PRIORITY_LIST} exact component={pages.EwpPriorityList} />
          <Route path={ROOTS.EWP_REVIEW_ITEMS} exact component={pages.EwpReviewItems} />
          <Route path={ROOTS.EWP_STRATEGY_LIST} exact component={pages.EwpStrategyList} />
          <Route path={ROOTS.EWP_GOAL_LIST} exact component={pages.EwpGoalList} />
          <Route path={ROOTS.EWP_KA_LIST} exact component={pages.EwpKaList} />
          <Route path={ROOTS.EWP_PRIORITY_CREATE} exact component={pages.EwpPriorityCreate} />
          <Route path={ROOTS.EWP_GOAL_CREATE} exact component={pages.EwpGoalCreate} />
          <Route path={ROOTS.EWP_STRATEGY_CREATE} exact component={pages.EwpStrategyCreate} />
          <Route path={ROOTS.EWP_KA_CREATE} exact component={pages.EwpKaCreate} />
          <Route path={ROOTS.EWP_PRIORITY_DETAILS} exact component={pages.EwpPriorityDetails} />
          <Route path={ROOTS.EWP_GOAL_DETAILS} exact component={pages.EwpGoalDetails} />
          <Route path={ROOTS.EWP_DASHBOARD} exact component={pages.EwpDashboard} />
          <Route path={ROOTS.EWP_USERS} exact component={pages.EwpUsers} />
          <Route path={ROOTS.EWP_STRATEGY_DETAILS} exact component={pages.EwpStrategyDetails} />
          <Route path={ROOTS.EWP_KA_DETAILS} exact component={pages.EwpKaDetails} />

          <Route path={ROOTS.RESOURCE_LIST_CARDS} exact component={pages.ResourceListCards} />

          <Route path={ROOTS.MY_TASKS} component={pages.MyTasks} />

          <Route path={ROOTS.REPORT_FUNCTION_GROUPS} component={pages.FunctionGroups} />
          <Route path={ROOTS.REPORT_CHART_BAR} component={pages.ReportChartBar} />
          <Route path={ROOTS.REPORT_STANDARD_RESPONSES} component={pages.StandardResponses} />
          <Route path={ROOTS.REPORT_SCORE} component={pages.ScoreReport} />
          <Route path={ROOTS.REPORTS} component={pages.Reports} />

          <Route path={ROOTS.TERMS_CONDITIONS_PAYMENT} component={pages.TermsConditionsPayment} />
          <Route path={ROOTS.STRIPE_SETUP} component={pages.StripeSetup} />
          <Route path={ROOTS.DEBRIEF_SCHEDULING} component={pages.DebriefScheduling} />

          <Route path={ROOTS.GROUP_ANALYSIS} component={pages.GroupAnalysis} />
          <Route path={ROOTS.ORG_ANALYSIS} component={pages.OrgAnalysis} />
          <Route path={ROOTS.VIEW_DEBRIEF} component={pages.ViewDebrief} />

          <Route path={ROOTS.LINK_RESOURCE_LIST} exact component={pages.LinkResourceList} />
          <Route path={ROOTS.LINK_RESOURCE} exact component={pages.LinkResource} />

          <Route path={ROOTS.NOT_FOUND} component={pages.NotFount} />

          <Redirect to={ROOTS.HOME_ORG} />
        </Switch>
      );
    }
    return <InternalAppWrapper>{routes}</InternalAppWrapper>;
  }

  return (
    <Switch>
      <Route path={ROOTS.SIGNIN} component={pages.SignInContainer} />
      <Route path={ROOTS.SIGNUP} component={pages.SignUpContainer} />
      <Route path={ROOTS.FORGOT_PASSWORD} component={pages.ForgotPasswordContainer} />
      <Route path={ROOTS.ACCOUNT_VERIFIED} component={pages.EmailVerifiedContainer} />
      <Route path={ROOTS.ACCOUNT_CREATED} component={pages.AccountCreatedContainer} />
      <Route path={ROOTS.START_SIGN_UP} component={pages.StartSignUpContainer} />
      <Route path={ROOTS.CHANGE_PASSWORD} component={pages.ChangePasswordContainer} />
      {step === 4 ? (
        <Route path={ROOTS.ORGANIZATION_PREVIEW_CREATE} component={pages.CreateOrganizationContainer} />
      ) : null}
      {step === 4 ? (
        <Route path={ROOTS.ORGANIZATION_PREVIEW_JOIN} component={pages.JoinOrganizationContainer} />
      ) : null}
      {step === 4 ? (
        <Route path={ROOTS.ORGANIZATION_PREVIEW} component={pages.JoinOrganizationPreviewContainer} />
      ) : null}
      {step === 4 ? (
        <Route path={ROOTS.CREATE_ORGANIZATION_EXISTING} component={pages.CreateOrganizationExisting} />
      ) : null}
      {step === 3 ? (
        <Route path={ROOTS.SUB_ORGANIZATION_PREVIEW_CREATE} component={pages.CreateSubOrganization} />
      ) : null}
      {step === 3 ? (
        <Route path={ROOTS.SUB_ORGANIZATION_PREVIEW} component={pages.CreateSubOrganizationPreview} />
      ) : null}
      {step === 7 ? (
        <Route path={ROOTS.ASSOCIATE_ORGANIZATION_PREVIEW} component={pages.AssociationOrganizationPreview} />
      ) : null}
      <Route path={ROOTS.REQUEST_SUBMIT_PENDING} component={pages.RequestSubmitPending} />
      <Route path={ROOTS.REQUEST_SUBMITTED} component={pages.RequestSubmittedContainer} />
      <Route path={ROOTS.PASSWORD_SUBMITTED} component={pages.ForgotSubmitPasswordContainer} />
      <Route path={ROOTS.TERMS_CONDITIONS} component={pages.TermsConditionsSignUp} />
      <Route path={ROOTS.PRIVACY_POLICY} component={pages.PrivacyPolicySignUp} />

      <Route path={ROOTS.EMAIL_ALREADY_EXIST} component={pages.EmailAlreadyExistContainer} />
      {step === 6 ? <Route path={ROOTS.CREATE_PROFILE} component={pages.CreateProfileContainer} /> : null}
      {step === 6 || step === 5 ? (
        <Route path={ROOTS.ASSOCIATION_SEND_PREVIEW} component={pages.AssociationSendPreview} />
      ) : null}
      <Route path={ROOTS.NOT_FOUND} component={pages.NotFount} />
      {/* Rest authData routes */}
      <Redirect
        to={{
          pathname: ROOTS.SIGNIN,
          state: location,
        }}
      />
    </Switch>
  );
};
