import { all, call, CallEffect, takeLatest } from 'redux-saga/effects';
import Api from '../../services/api';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { ActionModel, ISelectOptions, PaginationModel } from '../../models';
import {
  CERTIFICATION_CONSULTANT_AVAILABLE_ORGS,
  CERTIFICATION_CONSULTANT_CREATE,
  CERTIFICATION_CONSULTANT_DEACTIVATE_USERS,
  CERTIFICATION_CONSULTANT_UPDATE,
  CertificationConsultantDeactivatePayload,
  CertificationConsultantOrgPayload,
  CertificationConsultantPayload,
} from './certificationConsultantActions';

function* certificationConsultantCreateSaga({
  payload,
}: ActionModel<typeof CERTIFICATION_CONSULTANT_CREATE, CertificationConsultantPayload>): Generator<
  CallEffect<CertificationConsultantPayload>
> {
  const { callback, data } = payload;
  try {
    const response = yield call(Api.post, `beloved/beloved_certified_consultant/`, data);
    if (response) {
      callback();
      toast.success(t('common:toast.new-consultant'));
    }
  } catch (e: any) {
    if (e.response.status) {
      callback(e.response.data);
    } else {
      toast.error(t('common:toast.error'));
    }
  }
}

function* certificationConsultantUpdateSaga({
  payload,
}: ActionModel<typeof CERTIFICATION_CONSULTANT_UPDATE, CertificationConsultantPayload>): Generator<
  CallEffect<CertificationConsultantPayload>
> {
  const { callback, data, userId } = payload;
  try {
    const response = yield call(Api.put, `beloved/beloved_certified_consultant/${userId}/`, data);
    if (response) {
      callback();
      toast.success(t('common:toast.update-consultant'));
    }
  } catch (e: any) {
    if (e.response.status) {
      callback(e.response.data);
    } else {
      toast.error(t('common:toast.error'));
    }
  }
}

function* certificationConsultantDeactivateSaga({
  payload,
}: ActionModel<
  typeof CERTIFICATION_CONSULTANT_DEACTIVATE_USERS,
  CertificationConsultantDeactivatePayload
>): Generator<CallEffect<CertificationConsultantDeactivatePayload>> {
  const { usersIds, callback, is_active } = payload;
  try {
    const response = yield call(Api.post, `beloved/organization_beloved_certified_consultant/activate/`, {
      ids: usersIds,
      is_active,
    });

    if (response) {
      callback();
    }
    if (is_active) {
      toast.success(t('common:toast.activated-assignment'));
    } else {
      toast.success(t('common:toast.deactivated-assignment'));
    }
  } catch (e: any) {
    toast.error(t('common:toast.error'));
  }
}

function* getAvailableOrganizationsSaga({
  payload,
}: ActionModel<typeof CERTIFICATION_CONSULTANT_AVAILABLE_ORGS, CertificationConsultantOrgPayload>): Generator<
  CallEffect<CertificationConsultantOrgPayload>
> {
  const { callback, data } = payload;
  try {
    const response = yield call(
      Api.post,
      `beloved/organization_beloved_certified_consultant/available_org/?page=${data?.page || 1}`,
      data,
    );

    if (response) {
      callback && callback(response as PaginationModel<ISelectOptions[]>);
    }
  } catch (e) {
    console.log('error message', e);
  }
}

export function* saga() {
  yield all([takeLatest(CERTIFICATION_CONSULTANT_CREATE, certificationConsultantCreateSaga)]);
  yield all([takeLatest(CERTIFICATION_CONSULTANT_UPDATE, certificationConsultantUpdateSaga)]);
  yield all([takeLatest(CERTIFICATION_CONSULTANT_AVAILABLE_ORGS, getAvailableOrganizationsSaga)]);
  yield all([takeLatest(CERTIFICATION_CONSULTANT_DEACTIVATE_USERS, certificationConsultantDeactivateSaga)]);
}
