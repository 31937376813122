import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams, Link as RouterLink } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Alert, HeadlineOrganization, Modal, OrgBreadcrumbs, OrgProfileTabs } from '../../../../common';
import { Button, EAStatusLabel, Link, Loader, SimpleTooltip } from '../../../../controls';
import { useDispatch, useSelector } from 'react-redux';
import { ROOTS } from '../../../../../routes/routes';
import { changeCurrentDebriefSlide, getEAList } from '../../../../../store/equityAudit/equityAuditActions';
import {
  EAStatuses,
  EquityAuditModel,
  InstructionStep,
  IRouteParamsBase,
  PaginationModel,
} from '../../../../../models';
import Ill from '../../../../../assets/images/empty-ea-list.png';
import { useRole } from '../../../../../helpers/roles';
import { EA_ROLES } from '../../../../../constants';
import { ScheduleAlias } from '../../../../../constants';
import {
  basicDateFormat,
  debriefFormat,
  showRescheduleAlert,
  showViewDebriefBtn,
} from '../../../../../helpers';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { QueryParamsModel } from '../../../../../hooks/common/use-table-data';
import { useSelectCurrentOrg } from '../../../../../hooks/org/use-select-current-org';
import { StateType } from '../../../../../store/reducers';
import { YoutubeEALinkVideo } from '../../../../../constants/ea';

interface IState {
  data: PaginationModel<EquityAuditModel[]> | null;
  query: QueryParamsModel;
}

const EquityAuditList = () => {
  const { orgId } = useParams<IRouteParamsBase>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  const currentOrg = useSelectCurrentOrg();
  let [modalOpen, setModalOpen] = useState(false);
  const access = useRole();
  const showCreateBtn = access(['project_manager']);
  let [rescheduleAlert, setRescheduleAlert] = useState(false);
  let [notMobileAlert, setNotMobileAlert] = useState(false);
  const showGATab = access(['project_manager', 'audit_contributor']);
  const reportCounter: number = useSelector((state: StateType) => state.report.gaData.ea_report_counter);

  const initialState = {
    data: null,
    query: {
      sorting: null,
      search: '',
      page_size: 15,
      page: 1,
    },
  };

  let [state, setState] = useState<IState>(initialState);
  const { data } = state;

  useEffect(() => {
    if (currentOrg) {
      setState(initialState);
      dispatch(
        getEAList({
          params: initialState.query,
          callback: data => {
            if (data) {
              setState(prevState => ({ ...prevState, data }));
            }
          },
        }),
      );
      dispatch(changeCurrentDebriefSlide(null));
    }
  }, [currentOrg?.id]);

  const fetchMoreMobile = () => {
    dispatch(
      getEAList({
        params: {
          ...state.query,
          page: state.query.page + 1,
        },
        callback: data => {
          if (data) {
            setState(prevState => {
              if (prevState.data) {
                return {
                  ...prevState,
                  data: {
                    ...data,
                    result: [...prevState.data.result, ...data.result],
                  },
                  query: {
                    ...prevState.query,
                    page: prevState.query.page + 1,
                  },
                };
              }
              return prevState;
            });
          }
        },
      }),
    );
  };

  const handleOpenModal = (e: any) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const handleCreate = () => {
    if (data) {
      const step: InstructionStep = data.result.length ? 'intro1' : 'intro2';
      history.push(ROOTS.CREATE_EA_PREP.replace(':orgId', orgId), { step });
    }
  };

  const navToEADetails = (eaId: number) => (e: any) => {
    e.preventDefault();
    history.push(ROOTS.EA_DETAILS.replace(':orgId', orgId).replace(':eaId', String(eaId)));
  };

  const handleResume = (eaId: number) => (e: any) => {
    e.preventDefault();
    history.push(ROOTS.CREATE_EA.replace(':orgId', orgId), { eaId });
  };

  const handleTake = (eaId: number) => (e: any) => {
    e.preventDefault();
    history.push(ROOTS.TAKING_EA.replace(':orgId', orgId), { eaId });
  };

  const navToViewDebrief = (ea: EquityAuditModel) => {
    if (isMobileOnly) {
      setNotMobileAlert(true);
    } else {
      history.push(ROOTS.VIEW_DEBRIEF.replace(':orgId', orgId).replace(':eaId', `${ea.id}`));
    }
  };

  const handleScheduleDebrief = (ea: EquityAuditModel) => {
    if (ea.debrief_scheduled_date?.book_date) {
      if (showViewDebriefBtn(ea.debrief_scheduled_date)) {
        navToViewDebrief(ea);
        return null;
      }
      if (showRescheduleAlert(ea.debrief_scheduled_date)) {
        setRescheduleAlert(true);
        return null;
      }
    }

    if (ea.debrief) {
      history.push(
        ROOTS.DEBRIEF_SCHEDULING.replace(':debriefAlias', ScheduleAlias.ea)
          .replace(':orgId', orgId)
          .replace(':id', `${ea.id}`),
      );
    } else {
      history.push(ROOTS.STRIPE_SETUP.replace(':orgId', orgId).replace(':eaId', `${ea.id}`), {
        prevPath: location.pathname,
      });
    }
  };

  const navToReports = (eaId: number) => (e: any) => {
    e.preventDefault();
    history.push(ROOTS.REPORTS.replace(':orgId', orgId).replace(':eaId', `${eaId}`));
  };

  const debriefBtnName = (ea: EquityAuditModel) => {
    if (ea.debrief_scheduled_date?.book_date) {
      if (showViewDebriefBtn(ea.debrief_scheduled_date)) {
        return t('common:btn.view-debrief');
      }
      return t('common:btn.reschedule-debrief');
    } else if (ea.debrief) {
      return t('common:btn.schedule-debrief');
    } else {
      return t('common:btn.purchase-debrief');
    }
  };

  const navToYoutube = () => {
    window.open(YoutubeEALinkVideo, '_blank');
  };

  const renderEABtn = (ea: EquityAuditModel) => {
    const takeEAAccess =
      access(['project_manager']) ||
      (access(['audit_contributor']) && ea.ea_user_type?.id === EA_ROLES.EA_TEAM);

    if (ea.ea_status_id === EAStatuses.IN_PREPARATION && access(['project_manager'])) {
      return (
        <a href="#" onClick={handleResume(ea.id)} className={'b-panelItem__link'}>
          {t('common:btn.resume-preparation')}
        </a>
      );
    }
    if (ea.ea_status_id === EAStatuses.PREP_COMPLETED && takeEAAccess) {
      return (
        <a href="#" onClick={handleTake(ea.id)} className={'b-panelItem__link'}>
          {t('common:btn.take-audit')}
        </a>
      );
    }
    if (ea.ea_status_id === EAStatuses.IN_PROGRESS && takeEAAccess) {
      return (
        <a href="#" onClick={handleTake(ea.id)} className={'b-panelItem__link'}>
          {t('common:btn.resume-audit')}
        </a>
      );
    }
    if (ea.ea_status_id === EAStatuses.COMPLETED) {
      return (
        <div className="b-panelItem__links">
          {access(['project_manager', 'audit_contributor']) && (
            <a href="#" onClick={navToReports(ea.id)} className={'b-panelItem__link'}>
              {t('common:btn.view-report')}
            </a>
          )}
          {access(['project_manager', 'project_admin']) && (
            <div className="d-flex flex-column">
              {ea.show_purchase_debrief && !ea.show_view_debrief ? (
                <Link onClick={() => handleScheduleDebrief(ea)} className="b-panelItem__link pb-2">
                  {debriefBtnName(ea)}
                </Link>
              ) : null}
              {ea.show_view_debrief ? (
                <Link onClick={() => navToViewDebrief(ea)} className="b-panelItem__link">
                  {t('common:btn.view-debrief')}
                </Link>
              ) : null}
            </div>
          )}
        </div>
      );
    }
  };

  const renderExtra = (ea: EquityAuditModel) => {
    if (ea.ea_status_id < EAStatuses.IN_PROGRESS && ea.scheduled_date) {
      return (
        <>
          <div className="b-step__smallTitle">{t('common:headlines.ea-date')}</div>
          <div className="b-equityAudit__text12" style={{ marginBottom: 14 }}>
            {t('main:tentative-start-date')}
          </div>
          <div className="b-panelItem__info">
            <div className="b-panelItem__left">
              <div className="b-panelItem__val">{basicDateFormat(ea.scheduled_date)}</div>
            </div>
          </div>
        </>
      );
    }
  };

  const renderUpdatedDate = (ea: EquityAuditModel) => {
    if (ea.ea_status_id === EAStatuses.COMPLETED) {
      return ea.completed_date ? basicDateFormat(ea.completed_date) : t('common:column.n-a');
    }
    return ea.last_update ? basicDateFormat(ea.last_update) : t('common:column.n-a');
  };

  const renderContent = () => {
    if (!data) {
      return <Loader />;
    }

    if (data.result.length) {
      return (
        <div className="b-infScrollWrapper">
          <InfiniteScroll
            dataLength={data.result.length}
            next={fetchMoreMobile}
            hasMore={!(data.current_page === data.total_pages)}
            loader={<h4 className="b-no-content">{t('common:label.loading')}...</h4>}
          >
            <div className="b-infScrollWrapper__normalize">
              <div className="b-page__cardsList">
                {data.result.map((ea: EquityAuditModel) => {
                  return (
                    <div key={`ea-${ea.id}`} className="b-page__cardItem b-panelItem">
                      <h2 className="b-panelItem__statusContainer">
                        <EAStatusLabel ea={ea} />
                        <a
                          href="#"
                          className="b-panelItem__details"
                          aria-label="see more details"
                          onClick={navToEADetails(ea.id)}
                        >
                          {t('common:btn.details')}
                        </a>
                      </h2>
                      <div className="b-panelItem__info">
                        <div className="b-panelItem__left">
                          <div
                            className="b-panelItem__title"
                            onClick={() => showViewDebriefBtn(ea.debrief_scheduled_date)}
                          >
                            PM
                          </div>
                          <div
                            className="b-panelItem__title"
                            onClick={() => showRescheduleAlert(ea.debrief_scheduled_date)}
                          >
                            Start
                          </div>
                          <div className="b-panelItem__title">
                            {ea.ea_status_id === EAStatuses.COMPLETED
                              ? t('common:column.completed')
                              : t('common:column.last-updated')}
                          </div>
                          {ea.debrief_scheduled_date.book_full_date ? (
                            <div className="b-panelItem__title">{t('common:column.debrief')}</div>
                          ) : null}
                        </div>
                        <div>
                          <div className="b-panelItem__val">{ea.audit_pm.name}</div>
                          <div className="b-panelItem__val">
                            {ea.scheduled_date
                              ? basicDateFormat(ea.created_at || '')
                              : t('common:column.n-a')}
                          </div>
                          <div className="b-panelItem__val">{renderUpdatedDate(ea)}</div>
                          {ea.debrief_scheduled_date.book_full_date ? (
                            <div className="b-panelItem__val">
                              {debriefFormat(ea.debrief_scheduled_date.book_full_date)}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {renderExtra(ea)}
                      {renderEABtn(ea)}
                    </div>
                  );
                })}
              </div>
            </div>
          </InfiniteScroll>
        </div>
      );
    }

    return (
      <div className={'b-EAListPlaceholder'}>
        <div className="b-EAModal__title">{t('common:headlines.ea')}</div>
        <div className="b-step__wBox b-EAListPlaceholder__panl">
          <img src={Ill} className="b-EAListPlaceholder__ill" alt={t('awa:AT37')} />
          <h1 className="b-step__smallTitle" style={{ marginBottom: 32 }}>
            {t('awa:M26.title')}
          </h1>
          <div className="b-EAListPlaceholder__text">
            <p className="b-step__p">{t('awa:M26.msg.p1')}</p>
            <p className="b-step__p">{t('awa:M26.msg.p2')}</p>
            <p className="b-step__p">
              {t('awa:M26.msg.p3')}{' '}
              <a href="#" onClick={handleOpenModal} className="b-link">
                {t('awa:M26.msg.p4')}
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <div className="b-page__header -desktop">
          <HeadlineOrganization
            orgName={currentOrg?.name || ''}
            orgId={currentOrg?.id || ''}
            headline={t('common:headlines.ea')}
            customClasses={'-noDesktopMb mr-4'}
          />
          <div className="b-page__headerBtns -desktop">
            {data && showCreateBtn && (
              <Button
                className="btn "
                type={'orange-light'}
                onPress={handleCreate}
                title={t('common:btn.start-new-audit')}
                iconLeft={<div className="b-equityAudit__btnPlusIc">+</div>}
              />
            )}
          </div>
        </div>
        <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
        <OrgProfileTabs activeItemAlias={'equity-audit'} />
        <div className="d-flex justify-content-between mt-4">
          <div className="d-flex align-items-center">
            {!isMobileOnly && showGATab && currentOrg?.has_completed_ea ? (
              <SimpleTooltip id={`organization-analysis-tooltip`} title={t('awa:TT12.msg')}>
                <RouterLink
                  to={ROOTS.ORG_ANALYSIS.replace(':orgId', `${orgId}`)}
                  className="orange-light-text mr-4 b-tableTool -ga -active"
                >
                  {t('common:tabs.organization-analysis')}
                </RouterLink>
              </SimpleTooltip>
            ) : null}
            {!isMobileOnly && showGATab && reportCounter > 1 ? (
              <SimpleTooltip id={`group-analysis`} title={t('awa:TT13.msg')}>
                <RouterLink
                  to={ROOTS.GROUP_ANALYSIS.replace(':orgId', `${orgId}`)}
                  className="orange-light-text mr-4 b-tableTool -ga -active"
                >
                  {t('common:tabs.group-analysis')}
                </RouterLink>
              </SimpleTooltip>
            ) : null}
            {!isMobileOnly && currentOrg?.group_avg_access ? (
              <SimpleTooltip id={`group-analysis`} title={t('awa:TT13.msg')}>
                <RouterLink
                  to={ROOTS.GROUP_AVERAGES_SAVED.replace(':orgId', `${orgId}`)}
                  className="orange-light-text mr-4 b-tableTool -gav -active"
                >
                  {t('common:tabs.group-averages')}
                </RouterLink>
              </SimpleTooltip>
            ) : null}
          </div>
          <div>
            <Link onClick={navToYoutube} className="ml-2 b-link-icon -right -youtube-link">
              {t('common:btn.5-steps-video')}
            </Link>
          </div>
        </div>
        <div className="b-page__cards">
          {renderContent()}
          {data && showCreateBtn && (
            <div className="b-equityAudit__bottom -mobile">
              <Button
                iconLeft={<div className="b-equityAudit__btnPlusIc">+</div>}
                className={'btn -newEA'}
                type={'orange-light'}
                onPress={handleCreate}
                title={t('common:btn.create-new-audit')}
              />
            </div>
          )}
        </div>
      </main>
      {modalOpen && (
        <Modal width={935} onClose={() => setModalOpen(false)}>
          <div className={'b-EAModal  b-step'}>
            <div className={'b-EAModal__title'}>
              <a onClick={() => setModalOpen(false)} href="#" className={'b-EAModal__back -mobile'} />
              {t('awa:M35.title')}
            </div>
            <p className="b-step__p">
              {t('awa:M35.msg.p1')} <b>{t('awa:M35.msg.p2')}</b> {t('awa:M35.msg.p3')}
            </p>
            <p className="b-step__p">{t('awa:M35.msg.p4')}</p>
            <p className="b-step__p">{t('awa:M35.msg.p5')}</p>
            <p style={{ marginBottom: 8 }} className="b-step__smallTitle">
              {t('awa:M35.msg.p6')}
            </p>
            <p className="b-step__p">{t('awa:M35.msg.p7')}</p>
            <p className="b-step__p">{t('awa:M35.msg.p8')}</p>
            <p className="b-step__orangeBold b-step__textCenter -mb5 " style={{ marginBottom: 20 }}>
              {t('awa:M35.msg.p9')}
            </p>
            <div className="b-step__wBox -paddings -intro1Pop">
              <div>
                <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                  {t('awa:M35.msg.p10.title')} <b>{t('awa:M35.msg.p10.msg')}</b>
                </div>
                <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                  {t('awa:M35.msg.p11.title')} <b>{t('awa:M35.msg.p11.msg')}</b>
                </div>
                <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                  {t('awa:M35.msg.p12.title')} <b>{t('awa:M35.msg.p12.msg')}</b>
                </div>
                <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                  {t('awa:M35.msg.p13.title')} <b>{t('awa:M35.msg.p13.msg')}</b>
                </div>
              </div>
            </div>
            <div className="b-step__orangeBold b-step__textCenter -mb5 " style={{ marginBottom: 20 }}>
              {t('awa:M35.msg.p14')}
            </div>
            <div className="b-step__wBox -paddings -intro1Pop">
              <div>
                <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                  {t('awa:M35.msg.p15.title')} <b>{t('awa:M35.msg.p15.msg')}</b>
                </div>
                <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                  {t('awa:M35.msg.p16.title')} <b>{t('awa:M35.msg.p16.msg')}</b>
                </div>
                <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                  {t('awa:M35.msg.p17.title')} <b>{t('awa:M35.msg.p17.msg')}</b>
                </div>
                <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                  {t('awa:M35.msg.p18.title')} <b>{t('awa:M35.msg.p18.msg')}</b>
                </div>
                <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                  {t('awa:M35.msg.p19.title')} <b>{t('awa:M35.msg.p19.msg')}</b>
                </div>
              </div>
            </div>
            <div className="b-step__orangeBold b-step__textCenter -mb5 " style={{ marginBottom: 20 }}>
              {t('awa:M35.msg.p20')}
            </div>
            <div className="b-step__wBox -intro1Pop -paddings">
              <div>
                <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                  {t('awa:M35.msg.p21.title')} <b>{t('awa:M35.msg.p21.msg')}</b>
                </div>
                <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                  {t('awa:M35.msg.p22.title')} <b>{t('awa:M35.msg.p22.msg')}</b>
                </div>
                <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                  {t('awa:M35.msg.p23.title')} <b>{t('awa:M35.msg.p23.msg')}</b>
                </div>
                <div style={{ marginBottom: 15 }} className="b-equityAudit__text14">
                  {t('awa:M35.msg.p24.title')} <b>{t('awa:M35.msg.p24.msg')}</b>
                </div>
              </div>
            </div>
            <div className="b-step__p">{t('awa:M35.msg.p25')}</div>
            <div className="b-step__p">
              {t('awa:M35.msg.p26')}{' '}
              <a href="" className="b-link">
                {t('awa:M35.msg.p27')}
              </a>
            </div>
          </div>
        </Modal>
      )}
      {rescheduleAlert && (
        <Alert
          isOpen={rescheduleAlert}
          onRequestClose={() => setRescheduleAlert(false)}
          title={t('awa:N24.title')}
          text={t('awa:N24.msg')}
          buttons={{
            left: {
              title: t('common:btn.ok'),
              type: 'orange-light',
              onClick: () => setRescheduleAlert(false),
              enableEnter: true,
            },
          }}
        />
      )}
      {notMobileAlert && (
        <Alert
          isOpen={notMobileAlert}
          onRequestClose={() => setNotMobileAlert(false)}
          title={t('awa:M54.title')}
          text={t('awa:M54.msg')}
        />
      )}
    </>
  );
};

export default EquityAuditList;
