import React, { FC } from 'react';
import { Button } from '../../../../../controls';
import Illustration from '../../../../../../assets/images/icons/create@2x.png';
import { ElmTakingModel, HandleSaveType } from '../../../../../../models';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSave: HandleSaveType;
  data: ElmTakingModel;
}

const PQIntro: FC<IProps> = ({ onSave, data }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="b-EATaking__box">
        <img className="b-elmBox__illus" src={Illustration} alt={t('awa:AT1')} />
        <h1 className="b-EATaking__boxTitle">{t('awa:M65.title')}!</h1>
        <div className="b-elmTake__descBox">
          <p className="b-elm__p">{t('awa:M65.msg.p1')} </p>
          <p className="b-elm__p">
            <a rel="noreferrer" target="_blank" href={data.description?.link} className="b-link">
              {t('awa:M65.msg.p2')}
            </a>{' '}
            {t('awa:M65.msg.p3')}
          </p>
        </div>
        <Button
          className={'btn -type_form -desktop'}
          type={'orange-light'}
          onPress={() => onSave({ next: true, isIntro: true })}
          title={t('common:btn.next')}
        />
      </div>
      <div className="b-elm__mobBtn -mobile">
        <Button
          className={'btn -type_form'}
          type={'orange-light'}
          onPress={() => onSave({ next: true, isIntro: true })}
          title={t('common:btn.next')}
        />
      </div>
    </>
  );
};

export default PQIntro;
