import { all, call, CallEffect, put, takeLatest } from 'redux-saga/effects';
import Api from '../../services/api';
import {
  getUserSuccess,
  GET_USER,
  GetUserActionModel,
  GET_PROFILE,
  UPDATE_PROFILE,
  GetProfilePayload,
  ChangePasswordPayload,
  CHANGE_OLD_PASSWORD,
  UpdateUserSettingsPayload,
  UPDATE_USER_SETTINGS,
  RESEND_SECONDARY_EMAIL,
  ResendSecondaryEmailPayload,
  ConfirmSecondaryEmailPayload,
  CONFIRM_SECONDARY_EMAIL,
} from './profileActions';
import { isLoginAction } from '../authData/authDataActions';
import { ActionModel } from '../../models';
import { toast } from 'react-toastify';
import { t } from 'i18next';

function* getUserSaga({ payload }: any): any {
  try {
    const response = yield call(Api.get, 'entry/user/details/');
    if (response) {
      yield put(getUserSuccess(response));
      yield put(isLoginAction({ data: { isAuthenticated: response.login } }));
      payload.callback && payload.callback(response);
    }
  } catch (e: any) {
    console.log('error', e);
  }
}

function* getProfileSaga({
  payload,
}: ActionModel<typeof GET_PROFILE, GetProfilePayload>): Generator<CallEffect<GetProfilePayload>> {
  try {
    const response = yield call(Api.get, `entry/profile/${payload.user_id}`);
    if (response) {
      payload.callback(response);
    }
  } catch (e: any) {
    payload.callback(e.response.data);
  }
}

function* updateProfileSaga({ payload }: any): Generator<CallEffect<GetUserActionModel>> {
  try {
    const response = yield call(Api.put, `entry/profile/${payload.data.id}`, payload.data.user);
    if (response) {
      payload.callback(response);
    }
  } catch (e: any) {
    if (e.response) {
      payload.callback(e.response.data);
    } else {
      payload.callback(e);
    }
  }
}

function* changePasswordSaga({
  payload,
}: ActionModel<typeof CHANGE_OLD_PASSWORD, ChangePasswordPayload>): Generator<
  CallEffect<ChangePasswordPayload>
> {
  try {
    const response = yield call(Api.post, `entry/change_password/`, payload.data);
    if (response) {
      payload.callback(response);
    }
  } catch (e: any) {
    toast.error(t('common:toast.error'));
    if (e.response) {
      payload.callback(null, { error: e.response.data });
    } else {
      payload.callback(e);
    }
  }
}

function* updateUserSettingsSaga({
  payload,
}: ActionModel<typeof UPDATE_USER_SETTINGS, UpdateUserSettingsPayload>): Generator<
  CallEffect<UpdateUserSettingsPayload>
> {
  try {
    const response = yield call(Api.post, `entry/user/user_settings/`, payload.data);
    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e: any) {
    toast.error(t('common:toast.error'));
    if (e.response) {
      payload.callback && payload.callback(null, { error: e.response.data });
    }
  }
}

function* resendSecondaryEmailSaga({
  payload,
}: ActionModel<typeof RESEND_SECONDARY_EMAIL, ResendSecondaryEmailPayload>): Generator<CallEffect<any>> {
  try {
    const response = yield call(Api.post, `entry/email_secondary_verification/`, payload.data);
    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e: any) {
    toast.error(t(e.response.data.msg || 'common:toast.error'));
    if (e.response) {
      payload.callback && payload.callback(null, { error: e.response.data });
    } else {
      payload.callback && payload.callback(null, { error: e.response.data });
    }
  }
}

function* confirmSecondaryEmailSaga({
  payload,
}: ActionModel<typeof CONFIRM_SECONDARY_EMAIL, ConfirmSecondaryEmailPayload>): Generator<CallEffect<any>> {
  try {
    const response = yield call(Api.put, `entry/email_secondary_verification/`, payload.data);
    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e: any) {
    toast.error(t('common:toast.error'));
    if (e.response) {
      payload.callback && payload.callback(null, { error: e.response.data });
    } else {
      payload.callback && payload.callback(null, { error: e.response.data });
    }
  }
}

export function* saga() {
  yield all([takeLatest(GET_USER, getUserSaga)]);
  yield all([takeLatest(GET_PROFILE, getProfileSaga)]);
  yield all([takeLatest(UPDATE_PROFILE, updateProfileSaga)]);
  yield all([takeLatest(CHANGE_OLD_PASSWORD, changePasswordSaga)]);
  yield all([takeLatest(UPDATE_USER_SETTINGS, updateUserSettingsSaga)]);
  yield all([takeLatest(RESEND_SECONDARY_EMAIL, resendSecondaryEmailSaga)]);
  yield all([takeLatest(CONFIRM_SECONDARY_EMAIL, confirmSecondaryEmailSaga)]);
}
