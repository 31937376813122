import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import cross from '../../../../../assets/images/icons/cross.svg';
import { CohortStatusLabel, ImageSecured, Loader, ModalView } from '../../../../controls';
import { Carousel, CohortMessageItem, CohortSessionItem, NewsBlockItem } from '../../../../common';
import { DOMAIN_URI, Modals, ModalsModel } from '../../../../../constants';
import { Link, useParams } from 'react-router-dom';
import { ROOTS } from '../../../../../routes/routes';
import {
  IRouteParamsBase,
  CohortUtilsModel,
  BelSessionModel,
  CohortMessageModel,
} from '../../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { useTranslation } from 'react-i18next';
import EditIc from '../../../../../assets/images/icons/round-image-edit.svg';
import AddIc from '../../../../../assets/images/icons/round-content-add.svg';
import ActivityDeck from '../../../../../assets/images/icons/gslides.svg';
import { getCohortMessageList, getCohortSessionList } from '../../../../../store/cohort/cohortActions';
import { getCohortUtils } from '../../../../../store/utils/utilsActions';
import { basicDateFormat, getMessagePosition, getSessionPosition } from '../../../../../helpers';
import { useGetCohortDetails } from '../../../../../hooks/beloved/cohorts/use-get-cohort-details';
import { useIsAccessEditCohort } from '../../../../../hooks/beloved/cohorts/use-edit-access';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';
import { t } from 'i18next';

interface IRouteParams extends IRouteParamsBase {
  id: string;
}

const BelCohortDetails = () => {
  const { t } = useTranslation();
  const [modals, setModals] = useState<ModalsModel>({});
  const { id } = useParams<IRouteParams>();
  const dispatch = useDispatch();
  const [messages, setMessages] = useState<CohortMessageModel[]>([]);
  const [sessions, setSessions] = useState<BelSessionModel[]>([]);
  const { navBack } = useNavBack();
  const cohortOptions: CohortUtilsModel | null = useSelector(
    (state: StateType) => state.utils.cohort || null,
  );
  const cohort = useGetCohortDetails();
  const isAccess = useIsAccessEditCohort(cohort);

  useEffect(() => {
    if (!cohortOptions) {
      dispatch(getCohortUtils({}));
    }
  }, [cohortOptions]);

  useEffect(() => {
    dispatch(
      getCohortMessageList({
        data: {
          cohort_id: Number(id),
        },
        callback: (res: CohortMessageModel[]) => {
          setMessages(res);
        },
      }),
    );
    dispatch(
      getCohortSessionList({
        data: {
          cohort_id: Number(id),
        },
        callback: (res: BelSessionModel[]) => {
          setSessions(res);
        },
      }),
    );
  }, []);

  const setModal = (modal: keyof ModalsModel) => {
    setModals(prevState => ({
      ...prevState,
      [modal]: !modals[modal],
    }));
  };

  const renderContent = () => {
    if (cohort && cohortOptions) {
      const type = (cohortOptions.types || []).find(type => type.value === cohort.type)?.label || '';
      return (
        <div className="b-report b-cohortBel">
          <div className={`b-panelItem cohort`}>
            <div className={`b-panelItem__info cohort_details`}>
              <div className={`cohort_details_part d-flex`}>
                {cohort.image?.file ? (
                  <div className="b-avatar -cohort mr-3">
                    <ImageSecured src={`${DOMAIN_URI}${cohort.image.file}`} alt={t('common:alt.image')} />
                  </div>
                ) : null}
                <div className={`cohort_details_labels mt-2 pl-1`}>
                  <div className={`cohort_details_item`}>
                    <div className={`cohort_details_title`}>{t('common:column.type')}</div>
                    <div className={`cohort_details_val text-nowrap`}>{type}</div>
                  </div>
                  <div className={`cohort_details_item`}>
                    <div className={`cohort_details_title`}>{t('common:column.start-date')}</div>
                    <div className={`cohort_details_val`}>
                      {cohort.start_date ? basicDateFormat(cohort.start_date) : null}
                    </div>
                  </div>
                  {cohort.activity_deck ? (
                    <div className={`cohort_details_item`}>
                      <div className={`cohort_details_title`}>{t('common:column.activity-deck')}</div>
                      <div className={`cohort_details_val`}>
                        <a href={`${cohort.activity_deck || '#'}`} target="_blank" rel="noreferrer">
                          <img
                            className={`b-cohortBel__activity-img`}
                            src={ActivityDeck}
                            alt={t('common:alt.activity-deck')}
                          />
                        </a>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={`cohort_details_part`}>
                <div className={`cohort_details_btn_wrap`}>
                  <CohortStatusLabel status={cohort.status} />
                </div>
                <div className={`d-flex`}>
                  <div className={`cohort_details_labels mt-2`}>
                    <div className={`cohort_details_item`}>
                      <div className={`cohort_details_title`}>{t('common:column.end-date')}</div>
                      <div className={`cohort_details_val`}>
                        {cohort.end_date ? basicDateFormat(cohort.end_date) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`d-flex`}>
                  <div className={`cohort_details_labels`}>
                    <div className={`cohort_details_item`}>
                      <div className={`cohort_details_title`}>{t('common:label.created-by')}</div>
                      <div className={`cohort_details_val`}>{cohort.submitter.name}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`cohort_details_item pl-1`}>
              <div className={`cohort_details_title`}>{t('common:column.beloved-team')}</div>
              <div className={`cohort_details_val`}>
                <ul>
                  {cohort.beloved_team.map((user, userIndex) => {
                    return (
                      <li key={`team-${userIndex}`}>
                        <span>
                          {user.first_name} {user.last_name}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className={`cohort_details_item pl-1`}>
              <div className={`cohort_details_title`}>{t('common:column.cohort-organizations')}</div>
              <div className={`cohort_details_val`}>
                <ul>
                  {cohort.organizations.map((org, orgIndex) => (
                    <li key={`org-${orgIndex}`}>
                      <Link
                        to={ROOTS.BELOVED_COHORT_PARTICIPANT_DETAILS.replace(':id', `${id}`).replace(
                          ':organizationId',
                          `${org.id}`,
                        )}
                      >
                        <span>{org.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={`cohort_details_editCohort`}>
              {isAccess ? (
                <Link
                  to={ROOTS.BELOVED_COHORT_EDIT.replace(':id', `${id}`).replace(':action', 'edit')}
                  className="b-link-icon-left"
                >
                  <img src={EditIc} alt={t('common:alt.editIc')} />
                  {t('common:btn.edit-cohort-details')}
                </Link>
              ) : null}
            </div>
          </div>
          {isMobileOnly && (
            <div className="b-report__notification">
              <b className="b-report__notificationTitle">{t('main:reports.ea-completed.title')}</b>
              <span>{t('main:reports.ea-completed.msg')}</span>
            </div>
          )}
          {cohort.status !== 'in_preparation' ? (
            <div>
              <div className={`pageContainer__title title`}>
                <span>{t('common:headlines.sessions')}</span>
                {isAccess ? (
                  <Link
                    to={ROOTS.BELOVED_COHORT_SESSION.replace(':id', `${id}`).replace(':sessionId', 'create')}
                    className={`link`}
                  >
                    <>
                      <div className={`link__ic`}>
                        <img src={AddIc} alt={t('common:alt.addIc')} />
                      </div>
                      {t('common:btn.add-session')}
                    </>
                  </Link>
                ) : null}
              </div>
              {sessions.length ? (
                <Carousel initPosition={getSessionPosition(sessions)}>
                  {sessions.map(item => {
                    return <CohortSessionItem key={`cohort-session-${item.id}`} data={item} />;
                  })}
                </Carousel>
              ) : null}
              <div className={`pageContainer__title title`}>
                <span>{t('common:headlines.messages')}</span>
                {isAccess ? (
                  <Link
                    to={ROOTS.BELOVED_COHORT_MESSAGE.replace(':id', `${id}`).replace(':messageId', 'create')}
                    className={`link`}
                  >
                    <>
                      <div className={`link__ic`}>
                        <img src={AddIc} alt={t('common:alt.addIc')} />
                      </div>
                      {t('common:btn.add-message')}
                    </>
                  </Link>
                ) : null}
              </div>
              {messages.length ? (
                <Carousel initPosition={getMessagePosition(messages)}>
                  {messages.map(item => {
                    return <CohortMessageItem key={`message-item-${item.id}`} data={item} />;
                  })}
                </Carousel>
              ) : null}
              <div className={`pageContainer__title title`}>
                <span>{t('common:headlines.additional-resources')}</span>
                {isAccess ? (
                  <Link
                    to={ROOTS.BELOVED_COHORT_GENERAL_RESOURCES.replace(':id', `${id}`)}
                    className={`link`}
                  >
                    <>
                      <div className={`link__ic`}>
                        <img src={AddIc} alt={t('common:alt.addIc')} />
                      </div>
                      {t('common:btn.add-resource')}
                    </>
                  </Link>
                ) : null}
              </div>
              {cohort.resources.length ? (
                <Carousel>
                  {cohort.resources.map(item => {
                    return <NewsBlockItem key={`news-item-${item.id}`} data={item} />;
                  })}
                </Carousel>
              ) : null}
            </div>
          ) : null}
        </div>
      );
    }
    return <Loader fitParent />;
  };

  return (
    <>
      <main className="b-page">
        <h1 className="b-page__title">
          <EmptyLink onClick={navBack} className="b-button-icon-back" />
          {t('common:headlines.cohort-program')}: <span className="mark">{cohort?.name}</span>
        </h1>
        {renderContent()}
      </main>
      {modals.isMobileChartImpossible && (
        <ModalView
          isOpen={true}
          onRequestClose={() => setModal(Modals.isMobileChartImpossible)}
          customContentStyles={{
            width: '350px',
          }}
        >
          <div className={`modal`}>
            <div className={`cross`} onClick={() => setModal(Modals.isMobileChartImpossible)}>
              <img width={24} height={24} src={cross} alt={t('common:alt.cross')} />
            </div>
            <h2>{t('awa:M54.title')}</h2>
            <p>{t('awa:M54.msg')}</p>
          </div>
        </ModalView>
      )}
    </>
  );
};

export default BelCohortDetails;
