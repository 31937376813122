import * as React from 'react';
import { ReactElement, useEffect, useRef } from 'react';
import CarouselLib from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

interface IProps {
  children: ReactElement[];
  initPosition?: number;
}

const Carousel = ({ children, initPosition }: IProps) => {
  const carouselRef = useRef();

  useEffect(() => {
    // need a delay
    setTimeout(() => {
      // @ts-ignore
      const { slidesToShow, totalItems } = carouselRef.current.state;
      if (
        (initPosition || initPosition === 0) &&
        carouselRef &&
        carouselRef.current &&
        slidesToShow &&
        totalItems
      ) {
        if (initPosition < slidesToShow - 1) {
          return;
        }
        if (initPosition + 1 >= totalItems - (slidesToShow - 1)) {
          // @ts-ignore
          carouselRef.current.goToSlide(totalItems - 1 - (slidesToShow - 1));
          return;
        }
        // @ts-ignore
        carouselRef.current.goToSlide(initPosition);
      }
    }, 0);
  }, [
    carouselRef,
    initPosition,
    // @ts-ignore
    carouselRef.current?.state.slidesToShow,
    // @ts-ignore
    carouselRef.current?.state.totalItems,
  ]);

  return (
    <div className={`b-carouselWrapper`}>
      <CarouselLib
        // @ts-ignore
        ref={carouselRef}
        autoPlay={false}
        autoPlaySpeed={9999999999999}
        renderArrowsWhenDisabled
        renderDotsOutside
        renderButtonGroupOutside={true}
        slidesToSlide={3}
        draggable={false}
        showDots
        responsive={{
          superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
          },
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
          },
        }}
      >
        {children}
      </CarouselLib>
    </div>
  );
};

export default Carousel;
