import React from 'react';
import { Header } from '../../../common';
import styles from './TermsConditionsSignUp.module.scss';
import { useTranslation } from 'react-i18next';

const TermsConditionsSignUp = () => {
  const { t } = useTranslation();

  return (
    <div className={'container container-header white-container'}>
      <Header type={'awa_text'} />
      <main className={`white-container content-center-wrapper ${styles.container}`}>
        <section className={'pageContainer pageContainer__content'}>
          <div className={'b-formAccount -center'}>
            <h1>{t('pp:personal.p1')}</h1>
            <p>{t('pp:personal.p2')}</p>
            <p>{t('pp:personal.p3')}</p>
            <h2>{t('pp:personal.p4')}</h2>
            <p>
              {t('pp:personal.p5')} (“<b>{t('pp:personal.p6')}</b>“, “<b>{t('pp:personal.p7')}</b>“,{' '}
              {t('pp:personal.p8')} “<b>{t('pp:personal.p9')}</b>“). {t('pp:personal.p10')} “
              <b>{t('pp:personal.p11')}</b>“), {t('pp:personal.p12')} “<b>Website</b>”).{' '}
              {t('pp:personal.p14')} “<b>{t('pp:personal.p15')}</b>”). {t('pp:personal.p16')} “<b></b>”{' '}
              {t('pp:personal.p17')}
              {t('pp:personal.p18')}
            </p>
            <h2>{t('pp:personal.p19')}</h2>
            <p>{t('pp:personal.p20')}</p>
            <h2>{t('pp:personal.p21')}</h2>
            <p>{t('pp:personal.p22')}</p>
            <h2>{t('pp:personal.p23')}</h2>
            <p>{t('pp:personal.p24')}</p>
            <h2>{t('pp:personal.p25')}</h2>
            <p>{t('pp:personal.p26')}</p>
            <ul>
              <li>{t('pp:personal.p27')}</li>
              <li>{t('pp:personal.p28')}</li>
              <li>{t('pp:personal.p29')}</li>
              <li>{t('pp:personal.p30')}</li>
              <li>{t('pp:personal.p31')}</li>
              <li>{t('pp:personal.p32')}</li>
              <li>{t('pp:personal.p33')}</li>
              <li>{t('pp:personal.p34')}</li>
              <li>{t('pp:personal.p35')}</li>
            </ul>
            <h2>{t('pp:personal.p36')}</h2>
            <p>
              {t('pp:personal.p37')} “<b>{t('pp:personal.p38')}</b>”) {t('pp:personal.p39')}
            </p>
            <h2>{t('pp:personal.p40')}</h2>
            <p>{t('pp:personal.p41')}</p>
            <h2>{t('pp:personal.p42')}</h2>
            <p>{t('pp:personal.p43')}</p>
            <p>{t('pp:personal.p44')}</p>
            <h2>{t('pp:personal.p45')}</h2>
            <p>{t('pp:personal.p46')}</p>
            <h2>{t('pp:personal.p47')}</h2>
            <p>{t('pp:personal.p48')}</p>
            <h2>{t('pp:personal.p49')}</h2>
            <p>{t('pp:personal.p50')}</p>
            <h2>{t('pp:personal.p51')}</h2>
            <p>{t('pp:personal.p52')}</p>
            <h2>{t('pp:personal.p53')}</h2>
            <p>
              {t('pp:personal.p54')} (“<b>{t('pp:personal.p55')}</b>”). {t('pp:personal.p56')}
            </p>
            <h2>{t('pp:personal.p57')}</h2>
            <p>{t('pp:personal.p58')}</p>
            <h2>{t('pp:personal.p59')}</h2>
            <p>{t('pp:personal.p60')}</p>
            <h2>{t('pp:personal.p61')}</h2>
            <p>{t('pp:personal.p62')}</p>
            <h2>{t('pp:personal.p63')}</h2>
            <p>{t('pp:personal.p64')}</p>
            <h2>{t('pp:personal.p65')}</h2>
            <p>{t('pp:personal.p66')}</p>
            <h2>{t('pp:personal.p67')}</h2>
            <p>{t('pp:personal.p68')}</p>
            <h2>{t('pp:personal.p69')}</h2>
            <p>{t('pp:personal.p70')}</p>
            <h2>{t('pp:personal.p71')}</h2>
            <p>{t('pp:personal.p72')}</p>
            <h2>{t('pp:personal.p73')}</h2>
            <p>{t('pp:personal.p74')}</p>
            <h2>{t('pp:personal.p75')}</h2>
            <p>
              {t('pp:personal.p76')} <a href="mailto:privacy@wearebeloved.org">privacy@wearebeloved.org</a>.
            </p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default TermsConditionsSignUp;
