import React from 'react';
import { Button, Select } from '../../../controls';
import { Footer, Header, Modal } from '../../../common';
import { ISelectOptions } from '../../../../models';
import { IProfileFields } from './ProfileContainer';
import styles from '../../OrganizationApp/UserProfile/UserProfile.module.scss';
import Input from '../../../controls/Inputs/Input';
import { modalNames } from '../../../../constants';
import { useTranslation } from 'react-i18next';

interface IProps {
  errors?: any;
  loader?: string;
  fields: IProfileFields;
  languages: Array<ISelectOptions> | undefined;
  genders: Array<ISelectOptions> | undefined;
  pronouns: Array<ISelectOptions> | undefined;
  races: Array<ISelectOptions> | undefined;
  ethnicity: Array<ISelectOptions> | undefined;
  educations: Array<ISelectOptions> | undefined;
  onChange: (field: string) => (value: { value: string; label: string }) => void;
  onSubmit: () => void;
  handleOnBlur: (field: string) => () => void;
  modal: string | false;
  closeModal: () => void;
  newField: string;
  handleChangeNewField: (value: string) => void;
  handleCreateField: () => void;
}

const ProfileView = ({
  loader,
  errors,
  fields,
  genders,
  pronouns,
  ethnicity,
  educations,
  races,
  onSubmit,
  onChange,
  handleOnBlur,
  languages,
  modal,
  closeModal,
  newField,
  handleChangeNewField,
  handleCreateField,
}: IProps) => {
  const { t } = useTranslation();

  const blockSubmit = () => {
    return (
      fields.language === null ||
      fields.pronouns === null ||
      fields.race === null ||
      fields.ethnicity === null ||
      fields.education === null
    );
  };

  blockSubmit();

  return (
    <>
      {loader}
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        <main className={'white-container content-center-wrapper mb-5'} style={{ alignItems: 'flex-start' }}>
          <div className={'container-page-center mb-5'}>
            <div className={'container-row container-column'} style={{ marginBottom: '40px' }}>
              <h1 className={'b-title gray-text font-l'}>{t('common:label.Your-Profile')}</h1>
            </div>
            <div className={'container-row'}>
              <Select
                value={fields.language}
                placeholder={t('common:label.preferred-language')}
                options={languages}
                error={errors.language}
                handleChange={onChange('language')}
                handleUnselect={handleOnBlur('language')}
                autoFocus
              />
            </div>
            <div className={'container-row'}>
              <Select
                value={[...(fields.gender || [])]}
                placeholder={`${t('common:label.gender-identity')} ^`}
                options={genders}
                error={errors.gender}
                handleChange={onChange('gender')}
                handleUnselect={handleOnBlur('gender')}
                isMulti={true}
              />
            </div>
            <div className={'container-row'}>
              <Select
                value={fields.pronouns}
                placeholder={t('common:label.pronouns')}
                options={pronouns}
                error={errors.pronouns}
                handleChange={onChange('pronouns')}
                handleUnselect={handleOnBlur('pronouns')}
              />
            </div>
            <div className={'container-row'}>
              <Select
                value={[...(fields.race || [])]}
                placeholder={`${t('common:label.race')} ^`}
                options={races}
                error={errors.race}
                handleChange={onChange('race')}
                handleUnselect={handleOnBlur('race')}
                isMulti={true}
              />
            </div>
            <div className={'container-row'}>
              <Select
                value={[...(fields.ethnicity || [])]}
                placeholder={`${t('common:label.ethnicity')} ^`}
                options={ethnicity}
                error={errors.ethnicity}
                handleChange={onChange('ethnicity')}
                handleUnselect={handleOnBlur('ethnicity')}
                isMulti={true}
              />
            </div>
            <div className={'container-row'}>
              <Select
                value={fields.education}
                placeholder={t('common:label.education')}
                options={educations}
                error={errors.education}
                handleChange={onChange('education')}
                handleUnselect={handleOnBlur('education')}
              />
            </div>
            <span className={styles.info}>^{t('awa:M97.msg')}</span>
            <div className={'container-row'}>
              <Button
                onPress={onSubmit}
                title={t('common:btn.next')}
                size={'small'}
                type={'orange-light'}
                disabled={blockSubmit()}
                enableEnter
              />
            </div>
          </div>
        </main>
        {modal ? (
          <Modal
            width={545}
            onClose={closeModal}
            className={styles.modal}
            title={`${modalNames()[modal]}: ${
              modal === 'education' ? t('common:headlines.other') : t('common:headlines.self-describe')
            }`}
          >
            <div className={styles.container}>
              <div className={`container-row mb-0`}>
                <div className={'input-wrapper'}>
                  <Input
                    name={'newField'}
                    error={errors.newField}
                    value={newField}
                    ariaLabel={t('common:headlines.self-describe')}
                    type={'text'}
                    handleChange={value => handleChangeNewField(value)}
                  />
                </div>
              </div>
              <div className={styles.btns}>
                <Button
                  className={styles.btn}
                  title={t('common:btn.close')}
                  onPress={closeModal}
                  type={'transparency'}
                  size={'large'}
                />
                <Button
                  disabled={!newField.length}
                  className={styles.btn}
                  title={t('common:btn.save')}
                  onPress={handleCreateField}
                  type={'orange-light'}
                  size={'large'}
                />
              </div>
            </div>
          </Modal>
        ) : null}
        <Footer />
      </div>
    </>
  );
};

export default ProfileView;
