import React from 'react';
import { ISelectOptions } from '../../../models';
import styles from './OpenedSelectStyle.module.scss';
import { Button } from '../index';

interface IProps {
  values: ISelectOptions[];
  onClick: (value: string) => void;
}

const OpenedSelect = (props: IProps) => {
  const { onClick, values } = props;

  const handleClick = (value: string) => {
    onClick && onClick(value);
  };

  return (
    <div className={styles.select_wrapper}>
      {values.map((v, i) => {
        return (
          <Button
            // type={'transparency'}
            key={`existing-${v.value}-${i}`}
            onPress={() => handleClick(String(v.value))}
            title={v.label}
            className={styles.button}
          />
        );
      })}
    </div>
  );
};

export default OpenedSelect;
