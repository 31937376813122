import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import {
  EWPStrategyCreateData,
  EWPStrategyModel,
  ISelectOptions,
  OrganizationUserModel,
} from '../../../../../models';
import { useEwpApi } from '../../../../../hooks/ewp/use-ewp-api';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { CreateOrgUserModal, OrgBreadcrumbs } from '../../../../common';
import { Button, Link, Select } from '../../../../controls';
import { useGetStrategyOptions } from '../../../../../hooks/ewp/use-get-strategy-options';
import { useEwpDetails } from '../../../../../hooks/ewp/use-ewp-details';
import { useRole } from '../../../../../helpers/roles';

interface IForm {
  title: string;
  description: string;
  goals: ISelectOptions[];
  owners: ISelectOptions[];
}

const EwpStrategyCreate = () => {
  const { t } = useTranslation();
  const { ewpDetails } = useEwpDetails();
  const { owners, goals, fetchOwners } = useGetStrategyOptions();
  const location = useLocation<EWPStrategyModel | undefined>();
  const { createStrategy, updateStrategy } = useEwpApi();
  const { navBack } = useNavBack();
  const editData: EWPStrategyModel | undefined = location.state;
  const access = useRole();
  const isPm = access(['project_manager']);

  const initialForm: IForm = {
    title: '',
    description: '',
    goals: [],
    owners: [],
  };
  const [form, setForm] = useState<IForm>(initialForm);
  const [showCreateOrgUser, setShowCreateOrgUser] = useState(false);

  useEffect(() => {
    if (editData) {
      setForm({
        title: editData.title,
        description: editData.description,
        goals: editData.goals,
        owners: editData.owners.map(item => ({ value: item.id, label: item.name })),
      });
    }
  }, []);

  const handleChangeInput = (val: string, fieldName: string) => {
    setForm({ ...form, [fieldName]: val });
  };

  const handleChangeSelect = (val: ISelectOptions[], fieldName: string) => {
    if (val.find(item => item.value === -1)) {
      return;
    }
    if (val.find(item => item.value === -2)) {
      setShowCreateOrgUser(true);
      return;
    }
    setForm({ ...form, [fieldName]: val });
  };

  const handleOrgUserCreated = (user: OrganizationUserModel) => {
    fetchOwners(() => {
      setForm(form => ({
        ...form,
        owners: [
          ...form.owners,
          {
            value: user.id,
            label: `${user.first_name} ${user.last_name}`,
          },
        ],
      }));
    });
  };

  const handleSave = () => {
    const data: EWPStrategyCreateData = {
      title: form.title,
      description: form.description,
      owners: form.owners.map(item => item.value as number),
      goals_ids: form.goals.map(item => item.value as number),
    };
    const callback = () => {
      navBack();
    };
    if (editData) {
      updateStrategy(data, editData.id, callback);
    } else {
      createStrategy(data, callback);
    }
  };

  const _submitDisabled = () => {
    return !(form.title && form.goals.length);
  };

  return (
    <>
      <main className={'b-page'}>
        <h1 className="b-page__title -desktop">
          {t('common:headlines.equity-work-plan')}:&nbsp;
          <span className="mark">{ewpDetails?.organization_name}</span>
        </h1>
        <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
        <Link onClick={navBack} className="b-page-back-link mb-5">
          {editData ? t('common:btn.edit') : t('common:btn.create')}&nbsp;
          {t('common:label.strategy')}
        </Link>
        <div className="b-elmDetails__panelParam mb-4">
          <div className="b-elmDetails__title font-navy font-m font-weight-bold required-field-modifier">
            {t('common:label.strategy')}:
          </div>
          <div className="flex-grow-1">
            <input
              disabled={editData && !editData.permissions.edit}
              value={form.title}
              onChange={event => handleChangeInput(event.target.value, 'title')}
              type="text"
              placeholder={t('main:ewp.title-strategy')}
              className="b-resourceForm__fieldInput"
              aria-label={'Strategy Title'}
            />
          </div>
        </div>
        <div className="b-elmDetails__panelParam mb-4">
          <div className="b-elmDetails__title font-navy font-m font-weight-bold">
            {t('common:label.description')}:
          </div>
          <div className="flex-grow-1">
            <textarea
              disabled={editData && !editData.permissions.edit}
              value={form.description}
              onChange={event => handleChangeInput(event.target.value, 'description')}
              name={'description'}
              aria-label="Description"
              placeholder={t('main:ewp.description-strategy')}
              className="b-resourceForm__fieldInput"
              rows={2}
            />
          </div>
        </div>
        <div className="mb-4">
          {owners ? (
            <Select
              isMulti
              disabled={editData && !editData.permissions.assign_owner}
              wrapperStyles={{ maxWidth: 550, width: '100%' }}
              placeholder={t('common:label.owners')}
              handleChange={value => handleChangeSelect(value, 'owners')}
              value={form.owners}
              options={
                isPm
                  ? [
                      ...owners,
                      { value: -1, label: '---' },
                      { value: -2, label: t('common:headlines.add-new-user') },
                    ]
                  : owners
              }
            />
          ) : null}
        </div>
        <div className="mb-2">
          {goals ? (
            <Select
              disabled={editData && !editData.permissions.edit}
              customClasses="required-field-modifier -type_select"
              isMulti
              wrapperStyles={{ maxWidth: 550, width: '100%' }}
              placeholder={t('common:label.associated-goals')}
              handleChange={value => handleChangeSelect(value, 'goals')}
              value={form.goals}
              options={goals}
              noOptionsMessage={t('awa:N73.msg')}
            />
          ) : null}
        </div>
        <div className="mb-4 required-legend">*{t('common:label.required')}</div>
        <div className="contentContainer mt-5 d-lg-flex justify-content-center">
          <Button
            size={'large'}
            className="button-mh"
            type={'transparency'}
            onPress={navBack}
            title={t('common:btn.cancel')}
          />
          <Button
            disabled={_submitDisabled()}
            size={'large'}
            className="button-mh"
            onPress={handleSave}
            title={t('common:btn.save')}
          />
        </div>
      </main>
      {showCreateOrgUser && ewpDetails ? (
        <CreateOrgUserModal
          orgId={ewpDetails.organization_id}
          onClose={() => setShowCreateOrgUser(false)}
          onSubmit={handleOrgUserCreated}
        />
      ) : null}
    </>
  );
};

export default EwpStrategyCreate;
