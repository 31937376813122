import { call, all, take, StrictEffect, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  GetDashboardElmPayload,
  GET_DASHBOARD_ELM,
  GetDashboardEAPayload,
  GET_DASHBOARD_EA,
  GetDashboardMapPayload,
  GET_DASHBOARD_MAP,
  getDashboardMapSuccess,
  GetDashboardMapPointDataPayload,
  GET_DASHBOARD_MAP_POINT_DATA,
  getDashboardEASuccess,
  getDashboardElmSuccess,
} from './dashboardActions';
import Api from '../../services/api';
import { t } from 'i18next';

function* getDashboardElmSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, params },
    }: { payload: GetDashboardElmPayload } = yield take(GET_DASHBOARD_ELM);

    try {
      const response = yield call(Api.post, `beloved/dashboard/elms/`, undefined, undefined, params);

      if (response) {
        yield put(getDashboardElmSuccess(response));
        callback && callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* getDashboardEASaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, params },
    }: { payload: GetDashboardEAPayload } = yield take(GET_DASHBOARD_EA);

    try {
      const response = yield call(Api.post, `beloved/dashboard/equity_audits/`, undefined, undefined, params);

      if (response) {
        yield put(getDashboardEASuccess(response));
        callback && callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* getDashboardMapSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback },
    }: { payload: GetDashboardMapPayload } = yield take(GET_DASHBOARD_MAP);

    try {
      const response = yield call(Api.post, `beloved/dashboard/map/list/`);

      if (response) {
        yield put(getDashboardMapSuccess(response));
        callback && callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* getDashboardMapPointDataSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: GetDashboardMapPointDataPayload } = yield take(GET_DASHBOARD_MAP_POINT_DATA);

    try {
      const response = yield call(Api.post, `beloved/dashboard/map/point_details/`, data);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

export function* saga() {
  yield all([
    getDashboardElmSaga(),
    getDashboardEASaga(),
    getDashboardMapSaga(),
    getDashboardMapPointDataSaga(),
  ]);
}
