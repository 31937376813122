import React, { FC, useEffect } from 'react';
import { Modal } from '../../../../common';
import { Button, Loader } from '../../../../controls';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router';
import { useGetResourceOptions } from '../../../../../hooks/resources/use-get-resource-options';
import useTableData from '../../../../../hooks/common/use-table-data';
import { isEmptyObject } from '../../../../../helpers';
import { useParams } from 'react-router-dom';
import { getPageOptions } from '../../../../../helpers/resourceHelper';

interface IProps {
  onClose: () => void;
}

export interface IRouteParams {
  action: string;
}

const FilterModal: FC<IProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isBeloved = location.pathname.match('beloved');
  const { action } = useParams<IRouteParams>();
  const options = useGetResourceOptions();

  const pageOptions = getPageOptions(action, isBeloved);

  const {
    handleSelectFilter,
    isFiltersChanged,
    setExtraData,
    handleApplyFilter,
    filterData,
    handleClearFilters,
  } = useTableData(pageOptions.entity);

  useEffect(() => {
    setExtraData({ url: pageOptions.url });
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleSaveFilter = () => {
    handleApplyFilter();
    onClose();
  };

  const onClearFilters = () => {
    handleClearFilters();
    onClose();
  };

  const renderContent = () => {
    if (!options) return <Loader fitParent />;

    return (
      <ul>
        {isBeloved ? (
          <>
            <li>
              <span>{t('common:column.status')}:</span>
            </li>
            <li>
              <ul>
                {Object.values(options.statuses).map(item => {
                  const isActive = filterData.statuses?.includes(item.alias);
                  return (
                    <li key={`filter-statuses-${item.alias}`}>
                      <a
                        href="#"
                        className={isActive ? '-active' : ''}
                        onClick={e => {
                          e.preventDefault();
                          handleSelectFilter(item.alias, 'statuses');
                        }}
                      >
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </li>
          </>
        ) : null}
        <li>
          <a
            href="#"
            className={filterData.is_favorite ? '-active' : ''}
            onClick={e => {
              e.preventDefault();
              handleSelectFilter(1, 'is_favorite');
            }}
          >
            {t('common:label.favorite')}
          </a>
        </li>
        <li>
          <span> {t('common:label.resource-type')}:</span>
        </li>
        <li>
          <ul>
            {options.types.map(item => {
              const isActive = filterData.types?.includes(item.value);
              return (
                <li key={`filter-types-${item.value}`}>
                  <a
                    href="#"
                    className={isActive ? '-active' : ''}
                    onClick={e => {
                      e.preventDefault();
                      handleSelectFilter(item.value, 'types');
                    }}
                  >
                    {item.label}
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
        <li>
          <span>{t('common:label.standards')}:</span>
        </li>
        <li>
          <ul>
            {options.standards.map(item => {
              const isActive = filterData.resource_category?.includes(item.alias);
              return (
                <li key={`filter-standards-${item.alias}`}>
                  <a
                    href="#"
                    className={isActive ? '-active' : ''}
                    onClick={e => {
                      e.preventDefault();
                      handleSelectFilter(item.alias, 'resource_category');
                    }}
                  >
                    {item.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
        <li>
          <span>{t('common:label.elm-domains')}:</span>
        </li>
        <li>
          <ul>
            {options.elm_domains.map(item => {
              const isActive = filterData.resource_category?.includes(item.id);
              return (
                <li key={`filter-elm_domains-${item.id}`}>
                  <a
                    href="#"
                    className={isActive ? '-active' : ''}
                    onClick={e => {
                      e.preventDefault();
                      handleSelectFilter(item.id, 'resource_category');
                    }}
                  >
                    {item.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
        <li>
          <span>{t('common:label.publisher')}:</span>
        </li>
        <li>
          <ul>
            {options.ownerships.map(item => {
              const isActive = filterData.ownerships?.includes(item.value as number);
              return (
                <li key={`filter-ownerships-${item.value}`}>
                  <a
                    href="#"
                    className={isActive ? '-active' : ''}
                    onClick={e => {
                      e.preventDefault();
                      handleSelectFilter(item.value, 'ownerships');
                    }}
                  >
                    {item.label}
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
        {isBeloved ? (
          <>
            <li>
              <span>{t('common:label.target-sectors')}:</span>
            </li>
            <li>
              <ul>
                {options.sectors.map(item => {
                  const isActive = filterData.resource_sector?.includes(item.value as number);
                  return (
                    <li key={`filter-sectors-${item.value}`}>
                      <a
                        href="#"
                        className={isActive ? '-active' : ''}
                        onClick={e => {
                          e.preventDefault();
                          handleSelectFilter(item.value, 'resource_sector');
                        }}
                      >
                        {item.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </li>
          </>
        ) : null}
        {isBeloved ? (
          <>
            <li>
              <span>{t('common:label.distribution')}:</span>
            </li>
            <li>
              <ul>
                {options.distributions.map(item => {
                  const isActive = filterData.distribution?.includes(item.value);
                  return (
                    <li key={`filter-standards-${item.value}`}>
                      <a
                        href="#"
                        className={isActive ? '-active' : ''}
                        onClick={e => {
                          e.preventDefault();
                          handleSelectFilter(item.value, 'distribution');
                        }}
                      >
                        {item.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </li>
          </>
        ) : null}
      </ul>
    );
  };

  return (
    <>
      <Modal
        width={650}
        className={!isMobile ? 'b-filtersModal__mobileModal' : ''}
        title={t('common:btn.filter')}
        onClose={handleClose}
      >
        <div className="b-filtersModal">
          <div className="b-filtersModal__list b-filtersModal__list_fullWidth">
            <div className="b-filtersModal__title">{t('common:filter.by')}</div>
            <div className="b-filtersModal__filterScrollable">{renderContent()}</div>
          </div>
        </div>
        <div className="b-filtersModal__buttons">
          <Button className="button-mh" onPress={handleClose} title={t('common:btn.cancel')} size={'small'} />
          <Button
            className="button-mh"
            onPress={onClearFilters}
            disabled={isEmptyObject(filterData)}
            title={t('common:btn.clear-filters')}
            size={'middle'}
          />
          <Button
            className={'button-mh'}
            disabled={!isFiltersChanged}
            onPress={handleSaveFilter}
            title={t('common:btn.filter')}
            size={'small'}
          />
        </div>
      </Modal>
    </>
  );
};

export default FilterModal;
