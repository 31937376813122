import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import { AvatarZoomed, HeadlineOrganization, OrgBreadcrumbs, OrgProfileTabs } from '../../../common';
import { useInfiniteList } from '../../../../hooks/common/use-infinite-list';
import { BelCertifiedConsultantShortModel, IRouteParamsBase } from '../../../../models';
import { useParams } from 'react-router';
import { Loader } from '../../../controls';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';

const OrgCertifiedUserList = () => {
  const { orgId } = useParams<IRouteParamsBase>();
  const { t } = useTranslation();
  const currentOrg = useSelector((state: StateType) => state.organization.currentOrg);
  const { fetchMoreData, fetchData, data } = useInfiniteList<BelCertifiedConsultantShortModel>(
    `organizations/${orgId}/beloved_certified_consultant/`,
  );

  useEffect(() => {
    fetchData();
  }, []);

  const renderContent = () => {
    if (!data) {
      return <Loader />;
    }

    return (
      <>
        <div
          className="font-m font-grey font-weight-bold text-align-center mt-4 mb-5"
          style={{ maxWidth: 900 }}
        >
          {t('main:belovedConsultant.assigned-consultant')}
        </div>
        <div className="b-infScrollWrapper">
          <InfiniteScroll
            dataLength={data.result.length}
            next={fetchMoreData}
            hasMore={!(data.current_page === data.total_pages)}
            loader={<h4 className="b-no-content">{t('common:label.loading')}...</h4>}
          >
            <div className="b-infScrollWrapper__normalize">
              <div className="b-belovedUser__list">
                {data.result.map(user => {
                  return (
                    <div
                      key={`user-card-certified-${user.id}`}
                      className="b-belovedUserCard mb-4 mr-4 b-panel d-flex flex-column align-items-center"
                    >
                      <div className="mb-4">
                        <AvatarZoomed
                          first_name={''}
                          last_name={user.consultant_name}
                          avatar={user.avatar}
                          className="-w128"
                        />
                      </div>
                      <div className="font-m font-weight-bold text-center mb-3 font-navy">
                        {user.consultant_name}
                      </div>
                      {user.email ? (
                        <a
                          onClick={e => e.stopPropagation()}
                          href={`mailto:${user.email}`}
                          rel="noreferrer"
                          target="_blank"
                          className="orange-light-text d-block text-center font-s mt-3 mb-3"
                        >
                          {user.email}
                        </a>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  };

  return (
    <main className={'b-page'}>
      <div className="b-page__header -desktop">
        <HeadlineOrganization
          orgName={currentOrg?.name || ''}
          orgId={currentOrg?.id || ''}
          headline={t('common:column.beloved-certified-consultants')}
          customClasses={'-noDesktopMb'}
        />
      </div>
      <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
      <OrgProfileTabs activeItemAlias={'beloved-consultant'} />
      {renderContent()}
    </main>
  );
};

export default OrgCertifiedUserList;
