import { ISelectOptions } from '../../models';
import Api from '../../services/api';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StateType } from '../../store/reducers';

export const useConsultants = (orgId?: number) => {
  let [consultants, setConsultants] = useState<ISelectOptions[] | null>(null);
  const isBeloved = useSelector((state: StateType) => state.profile.authUser?.beloved_user);

  const getData = (orgId: number) => {
    Api.get(`organizations/${orgId}/ewp/available_consultants//`).then(res => {
      if (res) {
        setConsultants(res);
      }
    });
  };

  useEffect(() => {
    if (!isBeloved && orgId) {
      getData(orgId);
    }
  }, [orgId]);

  return {
    consultants,
  };
};
