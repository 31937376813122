import { ISelectOptions } from '../../global';

export interface AdminToolRoleModel extends ISelectOptions<number> {
  permissions: number[];
}

export interface AdminToolPermissionModel {
  name: string;
  items: ISelectOptions<number>[];
}

export interface AdminToolUserModel {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  user_roles: ISelectOptions[];
  status: string;
  status_date: string;
  is_beloved_leader: boolean;
  is_active: boolean;
  user_id: number | null;
}

export interface AdminToolOrgUserModel {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  is_active: boolean;
  organization_name: string;
  is_removed: boolean;
  status: string;
  user_id: number | null;
  user_obj_is_active: boolean | null;
  user_obj_is_removed: boolean | null;
  status_date: string;
  user_role_name: string;
  user_type_name: string;
}

export interface AdminToolOrgUserProfileModel {
  avatar: string;
  account_status: string;
  division: string;
  email: string;
  first_name: string;
  last_name: string;
  id: number;
  organizations: AdminToolOrgUserOrgModel[];
  pronouns_name: string;
  secondary_email: string | null;
  sign_up_is_complete: string;
  title: string;
  user_id: number | null;
  solutions: inAdminToolOrgUserSolutionModel[];
}

export interface inAdminToolOrgUserSolutionModel {
  text: string;
  action?: string;
  alias?: string;
}

export interface AdminToolOrgUserOrgModel {
  id: number;
  joined_on: string;
  is_removed: boolean;
  user_obj_is_active: boolean | null;
  user_obj_is_removed: boolean | null;
  org_affiliation: string;
  organization_id: number;
  organization_name: string;
  role_name: string;
  status: string;
  status_date: string | null;
  type_name: string;
  user_role_id: number;
  user_type_id: number;
}

export interface AdminToolUpdateUserModel {
  first_name: string;
  last_name: string;
  email: string;
  roles: ISelectOptions[];
}

export enum AdminToolSettingsGroups {
  ea = 'ea',
  debrief = 'debrief',
  ewp = 'ewp',
  elm = 'elm',
  notification = 'notification',
}

export interface AdminToolSettingsItemModel {
  id: number;
  description: string;
  alias: string;
  group_alias: AdminToolSettingsGroups;
  days_inactivity: number;
  number_reminders: number;
  days_between_reminders: number;
  is_active: boolean;
}

export interface AdminToolSettingsTimeModel {
  time_local: string;
  time_zone: string;
}

export type NotificationAliasType = 'ea_completed' | 'elm_published' | 'ea_debrief_not_scheduled';

export interface AdminToolSettingsNotificationModel {
  id: number;
  name: string;
  additional_data: any;
  alias: NotificationAliasType;
  email_notification: boolean;
  task_notifications: boolean;
}

export interface AdminToolSettingsTeamModel {
  default_users: number[];
  notifications: AdminToolSettingsNotificationModel[];
}

export interface AdminToolSettingsModel {
  sections: AdminToolSettingsItemModel[];
  time: AdminToolSettingsTimeModel;
  price: AdminToolSettingsPriceModel[];
  resource_expiration: AdminToolSettingsResourceModel;
  beloved_team: AdminToolSettingsTeamModel;
  default_debrief_leader_id: number;
}

export interface AdminToolSettingsPriceModel {
  fee: string;
  history: AdminToolSettingsPriceHistoryModel[];
  id: number;
  name: string;
  org_type: string;
}

export interface AdminToolSettingsResourceConditionsModel {
  alias: string;
  days: number | string;
  id: number;
  name: string;
}

export interface AdminToolSettingsResourceModel {
  conditions: AdminToolSettingsResourceConditionsModel[];
  days: number | string;
  is_active: boolean;
}

interface AdminToolSettingsPriceHistoryModel {
  date: string;
  fee: string;
  user_id: number;
  user_name: string;
}

export interface AdminToolSettingsSection {
  title: string;
  description: string;
  group_alias: AdminToolSettingsGroups;
  setting_items: {
    title: string;
    is_active: boolean;
    id: number;
  }[];
  days_inactivity: number;
  number_reminders: number;
  days_between_reminders: number;
}

export interface AdminToolSettingsUpdateModel {
  id: number;
  days_inactivity: number;
  number_reminders: number;
  days_between_reminders: number;
  is_active: boolean;
}

export interface BelCreatePmFormModel {
  first_name: string;
  last_name: string;
  email: string;
  user_type: ISelectOptions;
}

export type IsDeactivateBtnDisabledType =
  | 'activate_account'
  | 'deactivate_account'
  | 'activate_association'
  | 'deactivate_association'
  | 'delete_account'
  | 'delete_association';
