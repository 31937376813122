import Api from '../../services/api';
import { useParams } from 'react-router';
import {
  ChartEwpDashboardModel,
  EWPGoalCreateData,
  EWPGoalModel,
  EWPKeyActionCreateData,
  EWPKeyActionModel,
  EWPPriorityCreateData,
  EWPPriorityModel,
  EWPStrategyCreateData,
  EWPStrategyModel,
  IRouteParamsBase,
} from '../../models';
import { useDispatch } from 'react-redux';
import { getEWPDetails } from '../../store/ewp/ewpActions';

export const useEwpApi = () => {
  const dispatch = useDispatch();
  const { orgId, id } = useParams<IRouteParamsBase>();

  const createPriority = (data: EWPPriorityCreateData, callback?: () => void) => {
    Api.post(`organizations/${orgId}/ewp/${id}/priorities/`, data).then(res => {
      if (res) {
        dispatch(getEWPDetails({ orgId: Number(orgId) }));
        callback && callback();
      }
    });
  };

  const updatePriority = (data: EWPPriorityCreateData, elId: number, callback?: () => void) => {
    Api.put(`organizations/${orgId}/ewp/${id}/priorities/${elId}/`, data).then(res => {
      if (res) {
        callback && callback();
      }
    });
  };

  const deletePriority = (elId: number, callback: () => void) => {
    Api.delete(`organizations/${orgId}/ewp/${id}/priorities/${elId}/`).then(() => {
      callback();
    });
  };

  const getPriorityDetails = (elId: number, callback: (data: EWPPriorityModel) => void) => {
    Api.get(`organizations/${orgId}/ewp/${id}/priorities/${elId}/`).then(res => {
      if (res) {
        callback(res);
      }
    });
  };

  const createGoal = (data: EWPGoalCreateData, callback?: () => void) => {
    Api.post(`organizations/${orgId}/ewp/${id}/goals/`, data).then(res => {
      if (res) {
        dispatch(getEWPDetails({ orgId: Number(orgId) }));
        callback && callback();
      }
    });
  };

  const updateGoal = (data: EWPGoalCreateData, elId: number, callback?: () => void) => {
    Api.put(`organizations/${orgId}/ewp/${id}/goals/${elId}/`, data).then(res => {
      if (res) {
        callback && callback();
      }
    });
  };

  const deleteGoal = (elId: number, callback: () => void) => {
    Api.delete(`organizations/${orgId}/ewp/${id}/goals/${elId}/`).then(() => {
      callback();
    });
  };

  const getGoalDetails = (elId: number, callback: (data: EWPGoalModel) => void) => {
    Api.get(`organizations/${orgId}/ewp/${id}/goals/${elId}/`).then(res => {
      if (res) {
        callback(res);
      }
    });
  };

  const createStrategy = (data: EWPStrategyCreateData, callback?: () => void) => {
    Api.post(`organizations/${orgId}/ewp/${id}/strategies/`, data).then(res => {
      if (res) {
        dispatch(getEWPDetails({ orgId: Number(orgId) }));
        callback && callback();
      }
    });
  };

  const updateStrategy = (data: EWPStrategyCreateData, elId: number, callback?: () => void) => {
    Api.put(`organizations/${orgId}/ewp/${id}/strategies/${elId}/`, data).then(res => {
      if (res) {
        callback && callback();
      }
    });
  };

  const deleteStrategy = (elId: number, callback: () => void) => {
    Api.delete(`organizations/${orgId}/ewp/${id}/strategies/${elId}/`).then(() => {
      callback();
    });
  };

  const getStrategyDetails = (elId: number, callback: (data: EWPStrategyModel) => void) => {
    Api.get(`organizations/${orgId}/ewp/${id}/strategies/${elId}/`).then(res => {
      if (res) {
        callback(res);
      }
    });
  };

  const createKeyAction = (data: EWPKeyActionCreateData, callback?: () => void) => {
    Api.post(`organizations/${orgId}/ewp/${id}/key_actions/`, data).then(res => {
      if (res) {
        dispatch(getEWPDetails({ orgId: Number(orgId) }));
        callback && callback();
      }
    });
  };

  const updateKeyAction = (data: EWPKeyActionCreateData, elId: number, callback?: () => void) => {
    Api.put(`organizations/${orgId}/ewp/${id}/key_actions/${elId}/`, data).then(res => {
      if (res) {
        callback && callback();
      }
    });
  };

  const deleteKeyAction = (elId: number, callback: () => void) => {
    Api.delete(`organizations/${orgId}/ewp/${id}/key_actions/${elId}/`).then(() => {
      callback();
    });
  };

  const getKeyActionDetails = (elId: number, callback: (data: EWPKeyActionModel) => void) => {
    Api.get(`organizations/${orgId}/ewp/${id}/key_actions/${elId}/`).then(res => {
      if (res) {
        callback(res);
      }
    });
  };

  const getDashboard = (callback: (data: ChartEwpDashboardModel) => void) => {
    Api.get(`organizations/${orgId}/ewp/${id}/dashboard/`).then(res => {
      if (res) {
        callback(res);
      }
    });
  };

  return {
    createGoal,
    updateGoal,
    getGoalDetails,
    createPriority,
    updatePriority,
    deletePriority,
    getPriorityDetails,
    createStrategy,
    updateStrategy,
    getStrategyDetails,
    createKeyAction,
    updateKeyAction,
    deleteGoal,
    deleteStrategy,
    deleteKeyAction,
    getKeyActionDetails,
    getDashboard,
  };
};
