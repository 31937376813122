import { call, all, take, StrictEffect, put, select } from 'redux-saga/effects';
import {
  CreateElmPayload,
  CREATE_ELM,
  createElmSuccess,
  GET_ELM_LIST,
  UPDATE_ELM,
  UpdateElmPayload,
  GET_ELM_OPTIONS,
  getElmOptionsSuccess,
  GET_ELM_A_MANAGERS,
  getElmAManagersSuccess,
  CANCEL_ELM,
  CancelElmPayload,
  UPLOAD_USER_LIST_FILE,
  UploadUserListFilePayload,
  SEND_NOTIFICATION,
  SendNotificationPayload,
  sendNotificationSuccess,
  TAKE_ELM,
  TakeElmPayload,
  SUBMIT_ELM,
  GET_ELM_REPORT,
  GetElmReportPayload,
  PublishElmReportPayload,
  PUBLISH_ELM_REPORT,
  getElmOrgReportSuccess,
  SubmitElmPayload,
  SAVE_ANSWER_ELM,
  SaveAnswerElmPayload,
  CLOSE_ADMIN_ELM,
  CloseAdminElmPayload,
  GET_ELM_USER_LIST,
  GetElmUserListPayload,
  DeleteElmUserPayload,
  DELETE_ELM_USER,
  GET_AVAILABLE_ELM_USERS,
  GetAvailableElmUsersPayload,
  AddUserToElmPayload,
  ADD_USER_TO_ELM,
  DeactivateElmUserPayload,
  DEACTIVATE_ELM_USER,
  CreateElmUserPayload,
  CREATE_ELM_USER,
  GET_ELM_DETAILS,
  GetElmDetailsPayload,
  GetELMListPayload,
  getElmUserListSuccess,
  SET_USER_ROLE,
  SetUserRolePayload,
} from './elmActions';
import Api from '../../services/api';
import { getOrgIdFormRoute } from '../../helpers';
import { toast } from 'react-toastify';
import { ElmUserReportItemModel } from '../../models';
import { t } from 'i18next';
import { StateType } from '../reducers';

function* createElmSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback },
    }: { payload: CreateElmPayload } = yield take(CREATE_ELM);
    const orgId = getOrgIdFormRoute();
    try {
      const response = yield call(Api.post, `organizations/${orgId}/elm/`);
      if (response) {
        callback && callback();
        yield put(createElmSuccess(response));
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* updateElmSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data, elmId, isDetails },
    }: { payload: UpdateElmPayload } = yield take(UPDATE_ELM);
    const orgId = getOrgIdFormRoute();
    try {
      let response;
      if (isDetails) {
        response = yield call(Api.patch, `organizations/${orgId}/elm/${elmId}/`, undefined, data);
      } else {
        response = yield call(Api.put, `organizations/${orgId}/elm/${elmId}/`, data);
      }

      if (response) {
        callback(false, response);
      }
    } catch (e: any) {
      callback(true);
      if (e.response?.status === 400) {
        if (e.response.data?.msg) {
          toast.error(e.response.data.msg);
        }
      }
    }
  }
}

function* getElmListSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, params },
    }: { payload: GetELMListPayload } = yield take(GET_ELM_LIST);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.get, `organizations/${orgId}/elm/`, params);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* getElmDetailsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, elmId },
    }: { payload: GetElmDetailsPayload } = yield take(GET_ELM_DETAILS);
    const orgId = getOrgIdFormRoute();

    const response = yield call(Api.get, `organizations/${orgId}/elm/${elmId}/`);

    if (response) {
      callback && callback(response);
    }
  }
}

function* cancelElmSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, elmId },
    }: { payload: CancelElmPayload } = yield take(CANCEL_ELM);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.delete, `organizations/${orgId}/elm/${elmId}/cancel/`);

      if (response) {
        callback && callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* getElmOptionsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    yield take(GET_ELM_OPTIONS);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.get, `organizations/${orgId}/elm/options/`);

      if (response) {
        yield put(getElmOptionsSuccess(response));
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* getElmAManagersSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    yield take(GET_ELM_A_MANAGERS);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.get, `organizations/${orgId}/elm/available_managers/`);

      if (response) {
        yield put(getElmAManagersSuccess(response));
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* getElmUserListSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { params, callback, extraData },
    }: { payload: GetElmUserListPayload } = yield take(GET_ELM_USER_LIST);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(
        Api.get,
        `organizations/${orgId}/elm_users_list/${extraData?.elmId}/`,
        params,
      );

      if (response) {
        yield put(getElmUserListSuccess(response));
        callback && callback();
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* deleteElmUserSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: {
        callback,
        data: { elmId, elm_user_id, is_elm_org_team },
      },
    }: { payload: DeleteElmUserPayload } = yield take(DELETE_ELM_USER);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.post, `organizations/${orgId}/elm_users_list/${elmId}/remove/`, {
        elm_user_id,
        is_elm_org_team,
      });

      if (response) {
        callback && callback();
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* deactivateElmUserSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: {
        callback,
        data: { elmId, elm_user_ids, is_active, is_elm_org_team },
      },
    }: { payload: DeactivateElmUserPayload } = yield take(DEACTIVATE_ELM_USER);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(
        Api.post,
        `organizations/${orgId}/elm_users_list/${elmId}/deactivate_user/`,
        {
          elm_user_ids,
          is_elm_org_team,
          is_active,
        },
      );

      if (response) {
        callback && callback();
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* getAvailableElmUsersSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, elmId },
    }: { payload: GetAvailableElmUsersPayload } = yield take(GET_AVAILABLE_ELM_USERS);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(
        Api.get,
        `organizations/${orgId}/elm_users_list/${elmId}/available_user_to_elm/`,
      );

      if (response) {
        callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* addUserToElmSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, elmId, users_ids, elm_role_type },
    }: { payload: AddUserToElmPayload } = yield take(ADD_USER_TO_ELM);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(
        Api.post,
        `organizations/${orgId}/elm_users_list/${elmId}/add_user_to_elm/`,
        { users_ids: users_ids, elm_role_type: elm_role_type },
      );

      if (response) {
        callback && callback();
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* createElmUserSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, elmId, data },
    }: { payload: CreateElmUserPayload } = yield take(CREATE_ELM_USER);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.post, `organizations/${orgId}/elm_users_list/${elmId}/`, data);

      if (response) {
        callback && callback();
      }
    } catch (e: any) {
      callback && callback(null, e.response?.data);
    }
  }
}

function* uploadUserListFileSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { elmId, file, callback },
    }: { payload: UploadUserListFilePayload } = yield take(UPLOAD_USER_LIST_FILE);
    const orgId = getOrgIdFormRoute();

    const form = new FormData();
    form.append('file', file);
    try {
      const response = yield call(
        Api.put,
        `organizations/${orgId}/elm/${elmId}/file_actions/upload/`,
        form,
        undefined,
        {
          'Content-Type': 'multipart/form-data',
        },
      );

      if (response) {
        callback && callback(response);
      }
    } catch (e: any) {
      callback && callback(null, e.response?.data);
    }
  }
}

function* sendNotificationSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, elmId, userIds },
    }: { payload: SendNotificationPayload } = yield take(SEND_NOTIFICATION);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.post, `organizations/${orgId}/elm/send_notification/`, {
        elm_id: elmId,
        team_ids: userIds,
      });

      if (response) {
        yield put(sendNotificationSuccess(response));
        toast.success(t('common:toast.email-sent'));
        callback && callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* takeElmSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { elmId, callback },
    }: { payload: TakeElmPayload } = yield take(TAKE_ELM);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.post, `organizations/${orgId}/elm/${elmId}/take_elm/`);

      if (response) {
        callback && callback({ data: response });
      }
    } catch (e) {
      callback && callback({ error: true });
      toast.error(t('common:toast.error'));
    }
  }
}

function* saveAnswerElmSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, elmId, data },
    }: { payload: SaveAnswerElmPayload } = yield take(SAVE_ANSWER_ELM);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.put, `organizations/${orgId}/elm/${elmId}/save_answer/`, data);

      if (response) {
        callback && callback({ data: response });
      }
    } catch (e) {
      callback && callback({ error: true });
      toast.error(t('common:toast.error'));
    }
  }
}

function* submitElmSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, elmId },
    }: { payload: SubmitElmPayload } = yield take(SUBMIT_ELM);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.put, `organizations/${orgId}/elm/${elmId}/submit_elm/`);

      if (response) {
        callback && callback(false);
      }
    } catch (e) {
      callback && callback(true);
      toast.error(t('common:toast.error'));
    }
  }
}

function* closeAdminElmSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, elmId },
    }: { payload: CloseAdminElmPayload } = yield take(CLOSE_ADMIN_ELM);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.put, `organizations/${orgId}/elm/${elmId}/lock_elm/`);

      if (response) {
        toast.success(t('common:toast.elm-closed'));
        callback && callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* getElmReportSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    let beloved_user = yield select((state: StateType) => state.profile.authUser?.beloved_user);
    const {
      payload: {
        callback,
        data: { elmId, type },
      },
    }: { payload: GetElmReportPayload } = yield take(GET_ELM_REPORT);
    const orgId = getOrgIdFormRoute();

    const url = beloved_user
      ? `beloved/elm/${elmId}/get_report/`
      : `organizations/${orgId}/elm/${elmId}/get_report/${type}/`;

    try {
      const response = yield call(Api.get, url);

      if (response) {
        if (response.type === 'user_report') {
          callback &&
            callback({
              report: response.data as ElmUserReportItemModel[],
              no_submitted: response.no_submitted,
            });
        } else if (response.type === 'org_report') {
          yield put(getElmOrgReportSuccess(response.data));
          callback && callback({ org_name: response.organization_name, no_submitted: response.no_submitted });
        }
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* publishElmReportSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, elmId },
    }: { payload: PublishElmReportPayload } = yield take(PUBLISH_ELM_REPORT);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.post, `organizations/${orgId}/elm/${elmId}/publish_user_reports/`);

      if (response) {
        callback && callback(response);
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        if (e.response.data?.msg) {
          toast.error(e.response.data.msg);
        }
      }
    }
  }
}

function* setElmUserRoleSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data, elmId },
    }: { payload: SetUserRolePayload } = yield take(SET_USER_ROLE);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(
        Api.put,
        `organizations/${orgId}/elm_users_list/${elmId}/set_elm_role_type/`,
        data,
      );

      if (response) {
        callback && callback();
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        if (e.response.data?.msg) {
          toast.error(e.response.data.msg);
        }
      }
    }
  }
}

export function* saga() {
  yield all([
    createElmSaga(),
    getElmListSaga(),
    updateElmSaga(),
    getElmOptionsSaga(),
    getElmUserListSaga(),
    getElmAManagersSaga(),
    addUserToElmSaga(),
    cancelElmSaga(),
    uploadUserListFileSaga(),
    sendNotificationSaga(),
    takeElmSaga(),
    createElmUserSaga(),
    saveAnswerElmSaga(),
    submitElmSaga(),
    closeAdminElmSaga(),
    getElmReportSaga(),
    publishElmReportSaga(),
    deleteElmUserSaga(),
    getAvailableElmUsersSaga(),
    deactivateElmUserSaga(),
    getElmDetailsSaga(),
    setElmUserRoleSaga(),
  ]);
}
