import { CHANGE_AUTH_STEP, GET_USER_SUCCESS, ProfileActionsTypes } from './profileActions';
import { AuthUserModel } from '../../models';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';

export type UserDataState = {
  authUser: AuthUserModel | null;
};

const initialState: UserDataState = {
  authUser:
    (localStorage.getItem('authUser') && JSON.parse(<string>localStorage.getItem('authUser'))) || null,
};

const profileReducer = (
  state: UserDataState = initialState,
  action: ProfileActionsTypes<any>,
): UserDataState => {
  const { payload } = action;

  switch (action.type) {
    case GET_USER_SUCCESS: {
      localStorage.setItem('authUser', JSON.stringify(payload));
      return {
        ...state,
        authUser: payload,
      };
    }
    case CHANGE_AUTH_STEP: {
      if (!state.authUser) return state;
      return {
        ...state,
        authUser: {
          ...state.authUser,
          next_step: payload,
        },
      };
    }
    case LOGOUT_SUCCESS:
      return { ...state, authUser: null };
    default:
      return state;
  }
};

export default profileReducer;
