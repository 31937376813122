import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import Api from '../../services/api';
import { GET_AVG_CHART, GetAvgChartPayload, setActiveAvgChart } from './avgActions';
import { ActionModel } from '../../models';

function* getAvgChart({
  payload,
}: ActionModel<typeof GET_AVG_CHART, GetAvgChartPayload>): Generator<StrictEffect, void, any> {
  try {
    const route = payload.isBeloved
      ? `beloved/equity_audit/get_group_avg/${payload.id}/`
      : `organizations/${payload.org_id}/equity_audit/group_average/${payload.id}/get_group/`;
    const response = yield call(Api.get, route);
    if (response) {
      yield put(
        setActiveAvgChart({
          ...response,
          avgChart: response.substandard_chart,
        }),
      );
      payload.callback && payload.callback(response);
    }
  } catch (e: any) {
    console.log('e', e);
  }
}

export function* saga() {
  yield all([takeLatest(GET_AVG_CHART, getAvgChart)]);
}
