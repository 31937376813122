import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { EWPPriorityCreateData, EWPPriorityModel, ISelectOptions } from '../../../../../models';
import { useEwpApi } from '../../../../../hooks/ewp/use-ewp-api';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { OrgBreadcrumbs } from '../../../../common';
import { Button, Link, Select } from '../../../../controls';
import { useGetPriorityOptions } from '../../../../../hooks/ewp/use-get-priority-options';
import { useEwpDetails } from '../../../../../hooks/ewp/use-ewp-details';

interface IForm {
  title: string;
  description: string;
  stakeholders: ISelectOptions[];
  sub_standards: ISelectOptions[];
}

const EwpPriorityCreate = () => {
  const { t } = useTranslation();
  const { ewpDetails } = useEwpDetails();
  const options = useGetPriorityOptions();
  const location = useLocation<EWPPriorityModel | undefined>();
  const { createPriority, updatePriority } = useEwpApi();
  const { navBack } = useNavBack();
  const editData = location.state;

  const initialForm = {
    title: '',
    description: '',
    stakeholders: [],
    sub_standards: [],
  };

  const [form, setForm] = useState<IForm>(initialForm);

  useEffect(() => {
    if (editData) {
      setForm({
        title: location.state.title,
        description: location.state.description,
        stakeholders: location.state.stakeholders,
        sub_standards: location.state.sub_standards,
      });
    }
  }, []);

  const handleChangeInput = (val: string, fieldName: string) => {
    setForm({ ...form, [fieldName]: val });
  };

  const handleChangeSelect = (val: ISelectOptions, fieldName: string) => {
    setForm({ ...form, [fieldName]: val });
  };

  const handleSave = () => {
    const data: EWPPriorityCreateData = {
      title: form.title,
      description: form.description,
      stakeholders_ids: form.stakeholders.map(item => item.value as number),
      sub_standards_ids: form.sub_standards.map(item => item.value as number),
    };
    const callback = () => {
      navBack();
    };
    if (editData) {
      updatePriority(data, editData.id, callback);
    } else {
      createPriority(data, callback);
    }
  };

  const _submitDisabled = () => {
    return !(form.title && form.stakeholders.length && form.sub_standards.length);
  };

  return (
    <main className={'b-page'}>
      <h1 className="b-page__title -desktop">
        {t('common:headlines.equity-work-plan')}:&nbsp;
        <span className="mark">{ewpDetails?.organization_name}</span>
      </h1>
      <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
      <Link onClick={navBack} className="b-page-back-link mb-5">
        {editData ? t('common:btn.edit') : t('common:btn.create')}&nbsp;
        {t('common:tabs.priority')}
      </Link>
      <div className="b-elmDetails__panelParam mb-4">
        <div className="b-elmDetails__title font-navy font-m font-weight-bold required-field-modifier">
          {t('common:tabs.priority')}:
        </div>
        <div className="flex-grow-1">
          <input
            value={form.title}
            onChange={event => handleChangeInput(event.target.value, 'title')}
            type="text"
            placeholder={t('main:ewp.title-priority')}
            className="b-resourceForm__fieldInput"
            aria-label={'Priority Title'}
          />
        </div>
      </div>
      <div className="b-elmDetails__panelParam mb-4">
        <div className="b-elmDetails__title font-navy font-m font-weight-bold">
          {t('common:label.description')}:
        </div>
        <div className="flex-grow-1">
          <textarea
            value={form.description}
            onChange={event => handleChangeInput(event.target.value, 'description')}
            name={'description'}
            aria-label="Description"
            placeholder={t('main:ewp.description-priority')}
            className="b-resourceForm__fieldInput"
            rows={2}
          />
        </div>
      </div>
      <div className="mb-2">
        {options.subStandards ? (
          <Select
            customClasses="required-field-modifier -type_select"
            isMulti
            handleChange={value => handleChangeSelect(value, 'sub_standards')}
            wrapperStyles={{ maxWidth: 550, width: '100%' }}
            placeholder={t('common:label.substandards')}
            value={form.sub_standards}
            options={options.subStandards}
          />
        ) : null}
      </div>
      <div className="mb-2">
        {options.stakeholders ? (
          <Select
            customClasses="required-field-modifier -type_select"
            isMulti
            wrapperStyles={{ maxWidth: 550, width: '100%' }}
            placeholder={t('common:label.stakeholders')}
            handleChange={value => handleChangeSelect(value, 'stakeholders')}
            value={form.stakeholders}
            options={options.stakeholders}
          />
        ) : null}
      </div>
      <div className="mb-4 required-legend">*{t('common:label.required')}</div>
      <div className="contentContainer mt-5 d-lg-flex justify-content-center">
        <Button
          size={'large'}
          className="button-mh"
          type={'transparency'}
          onPress={navBack}
          title={t('common:btn.cancel')}
        />
        <Button
          disabled={_submitDisabled()}
          size={'large'}
          className="button-mh"
          onPress={handleSave}
          title={t('common:btn.save')}
        />
      </div>
    </main>
  );
};

export default EwpPriorityCreate;
