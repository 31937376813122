import React, { FC } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { basicDateFormat } from '../../../helpers';
import { ROOTS } from '../../../routes/routes';
import { CohortMessageModel, IRouteParamsBase } from '../../../models';

interface IProps {
  data: CohortMessageModel;
}

const CohortMessageItem: FC<IProps> = ({ data }) => {
  const location = useLocation();
  const isBeloved = location.pathname.match('beloved');
  const { id, orgId } = useParams<IRouteParamsBase>(); // id = cohort id

  const url = isBeloved
    ? ROOTS.BELOVED_COHORT_MESSAGE_DETAILS.replace(':id', `${id}`).replace(':messageId', `${data.id}`)
    : ROOTS.COHORT_MESSAGE_DETAILS.replace(':orgId', orgId)
        .replace(':id', `${id}`)
        .replace(':messageId', `${data.id}`);

  return (
    <Link to={url} className="b-panel b-carouselCommonItem">
      <div className="b-carouselCommonItem__header">
        <span className={`b-carouselCommonItem__user ${data.is_seen ? '-read' : ''}`}>{data.subject}</span>
        <div className="b-carouselCommonItem__dateWrap">
          {data.created_at ? (
            <span className="b-carouselCommonItem__date">{basicDateFormat(data.created_at)}</span>
          ) : null}

          {data.is_seen ? (
            <span className="b-button-icon -cohMessageRead -noHover " />
          ) : (
            <span className="b-button-icon -cohMessageUnread -noHover " />
          )}
        </div>
      </div>
      <div className={`b-newsBlockItem__text -pre -lg ${data.is_seen ? '-read' : ''}`}>
        {data.description || ''}
      </div>
    </Link>
  );
};

export default CohortMessageItem;
