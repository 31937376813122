import { useState } from 'react';
import Api from '../../../services/api';
import { AdminToolOrgUserProfileModel, CreateOrgModel } from '../../../models';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { useHistory } from 'react-router';

const useAtOrgUserProfile = () => {
  const history = useHistory();

  let [user, setUser] = useState<AdminToolOrgUserProfileModel | null>(null);

  const getUser = async (id: number) => {
    try {
      const response = await Api.get(`beloved/admin_tool/organization_users/${id}/`);
      if (response) {
        setUser(response);
      }
    } catch (e: any) {
      if (e.response.status === 404) {
        toast.error(e.response.data.detail);
        history.go(-1);
      } else {
        toast.error(t('common:toast.error'));
      }
    }
  };

  const removeOrg = async (afId: number, cb: (orgId: number | null) => void) => {
    try {
      const response = await Api.delete(`beloved/admin_tool/organization_users/${afId}/remove_affiliation/`);
      toast.success(t('common:toast.affiliation-deleted'));
      if (response.id) {
        cb(response.id);
        setUser(response);
      } else {
        cb(null);
      }
    } catch (e: any) {
      toast.error(t('common:toast.error'));
    }
  };

  const updateOrg = async (formData: CreateOrgModel, id: number, cb: () => void) => {
    try {
      const response = await Api.put(`beloved/admin_tool/organization_users/${id}/update_affiliation/`, {
        user_type_id: formData.type_id,
        user_role_id: formData.role_id,
      });
      if (response) {
        toast.success(t('common:toast.affiliation-updated'));
        setUser(response);
        cb();
      }
    } catch (e: any) {
      toast.error(t('common:toast.error'));
    }
  };

  const resolveAction = async (alias: string, afId: string) => {
    try {
      const response = await Api.post(`beloved/admin_tool/organization_users/resolve_action/`, {
        alias,
        user_team_id: afId,
      });
      if (response) {
        toast.success(t('common:toast.success'));
        setUser(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  };

  const passwordReset = async (afId: number, cb: (data: any) => void) => {
    try {
      const response = await Api.get(`beloved/admin_tool/organization_users/${afId}/password_reset/`);
      if (response) {
        cb(response);
      }
    } catch (e: any) {
      toast.error(t('common:toast.error'));
    }
  };

  return {
    user,
    updateOrg,
    getUser,
    removeOrg,
    passwordReset,
    resolveAction,
  };
};

export default useAtOrgUserProfile;
