import React, { FC } from 'react';
import { Button } from '../../../../../controls';
import StepIndicator from '../StepIndicator/StepIndicator';
import { InstructionStep } from '../../../../../../models';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

interface IProps {
  setCurrentStep: (step: InstructionStep) => void;
}

const Step4: FC<IProps> = ({ setCurrentStep }) => {
  const { t } = useTranslation();
  const history: any = useHistory();

  return (
    <div className={'b-step b-step__container -w735'}>
      <div style={{ marginBottom: 8 }} className="b-equityAudit__text16 b-equityAudit__textCenter">
        {t('common:headlines.ea-preparation')}
      </div>
      <div style={{ marginBottom: 16 }} className={'b-step__bigTitle'}>
        {t('common:headlines.ea-date')}
      </div>
      <div style={{ marginBottom: 36 }}>
        <StepIndicator step={4} />
      </div>
      <div className="b-step__p">
        {t('awa:M32.msg.p2')} <b>{t('awa:M32.msg.p3')}</b> {t('awa:M32.msg.p4')}
      </div>
      <div className="b-step__p">{t('awa:M32.msg.p5')}</div>
      <div className="b-step__p">{t('awa:M32.msg.p6')}</div>
      <div className="b-step__wBox -paddings">
        <div className="b-step__orangeBold b-step__textCenter -mb5">{t('main:ea-create.task')}:</div>
        <div className="b-step__p b-step__textCenter" style={{ marginBottom: 0 }}>
          {t('awa:M32.msg.p7')}
        </div>
      </div>
      <div className={`b-step__bottom`}>
        <Button
          className={'btn -type_form'}
          type={'transparency'}
          onPress={() => history.goBack()}
          title={t('common:btn.cancel')}
        />
        <div className={`b-step__bottom-left`}>
          <Button
            className="btn -type_form b-step__mr8"
            type={'bordered'}
            onPress={() => setCurrentStep('step3_5')}
            title={t('common:btn.back')}
          />
          <Button
            className={'btn -type_form'}
            type={'orange-light'}
            onPress={() => setCurrentStep('step5')}
            title={t('common:btn.next')}
            enableEnter
          />
        </div>
      </div>
    </div>
  );
};

export default Step4;
