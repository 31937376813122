import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { HeadlineOrganization, Modal } from '../../../../common';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Checkbox, Loader } from '../../../../controls';
import { AvgAuditsTreeModel, AvgModel, IRouteParamsBase } from '../../../../../models';
import { Modals, ModalsModel } from '../../../../../constants';
import { useTranslation } from 'react-i18next';
import GroupAveragesChartBar from './GroupAveragesChartBar';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';
import Api from '../../../../../services/api';
import { useSelectCurrentOrg } from '../../../../../hooks/org/use-select-current-org';
import { getAvgChart, setActiveAvgChart } from '../../../../../store/avg/avgActions';
import { useDispatch } from 'react-redux';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';

interface IRouteParams extends IRouteParamsBase {
  avg_id: string;
}

const GroupAveragesChartOrg = () => {
  const { t } = useTranslation();
  const [isCompare, setIsCompare] = useState<boolean>(false);
  const [modals, setModals] = useState<ModalsModel>({});
  const history = useHistory();
  const { orgId, avg_id, id } = useParams<IRouteParams>();
  const [chart, setChart] = useState<AvgAuditsTreeModel[] | null>(null);
  const isBeloved = location.pathname.match('beloved');
  const currentOrg = useSelectCurrentOrg();
  const dispatch = useDispatch();
  const { navBack } = useNavBack();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (history.location.state?.audit_tree?.length) {
      setChart(history.location.state?.audit_tree);
    }

    // Data by ID
    if (avg_id && !history.location.state?.audit_tree) {
      dispatch(
        getAvgChart({
          org_id: orgId,
          id: Number(id),
          isBeloved: !!isBeloved,
          callback: res => {
            if (res) {
              const data = res.substandard_chart.audits_tree_data.find(f => f.id === id);
              if (data) {
                setChart([data]);
              }
            }
          },
        }),
      );
      return;
    }

    if (!history.location.state?.audit_tree) {
      history.go(-1);
      return;
    }
  };

  const handleCompare = () => {
    setIsCompare(!isCompare);
  };

  const openModal = (modal: Modals) => {
    setModals(prevState => ({
      ...prevState,
      [modal]: true,
    }));
  };

  const closeModal = (modal: Modals) => {
    setModals(prevState => ({
      ...prevState,
      [modal]: false,
    }));
  };

  const renderContent = () => {
    if (!chart) {
      return <Loader fitParent />;
    }
    if (chart) {
      return (
        <main className={'pageContainer pageContainer__content'}>
          <div className="b-report">
            {isMobileOnly && (
              <div className="b-report__notification">
                <b className="b-report__notificationTitle">{t('main:reports.ea-completed.title')}</b>
                <span>{t('main:reports.ea-completed.msg')}</span>
              </div>
            )}

            <div className="b-report__header">
              {isMobileOnly && <div className="b-report__arrow" />}
              <HeadlineOrganization
                headline={t('common:btn.equity-audits')}
                orgName={t('common:headlines.org-and-sub-organizations', {
                  organization_name: currentOrg?.name,
                })}
                orgId={orgId}
                customClasses={'b-report__pageTitle'}
              />

              <div className="b-report__pageSubTitle">
                <h2 className="b-page__title2 font-navy -backIcon">
                  <EmptyLink onClick={navBack} className="b-button-icon-back" />
                  {t('common:tabs.standards-substandards-averages')}
                </h2>
              </div>
            </div>
            <div className={'b-chart__chartAveragesWrap'}>
              <div className="b-report__holder mb-3">
                <GroupAveragesChartBar chart={chart} isCompare={isCompare} />
              </div>
              <div className={'b-chart__compareBlock'}>
                <Checkbox
                  onChange={handleCompare}
                  checked={isCompare}
                  id="compareCheck"
                  className={'b-chart__checkbox'}
                >
                  <span className={'b-chart__compareBlock__text'}>
                    {t('common:checkbox.compare-against-global')}
                  </span>
                  <button
                    className="b-report__btnInfo mt-1"
                    onClick={() => openModal(Modals.isGlobalResultInfo)}
                  >
                    <span className="sr-only">{t('common:btn.information')}</span>
                  </button>
                </Checkbox>
              </div>
            </div>
          </div>
          <div className={'b-chart__standards'}>
            <span>{t('main:reports.click-for-avg-org')}</span>
          </div>
        </main>
      );
    }
  };

  return (
    <>
      {renderContent()}
      {modals.isGlobalResultInfo ? (
        <Modal
          width={545}
          onClose={() => closeModal(Modals.isGlobalResultInfo)}
          className={'b-chart__modal'}
          classNameTitle={'b-chart__modal__title'}
          title={t('awa:M55.title')}
        >
          <div>
            <p>{t('awa:M55.msg.p1')}</p>
            <p className={'b-chart__bold'}>{t('awa:M55.msg.p2')}</p>
            <p>{t('awa:M55.msg.p3')}</p>
            <p>{t('awa:M55.msg.p4')}</p>
            <p>{t('awa:M55.msg.p5')}</p>
            <div className={'container-row mt-5'}>
              <Button
                onPress={() => closeModal(Modals.isGlobalResultInfo)}
                title={t('common:btn.ok')}
                type={'orange-light'}
                size={'small'}
              />
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default GroupAveragesChartOrg;
