import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Link, Loader } from '../../../../controls';
import { useHistory, useParams } from 'react-router-dom';
import {
  FGDetailedModel,
  FunctionGroupGroupsModel,
  IRouteParamsBase,
  KeyValueNumberModel,
} from '../../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { getFGReport } from '../../../../../store/report/reportActions';
import { ROOTS } from '../../../../../routes/routes';
import { StateType } from '../../../../../store/reducers';
import { useTranslation } from 'react-i18next';
import { HeadlineOrganization } from '../../../../common';

const FunctionGroups = () => {
  const { t } = useTranslation();
  const { orgId, eaId } = useParams<IRouteParamsBase>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [fg, setFg] = useState<FGDetailedModel<FunctionGroupGroupsModel[]>>();
  const fgSelect: FGDetailedModel<KeyValueNumberModel<FunctionGroupGroupsModel>> | null = useSelector(
    (state: StateType) => state.report.reportsData[Number(eaId)]?.fg,
  );

  useEffect(() => {
    if (fgSelect) {
      getFGReportsSuccess(fgSelect);
      return;
    }
    dispatch(
      getFGReport({
        data: {
          eaId: Number(eaId),
        },
        callback: getFGReportsSuccess,
      }),
    );
  }, []);

  const getFGReportsSuccess = (res: FGDetailedModel<KeyValueNumberModel<FunctionGroupGroupsModel>>) => {
    if (!res.is_detailed) {
      history.push(ROOTS.REPORTS.replace(':orgId', orgId).replace(':eaId', `${eaId}`));
      return;
    }
    const fg = {
      ...res,
      groups: Object.values(res.groups),
    };
    setFg(fg);
  };

  const goBack = (e: any) => {
    e.stopPropagation();
    history.go(-1);
  };

  const renderContent = () => {
    if (fg && fgSelect) {
      return (
        <div className="b-report">
          <div className="b-report__header">
            {isMobileOnly && <div className="b-report__arrow" />}
            {!isMobileOnly && (
              <HeadlineOrganization
                orgName={fg.organization_name}
                orgId={orgId}
                headline={t('main:ea.ea')}
                customClasses={'b-report__pageTitle'}
              />
            )}

            <div className="b-report__pageSubTitle">
              <Link tabIndex={0} className="b-report__backBtn" onClick={goBack}>
                <div className="back-icon" />
              </Link>
              <span>{t('main:charts.stakeholder-fg')}</span>
            </div>
          </div>

          <div className="b-report__holder">
            <div className="b-report__frame">
              <p>{fg.description}</p>

              <div className="b-reportItemList">
                {fg.groups.map(group => {
                  return (
                    <div className="b-reportItem3" key={`fg-${group.id}`}>
                      <div className="b-reportItem3__left">
                        <span className="b-reportItem3__name">{group.name.replaceAll('/', ' / ')}</span>
                        <strong className="b-reportItem3__num">
                          {typeof group.score === 'number' ? `${group.score}%` : `${group.score}`}
                        </strong>
                      </div>
                      <div className="b-reportItem3__right">
                        <p>{group.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <Loader fitParent />;
  };

  return (
    <>
      <main className={'pageContainer pageContainer__content'}>{renderContent()}</main>
    </>
  );
};

export default FunctionGroups;
