import React, { FC } from 'react';
import { Modal } from '../../../../common';
import { Button } from '../../../../controls';
import { useTranslation } from 'react-i18next';
import { DataTableFilterModel } from '../../../../../hooks/common/use-table-data';
import useGetAvailableRoles from '../../../../../hooks/beloved/adminTool/use-get-available-roles';

interface IProps {
  onClose: () => void;
  isFiltersChanged: boolean;
  handleSelectFilter: (val: number | string, fieldName: string) => void;
  handleApplyFilter: () => void;
  filterData: DataTableFilterModel;
}

const Filters: FC<IProps> = ({
  onClose,
  handleSelectFilter,
  isFiltersChanged,
  handleApplyFilter,
  filterData,
}) => {
  const { t } = useTranslation();
  const availableRoles = useGetAvailableRoles();

  const handleClose = () => {
    onClose();
  };

  const handleSaveFilter = () => {
    handleApplyFilter();
    onClose();
  };

  return (
    <>
      <Modal width={650} title={t('common:btn.filter')} onClose={handleClose}>
        <div className="b-filtersModal">
          {availableRoles ? (
            <div className="b-filtersModal__list">
              <div className="b-filtersModal__title">{t('common:filter.by-role')}</div>
              <ul>
                {availableRoles.map(role => {
                  const isActive = filterData.user_roles?.includes(role.value as number);
                  return (
                    <li key={`filter-role-${role.value}`}>
                      <a
                        className={isActive ? '-active' : ''}
                        onClick={e => {
                          e.preventDefault();
                          handleSelectFilter(role.value, 'user_roles');
                        }}
                        href="#"
                      >
                        {role.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
          <div className="b-filtersModal__list">
            <div className="b-filtersModal__title">{t('common:filter.by-status')}</div>
            <ul>
              <li>
                <a
                  className={filterData.statuses?.includes('active') ? '-active' : ''}
                  onClick={e => {
                    e.preventDefault();
                    handleSelectFilter('active', 'statuses');
                  }}
                  href="#"
                >
                  {t('common:status.active')}
                </a>
              </li>
              <li>
                <a
                  className={filterData.statuses?.includes('deactivated') ? '-active' : ''}
                  onClick={e => {
                    e.preventDefault();
                    handleSelectFilter('deactivated', 'statuses');
                  }}
                  href="#"
                >
                  {t('common:status.deactivated')}
                </a>
              </li>
              <li>
                <a
                  className={filterData.statuses?.includes('pending') ? '-active' : ''}
                  onClick={e => {
                    e.preventDefault();
                    handleSelectFilter('pending', 'statuses');
                  }}
                  href="#"
                >
                  {t('common:status.pending')}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="b-filtersModal__buttons">
          <Button
            disabled={!isFiltersChanged}
            size={'large'}
            title={t('common:btn.filter')}
            onPress={handleSaveFilter}
          />
        </div>
      </Modal>
    </>
  );
};

export default Filters;
