import { all, call, StrictEffect, takeLatest } from 'redux-saga/effects';
import Api from '../../services/api';
import {
  DELETE_TASK,
  DeleteTaskPayload,
  GET_TASKS,
  GetTasksPayload,
  UPDATE_TASK,
  UpdateTaskPayload,
} from './taskActions';
import { ActionModel } from '../../models';

function* getTaskSaga({
  payload,
}: ActionModel<typeof GET_TASKS, GetTasksPayload>): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(Api.get, 'task_notifications/list/', payload.data);
    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e: any) {
    payload.callback && payload.callback(null, e);
  }
}

function* updateTaskSaga({
  payload,
}: ActionModel<typeof UPDATE_TASK, UpdateTaskPayload>): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(Api.post, 'task_notifications/update/', payload.data);
    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e: any) {
    payload.callback && payload.callback(null, e);
  }
}

function* deleteTaskSaga({
  payload,
}: ActionModel<typeof DELETE_TASK, DeleteTaskPayload>): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(Api.post, 'task_notifications/remove/', payload.data);
    payload.callback && payload.callback(response || {});
  } catch (e: any) {
    payload.callback && payload.callback(null, e);
  }
}

export function* saga() {
  yield all([takeLatest(UPDATE_TASK, updateTaskSaga)]);
  yield all([takeLatest(DELETE_TASK, deleteTaskSaga)]);
  yield all([takeLatest(GET_TASKS, getTaskSaga)]);
}
