import React, { useEffect, useState } from 'react';
import { EmptyLink, Loader } from '../../../controls';
import { useTranslation } from 'react-i18next';
import { useNavBack } from '../../../../hooks/common/use-nav-back';
import { useLocation } from 'react-router';
import { basicDateFormat, cutString } from '../../../../helpers';
import { BelGaItemModel } from '../../../../models';
import Api from '../../../../services/api';
import LineChart from '../../../common/LineChart/LineChart';

const BelGroupAnalysis = () => {
  const { t } = useTranslation();
  const { navBack } = useNavBack();
  const eaIds: number[] = useLocation().state;

  const [data, setData] = useState<BelGaItemModel[] | null>(null);

  useEffect(() => {
    fetchData();
  }, [eaIds]);

  const fetchData = async () => {
    const response: BelGaItemModel[] = await Api.post('beloved/equity_audit/audit_analysis/', {
      equity_audit_ids: eaIds,
    });

    if (response) {
      setData(response);
    }
  };

  const renderContent = () => {
    if (!data) return <Loader />;

    return (
      <div className={'b-customChart'}>
        <div className={'b-customChart__chart'}>
          <div className={'b-customChart__labels -w300'}>
            <ul>
              {data.map(item => {
                return (
                  <li className={`b-customChart__labelContainer -hovered`} key={`ga-${item.ea_id}`}>
                    <div className="font-weight-bold font-navy">{cutString(item.org_name, 24)}</div>
                    <div className="font-weight-normal font-navy">{basicDateFormat(item.submit_date)}</div>
                    <div className="b-customChart__labelHover ">
                      <div className="b-customChart__labelHoverContent b-panel">
                        <div className="font-weight-bold font-navy pb-2">{item.org_name}</div>
                        <div className="font-weight-normal font-grey">
                          {t('common:label.affiliation')}:&nbsp;
                          {item.affiliation || t('common:column.n-a')}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <LineChart
            data={data.map(item => ({ id: item.ea_id, standards_score_report: item.standards_score_report }))}
          />
        </div>
        <div className={'b-customChart__legend'}>
          <div className={'b-customChart__legendTitle'}>
            <span className={`b-customChart__legendDot Diversity`} />
            <span>{t('awa:M35.msg.p9')}</span>
          </div>
          <div className={'b-customChart__legendTitle'}>
            <span className={`b-customChart__legendDot Equity`} />
            <span>{t('awa:M35.msg.p14')}</span>
          </div>
          <div className={'b-customChart__legendTitle'}>
            <span className={`b-customChart__legendDot Inclusion`} />
            <span>{t('awa:M35.msg.p20')}</span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <main className="b-page">
      <div>
        <h1 className={'b-page__title'}>{t('common:tabs.ea')}</h1>
        <h2 className="b-page__title2 font-navy -backIcon">
          <EmptyLink onClick={navBack} className="b-button-icon-back" />
          {t('common:tabs.group-analysis')}
        </h2>
        <div className="b-report__holder">{renderContent()}</div>
      </div>
    </main>
  );
};

export default BelGroupAnalysis;
