import React from 'react';

function Pin({ onClick, color, size }: { color?: string; onClick?: () => void; size?: number }) {
  return (
    <div
      className={`b-mapBox__pin`}
      style={{
        background: color || 'var(--gray-60)',
        width: size || 7,
        height: size || 7,
      }}
      onClick={onClick}
    />
  );
}

export default React.memo(Pin);
