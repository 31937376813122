import {
  BelOrganizationsActionsTypes,
  BEL_GET_ORGANIZATION_DETAILS_SUCCESS,
  SET_BEL_ORGANIZATION_TEAM_ROLES,
} from './organizationsActions';
import { BelOrgDetailsModel, BelTeamAssignmentRolesModel } from '../../../models';
import { LOGOUT_SUCCESS } from '../../authData/authDataActions';

export type BelOrganizationsState = {
  currentBelOrg: BelOrgDetailsModel | null;
  orgTeamAssignmentRoles: BelTeamAssignmentRolesModel | null;
};

const initialState: BelOrganizationsState = {
  currentBelOrg: null,
  orgTeamAssignmentRoles: null,
};

const belOrganizationsReducer = (
  state: BelOrganizationsState = initialState,
  action: BelOrganizationsActionsTypes,
): BelOrganizationsState => {
  switch (action.type) {
    case LOGOUT_SUCCESS: {
      return initialState;
    }
    case BEL_GET_ORGANIZATION_DETAILS_SUCCESS: {
      return {
        ...state,
        currentBelOrg: action.payload,
      };
    }
    case SET_BEL_ORGANIZATION_TEAM_ROLES: {
      return {
        ...state,
        orgTeamAssignmentRoles: action.payload,
      };
    }
    default:
      return state;
  }
};

export default belOrganizationsReducer;
