import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, PopupPortal } from '../../../../common';
import { Button, Select } from '../../../../controls';
import { ISelectOptions } from '../../../../../models';
import { useHistory } from 'react-router';
import { ROOTS } from '../../../../../routes/routes';

interface IProps {
  availableOrgs: ISelectOptions[];
}

const CreateEwpButton: FC<IProps> = ({ availableOrgs }) => {
  const { t } = useTranslation();
  let [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  let [value, setValue] = useState<ISelectOptions | null>(null);

  const handleCreate = () => {
    if (!value) return;
    history.push(ROOTS.EWP_CREATE.replace(':orgId', `${value.value}`));
  };

  return (
    <>
      <button onClick={() => setModalOpen(true)} className="b-tableTool -addPlus -mobView -mr">
        {t('common:tabs.ewp')}
      </button>
      {modalOpen ? (
        <PopupPortal portalElementId={'create-ewp-modal'}>
          <Modal
            minHeight={350}
            width={500}
            title={t('main:ewp.select-org-for-ewp')}
            onClose={() => setModalOpen(false)}
          >
            <div>
              {availableOrgs ? (
                <div className="d-flex justify-content-center mt-5 mb-5">
                  <Select
                    handleChange={(value: ISelectOptions) => setValue(value)}
                    value={value}
                    options={availableOrgs}
                    noOptionsMessage={t('awa:N40.msg')}
                  />
                </div>
              ) : null}
              <div className="b-modal__buttons">
                <Button
                  className="button-mh"
                  size={'large'}
                  type={'transparency'}
                  title={t('common:btn.cancel')}
                  onPress={() => setModalOpen(false)}
                />
                <Button
                  disabled={!value}
                  className="button-mh"
                  size={'large'}
                  title={t('common:btn.create-ewp')}
                  onPress={handleCreate}
                />
              </div>
            </div>
          </Modal>
        </PopupPortal>
      ) : null}
    </>
  );
};

export default CreateEwpButton;
