import React, { FC, useEffect, useState } from 'react';
import { Modal } from '../index';
import { CheckedOptionsModel, ISelectOptions } from '../../../models';
import { Button, Checkbox, Select } from '../../controls';
import { useTranslation } from 'react-i18next';
import Api from '../../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { setBelOrganizationTeamRolesList } from '../../../store/beloved/organizations/organizationsActions';
import { StateType } from '../../../store/reducers';

interface UserModel {
  id: number;
  user_name: string;
  org_roles: ISelectOptions[];
}

interface IProps {
  onClose: (isRefresh?: boolean) => void;
  title: string;
  value: UserModel | null;
  values: ISelectOptions[];
  handleSave: (id: number, role_type_ids: number[]) => void;
  subTitle?: string;
  bottomInfo?: string;
  noOptionsMessage?: string;
  isEdit?: boolean;
}

const BelovedTeamAssignmentModal: FC<IProps> = ({
  onClose,
  title,
  subTitle,
  values,
  handleSave: onSave,
  value,
  noOptionsMessage,
  isEdit,
}) => {
  const { t } = useTranslation();
  const [tempUsers, setTempUsers] = useState<ISelectOptions | null>();
  const orgTeamAssignmentRoles = useSelector(
    (state: StateType) => state.belOrganizations.orgTeamAssignmentRoles,
  );
  const [tempOrganizationRoles, setTempOrganizationRoles] = useState<CheckedOptionsModel[]>(
    orgTeamAssignmentRoles?.organization_roles || [],
  );
  const [tempOrganizationToolsRoles, setTempOrganizationToolsRoles] = useState<CheckedOptionsModel[]>(
    orgTeamAssignmentRoles?.assigned_with_the_tools || [],
  );

  const [initialOrganizationToolsRoles, setOrganizationToolsRoles] = useState<CheckedOptionsModel[]>([]);
  const [endAssignmentModal, setEndAssignmentModal] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!orgTeamAssignmentRoles) {
      fetchGetRolesList();
    }
    if (isEdit && value) {
      setTempUsers({
        label: value.user_name,
        value: value.id,
      });
    } else {
      setTempUsers(null);
    }
  }, []);

  useEffect(() => {
    if (orgTeamAssignmentRoles) {
      let roles = orgTeamAssignmentRoles.organization_roles.map(s => ({
        label: s.label,
        value: s.value,
        isCheck: !isEdit ? false : value?.org_roles.map(r => r.value).includes(s.value),
      }));
      let rolesTool = orgTeamAssignmentRoles.assigned_with_the_tools.map(s => ({
        label: s.label,
        value: s.value,
        isCheck: !isEdit ? false : value?.org_roles.map(r => r.value).includes(s.value),
      }));
      setTempOrganizationRoles(roles);
      setTempOrganizationToolsRoles(rolesTool);
      setOrganizationToolsRoles(rolesTool);
    }
  }, [orgTeamAssignmentRoles]);

  const fetchGetRolesList = async () => {
    const res = await Api.get(`beloved/organizations/get_organization_roles/`);
    dispatch(setBelOrganizationTeamRolesList(res));
  };

  const handleSelect = (selectedValues: ISelectOptions) => {
    setTempUsers(selectedValues);
  };

  const handleChangeRoles = (isCheck: boolean, index: number) => {
    const data = [...tempOrganizationRoles];
    data[index] = {
      ...data[index],
      isCheck: isCheck,
    };
    setTempOrganizationRoles(data);
  };

  const handleSave = async () => {
    if (!tempUsers) {
      return;
    }
    const data = {
      id: Number(tempUsers.value),
      role_type_ids: [
        ...tempOrganizationRoles.filter(f => f.isCheck).map(f => Number(f.value)),
        ...tempOrganizationToolsRoles.filter(f => f.isCheck).map(f => Number(f.value)),
      ],
    };
    onSave(data.id, data.role_type_ids);
  };

  const handleRemoveDependency = (isCheck: boolean, index: number) => {
    const data = [...tempOrganizationToolsRoles];
    data[index] = {
      ...data[index],
      isCheck: isCheck,
    };
    setTempOrganizationToolsRoles(data);
  };

  const onEndAssignment = async () => {
    const response = await Api.delete('beloved/org_assignments/end_assignments/', undefined, {
      ids: [value?.id],
    });
    if (response) {
      onClose(true);
    }
  };

  return (
    <Modal title={title} onClose={onClose} classNameBack={'b-modal__assignBelUser'}>
      <div className={'b-EAModal pb-3'}>
        <div className={'b-step__teamModal__list'}>
          <Select
            value={tempUsers}
            placeholder={subTitle}
            ariaLabel="Ethnicity"
            options={values || []}
            handleChange={value => handleSelect(value)}
            noOptionsMessage={noOptionsMessage}
            disabled={isEdit}
          />
        </div>
        <div className={'b-step__teamModal__list'}>
          <div className="b-step__subTitle mb-4 bold ml-3">{t('main:belovedTeam.organization-roles')}</div>
          {tempOrganizationRoles.map((role, roleIndex) => {
            return (
              <label
                key={`org-role-${role.value}`}
                className="pb-3 d-flex size mb-2 justify-content-between b-adminToolRoles__permItem"
                htmlFor={`check-${role.value}`}
              >
                <div className="flex-shrink-1 b-step__subTitle">{role.label}</div>
                <div className="pl-2">
                  <Checkbox
                    noText
                    checked={!!role.isCheck}
                    id={`check-${role.value}`}
                    onChange={e => handleChangeRoles(e.target.checked, roleIndex)}
                  />
                </div>
              </label>
            );
          })}
        </div>
        {isEdit ? (
          <div className={'b-step__teamModal__list'}>
            <div className="b-step__subTitle mb-4 bold ml-3">Assigned within the Tools</div>
            {tempOrganizationToolsRoles.map((role, roleIndex) => {
              const isDisableAction = !initialOrganizationToolsRoles.find(f => f.value === role.value)
                ?.isCheck;
              return (
                <label
                  key={`org-role-${role.value}`}
                  className="pb-3 d-flex size mb-2 justify-content-between b-adminToolRoles__permItem"
                  htmlFor={`actions-check-${role.value}`}
                >
                  <div className="flex-shrink-1 b-step__subTitle">{role.label}</div>
                  <div className="pl-2">
                    <Checkbox
                      noText
                      checked={!!role.isCheck}
                      id={`actions-check-${role.value}`}
                      onChange={e => handleRemoveDependency(e.target.checked, roleIndex)}
                      disabled={isDisableAction}
                    />
                  </div>
                </label>
              );
            })}
          </div>
        ) : null}
      </div>
      <div className="b-EAModal__buttons pb-5">
        <Button
          size="middle"
          className="button-mh"
          type={'transparency'}
          onPress={onClose}
          title={t('common:btn.cancel')}
        />
        {isEdit ? (
          <Button
            size="middle"
            className="button-mh"
            type={'orange-light'}
            onPress={() => setEndAssignmentModal(true)}
            title={t('common:btn.delete')}
            disabled={!tempUsers}
            enableEnter
          />
        ) : null}
        <Button
          size="middle"
          className="button-mh"
          type={'orange-light'}
          onPress={handleSave}
          title={t('common:btn.save')}
          disabled={!tempUsers}
          enableEnter
        />
      </div>
      {endAssignmentModal ? (
        <Modal minHeight={300} onClose={() => setEndAssignmentModal(false)}>
          <>
            <div className={`b-modal`}>
              <p className={'simple-text text-center'}>{t('awa:N79.title')}</p>
              <div className={'d-flex justify-content-center mt-5'}>
                <p className={'simple-text text-center'}>{t('awa:N79.msg')}</p>
              </div>
            </div>
            <div className="b-modal__buttons">
              <Button
                onPress={() => setEndAssignmentModal(false)}
                title={t('common:btn.cancel')}
                size={'large'}
                type={'transparency'}
              />
              <Button
                onPress={() => onEndAssignment()}
                title={t('common:btn.yes')}
                size={'large'}
                type={'orange-light'}
              />
            </div>
          </>
        </Modal>
      ) : null}
    </Modal>
  );
};

export default BelovedTeamAssignmentModal;
