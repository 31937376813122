import { call, all, take, StrictEffect, put, throttle } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as actionTypes from './adminToolActions';
import Api from '../../../services/api';
import { ActionModel } from '../../../models';
import { t } from 'i18next';

function* belAtGetRolesSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    yield take(actionTypes.BEL_AT_GET_ROLES);
    try {
      const response = yield call(Api.get, `beloved/admin_tool/roles_func/`);

      if (response) {
        yield put(
          actionTypes.belAtGetRolesSuccess({
            roles: response.result,
            permissions: response.permissions,
          }),
        );
      }
    } catch (e) {
      console.log('message', e);
    }
  }
}

function* belAtCreateRoleSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { name, callback },
    }: { payload: actionTypes.BelAtCreateRolePayload } = yield take(actionTypes.BEL_AT_CREATE_ROLE);
    try {
      const response = yield call(Api.post, `beloved/admin_tool/roles_func/`, {
        label: name,
      });

      if (response) {
        yield put(actionTypes.belAtCreateRoleSuccess(response));
        callback && callback();
      }
    } catch (e: any) {
      if (e.response && e.response.status === 400) {
        if (e.response.data?.label) {
          toast.error(e.response.data.label[0]);
        }
      } else {
        toast.error(t('common:toast.error'));
      }
    }
  }
}

function* belAtUpdateRoleSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: {
        roleId,
        data: { label, permissions },
        callback,
      },
    }: { payload: actionTypes.BelAtUpdateRolePayload } = yield take(actionTypes.BEL_AT_UPDATE_ROLE);

    let data: actionTypes.BelAtUpdateRolePayload['data'] = {};
    if (label) data.label = label;
    if (permissions) data.permissions = permissions;

    try {
      const response = yield call(Api.patch, `beloved/admin_tool/roles_func/${roleId}/`, undefined, data);

      if (response) {
        callback && callback();
        yield put(actionTypes.belAtUpdateRoleSuccess(response));
        toast.success(t('common:toast.role-updated'));
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* belAtGetUsersSaga({
  payload: { callback, params },
}: ActionModel<typeof actionTypes.BEL_AT_GET_USERS, actionTypes.BelAtGetUserPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  try {
    const response = yield call(Api.get, `beloved/admin_tool/beloved_users/`, params);

    if (response) {
      yield put(actionTypes.belAtGetUsersSuccess(response));
      callback && callback();
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* belAtGetOrgUsersSaga({
  payload: { callback, params },
}: ActionModel<typeof actionTypes.BEL_AT_GET_ORG_USERS, actionTypes.BelAtGetOrgUserPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  try {
    const response = yield call(Api.get, `beloved/admin_tool/organization_users/`, params);

    if (response) {
      yield put(actionTypes.belAtGetOrgUsersSuccess(response));
      callback && callback();
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* belAtGetAvailableRolesSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback },
    }: { payload: actionTypes.BelAtGetAvailableRolesPayload } = yield take(
      actionTypes.BEL_AT_GET_AVAILABLE_ROLES,
    );
    try {
      const response = yield call(Api.get, `beloved/admin_tool/beloved_users/available_roles/`);
      if (response) {
        callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* belAtCreateUserSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: { payload: actionTypes.BelAtCreateUserPayload } = yield take(actionTypes.BEL_AT_CREATE_USER);

    const userData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      user_roles_ids: data.roles.map(role => role.value),
      is_beloved_leader: false,
    };

    try {
      const response = yield call(Api.post, `beloved/admin_tool/beloved_users/`, userData);

      if (response) {
        callback();
      }
    } catch (e: any) {
      if (e.response.status) {
        callback(e.response.data[0]);
      } else {
        toast.error(t('common:toast.error'));
      }
    }
  }
}

function* belAtUpdateUserSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback, userId },
    }: { payload: actionTypes.BelAtUpdateUserPayload } = yield take(actionTypes.BEL_AT_UPDATE_USER);

    const userData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      user_roles_ids: data.roles.map(role => role.value),
      is_beloved_leader: false,
    };

    try {
      const response = yield call(
        Api.patch,
        `beloved/admin_tool/beloved_users/${userId}/`,
        undefined,
        userData,
      );

      if (response) {
        callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* belAtDeleteUserSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { usersIds, isOrgUser, callback },
    }: { payload: actionTypes.BelAtDeleteUsersPayload } = yield take(actionTypes.BEL_AT_DELETE_USERS);

    const url = isOrgUser
      ? 'beloved/admin_tool/organization_users/remove/'
      : 'beloved/admin_tool/beloved_users/remove/';
    const data = isOrgUser ? { user_ids: usersIds } : { beloved_team_ids: usersIds };
    try {
      const response = yield call(Api.post, url, data);

      if (response) {
        callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* belAtDeactivateUserSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { usersIds, isOrgUser, callback, is_active },
    }: { payload: actionTypes.BelAtDeactivateUsersPayload } = yield take(actionTypes.BEL_AT_DEACTIVATE_USERS);

    const url = isOrgUser
      ? 'beloved/admin_tool/organization_users/deactivate/'
      : 'beloved/admin_tool/beloved_users/deactivate/';
    const data = isOrgUser ? { user_ids: usersIds, is_active } : { beloved_team_ids: usersIds, is_active };

    try {
      const response = yield call(Api.post, url, data);

      if (response) {
        callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* belCreatePmSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, orgId, callback },
    }: { payload: actionTypes.BelCreatePmPayload } = yield take(actionTypes.BEL_CREATE_PM);
    try {
      const response = yield call(Api.post, `beloved/organizations/${orgId}/add_pm/`, {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        user_type_id: data.user_type.value,
      });

      if (response) {
        callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* belChangePmActivitySaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data, orgId },
    }: { payload: actionTypes.BelChangePmActivity } = yield take(actionTypes.BEL_CHANGE_PM_ACTIVITY);

    try {
      const response = yield call(Api.post, `beloved/organizations/${orgId}/deactivate_pm/`, {
        pm_teams: data,
      });

      if (response) {
        callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

export function* saga() {
  yield all([
    throttle(400, actionTypes.BEL_AT_GET_USERS, belAtGetUsersSaga),
    throttle(400, actionTypes.BEL_AT_GET_ORG_USERS, belAtGetOrgUsersSaga),
    belAtGetRolesSaga(),
    belAtCreateRoleSaga(),
    belAtUpdateRoleSaga(),
    belAtGetAvailableRolesSaga(),
    belAtCreateUserSaga(),
    belAtUpdateUserSaga(),
    belAtDeleteUserSaga(),
    belAtDeactivateUserSaga(),
    belCreatePmSaga(),
    belChangePmActivitySaga(),
  ]);
}
