import React, { useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import ProfileContainer, { IProfileFields } from '../YourProfile/ProfileContainer';
import OfficeAddressContainer, { IAddressFields } from '../YourOfficeAddress/OfficeAddressContainer';
import RoleContainer, { IRoleFields } from '../YourRole/RoleContainer';
import {
  getUserSuccess,
  GetUserSuccessActionModel,
  updateProfile,
} from '../../../../store/profile/profileActions';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import { CreateProfileExistingContainer } from '../../index';
import { ROOTS } from '../../../../routes/routes';

export interface IFields {
  phone_number?: string;
  role: {
    title: string;
    division: string;
    phone_number?: string;
  };
  user_type: number;
  language: string;
  gender: (string | number)[];
  pronouns_id: string | null;
  race: (string | number)[];
  ethnicity: (string | number)[];
  education: string | number | null;
  office_type: string | null;
  office_address: {
    country: string;
    state: string;
    city: string;
    postal: string;
    address: string;
    suite: string;
  } | null;
}

const initialFields: IFields = {
  user_type: 0,
  role: {
    title: '',
    division: '',
    phone_number: '',
  },
  language: 'en',
  gender: [],
  pronouns_id: null,
  race: [],
  ethnicity: [],
  education: null,
  office_type: null,
  office_address: {
    country: '',
    state: '',
    city: '',
    postal: '',
    address: '',
    suite: '',
  },
};

const CreateProfileContainer = () => {
  const history = useHistory();
  let { path } = useRouteMatch();
  const [fields, setFields] = useState<IFields>(initialFields);
  const user = useSelector((state: StateType) => state.profile.authUser);
  const dispatch = useDispatch();
  const handleChange =
    (field: string) => (value: string | IRoleFields | IProfileFields | IAddressFields | IFields) => {
      switch (field) {
        case 'profile':
          setFields({ ...fields, ...(value as any) });
          break;
        default:
          setFields({ ...fields, [field]: value });
      }
    };

  useEffect(() => {
    if (user && user.user) {
      setFields(prevState => {
        let s = {
          ...prevState,
          office_address: (user?.orgs_role && user.orgs_role[0].address) || null,
        };
        if (user.user.division) {
          s.role.division = user.user.division;
        }
        if (user.user.title) {
          s.role.title = user.user.title;
        }
        return s;
      });
    }
  }, [user]);

  const handleSubmitProfile = (profile: IFields) => {
    if (user?.user?.notification?.need_phone_number) {
      profile.phone_number = profile.role.phone_number;
    }
    const body = {
      id: user ? user.user.id : 0,
      user: profile,
    };
    dispatch(
      updateProfile({
        callback: event => {
          if (event && !event.details) {
            history.push(`${path.replace(':step', 'success')}`);
          }
        },
        data: body,
      }),
    );
  };

  const onSubmit = () => {
    localStorage.removeItem('next_step');
    const userData = { ...user };
    userData.login = true;
    dispatch(getUserSuccess(userData as GetUserSuccessActionModel));
    history.push(ROOTS.HOME_ORG);
  };

  const currentRoot = history.location.pathname.split('/')[2];

  if (currentRoot === 'role') {
    return <RoleContainer onChange={handleChange} fields={fields} />;
  }

  if (currentRoot === 'profile') {
    return <ProfileContainer onChange={handleChange} fields={fields} />;
  }

  if (currentRoot === 'address') {
    return <OfficeAddressContainer onSubmit={handleSubmitProfile} onChange={handleChange} fields={fields} />;
  }

  if (currentRoot === 'success') {
    return <CreateProfileExistingContainer onNavigate={onSubmit} />;
  }

  return null;
};

export default CreateProfileContainer;
