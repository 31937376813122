import { UtilsFilterModel } from '../../models';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFilters } from '../../store/utils/utilsActions';
import { StateType } from '../../store/reducers';

export const useFilters = () => {
  const dispatch = useDispatch();
  const filterSelector = useSelector((state: StateType) => state.utils.filters);
  const [filterOptions, setFilterOptions] = useState<UtilsFilterModel | null>(filterSelector);

  const fetchFilterOption = () => {
    dispatch(
      getFilters({
        callback: response => {
          if (response) {
            setFilterOptions(response);
          }
        },
      }),
    );
  };

  useEffect(() => {
    if (!filterSelector) {
      fetchFilterOption();
    }
  }, []);

  return {
    filterOptions,
  };
};
