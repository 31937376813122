import { t } from 'i18next';

export const getDefaultValidatorNames = () => ({
  username: t('common:label.email'),
  password: t('common:label.password'),
  new_pass: t('common:label.password'),
  first_name: t('common:label.first-name'),
  last_name: t('common:label.last-name'),
  website: t('common:label.website'),
  type: t('common:label.type'),
  division: t('common:label.division/department/unit'),
  address: t('common:label.address'),
  country: t('common:label.country'),
  state: t('common:label.us.state'),
  city: t('common:label.us.city'),
  postal: t('common:label.us.postal'),
  suite: t('common:label.us.suite'),
  name: t('common:label.organization'),
  secondary_email: t('common:label.secondary-email-address'),
  gender: t('common:label.gender-identity'),
  race: t('common:label.race'),
  education: t('common:label.education'),
  ethnicity: t('common:label.ethnicity'),
  gender_data: t('common:label.gender-identity'),
  race_data: t('common:label.race'),
  education_data: t('common:label.education'),
  ethnicity_data: t('common:label.ethnicity'),
  language: t('common:label.language'),
  title: t('common:label.official-organization-title'),
  pronouns_data: t('common:label.pronouns'),
  org_name: t('common:label.organization'),
  pm_email: t('common:label.pm-email'),
  org_id: t('common:label.organization'),
  type_id: t('common:label.user-type'),
  role_id: t('common:label.user-role'),
  pronouns_id: t('common:label.pronouns'),
  activity_deck: t('common:label.activity_deck'),
  end_date: t('common:column.end-date'),
  start_date: t('common:column.start-date'),
  unlock_date: t('common:label.unlock-session-date'),
  meeting_link: t('common:label.meeting-link'),
  deck_link: t('common:label.session-deck'),
  post_work: t('common:label.post-work'),
  pre_work: t('common:label.pre-work'),
  reflection_prompts: t('common:label.reflection-prompts'),
  phone_number: t('common:label.phone-number'),
});
