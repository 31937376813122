import React, { useEffect, useState } from 'react';
import { ImageUploader } from '../../../common';
import styles from './MyProfile.module.scss';
import { Select, Input, Button, Editor } from '../../../controls';
import MyAccountTabs from '../../../common/MyAccountTabs/MyAccountTabs';
import { AuthUserModel, KeyValueModel, ProfileModel, UploadFileModel } from '../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import { isEmptyObject, sanitizedHTML, validate } from '../../../../helpers';
import { getProfile, getUser, updateProfile } from '../../../../store/profile/profileActions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface IState {
  errors: KeyValueModel<string>;
  profile: ProfileModel;
  initialProfile: ProfileModel;
}

const MyBiography = () => {
  const { t } = useTranslation();
  const currentUser = useSelector((state: StateType) => state.profile.authUser?.user);
  const dispatch = useDispatch();
  const pronounsList = useSelector((state: StateType) => state.utils.data.pronounsTypes);

  const initialState: IState = {
    errors: {},
    profile: {} as ProfileModel,
    initialProfile: {} as ProfileModel,
  };
  const [state, setState] = useState<IState>(initialState);
  const [initialProfile, setInitialProfile] = useState<ProfileModel | null>(null);
  const { profile, errors } = state;

  const validator = {
    required: ['pronouns_id', 'role'],
  };

  useEffect(() => {
    if (currentUser && isEmptyObject(state.profile)) {
      dispatch(
        getProfile({
          user_id: currentUser?.id,
          callback: getProfileSuccess,
        }),
      );
    }
  }, [currentUser?.id]);

  const getProfileSuccess = (profile: ProfileModel) => {
    setState(prevState => ({
      ...prevState,
      profile: profile,
    }));

    dispatch(
      getUser({
        callback: (user: AuthUserModel) => {
          if (user.user.notification.need_phone_number && !profile.phone_number) {
            setState(prevState => ({
              ...prevState,
              errors: {
                phone_number: t('common:errors.phone-number'),
              },
            }));
          }
        },
      }),
    );
    setInitialProfile(profile);
  };

  const handleChangeInput = (name: string, value: string) => {
    if (errors[name]) {
      delete errors[name];
    }
    setState(prevState => ({
      ...prevState,
      profile: {
        ...prevState.profile,
        [name]: value,
      },
    }));
  };

  const handleCancel = () => {
    dispatch(
      getProfile({
        user_id: currentUser?.id || 0,
        callback: getProfileSuccess,
      }),
    );
  };

  const handleSubmit = async () => {
    const updateData: any = {
      first_name: profile.first_name,
      email: profile.email,
      last_name: profile.last_name,
      secondary_email: profile.secondary_email || null,
      language: profile.language,
      office_type: profile.office_type,
      office_address: profile.office_address,
      gender: profile.gender_data && profile.gender_data.map(g => +g.value),
      pronouns_id: (profile.pronouns_data && profile.pronouns_data.value) || null,
      race: profile.race_data && profile.race_data.map(r => +r.value),
      ethnicity: (profile.ethnicity_data && profile.ethnicity_data?.map(e => +e.value)) || [],
      education: (profile.education_data && profile.education_data.value) || '',
      role: profile.role,
      avatar_id: profile.avatar_id,
      biography: profile.biography,
    };

    const { errors } = validate(validator, updateData);

    if (!isEmptyObject(errors)) {
      setState(prevState => ({
        ...prevState,
        errors: errors ? errors : {},
      }));
      return;
    }

    dispatch(
      updateProfile({
        callback: res => {
          if (res.id) {
            toast.success(t('common:toast.account-updated'));
            dispatch(
              getProfile({
                user_id: currentUser?.id || 0,
                callback: getProfileSuccess,
              }),
            );
          } else {
            setState(prevState => ({
              ...prevState,
              errors: res,
            }));
          }
        },
        data: {
          id: profile.user_id,
          user: updateData,
        },
      }),
    );
  };

  const getErrorField = (field: string): string => {
    if (field === 'education_data') {
      return 'education';
    }
    if (field === 'ethnicity_data') {
      return 'ethnicity';
    }
    if (field === 'gender_data') {
      return 'gender';
    }
    if (field === 'pronouns_data') {
      return 'pronouns';
    }
    if (field === 'race_data') {
      return 'race';
    }
    return '';
  };

  const onChangeSelect = (field: string) => (value: { value: string; label: string }) => {
    delete errors[getErrorField(field)];

    setState({
      ...state,
      profile: {
        ...state.profile,
        [field]: value,
      },
      errors: errors,
    });
  };

  const handleChangeTitle = (value: string) => {
    setState(prevState => ({
      ...prevState,
      profile: {
        ...prevState.profile,
        role: {
          ...prevState.profile.role,
          title: value,
        },
      },
    }));
  };

  const isBlockSaveButton = () => {
    return JSON.stringify(initialProfile) === JSON.stringify(state.profile);
  };

  const handleAvatarChange = (file: UploadFileModel | null) => {
    setState({
      ...state,
      profile: {
        ...state.profile,
        avatar: file ? file.file : null,
        avatar_id: file ? file.id : null,
      },
    });
  };

  return (
    <>
      <main className={'pageContainer pageContainer__content'}>
        <h1 className={'pageContainer__title'}>{t('common:headlines.my-account')}</h1>
        <MyAccountTabs activeItemAlias={'bio'} />
        {!isEmptyObject(profile) && (
          <div className={styles.form}>
            <ImageUploader
              placeholderText={`${profile.first_name[0]}${profile.last_name[0]}`}
              photo={profile.avatar}
              onChange={handleAvatarChange}
              type={'profile'}
            />
            <div className={styles.form__part__bio}>
              <Select
                value={profile.pronouns_data}
                placeholder={t('common:label.pronouns')}
                ariaLabel="Pronouns"
                options={pronounsList}
                error={errors.pronouns || errors.pronouns_id}
                handleChange={onChangeSelect('pronouns_data')}
              />
              <Input
                name={'title'}
                error={''}
                value={profile.role.title}
                type={'text'}
                placeholder={t('common:label.my-title')}
                ariaLabel="My Title"
                handleChange={value => handleChangeTitle(value)}
              />
              <div className="font-m mb-4 font-navy">{t('common:tabs.biography')}</div>
              <div className={styles.infinityEditor}>
                <Editor
                  onChange={value => handleChangeInput('biography', value)}
                  defaultValue={sanitizedHTML(initialProfile?.biography || '')}
                  placeholder={t('common:tabs.biography')}
                />
              </div>
            </div>
            <div className={styles.form__buttons}>
              <Button
                onPress={handleCancel}
                title={t('common:btn.cancel')}
                size={'small'}
                type={'transparency'}
                disabled={isBlockSaveButton()}
              />
              <Button
                onPress={handleSubmit}
                title={t('common:btn.save')}
                size={'small'}
                type={'orange-light'}
                disabled={isBlockSaveButton()}
              />
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default MyBiography;
