import React from 'react';
import styles from './HeaderStyles.module.scss';
import LogoAwa from '../../../assets/images/icons/logo-awa-dark.svg';
import Logo from '../../../assets/images/icons/logo-awa.svg';
import Burger from '../../../assets/images/icons/burger.png';
import Image from '../../controls/Image/Image';
import { useTranslation } from 'react-i18next';

interface IProps {
  type?: 'awa' | 'awa_text';
  showBurger?: boolean;
}

const HeaderView = ({ type, showBurger }: IProps) => {
  const { t } = useTranslation();
  return (
    <header className={styles.header}>
      <div>
        {showBurger && <Image width={'36px'} height={'36px'} alt={t('common:alt.burger')} source={Burger} />}
      </div>
      <div>
        {type ? (
          type === 'awa' ? (
            <Image width={'36px'} height={'36px'} alt={t('awa:AT9')} source={Logo} />
          ) : (
            <Image width={'36px'} height={'54px'} alt={t('awa:AT9')} source={LogoAwa} />
          )
        ) : (
          ''
        )}
      </div>
    </header>
  );
};

export default HeaderView;
