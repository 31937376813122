import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm/CheckoutForm';
import { useDispatch } from 'react-redux';
import { schedulePaymentIntent } from '../../../../store/payments/paymentsActions';
import { useParams } from 'react-router-dom';
import { IRouteParamsBase } from '../../../../models';
import * as stripeJs from '@stripe/stripe-js';
import {
  Appearance,
  CssFontSource,
  CustomFontSource,
} from '@stripe/stripe-js/types/stripe-js/elements-group';
import { STRIPE_PUBLIC_KEY } from '../../../../constants';
import { HeadlineOrganization } from '../../../common';
import { useSelectCurrentOrg } from '../../../../hooks/org/use-select-current-org';
import { useTranslation } from 'react-i18next';
import { useNavBack } from '../../../../hooks/common/use-nav-back';
import EmptyLink from '../../../controls/EmptyLink/EmptyLink';

const stripePromise = loadStripe(String(STRIPE_PUBLIC_KEY));

const StripeSetup = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [amount, setAmount] = useState();
  const { eaId } = useParams<IRouteParamsBase>();
  const dispatch = useDispatch();
  const currentOrg = useSelectCurrentOrg();
  const { t } = useTranslation();
  const { navBack } = useNavBack();

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (clientSecret) {
      setClientSecret(clientSecret);
      return;
    }

    dispatch(
      schedulePaymentIntent({
        data: {
          entity_id: Number(eaId),
          alias: 'equity_audit_debrief',
        },
        callback: res => {
          setClientSecret(res.result.resource.client_secret);
          setAmount(res.result.resource.amount);
        },
      }),
    );
  }, []);

  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      fontFamily: 'Rubik',
      focusOutline: 'yellow',
      colorIconTabSelected: '#ff9015',
    },
    rules: {
      '.Input': {
        border: '1px solid #b2b2b2',
      },
      '.Input:focus': {
        border: '1px solid #ff9015',
        outline: 'none',
        boxShadow: '0 0 0 1px #ff9015',
        borderRadius: '5px',
      },
      Tab: {
        border: '1px solid #b2b2b2',
      },
      '.Tab:hover': {
        border: '1px solid #b2b2b2',
        boxShadow: 'none',
      },
      '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
        border: '1px solid #ff9015',
        outline: 'none',
        boxShadow: '0 0 0 1px #ff9015',
        color: '#ff9015',
      },
    },
  };

  const fonts: Array<CssFontSource | CustomFontSource> = [
    { cssSrc: 'https://fonts.googleapis.com/css?family=Rubik|Rubik:bold' },
  ];

  const options: stripeJs.StripeElementsOptions = {
    clientSecret,
    appearance,
    fonts,
  };

  return (
    <>
      <main className={'b-page'}>
        <div className="b-EADetails b-elm">
          <div className="b-page__header">
            <HeadlineOrganization
              orgName={currentOrg?.name || ''}
              orgId={currentOrg?.id || ''}
              headline={t('main:ea.ea')}
              customClasses={'-noDesktopMb'}
            />
          </div>
          <h2 className="b-page__title2 -backIcon font-navy">
            <EmptyLink onClick={navBack} className="b-button-icon-back" />
            {t('common:headlines.debrief-purchase')}
          </h2>
          <div className={'b-payment'}>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm amount={amount} clientSecret={clientSecret} />
              </Elements>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default StripeSetup;
