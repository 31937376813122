import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { CohortStatusLabel, ImageSecured, Loader } from '../../../../controls';
import { BelCohortModel } from '../../../../../models';
import { ROOTS } from '../../../../../routes/routes';
import styles from './BelCohortListStyles.module.scss';
import { useTranslation } from 'react-i18next';
import { belGetCohortList } from '../../../../../store/cohort/cohortActions';
import { basicDateFormat } from '../../../../../helpers';
import { DOMAIN_URI } from '../../../../../constants';

const BelCohortList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [cohortList, setCohortList] = useState<BelCohortModel[] | null>(null);

  useEffect(() => {
    dispatch(
      belGetCohortList({
        callback: res => {
          setCohortList(res.result);
        },
      }),
    );
  }, []);

  const handleAddCohort = () => {
    history.push(ROOTS.BELOVED_COHORT_CREATE.replace(':id', `create`));
  };

  const navToCohort = (e: any, cohort_id: number) => {
    e.stopPropagation();
    history.push(ROOTS.BELOVED_COHORT_DETAILS.replace(':id', `${cohort_id}`));
  };

  const renderContent = () => {
    if (!cohortList) return <Loader fitParent />;

    if (!cohortList.length) {
      return <div>{t('main:cohort.no-cohort')}</div>;
    }

    if (cohortList.length) {
      return (
        <div className="b-page__cardsList">
          {cohortList.map((cohort, i) => {
            return (
              <div
                key={`cohort-${cohort.id}`}
                className={`b-panelItem ${styles.container} b-page__cardItem`}
                onClick={e => navToCohort(e, cohort.id)}
              >
                <h2 className="b-panelItem__statusContainer mb-2">
                  <div className="b-step__smallTitle">{cohort.name}</div>
                </h2>
                <div className="b-cohortBel">
                  <div className="d-flex mb-2">
                    {cohort.image?.file ? (
                      <div className="b-avatar -cohort mr-3">
                        <ImageSecured src={`${DOMAIN_URI}${cohort.image.file}`} alt={cohort.name} />
                      </div>
                    ) : null}
                    <div className="mt-2">
                      <div className="b-panelItem__title">
                        <CohortStatusLabel status={cohort.status} />
                      </div>
                      {cohort.start_date || cohort.end_date ? (
                        <div className="b-panelItem__title">
                          {cohort.start_date ? basicDateFormat(cohort.start_date) : null}
                          {(cohort.start_date && cohort.end_date && ' - ') || ''}
                          {cohort.end_date ? basicDateFormat(cohort.end_date) : null}
                        </div>
                      ) : null}
                      <div className="b-panelItem__title">{cohort.type_name}</div>
                    </div>
                  </div>
                </div>

                <div className="b-panelItem__statusContainer -createdBy">
                  <div className="b-step__smallTitle">{t('common:label.created-by')}:</div>
                  <span>{cohort.submitter?.name}</span>
                </div>
                <div className="b-panelItem__info">
                  <div className={`b-panelItem__left ${styles.left}`}>
                    {cohort.beloved_team && cohort.beloved_team.length ? (
                      <>
                        <div className="b-step__smallTitle">{t('common:column.beloved-team')}:</div>
                        <div className={`b-panelItem__title ${styles.organizations}`}>
                          {cohort.beloved_team.map((user, userIndex) => {
                            return (
                              <span key={`user-${i}-${userIndex}`}>
                                {user.first_name} {user.last_name}
                              </span>
                            );
                          })}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <>
      <main className={'b-page'}>
        <div>
          <div className={`b-page__header ${styles.header}`}>
            <h1 className="b-page__title">{t('main:cohort.cohorts')}</h1>
            <div className="b-page__controls">
              <div className="b-tableToolContainer">
                <button className="b-tableTool -addPlus -mr" onClick={handleAddCohort}>
                  {t('common:btn.new-cohort')}
                </button>
              </div>
            </div>
          </div>
          <div className="b-page__cards">{renderContent()}</div>
        </div>
      </main>
    </>
  );
};

export default BelCohortList;
