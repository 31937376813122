import React from 'react';
import { Button, Image } from '../../../controls';
import Logo from '../../../../assets/images/icons/verified.png';
import './EmailVerifiedStyle.scss';
import { Footer, Header } from '../../../common';
import Input from '../../../controls/Inputs/Input';
import { useTranslation } from 'react-i18next';

interface IProps {
  loader?: string;
  onSubmit: () => void;
  disable: boolean;
  status: boolean | null;
  onResendConfirmEmail: () => void;
  handleChangeInput: (name: string, value: string) => void;
  username: string;
  errors: any;
  subTitle: string;
}

const EmailVerifiedView = ({
  loader,
  onSubmit,
  disable,
  status,
  onResendConfirmEmail,
  handleChangeInput,
  username,
  errors,
  subTitle,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      {loader}
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        <main className={'white-container content-center-wrapper'}>
          <div className={'container-page-center'}>
            <div className={'container-row'}>
              <Image width={'190px'} height={'190px'} source={Logo} alt={t('awa:AT43')} />
            </div>
            {typeof status === 'boolean' && (
              <div className={'container-row m-0'}>
                <h1 className={'b-title blue-text m-0'}>
                  {status ? t('awa:M14.msg.p1') : t('awa:M14.msg.p2')}
                </h1>
              </div>
            )}
            {status === false ? (
              <>
                <div className={'container-row'}>
                  <span className={'text-description gray-text text-center'}>{subTitle}</span>
                </div>
                <div className={`container-row`}>
                  <div className={'input-wrapper'}>
                    <Input
                      name={'username'}
                      value={username}
                      type={'email'}
                      placeholder={t('common:label.email')}
                      ariaLabel="Email"
                      error={errors.username}
                      handleChange={value => handleChangeInput('username', value)}
                    />
                  </div>
                </div>
              </>
            ) : (
              ''
            )}

            {status === true && (
              <div className={'container-row mt-5'}>
                <Button
                  size={'small'}
                  onPress={onSubmit}
                  title={t('common:btn.login')}
                  type={'orange'}
                  disabled={disable}
                />
              </div>
            )}
            {status === false && (
              <div className={'container-row'}>
                <Button
                  size={'small'}
                  onPress={onResendConfirmEmail}
                  title={t('common:btn.resend')}
                  type={'orange'}
                />
              </div>
            )}
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default EmailVerifiedView;
