import React from 'react';
import './RadioButton.scss';

interface IRadioButton {
  title: string;
  value: string | number;
  selectedValue: string | number;
  onChangeValue?: (event: any) => void;
  tabIndex?: number;
  labelClasses?: string;
}

const RadioButton = ({
  title,
  value,
  selectedValue,
  onChangeValue,
  tabIndex,
  labelClasses,
}: IRadioButton) => {
  return (
    <label className={`content-left container-row button-radio ${labelClasses}`}>
      <input
        type={'radio'}
        tabIndex={tabIndex}
        checked={selectedValue === value}
        value={value}
        onChange={onChangeValue}
        className="RadioButton__input"
      />
      <span className={'text-long-description'}>{` ${title}`}</span>
    </label>
  );
};

export default RadioButton;
