import React, { useEffect, useMemo, useState } from 'react';
import { DraggableHeaderRenderer } from '../../components/common/DraggableHeaderRenderer/DraggableHeaderRenderer';
import { HeaderRendererProps } from 'react-data-grid';
import Api from '../../services/api';
import { ShowColumns } from '../../components/common/DraggableHeaderRenderer/ShowColumns';
import { ColumnType, TableDataEntities } from '../../models';

interface ResultInterface<T> {
  onColumnResize: (idx: number, width: number) => void;
  columns: ColumnType<T>[];
}

interface CollectConfigInfoInterface {
  key: string;
  isShow: boolean;
  width?: number;
}

function collectConfigInfo<T>(column: ColumnType<T>[]): CollectConfigInfoInterface[] {
  const newColumns: CollectConfigInfoInterface[] = [];
  column.map(c => {
    newColumns.push({
      key: c.key,
      width: c.width,
      isShow: typeof c.isShow === 'undefined' ? true : c.isShow,
    });
  });
  return newColumns;
}

function useTableConfig<T>(
  entityName: TableDataEntities,
  initColumn: ColumnType<T>[],
  forceRerender?: any,
): ResultInterface<T> {
  const [columns, setColumns] = useState(initColumn);

  let timer: NodeJS.Timeout;
  const onColumnResize = (idx: number, width: number) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      const newColumns = [...columns];
      newColumns[idx].width = Math.round(width);
      const fetchData = async () => {
        const configInfo = collectConfigInfo<T>(newColumns);
        await Api.put(`entry/table_config/${entityName}/`, configInfo);
      };
      fetchData();
    }, 1000);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response: CollectConfigInfoInterface[] = await Api.get(`entry/table_config/${entityName}/`);
      if (response) {
        const newColumnsOrder = new Map<number | string, ColumnType<T>>();
        initColumn.map((c, i) => {
          const findColumnIndex = response.findIndex(f => f.key === c.key);
          if (findColumnIndex >= 0) {
            newColumnsOrder.set(findColumnIndex, { ...c, ...response[findColumnIndex] });
          } else {
            newColumnsOrder.set(`${i}`, c);
          }
        });
        // @ts-ignore
        const sortedAsc = new Map([...newColumnsOrder.entries()].sort((a, b) => a[0] - b[0]));
        // @ts-ignore
        setColumns([...sortedAsc.values()]);
      } else {
        setColumns(initColumn);
      }
    };
    fetchData().catch(console.error);
  }, [forceRerender]);

  const saveColumns = (columns: ColumnType<T>[]) => {
    const configInfo = collectConfigInfo<T>(columns);
    Api.put(`entry/table_config/${entityName}/`, configInfo).then();
  };

  // @ts-ignore
  const draggableColumns: ColumnType<T>[] = useMemo(() => {
    function handleColumnsReorder(sourceKey: string, targetKey: string) {
      const sourceColumnIndex = columns.findIndex(c => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex(c => c.key === targetKey);
      const reorderedColumns = [...columns];
      reorderedColumns.splice(targetColumnIndex, 0, reorderedColumns.splice(sourceColumnIndex, 1)[0]);
      setColumns(reorderedColumns);
      saveColumns(reorderedColumns);
    }

    const collectColumnsHideShow = (): ColumnType<T>[] => {
      return columns
        .filter(f => !f.disableDrag)
        .map(c => {
          return { ...c, isShow: typeof c.isShow === 'undefined' ? true : c.isShow };
        });
    };

    const onColumnsView = (newColumns: ColumnType<T>[]) => {
      const tempColumns = columns.map(t => {
        const find = newColumns.find(n => n.key === t.key);
        return find || t;
      });
      setColumns(tempColumns);
      saveColumns(tempColumns);
    };

    const onRestoreToDefault = () => {
      setColumns(initColumn);
      saveColumns(initColumn);
    };

    return columns
      .map(c => {
        if (c.isShow === false) {
          return null;
        }
        return {
          ...c,
          headerRenderer(props: React.PropsWithChildren<HeaderRendererProps<T, unknown>>) {
            if (c.key === 'tools') {
              return (
                <ShowColumns
                  options={collectColumnsHideShow()}
                  onColumnsView={onColumnsView}
                  onRestoreToDefault={onRestoreToDefault}
                />
              );
            }

            if (c.disableDrag) {
              return c.headerRenderer ? c.headerRenderer(props) : null;
            }

            return (
              <DraggableHeaderRenderer
                {...props}
                onColumnsReorder={handleColumnsReorder}
                mainContent={c.headerRenderer ? c.headerRenderer(props) : null}
              />
            );
          },
        };
      })
      .filter(f => !!f);
  }, [columns]);

  return {
    columns: draggableColumns || [],
    onColumnResize,
  };
}

export default useTableConfig;
