import React, { FC } from 'react';
import { basicDateFormat, cutString } from '../../../helpers';
import { BelResourceModel } from '../../../models';
import { DOMAIN_URI } from '../../../constants';
import { useNavToViewResource } from '../../../hooks/resources/use-nav-to-view-resource';
import { ImageSecured } from '../../controls';
import { useTranslation } from 'react-i18next';

interface IProps {
  data: BelResourceModel;
  onClick?: (data: BelResourceModel) => void;
}

const NewsBlockItem: FC<IProps> = ({ data, onClick }) => {
  const { t } = useTranslation();
  const thumbnailPath = data.thumbnail.length ? `${DOMAIN_URI}${data.thumbnail[0].file}` : null;
  const isResourceView = location.pathname.match('resource/view');
  const navToViewResource = useNavToViewResource();

  const handleClick = (e: any) => {
    e.preventDefault();
    if (onClick) {
      onClick(data);
      return;
    }
    if (isResourceView && data.docs.length) {
      window.scrollTo({ top: 0 });
    }
    navToViewResource(data);
  };
  return (
    <a onClick={handleClick} href="#" key={`news-item-${data}`} className="b-panel b-newsBlockItem">
      <div className="b-newsBlockItem__header">
        <div className="b-newsBlockItem__user">{data.submitter?.name}</div>
        <div className="b-newsBlockItem__date">
          {data.published_date ? basicDateFormat(data.published_date) : t('common:column.n-a')}
        </div>
      </div>
      {thumbnailPath ? (
        <ImageSecured className="b-newsBlockItem__image" src={thumbnailPath} alt={t('awa:AT35')} />
      ) : null}
      <div className="b-newsBlockItem__text">{cutString(data.title, thumbnailPath ? 50 : 171)}</div>
      <div className="b-newsBlockItem__extra">
        {data.description ? <span>{cutString(data.description, 230)}</span> : null}
        <div className="b-newsBlockItem__readMore">{t('common:btn.read-more')}</div>
      </div>
    </a>
  );
};

export default NewsBlockItem;
