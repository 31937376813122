import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Illus from '../../../../../assets/images/icons/illus_next_steps.png';
import cross from '../../../../../assets/images/icons/cross.svg';
import { Modal, BelovedEaTabs, HeadlineOrganization } from '../../../../common';
import { Button, Link, Loader, ModalView } from '../../../../controls';
import { Modals, ModalsModel, Standards, URLChartType } from '../../../../../constants';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ROOTS } from '../../../../../routes/routes';
import {
  ScoreModel,
  IRouteParamsBase,
  KeyValueNumberModel,
  ReportsModel,
  StandardScoreModel,
} from '../../../../../models';
import ScoreGuideModal from '../ScoreGuideModal/ScoreGuideModal';
import { useDispatch, useSelector } from 'react-redux';
import { getReports } from '../../../../../store/report/reportActions';
import { getScoreData } from '../../../../../helpers';
import styles from './ReportsStyles.module.scss';
import { StateType } from '../../../../../store/reducers';
import { useRole } from '../../../../../helpers/roles';
import { useTranslation } from 'react-i18next';

interface ILocationState {
  isBelovedApp?: boolean;
}

const Reports = () => {
  const { t } = useTranslation();
  const [modals, setModals] = useState<ModalsModel>({});
  const [reports, setReports] = useState<ReportsModel<ScoreModel[], StandardScoreModel[]> | null>(null);
  const { orgId, eaId } = useParams<IRouteParamsBase>();
  let location = useLocation<ILocationState | undefined>();
  const reportsSelect: ReportsModel<
    KeyValueNumberModel<ScoreModel>,
    KeyValueNumberModel<StandardScoreModel>
  > | null = useSelector((state: StateType) => state.report.reportsData[Number(eaId)]?.reports);
  const history = useHistory();
  const dispatch = useDispatch();
  const access = useRole();
  const hideDebriefBtn = access(['audit_contributor']);

  useEffect(() => {
    if (reportsSelect) {
      getReportsSuccess(reportsSelect);
      return;
    }
    dispatch(
      getReports({
        data: {
          eaId: Number(eaId),
        },
        callback: getReportsSuccess,
      }),
    );
  }, []);

  const getReportsSuccess = (
    data: ReportsModel<KeyValueNumberModel<ScoreModel>, KeyValueNumberModel<StandardScoreModel>>,
  ) => {
    Object.values(data.standards_score).map(m => {
      m.sub_standards = Object.values(m.sub_standards);
      return m;
    });
    data.standards_score = Object.values(data.standards_score) as StandardScoreModel[];
    data.function_groups_score = Object.values(data.function_groups_score) as ScoreModel[];
    setReports(data as ReportsModel<ScoreModel[], StandardScoreModel[]>);
  };

  const setModal = (modal: keyof ModalsModel) => {
    setModals(prevState => ({
      ...prevState,
      [modal]: !modals[modal],
    }));
  };

  const navToScore = (score: number | undefined) => {
    history.push(
      ROOTS.REPORT_SCORE.replace(':orgId', `${orgId}`)
        .replace(':eaId', `${eaId}`)
        .replace(':score', `${score}`),
    );
  };

  const navToChart = (name: string) => {
    if (isMobileOnly) {
      setModals(prevState => ({
        ...prevState,
        [Modals.isMobileChartImpossible]: true,
      }));
      return;
    }
    history.push(
      ROOTS.REPORT_CHART_BAR.replace(':orgId', `${orgId}`)
        .replace(':eaId', `${eaId}`)
        .replace(':chartId', name),
    );
  };

  const goBack = (e: any) => {
    e.stopPropagation();
    if (location.state?.isBelovedApp) {
      history.go(-1);
    } else {
      history.push(ROOTS.EQUITY_AUDIT_LIST.replace(':orgId', `${orgId}`));
    }
  };

  const navToFG = () => {
    history.push(ROOTS.REPORT_FUNCTION_GROUPS.replace(':orgId', `${orgId}`).replace(':eaId', `${eaId}`));
  };

  const handleScheduleDebrief = () => {
    history.push(ROOTS.STRIPE_SETUP.replace(':orgId', orgId).replace(':eaId', `${eaId}`), {
      prevPath: location.pathname,
    });
  };

  const renderContent = () => {
    if (reports && reportsSelect) {
      return (
        <div className="b-report">
          {isMobileOnly && (
            <div className="b-report__notification">
              <b className="b-report__notificationTitle">{t('main:reports.ea-completed.title')}</b>
              <span>{t('main:reports.ea-completed.msg')}</span>
            </div>
          )}

          <div className="b-report__header">
            {isMobileOnly && <div className="b-report__arrow" />}
            <HeadlineOrganization
              orgName={reports.organization_name}
              orgId={orgId}
              headline={t('main:ea.ea')}
            />
            {!location.state?.isBelovedApp ? (
              <h2 className="b-report__pageSubTitle">
                <Link tabIndex={0} className="b-report__backBtn" onClick={goBack}>
                  <div className="back-icon" />
                </Link>
                {reports.is_detailed ? (
                  <span>{t('main:reports.detailed-report')}</span>
                ) : (
                  <span>{t('main:reports.basic-report')}</span>
                )}
              </h2>
            ) : null}
            {location.state?.isBelovedApp ? (
              <div className="b-report__tabsWrapper">
                <BelovedEaTabs activeItemAlias="report" />
              </div>
            ) : null}
          </div>

          <div className="b-report__holder">
            <div className="b-report__frame">
              <div className="b-report__heading">
                <h3 className="b-report__headingTitle">
                  {location.state?.isBelovedApp ? t('main:reports.scores') : t('main:reports.your-scores')}
                </h3>
                <a className="b-report__headingLink" onClick={() => setModal(Modals.isScoreGuide)}>
                  {t('common:btn.score-guide')}
                </a>
                <button className="b-report__btnInfo" onClick={() => setModal(Modals.isScoreGuide)}>
                  <span className="sr-only">{t('common:btn.information')}</span>
                </button>
              </div>
              <p>{location.state?.isBelovedApp ? t('main:texts.t4') : t('main:texts.t3')}</p>
              <div className="b-reportItemList">
                {reports.standards_score.map((standard: StandardScoreModel) => {
                  const scoreData = getScoreData(standard.score);
                  return (
                    <Link
                      onClick={() => navToScore(standard.id)}
                      className="b-reportItem"
                      key={`score-${standard.id}`}
                    >
                      <span className="b-reportItem__name">{standard.name}</span>
                      <div className={'b-report__statusWrap'}>
                        <div className={`b-report__status -${scoreData.alias || 'inquiring'}`}>
                          {scoreData.label}
                        </div>
                      </div>
                      <ul className="b-reportItem__list">
                        {standard.sub_standards.map(sub => {
                          return (
                            <li key={`${Standards.diversity}-${sub.name}`}>
                              <strong className="b-reportItem__num">
                                {typeof sub.score === 'number' ? `${sub.score}%` : `${sub.score}`}
                              </strong>
                              <span>{sub.name}</span>
                            </li>
                          );
                        })}
                      </ul>
                      <div className="b-reportItem__arrow">
                        <span className="sr-only">{t('common:label.show-more')}</span>
                      </div>
                    </Link>
                  );
                })}
              </div>

              {!reports.is_detailed && (
                <>
                  <div className="b-report__heading">
                    <h3 className="b-report__headingTitle">{t('awa:M98.title')}</h3>
                  </div>

                  <div className="b-nextSteps">
                    <img
                      className="b-nextSteps__illustration"
                      width={122}
                      height={122}
                      src={Illus}
                      alt={t('awa:AT25')}
                    />
                    <div className="b-nextSteps__holder">
                      {!hideDebriefBtn && (
                        <span className="b-nextSteps__subtitle">{t('common:btn.schedule-debrief')}</span>
                      )}
                      <strong className="b-nextSteps__title">{t('awa:M98.msg.p1')}</strong>
                      {hideDebriefBtn ? <p>{t('awa:M98.msg.p2')}</p> : <p>{t('awa:M98.msg.p3')} </p>}
                      <b>{t('awa:M98.msg.p4')}</b>
                      <ul className="b-nextSteps__list">
                        <li>
                          <div className="b-nextSteps__listIcon -item2" />
                          {t('awa:M98.msg.p5')}
                        </li>
                        <li>
                          <div className="b-nextSteps__listIcon -item3" />
                          {t('awa:M98.msg.p6')}
                        </li>
                        <li>
                          <div className="b-nextSteps__listIcon -item1" />
                          {t('awa:M98.msg.p7')}
                        </li>
                      </ul>
                      {!hideDebriefBtn && (
                        <Button
                          title={t('common:btn.purchase-debrief')}
                          type={'orange-light'}
                          onPress={() => handleScheduleDebrief()}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}

              {reports.is_detailed && (
                <>
                  <div className="b-report__heading">
                    <h3 className="b-report__headingTitle">{t('main:reports.detailed-analysis')}</h3>
                  </div>

                  <div className="b-report__accordionItem">
                    <div className="b-report__accordionOpener">
                      <b className="b-report__accordionOpenerTitle">{t('main:reports.standard-chart')}</b>
                      {isMobileOnly ? (
                        <button
                          tabIndex={0}
                          className="b-reportItem__arrow"
                          onClick={() => navToChart(URLChartType.standard)}
                        >
                          <span className="sr-only">{t('common:label.show-more')}</span>
                        </button>
                      ) : (
                        <button
                          className="b-report__chartLink"
                          onClick={() => navToChart(URLChartType.standard)}
                        >
                          {t('common:btn.chart')}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="b-report__accordionItem">
                    <div className="b-report__accordionOpener">
                      <b tabIndex={0} className="b-report__accordionOpenerTitle" onClick={() => navToFG()}>
                        {t('main:charts.stakeholder-fg')}
                      </b>
                      {isMobileOnly ? (
                        <button tabIndex={0} className="b-reportItem__arrow" onClick={() => navToFG()}>
                          <span className="sr-only">{t('common:label.show-more')}</span>
                        </button>
                      ) : (
                        <button
                          className="b-report__chartLink"
                          onClick={() => navToChart(URLChartType.group)}
                        >
                          {t('common:btn.chart')}
                        </button>
                      )}
                    </div>
                    <div className="b-report__accordionContent">
                      <dl className="b-report__detailsList">
                        {reports.function_groups_score.map(score => {
                          return (
                            <div key={`fg-${score.id}`}>
                              <dt>{score.name}</dt>
                              <dd>
                                {typeof score.score === 'number' ? `${score.score}%` : `${score.score}`}
                              </dd>
                            </div>
                          );
                        })}
                      </dl>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      );
    }
    return <Loader fitParent />;
  };
  return (
    <>
      <main className={'pageContainer pageContainer__content'}>{renderContent()}</main>
      {modals.isScoreGuide && (
        <Modal width={545} showCloseBtn={false} onClose={() => setModal(Modals.isScoreGuide)}>
          <ScoreGuideModal onClose={() => setModal(Modals.isScoreGuide)} />
        </Modal>
      )}
      {modals.isMobileChartImpossible && (
        <ModalView
          isOpen={true}
          onRequestClose={() => setModal(Modals.isMobileChartImpossible)}
          customContentStyles={{
            width: '350px',
          }}
        >
          <div className={styles.modal}>
            <div className={styles.cross} onClick={() => setModal(Modals.isMobileChartImpossible)}>
              <img width={24} height={24} src={cross} alt={t('common:alt.cross')} />
            </div>
            <h2>{t('awa:M54.title')}</h2>
            <p>{t('awa:M54.msg')}</p>
          </div>
        </ModalView>
      )}
    </>
  );
};

export default Reports;
