import React, { FC } from 'react';
import { Modal, PopupPortal } from '../../index';
import { useHistory } from 'react-router';
import { Button } from '../../../controls';
import { useTranslation } from 'react-i18next';
import { ROOTS } from '../../../../routes/routes';

interface IProps {
  setPopupStatus: (status: boolean) => void;
}

const PhoneRequired: FC<IProps> = ({ setPopupStatus }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const navToProfile = () => {
    setPopupStatus(false);
    history.push(ROOTS.MY_PROFILE);
  };

  return (
    <PopupPortal portalElementId={'user-avatar-portal'}>
      <Modal
        minHeight={300}
        width={450}
        title={t('common:headlines.profile-update')}
        onClose={() => setPopupStatus(false)}
      >
        <div className={`b-modal`}>
          <p className={'simple-text text-center'}>{t('main:add-phone-number.msg.p1')}</p>
          <p className={'simple-text text-center mt-3 mb-5'}>{t('main:add-phone-number.msg.p2')}</p>
          <div className="b-modal__buttons">
            <Button onPress={navToProfile} title={t('common:btn.next')} size={'large'} type={'primary'} />
          </div>
        </div>
      </Modal>
    </PopupPortal>
  );
};

export default PhoneRequired;
