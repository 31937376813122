import { call, all, take, StrictEffect, put, throttle } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as actionTypes from './organizationsActions';
import Api from '../../../services/api';
import { t } from 'i18next';

function* belGetOrganizationDetailsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, orgId },
    }: { payload: actionTypes.BelGetOrganizationDetailsPayload } = yield take(
      actionTypes.BEL_GET_ORGANIZATION_DETAILS,
    );

    try {
      const response = yield call(Api.get, `beloved/organizations/${orgId}/details/`);

      if (response) {
        yield put(actionTypes.belGetOrganizationDetailsSuccess(response));
        callback && callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* belGetPmProfileSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, orgId },
    }: { payload: actionTypes.BelGetPmProfilePayload } = yield take(actionTypes.BEL_GET_PM_PROFILE);

    try {
      const response = yield call(Api.get, `beloved/organizations/${orgId}/pm_profile/`);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* belRemoveSubOrgSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, orgId },
    }: { payload: actionTypes.BelRemoveSubOrgPayload } = yield take(actionTypes.BEL_REMOVE_SUB_ORG);

    try {
      const response = yield call(Api.delete, `beloved/organizations/${orgId}/delete_parent_org/`);

      if (response) {
        toast.success(t('common:toast.affiliation-created'));
        callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

export function* saga() {
  yield all([belGetOrganizationDetailsSaga(), belGetPmProfileSaga(), belRemoveSubOrgSaga()]);
}
