import React, { FC, ReactNode, useEffect, useState } from 'react';
import EwpElementStatusLabel from './EwpElementStatusLabel';
import { LinkSelect, Hovered } from '../../../../controls';
import { useTranslation } from 'react-i18next';
import {
  EwpElementApprovalStatuses,
  EwpElementTypes,
  EwpElStatusHistoryItemModel,
  EwpStatuses,
  EWPTeamModel,
  ISelectOptions,
} from '../../../../../models';
import EwpStatusLabel from './EwpStatusLabel';
import Api from '../../../../../services/api';
import GoalStatusLabel from './GoalStatusLabel';
import { useEwpStatuses } from '../../../../../hooks/ewp/use-ewp-statuses';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { basicDateFormat } from '../../../../../helpers';
import { genericNotesService } from '../../../../common/GenericNotes/GenericNotes';
import { useGetTexts } from '../../../../../hooks/common/use-get-texts';

interface IProps {
  addNoteOnAStatusChanged?: boolean;
  type: EwpElementTypes;
  data: {
    approval_status: EwpElementApprovalStatuses;
    status: number;
    entityId: number;
    ewp_status_id: EwpStatuses;
    orgId: number;
    ewp_id: number;
    owners?: EWPTeamModel[];
    ewp_pms?: EWPTeamModel[];
    has_approved_parents: boolean;
    status_history: EwpElStatusHistoryItemModel[];
  };
  onApproveStatusChange: (newData: any) => void;
  onStatusChange?: (newData: any) => void;
}

const ElementStatusController: FC<IProps> = ({
  type,
  data: {
    approval_status,
    status,
    ewp_status_id,
    entityId,
    orgId,
    ewp_id,
    owners,
    ewp_pms,
    has_approved_parents,
    status_history,
  },
  onApproveStatusChange,
  onStatusChange,
  addNoteOnAStatusChanged,
}) => {
  const myUser = useSelector((state: StateType) => state.profile.authUser?.user);
  const { approvalStatusAutoNotesTexts } = useGetTexts();

  const { t } = useTranslation();
  const { ewpElementStatuses, ewpGoalStatuses } = useEwpStatuses();
  const meEwpPm = myUser && ewp_pms && Boolean(ewp_pms.find(user => user.user_id === myUser.id));
  const meOwner = myUser && owners && Boolean(owners.find(user => user.user_id === myUser.id));
  const elementCanBeStarted =
    approval_status === EwpElementApprovalStatuses.APPROVED &&
    ewp_status_id >= EwpStatuses.IN_PROGRESS &&
    has_approved_parents;

  let [availableStatuses, setAvailableStatuses] = useState<ISelectOptions[] | null>(null);

  useEffect(() => {
    if (!elementCanBeStarted) {
      fetchAvailableStatuses();
    }
  }, []);

  const fetchAvailableStatuses = () => {
    Api.get(`organizations/${orgId}/ewp/ewp_elements/available_status/${type}/${entityId}/`).then(res => {
      if (res) {
        setAvailableStatuses(res);
      }
    });
  };

  const handleChangeApproveStatus = (newStatus: ISelectOptions) => {
    Api.put(`organizations/${orgId}/ewp/ewp_elements/update_approval_status/${type}/${entityId}/`, {
      status_id: newStatus.value,
    }).then(res => {
      if (res) {
        fetchAvailableStatuses();
        onApproveStatusChange(res);
        if (addNoteOnAStatusChanged) {
          const newStatus = approvalStatusAutoNotesTexts[approval_status].find(
            item => res.approval_status === item.resultStatus,
          );
          if (genericNotesService.createNote && newStatus) {
            genericNotesService.createNote(newStatus.text, true);
          }
        }
      }
    });
  };

  const handleChangeElementStatus = (newStatus: ISelectOptions) => {
    Api.patch(`organizations/${orgId}/ewp/${ewp_id}/key_actions/${entityId}/`, undefined, {
      status: newStatus.value,
    }).then(response => {
      onStatusChange && onStatusChange(response);
      if (addNoteOnAStatusChanged) {
        if (genericNotesService.createNote) {
          genericNotesService.createNote(
            `${t('common:label.status-updated-to')}: ${response.status_name}`,
            true,
          );
        }
      }
    });
  };

  const handleChangeGoalStatus = (newStatus: ISelectOptions) => {
    Api.patch(`organizations/${orgId}/ewp/${ewp_id}/goals/${entityId}/`, undefined, {
      status: newStatus.value,
    }).then(response => {
      onStatusChange && onStatusChange(response);
      if (addNoteOnAStatusChanged) {
        if (genericNotesService.createNote) {
          genericNotesService.createNote(
            `${t('common:label.status-updated-to')}: ${response.status_name}`,
            true,
          );
        }
      }
    });
  };

  const renderApprovalLabel = () => {
    if (
      status_history.length &&
      approval_status >= EwpElementApprovalStatuses.IN_REVIEW &&
      approval_status <= EwpElementApprovalStatuses.APPROVED
    ) {
      return (
        <Hovered
          content={
            <div className="font-s font-grey">
              <div className="pb-1">
                {t('common:filter.by')} {status_history[0].updated_by}
              </div>
              <div>{basicDateFormat(status_history[0].updated_at)}</div>
            </div>
          }
        >
          <EwpStatusLabel status={approval_status} />
        </Hovered>
      );
    }
    return <EwpStatusLabel status={approval_status} />;
  };

  const renderLabel = (): ReactNode => {
    if (elementCanBeStarted) {
      if (type === EwpElementTypes.priority) {
        return renderApprovalLabel();
      }
      if (type === EwpElementTypes.goal) {
        return <GoalStatusLabel status={status} />;
      }
      return <EwpElementStatusLabel status={status} />;
    }
    return renderApprovalLabel();
  };

  const renderSelect = () => {
    if (!elementCanBeStarted && availableStatuses?.length) {
      return (
        <LinkSelect
          title={t('common:label.change-status')}
          options={availableStatuses}
          onChange={handleChangeApproveStatus}
        />
      );
    }
    if (elementCanBeStarted) {
      if (type === EwpElementTypes.key_action && (meEwpPm || meOwner)) {
        return (
          <LinkSelect
            title={t('common:label.change-status')}
            options={ewpElementStatuses}
            onChange={handleChangeElementStatus}
          />
        );
      }
      if (type === EwpElementTypes.goal && meEwpPm) {
        return (
          <LinkSelect
            title={t('common:label.change-status')}
            options={ewpGoalStatuses}
            onChange={handleChangeGoalStatus}
          />
        );
      }
    }
    return null;
  };

  return (
    <div className="d-flex">
      <div className="ml-5 mr-5">{renderLabel()}</div>
      {renderSelect()}
    </div>
  );
};

export default ElementStatusController;
