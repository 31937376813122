import React, { FC } from 'react';
import WelcomeView from './WelcomeView';

interface IProps {
  history: any;
}

const WelcomeContainer: FC<IProps> = ({ history }): JSX.Element => {
  return <WelcomeView history={history} />;
};

export default WelcomeContainer;
