import Api from '../../services/api';
import { useSelectCurrentOrg } from '../org/use-select-current-org';
import { useEffect, useState } from 'react';
import { CohortModel, IRouteParamsBase } from '../../models';
import { useParams } from 'react-router';

export const useGetOrgCohortDetails = (orgId?: string) => {
  const currentOrg = useSelectCurrentOrg();
  const { id } = useParams<IRouteParamsBase>();
  let [data, setData] = useState<CohortModel | null>(null);

  const fetchData = async () => {
    const organizationId = currentOrg?.id || orgId;
    const response = await Api.get(`organizations/${organizationId}/cohort/${id}/details_cohorts/`);
    if (response) {
      return response;
    }
  };

  useEffect(() => {
    fetchData().then(res => {
      if (res) {
        setData(res);
      }
    });
  }, [currentOrg]);

  return data;
};
