import React, { FC, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Input, Loader } from '../../../../../controls';
import { Modal } from '../../../../../common';
import { useTranslation } from 'react-i18next';
import Api from '../../../../../../services/api';
import { useHistory, useParams } from 'react-router';
import { IRouteParamsBase, KeyValueModel } from '../../../../../../models';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveAvgChart } from '../../../../../../store/avg/avgActions';
import { StateType } from '../../../../../../store/reducers';
import { ROOTS } from '../../../../../../routes/routes';

interface IProps {
  onClose: () => void;
  ea_ids: number[];
}

interface IRouteParams extends IRouteParamsBase {
  avg_id: string;
}

const UpdateGroupAverage: FC<IProps> = ({ onClose, ea_ids }) => {
  const { t } = useTranslation();
  const isBeloved = location.pathname.match('beloved');
  const { orgId, avg_id } = useParams<IRouteParams>();
  const [errors, setErrors] = useState<KeyValueModel<string>>({});
  const [loading, setLoading] = useState(false);
  const activeChart = useSelector((state: StateType) => state.avg.activeChart);
  const dispatch = useDispatch();
  const history = useHistory();
  const baseUpdateUrl = isBeloved
    ? `beloved/equity_audit/update_group_avg/${avg_id}/`
    : `organizations/${orgId}/equity_audit/group_average/${avg_id}/update_group/`;
  const baseCreateUrl = isBeloved
    ? `beloved/equity_audit/create_group_avg/`
    : `organizations/${orgId}/equity_audit/group_average/create_group/`;

  const [value, setValue] = useState<string>(activeChart?.name || '');

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const res = await Api.put(baseUpdateUrl, { ea_ids, name: value });
      setLoading(false);
      if (res) {
        toast.success(t('common:toast.avg-updated'));
        dispatch(
          setActiveAvgChart({
            ...res,
            avgChart: res.substandard_chart,
          }),
        );
        onClose();
      }
    } catch (err: any) {
      setLoading(false);
      if (err.response?.data) {
        setErrors(err.response.data);
      }
    }
  };

  const handleCreateNewAVg = async () => {
    try {
      setLoading(true);
      const res = await Api.post(baseCreateUrl, { ea_ids, name: value });
      setLoading(false);
      if (res) {
        toast.success(t('common:toast.avg-created'));
        const route = isBeloved
          ? ROOTS.BEL_GROUP_AVERAGES_CHART.replace(':id', res.id)
          : ROOTS.GROUP_AVERAGES_CHART.replace(':orgId', `${orgId}`).replace(':id', res.id);
        history.push(route, {
          ea_ids: ea_ids,
          name: value,
        });
        onClose();
      }
    } catch (err: any) {
      setLoading(false);
      if (err.response?.data) {
        setErrors(err.response.data);
      }
    }
  };

  const onChangeValue = (val: string) => {
    setErrors({});
    setValue(val);
  };

  if (loading) {
    return <Loader fitParent />;
  }

  return (
    <>
      <Modal
        width={500}
        className={!isMobile ? 'b-filtersModal__mobileModal' : ''}
        title={t('common:headlines.update-group-average')}
        onClose={onClose}
        classNameTitle="b-filtersModal__modalTitle"
      >
        <div className="b-filtersModal justify-content-center">
          <div className={'b-step__teamModal__list'}>
            <Input
              value={value}
              type="text"
              handleChange={onChangeValue}
              placeholder={t('common:label.name')}
              ariaLabel={t('common:label.name')}
              error={errors.name}
            />
          </div>
        </div>
        <div className="b-EAModal__buttons pb-5">
          <Button
            size="middle"
            className="button-mh"
            type={'orange-light'}
            onPress={handleUpdate}
            title={t('common:btn.update')}
          />
          <Button
            size="middle"
            className="button-mh"
            type={'orange-light'}
            onPress={handleCreateNewAVg}
            title={t('common:btn.save-new')}
            enableEnter
          />
        </div>
      </Modal>
    </>
  );
};

export default UpdateGroupAverage;
