import axios, { Method, ResponseType } from 'axios';
import { API_URI, OAUTH_CREDS } from '../constants';
import { logoutRequest } from '../store/authData/authDataActions';
import { globals } from '../store/globals';
import { ROOTS } from '../routes/routes';
import { DownloadFileModel, KeyValueModel } from '../models';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { ReqParamsModel } from '../hooks/common/use-table-data';
import moment from 'moment-timezone';
import dayjs from 'dayjs';

export default class Api {
  static methods = {
    GET: 'get',
    POST: 'post',
    PATCH: 'patch',
    PUT: 'put',
    DELETE: 'delete',
  };

  static composeRouteUrl(route: string) {
    if (route.startsWith('http')) {
      return route;
    }
    return `${API_URI}${route}`;
  }

  static get(route: string, params?: object, appendHeaders?: object) {
    return Api.request(route, params, undefined, Api.methods.GET, appendHeaders);
  }

  static put(route: string, data?: any, params?: object, appendHeaders?: object): Promise<any> {
    return Api.request(route, params, data, Api.methods.PUT, appendHeaders);
  }

  static patch(route: string, params?: KeyValueModel<string>, data?: object) {
    return Api.request(route, params, data, Api.methods.PATCH);
  }

  static post(route: string, data?: any, appendHeaders?: object, params?: ReqParamsModel): Promise<any> {
    return Api.request(route, params, data, Api.methods.POST, appendHeaders);
  }

  static delete(route: string, params?: object, data?: object, appendHeaders?: object) {
    return Api.request(route, params, data, Api.methods.DELETE, appendHeaders);
  }

  static async uploadFile(file: Blob, is_thumbnail: 0 | 1): Promise<any> {
    let token = localStorage.getItem('token');

    const url = Api.composeRouteUrl('files/');

    const formData = new FormData();
    formData.append('file', file);
    formData.append('is_thumbnail', String(is_thumbnail));

    let headers: object = {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'X-Requested-With': 'XMLHttpRequest',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`,
    };

    return axios.post(url, formData, { headers });
  }

  static async downloadFile(route: string, method: Method, data?: any): Promise<DownloadFileModel> {
    const url = Api.composeRouteUrl(route);
    let headers: any = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    };

    let token = localStorage.getItem('token');
    const userTimezone = dayjs.tz.guess();
    if (token) {
      headers = {
        ...headers,
        'X-AUTH': `Bearer ${token}`,
        'X-TZ': userTimezone,
      };
    }
    return axios({
      url,
      data,
      headers,
      method,
      responseType: 'blob',
    })
      .then(resp => {
        const filename = resp.headers['content-disposition'].split('filename=')[1].replaceAll('"', '');
        return {
          blob: resp.data,
          fileName: filename,
        };
      })
      .catch(err => {
        return Promise.reject(err.response || err);
      });
  }

  static async request(
    route: string,
    params: object | undefined,
    data: object | undefined,
    method: any,
    appendHeaders?: object,
    callback?: (response: any) => void,
    responseType?: ResponseType,
  ): Promise<any> {
    const url = Api.composeRouteUrl(route);
    let headers: any = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    };

    let token = localStorage.getItem('token');
    if (token) {
      headers = { ...headers, 'X-AUTH': `Bearer ${token}` };
    }
    if (appendHeaders) {
      headers = { ...headers, ...appendHeaders };
    }
    return axios({
      method,
      url,
      headers,
      params,
      data,
      responseType,
    })
      .then(resp => {
        return resp.data;
      })
      .catch(async err => {
        if (err.response && err.response.status === 401) {
          try {
            await Api.refreshToken();
            return await Api.request(route, params, data, method, appendHeaders, callback);
          } catch (err: any) {
            // if error redirect user to login page
            return Promise.reject(err.response || err);
          }
        }
        if (err.response && err.response.status === 403) {
          toast.error(err.response?.data?.detail || 'Error 403 - Forbidden');
          setTimeout(() => {
            if (globals.history && globals.store) {
              const state = globals.store.getState();
              if (state.profile.authUser?.beloved_user) {
                globals.history.replace(ROOTS.BELOVED_DASHBOARD_EA);
              } else {
                globals.history.replace(ROOTS.HOME_ORG);
              }
              location.reload();
            }
          }, 1500);
          return;
        }
        if (err.response && err.response.status === 502) {
          toast.error('Error 502 - Please try again in a few minutes');
          throw err;
        }
        if (err.response && err.response.status === 500) {
          Api.handleError(err, route);
          throw err;
        }
        if (err.response && err.response.status === 404) {
          if (globals.history) {
            globals.history.replace(ROOTS.NOT_FOUND);
            location.reload();
          }
        }
        if (callback) {
          callback(err.response || err);
        }
        throw err;
      });
  }

  static handleError(error: any, route?: string) {
    const user = globals.store?.getState().profile.authUser;
    const data = {
      user,
      route,
      error_data: error,
      is_mobile: isMobile,
      user_agent: navigator.userAgent,
      location_url: location.href,
      platform: navigator.platform,
    };
    if (route) {
      data.route = Api.composeRouteUrl(route);
    }
    Api.post('utils/set_error_log/', data);
  }

  static refreshToken = async () => {
    const auth = localStorage.getItem('refresh_token');
    if (auth) {
      const urlencoded = new URLSearchParams();
      urlencoded.append('grant_type', OAUTH_CREDS.GRANT_TYPES.REFRESH);
      urlencoded.append('client_id', OAUTH_CREDS.CLIENT_ID);
      urlencoded.append('client_secret', OAUTH_CREDS.CLIENT_SECRET);
      urlencoded.append('refresh_token', auth);

      const url = Api.composeRouteUrl('auth/token/');
      return axios({
        url: url,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XMLHttpRequest',
          'Cache-Control': 'no-cache',
        },
        data: urlencoded,
      })
        .then(async response => {
          response.data.access_token && localStorage.setItem('token', response.data.access_token);
          response.data.refresh_token && localStorage.setItem('refresh_token', response.data.refresh_token);
          return response.data;
        })
        .catch(err => {
          // @ts-ignore
          globals.store && globals.store.dispatch(logoutRequest());
          localStorage.clear();
          document.location.reload();
          return Promise.reject(err.response || err);
        });
    } else {
      // @ts-ignore
      globals.store && globals.store.dispatch(logoutRequest());
      localStorage.clear();
      return Promise.reject('refresh_token is empty!!!');
    }
  };
}
