import {
  ActionModel,
  BelResourceModel,
  BelCohortCreateModel,
  BelCohortModel,
  ISelectOptions,
  CohortMessageModel,
  PaginationModel,
  BelSessionCreateModel,
  BelSessionModel,
  BelCreateMessageModel,
  BelSessionFormModel,
  CheckedOptionsModel,
  BelParticipantModel,
  CohortUserModel,
  CohortOrganizationModel,
} from '../../models';
import { actionCreator } from '../actionCreator';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';
import { BelCohortState } from './cohortReducer';
import { QueryParamsModel, ReqParamsModel } from '../../hooks/common/use-table-data';

export interface BelGetCohortListPayload {
  callback?: (res: PaginationModel<BelCohortModel[]>) => void;
}

export const BEL_GET_COHORT_LIST = 'beloved/BEL_GET_COHORT_LIST';
export const belGetCohortList = actionCreator<BelGetCohortListPayload>(BEL_GET_COHORT_LIST);

export interface BelGetCohortOrganizationsPayload {
  data?: {
    organization_name?: string;
    organization_ids?: number[];
    cohort_id?: number;
    page?: number;
  };
  callback?: (res: PaginationModel<ISelectOptions[]>) => void;
}

export const BEL_GET_COHORT_ORG = 'beloved/BEL_GET_COHORT_ORG';
export const belGetCohortOrganizations = actionCreator<BelGetCohortOrganizationsPayload>(BEL_GET_COHORT_ORG);

export interface BelCreateCohortPayload {
  data: BelCohortCreateModel;
  callback?: (res: BelCohortModel | null, error?: any) => void;
}

export const BEL_CREATE_COHORT = 'beloved/BEL_CREATE_COHORT';
export const belCreateCohort = actionCreator<BelCreateCohortPayload>(BEL_CREATE_COHORT);

export interface BelSetSessionFieldsPayload {
  data: BelSessionFormModel | null;
}

export const BEL_SET_SESSION_FIELDS = 'beloved/BEL_SET_SESSION_FIELDS';
export const belSetSessionFields = actionCreator<BelSetSessionFieldsPayload>(BEL_SET_SESSION_FIELDS);

export interface BelSetGeneralResourceFieldsPayload {
  data: CheckedOptionsModel[] | null;
}

export const BEL_SET_GENERAL_RESOURCE_FIELDS = 'beloved/BEL_SET_GENERAL_RESOURCE_FIELDS';
export const belSetGeneralResourceFields = actionCreator<BelSetGeneralResourceFieldsPayload>(
  BEL_SET_GENERAL_RESOURCE_FIELDS,
);

export interface BelGetCohortResourcesPayload {
  data: {
    cohort_id: number;
  };
  callback?: (res: BelResourceModel[], error?: any) => void;
}

export const BEL_GET_COHORT_RESOURCES = 'beloved/BEL_GET_COHORT_RESOURCES';

export interface BelUpdateCohortPayload {
  data: BelCohortCreateModel;
  callback?: (res: BelCohortModel | null, error?: any) => void;
}

export const BEL_UPDATE_COHORT = 'beloved/BEL_UPDATE_COHORT';
export const belUpdateCohort = actionCreator<BelUpdateCohortPayload>(BEL_UPDATE_COHORT);

export interface BelUpdateCohortFieldPayload {
  data: any;
  callback?: (res: BelCohortModel | null, error?: any) => void;
}

export const BEL_UPDATE_COHORT_FIELD = 'beloved/BEL_UPDATE_COHORT_FIELD';
export const belUpdateCohortField = actionCreator<BelUpdateCohortFieldPayload>(BEL_UPDATE_COHORT_FIELD);

export interface BelGetCohortTeamPayload {
  callback?: (res: ISelectOptions[]) => void;
}

export const BEL_GET_COHORT_TEAM = 'beloved/BEL_GET_COHORT_TEAM';
export const belGetCohortTeam = actionCreator<BelGetCohortTeamPayload>(BEL_GET_COHORT_TEAM);

export interface BelGetCohortPayload {
  data: {
    id: number;
  };
  callback?: (res: BelCohortModel) => void;
}

export interface BelResetCohortPayload {
  callback?: () => void;
}

export const BEL_GET_COHORT = 'beloved/BEL_GET_COHORT';
export const getCohort = actionCreator<BelGetCohortPayload>(BEL_GET_COHORT);
export const BEL_GET_COHORT_SUCCESS = 'beloved/BEL_GET_COHORT_SUCCESS';
export const getCohortSuccess = actionCreator<BelCohortModel>(BEL_GET_COHORT_SUCCESS);
export const BEL_RESET_COHORT = 'beloved/BEL_RESET_COHORT';
export const resetCohort = actionCreator<BelResetCohortPayload>(BEL_RESET_COHORT);

export interface BelGetCohortSessionPayload {
  data: {
    id: number;
  };
  callback?: (res: BelSessionModel) => void;
}

export const BEL_GET_COHORT_SESSION = 'beloved/BEL_GET_COHORT_SESSION';
export const getCohortSession = actionCreator<BelGetCohortSessionPayload>(BEL_GET_COHORT_SESSION);

export interface BelGetCohortSessionListPayload {
  data: {
    cohort_id: number;
  };
  callback?: (res: BelSessionModel[]) => void;
}

export const BEL_GET_COHORT_SESSION_LIST = 'beloved/BEL_GET_COHORT_SESSION_LIST';
export const getCohortSessionList =
  actionCreator<BelGetCohortSessionListPayload>(BEL_GET_COHORT_SESSION_LIST);

export interface BelGetCohortMessagePayload {
  data: {
    id: number;
  };
  callback?: (res: CohortMessageModel) => void;
}

export const BEL_GET_COHORT_MESSAGE = 'beloved/BEL_GET_COHORT_MESSAGE';
export const getCohortMessage = actionCreator<BelGetCohortMessagePayload>(BEL_GET_COHORT_MESSAGE);

export interface BelGetCohortMessageListPayload {
  data: {
    cohort_id: number;
  };
  callback?: (res: CohortMessageModel[]) => void;
}

export const BEL_GET_COHORT_MESSAGE_LIST = 'beloved/BEL_GET_COHORT_MESSAGE_LIST';
export const getCohortMessageList =
  actionCreator<BelGetCohortMessageListPayload>(BEL_GET_COHORT_MESSAGE_LIST);

export interface BelGetCohortGeneralResourcePayload {
  data: {
    id: number;
  };
  callback?: (res: BelResourceModel) => void;
}

export const BEL_GET_COHORT_GENERAL_RESOURCE = 'beloved/BEL_GET_COHORT_GENERAL_RESOURCE';

export interface BelCreateCohortMessagePayload {
  data: BelCreateMessageModel;
  callback?: (res: CohortMessageModel, err?: any) => void;
}

export const BEL_CREATE_COHORT_MESSAGE = 'beloved/BEL_CREATE_COHORT_MESSAGE';
export const createCohortMessage = actionCreator<BelCreateCohortMessagePayload>(BEL_CREATE_COHORT_MESSAGE);

export interface BelUpdateCohortMessagePayload {
  data: BelCreateMessageModel;
  callback?: (res: CohortMessageModel, err?: any) => void;
}

export const BEL_UPDATE_COHORT_MESSAGE = 'beloved/BEL_UPDATE_COHORT_MESSAGE';
export const updateCohortMessage = actionCreator<BelUpdateCohortMessagePayload>(BEL_UPDATE_COHORT_MESSAGE);

export interface BelDeleteCohortMessagePayload {
  data: {
    id: number;
  };
  callback?: (res: CohortMessageModel, err?: any) => void;
}

export const BEL_DELETE_COHORT_MESSAGE = 'beloved/BEL_DELETE_COHORT_MESSAGE';
export const deleteCohortMessage = actionCreator<BelDeleteCohortMessagePayload>(BEL_DELETE_COHORT_MESSAGE);

export interface BelCreateCohortSessionPayload {
  data: BelSessionCreateModel;
  callback?: (res: BelSessionModel | null, error?: any) => void;
}

export const BEL_CREATE_COHORT_SESSION = 'beloved/BEL_CREATE_COHORT_SESSION';
export const belCreateCohortSession = actionCreator<BelCreateCohortSessionPayload>(BEL_CREATE_COHORT_SESSION);

export interface BelDeleteCohortSessionPayload {
  data: {
    id: number;
  };
  callback?: (res: any | null, error?: any) => void;
}

export const BEL_DELETE_COHORT_SESSION = 'beloved/BEL_DELETE_COHORT_SESSION';
export const belDeleteCohortSession = actionCreator<BelDeleteCohortSessionPayload>(BEL_DELETE_COHORT_SESSION);

export interface BelUpdateCohortSessionPayload {
  data: BelSessionCreateModel;
  callback?: (res: BelSessionModel | null, error?: any) => void;
}

export const BEL_UPDATE_COHORT_SESSION = 'beloved/BEL_UPDATE_COHORT_SESSION';
export const belUpdateCohortSession = actionCreator<BelUpdateCohortSessionPayload>(BEL_UPDATE_COHORT_SESSION);

export interface GetCohortOrganizationPayload {
  params: ReqParamsModel;
  extraData?: {
    cohort_id?: string;
  };
  callback?: (res: any | null, error?: any) => void;
}

export const GET_COHORT_ORGANIZATIONS = 'beloved/GET_COHORT_ORGANIZATIONS';
export const GET_COHORT_ORGANIZATIONS_SUCCESS = 'beloved/GET_COHORT_ORGANIZATIONS_SUCCESS';
export const getCohortOrganization = actionCreator<GetCohortOrganizationPayload>(GET_COHORT_ORGANIZATIONS);
export const getCohortOrganizationSuccess = actionCreator<PaginationModel<CohortOrganizationModel[]>>(
  GET_COHORT_ORGANIZATIONS_SUCCESS,
);

export interface BelAvailableResourcePayload {
  params: ReqParamsModel;
  extraData?: {
    cohort_id?: string;
    session_id?: string;
  };
  callback?: (res: any | null, error?: any) => void;
}

export const BEL_AVAILABLE_RESOURCE = 'beloved/BEL_AVAILABLE_RESOURCE';
export const BEL_AVAILABLE_RESOURCE_SUCCESS = 'beloved/BEL_AVAILABLE_RESOURCE_SUCCESS';
export const belAvailableResource = actionCreator<BelAvailableResourcePayload>(BEL_AVAILABLE_RESOURCE);
export const belAvailableResourceSuccess = actionCreator<BelAvailableResourcePayload>(
  BEL_AVAILABLE_RESOURCE_SUCCESS,
);

export interface BelCohortParticipantPayload {
  params: ReqParamsModel;
  extraData?: {
    cohort_id?: string;
    orgId?: string;
  };
  callback?: (res: any | null, error?: any) => void;
}

export const BEL_GET_COHORT_PARTICIPANT = 'beloved/BEL_GET_COHORT_PARTICIPANT';
export const BEL_GET_COHORT_PARTICIPANT_SUCCESS = 'beloved/BEL_GET_COHORT_PARTICIPANT_SUCCESS';
export const betGetCohortParticipant = actionCreator<BelCohortParticipantPayload>(BEL_GET_COHORT_PARTICIPANT);
export const betGetCohortParticipantSuccess = actionCreator<BelParticipantModel>(
  BEL_GET_COHORT_PARTICIPANT_SUCCESS,
);

export interface BelAvailableSessionResourcePayload {
  data: { session_id: string };
  callback?: (res: any | null, error?: any) => void;
}

export const BEL_AVAILABLE_SESSION_RESOURCE = 'beloved/BEL_AVAILABLE_SESSION_RESOURCE';

export interface AvailableParticipantResourcePayload {
  data: {
    organization_id: string;
    cohort_id: string;
  };
  callback?: (res: CohortUserModel[] | null, error?: any) => void;
}

export const AVAILABLE_PARTICIPANT = 'organization/AVAILABLE_PARTICIPANT';
export const availableParticipantResource =
  actionCreator<AvailableParticipantResourcePayload>(AVAILABLE_PARTICIPANT);

export interface AddParticipantPayload {
  data: {
    cohort_id: string;
    organization_id: string;
  };
  params: {
    users_id: number[];
  };
  callback?: (res: any | null, error?: any) => void;
}

export const ADD_PARTICIPANT = 'organization/ADD_PARTICIPANT';
export const addParticipant = actionCreator<AddParticipantPayload>(ADD_PARTICIPANT);

export interface DeleteParticipantPayload {
  data: {
    cohort_id: string;
    organization_id: string;
  };
  params: {
    users_id: number[];
  };
  callback?: (res: any | null, error?: any) => void;
}

export const DELETE_PARTICIPANT = 'organization/DELETE_PARTICIPANT';
export const deleteParticipant = actionCreator<DeleteParticipantPayload>(DELETE_PARTICIPANT);

export interface ChangeVisibilityParticipantPayload {
  data: {
    cohort_id: string;
    organization_id: string;
  };
  params: {
    team_ids: number[];
    is_visible: boolean;
  };
  callback?: (res: any | null, error?: any) => void;
}

export const CHANGE_VISIBILITY_PARTICIPANT = 'organization/CHANGE_VISIBILITY_PARTICIPANT';
export const changeVisibilityParticipant = actionCreator<ChangeVisibilityParticipantPayload>(
  CHANGE_VISIBILITY_PARTICIPANT,
);

//-----SET_QUERY---//
export const SET_QUERY = 'beloved-cohort-resource/SET_QUERY';
export const setQuery = actionCreator<QueryParamsModel>(SET_QUERY);

export const SET_SELECTED_COHORT_RESOURCE_IDS = 'beloved/SET_SELECTED_COHORT_RESOURCE_IDS';
export const setSelectedCohortResourceIds = actionCreator<BelCohortState['selectedResourceIds']>(
  SET_SELECTED_COHORT_RESOURCE_IDS,
);

export interface GetSelectedCohortResourceIdsPayload {
  data: { resource_ids: number[] };
  callback?: (res: ISelectOptions[] | null, error?: any) => void;
}

export const GET_SELECTED_COHORT_RESOURCE_IDS = 'beloved/GET_SELECTED_COHORT_RESOURCE_IDS';
export const getSelectedCohortResourceIds = actionCreator<GetSelectedCohortResourceIdsPayload>(
  GET_SELECTED_COHORT_RESOURCE_IDS,
);

export type CohortActionsTypes =
  | ActionModel<typeof BEL_GET_COHORT_GENERAL_RESOURCE, BelGetCohortGeneralResourcePayload>
  | ActionModel<typeof BEL_SET_SESSION_FIELDS, BelSetSessionFieldsPayload>
  | ActionModel<typeof BEL_SET_GENERAL_RESOURCE_FIELDS, BelSetGeneralResourceFieldsPayload>
  | ActionModel<typeof BEL_UPDATE_COHORT_FIELD, BelUpdateCohortFieldPayload>
  | ActionModel<typeof BEL_AVAILABLE_SESSION_RESOURCE, BelAvailableSessionResourcePayload>
  | ActionModel<typeof BEL_GET_COHORT_MESSAGE, BelGetCohortMessagePayload>
  | ActionModel<typeof BEL_GET_COHORT_MESSAGE_LIST, BelGetCohortMessageListPayload>
  | ActionModel<typeof BEL_CREATE_COHORT_MESSAGE, BelCreateCohortMessagePayload>
  | ActionModel<typeof BEL_UPDATE_COHORT_MESSAGE, BelUpdateCohortMessagePayload>
  | ActionModel<typeof BEL_DELETE_COHORT_MESSAGE, BelDeleteCohortMessagePayload>
  | ActionModel<typeof BEL_GET_COHORT_LIST, BelGetCohortListPayload>
  | ActionModel<typeof BEL_GET_COHORT_ORG, BelGetCohortOrganizationsPayload>
  | ActionModel<typeof BEL_GET_COHORT_TEAM, BelGetCohortTeamPayload>
  | ActionModel<typeof BEL_GET_COHORT, BelGetCohortPayload>
  | ActionModel<typeof BEL_GET_COHORT_SUCCESS, BelCohortModel>
  | ActionModel<typeof BEL_GET_COHORT_SESSION, BelGetCohortSessionPayload>
  | ActionModel<typeof BEL_CREATE_COHORT_SESSION, BelCreateCohortSessionPayload>
  | ActionModel<typeof BEL_UPDATE_COHORT_SESSION, BelUpdateCohortSessionPayload>
  | ActionModel<typeof BEL_DELETE_COHORT_SESSION, BelDeleteCohortSessionPayload>
  | ActionModel<typeof BEL_AVAILABLE_RESOURCE, BelAvailableResourcePayload>
  | ActionModel<typeof BEL_AVAILABLE_RESOURCE_SUCCESS, PaginationModel<BelResourceModel[]>>
  | ActionModel<typeof BEL_GET_COHORT_PARTICIPANT, BelCohortParticipantPayload>
  | ActionModel<typeof BEL_GET_COHORT_PARTICIPANT_SUCCESS, PaginationModel<BelParticipantModel[]>>
  | ActionModel<typeof GET_COHORT_ORGANIZATIONS, GetCohortOrganizationPayload>
  | ActionModel<typeof GET_COHORT_ORGANIZATIONS_SUCCESS, PaginationModel<CohortOrganizationModel[]>>
  | ActionModel<typeof SET_QUERY, BelCohortState['query']>
  | ActionModel<typeof SET_SELECTED_COHORT_RESOURCE_IDS, BelCohortState['selectedResourceIds']>
  | ActionModel<typeof GET_SELECTED_COHORT_RESOURCE_IDS, GetSelectedCohortResourceIdsPayload>
  | ActionModel<typeof BEL_RESET_COHORT, BelResetCohortPayload>
  | ActionModel<typeof LOGOUT_SUCCESS>;
