import React, { FC, useEffect, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { HeaderRendererProps, SortDirection } from 'react-data-grid';
import { Link } from '../../controls';
import DatePicker from 'react-datepicker';
import {
  convertDateToIso,
  convertDateToIsoWithTzOffset,
  getBasicDateFormatCodeForDatePicker,
} from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { DataTableDateFilterModel, DataTableFilterModel } from '../../../hooks/common/use-table-data';
import i18next from 'i18next';
import EmptyButton from '../../controls/Button/EmptyButton';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

interface IProps {
  rdgProps: HeaderRendererProps<any>;
  onDateFilterChanged: (payload: DataTableDateFilterModel) => void;
  selectedFilters?: DataTableFilterModel;
  periodFieldName: string;
  dateFilterId: string;
  dateRangeFieldName: string;
  fiscalOption?: boolean;
}

interface IState {
  from_date: Date | null;
  to_date: Date | null;
  selectedOption: string | null;
}

const TableDateFilter: FC<IProps> = ({
  rdgProps,
  onDateFilterChanged,
  dateFilterId,
  periodFieldName,
  dateRangeFieldName,
  selectedFilters,
  fiscalOption,
}) => {
  let [show, setShow] = useState(false);
  const { t } = useTranslation();

  const initialState = {
    from_date: null,
    to_date: null,
    selectedOption: null,
  };

  let [state, setState] = useState<IState>(initialState);
  const [calendarOpened, setCalendarOpened] = useState(false);

  const hasSelectedFilters = state.selectedOption || state.from_date || state.to_date;

  const getOptions = () => {
    let options = [
      {
        value: 'this_month',
        label: i18next.t('common:label.this-month'),
      },
      {
        value: 'last_month',
        label: i18next.t('common:label.last-month'),
      },
    ];
    if (fiscalOption) {
      options = [
        ...options,
        {
          value: 'this_fiscal_year',
          label: i18next.t('common:label.this-Fiscal-year'),
        },
        {
          value: 'last_fiscal_year',
          label: i18next.t('common:label.last-Fiscal-year'),
        },
      ];
    } else {
      options = [
        ...options,
        {
          value: 'this_year',
          label: i18next.t('common:label.this-year'),
        },
        {
          value: 'last_year',
          label: i18next.t('common:label.last-year'),
        },
      ];
    }
    return options;
  };

  const presetFormStore = () => {
    if (selectedFilters) {
      setState(prevState => {
        const newState = { ...prevState };
        if (selectedFilters[periodFieldName] && selectedFilters[periodFieldName][0]) {
          newState.selectedOption = selectedFilters[periodFieldName][0] as string;
        }
        if (selectedFilters[dateRangeFieldName]) {
          if (selectedFilters[dateRangeFieldName][0]) {
            newState.from_date = new Date(selectedFilters[dateRangeFieldName][0] as string);
          }
          if (selectedFilters[dateRangeFieldName][1]) {
            newState.to_date = new Date(selectedFilters[dateRangeFieldName][1] as string);
          }
        }
        return newState;
      });
    }
  };

  useEffect(() => {
    presetFormStore();
  }, [selectedFilters, periodFieldName, dateRangeFieldName]);

  const handleChangeDate = (newDate: Date | null, fieldName: string) => {
    console.log('newDate', newDate);
    setState({ ...state, [fieldName]: newDate, selectedOption: null });
  };

  const handleChangeOption = (opt: string) => {
    setState(prevState => {
      return {
        ...prevState,
        to_date: null,
        from_date: null,
        selectedOption: prevState.selectedOption === opt ? null : opt,
      };
    });
  };

  const handleClickFilters = () => {
    setShow(!show);
  };

  const onClickOutsidePopover = () => {
    if (calendarOpened) {
      return;
    }
    setShow(false);
  };

  const handleCalendarOpen = () => {
    setTimeout(() => {
      setCalendarOpened(true);
    }, 100);
  };

  const handleCalendarClose = () => {
    setTimeout(() => {
      setCalendarOpened(false);
    }, 100);
  };

  const handleCancel = () => {
    setShow(false);
    presetFormStore();
  };

  const handleSave = () => {
    onDateFilterChanged({
      [dateFilterId]: {
        [periodFieldName]: [state.selectedOption || ''],
        [dateRangeFieldName]: [
          state.from_date ? convertDateToIso(state.from_date) : '',
          state.to_date ? convertDateToIso(state.to_date) : '',
        ],
      },
    });
    setShow(false);
  };

  const renderContent = () => {
    return (
      <div className="b-panel b-tableDateFilter__modal">
        {getOptions().map(item => {
          return (
            <Link
              onClick={() => handleChangeOption(item.value)}
              key={`${item.value}-table-filter-opt`}
              className={`b-tableDateFilter__opt ${item.value === state.selectedOption ? '-active' : ''}`}
            >
              {item.label}
            </Link>
          );
        })}
        <div className="b-tableDateFilter__row d-flex justify-content-between">
          <div className="d-flex w-50">
            <div className="b-tableDateFilter__rowTitle">{t('common:label.after')}:</div>
            {state.from_date ? (
              <button
                className="b-button-icon -close"
                onClick={e => {
                  e.stopPropagation();
                  handleChangeDate(null, 'from_date');
                }}
              />
            ) : null}
            <div className="b-tableDateFilter__date">
              <DatePicker
                onCalendarOpen={handleCalendarOpen}
                onCalendarClose={handleCalendarClose}
                maxDate={state.to_date}
                dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                selected={state.from_date}
                onChange={newDate => handleChangeDate(newDate, 'from_date')}
                placeholderText={'MM.DD.YYYY'}
                ariaLabelledBy="from_date"
              />
            </div>
          </div>
          <div className="d-flex w-50">
            <div className="b-tableDateFilter__rowTitle">{t('common:label.before')}:</div>
            {state.to_date ? (
              <button
                className="b-button-icon -close"
                onClick={e => {
                  e.stopPropagation();
                  handleChangeDate(null, 'to_date');
                }}
              />
            ) : null}
            <div className="b-tableDateFilter__date">
              <DatePicker
                onCalendarOpen={handleCalendarOpen}
                onCalendarClose={handleCalendarClose}
                minDate={state.from_date}
                dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                selected={state.to_date}
                onChange={newDate => handleChangeDate(newDate, 'to_date')}
                placeholderText={'MM.DD.YYYY'}
                ariaLabelledBy="to_date"
              />
            </div>
          </div>
        </div>
        <div className="mt-2 mb-2 d-flex justify-content-center align-items-center">
          <Link onClick={handleCancel} className="orange-light-text pr-5">
            {t('common:btn.cancel')}
          </Link>
          <Link onClick={handleSave} className="orange-light-text">
            {t('common:btn.save')}
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex align-items-center" onPointerDown={e => e.stopPropagation()}>
      <Popover
        isOpen={show}
        positions={['bottom']}
        content={renderContent}
        containerClassName={'popover'}
        align={'start'}
        onClickOutside={onClickOutsidePopover}
      >
        <button
          title={'Filter'}
          aria-label={'Filter'}
          onClick={handleClickFilters}
          className={`b-button-icon -filter -w16 flex-shrink-0 mr-1 ${
            hasSelectedFilters ? '-colorOrange' : ''
          }`}
        >
          <span aria-hidden="true"></span>
        </button>
      </Popover>
      <Link onClick={() => rdgProps.onSort(false)} className="b-rdgTable__headerCell -clickable">
        {rdgProps.column.name}
        <span className={`b-tableArrow ${getArrClass(rdgProps.sortDirection)}`} />
      </Link>
    </div>
  );
};

export default TableDateFilter;
