import { SortDirection } from 'react-data-grid';
import React from 'react';
import { t } from 'i18next';
import { ColumnType, EwpUserTableElement, EwpUserTableItem } from '../../../../../../models';
import { ComaList } from '../../../../../controls';
import TableHoverCell from '../../../../../common/TableHoverCell/TableHoverCell';
import { Link } from 'react-router-dom';
import { ROOTS } from '../../../../../../routes/routes';
import { useGetEwpRoute } from '../../../../../../hooks/ewp/use-get-ewp-route';
import { convertUserStatus } from '../../../../../../helpers';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

export const getColumns = (): ColumnType<EwpUserTableItem>[] => [
  {
    key: 'name',
    name: t('common:label.name'),
    resizable: true,
    sortable: true,
    editable: false,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'title',
    name: t('common:label.title'),
    resizable: true,
    sortable: true,
    editable: false,
    formatter(props) {
      return <>{props.row.title || t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'ewp_roles',
    name: t('common:label.ewp-role'),
    resizable: true,
    sortable: false,
    editable: false,
    headerCellClass: 'b-rdgTable__headerCell',
    formatter(props) {
      return (
        <div className="b-adminToolUsers__statusCell -ellipse">
          <ComaList
            tag={'div'}
            uKey={`${props.row.user_id}-ewp_roles`}
            nullText={t('common:column.n-a')}
            arr={props.row.ewp_roles.slice(0, 3).map(roleLabel => ({ title: roleLabel }))}
          />
        </div>
      );
    },
  },
  {
    key: 'ewp_elements',
    name: t('common:label.ewp-elements'),
    resizable: true,
    sortable: false,
    width: 160,
    editable: false,
    headerCellClass: 'b-rdgTable__headerCell',
    formatter(props) {
      const renderItem = (item: EwpUserTableElement) => {
        const getRoute = () => {
          const EWP_STRATEGY_DETAILS = useGetEwpRoute(ROOTS.EWP_STRATEGY_DETAILS);
          const EWP_KA_DETAILS = useGetEwpRoute(ROOTS.EWP_KA_DETAILS);
          switch (item.element_type) {
            case 'key_action':
              return EWP_KA_DETAILS.replace(':elId', `${item.id}`);
            case 'strategy':
              return EWP_STRATEGY_DETAILS.replace(':elId', `${item.id}`);
            default:
              return '';
          }
        };

        const cssClass = () => {
          switch (item.element_type) {
            case 'key_action':
              return '-ewp_ka';
            case 'strategy':
              return '-ewp_strats';
            default:
              return '';
          }
        };
        return (
          <div className="d-flex align-items-center b-ewp__cellEl" key={`el-${item.id}-${props.row.user_id}`}>
            <div className={`b-button-icon -w12 -noHover ${cssClass()}`} />
            &nbsp;
            <Link onClick={e => e.stopPropagation()} className="orange-light-text ellipse" to={getRoute()}>
              {item.title}
            </Link>
          </div>
        );
      };

      return <TableHoverCell renderItem={renderItem} data={props.row.ewp_elements} />;
    },
  },
  {
    key: 'ewp_status',
    name: t('common:label.ewp-status'),
    resizable: true,
    isShow: false,
    sortable: true,
    editable: false,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      const {
        row: { ewp_status, ewp_status_date },
      } = props;
      return <>{ewp_status ? convertUserStatus(ewp_status, ewp_status_date) : t('common:column.n-a')}</>;
    },
  },
  {
    key: 'org_status',
    name: t('common:label.org-status'),
    resizable: true,
    isShow: true,
    sortable: true,
    editable: false,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      const {
        row: { org_status, org_status_date },
      } = props;
      return <>{org_status ? convertUserStatus(org_status, org_status_date) : t('common:column.n-a')}</>;
    },
  },
  {
    key: 'division',
    name: t('common:label.div/dept/unit'),
    resizable: true,
    isShow: false,
    sortable: true,
    editable: false,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      return <>{props.row.division || t('common:column.n-a')}</>;
    },
  },
  {
    key: 'awa_role',
    name: t('common:column.awa-role'),
    resizable: true,
    isShow: false,
    sortable: true,
    editable: false,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      return <>{props.row.awa_role || t('common:column.n-a')}</>;
    },
  },
  {
    key: 'email',
    name: t('common:label.email'),
    resizable: true,
    isShow: false,
    sortable: true,
    editable: false,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'tools',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
  },
];
