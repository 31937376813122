import React, { useEffect, useState } from 'react';
import DataGrid from 'react-data-grid';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../../controls';
import { BelOrgModel, PaginationModel, TableDataEntities } from '../../../../models';
import { getColumns } from './parts/columns';
import { Alert, McTableTool, Pagination } from '../../../common';
import { useHistory, useLocation } from 'react-router';
import { ROOTS } from '../../../../routes/routes';
import { basicDateFormat } from '../../../../helpers';
import useHasAccess from '../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { useIsDarkMode } from '../../../../hooks/common/use-is-dark-mode';
import useBulkRemoveRequest, { BulkRemoveEntities } from '../../../../hooks/common/use-bulk-remove-request';
import SortModalButton from '../../../common/SortModalButton/SortModalButton';
import useTableConfig from '../../../../hooks/common/use-table-config';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { showAlert } from '../../../common/Alert/RemoteAlert';
import { useFilters } from '../../../../hooks/filters/use-filters';
import FilterModalButton from '../../../common/FilterModalButton/FilterModalButton';
import { useTableDataV2 } from '../../../../hooks/common/use-table-data-v2';

const Organizations = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isDarkMode } = useIsDarkMode();
  const { removeEntities } = useBulkRemoveRequest();
  const location = useLocation();

  const {
    query,
    data,
    fetchData,
    handleChangePage,
    handleChangePageSize,
    handleSearch,
    handleSort,
    setSortingData,
    sortingData,
    handleChangeDirection,
    handleChangeSortField,
    fetchMoreMobile,
    selectedEntitiesIds,
    setSelectedEntitiesIds,
    handleSelectOne,
    handleSelectOneMob,
    infiniteList,
    handleApplyFilter,
    handleSelectFilter,
    filterData,
    isFiltersChanged,
  } = useTableDataV2<BelOrgModel>({
    baseUrl: 'beloved/organizations/',
    entityName: TableDataEntities.ORGANIZATIONS,
    enableSelectedEntities: true,
  });
  const { columns, onColumnResize } = useTableConfig<BelOrgModel>(
    TableDataEntities.ORGANIZATIONS,
    getColumns(),
    undefined,
  );

  const { filterOptions } = useFilters();

  const hasAccessToProfile = useHasAccess(BelovedPermissions.ORGANIZATION_PROFILE);
  const hasAccessToEa = useHasAccess(BelovedPermissions.EA_LIST);
  const hasAccessToDebrief = useHasAccess(BelovedPermissions.DEBRIEF_LIST);
  const hasAccessToRmOrg = useHasAccess(BelovedPermissions.ORGANIZATION_REMOVE_ORG);
  const hasAccessToMarketingComCreate = useHasAccess(BelovedPermissions.MARKETING_COMMUNICATION_CREATE);

  let [isDeletePopup, setIsDeletePopup] = useState(false);

  useEffect(() => {
    fetchData(query);
  }, [location.key]);

  const navToDetails = (org: BelOrgModel) => {
    if (hasAccessToProfile) {
      history.push(ROOTS.BELOVED_ORGANIZATION_DETAILS.replace(':orgId', `${org.id}`));
    } else {
      showAlert({
        title: '',
        text: t('awa:N78.msg'),
        buttons: {
          right: {
            title: 'OK',
          },
        },
      });
    }
  };

  const navToDebriefsList = (org: BelOrgModel) => (e: any) => {
    e.stopPropagation();
    history.push(ROOTS.BELOVED_DEBRIEFS, {
      org: {
        id: org.id,
        name: org.organization_name,
      },
    });
  };

  const navToEa = (org: BelOrgModel) => (e: any) => {
    e.stopPropagation();
    history.push(ROOTS.BELOVED_EQUITY_AUDITS, {
      org: {
        id: org.id,
        name: org.organization_name,
      },
    });
  };

  const handleDelete = async () => {
    removeEntities(BulkRemoveEntities.ORG, selectedEntitiesIds, () => {
      fetchData(query);
      setSelectedEntitiesIds([]);
      setIsDeletePopup(false);
    });
  };

  const renderContent = () => {
    if (!data) return <Loader fitParent />;

    return (
      <>
        <div className="-desktop">
          <DndProvider backend={HTML5Backend}>
            <DataGrid
              onRowClick={navToDetails}
              onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
              columns={columns}
              onColumnResize={onColumnResize}
              sortColumns={query.sorting ? [query.sorting] : []}
              rows={data.result}
              className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
              style={{
                height: ((data.result.length || 0) + 1) * 60,
              }}
              onSelectedRowsChange={handleSelectOne}
              rowHeight={60}
              rowKeyGetter={(row: BelOrgModel) => row.id}
              selectedRows={new Set(selectedEntitiesIds)}
            />
          </DndProvider>
          <Pagination
            data={data || ({} as PaginationModel)}
            handleChangePageSize={handleChangePageSize}
            changePage={handleChangePage}
          />
        </div>
        {isMobile && (
          <div className="-mobile b-panelTitleOutside__list">
            <InfiniteScroll
              dataLength={infiniteList.length}
              next={fetchMoreMobile}
              hasMore={!(data.current_page === data.total_pages)}
              loader={<h4>{t('common:label.loading')}...</h4>}
            >
              {infiniteList.map((org: BelOrgModel) => {
                const isChecked = !!selectedEntitiesIds.find(f => f === org.id);

                return (
                  <div key={`mob-item-${org.id}`} className="b-panelTitleOutside__container">
                    <span className="b-panelTitleOutside__name">{org.organization_name}</span>
                    <div className="b-panelTitleOutside" onClick={() => navToDetails(org)}>
                      <label className="checkbox b-at-user__check" onClick={e => e.stopPropagation()}>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={e => handleSelectOneMob(e, org.id)}
                        />
                        <span className="checkbox_fakeInput" />
                      </label>
                      <table>
                        <tr>
                          <th>{t('common:column.org-affiliation')}</th>
                          <td>{org.affiliation}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.org-type')}</th>
                          <td>{org.type}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.pm')}</th>
                          <td>{org.project_manager}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.joined-on')}</th>
                          <td>
                            {org.joined ? basicDateFormat(new Date(org.joined)) : t('common:column.n-a')}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:column.last-audit')}</th>
                          <td>
                            {org.last_audit
                              ? basicDateFormat(new Date(org.last_audit))
                              : t('common:column.n-a')}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:label.address')}</th>
                          <td>{org.address?.full_address || t('common:column.n-a')}</td>
                        </tr>
                        <tr>
                          <th>{t('common:label.website')}</th>
                          <td>
                            {org.website ? (
                              <a
                                className="b-link"
                                target="_blank"
                                rel="noreferrer"
                                href={`//${org.website}`}
                              >
                                {org.website}
                              </a>
                            ) : (
                              t('common:column.n-a')
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:label.legacy-audit-organization')}</th>
                          <td>
                            {org.legacy_audit ? (
                              <div className="d-flex h-100 align-items-center">
                                <div className="b-button-icon -checkmark" />
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      </table>
                      <div className="b-panelTitleOutside__linksList">
                        {org.has_ea && hasAccessToEa && (
                          <button type="button" onClick={navToEa(org)} className="b-panelTitleOutside__link">
                            <span className="b-button-icon -colorNavy -mh2  view" />
                            {t('common:btn.equity-audits')}
                          </button>
                        )}
                        {org.has_debrief && hasAccessToDebrief && (
                          <button
                            type="button"
                            onClick={navToDebriefsList(org)}
                            className="b-panelTitleOutside__link"
                          >
                            <span className="b-button-icon -colorNavy -mh2  edit" />
                            {t('common:btn.debriefs')}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <main className="b-page">
        <div>
          <h1 className="b-page__title">{t('common:headlines.organizations')}</h1>
          <div className="b-page__controls">
            <div className="b-tableToolContainer">
              {hasAccessToRmOrg ? (
                <button
                  onClick={() => setIsDeletePopup(true)}
                  disabled={!selectedEntitiesIds.length}
                  className="b-tableTool -delete -mobView -mr"
                >
                  {t('common:btn.delete')}
                </button>
              ) : null}
              {filterOptions ? (
                <FilterModalButton
                  query={query}
                  handleSelectFilter={handleSelectFilter}
                  handleApplyFilter={handleApplyFilter}
                  filterData={filterData}
                  isFiltersChanged={isFiltersChanged}
                  filters={filterOptions.organizations}
                />
              ) : null}
              {hasAccessToMarketingComCreate ? <McTableTool orgIds={selectedEntitiesIds} /> : null}
            </div>
            <div className="b-page__search">
              <form action="#" className="searchForm">
                <input
                  type="search"
                  value={query.search}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder={t('common:label.search')}
                  aria-label="Search field"
                />
                <span className="searchForm__icon" />
              </form>
            </div>
          </div>
          <div className="-mobile">
            <div className="b-tableToolContainer -centered">
              {/*<div className="b-equityAudits__filters">*/}
              <SortModalButton
                setSortingData={setSortingData}
                sortingData={sortingData}
                columns={columns}
                query={query}
                handleChangeSortField={handleChangeSortField}
                handleSort={handleSort}
              />
              <button
                onClick={handleChangeDirection}
                type="button"
                className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                    ${query.sorting?.direction === 'DESC' && '-active'}
                  `}
              >
                {t('common:btn.a-z-order')}
              </button>
            </div>
          </div>
        </div>
        {renderContent()}
      </main>
      {isDeletePopup ? (
        <Alert
          isOpen={isDeletePopup}
          onRequestClose={() => setIsDeletePopup(false)}
          title={t('awa:N38.title')}
          text={<p />}
          buttons={{
            left: {
              type: 'bordered',
              title: t('common:btn.cancel'),
              onClick: () => setIsDeletePopup(false),
            },
            right: {
              title: t('common:btn.delete'),
              onClick: handleDelete,
            },
          }}
        />
      ) : null}
    </>
  );
};

export default Organizations;
