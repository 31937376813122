import React, { useEffect, useState } from 'react';
import { Select, Tooltip } from '../../../../../controls';
import styles from '../../AddAffiliation.module.scss';
import { useSelector } from 'react-redux';
import { CreateOrgModel, KeyValueModel, ISelectOptions } from '../../../../../../models';
import { StateType } from '../../../../../../store/reducers';
import RadioSelect from '../../../../../controls/RadioSelect/RadioSelect';
import { isMobile } from 'react-device-detect';
import { Modal } from '../../../../../common';
import { useTranslation } from 'react-i18next';
import { getUserRoleList } from '../../../../../../helpers';

interface OrganizationModel {
  label: string;
  value: string | number;
  disabled?: boolean;
  parent_org_name?: string;
}

interface IState {
  userTypes: OrganizationModel[];
}

interface IProps {
  handleChangeSelect: (name: string, data: ISelectOptions) => void;
  formData: CreateOrgModel;
  errors: KeyValueModel<string> | null;
  constRoleId?: number;
}

const UserRoleSelect = ({ handleChangeSelect, formData, errors, constRoleId }: IProps) => {
  const { t } = useTranslation();
  const userTypesSelector = useSelector((state: StateType) => state.utils.data.userTypesRoles);

  const user = useSelector((state: StateType) => state.profile.authUser);
  const initialState: IState = {
    userTypes: [],
  };

  const [state, setState] = useState<IState>(initialState);
  const [modal, setModal] = useState<boolean>(false);
  const { userTypes } = state;

  const getUserTypes = () => {
    let userTypesSel = [...(userTypesSelector || [])];
    if (constRoleId) {
      userTypesSel = userTypesSel.filter(f => !!f.groups.find((g: any) => g.id === constRoleId));
    }
    const userTypes =
      userTypesSel.map(r => {
        return { label: r.name, value: r.id };
      }) || [];
    setState(prevState => {
      return {
        ...prevState,
        userTypes: userTypes,
      };
    });
  };

  useEffect(() => {
    getUserTypes();
  }, [userTypesSelector]);

  useEffect(() => {
    getUserTypes();
  }, [user, userTypesSelector]);

  const userRoleList = getUserRoleList(userTypesSelector, formData.type_id);

  const handleModal = () => {
    setModal(!modal);
  };

  const roleContentRender = () => {
    return (
      <div className={styles.infoPopup}>
        {!isMobile && <strong className={styles.infoPopup_title}>{t('awa:M11.msg.p1')}</strong>}
        <div className={styles.infoPopup_item}>
          <span className={styles.infoPopup_subTitle}>{t('awa:M11.msg.p2.title')}</span>
          <p>{t('awa:M11.msg.p2.msg')}</p>
        </div>
        <div className={styles.infoPopup_item}>
          <span className={styles.infoPopup_subTitle}>{t('awa:M11.msg.p3.title')}</span>
          <p>{t('awa:M11.msg.p3.msg')}</p>
        </div>
        <div className={styles.infoPopup_item}>
          <span className={styles.infoPopup_subTitle}>{t('awa:M11.msg.p4.title')}</span>
          <p>{t('awa:M11.msg.p4.msg')}</p>
        </div>
        <div className={styles.infoPopup_item}>
          <span className={styles.infoPopup_subTitle}>{t('awa:M11.msg.p5.title')}</span>
          <p>{t('awa:M11.msg.p5.msg')}</p>
        </div>
        <div className={styles.infoPopup_item}>
          <span className={styles.infoPopup_subTitle}>{t('awa:M11.msg.p6.title')}</span>
          <p>{t('awa:M11.msg.p6.msg')}</p>
        </div>
        <div className={styles.infoPopup_item}>
          <span className={styles.infoPopup_subTitle}>{t('awa:M11.msg.p7.title')}</span>
          <p>{t('awa:M11.msg.p7.msg')}</p>
        </div>
        <div className={styles.infoPopup_item}>
          <span className={styles.infoPopup_subTitle}>{t('awa:M11.msg.p8.title')}</span>
          <p>{t('awa:M11.msg.p8.msg')}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.form}>
        <div className={styles.form__part}>
          <Select
            value={(formData.type_id && userTypes.find(f => f.value === formData.type_id)) || null}
            placeholder={t('common:label.user-type')}
            ariaLabel="User type"
            options={userTypes.filter(f => f.value !== 1) || []}
            error={(errors && errors.type_id) || ''}
            handleChange={(value: ISelectOptions) => handleChangeSelect('type_id', value)}
            height={52}
          />
        </div>
      </div>
      {!constRoleId ? (
        <div className={styles.form__additional}>
          <div className={styles.form__additionalCol}>
            <div className={styles.form__suborganization}>
              <div className={styles.form__suborganization_opener}>
                <span>User Role</span>
                {!isMobile ? (
                  <Tooltip
                    title={roleContentRender()}
                    place={'right'}
                    customProps={{
                      overridePosition: (position: { left: number; top: number }) => {
                        return { left: position.left, top: 0 };
                      },
                    }}
                  >
                    <div className={styles.toolsPanel_button}>
                      <span
                        className={`${styles.toolsPanel__icon} ${styles.toolsPanel__icon_info_outline}`}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <button className={styles.toolsPanel_button} onClick={handleModal}>
                    <span className={`${styles.toolsPanel__icon} ${styles.toolsPanel__icon_info_outline}`} />
                  </button>
                )}
              </div>
              <div className={'error-notification'}>
                <span className={'error-notification-text'}>{errors?.role_id}</span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className={`${styles.form} ${styles.container_wrapper}`}>
        {!constRoleId ? (
          <div className={styles.form__part}>
            {userRoleList.length ? (
              <RadioSelect
                value={(formData.role_id && userRoleList.find(f => f.value === formData.role_id)) || null}
                handleChange={(value: ISelectOptions) => handleChangeSelect('role_id', value)}
                values={userRoleList}
                hideDescription={true}
              />
            ) : null}
          </div>
        ) : (
          <span className={`b-formAccount__subTitle`}>
            {t('common:label.user-role')}: {userRoleList.find(r => r.value === constRoleId)?.label || ''}
          </span>
        )}
      </div>
      {modal ? (
        <Modal width={545} onClose={handleModal} classNameBack={styles.modalBack} title={t('awa:M11.msg.p1')}>
          {roleContentRender()}
        </Modal>
      ) : null}
    </>
  );
};

export default UserRoleSelect;
