import React, { FC } from 'react';
import styles from './CheckedItems.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  value: number;
  total: number;
}

const CheckedItems: FC<IProps> = ({ value, total }) => {
  const { t } = useTranslation();

  if (!value || !total) {
    return null;
  }

  return <div className={styles.content}>{t('main:pagination.selectedOf', { value, total })}</div>;
};

export default CheckedItems;
