import React, { FC, ReactNode, useState } from 'react';
import { Button } from '../../../../../controls';
import StepIndicator from '../StepIndicator/StepIndicator';
import { IRouteParamsBase, ISelectOptions, InstructionStep } from '../../../../../../models';
import { Modal } from '../../../../../common';
import { useHistory, useParams } from 'react-router';
import { ROOTS } from '../../../../../../routes/routes';
import { useTranslation } from 'react-i18next';
import PopupPortal from '../../../../../common/PopupPortal/PopupPortal';

interface IProps {
  currentOrgType: ISelectOptions;
  renderOrgTypeContent: () => ReactNode;
  setCurrentStep: (step: InstructionStep) => void;
}

const Step5: FC<IProps> = ({ renderOrgTypeContent, setCurrentStep }) => {
  const { t } = useTranslation();
  const history: any = useHistory();
  const { orgId } = useParams<IRouteParamsBase>();

  let [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (e: any) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const handleCreateEA = () => {
    history.push(ROOTS.CREATE_EA.replace(':orgId', orgId));
  };

  return (
    <>
      <div className={'b-step b-step__container -w735'}>
        <div style={{ marginBottom: 8 }} className="b-equityAudit__text16 b-equityAudit__textCenter">
          {t('common:headlines.ea-preparation')}
        </div>
        <div style={{ marginBottom: 16 }} className={'b-step__bigTitle'}>
          {t('awa:M33.msg.p1')}
        </div>
        <div style={{ marginBottom: 36 }}>
          <StepIndicator step={5} />
        </div>
        <div className="b-step__p">{t('awa:M33.msg.p2')}</div>
        <div className="b-step__p">{t('awa:M33.msg.p3')}</div>
        <div className="b-step__p">
          {t('awa:M33.msg.p4')}{' '}
          <a href="#" onClick={handleOpenModal} className="b-link">
            {t('awa:M33.msg.p5')}
          </a>
          .
        </div>
        <div className="b-step__wBox -paddings">
          <div className="b-step__orangeBold b-step__textCenter -mb5">{t('awa:M33.msg.p6')}</div>
          <div className="b-step__p b-step__textCenter" style={{ marginBottom: 0 }}>
            {t('awa:M33.msg.p7')} <b>{t('awa:M33.msg.p8')}</b> {t('awa:M33.msg.p9')}{' '}
            <b>{t('awa:M33.msg.p10')}</b>.
          </div>
        </div>
        <div className={`b-step__bottom`}>
          <Button
            className={'btn -type_form'}
            type={'transparency'}
            onPress={() => history.goBack()}
            title={t('common:btn.cancel')}
          />
          <div className={`b-step__bottom-left`}>
            <Button
              className="btn -type_form b-step__mr8"
              type={'bordered'}
              onPress={() => setCurrentStep('step4')}
              title={t('common:btn.back')}
            />
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={handleCreateEA}
              title={t('common:btn.next')}
              enableEnter
            />
          </div>
        </div>
      </div>
      {modalOpen && (
        <PopupPortal portalElementId={'user-guide-modal-1'}>
          <Modal title={t('awa:M34.title')} width={935} onClose={() => setModalOpen(false)}>
            <div className={'b-EAModal  b-step'}>
              <div className="b-step__p">{t('awa:M34.msg.p1')}</div>
              <div style={{ marginBottom: 12 }} className="b-equityAudit__text16">
                {t('awa:M34.msg.p2')}
              </div>
              <ul style={{ marginBottom: 20 }}>
                <li>{t('awa:M34.msg.p3')}</li>
                <li>{t('awa:M34.msg.p4')}</li>
                <li>{t('awa:M34.msg.p5')}</li>
                <li>{t('awa:M34.msg.p6')}</li>
              </ul>
              <div className="b-step__p">{t('awa:M34.msg.p7')}</div>
              <div className="b-step__p">{t('awa:M34.msg.p8')}</div>
              <div style={{ marginBottom: 20 }}>{renderOrgTypeContent()}</div>
              <div style={{ marginBottom: 12 }} className="b-step__smallTitle -grey">
                {t('main:ea-create.step5.students.title')}
              </div>
              <ul style={{ marginBottom: 36 }}>
                <li>{t('main:ea-create.step5.students.msg.p1')}</li>
                <li>{t('main:ea-create.step5.students.msg.p2')}</li>
                <li>{t('main:ea-create.step5.students.msg.p3')}</li>
                <li>{t('main:ea-create.step5.students.msg.p4')}</li>
                <li>{t('main:ea-create.step5.students.msg.p5')}</li>
                <li>{t('main:ea-create.step5.students.msg.p6')}</li>
                <li>{t('main:ea-create.step5.students.msg.p7')}</li>
                <li>{t('main:ea-create.step5.students.msg.p8')}</li>
                <li>{t('main:ea-create.step5.students.msg.p9')}</li>
                <li>{t('main:ea-create.step5.students.msg.p10')}</li>
              </ul>
              <div style={{ marginBottom: 12 }} className="b-step__smallTitle -grey">
                {t('main:ea-create.step5.stuff.title')}
              </div>
              <ul style={{ marginBottom: 36 }}>
                <li>{t('main:ea-create.step5.stuff.msg.p1')}</li>
                <li>{t('main:ea-create.step5.stuff.msg.p2')}</li>
                <li>{t('main:ea-create.step5.stuff.msg.p3')}</li>
              </ul>
              <div style={{ marginBottom: 12 }} className="b-step__smallTitle -grey">
                {t('main:ea-create.step5.administrators.title')}
              </div>
              <ul style={{ marginBottom: 36 }}>
                <li>{t('main:ea-create.step5.administrators.msg.p1')}</li>
                <li>{t('main:ea-create.step5.administrators.msg.p2')}</li>
                <li>{t('main:ea-create.step5.administrators.msg.p3')}</li>
              </ul>
              <div style={{ marginBottom: 12 }} className="b-step__smallTitle -grey">
                {t('main:ea-create.step5.vendors.title')}
              </div>
              <ul style={{ marginBottom: 36 }}>
                <li>{t('main:ea-create.step5.vendors.msg.p1')}</li>
                <li>{t('main:ea-create.step5.vendors.msg.p2')}</li>
                <li>{t('main:ea-create.step5.vendors.msg.p3')}</li>
                <li>{t('main:ea-create.step5.vendors.msg.p4')}</li>
                <li>{t('main:ea-create.step5.vendors.msg.p5')}</li>
              </ul>
              <div style={{ marginBottom: 12 }} className="b-step__smallTitle -grey">
                {t('main:ea-create.step5.community-partners.title')}
              </div>
              <ul style={{ marginBottom: 36 }}>
                <li>{t('main:ea-create.step5.community-partners.msg.p1')}</li>
                <li>{t('main:ea-create.step5.community-partners.msg.p2')}</li>
                <li>{t('main:ea-create.step5.community-partners.msg.p3')}</li>
              </ul>
              <div style={{ marginBottom: 12 }} className="b-step__smallTitle -grey">
                {t('main:ea-create.step5.board-directors.title')}
              </div>
              <ul style={{ marginBottom: 36 }}>
                <li>{t('main:ea-create.step5.board-directors.msg.p1')}</li>
                <li>{t('main:ea-create.step5.board-directors.msg.p2')}</li>
                <li>{t('main:ea-create.step5.board-directors.msg.p3')}</li>
                <li>{t('main:ea-create.step5.board-directors.msg.p4')}</li>
              </ul>
              <div style={{ marginBottom: 12 }} className="b-step__smallTitle -grey">
                {t('main:ea-create.step5.institutional-advancement.title')}
              </div>
              <ul style={{ marginBottom: 36 }}>
                <li>{t('main:ea-create.step5.institutional-advancement.msg.p1')}</li>
                <li>{t('main:ea-create.step5.institutional-advancement.msg.p2')}</li>
                <li>{t('main:ea-create.step5.institutional-advancement.msg.p3')}</li>
                <li>{t('main:ea-create.step5.institutional-advancement.msg.p4')}</li>
              </ul>
              <div style={{ marginBottom: 12 }} className="b-step__smallTitle -grey">
                {t('main:ea-create.step5.employee-assistance-grants.title')}
              </div>
              <ul style={{ marginBottom: 36 }}>
                <li>{t('main:ea-create.step5.employee-assistance-grants.msg.p1')}</li>
                <li>{t('main:ea-create.step5.employee-assistance-grants.msg.p2')}</li>
                <li>{t('main:ea-create.step5.employee-assistance-grants.msg.p3')}</li>
                <li>{t('main:ea-create.step5.employee-assistance-grants.msg.p4')}</li>
                <li>{t('main:ea-create.step5.employee-assistance-grants.msg.p5')}</li>
                <li>{t('main:ea-create.step5.employee-assistance-grants.msg.p6')}</li>
              </ul>
              <div style={{ marginBottom: 12 }} className="b-step__smallTitle -grey">
                {t('main:ea-create.step5.scholarships.title')}
              </div>
              <ul style={{ marginBottom: 36 }}>
                <li>{t('main:ea-create.step5.scholarships.msg.p1')}</li>
                <li>{t('main:ea-create.step5.scholarships.msg.p2')}</li>
                <li>{t('main:ea-create.step5.scholarships.msg.p3')}</li>
                <li>{t('main:ea-create.step5.scholarships.msg.p4')}</li>
              </ul>
              <div className="b-step__p">
                {t('main:ea-create.step5.download.p1')}{' '}
                <a
                  href="https://drive.google.com/file/d/1BN6xOJQ-3AqoLZtaHLXlNOD69tHgWXkm/view"
                  rel="noreferrer"
                  target="_blank"
                  className="b-link"
                >
                  {t('main:ea-create.step5.download.p2')}
                </a>
              </div>
            </div>
          </Modal>
        </PopupPortal>
      )}
    </>
  );
};

export default Step5;
