export enum ROOTS {
  SIGNIN = '/sign-in',
  SIGNUP = '/sign-up',
  FORGOT_PASSWORD = '/forgot-password',
  CHANGE_PASSWORD = '/change-password/:hash',
  ACCOUNT_VERIFIED = '/account-verification/:hash',
  ACCOUNT_CREATED = '/account-created',
  START_SIGN_UP = '/start-sign-up',
  ORGANIZATION_PREVIEW_CREATE = '/organization-preview/create',
  CREATE_ORGANIZATION_EXISTING = '/organization-preview/existing',
  ORGANIZATION_PREVIEW = '/organization-preview',
  ORGANIZATION_PREVIEW_JOIN = '/organization-preview/join',
  SUB_ORGANIZATION_PREVIEW = '/sub-organization',
  SUB_ORGANIZATION_PREVIEW_CREATE = '/sub-organization/create',
  ASSOCIATE_ORGANIZATION_PREVIEW = '/associate-organization',
  ASSOCIATION_SEND_PREVIEW = '/associate-organization/success',
  REQUEST_SUBMITTED = '/request-submitted',
  REQUEST_SUBMIT_PENDING = '/request-submit-pending',
  PASSWORD_SUBMITTED = '/password-submitted',
  EMAIL_ALREADY_EXIST = '/email-already-exist',
  CREATE_PROFILE = '/create_profile/:step',
  MY_TASKS = '/my-tasks',
  MY_ACCOUNT = '/my-account',
  MY_PROFILE = '/my-profile',
  TERMS_CONDITIONS = '/terms-user-agreement',
  PRIVACY_POLICY = '/privacy-policy',
  HOME_ORG = '/home',

  // Organization App
  ORG_PROFILE = '/org/:orgId/org-profile',
  CREATE_NEW_SUB_ORGANIZATION_SUCCESS = '/org/:orgId/successfully-created-organization',
  ORG_USERS = '/org/:orgId/org-users',
  ORG_USERS_CREATE = '/org/:orgId/org-users/create',
  CREATE_SUB_ORG = '/org/:orgId/create-sub-org',
  USER_PROFILE = '/org/:orgId/user-profile/:userId',
  ADDED_NEW_USER_SUCCESS = '/org/:orgId/new-user-added',
  GROUP_ANALYSIS = '/org/:orgId/group-analysis',
  GROUP_AVERAGES = '/org/:orgId/group-averages/:avg_id',
  GROUP_AVERAGES_CHART_ORG = '/org/:orgId/group-averages-chart/:avg_id/substandard/:id',
  GROUP_AVERAGES_CHART = '/org/:orgId/group-averages-chart/:id',
  GROUP_AVERAGES_SAVED = '/org/:orgId/group-averages-saved',
  GROUP_AVERAGES_SAVED_FOR_EAs = '/org/:orgId/group-averages-saved-ea',
  ORG_ANALYSIS = '/org/:orgId/org-analysis',

  // Equity audit
  EQUITY_AUDIT_LIST = '/org/:orgId/equity-audit-list',
  CREATE_EA_PREP = '/org/:orgId/create-equity-audit-prep',
  CREATE_EA = '/org/:orgId/create-equity-audit',
  TAKING_EA = '/org/:orgId/taking-equity-audit',
  EA_DETAILS = '/org/:orgId/ea-details/:eaId',

  // debrief
  STRIPE_SETUP = '/org/:orgId/stripe-setup/:eaId',
  DEBRIEF_SCHEDULING = '/org/:orgId/debrief-scheduling/:id/:debriefAlias',
  VIEW_DEBRIEF = '/org/:orgId/view-debrief/:eaId',
  TERMS_CONDITIONS_PAYMENT = '/org/terms-user-payment',

  // elm
  ELM_LIST = '/org/:orgId/elm-list',
  ELM_USER_LIST = '/org/:orgId/elm-details/:elmId/users',
  CREATE_ELM = '/org/:orgId/create-elm',
  CREATE_ELM_INTRO = '/org/:orgId/create-elm-intro',
  ELM_DETAILS = '/org/:orgId/elm-details/:elmId',
  TAKE_ELM = '/org/:orgId/take-elm',
  ELM_MY_REPORT = '/org/:orgId/elm-my-report/:elmId',
  ELM_ORG_REPORT_RESP = '/org/:orgId/elm-org-report/:elmId/resp',
  ELM_ORG_REPORT_DOMAIN_RESULTS = '/org/:orgId/elm-org-report/:elmId/domain-results',

  //EquityAuditReports
  REPORTS = '/org/:orgId/equity-audits/reports/:eaId',
  REPORT_SCORE = '/org/:orgId/equity-audits/reports/:eaId/:score',
  REPORT_CHART_BAR = '/org/:orgId/equity-audits/reports/:eaId/chart/:chartId',
  REPORT_FUNCTION_GROUPS = '/org/:orgId/equity-audits/reports/:eaId/function-groups',
  REPORT_STANDARD_RESPONSES = '/org/:orgId/equity-audits/reports/:eaId/standard-responses/:standardId',

  // Cohort
  COHORT_LIST = '/org/:orgId/cohort/list',
  COHORT_DETAILS = '/org/:orgId/cohort/:id/details',
  COHORT_ORGS = '/org/:orgId/cohort/:id/orgs',
  COHORT_SESSION_DETAILS = '/org/:orgId/cohort/:id/session-details/:sessionId',
  COHORT_MESSAGE_DETAILS = '/org/:orgId/cohort/:id/message-details/:messageId',
  COHORT_PARTICIPANT_DETAILS = '/org/:orgId/cohort/:id/participant/:organizationId',

  //beloved users
  BELOVED_USER_LIST = '/beloved-user/list',
  BELOVED_USER_PROFILE = '/beloved-user/profile/:id/:orgId',
  BELOVED_ORG_USER_LIST = '/beloved/organization/:orgId/beloved-user',
  ORG_BELOVED_USER_LIST = '/org/:orgId/bel-user/list',
  BEL_ORG_ASSIGNMENT_LIST = '/beloved/org-assignment/list',

  // Beloved Certified Consultant
  BELOVED_CERTIFIED_CONSULTANT = '/beloved-certified-consultant/list',
  BELOVED_CERTIFIED_CONSULTANT_ASSIGNMENTS = '/beloved/consultant-assignment/list',
  BELOVED_ORG_CERTIFIED_CONSULTANT = '/beloved/organization/:orgId/beloved-certified-consultant',

  // Org Certified Consultant
  ORG_BELOVED_CONSULTANT = '/org/:orgId/bel-consultants/list',

  // Beloved App
  BELOVED_DASHBOARD_EA = '/beloved/dashboard/ea',
  BELOVED_DASHBOARD_ELM = '/beloved/dashboard/elm',
  BELOVED_DASHBOARD_MAP = '/beloved/dashboard/map',
  BELOVED_ORGANIZATIONS = '/beloved/organizations',
  BELOVED_ORGANIZATION_DETAILS = '/beloved/organizations/:orgId/details',
  BELOVED_PM_PROFILE = '/beloved/organizations/:orgId/pm_profile',
  BELOVED_DEBRIEFS = '/beloved/debriefs',
  BELOVED_EQUITY_AUDITS = '/beloved/equity-audits',
  BELOVED_EQUITY_AUDIT_DETAILS = '/beloved/equity-audits/:eaId/details',
  BELOVED_ORG_CREDITS = '/beloved/organization/:orgId/credits',
  BELOVED_ADMIN_TOOL_USERS = '/beloved/admin-tool/users',
  BELOVED_ADMIN_TOOL_ORG_USERS = '/beloved/admin-tool/org-users',
  BELOVED_ADMIN_TOOL_ORG_USER_PROFILE = '/beloved/admin-tool/org-user/:id',
  BELOVED_ADMIN_TOOL_ROLES = '/beloved/admin-tool/roles',
  BELOVED_ADMIN_TOOL_SETTINGS = '/beloved/admin-tool/settings',
  BELOVED_MY_BIO = '/my-bio',
  BEL_GROUP_ANALYSIS = '/beloved/equity-audits/group-analysis',
  BELOVED_CREDIT_LIST = '/beloved/credits',
  BELOVED_CREDITS = '/beloved/organization/:orgId/credits',

  BELOVED_ELM_LIST = '/beloved/elms',
  BELOVED_ELM_DETAILS = '/beloved/elms/:elmId/details',

  BEL_GROUP_AVERAGES = '/beloved/equity-audits/group-averages/:avg_id',
  BEL_GROUP_AVERAGES_CHART_ORG = '/beloved/equity-audits/group-averages-chart/:avg_id/substandard/:id',
  BEL_GROUP_AVERAGES_CHART = '/beloved/equity-audits/group-averages-chart/:id',
  BEL_GROUP_AVERAGES_SAVED = '/beloved/equity-audits/group-averages-saved',
  BEL_GROUP_AVERAGES_SAVED_FOR_EAs = '/beloved/equity-audits/group-averages-saved-ea',

  // Cohort
  BELOVED_COHORT_LIST = '/beloved/cohorts',
  BELOVED_COHORT_DETAILS = '/beloved/cohort/:id/details',
  BELOVED_COHORT_EDIT = '/beloved/cohort/edit/:id',
  BELOVED_COHORT_CREATE = '/beloved/cohort/:id',
  BELOVED_COHORT_SESSION = '/beloved/cohort/:id/session/:sessionId',
  BELOVED_COHORT_MESSAGE = '/beloved/cohort/:id/message/:messageId',
  BELOVED_COHORT_MESSAGE_DETAILS = '/beloved/cohort/:id/message-details/:messageId',
  BELOVED_COHORT_GENERAL_RESOURCES = '/beloved/cohort/:id/general',
  BELOVED_COHORT_RESOURCE_LIB = '/beloved/cohort/:id/library/:sessionId',
  BELOVED_COHORT_PARTICIPANT_DETAILS = '/beloved/cohort/:id/participants/:organizationId',
  BELOVED_COHORT_SESSION_DETAILS = '/beloved/cohort/:id/session-details/:sessionId',

  // Resources
  RESOURCE_VIEW = '/resource/view/:id',
  RESOURCE_LIST_CARDS = '/resource/list/cards/:action',
  BELOVED_RESOURCE_VIEW = '/beloved/resource/view/:id',
  BELOVED_RESOURCE_DETAILS = '/beloved/resource/details/:id',
  BELOVED_CREATE_RESOURCE = '/beloved/resource/create',
  BELOVED_RESOURCE_LIST_TABLE = '/beloved/resource/list/table',
  BELOVED_RESOURCE_LIST_CARDS = '/beloved/resource/list/cards',

  // Messages
  BELOVED_MESSAGE_LIST = '/beloved/message/list',
  BELOVED_MESSAGE_DETAILS = '/beloved/message/:id/details',
  BELOVED_MESSAGE_CREATE = '/beloved/message/create',
  BELOVED_MESSAGE_ORGANIZATIONS = '/beloved/message/:messageId/orgs',

  // EWP
  EWP_DETAILS = '/org/:orgId/ewp', // don't remove orgId from here!
  EWP_DASHBOARD = '/org/:orgId/ewp/:id/dashboard',
  EWP_USERS = '/org/:orgId/ewp/:id/ewp-users',
  EWP_REVIEW_ITEMS = '/org/:orgId/ewp/:id/review-items',
  EWP_PRIORITY_LIST = '/org/:orgId/ewp/:id/priority-list',
  EWP_PRIORITY_CREATE = '/org/:orgId/ewp/:id/priority-create',
  EWP_PRIORITY_DETAILS = '/org/:orgId/ewp/:id/priority-details/:elId',
  EWP_GOAL_LIST = '/org/:orgId/ewp/:id/goal-list',
  EWP_GOAL_CREATE = '/org/:orgId/ewp/:id/goal-create',
  EWP_GOAL_DETAILS = '/org/:orgId/ewp/:id/goal-details/:elId',
  EWP_STRATEGY_LIST = '/org/:orgId/ewp/:id/strategy-list',
  EWP_STRATEGY_CREATE = '/org/:orgId/ewp/:id/strategy-create',
  EWP_STRATEGY_DETAILS = '/org/:orgId/ewp/:id/strategy-details/:elId',
  EWP_KA_LIST = '/org/:orgId/ewp/:id/ka-list',
  EWP_KA_CREATE = '/org/:orgId/ewp/:id/ka-create',
  EWP_KA_DETAILS = '/org/:orgId/ewp/:id/ka-details/:elId',
  EWP_PREPARATION = '/org/:orgId/ewp/preparation',
  EWP_CREATE = '/org/:orgId/ewp/create',

  BELOVED_EWP_LIST = '/beloved/ewp/list',
  BELOVED_EWP_DETAILS = '/beloved/ewp/:orgId', // don't remove orgId from here!
  BELOVED_EWP_PREPARATION = '/beloved/:orgId/ewp/preparation',

  LINK_RESOURCE = '/link-resource/:entity/:entityId',
  LINK_RESOURCE_LIST = '/link-resource-list/:entity/:entityId',

  //HTML
  MY_SETTINGS = '/my-settings',
  SUPPORT = '/support',
  NOT_FOUND = '/not-found',

  //Maintenance
  MAINTENANCE = '/maintenance',
}
