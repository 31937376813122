import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import CreateOrganizationView from './CreateOrganizationView';
import { httpUrl, isValidFields, validate } from '../../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { checkUniqOrganization } from '../../../../store/utils/utilsActions';
import {
  CreateOrganizationModel,
  KeyValueModel,
  OrganizationTypeInfoModel,
  ApiAddressModel,
  ISelectOptions,
} from '../../../../models';
import { useHistory } from 'react-router-dom';
import {
  createOrganization,
  CreateOrganizationAction,
  createSubOrganization,
  CreateSubOrganizationAction,
} from '../../../../store/organization/organizationActions';
import { changeAuthStepAction, getUser } from '../../../../store/profile/profileActions';
import { StateType } from '../../../../store/reducers';
import AuthNavigationHelper from '../../../../helpers/AuthNavigationHelper';
import { ModalsModel } from '../../../../constants';
import { useTranslation } from 'react-i18next';

interface IProps {
  title: string;
  showSubTitle: boolean;
}

const step1Validator = {
  required: ['name', 'website', 'type', 'division'],
  custom: [httpUrl(['website'])],
};

const step1SubValidator = {
  required: ['name', 'website', 'type'],
  custom: [httpUrl(['website'])],
};

const CreateOrganizationContainer = ({ title, showSubTitle }: IProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    address: {
      country: 'us',
    },
  } as CreateOrganizationModel);
  const [step, setStep] = useState(1);
  const [modals, setModals] = useState<ModalsModel>({});
  const [errors, setErrors] = useState({} as any);
  const orgTypes: ISelectOptions[] | null = useSelector(
    (state: StateType) => state.utils.data.organizationsTypes || null,
  );
  const [showExistingOrg, setShowExistingOrg] = useState(false);
  const isInternation = !(formData?.address?.country && formData.address.country.toLowerCase() === 'us');
  const step2Validator = {
    required: ['country', 'city', 'address', 'postal', 'state'],
    requiredFieldName: {
      postal: isInternation ? t('common:label.international.postal') : t('common:label.us.postal'),
      state: isInternation ? t('common:label.international.state') : t('common:label.us.state'),
      city: isInternation ? t('common:label.international.city') : t('common:label.us.city'),
    },
  };
  const refs: KeyValueModel<MutableRefObject<any>> = {
    k12: useRef(),
    university: useRef(),
    nonProfit: useRef(),
    forProfit: useRef(),
  };
  const [infoList, setInfoList] = useState<OrganizationTypeInfoModel[]>([
    {
      id: 1,
      content: (
        <>
          <h3 ref={refs.k12}>{t('awa:M92.msg.p1')}</h3>
          <p>
            {t('awa:M92.msg.p2')} <br></br>
            {t('awa:M92.msg.p3')}
          </p>
          <p>
            {t('awa:M92.msg.p4')}
            <br></br>
            {t('awa:M92.msg.p5')}
          </p>
        </>
      ),
    },
    {
      id: 2,
      content: (
        <>
          <h3 ref={refs.university}>{t('awa:M93.msg.p1')}</h3>
          <p>{t('awa:M93.msg.p2')}</p>
        </>
      ),
    },
    {
      id: 3,
      content: (
        <>
          <h3 ref={refs.nonProfit}>{t('awa:M95.msg.p1')}</h3>
          <p>{t('awa:M95.msg.p2')}</p>
        </>
      ),
    },
    {
      id: 4,
      content: (
        <>
          <h3 ref={refs.forProfit}>{t('awa:M94.msg.p1')}</h3>
          <p>{t('awa:M94.msg.p2')}</p>
        </>
      ),
    },
    {
      id: null,
      content: (
        <>
          <h3>{t('awa:M96.msg.p1')}</h3>
          <p>{t('awa:M96.msg.p2')}</p>
        </>
      ),
    },
  ]);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: StateType) => state.profile.authUser);

  useEffect(() => {
    if (user && user.organization_data) {
      setFormData({
        ...formData,
        division: user.organization_data.division,
        type: user.organization_data.type,
        website: user.organization_data.website,
        name: user.organization_data.name,
      });
    }
  }, [user]);

  useEffect(() => {
    if (formData?.type?.value && modals.isOrgSetupInfo) {
      setTimeout(() => {
        try {
          const scrollOption: ScrollIntoViewOptions = { behavior: 'smooth' };
          switch (formData.type.value) {
            case 1: {
              refs.k12.current.scrollIntoView(scrollOption);
              break;
            }
            case 2: {
              refs.university.current.scrollIntoView(scrollOption);
              break;
            }
            case 3: {
              refs.nonProfit.current.scrollIntoView(scrollOption);
              break;
            }
            case 4: {
              refs.forProfit.current.scrollIntoView(scrollOption);
              break;
            }
            default: {
              return null;
            }
          }
        } catch (e) {
          console.log('e', e);
        }
      });
    }
  }, [modals.isOrgSetupInfo]);

  const handleChangeInput = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    if (errors[name]) {
      delete errors[name];
      setErrors(errors);
    }
  };

  const handleChangeSelect = (name: string, data: ISelectOptions) => {
    setFormData({
      ...formData,
      [name]: data,
    });
    if (errors[name]) {
      delete errors[name];
      setErrors(errors);
    }
  };

  const onSubmit = () => {
    const { errors } = validate(step2Validator, formData.address);
    if (Object.values(errors || {}).length) {
      setErrors(errors as any);
      return;
    }

    const createOrganizationSuccess = (data: any) => {
      if (data.error) {
        return;
      }
      dispatch(changeAuthStepAction(data.next_step));
      const url = AuthNavigationHelper.getUrl(data.next_step);
      history.push(url);
    };

    const createSubOrganizationSuccess = (data: any) => {
      if (data.error) {
        return;
      }

      dispatch(getUser({}));
      dispatch(changeAuthStepAction(data.next_step));
      const url = AuthNavigationHelper.getUrl(data.next_step);
      history.push(url);
    };

    const apiData: any = { ...formData };
    apiData.type = apiData.type.value;
    apiData.website = apiData.website.toLowerCase();
    if (user?.next_step === 3) {
      apiData.id = user.organization_data?.id;
      dispatch(
        createSubOrganization({
          callback: createSubOrganizationSuccess,
          data: apiData,
        } as CreateSubOrganizationAction),
      );
    } else if (user?.next_step === 4) {
      dispatch(
        createOrganization({
          callback: createOrganizationSuccess,
          data: apiData,
        } as CreateOrganizationAction),
      );
    }
  };

  const onBack = () => {
    history.go(-1);
  };

  const handleAddressChange = (name: string, data: ApiAddressModel) => {
    setFormData({
      ...formData,
      address: data,
    });
    if (errors[name]) {
      delete errors[name];
      setErrors(errors);
    }
  };

  const checkUniqOrganizationResult = (step: number, result: any) => {
    if (result.errors && result.errors.length) {
      let errors = {};
      result.errors.forEach((e: any) => (errors = { ...errors, ...e }));
      setErrors(errors);
      setShowExistingOrg(true);
      return;
    }
    setStep(step);
  };

  const changeStep = (step: number) => {
    let errors: KeyValueModel<string> | null;
    if (user?.next_step === 3) {
      errors = validate(step1SubValidator, formData).errors;
    } else {
      errors = validate(step1Validator, formData).errors;
    }
    if (Object.values(errors || {}).length && step !== 1) {
      setErrors(errors as any);
      return;
    }

    if (step === 2) {
      dispatch(
        checkUniqOrganization({
          callback: result => checkUniqOrganizationResult(step, result),
          data: {
            website: formData.website,
            name: formData.name,
            id: user?.next_step === 3 ? user?.organization_data?.id : undefined,
          },
        }),
      );
      return;
    }
    setStep(step);
  };

  const handleCloseOrganizationExisting = () => {
    setShowExistingOrg(false);
  };

  const setModal = (modal: keyof ModalsModel) => (e: any) => {
    e.preventDefault();
    setModals(prevState => ({
      ...prevState,
      [modal]: !modals[modal],
    }));
  };

  const closeModal = (modal: keyof ModalsModel) => {
    setModals(prevState => ({
      ...prevState,
      [modal]: false,
    }));
  };

  let isDisableStep1Button = true;
  if (user?.next_step === 3) {
    isDisableStep1Button = isValidFields(step1SubValidator, formData);
  } else {
    isDisableStep1Button = isValidFields(step1Validator, formData);
  }

  const isDisableStep2Button = isValidFields(step2Validator, formData.address);

  return (
    <CreateOrganizationView
      formData={formData as CreateOrganizationModel}
      handleChangeInput={handleChangeInput}
      handleChangeSelect={handleChangeSelect}
      errors={errors}
      types={orgTypes}
      onSubmit={onSubmit}
      onBack={onBack}
      step={step}
      setStep={changeStep}
      user={user}
      title={title}
      showExistingOrg={showExistingOrg}
      handleCloseOrganizationExisting={handleCloseOrganizationExisting}
      handleAddressChange={handleAddressChange}
      isDisableStep1Button={isDisableStep1Button}
      isDisableStep2Button={isDisableStep2Button}
      setModal={setModal}
      closeModal={closeModal}
      modals={modals}
      infoList={infoList}
      showSubTitle={showSubTitle}
    />
  );
};

export default CreateOrganizationContainer;
