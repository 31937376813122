import { actionCreator } from '../actionCreator';
import { ActionModel } from '../../models';

//-----Login----//
export const LOGIN = 'LOGIN';

export interface LoginPayload {
  data: { username: string; password: string };
  callback: (response: any) => void;
}

export const login = actionCreator<LoginPayload>(LOGIN);

//-----Verification_account----//
export const VERIFIED_ACCOUNT_REQUEST = 'VERIFIED_ACCOUNT_REQUEST';

export interface verificationAccountAction {
  data: { hash: string };
  callback: (response: any) => void;
}

export const verificationAccountRequest = actionCreator<verificationAccountAction>(VERIFIED_ACCOUNT_REQUEST);

//-----Create_account----//CREATE_ACCOUNT_REQUEST

export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST';

export interface CreateAccountPayload {
  data: {
    username: string;
    password: string;
    first_name: string;
    last_name: string;
  };
  callback: (response: any) => void;
}

export const createAccountRequest = actionCreator<CreateAccountPayload>(CREATE_ACCOUNT_REQUEST);

//-----POST_RESENT_EMAIL_CONFIRM----//
export const RESENT_EMAIL_CONFIRM = 'RESENT_EMAIL_CONFIRM';

export interface ResendConfirmEmailPayload {
  data: {
    username: string;
    hash?: string;
  };
  callback: (response: any) => void;
}

export const resentEmailConfirmRequest = actionCreator<ResendConfirmEmailPayload>(RESENT_EMAIL_CONFIRM);

//-----FORGOT_PASSWORD----//

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';

export const forgotPasswordRequest = actionCreator<ResendConfirmEmailPayload>(FORGOT_PASSWORD);

//-----SET_SEEN_DECLINE_POPUP----//

export const SET_SEEN_DECLINE_POPUP = 'SET_SEEN_DECLINE_POPUP';

export const setSeenDeclinePopup = actionCreator(SET_SEEN_DECLINE_POPUP);

//-----CHANGE_PASSWORD----//

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export interface ChangePasswordPayload {
  data: {
    hash: string;
    password: string;
  };
  callback: (response: any) => void;
}

export const changePasswordRequest = actionCreator<ChangePasswordPayload>(CHANGE_PASSWORD);

export interface LogoutPayload {
  callback?: () => void;
}

//-----LOGOUT----//
export const LOGOUT = 'LOGOUT';
export const logoutRequest = actionCreator<LogoutPayload>(LOGOUT);

//-----LOGOUT_SUCCESS----//
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const logoutSuccess = actionCreator(LOGOUT_SUCCESS);

//-----IS_LOGIN----//
export const IS_LOGIN = 'IS_LOGIN';

export interface isLoginActionModel {
  data: { isAuthenticated: boolean };
}

//-----IS_LOGIN----//
export const SET_SIGN_UP_FIELDS = 'SET_SIGN_UP_FIELDS';

//-----IS_LOGIN----//
export const CHECK_FORGOT_PASS_ACTIVE = 'CHECK_FORGOT_PASS_ACTIVE';

export interface CheckForgotPassActivePayload {
  data: { hash: string };
  callback: (res: any, err?: any) => void;
}

export const checkForgotPassActive = actionCreator<CheckForgotPassActivePayload>(CHECK_FORGOT_PASS_ACTIVE);

export interface SetSignUpFieldsPayload {
  username: string;
  first_name: string;
  last_name: string;
}
export const setSignUpFields = actionCreator<SetSignUpFieldsPayload>(SET_SIGN_UP_FIELDS);

export const isLoginAction = actionCreator<isLoginActionModel>(IS_LOGIN);

//-----SET_REDIRECT_LOGIN_URL----//
export const SET_REDIRECT_LOGIN_URL = 'SET_REDIRECT_LOGIN_URL';
type SetRedirectLoginUrlPayload = string | null;
export const setRedirectLoginUrl = actionCreator<SetRedirectLoginUrlPayload>(SET_REDIRECT_LOGIN_URL);

export type AuthDataActionsTypes =
  | ActionModel<typeof LOGIN, LoginPayload>
  | ActionModel<typeof VERIFIED_ACCOUNT_REQUEST, verificationAccountAction>
  | ActionModel<typeof CREATE_ACCOUNT_REQUEST, CreateAccountPayload>
  | ActionModel<typeof CHECK_FORGOT_PASS_ACTIVE, any>
  | ActionModel<typeof FORGOT_PASSWORD, ChangePasswordPayload>
  | ActionModel<typeof IS_LOGIN, isLoginActionModel>
  | ActionModel<typeof LOGOUT>
  | ActionModel<typeof SET_SIGN_UP_FIELDS, SetSignUpFieldsPayload>
  | ActionModel<typeof SET_REDIRECT_LOGIN_URL, SetRedirectLoginUrlPayload>
  | ActionModel<typeof LOGOUT_SUCCESS>;
