import React from 'react';
import { useRowSelection } from 'react-data-grid';
import { BelCertifiedConsultantModel, ColumnType, EwpTypes } from '../../../../../models';
import { basicDateFormat, cutString } from '../../../../../helpers';
import { Checkbox, ComaList } from '../../../../controls';
import i18n from 'i18next';
import useHasAccess from '../../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../../constants';

export const getColumns = (): ColumnType<BelCertifiedConsultantModel>[] => [
  {
    key: 'first_name',
    name: i18n.t('common:label.first-name'),
    sortable: true,
    editable: false,
    resizable: true,
  },
  {
    key: 'last_name',
    name: i18n.t('common:column.last'),
    width: 170,
    sortable: true,
    editable: false,
    resizable: true,
  },
  {
    key: 'email',
    name: i18n.t('common:column.email'),
    sortable: true,
    editable: false,
    resizable: true,
  },
  {
    key: 'status',
    name: i18n.t('common:column.status'),
    width: 120,
    sortable: true,
    editable: false,
    resizable: true,
    formatter(props) {
      return (
        <div className="b-adminToolUsers__statusCell">
          <div>{props.row.status} -</div>
          <div>
            {props.row.status_date ? basicDateFormat(props.row.status_date) : i18n.t('common:column.n-a')}
          </div>
        </div>
      );
    },
  },
  {
    key: 'organizations',
    name: i18n.t('common:column.organizations'),
    sortable: false,
    resizable: true,
    editable: false,
    width: 190,
    headerCellClass: 'b-rdgTable__headerCell',
    formatter(props) {
      return (
        <div className="h-100 d-flex justify-content-between align-items-center">
          <div className="b-adminToolUsers__statusCell">
            <ComaList
              tag={'div'}
              uKey="b-l-"
              nullText={i18n.t('common:column.n-a')}
              arr={props.row.organizations.slice(0, 3).map(org => ({ title: cutString(org, 20) }))}
            />
          </div>
        </div>
      );
    },
  },
  {
    key: 'tools',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
  },
  {
    key: 'select',
    name: '',
    width: 40,
    maxWidth: 40,
    disableDrag: true,
    headerRenderer(props) {
      return (
        <Checkbox
          checked={props.allRowsSelected}
          onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
        />
      );
    },
    formatter: props => {
      const [isRowSelected, onRowSelectionChange] = useRowSelection();

      return (
        <div className="b-rdgTable__cellCenter">
          <div className={'checkBox'} onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={isRowSelected}
              onChange={e => {
                onRowSelectionChange({
                  row: props.row,
                  checked: e.target.checked,
                  isShiftClick: false,
                });
              }}
            />
          </div>
        </div>
      );
    },
  },
];
