import React, { useEffect, useState } from 'react';
import ChangePasswordView from './ChangePasswordView';
import { useDispatch } from 'react-redux';
import { password as passwordValidation, samePasswords, validate } from '../../../../helpers';
import { ROOTS } from '../../../../routes/routes';
import { changePasswordRequest, checkForgotPassActive } from '../../../../store/authData/authDataActions';
import { Modals } from '../../../../constants';

interface IProps {
  history: any;
}

const ChangePasswordContainer = ({ history }: IProps) => {
  const validator = {
    required: ['password', 'confirmPassword'],
    custom: [passwordValidation(['password']), samePasswords(['password', 'confirmPassword'])],
  };

  const sameValidator = {
    required: ['password', 'confirmPassword'],
    custom: [samePasswords(['password', 'confirmPassword'])],
  };

  const passwordLength = {
    required: ['password'],
    custom: [passwordValidation(['password'])],
  };
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setErrors] = useState<any>('');
  const [modals, setModals] = useState({});

  useEffect(() => {
    dispatch(
      checkForgotPassActive({
        data: {
          hash: history.location.pathname.replace(/^.+\//gm, ''),
        },
        callback: (res, err) => {
          if (err || res.is_expired) {
            setModals({
              [Modals.isExpiredHash]: true,
            });
          }
        },
      }),
    );
  }, []);

  useEffect(() => {
    setErrors({});
  }, [password, confirmPassword]);

  const handleNavigate = (response: any) => {
    switch (response.result.msg) {
      case 'New password successfully set.':
        history.push(ROOTS.SIGNIN);
        break;
      default:
        setErrors({ confirmPassword: response.result.msg });
        break;
    }
  };

  const handleOnBlur = (field: string) => () => {
    let errorCase;
    switch (field) {
      case 'confirmPassword':
        errorCase = validate(sameValidator, { password, confirmPassword });
        break;
      case 'password':
        errorCase = validate(passwordLength, { password });
        break;
      default:
        errorCase = { errors: error };
    }
    setErrors(errorCase.errors);
  };

  const handleChangePassword = (field: string) => (value: string) => {
    switch (field) {
      case 'password':
        setPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    let { errors } = validate(validator, { password, confirmPassword });
    setErrors(errors);
    if (!Object.keys(errors || {}).length) {
      dispatch(
        changePasswordRequest({
          data: {
            hash: history.location.pathname.replace(/^.+\//gm, ''),
            password: password,
          },
          callback: handleNavigate,
        }),
      );
    }
  };

  const handleCloseExpiredModel = () => {
    setModals({
      [Modals.isExpiredHash]: false,
    });
    history.push(ROOTS.SIGNIN);
  };

  return (
    <ChangePasswordView
      handleOnBlur={handleOnBlur}
      errors={error}
      password={password}
      confirmPassword={confirmPassword}
      onChange={handleChangePassword}
      onSubmit={handleSubmit}
      handleCloseExpiredModel={handleCloseExpiredModel}
      modals={modals}
    />
  );
};

export default ChangePasswordContainer;
