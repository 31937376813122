import React, { FC } from 'react';
import Ill1 from '../../../../../assets/images/icons/password@2x.png';
import { Button } from '../../../../controls';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { ROOTS } from '../../../../../routes/routes';
import { useHistory, useParams } from 'react-router';
import { EWPModel, EwpTypes, IRouteParamsBase } from '../../../../../models';

const AddElementPanel: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const ewpDetails = useSelector((state: StateType) => state.ewp.ewpDetails);
  const { orgId } = useParams<IRouteParamsBase>();
  const hasAllElements =
    ewpDetails &&
    ewpDetails.elements.has_goal &&
    ewpDetails.elements.has_priority &&
    ewpDetails.elements.has_strategy &&
    ewpDetails.elements.has_key_action;

  const user = useSelector((state: StateType) => state.profile.authUser);
  const isBeloved = user?.beloved_user;

  const renderNoElementsText = (ewpDetails: EWPModel) => {
    if (user?.user) {
      const isConsultant = ewpDetails.consultants.map(c => c.user_id).includes(user.user.id);
      if (
        isConsultant &&
        (ewpDetails.ewp_type_id === EwpTypes.org_with_consultant_active_role ||
          ewpDetails.ewp_type_id === EwpTypes.org_with_consultant_reviewer_role)
      ) {
        return <p className="b-marketingPanel__text font-navy">{t('awa:M232.msg.p1')}</p>;
      }
    }
    if (
      isBeloved &&
      (ewpDetails.ewp_type_id === EwpTypes.beloved_deep_dive_clients ||
        ewpDetails.ewp_type_id === EwpTypes.beloved_cohort_clients)
    ) {
      if (ewpDetails.beloved_team.map(b => b.user_id).includes(user?.user.id || 0)) {
        return <p className="b-marketingPanel__text font-navy">{t('awa:M230.msg.p1')}</p>;
      } else {
        return <p className="b-marketingPanel__text font-navy">{t('awa:M231.msg.p1')}</p>;
      }
    }
    if (ewpDetails.managers.map(b => b.user_id).includes(user?.user.id || 0)) {
      return <p className="b-marketingPanel__text font-navy">{t('awa:M234.msg.p1')}</p>;
    }

    return (
      <>
        <p className="b-marketingPanel__text font-navy">
          <div>{t('awa:M138.msg.p1')}</div>
          {t('awa:M138.msg.p2')}
        </p>
        <p className="b-marketingPanel__text font-navy">
          {t('awa:M138.msg.p3')}&nbsp;
          <b>{t('common:tabs.priorities').toLowerCase()}</b>,&nbsp;
          {t('awa:M138.msg.p4')}&nbsp;
          <b>{t('common:tabs.goals').toLowerCase()}</b>, <b>{t('common:tabs.strategies').toLowerCase()}</b>,{' '}
          {t('common:label.and')}&nbsp;
          <div>
            <b>{t('common:tabs.key-actions').toLowerCase()}</b>.
          </div>
        </p>
      </>
    );
  };

  const renderContent = () => {
    if (!ewpDetails) return null;
    const btnDisabled = !ewpDetails?.ewp_permissions.ewp_priority.create;
    if (!ewpDetails.elements.has_priority) {
      return (
        <>
          {renderNoElementsText(ewpDetails)}
          <div className="d-flex mb-4 mt-5 justify-content-center">
            <Button
              disabled={btnDisabled}
              onPress={() =>
                history.push(
                  ROOTS.EWP_PRIORITY_CREATE.replace(':orgId', orgId).replace(':id', `${ewpDetails.id}`),
                )
              }
              title={t('common:label.add-priority')}
            />
          </div>
        </>
      );
    }
    if (!ewpDetails.elements.has_goal) {
      return (
        <>
          <p className="b-marketingPanel__text font-navy">
            {t('awa:M139.msg.p1')}&nbsp;
            <b>{t('common:tabs.goals').toLowerCase()}</b>.&nbsp;
            {t('awa:M139.msg.p2')}&nbsp;
            <b>{t('common:tabs.strategies').toLowerCase()}</b>&nbsp;
            {t('common:label.and-the')}&nbsp;
            <b>{t('common:tabs.key-actions').toLowerCase()}</b>.
          </p>
          <div className="d-flex mb-4 mt-4 justify-content-center">
            <Button
              disabled={btnDisabled}
              onPress={() =>
                history.push(
                  ROOTS.EWP_GOAL_CREATE.replace(':orgId', orgId).replace(':id', `${ewpDetails.id}`),
                )
              }
              title={t('common:label.add-goal')}
            />
          </div>
        </>
      );
    }
    if (!ewpDetails.elements.has_strategy) {
      return (
        <>
          <p className="b-marketingPanel__text font-navy">
            {t('awa:M140.msg.p1')}&nbsp;
            <b>{t('common:tabs.strategies').toLowerCase()}</b>.&nbsp;
            {t('awa:M140.msg.p2')}&nbsp;
            <b>{t('common:tabs.key-actions').toLowerCase()}</b>.
          </p>
          <div className="d-flex mb-4 mt-4 justify-content-center">
            <Button
              disabled={btnDisabled}
              onPress={() =>
                history.push(
                  ROOTS.EWP_STRATEGY_CREATE.replace(':orgId', orgId).replace(':id', `${ewpDetails.id}`),
                )
              }
              title={t('common:label.add-strategy')}
            />
          </div>
        </>
      );
    }
    if (!ewpDetails.elements.has_key_action) {
      return (
        <>
          <p className="b-marketingPanel__text font-navy">
            {t('awa:M141.msg.p1')}&nbsp;
            <b>{t('common:tabs.key-actions').toLowerCase()}</b>.
          </p>
          <div className="d-flex mb-4 mt-4 justify-content-center">
            <Button
              disabled={btnDisabled}
              onPress={() =>
                history.push(ROOTS.EWP_KA_CREATE.replace(':orgId', orgId).replace(':id', `${ewpDetails.id}`))
              }
              title={t('common:label.add-key-action')}
            />
          </div>
        </>
      );
    }
  };

  if (hasAllElements) return null;

  return (
    <div className="b-panelData mt-4 contentContainer">
      <img className="b-marketingPanel__imgMain" src={Ill1} alt={t('awa:AT25')} />
      {renderContent()}
    </div>
  );
};

export default AddElementPanel;
