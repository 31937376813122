import { Loader } from '../../../../controls';
import React, { useEffect, useState } from 'react';
import { EwpPreparationStepEnum, IRouteParamsBase } from '../../../../../models';
import { PreparationEWPIntro } from './PreparationEWPIntro/PreparationEWPIntro';
import { PreparationEWPIntroDetails } from './PreparationEWPIntroDetails/PreparationEWPIntroDetails';
import { PreparationEWPIntro2 } from './PreparationEWPIntro2/PreparationEWPIntro2';
import { PreparationEWPStep1 } from './PreparationEWPStep1/PreparationEWPStep1';
import { PreparationEWPStep2 } from './PreparationEWPStep2/PreparationEWPStep2';
import { PreparationEWPStep3 } from './PreparationEWPStep3/PreparationEWPStep3';
import { PreparationEWPStep4 } from './PreparationEWPStep4/PreparationEWPStep4';
import { PreparationEWPFinish } from './PreparationFinish/PreparationFinish';
import { useHistory } from 'react-router';
import { ROOTS } from '../../../../../routes/routes';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';

export const PreparationEWP = () => {
  const isLoading = false;
  const { orgId } = useParams<IRouteParamsBase>();
  const [stepsStack, setStepsStack] = useState<EwpPreparationStepEnum[]>([EwpPreparationStepEnum.intro1]);
  const user = useSelector((state: StateType) => state.profile.authUser);
  const isBelovedUser = user?.beloved_user;
  const lastStep = stepsStack[stepsStack.length - 1];
  const history = useHistory();
  const setCurrentStep = (step: EwpPreparationStepEnum) => {
    setStepsStack([...stepsStack, step]);
  };

  const goPrevStep = () => {
    stepsStack.splice(stepsStack.length - 1, 1);
  };

  useEffect(() => {
    if (history.location.state) {
      setStepsStack([history.location.state.step]);
    }
  }, [history.location.state]);

  const cancelStep = () => {
    if (history.location.state?.ewpId) {
      if (isBelovedUser) {
        history.push(ROOTS.BELOVED_EWP_DETAILS.replace(':orgId', `${orgId}`));
      } else {
        history.push(ROOTS.EWP_DETAILS.replace(':orgId', `${orgId}`));
      }
      return;
    }
    setCurrentStep(EwpPreparationStepEnum.intro2);
  };

  const renderSteps = () => {
    switch (lastStep) {
      case EwpPreparationStepEnum.intro1: {
        return <PreparationEWPIntro setCurrentStep={setCurrentStep} />;
      }
      case EwpPreparationStepEnum.introDetails: {
        return <PreparationEWPIntroDetails goPrevStep={goPrevStep} setCurrentStep={setCurrentStep} />;
      }
      case EwpPreparationStepEnum.intro2: {
        return <PreparationEWPIntro2 setCurrentStep={setCurrentStep} />;
      }
      case EwpPreparationStepEnum.step1: {
        return <PreparationEWPStep1 setCurrentStep={setCurrentStep} cancelStep={cancelStep} />;
      }
      case EwpPreparationStepEnum.step2: {
        return <PreparationEWPStep2 setCurrentStep={setCurrentStep} cancelStep={cancelStep} />;
      }
      case EwpPreparationStepEnum.step3: {
        return <PreparationEWPStep3 setCurrentStep={setCurrentStep} cancelStep={cancelStep} />;
      }
      case EwpPreparationStepEnum.step4: {
        return <PreparationEWPStep4 setCurrentStep={setCurrentStep} cancelStep={cancelStep} />;
      }
      case EwpPreparationStepEnum.finish: {
        return <PreparationEWPFinish setCurrentStep={setCurrentStep} />;
      }
    }
  };

  return (
    <>
      <main className={'pageContainer -noRelative pageContainer__content'}>
        {isLoading && <Loader fitParent />}
        {renderSteps()}
      </main>
    </>
  );
};
