import React from 'react';
import styles from './CollapseStyles.module.scss';
import Collapse, { Panel } from 'rc-collapse/es';
import { CollapsePanelProps } from 'rc-collapse/es/interface';
require('rc-collapse/assets/index.css');

interface IProps {
  title: string;
  children?: JSX.Element;
  hasChildren: boolean;
}

const CollapseView = ({ title, children, hasChildren }: IProps) => {
  return (
    <div>
      <Collapse
        accordion={true}
        className={`${styles.collapse}`}
        expandIcon={(props: CollapsePanelProps) => {
          if (!hasChildren) {
            return <></>;
          }
          return <div className={`${styles.icon} ${props.isActive ? styles.opened : ''}`} />;
        }}
      >
        <Panel header={title} headerClass={`${styles.panel} ${hasChildren ? styles.activeTitle : ''}`}>
          {children}
        </Panel>
      </Collapse>
    </div>
  );
};

export default CollapseView;
