import React, { FC } from 'react';
import { Checkbox } from '../../../../controls';
import { useTranslation } from 'react-i18next';
import { AdminToolSettingsResourceModel } from '../../../../../models';
import InputMask from '../../../../controls/Inputs/InputMask';

interface IProps {
  resource: AdminToolSettingsResourceModel | null;
  onChangeResourceDay: (value: string) => void;
  onChangeConditionsDay: (value: string, index: number) => void;
  onChangeActive: (val: boolean) => void;
}

const mask = [
  { mask: '' },
  {
    mask: 'num',
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        scale: 0,
        thousandsSeparator: ',',
        padFractionalZeros: true,
        radix: '.',
        mapToRadix: [','],
      },
    },
  },
];

const ResourceExpiration: FC<IProps> = ({
  resource,
  onChangeResourceDay,
  onChangeActive,
  onChangeConditionsDay,
}) => {
  const { t } = useTranslation();

  if (!resource) {
    return <></>;
  }

  return (
    <>
      <div className="b-resourceCard font-grey font-s pr-4">
        <div className="font-weight-bold pb-2">{t('common:headlines.resource-library-expiration')}</div>
        <div className="pb-4">{t('main:atSettings.resource-sub')}:</div>
        <label className="pb-3 d-flex justify-content-between" htmlFor={`check-is-active-resource`}>
          <div className="flex-shrink-1">{t('common:headlines.is-active')}</div>
          <div className="pl-2">
            <Checkbox
              noText
              checked={resource.is_active}
              id={`check-is-active-resource`}
              onChange={e => onChangeActive(e.target.checked)}
            />
          </div>
        </label>
        <div className="d-flex justify-content-between align-items-center">
          <div>{t('main:atSettings.days-before-expiration')}</div>
          <div className="d-flex align-items-center b-credits__simpleInputWrap">
            <InputMask
              className="b-credits__simpleInput -w40"
              value={`${resource.days}`}
              ariaLabel={t('main:atSettings.days-before-expiration')}
              handleChange={(value: string) => onChangeResourceDay(value)}
              hideErrorBlock
              mask={mask}
            />
          </div>
        </div>
      </div>

      <div className="b-resourceCard font-grey font-s pr-4">
        <div className="font-weight-bold pb-2">{t('main:atSettings.resource-library-conditional')}</div>
        <div className="pb-4">{t('awa:M243.msg')}</div>
        {resource.conditions.map((c, cIndex) => {
          return (
            <div key={`conditions-${c.id}`} className="d-flex justify-content-between align-items-center">
              <div>{c.name}</div>
              <div className="d-flex align-items-center b-credits__simpleInputWrap">
                <InputMask
                  className="b-credits__simpleInput -w40"
                  value={`${c.days}`}
                  ariaLabel={t('common:label.fee')}
                  handleChange={(value: string) => onChangeConditionsDay(value, cIndex)}
                  hideErrorBlock
                  mask={mask}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ResourceExpiration;
