import React, { useEffect, useState } from 'react';
import { HeadlineOrganization, OrgBreadcrumbs, Pagination } from '../../../../common';
import EwpTabs from '../parts/EwpTabs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { getColumns } from './parts/columns';
import { Loader } from '../../../../controls';
import EwpKaItem from './parts/EwpKaItem';
import AddElementPanel from '../parts/AddElementPanel';
import useTableData from '../../../../../hooks/common/use-table-data';
import { useHistory, useParams } from 'react-router';
import { IRouteParamsBase, EWPKeyActionModel, TableDataEntities } from '../../../../../models';
import DataGrid from 'react-data-grid';
import { useIsDarkMode } from '../../../../../hooks/common/use-is-dark-mode';
import { useGetEwpRoute } from '../../../../../hooks/ewp/use-get-ewp-route';
import { ROOTS } from '../../../../../routes/routes';
import BackTitleButton from '../parts/BackTitleButton';
import SortModalButton from '../../../../common/SortModalButton/SortModalButton';
import FilterModalButton from '../../../../common/FilterModalButton/FilterModalButton';
import { useFilterOptions } from '../../../../../hooks/ewp/use-filter-options';
import { useEwpDetails } from '../../../../../hooks/ewp/use-ewp-details';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import useTableConfig from '../../../../../hooks/common/use-table-config';
import { useDispatch } from 'react-redux';
import { useEwpStatuses } from '../../../../../hooks/ewp/use-ewp-statuses';

const EwpKaList = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useIsDarkMode();
  const { ewpDetails } = useEwpDetails();
  let [mode, setMode] = useState<'table' | 'card'>('table');
  const ewpId = useParams<IRouteParamsBase>().id;
  const history = useHistory();
  const EWP_KA_CREATE = useGetEwpRoute(ROOTS.EWP_KA_CREATE);
  const EWP_KA_DETAILS = useGetEwpRoute(ROOTS.EWP_KA_DETAILS);
  const { filterOptions } = useFilterOptions(ewpDetails?.organization_id);
  const isAllowToCreate = ewpDetails?.ewp_permissions.ewp_key_action.create;
  const dispatch = useDispatch();
  const { ewpElementStatuses } = useEwpStatuses();

  const {
    data,
    query,
    infiniteList,
    handleChangeDirection,
    handleChangeSortField,
    setSortingData,
    sortingData,
    handleApplyFilter,
    handleSelectFilter,
    isFiltersChanged,
    filterData,
    fetchMoreMobile,
    fetchData,
    handleChangePage,
    handleSelectOne,
    handleChangePageSize,
    handleSearch,
    selectedEntitiesIds,
    handleSort,
    setFilterData,
    setQuery,
  } = useTableData(TableDataEntities.EWP_KA_LIST, { ewp_id: ewpId });

  useEffect(() => {
    fetchData(query);
  }, []);

  useEffect(() => {
    if (typeof history.location.state?.status === 'number') {
      const statusFilter = ewpElementStatuses[history.location.state?.status].value;
      const params = { ...query };
      params.filters = { status: [statusFilter] };
      setFilterData({ status: [statusFilter] });
      dispatch(setQuery(params));
      fetchData(params);
    }
  }, [history.location.state?.status]);

  const navToDetails = (data: EWPKeyActionModel) => {
    history.push(EWP_KA_DETAILS.replace(':elId', `${data.id}`));
  };

  const { columns, onColumnResize } = useTableConfig<EWPKeyActionModel>(
    TableDataEntities.EWP_KA_LIST,
    getColumns(),
  );

  const renderContent = () => {
    if (!data) return <Loader />;

    if (!ewpDetails?.elements.has_key_action) {
      return <AddElementPanel />;
    }

    if (data) {
      if (mode === 'card') {
        if (!data.result.length) {
          return <div className="b-no-content">{t('main:ewp.no-ka')}</div>;
        }
        return (
          <div className="mt-4">
            <div className="b-resourceListItems__wrapper">
              <InfiniteScroll
                dataLength={infiniteList.length}
                next={fetchMoreMobile}
                hasMore={!(data.current_page === data.total_pages)}
                loader={<h4 className="b-no-content">{t('common:label.loading')}...</h4>}
              >
                <div className="b-resourceListItems">
                  {infiniteList.map((item: EWPKeyActionModel) => {
                    return <EwpKaItem data={item} key={`card-${item.id}`} />;
                  })}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        );
      }
      if (mode === 'table') {
        return (
          <div>
            <DndProvider backend={HTML5Backend}>
              <DataGrid
                noRowsFallback={<div className="b-no-content">{t('main:ewp.no-ka')}</div>}
                onRowClick={navToDetails}
                onColumnResize={onColumnResize}
                onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
                columns={columns}
                sortColumns={query.sorting ? [query.sorting] : []}
                rows={data.result}
                onSelectedRowsChange={handleSelectOne}
                className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
                style={
                  data.result.length
                    ? {
                        height: ((data.result.length || 0) + 1) * 60,
                      }
                    : undefined
                }
                rowHeight={60}
                selectedRows={new Set(selectedEntitiesIds)}
                rowKeyGetter={(row: EWPKeyActionModel) => row.id}
              />
            </DndProvider>
            <Pagination
              data={data}
              handleChangePageSize={handleChangePageSize}
              changePage={handleChangePage}
            />
          </div>
        );
      }
    }
  };

  return (
    <main className={'b-page'}>
      <div className="b-page__header -desktop">
        <HeadlineOrganization
          orgName={ewpDetails?.organization_name || ''}
          orgId={ewpDetails?.organization_id || ''}
          headline={t('common:headlines.equity-work-plan')}
          customClasses={'-noDesktopMb'}
          backBtn={
            <BackTitleButton
              customRoute={
                typeof history.location.state?.status === 'number'
                  ? ROOTS.EWP_DASHBOARD.replace(':id', `${ewpId}`)
                  : ''
              }
            />
          }
        />
      </div>
      <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
      <EwpTabs activeItemAlias={'key-action'} />
      {ewpDetails?.elements.has_key_action ? (
        <div className="b-page__controls">
          <div className="b-tableToolContainer">
            <button
              aria-label="Publish Resource"
              onClick={() => setMode('table')}
              className={`b-tableTool -tableView -mobView -mr ${mode === 'table' ? '-active' : ''}`}
            >
              {t('common:tabs.table')}
            </button>
            <button
              aria-label="Publish Resource"
              onClick={() => setMode('card')}
              className={`b-tableTool -cardView -mobView -mr ${mode === 'card' ? '-active' : ''}`}
            >
              {t('common:tabs.cards')}
            </button>
            {isAllowToCreate ? (
              <button
                onClick={() => history.push(EWP_KA_CREATE)}
                className="b-tableTool -addPlus -mobView -mr"
              >
                {t('common:label.key-action')}
              </button>
            ) : null}
            {filterOptions ? (
              <FilterModalButton
                query={query}
                handleSelectFilter={handleSelectFilter}
                handleApplyFilter={handleApplyFilter}
                filterData={filterData}
                isFiltersChanged={isFiltersChanged}
                filters={filterOptions.key_action}
              />
            ) : null}
            {mode === 'card' ? (
              <>
                <SortModalButton
                  sortingData={sortingData}
                  setSortingData={setSortingData}
                  columns={columns}
                  query={query}
                  handleChangeSortField={handleChangeSortField}
                  handleSort={handleSort}
                />
                <button
                  onClick={handleChangeDirection}
                  type="button"
                  className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                     ${query.sorting?.direction === 'DESC' && '-active'}
                  `}
                >
                  {t('common:btn.a-z-order')}
                </button>
              </>
            ) : null}
          </div>
          <div className="b-page__search">
            <form action="#" className="searchForm">
              <input
                type="search"
                value={query.search}
                onChange={e => handleSearch(e.target.value)}
                placeholder={t('common:label.search')}
                aria-label="Search field"
              />
              <span className="searchForm__icon" />
            </form>
          </div>
        </div>
      ) : null}
      {renderContent()}
    </main>
  );
};

export default EwpKaList;
