import {
  EquityAuditActionsTypes,
  CREATE_EA_SUCCESS,
  GET_EA_DETAILS_SUCCESS,
  CLEAR_CREATE_EA_DATA,
  UPDATE_EA_SUCCESS,
  GET_USERS_LIST_FOR_CREATE_SUCCESS,
  GET_EA_OPTIONS_FOR_CREATE_SUCCESS,
  TAKE_EA_SUCCESS,
  SAVE_ANSWER_SUCCESS,
  DELETE_F_GROUP_SUCCESS,
  CLEAR_TAKE_EA_DATA,
  SKIP_F_GROUP_SUCCESS,
  CHANGE_CURRENT_DEBRIEF_SLIDE,
} from './equityAuditActions';
import { EAOptions, EAUser, EquityAuditModel, EATaking, CurrentDebriefSlideModel } from '../../models';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';
// import { QueryParamsModel } from '../../hooks/common/use-table-data';
import { ORG_CHANGED } from '../organization/organizationActions';

export type EquityAuditState = {
  createEAData: EquityAuditModel | null;
  usersList: EAUser[] | null;
  EAOptions: EAOptions | null;
  takingEA: EATaking | null;
  currentDebriefSlide: CurrentDebriefSlideModel | null;
};

const initialState: EquityAuditState = {
  createEAData: null,
  usersList: null,
  EAOptions: null,
  takingEA: null,
  currentDebriefSlide: null,
};

const equityAuditReducer = (
  state: EquityAuditState = initialState,
  action: EquityAuditActionsTypes,
): EquityAuditState => {
  switch (action.type) {
    case CHANGE_CURRENT_DEBRIEF_SLIDE:
      return {
        ...state,
        currentDebriefSlide: action.payload,
      };
    case GET_EA_DETAILS_SUCCESS:
    case UPDATE_EA_SUCCESS:
    case CREATE_EA_SUCCESS:
      return {
        ...state,
        createEAData: action.payload,
      };
    case CLEAR_CREATE_EA_DATA:
      return {
        ...state,
        createEAData: null,
      };
    case CLEAR_TAKE_EA_DATA:
      return {
        ...state,
        takingEA: null,
      };
    case GET_USERS_LIST_FOR_CREATE_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
      };
    case GET_EA_OPTIONS_FOR_CREATE_SUCCESS:
      return {
        ...state,
        EAOptions: action.payload,
      };
    case TAKE_EA_SUCCESS: {
      return {
        ...state,
        takingEA: action.payload,
      };
    }
    case SAVE_ANSWER_SUCCESS: {
      const { current_question_id, answer_id, current_function_group_id } = action.payload;
      if (state.takingEA) {
        const newGroups = [...state.takingEA.function_groups].map(g => {
          if (g.id === current_function_group_id) {
            return {
              ...g,
              is_skipped: false,
            };
          }
          return g;
        });

        let newQuestions = [...state.takingEA.questions];
        if (current_question_id) {
          newQuestions = state.takingEA.questions.map(q => {
            if (q.id === current_question_id) {
              return {
                ...q,
                answer: answer_id || null,
              };
            }
            return q;
          });
        }

        return {
          ...state,
          takingEA: {
            ...state.takingEA,
            questions: newQuestions,
            function_groups: newGroups,
            current_function_group_id: current_function_group_id || null,
            current_question_id: current_question_id || null,
          },
        };
      }
      return state;
    }
    case DELETE_F_GROUP_SUCCESS:
      if (state.takingEA) {
        return {
          ...state,
          takingEA: {
            ...state.takingEA,
            questions: action.payload,
          },
        };
      }
      return state;

    case SKIP_F_GROUP_SUCCESS: {
      if (state.takingEA) {
        const newGroups = [...state.takingEA.function_groups].map(g => {
          if (+g.id === +action.payload.fGroupId) {
            return {
              ...g,
              is_skipped: true,
            };
          }
          return g;
        });

        return {
          ...state,
          takingEA: {
            ...state.takingEA,
            function_groups: newGroups,
          },
        };
      }
      return state;
    }
    case LOGOUT_SUCCESS:
      return initialState;
    case ORG_CHANGED:
      return initialState;

    default:
      return state;
  }
};

export default equityAuditReducer;
