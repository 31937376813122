import React, { useEffect, useState } from 'react';
import Intro1 from './Intro1/Intro1';
import Intro2 from './Intro2/Intro2';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import Step3_5 from './Step3_5/Step3_5';
import Step4 from './Step4/Step4';
import Step5 from './Step5/Step5';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { InstructionStep, ISelectOptions } from '../../../../../models';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

interface ILocationState {
  step: InstructionStep;
}

const CreateEAPrep = () => {
  const location = useLocation<ILocationState>();
  const orgTypes = useSelector((state: StateType) => state.utils.data.organizationsTypes);
  const { t } = useTranslation();

  let [currentOrgType, setCurrentOrgType] = useState<ISelectOptions | null>(null);
  let [currentStep, setCurrentStep] = useState<InstructionStep>('intro1');

  useEffect(() => {
    if (orgTypes) {
      setCurrentOrgType(orgTypes[0]);
    }
  }, [orgTypes]);

  useEffect(() => {
    if (location.state?.step) {
      setCurrentStep(location.state.step);
    }
  }, []);

  const handleChangeSelect = (val: ISelectOptions) => {
    setCurrentOrgType(val);
  };

  const changeStep = (step: InstructionStep) => {
    setCurrentStep(step);
    window.scrollTo({ top: 0 });
  };

  const renderOrgTypeContent = () => {
    if (currentOrgType) {
      switch (currentOrgType.value) {
        case 1:
          return (
            <ul style={{ marginBottom: 20 }}>
              <li>{t('common:org-type.students')}</li>
              <li>{t('common:org-type.parents')}</li>
              <li>{t('common:org-type.faculty-staff')}</li>
              <li>{t('common:org-type.administrators')}</li>
              <li>{t('common:org-type.subcontractors')}</li>
              <li>{t('common:org-type.community')}</li>
              <li>{t('common:org-type.board-directors')}</li>
              <li>{t('common:org-type.institutional-advancement')}</li>
              <li>{t('common:org-type.employee-assistance')}</li>
              <li>{t('common:org-type.scholarships')}</li>
            </ul>
          );
        case 2:
          return (
            <ul style={{ marginBottom: 20 }}>
              <li>{t('common:org-type.students')}</li>
              <li>{t('common:org-type.parents')}</li>
              <li>{t('common:org-type.faculty-staff')}</li>
              <li>{t('common:org-type.administrators')}</li>
              <li>{t('common:org-type.subcontractors')}</li>
              <li>{t('common:org-type.community')}</li>
              <li>{t('common:org-type.board-directors')}</li>
              <li>{t('common:org-type.institutional-advancement')}</li>
              <li>{t('common:org-type.employee-assistance')}</li>
              <li>{t('common:org-type.scholarships')}</li>
            </ul>
          );
        case 3:
          return (
            <ul style={{ marginBottom: 20 }}>
              <li>{t('common:org-type.target-population')}</li>
              <li>{t('common:org-type.staff')}</li>
              <li>{t('common:org-type.management')}</li>
              <li>{t('common:org-type.subcontractors')}</li>
              <li>{t('common:org-type.community')}</li>
              <li>{t('common:org-type.board-directors')}</li>
              <li>{t('common:org-type.institutional-advancement')}</li>
              <li>{t('common:org-type.employee-assistance')}</li>
              <li>{t('common:org-type.CSR-foundation-giving')}</li>
            </ul>
          );
        case 4:
          return (
            <ul style={{ marginBottom: 20 }}>
              <li>{t('common:org-type.customers')}</li>
              <li>{t('common:org-type.staff')}</li>
              <li>{t('common:org-type.management-suite')}</li>
              <li>{t('common:org-type.subcontractors')}</li>
              <li>{t('common:org-type.community')}</li>
              <li>{t('common:org-type.board-trustees')}</li>
              <li>{t('common:org-type.institutional-advancement')}</li>
              <li>{t('common:org-type.employee-assistance')}</li>
              <li>{t('common:org-type.grantmaking-CSR')}</li>
            </ul>
          );
      }
    }

    return (
      <ul style={{ marginBottom: 20 }}>
        <li>{t('common:org-type.students')}</li>
        <li>{t('common:org-type.parents')}</li>
        <li>{t('common:org-type.faculty-staff')}</li>
        <li>{t('common:org-type.administrators')}</li>
        <li>{t('common:org-type.subcontractors')}</li>
        <li>{t('common:org-type.community')}</li>
        <li>{t('common:org-type.board-directors')}</li>
        <li>{t('common:org-type.institutional-advancement')}</li>
        <li>{t('common:org-type.employee-assistance')}</li>
        <li>{t('common:org-type.scholarships')}</li>
      </ul>
    );
  };

  const renderSteps = () => {
    if (orgTypes && currentOrgType) {
      switch (currentStep) {
        case 'intro1':
          return <Intro1 setCurrentStep={changeStep} />;
        case 'intro2':
          return <Intro2 setCurrentStep={changeStep} />;
        case 'step1':
          return <Step1 setCurrentStep={changeStep} />;
        case 'step2':
          return <Step2 setCurrentStep={changeStep} />;
        case 'step3': {
          return (
            <Step3
              setCurrentStep={changeStep}
              renderOrgTypeContent={renderOrgTypeContent}
              orgTypes={orgTypes}
              currentType={currentOrgType}
              handleChangeSelect={handleChangeSelect}
            />
          );
        }

        case 'step3_5':
          return <Step3_5 setCurrentStep={changeStep} />;
        case 'step4':
          return <Step4 setCurrentStep={changeStep} />;
        case 'step5':
          return (
            <Step5
              setCurrentStep={changeStep}
              renderOrgTypeContent={renderOrgTypeContent}
              currentOrgType={currentOrgType}
            />
          );
      }
    }
    return null;
  };

  return (
    <>
      <main className={'pageContainer -noRelative pageContainer__content'}>{renderSteps()}</main>
    </>
  );
};

export default CreateEAPrep;
