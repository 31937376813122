import React, { useEffect, useState } from 'react';
import useAtOrgUserProfile from '../../../../hooks/beloved/adminTool/use-at-org-user-profile';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../controls';
import { Loader } from '../../../controls';
import { Alert, AvatarZoomed, Modal } from '../../../common';
import AddAffiliation from '../../OrganizationApp/AddAffiliation/AddAffiliation';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { AdminToolOrgUserOrgModel, CreateOrgModel } from '../../../../models';
import { ROOTS } from '../../../../routes/routes';
import { cutString, basicDateFormat } from '../../../../helpers';
import { toast } from 'react-toastify';

const AdminToolOrgUserProfile = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  let [affiliationToEdit, setAffiliationToEdit] = useState<AdminToolOrgUserOrgModel | null>(null);
  let [pwLink, setPwLink] = useState('');
  let [removeId, setRemoveId] = useState<number | null>(null);

  const { updateOrg, resolveAction, getUser, user, passwordReset, removeOrg } = useAtOrgUserProfile();

  useEffect(() => {
    getUser(Number(id));
  }, []);

  const handleRemoveOrg = () => {
    if (removeId) {
      removeOrg(removeId, orgId => {
        setRemoveId(null);
        if (orgId) {
          history.replace(ROOTS.BELOVED_ADMIN_TOOL_ORG_USER_PROFILE.replace(':id', String(orgId)));
        } else {
          history.go(-1);
        }
      });
    }
  };

  const handleUpdateOrg = (formData: CreateOrgModel) => {
    if (affiliationToEdit) {
      updateOrg(formData, affiliationToEdit.id, () => {
        setAffiliationToEdit(null);
      });
    }
  };

  const handleResetPw = (e: any) => {
    e.preventDefault();
    passwordReset(Number(id), data => {
      setPwLink(data.result);
    });
  };

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(pwLink);
    toast.success(t('common:label.copied'));
    setPwLink('');
  };

  const handleClickSolution = (alias: string) => (e: any) => {
    e.preventDefault();
    resolveAction(alias, id);
  };

  if (!user) return <Loader fitParent />;

  return (
    <>
      <main className={'b-page'}>
        <div className="b-page__730">
          <div className="b-page__header -mobReverse">
            <div>
              <h1 className="b-page__title -noDesktopMb">
                {t('common:headlines.user-profile')}:&nbsp;
                <span className="mark">
                  {user.first_name} {user.last_name}
                </span>
              </h1>
              <div className="b-orgUserProfile__headText">
                {user.email}
                <span>{user.pronouns_name}</span>
              </div>
            </div>
            <div className="b-avatar__profile">
              <AvatarZoomed avatar={user.avatar} first_name={user.first_name} last_name={user.last_name} />
            </div>
          </div>
          <form action="">
            <div className="b-orgUserProfile__form">
              <div className="b-orgUserProfile__formRow">
                <div className="b-orgUserProfile__formInput">
                  <Input placeholder="title" type={'text'} value={user.title || t('common:column.n-a')} />
                </div>
                <div className="b-orgUserProfile__formInput">
                  <Input
                    placeholder="Division/Department/Unit"
                    type={'text'}
                    value={user.division || t('common:column.n-a')}
                  />
                </div>
              </div>
              <div className="b-orgUserProfile__formRow">
                <div className="b-orgUserProfile__formInput">
                  <Input
                    placeholder="Secondary Email"
                    type={'text'}
                    value={user.secondary_email || t('common:column.n-a')}
                  />
                </div>
              </div>
              <div className="b-orgUserProfile__formRow">
                <div className="b-orgUserProfile__formInput">
                  <Input
                    placeholder="Account Status"
                    type={'text'}
                    value={user.account_status || t('common:column.n-a')}
                  />
                </div>
                <div className="b-orgUserProfile__formInput">
                  {user.solutions?.length
                    ? user.solutions.map((item, index) => {
                        return (
                          <div key={`sol-${index}`} className="b-orgUserProfile__solution">
                            <p>{item.text}</p>
                            {item.action ? (
                              <a
                                href="#"
                                onClick={handleClickSolution(item.alias as string)}
                                className="orange-light-text"
                              >
                                {item.action}
                              </a>
                            ) : null}
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
            {user.user_id ? (
              <a onClick={handleResetPw} href="#" className="orange-light-text">
                {t('common:label.Generate-Password-Reset')}
              </a>
            ) : null}
            <div className="b-orgUserProfile__orgs">
              <h1 className="ag-userProfile_title">{t('common:headlines.organization-&-affiliation')}</h1>

              <div className="ag-userlist ag-userProfile">
                {user.organizations.length
                  ? user.organizations.map(org => {
                      return (
                        <div key={`org-${org.id}`} className="ag-userlist_item">
                          <h2 className="ag-userlist_title">{org.organization_name}</h2>
                          <div className="ag-userlist_box">
                            {!org.is_removed ? (
                              <div className={'ag-icons'}>
                                <button
                                  type="button"
                                  aria-label="Edit"
                                  className="ag-icon ag-icon_edit"
                                  onClick={() => setAffiliationToEdit(org)}
                                />
                                <button
                                  type="button"
                                  className="ag-icon ag-icon_delete"
                                  aria-label="Delete"
                                  onClick={() => setRemoveId(org.id)}
                                />
                              </div>
                            ) : null}
                            <table className="ag-userlist_list">
                              <tbody>
                                <tr>
                                  <th>{t('common:column.org-affiliation')}:</th>
                                  <td>{org.org_affiliation}</td>
                                </tr>
                                {org.status ? (
                                  <tr>
                                    <th>
                                      {org.status} {t('common:label.on')}:
                                    </th>
                                    <td>
                                      {org.status_date
                                        ? basicDateFormat(org.status_date)
                                        : t('common:column.n-a')}
                                    </td>
                                  </tr>
                                ) : null}
                                <tr>
                                  <th>{t('common:column.user-type')}:</th>
                                  <td>{org.type_name}</td>
                                </tr>
                                <tr>
                                  <th>{t('common:column.role')}:</th>
                                  <td>{org.role_name}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </form>
        </div>
      </main>
      {affiliationToEdit ? (
        <Modal
          width={545}
          onClose={() => setAffiliationToEdit(null)}
          className="b-filtersModal__mobileModal"
          title={t('common:headlines.edit-affiliation')}
        >
          <AddAffiliation
            editAffiliationForm={affiliationToEdit}
            handleClosePopup={() => setAffiliationToEdit(null)}
            saveAffiliation={handleUpdateOrg}
            availableOrgs={[]}
          />
        </Modal>
      ) : null}
      {pwLink ? (
        <Alert
          isOpen={!!pwLink}
          onRequestClose={() => setPwLink('')}
          // title={t('awa:N28.title')}
          title={'Password Reset Link'}
          // text={t('awa:N28.msg')}
          text={
            <>
              <p>{cutString(pwLink, 34)}</p>
              <p>
                Share the link by pasting it in an email message to the user by using your Beloved email
                address.
              </p>
              <p>Note: The link is valid for 10 minutes.</p>
            </>
          }
          buttons={{
            left: {
              title: t('common:btn.cancel'),
              type: 'transparency',
              onClick: () => setPwLink(''),
              enableEnter: true,
            },
            right: {
              title: t('common:btn.Copy-Link'),
              type: 'orange-light',
              onClick: handleCopyLink,
              enableEnter: true,
            },
          }}
        />
      ) : null}
      {removeId ? (
        <Alert
          isOpen={Boolean(removeId)}
          onRequestClose={() => setRemoveId(null)}
          title={t('awa:N6.msg')}
          text={<p />}
          buttons={{
            left: {
              title: t('common:btn.cancel'),
              type: 'white',
              onClick: () => setRemoveId(null),
            },
            right: {
              title: t('common:btn.delete'),
              type: 'orange-light',
              onClick: handleRemoveOrg,
            },
          }}
        />
      ) : null}
    </>
  );
};

export default AdminToolOrgUserProfile;
