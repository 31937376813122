import { BEL_GET_ELM_DETAILS_SUCCESS, BEL_UPDATE_ELM_SUCCESS, BelElmActionsTypes } from './elmActions';
import { BelElmDetailsModel } from '../../../models';
import { LOGOUT_SUCCESS } from '../../authData/authDataActions';

export type BelElmState = {
  currentElm: BelElmDetailsModel | null;
};

const initialState: BelElmState = {
  currentElm: null,
};

const belElmReducer = (state: BelElmState = initialState, action: BelElmActionsTypes): BelElmState => {
  switch (action.type) {
    case BEL_GET_ELM_DETAILS_SUCCESS: {
      return {
        ...state,
        currentElm: action.payload,
      };
    }
    case BEL_UPDATE_ELM_SUCCESS: {
      return {
        ...state,
        currentElm: action.payload,
      };
    }
    case LOGOUT_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default belElmReducer;
