import React, { FC } from 'react';
import { statusValues } from '../../../../../constants';
import { isMobile } from 'react-device-detect';
import { Button } from '../../../../controls';
import { Modal } from '../../../../common';
import { useTranslation } from 'react-i18next';
import { DataTableFilterModel } from '../../../../../hooks/common/use-table-data';

interface IProps {
  onClose: () => void;
  handleSelectFilter: (val: number | string, fieldName: string) => void;
  handleApplyFilter: () => void;
  filterData: DataTableFilterModel;
}

const FilterModal: FC<IProps> = ({ onClose, filterData, handleApplyFilter, handleSelectFilter }) => {
  const { t } = useTranslation();
  const userFilters = statusValues();

  return (
    <>
      <Modal
        width={650}
        onClose={onClose}
        className={!isMobile ? 'b-filtersModal__mobileModal' : ''}
        title={t('common:btn.filter')}
      >
        <div className="b-filtersModal">
          <div className="b-filtersModal__list b-filtersModal__list_fullWidth">
            <div className="b-filtersModal__title">
              {t('common:label.by-organization-association-status')}
            </div>
            <ul>
              {userFilters.map(item => {
                const isActive = filterData.status?.includes(item.value);
                return (
                  <li key={`filter-${item.value}`}>
                    <a
                      href="#"
                      className={isActive ? '-active' : ''}
                      onClick={e => {
                        e.preventDefault();
                        handleSelectFilter(item.value, 'status');
                      }}
                    >
                      {item.label}
                    </a>
                  </li>
                );
              })}
              <li>
                <a
                  href="#"
                  className={filterData.no_organization ? '-active' : ''}
                  onClick={e => {
                    e.preventDefault();
                    handleSelectFilter(String(true), 'no_organization');
                  }}
                >
                  {t('common:btn.no-association')}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="b-filtersModal__buttons">
          <Button
            onPress={() => {
              handleApplyFilter();
              onClose();
            }}
            title={t('common:btn.filter')}
            size={'large'}
            type={'orange-light'}
          />
        </div>
      </Modal>
    </>
  );
};

export default FilterModal;
