import { useTranslation } from 'react-i18next';

export const useGetTexts = () => {
  const { t } = useTranslation();
  const approvalStatusAutoNotesTexts = [
    [],
    [{ resultStatus: 2, text: t('common:label.Submitted-for-Review') }],
    [
      { resultStatus: 3, text: t('common:status.in-review') },
      { resultStatus: 1, text: t('main:automatic-notes.Review-Cancelled') },
    ],
    [
      { resultStatus: 4, text: t('common:label.Review-Completed') },
      { resultStatus: 1, text: t('main:automatic-notes.Sent-Back-to-Editing') },
      { resultStatus: 2, text: t('main:automatic-notes.review-cancelled') },
    ],
    [
      { resultStatus: 5, text: t('common:label.approved') },
      { resultStatus: 1, text: t('main:automatic-notes.Sent-Back-to-Editing') },
    ],
    [
      { resultStatus: 1, text: t('main:automatic-notes.Approval-Cancelled-Sent-Back-to-Editing') },
      { resultStatus: 4, text: t('main:automatic-notes.Approval-Cancelled-Sent-Back-to-Review-Completed') },
    ],
  ];

  return {
    approvalStatusAutoNotesTexts,
  };
};
