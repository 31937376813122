import { call, all, take, StrictEffect, put, throttle, select } from 'redux-saga/effects';
import * as actionTypes from './resourcesActions';
import Api from '../../../services/api';
import { toast } from 'react-toastify';
import { convertResourceOptions } from '../../../helpers';
import { ActionModel, BelResourceOptionModel, BelResourceStandartOptionModel } from '../../../models';
import { t } from 'i18next';
import { StateType } from '../../reducers';
import { getLinkResourceListSuccess } from './resourcesActions';

function* belGetResourceOptionsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback },
    }: { payload: actionTypes.BelGetResourceOptionsPayload } = yield take(
      actionTypes.BEL_GET_RESOURCE_OPTIONS,
    );

    try {
      const response = yield call(Api.get, `utils/resource/common`);

      const data = {
        types: convertResourceOptions(response.types),
        tags: convertResourceOptions(response.tags),
        ownerships: convertResourceOptions(response.ownerships),
        distributions: convertResourceOptions(response.distributions),
        statuses: response.statuses,
        categories: response.categories,
        sectors: convertResourceOptions(response.sectors),
        access_types: convertResourceOptions(response.access_types),
        standards: response.standards,
        elm_domains: response.elm_domains,
      };

      if (response) {
        yield put(actionTypes.belGetResourceOptionsSuccess(data));
        if (callback) {
          yield call(callback);
        }
      }
    } catch (e) {
      console.log('message', e);
    }
  }
}

function* belCreateResourceSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: actionTypes.BelCreateResourcePayload } = yield take(actionTypes.BEL_CREATE_RESOURCE);

    try {
      const response = yield call(Api.post, `beloved/resource/create/`, data);

      if (response) {
        callback && callback();
        toast.success(t('common:toast.resource-created'));
      }
    } catch (e) {
      console.log('message', e);
      toast.error(t('common:toast.error'));
    }
  }
}

function* getAvailableOrgSaga({
  payload: { callback, data },
}: ActionModel<typeof actionTypes.GET_AVAILABLE_ORG, actionTypes.GetAvailableOrgPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  try {
    const response = yield call(Api.post, `beloved/resource/available_to_link_org/`, data);

    if (response) {
      callback && callback(response);
    }
  } catch (e) {
    console.log('message', e);
  }
}

function* getAvailableCohortSaga({
  payload: { callback, data },
}: ActionModel<typeof actionTypes.GET_AVAILABLE_COHORT, actionTypes.GetAvailableCohortPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  try {
    const response = yield call(Api.post, `beloved/resource/get_available_cohorts/`, data);
    if (response) {
      callback && callback(response);
    }
  } catch (e) {
    console.log('message', e);
  }
}

function* linkAvailableOrgSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: actionTypes.LinkAvailableOrgPayload } = yield take(actionTypes.LINK_AVAILABLE_ORG);

    try {
      const response = yield call(Api.post, `beloved/resource/link_org_to_resource/`, data);
      if (response) {
        callback && callback();
        toast.success(t('common:toast.share-success'));
      }
    } catch (e) {
      console.log('message', e);
    }
  }
}

function* linkAvailableCohortSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: actionTypes.LinkAvailableCohortPayload } = yield take(actionTypes.LINK_AVAILABLE_COHORT);

    try {
      const response = yield call(Api.post, `beloved/resource/link_cohort/`, data);
      if (response) {
        callback && callback();
        toast.success(t('common:toast.share-success'));
      }
    } catch (e) {
      console.log('message', e);
    }
  }
}

function* getAvailableCohortSessionSaga({
  payload: { callback, data, cohort_id },
}: ActionModel<
  typeof actionTypes.GET_AVAILABLE_COHORT_SESSION,
  actionTypes.GetAvailableCohortSessionPayload
>): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(
      Api.post,
      `beloved/resource/get_available_cohort_sessions/${cohort_id}/`,
      data,
    );
    if (response) {
      callback && callback(response);
    }
  } catch (e) {
    console.log('message', e);
  }
}

function* linkAvailableCohortSessionSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: actionTypes.LinkAvailableCohortSessionPayload } = yield take(
      actionTypes.LINK_AVAILABLE_COHORT_SESSION,
    );

    try {
      const response = yield call(Api.post, `beloved/resource/link_cohort_session/`, data);
      if (response) {
        callback && callback();
        toast.success(t('common:toast.share-success'));
      }
    } catch (e) {
      console.log('message', e);
    }
  }
}

function* belUpdateResourceSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data, id },
    }: { payload: actionTypes.BelUpdateResourcePayload } = yield take(actionTypes.BEL_UPDATE_RESOURCE);

    try {
      const response = yield call(Api.put, `beloved/resource/update/${id}/`, data);

      if (response) {
        callback && callback();
      }
    } catch (e) {
      console.log('message', e);
      toast.error(t('common:toast.error'));
    }
  }
}

function* belGetResourceSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, id },
    }: { payload: actionTypes.BelGetResourcePayload } = yield take(actionTypes.BEL_GET_RESOURCE);

    try {
      const response = yield call(Api.post, `beloved/resource/details/`, {
        resource_id: id,
      });

      if (response) {
        callback(response.result.resource);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* belDeleteResources(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, ids },
    }: { payload: actionTypes.BelDeleteResourcePayload } = yield take(actionTypes.BEL_DELETE_RESOURCES);
    try {
      const response = yield call(Api.post, `beloved/resource/mass_activation/`, {
        is_active: 0,
        resource_ids: ids,
      });
      if (response) {
        callback && callback();
      }
    } catch (e) {
      console.log('message', e);
      toast.error(t('common:toast.error'));
    }
  }
}

function* belChangeResourceStatus(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, ids, status },
    }: { payload: actionTypes.BelChangeResourceStatusPayload } = yield take(
      actionTypes.BEL_CHANGE_RESOURCE_STATUS,
    );

    try {
      const response = yield call(Api.post, `beloved/resource/mass_change_status/`, {
        status,
        resource_ids: ids,
      });
      if (response) {
        callback && callback();
      }
    } catch (e) {
      console.log('message', e);
      toast.error(t('common:toast.error'));
    }
  }
}

function* belGetResourceListSaga({
  payload: { callback, params, extraData },
}: ActionModel<typeof actionTypes.BEL_GET_RESOURCE_LIST, actionTypes.BelGetResourceListPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  try {
    let options = yield select((state: StateType) => state.belResource.options);
    let { resource_category } = params;

    if (options && resource_category && typeof resource_category === 'string') {
      let params = resource_category.split(',');

      // replaceStandardIDs

      if (params.includes('diversity')) {
        params = params.filter(item => item !== 'diversity');
        const group = options.standards.find(
          (item: BelResourceStandartOptionModel) => item.alias === 'diversity',
        );
        group.children.forEach((item: BelResourceOptionModel) => {
          params.push(String(item.id));
        });
        resource_category = params.join(',');
      }
      if (params.includes('equity')) {
        params = params.filter(item => item !== 'equity');
        const group = options.standards.find(
          (item: BelResourceStandartOptionModel) => item.alias === 'equity',
        );
        group.children.forEach((item: BelResourceOptionModel) => {
          params.push(String(item.id));
        });
        resource_category = params.join(',');
      }
      if (params.includes('inclusion')) {
        params = params.filter(item => item !== 'inclusion');
        const group = options.standards.find(
          (item: BelResourceStandartOptionModel) => item.alias === 'inclusion',
        );
        group.children.forEach((item: BelResourceOptionModel) => {
          params.push(String(item.id));
        });
        resource_category = params.join(',');
      }
    }

    const url = extraData?.url || 'beloved/resource/list/';
    const response = yield call(Api.get, url, { ...params, resource_category });

    if (response) {
      yield put(actionTypes.belGetResourceListSuccess(response));
      callback && callback();
    }
  } catch (e: any) {
    if (e.response?.status === 400) {
      toast.info(e.response.data.msg);
    }
  }
}

function* getLinkResourceListSaga({
  payload: { callback, params, extraData },
}: ActionModel<typeof actionTypes.GET_LINK_RESOURCE_LIST, actionTypes.GetLinkResourceListPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  try {
    const response = yield call(
      Api.get,
      `resource/relation/${extraData?.entity}/${extraData?.entityId}/available_resource_list/?ex_rel=true`,
      params,
    );

    if (response) {
      callback && callback();
      yield put(getLinkResourceListSuccess(response));
    }
  } catch (e: any) {
    console.log('error message', e);
  }
}

export function* saga() {
  yield all([
    throttle(400, actionTypes.BEL_GET_RESOURCE_LIST, belGetResourceListSaga),
    belGetResourceOptionsSaga(),
    belCreateResourceSaga(),
    belGetResourceSaga(),
    belUpdateResourceSaga(),
    belDeleteResources(),
    throttle(400, actionTypes.GET_AVAILABLE_ORG, getAvailableOrgSaga),
    throttle(400, actionTypes.GET_LINK_RESOURCE_LIST, getLinkResourceListSaga),
    throttle(400, actionTypes.GET_AVAILABLE_COHORT, getAvailableCohortSaga),
    throttle(400, actionTypes.GET_AVAILABLE_COHORT_SESSION, getAvailableCohortSessionSaga),
    belChangeResourceStatus(),
    linkAvailableOrgSaga(),
    linkAvailableCohortSaga(),
    linkAvailableCohortSessionSaga(),
  ]);
}
