import { Button } from '../../../../../controls';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EwpPreparationStepEnum } from '../../../../../../models';

interface IProps {
  setCurrentStep: (step: EwpPreparationStepEnum) => void;
}

export const PreparationEWPIntro = ({ setCurrentStep }: IProps) => {
  const { t } = useTranslation();

  const handleOpenModal = (e: any) => {
    e.preventDefault();
    setCurrentStep(EwpPreparationStepEnum.introDetails);
  };

  return (
    <div className={'b-step__container -centerContent'}>
      <h1 className={'b-step__bigTitle'}>{t('common:headlines.new-ewp')}</h1>
      <p className={'paragraph mb-4'}>{t('awa:M136.msg.p1')}</p>
      <p className="paragraph">
        {t('main:ewp-create.intro.p3')}
        <a className="b-link" onClick={handleOpenModal} href="#">
          {t('main:ewp-create.intro.p4')}.
        </a>
      </p>
      <div className="b-step__btns">
        <Button
          className={'btn -type_form'}
          type={'orange-light'}
          onPress={() => setCurrentStep(EwpPreparationStepEnum.intro2)}
          title={t('common:btn.next')}
          enableEnter
        />
      </div>
    </div>
  );
};
