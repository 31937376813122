import React, { FC } from 'react';

type TagType = 'div' | 'span' | 'non' | 'a';

interface IProps {
  arr: { title: string }[];
  uKey: string;
  tag?: TagType;
  nullText?: string;
  handleClick?: (e: any, index: number) => void;
}

const Elem: FC<{ tag?: TagType; handleClick?: (e: any) => void }> = ({ tag, children, handleClick }) => {
  if (tag === 'div') {
    return <div>{children}</div>;
  } else if (tag === 'non') {
    return <>{children}</>;
  } else if (tag === 'a') {
    return (
      <a className={'b-link'} href={'#'} onClick={handleClick}>
        {children}
      </a>
    );
  }
  return <span>{children}</span>;
};

const ComaList: FC<IProps> = ({ arr, uKey, tag, nullText, handleClick }) => {
  if (!arr.length) return <>{nullText}</>;

  const res = arr.map((item, index) => {
    if (arr && index === arr.length - 1) {
      return (
        <Elem tag={tag} key={`${uKey}-${index}`} handleClick={e => handleClick && handleClick(e, index)}>
          {item.title}
        </Elem>
      );
    }
    return (
      <Elem tag={tag} key={`${uKey}-${index}`} handleClick={e => handleClick && handleClick(e, index)}>
        {item.title},{' '}
      </Elem>
    );
  });

  return <>{res}</>;
};

ComaList.defaultProps = {
  tag: 'span',
  nullText: '-',
};

export default ComaList;
