import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import DataGrid from 'react-data-grid';
import { Alert, BelovedAdminToolsTabs, Pagination } from '../../../common';
import {
  belAtCreateUser,
  belAtUpdateUser,
  belAtDeleteUser,
  belAtDeactivateUser,
} from '../../../../store/beloved/adminTools/adminToolActions';
import { Checkbox, Loader } from '../../../controls';
import { AdminToolUserModel, AdminToolUpdateUserModel, TableDataEntities } from '../../../../models';
import useGetAvailableRoles from '../../../../hooks/beloved/adminTool/use-get-available-roles';
import { getColumns } from './parts/columns';
import UserForm from './parts/UserForm';
import Filters from './parts/Filters';
import { isMobile } from 'react-device-detect';
import { convertUserStatus, isEmptyObject } from '../../../../helpers';
import { Modals, ModalsModel } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { useIsDarkMode } from '../../../../hooks/common/use-is-dark-mode';
import SortModalButton from '../../../common/SortModalButton/SortModalButton';
import useTableData from '../../../../hooks/common/use-table-data';
import useTableConfig from '../../../../hooks/common/use-table-config';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

interface IState {
  modals: ModalsModel;
}

const AdminToolUsers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isDarkMode } = useIsDarkMode();

  const {
    data,
    fetchData,
    query,
    handleChangeSortField,
    handleChangePage,
    handleChangePageSize,
    handleSelectOne,
    handleChangeDirection,
    handleSort,
    setSortingData,
    sortingData,
    handleSearch,
    handleSelectAll,
    handleSelectOneMob,
    fetchMoreMobile,
    infiniteList,
    selectedEntitiesIds,
    setSelectedEntitiesIds,
    handleSelectFilter,
    isFiltersChanged,
    handleApplyFilter,
    filterData,
  } = useTableData(TableDataEntities.AT_BEL_USERS);

  const { columns, onColumnResize } = useTableConfig<AdminToolUserModel>(
    TableDataEntities.AT_BEL_USERS,
    getColumns(),
  );

  const availableRoles = useGetAvailableRoles();

  let [isUserFormOpen, setIsUserFormOpen] = useState(false);
  let [removeAlert, setRemoveAlert] = useState(false);
  let [currentUser, setCurrentUser] = useState<AdminToolUserModel | undefined>(undefined);

  const initialState: IState = {
    modals: {},
  };

  let [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    fetchData(query);
  }, []);

  const handleCloseUserForm = () => {
    setIsUserFormOpen(false);
    if (currentUser) {
      setCurrentUser(undefined);
    }
  };

  const manipulateModal = (modalName: string, open: boolean) => {
    setState(prevState => ({
      ...prevState,
      modals: {
        ...state.modals,
        [modalName]: open,
      },
    }));
  };

  const handleClickUser = (user: AdminToolUserModel) => {
    setIsUserFormOpen(true);
    setCurrentUser(user);
  };

  const saveUserCallback = () => {
    setIsUserFormOpen(false);
    setCurrentUser(undefined);
    fetchData(query);
  };

  const handleSaveUserForm = (userData: AdminToolUpdateUserModel, callback: (error?: string) => void) => {
    if (currentUser) {
      dispatch(
        belAtUpdateUser({
          data: userData,
          userId: currentUser.id,
          callback: saveUserCallback,
        }),
      );
    } else {
      dispatch(
        belAtCreateUser({
          data: userData,
          callback: error => {
            if (error) {
              callback(error);
              return;
            }
            saveUserCallback();
          },
        }),
      );
    }
  };

  const handleDeleteUsers = () => {
    dispatch(
      belAtDeleteUser({
        usersIds: selectedEntitiesIds,
        callback: () => {
          fetchData(query);
          setSelectedEntitiesIds([]);
          setRemoveAlert(false);
        },
      }),
    );
  };

  const handleChangeUserStatus = (is_active: boolean) => {
    dispatch(
      belAtDeactivateUser({
        usersIds: selectedEntitiesIds,
        is_active,
        callback: () => {
          setSelectedEntitiesIds([]);
          fetchData(query);
        },
      }),
    );
  };

  const _isDeactiveBtnDisabled = (is_active: boolean) => {
    if (data) {
      const selectedUsers = selectedEntitiesIds.map(id => {
        let user = data.result.find(user => user.id === id);
        if (user) return user;
      });
      const has_deactivated = !!selectedUsers.find(user => user?.is_active === is_active);
      return !selectedEntitiesIds.length || !has_deactivated;
    }
    return true;
  };

  const renderContent = () => {
    if (!data) return <Loader fitParent />;

    return (
      <>
        <div className="b-page__controls">
          <div className="b-tableToolContainer">
            <button
              disabled={_isDeactiveBtnDisabled(false)}
              onClick={() => handleChangeUserStatus(true)}
              className="b-tableTool -userOn -mobView -mr"
            >
              {t('common:btn.activate')}
            </button>
            <button
              disabled={_isDeactiveBtnDisabled(true)}
              onClick={() => handleChangeUserStatus(false)}
              className="b-tableTool -userOff -mobView -mr"
            >
              {t('common:btn.deactivate')}
            </button>
            <button
              onClick={() => setRemoveAlert(true)}
              disabled={!selectedEntitiesIds.length}
              className="b-tableTool -delete -mobView -mr"
            >
              {t('common:btn.delete')}
            </button>
            <button onClick={() => setIsUserFormOpen(true)} className="b-tableTool -addPlus -mobView -mr">
              {t('common:btn.new-user')}
            </button>
            <div className="-mobile b-tableTool -noIcon -mobView b-adminToolUsers__checkAllMob">
              <Checkbox
                noText
                checked={!!selectedEntitiesIds.length && selectedEntitiesIds.length === data.result.length}
                onChange={handleSelectAll}
              />
            </div>
            <div className="-desktop">
              <button
                onClick={() => manipulateModal(Modals.isFilter, true)}
                className={`
                    b-tableTool
                    -mr
                    -filter
                    -colorGrey
                     ${!isEmptyObject(query?.filters) ? '-active' : ''}
                  `}
              >
                {t('common:btn.filter')}
              </button>
            </div>
          </div>
          <div className="-mobile b-tableToolContainer -mobMb">
            <SortModalButton
              setSortingData={setSortingData}
              sortingData={sortingData}
              columns={columns}
              query={query}
              handleChangeSortField={handleChangeSortField}
              handleSort={handleSort}
            />
            <button
              onClick={handleChangeDirection}
              type="button"
              className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                    ${query.sorting?.direction === 'DESC' && '-active'}
                  `}
            >
              {t('common:btn.a-z-order')}
            </button>
            <button
              onClick={() => manipulateModal(Modals.isFilter, true)}
              className={`
                    b-tableTool
                    -mr
                    -filter
                    -colorGrey
                    ${!isEmptyObject(query?.filters) ? '-active' : ''}
                  `}
            >
              {t('common:btn.filter')}
            </button>
          </div>
          <div className="b-page__search">
            <form action="#" className="searchForm">
              <input
                type="search"
                value={query.search}
                onChange={e => handleSearch(e.target.value)}
                placeholder={t('common:label.search')}
              />
              <span className="searchForm__icon" />
            </form>
          </div>
        </div>

        <div className="-desktop">
          <DndProvider backend={HTML5Backend}>
            <DataGrid
              onRowClick={handleClickUser}
              onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
              columns={columns}
              onColumnResize={onColumnResize}
              sortColumns={query.sorting ? [query.sorting] : []}
              rows={data.result}
              onSelectedRowsChange={handleSelectOne}
              className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
              style={{
                height: ((data.result.length || 0) + 1) * 60,
              }}
              rowHeight={60}
              selectedRows={new Set(selectedEntitiesIds)}
              rowKeyGetter={(row: AdminToolUserModel) => row.id}
            />
          </DndProvider>
          <Pagination data={data} handleChangePageSize={handleChangePageSize} changePage={handleChangePage} />
        </div>
        {isMobile && (
          <div className="-mobile b-adminToolUsers__mobileList">
            <InfiniteScroll
              dataLength={infiniteList.length}
              next={fetchMoreMobile}
              hasMore={!(data.current_page === data.total_pages)}
              loader={<h4>{t('common:label.loading')}...</h4>}
            >
              {infiniteList.map((user: AdminToolUserModel) => {
                const isChecked = !!selectedEntitiesIds.find(f => f === user.id);

                return (
                  <div
                    key={`user-${user.id}`}
                    className="b-at-user b-panel"
                    onClick={() => handleClickUser(user)}
                  >
                    <label className="checkbox b-at-user__check" onClick={e => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={e => handleSelectOneMob(e, user.id)}
                      />
                      <span className="checkbox_fakeInput" />
                    </label>
                    <span className="b-at-user__userName">
                      {user.first_name} {user.last_name}
                    </span>
                    <ul className="b-at-user__list">
                      <li>{user.email}</li>
                      <li>
                        {user.user_roles.map((role, index) => {
                          if (index === user.user_roles.length - 1) {
                            return <div key={`role-${index}`}>{role.label}</div>;
                          }
                          return <div key={`role-${index}`}>{role.label}, </div>;
                        })}
                      </li>
                      <li>{convertUserStatus(user.status, user.status_date)}</li>
                    </ul>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <h1 className={'b-page__title'}>{t('common:label.admin-tool')}</h1>
        <BelovedAdminToolsTabs activeItemAlias={'users'} />
        {renderContent()}
      </main>
      {isUserFormOpen && availableRoles && (
        <UserForm
          availableRoles={availableRoles}
          onClose={handleCloseUserForm}
          userData={currentUser}
          onSave={handleSaveUserForm}
        />
      )}
      {state.modals.isFilter ? (
        <Filters
          isFiltersChanged={isFiltersChanged}
          handleApplyFilter={handleApplyFilter}
          handleSelectFilter={handleSelectFilter}
          filterData={filterData}
          onClose={() => manipulateModal(Modals.isFilter, false)}
        />
      ) : null}
      {removeAlert ? (
        <Alert
          isOpen={removeAlert}
          onRequestClose={() => setRemoveAlert(false)}
          title={t('awa:N33.title')}
          text={
            <>
              <p>{t('awa:N33.msg')}</p>
            </>
          }
          buttons={{
            left: {
              title: t('common:btn.cancel'),
              type: 'white',
              onClick: () => setRemoveAlert(false),
            },
            right: {
              title: t('common:btn.delete'),
              type: 'orange-light',
              onClick: handleDeleteUsers,
            },
          }}
        />
      ) : null}
    </>
  );
};

export default AdminToolUsers;
