import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Modal } from '../../../common';
import { Button, Input } from '../../../controls';
import styles from './AddNewUser.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddNewUserOrganizationModel,
  CreateOrgModel,
  ISelectAvailableOrg,
  KeyValueModel,
  IRouteParamsBase,
} from '../../../../models';
import { StateType } from '../../../../store/reducers';
import { email, validate } from '../../../../helpers';
import { ROOTS } from '../../../../routes/routes';
import { addNewUser, getAvailableOrgs } from '../../../../store/organization/organizationActions';
import AddAffiliation from '../AddAffiliation/AddAffiliation';
import { Modals, ModalsModel } from '../../../../constants';
import { useTranslation } from 'react-i18next';

interface AddUserModel {
  first_name: string;
  last_name: string;
  email: string;
  org_id?: number | null;
  action: 'add' | 'remove';
}

interface IState {
  formData: AddUserModel;
  errors: KeyValueModel<string> | null;
  orgs: CreateOrgModel[];
  modals: ModalsModel;
}

const validatorForSave = {
  required: ['first_name', 'last_name', 'email'],
};

const AddNewUser = () => {
  const validator = {
    required: ['first_name', 'last_name', 'email'],
    custom: [email(['email'])],
  };
  const { t } = useTranslation();
  const { orgId } = useParams<IRouteParamsBase>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [availableOrgs, setAvailableOrgs] = useState<ISelectAvailableOrg[]>([]);
  const userRoles = useSelector((state: StateType) => state.utils.data.userRoles);
  const userTypesRoles = useSelector((state: StateType) => state.utils.data.userTypesRoles);
  const currentOrg = useSelector((state: StateType) => state.organization.currentOrg);

  const initialState: IState = {
    formData: {
      first_name: '',
      last_name: '',
      email: '',
      action: 'add',
    },
    errors: {},
    orgs: [],
    modals: {
      isAddAffiliation: false,
    },
  };

  const [state, setState] = useState<IState>(initialState);
  const { errors, orgs, modals } = state;

  useEffect(() => {
    if (!currentOrg) {
      return;
    }
    setState({
      ...state,
      formData: {
        ...state.formData,
        org_id: currentOrg.id,
      },
    });
  }, [currentOrg]);

  useEffect(() => {
    const url = location.pathname.split('/');
    const organization_id = url[url.findIndex(f => f === 'org') + 1];
    const id = url[url.findIndex(f => f === 'user-profile') + 1];
    dispatch(
      getAvailableOrgs({
        data: {
          organization_id,
          id,
        },
        callback: getAvailableOrgsSuccess,
      }),
    );
  }, []);

  const getAvailableOrgsSuccess = (res: ISelectAvailableOrg[]) => {
    setAvailableOrgs(res);
  };

  const handleChangeInput = (name: string, value: string) => {
    if (errors && errors[name]) {
      delete errors[name];
    }
    setState({
      ...state,
      formData: {
        ...state.formData,
        [name]: value,
      } as any,
      errors: errors,
    });
  };

  const handleSubmit = () => {
    let { errors }: any = validate(validator, state.formData) || {};
    if (!orgs.length) {
      errors.orgs = 'You need add at least one affiliation';
    } else {
      delete errors.orgs;
    }
    setState({
      ...state,
      errors: errors,
    });
    if (!Object.values(errors || {}).length) {
      dispatch(
        addNewUser({
          organization_id: (currentOrg && String(currentOrg.id)) || '',
          data: {
            ...state.formData,
            organizations: orgs.map(org => {
              return {
                organization_id: org.org_id,
                user_type_id: org.type_id,
                user_role_id: org.role_id,
                division: org.division,
              };
            }) as AddNewUserOrganizationModel[],
          },
          callback: (result, err) => {
            if (!err) {
              history.push(ROOTS.ADDED_NEW_USER_SUCCESS.replace(':orgId', `${orgId}`));
            }
          },
        }),
      );
    }
  };

  const handleCancel = () => {
    history.go(-1);
  };

  const openModal = (modalName: string) => {
    setState({
      ...state,
      modals: {
        ...state.modals,
        [modalName]: true,
      },
    });
  };

  const closeModal = (modalName: string) => {
    setState({
      ...state,
      modals: {
        ...state.modals,
        [modalName]: false,
      },
    });
  };

  const saveAffiliation = (formData: CreateOrgModel) => {
    const orgs = [...state.orgs];
    delete errors?.orgs;
    orgs.push(formData);
    setState({
      ...state,
      orgs: orgs,
      modals: {
        isAddAffiliation: false,
      },
      errors: errors,
    });
  };

  const handleRemoveAffiliation = (org: CreateOrgModel, index: number) => {
    const orgs = [...state.orgs];
    orgs.splice(index, 1);
    setState({
      ...state,
      orgs: orgs,
    });
  };

  const valid = validate(validatorForSave, state.formData);
  const isSaveActive = !Object.values(valid.errors || {}).length && orgs.length;

  return (
    <>
      <main className={'pageContainer pageContainer__content'}>
        <div>
          <h1 className={'pageContainer__title'}>{t('common:headlines.add-new-user')}</h1>
          <div>
            <div className={styles.form}>
              <div className={styles.form__part}>
                <Input
                  name={'first_name'}
                  error={(errors && errors.first_name) || ''}
                  value={state.formData.first_name || ''}
                  type={'text'}
                  placeholder={t('common:label.first-name')}
                  ariaLabel="First Name"
                  handleChange={(value: string) => handleChangeInput('first_name', value)}
                  autoFocus
                />
              </div>
              <div className={styles.form__part}>
                <Input
                  name={'last_name'}
                  error={(errors && errors.last_name) || ''}
                  value={state.formData.last_name || ''}
                  type={'text'}
                  placeholder={t('common:label.last-name')}
                  ariaLabel="Last Name"
                  handleChange={(value: string) => handleChangeInput('last_name', value)}
                />
              </div>
              <div className={styles.form__part}>
                <Input
                  name={'email'}
                  error={(errors && errors.email) || ''}
                  value={state.formData.email || ''}
                  type={'text'}
                  placeholder={t('common:label.email-address')}
                  ariaLabel="Email Address"
                  handleChange={(value: string) => handleChangeInput('email', value)}
                />
              </div>
            </div>
            <div className={styles.form__additional}>
              <div className={styles.form__additionalCol}>
                <div className={styles.form__suborganization}>
                  <span className={styles.form__suborganization_opener}>
                    {t('common:headlines.organization-&-affiliation')}
                  </span>
                  <div className={'error-notification'}>
                    <span className={'error-notification-text'}>{errors?.orgs}</span>
                  </div>
                </div>
              </div>
            </div>
            {(orgs.length && (
              <div className={`${styles.form} ag-userlist`}>
                {orgs.map((org, i) => {
                  const getAffiliation: ISelectAvailableOrg | null =
                    availableOrgs.find(f => f.value === org.org_id) || null;
                  const getUserRole = userRoles?.find(f => f.id === org.role_id);
                  const getUserTypeRole = userTypesRoles?.find(f => f.id === org.type_id);
                  return (
                    <div key={`org-${org.org_id}`} className={`ag-userlist_item ${styles.form__affiliation}`}>
                      <h2 className="ag-userlist_title">{getAffiliation?.parent_org_name}</h2>
                      <div className={`ag-userlist_box`}>
                        <div className={`${styles.btn__affiliation}`}>
                          <button
                            type="button"
                            aria-label="Delete"
                            className={`${styles.ag_icon} ${styles.ag_icon_delete}`}
                            onClick={() => handleRemoveAffiliation(org, i)}
                          />
                        </div>
                        <table className="ag-userlist_list">
                          <tbody>
                            <tr>
                              <th>{t('common:column.org-affiliation')}:</th>
                              <td>{getAffiliation?.label}</td>
                            </tr>
                            <tr>
                              <th>{t('common:column.user-type')}:</th>
                              <td>{getUserTypeRole?.name}</td>
                            </tr>
                            <tr>
                              <th>Role:</th>
                              <td>{getUserRole?.name}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                })}
              </div>
            )) ||
              null}
            <div className={styles.form__part}>
              <button className={styles.form__addLink} onClick={() => openModal(Modals.isAddAffiliation)}>
                {t('common:btn.add-affiliation')}
              </button>
            </div>
            <div className={styles.form__additional}>
              <div className={styles.form__buttons}>
                <Button
                  onPress={handleCancel}
                  title={t('common:btn.cancel')}
                  size={'large'}
                  type={'transparency'}
                />
                <Button
                  onPress={handleSubmit}
                  title={t('common:btn.save')}
                  size={'large'}
                  type={'orange-light'}
                  disabled={!isSaveActive}
                  enableEnter
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      {modals.isAddAffiliation ? (
        <Modal
          width={545}
          onClose={() => closeModal(Modals.isAddAffiliation)}
          className={styles.modal}
          title={t('common:headlines.add-affiliation')}
        >
          <AddAffiliation
            handleClosePopup={() => closeModal(Modals.isAddAffiliation)}
            saveAffiliation={saveAffiliation}
            availableOrgs={availableOrgs.filter(f => !orgs.find(o => o.org_id === f.value))}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default AddNewUser;
