import React, { FC } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface IProps {
  value: number | string;
}

const ProgressPie: FC<IProps> = ({ value }) => {
  return (
    <CircularProgressbar
      value={typeof value === 'number' ? value : 0}
      text={typeof value === 'number' ? `${value}%` : `${value}`}
      strokeWidth={10}
      styles={buildStyles({
        textColor: 'var(--primay-50)',
        pathColor: 'var(--primay-50)',
        trailColor: 'var(--trail)',
      })}
    />
  );
};

export default ProgressPie;
