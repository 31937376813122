import React, { useEffect, useState } from 'react';
import JoinOrganizationView from './JoinOrganizationView';
import { email, isValidFields, validate } from '../../../../helpers';
import { useDispatch } from 'react-redux';
import { JoinOrganizationModel, KeyValueModel } from '../../../../models';
import { useHistory } from 'react-router-dom';
import { joinOrganization } from '../../../../store/organization/organizationActions';
import { changeAuthStepAction } from '../../../../store/profile/profileActions';
import AuthNavigationHelper from '../../../../helpers/AuthNavigationHelper';
import { ROOTS } from '../../../../routes/routes';

const sameValidator = {
  required: ['name', 'email', 'division'],
  custom: [email(['email'])],
};

const associationValidator = {
  required: ['name', 'email'],
  custom: [email(['email'])],
};

interface IProps {
  title: string;
  onBackCallback?: () => void;
  mode: string;
  onSubmitCallback: (data: any) => void;
  parentErrors: KeyValueModel<string> | null;
}

interface IState {
  formData: JoinOrganizationModel;
}

const JoinOrganizationContainer = ({
  title,
  onBackCallback,
  mode,
  onSubmitCallback,
  parentErrors,
}: IProps) => {
  const [state, setState] = useState<IState>({
    formData: {
      name: '',
      division: '',
      email: '',
    },
  });

  const [errors, setErrors] = useState({} as any);
  const [serverError, setServerError] = useState<string>('');
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (parentErrors) {
      setErrors(parentErrors);
    }
    return () => {
      setErrors({});
    };
  }, [parentErrors]);

  const handleChangeInput = (name: string, value: string) => {
    setState({
      formData: {
        ...state.formData,
        [name]: value,
      },
    });
    if (errors[name]) {
      delete errors[name];
      setErrors(errors);
    }
  };

  const onSubmit = () => {
    const { errors } = validate(mode ? associationValidator : sameValidator, state.formData);
    if (Object.values(errors || {}).length) {
      setErrors(errors);
      return;
    }

    const joinOrganizationResult = (data: any, errors: ErrorEvent) => {
      if (errors) {
        setServerError('Entered data is incorrect');
        return;
      }

      if (data.next_step === 5) {
        history.push(ROOTS.REQUEST_SUBMITTED);
      } else {
        dispatch(changeAuthStepAction(data.next_step));
        const url = AuthNavigationHelper.getUrl(data.next_step);
        history.push(url);
      }
    };

    if (mode) {
      onSubmitCallback(state.formData);
      return;
    }

    dispatch(
      joinOrganization({
        callback: joinOrganizationResult,
        data: state.formData,
      }),
    );
  };

  const onBack = () => {
    if (onBackCallback) {
      onBackCallback();
      return;
    }
    history.go(-1);
  };

  const isDisableButton = isValidFields(mode ? associationValidator : sameValidator, state.formData);

  return (
    <JoinOrganizationView
      formData={state.formData}
      handleChangeInput={handleChangeInput}
      errors={errors}
      onSubmit={onSubmit}
      onBack={onBack}
      title={title}
      serverError={serverError}
      mode={mode}
      isDisableButton={isDisableButton}
    />
  );
};

export default JoinOrganizationContainer;
