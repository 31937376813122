import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ElmGuide, HeadlineOrganization, OrgBreadcrumbs, OrgProfileTabs } from '../../../../common';
import {
  ElmModel,
  EquityAuditModel,
  IRouteParamsBase,
  ISelectOptions,
  OrganizationDetailsModel,
  PaginationModel,
} from '../../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { Button, ElmStatusLabel, Link, Loader, SimpleTooltip } from '../../../../controls';
import { closeAdminElm, getElmList, publishElmReport } from '../../../../../store/elm/elmActions';
import { useHistory, useParams } from 'react-router';
import { ROOTS } from '../../../../../routes/routes';
import Illustration from '../../../../../assets/images/empty-ea-list.png';
import Illus from '../../../../../assets/images/icons/illus_next_steps.png';
import { useRole } from '../../../../../helpers/roles';
import { ElmRoles, ElmStatuses, ElmUserStatuses } from '../../../../../constants/elm';
import {
  basicDateFormat,
  debriefFormat,
  hasElmRole,
  isElmUser,
  showRescheduleAlert,
  showViewDebriefBtn,
} from '../../../../../helpers';
import { useTranslation } from 'react-i18next';
import { QueryParamsModel } from '../../../../../hooks/common/use-table-data';
import { showAlert } from '../../../../common/Alert/RemoteAlert';
import { ScheduleAlias } from '../../../../../constants';

interface IState {
  data: PaginationModel<ElmModel[]> | null;
  query: QueryParamsModel;
}

const ElmList = () => {
  const { t } = useTranslation();
  const { orgId } = useParams<IRouteParamsBase>();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrg: OrganizationDetailsModel | null = useSelector(
    (state: StateType) => state.organization.currentOrg,
  );

  let [guideVisible, setGuideVisible] = useState(false);
  const access = useRole();
  const isPm = access(['project_manager']);

  const initialState = {
    data: null,
    query: {
      sorting: null,
      search: '',
      page_size: 15,
      page: 1,
    },
  };

  let [state, setState] = useState<IState>(initialState);
  const { data } = state;

  useEffect(() => {
    if (currentOrg) {
      setState(initialState);
      dispatch(
        getElmList({
          params: initialState.query,
          callback: data => {
            if (data) {
              setState(prevState => ({ ...prevState, data }));
            }
          },
        }),
      );
    }
    return () => {
      setState(initialState);
    };
  }, [currentOrg?.id]);

  const fetchMoreMobile = () => {
    dispatch(
      getElmList({
        params: {
          ...state.query,
          page: state.query.page + 1,
        },
        callback: data => {
          if (data) {
            setState(prevState => {
              if (prevState.data) {
                return {
                  ...prevState,
                  data: {
                    ...data,
                    result: [...prevState.data.result, ...data.result],
                  },
                  query: {
                    ...prevState.query,
                    page: prevState.query.page + 1,
                  },
                };
              }
              return prevState;
            });
          }
        },
      }),
    );
  };

  const handleNewElm = () => {
    history.push(ROOTS.CREATE_ELM_INTRO.replace(':orgId', orgId));
  };

  const navToMyReport = (elmId: number) => {
    history.push(ROOTS.ELM_MY_REPORT.replace(':orgId', orgId).replace(':elmId', String(elmId)));
  };

  const navToElmDetails = (elmId: number) => (e: any) => {
    e.preventDefault();
    history.push(ROOTS.ELM_DETAILS.replace(':orgId', orgId).replace(':elmId', String(elmId)));
  };

  const navToOrgReport = (elmId: number) => {
    history.push(ROOTS.ELM_ORG_REPORT_RESP.replace(':orgId', orgId).replace(':elmId', String(elmId)));
  };

  const handleTakeElm = (elmId: number) => {
    history.push(ROOTS.TAKE_ELM.replace(':orgId', orgId), { elmId });
  };

  const handleCloseElm = (elm: ElmModel) => {
    const action = () => {
      dispatch(
        closeAdminElm({
          elmId: elm.id,
          callback: data => {
            setState(prevState => {
              if (prevState.data) {
                const newList = prevState.data.result.map(item => {
                  if (item.id === data.id) {
                    return data;
                  }
                  return item;
                });
                return {
                  ...prevState,
                  data: {
                    ...prevState.data,
                    result: newList,
                  },
                };
              }
              return prevState;
            });
          },
        }),
      );
    };

    showAlert({
      title: t('main:elm-close-admin.title'),
      text: (
        <>
          <p>{t('main:elm-close-admin.msg.p1')}</p>
          <p>{t('main:elm-close-admin.msg.p2')}</p>
        </>
      ),
      buttons: {
        left: {
          title: t('common:btn.no'),
          type: 'transparency',
        },
        right: {
          title: t('common:btn.yes'),
          type: 'primary',
          onClick: action,
        },
      },
    });
  };

  const handlePublishElmReport = (elm: ElmModel) => {
    dispatch(
      publishElmReport({
        elmId: elm.id,
        callback: data => {
          setState(prevState => {
            if (prevState.data) {
              const newList = prevState.data.result.map(item => {
                if (item.id === data.id) {
                  return data;
                }
                return item;
              });
              return {
                ...prevState,
                data: {
                  ...prevState.data,
                  result: newList,
                },
              };
            }
            return prevState;
          });
        },
      }),
    );
  };

  const handleScheduleDebrief = (elm: ElmModel) => {
    history.push(
      ROOTS.DEBRIEF_SCHEDULING.replace(':debriefAlias', ScheduleAlias.elm)
        .replace(':orgId', orgId)
        .replace(':id', `${elm.id}`),
    );
  };

  const renderElmBtn = (elm: ElmModel) => {
    let links: { label: string; onClick: () => any; disabled?: boolean }[] = [];
    let tips: string[] = [];

    if (elm.status === ElmStatuses.IN_PROGRESS) {
      if (hasElmRole(elm, ElmRoles.USER)) {
        if (elm.user_elm_rel.elm_user_status_id < ElmUserStatuses.COMPLETED) {
          const link = {
            label: t('common:btn.start-my-elm'),
            onClick: () => handleTakeElm(elm.id),
          };
          if (elm.user_elm_rel.elm_user_status_id >= ElmUserStatuses.STARTED) {
            link.label = t('common:btn.resume-my-elm');
          }
          links.push(link);
        } else if (elm.user_elm_rel.elm_user_status_id >= ElmUserStatuses.COMPLETED) {
          links.push({
            label: t('common:btn.view-my-report'),
            disabled: true,
            onClick: () => null,
          });
          // tips.push(t('main:elm-my-report-disabled-text'));
          tips.push(t('awa:M171.msg'));
        }
      }
    } else if (elm.status === ElmStatuses.COMPLETED) {
      if (isPm) {
        links.push({
          label: t('common:btn.view-report'),
          onClick: () => null,
          disabled: true,
        });
        links.push({
          label: t('common:btn.close-admin'),
          onClick: () => handleCloseElm(elm),
        });
      } else if (hasElmRole(elm, ElmRoles.USER)) {
        if (elm.user_elm_rel.elm_user_status_id >= ElmUserStatuses.COMPLETED) {
          links.push({
            label: t('common:btn.view-my-report'),
            disabled: true,
            onClick: () => null,
          });
          // tips.push(t('main:elm-my-report-disabled-text'));
          tips.push(t('awa:M171.msg'));
        }
      }
    } else if (elm.status === ElmStatuses.COMPLETED_LOCKED) {
      if (isPm) {
        if (!elm.is_no_org_report) {
          links.push({
            label: t('common:btn.view-organization-report'),
            disabled: !elm.has_organization_report,
            onClick: () => navToOrgReport(elm.id),
          });
        }
        if (elm.is_user_report_published) {
          links.push({
            label: t('common:btn.report-published'),
            disabled: true,
            onClick: () => null,
          });
        } else {
          links.push({
            disabled: !elm.submitted,
            label: t('common:btn.publish-report'),
            onClick: () => handlePublishElmReport(elm),
          });
        }
        if (elm.status === ElmStatuses.COMPLETED_LOCKED) {
          links.push({
            label: elm.debrief_scheduled_date.book_full_date
              ? t('common:btn.reschedule-debrief')
              : t('common:btn.schedule-debrief'),
            onClick: () => handleScheduleDebrief(elm),
          });
        }
        if (hasElmRole(elm, ElmRoles.USER)) {
          if (elm.user_elm_rel.has_report) {
            links.push({
              label: t('common:btn.view-my-report'),
              onClick: () => navToMyReport(elm.id),
            });
          }
        }
      } else {
        if (hasElmRole(elm, ElmRoles.USER)) {
          // if elm completed and user took and completed elm
          if (elm.user_elm_rel.elm_user_status_id >= ElmUserStatuses.COMPLETED) {
            const link: any = {
              label: t('common:btn.view-my-report'),
              disabled: true,
              onClick: () => {},
            };
            if (elm.user_elm_rel.has_report) {
              link.onClick = () => navToMyReport(elm.id);
              link.disabled = false;
            } else {
              // tips.push(t('main:elm-my-report-disabled-text'));
              tips.push(t('awa:M171.msg'));
            }
            links.push(link);
          }
        } else if (hasElmRole(elm, ElmRoles.MANAGER)) {
          links.push({
            label: t('common:btn.view-report'),
            disabled: !elm.has_organization_report,
            onClick: () => navToOrgReport(elm.id),
          });
        }
      }
    }
    return (
      <>
        <div className="b-elmItem__links">
          {links.map(link => {
            return (
              <Link
                key={`link-${Math.random()}`}
                onClick={link.onClick}
                className={`b-panelItem__link ${link.disabled ? '-disabled' : ''}`}
              >
                {link.label}
              </Link>
            );
          })}
        </div>
        {tips.length
          ? tips.map((text, index) => (
              <div className="b-elmItem__tipText" key={`text-${text}-${index}`}>
                {text}
              </div>
            ))
          : null}
      </>
    );
  };

  const renderMetrics = (elm: ElmModel) => {
    let metrics: ISelectOptions[] = [];
    if (elm.status < ElmStatuses.IN_PROGRESS) {
      if (elm.import_file) {
        metrics.push({ label: t('common:status.invited'), value: elm.invited });
        metrics.push({ label: t('common:status.registered'), value: elm.registered });
      } else {
        metrics.push({
          label: t('common:headlines.user-list'),
          value: t('main:list-not-uploaded') as string,
        });
      }
    } else if (elm.status >= ElmStatuses.IN_PROGRESS) {
      metrics.push({ label: t('common:status.submitted'), value: elm.submitted });
      if (elm.status === ElmStatuses.IN_PROGRESS) {
        metrics.push({ label: t('common:status.in-progress'), value: elm.in_progress });
      } else {
        metrics.push({
          label: t('common:status.not-completed'),
          value: elm.not_completed,
        });
      }
      metrics.push({ label: t('common:status.not-started'), value: elm.not_started });
    }
    if (elm.debrief_scheduled_date.book_full_date) {
      metrics.push({
        label: t('common:label.debrief'),
        value: debriefFormat(elm.debrief_scheduled_date.book_full_date),
      });
    }

    return metrics.map(item => {
      return (
        <div key={`metric-${Math.random()}`} className="b-elmItem__row">
          <div className="b-elmItem__title">{item.label}</div>
          <div className="b-elmItem__val">{item.value}</div>
        </div>
      );
    });
  };

  const renderContent = () => {
    if (!data) return <Loader />;

    if (data.result.length) {
      return (
        <div className="b-infScrollWrapper">
          <InfiniteScroll
            dataLength={data.result.length}
            next={fetchMoreMobile}
            hasMore={!(data.current_page === data.total_pages)}
            loader={<h4 className="b-no-content">{t('common:label.loading')}...</h4>}
          >
            <div className="b-infScrollWrapper__normalize">
              <div className="b-page__cardsList">
                {data.result.map((elm: ElmModel) => {
                  return (
                    <div key={`elm-${elm.id}`} className="b-panelItem b-page__cardItem">
                      <h2 className="b-panelItem__statusContainer">
                        <SimpleTooltip
                          id={`${elm.id}-status-tt`}
                          title={elm.status >= ElmStatuses.COMPLETED ? t('awa:TT1.msg') : ''}
                        >
                          <ElmStatusLabel elm={elm} />
                        </SimpleTooltip>
                        {isPm && (
                          <button className="b-panelItem__details" onClick={navToElmDetails(elm.id)}>
                            {t('common:btn.details')}
                          </button>
                        )}
                      </h2>
                      <div className="b-elmItem__row">
                        <div className="b-elmItem__title">{t('common:headlines.start-date')}</div>
                        <div className="b-elmItem__val">{basicDateFormat(elm.start_date)}</div>
                      </div>
                      <div className="b-elmItem__row">
                        <div className="b-elmItem__title">{t('common:headlines.end-date')}</div>
                        <div className="b-elmItem__val">{debriefFormat(elm.end_date)}</div>
                      </div>
                      {!isElmUser(elm, !!isPm) ? (
                        <div className="b-elmItem__row">
                          <div className="b-elmItem__title">{t('common:headlines.associate-ea')}</div>
                          <div className="b-elmItem__val">
                            {elm.equity_audit_name ? (
                              <>
                                {t('common:status.completed-on')} {basicDateFormat(elm.equity_audit_name)}
                              </>
                            ) : (
                              t('common:column.n-a')
                            )}
                          </div>
                        </div>
                      ) : null}
                      {!isElmUser(elm, !!isPm) && renderMetrics(elm)}
                      {hasElmRole(elm, ElmRoles.USER) && elm.status >= ElmStatuses.IN_PROGRESS ? (
                        <>
                          {elm.user_elm_rel.submitted_date ? (
                            <div className="b-elmItem__row">
                              <div className="b-elmItem__title font-weight-bold font-green">
                                {t('common:label.response-submitted')}
                              </div>
                              <div className="b-elmItem__val font-green">
                                {basicDateFormat(elm.user_elm_rel.submitted_date)}
                              </div>
                            </div>
                          ) : (
                            <div className="font-s font-weight-bold mb-3 font-primary80">
                              {t('common:label.response-not-submitted')}
                            </div>
                          )}
                          {elm.status === ElmStatuses.COMPLETED ? (
                            <div className="b-elmItem__row">
                              <div className="b-elmItem__val font-weight-bold">
                                <a
                                  rel="noreferrer"
                                  target="_blank"
                                  className="b-panelItem__details"
                                  href={`mailto:${
                                    currentOrg?.org_pms?.[0]?.email || 'support@awabybeloved.freshdesk.com'
                                  }?subject=Awa ELM Information request`}
                                >
                                  {t('common:label.contact-organization-pm')}
                                </a>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ) : null}
                      {renderElmBtn(elm)}
                    </div>
                  );
                })}
              </div>
            </div>
          </InfiniteScroll>
        </div>
      );
    }

    if (isPm) {
      if (currentOrg?.credits.elm) {
        return (
          <div className="b-elmBox">
            <img className="b-elmBox__illus" src={Illustration} alt={t('awa:AT37')} />
            <h2 className="b-elmBox__title">{t('awa:M69.title')}</h2>
            <div className="b-EAListPlaceholder__text">
              <p>{t('awa:M69.msg.p1')}</p>
              <p>
                {t('awa:M69.msg.p2')}
                <b className="b-elmBox__mark">{t('awa:M69.msg.p3')}</b>
                {t('awa:M69.msg.p4')}
              </p>
              <p>
                {t('awa:M69.msg.p5')}{' '}
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    setGuideVisible(true);
                  }}
                >
                  {t('awa:M69.msg.p6')}
                </a>
                .
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <div className="b-nextSteps">
            <img
              className="b-nextSteps__illustration"
              width={122}
              height={122}
              src={Illus}
              alt={t('awa:AT25')}
            />
            <div className="b-nextSteps__holder">
              <span className="b-nextSteps__subtitle">{t('common:headlines.plan-equity-map')}</span>
              <h2 className="b-nextSteps__title">{t('awa:M73.msg.p1')}</h2>
              <p>{t('awa:M73.msg.p2')}</p>
              <b>{t('awa:M73.msg.p3')}</b>
              <ul className="b-nextSteps__list">
                <li>
                  <div className="b-nextSteps__listIcon -item2" />
                  {t('awa:M73.msg.p4')}
                </li>
                <li>
                  <div className="b-nextSteps__listIcon -item3" />
                  {t('awa:M73.msg.p5')}
                </li>
                <li>
                  <div className="b-nextSteps__listIcon -item1" />
                  {t('awa:M73.msg.p6')}
                </li>
              </ul>
              <a
                rel="noreferrer"
                target="_blank"
                className="b-nextSteps__linkBtn"
                href="mailto:support@awabybeloved.freshdesk.com?subject=Request for ELM Information&body=Dear Beloved Team,%0d%0aI'm interested in learning about the Equity Lens Map tool. Please, contact me via email or via phone <please add your phone number>.%0d%0a%0d%0aThank you!"
              >
                {t('awa:M73.msg.p7')}
              </a>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="b-elmBox">
          <img className="b-elmBox__illus" src={Illustration} alt={t('awa:AT37')} />
          <h2 className="b-elmBox__title">{t('awa:M74.title')}</h2>
          <div className="b-EAListPlaceholder__text">
            <p>{t('awa:M74.msg.p1')}</p>
            <p>
              <b>{t('awa:M74.msg.p2')}</b>
            </p>
            <ul className="b-elmBox__list">
              <li>{t('awa:M74.msg.p3')}</li>
              <li>{t('awa:M74.msg.p4')}</li>
              <li>{t('awa:M74.msg.p5')}</li>
              <li>{t('awa:M74.msg.p6')}</li>
            </ul>
            <p>{t('awa:M74.msg.p7')}</p>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <main className={'b-page'}>
        <div className="b-page__header -desktop">
          <HeadlineOrganization
            orgName={currentOrg?.name || ''}
            orgId={currentOrg?.id || ''}
            headline={t('common:headlines.equity-lens-map')}
            customClasses={'-noDesktopMb'}
          />

          {isPm && currentOrg?.credits.elm && (
            <div className={'b-page__headerBtns'}>
              <Button
                className={'btn -newEA'}
                type={'orange-light'}
                onPress={handleNewElm}
                title={t('common:btn.start-new-elm')}
                iconLeft={<div className="b-equityAudit__btnPlusIc">+</div>}
              />
            </div>
          )}
        </div>
        <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
        <OrgProfileTabs activeItemAlias={'elm'} />
        <div className="b-page__cards">
          {renderContent()}
          {isPm && currentOrg?.credits.elm && (
            <div className="b-elm__mobBtn -mobile">
              <Button
                className={'btn -newEA'}
                type={'orange-light'}
                onPress={handleNewElm}
                title={t('common:btn.start-new-elm')}
                iconLeft={<div className="b-equityAudit__btnPlusIc">+</div>}
              />
            </div>
          )}
        </div>
      </main>
      {guideVisible ? <ElmGuide onClose={() => setGuideVisible(false)} /> : null}
    </>
  );
};

export default ElmList;
