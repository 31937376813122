import {
  ActionModel,
  CoordinatesAddressModel,
  DashboardELMModel,
  DashboardMapModel,
  OrgAddressModel,
  PmAddressModel,
} from '../../models';
import { actionCreator } from '../actionCreator';
import { DashboardEAModel } from '../../models';
import { ReqParamsModel } from '../../hooks/common/use-table-data';

//-----GET_DASHBOARD_ELM----//
export const GET_DASHBOARD_ELM = 'GET_DASHBOARD_ELM';
export const GET_DASHBOARD_ELM_SUCCESS = 'GET_DASHBOARD_ELM_SUCCESS';
export interface GetDashboardElmPayload {
  params?: ReqParamsModel;
  callback?: (res: DashboardELMModel) => void;
}
export const getDashboardElm = actionCreator<GetDashboardElmPayload>(GET_DASHBOARD_ELM);
export const getDashboardElmSuccess = actionCreator<DashboardELMModel>(GET_DASHBOARD_ELM_SUCCESS);

//-----GET_DASHBOARD_ELM----//
export const GET_DASHBOARD_EA = 'GET_DASHBOARD_EA';
export const GET_DASHBOARD_EA_SUCCESS = 'GET_DASHBOARD_EA_SUCCESS';
export interface GetDashboardEAPayload {
  params?: ReqParamsModel;
  callback?: (res: DashboardEAModel) => void;
}
export const getDashboardEA = actionCreator<GetDashboardEAPayload>(GET_DASHBOARD_EA);
export const getDashboardEASuccess = actionCreator<DashboardEAModel>(GET_DASHBOARD_EA_SUCCESS);

//-----GET_DASHBOARD_MAP----//
export const GET_DASHBOARD_MAP = 'GET_DASHBOARD_MAP';
export const GET_DASHBOARD_MAP_SUCCESS = 'GET_DASHBOARD_MAP_SUCCESS';
export interface GetDashboardMapPayload {
  callback?: (res: CoordinatesAddressModel) => void;
}
export const getDashboardMap = actionCreator<GetDashboardMapPayload>(GET_DASHBOARD_MAP);
export const getDashboardMapSuccess = actionCreator<DashboardMapModel>(GET_DASHBOARD_MAP_SUCCESS);

//-----GET_DASHBOARD_MAP_POINT_DATA----//
export const GET_DASHBOARD_MAP_POINT_DATA = 'GET_DASHBOARD_MAP_POINT_DATA';
export interface GetDashboardMapPointDataPayload {
  callback?: (res: OrgAddressModel | PmAddressModel) => void;
  data: {
    id: number;
    type: 'pm' | 'org';
  };
}
export const getDashboardMapPointData = actionCreator<GetDashboardMapPointDataPayload>(
  GET_DASHBOARD_MAP_POINT_DATA,
);

export type DashboardActionsTypes =
  | ActionModel<typeof GET_DASHBOARD_ELM, GetDashboardElmPayload>
  | ActionModel<typeof GET_DASHBOARD_EA, GetDashboardEAPayload>
  | ActionModel<typeof GET_DASHBOARD_MAP, GetDashboardMapPayload>
  | ActionModel<typeof GET_DASHBOARD_MAP_SUCCESS, DashboardMapModel>
  | ActionModel<typeof GET_DASHBOARD_MAP_POINT_DATA, GetDashboardMapPointDataPayload>
  | ActionModel<typeof GET_DASHBOARD_EA_SUCCESS, DashboardEAModel>
  | ActionModel<typeof GET_DASHBOARD_ELM_SUCCESS, DashboardELMModel>;
