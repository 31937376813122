import React, { useEffect, useState } from 'react';
import { Footer, Header } from '../../../common';
import { Button, Image } from '../../../controls';
import Logo from '../../../../assets/images/icons/_invite_to_sub_org.png';
import styles from './CreateSubOrganizationStyle.module.scss';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import { useHistory } from 'react-router-dom';
import { ROOTS } from '../../../../routes/routes';
import { useTranslation } from 'react-i18next';

const CreateSubOrganizationPreview = () => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const user = useSelector((state: StateType) => state.profile.authUser);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, []);

  useEffect(() => {
    setCurrentUser(user);
  }, [user]);

  const handleNext = () => {
    history.push(ROOTS.SUB_ORGANIZATION_PREVIEW_CREATE, { title: t('common:btn.New-Organization') });
  };
  return (
    <>
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        <main className={'white-container content-center-wrapper'}>
          <div className={'container-page-center'}>
            <div className={'container-row'}>
              <Image width={'190px'} height={'190px'} source={Logo} alt={t('awa:AT19')} />
            </div>
            <div className={'container-row'}>
              <h1 className={`${styles.title}`} style={{ maxWidth: '328px' }}>
                {t('awa:M17.title')}
              </h1>
            </div>
            <div className={'text-container container-row text-center'} style={{ marginTop: '15px' }}>
              <p className={styles.sub_text} style={{ maxWidth: '388px' }}>
                {t('awa:M17.msg', {
                  sub_org_name:
                    currentUser && currentUser.organization_data && currentUser.organization_data.name,
                })}
              </p>
            </div>
            <div className={'container-row'}>
              <Button
                onPress={handleNext}
                title={t('common:btn.next')}
                type={'orange-light'}
                disabled={false}
                className={styles.button}
                enableEnter
              />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default CreateSubOrganizationPreview;
