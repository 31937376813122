import React, { FC, useRef, useState } from 'react';
import { Link } from '../index';
import { ISelectOptions } from '../../../models';
import { useClickOutside } from '../../../hooks/common/useClickOutside';

interface IProps {
  title: string;
  options: ISelectOptions[];
  onChange: (value: ISelectOptions) => void;
}

const LinkSelect: FC<IProps> = ({ title, options, onChange }) => {
  let [open, setOpen] = useState(false);

  const menuRef = useRef(null);
  useClickOutside(menuRef, () => setOpen(false));

  const handleChange = (value: ISelectOptions) => {
    setOpen(false);
    onChange(value);
  };

  return (
    <div ref={menuRef} className="position-relative">
      <Link onClick={() => setOpen(!open)} className="b-link">
        {title}
      </Link>
      {open ? (
        <div className="position-absolute b-linkSelect__options">
          {options.map(item => {
            return (
              <Link
                className="b-linkSelect__item font-grey"
                onClick={() => handleChange(item)}
                key={`${Math.random()}${item.value}`}
              >
                {item.label}
              </Link>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default LinkSelect;
