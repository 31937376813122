import React, { FC } from 'react';
import { ElmModel } from '../../../models';
import { ElmStatuses } from '../../../constants/elm';
import { t } from 'i18next';

interface IProps {
  elm: ElmModel;
}

const ElmStatusLabel: FC<IProps> = ({ elm }) => {
  switch (elm.status) {
    case ElmStatuses.IN_PREPARATION:
      return <div className="b-status -inPrep">{t('common:status.in-preparation')}</div>;
    case ElmStatuses.SCHEDULED:
      return <div className="b-status -prepCompl">{t('common:status.scheduled')}</div>;
    case ElmStatuses.IN_PROGRESS:
      return <div className="b-status -inProgress">{t('common:status.in-progress')}</div>;
    case ElmStatuses.COMPLETED:
      return <div className="b-status -completed">{t('common:status.completed')}</div>;
    case ElmStatuses.COMPLETED_LOCKED:
      return <div className="b-status -completed -locked">{t('common:status.completed')}</div>;
    default:
      return null;
  }
};

export default ElmStatusLabel;
