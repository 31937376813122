import { ActionModel, AvgModel, AvgSubstandardModel } from '../../models';
import { actionCreator } from '../actionCreator';

export interface GetCreditsListPayload extends Partial<Omit<AvgModel, 'id' | 'avgChart'>> {
  id: number | 'compute';
  avgChart: AvgSubstandardModel;
}

//-----SET_ACTIVE_AVG_CHART----//
export const SET_ACTIVE_AVG_CHART = 'SET_ACTIVE_AVG_CHART';
export const setActiveAvgChart = actionCreator<GetCreditsListPayload>(SET_ACTIVE_AVG_CHART);

export interface GetAvgChartPayload {
  id: number;
  isBeloved: boolean;
  org_id: string;
  callback?: (res: AvgModel) => void;
}

export const GET_AVG_CHART = 'GET_AVG_CHART';
export const getAvgChart = actionCreator<GetAvgChartPayload>(GET_AVG_CHART);

export type CreditActionsTypes =
  | ActionModel<typeof GET_AVG_CHART, GetAvgChartPayload>
  | ActionModel<typeof SET_ACTIVE_AVG_CHART, GetCreditsListPayload>;
