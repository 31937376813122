import React from 'react';
import MyAccountTabs from '../../../common/MyAccountTabs/MyAccountTabs';
import styles from './Support.module.scss';
import { useTranslation } from 'react-i18next';

const Support = () => {
  const { t } = useTranslation();
  return (
    <>
      <main className={'pageContainer pageContainer__content'}>
        <h1 className={'pageContainer__title'}>{t('common:headlines.my-account')}</h1>
        <MyAccountTabs activeItemAlias={'support'} />
        <div className={styles.form} />
      </main>
    </>
  );
};

export default Support;
