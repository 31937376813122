import React from 'react';
import { EwpPreparationStepEnum, IRouteParamsBase } from '../../../../../../models';
import { useTranslation } from 'react-i18next';
import Pyramid from '../../../../../../assets/images/ewp-graphics@3x.png';
import { Button } from '../../../../../controls';
import { useHistory } from 'react-router';
import { ROOTS } from '../../../../../../routes/routes';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../../store/reducers';
import EmptyLink from '../../../../../controls/EmptyLink/EmptyLink';

interface IProps {
  setCurrentStep: (step: EwpPreparationStepEnum) => void;
  goPrevStep: () => void;
}

export const PreparationEWPIntroDetails = ({ setCurrentStep, goPrevStep }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { orgId } = useParams<IRouteParamsBase>();
  const user = useSelector((state: StateType) => state.profile.authUser);
  const isBelovedUser = user?.beloved_user;

  const handlePrevStep = (e: any) => {
    e.preventDefault();
    if (history.location.state?.ewpId) {
      if (isBelovedUser) {
        history.push(ROOTS.BELOVED_EWP_DETAILS.replace(':orgId', `${orgId}`));
      } else {
        history.push(ROOTS.EWP_DETAILS.replace(':orgId', `${orgId}`));
      }
      return;
    }
    goPrevStep();
  };

  return (
    <>
      <div className="b-page__header -desktop">
        <h1 className="b-page__title b-ewp__details-title -noDesktopMb">
          <EmptyLink onClick={handlePrevStep} className="b-button-icon-back" />
          {t('common:headlines.introduction-ewp')}
          <EmptyLink onClick={handlePrevStep} className="b-modal__cross" />
        </h1>
      </div>
      <div className="b-page__cards">
        <p className="paragraph mb-4">{t('awa:M134.msg.p1')}</p>
        <p className="paragraph">{t('awa:M134.msg.p2')}</p>
        <div className={'b-ewp__details-pyramid'}>
          <img src={Pyramid} alt={t('common:alt.pyramid')} />
        </div>
        <div className={'b-ewp__details-cards'}>
          <h3>{t('main:ewp-create.intro-details.priorities.title')}</h3>
          <div className={'b-panelItem'}>
            <p className={`paragraph`}>{t('main:ewp-create.intro-details.priorities.msg')}</p>
          </div>
          <h3>{t('main:ewp-create.intro-details.goals.title')}</h3>
          <div className={'b-panelItem'}>
            <p className={`paragraph`}>{t('main:ewp-create.intro-details.goals.msg')}</p>
          </div>
          <h3>{t('main:ewp-create.intro-details.strategies.title')}</h3>
          <div className={'b-panelItem'}>
            <p className={`paragraph`}>{t('main:ewp-create.intro-details.strategies.msg')}</p>
          </div>
          <h3>{t('main:ewp-create.intro-details.key-actions.title')}</h3>
          <div className={'b-panelItem'}>
            <p className={`paragraph`}>{t('main:ewp-create.intro-details.key-actions.msg')}</p>
          </div>
        </div>
        {!history.location.state?.ewpId ? (
          <div className="flex-center mt-5">
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={() => setCurrentStep(EwpPreparationStepEnum.intro1)}
              title={t('common:btn.get-started')}
              enableEnter
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
