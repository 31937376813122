import React from 'react';
import { useHistory } from 'react-router-dom';
import { Image } from '../../../../../controls';
import Logo from '../../../../../../assets/images/icons/invite_to_sub_org.png';
import styles from './SubOrganizationCreatedSuccess.module.scss';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const SubOrganizationCreatedSuccess = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const isNew = new URLSearchParams(history.location.search).get('isNew');

  return (
    <>
      <main className={`${styles.container} ${isMobileOnly ? styles.mobileContainer : ''}`}>
        <div className={`text-container`}>
          <div className={'container-row'}>
            <Image width={'190px'} height={'190px'} source={Logo} alt={t('awa:AT73')} />
          </div>
          <div className={'container-row'}>
            <h1 className={styles.title}>{t('awa:M12.title')}</h1>
          </div>
          <div className={'container-row'}>
            {isNew === 'true' ? (
              <p className={styles.text}>{t('awa:M12.msg.p1')}</p>
            ) : (
              <p className={styles.text}>{t('awa:M12.msg.p2')}</p>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default SubOrganizationCreatedSuccess;
