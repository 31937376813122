import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DataGrid, { SortDirection } from 'react-data-grid';
import { ColumnType, KeyValueModel, PaginationModel, TableDataEntities } from '../../../models';
import { Pagination } from '../index';
import { useIsDarkMode } from '../../../hooks/common/use-is-dark-mode';
import { useTableDataV2 } from '../../../hooks/common/use-table-data-v2';
import { Loader } from '../../controls';
import useTableConfig from '../../../hooks/common/use-table-config';

interface IProps<T = any> {
  baseUrl: string;
  entityName: TableDataEntities;
  onRowClick?: (row: T) => void;
  extraData?: KeyValueModel<any>;
  cols: ColumnType<T>[];
  handleSelectOne: (selectedRows: Set<number>) => void;
  selectedEntitiesIds: number[];
}

export function DataTable<T extends { id: number }>(props: IProps<T>) {
  const { baseUrl, entityName, onRowClick, cols, handleSelectOne, selectedEntitiesIds, extraData } = props;
  const { isDarkMode } = useIsDarkMode();

  const { query, data, handleChangePage, handleChangePageSize, handleSort } = useTableDataV2<T>({
    baseUrl,
    entityName,
    extraData,
  });

  const getArrClass = (direction: SortDirection | undefined) => {
    if (direction === 'DESC') return '-reverse';
    if (!direction) return '-hide';
    return '';
  };

  const getColumns = (cols: ColumnType<T>[]): ColumnType<T>[] => {
    return cols.map(col => {
      let resultCol: ColumnType<T> = {
        ...col,
        headerCellClass: !col.sortable ? 'b-rdgTable__headerCell' : col.headerCellClass,
      };
      if (col.sortable) {
        resultCol.headerRenderer = props => {
          return (
            <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
              {props.column.name}
              <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
            </div>
          );
        };
      }
      if (col.headerRenderer) {
        resultCol.headerRenderer = col.headerRenderer;
      }
      return resultCol;
    });
  };

  const { onColumnResize, columns } = useTableConfig<T>(entityName, getColumns(cols));

  if (!data) {
    return <Loader />;
  }
  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <DataGrid
          onRowClick={onRowClick}
          onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
          columns={columns}
          onColumnResize={onColumnResize}
          sortColumns={query.sorting ? [query.sorting] : []}
          rows={data.result}
          className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
          style={{
            height: ((data.result.length || 0) + 1) * 60,
          }}
          rowHeight={60}
          rowKeyGetter={(row: T) => row.id}
          onSelectedRowsChange={handleSelectOne}
          selectedRows={new Set(selectedEntitiesIds)}
        />
      </DndProvider>
      <Pagination
        data={data || ({} as PaginationModel)}
        handleChangePageSize={handleChangePageSize}
        changePage={handleChangePage}
      />
    </div>
  );
}
