import React, { FC } from 'react';
import { ComaList, Link } from '../../../../../controls';
import { useTranslation } from 'react-i18next';
import { EwpElementTypes, EWPGoalModel } from '../../../../../../models';
import { basicDateFormat } from '../../../../../../helpers';
import { useHistory } from 'react-router';
import { useGetEwpRoute } from '../../../../../../hooks/ewp/use-get-ewp-route';
import { ROOTS } from '../../../../../../routes/routes';
import ElementStatusCombined from '../../parts/ElementStatusCombined';

interface IProps {
  data: EWPGoalModel;
}

const EwpGoalItem: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const EWP_GOAL_DETAILS = useGetEwpRoute(ROOTS.EWP_GOAL_DETAILS);

  return (
    <div className={`b-resourceCard b-ewpElementCard ${data.is_broken ? '-broken' : ''}`}>
      {data.is_broken ? <div className="b-ewpElementCard__brokenText">{t('awa:M149.msg')}</div> : null}
      <Link
        onClick={() => history.push(EWP_GOAL_DETAILS.replace(':elId', `${data.id}`))}
        className="b-resourceCard__heading"
      >
        <h2 className="b-resourceCard__title">{data.title}</h2>
        <div className="flex-shrink-0 b-button-icon -colorOrange -w40 -ewp_goals" />
      </Link>
      <div className="b-resourceCard__dList position-relative">
        <div className="position-absolute" style={{ top: 0, right: 0 }}>
          <ElementStatusCombined
            type={EwpElementTypes.goal}
            data={{
              status: data.status,
              status_history: data.status_history,
              approval_status: data.approval_status,
              ewp_status_id: data.ewp_status_id,
              has_approved_parents: data.has_approved_parents,
            }}
          />
        </div>
        <dl>
          <dt>{t('common:column.last-update')}:</dt>
          <dd>{basicDateFormat(data.updated_at)}</dd>
        </dl>
        <dl>
          <dd>{data.description}</dd>
        </dl>
        <dl>
          <dt>{t('common:label.years')}:</dt>
          <dd>
            <ComaList arr={data.year_list_data.map(item => ({ title: item.label }))} uKey={'goal-year'} />
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default EwpGoalItem;
