import React, { RefObject } from 'react';
import { Footer, Header } from '../../../common';
import SameAsHeadquarterView from './path/SameAsHeadquarterView';
import { ISelectOptions } from '../../../../models';
import { IAddressFields } from './OfficeAddressContainer';
import { useTranslation } from 'react-i18next';
import RadioSelect from '../../../controls/RadioSelect/RadioSelect';

interface IProps {
  errors?: any;
  loader?: string;
  fields: IAddressFields;
  countries: Array<ISelectOptions>;
  onChange: (field: string) => (value: string) => void;
  onSubmit: () => void;
  handleOnBlur: (field: string) => () => void;
  onBackNavigation: () => void;
  onHandleChange: (place: any, ref: RefObject<HTMLInputElement>) => void;
  onSelectOffice: (value: string) => void;
  officeType: string;
  isInternation: boolean;
}

const OfficeAddressView = ({
  loader,
  fields,
  errors,
  countries,
  handleOnBlur,
  onChange,
  onSubmit,
  onBackNavigation,
  onHandleChange,
  onSelectOffice,
  officeType,
  isInternation,
}: IProps) => {
  const { t } = useTranslation();

  const addressTypes = [
    {
      label: t('common:radio-btn.same-headquarter'),
      value: '1',
    },
    {
      label: t('common:radio-btn.satellite-office'),
      value: '2',
    },
    {
      label: t('common:radio-btn.work-remotely'),
      value: '3',
    },
  ];

  return (
    <>
      {loader}
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        <main className={'white-container content-center-wrapper'} style={{ alignItems: 'flex-start' }}>
          <div className={'container-page-center mb-5'}>
            <div className={'container-row container-column'} style={{ marginBottom: '40px' }}>
              <h1 className={'b-title gray-text font-l'}>{t('common:headlines.your-office-address')}</h1>
            </div>
            <RadioSelect
              value={(officeType && addressTypes.find(f => f.value === officeType)) || null}
              handleChange={(value: ISelectOptions) => onSelectOffice(String(value.value))}
              values={addressTypes}
              hideDescription={true}
            />
            <article style={{ overflow: 'auto' }}>
              <SameAsHeadquarterView
                errors={errors}
                fields={fields}
                countries={countries}
                handleOnBluer={handleOnBlur}
                onBackNavigation={onBackNavigation}
                onChange={onChange}
                onHandleChange={onHandleChange}
                officeType={officeType}
                isInternation={isInternation}
                onSubmit={onSubmit}
              />
            </article>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default OfficeAddressView;
