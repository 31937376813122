import React from 'react';
import { SortDirection } from 'react-data-grid';
import { BelDebriefModel, ColumnType, TableDataEntities } from '../../../../../models';
import { ROOTS } from '../../../../../routes/routes';
import { useHistory } from 'react-router';
import { basicDateFormat, debriefFormat } from '../../../../../helpers';
import useHasAccess from '../../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../../constants';
import { useTranslation } from 'react-i18next';
import useTableData from '../../../../../hooks/common/use-table-data';
import { TableDateFilter } from '../../../../common';
import EmptyButton from '../../../../controls/Button/EmptyButton';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

interface ColumnsHandlers {
  onCellClick: (fieldName: string, debrief: BelDebriefModel) => void;
}

export const getColumns = ({ onCellClick }: ColumnsHandlers): ColumnType<BelDebriefModel>[] => {
  const { t } = useTranslation();
  const isBeloved = useSelector((state: StateType) => state.profile.authUser)?.beloved_user;
  return [
    {
      key: 'organization_name',
      name: t('common:column.organization-name'),
      width: 170,
      sortable: true,
      editable: false,
      resizable: true,
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'affiliation',
      name: t('common:column.affiliation'),
      sortable: true,
      editable: false,
      resizable: true,
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'org_type',
      name: t('common:column.org-type'),
      sortable: true,
      editable: false,
      resizable: true,
      headerCellClass: 'b-rdgTable__headerCell',
      formatter(props) {
        return <>{props.row.type}</>;
      },
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'project_manager',
      name: t('common:column.project-manager'),
      sortable: true,
      editable: false,
      resizable: true,
      formatter(props) {
        const history = useHistory();
        const hasAccessPmProfile = useHasAccess(BelovedPermissions.ORGANIZATION_PM_PROFILE);

        if (!props.row.project_manager) return null;

        if (!hasAccessPmProfile) {
          return <>{props.row.project_manager.name}</>;
        }

        const navToPmProfile = (orgId: number) => (e: any) => {
          e.stopPropagation();
          e.preventDefault();
          history.push(ROOTS.BELOVED_PM_PROFILE.replace(':orgId', `${orgId}`));
        };

        return (
          <a href="#" onClick={navToPmProfile(props.row.organization_id)} className="b-link">
            {props.row.project_manager.name}
          </a>
        );
      },
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'debrief_scheduled_date',
      name: t('common:column.debrief-scheduled-date'),
      sortable: true,
      editable: false,
      resizable: true,
      headerCellClass: 'b-rdgTable__headerCell',
      formatter: props => (
        <>
          {props.row.debrief_scheduled_date
            ? debriefFormat(props.row.debrief_scheduled_date)
            : t('common:column.n-a')}
        </>
      ),
      headerRenderer(props) {
        const { handleSelectDateFilter, query } = useTableData(TableDataEntities.DEBRIEFS);
        if (query.dateFilters) {
          return (
            <TableDateFilter
              dateFilterId={'debrief_scheduled_date'}
              selectedFilters={query.dateFilters['debrief_scheduled_date']}
              periodFieldName={'debrief_scheduled_date_period'}
              dateRangeFieldName={'debrief_scheduled_date_date_range'}
              onDateFilterChanged={handleSelectDateFilter}
              rdgProps={props}
              fiscalOption={true}
            />
          );
        }
      },
    },
    {
      key: 'debrief_leader',
      name: t('common:column.debrief-leader'),
      sortable: true,
      editable: false,
      resizable: true,
      headerCellClass: 'b-rdgTable__headerCell',
      formatter: props => {
        const hasAccessToAssignLead = useHasAccess(BelovedPermissions.DEBRIEF_ASSIGN_LEAD);
        const onClick = (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          onCellClick && onCellClick('debrief_leader', props.row);
        };
        if (hasAccessToAssignLead) {
          return (
            <a href="#" className="debriefLeadField" onClick={onClick}>
              {props.row.debrief_leader?.name || t('common:btn.select-leader')}
            </a>
          );
        } else {
          return <> {props.row.debrief_leader?.name || ''}</>;
        }
      },
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'submit_date',
      name: t('common:label.submission-date'),
      width: 90,
      sortable: true,
      editable: false,
      isShow: false,
      resizable: true,
      formatter(props) {
        return (
          <>
            {props.row.submit_date
              ? basicDateFormat(new Date(props.row.submit_date))
              : t('common:column.n-a')}
          </>
        );
      },
      headerRenderer(props) {
        const { handleSelectDateFilter, query } = useTableData(TableDataEntities.DEBRIEFS);
        if (query.dateFilters) {
          return (
            <TableDateFilter
              dateFilterId={'submit_date'}
              selectedFilters={query.dateFilters['submit_date']}
              periodFieldName={'submit_date_period'}
              dateRangeFieldName={'submit_date_range'}
              onDateFilterChanged={handleSelectDateFilter}
              rdgProps={props}
              fiscalOption={true}
            />
          );
        }
      },
    },
    {
      key: 'legacy_audit',
      name: t('common:label.legacy-audits'),
      width: 90,
      sortable: true,
      editable: false,
      isShow: false,
      resizable: true,
      formatter(props) {
        if (!props.row.legacy_audit) {
          return null;
        }
        return (
          <div className="d-flex justify-content-center h-100 align-items-center">
            <div className="b-button-icon -checkmark" />
          </div>
        );
      },
      headerRenderer(props) {
        return (
          <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
            {props.column.name}
            <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
          </div>
        );
      },
    },
    {
      key: 'tools',
      name: '',
      disableDrag: true,
      width: 40,
      maxWidth: 40,
    },
    {
      key: 'select',
      name: '',
      width: 90,
      maxWidth: 90,
      disableDrag: true,
      isShow: !!isBeloved,
      headerRenderer() {
        return (
          <div className="b-rdgTable__cellCenter">
            <span className="b-button-icon -colorNavy -mh2  dots" />
          </div>
        );
      },
      formatter: props => {
        const history = useHistory();
        const hasAccessToEaReport = useHasAccess(BelovedPermissions.EA_REPORT);
        const hasAccessToViewDebrief = useHasAccess(BelovedPermissions.DEBRIEF_VIEW_PRESENTATION);

        const navToReport = (debrief: BelDebriefModel) => (e: any) => {
          e.stopPropagation();
          history.push(
            ROOTS.REPORTS.replace(':orgId', `${debrief.organization_id}`).replace(':eaId', `${debrief.id}`),
            { isBelovedApp: true },
          );
        };

        return (
          <div className="b-rdgTable__cellCenter">
            {hasAccessToEaReport ? (
              <EmptyButton
                onClick={navToReport(props.row)}
                className="b-button-icon -colorNavy -mh2  ea-report"
                aria-label="Detailed Report"
                ariaTitleLabel={'Debrief Details'}
              />
            ) : null}
            {hasAccessToViewDebrief ? (
              <EmptyButton
                className="b-button-icon -colorNavy -mh2  desktop"
                aria-label="Equity audit debrief"
                ariaTitleLabel={'Debrief Deck'}
              />
            ) : null}
          </div>
        );
      },
    },
  ];
};
