import React, { useEffect, useState } from 'react';
import { BelovedAdminToolsTabs } from '../../../common';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Loader, Select } from '../../../controls';
import {
  AdminToolSettingsSection,
  AdminToolSettingsItemModel,
  AdminToolSettingsModel,
  AdminToolSettingsGroups,
  AdminToolSettingsUpdateModel,
  TimezoneSelectOption,
  ISelectOptions,
  AdminToolSettingsPriceModel,
  AdminToolSettingsResourceModel,
  AdminToolSettingsNotificationModel,
  AdminToolSettingsTeamModel,
  BelovedUserShortModel,
} from '../../../../models';
import Api from '../../../../services/api';
import TimeZoneSelectView from '../../../common/TimeZoneSelect/TimeZoneSelect';
import { toast } from 'react-toastify';
import { timeOptions } from '../../../../constants';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import PriceCard from './parts/PriceCard';
import ResourceExpiration from './parts/ResourceExpiration';
import { convertToNum } from '../../../../helpers';
import BelovedTeamNotification from './parts/BelovedTeamNotification';
import DebriefCard from './parts/DebriefCard';
import { setDebriefLeader } from '../../../../store/beloved/debriefs/debriefsActions';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

interface TimeState {
  tz: string;
  initTz: string;
  time: ISelectOptions | null;
  initTime: ISelectOptions | null;
}

const days: number[] = [];
for (let i = 1; i < 31; i++) {
  days.push(i);
}

const tempNotificationData = {
  completed_ea: {
    email: false,
    task: false,
  },
  publish_elm: {
    email: false,
    task: true,
  },
  ea_debrief_purchase: {
    email: false,
    task: true,
    days: 7,
  },
  user: {},
};

const AdminToolSettings = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState<AdminToolSettingsSection[] | null>(null);

  const [initPrice, setInitPrice] = useState<AdminToolSettingsPriceModel[]>([]);
  const [price, setPrice] = useState<AdminToolSettingsPriceModel[]>([]);
  const [initResource, setInitResource] = useState<AdminToolSettingsResourceModel | null>(null);
  const [resource, setResource] = useState<AdminToolSettingsResourceModel | null>(null);
  const [availableBelovedUsers, setAvailableBelovedUsers] = useState<ISelectOptions[]>([]);
  const [team, setTeam] = useState<AdminToolSettingsTeamModel | null>(null);
  const [debriefLeaderId, setDebriefLeaderId] = useState<number | null>(null);

  const [initialForm, setInitialForm] = useState<AdminToolSettingsSection[] | null>(null);
  const [timeState, setTimeState] = useState<TimeState>({
    tz: '',
    initTz: '',
    time: null,
    initTime: null,
  });

  useEffect(() => {
    fetchSettings();
    fetchBelovedTeamUsers();
  }, []);

  const fetchBelovedTeamUsers = async () => {
    const response = await Api.get(`beloved/admin_tool/settings/beloved_team/`);
    if (response) {
      setAvailableBelovedUsers(response);
    }
  };

  const generateSection = (
    initData: AdminToolSettingsItemModel[],
    type: AdminToolSettingsGroups,
    title: string,
    description: string,
  ): AdminToolSettingsSection => {
    const firstItem = initData.find(item => item.group_alias === type);
    return {
      title,
      description,
      group_alias: type,
      setting_items: initData
        .filter(item => item.group_alias === type)
        .map(item => ({ title: item.description, is_active: item.is_active, id: item.id })),
      number_reminders: firstItem?.number_reminders || 0,
      days_between_reminders: firstItem?.days_between_reminders || 0,
      days_inactivity: firstItem?.days_inactivity || 0,
    };
  };

  const generateUpdate = (sections: AdminToolSettingsSection[]): AdminToolSettingsUpdateModel[] => {
    const result: AdminToolSettingsUpdateModel[] = [];
    sections.forEach(section => {
      section.setting_items.forEach(item => {
        result.push({
          id: item.id,
          is_active: item.is_active,
          number_reminders: section.number_reminders,
          days_inactivity: section.days_inactivity,
          days_between_reminders: section.days_between_reminders,
        });
      });
    });
    return result;
  };

  const fetchSettings = async () => {
    const response: AdminToolSettingsModel = await Api.get('beloved/admin_tool/settings/');
    if (response) {
      const { sections, time, price, resource_expiration, beloved_team, default_debrief_leader_id } =
        response;

      const eaSection = generateSection(
        sections,
        AdminToolSettingsGroups.ea,
        t('main:atSettings.ea-title'),
        t('main:atSettings.ea-description'),
      );
      const elmSection = generateSection(
        sections,
        AdminToolSettingsGroups.elm,
        t('main:atSettings.elm-title'),
        t('main:atSettings.elm-description'),
      );
      const debriefSection = generateSection(
        sections,
        AdminToolSettingsGroups.debrief,
        t('main:atSettings.debrief-title'),
        t('main:atSettings.debrief-description'),
      );
      const ewpSection = generateSection(
        sections,
        AdminToolSettingsGroups.ewp,
        t('main:atSettings.ewp-title'),
        t('main:atSettings.ewp-description'),
      );
      setInitialForm([eaSection, elmSection, debriefSection, ewpSection]);
      setForm([eaSection, elmSection, debriefSection, ewpSection]);
      setInitPrice(price);
      setPrice(price);
      setResource(resource_expiration);
      setInitResource({ ...resource_expiration });
      setTimeState({
        ...timeState,
        tz: time.time_zone,
        initTz: time.time_zone,
        time: timeOptions.find(item => item.value === time.time_local) || null,
        initTime: timeOptions.find(item => item.value === time.time_local) || null,
      });
      setTeam(beloved_team);
      setDebriefLeaderId(default_debrief_leader_id);
    }
  };

  const handleChangeOption = (group: AdminToolSettingsGroups, id: number, is_active: boolean) => {
    if (!form) return;
    const newForm = form.map(section => {
      if (section.group_alias === group) {
        const updatedItems = section.setting_items.map(item => {
          if (item.id === id) {
            return { ...item, is_active };
          }
          return item;
        });
        return {
          ...section,
          setting_items: updatedItems,
        };
      }
      return section;
    });
    setForm(newForm);
  };

  const handleChangeDay = (group: AdminToolSettingsGroups, fieldName: string, value: number) => {
    if (!form) return;
    const newForm = form.map(section => {
      if (section.group_alias === group) {
        return {
          ...section,
          [fieldName]: value,
        };
      }
      return section;
    });
    setForm(newForm);
  };

  const handleChangeTz = (value: TimezoneSelectOption) => {
    setTimeState({
      ...timeState,
      tz: value.value,
    });
  };

  const handleChangeTime = (value: ISelectOptions) => {
    setTimeState({
      ...timeState,
      time: value,
    });
  };

  const handleCancel = () => {
    setForm(initialForm);
    setTimeState({
      ...timeState,
      tz: timeState.initTz,
      time: timeState.initTime,
    });
  };

  const collectChangedPrice = () => {
    const changedPrices: { id: number; fee: string }[] = [];
    price.map(p => {
      const findPrice = initPrice.find(initP => initP.id === p.id);
      if (findPrice && findPrice.fee !== p.fee) {
        changedPrices.push({ id: p.id, fee: p.fee });
      }
    });
    return changedPrices;
  };

  const collectChangedResource = () => {
    const body: Partial<AdminToolSettingsResourceModel> = {
      conditions: [],
    };
    if (!resource || !initResource) {
      return body;
    }
    if (resource.is_active !== initResource.is_active) {
      body.is_active = resource.is_active;
    }
    if (convertToNum(resource.days) !== initResource.days) {
      body.days = convertToNum(resource.days);
    }
    const conditions: any[] = resource.conditions
      .map(c => {
        const findItem = initResource.conditions.find(f => f.alias === c.alias);
        if (findItem && findItem.days !== convertToNum(c.days)) {
          return {
            ...c,
            days: convertToNum(c.days),
          };
        }
      })
      .filter(f => !!f);
    if (conditions.length) {
      body.conditions = conditions;
    }
    return body;
  };

  const handleSave = async () => {
    if (!form) return;
    const update = generateUpdate(form);
    const data: any = {
      sections: update,
      time: {
        time_zone: timeState.tz,
        time_local: timeState.time?.value,
      },
      price: collectChangedPrice(),
      resource_expiration: collectChangedResource(),
      beloved_team: team,
      default_debrief_leader_id: debriefLeaderId,
    };
    const response = await Api.patch('beloved/admin_tool/settings/update_section/', undefined, data);
    if (response) {
      setInitPrice(response.price);
      setPrice(response.price);
      setResource(response.resource_expiration);
      setInitResource(response.resource_expiration);
      toast.success(t('awa:S21.msg'));
    }
  };

  const onChangePrice = (index: number, value: string) => {
    const newPrice = [...price];
    newPrice[index] = {
      ...newPrice[index],
      fee: value,
    };
    setPrice(newPrice);
  };

  const onChangeNotificationDay = (index: number, value: string) => {
    if (!team) {
      return;
    }
    const newNotifications: AdminToolSettingsNotificationModel[] = [...team.notifications];
    newNotifications[index].additional_data.days_after = value;
    setTeam({
      ...team,
      notifications: newNotifications,
    });
  };

  const handleChangeNotification = (
    index: number,
    type: 'email_notification' | 'task_notifications',
    value: boolean,
  ) => {
    if (!team) {
      return;
    }
    const newNotifications: AdminToolSettingsNotificationModel[] = [...team.notifications];
    newNotifications[index][type] = value;
    setTeam({
      ...team,
      notifications: newNotifications,
    });
  };

  const onChangeResourceDay = (value: string) => {
    if (!resource) {
      return;
    }
    setResource({
      ...resource,
      days: value,
    });
  };

  const onChangeActiveResource = (val: boolean) => {
    if (!resource) {
      return;
    }
    setResource({
      ...resource,
      is_active: val,
    });
  };

  const onChangeConditionsDay = (value: string, index: number) => {
    if (!resource) {
      return;
    }
    const conditions = [...resource.conditions];
    conditions[index] = {
      ...conditions[index],
      days: value,
    };
    setResource({
      ...resource,
      conditions: conditions,
    });
  };

  const renderContent = () => {
    if (!form) return <Loader />;

    return (
      <div className="pt-5">
        <div className="b-resourceListItems__wrapper">
          <div className="b-resourceListItems">
            {form.map(section => {
              return (
                <div key={`section-${section.group_alias}`} className="b-resourceCard font-grey font-s pr-4">
                  <div className="font-weight-bold pb-2">{section.title}</div>
                  <div className="pb-3">{section.description}:</div>
                  {section.setting_items.map(option => {
                    return (
                      <label
                        key={`option-${section.group_alias}-${option.id}`}
                        className="pb-3 d-flex justify-content-between"
                        htmlFor={`check-${section.group_alias}-${option.id}`}
                      >
                        <div className="flex-shrink-1">{option.title}</div>
                        <div className="pl-2">
                          <Checkbox
                            noText
                            checked={option.is_active}
                            id={`check-${section.group_alias}-${option.id}`}
                            onChange={e =>
                              handleChangeOption(section.group_alias, option.id, e.target.checked)
                            }
                          />
                        </div>
                      </label>
                    );
                  })}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>{t('main:atSettings.doi')}</div>
                    <select
                      onChange={event =>
                        handleChangeDay(section.group_alias, 'days_inactivity', Number(event.target.value))
                      }
                      value={section.days_inactivity}
                      className="simple-select font-weight-bold font-grey"
                      id={`select-${section.group_alias}-days_inactivity`}
                    >
                      {days.map(day => (
                        <option key={`doi-${day}`} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>{t('main:atSettings.nor')}</div>
                    <select
                      onChange={event =>
                        handleChangeDay(section.group_alias, 'number_reminders', Number(event.target.value))
                      }
                      value={section.number_reminders}
                      className="simple-select font-weight-bold font-grey"
                      id={`select-${section.group_alias}-number_reminders`}
                    >
                      {days.map(day => (
                        <option key={`nor-${day}`} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>{t('main:atSettings.dbr')}</div>
                    <select
                      onChange={event =>
                        handleChangeDay(
                          section.group_alias,
                          'days_between_reminders',
                          Number(event.target.value),
                        )
                      }
                      value={section.days_between_reminders}
                      className="simple-select font-weight-bold font-grey"
                      id={`select-${section.group_alias}-days_between_reminders`}
                    >
                      {days.map(day => (
                        <option key={`dbr-${day}`} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              );
            })}
            <div className="b-resourceCard font-grey font-s pr-4">
              <div className="font-weight-bold pb-2"> {t('main:atSettings.email-time')}</div>
              <div className="pb-4">{t('main:atSettings.email-time-description')}:</div>
              <Select
                name={'organization'}
                value={timeState.time}
                placeholder={t('common:column.time')}
                options={timeOptions}
                wrapperStyles={{ width: '100%' }}
                handleChange={handleChangeTime}
              />
              <TimeZoneSelectView
                value={timeState.tz}
                onChange={handleChangeTz}
                height={52}
                wrapperStyles={{ width: '100%' }}
                placeholder={t('common:label.time-zone')}
                ariaLabel="Time Zone"
              />
            </div>
            <PriceCard price={price} onChangePrice={onChangePrice} />
            <ResourceExpiration
              resource={resource}
              onChangeResourceDay={onChangeResourceDay}
              onChangeActive={onChangeActiveResource}
              onChangeConditionsDay={onChangeConditionsDay}
            />
            {team?.notifications ? (
              <BelovedTeamNotification
                team={team}
                setTeam={setTeam}
                onChangeNotification={handleChangeNotification}
                onChangeNotificationDay={onChangeNotificationDay}
                availableBelovedUsers={availableBelovedUsers}
              />
            ) : null}
            <DebriefCard
              debriefLeaderId={debriefLeaderId}
              setDebriefLeader={setDebriefLeaderId}
              availableBelovedUsers={availableBelovedUsers}
            />
          </div>
          <div className="d-flex mb-5 justify-content-center">
            <Button
              size="middle"
              className="button-mh"
              type={'transparency'}
              title={t('common:btn.cancel')}
              onPress={handleCancel}
            />
            <Button size="middle" className="button-mh" title={t('common:btn.save')} onPress={handleSave} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <main className={'b-page'}>
      <h1 className={'b-page__title'}>{t('common:label.admin-tool')}</h1>
      <BelovedAdminToolsTabs activeItemAlias={'settings'} />
      {renderContent()}
    </main>
  );
};

export default AdminToolSettings;
