import { useTranslation } from 'react-i18next';

export const useEwpStatuses = () => {
  const { t } = useTranslation();

  const ewpElementStatuses = [
    { label: t('common:label.Not-Started-Yet'), value: 1 },
    { label: t('common:status.in-progress'), value: 2 },
    { label: t('common:label.stalled'), value: 3 },
    { label: t('common:label.No-Longer-Pursuing'), value: 4 },
    { label: t('common:status.completed'), value: 5 },
  ];

  const ewpGoalStatuses = [
    { label: t('common:status.Not-Updated-Yet'), value: 1 },
    { label: t('common:status.met'), value: 2 },
    { label: t('common:status.positive-growth'), value: 3 },
    { label: t('common:status.no-change'), value: 4 },
    { label: t('common:status.negative-growth'), value: 5 },
    { label: t('common:label.No-Longer-Pursuing'), value: 6 },
  ];

  return {
    ewpElementStatuses,
    ewpGoalStatuses,
  };
};
