import i18n from 'i18next';
import { BaseModel, KeyValueNumberModel } from '../models';

export enum ElmStatuses {
  IN_PREPARATION = 1,
  SCHEDULED = 2,
  IN_PROGRESS = 3,
  COMPLETED = 4,
  COMPLETED_LOCKED = 5,
}

export enum ElmRoles {
  USER = 'user',
  MANAGER = 'manager',
}

export enum ElmUserStatuses {
  INVITED = 1,
  CANCELED = 2,
  STARTED = 3,
  TERM_ACCEPTED = 4,
  IN_PROGRESS = 5,
  COMPLETED = 6,
}

export const elmStatusList = (): KeyValueNumberModel<BaseModel> => ({
  0: { id: ElmStatuses.IN_PREPARATION, title: i18n.t('common:status.in-preparation') },
  1: { id: ElmStatuses.SCHEDULED, title: i18n.t('common:status.scheduled') },
  2: { id: ElmStatuses.IN_PROGRESS, title: i18n.t('common:status.in-progress') },
  3: { id: ElmStatuses.COMPLETED, title: i18n.t('common:status.completed') },
  4: { id: ElmStatuses.COMPLETED_LOCKED, title: i18n.t('common:status.completed-locked') },
});

// 'invited' | 'canceled' | 'started' | 'terms-accepted' | 'in_progress' | 'completed';
