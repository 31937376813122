import React, { useEffect, useState } from 'react';
import RoleView from './RoleView';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IFields } from '../Profile/CreateProfileContainer';
import { phone, validate } from '../../../../helpers';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';

interface IProps {
  fields: IFields;
  onChange: (field: string) => (value: string | IRoleFields) => void;
}

export interface IRoleFields {
  title: string;
  division: string;
  phone_number?: string;
}

const RoleContainer = ({ fields, onChange }: IProps) => {
  const user = useSelector((state: StateType) => state.profile.authUser?.user);

  const validator = {
    required: ['title', 'division', user?.notification.need_phone_number ? 'phone_number' : ''],
    custom: [phone(['phone_number'])],
  };

  const validatorTitle = {
    required: ['title'],
  };

  const validatorDivision = {
    required: ['division'],
  };

  const validationPhoneNumber = {
    required: ['phone_number'],
    custom: [phone(['phone_number'])],
  };

  const history = useHistory();
  let { path } = useRouteMatch();
  let [error, setErrors] = useState<any>({});
  const [roleFields, setRoleFields] = useState(fields.role);

  useEffect(() => {
    setErrors({});
  }, [roleFields]);
  const handleBackNavigation = () => {
    history.go(-1);
  };
  const handleSubmit = () => {
    let { errors } = validate(validator, roleFields);
    setErrors(errors);
    if (!Object.keys(error).length) {
      onChange('role')(roleFields);
      history.push(`${path.replace(':step', 'profile')}`);
    }
  };
  const handleOnBlur = (field: string) => () => {
    let errorCase;
    switch (field) {
      case 'title':
        errorCase = validate(validatorTitle, roleFields);
        break;
      case 'division':
        errorCase = validate(validatorDivision, roleFields);
        break;
      case 'phone_number': {
        if (!user?.notification.need_phone_number) {
          return;
        }
        errorCase = validate(validationPhoneNumber, roleFields);
        break;
      }

      default:
        errorCase = { errors: error };
    }
    setErrors(errorCase.errors);
  };
  const handleOnChange = (field: string) => (value: string) => {
    setRoleFields({ ...roleFields, [field]: value });
  };

  return (
    <RoleView
      onSubmit={handleSubmit}
      handleOnBlur={handleOnBlur}
      fields={roleFields}
      onBackNavigation={handleBackNavigation}
      onChange={handleOnChange}
      errors={error}
    />
  );
};

export default RoleContainer;
