import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IRouteParamsBase } from '../../../../models';
import { EmptyLink, Input, Loader } from '../../../controls';
import { belGetEaDetails } from '../../../../store/beloved/equityAudit/equityAuditActions';
import { BelovedEaTabs, HeadlineOrganization } from '../../../common';
import { StateType } from '../../../../store/reducers';
import { basicDateFormat } from '../../../../helpers';
import { countries } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { useNavBack } from '../../../../hooks/common/use-nav-back';
import { Link as RouterLink } from 'react-router-dom';
import { ROOTS } from '../../../../routes/routes';

const EquityAuditDetails = () => {
  const { t } = useTranslation();
  const { navBack } = useNavBack();

  const { eaId } = useParams<IRouteParamsBase>();
  const dispatch = useDispatch();
  const data = useSelector((state: StateType) => state.belEquityAudits.currentEa);
  const user = useSelector((state: StateType) => state.profile.authUser);
  const isBeloved = user?.beloved_user;

  useEffect(() => {
    dispatch(
      belGetEaDetails({
        orgId: Number(eaId),
      }),
    );
  }, [eaId]);

  if (!data) return <Loader />;

  const countryValue =
    countries.find(f => f.value === (data?.address.country && data.address.country.toLowerCase())) ||
    countries.find(f => f.value === 'us');

  const isInternation = !(data.address.country && data.address.country.toLowerCase() === 'us');

  return (
    <>
      <main className="b-page">
        <HeadlineOrganization
          orgName={data.org_name}
          orgId={data.organization_id}
          headline={t('main:ea.ea')}
          customClasses={'-withTabs'}
          backBtn={<EmptyLink onClick={navBack} className="b-button-icon-back" />}
        />
        <BelovedEaTabs activeItemAlias={'details'} />
        <form action="#" className="b-page__730 pt">
          <div className="b-equityAuditsDetails__formRow">
            <Input
              value={data.affiliation}
              disabled
              type="text"
              placeholder={t('common:label.affiliation')}
              ariaLabel="Affiliation"
            />
            <Input
              value={data.type}
              disabled
              type="text"
              placeholder={t('common:label.type')}
              ariaLabel="Type"
            />
            <Input
              value={data.status_name}
              disabled
              type="text"
              placeholder={t('common:label.status')}
              ariaLabel="Status"
            />
            <Input
              value={data.last_update ? basicDateFormat(new Date(data.last_update)) : t('common:column.n-a')}
              disabled
              type="text"
              placeholder={t('common:label.last-update')}
              ariaLabel="Last Update"
            />
            <Input
              value={
                data.debrief_date ? basicDateFormat(new Date(data.debrief_date)) : t('common:column.n-a')
              }
              disabled
              type="text"
              placeholder={t('common:label.debrief')}
              ariaLabel="Debrief"
            />
            <Input
              value={data.project_manager}
              disabled
              type="text"
              placeholder={t('common:label.pm')}
              ariaLabel="PM"
            />
            <Input
              value={data.project_manager_email}
              disabled
              type="email"
              placeholder={t('common:label.pm-email')}
              ariaLabel="PM Email"
            />
            <Input
              value={data.joined ? basicDateFormat(new Date(data.joined)) : t('common:column.n-a')}
              disabled
              type="text"
              placeholder={t('common:label.joined')}
              ariaLabel="Joined"
            />
            <Input
              value={data.website}
              disabled
              type="text"
              placeholder={t('common:label.website')}
              ariaLabel="Website"
            />
            {data.has_recommended_resources || isBeloved ? (
              <RouterLink
                to={ROOTS.LINK_RESOURCE.replace(':entity', 'equity_audit').replace(':entityId', `${data.id}`)}
                className="d-flex align-items-center mb-4"
              >
                <div className="b-panelTitleOutside__iconWrapper">
                  <div className="b-button-icon -colorOrange -noHover -icResources" />
                </div>
                <div className="font-m b-link ml-2 mr-3 font-weight-bold">
                  {t('common:btn.recommended-resources')}
                </div>
              </RouterLink>
            ) : null}
          </div>
          <h2 className="b-equityAuditsDetails__formTitle">{t('main:ea.organization-details')}</h2>
          <div className="b-equityAuditsDetails__formRow">
            <Input
              value={data.employees_number_name}
              disabled
              type="text"
              placeholder={t('common:label.number-of-employees')}
              ariaLabel="Number of Employees"
            />
            <Input
              value={data.org_age_name}
              disabled
              type="text"
              placeholder={t('common:label.age-organization')}
              ariaLabel="Age of Your Organization"
            />
            <Input
              value={data.geographic_scope_name}
              disabled
              type="text"
              placeholder={t('common:label.geographic-scopes')}
              ariaLabel="Geographic Scope of Services Provided"
            />
            <Input
              value={data.geographic_area_name}
              disabled
              type="text"
              placeholder={t('common:label.geographic-areas')}
              ariaLabel="Geographic Areas Served"
            />
            <Input
              value={data.sector_type_name}
              disabled
              type="text"
              placeholder={t('common:label.sector-type')}
              ariaLabel="Sector Type"
            />
            <Input
              value={data.primary_type_name}
              disabled
              type="text"
              placeholder={t('common:label.primary-type')}
              ariaLabel="Primary Type of Service Provided"
            />
          </div>
          <h2 className="b-equityAuditsDetails__formTitle">{t('common:headlines.headquarter-address')}</h2>
          <div className="b-equityAuditsDetails__formRow">
            <Input
              value={data.address.address}
              disabled
              type="text"
              placeholder={t('common:label.address')}
              ariaLabel="Address"
            />
            <Input
              value={data.address.suite}
              disabled
              type="text"
              ariaLabel="Suite/Office/Floor"
              placeholder={isInternation ? t('common:label.international.suite') : t('common:label.us.suite')}
            />
            <Input
              value={data.address.city}
              disabled
              type="text"
              placeholder={t('common:label.us.city')}
              ariaLabel="City"
            />
            <Input
              value={data.address.state}
              disabled
              type="text"
              placeholder={t('common:label.us.state')}
              ariaLabel="State"
            />
            <Input
              value={data.address.postal}
              disabled
              type="text"
              ariaLabel="Postal code"
              placeholder={
                isInternation ? t('common:label.international.postal') : t('common:label.us.suite')
              }
            />
            <Input
              value={(countryValue && countryValue.label) || ''}
              disabled
              type="text"
              placeholder={t('common:label.country')}
              ariaLabel="Country"
            />
          </div>
        </form>
      </main>
    </>
  );
};

export default EquityAuditDetails;
