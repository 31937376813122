import {
  ActionModel,
  EAUpdateData,
  EAUser,
  EquityAuditModel,
  EAOptions,
  EATaking,
  TeamType,
  Question,
  PaginationModel,
  CurrentDebriefSlideModel,
} from '../../models';
import { actionCreator } from '../actionCreator';
import { ISelectOptions, DebriefModel } from '../../models';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';
import { QueryParamsModel } from '../../hooks/common/use-table-data';
import { ORG_CHANGED } from '../organization/organizationActions';

//-----GET_EA_LIST----//
export const GET_EA_LIST = 'GET_EA_LIST';
export interface GetEAListPayload {
  params: QueryParamsModel;
  callback?: (data?: PaginationModel<EquityAuditModel[]>) => void;
}
export const getEAList = actionCreator<GetEAListPayload>(GET_EA_LIST);

//-----GET_ORG_LIST_FOR_CREATE----//
export const GET_ORG_LIST_FOR_CREATE = 'GET_ORG_LIST_FOR_CREATE';
export interface GetOrgListForCreatePayload {
  callback: (orgList: ISelectOptions[]) => void;
}
export const getOrgListForCreate = actionCreator<GetOrgListForCreatePayload>(GET_ORG_LIST_FOR_CREATE);

//-----GET_USERS_LIST_FOR_CREATE----//
export const GET_USERS_LIST_FOR_CREATE = 'GET_USERS_LIST_FOR_CREATE';
export const getUsersListForCreate = actionCreator(GET_USERS_LIST_FOR_CREATE);

//-----GET_USERS_LIST_FOR_CREATE_SUCCESS----//
export const GET_USERS_LIST_FOR_CREATE_SUCCESS = 'GET_USERS_LIST_FOR_CREATE_SUCCESS';
export const getUsersListForCreateSuccess = actionCreator<EAUser[]>(GET_USERS_LIST_FOR_CREATE_SUCCESS);

//-----GET_EA_OPTIONS_FOR_CREATE----//
export const GET_EA_OPTIONS_FOR_CREATE = 'GET_EA_OPTIONS_FOR_CREATE';
export const getEAOptionsForCreate = actionCreator(GET_EA_OPTIONS_FOR_CREATE);

//-----GET_EA_OPTIONS_FOR_CREATE----//
export const GET_EA_OPTIONS_FOR_CREATE_SUCCESS = 'GET_EA_OPTIONS_FOR_CREATE_SUCCESS';
export const getEAOptionsForCreateSuccess = actionCreator<EAOptions>(GET_EA_OPTIONS_FOR_CREATE_SUCCESS);

//-----GET_EA_DETAILS----//
export const GET_EA_DETAILS = 'GET_EA_DETAILS';
export interface GetEADetailsPayload {
  eaId: number;
  isDetailsPage?: boolean;
  callback?: (data?: EquityAuditModel) => void;
}
export const getEADetails = actionCreator<GetEADetailsPayload>(GET_EA_DETAILS);

//-----GET_EA_DETAILS_SUCCESS----//
export const GET_EA_DETAILS_SUCCESS = 'GET_EA_DETAILS_SUCCESS';
export const getEADetailsSuccess = actionCreator<EquityAuditModel>(GET_EA_DETAILS_SUCCESS);

//-----CREATE_EA----//
export const CREATE_EA = 'CREATE_EA';
export interface CreateEAPayload {
  orgId: number;
  callback?: () => void;
}
export const createEA = actionCreator<CreateEAPayload>(CREATE_EA);

//-----CREATE_EA_SUCCESS----//
export const CREATE_EA_SUCCESS = 'CREATE_EA_SUCCESS';
export const createEASuccess = actionCreator<EquityAuditModel>(CREATE_EA_SUCCESS);

//-----CANCEL_EA----//
export const CANCEL_EA = 'CANCEL_EA';
export interface CancelEAPayload {
  eaId: number;
  callback?: () => void;
}
export const cancelEA = actionCreator<CancelEAPayload>(CANCEL_EA);

//-----UPDATE_EA----//
export const UPDATE_EA = 'UPDATE_EA';
export interface UpdateEAPayload {
  eaId: number;
  isDetails?: boolean;
  data: EAUpdateData;
  callback?: (data?: EquityAuditModel) => void;
}
export const updateEA = actionCreator<UpdateEAPayload>(UPDATE_EA);

//-----UPDATE_EA_SUCCESS----//
export const UPDATE_EA_SUCCESS = 'UPDATE_EA_SUCCESS';
export const updateEASuccess = actionCreator<EquityAuditModel>(UPDATE_EA_SUCCESS);

//-----TAKE_EA----//
export const TAKE_EA = 'TAKE_EA';
export interface TakeEAPayload {
  eaId: number;
  callback?: () => void;
}
export const takeEA = actionCreator<TakeEAPayload>(TAKE_EA);

//-----TAKE_EA_SUCCESS----//
export const TAKE_EA_SUCCESS = 'TAKE_EA_SUCCESS';
export const takeEASuccess = actionCreator<EATaking>(TAKE_EA_SUCCESS);

//-----SAVE_ANSWER----//
export const SAVE_ANSWER = 'SAVE_ANSWER';
export interface SaveAnswerPayload {
  eaId: number;
  data: {
    current_question_id?: number;
    current_function_group_id?: string;
    answer_id?: string | null;
  };
  callback?: () => void;
}
export const saveAnswer = actionCreator<SaveAnswerPayload>(SAVE_ANSWER);

//-----SAVE_ANSWER_SUCCESS----//
export const SAVE_ANSWER_SUCCESS = 'SAVE_ANSWER_SUCCESS';
export const saveAnswerSuccess = actionCreator<SaveAnswerPayload['data']>(SAVE_ANSWER_SUCCESS);

//-----DELETE_F_GROUP----//
export const DELETE_F_GROUP = 'DELETE_F_GROUP';
export interface DeleteFGroupPayload {
  eaId: number;
  fGroupId: string;
  callback?: () => void;
}
export const deleteFGroup = actionCreator<DeleteFGroupPayload>(DELETE_F_GROUP);

//-----DELETE_F_GROUP_SUCCESS----//
export const DELETE_F_GROUP_SUCCESS = 'DELETE_F_GROUP_SUCCESS';
export const deleteFGroupSuccess = actionCreator<Question[]>(DELETE_F_GROUP_SUCCESS);

//-----SKIP_F_GROUP----//
export const SKIP_F_GROUP = 'SKIP_F_GROUP';
export interface SkipFGroupPayload {
  eaId: number;
  fGroupId: string;
  callback?: () => void;
}
export const skipFGroup = actionCreator<SkipFGroupPayload>(SKIP_F_GROUP);

//-----SKIP_F_GROUP_SUCCESS----//
export const SKIP_F_GROUP_SUCCESS = 'SKIP_F_GROUP_SUCCESS';
export interface SkipFGroupSuccessPayload {
  fGroupId: string;
}
export const skipFGroupSuccess = actionCreator<SkipFGroupSuccessPayload>(SKIP_F_GROUP_SUCCESS);

//-----SEND_NOTIFICATION----//
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export interface SendNotificationPayload {
  target: TeamType;
  ea_id: number;
  userIds?: number[];
  callback?: (target: TeamType) => void;
}
export const sendNotification = actionCreator<SendNotificationPayload>(SEND_NOTIFICATION);

//-----SUBMIT_EA----//
export const SUBMIT_EA = 'SUBMIT_EA';
export interface SubmitEAPayload {
  ea_id: number;
  callback?: () => void;
}
export const submitEA = actionCreator<SubmitEAPayload>(SUBMIT_EA);

//-----CLEAR_CREATE_EA_DATA----//
export const CLEAR_CREATE_EA_DATA = 'CLEAR_CREATE_EA_DATA';
export const clearCreateEAData = actionCreator(CLEAR_CREATE_EA_DATA);

//-----CLEAR_TAKE_EA_DATA----//
export const CLEAR_TAKE_EA_DATA = 'CLEAR_TAKE_EA_DATA';
export const clearTakeEAData = actionCreator(CLEAR_TAKE_EA_DATA);

//-----VIEW_DEBRIEF----//
export const VIEW_DEBRIEF = 'org/VIEW_DEBRIEF';
export interface ViewDebriefPayload {
  ea_id: number;
  callback?: (data: DebriefModel) => void;
}
export const viewDebrief = actionCreator<ViewDebriefPayload>(VIEW_DEBRIEF);

export const CHANGE_CURRENT_DEBRIEF_SLIDE = 'CHANGE_CURRENT_DEBRIEF_SLIDE';

export const changeCurrentDebriefSlide = actionCreator<CurrentDebriefSlideModel | null>(
  CHANGE_CURRENT_DEBRIEF_SLIDE,
);

export type EquityAuditActionsTypes =
  | ActionModel<typeof GET_ORG_LIST_FOR_CREATE, GetOrgListForCreatePayload>
  | ActionModel<typeof GET_USERS_LIST_FOR_CREATE>
  | ActionModel<typeof ORG_CHANGED>
  | ActionModel<typeof CREATE_EA, CreateEAPayload>
  | ActionModel<typeof LOGOUT_SUCCESS>
  | ActionModel<typeof CREATE_EA_SUCCESS, EquityAuditModel>
  | ActionModel<typeof GET_EA_LIST, GetEAListPayload>
  | ActionModel<typeof GET_EA_DETAILS_SUCCESS, EquityAuditModel>
  | ActionModel<typeof UPDATE_EA, UpdateEAPayload>
  | ActionModel<typeof UPDATE_EA_SUCCESS, EquityAuditModel>
  | ActionModel<typeof CLEAR_CREATE_EA_DATA>
  | ActionModel<typeof CLEAR_TAKE_EA_DATA>
  | ActionModel<typeof GET_USERS_LIST_FOR_CREATE_SUCCESS, EAUser[]>
  | ActionModel<typeof GET_EA_OPTIONS_FOR_CREATE_SUCCESS, EAOptions>
  | ActionModel<typeof TAKE_EA, TakeEAPayload>
  | ActionModel<typeof TAKE_EA_SUCCESS, EATaking>
  | ActionModel<typeof SAVE_ANSWER, SaveAnswerPayload>
  | ActionModel<typeof SAVE_ANSWER_SUCCESS, SaveAnswerPayload['data']>
  | ActionModel<typeof DELETE_F_GROUP, DeleteFGroupPayload>
  | ActionModel<typeof DELETE_F_GROUP_SUCCESS, Question[]>
  | ActionModel<typeof SEND_NOTIFICATION, SendNotificationPayload>
  | ActionModel<typeof SKIP_F_GROUP_SUCCESS, SkipFGroupSuccessPayload>
  | ActionModel<typeof CHANGE_CURRENT_DEBRIEF_SLIDE, CurrentDebriefSlideModel | null>;
