import Api from '../../services/api';
import { getEWPDetails } from '../../store/ewp/ewpActions';
import { useDispatch } from 'react-redux';

export const useEwpReviewElementsApi = () => {
  const dispatch = useDispatch();

  const markRead = (orgId: number, action: boolean, ids: number[], callback?: () => void) => {
    Api.put(`organizations/${orgId}/ewp/ewp_elements/read/`, {
      ids: ids,
      action,
    }).then(res => {
      if (res) {
        dispatch(getEWPDetails({ orgId }));
        callback && callback();
      }
    });
  };

  const markSnooze = (orgId: number, action: boolean, ids: number[], callback?: () => void) => {
    Api.put(`organizations/${orgId}/ewp/ewp_elements/snooze/`, {
      ids: ids,
      action,
    }).then(res => {
      if (res) {
        callback && callback();
      }
    });
  };

  return {
    markRead,
    markSnooze,
  };
};
