import { all, call, CallEffect, put, takeLatest, StrictEffect } from 'redux-saga/effects';
import { ActionModel, CreateOrganizationModel } from '../../models';
import Api from '../../services/api';
import {
  CHECK_UNIQ_ORGANIZATION,
  GET_APP_OPTIONS,
  getAppOptionsSuccess,
  createEthnicitySuccess,
  createGenderSuccess,
  createPronounsSuccess,
  createRaceSuccess,
  CREATE_ETHNICITY,
  CREATE_RACE,
  CREATE_GENDER,
  CREATE_PRONOUNS,
  CreateGenderPayload,
  CreateRacePayload,
  CreatePronounsPayload,
  CreateEthnicityPayload,
  CreateEducationPayload,
  createEducationSuccess,
  CREATE_EDUCATION,
  GET_INFO,
  GetInfoPayload,
  getInfoSuccess,
  GET_COHORT_UTILS,
  getCohortUtilsSuccess,
  GetCommonPayload,
  GET_FILTERS,
  GetFiltersPayload,
  getFiltersSuccess,
} from './utilsActions';
import { toast } from 'react-toastify';
import { t } from 'i18next';

function* getAppOptionsSaga(): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(Api.get, 'utils/options/');
    if (response) {
      yield put(getAppOptionsSuccess(response));
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* checkUniqOrganizationSaga({ payload }: any): Generator<CallEffect<CreateOrganizationModel>> {
  try {
    const response = yield call(Api.post, 'utils/check_unique_organisation/', payload.data);
    if (response) {
      payload.callback(response);
    }
  } catch (e) {
    payload.callback({ error: e });
  }
}

function* createGenderSaga({
  payload,
}: ActionModel<typeof CREATE_GENDER, CreateGenderPayload>): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(Api.post, 'utils/gender/', payload.data);
    if (response) {
      yield put(createGenderSuccess(response));
      payload.callback(response);
    }
  } catch (e) {
    payload.callback({ error: e });
  }
}

function* createPronounsSaga({
  payload,
}: ActionModel<typeof CREATE_PRONOUNS, CreatePronounsPayload>): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(Api.post, 'utils/pronouns/', payload.data);
    if (response) {
      payload.callback(response);
      yield put(createPronounsSuccess(response));
    }
  } catch (e) {
    payload.callback({ error: e });
  }
}

function* createRaceSaga({
  payload,
}: ActionModel<typeof CREATE_RACE, CreateRacePayload>): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(Api.post, 'utils/race/', payload.data);
    if (response) {
      payload.callback(response);
      yield put(createRaceSuccess(response));
    }
  } catch (e) {
    payload.callback({ error: e });
  }
}

function* createEthnicitySaga({
  payload,
}: ActionModel<typeof CREATE_ETHNICITY, CreateEthnicityPayload>): Generator<StrictEffect, void, any> {
  try {
    payload.data.is_hispanic = false;
    const response = yield call(Api.post, 'utils/ethnicity/', payload.data);
    if (response) {
      payload.callback(response);
      yield put(createEthnicitySuccess(response));
    }
  } catch (e) {
    payload.callback({ error: e });
  }
}

function* createEducationSaga({
  payload,
}: ActionModel<typeof CREATE_EDUCATION, CreateEducationPayload>): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(Api.post, 'utils/education/', payload.data);
    if (response) {
      payload.callback(response);
      yield put(createEducationSuccess(response));
    }
  } catch (e) {
    payload.callback({ error: e });
  }
}

function* getInfoSaga({
  payload,
}: ActionModel<typeof GET_INFO, GetInfoPayload>): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(Api.get, 'utils/app_info/');
    if (response) {
      payload.callback && payload.callback(response);
      yield put(getInfoSuccess(response));
    }
  } catch (e) {
    payload.callback && payload.callback(null, { error: e });
  }
}

function* getCohortUtilsSaga({
  payload,
}: ActionModel<typeof GET_COHORT_UTILS, GetCommonPayload>): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(Api.get, 'utils/cohort/common/');
    if (response) {
      payload?.callback && payload.callback(response);
      yield put(getCohortUtilsSuccess(response));
    }
  } catch (e) {
    console.log('e', e);
  }
}

function* getFiltersSaga({
  payload,
}: ActionModel<typeof GET_FILTERS, GetFiltersPayload>): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(Api.get, 'utils/filter_options/');
    if (response) {
      payload.callback && payload.callback(response);
      yield put(getFiltersSuccess(response));
    }
  } catch (e) {
    payload.callback && payload.callback(null, { error: e });
  }
}

export function* saga() {
  yield all([takeLatest(CHECK_UNIQ_ORGANIZATION, checkUniqOrganizationSaga)]);
  yield all([takeLatest(GET_APP_OPTIONS, getAppOptionsSaga)]);
  yield all([takeLatest(CREATE_ETHNICITY, createEthnicitySaga)]);
  yield all([takeLatest(CREATE_RACE, createRaceSaga)]);
  yield all([takeLatest(CREATE_GENDER, createGenderSaga)]);
  yield all([takeLatest(GET_INFO, getInfoSaga)]);
  yield all([takeLatest(CREATE_EDUCATION, createEducationSaga)]);
  yield all([takeLatest(GET_COHORT_UTILS, getCohortUtilsSaga)]);
  yield all([takeLatest(CREATE_PRONOUNS, createPronounsSaga)]);
  yield all([takeLatest(GET_FILTERS, getFiltersSaga)]);
}
