import React from 'react';
import MyAccountTabs from '../../../common/MyAccountTabs/MyAccountTabs';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <main className={'pageContainer pageContainer__content'}>
        <h1 className={'pageContainer__title'}>{t('common:headlines.my-account')}</h1>
        <MyAccountTabs activeItemAlias={'privacy-policy'} />
        <div className={'b-formAccount'}>
          <h2>Privacy Policy</h2>
          <p>LAST UPDATED: September 21, 2021</p>
          <h3>Introduction</h3>
          <p>
            Beloved Community, Incorporated (&quot;<b>Beloved Community</b>&quot;, &quot;
            <b>we</b>&quot; or “<b>us</b>”) respects your privacy and is committed to protecting it through
            our compliance with this policy. This policy describes the types of information we may collect
            from you or that you may provide when you visit the website www.wearebeloved.org (the “
            <b>Website</b>”) or the products or services offered by Beloved Community (the “<b>Services</b>”)
            and our practices for collecting, using, maintaining, protecting, and disclosing that information.
          </p>
          <p>This policy applies to information we collect:</p>
          <ul>
            <li>On the Website;</li>
            <li>Through the registration process;</li>
            <li>In email, text, and other electronic messages between you and this Website;</li>
            <li>
              In connection with your use of Beloved Community’s Diversity, Equity, and Inclusion Standards
              and Indicators – Equity Audit (the “<b>Equity Audit</b>
              ”); and
            </li>
            <li>
              In connection with your use of Beloved Community’s Equity Lens Map module (“
              <b>Equity Lens Map</b>”).
            </li>
          </ul>
          <p>
            Please read this policy carefully to understand our policies and practices regarding your
            information and how we will treat it. If you do not agree with our policies and practices, your
            choice is not to use our Website or Services. By accessing or using the Website or Services, you
            agree to this privacy policy. This policy may change from time to time (see{' '}
            <i>Changes to Our Privacy Policy</i>). Your continued use of the Website or Services after we make
            changes is deemed to be acceptance of those changes, so please check the policy periodically for
            updates.
          </p>
          <h3>Information We Collect About You.</h3>
          <p>
            We collect your Personal Data when you provide it to us, when you use our Website and Services,
            and when other sources provide it to us, as follows:
          </p>
          <p>
            <b>Personal and organizational information you provide to us</b>: we collect the information that
            you give to us, for example, when you:
          </p>
          <ul>
            <li>Contact us by phone, email, or otherwise;</li>
            <li>Fill in forms on the Website;</li>
            <li>Sign up for our newsletter;</li>
            <li>Register to use the Equity Audit and/or Equity Lens Map;</li>
            <li>
              Respond to questions contained within the Equity Audit or Equity Lens Map (see{' '}
              <i>Additional Terms Specific to the Equity Audit</i> and{' '}
              <i>Additional Terms Specific to the Equity Lens Map</i>); and
            </li>
            <li>Respond to surveys run by ourselves (for example, to get feedback on our own services).</li>
          </ul>
          <p>
            <b>Technical and usage information</b>: as you navigate through and interact with our Website, we
            may use automatic data collection technologies to collect information about your equipment,
            browsing actions, and patterns, including:
          </p>
          <ul>
            <li>
              The type of browser and/or device used, operating system, files viewed, date/time stamp,
              referrer page and IP address.
            </li>
            <li>
              Your usage of our products and services, including which webpages you view, the Services you
              view or search for, your referring and exit pages, the length of your visits to certain pages
              and the times and dates of these actions.
            </li>
          </ul>
          <p>
            We use cookies and similar tracking technologies to gather and track such technical and usage
            information. A cookie is a small file placed on the hard drive of your computer. Tracking
            technologies we use, such as Squarespace Analytics, use cookies to collect and track information
            and to improve and analyze our Website and Services. The use of cookies is a common practice
            adopted by most major sites to better serve their visitors. Most browsers are designed to accept
            cookies, but they can be easily modified to block cookies. See your browser’s “Help” files for
            details on how to block cookies, how you know when you have received cookies, and how to disable
            cookies completely. However, if you do not accept cookies, you may be unable to access certain
            parts of our Website or Services.
          </p>
          <h3>TECHNICAL AND USAGE INFORMATION: </h3>
          <p>
            As you navigate through and interact with our Website, we may use automatic data collection
            technologies to collect information about your equipment, browsing actions, and patterns,
            including:
          </p>
          <ul>
            <li>
              The type of browser and/or device used, operating system, files viewed, date/time stamp,
              referrer page and IP address.
            </li>
            <li>
              Your usage of our products and services, including which web pages you view, the Services you
              view or search for, your referring and exit pages, the length of your visits to certain pages
              and the times and dates of these actions.
            </li>
          </ul>
          <p>
            We use cookies and similar tracking technologies to gather and track such technical and usage
            information. A cookie is a small file placed on the hard drive of your computer. Tracking
            technologies we use, such as Squarespace Analytics, use cookies to collect and track information
            and to improve and analyze our Website and Services. The use of cookies is a common practice
            adopted by most major sites to better serve their visitors. Most browsers are designed to accept
            cookies, but they can be easily modified to block cookies. See your browser’s “Help” files for
            details on how to block cookies, how you know when you have received cookies, and how to disable
            cookies completely. However, if you do not accept cookies, you may be unable to access certain
            parts of our Website or Services.
          </p>
          <h3>How We Use Your Information</h3>
          <p>We use the information that we collect about you to:</p>
          <ul>
            <li>To present our Website and Services to you;</li>
            <li>To provide you with information, products, or services that you request from us;</li>
            <li>To fulfill any other purpose for which you provide it;</li>
            <li>
              To carry out our obligations and enforce our rights arising from any contracts entered into
              between you and us, including for billing and collection;
            </li>
            <li>To notify you about changes to the Website or Services;</li>
            <li>To allow you to participate in the Equity Audit and/or Equity Lens Map;</li>
            <li>
              To invite you to participate in surveys run by us on our own behalf, for example to invite your
              feedback on the Website and Services;
            </li>
            <li>
              To gather analysis or valuable information so that we can develop, test, and improve the Website
              and Services;
            </li>
            <li>To monitor the usage of our Website and Services</li>
            <li>In any other way we may describe when you provide the information; and/or</li>
            <li>For any other purpose with your consent.</li>
          </ul>
          <h3>When We Share Your Information</h3>
          <p>
            We may disclose aggregated information and/or anonymous disaggregated information collected from
            your use of the Equity Audit and Equity Lens Map without restriction. (See{' '}
            <i>Additional Terms Specific to the Equity Audit</i> and/or{' '}
            <i>Additional Terms Specific to the Equity Lens Map</i> below.)
          </p>
          <p>We may also disclose personal information that we collect as follows:</p>
          <ul>
            <li>
              To the third party service providers we use to support our business (see{' '}
              <i>Service Providers</i> below);
            </li>
            <li>
              To a buyer or other successor in the event of a merger, reorganization, restructuring,
              dissolution, or other sale or transfer of some or all of Beloved Community’s assets, whether as
              a going concern or as part of a liquidation or similar proceeding, in which personal information
              held by Beloved Community about our Website and Service users is among the assets transferred;
            </li>
            <li>
              To comply with any court order, law, or legal process, including to respond to any government or
              regulatory request;
            </li>
            <li>
              To enforce or apply our Terms of Use and other agreements, including for billing and collection
              purposes;
            </li>
            <li>To protect the security or integrity of our Website and Services;</li>
            <li>For any other purpose disclosed by us when you provide the information; and/or</li>
            <li>With your consent.</li>
          </ul>
          <h3>Choices About How We Use And Disclose Your Information</h3>
          <p>
            We strive to provide you with choices regarding the personal information you provide to us. We
            have created mechanisms to provide you with the following control over your information:
          </p>
          <ul>
            <li>
              You can set your browser to refuse all or some browser cookies or to alert you when cookies are
              being sent. If you disable or refuse cookies, please note that some parts of the Website or
              Services may then be inaccessible or not function properly.
            </li>
            <li>
              If you do not wish to have your e-mail address or other contact information used by Beloved
              Community to promote our own products or services, you can opt-out by sending us an e-mail
              stating your request to <a href="mailto:privacy@wearebeloved.org">privacy@wearebeloved.org</a>.
              If we have sent you a promotional e-mail, you may send us a return e-mail asking to be omitted
              from future e-mail distributions. This opt-out does not apply to information provided to or by
              Beloved Community in connection with purchases, license, or other agreements entered into
              between you and Beloved Community, or other transactions.
            </li>
            <li>
              You have the right to withdraw your consent at any time where Beloved Community relied on your
              consent to process your information.
            </li>
          </ul>
          <h3>Service Providers</h3>
          <p>
            We employ third party companies and individuals (“Service Providers”) to facilitate our Website
            and Services, to provide the Website and Services on our behalf, to perform service-related
            activities or to assist us in monitoring and analyzing how our Website and Services are used. In
            general, Service Providers used by us will only collect, use, and disclose your information to the
            extent necessary to allow them to perform the services they provide to us. However, certain
            Service Providers have their own privacy policies in respect to the information provided to them,
            such as those named below:
          </p>
          <ul>
            <li>
              <b>Squarespace Analytics</b>: Our Website is hosted on Squarespace. Squarespace Analytics is a
              web analytics service offered by Squarespace that tracks and reports website traffic. For more
              information on the privacy practices of Squarespace, please visit the Squarespace{' '}
              <a href="https://www.squarespace.com/privacy/">Privacy Policy</a>.
            </li>
            <li>
              <b>Quicken</b>: We use Quicken to process payments for our Services. We will not store or
              collect your payment details. That information is provided directly to Quicken, whose use of
              your personal information is governed by their{' '}
              <a href="https://www.quicken.com/privacy/us">Privacy Policy</a>.
            </li>
            <li>
              <b>Mailchimp</b>: We use Mailchimp to manage newsletter subscriptions and send emails to our
              subscribers. For more information on their privacy practices, please visit their{' '}
              <a href="https://mailchimp.com/legal/privacy/">Privacy Policy</a>.
            </li>
          </ul>
          <h3>Links To Other Websites</h3>
          <p>
            This privacy policy covers our Website and Services. Our Website may contain links to and from
            third party websites. If you click on a link to those websites, you will leave our website to go
            to the website you selected. We are not responsible for the privacy policies or the content on
            such sites. You should read the privacy policy of each website that you visit.
          </p>
          <h3>Protecting Children’s Privacy</h3>
          <p>
            We are concerned about the safety and privacy of children online. Therefore, we do not and will
            not knowingly contact or collect personal information from children under 13. Our site is not
            intended to solicit information of any kind from children under 13. It remains possible, however,
            that we may receive information given to us by or pertaining to children under 13. If we are
            notified of this, as soon as we verify the information, we will promptly delete the information
            from our servers. If you want to notify us of our receipt of information by or about any child
            under 13, please email us at{' '}
            <a href="mailto:privacy@wearebeloved.org">privacy@wearebeloved.org</a>.
          </p>
          <h3>Security</h3>
          <p>
            To help protect the privacy of your Personal Data collected by us, we maintain physical, technical
            and administrative safeguards. We update and test our security technology on an ongoing basis. We
            restrict access to your Personal Data to those employees who need to know that information to
            provide the Services. In addition, we train our employees about the importance of confidentiality
            and maintaining the privacy and security of personal data processed by the services. We commit to
            taking appropriate disciplinary measures to enforce our employees’ privacy responsibilities.
          </p>
          <h3>How long we keep your Personal Data</h3>
          <p>
            Your Personal Data is stored by us on the servers of the cloud-based database management services
            that we engage, located in the United States. We retain your Personal Data collected as reasonably
            necessary to fulfill the purposes for which we collected it, and to comply with our legal
            obligations. Personal Data of EEA residents that remains inactive (you do not take any action or
            are contacted within 1 full year) will be deleted.
          </p>
          <p>
            In no event will we keep your Personal Data for longer than is strictly necessary for the purposes
            defined in this Privacy Policy. For more information on where and how long your Personal Data is
            stored, please contact our Data Privacy Officer at the address or phone number listed below.
          </p>
          <h3>Our Headquarters</h3>
          <p>
            Beloved Community is located in the United States. Whether or not you live in the United States,
            information we collect from you will be processed in the United States. The United States has not
            sought nor received a finding of “adequacy” from foreign officials, including the European Union
            under Article 45 of the GDPR. We rely on derogations for specific situations as set forth in
            Article 49 of the GDPR. In particular, for EEA residents, we collect and transfer to the U.S.
            Personal Data only: (i) with your consent; (ii) to perform a contract with you; (iii) to conclude
            or perform a contract with another person in the furtherance of your or our legal interests; (iv)
            or to fulfill a compelling legitimate interest of ours in a manner that does not outweigh your
            rights and freedoms. We strive to apply suitable safeguards to protect the privacy and security of
            your Personal Data and to use it only consistent with your relationship with Beloved Community and
            the practices described in this Privacy Policy.
          </p>
          <h2>United Kingdom and European Union Data Subject Rights</h2>
          <h3>UK and EU Residents</h3>
          <p>
            If you are a resident of the United Kingdom or European Union (“EU”), Lichtenstein, Norway or
            Iceland (collectively “EEA”), you may have additional rights under the EU General Data Protection
            Regulation (the “GDPR”) and UK General Data Protection Regulation with respect to your Personal
            Data, as outlined below.
          </p>
          <p>
            For this section, we use the terms “Personal Data” and “processing” as they are defined in the
            GDPR, but “Personal Data” generally means information that can be used to individually identify a
            person, and “processing” generally covers actions that can be performed in connection with data
            such as collection, use, storage and disclosure. Beloved Community will be the controller of your
            Personal Data processed in connection with the Services. Personal Data includes “Special
            Categories of Personal Data”. “Special Categories of Personal Data” includes information revealing
            racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union
            membership, and the processing of genetic data, biometric data for the purpose of uniquely
            identifying a natural person, data concerning health or data concerning a natural person’s sex
            life or sexual orientation.
          </p>
          <p>
            If there are any conflicts between this section and any other provision of this Privacy Policy,
            the policy or portion that is more protective of Personal Data shall control to the extent of such
            conflict. If you have any questions about this section or whether any of the following applies to
            you, please contact us at <a href="mailto:privacy@wearebeloved.org">privacy@wearebeloved.org</a>.
          </p>
          <h3>Personal Data We Collect</h3>
          <p>
            The “Information We Collect About You” section above details the Personal Data that we collect
            from you.
          </p>
          <h3>Personal Data Use and Processing Grounds</h3>
          <p>
            The “How We Use Your Information” section above explains how we use your Personal Data. We will
            only process your Personal Data if we have a lawful basis for doing so. Lawful bases for
            processing include consent, contractual necessity and our “legitimate interests” or the legitimate
            interest of others, as further described below.
          </p>
          <ul>
            <li>
              <b>Contractual Necessity</b>: We process Personal Data as a matter of “contractual necessity”,
              meaning that we need to process the data to perform under our Terms of Use with you, which
              enables us to provide you with the Website or pursuant to our client agreements. When we process
              data due to contractual necessity, failure to provide such Personal Data will result in your
              inability to use some or all portions of the Website that require such data.
            </li>
            <li>
              <b>Legitimate Interest</b>: We process the other Personal Data listed above when we believe it
              furthers the legitimate interest of us or third parties. Examples of these legitimate interests
              include:
              <ul>
                <li>Operation and improvement of our business, products and services</li>
                <li>Marketing of our products and services</li>
                <li>Provision of customer support</li>
                <li>Protection from fraud or security threats</li>
                <li>Compliance with legal obligations</li>
                <li>Completion of corporate transactions</li>
              </ul>
            </li>
            <li>
              <b>Consent</b>: In some cases, we process Personal Data based on the consent you expressly grant
              to us at the time we collect such data. When we process Personal Data based on your consent, it
              will be expressly indicated to you at the point and time of collection.
            </li>
            <li>
              <b>Other Processing Grounds</b>: From time to time we may also need to process Personal Data to
              comply with a legal obligation, if it is necessary to protect the vital interests of you or
              other data subjects, or if it is necessary for a task carried out in the public interest.
            </li>
          </ul>
          <h3>Sharing Personal Data</h3>
          <p>
            The “When We Share Your Information” section above details how we share your Personal Data with
            third parties.
          </p>
          <h3>EU Data Subject Rights</h3>
          <p>
            You have certain rights with respect to your Personal Data, including those set forth below. For
            more information about these rights, or to submit a request, please email us at{' '}
            <a href="mailto:privacy@wearebeloved.org">privacy@wearebeloved.org</a>. Please note that in some
            circumstances, we may not be able to fully comply with your request, such as if it is frivolous or
            extremely impractical, if it jeopardizes the rights of others, or if it is not required by law,
            but in those circumstances, we will still respond to notify you of such a decision. In some cases,
            we may also need you to provide us with additional information, which may include Personal Data,
            if necessary to verify your identity and the nature of your request.
          </p>
          <ul>
            <li>
              <b>Access</b>: You can request more information about the Personal Data we hold about you and
              request a copy of such Personal Data. You can also access certain of your Personal Data by
              contacting us at <a href="mailto:privacy@wearebeloved.org">privacy@wearebeloved.org</a>.
            </li>
            <li>
              <b>Rectification</b>: If you believe that any Personal Data we are holding about you is
              incorrect or incomplete, you can request that we correct or supplement such data. You can also
              correct some of this information directly by logging on to your account or by contacting us at{' '}
              <a href="mailto:privacy@wearebeloved.org">privacy@wearebeloved.org</a>.
            </li>
            <li>
              <b>Erasure</b>: You can request that we erase some or all of your Personal Data from our
              systems.
            </li>
            <li>
              <b>Withdrawal of Consent</b>: If we are processing your Personal Data based on your consent (as
              indicated at the time of collection of such data), you have the right to withdraw your consent
              at any time. Please note, however, that if you exercise this right, you may have to then provide
              express consent on a case-by-case basis for the use or disclosure of certain of your Personal
              Data, if such use or disclosure is necessary to enable you to utilize some or all of our
              Services.
            </li>
            <li>
              <b>Portability</b>: You can ask for a copy of your Personal Data in a machine-readable format.
              You can also request that we transmit the data to another controller where technically feasible.
            </li>
            <li>
              <b>Objection</b>: You can contact us to let us know that you object to the further use or
              disclosure of your Personal Data for certain purposes, such as for direct marketing purposes.
            </li>
            <li>
              <b>Restriction of Processing</b>: You can ask us to restrict further processing of your Personal
              Data.
            </li>
            <li>
              Right to File Complaint: You have the right to lodge a complaint about Beloved Community’s
              practices with respect to your Personal Data with the supervisory authority of your country or
              EU Member State. A list of Supervisory Authorities is available here:{' '}
              <a href="https://edpb.europa.eu/about-edpb/board/members_en">
                https://edpb.europa.eu/about-edpb/board/members_en
              </a>
              .
            </li>
          </ul>
          <h3>Additional Terms Specific To Use Of The Equity Audit</h3>
          <p>
            Beloved Community has created the Equity Audit as a free assessment and evaluation tool. The
            following terms and conditions apply to information collected by means of the Equity Audit or by
            registering to use the Equity Audit:
          </p>
          <ul>
            <li>
              We will collect your responses, reports, and other information inputted or generated in
              connection with your use of the Equity Audit. Beloved Community will use this information to
              derive statistical and other data related to improving the Equity Audit, to provide analytics
              and measurements to our partners and the public, and to conduct and support research on and
              discussion of topics of diversity, equity, and inclusion.
            </li>
            <li>
              We will not share or disclose organization-specific or individual-specific data with any third
              party or with any Website user (other than the individual or organizational user that completed
              the Equity Audit) in any individualized manner. Such data, minus organizational or individual
              identifying information will, however, be used for Beloved Community’s research purposes and may
              be shared with and disclosed to third parties in the aggregate.
            </li>
            <li>
              Beloved Community shall own all right, title, and interest in the anonymized and/or aggregated
              data derived from the information inputted or generated in connection with your use of the
              Equity Audit.
            </li>
            <li>
              We may contact users of the Equity Audit to inform them of updates or new resources available
              from Beloved Community or to ask them about their experience with using the Equity Audit.
            </li>
            <li>
              We may provide you with notices about our certification mark, including expiration and renewal
              notices.
            </li>
          </ul>
          <h3>Additional Terms Specific To Use Of The Equity Lens Map</h3>
          <p>
            Beloved Community has created the Equity Lens Map as an additional assessment and evaluation tool.
            The following terms and conditions apply to information collected by means of the Equity Lens Map:
          </p>
          <ul>
            <li>
              We will collect your responses, reports, and other information inputted or generated in
              connection with your use of the Equity Lens Map. Beloved Community will use this information to
              derive statistical and other data related to improving the Equity Lens Map, to provide analytics
              and measurements to our partners and the public, and to conduct and support research on and
              discussion of topics of diversity, equity, and inclusion.
            </li>
            <li>
              Except as otherwise provided below, we will not share or disclose individual-specific data with
              any third party or with any Website user in any individualized manner. For this reason, we will
              also not share or disclose individual-specific data with the organization that ordered the
              Equity Lens Map and for which the individual is employed or working. Such data, minus individual
              identifying information will, however, be disclosed to the organization for which the individual
              works, as well as be used for Beloved Community’s research purposes and may be shared with and
              disclosed to third parties in the aggregate.
            </li>
            <li>
              Except as otherwise provided below, we will not share or disclose organization-specific data
              with any third party or with any Website user (other than the organizational user that completed
              the Equity Lens Map) in any individualized manner. Such data, minus organizational identifying
              information will, however, be used for Beloved Community’s research purposes and may be shared
              with and disclosed to third parties in the aggregate.
            </li>
            <li>
              If a third-party customer (the “Customer”) contracts with Beloved Community to provide you
              access to the Equity Lens Map, we may share or disclose organization-specific (but not
              individual-specific) data with the Customer.
            </li>
            <li>
              Beloved Community shall own all right, title, and interest in the anonymized and/or aggregated
              data derived from the information inputted or generated in connection with your use of the
              Equity Lens Map.
            </li>
            <li>
              We may contact users of the Equity Lens Map to inform them of updates or new resources available
              from Beloved Community or to ask them about their experience with using the Equity Lens Map.
            </li>
          </ul>
          <h3>Changes To This Privacy Policy</h3>
          <p>
            We may update or change this Privacy Policy from time to time in order to reflect changes in the
            services, changes in the law, or for other reasons as deemed necessary by Beloved Community, in
            its sole discretion. The date this Privacy Policy was last revised is identified at the top of the
            page. If we make material changes to this Privacy Policy, we will notify you by e-mail to the
            primary e-mail address specified in your Account or through a notice on the Website home page. You
            are responsible for ensuring we have an up-to-date, active and deliverable e-mail address for you
            or for periodically visiting our Website and this Privacy Policy to check for any changes. Your
            continued use of our Services following posting of changes constitutes your acceptance of such
            changes.
          </p>
          <h3>Questions?</h3>
          <p>
            If you have any questions, comments, or complaints regarding this Privacy Policy or our website,
            please contact us at:
          </p>
          <p>
            Beloved Community <br />
            3157 Gentilly Blvd. <br />
            #176 <br />
            New Orleans, LA 70122 <br />
            <a href="mailto:privacy@wearebeloved.org">privacy@wearebeloved.org</a>
          </p>
        </div>
      </main>
    </>
  );
};

export default PrivacyPolicy;
