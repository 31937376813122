import { combineReducers, Reducer } from 'redux';
import { AuthDataActionsTypes } from './authData/authDataActions';
import authData, { authDataState } from './authData/authDataReducer';
import utils, { UtilsState } from './utils/utilsReducer';
import organization, { OrganizationState } from './organization/organizationReducer';
import profile, { UserDataState } from './profile/profileReducer';
import equityAudit, { EquityAuditState } from './equityAudit/equityAuditReducer';
import { UtilsActionsTypes } from './utils/utilsActions';
import { ProfileActionsTypes } from './profile/profileActions';
import { EquityAuditActionsTypes } from './equityAudit/equityAuditActions';
import { TaskActionsTypes } from './task/taskActions';
import { BelAdminToolActionsTypes } from './beloved/adminTools/adminToolActions';
import belAdminTool, { BelAdminToolState } from './beloved/adminTools/adminToolReducer';
import { BelResourceActionsTypes } from './beloved/resources/resourcesActions';
import belResource, { BelResourceState } from './beloved/resources/resourcesReducer';
import { PaymentActionsTypes } from './payments/paymentsActions';
import payments, { PaymentState } from './payments/paymentsReducer';
import report, { ReportState } from './report/reportReducer';
import { ReportActionsTypes } from './report/reportActions';
import elm, { ElmState } from './elm/elmReducer';
import { ElmActionsTypes } from './elm/elmActions';
import { BelElmActionsTypes } from './beloved/elm/elmActions';
import belElm, { BelElmState } from './beloved/elm/elmReducer';
import belOrganizations, { BelOrganizationsState } from './beloved/organizations/organizationsReducer';
import { BelOrganizationsActionsTypes } from './beloved/organizations/organizationsActions';
import belEquityAudits, { BelEaState } from './beloved/equityAudit/equityAuditReducer';
import { BelEaActionsTypes } from './beloved/equityAudit/equityAuditActions';

import tables, { TablesState, TableActionsTypes } from './table/tableReducer';

import belDebriefs, { BelDebriefsState } from './beloved/debriefs/debriefsReducer';
import { BelDebriefsActionsTypes } from './beloved/debriefs/debriefsActions';
import credit, { CreditState } from './credit/creditReducer';
import { CreditActionsTypes } from './credit/creditActions';
import { DashboardActionsTypes } from './dashboard/dashboardActions';
import dashboard, { DashboardState } from './dashboard/dashboardReducer';
import { CohortActionsTypes } from './cohort/cohortActions';
import cohort, { BelCohortState } from './cohort/cohortReducer';
import { EwpActionsTypes } from './ewp/ewpActions';
import ewp, { EwpState } from './ewp/ewpReducer';
import avg, { AvgState } from './avg/avgReducer';

export type ActionType =
  | AuthDataActionsTypes
  | TableActionsTypes
  | UtilsActionsTypes
  | BelAdminToolActionsTypes
  | BelResourceActionsTypes
  | ProfileActionsTypes
  | EquityAuditActionsTypes
  | TaskActionsTypes
  | ReportActionsTypes
  | BelElmActionsTypes
  | ElmActionsTypes
  | PaymentActionsTypes
  | BelOrganizationsActionsTypes
  | BelEaActionsTypes
  | BelDebriefsActionsTypes
  | CreditActionsTypes
  | DashboardActionsTypes
  | EwpActionsTypes
  | CohortActionsTypes;

export type StateType = {
  authData: authDataState;

  tables: TablesState;

  utils: UtilsState;
  belAdminTool: BelAdminToolState;
  belResource: BelResourceState;
  organization: OrganizationState;
  profile: UserDataState;
  equityAudit: EquityAuditState;
  elm: ElmState;
  report: ReportState;
  belOrganizations: BelOrganizationsState;
  belEquityAudits: BelEaState;
  belElm: BelElmState;
  payments: PaymentState;
  belDebriefs: BelDebriefsState;
  credit: CreditState;
  dashboard: DashboardState;
  cohort: BelCohortState;
  ewp: EwpState;
  avg: AvgState;
};

const appReducer: Reducer<StateType> = combineReducers<StateType>({
  authData,
  avg,
  tables,

  utils,
  belAdminTool,
  belResource,
  organization,
  profile,
  equityAudit,
  elm,
  report,
  belOrganizations,
  belEquityAudits,
  belElm,
  payments,
  belDebriefs,
  credit,
  dashboard,
  ewp,
  cohort,
});

export default (state: StateType | undefined, action: any) => {
  return appReducer(state, action);
};
