import React, { useEffect, useState } from 'react';
import { Alert, Modal } from '../../../common';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTask, getTasks, updateTask } from '../../../../store/task/taskActions';
import { CreateOrgModel, KeyValueModel, PaginationModel, TaskAliasType, TaskModel } from '../../../../models';
import { Modals, ModalsModel, ScheduleAlias, TaskAlias } from '../../../../constants';
import styles from './MyTasksStyles.module.scss';
import AddUserRole from '../AddUserRole/AddUserRole';
import { ROOTS } from '../../../../routes/routes';
import { useHistory } from 'react-router-dom';
import { basicDateFormat, dateWithTime, debriefFormat, getLocaleUtcDateTime } from '../../../../helpers';
import { StateType } from '../../../../store/reducers';
import { Checkbox, Loader } from '../../../controls';
import { getUser, updateUserSettings } from '../../../../store/profile/profileActions';
import { useTranslation } from 'react-i18next';

interface IState {
  tasks: PaginationModel<TaskModel[]>;
}

const MyTasks = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState<IState>({
    tasks: {
      current_page: 1,
      page_size: 1000,
    } as PaginationModel<TaskModel[]>,
  });
  const [userRoleModal, setUserRoleModal] = useState<TaskModel | null>(null);
  const [disableButton, setDisableButton] = useState(false);
  const user = useSelector((state: StateType) => state.profile.authUser);
  const [modals, setModals] = useState<ModalsModel>({});
  const [checkboxConfirm, setCheckboxConfirm] = useState<boolean>(false);
  const history = useHistory();

  const { tasks } = state;

  useEffect(() => {
    requestToTasks();
  }, []);

  const requestToTasks = (requestData: KeyValueModel<any> = {}) => {
    dispatch(
      getTasks({
        data: {
          page_size: requestData.page_size || state.tasks.page_size,
          page: requestData.current_page || state.tasks.current_page,
        },
        callback: (res: PaginationModel<TaskModel[]>) => {
          setState({
            ...state,
            tasks: res,
          });
        },
      }),
    );
  };

  const handleAccept = (task: TaskModel, index: number) => {
    if (!task.sender.user_role_id && task.alias === TaskAlias.join) {
      setUserRoleModal(task);
      return;
    }
    setDisableButton(true);
    if (disableButton) {
      return;
    }
    dispatch(
      updateTask({
        data: {
          tasks: [
            {
              id: task.id,
              user_role: task.sender.user_role_id,
              user_type: task.sender.user_type_id,
            },
          ],
          status: 'accept',
        },
        callback: res => {
          setDisableButton(false);
          if (res) {
            requestToTasks();
            const result = state.tasks.result;
            result[index] = {
              ...state.tasks.result[index],
              ...res,
            };
            setState(prevState => ({
              ...prevState,
              tasks: {
                ...prevState.tasks,
                result: result,
              },
            }));
          }
        },
      }),
    );
  };

  const handleReject = (id: number, index: number) => {
    if (disableButton) {
      return;
    }
    setDisableButton(true);
    dispatch(
      updateTask({
        data: {
          tasks: [
            {
              id: id,
            },
          ],
          status: 'decline',
        },
        callback: res => {
          setDisableButton(false);
          if (res) {
            requestToTasks();
            const result = state.tasks.result;
            result[index] = {
              ...state.tasks.result[index],
              ...res,
            };
            setState(prevState => ({
              ...prevState,
              tasks: {
                ...prevState.tasks,
                result: result,
              },
            }));
          }
        },
      }),
    );
  };

  const deleteTaskRequest = (id: number) => {
    if (disableButton) {
      return;
    }
    setDisableButton(true);
    if (checkboxConfirm && user) {
      dispatch(
        updateUserSettings({
          data: {
            ...user.user.settings,
            confirmation_popup: false,
          },
          callback: () => {
            dispatch(getUser({}));
          },
        }),
      );
    }
    dispatch(
      deleteTask({
        data: {
          ids: [id],
        },
        callback: res => {
          setDisableButton(false);
          closeModal(Modals.isDeleteTask);
          if (res) {
            requestToTasks();
          }
        },
      }),
    );
  };

  const handleNavResource = (task: TaskModel) => {
    history.push(
      ROOTS.LINK_RESOURCE.replace(':entity', 'equity_audit').replace(
        ':entityId',
        `${task.additional_fields.equity_audit_id}`,
      ),
    );
  };

  const handleDelete = (id: number) => {
    if (user && user.user.settings.confirmation_popup) {
      setModals(prevState => ({
        ...prevState,
        [Modals.isDeleteTask]: id,
      }));
      return;
    }
    deleteTaskRequest(id);
  };

  const handleReschedule = (task: TaskModel) => {
    if (!task.additional_fields?.equity_audit_id) {
      return;
    }
    if (!task.additional_fields.reschedule) {
      setModals(prevState => ({
        ...prevState,
        [Modals.isReschedule]: true,
      }));
      return;
    }
    history.push(
      ROOTS.DEBRIEF_SCHEDULING.replace(':debriefAlias', ScheduleAlias.ea)
        .replace(':orgId', `${task.organization.id}`)
        .replace(':id', `${task.additional_fields.equity_audit_id}`),
    );
  };

  const handleDetails = (task: TaskModel) => {
    if (task.organization.id && task.additional_fields.ewp_id) {
      history.push(ROOTS.EWP_DETAILS.replace(':orgId', `${task.organization.id}`));
    } else if (task.additional_fields.object_type === 'elm' && task.additional_fields.object_id) {
      history.push(
        ROOTS.ELM_DETAILS.replace(':orgId', `${task.additional_fields.organization_id}`).replace(
          ':elmId',
          `${task.additional_fields.object_id}`,
        ),
      );
    } else if (
      (task.additional_fields.object_type === 'debrief' || task.additional_fields.object_type === 'ea') &&
      task.additional_fields.object_id
    ) {
      history.push(
        ROOTS.EA_DETAILS.replace(':orgId', `${task.additional_fields.organization_id}`).replace(
          ':eaId',
          `${task.additional_fields.object_id}`,
        ),
      );
    } else if (task.additional_fields.object_type === 'ewp' && task.additional_fields.object_id) {
      history.push(ROOTS.EWP_DETAILS.replace(':orgId', `${task.additional_fields.organization_id}`));
    } else if (task.organization.id || task.additional_fields.equity_audit_id) {
      history.push(
        ROOTS.EA_DETAILS.replace(':orgId', `${task.organization.id}`).replace(
          ':eaId',
          `${task.additional_fields.equity_audit_id}`,
        ),
      );
    }
  };

  const handleDetailsElm = (task: TaskModel) => {
    if (task.alias === TaskAlias.elmReadyToTake) {
      history.push(ROOTS.ELM_LIST.replace(':orgId', `${task.organization.id}`));
      return;
    }
    if (task.additional_fields.available_details) {
      history.push(
        ROOTS.ELM_DETAILS.replace(':orgId', `${task.organization.id}`).replace(
          ':elmId',
          `${task.additional_fields.elm_id}`,
        ),
      );
    } else {
      history.push(ROOTS.ELM_LIST.replace(':orgId', `${task.organization.id}`));
    }
  };

  const handleDetailsBelovedNav = (task: TaskModel) => {
    if (
      task.alias === TaskAlias.orgBelovedTeamEaDebriefNotScheduled ||
      task.alias === TaskAlias.orgBelovedTeamEaCompleted
    ) {
      history.push(
        ROOTS.BELOVED_EQUITY_AUDIT_DETAILS.replace(':eaId', `${task.additional_fields.equity_audit_id}`),
      );
    } else if (task.alias === TaskAlias.orgBelovedTeamElmPublished) {
      history.push(ROOTS.ELM_ORG_REPORT_RESP.replace(':elmId', `${task.additional_fields.elm_id}`));
    }
  };

  const renderTask = (task: TaskModel) => {
    if (task.alias === TaskAlias.join) {
      return (
        <tbody>
          <tr>
            <th>{t('common:column.from')}</th>
            <td>{task.sender.full_name}</td>
          </tr>
          <tr>
            <th>{t('common:column.email')}</th>
            <td>{task.sender.email}</td>
          </tr>
          <tr>
            <th>{t('common:column.organization')}</th>
            <td>{task.organization.name}</td>
          </tr>
          <tr>
            <th>{t('common:column.division')}</th>
            <td>{task.organization.division}</td>
          </tr>
          <tr>
            <th>{t('common:column.request-date')}</th>
            <td>{basicDateFormat(task.created_at)}</td>
          </tr>
          {(task.accepted_date || task.declined_date) && task.is_active && (
            <tr>
              <th>{t('common:column.status')}</th>
              {task.accepted_date && (
                <td>
                  {t('common:label.accepted-on')} {basicDateFormat(task.accepted_date)}
                </td>
              )}
              {task.declined_date && (
                <td>
                  {t('common:label.declined-on')} {basicDateFormat(task.declined_date)}
                </td>
              )}
            </tr>
          )}
          {!task.is_active && (
            <tr>
              <th>{t('common:column.status')}</th>
              {task.organization?.status && <td>{task.organization.status}</td>}
            </tr>
          )}
        </tbody>
      );
    } else if (task.alias === TaskAlias.association) {
      return (
        <tbody>
          <tr>
            <th>{t('common:column.from')}</th>
            <td>{task.sender.full_name}</td>
          </tr>
          <tr>
            <th>{t('common:column.email')}</th>
            <td>{task.sender.email}</td>
          </tr>
          <tr>
            <th>{t('common:column.parent-organization')}</th>
            <td>{task.organization.parent_org}</td>
          </tr>
          <tr>
            <th>{t('common:column.sub-organization')}</th>
            <td>{task.organization.sub_org}</td>
          </tr>
          <tr>
            <th>{t('common:column.request-date')}</th>
            <td>{basicDateFormat(task.created_at)}</td>
          </tr>
          {(task.accepted_date || task.declined_date) && (
            <tr>
              <th>{t('common:column.status')}</th>
              {task.accepted_date && <td>Accepted on {basicDateFormat(task.accepted_date)}</td>}
              {task.declined_date && <td>Declined on {basicDateFormat(task.declined_date)}</td>}
            </tr>
          )}
        </tbody>
      );
    } else if (task.alias === TaskAlias.eaAddedResource) {
      return (
        <tbody>
          <tr>
            <th>{t('common:column.organization')}</th>
            <td>{task.organization.name || task.additional_fields.organization}</td>
          </tr>
          {task.additional_fields.start_date ? (
            <tr>
              <th>{t('common:column.created')}</th>
              <td>{dateWithTime(task.additional_fields.start_date)}</td>
            </tr>
          ) : null}
          <tr>
            <td colSpan={2}>
              {t('awa:M242.msg', { date: basicDateFormat(task.additional_fields.created_at) })}
            </td>
          </tr>
        </tbody>
      );
    } else if (task.alias === TaskAlias.upcomingCohort || task.alias === TaskAlias.debriefUpcoming) {
      return (
        <tbody>
          <tr>
            <th>{t('common:column.organization')}</th>
            <td>{task.organization.name || task.additional_fields.organization}</td>
          </tr>
          {task.additional_fields.created ? (
            <tr>
              <th>{t('common:column.created')}</th>
              <td>{getLocaleUtcDateTime(task.additional_fields.created)}</td>
            </tr>
          ) : null}
          {task.additional_fields.start_date ? (
            <tr>
              <th>{t('common:column.start-date')}</th>
              <td>{basicDateFormat(task.additional_fields.start_date)}</td>
            </tr>
          ) : null}
          {task.additional_fields.end_date ? (
            <tr>
              <th>{t('common:column.end-date')}</th>
              <td>{basicDateFormat(task.additional_fields.end_date)}</td>
            </tr>
          ) : null}
          {task.additional_fields.scheduled_date ? (
            <tr>
              <th>{t('common:column.date')}</th>
              <td>{basicDateFormat(task.additional_fields.scheduled_date)}</td>
            </tr>
          ) : null}
        </tbody>
      );
    } else if (
      task.alias === TaskAlias.auditManager ||
      task.alias === TaskAlias.auditTeam ||
      task.alias === TaskAlias.reqEWPUserTeam ||
      task.alias === TaskAlias.reqEwpUserTeamConsultant ||
      task.alias === TaskAlias.eventAudit
    ) {
      return (
        <tbody>
          {task.additional_fields.from ? (
            <tr>
              <th>{t('common:column.from')}</th>
              <td>{task.additional_fields.from}</td>
            </tr>
          ) : null}
          {task.additional_fields.email ? (
            <tr>
              <th>{t('common:column.email')}</th>
              <td>{task.additional_fields.email}</td>
            </tr>
          ) : null}
          <tr>
            <th>{t('common:column.organization')}</th>
            <td>{task.organization.name || task.additional_fields.organization}</td>
          </tr>
          {task.additional_fields.start_date ? (
            <tr>
              <th>{t('common:column.start-date')}</th>
              <td>{basicDateFormat(task.additional_fields.start_date)}</td>
            </tr>
          ) : null}
          {task.additional_fields.text ? (
            <tr>
              <td colSpan={2}>{task.additional_fields.text}</td>
            </tr>
          ) : null}
          {task.additional_fields.status ? (
            <tr>
              <th>{t('common:column.status')}</th>
              <td>
                {task.additional_fields.status} on {basicDateFormat(task.additional_fields.status_date)}
              </td>
            </tr>
          ) : null}
        </tbody>
      );
    } else if (task.alias === TaskAlias.debrief) {
      return (
        <tbody>
          <tr>
            <th>{t('common:column.organization')}</th>
            <td>{task.organization.name}</td>
          </tr>
          <tr>
            <th>{t('common:column.debrief')}</th>
            <td>{debriefFormat(task.additional_fields.debrief_scheduled_date)}</td>
          </tr>
        </tbody>
      );
    } else if (task.alias === TaskAlias.elmReadyToTake) {
      return (
        <tbody>
          <tr>
            <th>{t('common:column.organization')}</th>
            <td>{task.organization.name}</td>
          </tr>
          <tr>
            <th>{t('common:column.start-date')}</th>
            <td>{basicDateFormat(task.additional_fields.start_date)}</td>
          </tr>
          <tr>
            <th>{t('common:column.end-date')}</th>
            <td>{basicDateFormat(task.additional_fields.end_date)}</td>
          </tr>
        </tbody>
      );
    } else if (task.alias === TaskAlias.resourceExpirationNotificationEATeam) {
      return (
        <tbody>
          <tr>
            <th>{t('common:column.organization')}</th>
            <td>{task.organization.name}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              {t('awa:M240.msg.p1', {
                name: task.additional_fields.organization_name,
                days: task.additional_fields.days,
              })}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t('awa:M240.msg.p2')}</td>
          </tr>
        </tbody>
      );
    } else if (task.alias === TaskAlias.resourceExpirationNotificationPM) {
      return (
        <tbody>
          <tr>
            <th>{t('common:column.organization')}</th>
            <td>{task.organization.name}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              {t('awa:M239.msg.p1', {
                name: task.additional_fields.organization_name,
                days: task.additional_fields.days,
              })}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t('awa:M239.msg.p2')}</td>
          </tr>
        </tbody>
      );
    } else if (
      task.alias === TaskAlias.basicReportAvailable ||
      task.alias === TaskAlias.detailedReportAvailable ||
      task.alias === TaskAlias.elmReportIsAvailable ||
      task.alias === TaskAlias.elmOrgReportIsAvailable ||
      task.alias === TaskAlias.auditUpcoming ||
      task.alias === TaskAlias.inactivityNotification ||
      task.alias === TaskAlias.reqToJoinOrganizationConsultant
    ) {
      return (
        <tbody>
          <tr>
            <th>{t('common:column.organization')}</th>
            <td>{task.organization.name}</td>
          </tr>
          {task.additional_fields.last_edit_date && task.alias === TaskAlias.auditUpcoming ? (
            <tr>
              <th>{t('common:column.ea-start-date')}</th>
              <td>{basicDateFormat(task.additional_fields.start_date)}</td>
            </tr>
          ) : null}
          {task.additional_fields.last_edit_date ? (
            <tr>
              <th>{t('common:column.last-edit-date')}</th>
              <td>{basicDateFormat(task.additional_fields.last_edit_date)}</td>
            </tr>
          ) : null}
        </tbody>
      );
    } else if (
      task.alias === TaskAlias.orgBelovedTeamEaDebriefNotScheduled ||
      task.alias === TaskAlias.orgBelovedTeamEaCompleted ||
      task.alias === TaskAlias.orgBelovedTeamElmPublished
    ) {
      return (
        <tbody>
          <tr>
            <th>{t('common:column.organization')}</th>
            <td>{task.organization.name}</td>
          </tr>
          {task.additional_fields.scheduled_date && task.alias === TaskAlias.orgBelovedTeamEaCompleted ? (
            <tr>
              <th>{t('common:label.submission-date')}</th>
              <td>{getLocaleUtcDateTime(task.additional_fields.scheduled_date)}</td>
            </tr>
          ) : null}
          {task.additional_fields.elm_publish_date ? (
            <tr>
              <th>{t('common:label.publish-date')}</th>
              <td>{getLocaleUtcDateTime(task.additional_fields.elm_publish_date)}</td>
            </tr>
          ) : null}
          {task.additional_fields.purchase_date ? (
            <tr>
              <th>{t('common:label.purchase-date')}</th>
              <td>{getLocaleUtcDateTime(task.additional_fields.purchase_date)}</td>
            </tr>
          ) : null}
        </tbody>
      );
    }
  };

  const getIconClass = (alias: TaskAliasType): string => {
    switch (alias) {
      case TaskAlias.join:
      case TaskAlias.association: {
        return '_book';
      }
      case TaskAlias.auditTeam:
      case TaskAlias.auditManager:
      case TaskAlias.eventAudit: {
        return '_audit';
      }
      case TaskAlias.debrief: {
        return '_calendar';
      }
      case TaskAlias.elmReportIsAvailable:
      case TaskAlias.elmReadyToTake:
      case TaskAlias.inactivityNotification:
      case TaskAlias.elmOrgReportIsAvailable: {
        return '_elm';
      }
      case TaskAlias.reqEWPUserTeam:
      case TaskAlias.reqEwpUserTeamConsultant: {
        return '_ewp';
      }
      case TaskAlias.basicReportAvailable:
      case TaskAlias.detailedReportAvailable:
      case TaskAlias.auditUpcoming: {
        return '_ea';
      }
      case TaskAlias.upcomingCohort: {
        return '_cohort';
      }
      case TaskAlias.eaAddedResource: {
        return '_resource';
      }
      default: {
        return '_book';
      }
    }
  };

  const renderEmptyBlock = () => {
    return (
      <div className={styles.emptyBlock}>
        <div className={styles.emptyBlock__card}>
          <span className={styles.emptyBlock__text}>{t('main:my-task.no-tasks')}</span>
        </div>
      </div>
    );
  };

  const renderDetailsBtnText = (task: TaskModel) => {
    if (task.alias === TaskAlias.inactivityNotification) {
      if (
        task.additional_fields.alias === 'in_preparation' ||
        task.additional_fields.alias === 'in_progress' ||
        task.additional_fields.alias === 'not_purchased' ||
        task.additional_fields.alias === 'no_users_added' ||
        task.additional_fields.alias === 'no_updates' ||
        task.additional_fields.alias === 'not_closed' ||
        task.additional_fields.alias === 'no_updates' ||
        task.additional_fields.alias === 'no_elements_updates'
      ) {
        return t('common:btn.continue');
      } else if (task.additional_fields.alias === 'preparation_completed') {
        return t('common:btn.start-ea');
      } else if (task.additional_fields.alias === 'not_scheduled') {
        return t('common:btn.schedule-debrief');
      } else if (task.additional_fields.alias === 'not_published') {
        return t('common:btn.publish-report');
      }
    } else if (
      task.alias === TaskAlias.orgBelovedTeamEaCompleted ||
      task.alias === TaskAlias.orgBelovedTeamEaDebriefNotScheduled
    ) {
      return t('main:ea.ea');
    } else if (task.alias === TaskAlias.orgBelovedTeamElmPublished) {
      return t('common:btn.elm-report');
    } else {
      return t('common:btn.details');
    }
  };

  const renderButtons = (task: TaskModel, index: number) => {
    if (task.alias === TaskAlias.debrief) {
      return (
        <div className="b-myTasksItem__tools">
          <button className="b-myTasksItem__btn" onClick={() => handleReschedule(task)}>
            {t('common:btn.reschedule')}
          </button>
          <button className="b-myTasksItem__delete" onClick={() => handleDelete(task.id)}>
            {t('common:btn.delete')}
          </button>
        </div>
      );
    } else if (task.alias === TaskAlias.eaAddedResource) {
      return (
        <div className="b-myTasksItem__tools">
          <button className="b-myTasksItem__btn" onClick={() => handleNavResource(task)}>
            {t('common:btn.view-resources')}
          </button>
          <button className="b-myTasksItem__delete" onClick={() => handleDelete(task.id)}>
            {t('common:btn.delete')}
          </button>
        </div>
      );
    } else if (task.alias === TaskAlias.resourceExpirationNotificationPM) {
      return (
        <div className="b-myTasksItem__tools">
          <a
            rel="noreferrer"
            target="_blank"
            className="b-myTasksItem__btn"
            href="mailto:support@awabybeloved.freshdesk.com?subject=Request for Beloved Services&body=Dear Beloved Team,%0d%0aI'm interested in learning about Beloved Services. Please, contact me via phone <please add your phone number> or email.%0d%0a%0d%0aThank you!"
          >
            {t('common:btn.contact-beloved')}
          </a>
          <button className="b-myTasksItem__delete" onClick={() => handleDelete(task.id)}>
            {t('common:btn.delete')}
          </button>
        </div>
      );
    } else if (
      task.alias === TaskAlias.upcomingCohort ||
      task.alias === TaskAlias.resourceExpirationNotificationEATeam
    ) {
      return (
        <div className="b-myTasksItem__tools">
          <button className="b-myTasksItem__delete" onClick={() => handleDelete(task.id)}>
            {t('common:btn.delete')}
          </button>
        </div>
      );
    } else if (
      task.alias === TaskAlias.basicReportAvailable ||
      task.alias === TaskAlias.debriefUpcoming ||
      task.alias === TaskAlias.detailedReportAvailable ||
      task.alias === TaskAlias.inactivityNotification ||
      task.alias === TaskAlias.auditUpcoming
    ) {
      return (
        <div className="b-myTasksItem__tools">
          <button className="b-myTasksItem__btn" onClick={() => handleDetails(task)}>
            {renderDetailsBtnText(task)}
          </button>
          <button className="b-myTasksItem__delete" onClick={() => handleDelete(task.id)}>
            {t('common:btn.delete')}
          </button>
        </div>
      );
    } else if (
      task.alias === TaskAlias.orgBelovedTeamEaDebriefNotScheduled ||
      task.alias === TaskAlias.orgBelovedTeamEaCompleted ||
      task.alias === TaskAlias.orgBelovedTeamElmPublished
    ) {
      return (
        <div className="b-myTasksItem__tools">
          <button className="b-myTasksItem__btn" onClick={() => handleDetailsBelovedNav(task)}>
            {renderDetailsBtnText(task)}
          </button>
          <button className="b-myTasksItem__delete" onClick={() => handleDelete(task.id)}>
            {t('common:btn.delete')}
          </button>
        </div>
      );
    } else if (
      task.alias === TaskAlias.elmReadyToTake ||
      task.alias === TaskAlias.elmReportIsAvailable ||
      task.alias === TaskAlias.elmOrgReportIsAvailable
    ) {
      return (
        <div className="b-myTasksItem__tools">
          <button className="b-myTasksItem__btn" onClick={() => handleDetailsElm(task)}>
            {t('common:btn.details')}
          </button>
          <button className="b-myTasksItem__delete" onClick={() => handleDelete(task.id)}>
            {t('common:btn.delete')}
          </button>
        </div>
      );
    }
    return (
      <div className="b-myTasksItem__tools">
        {!(task.accepted_date || task.declined_date) && task.is_active && (
          <>
            <button
              className="b-myTasksItem__btn b-myTasksItem__btn_accept"
              onClick={() => handleAccept(task, index)}
            >
              {t('common:btn.accept')}
            </button>
            <button
              className="b-myTasksItem__btn b-myTasksItem__btn_reject"
              onClick={() => handleReject(task.id, index)}
            >
              {t('common:btn.reject')}
            </button>
          </>
        )}
        <button className="b-myTasksItem__delete" onClick={() => handleDelete(task.id)}>
          {t('common:btn.delete')}
        </button>
      </div>
    );
  };

  const closeModal = (modal: keyof ModalsModel) => {
    setModals(prevState => ({
      ...prevState,
      [modal]: false,
    }));
  };

  const saveRole = (formData: CreateOrgModel) => {
    dispatch(
      updateTask({
        data: {
          tasks: [
            {
              id: Number(userRoleModal?.id),
              user_role: Number(formData.role_id),
              user_type: Number(formData.type_id),
            },
          ],
          status: 'accept',
        },
        callback: res => {
          setDisableButton(false);
          if (res) {
            requestToTasks();
            setUserRoleModal(null);
          }
        },
      }),
    );
  };

  return (
    <>
      <main className={'pageContainer pageContainer__content'}>
        <div className="b-myTasks">
          <div className="b-myTasks__header">
            <h1 className={'pageContainer__title b-myTasks__pageTitle'}>{t('common:tabs.my-tasks')}</h1>
          </div>
          <div className="b-myTasksItemList">
            {tasks?.result && tasks.result.length
              ? tasks.result.map((task, index: number) => {
                  return (
                    <div key={`task-${task.id}`} className="b-myTasksItem">
                      <strong className="b-myTasksItem__title">
                        <span
                          className={`b-myTasksItem__icon b-myTasksItem__icon${getIconClass(task.alias)}`}
                        />
                        {task.name}
                      </strong>
                      <span className="b-myTasksItem__subTitle">{debriefFormat(task.created_at)}</span>
                      <table>{renderTask(task)}</table>
                      {renderButtons(task, index)}
                    </div>
                  );
                })
              : null}
            {!tasks?.result ? <Loader fitParent /> : null}
          </div>
        </div>
        {tasks?.result && !tasks.result.length ? renderEmptyBlock() : null}
      </main>
      {userRoleModal ? (
        <Modal
          width={545}
          onClose={() => setUserRoleModal(null)}
          className={styles.modal}
          title={`Assign a User Role`}
        >
          <AddUserRole handleClosePopup={() => setUserRoleModal(null)} saveAffiliation={saveRole} />
        </Modal>
      ) : null}
      {modals.isDeleteTask && (
        <Alert
          isOpen={!!modals.isDeleteTask}
          onRequestClose={() => closeModal(Modals.isDeleteTask)}
          title={'Delete'}
          text={
            <div>
              <p>{t('awa:N27.msg.p1')}</p>
              <div className={styles.checkbox}>
                <Checkbox checked={checkboxConfirm} onChange={e => setCheckboxConfirm(e.target.checked)}>
                  <span>{t('awa:N27.msg.p2')}</span>
                </Checkbox>
              </div>
            </div>
          }
          buttons={{
            left: {
              title: t('common:btn.cancel'),
              type: 'white',
              onClick: () => closeModal(Modals.isDeleteTask),
            },
            right: {
              type: 'orange-light',
              onClick: () => deleteTaskRequest(Number(modals.isDeleteTask)),
              title: t('common:btn.delete'),
              enableEnter: true,
            },
          }}
        />
      )}
      {modals.isReschedule && (
        <Alert
          isOpen={modals.isReschedule}
          onRequestClose={() => closeModal(Modals.isReschedule)}
          title={t('awa:N24.title')}
          text={t('awa:N24.msg')}
          buttons={{
            left: {
              title: t('common:btn.ok'),
              type: 'orange-light',
              onClick: () => closeModal(Modals.isReschedule),
              enableEnter: true,
            },
          }}
        />
      )}
    </>
  );
};

export default MyTasks;
