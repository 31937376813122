import React, { FC } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Plugin,
  ChartOptions,
  LegendItem,
  LegendElement,
  ChartEvent,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { KeyValueModel, EaByModel, ELMByStatusModel } from '../../../models';
import {
  dashboardEaBackgrounds,
  dashboardElmBackgrounds,
  elmByDivisionBackgrounds,
} from '../../../constants/dashboard';
import { useTranslation } from 'react-i18next';
ChartJS.register(ArcElement, Tooltip, Legend);

interface IProps {
  chart: KeyValueModel<EaByModel> | KeyValueModel<ELMByStatusModel> | undefined;
  type: 'elm' | 'ea' | 'elmByDivision';
}

const ChartPie: FC<IProps> = ({ chart, type }) => {
  const { t } = useTranslation();

  const getBackgrounds = () => {
    if (type === 'elm') {
      return dashboardElmBackgrounds;
    }
    if (type === 'ea') {
      return dashboardEaBackgrounds;
    }
    if (type === 'elmByDivision') {
      return elmByDivisionBackgrounds;
    }
  };

  const getTitle = () => {
    switch (type) {
      case 'elmByDivision':
        return 'State:';
      default:
        return '';
    }
  };

  const state = {
    labels: Object.values(chart || {}).map(m => m.type || m.status),
    datasets: [
      {
        labels: Object.keys(chart || {}).map(m => m),
        backgroundColor: getBackgrounds(),
        borderWidth: 0,
        hoverOffset: 5,
        data: Object.values(chart || {}).map(m => m.percent),
      },
    ],
  };
  const options: ChartOptions<any> = {
    responsive: true,
    layout: {
      padding: {
        bottom: 5,
        top: 0,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (data: any) {
            const el: any = chart?.[data.dataset && data.dataset.labels[data.dataIndex]] || 0;
            return `${data.label}: ${el.val} (${data.formattedValue}%)`;
          },
        },
      },
      legend: {
        position: 'bottom',
        display: false,
        lineWidth: 0,
        labels: {
          boxWidth: 4,
          usePointStyle: true,
          pointStyle: 'circle',
        },
        onClick: (
          el: LegendElement<any>,
          e: ChartEvent,
          legendItem: LegendItem,
          legend: LegendElement<any>,
        ) => {},
      },
      title: {
        display: true,
      },
      datalabels: {
        labels: [
          {
            align: 'top',
            formatter: (value: string, data: any) => {
              if ((chart?.[data.dataset && data.dataset.labels[data.dataIndex]].percent || 0) < 9) {
                return '';
              }
              const count = chart?.[data.dataset && data.dataset.labels[data.dataIndex]].val || 0;
              return `${count}`;
            },
            font: {
              weight: 'bold',
              family: 'Rubik',
              size: '16px',
            },
            color: '#fff',
          },
          {
            align: 'bottom',
            formatter: (value: string, data: any) => {
              if ((chart?.[data.dataset && data.dataset.labels[data.dataIndex]].percent || 0) < 9) {
                return '';
              }
              return `(${value}%)`;
            },
            font: {
              weight: 'normal',
              family: 'Rubik',
              size: '11px',
            },
            color: '#fff',
          },
        ],
      },
    },
  };

  const title = getTitle();

  return (
    <>
      <div className="b-statusChart__container">
        <Pie data={state} options={options} plugins={[ChartDataLabels] as Plugin<any>[]} />
      </div>
      {title && <div className="b-statusChart__label">{title}</div>}
      {type === 'elm' && (
        <div className="b-statusMonthsChart__legend">
          <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--completed">
            {t('common:status.completed')}
          </div>
          <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--inProgress">
            {t('common:status.in-progress')}
          </div>
          <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--prepCompleted">
            {t('common:status.not-started')}
          </div>
        </div>
      )}
      {type === 'ea' && (
        <div className="b-statusMonthsChart__legend">
          <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--inProgress">
            {t('main:charts.non-profit')}
          </div>
          <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--completed">
            {t('main:charts.k12-school')}
          </div>
          <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--prepCompleted">
            {t('main:charts.college')}
          </div>
          <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--inPrep">
            {t('main:charts.for-profit')}
          </div>
        </div>
      )}
      {type === 'elmByDivision' && (
        <div className="b-statusMonthsChart__legend">
          <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--completed">
            {t('common:column.division')} A
          </div>
          <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--inProgress">
            {t('common:column.division')} B
          </div>
          <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--prepCompleted">
            {t('common:column.division')} C
          </div>
          <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--inPrep">
            {t('common:column.division')} D
          </div>
          <div className="b-statusMonthsChart__legendItem b-statusMonthsChart__legendItem--deleted">
            {t('common:column.division')} E
          </div>
        </div>
      )}
    </>
  );
};

export default ChartPie;
