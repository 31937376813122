import { BelCohortFormModel, BelCohortCreateModel, BelSessionModel, CohortMessageModel } from '../models';
import dayjs from 'dayjs';

export const cohortConvertToRequest = (cohort: BelCohortFormModel, id?: string): BelCohortCreateModel => {
  const belovedTeamIds = cohort.beloved_team && cohort.beloved_team.map(b => Number(b.value));
  const data: BelCohortCreateModel = {
    beloved_team_ids: belovedTeamIds || undefined,
    organizations:
      (cohort.organizations &&
        cohort.organizations.map(b => ({
          organization_id: Number(b.value),
          beloved_team_id:
            b.beloved_team_id && belovedTeamIds.includes(b.beloved_team_id) ? b.beloved_team_id : undefined,
        }))) ||
      undefined,
    status: (cohort.status?.value && String(cohort.status.value)) || undefined,
    type: (cohort.type?.value && String(cohort.type.value)) || undefined,
    start_date: (cohort?.start_date && new Date(cohort.start_date)) || undefined,
    end_date: (cohort?.end_date && new Date(cohort.end_date)) || undefined,
    name: cohort.name,
    activity_deck: cohort.activity_deck || '',
    image_id: cohort.image_id || undefined,
  };

  if (cohort.image_id === null || cohort.image_id) {
    data.image_id = cohort.image_id;
  }
  if (id) {
    data.id = Number(id);
  }
  return data;
};

export const getSessionPosition = (sessions: BelSessionModel[]): number => {
  if (sessions) {
    let tempSession: BelSessionModel | null = null;
    sessions.map(session => {
      if (dayjs(session.start_date).isBefore(dayjs().add(-1, 'd'))) {
        tempSession = session;
      }
      return session;
    });
    if (tempSession) {
      return sessions.findIndex(s => s.id === tempSession?.id);
    }
  }
  return 0;
};

export const getMessagePosition = (messages: CohortMessageModel[]): number => {
  if (messages) {
    return messages.findIndex(f => !f.is_seen);
  }
  return 0;
};
