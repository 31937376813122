import React, { useEffect, useState } from 'react';
import { Alert, HeadlineOrganization, OrgBreadcrumbs } from '../../../../common';
import { Loader } from '../../../../controls';
import {
  ElmQuestionModel,
  ElmTakingModel,
  ElmTakingStepType,
  HandleSaveType,
  OrganizationDetailsModel,
} from '../../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import Intro from './Intro/Intro';
import PQIntro from './PQIntro/PQIntro';
import Question from './Question/Question';
import DQIntro from './DQIntro/DQIntro';
import Submission from './Submission/Submission';
import { saveAnswerElm, SaveAnswerElmPayload, submitElm, takeElm } from '../../../../../store/elm/elmActions';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

interface ILocationState {
  elmId: number;
}

interface IState {
  currentStep: ElmTakingStepType | null;
  currentQuestion: ElmQuestionModel | null;
  data: ElmTakingModel | null;
}

const TakeElm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentOrg: OrganizationDetailsModel | null = useSelector(
    (state: StateType) => state.organization.currentOrg,
  );
  const location = useLocation<ILocationState>();
  const { elmId } = location.state;

  let [isLoading, setIsLoading] = useState(false);
  let [showSubmitAlert, setShowSubmitAlert] = useState(false);

  const initialState = {
    currentStep: null,
    currentQuestion: null,
    data: null,
  };

  const [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (elmId) {
      dispatch(
        takeElm({
          elmId,
          callback: ({ data }) => {
            if (data) {
              setState(prevState => ({
                ...prevState,
                currentQuestion: data.question,
                data,
                currentStep: data.step,
              }));
            }
          },
        }),
      );
    }
  }, []);

  const closeSubmitAlert = () => {
    setShowSubmitAlert(false);
    history.go(-1);
  };

  const handleSubmitElm = () => {
    setIsLoading(true);
    dispatch(
      submitElm({
        elmId,
        callback: error => {
          if (!error) {
            setShowSubmitAlert(true);
          }
        },
      }),
    );
  };

  const handleSaveAnswer: HandleSaveType = ({ next, isIntro, answer }) => {
    if (!next && !isIntro) {
      history.go(-1);
      return;
    }
    const data: SaveAnswerElmPayload['data'] = {
      next,
      step: state.currentStep as ElmTakingStepType,
      answer,
    };
    if (state.currentQuestion) {
      data.question_id = state.currentQuestion.id;
    }
    dispatch(
      saveAnswerElm({
        elmId,
        data,
        callback: ({ data }) => {
          if (next) {
            if (data) {
              setState(prevState => ({
                ...prevState,
                currentQuestion: data.question,
                data,
                currentStep: data.step,
              }));
            }
          } else {
            history.go(-1);
          }
        },
      }),
    );
  };

  const renderContent = () => {
    if (!state.currentStep) {
      return <Loader fitParent />;
    }
    if (state.currentStep === 'finish') {
      return <Submission onSave={handleSaveAnswer} onSubmit={handleSubmitElm} />;
    }
    if (state.currentStep === 'intro') {
      return <Intro onSave={handleSaveAnswer} />;
    }
    if (state.currentStep === 'identity_questions') {
      if (state.currentQuestion) {
        return <Question onSave={handleSaveAnswer} question={state.currentQuestion} />;
      } else {
        return <PQIntro data={state.data as ElmTakingModel} onSave={handleSaveAnswer} />;
      }
    }
    if (state.currentStep === 'domain_questions') {
      if (state.currentQuestion) {
        return (
          <Question
            counter={state.data?.counter}
            onSave={handleSaveAnswer}
            question={state.currentQuestion}
          />
        );
      } else {
        return <DQIntro data={state.data as ElmTakingModel} onSave={handleSaveAnswer} />;
      }
    }
    return null;
  };

  return (
    <div className="pageContainer pageContainer__content -noRelative b-elm">
      <HeadlineOrganization
        orgName={currentOrg?.name || ''}
        orgId={currentOrg?.id || ''}
        headline={t('common:headlines.equity-lens-map')}
        customClasses={'-desktop'}
      />
      <div className="pageContainer__title b-equityAudit__pageTitle -mobile">
        {t('common:headlines.equity-lens-map')}
      </div>
      <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
      {isLoading && <Loader fitParent />}
      {renderContent()}
      {showSubmitAlert ? (
        <Alert
          isOpen={showSubmitAlert}
          onRequestClose={closeSubmitAlert}
          title={t('awa:M68.title')}
          text={t('awa:M68.msg')}
          buttons={{
            left: {
              title: t('common:btn.ok'),
              type: 'orange-light',
              onClick: closeSubmitAlert,
              enableEnter: true,
            },
          }}
        />
      ) : null}
    </div>
  );
};

export default TakeElm;
