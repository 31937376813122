import { ThemeEnum } from '../../models';
import { useEffect, useState } from 'react';
import { DARK_MODE_ENABLED } from '../../constants';

export const useIsDarkMode = () => {
  let [isDarkMode, setIsDarkMode] = useState<boolean | null>(
    Number(localStorage.getItem('theme')) === ThemeEnum.dark || null,
  );
  useEffect(() => {
    if (DARK_MODE_ENABLED) {
      setIsDarkMode(document.documentElement.className === 'dark');
    } else {
      setIsDarkMode(false);
    }
  }, [document.documentElement.className]);

  return { isDarkMode };
};
