import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartLabelModel, ChartStandardModel } from '../../../models';
import { Chart, ChartData, Plugin } from 'chart.js';
import { getChartLineColor } from '../../../helpers';
import { useChart } from '../../../hooks/common/use-chart';

interface IProps {
  chart: ChartStandardModel | undefined;
  isCompare: boolean;
  isDebrief?: boolean;
  rowClick?: (row: ChartLabelModel) => void;
  additionalRow?: number[] | 'N/A'[];
}

function ChartBar({ chart, isCompare, isDebrief, rowClick, additionalRow }: IProps) {
  if (!chart || !Object.values(chart).length) {
    return null;
  }

  const chartRef = useRef<HTMLCanvasElement>(null);
  const { chartOptions, backgroundPlugin, isDarkMode } = useChart(chart);
  const { labels, data, global_data } = chart;
  if (isDarkMode === null) {
    return null;
  }
  const getLineHeight = () => {
    if (additionalRow && isCompare) {
      return 0.8;
    } else if (additionalRow) {
      return 0.7;
    }
    if (isCompare) {
      return 0.7;
    } else {
      return 0.5;
    }
  };

  const getNumberedData = (items: number[] | 'N/A'[]): number[] => {
    const intData = items.map(d => {
      if (d === 'N/A') {
        return 0;
      }
      return d;
    });
    return intData;
  };

  const datasets = [
    {
      label: 'Standard',
      backgroundColor: (data: any) => {
        return getChartLineColor(labels?.[data.index]?.alias);
      },
      borderColor: 'rgba(1, 1, 1, 0)',
      borderWidth: 1,
      hoverBackgroundColor: '#ffb71b',
      hoverBorderColor: '#ffb71b',
      data: getNumberedData(data),
      borderRadius: 20,
      barPercentage: getLineHeight(),
      categoryPercentage: getLineHeight(),
    },
  ];
  if (additionalRow) {
    datasets.push({
      label: 'Global2',
      backgroundColor: () => '#777',
      borderColor: 'rgba(1, 1, 1, 0)',
      borderWidth: 1,
      hoverBackgroundColor: '#ccc',
      hoverBorderColor: '#ccc',
      data: getNumberedData(additionalRow),
      borderRadius: 20,
      barPercentage: getLineHeight(),
      categoryPercentage: getLineHeight(),
    });
  }
  if (isCompare) {
    datasets.push({
      label: 'Global',
      backgroundColor: () => '#ccc',
      borderColor: 'rgba(1, 1, 1, 0)',
      borderWidth: 1,
      hoverBackgroundColor: '#ccc',
      hoverBorderColor: '#ccc',
      data: global_data,
      borderRadius: 20,
      barPercentage: getLineHeight(),
      categoryPercentage: getLineHeight(),
    });
  }

  const dataHorBar: ChartData<any, number, ChartLabelModel> = {
    labels: labels,
    datasets,
  };

  const getHeight = (): number => {
    let result = 16;
    if (isDebrief) {
      if (data.length > 10) {
        result = 8;
      } else if (data.length > 6 && data.length <= 10) {
        result = 11;
      }
    }
    return data.length * result;
  };

  return (
    <Bar
      data={dataHorBar}
      ref={chartRef as any}
      getElementAtEvent={(i: any, event: any) => {
        if (chartRef.current && rowClick) {
          const chart = Chart.getChart(chartRef.current);
          const clickedElements = chart!.getElementsAtEventForMode(
            event,
            'y',
            { axis: 'x', intersect: false },
            true,
          );
          if (clickedElements.length > 0) {
            rowClick(labels[clickedElements[0].index]);
            console.log(labels[clickedElements[0].index]);
          }
        }
      }}
      options={chartOptions}
      plugins={[ChartDataLabels, backgroundPlugin] as Plugin<any>[]}
      height={getHeight()}
    />
  );
}

export default ChartBar;
