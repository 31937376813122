import React, { FC } from 'react';
import { t } from 'i18next';
import { ResourceStatuses } from '../../../constants';

interface IProps {
  status: ResourceStatuses;
}

const ResourceStatusLabel: FC<IProps> = ({ status }) => {
  switch (status) {
    case ResourceStatuses.SUBMIT:
      return <div className="b-status -used">{t('common:status.submitted')}</div>;
    case ResourceStatuses.ACCEPT:
      return <div className="b-status -prepCompl">{t('common:status.accepted')}</div>;
    case ResourceStatuses.REJECT:
      return <div className="b-status -red">{t('common:status.rejected')}</div>;
    case ResourceStatuses.UNPUBLISH:
      return <div className="b-status -darkNavy">{t('common:status.unpublished')}</div>;
    case ResourceStatuses.PUBLISH:
      return <div className="b-status -completed">{t('common:status.published')}</div>;
    case ResourceStatuses.IN_REVIEW:
      return <div className="b-status -inProgress">{t('common:status.in-review')}</div>;
    default:
      return null;
  }
};

export default ResourceStatusLabel;
