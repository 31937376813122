import React, { useEffect } from 'react';
import BackTitleButton from '../parts/BackTitleButton';
import { HeadlineOrganization, OrgBreadcrumbs, Pagination } from '../../../../common';
import EwpTabs from '../parts/EwpTabs';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useIsDarkMode } from '../../../../../hooks/common/use-is-dark-mode';
import useTableData from '../../../../../hooks/common/use-table-data';
import {
  EwpElementTypes,
  EwpReviewItem,
  IRouteParamsBase,
  TableDataEntities,
  TableToolSelectOptionModel,
} from '../../../../../models';
import { getColumns } from './parts/columns';
import { Loader, TableToolSelect } from '../../../../controls';
import DataGrid from 'react-data-grid';
import { useGetEwpRoute } from '../../../../../hooks/ewp/use-get-ewp-route';
import { ROOTS } from '../../../../../routes/routes';
import { useFilterOptions } from '../../../../../hooks/ewp/use-filter-options';
import FilterModalButton from '../../../../common/FilterModalButton/FilterModalButton';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import useTableConfig from '../../../../../hooks/common/use-table-config';
import { useEwpDetails } from '../../../../../hooks/ewp/use-ewp-details';
import { toast } from 'react-toastify';
import { useEwpReviewElementsApi } from '../../../../../hooks/ewp/use-ewp-review-elements-api';

const EwpReviewItems = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isDarkMode } = useIsDarkMode();
  const { ewpDetails } = useEwpDetails();

  const ewpId = useParams<IRouteParamsBase>().id;
  const { markSnooze, markRead } = useEwpReviewElementsApi();
  const { filterOptions } = useFilterOptions(ewpDetails?.organization_id);
  const EWP_GOAL_DETAILS = useGetEwpRoute(ROOTS.EWP_GOAL_DETAILS);
  const EWP_PRIORITY_DETAILS = useGetEwpRoute(ROOTS.EWP_PRIORITY_DETAILS);
  const EWP_STRATEGY_DETAILS = useGetEwpRoute(ROOTS.EWP_STRATEGY_DETAILS);
  const EWP_KA_DETAILS = useGetEwpRoute(ROOTS.EWP_KA_DETAILS);

  const {
    data,
    query,
    fetchData,
    handleChangePage,
    handleSelectOne,
    handleChangePageSize,
    handleSearch,
    handleApplyFilter,
    handleSelectFilter,
    filterData,
    isFiltersChanged,
    selectedEntitiesIds,
    handleSort,
    setSelectedEntitiesIds,
  } = useTableData(TableDataEntities.EWP_REVIEW_ITEMS, { ewp_id: ewpId });

  useEffect(() => {
    fetchData(query);
  }, []);

  const { columns, onColumnResize } = useTableConfig<EwpReviewItem>(
    TableDataEntities.EWP_REVIEW_ITEMS,
    getColumns(),
  );

  const navToDetails = (data: EwpReviewItem) => {
    if (!ewpDetails) return;
    markRead(ewpDetails.organization_id, true, [data.id]);
    if (data.element_type === EwpElementTypes.priority) {
      history.push(EWP_PRIORITY_DETAILS.replace(':elId', `${data.element_id}`));
    } else if (data.element_type === EwpElementTypes.goal) {
      history.push(EWP_GOAL_DETAILS.replace(':elId', `${data.element_id}`));
    } else if (data.element_type === EwpElementTypes.strategy) {
      history.push(EWP_STRATEGY_DETAILS.replace(':elId', `${data.element_id}`));
    } else if (data.element_type === EwpElementTypes.key_action) {
      history.push(EWP_KA_DETAILS.replace(':elId', `${data.element_id}`));
    }
  };

  const handleClickActionOption = async (alias: string) => {
    if (!ewpDetails) return;

    const callback = () => {
      toast.success(t('common:toast.success'));
      setSelectedEntitiesIds([]);
      fetchData(query);
    };

    switch (alias) {
      case 'read': {
        markRead(ewpDetails.organization_id, true, selectedEntitiesIds, callback);
        return;
      }
      case 'unread': {
        markRead(ewpDetails.organization_id, false, selectedEntitiesIds, callback);
        return;
      }
      case 'snooze': {
        markSnooze(ewpDetails.organization_id, true, selectedEntitiesIds, callback);
        return;
      }
      case 'unsnooze': {
        markSnooze(ewpDetails.organization_id, false, selectedEntitiesIds, callback);
        return;
      }
    }
  };

  const elementActionOptions: TableToolSelectOptionModel[] = [
    {
      title: t('common:label.mark-as-read'),
      alias: 'read',
      onClick: handleClickActionOption,
    },
    {
      title: t('common:label.mark-as-unread'),
      alias: 'unread',
      onClick: handleClickActionOption,
    },
    {
      title: t('common:label.snooze'),
      alias: 'snooze',
      onClick: handleClickActionOption,
    },
    {
      title: t('common:label.unsnooze'),
      alias: 'unsnooze',
      onClick: handleClickActionOption,
    },
  ];

  const renderContent = () => {
    if (!data) return <Loader />;

    return (
      <div>
        <DndProvider backend={HTML5Backend}>
          <DataGrid
            noRowsFallback={<div className="b-no-content">{t('awa:N41.msg')}</div>}
            rowClass={row => (!row.is_read ? 'font-weight-bold' : '')}
            onRowClick={navToDetails}
            onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
            columns={columns}
            sortColumns={query.sorting ? [query.sorting] : []}
            rows={data.result}
            onColumnResize={onColumnResize}
            onSelectedRowsChange={handleSelectOne}
            className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
            style={
              data.result.length
                ? {
                    height: ((data.result.length || 0) + 1) * 60,
                  }
                : undefined
            }
            rowHeight={60}
            selectedRows={new Set(selectedEntitiesIds)}
            rowKeyGetter={(row: EwpReviewItem) => row.id}
          />
        </DndProvider>
        <Pagination data={data} handleChangePageSize={handleChangePageSize} changePage={handleChangePage} />
      </div>
    );
  };

  return (
    <main className={'b-page'}>
      <div className="b-page__header -desktop">
        <HeadlineOrganization
          orgName={ewpDetails?.organization_name || ''}
          orgId={ewpDetails?.organization_id || ''}
          headline={t('common:headlines.equity-work-plan')}
          customClasses={'-noDesktopMb'}
          backBtn={<BackTitleButton />}
        />
      </div>
      <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
      <EwpTabs activeItemAlias={'review-items'} />
      <div className="b-page__controls">
        <div className="b-tableToolContainer">
          {filterOptions ? (
            <FilterModalButton
              query={query}
              handleSelectFilter={handleSelectFilter}
              handleApplyFilter={handleApplyFilter}
              filterData={filterData}
              isFiltersChanged={isFiltersChanged}
              filters={filterOptions.review_items}
            />
          ) : null}
          <TableToolSelect
            disabled={!selectedEntitiesIds.length}
            buttonClassName={' b-tableTool -mr -actions -colorGrey'}
            buttonTitle={t('common:label.actions')}
            options={elementActionOptions}
          />
        </div>
        <div className="b-page__search">
          <form action="#" className="searchForm">
            <input
              type="search"
              value={query.search}
              onChange={e => handleSearch(e.target.value)}
              placeholder={t('common:label.search')}
              aria-label="Search field"
            />
            <span className="searchForm__icon" />
          </form>
        </div>
      </div>
      {renderContent()}
    </main>
  );
};

export default EwpReviewItems;
