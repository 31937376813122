import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert, Modal } from '../../../common';
import { Button, Loader } from '../../../controls';
import styles from './UserProfile.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  createUserOrgAffiliation,
  getAvailableOrgs,
  getUserDetails,
  updateUserOrgAffiliation,
  usersInvite,
  usersRemove,
  userSwitchStatus,
} from '../../../../store/organization/organizationActions';
import {
  CreateOrgModel,
  OrganizationDetailsModel,
  OrganizationProfileModel,
  UserModel,
  ISelectOptions,
} from '../../../../models';
import { StateType } from '../../../../store/reducers';
import AddAffiliation from '../AddAffiliation/AddAffiliation';
import { toast } from 'react-toastify';
import { Modals, ModalsModel } from '../../../../constants';
import { useRole } from '../../../../helpers/roles';
import { convertUserStatus } from '../../../../helpers';
import { useTranslation } from 'react-i18next';

interface IState {
  user: UserModel | null;
  organizations: OrganizationProfileModel[];
  modals: ModalsModel;
  editAffiliationModal: OrganizationProfileModel | null;
}

const UserProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentOrg: OrganizationDetailsModel | null = useSelector((state: StateType) => {
    return state.organization.currentOrg;
  });

  const initialState: IState = {
    user: null,
    organizations: [],
    modals: {
      isDeactivateUser: false,
      isDeleteAffiliation: false,
      isAddAffiliation: false,
      isDeleteUser: false,
      isResendEmail: false,
      isActivateUser: false,
    },
    editAffiliationModal: null,
  };

  const [state, setState] = useState<IState>(initialState);
  const [availableOrgs, setAvailableOrgs] = useState<ISelectOptions[]>([]);
  const { user, organizations, modals, editAffiliationModal } = state;
  const access = useRole();
  const isAccess = access(['project_admin', 'project_manager']);

  useEffect(() => {
    getUser();
    getAvailableOrgsList();
  }, []);

  const getAvailableOrgsList = () => {
    const url = location.pathname.split('/');
    const organization_id = url[url.findIndex((f: string) => f === 'org') + 1];
    const id = url[url.findIndex((f: string) => f === 'user-profile') + 1];
    dispatch(
      getAvailableOrgs({
        data: {
          organization_id,
          id,
        },
        callback: getAvailableOrgsSuccess,
      }),
    );
  };

  const getUserSuccess = (res: any) => {
    setState(prevState => {
      return {
        ...prevState,
        user: res.user,
        organizations: res.organizations,
        modals: {
          isDeactivateUser: false,
          isDeleteAffiliation: false,
          isAddAffiliation: false,
          isDeleteUser: false,
          isResendEmail: false,
          isActivateUser: false,
        },
      };
    });
  };

  const getAvailableOrgsSuccess = (res: ISelectOptions[]) => {
    setAvailableOrgs(res);
  };

  const getUser = () => {
    const url = location.pathname.split('/');
    const organization_id = url[url.findIndex((f: string) => f === 'org') + 1];
    const id = url[url.findIndex((f: string) => f === 'user-profile') + 1];
    dispatch(
      getUserDetails({
        data: {
          organization_id,
          id,
        },
        callback: (res, error) => {
          if (error) {
            history.go(-1);
          }
          if (res) {
            getUserSuccess(res);
          }
        },
      }),
    );
  };

  const openModal = (modalName: string) => {
    setState({
      ...state,
      modals: {
        ...state.modals,
        [modalName]: true,
      },
    });
  };

  const closeModal = (modalName: string) => {
    setState({
      ...state,
      modals: {
        ...state.modals,
        [modalName]: false,
      },
    });
  };

  const handleDeactivateUser = () => {
    const url = location.pathname.split('/');
    const id = url[url.findIndex((f: string) => f === 'user-profile') + 1];

    dispatch(
      userSwitchStatus({
        organization_id: String(currentOrg?.id),
        data: {
          team_ids: [+id || 0],
          is_active: false,
        },
        callback: (res, err) => {
          if (err) {
            toast.error(t('common:toast.error'));
            return;
          }
          getUser();
        },
      }),
    );
  };

  const handleActivateUser = () => {
    const url = location.pathname.split('/');
    const id = url[url.findIndex((f: string) => f === 'user-profile') + 1];

    dispatch(
      userSwitchStatus({
        organization_id: String(currentOrg?.id),
        data: {
          team_ids: [+id || 0],
          is_active: true,
        },
        callback: (res, err) => {
          if (err) {
            toast.error(t('common:toast.error'));
            return;
          }
          toast.success(res.msg);
          getUser();
        },
      }),
    );
  };

  const handleDeleteUser = () => {
    const url = location.pathname.split('/');
    const id = url[url.findIndex((f: string) => f === 'user-profile') + 1];

    dispatch(
      usersRemove({
        organization_id: String(currentOrg?.id),
        data: {
          team_ids: [+id || 0],
        },
        callback: (res, err) => {
          if (err) {
            toast.error(t('common:toast.error'));
            return;
          }
          toast.success(res.msg);
          history.go(-1);
        },
      }),
    );
  };

  const handleRemoveAffiliation = () => {
    dispatch(
      createUserOrgAffiliation({
        organization_id: String(currentOrg?.id),
        data: {
          email: user?.email || '',
          action: 'remove',
          orgs: [{ org_id: Number(modals.isDeleteAffiliation) }],
        },
        callback: (result, err) => {
          if (err) {
            toast.error(t('common:toast.error'));
            return;
          }
          toast.success(t('common:toast.success'));
          getUser();
        },
      }),
    );
  };

  const handleOpenRemoveAffiliation = (org: OrganizationProfileModel) => {
    setState({
      ...state,
      modals: {
        ...state.modals,
        isDeleteAffiliation: org.organization_id,
      },
    });
  };

  const handleOpenSendInvite = (org: OrganizationProfileModel) => {
    setState({
      ...state,
      modals: {
        ...state.modals,
        isResendEmail: org.id,
      },
    });
  };

  const handleResendEmail = () => {
    dispatch(
      usersInvite({
        organization_id: String(currentOrg?.id),
        data: {
          team_ids: [+(modals.isResendEmail || 0) || 0],
        },
        callback: (result, err) => {
          if (err) {
            toast.error(t('common:toast.error'));
            return;
          }
          toast.success(result.msg);
          getUser();
        },
      }),
    );
  };

  const saveAffiliation = (formData: CreateOrgModel) => {
    if (editAffiliationModal) {
      dispatch(
        updateUserOrgAffiliation({
          organization_id: String(currentOrg?.id),
          affiliation_id: String(editAffiliationModal.id),
          data: {
            user_type_id: Number(formData.type_id),
            user_role_id: Number(formData.role_id),
          },
          callback: (result, err) => {
            if (err) {
              toast.error(t('common:toast.error'));
              return;
            }
            getAvailableOrgsList();
            getUser();
            closeEditAffiliationModal();
          },
        }),
      );
      return;
    }
    dispatch(
      createUserOrgAffiliation({
        organization_id: String(currentOrg?.id),
        data: {
          email: user?.email || '',
          action: 'add',
          first_name: user?.first_name,
          last_name: user?.last_name,
          orgs: [formData],
        },
        callback: (result, err) => {
          if (err) {
            toast.error(t('common:toast.error'));
            return;
          }
          getAvailableOrgsList();
          getUser();
        },
      }),
    );
  };

  const goBack = (e: any) => {
    e.stopPropagation();
    history.go(-1);
  };

  const handleChangeRole = (org: OrganizationProfileModel) => {
    setState({
      ...state,
      editAffiliationModal: org,
    });
  };

  const closeEditAffiliationModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        editAffiliationModal: null,
      };
    });
  };

  return (
    <>
      {user ? (
        <main className={`pageContainer__content ${styles.pageContainer}`}>
          <div className="ag-userProfile">
            <div className="ag-userProfile_heading">
              <div className="ag-userProfile_headingRow">
                <a className="ag-userProfile_backBtn" onClick={goBack} />
                <div className="ag-userProfile_userName">
                  {user?.first_name} {user?.last_name}
                </div>
                {isAccess && (
                  <ul className="ag-userProfile_tools">
                    <li>
                      {user.is_active && (
                        <button
                          type="button"
                          className="ag-icon ag-icon_person_off"
                          aria-label="Deactivate User"
                          onClick={() => openModal(Modals.isDeactivateUser)}
                        />
                      )}
                      {!user.is_active && (
                        <button
                          type="button"
                          className="ag-icon ag-icon_person_on"
                          aria-label="Activate User"
                          onClick={() => openModal(Modals.isActivateUser)}
                        />
                      )}
                    </li>
                    <li>
                      <button
                        type="button"
                        aria-label="Delete User"
                        className="ag-icon ag-icon_delete"
                        onClick={() => openModal(Modals.isDeleteUser)}
                      />
                    </li>
                  </ul>
                )}
              </div>

              <ul className="ag-userProfile_userInfo">
                <li>{user?.email}</li>
                {user?.division && <li>{user.division}</li>}
                <li>{convertUserStatus(user?.status || null, user.status_date)}</li>
              </ul>
            </div>

            <h1 className="ag-userProfile_title">{t('common:headlines.organization-&-affiliation')}</h1>

            <div className="ag-userlist">
              {(organizations.length &&
                organizations.map(org => {
                  return (
                    <div key={`org-${org.id}`} className="ag-userlist_item">
                      <h2 className="ag-userlist_title">{org.organization_name}</h2>
                      <div className={`ag-userlist_box`}>
                        {isAccess && (
                          <div className={`${styles.btn__affiliation}`}>
                            {!org.is_accepted && (
                              <button
                                type="button"
                                className="ag-icon ag-icon_send"
                                aria-label={'send-invite'}
                                onClick={() => handleOpenSendInvite(org)}
                              />
                            )}
                            <button
                              type="button"
                              aria-label="Edit"
                              className="ag-icon ag-icon_edit"
                              onClick={() => handleChangeRole(org)}
                            />
                            <button
                              type="button"
                              className="ag-icon ag-icon_delete"
                              aria-label="Delete"
                              onClick={() => handleOpenRemoveAffiliation(org)}
                            />
                          </div>
                        )}
                        <table className="ag-userlist_list">
                          <tbody>
                            <tr>
                              <th>{t('common:column.org-affiliation')}:</th>
                              <td>{org.org_affiliation}</td>
                            </tr>
                            <tr>
                              <th>{t('common:column.joined-on')}:</th>
                              <td>{org.joined_on}</td>
                            </tr>
                            <tr>
                              <th>{t('common:column.user-type')}:</th>
                              <td>{org.type_name}</td>
                            </tr>
                            <tr>
                              <th>{t('common:column.role')}:</th>
                              <td>{org.role_name}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                })) ||
                ''}
            </div>
            <div className={`${styles.form__part} ${styles.form__affiliation_btn}`}>
              <div className={styles.form__addLink} onClick={() => openModal(Modals.isAddAffiliation)}>
                {t('common:btn.add-affiliation')}
              </div>
            </div>
            <div className="ag-userProfile_buttons">
              <Button
                onPress={() => history.go(-1)}
                title={t('common:btn.cancel')}
                size={'small'}
                type={'transparency'}
              />
              <Button
                onPress={() => history.go(-1)}
                title={t('common:btn.save')}
                size={'small'}
                type={'orange-light'}
                enableEnter
              />
            </div>
          </div>
          {modals.isDeactivateUser ? (
            <Alert
              isOpen={modals.isDeactivateUser}
              onRequestClose={() => closeModal(Modals.isDeactivateUser)}
              buttons={{
                left: {
                  type: 'white',
                  onClick: () => closeModal(Modals.isDeactivateUser),
                  title: t('common:btn.save'),
                },
                right: {
                  type: 'orange-light',
                  onClick: handleDeactivateUser,
                  title: t('common:btn.deactivate'),
                },
              }}
              title={t('awa:N7.title')}
              text={t('awa:N7.msg')}
              contentStyles={{
                width: '350px',
                transform: 'translate(-50%, -80%)',
              }}
            />
          ) : null}
          {modals.isActivateUser ? (
            <Alert
              isOpen={modals.isActivateUser}
              onRequestClose={() => closeModal(Modals.isActivateUser)}
              buttons={{
                left: {
                  type: 'white',
                  onClick: () => closeModal(Modals.isActivateUser),
                  title: t('common:btn.cancel'),
                },
                right: {
                  type: 'orange-light',
                  onClick: handleActivateUser,
                  title: t('common:btn.activate'),
                },
              }}
              title={t('awa:N11.title')}
              text={t('awa:N11.msg')}
              contentStyles={{
                width: '350px',
                transform: 'translate(-50%, -80%)',
              }}
            />
          ) : null}
          {modals.isDeleteUser ? (
            <Alert
              isOpen={modals.isDeleteUser}
              onRequestClose={() => closeModal(Modals.isDeleteUser)}
              buttons={{
                left: {
                  type: 'white',
                  onClick: () => closeModal(Modals.isDeleteUser),
                  title: t('common:btn.cancel'),
                },
                right: {
                  type: 'orange-light',
                  onClick: handleDeleteUser,
                  title: t('common:btn.delete'),
                },
              }}
              title={t('main:org-users.delete-user.title')}
              text={t('main:org-users.delete-user.msg')}
              contentStyles={{
                width: '350px',
                transform: 'translate(-50%, -80%)',
              }}
            />
          ) : null}
          {modals.isDeleteAffiliation ? (
            <Alert
              isOpen={!!modals.isDeleteAffiliation}
              onRequestClose={() => closeModal(Modals.isDeleteAffiliation)}
              buttons={{
                left: {
                  type: 'white',
                  onClick: () => closeModal(Modals.isDeleteAffiliation),
                  title: t('common:btn.cancel'),
                },
                right: {
                  type: 'orange-light',
                  onClick: handleRemoveAffiliation,
                  title: t('common:btn.delete'),
                },
              }}
              title={t('awa:N6.msg')}
              text={` `}
              contentStyles={{
                width: '350px',
                transform: 'translate(-50%, -80%)',
              }}
            />
          ) : null}
          {modals.isResendEmail ? (
            <Alert
              isOpen={!!modals.isResendEmail}
              onRequestClose={() => closeModal(Modals.isResendEmail)}
              buttons={{
                left: {
                  type: 'white',
                  onClick: () => closeModal(Modals.isResendEmail),
                  title: t('common:btn.cancel'),
                },
                right: {
                  type: 'orange-light',
                  onClick: handleResendEmail,
                  title: 'Send',
                },
              }}
              title={t('main:org-users.new-invite')}
              text={` `}
              contentStyles={{
                width: '350px',
                transform: 'translate(-50%, -80%)',
              }}
            />
          ) : null}
          {editAffiliationModal ? (
            <Modal
              width={545}
              onClose={closeEditAffiliationModal}
              className={styles.modal}
              title={t('common:headlines.edit-affiliation')}
            >
              <AddAffiliation
                editAffiliationForm={editAffiliationModal}
                handleClosePopup={closeEditAffiliationModal}
                saveAffiliation={saveAffiliation}
                availableOrgs={availableOrgs}
              />
            </Modal>
          ) : null}
          {modals.isAddAffiliation ? (
            <Modal
              width={545}
              onClose={() => closeModal(Modals.isAddAffiliation)}
              className={styles.modal}
              title={t('common:headlines.add-affiliation')}
            >
              <AddAffiliation
                handleClosePopup={() => closeModal(Modals.isAddAffiliation)}
                saveAffiliation={saveAffiliation}
                availableOrgs={availableOrgs}
              />
            </Modal>
          ) : null}
        </main>
      ) : (
        <Loader fitParent />
      )}
    </>
  );
};

export default UserProfile;
