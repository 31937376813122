import { call, all, take, StrictEffect, put, throttle } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  BEL_GET_DEBRIEFS_LIST,
  BelGetDebriefsListPayload,
  belGetDebriefsListSuccess,
  GET_BELOVED_USERS,
  GetBelovedUsersPayload,
  SET_DEBRIEF_LEADER,
  SetDebriefLeaderPayload,
} from './debriefsActions';
import Api from '../../../services/api';
import { ActionModel } from '../../../models';
import { t } from 'i18next';

function* belGetDebriefsListSaga({
  payload: { callback, params },
}: ActionModel<typeof BEL_GET_DEBRIEFS_LIST, BelGetDebriefsListPayload>): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(Api.get, `beloved/debrief/`, params);

    if (response) {
      yield put(belGetDebriefsListSuccess(response));
      callback && callback();
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* getBelovedUsersSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback },
    }: { payload: GetBelovedUsersPayload } = yield take(GET_BELOVED_USERS);

    try {
      const response = yield call(Api.get, `beloved/debrief/get_beloved_team/`);

      if (response) {
        callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* setDebriefLeaderSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, debrief_id, user_id },
    }: { payload: SetDebriefLeaderPayload } = yield take(SET_DEBRIEF_LEADER);

    try {
      const response = yield call(Api.post, `beloved/debrief/choice_leader_debrief/`, {
        id_lead: user_id,
        id_debrief: debrief_id,
      });

      if (response) {
        toast.success(response.msg);
        callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

// setDebriefLeader

export function* saga() {
  yield all([
    throttle(400, BEL_GET_DEBRIEFS_LIST, belGetDebriefsListSaga),
    getBelovedUsersSaga(),
    setDebriefLeaderSaga(),
  ]);
}
