import React, { FC } from 'react';
import { SimpleTooltip } from '../../../../controls';
import { BelResourceShortModel } from '../../../../../models';
import { useTranslation } from 'react-i18next';
import { useAddToFav } from '../../../../../hooks/resources/use-add-to-fav';
import ResourceShare from './ResourceShare';

interface IProps {
  data: BelResourceShortModel;
}

const ResourceTools: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();
  const { is_favorite, addToFav } = useAddToFav(data);
  const isBeloved = location.pathname.match('beloved');

  return (
    <ul className="b-resourceCard__tools">
      {isBeloved ? (
        <ResourceShare
          type={'card'}
          data={[data]}
          selectedEntitiesIds={[data.id]}
          customHandleButton={<span className="b-resourceCard__share" aria-label="Share" />}
        />
      ) : null}
      <SimpleTooltip
        id={`add-to-fav-${data.id}`}
        title={is_favorite ? t('common:tooltip.click-remove-fav') : t('common:tooltip.click-add-fav')}
      >
        <span
          onClick={() => addToFav(data.id)}
          className={`b-resourceCard__bookmark ${is_favorite ? 'b-resourceCard__bookmark--active' : ''}`}
          aria-label="Bookmark"
        />
      </SimpleTooltip>
    </ul>
  );
};

export default ResourceTools;
