import React from 'react';
import AccountCreatedView from './AccountCreatedView';

interface IProps {
  history: any;
}

const AccountCreatedContainer = ({ history }: IProps) => {
  const handleNavigate = () => {
    history.push('/sign-in');
  };
  return <AccountCreatedView onNavigate={handleNavigate} />;
};

export default AccountCreatedContainer;
