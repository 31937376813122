import React, { FC } from 'react';
import { CreditModel } from '../../../models';
import { CreditStatuses } from '../../../constants/credit';
import { useTranslation } from 'react-i18next';

interface IProps {
  credit: CreditModel;
}

const CreditStatusLabel: FC<IProps> = ({ credit }) => {
  const { t } = useTranslation();
  switch (credit.status) {
    case CreditStatuses.AVAILABLE:
      return <div className="b-status -completed">{t('common:label.available')}</div>;
    case CreditStatuses.REIMBURSED:
      return <div className="b-status -inProgress">{t('common:label.reimbursed')}</div>;
    case CreditStatuses.REVOKED:
      return <div className="b-status -prepCompl">{t('common:label.revoked')}</div>;
    case CreditStatuses.USED:
      return <div className="b-status -used">{t('common:label.used')}</div>;
    default:
      return null;
  }
};

export default CreditStatusLabel;
