import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { BelovedUserCard } from '../../../common';
import { Loader } from '../../../controls';
import { useInfiniteList } from '../../../../hooks/common/use-infinite-list';
import { BelovedUserShortModel } from '../../../../models';

const BelovedUserList = () => {
  const { t } = useTranslation();
  const { fetchMoreData, fetchData, data } = useInfiniteList<BelovedUserShortModel>('beloved_team/');

  useEffect(() => {
    fetchData();
  }, []);

  if (!data) {
    return <Loader />;
  }

  return (
    <main className="b-page">
      <h1 className="b-page__title mb-5">{t('common:tabs.beloved-community-team')}</h1>
      <div className="b-infScrollWrapper">
        <InfiniteScroll
          dataLength={data.result.length}
          next={fetchMoreData}
          hasMore={!(data.current_page === data.total_pages)}
          loader={<h4 className="b-no-content">{t('common:label.loading')}...</h4>}
        >
          <div className="b-infScrollWrapper__normalize">
            <div className="b-belovedUser__list">
              {data.result.map(item => {
                return <BelovedUserCard key={`user-card-${item.id}`} user={item} />;
              })}
            </div>
          </div>
        </InfiniteScroll>
      </div>
    </main>
  );
};

export default BelovedUserList;
