import { ISelectOptions } from '../models';
import i18n from 'i18next';

export enum CreditStatuses {
  AVAILABLE = 1,
  REIMBURSED = 2,
  REVOKED = 3,
  USED = 4,
}

export enum CreditActions {
  REIMBURSE = 'reimburse',
  REVOKE = 'revoke',
  RE_ISSUE = 're-issue',
}

export const getCreditStatusList = (): ISelectOptions[] => [
  {
    label: i18n.t('common:status.available'),
    value: CreditStatuses.AVAILABLE,
  },
  {
    label: i18n.t('common:status.reimbursed'),
    value: CreditStatuses.REIMBURSED,
  },
  {
    label: i18n.t('common:status.revoked'),
    value: CreditStatuses.REVOKED,
  },
  {
    label: i18n.t('common:status.used'),
    value: CreditStatuses.USED,
  },
];
