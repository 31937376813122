import { ISelectOptions, KeyValueModel } from '../models';
import { t } from 'i18next';
import { getDefaultValidatorNames } from '../constants';

export interface Validator {
  required?: Array<string>;
  custom?: any;
  requiredFieldName?: KeyValueModel<string>;
}

export const validate = <T = KeyValueModel<string>>(validator: Validator, fields: T) => {
  const DefaultValidatorNames = getDefaultValidatorNames();
  if (validator) {
    let errors: KeyValueModel<string> = {};
    let unfilled =
      validator &&
      validator.required &&
      validator.required.filter(
        //@ts-ignore
        (field: string) => !fields[field] || (Array.isArray(fields[field]) && !fields[field].length),
      );
    if (validator.custom) {
      const result = validator.custom.map(({ error, validate, fields: validationFields }: any) => {
        //@ts-ignore
        const valid = validate(...validationFields.map((field: string) => fields[field]));
        const errors: KeyValueModel<string> = {};
        validationFields.forEach((item: string) => {
          errors[item] = error;
        });
        return valid ? null : errors;
      });
      const customErrors = result.filter(Boolean);
      if (customErrors.length) {
        customErrors.forEach((error: string) => {
          errors = Object.assign(errors, error);
        });
      }
    }

    if (unfilled && unfilled.length) {
      unfilled.forEach((field: string) => {
        errors[field] = `${
          validator.requiredFieldName?.[field] ||
          //@ts-ignore
          DefaultValidatorNames[field] ||
          field.charAt(0).toUpperCase() + field.slice(1)
        } required`;
      });
    }
    return { errors };
  }
  return { errors: null };
};

export const email = (fields: Array<string>) => ({
  error: t('common:errors.incorrect-email-format'),
  validate: validateEmail,
  fields,
});

export const phone = (fields: Array<string>) => ({
  error: t('common:errors.invalid-phone-format'),
  validate: validatePhone,
  fields,
});

export const ssn = (fields: Array<string>) => ({
  error: 'SSN format must be XXX-XX-XXXX',
  validate: validateSSN,
  fields,
});

export const samePasswords = (fields: Array<string>) => ({
  error: t('common:errors.passwords-same'),
  validate: (password: string, repeat: string) => password === repeat,
  fields,
});

export const httpUrl = (fields: Array<string>) => {
  return {
    error: t('common:errors.invalid-URL'),
    validate: validateURL,
    fields,
  };
};

export const password = (fields: Array<string>) => ({
  error: t('common:errors.passwords-8-characters'),
  validate: validatePassword,
  fields,
});

export const activityTime = (start: Date | null, end: Date | null, fields: Array<string>) => ({
  error: t('common:errors.start-time-required'),
  validate: () => validateActivityTime(start, end),
  fields,
});

export const atLeastOneOption = (fields: Array<string>) => ({
  error: 'Select at least one option',
  validate: (options: ISelectOptions[]) => !!options.length,
  fields,
});

const validatePassword = (password: string) => {
  const re = /^.*.{8,20}$/;
  return re.test(password);
};

export const validateURL = (url: string) => {
  if (!url) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const re =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
  return re.test(url);
};

export const validateEmail = (email: string) => {
  /* eslint-disable no-useless-escape */
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const validatePhone = (phone: string) => {
  /* eslint-disable no-useless-escape */
  const re = /^\+?1?\d{8,20}$/;
  return re.test(phone);
};

const validateSSN = (ssn: string) => {
  /* eslint-disable no-useless-escape */
  const re = /^\d{3}-\d{2}-\d{4}$/;
  return re.test(ssn);
};

export const checkEmptyField = (field: string) => {
  /* eslint-disable no-useless-escape */
  const re = /^\s*\d*\.?\d+\s*$/;
  return re.test(field);
};

const validateActivityTime = (start_time: Date | null, end_time: Date | null) => {
  return !(start_time === null && end_time !== null);
};
