import { SortDirection, useRowSelection } from 'react-data-grid';
import {
  BelResourceShortModel,
  ColumnType,
  IRouteParamsBase,
  TableDataEntities,
} from '../../../../../models';
import { basicDateFormat, cutString } from '../../../../../helpers';
import { Checkbox } from '../../../../controls';
import React from 'react';
import { ResourceStatuses } from '../../../../../constants';
import { t } from 'i18next';
import useHasAccess from '../../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../../constants/roles';
import { useHistory, useParams } from 'react-router';
import { ROOTS } from '../../../../../routes/routes';
import useTableData from '../../../../../hooks/common/use-table-data';
import { TableDateFilter } from '../../../../common';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

interface ColumnsHandlers {
  onCellClick?: (fieldName: string, data: BelResourceShortModel) => void;
}

export const getColumns = ({ onCellClick }: ColumnsHandlers): ColumnType<BelResourceShortModel>[] => [
  {
    key: 'title',
    name: t('common:column.title'),
    width: 270,
    sortable: true,
    cellClass: 'b-rdgTable__cellTextWrap',
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      return <>{cutString(props.row.title, 143)}</>;
    },
  },
  {
    key: 'ownership',
    name: t('common:label.publisher'),
    sortable: false,
    cellClass: 'b-rdgTable__cellTextWrap',
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      return <>{props.row.ownership.name}</>;
    },
  },
  {
    key: 'type',
    name: t('common:label.type'),
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      return <>{props.row.type.name}</>;
    },
  },
  {
    key: 'categories',
    name: t('common:label.categories'),
    sortable: false,
    cellClass: 'b-rdgTable__cellTextWrap',
    editable: false,
    resizable: true,
    formatter(props) {
      return <div>{cutString(props.row.categories.map(cat => cat.title).join(', '), 33)}</div>;
    },
  },
  {
    key: 'distribution',
    name: t('common:label.distribution'),
    sortable: false,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      return <div>{props.row.distribution.name}</div>;
    },
  },
  {
    key: 'status',
    name: t('common:label.status'),
    width: 110,
    sortable: false,
    editable: false,
    resizable: true,
    cellClass: 'b-rdgTable__cellTextWrap',
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      const renderStatus = (status: ResourceStatuses) => {
        switch (status) {
          case ResourceStatuses.ACCEPT:
            return (
              <span className={`b-tableStatusMark preparationCompleted`}>{t('common:status.accepted')}</span>
            );
          case ResourceStatuses.IN_REVIEW:
            return <span className={`b-tableStatusMark inProgress`}>{t('common:status.in-review')}</span>;
          case ResourceStatuses.PUBLISH:
            return (
              <div>
                <div className={`b-tableStatusMark completed`}>{t('common:status.published')}</div>
                <div>{props.row.published_date ? basicDateFormat(props.row.published_date) : null}</div>
              </div>
            );
          case ResourceStatuses.UNPUBLISH:
            return (
              <span className={`b-tableStatusMark preparationCompleted`}>
                {t('common:status.unpublished')}
              </span>
            );
          case ResourceStatuses.REJECT:
            return <span className={`b-tableStatusMark redest`}>{t('common:status.rejected')}</span>;
          case ResourceStatuses.SUBMIT:
            return <span className={`b-tableStatusMark inactive`}>{t('common:status.submitted')}</span>;
          default:
            return null;
        }
      };
      return renderStatus(props.row.status);
    },
  },
  {
    key: 'updated_at',
    name: t('common:column.last-update'),
    width: 110,
    sortable: false,
    editable: false,
    resizable: true,
    formatter(props) {
      return <>{props.row.updated_at ? basicDateFormat(props.row.updated_at) : t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      const { handleSelectDateFilter, query } = useTableData(TableDataEntities.RESOURCES);
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'updated_at'}
            selectedFilters={query.dateFilters['updated_at']}
            periodFieldName={'updated_at_period'}
            dateRangeFieldName={'updated_at_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
            fiscalOption={true}
          />
        );
      }
    },
  },
  {
    key: 'access_type',
    name: t('common:label.access'),
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      return <>{props.row.access_type.name}</>;
    },
  },
  {
    key: 'created_at',
    name: t('common:column.create-date'),
    width: 90,
    sortable: false,
    isShow: false,
    editable: false,
    resizable: true,
    formatter(props) {
      return (
        <>{props.row.created_at ? basicDateFormat(new Date(props.row.created_at)) : t('common:column.n-a')}</>
      );
    },
    headerRenderer(props) {
      const { handleSelectDateFilter, query } = useTableData(TableDataEntities.RESOURCES);
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'created_at'}
            selectedFilters={query.dateFilters['created_at']}
            periodFieldName={'created_at_period'}
            dateRangeFieldName={'created_at_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
            fiscalOption={true}
          />
        );
      }
    },
  },
  {
    key: 'published_date',
    name: t('common:label.publish-date'),
    width: 140,
    sortable: true,
    isShow: false,
    editable: false,
    resizable: true,
    formatter(props) {
      return (
        <>
          {props.row.published_date
            ? basicDateFormat(new Date(props.row.published_date))
            : t('common:column.n-a')}
        </>
      );
    },
    headerRenderer(props) {
      const { handleSelectDateFilter, query } = useTableData(TableDataEntities.RESOURCES);
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'published_date'}
            selectedFilters={query.dateFilters['published_date']}
            periodFieldName={'published_date_period'}
            dateRangeFieldName={'published_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
            fiscalOption={true}
          />
        );
      }
    },
  },
  {
    key: 'submitter_name',
    name: t('common:label.submitted-by'),
    width: 90,
    sortable: false,
    editable: false,
    isShow: false,
    resizable: true,
    formatter(props) {
      const history = useHistory();
      const { orgId } = useParams<IRouteParamsBase>();

      const handleClick = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (!props.row.submitter) return;
        history.push(
          ROOTS.BELOVED_USER_PROFILE.replace(':id', `${props.row.submitter.id}`).replace(
            ':orgId',
            `${orgId || 'general'}`,
          ),
        );
      };

      if (props.row.submitter && props.row.submitter.enabled_view) {
        return (
          <a href="#" className="b-link" onClick={handleClick}>
            {props.row.submitter.name}
          </a>
        );
      } else if (props.row.submitter) {
        return <>{props.row.submitter.name}</>;
      }
      return <>{t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'reviewer_name',
    name: t('common:column.reviewed-approved-by'),
    width: 90,
    sortable: false,
    isShow: false,
    editable: false,
    resizable: true,
    formatter(props) {
      const history = useHistory();

      const handleClick = (e: any) => {
        e.stopPropagation();
        const { orgId } = useParams<IRouteParamsBase>();

        if (!props.row.reviewer) return;
        history.push(
          ROOTS.BELOVED_USER_PROFILE.replace(':id', `${props.row.reviewer.id}`).replace(
            ':orgId',
            `${orgId || 'general'}`,
          ),
        );
      };

      if (props.row.reviewer && props.row.reviewer.enabled_view) {
        return (
          <a href="#" className="b-link" onClick={handleClick}>
            {props.row.reviewer.name}
          </a>
        );
      } else if (props.row.reviewer) {
        return <>{props.row.reviewer.name}</>;
      }
      return <>{t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'tools',
    name: '',
    width: 40,
    maxWidth: 40,
    disableDrag: true,
    formatter: props => {
      return (
        <div className="b-rdgTable__cellCenter" onClick={e => e.stopPropagation()}>
          <button
            onClick={onCellClick ? () => onCellClick('navToView', props.row) : () => null}
            className="b-button-icon -colorNavy -mh2 resource-view"
          />
        </div>
      );
    },
  },
  {
    key: 'select',
    name: '',
    width: 40,
    maxWidth: 40,
    disableDrag: true,
    headerRenderer(props) {
      return (
        <Checkbox
          checked={props.allRowsSelected}
          onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
        />
      );
    },
    formatter: props => {
      const [isRowSelected, onRowSelectionChange] = useRowSelection();

      return (
        <div className="b-rdgTable__cellCenter">
          <div className={'checkBox'} onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={isRowSelected}
              onChange={e => {
                onRowSelectionChange({
                  row: props.row,
                  checked: e.target.checked,
                  isShiftClick: false,
                });
              }}
            />
          </div>
        </div>
      );
    },
  },
];
