import React, { FC, ReactNode } from 'react';

interface Props {
  content: ReactNode;
}

const Hovered: FC<Props> = ({ children, content }) => {
  return (
    <div className="b-hovered">
      {children}
      <div className="b-hovered__body">
        <div className="b-hovered__content ">{content}</div>
      </div>
    </div>
  );
};

export default Hovered;
