import React, { FC, useState } from 'react';
import { PaginationModel, ISelectOptions, AsyncSelectResultModal } from '../../../../../models';
import { Modal } from '../../../../common';
import { AsyncPaginateSelect, Button, Select } from '../../../../controls';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { certificationConsultantAvailableOrgs } from '../../../../../store/cetrificationConsultant/certificationConsultantActions';
import Api from '../../../../../services/api';
import { toast } from 'react-toastify';

interface IProps {
  availableConsultants: ISelectOptions[];
  onClose: () => void;
  onSave: () => void;
}

const NewAssignmentModal: FC<IProps> = ({ onClose, onSave, availableConsultants }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [defaultOrganizationOptions, setDefaultOrganizationOptions] = useState<ISelectOptions[]>([]);
  const [tempSelect, setTempSelect] = useState<ISelectOptions[]>([]);
  const [selectedConsultant, setSelectedConsultant] = useState<ISelectOptions | null>(null);

  const handleGetAvailableOrgs = (value: ISelectOptions) => {
    dispatch(
      certificationConsultantAvailableOrgs({
        data: {
          organization_ids: [],
          consultant_id: Number(value.value),
        },
        callback: (res: PaginationModel<ISelectOptions[]>) => {
          setDefaultOrganizationOptions(res.result);
        },
      }),
    );
  };

  const handleChangeTempSelect = (value: ISelectOptions[]) => {
    setTempSelect(value);
  };

  async function loadOptions(
    search: string,
    loadedOptions: ISelectOptions[],
    additional?: { page: number },
  ): Promise<AsyncSelectResultModal | undefined> {
    if (!selectedConsultant?.value) {
      return;
    }
    const res: AsyncSelectResultModal | null = await new Promise(
      (
        resolve: (value: AsyncSelectResultModal | PromiseLike<AsyncSelectResultModal | null> | null) => void,
      ) => {
        dispatch(
          certificationConsultantAvailableOrgs({
            data: {
              organization_name: search,
              organization_ids: [],
              consultant_id: Number(selectedConsultant.value),
              page: additional?.page || 1,
            },
            callback: async (res: PaginationModel<ISelectOptions[]>) => {
              setDefaultOrganizationOptions(res.result);
              resolve({
                options: res.result,
                hasMore: res.current_page < res.total_pages,
                additional: {
                  page: res.current_page + 1,
                },
              });
            },
          }),
        );
      },
    );
    if (res) {
      return res;
    }
  }

  const handleChangeConsultant = (value: ISelectOptions) => {
    setTempSelect([]);
    handleGetAvailableOrgs(value);
    setSelectedConsultant(value);
  };

  const handleSave = async () => {
    const response = await Api.post('beloved/organization_beloved_certified_consultant/add_assignments/', {
      consultant_id: selectedConsultant?.value,
      organization_ids: tempSelect.map(t => Number(t.value)),
    });
    if (response) {
      toast.success(t('common:toast.new-assignment'));
      onSave();
    }
  };

  return (
    <Modal
      className="b-adminToolUsers__userModal"
      width={500}
      title={t('common:btn.new-assignment')}
      onClose={onClose}
    >
      <div className="b-modal">
        <div className="b-modal__content b-adminToolUsers__userForm">
          <div className="b-adminToolUsers__userFormRow">
            <div className="b-adminToolUsers__userFormInput">
              <Select
                handleChange={handleChangeConsultant}
                value={selectedConsultant}
                options={availableConsultants}
                placeholder={t('common:label.certified-consultant')}
                noOptionsMessage={t('main:certification-consultant.no-options')}
              />
            </div>
          </div>
          <div className="b-adminToolUsers__userFormRow">
            <div className="b-adminToolUsers__userFormInput">
              <AsyncPaginateSelect
                handleChange={(value: ISelectOptions[]) => handleChangeTempSelect(value)}
                value={tempSelect}
                defaultOptions={defaultOrganizationOptions}
                isMulti
                isSearchable
                loadOptions={loadOptions}
                placeholder={t('common:label.awa-organizations')}
                noOptionsMessage={t('awa:N70.msg')}
                ariaLabel="organizations"
                disabled={!selectedConsultant}
                cacheUniqs={[selectedConsultant]}
              />
            </div>
          </div>
          <div className="b-modal__buttons">
            <Button
              title={t('common:btn.cancel')}
              type="transparency"
              size="large"
              onPress={onClose}
              className="button-mh"
            />
            <Button title={t('common:btn.save')} size="large" onPress={handleSave} className="button-mh" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewAssignmentModal;
