import React, { FC } from 'react';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import { isMobileOnly } from 'react-device-detect';
import { Button } from '../../../../../controls';
import { FunctionGroup } from '../../../../../../models';
import { useTranslation } from 'react-i18next';

interface IProps {
  handleSaveAnswer: (answerId: string | null, isNext: boolean) => void;
  handleBack: () => void;
  skipGroup: () => void;
  currentGroupId: string;
  function_groups: FunctionGroup[];
}

const GroupDescription: FC<IProps> = props => {
  const { t } = useTranslation();
  const { handleBack, handleSaveAnswer, skipGroup, currentGroupId, function_groups } = props;

  const currentFunctionGroup = function_groups.find(g => g.id === currentGroupId);

  if (!currentFunctionGroup) return null;

  const handleNext = () => {
    handleSaveAnswer(null, true);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    handleSaveAnswer(null, false);
  };

  const handleSkip = (e: any) => {
    e.preventDefault();
    skipGroup();
  };

  const _isFirstGroup = () => {
    return 0 === function_groups.findIndex(g => g.id === currentGroupId);
  };

  return (
    <>
      {!isMobileOnly && (
        <a href="#" className="b-EACreate__saveBtn b-EATaking__saveExit" onClick={handleSave}>
          <img src={SaveIc} alt={t('common:alt.saveIc')} />
          {t('common:btn.save-exit')}
        </a>
      )}
      <div className="b-EATaking__box">
        <div className="b-EATaking__container">
          <p className="-w100">{currentFunctionGroup.description.text}</p>
          {currentFunctionGroup.description.list.length ? (
            <ul className="b-EATaking__list -w100">
              {currentFunctionGroup.description.list.map((text, index) => {
                return <li key={`description-text-${index}`}>{text}</li>;
              })}
            </ul>
          ) : null}
          {currentFunctionGroup.description.more_text ? (
            <p className="-w100">{currentFunctionGroup.description.more_text}</p>
          ) : null}
          {currentFunctionGroup.description.more_list?.length ? (
            <ul className="b-EATaking__list -w100">
              {currentFunctionGroup.description.more_list.map((text, index) => {
                return <li key={`description-text-${index}`}>{text}</li>;
              })}
            </ul>
          ) : null}
          <p>{t('main:taking-audit.gd.p1')}</p>
          <a href="#" onClick={handleSkip} className="b-EATaking__link">
            {t('main:taking-audit.gd.p2')}
          </a>
          {!isMobileOnly && (
            <div className="b-EATaking__buttons">
              <Button
                className={'btn -type_form'}
                type={'transparency'}
                onPress={handleBack}
                title={t('common:btn.back')}
                disabled={_isFirstGroup()}
              />
              <Button
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleNext}
                title={t('common:btn.next')}
                enableEnter
              />
            </div>
          )}
        </div>
      </div>

      {isMobileOnly && (
        <React.Fragment>
          <div className="b-EATaking__buttons">
            <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSave}>
              <img src={SaveIc} alt={t('common:alt.saveIc')} />
              {t('common:btn.save-exit')}
            </a>
            <Button
              className={'btn'}
              type={'orange-light'}
              onPress={handleNext}
              title={t('common:btn.next')}
              enableEnter
            />
          </div>

          <Button
            disabled={_isFirstGroup()}
            className={'btn full-width'}
            type={'transparency'}
            onPress={handleBack}
            title={t('common:btn.back')}
          />
        </React.Fragment>
      )}
    </>
  );
};

export default GroupDescription;
