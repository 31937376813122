import {
  CLEAR_REPORTS,
  GET_FG_CHART_SUCCESS,
  GET_FG_REPORT_SUCCESS,
  GET_GROUP_ANALYSIS_CHART_SUCCESS,
  GET_REPORTS_SUCCESS,
  GET_STANDARD_CHART_SUCCESS,
  GET_STANDARD_REPORT_SUCCESS,
  ReportActionsTypes,
} from './reportActions';
import {
  ChartReportModel,
  FGDetailedModel,
  FunctionGroupGroupsModel,
  GroupAnalysisModel,
  KeyValueNumberModel,
  ReportsModel,
  ScoreModel,
  StandardScoreModel,
} from '../../models';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';

interface ReportsStateModel {
  reports: ReportsModel<KeyValueNumberModel<ScoreModel>, KeyValueNumberModel<StandardScoreModel>> | null;
  scoreReports: KeyValueNumberModel<StandardScoreModel>;
  FGChart: ChartReportModel;
  standardChart: ChartReportModel;
  fg: FGDetailedModel<KeyValueNumberModel<FunctionGroupGroupsModel>>;
}

export type ReportState = {
  reportsData: KeyValueNumberModel<ReportsStateModel>;
  gaData: GroupAnalysisModel;
};

const initialState: ReportState = {
  reportsData: {},
  gaData: {} as GroupAnalysisModel,
};

const reportReducer = (state: ReportState = initialState, action: ReportActionsTypes): ReportState => {
  switch (action.type) {
    case GET_REPORTS_SUCCESS: {
      const reportsData = {
        ...state.reportsData,
        [action.payload.eaId]: {
          ...state.reportsData[action.payload.eaId],
          reports: action.payload,
        },
      };
      return {
        ...state,
        reportsData: reportsData,
      };
    }
    case GET_STANDARD_REPORT_SUCCESS: {
      const reportsData = {
        ...state.reportsData,
        [action.payload.eaId]: {
          ...(state.reportsData[action.payload.eaId] || {}),
          scoreReports: {
            ...(state.reportsData[action.payload.eaId]?.scoreReports || {}),
            [action.payload.id]: action.payload,
          },
        },
      };
      return {
        ...state,
        reportsData: reportsData,
      };
    }
    case GET_FG_CHART_SUCCESS: {
      const reportsData = {
        ...state.reportsData,
        [action.payload.eaId]: {
          ...state.reportsData[action.payload.eaId],
          FGChart: action.payload,
        },
      };
      return {
        ...state,
        reportsData: reportsData,
      };
    }
    case GET_STANDARD_CHART_SUCCESS: {
      const reportsData = {
        ...state.reportsData,
        [action.payload.eaId]: {
          ...state.reportsData[action.payload.eaId],
          standardChart: action.payload,
        },
      };
      return {
        ...state,
        reportsData: reportsData,
      };
    }
    case GET_FG_REPORT_SUCCESS: {
      const reportsData = {
        ...state.reportsData,
        [action.payload.eaId]: {
          ...state.reportsData[action.payload.eaId],
          fg: action.payload,
        },
      };
      return {
        ...state,
        reportsData: reportsData,
      };
    }
    case CLEAR_REPORTS: {
      return {
        ...initialState,
      };
    }
    case LOGOUT_SUCCESS:
      return { ...initialState };
    case GET_GROUP_ANALYSIS_CHART_SUCCESS: {
      return {
        ...state,
        gaData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reportReducer;
