import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Calendar } from '../../../common';
import { useTranslation } from 'react-i18next';
import { ScheduleAlias } from '../../../../constants';

const DebriefScheduling = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const goBack = () => {
    history.go(-1);
  };

  return (
    <>
      <main className={'pageContainer pageContainer__content'}>
        <div className="b-calendar position-relative">
          <div className="b-calendar__header">
            <h1 className="b-calendar__pageSubTitle mb-4">
              <a className="b-calendar__backBtn" onClick={goBack}>
                <div className="back-icon" />
              </a>
              <span>{t('common:headlines.debrief-scheduling')}</span>
            </h1>
          </div>
          <Calendar />
        </div>
      </main>
    </>
  );
};

export default DebriefScheduling;
