import { useDispatch, useSelector } from 'react-redux';
import { SortColumn } from 'react-data-grid';
import React, { useEffect, useState } from 'react';
import { StateType } from '../../store/reducers';
import {
  TableItemModel,
  PaginationModel,
  ReqParamsModel,
  QueryParamsModel,
  DataTableFilterModel,
  DataTableDateFilterModel,
  UseTableDataConfig,
} from '../../models';
import { isEmptyObject, onlyUnique } from '../../helpers';
import Api from '../../services/api';
import { setTableItem, SetTableItemPayload } from '../../store/table/tableReducer';

let timerId: NodeJS.Timeout;

export function useTableDataV2<T extends { id: number }>({
  baseUrl,
  entityName,
  enableSelectedEntities,
  extraData: initExtraData,
}: UseTableDataConfig) {
  const dispatch = useDispatch();
  const tableItem: TableItemModel | undefined = useSelector((state: StateType) => state.tables[entityName]);
  const data: PaginationModel<T[]> | null = tableItem ? tableItem.list : null;
  const query: QueryParamsModel = tableItem
    ? tableItem.query
    : {
        filters: {},
        sorting: null,
        search: '',
        page_size: 15,
        page: 1,
        dateFilters: {},
      };

  let [selectedEntities, setSelectedEntities] = useState<any[]>([]);
  let [selectedEntitiesIds, setSelectedEntitiesIds] = useState<number[]>([]);
  let [sortingData, setSortingData] = useState<QueryParamsModel['sorting']>(query.sorting);
  let [filterData, setFilterData] = useState<DataTableFilterModel>(query.filters);
  let [isFiltersChanged, setIsFiltersChanged] = useState(false);
  let [infiniteList, setInfiniteList] = useState<T[]>([]);
  let [extraData, setExtraData] = useState<any | undefined>(initExtraData);

  useEffect(() => {
    if (data) {
      setInfiniteList(prevState => {
        if (data) {
          return data.current_page === 1 ? data.result : [...prevState, ...data.result];
        }
        return prevState;
      });
    }
  }, [data?.result]);

  const fetchData = (query: QueryParamsModel, noSetQuery?: boolean) => {
    let params: ReqParamsModel = {
      page: query.page,
      page_size: query.page_size,
    };
    if (query.search) {
      params.search = query.search;
    }
    if (query.sorting) {
      params.ordering =
        query.sorting.direction === 'DESC' ? query.sorting.columnKey : `-${query.sorting.columnKey}`;
    }
    if (query.filters) {
      Object.keys(query.filters).forEach(key => {
        params[key] = query.filters[key].join(',');
      });
    }
    if (extraData) {
      params = {
        ...params,
        ...extraData,
      };
    }
    if (!isEmptyObject(query.dateFilters)) {
      Object.keys(query.dateFilters).forEach(key => {
        Object.keys(query.dateFilters[key]).forEach(childKey => {
          const arrParams = query.dateFilters[key][childKey];
          if (arrParams.length) {
            params[childKey] = arrParams.join(',');
          }
        });
      });
    }
    Api.get(baseUrl, params).then(response => {
      const tableItem: SetTableItemPayload['tableItem'] = {
        list: response,
      };
      if (!noSetQuery) {
        tableItem.query = query;
      }
      dispatch(
        setTableItem({
          id: entityName,
          tableItem,
        }),
      );
    });
  };

  const handleChangePage = (page: number) => {
    fetchData({ ...query, page });
  };

  const handleChangePageSize = (page_size: number) => {
    fetchData({ ...query, page_size, page: 1 });
  };

  const handleSearch = (text: string) => {
    const newQuery: QueryParamsModel = { ...query, search: text, page: 1 };
    dispatch(
      setTableItem({
        id: entityName,
        tableItem: {
          query: newQuery,
        },
      }),
    );
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fetchData(newQuery, true);
    }, 400);
  };

  const handleSelectOne = (selectedRows: Set<number>) => {
    // @ts-ignore
    setSelectedEntitiesIds([...selectedRows]);
    if (enableSelectedEntities) {
      const filtered = [...(data?.result || []), ...selectedEntities].filter((f: any) =>
        selectedRows.has(f.id),
      );
      setSelectedEntities(filtered.filter(onlyUnique));
    }
  };

  const handleChangeDirection = () => {
    const queryParams = { ...query };
    if (!queryParams.sorting) {
      queryParams.sorting = {
        direction: 'ASC',
        columnKey: 'updated_at',
      };
    }
    const sortData: SortColumn = {
      ...queryParams.sorting,
      direction: queryParams.sorting.direction === 'ASC' ? 'DESC' : 'ASC',
    };
    handleSort(sortData);
  };

  const handleSort = (sortData: SortColumn) => {
    const newQuery: QueryParamsModel = {
      ...query,
      page: 1,
      sorting: sortData,
    };
    setSortingData(sortData);
    fetchData(newQuery);
  };

  const handleChangeSortField = (field: string) => {
    const newSorting: QueryParamsModel['sorting'] =
      query.sorting?.columnKey === field ? null : { columnKey: field, direction: 'ASC' };
    setSortingData(newSorting);
  };

  const fetchMoreMobile = () => {
    fetchData({ ...query, page: query.page + 1 });
  };

  const handleSelectOneMob = (e: React.ChangeEvent<HTMLInputElement>, entityId: number) => {
    e.stopPropagation();
    const ids = [...selectedEntitiesIds];
    if (e.target.checked) {
      ids.push(entityId);
    } else {
      const findUserIndex = selectedEntitiesIds.findIndex(f => f === entityId);
      ids.splice(findUserIndex, 1);
    }
    setSelectedEntitiesIds(ids);
  };

  const handleSelectAll = () => {
    const selected: number[] = [];
    if (!data) return null;
    if (selectedEntitiesIds.length !== data.result.length) {
      data.result.map(item => {
        selected.push(item.id);
      });
    }
    setSelectedEntitiesIds(selected);
  };

  const handleApplyFilter = () => {
    const newQuery: QueryParamsModel = {
      ...query,
      filters: filterData,
      page: 1,
    };
    fetchData(newQuery);
  };

  const handleClearFilters = () => {
    const newQuery: QueryParamsModel = {
      ...query,
      filters: {},
      page: 1,
    };
    fetchData(newQuery);
  };

  const handleSelectFilter = (val: number | string, fieldName: string) => {
    let res = filterData[fieldName] ? [...filterData[fieldName]] : [];
    if (res.includes(val)) {
      res = res.filter(id => id !== val);
    } else {
      res.push(val);
    }

    if (!res.length) {
      const newData = { ...filterData };
      delete newData[fieldName];
      setFilterData(newData);
    } else {
      setFilterData({ ...filterData, [fieldName]: res });
    }

    setIsFiltersChanged(true);
  };

  const handleSelectDateFilter = (newFilters: DataTableDateFilterModel) => {
    const newQuery: QueryParamsModel = {
      ...query,
      dateFilters: {
        ...query.dateFilters,
        ...newFilters,
      },
      page: 1,
    };
    fetchData(newQuery);
  };

  return {
    data,
    query,
    fetchData,
    handleChangePage,
    handleChangePageSize,
    selectedEntitiesIds,
    setSelectedEntitiesIds,
    handleSearch,
    handleSelectOne,
    handleSort,
    sortingData,
    handleClearFilters,
    handleChangeDirection,
    handleChangeSortField,
    fetchMoreMobile,
    handleSelectOneMob,
    handleSelectAll,
    handleApplyFilter,
    handleSelectDateFilter,
    handleSelectFilter,
    isFiltersChanged,
    filterData,
    infiniteList,
    setSortingData,
    setFilterData,
    selectedEntities,
    setSelectedEntities,
    setExtraData,
  };
}
