import { actionCreator } from '../actionCreator';
import { ActionModel } from '../../models';

interface TaskModelRequest {
  id: number;
  user_role?: number;
  user_type?: number;
}

interface TaskModel {
  tasks: TaskModelRequest[];
  status?: 'accept' | 'decline';
}

export const GET_TASKS = 'GET_TASKS';
export interface GetTasksPayload {
  data: any;
  callback: (res: any, error?: any) => void;
}

export const getTasks = actionCreator<GetTasksPayload>(GET_TASKS);

export const UPDATE_TASK = 'UPDATE_TASK';
export interface UpdateTaskPayload {
  data: TaskModel;
  callback: (res: any, error?: any) => void;
}

export const updateTask = actionCreator<UpdateTaskPayload>(UPDATE_TASK);

export const DELETE_TASK = 'DELETE_TASK';

interface DeleteTaskModel {
  ids: number[];
}

export interface DeleteTaskPayload {
  data: DeleteTaskModel;
  callback: (res: any, error?: any) => void;
}

export const deleteTask = actionCreator<DeleteTaskPayload>(DELETE_TASK);

export type TaskActionsTypes<T = never> =
  | ActionModel<typeof UPDATE_TASK, UpdateTaskPayload>
  | ActionModel<typeof DELETE_TASK, DeleteTaskPayload>
  | ActionModel<typeof GET_TASKS, GetTasksPayload>
  | T;
