import React, { useState } from 'react';
import { Select } from '../../controls';
import { ApiAddressModel, ISelectOptions } from '../../../models';
import Autocomplete from 'react-google-autocomplete';
import styles from './AddressAutocompleteStyle.module.scss';
import Input from '../../controls/Inputs/Input';
import { countries, GOOGLE_CONSOLE_KEY } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { getLatLnd } from '../../../helpers';

interface IProps {
  address: ApiAddressModel;
  onChange: (name: string, data: ApiAddressModel) => void;
  errors: any;
}

const AddressAutocomplete = ({ address, onChange, errors }: IProps) => {
  const { t } = useTranslation();
  const [isFocusAddress, setFocusAddress] = useState(false);

  const addressInputChange = (name: string, e: any) => {
    onChange(name, {
      ...address,
      [name]: e.target.value,
    });
  };
  const locationChangeHandler = async (place: any, ref: any) => {
    let address_details: ApiAddressModel = {
      full_address: place.formatted_address,
      address: '',
      country: '',
      state: '',
      city: '',
      street: '',
      postal: '',
      suite: '',
      place_id: place.place_id,
      number: null,
    };

    if (!place.address_components) {
      return;
    }

    place.address_components.reverse().map((value: any) => {
      if (value.types.find((f: string) => f === 'street_number')) {
        address_details.number = value.long_name;
      }
      if (value.types.find((f: string) => f === 'route')) {
        address_details.street = value.long_name;
      }
      if (value.types.find((f: string) => f === 'country')) {
        address_details.country = value.short_name;
      }
      if (value.types.find((f: string) => f === 'postal_code')) {
        address_details.postal = value.long_name;
      }
      if (value.types.find((f: string) => f === 'locality' || f === 'postal_town')) {
        address_details.city = value.long_name;
      }
      if (value.types.find((f: string) => f === 'administrative_area_level_1')) {
        address_details.city = value.long_name;
      }
      if (value.types.find((f: string) => f === 'administrative_area_level_1')) {
        address_details.state = value.long_name;
      }
    });

    address_details.address = address_details.number
      ? `${address_details.number} ${address_details.street}`
      : `${address_details.street}`;

    const latLng = await getLatLnd(address_details);
    address_details = {
      ...address_details,
      ...(latLng || {}),
    };
    ref.value = address_details.address;

    onChange('', {
      ...address,
      ...address_details,
    });
  };

  const handleChangeAddressSelect = (name: string, data: ISelectOptions) => {
    onChange(name, {
      ...address,
      [name]: data.value,
    });
  };

  const handleChangeInput = (name: string, value: any) => {
    onChange(name, {
      ...address,
      [name]: value,
    });
  };

  const isInternation = !(address.country && address.country.toLowerCase() === 'us');

  const onInputFocus = () => {
    setFocusAddress(true);
  };

  const onInputBlur = () => {
    setFocusAddress(false);
  };

  const countryValue =
    countries.find(f => f.value === (address.country && address.country.toLowerCase())) ||
    countries.find(f => f.value === 'us');

  return (
    <div className={'autocomplete'}>
      <div className={`container-row mb-0 mt-0`}>
        <Select
          name={'country'}
          value={countryValue}
          placeholder={t('common:label.country')}
          options={countries}
          error={errors.country}
          handleChange={(value: ISelectOptions) => handleChangeAddressSelect('country', value)}
          height={48}
          autoFocus
        />
      </div>

      <div className={`container-row mt-0 mb-0`}>
        <div className={styles.input_wrapper}>
          <div
            className={`
                        ${styles.input_placeholder_block} 
                        ${errors && errors.length > 0 ? styles.input_wrapper_error : ''} 
                        ${isFocusAddress ? styles.input_label_focus : ''}`}
            data-content={address.address && address.address.length > 0 ? 'Address' : ''}
          >
            <Autocomplete
              placeholder={t('common:label.address')}
              onChange={e => addressInputChange('address', e)}
              defaultValue={address.address}
              onPlaceSelected={locationChangeHandler}
              apiKey={`${GOOGLE_CONSOLE_KEY}&callback=Function.prototype`}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
              aria-label={t('common:label.address')}
              options={{
                types: ['address'],
                componentRestrictions: { country: address.country || 'us' },
              }}
              className={`
                            ${styles.input} 
                            ${!address.country ? styles.input_disable : ''} 
                            ${errors.address ? styles.input_error : ''}
                            ${isFocusAddress ? styles.input_focus : ''}
                            `}
            />
          </div>
          <div className={'error-notification'}>
            <span className={'error-notification-text'}>{errors.address}</span>
          </div>
        </div>
      </div>
      <div className={`container-row mt-0 mb-0`}>
        <div className={'input-wrapper'}>
          <Input
            name={'suite'}
            error={errors.suite}
            value={address.suite || ''}
            type={'text'}
            placeholder={isInternation ? t('common:label.international.suite') : t('common:label.us.suite')}
            handleChange={value => handleChangeInput('suite', value)}
          />
        </div>
      </div>
      <div className={`container-row mt-0 mb-0`}>
        <div className={'input-wrapper'}>
          <Input
            name={'city'}
            error={errors.city}
            value={address.city || ''}
            type={'text'}
            // disabled={!(address.state && !address.city)}
            placeholder={isInternation ? t('common:label.international.city') : t('common:label.us.city')}
            handleChange={value => handleChangeInput('city', value)}
          />
        </div>
      </div>
      <div className={`container-row mt-0 mb-0`}>
        <div className={'input-wrapper'}>
          <Input
            name={'state'}
            error={errors.state}
            value={address.state || ''}
            type={'text'}
            // disabled={true}
            placeholder={isInternation ? t('common:label.international.state') : t('common:label.us.state')}
            handleChange={value => handleChangeInput('state', value)}
          />
        </div>
      </div>
      <div className={`container-row mt-0 mb-0`}>
        <div className={'input-wrapper'}>
          <Input
            name={'postal'}
            error={errors.postal}
            value={address.postal || ''}
            type={'text'}
            disabled={false}
            placeholder={isInternation ? t('common:label.international.postal') : t('common:label.us.postal')}
            handleChange={value => handleChangeInput('postal', value)}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressAutocomplete;
