import {
  CreditActionsTypes,
  GET_CREDITS_LIST_SUCCESS,
  CREATE_CREDIT_SUCCESS,
  GET_CREDITS_OPTIONS_SUCCESS,
  UPDATE_CREDIT_SUCCESS,
} from './creditActions';
import { CreditModel, CreditOptionsModel } from '../../models';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';

export type CreditState = {
  creditList: CreditModel[] | null;
  creditOptions: CreditOptionsModel | null;
};

const initialState: CreditState = {
  creditList: null,
  creditOptions: null,
};

const creditReducer = (state: CreditState = initialState, action: CreditActionsTypes): CreditState => {
  switch (action.type) {
    case GET_CREDITS_LIST_SUCCESS:
      return {
        ...state,
        creditList: action.payload,
      };
    case LOGOUT_SUCCESS: {
      return initialState;
    }
    case CREATE_CREDIT_SUCCESS: {
      if (state.creditList) {
        return {
          ...state,
          creditList: [action.payload, ...state.creditList],
        };
      }
      return state;
    }
    case UPDATE_CREDIT_SUCCESS: {
      if (state.creditList) {
        const res = state.creditList.map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
        return {
          ...state,
          creditList: res,
        };
      }
      return state;
    }
    case GET_CREDITS_OPTIONS_SUCCESS: {
      return {
        ...state,
        creditOptions: action.payload,
      };
    }
    default:
      return state;
  }
};

export default creditReducer;
