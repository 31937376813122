import {
  ElmActionsTypes,
  GET_ELM_ORG_REPORT_SUCCESS,
  GET_ELM_OPTIONS_SUCCESS,
  GET_ELM_A_MANAGERS_SUCCESS,
  CREATE_ELM_SUCCESS,
  SEND_NOTIFICATION_SUCCESS,
  GET_ELM_REPORT,
  SET_QUERY,
  GET_ELM_USER_LIST_SUCCESS,
} from './elmActions';
import {
  EAUser,
  ElmModel,
  ElmOptionsModel,
  ElmOrgReportModel,
  ElmUserModel,
  ElmUserPaginationModel,
} from '../../models';
import { basicDateFormat } from '../../helpers';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';
import { ORG_CHANGED } from '../organization/organizationActions';
import { QueryParamsModel } from '../../hooks/common/use-table-data';

export type ElmState = {
  createElmData: ElmModel | null;
  managersList: EAUser[] | null;
  elmOptions: ElmOptionsModel | null;
  elmOrgReport: ElmOrgReportModel | null;
  query: QueryParamsModel;
  elmUserList: ElmUserPaginationModel<ElmUserModel[]> | null;
};

const initialState: ElmState = {
  createElmData: null,
  managersList: null,
  elmOptions: null,
  elmOrgReport: null,
  elmUserList: null,
  query: {
    filters: {},
    sorting: null,
    search: '',
    page_size: 15,
    page: 1,
  },
};

const elmReducer = (state: ElmState = initialState, action: ElmActionsTypes): ElmState => {
  switch (action.type) {
    case GET_ELM_OPTIONS_SUCCESS: {
      const equity_audits = [...action.payload.equity_audits];
      equity_audits.map(e => {
        e.label = `Completed on ${basicDateFormat(e.label)}`;
        return e;
      });
      return {
        ...state,
        elmOptions: action.payload,
      };
    }
    case GET_ELM_REPORT: {
      return {
        ...state,
        elmOrgReport: null,
      };
    }
    case GET_ELM_USER_LIST_SUCCESS: {
      return {
        ...state,
        elmUserList: action.payload,
      };
    }
    case GET_ELM_ORG_REPORT_SUCCESS: {
      return {
        ...state,
        elmOrgReport: action.payload,
      };
    }
    case GET_ELM_A_MANAGERS_SUCCESS: {
      return {
        ...state,
        managersList: action.payload,
      };
    }
    case SET_QUERY: {
      return {
        ...state,
        query: action.payload,
      };
    }
    case CREATE_ELM_SUCCESS: {
      return {
        ...state,
        createElmData: action.payload,
      };
    }
    case SEND_NOTIFICATION_SUCCESS: {
      return { ...state };
    }
    case LOGOUT_SUCCESS:
      return initialState;
    case ORG_CHANGED:
      return initialState;
    default:
      return state;
  }
};

export default elmReducer;
