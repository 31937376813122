import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { EwpCreateStepEnum, IRouteParamsBase } from '../../../../../models';
import { ROOTS } from '../../../../../routes/routes';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
interface IProps {
  activeItemAlias:
    | 'details'
    | 'dashboard'
    | 'review-items'
    | 'users'
    | 'priority'
    | 'goal'
    | 'strategy'
    | 'key-action';
}

const EwpTabs: FC<IProps> = ({ activeItemAlias }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { orgId } = useParams<IRouteParamsBase>();
  const ewpDetails = useSelector((state: StateType) => state.ewp.ewpDetails);
  const isBeloved = useSelector((state: StateType) => state.profile.authUser?.beloved_user);

  const ewpNotCreated = ewpDetails?.ewp_screen !== EwpCreateStepEnum.finish;

  const EWP_DETAILS = isBeloved ? ROOTS.BELOVED_EWP_DETAILS : ROOTS.EWP_DETAILS;

  const handleClick = (alias: IProps['activeItemAlias']) => (e: any) => {
    e.preventDefault();
    if (!ewpDetails) return null;
    switch (alias) {
      case 'details':
        history.push(EWP_DETAILS.replace(':orgId', `${orgId}`));
        break;
      case 'priority':
        history.push(
          ROOTS.EWP_PRIORITY_LIST.replace(':orgId', `${orgId}`).replace(':id', `${ewpDetails.id}`),
        );
        break;
      case 'users':
        history.push(ROOTS.EWP_USERS.replace(':orgId', `${orgId}`).replace(':id', `${ewpDetails.id}`));
        break;
      case 'review-items':
        history.push(ROOTS.EWP_REVIEW_ITEMS.replace(':orgId', `${orgId}`).replace(':id', `${ewpDetails.id}`));
        break;
      case 'goal':
        history.push(ROOTS.EWP_GOAL_LIST.replace(':orgId', `${orgId}`).replace(':id', `${ewpDetails.id}`));
        break;
      case 'strategy':
        history.push(
          ROOTS.EWP_STRATEGY_LIST.replace(':orgId', `${orgId}`).replace(':id', `${ewpDetails.id}`),
        );
        break;
      case 'key-action':
        history.push(ROOTS.EWP_KA_LIST.replace(':orgId', `${orgId}`).replace(':id', `${ewpDetails.id}`));
        break;
      case 'dashboard':
        history.push(ROOTS.EWP_DASHBOARD.replace(':orgId', `${orgId}`).replace(':id', `${ewpDetails.id}`));
        break;
    }
  };
  return (
    <nav className="b-tabs" id="org-tabs">
      <a
        id={activeItemAlias === 'details' ? 'active-org-tab' : ''}
        href="google.com"
        className={`
        b-tabs__item 
        ${activeItemAlias === 'details' ? 'b-tabs__item_active' : ''}
        `}
        onClick={handleClick('details')}
      >
        <div className={`b-tabs__icon b-tabs__icon_ewp_details`} />
        <span className="b-tabs__text">{t('common:tabs.ewp-details')}</span>
      </a>
      <a
        id={activeItemAlias === 'review-items' ? 'active-org-tab' : ''}
        href="#"
        className={`
        b-tabs__item 
        ${ewpDetails?.unread_elements_count ? '-mark' : ''}
        ${activeItemAlias === 'review-items' ? 'b-tabs__item_active' : ''}
        ${ewpNotCreated ? 'b-tabs__item_disabled' : ''}
        `}
        onClick={handleClick('review-items')}
      >
        <div className="b-tabs__icon b-tabs__ewp_review_items" />
        <span className="b-tabs__text">{t('common:tabs.review-items')}</span>
      </a>
      <a
        id={activeItemAlias === 'dashboard' ? 'active-org-tab' : ''}
        href="#"
        className={`
        b-tabs__item 
        ${activeItemAlias === 'dashboard' ? 'b-tabs__item_active' : ''}
        ${ewpNotCreated ? 'b-tabs__item_disabled' : ''}
        `}
        onClick={handleClick('dashboard')}
      >
        <div className="b-tabs__icon b-tabs__icon_dashboard" />
        <span className="b-tabs__text">{t('common:tabs.dashboard')}</span>
      </a>
      <a
        id={activeItemAlias === 'users' ? 'active-org-tab' : ''}
        href="#"
        className={`
        b-tabs__item 
        ${activeItemAlias === 'users' ? 'b-tabs__item_active' : ''}
        ${ewpNotCreated ? 'b-tabs__item_disabled' : ''}
        `}
        onClick={handleClick('users')}
      >
        <div className="b-tabs__icon b-tabs__icon_users" />
        <span className="b-tabs__text">{t('common:tabs.ewp-members')}</span>
      </a>
      <a
        id={activeItemAlias === 'priority' ? 'active-org-tab' : ''}
        href="#"
        className={`
        b-tabs__item 
        ${activeItemAlias === 'priority' ? 'b-tabs__item_active' : ''}
        ${ewpNotCreated ? 'b-tabs__item_disabled' : ''}
        `}
        onClick={handleClick('priority')}
      >
        <div className="b-tabs__icon b-tabs__icon_ewp_priority" />
        <span className="b-tabs__text">{t('common:tabs.priority')}</span>
      </a>
      <a
        id={activeItemAlias === 'goal' ? 'active-org-tab' : ''}
        href="#"
        className={`
        b-tabs__item 
        ${activeItemAlias === 'goal' ? 'b-tabs__item_active' : ''}
        ${ewpNotCreated ? 'b-tabs__item_disabled' : ''}
        `}
        onClick={handleClick('goal')}
      >
        <div className="b-tabs__icon b-tabs__icon_ewp_goals" />
        <span className="b-tabs__text">{t('common:tabs.goals')}</span>
      </a>
      <a
        id={activeItemAlias === 'strategy' ? 'active-org-tab' : ''}
        href="#"
        className={`
        b-tabs__item 
        ${activeItemAlias === 'strategy' ? 'b-tabs__item_active' : ''}
        ${ewpNotCreated ? 'b-tabs__item_disabled' : ''}
        `}
        onClick={handleClick('strategy')}
      >
        <div className="b-tabs__icon b-tabs__icon_ewp_strats" />
        <span className="b-tabs__text">{t('common:tabs.strategies')}</span>
      </a>
      <a
        id={activeItemAlias === 'key-action' ? 'active-org-tab' : ''}
        href="#"
        className={`
        b-tabs__item 
        ${activeItemAlias === 'key-action' ? 'b-tabs__item_active' : ''}
        ${ewpNotCreated ? 'b-tabs__item_disabled' : ''}
        `}
        onClick={handleClick('key-action')}
      >
        <div className="b-tabs__icon b-tabs__icon_ewp_ka" />
        <span className="b-tabs__text">{t('common:tabs.key-actions')}</span>
      </a>
    </nav>
  );
};

export default EwpTabs;
