import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { HeadlineOrganization, Modal } from '../../../../common';
import { Modals, ModalsModel } from '../../../../../constants';
import ScoreGuideModal from '../ScoreGuideModal/ScoreGuideModal';
import ProgressPie from '../../../../common/ProgressPie/ProgressPie';
import { getStandardReport } from '../../../../../store/report/reportActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IRouteParamsBase, KeyValueNumberModel, StandardScoreModel } from '../../../../../models';
import { Link, Loader } from '../../../../controls';
import { ROOTS } from '../../../../../routes/routes';
import { useHistory } from 'react-router';
import { StateType } from '../../../../../store/reducers';
import { useTranslation } from 'react-i18next';

const ScoreReport = () => {
  const { t } = useTranslation();
  const [modals, setModals] = useState<ModalsModel>({});
  const [report, setReport] = useState<StandardScoreModel | null>(null);
  const { orgId, eaId, score } = useParams<IRouteParamsBase>();
  const scoreReportsSelect: KeyValueNumberModel<StandardScoreModel> = useSelector(
    (state: StateType) => state.report.reportsData[Number(eaId)]?.scoreReports,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (scoreReportsSelect?.[Number(score)]) {
      getStandardReportSuccess(scoreReportsSelect[Number(score)]);
      return;
    }
    dispatch(
      getStandardReport({
        data: {
          eaId: Number(eaId),
          standard_id: Number(score),
        },
        callback: getStandardReportSuccess,
      }),
    );
  }, []);

  const getStandardReportSuccess = (data: StandardScoreModel) => {
    data.sub_standards = Object.values(data.sub_standards);
    setReport(data);
  };

  const setModal = (modal: keyof ModalsModel) => {
    setModals(prevState => ({
      ...prevState,
      [modal]: !modals[modal],
    }));
  };

  const goBack = (e: any) => {
    e.stopPropagation();
    history.go(-1);
  };

  const navToReview = (id: number) => {
    history.push(
      ROOTS.REPORT_STANDARD_RESPONSES.replace(':orgId', `${orgId}`)
        .replace(':eaId', `${eaId}`)
        .replace(':standardId', `${id}`),
    );
  };

  const renderContent = () => {
    if (report && scoreReportsSelect && scoreReportsSelect?.[Number(score)]) {
      return (
        <div className="b-report">
          <div className="b-report__header">
            {isMobileOnly && <div className="b-report__arrow" />}
            {!isMobileOnly && (
              <HeadlineOrganization
                orgName={report.organization_name}
                orgId={orgId}
                headline={t('main:ea.ea')}
                customClasses={'b-report__pageTitle'}
              />
            )}

            <div className="b-report__pageSubTitle">
              <Link tabIndex={0} className="b-report__backBtn" onClick={goBack}>
                <div className="back-icon" />
              </Link>
              <span>{report.name}</span>
            </div>
          </div>

          <div className="b-report__holder">
            <div className="b-report__frame">
              <div className="b-report__chartBlock">
                <div className="b-report__chartBlockHolder">
                  <strong className="b-report__chartTitle">{t('main:reports.your-score')}</strong>
                  <div className="b-report__chart">
                    <div className="b-report__chartContainer">
                      <ProgressPie value={report.score || 0} />
                    </div>
                  </div>
                  <div className={`b-report__status -${report.score_band?.toLocaleLowerCase()}`}>
                    {report.score_band}
                  </div>
                </div>
                <div className="b-report__chartRow">
                  <a className="b-report__headingLink" onClick={() => setModal(Modals.isScoreGuide)}>
                    {t('common:btn.score-guide')}
                  </a>
                  <button className="b-report__btnInfo" onClick={() => setModal(Modals.isScoreGuide)}>
                    <span className="sr-only">{t('common:btn.information')}</span>
                  </button>
                </div>
                <p>{report.description}</p>
              </div>
              <div className="b-reportItemList">
                {report.sub_standards.map(standard => {
                  return (
                    <div className="b-reportItem2" key={standard.id}>
                      <span className="b-reportItem2__name">{standard.name}</span>
                      <strong className="b-reportItem2__num">
                        {typeof standard.score === 'number' ? `${standard.score}%` : `${standard.score}`}
                      </strong>
                      {report.is_detailed && (
                        <div className="b-reportItem2__text">
                          <p>{standard.description}</p>
                          {!report.is_legacy_audit ? (
                            <a tabIndex={0} onClick={() => navToReview(standard.id)}>
                              {t('main:reports.review-responses')}
                            </a>
                          ) : null}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <Loader fitParent />;
  };

  return (
    <>
      <main className={'pageContainer pageContainer__content'}>{renderContent()}</main>
      {modals.isScoreGuide && (
        <Modal showCloseBtn={false} width={545} onClose={() => setModal(Modals.isScoreGuide)}>
          <ScoreGuideModal onClose={() => setModal(Modals.isScoreGuide)} />
        </Modal>
      )}
    </>
  );
};

export default ScoreReport;
