import { SortDirection, useRowSelection } from 'react-data-grid';
import { BelResourceShortModel, ColumnType } from '../../../../../../models';
import { cutString } from '../../../../../../helpers';
import { Checkbox } from '../../../../../controls';
import React from 'react';
import { t } from 'i18next';
import EmptyButton from '../../../../../controls/Button/EmptyButton';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

interface ColumnsHandlers {
  onCellClick?: (fieldName: string, data: BelResourceShortModel) => void;
}

export const getColumns = ({ onCellClick }: ColumnsHandlers): ColumnType<BelResourceShortModel>[] => [
  {
    key: 'title',
    name: t('common:column.title'),
    width: 200,
    sortable: true,
    cellClass: 'b-rdgTable__cellTextWrap',
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      return <>{cutString(props.row.title, 120)}</>;
    },
  },
  {
    key: 'ownership',
    name: t('common:label.publisher'),
    sortable: true,
    width: 150,
    cellClass: 'b-rdgTable__cellTextWrap',
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      return <>{props.row.ownership.name}</>;
    },
  },
  {
    key: 'type',
    name: t('common:label.type'),
    sortable: true,
    width: 200,
    editable: false,
    resizable: true,
    headerCellClass: 'b-rdgTable__headerCell',
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      return <>{props.row.type.name}</>;
    },
  },
  {
    key: 'categories',
    name: t('common:label.categories'),
    sortable: false,
    cellClass: 'b-rdgTable__cellTextWrap',
    editable: false,
    resizable: true,
    headerCellClass: 'b-rdgTable__headerCell',
    formatter(props) {
      return <div>{cutString(props.row.categories.map(cat => cat.title).join(', '), 125)}</div>;
    },
  },
  {
    key: 'tools',
    name: '',
    width: 70,
    maxWidth: 70,
    disableDrag: true,
    formatter: props => {
      return (
        <div className="b-rdgTable__cellCenter" onClick={e => e.stopPropagation()}>
          <EmptyButton
            onClick={onCellClick ? () => onCellClick('navToView', props.row) : () => null}
            className="b-button-icon -colorNavy -mh2  resource-view"
          />
        </div>
      );
    },
  },
  {
    key: 'select',
    name: '',
    width: 60,
    maxWidth: 60,
    disableDrag: true,
    headerRenderer(props) {
      return (
        <Checkbox
          checked={props.allRowsSelected}
          onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
        />
      );
    },
    formatter: props => {
      const [isRowSelected, onRowSelectionChange] = useRowSelection();

      return (
        <div className="b-rdgTable__cellCenter">
          <div className={'checkBox'} onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={isRowSelected}
              onChange={e => {
                onRowSelectionChange({
                  row: props.row,
                  checked: e.target.checked,
                  isShiftClick: false,
                });
              }}
            />
          </div>
        </div>
      );
    },
  },
];
