import React, { FC, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Loader } from '../../../../../controls';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import EditIc from '../../../../../../assets/images/icons/round-image-edit.svg';
import { Alert, SelectValuesModal } from '../../../../../common';
import InfoIc from '../../../../../../assets/images/icons/round-action-info.svg';
import {
  EwpCreateStepEnum,
  EwpTypes,
  EWPUpdateData,
  ISelectOptions,
  TeamType,
} from '../../../../../../models';
import { StateType } from '../../../../../../store/reducers';
import { useTranslation } from 'react-i18next';
import { getEwpAvailableManagers } from '../../../../../../store/ewp/ewpActions';
import { EwpHelpers } from '../../../../../../helpers/ewpHelpers';
import { useHistory } from 'react-router';
import { basicDateFormat, convertUserStatus } from '../../../../../../helpers';
import { useConsultants } from '../../../../../../hooks/ewp/use-consultants';

interface IProps {
  handleCancel: () => void;
  saveData: (data: EWPUpdateData, isNext: boolean) => void;
}

interface IState {
  teamUsers: number[];
  managerUsers: number[];
  consultantsUsers: number[];
  tempUsers: number[];
  modalType: TeamType | null;
  tipType: TeamType | null;
}

export const EWPManagers: FC<IProps> = ({ handleCancel, saveData }) => {
  const { t } = useTranslation();
  const createEWPData = useSelector((state: StateType) => state.ewp.createEWPData);
  const dispatch = useDispatch();
  const [usersList, setUserList] = useState<ISelectOptions[] | null>(null);
  const history = useHistory();

  const { consultants } = useConsultants(createEWPData?.organization_id);

  const hasConsultants =
    createEWPData?.ewp_type_id === EwpTypes.org_with_consultant_reviewer_role ||
    createEWPData?.ewp_type_id === EwpTypes.org_with_consultant_active_role;

  const initialState = {
    teamUsers: [],
    managerUsers: [],
    consultantsUsers: [],
    tempUsers: [],
    modalType: null,
    tipType: null,
  };
  let [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (createEWPData) {
      setState(prevState => {
        return {
          ...prevState,
          teamUsers: createEWPData.team_ids,
          managerUsers: createEWPData.manager_ids,
          consultantsUsers: createEWPData.consultant_ids,
        };
      });

      dispatch(
        getEwpAvailableManagers({
          orgId: createEWPData.organization_id,
          callback: res => {
            setUserList(res);
          },
        }),
      );
    }
  }, [createEWPData]);

  const handleEditTeamList = (modalType: TeamType) => (e: any) => {
    e.preventDefault();
    if (modalType === 'team' && usersList && !usersList.length) {
      setState({
        ...state,
        tipType: 'team_empty',
      });
      return;
    }
    if (modalType === 'consultants' && consultants && !consultants.length) {
      setState({
        ...state,
        tipType: 'consultants_empty',
      });
      return;
    }
    if (modalType === 'managers' && usersList && !usersList.length) {
      setState({
        ...state,
        tipType: 'managers_empty',
      });
      return;
    }
    setState(prevState => {
      const newState = { ...prevState, modalType };
      if (modalType === 'managers') {
        newState.tempUsers = prevState.managerUsers;
      } else if (modalType === 'team') {
        newState.tempUsers = prevState.teamUsers;
      } else if (modalType === 'consultants') {
        newState.tempUsers = prevState.consultantsUsers;
      }
      return newState;
    });
  };

  const handleCloseModal = () => {
    setState({ ...state, modalType: null, tempUsers: [] });
  };

  const handleSaveUserList = (tempUsers: number[]) => {
    setState(prevState => {
      const newState = { ...prevState };
      if (prevState.modalType === 'team') {
        newState.teamUsers = tempUsers;
      } else if (prevState.modalType === 'managers') {
        newState.managerUsers = tempUsers;
      } else if (prevState.modalType === 'consultants') {
        newState.consultantsUsers = tempUsers;
      }
      newState.tempUsers = [];
      newState.modalType = null;
      return newState;
    });
  };

  const handleNext = () => {
    const data: EWPUpdateData = {
      team_ids: state.teamUsers,
      manager_ids: state.managerUsers,
      ewp_screen: EwpCreateStepEnum.date,
    };
    if (hasConsultants) {
      data.consultant_ids = state.consultantsUsers;
    }
    saveData(data, true);
  };

  const handleSaveExit = (e: any) => {
    e.preventDefault();
    const data: EWPUpdateData = {
      team_ids: state.teamUsers,
      manager_ids: state.managerUsers,
      ewp_screen: EwpCreateStepEnum.team,
    };
    if (hasConsultants) {
      data.consultant_ids = state.consultantsUsers;
    }
    saveData(data, false);
  };

  const showTip = (tipType: TeamType) => (e: any) => {
    e.preventDefault();
    setState({ ...state, tipType });
  };

  const hideTip = () => {
    setState({ ...state, tipType: null });
  };

  if (!createEWPData) return <Loader fitParent />;

  return (
    <>
      <div className={'b-EACreate__step'}>
        <div className="b-EACreate__header">
          <h1 className={'pageContainer__title b-EATypo__pageTitle'}>{t('common:headlines.new-ewp')}</h1>
          {!isMobileOnly && (
            <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
              <img src={SaveIc} alt={t('common:alt.saveIc')} />
              {t('common:btn.save-exit')}
            </a>
          )}
        </div>
        <div className={'b-step__orgWrapper'}>
          <div className="b-equityAudit__text16">{t('common:headlines.organization')}</div>
          <div className={'b-step__orgText'}>{createEWPData.organization_name}</div>
        </div>
        {hasConsultants ? (
          <div className={'b-step__info'}>
            <div className={'b-step__titleWrapper'}>
              <div className={'b-step__title'}>{t('main:ewp-create.consultants.title')}</div>
              <a href="#" onClick={showTip('consultants')}>
                <img src={InfoIc} alt={t('common:alt.infoIc')} />
              </a>
            </div>
            <div className={`b-equityAudit__text12 ${'b-step__textWrapper'}`}>{t('awa:M145.msg')}</div>
            {state.consultantsUsers.length ? (
              <div className={'b-EACreate__users'}>
                {state.consultantsUsers.map(userId => {
                  const user = consultants?.find(user => user.value === userId);
                  const createdUser = createEWPData?.consultants.find(user => user.id === userId);
                  if (user || createdUser) {
                    return (
                      <div className={'b-EACreate__user'} key={`team-${userId}`}>
                        <div className="b-EACreate__userName">{user?.label || createdUser?.name}</div>
                        <div className="b-equityAudit__text16">
                          {createdUser && createdUser.status_date
                            ? convertUserStatus(createdUser.status, createdUser.status_date)
                            : basicDateFormat(new Date())}
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            ) : null}
            <a href="#" className={'b-step__add'} onClick={handleEditTeamList('consultants')}>
              {state.consultantsUsers.length ? (
                <>
                  <img style={{ paddingRight: 5 }} src={EditIc} alt={t('common:alt.editIc')} />
                  {t('common:btn.edit-ewp-consulting')}
                </>
              ) : (
                <>
                  <div className={'b-step__add__ic -transparent'}>
                    <img src={EditIc} alt={t('common:alt.editIc')} />
                  </div>
                  {t('common:btn.edit-ewp-consulting')}
                </>
              )}
            </a>
          </div>
        ) : null}
        <div className={'b-step__info'}>
          <div className={'b-step__titleWrapper'}>
            <div className={'b-step__title'}>{t('main:ewp-create.team.title')}</div>
            <a href="#" onClick={showTip('team')}>
              <img src={InfoIc} alt={t('common:alt.infoIc')} />
            </a>
          </div>
          <div className={`b-equityAudit__text12 ${'b-step__textWrapper'}`}>
            {t('main:ewp-create.team.msg')}
          </div>
          {state.teamUsers.length ? (
            <div className={'b-EACreate__users'}>
              {state.teamUsers.map(userId => {
                const user = usersList?.find(user => user.value === userId);
                const createdUser = createEWPData?.team.find(user => user.id === userId);
                if (user || createdUser) {
                  return (
                    <div className={'b-EACreate__user'} key={`team-${userId}`}>
                      <div className="b-EACreate__userName">{user?.label || createdUser?.name}</div>
                      <div className="b-equityAudit__text16">
                        {createdUser && createdUser.status_date
                          ? convertUserStatus(createdUser.status, createdUser.status_date)
                          : basicDateFormat(new Date())}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          ) : null}
          <a href="#" className={'b-step__add'} onClick={handleEditTeamList('team')}>
            {state.teamUsers.length ? (
              <>
                <img style={{ paddingRight: 5 }} src={EditIc} alt={t('common:alt.editIc')} />
                {t('common:btn.edit-ewp-team')}
              </>
            ) : (
              <>
                <div className={'b-step__add__ic -transparent'}>
                  <img src={EditIc} alt={t('common:alt.editIc')} />
                </div>
                {t('common:btn.edit-ewp-team')}
              </>
            )}
          </a>
        </div>
        <div className={'b-step__info'}>
          <div className={'b-step__titleWrapper'}>
            <div className={'b-step__title'}>{t('main:ewp-create.manager.title')}</div>
            <a href="#" onClick={showTip('managers')}>
              <img src={InfoIc} alt={t('common:alt.infoIc')} />
            </a>
          </div>
          <div className={`b-equityAudit__text12 mw-100`}>{t('main:ewp-create.manager.msg')}</div>
          {state.managerUsers.length ? (
            <div className={'b-EACreate__users'}>
              {state.managerUsers.map(userId => {
                const user = usersList?.find(user => user.value === userId);
                const createdUser = createEWPData?.managers.find(user => user.id === userId);
                if (user || createdUser) {
                  return (
                    <div className={'b-EACreate__user'} key={`manager-${userId}`}>
                      <div className="b-EACreate__userName">{user?.label || createdUser?.name}</div>
                      <div className="b-equityAudit__text16">
                        {createdUser && createdUser.status_date
                          ? convertUserStatus(createdUser.status, createdUser.status_date)
                          : basicDateFormat(new Date())}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          ) : null}
          <a href="#" className={'b-step__add'} onClick={handleEditTeamList('managers')}>
            {state.managerUsers.length ? (
              <>
                <img style={{ paddingRight: 5 }} src={EditIc} alt={t('common:alt.editIc')} />
                {t('common:btn.edit-managers')}
              </>
            ) : (
              <>
                <div className={'b-step__add__ic -transparent'}>
                  <img src={EditIc} alt={t('common:alt.editIc')} />
                </div>
                {t('common:btn.edit-ewp-manager')}
              </>
            )}
          </a>
        </div>
        <div className={`b-step__bottom`}>
          <div className={`b-step__bottom-left`}>
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={() => history.go(-1)}
              title={t('common:btn.back')}
            />
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={handleCancel}
              title={t('common:btn.cancel')}
            />
          </div>
          {isMobileOnly ? (
            <div className={`b-step__bottom-left`}>
              <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
                <img src={SaveIc} alt={t('common:alt.saveIc')} />
                {t('common:btn.save-exit')}
              </a>
              <Button
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleNext}
                title={t('common:btn.next')}
                enableEnter
              />
            </div>
          ) : (
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={handleNext}
              title={t('common:btn.next')}
              enableEnter
            />
          )}
        </div>
      </div>
      {state.tipType === 'team_empty' || state.tipType === 'managers_empty' ? (
        <Alert
          isOpen={true}
          onRequestClose={hideTip}
          title={t('awa:M100.title')}
          text={state.tipType === 'team_empty' ? t('awa:M100.msg') : t('awa:M101.msg')}
        />
      ) : null}
      {state.tipType === 'consultants_empty' ? (
        <Alert isOpen={true} onRequestClose={hideTip} title={t('awa:M100.title')} text={t('awa:M100.msg')} />
      ) : null}
      {(state.modalType === 'team' || state.modalType === 'managers') && (
        <SelectValuesModal
          title={
            state.modalType === 'team' ? t('common:headlines.add-member') : t('common:headlines.add-managers')
          }
          subTitle={t('common:headlines.organization-users')}
          bottomInfo={`*${t('main:add-member.received')}.`}
          onClose={handleCloseModal}
          value={state.modalType === 'team' ? state.teamUsers : state.managerUsers}
          values={EwpHelpers.getUserList(
            state.teamUsers,
            state.managerUsers,
            usersList || [],
            state.modalType,
          )}
          handleSave={tempUsers => handleSaveUserList(tempUsers)}
        />
      )}
      {consultants && state.modalType === 'consultants' && (
        <SelectValuesModal
          title={t('common:headlines.add-EWP-consultants')}
          subTitle={t('common:headlines.consultants-users')}
          bottomInfo={`*${t('main:add-member.received')}.`}
          onClose={handleCloseModal}
          value={state.consultantsUsers}
          values={consultants}
          handleSave={tempUsers => handleSaveUserList(tempUsers)}
        />
      )}
      {state.tipType === 'team' ? (
        <Alert
          isOpen={!!state.tipType}
          onRequestClose={hideTip}
          title={t('awa:M132.title')}
          text={t('awa:M132.msg')}
        />
      ) : null}
      {state.tipType === 'managers' ? (
        <Alert
          isOpen={!!state.tipType}
          onRequestClose={hideTip}
          title={t('awa:M142.title')}
          text={t('awa:M142.msg')}
        />
      ) : null}
      {state.tipType === 'consultants' ? (
        <Alert
          isOpen={!!state.tipType}
          onRequestClose={hideTip}
          title={t('awa:M144.title')}
          text={t('awa:M144.msg')}
        />
      ) : null}
    </>
  );
};
