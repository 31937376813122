import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  totalSteps: number;
  currentStep: number;
}

const Stepper: FC<IProps> = ({ totalSteps, currentStep }) => {
  const renderSteps = () => {
    const total = [];
    for (let i = 1; i <= totalSteps; i++) {
      total.push(
        <div key={`step-${i}`} className={`b-step__stepper-count ${currentStep >= i ? '-active' : ''}`}>
          {i}
        </div>,
      );
    }
    return total;
  };

  return <div className={'b-step__stepper'}>{renderSteps()}</div>;
};

export default Stepper;
