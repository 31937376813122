import { useParams } from 'react-router';
import { BelCohortModel, IRouteParamsBase } from '../../../models';
import { useEffect, useState } from 'react';
import Api from '../../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../store/reducers';
import { getCohortSuccess, resetCohort } from '../../../store/cohort/cohortActions';

export const useGetCohortDetails = (): BelCohortModel | null => {
  const { id } = useParams<IRouteParamsBase>();
  const cohortSelect = useSelector((state: StateType) => state.cohort.currentCohort);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { orgId } = useParams<IRouteParamsBase>();

  const fetchData = async () => {
    if (id && id !== 'create') {
      const url = orgId ? `organizations/${orgId}/cohort/${id}/details_cohorts/` : `beloved/cohort/${id}/`;
      const response = await Api.get(url);
      if (response) {
        return response;
      }
    }
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if ((id && !cohortSelect) || (id && cohortSelect?.id !== Number(id))) {
      setLoading(true);
      dispatch(resetCohort({}));
      fetchData().then(cohort => {
        setLoading(false);
        dispatch(getCohortSuccess(cohort));
      });
    }
  }, [id]);

  return cohortSelect;
};
