import React, { FC } from 'react';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import { isMobileOnly } from 'react-device-detect';
import { Button } from '../../../../../controls';
import { useHistory } from 'react-router';
import Pic from '../../../../../../assets/images/group@2x.png';
import { useRole } from '../../../../../../helpers/roles';
import { useTranslation } from 'react-i18next';

interface IProps {
  navBack: () => void;
  onSubmit: () => void;
  enableEnter: boolean;
}

const Submission: FC<IProps> = ({ navBack, onSubmit, enableEnter }) => {
  const { t } = useTranslation();
  const history: any = useHistory();
  const access = useRole();

  const handleSaveExit = () => {
    history.goBack();
  };

  return (
    <>
      {!isMobileOnly && (
        <a href="#" className="b-EACreate__saveBtn b-EATaking__saveExit" onClick={handleSaveExit}>
          <img src={SaveIc} alt={t('common:alt.saveIc')} />
          {t('common:btn.save-exit')}
        </a>
      )}
      <div className="b-EATaking__box">
        <div className="b-EATaking__container">
          <img width={190} src={Pic} alt={t('awa:AT3')} style={{ marginBottom: 28 }} />
          <h2 className="b-EATaking__boxTitle">{t('awa:M53.title')}</h2>
          <p>{t('awa:M53.msg.p1')}</p>
          <p>{t('awa:M53.msg.p2')}</p>
          <p className="w-100">
            <a href={t('awa:M53:msg.p4')} className="b-link" rel="noreferrer" target="_blank">
              {t('awa:M53.msg.p3')}
            </a>
          </p>

          <p>{t('awa:M53.msg.p5')}</p>
          {!isMobileOnly && (
            <div className="b-EATaking__buttons">
              <Button
                className={'btn -type_form'}
                type={'transparency'}
                onPress={navBack}
                title={t('common:btn.back')}
              />
              {access(['project_manager']) && (
                <Button
                  className={'btn -type_form'}
                  type={'orange-light'}
                  onPress={onSubmit}
                  title={t('common:btn.submit')}
                  enableEnter={enableEnter}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {isMobileOnly && (
        <React.Fragment>
          <div className="b-EATaking__buttons">
            <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
              <img src={SaveIc} alt={t('common:alt.saveIc')} />
              {t('common:btn.save-exit')}
            </a>
            {access(['project_manager']) && (
              <Button
                className={'btn'}
                type={'orange-light'}
                onPress={onSubmit}
                title={t('common:btn.submit')}
                enableEnter
              />
            )}
          </div>

          <Button
            className={'btn full-width'}
            type={'transparency'}
            onPress={navBack}
            title={t('common:btn.back')}
          />
        </React.Fragment>
      )}
    </>
  );
};

export default Submission;
