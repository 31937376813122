import React from 'react';
import styles from './ScoreGuideModalStyles.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from '../../../../controls';

interface IProps {
  onClose: () => void;
}

const ScoreGuideModal = ({ onClose }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={`b-EAModal  b-step ${styles.modal}`}>
      <div className={'b-EAModal__title'}>
        <Link onClick={onClose} className="b-EAModal__close" />
        {t('awa:M61.title')}
      </div>
      <div className="b-step__p">{t('awa:M61.msg.p1')}</div>
      <div className="b-step__p">{t('awa:M61.msg.p2')}</div>
      <div className={styles.b__list}>
        <ul className={styles.list}>
          <li className={`${styles.list__item} ${styles.gray}`}>
            <div className={styles.list__item__wrap}>
              <span className={`${styles.list__item__text}`}>{t('awa:M61.msg.p3')}</span>
              <span className={styles.list__item__range}>{t('awa:M61.msg.p4')}</span>
            </div>
          </li>
          <li className={`${styles.list__item} ${styles.black}`}>
            <div className={styles.list__item__wrap}>
              <span className={`${styles.list__item__text}`}>{t('awa:M61.msg.p5')}</span>
              <span className={styles.list__item__range}>26%-50%</span>
            </div>
          </li>
          <li className={`${styles.list__item} ${styles.yellow}`}>
            <div className={styles.list__item__wrap}>
              <span className={`${styles.list__item__text}`}>{t('awa:M61.msg.p6')}</span>
              <span className={styles.list__item__range}>51%-74%</span>
            </div>
          </li>
          <li className={`${styles.list__item} ${styles.orange}`}>
            <div className={styles.list__item__wrap}>
              <span className={`${styles.list__item__text}`}>{t('awa:M61.msg.p7')}</span>
              <span className={styles.list__item__range}>75%-90%</span>
            </div>
          </li>
          <li className={`${styles.list__item} ${styles.green}`}>
            <div className={styles.list__item__wrap}>
              <span className={`${styles.list__item__text}`}>{t('awa:M61.msg.p8')}</span>
              <span className={styles.list__item__range}>91%-100%</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ScoreGuideModal;
