import React, { useEffect, useState } from 'react';
import DataGrid, { SortColumn } from 'react-data-grid';
import { isMobile } from 'react-device-detect';
import styles from '../../OrgUsers/OrgUsers.module.scss';
import { Modals, ModalsModel, orderValues } from '../../../../../constants';
import { getColumns } from './parts/columns';
import {
  Button,
  Loader,
  RadioButton,
  DownloadElmTemplate,
  ElmFileUploader,
  SimpleTooltip,
} from '../../../../controls';
import {
  ElmAvailableUserModel,
  ElmUserModel,
  ElmUserPaginationModel,
  IRouteParamsBase,
  PaginationModel,
  TableDataEntities,
} from '../../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { HeadlineOrganization, Modal, Pagination } from '../../../../common';
import { ROOTS } from '../../../../../routes/routes';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  deactivateElmUser,
  deleteElmUser,
  getAvailableElmUsers,
  setQuery,
  setUserRole,
} from '../../../../../store/elm/elmActions';
import AddUser from './AddUser';
import { ElmStatuses } from '../../../../../constants/elm';
import { useTranslation } from 'react-i18next';
import { useIsDarkMode } from '../../../../../hooks/common/use-is-dark-mode';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import useTableConfig from '../../../../../hooks/common/use-table-config';
import useTableData from '../../../../../hooks/common/use-table-data';
import FilterModal from './parts/FilterModal';
import { ElmState } from '../../../../../store/elm/elmReducer';
import { useSelectCurrentOrg } from '../../../../../hooks/org/use-select-current-org';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';

interface IState {
  selectedUserIds: number[];
  modals: ModalsModel;
  delete_option: 'all' | 'elm';
  deactivate_option: 'all' | 'elm';
  role_option: number;
  availableUsers: ElmAvailableUserModel[] | null;
  sorting: SortColumn | null;
}

interface IRouteParams extends IRouteParamsBase {
  elmId: string;
}

const ElmUserList = () => {
  const { t } = useTranslation();
  const currentOrg = useSelectCurrentOrg();

  const { orgId, elmId } = useParams<IRouteParams>();
  const { isDarkMode } = useIsDarkMode();
  const history = useHistory();
  const dispatch = useDispatch();
  const { navBack } = useNavBack();

  const [state, setState] = useState<IState>({
    selectedUserIds: [],
    modals: {},
    delete_option: 'elm',
    deactivate_option: 'elm',
    role_option: 0,
    availableUsers: null,
    sorting: null,
  });

  const {
    query,
    fetchData,
    handleChangePage,
    handleChangePageSize,
    handleSearch,
    handleSort,
    selectedEntitiesIds,
    setSelectedEntitiesIds,
    handleSelectOne,
    handleSelectAll,
  } = useTableData(TableDataEntities.ELM_USER_LIST, { elmId: Number(elmId) });

  const elmUserList: ElmUserPaginationModel<ElmUserModel[]> | null = useSelector(
    (state: StateType) => state.elm.elmUserList || null,
  );
  const elmRoleTypeList = useSelector((state: StateType) => state.utils.data.elmRoleTypes);
  const elmRoleTypes = elmRoleTypeList.filter(f => f.value);

  const isMaxUserUploaded = Boolean(
    elmUserList && elmUserList.elm_obj.count_elm_users.exists >= elmUserList.elm_obj.count_elm_users.allowed,
  );

  useEffect(() => {
    if (state.selectedUserIds !== selectedEntitiesIds) {
      setState({
        ...state,
        selectedUserIds: selectedEntitiesIds,
      });
    }
  }, [selectedEntitiesIds]);

  const { modals, availableUsers } = state;
  const elmClosed = Boolean(elmUserList?.elm_obj && elmUserList.elm_obj.status >= ElmStatuses.COMPLETED);
  const location = useLocation<any>();

  useEffect(() => {
    let newQuery: ElmState['query'] = { ...query };
    if (location.state) {
      if (location.state.org) {
        newQuery.search = location.state.org.name;
        newQuery.page = 1;
        dispatch(setQuery(newQuery));
      }
    }
    fetchData(newQuery);
  }, [location.key]);

  useEffect(() => {
    if (query.sorting !== state.sorting) {
      setState({
        ...state,
        sorting: query.sorting,
      });
    }
  }, [query.sorting]);

  useEffect(() => {
    fetchAvailableElmUsers();
  }, []);

  const fetchAvailableElmUsers = () => {
    dispatch(
      getAvailableElmUsers({
        elmId: Number(elmId),
        callback: data => {
          setState(prevState => ({ ...prevState, availableUsers: data }));
        },
      }),
    );
  };

  const onFileLoad = () => {
    fetchData(query);
  };

  const openModal = (modalName: string) => {
    setState({
      ...state,
      modals: {
        ...state.modals,
        [modalName]: true,
      },
    });
  };

  const closeModal = (modalName: string) => {
    setState(prevState => ({
      ...prevState,
      modals: {
        ...state.modals,
        [modalName]: false,
      },
    }));
  };

  const _isSelectedAll = () => {
    if (elmUserList) {
      if (!elmUserList.result.length) return false;
      return state.selectedUserIds.length === elmUserList.result.length;
    }
    return false;
  };

  const handleSelectUser = (user: ElmUserModel) => {
    let ids: number[] = [...state.selectedUserIds];
    if (ids.includes(user.elm_user_id)) {
      ids = ids.filter(id => user.elm_user_id !== id);
    } else {
      ids.push(user.elm_user_id);
    }
    setState({ ...state, selectedUserIds: ids });
  };

  const handleSorting = () => {
    if (state.sorting) {
      handleSort(state.sorting);
    }

    setState({
      ...state,
      modals: {},
    });
  };

  const handleChangeOrder = (value: string) => {
    setState({
      ...state,
      sorting: {
        columnKey: value,
        direction: 'ASC',
      },
    });
  };

  const handleChangeActionOption = (val: 'all' | 'elm', action: 'delete' | 'deactivate') => {
    const newState: IState = { ...state };
    if (action === 'delete') {
      newState.delete_option = val;
    }
    if (action === 'deactivate') {
      newState.deactivate_option = val;
    }

    setState(newState);
  };

  const handleChangeActionRole = (val: string) => {
    const newState: IState = { ...state };
    newState.role_option = Number(val);
    setState(newState);
  };

  const handleDeleteUser = () => {
    dispatch(
      deleteElmUser({
        data: {
          elmId: Number(elmId),
          elm_user_id: state.selectedUserIds,
          is_elm_org_team: state.delete_option === 'all',
        },
        callback: () => {
          fetchAvailableElmUsers();
          fetchData(query);
          setState({
            ...state,
            modals: {
              ...state.modals,
              [Modals.isDeleteUser]: false,
            },
            selectedUserIds: [],
          });
          setSelectedEntitiesIds([]);
        },
      }),
    );
  };

  const handleDeactivateUser = () => {
    // deactivateElmUser
    dispatch(
      deactivateElmUser({
        data: {
          is_active: false,
          elmId: Number(elmId),
          elm_user_ids: state.selectedUserIds,
          is_elm_org_team: state.deactivate_option === 'all',
        },
        callback: () => {
          fetchAvailableElmUsers();
          fetchData(query);
          setState({
            ...state,
            modals: {
              ...state.modals,
              [Modals.isDeactivateUser]: false,
            },
            selectedUserIds: [],
          });
          setSelectedEntitiesIds([]);
        },
      }),
    );
  };

  const handleActivateUser = () => {
    dispatch(
      deactivateElmUser({
        data: {
          is_active: true,
          elmId: Number(elmId),
          elm_user_ids: state.selectedUserIds,
          is_elm_org_team: false,
        },
        callback: () => {
          fetchAvailableElmUsers();
          fetchData(query);
          setState({
            ...state,
            modals: {
              ...state.modals,
              [Modals.isDeactivateUser]: false,
            },
            selectedUserIds: [],
          });
          setSelectedEntitiesIds([]);
        },
      }),
    );
  };

  const userDidAdd = () => {
    fetchAvailableElmUsers();
    handleChangePage(1);
    closeModal(Modals.isAddUser);
  };

  const handleChangeOrderDir = () => {
    let sorting: SortColumn = {
      columnKey: state.sorting?.columnKey || 'id',
      direction: state.sorting?.direction === 'ASC' ? 'DESC' : 'ASC',
    };

    if (!state.sorting) {
      const sorting: SortColumn = {
        columnKey: 'id',
        direction: 'DESC',
      };
      handleSort(sorting);
      return;
    }

    handleSort(sorting);

    setState({
      ...state,
      sorting: sorting,
    });
  };

  const _isDeactiveBtnDisabled = (is_active: boolean) => {
    if (elmUserList?.elm_obj && elmUserList) {
      const selectedUsers = state.selectedUserIds.map(id => {
        let user = elmUserList.result.find(user => user.elm_user_id === id);
        if (user) return user;
      });
      const has_deactivated = !!selectedUsers.find(user => user?.is_active === is_active);
      return !state.selectedUserIds.length || !has_deactivated;
    }
    return true;
  };

  const navigateToUserProfile = (user: ElmUserModel) => {
    history.push(ROOTS.USER_PROFILE.replace(':orgId', orgId).replace(':userId', String(user.org_user_id)));
  };

  const onRowClick = (row: ElmUserModel) => {
    navigateToUserProfile(row);
  };

  const { columns, onColumnResize } = useTableConfig<ElmUserModel>(
    TableDataEntities.ELM_USER_LIST,
    getColumns(),
  );

  const handleSetRole = () => {
    dispatch(
      setUserRole({
        elmId: Number(elmId),
        data: { elm_role_type: state.role_option, users_ids: selectedEntitiesIds },
        callback: () => {
          fetchData(query);
          closeModal(Modals.isSetRole);
          setSelectedEntitiesIds([]);
        },
      }),
    );
  };

  const renderContent = () => {
    if (!elmUserList) return null;

    if (isMobile) {
      return (
        <div className={`${styles.boxList} mt-3`}>
          {elmUserList.result.map(user => {
            const isChecked = !!state.selectedUserIds.find(f => f === user.elm_user_id);
            return (
              <div
                key={`user-${user.elm_user_id}`}
                className={`${styles.boxList_item} b-panel`}
                onClick={() => navigateToUserProfile(user)}
              >
                <label className={'checkbox ' + styles.checkbox} onClick={e => e.stopPropagation()}>
                  <input type="checkbox" checked={isChecked} onChange={() => handleSelectUser(user)} />
                  <span className="checkbox_fakeInput" />
                </label>
                <span className={styles.boxList_userName}>
                  {user.first_name} {user.last_name}
                </span>
                <ul>
                  <li>{user.user_title}</li>
                  <li>{user.user_division}</li>
                  <li>{user.status_user_elm}</li>
                </ul>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <DataGrid
          onRowClick={onRowClick}
          onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
          columns={columns}
          onColumnResize={onColumnResize}
          sortColumns={query.sorting ? [query.sorting] : []}
          rows={elmUserList?.result || []}
          className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
          style={{
            height: ((elmUserList?.result.length || 0) + 1) * 60,
          }}
          rowHeight={60}
          rowKeyGetter={(row: ElmUserModel) => {
            return row.elm_user_id;
          }}
          onSelectedRowsChange={handleSelectOne}
          selectedRows={new Set(state.selectedUserIds)}
        />
      </DndProvider>
    );
  };

  if (!elmUserList?.result) return <Loader />;

  return (
    <>
      <div className={'b-page'}>
        <div className="b-page__header">
          <HeadlineOrganization
            orgName={currentOrg?.name || ''}
            orgId={currentOrg?.id || ''}
            headline={t('common:headlines.equity-lens-map')}
            customClasses={'-noDesktopMb'}
          />
        </div>
        <h2 className="b-page__title2 -backIcon font-navy">
          <EmptyLink onClick={navBack} className="b-button-icon-back" />
          {t('common:headlines.user-list')}
        </h2>
        <div>
          <div className="b-page__controls">
            <div className="b-tableToolContainer">
              <button
                aria-label="Click to Filter"
                onClick={() => openModal(Modals.isFilter)}
                className={`
                    b-tableTool
                    -mr
                    -filter
                    -colorGrey
                    ${query.filters?.roles?.length || query.filters?.statuses?.length ? '-active' : ''}
                  `}
              >
                {t('common:btn.filter')}
              </button>
              <button
                aria-label="Click to Sort"
                onClick={() => openModal(Modals.isSorting)}
                type="button"
                className={`
                    b-tableTool -sort -colorGrey -mr
                    ${query.sorting && state.sorting?.columnKey !== 'id' && '-active'}
                  `}
              >
                {t('common:btn.sort')}
              </button>
              <button
                onClick={handleChangeOrderDir}
                type="button"
                className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                    ${state.sorting?.direction === 'DESC' && '-active'}
                  `}
              >
                {t('common:btn.a-z-order')}
              </button>
            </div>
            <div className="b-page__search">
              <form action="#" className="searchForm">
                <input
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={query.search}
                  onChange={e => handleSearch(e.target.value)}
                />
                <span className="searchForm__icon" />
              </form>
            </div>
          </div>
          <div className="b-page__controls -mobHorizontal">
            <div className="b-tableToolContainer">
              <button
                className="b-tableTool -userOn -mobView -mr"
                disabled={_isDeactiveBtnDisabled(false) || elmClosed}
                onClick={handleActivateUser}
              >
                {t('common:btn.activate')}
              </button>
              <button
                className="b-tableTool -userOff -mobView -mr"
                disabled={_isDeactiveBtnDisabled(true) || elmClosed}
                onClick={() => openModal(Modals.isDeactivateUser)}
              >
                {t('common:btn.deactivate')}
              </button>
              <button
                className="b-tableTool -elm-role -mobView -mr"
                disabled={!state.selectedUserIds.length || elmClosed}
                onClick={() => openModal(Modals.isSetRole)}
              >
                {t('common:btn.set-role')}
              </button>
              <button
                className="b-tableTool -delete -mobView -mr"
                disabled={!state.selectedUserIds.length || elmClosed}
                onClick={() => openModal(Modals.isDeleteUser)}
              >
                {t('common:btn.delete')}
              </button>
            </div>
            <div className="b-tableToolContainer">
              <SimpleTooltip
                title={
                  isMaxUserUploaded
                    ? t('awa:ER8.msg', { x: elmUserList.elm_obj.count_elm_users.allowed })
                    : ''
                }
                id={'isMaxUserUploaded'}
              >
                <button
                  className="b-tableTool -addPlus -mobView -mr"
                  onClick={() => openModal(Modals.isAddUser)}
                  disabled={elmClosed || isMaxUserUploaded}
                >
                  {t('common:btn.new-user')}
                </button>
              </SimpleTooltip>

              {elmUserList.elm_obj.status < ElmStatuses.COMPLETED ? (
                <SimpleTooltip
                  title={
                    isMaxUserUploaded
                      ? t('awa:ER8.msg', { x: elmUserList.elm_obj.count_elm_users.allowed })
                      : ''
                  }
                  id={'isMaxUserUploaded'}
                >
                  <ElmFileUploader
                    disabled={isMaxUserUploaded}
                    onFileLoad={onFileLoad}
                    import_errors={elmUserList.elm_obj.uploaded_file_data.import_errors}
                    value={elmUserList.elm_obj.uploaded_file_data.import_file}
                    elmId={Number(elmId)}
                  />
                </SimpleTooltip>
              ) : null}
              {isMobile ? (
                <label className="checkbox">
                  <input checked={_isSelectedAll()} type="checkbox" onChange={handleSelectAll} />
                  <span className="checkbox_fakeInput" />
                </label>
              ) : null}
            </div>
          </div>
          {elmUserList.elm_obj.status < ElmStatuses.COMPLETED ? (
            <div className="d-flex justify-content-end">
              <DownloadElmTemplate />
            </div>
          ) : null}
          {elmClosed ? <div className="font-primary80 font-s">{t('awa:M169.msg')}</div> : null}
          {renderContent()}
        </div>
        {!isMobile && (
          <Pagination
            data={elmUserList as PaginationModel}
            handleChangePageSize={handleChangePageSize}
            changePage={handleChangePage}
          />
        )}
      </div>
      {modals.isFilter ? <FilterModal onClose={() => closeModal(Modals.isFilter)} elmId={elmId} /> : null}
      {modals.isSorting ? (
        <Modal
          showCloseBtn={false}
          width={350}
          onClose={() => closeModal(Modals.isSorting)}
          className={!isMobile ? 'b-filtersModal__mobileModal' : ''}
          title={'Sort'}
          classNameTitle="b-filtersModal__modalTitle"
        >
          <div className="b-filtersModal">
            <div className="b-filtersModal__list">
              <div className="b-filtersModal__title">{t('common:filter.by')}</div>
              <ul>
                {orderValues().map(o => {
                  const isActive = state.sorting?.columnKey === o.value;
                  return (
                    <li key={o.value}>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          handleChangeOrder(o.value);
                        }}
                        className={isActive ? '-active' : ''}
                      >
                        {o.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="b-filtersModal__buttons">
            <Button
              onPress={handleSorting}
              title={t('common:filter.sort')}
              size={'large'}
              type={'orange-light'}
            />
          </div>
        </Modal>
      ) : null}
      {modals.isDeleteUser ? (
        <Modal
          showCloseBtn={false}
          width={350}
          onClose={() => closeModal(Modals.isDeleteUser)}
          className={!isMobile ? 'b-filtersModal__mobileModal' : ''}
        >
          <div className="b-modal">
            <h4 className="b-modal__title">{t('awa:N32.title')}</h4>
            <div className="b-modal__content b-elmUserActionModal">
              <RadioButton
                title={t('common:btn.elm-user-list-only')}
                value={'elm'}
                onChangeValue={e => {
                  handleChangeActionOption(e.target.value, 'delete');
                }}
                selectedValue={state.delete_option}
              />
              <p className="b-modal__text">{t('awa:N32.msg.p1')}</p>
              <RadioButton
                title={t('common:btn.elm-org-user-list')}
                value={'all'}
                selectedValue={state.delete_option}
                onChangeValue={e => {
                  handleChangeActionOption(e.target.value, 'delete');
                }}
              />
              <p className="b-modal__text">{t('awa:N32.msg.p1')}</p>
            </div>
            <div className="b-modal__buttons">
              <Button
                onPress={() => closeModal(Modals.isDeleteUser)}
                title={t('common:btn.cancel')}
                size={'large'}
                type={'transparency'}
              />
              <Button
                onPress={handleDeleteUser}
                title={t('common:btn.delete')}
                size={'large'}
                type={'orange-light'}
              />
            </div>
          </div>
        </Modal>
      ) : null}
      {modals.isDeactivateUser ? (
        <Modal
          showCloseBtn={false}
          width={350}
          onClose={() => closeModal(Modals.isDeactivateUser)}
          className={!isMobile ? 'b-filtersModal__mobileModal' : ''}
        >
          <div className="b-modal">
            <h4 className="b-modal__title">{t('awa:N31.title')}</h4>
            <div className="b-modal__content b-elmUserActionModal">
              <RadioButton
                title={t('common:btn.elm-user-list-only')}
                value={'elm'}
                onChangeValue={e => {
                  handleChangeActionOption(e.target.value, 'deactivate');
                }}
                selectedValue={state.deactivate_option}
              />
              <p className="b-modal__text">{t('awa:N31.msg.p1')}</p>
              <RadioButton
                title={t('common:btn.elm-org-user-list')}
                value={'all'}
                selectedValue={state.deactivate_option}
                onChangeValue={e => {
                  handleChangeActionOption(e.target.value, 'deactivate');
                }}
              />
              <p className="b-modal__text">{t('awa:N31.msg.p2')}</p>
            </div>
            <div className="b-modal__buttons">
              <Button
                onPress={() => closeModal(Modals.isDeactivateUser)}
                title={t('common:btn.cancel')}
                size={'large'}
                type={'transparency'}
              />
              <Button
                onPress={handleDeactivateUser}
                title={t('common:btn.deactivate')}
                size={'large'}
                type={'orange-light'}
              />
            </div>
          </div>
        </Modal>
      ) : null}
      {modals.isSetRole ? (
        <Modal
          showCloseBtn={false}
          width={350}
          onClose={() => closeModal(Modals.isSetRole)}
          className={!isMobile ? 'b-filtersModal__mobileModal' : ''}
        >
          <div className="b-modal">
            <h4 className="b-modal__title mb-4">{t('common:column.role-type')}</h4>
            <div className="b-modal__content b-elmUserActionModal pb-3">
              {elmRoleTypes.map(type => {
                return (
                  <RadioButton
                    key={`elm-role-${type.value}`}
                    title={type.label}
                    value={type.value}
                    onChangeValue={e => {
                      handleChangeActionRole(e.target.value);
                    }}
                    selectedValue={state.role_option}
                  />
                );
              })}
            </div>
            <div className="b-modal__buttons">
              <Button
                onPress={() => closeModal(Modals.isSetRole)}
                title={t('common:btn.cancel')}
                size={'large'}
                type={'transparency'}
              />
              <Button
                onPress={handleSetRole}
                title={t('common:btn.set-role')}
                size={'large'}
                type={'orange-light'}
              />
            </div>
          </div>
        </Modal>
      ) : null}
      {modals.isAddUser ? (
        <AddUser
          availableUsersCount={elmUserList.elm_obj.available_credit}
          availableUsers={availableUsers}
          onAddUser={userDidAdd}
          onClose={() => closeModal(Modals.isAddUser)}
        />
      ) : null}
    </>
  );
};

export default ElmUserList;
