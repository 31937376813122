import React from 'react';
import { Tooltip } from '../index';
import { ISelectOptions } from '../../../models';
import styles from './RadioSelectStyle.module.scss';

interface IProps {
  value: ISelectOptions | null;
  handleChange: (option: ISelectOptions) => void;
  values: ISelectOptions[];
  error?: string;
  hideDescription?: boolean;
}

const RadioSelect = ({ handleChange, value, values, hideDescription }: IProps) => {
  const onChange = (value: string | number) => {
    const findItem = values.find(v => v.value == value);
    if (findItem) {
      handleChange(findItem);
    }
  };

  const getTabIndex = (v: ISelectOptions, i: number) => {
    if (value) {
      if (value.value === v.value) {
        return 0;
      } else {
        return -1;
      }
    }
    if (!value) {
      if (i === 0) {
        return 0;
      } else {
        return -1;
      }
    }
  };

  return (
    <div className={styles.container} role="radiogroup" aria-labelledby="radiogroup">
      <div className={styles.hide} id={'radiogroup'}>
        radiogroup
      </div>
      {values.length &&
        values.map((v, i) => {
          return (
            <Tooltip
              key={`radio-select-${v.value}`}
              id={v.value}
              effect={'float'}
              hideDescription={hideDescription}
              title={
                v.description ? (
                  <div className={styles.tooltip}>
                    <span className={styles.tooltip__title}>{v.label}</span>
                    <span className={styles.tooltip__description}>{v.description}</span>
                  </div>
                ) : null
              }
            >
              <label aria-checked={false} role="radio" className={'content-left container-row button-radio'}>
                <input
                  onChange={() => onChange(v.value)}
                  type={'radio'}
                  tabIndex={getTabIndex(v, i)}
                  checked={(value && value.value) === v.value}
                  aria-label={`${value}`}
                />
                <span className={'text-long-description'}>{` ${v.label}`}</span>
              </label>
            </Tooltip>
          );
        })}
    </div>
  );
};

export default RadioSelect;
