import React, { FC, ReactNode, useRef, useState } from 'react';
import { PopupPortal } from '../index';

interface IProps {
  renderItem: (item: any) => ReactNode;
  data: any[];
}

interface PanelParams {
  left: number;
  top: number;
  width: number;
}

const TableHoverCell: FC<IProps> = ({ data, renderItem }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [panelParams, setPanelParams] = useState<PanelParams | null>(null);

  const handleHover = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setPanelParams({
        width: rect.width,
        top: rect.top - 1,
        left: rect.left,
      });
    }
  };

  const handleMouseLeave = () => {
    setPanelParams(null);
  };

  if (data.length <= 3) {
    return <div className="b-adminToolUsers__statusCell">{data.map(renderItem)}</div>;
  }

  return (
    <div
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleHover}
      ref={containerRef}
      className="position-relative b-adminToolUsers__statusCell"
    >
      {data.slice(0, 3).map(renderItem)}
      {panelParams ? (
        <PopupPortal portalElementId={'UNIC'}>
          <div
            className="position-absolute hoveredCell"
            style={{
              width: panelParams.width,
              top: panelParams.top,
              left: panelParams.left,
            }}
          >
            {data.map(renderItem)}
          </div>
        </PopupPortal>
      ) : null}
    </div>
  );
};

export default TableHoverCell;
