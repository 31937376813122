import React, { FC, useEffect, useState } from 'react';
import styles from './AddMember.module.scss';
import EditIc from '../../../assets/images/icons/round-image-edit.svg';
import AddIc from '../../../assets/images/icons/round-content-add.svg';
import { Modal } from '../index';
import { createPortal } from 'react-dom';
import { EAUser } from '../../../models';
import { Button, Checkbox } from '../../controls';
import { useTranslation } from 'react-i18next';

interface IProps {
  selectedUsers: number[];
  type: 'managers' | 'team';
  usersList: EAUser[];
  onSave: (data: number[]) => void;
}

const AddMember: FC<IProps> = ({ selectedUsers, type, usersList, onSave }) => {
  const { t } = useTranslation();

  let [modalOpened, setModalOpened] = useState(false);
  let [tempUsers, setTempUsers] = useState<number[]>([]);

  const mountContainer = document.body;
  let popupContainer = document.getElementById('add-member-popup-portal');

  if (!popupContainer) {
    popupContainer = document.createElement('div');
    popupContainer.id = 'add-member-popup-portal';
  }

  useEffect(() => {
    mountContainer?.appendChild(popupContainer as HTMLElement);
    return () => {
      mountContainer?.removeChild(popupContainer as HTMLElement);
    };
  }, [mountContainer, popupContainer]);

  const openModal = (e: any) => {
    e.preventDefault();
    setModalOpened(true);
    setTempUsers(selectedUsers);
  };

  const handleSelectUser = (userId: number) => {
    setTempUsers(prevState => {
      let newState = [...prevState];
      if (newState.includes(userId)) {
        newState = newState.filter(item => item !== userId);
      } else {
        newState.push(userId);
      }
      return newState;
    });
  };

  const handleSave = () => {
    onSave(tempUsers);
    setTempUsers([]);
    setModalOpened(false);
  };

  const renderModal = () => {
    const modal = (
      <Modal
        title={type === 'team' ? t('common:headlines.add-member') : t('common:headlines.add-managers')}
        onClose={() => setModalOpened(false)}
      >
        <div className={'b-EAModal'}>
          <div className={styles.title}>{t('common:headlines.organization-users')}</div>
          <div className={styles.list}>
            {usersList &&
              usersList.map((user: EAUser) => {
                return (
                  <div key={`user-${user.id}`} className={styles.item}>
                    <div className={'b-equityAudit__text16'}>{user.name}</div>
                    <Checkbox
                      noText
                      onChange={() => handleSelectUser(user.id)}
                      checked={tempUsers.includes(user.id)}
                    />
                  </div>
                );
              })}
          </div>
          <div className={styles.tip}>
            <div className={'b-equityAudit__text14'}>*{t('main:add-member.received')}.</div>
          </div>
          <div className="b-EAModal__buttons">
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={() => setModalOpened(false)}
              title={t('common:btn.cancel')}
            />
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={handleSave}
              title={t('common:btn.save')}
            />
          </div>
        </div>
      </Modal>
    );
    return createPortal(modal, popupContainer as HTMLElement);
  };

  return (
    <>
      <a href="#" className={styles.link} onClick={openModal}>
        {selectedUsers.length ? (
          <>
            <img style={{ paddingRight: 5 }} src={EditIc} alt={t('common:alt.editIc')} />
            {t('common:headlines.edit-managers')}
          </>
        ) : (
          <>
            <div className={styles.link__ic}>
              <img src={AddIc} alt={t('common:alt.addIc')} />
            </div>
            {t('common:headlines.add-a-new-member')}
          </>
        )}
      </a>
      {modalOpened && renderModal()}
    </>
  );
};

export default AddMember;
