import { FormatterProps, SortDirection, useRowSelection } from 'react-data-grid';
import { Checkbox } from '../../../../../controls';
import { ColumnType, ElmUserModel } from '../../../../../../models';
import React from 'react';
import { t } from 'i18next';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

export const getColumns = (): ColumnType<ElmUserModel>[] => [
  {
    key: 'first_name',
    name: t('common:column.first'),
    width: 130,
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'last_name',
    name: t('common:column.last'),
    width: 130,
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'user_division',
    name: t('common:column.div-dept-unit'),
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'user_title',
    name: t('common:column.title'),
    sortable: true,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'user_role_name',
    name: t('common:column.awa-role'),
    sortable: true,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'status_user_elm',
    name: t('common:column.status'),
    width: 200,
    sortable: true,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'elm_role_type_name',
    name: t('common:label.role-type'),
    width: 200,
    sortable: true,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'tools',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
  },
  {
    key: 'select',
    name: '',
    width: 90,
    maxWidth: 90,
    disableDrag: true,
    headerRenderer(props) {
      return (
        <Checkbox
          checked={props.allRowsSelected}
          onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
        />
      );
    },
    formatter: (props: FormatterProps<ElmUserModel>) => {
      const [isRowSelected, onRowSelectionChange] = useRowSelection();

      return (
        <div className={'checkBox'} onClick={e => e.stopPropagation()}>
          <Checkbox
            checked={isRowSelected}
            onChange={e => {
              onRowSelectionChange({
                row: props.row,
                checked: e.target.checked,
                isShiftClick: false,
              });
            }}
          />
        </div>
      );
    },
  },
];
