const getDomainUri = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return 'https://dev.awa.wearebeloved.org';
    case 'stage':
      return 'https://stage.awa.wearebeloved.org';
    case 'demo':
      return 'https://demo.my.awabybeloved.com';
    case 'stage2prod':
      return 'https://stage2prod.my.awabybeloved.com';
    case 'prod':
      return 'https://my.awabybeloved.com';
    default:
      return process.env.REACT_APP_DOMAIN_URI;
  }
};

const getDarkModeEnabled = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return true;
    case 'stage':
      return true;
    case 'demo':
      return true;
    case 'stage2prod':
      return true;
    case 'prod':
      return true;
    default:
      return false;
  }
};

const getStripePublicKey = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return 'pk_test_51K54btEmRDHrOAvibVez6jOyUE7UIu6pESYbZqbxdRQS2oP3ENaQwyTsYRTJbRim9GqYb0PV0pF9vQ24yg2POIf200jnvOJos6';
    case 'stage':
      return 'pk_test_51K54btEmRDHrOAvibVez6jOyUE7UIu6pESYbZqbxdRQS2oP3ENaQwyTsYRTJbRim9GqYb0PV0pF9vQ24yg2POIf200jnvOJos6';
    case 'demo':
      return 'pk_test_51K54btEmRDHrOAvibVez6jOyUE7UIu6pESYbZqbxdRQS2oP3ENaQwyTsYRTJbRim9GqYb0PV0pF9vQ24yg2POIf200jnvOJos6';
    case 'stage2prod':
      return 'pk_test_51K54btEmRDHrOAvibVez6jOyUE7UIu6pESYbZqbxdRQS2oP3ENaQwyTsYRTJbRim9GqYb0PV0pF9vQ24yg2POIf200jnvOJos6';
    case 'prod':
      return 'pk_live_51JpmRHGBJ73at00HbG3tTaYp0jlpxQFlLZnZNFuvPjg2loAm1zr5CQCHyS4Wzpbcs2r3OOJojESvWpviayGazjUb000ahN3cqX';
    default:
      return process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  }
};

export const SHOW_MAINTENANCE_PAGE = false;
export const DOMAIN_URI = getDomainUri();
export const API_URI = `${DOMAIN_URI}/api/v1/`;

enum GRANT_TYPES_KEYS {
  PASSWORD = 'password',
  REFRESH = 'refresh_token',
}

export const STRIPE_PUBLIC_KEY = getStripePublicKey();

export const OAUTH_CREDS = {
  CLIENT_ID: 'cU44KSpHXjbziiwsLavJXhEXuLDdX2Rkfim27CvS',
  CLIENT_SECRET:
    '6D5DcxzLwzgp4m6umidhAKdNztaGKyXnYXQXLzDVpKlY0byg3ON2NQOTsPmlWe29YJVZIrh6unZJyIlZcmuzdrIIGkSfZlzZNUExiziwDbMN63uyQig6IsSJydrKPdHE',
  GRANT_TYPES: GRANT_TYPES_KEYS,
};

export const DARK_MODE_ENABLED = getDarkModeEnabled();

export const MAP_GL_KEY = `pk.eyJ1IjoicmV2dnYiLCJhIjoiY2wxMjdraHM5MDh2ajNkcDRlcjdsZWgyciJ9.mikIXIBhyPP5D8GiRjaHVw`;
export const GEOCODE_GL_KEY = 'AIzaSyBFyVmL9QPi44uCHGuMKJ8B5NG0E42dvdQ'; // this key should use only for dev
export const GOOGLE_CONSOLE_KEY = 'AIzaSyAblW8hvo0Z8KBSfpa3VyWwZTNBAfKha7k';
// export const GEOCODE_GL_KEY = 'AIzaSyD3Nj1N2a9y5ZlVwmhZOejMwseNm9QKf3s' // this key need to be used on prod but first need to configure payments in the gl console account
