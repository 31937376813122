import React, { useEffect, useState } from 'react';
import { Button, Input } from '../../../../../controls';
import { email, isValidFields, validate } from '../../../../../../helpers';
import { CreateOrgModel, ISelectOptions, KeyValueModel } from '../../../../../../models';
import styles from './AddNewUserStyle.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../../store/reducers';
import UserRoleSelect from '../../../AddAffiliation/parts/UserRoleSelect/UserRoleSelect';

interface FormDataModel extends CreateOrgModel {
  first_name: '';
  last_name: '';
  email: '';
}

interface OrganizationModel {
  label: string;
  value: string | number;
  disabled?: boolean;
  parent_org_name?: string;
}

interface IProps {
  handleClosePopup: () => void;
  savedNewUser: (formData: FormDataModel) => void;
}

interface IState {
  formData: FormDataModel;
  errors: KeyValueModel<string> | null;
  userTypes: OrganizationModel[];
}

const AddNewUser = ({ handleClosePopup, savedNewUser }: IProps) => {
  const validator = {
    required: ['first_name', 'last_name', 'email', 'type_id'],
    custom: [email(['email'])],
  };
  const { t } = useTranslation();
  const [state, setState] = useState<IState>({
    errors: {},
    formData: {
      first_name: '',
      last_name: '',
      email: '',
      role_id: 8,
      type_id: 2,
    },
    userTypes: [],
  });
  const userTypesSelector = useSelector((state: StateType) => state.utils.data.userTypesRoles);
  const { errors, formData } = state;

  useEffect(() => {
    const userTypes =
      userTypesSelector?.map(r => {
        return { label: r.name, value: r.id };
      }) || [];
    setState(prevState => {
      return {
        ...prevState,
        userTypes: userTypes,
      };
    });
  }, [userTypesSelector]);

  const handleChangeInput = (name: string, value: string) => {
    if (errors) {
      delete errors[name];
    }
    setState({
      ...state,
      formData: {
        ...state.formData,
        [name]: value,
      },
      errors,
    });
  };

  const handleCreateUser = () => {
    let { errors } = validate(validator, state.formData);
    setState({
      ...state,
      errors: errors,
    });
    if (!Object.values(errors || {}).length) {
      savedNewUser(state.formData);
    }
  };

  const handleChangeSelect = (name: string, data: ISelectOptions) => {
    if (errors && errors[name]) {
      delete errors[name];
    }
    setState({
      ...state,
      formData: {
        ...state.formData,
        [name]: data.value,
      },
      errors: errors,
    });
  };

  const isDisableButton = isValidFields(validator, state.formData);

  return (
    <div className={`${styles.form} ${styles.mobileForm}`}>
      <Input
        name={'first_name'}
        error={(errors && errors.first_name) || ''}
        value={formData.first_name || ''}
        type={'text'}
        placeholder={t('common:label.first-name')}
        handleChange={(value: string) => handleChangeInput('first_name', value)}
        autoFocus
      />
      <Input
        name={'last_name'}
        error={(errors && errors.last_name) || ''}
        value={formData.last_name || ''}
        type={'text'}
        placeholder={t('common:label.last-name')}
        handleChange={(value: string) => handleChangeInput('last_name', value)}
      />
      <Input
        name={'email'}
        error={(errors && errors.email) || ''}
        value={formData.email || ''}
        type={'text'}
        placeholder={t('common:label.email-address')}
        handleChange={(value: string) => handleChangeInput('email', value)}
      />
      <UserRoleSelect
        handleChangeSelect={handleChangeSelect}
        formData={formData}
        errors={errors}
        constRoleId={8}
      />
      <div className={styles.buttons}>
        <Button
          onPress={handleClosePopup}
          title={t('common:btn.cancel')}
          size={'large'}
          type={'transparency'}
        />
        <Button
          onPress={handleCreateUser}
          title={t('common:btn.save')}
          size={'large'}
          disabled={isDisableButton}
          type={'orange-light'}
          enableEnter
        />
      </div>
    </div>
  );
};

export default AddNewUser;
