import { call, all, takeLatest, CallEffect, put, StrictEffect, select } from 'redux-saga/effects';
import {
  ASSOCIATION_ORGANIZATION,
  CREATE_ORGANIZATION,
  CREATE_SUB_ORGANIZATION,
  JOIN_ORGANIZATION,
  GET_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_USERS,
  getOrganizationDetailsSuccess,
  UPDATE_ORGANIZATION_DETAILS,
  updateOrganizationDetailsSuccess,
  getOrganizationUsersSuccess,
  CREATE_PROFILE_SUB_ORGANIZATION,
  createProfileSubOrganizationSuccess,
  CREATE_USER_ORG_AFFILIATION,
  GET_USER_DETAILS,
  GetUserDetailsPayload,
  USER_SWITCH_STATUS,
  USERS_INVITE,
  USERS_REMOVE,
  UserSwitchStatusModalPayload,
  UsersInvitePayload,
  UsersRemovePayload,
  GET_AVAILABLE_ORGS,
  GetAvailableOrgsPayload,
  ADD_NEW_USER,
  AddNewUserPayload,
  RESEND_JOIN_ORGANIZATION,
  ResendJoinOrganizationPayload,
  REJECT_JOIN_ORGANIZATION,
  RejectJoinOrganizationPayload,
  GetOrganizationDetailsPayload,
  UPDATE_USER_ORG_AFFILIATION,
  orgChanged,
  PATCH_ORGANIZATION_DETAILS,
} from './organizationActions';
import Api from '../../services/api';
import { JoinOrganizationModel, CreateOrganizationModel, ActionModel } from '../../models';
import { getUserSuccess } from '../profile/profileActions';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { StateType } from '../reducers';
import { showAlert } from '../../components/common/Alert/RemoteAlert';
import { getOrgIdFormRoute } from '../../helpers';

function* createOrganizationSaga({ payload }: any): Generator<any> {
  try {
    const response: any = yield call(Api.post, 'entry/organizations/', payload.data);
    if (response) {
      payload.callback(response);
      yield put(getUserSuccess(response));
    }
  } catch (e) {
    payload.callback({ error: e });
  }
}

function* joinOrganizationSaga({ payload }: any): Generator<CallEffect<JoinOrganizationModel>> {
  try {
    const response = yield call(Api.post, 'entry/organizations/join/', payload.data);

    if (response) {
      payload.callback(response);
    }
  } catch (e: any) {
    payload.callback(null, e);
  }
}

function* createSubOrganizationSaga({ payload }: any): Generator<CallEffect<CreateOrganizationModel>> {
  try {
    const response = yield call(Api.patch, `entry/organizations/${payload.data.id}/`, {}, payload.data);
    if (response) {
      payload.callback(response);
    }
  } catch (e) {
    payload.callback({ error: e });
  }
}

function* associationOrganizationSaga({ payload }: any): Generator<CallEffect<CreateOrganizationModel>> {
  try {
    const response = yield call(Api.post, `entry/organizations/associate/`, payload.data);
    if (response) {
      payload.callback(response);
    }
  } catch (e) {
    payload.callback({ error: e });
  }
}

function* getOrganizationDetailsSaga({
  payload,
}: ActionModel<typeof GET_ORGANIZATION_DETAILS, GetOrganizationDetailsPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  try {
    const response = yield call(Api.get, `organizations/profile/${payload.orgId}/`);
    const currentOrg = yield select((state: StateType) => state.organization.currentOrg);
    if (response) {
      payload.callback && payload.callback(response, undefined);
      yield put(getOrganizationDetailsSuccess(response));
      if (currentOrg && currentOrg.id !== response.id) {
        yield put(orgChanged());
      }
    }
  } catch (e: any) {
    if (payload.callback) {
      payload.callback(undefined, e);
    }
    if (e.response.status === 404) {
      toast.error(t('common:toast.error'));
      return;
    }
    toast.error(e.response.data?.detail || 'Oops... something went wrong');
  }
}

function* updateOrganizationDetailsSaga({ payload }: any): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(Api.put, `organizations/profile/${payload.orgId}/`, payload.data);

    if (response) {
      payload.callback(response);
      yield put(updateOrganizationDetailsSuccess(response));
    }
  } catch (e) {
    toast.error(
      'There was an issue saving the profile. Please, try again. If you continue experiencing this issue please contact support.',
    );
  }
}

function* patchOrganizationDetailsSaga({ payload }: any): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(
      Api.patch,
      `organizations/profile/${payload.orgId}/`,
      undefined,
      payload.data,
    );

    if (response) {
      payload.callback(response);
      yield put(updateOrganizationDetailsSuccess(response));
    }
  } catch (e) {
    toast.error(
      'There was an issue saving the profile. Please, try again. If you continue experiencing this issue please contact support.',
    );
  }
}

function* getOrganizationUsersSaga({ payload }: any): Generator<StrictEffect, void, any> {
  try {
    const orgId = getOrgIdFormRoute();
    const response = yield call(Api.get, `organizations/${orgId}/users/`, payload.params);

    if (response) {
      yield put(getOrganizationUsersSuccess(response));
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* createProfileSubOrganizationSaga({ payload }: any): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(Api.post, `organizations/${payload.orgId}/sub_org/`, payload.data);
    if (response) {
      payload.callback && payload.callback(response);
      yield put(createProfileSubOrganizationSuccess(response));
    }
  } catch (e: any) {
    if (e.response) {
      payload.callback && payload.callback(null, e.response.data);
    }
    toast.error(t('common:toast.error'));
  }
}
// createUserOrgAffiliation
function* createUserOrgAffiliationSaga({ payload }: any): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(
      Api.post,
      `organizations/${payload.organization_id}/users/org_affiliation/`,
      payload.data,
    );
    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e) {
    payload.callback && payload.callback(null, e);
    toast.error(t('common:toast.error'));
  }
}

function* updateUserOrgAffiliationSaga({ payload }: any): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(
      Api.patch,
      `organizations/${payload.organization_id}/users/${payload.affiliation_id}/`,
      undefined,
      payload.data,
    );
    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e) {
    payload.callback && payload.callback(null, e);
    toast.error(t('common:toast.error'));
  }
}

function* getProfileDetailsSaga({
  payload,
}: ActionModel<typeof GET_USER_DETAILS, GetUserDetailsPayload>): Generator<StrictEffect, void, any> {
  try {
    const { data } = payload;
    const response = yield call(Api.get, `organizations/${data.organization_id}/users/${data.id}/details/`);

    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e: any) {
    if (e.response.status === 400) {
      if (payload.callback) {
        payload.callback(undefined, true);
      }
    } else {
      toast.error(t('common:toast.error'));
    }
  }
}

function* getAvailableOrgsSaga({
  payload,
}: ActionModel<typeof GET_AVAILABLE_ORGS, GetAvailableOrgsPayload>): Generator<StrictEffect, void, any> {
  try {
    const { data } = payload;
    const response = yield call(
      Api.get,
      `organizations/${data.organization_id}/users/available_orgs_to_affiliate/${data.id}/`,
    );

    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* userSwitchStatusSaga({
  payload,
}: ActionModel<typeof USER_SWITCH_STATUS, UserSwitchStatusModalPayload>): Generator<StrictEffect, void, any> {
  try {
    const { data } = payload;
    const response = yield call(Api.post, `organizations/${payload.organization_id}/users/deactivate/`, data);

    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* usersInviteSaga({
  payload,
}: ActionModel<typeof USERS_INVITE, UsersInvitePayload>): Generator<StrictEffect, void, any> {
  try {
    const { data } = payload;
    const response = yield call(Api.post, `organizations/${payload.organization_id}/users/invite/`, data);

    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* usersRemoveSaga({
  payload,
}: ActionModel<typeof USERS_REMOVE, UsersRemovePayload>): Generator<StrictEffect, void, any> {
  try {
    const { data } = payload;
    const response = yield call(Api.post, `organizations/${payload.organization_id}/users/remove/`, data);

    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* addNewUserSaga({
  payload,
}: ActionModel<typeof ADD_NEW_USER, AddNewUserPayload>): Generator<StrictEffect, void, any> {
  try {
    const { data } = payload;
    const response = yield call(Api.post, `organizations/${payload.organization_id}/users/`, data);

    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e: any) {
    payload.callback && payload.callback(null, e);
    if (e.response.status === 400 && e.response.data.user_already_exists) {
      showAlert({
        title: t('awa:N47.title'),
        text: t('awa:N47.msg'),
        buttons: {
          right: {
            title: t('common:btn.ok'),
          },
        },
      });
    }
  }
}

function* resendJoinOrganizationSaga({
  payload,
}: ActionModel<typeof RESEND_JOIN_ORGANIZATION, ResendJoinOrganizationPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  try {
    const response = yield call(Api.post, `entry/organizations/join/`, payload.data);

    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

function* rejectJoinOrganizationSaga({
  payload,
}: ActionModel<typeof REJECT_JOIN_ORGANIZATION, RejectJoinOrganizationPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  try {
    const response = yield call(Api.post, `entry/organizations/join_decline/`, {});

    if (response) {
      payload.callback && payload.callback(response);
    }
  } catch (e) {
    toast.error(t('common:toast.error'));
  }
}

export function* saga() {
  yield all([takeLatest(CREATE_ORGANIZATION, createOrganizationSaga)]);
  yield all([takeLatest(JOIN_ORGANIZATION, joinOrganizationSaga)]);
  yield all([takeLatest(CREATE_SUB_ORGANIZATION, createSubOrganizationSaga)]);
  yield all([takeLatest(ASSOCIATION_ORGANIZATION, associationOrganizationSaga)]);
  yield all([takeLatest(GET_ORGANIZATION_DETAILS, getOrganizationDetailsSaga)]);
  yield all([takeLatest(UPDATE_ORGANIZATION_DETAILS, updateOrganizationDetailsSaga)]);
  yield all([takeLatest(PATCH_ORGANIZATION_DETAILS, patchOrganizationDetailsSaga)]);
  yield all([takeLatest(GET_ORGANIZATION_USERS, getOrganizationUsersSaga)]);
  yield all([takeLatest(CREATE_PROFILE_SUB_ORGANIZATION, createProfileSubOrganizationSaga)]);
  yield all([takeLatest(CREATE_USER_ORG_AFFILIATION, createUserOrgAffiliationSaga)]);
  yield all([takeLatest(UPDATE_USER_ORG_AFFILIATION, updateUserOrgAffiliationSaga)]);
  yield all([takeLatest(USER_SWITCH_STATUS, userSwitchStatusSaga)]);
  yield all([takeLatest(USERS_INVITE, usersInviteSaga)]);
  yield all([takeLatest(USERS_REMOVE, usersRemoveSaga)]);
  yield all([takeLatest(GET_USER_DETAILS, getProfileDetailsSaga)]);
  yield all([takeLatest(ADD_NEW_USER, addNewUserSaga)]);
  yield all([takeLatest(RESEND_JOIN_ORGANIZATION, resendJoinOrganizationSaga)]);
  yield all([takeLatest(REJECT_JOIN_ORGANIZATION, rejectJoinOrganizationSaga)]);
  yield all([takeLatest(GET_AVAILABLE_ORGS, getAvailableOrgsSaga)]);
}
