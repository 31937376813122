import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ROOTS } from '../../../../../routes/routes';
import { useHistory, useLocation } from 'react-router';
import { getColumns } from '../parts/columns';
import { Link, Loader } from '../../../../controls';
import { Modals, ModalsModel } from '../../../../../constants';
import useTableData, { QueryParamsModel } from '../../../../../hooks/common/use-table-data';
import { BelResourceOptionModel, BelResourceShortModel } from '../../../../../models';
import { ResourceCard } from '../../../../common';
import { isMobileOnly } from 'react-device-detect';
import { isEmptyObject } from '../../../../../helpers';
import FilterModal from '../parts/FilterModal';
import { useParams } from 'react-router-dom';
import {
  BelGetResourceListPayload,
  setResourcesQuery,
} from '../../../../../store/beloved/resources/resourcesActions';
import { useDispatch, useSelector } from 'react-redux';
import SortModalButton from '../../../../common/SortModalButton/SortModalButton';
import { getPageOptions } from '../../../../../helpers/resourceHelper';
import { StateType } from '../../../../../store/reducers';
import { getAccessByGlobal } from '../../../../../helpers/roles';

interface IState {
  modals: ModalsModel;
  isLoading: boolean;
}

export interface IRouteParams {
  action: 'general' | 'favorite' | 'recommend';
}

const ResourceListCards: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const isBeloved = location.pathname.match('beloved');
  const { action } = useParams<IRouteParams>();
  const dispatch = useDispatch();

  const pageOptions = getPageOptions(action, isBeloved);

  const {
    data,
    query,
    fetchData,
    handleSearch,
    handleChangeDirection,
    handleSort,
    sortingData,
    setSortingData,
    fetchMoreMobile,
    handleChangeSortField,
    infiniteList,
  } = useTableData<BelGetResourceListPayload['extraData']>(pageOptions.entity);
  const user = useSelector((state: StateType) => state.profile.authUser);
  const isAccess = !!getAccessByGlobal('project_manager');

  const initialState: IState = {
    modals: {},
    isLoading: false,
  };

  let [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (!user?.user.show_res_lib) {
      return;
    }
    setState({
      ...initialState,
      isLoading: true,
    });
    const newQuery: QueryParamsModel = {
      sorting: null,
      search: history.location.state?.search || '',
      page_size: 15,
      page: 1,
      filters: {},
    };
    dispatch(setResourcesQuery(newQuery));
    setSortingData(null);

    fetchData(newQuery, { url: pageOptions.url });
  }, [user?.user.show_res_lib, action, history.location.state]);

  useEffect(() => {
    if (data) {
      setState(prevState => ({ ...prevState, isLoading: false }));
    }
  }, [data?.result]);

  const manipulateModal = (modalName: string, open: boolean) => {
    setState(prevState => ({
      ...prevState,
      modals: {
        ...state.modals,
        [modalName]: open,
      },
    }));
  };

  const navToTable = () => {
    history.push(ROOTS.BELOVED_RESOURCE_LIST_TABLE.replace(':action', `${action}`));
  };

  const columns = getColumns({});

  const getEmptyText = () => {
    if (action === 'favorite') {
      return t('main:resources.no-favorite-resources');
    } else if (action === 'recommend') {
      return t('main:resources.no-recommended-resources');
    }
    return t('awa:M121.msg');
  };

  const getNoResLibText = () => {
    if (user && isAccess) {
      return (
        <>
          <p>{t('awa:M235.msg.p1')}</p>
          <br />
          <p>{t('awa:M235.msg.p2', { x: user.user.res_lid_ea_days })}</p>
          <br />
          <p>{t('awa:M235.msg.p3')}</p>
        </>
      );
    } else {
      return (
        <>
          <p>{t('awa:M236.msg.p1')}</p>
          <br />
          <p>{t('awa:M236.msg.p2')}</p>
          <br />
          <p>{t('awa:M236.msg.p3')}</p>
        </>
      );
    }
  };

  const getNoAccessText = () => {
    if (isAccess) {
      return (
        <>
          <p>{t('awa:M237.msg')}</p>
        </>
      );
    } else {
      return (
        <>
          <p>{t('awa:M238.msg')}</p>
        </>
      );
    }
  };

  const handleTag = (tag: BelResourceOptionModel) => {
    handleSearch(`#${tag.title}`);
  };

  const renderContent = () => {
    if (state.isLoading) return <Loader fitParent />;
    if (!user?.user.show_res_lib) {
      if (user?.user.has_res_access) {
        return <div className="b-no-content">{getNoAccessText()}</div>;
      }
      return <div className="b-no-content">{getNoResLibText()}</div>;
    }

    if (!data?.result?.length) {
      return <div className="b-no-content">{getEmptyText()}</div>;
    }

    return (
      <>
        <div className="b-resourceListItems__wrapper">
          <InfiniteScroll
            dataLength={infiniteList.length}
            next={fetchMoreMobile}
            hasMore={!(data.current_page === data.total_pages)}
            loader={<h4 className="b-no-content">{t('common:label.loading')}...</h4>}
          >
            <div className="b-resourceListItems">
              {infiniteList.map((item: BelResourceShortModel) => {
                return <ResourceCard data={item} key={`card-${item.id}`} handleTag={handleTag} />;
              })}
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  };

  const getSubTitleName = () => {
    if (action === 'general') {
      return '';
    } else if (action === 'favorite') {
      return t('common:tabs.my-favorites');
    } else if (action === 'recommend') {
      return t('common:tabs.beloved-recommended');
    }
  };

  const subTitleName = getSubTitleName();

  return (
    <>
      <main className={'b-page'}>
        <h1 className={'b-page__title'}>
          {t('common:tabs.resource-library')}
          {subTitleName ? ':' : ''}
          <span className="mark">{subTitleName}</span>
        </h1>
        {isBeloved && !isMobileOnly ? (
          <div className="b-tabs">
            <Link className="b-tabs__item" onClick={navToTable}>
              <div className="b-tabs__icon b-tabs__icon_table" />
              <span className="b-tabs__text">{t('common:tabs.table')}</span>
            </Link>
            <Link className="b-tabs__item b-tabs__item_active">
              <div className="b-tabs__icon b-tabs__icon_card" />
              <span className="b-tabs__text">{t('common:tabs.cards')}</span>
            </Link>
          </div>
        ) : null}
        <div className="b-page__controls">
          <div className="b-tableToolContainer">
            <button
              aria-label="Click to Filter"
              onClick={() => manipulateModal(Modals.isFilter, true)}
              className={`
                    b-tableTool
                    -mr
                    -filter
                    -colorGrey
                    ${!isEmptyObject(query.filters) ? '-active' : ''}
                  `}
              disabled={!user?.user.show_res_lib}
            >
              {t('common:btn.filter')}
            </button>
            <SortModalButton
              sortingData={sortingData}
              setSortingData={setSortingData}
              columns={columns}
              query={query}
              handleChangeSortField={handleChangeSortField}
              handleSort={handleSort}
              disabled={!user?.user.show_res_lib}
            />
            <button
              onClick={handleChangeDirection}
              type="button"
              className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                    ${query.sorting?.direction === 'DESC' && '-active'}
                  `}
              disabled={!user?.user.show_res_lib}
            >
              {t('common:btn.a-z-order')}
            </button>
          </div>
          <div className="b-page__search">
            <form action="#" className="searchForm">
              <input
                type="search"
                value={query.search}
                onChange={e => handleSearch(e.target.value)}
                placeholder={t('common:label.search-resource')}
                aria-label="Search field"
                disabled={!user?.user.show_res_lib}
              />
              <span className="searchForm__icon" />
            </form>
          </div>
        </div>
        {renderContent()}
      </main>
      {state.modals.isFilter ? <FilterModal onClose={() => manipulateModal(Modals.isFilter, false)} /> : null}
    </>
  );
};

export default ResourceListCards;
