import React, { FC, useEffect, useState } from 'react';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import { isMobileOnly } from 'react-device-detect';
import { Button } from '../../../../../controls';
import { Question, QuestionOption } from '../../../../../../models';
import { Alert } from '../../../../../common';
import { useTranslation } from 'react-i18next';

interface IProps {
  question: Question;
  groupQuestions: Question[];
  handleSaveAnswer: (answerId: string | null, isNext: boolean) => void;
  handleBack: () => void;
  deleteGroup: () => void;
}

const Quiz: FC<IProps> = props => {
  const { t } = useTranslation();
  const { question, handleSaveAnswer, handleBack, groupQuestions, deleteGroup } = props;

  let [answerId, setAnswerId] = useState<string | null>(question.answer);
  let [showExpl, setShowExpl] = useState<boolean>(false);
  let [showDeleteInfo, setShowDeleteInfo] = useState<boolean>(false);
  let [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  useEffect(() => {
    setAnswerId(question.answer);
  }, [question]);

  const keyPressListener = (event: any) => {
    const keyName = event.key;
    const i = keyName - 1;
    if (keyName === 'Enter') {
      handleNext();
    } else if (!isNaN(i)) {
      if (question.options[i]) {
        handleAnswer(question.options[i].id);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', keyPressListener);
    return () => {
      document.removeEventListener('keypress', keyPressListener);
    };
  }, [keyPressListener]);

  const handleAnswer = (aId: string) => {
    setAnswerId(aId === answerId ? null : aId);
  };

  const handleNext = () => {
    handleSaveAnswer(answerId, true);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    handleSaveAnswer(answerId, false);
  };

  const confirmDeleteGroup = () => {
    setShowDeleteConfirm(false);
    deleteGroup();
  };

  const handleClickDeleteGroup = (e: any) => {
    e.preventDefault();
    setShowDeleteConfirm(true);
  };

  return (
    <>
      {!isMobileOnly && (
        <a href="#" className="b-EACreate__saveBtn b-EATaking__saveExit" onClick={handleSave}>
          <img src={SaveIc} alt={t('common:alt.saveIc')} />
          {t('common:btn.save-exit')}
        </a>
      )}
      <div className="b-EATaking__box">
        <div className="b-EATaking__questions">
          <div className="b-EATaking__counter">
            <span>{groupQuestions.findIndex(q => q.id === question.id) + 1}</span>/
            <span>{groupQuestions.length}</span>
          </div>
          <p>
            {question.question_text}
            {question.explanation && (
              <button
                className="b-EATaking__infoLink"
                aria-label="Click to see details"
                onClick={() => setShowExpl(true)}
              />
            )}
          </p>
          <ul className="b-EATaking__questionsList">
            {question.options.map((opt: QuestionOption) => {
              return (
                <li key={`ea-opt-${opt.id}`} className={`${opt.id === answerId ? 'active' : ''}`}>
                  <button
                    tabIndex={-1}
                    onClick={e => {
                      e.preventDefault();
                      handleAnswer(opt.id);
                    }}
                  >
                    {opt.text}
                  </button>
                </li>
              );
            })}
          </ul>
          <div className="b-EATaking__linkRow">
            <a href="#" onClick={handleClickDeleteGroup} className="b-EATaking__link">
              {t('common:btn.delete-entire-section')}
            </a>
            <button
              className="b-EATaking__infoLink"
              onClick={() => setShowDeleteInfo(true)}
              aria-label="Click to see details"
            />
          </div>

          {!isMobileOnly && (
            <div className="b-EATaking__buttons">
              <Button
                tabIndex={-1}
                className={'btn -type_form'}
                type={'transparency'}
                onPress={handleBack}
                title={t('common:btn.back')}
              />
              <Button
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleNext}
                title={t('common:btn.next')}
              />
            </div>
          )}
        </div>
      </div>
      {isMobileOnly && (
        <React.Fragment>
          <div className="b-EATaking__buttons">
            <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSave}>
              <img src={SaveIc} alt={t('common:alt.saveIc')} />
              {t('common:btn.save-exit')}
            </a>
            <Button
              className={'btn'}
              type={'orange-light'}
              onPress={handleNext}
              title={t('common:btn.next')}
              enableEnter
            />
          </div>

          <Button
            className={'btn full-width'}
            type={'transparency'}
            onPress={handleBack}
            title={t('common:btn.back')}
          />
        </React.Fragment>
      )}
      {showExpl ? (
        <Alert isOpen={showExpl} onRequestClose={() => setShowExpl(false)} text={question.explanation} />
      ) : null}
      {showDeleteInfo ? (
        <Alert
          isOpen={showDeleteInfo}
          onRequestClose={() => setShowDeleteInfo(false)}
          title={t('awa:M82.title')}
          text={t('awa:M82.msg')}
        />
      ) : null}
      {showDeleteConfirm ? (
        <Alert
          isOpen={showDeleteConfirm}
          onRequestClose={() => setShowDeleteConfirm(false)}
          title={t('awa:M80.title')}
          text={
            <>
              <p>{t('awa:M80.msg.p1')}</p>
              <p>{t('awa:M80.msg.p2')}</p>
            </>
          }
          buttons={{
            left: {
              title: t('common:btn.no'),
              type: 'transparency',
              onClick: () => setShowDeleteConfirm(false),
            },
            right: {
              title: t('common:btn.yes'),
              type: 'orange-light',
              onClick: confirmDeleteGroup,
            },
          }}
        />
      ) : null}
    </>
  );
};

export default Quiz;
