import * as React from 'react';
import './CheckBox.scss';
import { FC } from 'react';
import { generateHash } from '../../../helpers';

interface IProps {
  size?: string;
  color?: string;
  error?: string;
  checked: boolean;
  disabled?: boolean;
  children?: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  noText?: boolean;
  className?: string;
  id?: string;
}

const Checkbox: FC<IProps> = props => {
  const { checked, children, onChange, error, size, disabled = false, noText, className, id } = props;

  return (
    <div className={`check-box-body ${noText ? '' : 'check-box-container'} ${className || ''}`}>
      <div className={`check-box-wrap`}>
        <input
          tabIndex={disabled ? -1 : 0}
          className={`${size || ''} ${error || ''}`}
          checked={checked}
          disabled={disabled}
          type="checkbox"
          onChange={onChange}
          aria-label={'check-box'}
          id={id}
        />
        {children && <label htmlFor={id}>{noText ? null : children}</label>}
      </div>
    </div>
  );
};

Checkbox.defaultProps = {
  id: generateHash(),
};

export default Checkbox;
