import React, { FC } from 'react';
import { EwpElementApprovalStatuses, EwpStatuses } from '../../../../../models';
import { useTranslation } from 'react-i18next';

interface IProps {
  status: EwpStatuses | EwpElementApprovalStatuses;
}

const EwpStatusLabel: FC<IProps> = ({ status }) => {
  const { t } = useTranslation();
  switch (status) {
    case EwpStatuses.IN_PREP:
      return <div className="b-status -inProgress">{t('common:status.editing')}</div>;
    case EwpStatuses.SUBMITTED_FOR_REVIEW:
      return <div className="b-status -color1">{t('common:label.Submitted-for-Review')}</div>;
    case EwpStatuses.IN_REVIEW:
      return <div className="b-status -color2">{t('common:status.in-review')}</div>;
    case EwpStatuses.REVIEW_COMPLETED:
      return <div className="b-status -color3">{t('common:label.Review-Completed')}</div>;
    case EwpStatuses.APPROVED:
      return <div className="b-status -completed">{t('common:label.approved')}</div>;
    case EwpStatuses.IN_PROGRESS:
      return <div className="b-status -inProgress">{t('common:status.in-progress')}</div>;
    case EwpStatuses.COMPLETED:
      return <div className="b-status -completed">{t('common:status.completed')}</div>;
    default:
      return null;
  }
};

export default EwpStatusLabel;
