import React, { FC } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { basicDateFormat, getZoneAbbr } from '../../../helpers';
import { ROOTS } from '../../../routes/routes';
import { BelSessionModel, IRouteParamsBase } from '../../../models';
import MeetingLink from '../../../assets/images/icons/icons-meeting-link-enabled.svg';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface IProps {
  data: BelSessionModel;
}

const CohortSessionItem: FC<IProps> = ({ data }) => {
  const location = useLocation();
  const isBeloved = location.pathname.match('beloved');
  const { id, orgId } = useParams<IRouteParamsBase>(); // id = cohort id
  const { t } = useTranslation();

  const url = isBeloved
    ? ROOTS.BELOVED_COHORT_SESSION_DETAILS.replace(':id', `${id}`).replace(':sessionId', `${data.id}`)
    : ROOTS.COHORT_SESSION_DETAILS.replace(':orgId', orgId)
        .replace(':id', `${id}`)
        .replace(':sessionId', `${data.id}`);

  const isOld = dayjs(data.start_date).isBefore(dayjs().add(-1, 'd'));

  return (
    <Link to={url} className={`b-panel b-carouselCommonItem ${isOld ? '-old' : ''}`}>
      <div className="b-carouselCommonItem__header -session">
        <span className="b-carouselCommonItem__user">{data.title}</span>
      </div>
      {data.start_date ? (
        <span className="b-carouselCommonItem__date -session">
          {basicDateFormat(data.start_date)} -&nbsp;
          {dayjs(data.start_date || '')
            .format('LT')
            .split(' ')
            .join('')
            .toLowerCase()}
          -
          {dayjs(data.end_date || '')
            .format('LT')
            .split(' ')
            .join('')
            .toLowerCase()}{' '}
          {getZoneAbbr(data.start_date || '')}
        </span>
      ) : null}
      <div className="b-newsBlockItem__text -md">{data.description || ''}</div>
      <div onClick={e => e.stopPropagation()} className={'b-newsBlockItem__link'}>
        {data.meeting_link ? (
          <a href={data.meeting_link}>
            <img className={'b-cohortSession__img'} src={MeetingLink} alt={t('common:alt.meeting-link')} />
          </a>
        ) : null}
      </div>
    </Link>
  );
};

export default CohortSessionItem;
