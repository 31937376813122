import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseGaModel } from '../../../models';
import { Tooltip } from '../../controls';
const percent = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

interface IProps {
  data: BaseGaModel[];
}

const LineChart: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  const renderLines = (orgs: BaseGaModel[]) => {
    return orgs.map(org => {
      if (!org.standards_score_report) {
        return;
      }
      const reports = Object.values(org.standards_score_report);
      if (!reports.length) {
        return (
          <div key={`org-${org.id}`} className={'b-customChart__line'}>
            <span className={`b-customChart__empty`}>
              No Equity Audits were completed in the last 12 months
            </span>
          </div>
        );
      }
      let scores = reports.map(r => (typeof r.score === 'string' ? 0 : r.score));
      const minValue = Math.min(...scores);
      const maxValue = Math.max(...scores);

      return (
        <div key={`line-org-lines-${org.id}`} className={'b-customChart__line'}>
          {reports.map(report => {
            const className = `${report.name}`;
            return (
              <Tooltip
                id={`dot-${org.id}-${report.name}`}
                key={`dot-${org.id}-${report.name}`}
                className={`b-customChart__dot ${className}`}
                place={'top'}
                style={{
                  left: `${report.score}%`,
                }}
                title={
                  <span className={'b-customChart__tooltip'}>
                    {report.name}: {typeof report.score === 'string' ? report.score : `${report.score}%`}
                  </span>
                }
              >
                <span />
              </Tooltip>
            );
          })}
          <span
            style={{
              width: `${maxValue - minValue}%`,
              left: `${minValue}%`,
            }}
            className={'b-customChart__band'}
          />
        </div>
      );
    });
  };

  return (
    <div className={'b-customChart__graph'}>
      <div className={'b-customChart__percentage'}>
        {percent.map((per, index) => {
          if (index === 0) {
            return (
              <span key={`per-number-${per}`} style={{ left: `calc(${index * 10}%)` }}>
                {t('common:column.n-a')}
              </span>
            );
          }
          return (
            <span key={`per-number-${per}`} style={{ left: `calc(${index * 10}%)` }}>
              {per}%
            </span>
          );
        })}
      </div>
      <div className={'b-customChart__lines -heightAuto'}>{renderLines(data)}</div>
    </div>
  );
};

export default LineChart;
