import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import { belGetOrganizationDetails } from '../../../../store/beloved/organizations/organizationsActions';
import { useParams } from 'react-router';
import { BelovedUserShortModel, IRouteParamsBase, ISelectOptions } from '../../../../models';
import { BelovedOrgTabs, BelovedUserCard, HeadlineOrganization } from '../../../common';
import { useInfiniteList } from '../../../../hooks/common/use-infinite-list';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Loader } from '../../../controls';
import { useTranslation } from 'react-i18next';
import Api from '../../../../services/api';
import useHasAccess from '../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../constants';
import BelovedTeamAssignmentModal from '../../../common/BelovedTeamAssignmentModal/BelovedTeamAssignmentModal';
import { showAlert } from '../../../common/Alert/RemoteAlert';

interface ModalModel {
  isEdit: boolean;
}

const BelovedOrgUserList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentBelOrg = useSelector((state: StateType) => state.belOrganizations.currentBelOrg);
  const { orgId } = useParams<IRouteParamsBase>();
  const [availableTeam, setAvailableTeam] = useState<ISelectOptions[] | null>(null);
  const { fetchMoreData, fetchData, data } = useInfiniteList<BelovedUserShortModel>(
    `beloved/organizations/${orgId}/get_beloved_team/`,
  );
  let [showModal, setShowModal] = useState<ModalModel | null>(null);
  let [selectedTeam, setSelectedTeam] = useState<BelovedUserShortModel | null>(null);
  const canAssignedBelovedTeam = useHasAccess(BelovedPermissions.ASSIGNED_BELOVED_TEAM);

  useEffect(() => {
    fetchData();
    if (!currentBelOrg) {
      dispatch(
        belGetOrganizationDetails({
          orgId: Number(orgId),
        }),
      );
    }
  }, [orgId]);

  useEffect(() => {
    if (canAssignedBelovedTeam) {
      fetchAvailableUserList();
    }
  }, [canAssignedBelovedTeam]);

  const fetchAvailableUserList = async () => {
    Api.get(`beloved/organizations/${orgId}/available_beloved_team/`).then(res => {
      if (res) {
        setAvailableTeam(res);
      }
    });
  };

  const handleSaveTeam = async (id: number, role_type_ids: number[]) => {
    let response;
    if (showModal?.isEdit) {
      response = await Api.post(`beloved/organizations/${orgId}/update_organization_roles/`, {
        role_type_ids,
        org_assignment_id: id,
      });
    } else {
      response = await Api.post(`beloved/organizations/${orgId}/add_beloved_team/`, {
        role_type_ids,
        beloved_team_id: id,
      });
    }
    if (response) {
      fetchData();
      setSelectedTeam(null);
      setShowModal(null);
      fetchAvailableUserList();
    }
  };

  const handleCloseEditModal = (isRefresh?: boolean) => {
    if (isRefresh) {
      fetchData();
      setSelectedTeam(null);
      setShowModal(null);
      fetchAvailableUserList();
    }
    setShowModal(null);
  };

  const handleEditRole = (user: BelovedUserShortModel) => {
    setSelectedTeam(user);
    setShowModal({ isEdit: true });
  };

  const renderContent = () => {
    if (!data) {
      return <Loader />;
    }
    return (
      <>
        <div
          className="font-m font-grey font-weight-bold text-align-center mt-4 mb-5"
          style={{ maxWidth: 900 }}
        >
          {data.result.length ? t('main:belovedTeam.assigned-team') : t('main:belovedTeam.no-assigned-team')}
        </div>

        {data.result.length ? (
          <div className="b-infScrollWrapper">
            <InfiniteScroll
              dataLength={data.result.length}
              next={fetchMoreData}
              hasMore={!(data.current_page === data.total_pages)}
              loader={<h4 className="b-no-content">{t('common:label.loading')}...</h4>}
            >
              <div className="b-infScrollWrapper__normalize">
                <div className="b-belovedUser__list">
                  {data.result.map(item => {
                    return (
                      <BelovedUserCard
                        key={`user-card-${item.id}`}
                        user={item}
                        onEdit={() => handleEditRole(item)}
                      />
                    );
                  })}
                </div>
              </div>
            </InfiniteScroll>
          </div>
        ) : null}
        {canAssignedBelovedTeam ? (
          <div className="d-flex justify-content-center mt-5">
            <Button
              onPress={() => setShowModal({ isEdit: false })}
              title={t('common:btn.edit-beloved-team')}
            />
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <div className="b-page__header -desktop">
          <HeadlineOrganization
            orgName={currentBelOrg?.name || ''}
            orgId={currentBelOrg?.id || ''}
            headline={t('common:column.beloved-team')}
            customClasses={'-noDesktopMb'}
          />
        </div>
        <BelovedOrgTabs activeItemAlias={'beloved-team'} />
        {renderContent()}
      </main>

      {showModal ? (
        <BelovedTeamAssignmentModal
          value={{
            id: Number(selectedTeam?.org_assignment_id),
            user_name: `${selectedTeam?.first_name} ${selectedTeam?.last_name}`,
            org_roles: selectedTeam?.org_roles || [],
          }}
          values={availableTeam || []}
          onClose={handleCloseEditModal}
          title={t('main:belovedTeam.edit-member')}
          handleSave={handleSaveTeam}
          isEdit={showModal.isEdit}
        />
      ) : null}
    </>
  );
};

export default BelovedOrgUserList;
