import { SortDirection, useRowSelection } from 'react-data-grid';
import { basicDateFormat } from '../../../../../../helpers';
import { Checkbox, ComaList, TableCellWarning } from '../../../../../controls';
import React from 'react';
import { t } from 'i18next';
import {
  ColumnType,
  EwpElementTypes,
  EWPGoalModel,
  IRouteParamsBase,
  TableDataEntities,
} from '../../../../../../models';
import ElementTableStatusCombined from '../../parts/ElementTableStatusCombined';
import useTableData from '../../../../../../hooks/common/use-table-data';
import { TableDateFilter } from '../../../../../common';
import { useParams } from 'react-router';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

export const getColumns = (): ColumnType<EWPGoalModel>[] => [
  {
    key: 'title',
    name: t('common:tabs.goal'),
    resizable: true,
    sortable: true,
    editable: false,
    formatter(props) {
      return (
        <div className="d-flex align-items-center h-100">
          {props.row.is_broken ? <TableCellWarning text={t('awa:M149.msg')} /> : null}
          <div className="b-rdgTable__cellBase">{props.row.title}</div>
        </div>
      );
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'year_list',
    name: t('common:label.years'),
    resizable: true,
    width: 135,
    sortable: true,
    editable: false,
    formatter(props) {
      return (
        <ComaList arr={props.row.year_list_data.map(item => ({ title: item.label }))} uKey={'goal-year'} />
      );
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'user_create_name',
    name: t('common:label.author'),
    resizable: true,
    sortable: false,
    width: 135,
    editable: false,
    isShow: false,
    headerCellClass: 'b-rdgTable__headerCell',
    formatter(props) {
      return (
        <div className="d-flex align-items-center h-100">
          <div className="b-rdgTable__cellBase">{props.row.user_create_name}</div>
        </div>
      );
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'created_at',
    name: t('common:column.create-date'),
    width: 90,
    sortable: true,
    editable: false,
    isShow: false,
    resizable: true,
    formatter(props) {
      return (
        <>{props.row.created_at ? basicDateFormat(new Date(props.row.created_at)) : t('common:column.n-a')}</>
      );
    },
    headerRenderer(props) {
      const ewpId = useParams<IRouteParamsBase>().id;
      const { handleSelectDateFilter, query } = useTableData(TableDataEntities.EWP_GOAL_LIST, {
        ewp_id: ewpId,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'created_at'}
            selectedFilters={query.dateFilters['created_at']}
            periodFieldName={'created_at_period'}
            dateRangeFieldName={'created_at_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
            fiscalOption={true}
          />
        );
      }
    },
  },
  {
    key: 'updated_at',
    name: t('common:column.last-update'),
    resizable: true,
    width: 135,
    sortable: true,
    editable: false,
    formatter(props) {
      return <>{props.row.updated_at ? basicDateFormat(props.row.updated_at) : t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      const ewpId = useParams<IRouteParamsBase>().id;
      const { handleSelectDateFilter, query } = useTableData(TableDataEntities.EWP_GOAL_LIST, {
        ewp_id: ewpId,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'updated_at'}
            selectedFilters={query.dateFilters['updated_at']}
            periodFieldName={'updated_at_period'}
            dateRangeFieldName={'updated_at_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
          />
        );
      }
    },
  },
  {
    key: 'status_name',
    name: t('common:column.status'),
    sortable: true,
    resizable: true,
    width: 135,
    editable: false,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      return (
        <ElementTableStatusCombined
          type={EwpElementTypes.goal}
          approval_status={props.row.approval_status}
          approval_status_name={props.row.approval_status_name}
          status={props.row.status}
          status_name={props.row.status_name}
          ewp_status_id={props.row.ewp_status_id}
          has_approved_parents={props.row.has_approved_parents}
        />
      );
    },
  },
  {
    key: 'tools',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
  },
  {
    key: 'select',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
    headerRenderer(props) {
      return (
        <Checkbox
          checked={props.allRowsSelected}
          onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
        />
      );
    },
    formatter: props => {
      const [isRowSelected, onRowSelectionChange] = useRowSelection();

      return (
        <div className="b-rdgTable__cellCenter">
          <div className={'checkBox'} onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={isRowSelected}
              onChange={e => {
                onRowSelectionChange({
                  row: props.row,
                  checked: e.target.checked,
                  isShiftClick: false,
                });
              }}
            />
          </div>
        </div>
      );
    },
  },
];
