import React from 'react';

interface IProps {
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  className?: string;
}

const EmptyLink = ({ onClick, className }: IProps) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <a
      title={'Empty link'}
      aria-label={'Link'}
      href="#"
      onClick={handleClick}
      className={`b-button-empty ${className || ''}`}
    >
      <span aria-hidden="true"></span>
    </a>
  );
};

export default EmptyLink;
