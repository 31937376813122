import React, { useEffect, useState } from 'react';
import DataGrid from 'react-data-grid';
import { useLocation } from 'react-router';
import { Loader } from '../../../controls';
import { CreditModel, PaginationModel, QueryParamsModel, TableDataEntities } from '../../../../models';
import { getColumns } from './parts/columns';
import { Alert, Pagination } from '../../../common';
import { useTranslation } from 'react-i18next';
import useHasAccess from '../../../../hooks/beloved/adminTool/user-has-access';
import { useIsDarkMode } from '../../../../hooks/common/use-is-dark-mode';
import useBulkRemoveRequest, { BulkRemoveEntities } from '../../../../hooks/common/use-bulk-remove-request';
import SortModalButton from '../../../common/SortModalButton/SortModalButton';
import useTableConfig from '../../../../hooks/common/use-table-config';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { BelovedPermissions } from '../../../../constants';
import { useTableDataV2 } from '../../../../hooks/common/use-table-data-v2';
import CheckedItems from '../../../common/CheckedItems/CheckedItems';
import CreateCredit from '../OrgCredits/parts/CreateCredit';
import { Popover } from 'react-tiny-popover';
import useCredits from '../../../../hooks/beloved/credits/use-credits';
import { getCreditsOptions } from '../../../../store/credit/creditActions';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import UpdateCredit from '../OrgCredits/parts/UpdateCredit';
import FilterModalButton from '../../../common/FilterModalButton/FilterModalButton';
import SimpleTooltipPopover from '../../../controls/SimpleTooltip/SimpleTooltipPopover';
import useFileDownload from '../../../../hooks/common/use-file-download';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import { basicDateFormat } from '../../../../helpers';
import { useFilters } from '../../../../hooks/filters/use-filters';

interface ILocationState {
  org?: { id: number; name: string };
}

const CreditList = () => {
  const { t } = useTranslation();
  const location = useLocation<ILocationState | undefined>();
  const { isDarkMode } = useIsDarkMode();
  const { removeEntities } = useBulkRemoveRequest();
  const hasAccessToCreateCredit = useHasAccess(BelovedPermissions.CREDITS_CREATE);
  const hasAccessToUpdateCredit = useHasAccess(BelovedPermissions.CREDITS_UPDATE);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateCreditOpen, setUpdateCreditOpen] = useState<CreditModel | null>(null);
  const [isOpenActions, setIsOpenActions] = useState<boolean>(false);

  const { revokeCredits, reimburseCredits, reissueCredits } = useCredits();
  const { isDownloadingFile, downloadFile } = useFileDownload();
  const creditOptions = useSelector((state: StateType) => state.credit.creditOptions);
  const dispatch = useDispatch();

  const {
    query,
    data,
    fetchData,
    handleChangePage,
    handleChangePageSize,
    handleSearch,
    handleSort,
    handleChangeDirection,
    handleChangeSortField,
    sortingData,
    setSortingData,
    selectedEntitiesIds,
    handleSelectOne,
    setSelectedEntitiesIds,
    filterData,
    handleSelectFilter,
    handleApplyFilter,
    isFiltersChanged,
    infiniteList,
    fetchMoreMobile,
    handleSelectOneMob,
  } = useTableDataV2<CreditModel>({
    baseUrl: 'beloved/organization_credits/',
    entityName: TableDataEntities.CREDIT_LIST,
  });

  const { columns, onColumnResize } = useTableConfig<CreditModel>(
    TableDataEntities.CREDIT_LIST,
    getColumns(),
  );
  const { filterOptions } = useFilters();

  const hasAccessToRmElm = useHasAccess(BelovedPermissions.ELM_REMOVE);
  let [isDeletePopup, setIsDeletePopup] = useState(false);

  useEffect(() => {
    let newQuery: QueryParamsModel = { ...query };
    if (location.state) {
      if (location.state.org) {
        newQuery.search = location.state.org.name;
        newQuery.page = 1;
      }
    }
    fetchData(newQuery);
  }, [location.key]);

  useEffect(() => {
    if (!creditOptions?.credit_types) {
      dispatch(getCreditsOptions());
    }
  }, []);

  const navToDetails = (credit: CreditModel) => {
    if (hasAccessToUpdateCredit) {
      setUpdateCreditOpen(credit);
    }
  };

  const handleDelete = () => {
    removeEntities(BulkRemoveEntities.CREDITS, selectedEntitiesIds, () => {
      fetchData(query);
      setSelectedEntitiesIds([]);
      setIsDeletePopup(false);
    });
  };

  const renderContent = () => {
    if (!data || isDownloadingFile) return <Loader fitParent />;

    return (
      <>
        <div className="-desktop position-relative">
          <CheckedItems value={selectedEntitiesIds.length} total={data.count} />
          <DndProvider backend={HTML5Backend}>
            <DataGrid
              onRowClick={navToDetails}
              onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
              columns={columns}
              onColumnResize={onColumnResize}
              sortColumns={query.sorting ? [query.sorting] : []}
              rows={data.result}
              className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
              style={{
                height: ((data.result.length || 0) + 1) * 60,
              }}
              rowHeight={60}
              rowKeyGetter={(row: CreditModel) => row.id}
              onSelectedRowsChange={handleSelectOne}
              selectedRows={new Set(selectedEntitiesIds)}
            />
          </DndProvider>
          <Pagination
            data={data || ({} as PaginationModel)}
            handleChangePageSize={handleChangePageSize}
            changePage={handleChangePage}
          />
        </div>
        {isMobile && (
          <div className="-mobile b-panelTitleOutside__list">
            <InfiniteScroll
              dataLength={infiniteList.length}
              next={fetchMoreMobile}
              hasMore={!(data.current_page === data.total_pages)}
              loader={<h4>{t('common:label.loading')}...</h4>}
            >
              {infiniteList.map((credit: CreditModel) => {
                const isChecked = !!selectedEntitiesIds.find(f => f === credit.id);

                return (
                  <div key={`mob-item-${credit.id}`} className="b-panelTitleOutside__container">
                    <span className="b-panelTitleOutside__name">{credit.organization_name}</span>
                    <div className="b-panelTitleOutside" onClick={() => navToDetails(credit)}>
                      <label className="checkbox b-at-user__check" onClick={e => e.stopPropagation()}>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={e => handleSelectOneMob(e, credit.id)}
                        />
                        <span className="checkbox_fakeInput" />
                      </label>
                      <table>
                        <tr>
                          <th>{t('common:column.organization-name')}</th>
                          <td>{credit.organization_name}</td>
                        </tr>
                        <tr>
                          <th>{t('common:label.credit-name')}</th>
                          <td>{credit.credit_name}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.affiliation')}</th>
                          <td>{credit.affiliation}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.org-type')}</th>
                          <td>{credit.org_type}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.pm')}</th>
                          <td>{credit.name_pm}</td>
                        </tr>
                        <tr>
                          <th>{t('common:label.beloved-users')}</th>
                          <td>
                            {credit.beloved_leader?.first_name} {credit.beloved_leader?.last_name}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:label.status-name')}</th>
                          <td>{credit.status || t('common:column.n-a')}</td>
                        </tr>
                        <tr>
                          <th>{t('common:label.fee')}</th>
                          <td>{t('common:label.fee')}</td>
                        </tr>
                        <tr>
                          <th>{t('common:label.payment-date')}</th>
                          <td>
                            {credit.payment_date
                              ? basicDateFormat(new Date(credit.payment_date))
                              : t('common:column.n-a')}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:column.create-date')}</th>
                          <td>
                            {credit.created_at
                              ? basicDateFormat(new Date(credit.created_at))
                              : t('common:column.n-a')}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </>
    );
  };

  const handleAddCredit = () => {
    setCreateModalOpen(true);
  };

  const handleAction = () => {
    fetchData(query);
    setSelectedEntitiesIds([]);
    setIsOpenActions(false);
  };

  const handleRevoke = async () => {
    await revokeCredits(selectedEntitiesIds, handleAction);
  };

  const handleReimburse = async () => {
    await reimburseCredits(selectedEntitiesIds, handleAction);
  };

  const handleReissue = async () => {
    await reissueCredits(selectedEntitiesIds, handleAction);
  };

  const onSuccessCreditAction = () => {
    fetchData(query);
  };

  const handleCloseEditCredit = () => {
    setUpdateCreditOpen(null);
  };

  const handleDownloadSelected = () => {
    downloadFile(`beloved/organization_credits/export_credits/`, 'POST', {
      ids: selectedEntitiesIds,
    });
    setIsOpenActions(false);
  };

  const handleDownloadFiltered = () => {
    downloadFile(`beloved/organization_credits/export_credits/`, 'POST', {
      filters: filterData,
    });
    setIsOpenActions(false);
  };

  const handleDownloadAll = () => {
    downloadFile(`beloved/organization_credits/export_credits/`, 'POST', {
      all: true,
    });
    setIsOpenActions(false);
  };

  const renderActionsContent = () => {
    return (
      <div className="b-dropDownMenu">
        <ul>
          <SimpleTooltipPopover title={t('common:tooltip.credit-revoke')}>
            <li className={`${!selectedEntitiesIds.length ? '-disabled' : ''}`} onClick={handleRevoke}>
              {t('common:btn.revoke')}
            </li>
          </SimpleTooltipPopover>
          <SimpleTooltipPopover title={t('common:tooltip.credit-reimburse')}>
            <li className={`${!selectedEntitiesIds.length ? '-disabled' : ''}`} onClick={handleReimburse}>
              {t('common:btn.reimburse')}
            </li>
          </SimpleTooltipPopover>

          <SimpleTooltipPopover title={t('common:tooltip.credit-re-issue')}>
            <li className={`${!selectedEntitiesIds.length ? '-disabled' : ''}`} onClick={handleReissue}>
              {t('common:btn.re-issue')}
            </li>
          </SimpleTooltipPopover>

          <SimpleTooltipPopover title={t('common:tooltip.download-selected')}>
            <li
              className={`${!selectedEntitiesIds.length ? '-disabled' : ''}`}
              onClick={handleDownloadSelected}
            >
              {t('common:btn.download-selected')}
            </li>
          </SimpleTooltipPopover>

          <SimpleTooltipPopover title={t('common:tooltip.download-filtered')}>
            <li onClick={handleDownloadFiltered}>{t('common:btn.download-filtered')}</li>
          </SimpleTooltipPopover>

          <SimpleTooltipPopover title={t('common:tooltip.download-all')}>
            <li onClick={handleDownloadAll}>{t('common:btn.download-all')}</li>
          </SimpleTooltipPopover>
        </ul>
      </div>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <div>
          <h1 className={'b-page__title'}>{t('common:tabs.beloved-credits')}</h1>
          <div className="b-page__controls">
            <div className="b-tableToolContainer">
              <Popover
                isOpen={isOpenActions}
                positions={['bottom']}
                content={renderActionsContent()}
                align={'start'}
                reposition={false}
                containerClassName={'popover'}
                onClickOutside={() => setIsOpenActions(false)}
              >
                <button
                  aria-label="Actions"
                  disabled={false}
                  onClick={() => setIsOpenActions(!isOpenActions)}
                  className={`b-tableTool -download -mobView -mr`}
                >
                  <span className={'-desktop'}>{t('common:btn.actions')}</span>
                </button>
              </Popover>
              {hasAccessToRmElm ? (
                <button
                  onClick={() => setIsDeletePopup(true)}
                  disabled={!selectedEntitiesIds.length}
                  className="b-tableTool -delete -mobView -mr"
                >
                  {t('common:btn.delete')}
                </button>
              ) : null}
              {hasAccessToCreateCredit ? (
                <button onClick={handleAddCredit} className="b-tableTool -addPlus -mobView -mr">
                  {t('common:btn.credit')}
                </button>
              ) : null}
              {filterOptions ? (
                <FilterModalButton
                  query={query}
                  handleSelectFilter={handleSelectFilter}
                  handleApplyFilter={handleApplyFilter}
                  filterData={filterData}
                  isFiltersChanged={isFiltersChanged}
                  filters={filterOptions.credits}
                />
              ) : null}
            </div>
            <div className="b-page__search">
              <form action="#" className="searchForm">
                <input
                  type="search"
                  value={query.search}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder={t('common:label.search')}
                  aria-label={'search'}
                />
                <span className="searchForm__icon" />
              </form>
            </div>
          </div>

          <div className="-mobile">
            <div className="b-tableToolContainer -centered">
              <SortModalButton
                sortingData={sortingData}
                setSortingData={setSortingData}
                columns={columns}
                query={query}
                handleChangeSortField={handleChangeSortField}
                handleSort={handleSort}
              />
              <button
                onClick={handleChangeDirection}
                type="button"
                className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                    ${query.sorting?.direction === 'DESC' && '-active'}
                  `}
              >
                {t('common:btn.a-z-order')}
              </button>
            </div>
          </div>
        </div>
        {renderContent()}
      </main>
      {isDeletePopup ? (
        <Alert
          isOpen={isDeletePopup}
          onRequestClose={() => setIsDeletePopup(false)}
          title={t('awa:N38.title')}
          text={<p />}
          buttons={{
            left: {
              type: 'bordered',
              title: t('common:btn.cancel'),
              onClick: () => setIsDeletePopup(false),
            },
            right: {
              title: t('common:btn.delete'),
              onClick: handleDelete,
            },
          }}
        />
      ) : null}
      {createModalOpen ? (
        <CreateCredit
          setCreateModalOpen={setCreateModalOpen}
          onSuccessCreate={onSuccessCreditAction}
          selectCustomOrgId
        />
      ) : null}
      {updateCreditOpen ? (
        <UpdateCredit
          onClose={handleCloseEditCredit}
          onSuccessUpdate={onSuccessCreditAction}
          credit={updateCreditOpen}
        />
      ) : null}
    </>
  );
};

export default CreditList;
