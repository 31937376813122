import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import { ISelectOptions } from '../../../models';
import styles from './SelectStyle.module.scss';
import AsyncSelect from 'react-select/async';
import { OptionType } from 'dayjs';
import { OptionsType } from 'react-select/src/types';

interface IInput {
  error?: string;
  placeholder?: string;
  type?: string;
  value: any;
  handleChange?: (value: any) => void;
  handleUnselect?: () => void;
  customClasses?: any;
  height?: string | number;
  name?: string;
  wrapperStyles?: CSSProperties;
  disabled?: boolean;
  // Additional in MenuList on top of the options
  topAdditional?: JSX.Element;
  // Additional in MenuList on bottom of the options
  bottomAdditional?: JSX.Element;
  isMulti?: boolean;
  autoFocus?: boolean;
  tabIndex?: number;
  ariaLabel?: string;
  isSearchable?: boolean;
  loadOptions(inputValue: string, callback: (options: OptionsType<ISelectOptions>) => void): void;
  defaultOptions?: ReadonlyArray<OptionType | ISelectOptions> | boolean | undefined;
  onMenuOpen?: (() => void) | undefined;
}

const MenuList = (
  { children, ...rest }: any,
  topAdditional?: JSX.Element,
  bottomAdditional?: JSX.Element,
) => {
  return (
    <components.MenuList {...rest}>
      {topAdditional}
      {children}
      {bottomAdditional}
    </components.MenuList>
  );
};

const AsyncSelectBlock = (props: IInput) => {
  const {
    error,
    placeholder,
    handleChange,
    value,
    customClasses,
    height,
    name,
    wrapperStyles,
    disabled,
    topAdditional,
    bottomAdditional,
    isMulti,
    autoFocus,
    tabIndex,
    ariaLabel,
    isSearchable,
    loadOptions,
    defaultOptions,
    onMenuOpen,
  } = props;

  const ref = useRef<any>(null);
  const [isFocus, setFocus] = useState(false);

  useEffect(() => {
    if (autoFocus) {
      ref.current.focus();
    }
  }, []);

  const getBorderColor = (isFocused: boolean) => {
    if (error) {
      return 'red';
    }
    if (isFocused) {
      return 'var(--primary-60)';
    }
    return 'var(--gray-30)';
  };

  const customStyles = {
    control: (base: any, { isFocused }: any) => ({
      ...base,
      minHeight: height || '52px',
      color: 'var(--gray-60)',
      background: 'inherit',
      opacity: '1',
      borderColor: getBorderColor(isFocused),
      boxShadow: isFocused ? ' 0 0 0 1px var(--primary-60)' : 0,
    }),
    singleValue: (base: any) => ({
      ...base,
      color: 'var(--gray-60)',
      fontFamily: 'Rubik',
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 9999,
      color: 'var(--primary-navy)',
      background: 'var(--background)',
    }),
    input: (base: any) => ({
      ...base,
      color: 'var(--gray-60)',
    }),
    placeholder: (base: any) => ({
      ...base,
      color: 'var(--gray-60)',
    }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor: 'var(--gray-10)',
      color: 'var(--gray-60)',
      '& div': {
        color: 'var(--gray-60)',
      },
    }),
  };

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  return (
    <div style={wrapperStyles} className={styles.select_wrapper}>
      <div
        className={`${styles.input_placeholder_block} ${error ? styles.select_wrapper_error : ''} ${
          isFocus ? styles.labelFocus : ''
        }`}
        data-content={value ? placeholder : ''}
      >
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          name={name}
          placeholder={placeholder}
          isSearchable={isSearchable}
          value={value}
          components={{
            IndicatorSeparator: () => null,
            MenuList: props => MenuList(props, topAdditional, bottomAdditional),
          }}
          defaultOptions={defaultOptions}
          onFocus={onFocus}
          onBlur={onBlur}
          isDisabled={disabled}
          onChange={handleChange}
          styles={customStyles}
          isMulti={isMulti}
          onMenuOpen={onMenuOpen}
          isOptionDisabled={option => option.disabled}
          menuPlacement={'auto'}
          className={`${(customClasses && customClasses) || ''} ${error ? styles.select_error : ''}`}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: 'var(--primary-60)',
              primary25: 'var(--gray-10)',
              primary50: 'var(--primary-60)',
              neutral20: error ? 'red' : 'var(--gray-30)',
            },
          })}
          // @ts-ignore
          tabIndex={tabIndex}
          aria-label={ariaLabel || placeholder}
        />
        <div className={'error-notification'}>
          <span className={'error-notification-text'}>{error}</span>
        </div>
      </div>
    </div>
  );
};

export default AsyncSelectBlock;
