import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '../../../../common';
import { useHistory, useParams } from 'react-router-dom';
import { StateType } from '../../../../../store/reducers';
import { EwpCreateStepEnum, EWPUpdateData, IRouteParamsBase } from '../../../../../models';
import { Loader } from '../../../../controls';
import { ROOTS } from '../../../../../routes/routes';
import { useTranslation } from 'react-i18next';
import { EWPManagers } from './EWPManagers/EWPManagers';
import { EWPDate } from './EWPDate/EWPDate';
import { cancelEWP, createEWP, getEWPDetails, updateEWP } from '../../../../../store/ewp/ewpActions';
import { EWPVision } from './EWPVision/EWPVision';
import { EWPDateResult } from './EWPDateResult/EWPDateResult';

interface IState {
  currentStep: EwpCreateStepEnum;
  cancelConfirmModal: boolean;
}

export const EWPCreate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history: any = useHistory();
  const { orgId } = useParams<IRouteParamsBase>();
  const isBeloved = useSelector((state: StateType) => state.profile.authUser?.beloved_user);
  const EWP_DETAILS = isBeloved ? ROOTS.BELOVED_EWP_DETAILS : ROOTS.EWP_DETAILS;
  const createEWPData = useSelector((state: StateType) => state.ewp.createEWPData);
  let [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state: StateType) => state.profile.authUser);
  const isBelovedUser = user?.beloved_user;

  const initialState = {
    currentStep: EwpCreateStepEnum.team,
    cancelConfirmModal: false,
  };

  let [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (!createEWPData) {
      dispatch(
        getEWPDetails({
          isCreate: true,
          orgId: Number(orgId),
          callback: res => {
            if (!res) {
              dispatch(
                createEWP({
                  callback: (res, err) => {
                    if (err) {
                      if (isBeloved) {
                        history.go(-1);
                      } else {
                        if (isBelovedUser) {
                          history.push(ROOTS.BELOVED_EWP_DETAILS.replace(':orgId', `${orgId}`));
                        } else {
                          history.push(ROOTS.EWP_DETAILS.replace(':orgId', `${orgId}`));
                        }
                      }
                    }
                  },
                }),
              );
            }
          },
        }),
      );
    }
    if (createEWPData?.ewp_screen === EwpCreateStepEnum.finish) {
      history.push(EWP_DETAILS.replace(':orgId', `${orgId}`));
    }
  }, []);

  useEffect(() => {
    if (createEWPData && createEWPData.ewp_screen !== EwpCreateStepEnum.finish) {
      setState(prevState => ({ ...prevState, currentStep: createEWPData.ewp_screen }));
    }
  }, [createEWPData]);

  const onChangeStep = (ewpStep: EwpCreateStepEnum) => {
    setState(prevState => ({ ...prevState, currentStep: ewpStep }));
  };

  const handleCancel = () => {
    setState({ ...state, cancelConfirmModal: true });
  };

  const cancelEwp = () => {
    if (createEWPData) {
      dispatch(
        cancelEWP({
          ewpId: createEWPData.id,
          callback: () => {
            if (isBeloved) {
              history.replace(ROOTS.BELOVED_EWP_LIST);
            } else {
              if (isBelovedUser) {
                history.push(ROOTS.BELOVED_EWP_DETAILS.replace(':orgId', `${orgId}`));
              } else {
                history.push(ROOTS.EWP_DETAILS.replace(':orgId', `${orgId}`));
              }
            }
          },
        }),
      );
    }
  };

  const saveData = (data: EWPUpdateData, isNext: boolean) => {
    if (createEWPData) {
      setIsLoading(true);
      dispatch(
        updateEWP({
          ewpId: createEWPData.id,
          orgId: createEWPData.organization_id,
          data,
          isDetails: false,
          callback: () => {
            if (isNext) {
              setIsLoading(false);
            } else {
              history.push(EWP_DETAILS.replace(':orgId', `${orgId}`));
            }
          },
        }),
      );
    }
  };

  const renderSteps = () => {
    switch (state.currentStep) {
      case EwpCreateStepEnum.team: {
        return <EWPManagers saveData={saveData} handleCancel={handleCancel} />;
      }
      case EwpCreateStepEnum.date: {
        return <EWPDate saveData={saveData} handleCancel={handleCancel} onChangeStep={onChangeStep} />;
      }
      case EwpCreateStepEnum.dateResult: {
        return <EWPDateResult saveData={saveData} handleCancel={handleCancel} onChangeStep={onChangeStep} />;
      }
      case EwpCreateStepEnum.vision: {
        return <EWPVision saveData={saveData} handleCancel={handleCancel} onChangeStep={onChangeStep} />;
      }
      default: {
        return <EWPManagers saveData={saveData} handleCancel={handleCancel} />;
      }
    }
  };

  return (
    <>
      <main className={'pageContainer -noRelative pageContainer__content'}>
        {isLoading && <Loader fitParent />}
        {renderSteps()}
      </main>
      {state.cancelConfirmModal ? (
        <Alert
          isOpen={state.cancelConfirmModal}
          onRequestClose={() => setState({ ...state, cancelConfirmModal: false })}
          title={t('main:ewp-create.cancel.title')}
          text={t('main:ewp-create.cancel.msg.p1') + '\n' + t('main:ewp-create.cancel.msg.p2')}
          buttons={{
            left: {
              title: t('common:btn.no'),
              type: 'bordered',
              onClick: () => setState({ ...state, cancelConfirmModal: false }),
            },
            right: {
              title: t('common:btn.cancel'),
              type: 'orange-light',
              onClick: cancelEwp,
            },
          }}
        />
      ) : null}
    </>
  );
};
