import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Input, Select } from '../../../controls';
import styles from './CreateSubOrg.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  createProfileSubOrganization,
  getOrganizationDetails,
  getOrganizationUsers,
} from '../../../../store/organization/organizationActions';
import {
  CreateOrganizationModel,
  IRouteParamsBase,
  KeyValueModel,
  OrganizationDetailsModel,
  OrganizationUsersModel,
  ISelectOptions,
} from '../../../../models';
import { StateType } from '../../../../store/reducers';
import AddNewUser from './parts/AddNewUser/AddNewUser';
import { httpUrl, isValidFields, validate } from '../../../../helpers';
import { ROOTS } from '../../../../routes/routes';
import { getUser } from '../../../../store/profile/profileActions';
import { Modal } from '../../../common';
import { useRole } from '../../../../helpers/roles';
import { useTranslation } from 'react-i18next';

interface IState {
  formData: CreateOrganizationModel | null;
  errors: KeyValueModel<string> | null;
  wasEdit: boolean;
  currentTab: number;
  userName: ISelectOptions<any> | null;
  users: ISelectOptions<any>[] | null;
  name: string;
  website: string;
  type: ISelectOptions | null;
  division: string;
  address: {};
  organization_manager: {
    email: string;
    first_name: string;
    last_name: string;
    division: string;
  } | null;
  isCreateUserPopup: boolean;
}

const CreateSubOrg = () => {
  const validator = {
    required: ['type', 'name', 'website'],
    custom: [httpUrl(['website'])],
    requiredFieldName: {
      name: 'Sub-Organization name',
    },
  };
  const { t } = useTranslation();
  const { orgId } = useParams<IRouteParamsBase>();
  const dispatch = useDispatch();
  const history = useHistory();
  const getAccess = useRole();
  const currentOrg: OrganizationDetailsModel | null = useSelector((state: StateType) => {
    return state.organization.currentOrg;
  });
  const currentUser = useSelector((state: StateType) => state.profile.authUser);
  const orgTypes: ISelectOptions[] | null = useSelector(
    (state: StateType) => state.utils.data.organizationsTypes || null,
  );
  const orgUsers: OrganizationUsersModel | null = useSelector(
    (state: StateType) => state.organization.currentOrgUsers || null,
  );
  const initialState: IState = {
    address: {},
    currentTab: 0,
    division: '',
    errors: {},
    formData: null,
    isCreateUserPopup: false,
    name: '',
    organization_manager: null,
    type: null,
    userName: null,
    users: null,
    wasEdit: false,
    website: '',
  };
  const [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    dispatch(getOrganizationDetails({ orgId: Number(orgId) }));
    dispatch(
      getOrganizationUsers({
        params: {
          page_size: 1000,
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (currentOrg && orgTypes && !state.wasEdit) {
      setState(prevState => {
        return {
          ...prevState,
          userName: null,
          name: '',
          website: '',
          type: null,
          division: '',
          address: {},
          organization_manager: {
            email: '',
            first_name: '',
            last_name: '',
            division: '',
          },
          formData: {
            name: currentOrg.name,
            website: currentOrg.website,
            type: Number(orgTypes.find(type => type.label === currentOrg.type_name)?.value) || 0,
            division: currentOrg.division,
            address: currentOrg.address,
          },
        };
      });
    }
  }, [currentOrg, orgTypes, currentUser, state.wasEdit]);

  useEffect(() => {
    if (orgUsers !== null) {
      let users: ISelectOptions[] = orgUsers?.result.map((user: any) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user,
      }));

      users.push({ label: '—', value: '', disabled: true });
      users.push({ label: t('common:btn.add-new-user'), value: 'new_user' });
      setState({ ...state, users: users });
    }
  }, [orgUsers]);

  const handleChangeSelect = (name: string, data: ISelectOptions) => {
    if (name === 'userName' && data.value === 'new_user') {
      setState({
        ...state,
        isCreateUserPopup: true,
      });
      return;
    }

    if (!state.formData) return null;
    if (state.errors && state.errors[name]) {
      delete state.errors[name];
    }
    setState({
      ...state,
      [name]: data,
      wasEdit: true,
      errors: state.errors,
    });
  };

  const handleChangeInput = (name: string, value: string) => {
    if (!state) return null;
    if (state.errors && state.errors[name]) {
      delete state.errors[name];
    }
    setState({
      ...state,
      wasEdit: true,
      [name]: value,
      errors: state.errors,
    });
  };
  const handleSubmit = () => {
    const { errors } = validate(validator, state);
    if (Object.values(errors || {}).length) {
      // setErrors(errors);
      setState({
        ...state,
        errors: errors,
      });
      return;
    }

    let data = {
      name: state.name,
      website: state.website.toLowerCase(),
      type: state.type?.value,
      division: state.division,
      address: {},
      organization_manager: {
        email: state.userName?.value.email,
        first_name: state.userName?.value.first_name,
        last_name: state.userName?.value.last_name,
        user_type_id: state.userName?.value.type_id,
      },
    };
    dispatch(
      createProfileSubOrganization({
        orgId: Number(orgId),
        data: data,
        callback: (response, error) => {
          if (!error) {
            dispatch(getOrganizationDetails({ orgId: Number(orgId) }));
            dispatch(getUser({}));
            if (currentUser && state.userName?.value.email === currentUser.user.email) {
              history.push(ROOTS.ORG_PROFILE.replace(':orgId', `${response.id}`));
              return;
            }
            history.push({
              pathname: ROOTS.CREATE_NEW_SUB_ORGANIZATION_SUCCESS.replace(':orgId', orgId),
              search: `?isNew=${!state.userName?.value.user_id}`,
            });
            return;
          } else {
            setState({
              ...state,
              errors: error,
            });
          }
        },
      }),
    );
  };

  const handleCloseCreateUser = () => {
    setState({
      ...state,
      isCreateUserPopup: false,
    });
  };

  const savedNewUser = (user: any) => {
    const users = state.users;
    const userData = { label: `${user.first_name} ${user.last_name}`, value: user };
    users && users.splice(users.length - 2, 0, userData);

    setState({
      ...state,
      isCreateUserPopup: false,
      users: users,
      userName: userData,
    });
  };

  const onCancel = () => {
    history.go(-1);
  };

  const isAccess = getAccess(['project_admin', 'project_manager']);
  const isDisableButton = isValidFields(validator, state);

  return (
    <>
      <main className={`pageContainer__content ${styles.pageContainer}`}>
        {state.isCreateUserPopup && (
          <Modal width={545} onClose={() => handleCloseCreateUser()} title={t('common:btn.add-new-user')}>
            <AddNewUser handleClosePopup={handleCloseCreateUser} savedNewUser={savedNewUser} />
          </Modal>
        )}
        {state.formData && orgTypes ? (
          <div>
            <h1 className={'pageContainer__title_suborg'}>{t('common:label.Create-New-Sub-Organization')}</h1>
            <div className={styles.form}>
              <div className={styles.form__part}>
                <div className={styles.form__part}>
                  <Input
                    name={'name'}
                    error={state.errors?.name || ''}
                    value={state.name}
                    type={'text'}
                    disabled={!isAccess}
                    placeholder={t('common:label.sub-organization-name')}
                    ariaLabel="Sub-Organization Name"
                    handleChange={(value: string) => handleChangeInput('name', value)}
                    autoFocus
                  />
                </div>
              </div>
              <div className={styles.form__part}>
                <Select
                  name={'type'}
                  value={orgTypes.find(f => f.value === state.type?.value) || null}
                  placeholder={t('common:label.type')}
                  ariaLabel="Type"
                  options={orgTypes}
                  disabled={!isAccess}
                  error={state.errors?.type || ''}
                  handleChange={(value: ISelectOptions) => handleChangeSelect('type', value)}
                  height={52}
                />
              </div>
            </div>
            <div className={styles.second_part_form}>
              <div className={styles.form__part}>
                <div className={styles.form__part}>
                  <Input
                    name={'website'}
                    error={state.errors?.website || ''}
                    value={state.website || ''}
                    type={'text'}
                    disabled={!isAccess}
                    placeholder={t('common:label.website')}
                    ariaLabel="Website"
                    handleChange={(value: string) => handleChangeInput('website', value)}
                  />
                </div>
              </div>
              <div className={styles.form__part}>
                <div className={styles.form__part}>
                  <Input
                    name={'division'}
                    error={state.errors?.division || ''}
                    value={state.division || ''}
                    type={'text'}
                    disabled={!isAccess}
                    placeholder={t('common:label.division/department/unit')}
                    ariaLabel="Division/Department/Unit"
                    handleChange={(value: string) => handleChangeInput('division', value)}
                  />
                </div>
              </div>
            </div>
            <div className={styles.form__additional}>
              <div className={styles.form__additionalCol}>
                <div className={styles.form__suborganization}>
                  <span className={styles.form__suborganization_opener}>
                    {t('common:label.project-manager')}
                    <div className={`${styles.icon} ${styles.icon_arrow}`} />
                  </span>
                </div>
              </div>
            </div>
            {!state.userName && (
              <div className={`${styles.form}  ${styles.container_wrapper}`}>
                <div className={styles.form__part}>
                  <Select
                    name={'userName'}
                    value={(state.users && state.users.find(f => f.label === state.userName?.label)) || null}
                    placeholder={t('common:label.name-pm')}
                    ariaLabel="Name of PM"
                    options={state.users || []}
                    error={''}
                    disabled={!isAccess}
                    handleChange={(value: ISelectOptions) => handleChangeSelect('userName', value)}
                    height={52}
                  />
                </div>
                <div className={styles.form__part} />
              </div>
            )}
            {state.userName && (
              <>
                <div className={`${styles.form} ${styles.container_wrapper}`}>
                  <div className={styles.form__part}>
                    <Input
                      name={'first_name'}
                      value={state.userName?.value?.first_name || ''}
                      type={'text'}
                      disabled={true}
                      placeholder={t('common:label.first-name')}
                      ariaLabel="First name"
                    />
                  </div>
                  <div className={styles.form__part}>
                    <Input
                      name={'last_name'}
                      value={state.userName?.value?.last_name || ''}
                      type={'text'}
                      disabled={true}
                      placeholder={t('common:label.last-name')}
                      ariaLabel="Last name"
                    />
                  </div>
                </div>
                <div className={`mt-0 ${styles.form}`}>
                  <div className={styles.form__part}>
                    <Input
                      name={'email'}
                      value={state.userName?.value?.email || ''}
                      type={'text'}
                      disabled={true}
                      placeholder={t('common:label.email-address')}
                      ariaLabel="Email Address"
                    />
                  </div>
                  <div className={styles.form__part} />
                </div>
              </>
            )}
            <div className={styles.form}>
              <div className={styles.form__buttons}>
                <Button
                  onPress={onCancel}
                  title={t('common:btn.cancel')}
                  size={'large'}
                  type={'transparency'}
                />
                <Button
                  onPress={handleSubmit}
                  disabled={isDisableButton || !isAccess}
                  title={t('common:btn.save')}
                  size={'large'}
                  type={'orange-light'}
                  enableEnter
                />
              </div>
            </div>
          </div>
        ) : null}
      </main>
    </>
  );
};

export default CreateSubOrg;
