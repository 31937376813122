import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ROOTS } from '../../../routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../store/reducers';
import useHasAccess from '../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { setNavDetailsSource } from '../../../store/ewp/ewpActions';
import { useLocation } from 'react-router';

interface IProps {
  activeItemAlias:
    | 'profile'
    | 'demographics'
    | 'debrief'
    | 'credits'
    | 'beloved-team'
    | 'eas'
    | 'elm'
    | 'EWP'
    | 'beloved-consultant';
}

const BelovedOrgTabs: FC<IProps> = ({ activeItemAlias }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentBelOrg = useSelector((state: StateType) => state.belOrganizations.currentBelOrg);
  const isBeloved = useSelector((state: StateType) => state.profile.authUser)?.beloved_user;

  const hasAccessToEa = useHasAccess(BelovedPermissions.EA_LIST);
  const hasAccessToElm = useHasAccess(BelovedPermissions.ELM_LIST);
  const hasAccessToDebrief = useHasAccess(BelovedPermissions.DEBRIEF_LIST);
  const hasAccessToProfile = useHasAccess(BelovedPermissions.ORGANIZATION_PROFILE);
  const hasAccessToCredits = useHasAccess(BelovedPermissions.CREDITS_LIST);
  const hasAccessToEwpDetails = useHasAccess(BelovedPermissions.EWP_DETAILS);
  const hasAccessCertifiedConsultant = useHasAccess(BelovedPermissions.CERTIFIED_CONSULTANT);
  const hasAccessToBelovedTeam = !!isBeloved;

  if (!currentBelOrg) return null;

  const handleClick = (alias: IProps['activeItemAlias']) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    switch (alias) {
      case 'profile':
        history.push(ROOTS.BELOVED_ORGANIZATION_DETAILS.replace(':orgId', `${currentBelOrg.id}`));
        break;
      case 'demographics':
        break;
      case 'elm':
        history.push(ROOTS.BELOVED_ELM_LIST, {
          org: {
            id: currentBelOrg.id,
            name: currentBelOrg.name,
          },
          isBackButton: true,
        });
        break;
      case 'beloved-team':
        history.push(ROOTS.BELOVED_ORG_USER_LIST.replace(':orgId', `${currentBelOrg.id}`));
        break;
      case 'credits':
        history.push(ROOTS.BELOVED_ORG_CREDITS.replace(':orgId', `${currentBelOrg.id}`));
        break;
      case 'debrief':
        history.push(ROOTS.BELOVED_DEBRIEFS, {
          org: {
            id: currentBelOrg.id,
            name: currentBelOrg.name,
          },
          isBackButton: true,
        });
        break;
      case 'eas':
        history.push(ROOTS.BELOVED_EQUITY_AUDITS, {
          org: {
            id: currentBelOrg.id,
            name: currentBelOrg.name,
          },
          isBackButton: true,
        });
        break;
      case 'EWP':
        dispatch(setNavDetailsSource(location.pathname));
        history.push(ROOTS.BELOVED_EWP_DETAILS.replace(':orgId', `${currentBelOrg.id}`), {
          isBackButton: true,
        });
        break;
      case 'beloved-consultant':
        history.push(ROOTS.BELOVED_ORG_CERTIFIED_CONSULTANT.replace(':orgId', `${currentBelOrg.id}`));
        break;
    }
  };

  return (
    <>
      <nav className="b-tabs">
        {hasAccessToProfile ? (
          <a
            href="#"
            className={`b-tabs__item ${activeItemAlias === 'profile' ? 'b-tabs__item_active' : ''}`}
            onClick={handleClick('profile')}
          >
            <div className={`b-tabs__icon b-tabs__icon_organization`} />
            <span className="b-tabs__text">{t('common:tabs.profile')}</span>
          </a>
        ) : null}
        <a
          href="#"
          className={`
                b-tabs__item
                b-tabs__item_disabled
                ${activeItemAlias === 'demographics' ? 'b-tabs__item_active' : ''}
              `}
          onClick={handleClick('demographics')}
        >
          <div className="b-tabs__icon b-tabs__icon_map" />
          <span className="b-tabs__text">{t('common:tabs.demographics')}</span>
        </a>
        {hasAccessToCredits ? (
          <a
            href="#"
            className={`
                b-tabs__item
                ${activeItemAlias === 'credits' ? 'b-tabs__item_active' : ''}
              `}
            onClick={handleClick('credits')}
          >
            <div className="b-tabs__icon b-tabs__icon_beloved_credit" />
            <span className="b-tabs__text">{t('common:tabs.beloved-credits')}</span>
          </a>
        ) : null}
        {hasAccessToEa ? (
          <a
            href="#"
            className={`
            b-tabs__item
            ${activeItemAlias === 'eas' ? 'b-tabs__item_active' : ''}
            `}
            onClick={handleClick('eas')}
          >
            <div className="b-tabs__icon b-tabs__icon_ea" />
            <span className="b-tabs__text">{t('common:tabs.ea')}</span>
          </a>
        ) : null}
        {hasAccessToDebrief ? (
          <a
            href="#"
            className={`
            b-tabs__item
            ${activeItemAlias === 'debrief' ? 'b-tabs__item_active' : ''}
            `}
            onClick={handleClick('debrief')}
          >
            <div className="b-tabs__icon b-tabs__icon_debrief" />
            <span className="b-tabs__text">{t('common:tabs.debriefs')}</span>
          </a>
        ) : null}
        {hasAccessToElm ? (
          <a
            href="#"
            className={`
            b-tabs__item
            ${activeItemAlias === 'elm' ? 'b-tabs__item_active' : ''}
            `}
            onClick={handleClick('elm')}
          >
            <div className="b-tabs__icon b-tabs__icon_elm" />
            <span className="b-tabs__text">{t('common:tabs.elms')}</span>
          </a>
        ) : null}
        {hasAccessToEwpDetails ? (
          <a
            id={activeItemAlias === 'EWP' ? 'active-org-tab' : ''}
            href="#"
            className={`b-tabs__item ${activeItemAlias === 'EWP' ? 'b-tabs__item_active' : ''}`}
            onClick={handleClick('EWP')}
          >
            <div aria-label={'EWP'} className="b-tabs__icon b-tabs__icon_ga" />
            <span aria-label={'EWP'} className="b-tabs__text">
              {t('common:tabs.ewp')}
            </span>
          </a>
        ) : null}
        {hasAccessToBelovedTeam ? (
          <a
            href="#"
            className={`
            b-tabs__item
            ${activeItemAlias === 'beloved-team' ? 'b-tabs__item_active' : ''}
            `}
            onClick={handleClick('beloved-team')}
          >
            <div className="b-tabs__icon b-tabs__icon_belovedTeam" />
            <span className="b-tabs__text">{t('common:column.beloved-team')}</span>
          </a>
        ) : null}
        {hasAccessCertifiedConsultant ? (
          <a
            id={activeItemAlias === 'beloved-consultant' ? 'active-org-tab' : ''}
            href="#"
            className={`b-tabs__item ${
              activeItemAlias === 'beloved-consultant' ? 'b-tabs__item_active' : ''
            }`}
            onClick={handleClick('beloved-consultant')}
          >
            <div aria-label={'Beloved Team'} className="b-tabs__icon b-tabs__icon_belovedTeam" />
            <span aria-label={'Beloved Team'} className="b-tabs__text">
              {t('common:column.beloved-certified-consultants')}
            </span>
          </a>
        ) : null}
      </nav>
    </>
  );
};

export default BelovedOrgTabs;
