import { SortDirection, useRowSelection } from 'react-data-grid';
import { basicDateFormat, cutString } from '../../../../../../helpers';
import { Checkbox, ComaList } from '../../../../../controls';
import React from 'react';
import { t } from 'i18next';
import {
  ColumnType,
  EwpElementTypes,
  EWPKeyActionModel,
  IRouteParamsBase,
  TableDataEntities,
} from '../../../../../../models';
import ElementTableStatusCombined from '../../parts/ElementTableStatusCombined';
import useTableData from '../../../../../../hooks/common/use-table-data';
import { TableDateFilter } from '../../../../../common';
import { useParams } from 'react-router';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

export const getColumns = (): ColumnType<EWPKeyActionModel>[] => [
  {
    key: 'title',
    name: t('common:label.key-action'),
    resizable: true,
    sortable: true,
    editable: false,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'key_types',
    name: t('common:column.type'),
    resizable: true,
    sortable: false,
    editable: false,
    headerCellClass: 'b-rdgTable__headerCell',
    formatter(props) {
      return <ComaList arr={props.row.key_types.map(item => ({ title: item.label }))} uKey={'ka-type'} />;
    },
  },
  {
    key: 'user_create_name',
    name: t('common:label.author'),
    resizable: true,
    sortable: false,
    width: 135,
    editable: false,
    isShow: false,
    headerCellClass: 'b-rdgTable__headerCell',
    formatter(props) {
      return (
        <div className="d-flex align-items-center h-100">
          <div className="b-rdgTable__cellBase">{props.row.user_create_name}</div>
        </div>
      );
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'created_at',
    name: t('common:column.create-date'),
    width: 90,
    sortable: true,
    editable: false,
    isShow: false,
    resizable: true,
    formatter(props) {
      return (
        <>{props.row.created_at ? basicDateFormat(new Date(props.row.created_at)) : t('common:column.n-a')}</>
      );
    },
    headerRenderer(props) {
      const ewpId = useParams<IRouteParamsBase>().id;
      const { handleSelectDateFilter, query } = useTableData(TableDataEntities.EWP_KA_LIST, {
        ewp_id: ewpId,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'created_at'}
            selectedFilters={query.dateFilters['created_at']}
            periodFieldName={'created_at_period'}
            dateRangeFieldName={'created_at_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
            fiscalOption={true}
          />
        );
      }
    },
  },
  {
    key: 'owners',
    name: t('common:label.owners'),
    resizable: true,
    width: 120,
    sortable: true,
    editable: false,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      return (
        <div className="b-adminToolUsers__statusCell">
          <ComaList
            tag={'div'}
            uKey="b-l-"
            nullText={t('common:column.n-a')}
            arr={props.row.owners.slice(0, 3).map(user => ({ title: cutString(user.name, 20) }))}
          />
        </div>
      );
    },
  },
  {
    key: 'last_updated',
    name: t('common:column.last-update'),
    resizable: true,
    sortable: true,
    width: 100,
    editable: false,
    formatter(props) {
      return <>{props.row.last_updated ? basicDateFormat(props.row.last_updated) : t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      const ewpId = useParams<IRouteParamsBase>().id;
      const { handleSelectDateFilter, query } = useTableData(TableDataEntities.EWP_KA_LIST, {
        ewp_id: ewpId,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'last_updated'}
            selectedFilters={query.dateFilters['last_updated']}
            periodFieldName={'last_updated_period'}
            dateRangeFieldName={'last_updated_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
          />
        );
      }
    },
  },
  {
    key: 'start_date',
    name: t('common:column.start-date'),
    resizable: true,
    sortable: true,
    width: 100,
    editable: false,
    formatter(props) {
      return <>{props.row.start_date ? basicDateFormat(props.row.start_date) : t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      const ewpId = useParams<IRouteParamsBase>().id;
      const { handleSelectDateFilter, query } = useTableData(TableDataEntities.EWP_KA_LIST, {
        ewp_id: ewpId,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'start_date'}
            selectedFilters={query.dateFilters['start_date']}
            periodFieldName={'start_date_period'}
            dateRangeFieldName={'start_date_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
          />
        );
      }
    },
  },
  {
    key: 'estimated_completion_date',
    name: t('common:column.end-date'),
    resizable: true,
    width: 100,
    sortable: true,
    editable: false,
    headerRenderer(props) {
      const ewpId = useParams<IRouteParamsBase>().id;
      const { handleSelectDateFilter, query } = useTableData(TableDataEntities.EWP_KA_LIST, {
        ewp_id: ewpId,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'estimated_completion_date'}
            selectedFilters={query.dateFilters['estimated_completion_date']}
            periodFieldName={'estimated_completion_date_period'}
            dateRangeFieldName={'estimated_completion_date_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
          />
        );
      }
    },
    formatter(props) {
      return (
        <>
          {props.row.estimated_completion_date
            ? basicDateFormat(props.row.estimated_completion_date)
            : t('common:column.n-a')}
        </>
      );
    },
  },
  {
    key: 'status_name',
    name: t('common:column.status'),
    resizable: true,
    sortable: true,
    width: 135,
    editable: false,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      return (
        <ElementTableStatusCombined
          type={EwpElementTypes.key_action}
          approval_status={props.row.approval_status}
          approval_status_name={props.row.approval_status_name}
          status={props.row.status}
          status_name={props.row.status_name}
          ewp_status_id={props.row.ewp_status_id}
          has_approved_parents={props.row.has_approved_parents}
        />
      );
    },
  },
  {
    key: 'tools',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
  },
  {
    key: 'select',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
    headerRenderer(props) {
      return (
        <Checkbox
          checked={props.allRowsSelected}
          onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
        />
      );
    },
    formatter: props => {
      const [isRowSelected, onRowSelectionChange] = useRowSelection();

      return (
        <div className="b-rdgTable__cellCenter">
          <div className={'checkBox'} onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={isRowSelected}
              onChange={e => {
                onRowSelectionChange({
                  row: props.row,
                  checked: e.target.checked,
                  isShiftClick: false,
                });
              }}
            />
          </div>
        </div>
      );
    },
  },
];
