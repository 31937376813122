import React from 'react';
import './Button.scss';

interface IProps {
  style?: Object;
  disabled?: boolean;
  source: string;
  onPress: () => void;
  type?: 'orange' | 'orange-light' | 'transparency' | 'white' | undefined;
  customProps?: any;
  className?: string;
  size?: 'small' | 'large' | 'middle' | 'x';
  width?: string | number;
  height?: string | number;
}

const getStyle = (type: 'orange' | 'orange-light' | 'transparency' | 'white' | undefined) => {
  switch (type) {
    case 'orange':
      return 'orange';
    case 'orange-light':
      return 'orange-light';
    case 'transparency':
      return 'transparency';
    case 'white':
      return 'white';
    default:
      return 'orange';
  }
};

const ButtonImage = ({
  disabled,
  onPress,
  type,
  customProps,
  className,
  size,
  source,
  height,
  width,
}: IProps) => {
  return (
    <button
      disabled={disabled}
      onClick={onPress}
      className={`
            button 
            ${getStyle(type)} ${(className && className) || ''}
            ${size && `button-${size}`}
            `}
      style={{
        width: width,
        height: height,
        padding: '1px',
        marginRight: '8px',
      }}
      {...((customProps && customProps) || {})}
    >
      <img width={width} height={height} src={source} alt={`button-${type}`} />
    </button>
  );
};

ButtonImage.defaultProps = {
  type: 'navigation',
};

export default ButtonImage;
