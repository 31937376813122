import React from 'react';
import { Button } from '../../../controls';
import Input from '../../../controls/Inputs/Input';
import './ChangePasswordStyle.scss';
import { Footer, Header } from '../../../common';
import { useTranslation } from 'react-i18next';
import ModalView from '../../../controls/ModalView/ModalView';
import { ModalsModel } from '../../../../constants';

interface IProps {
  errors: any;
  loader?: string;
  password: string;
  confirmPassword: string;
  onChange: (field: string) => (value: string) => void;
  onSubmit: () => void;
  handleOnBlur: (field: string) => () => void;
  modals: ModalsModel;
  handleCloseExpiredModel: () => void;
}

const ChangePasswordView = ({
  errors,
  loader,
  password,
  confirmPassword,
  onChange,
  onSubmit,
  handleOnBlur,
  modals,
  handleCloseExpiredModel,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      {loader}
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        <main className={'white-container content-center-wrapper'}>
          <div className={'container-page-center'}>
            <div className={'container-row'}>
              <h1 className={'b-title gray-text'}>{t('awa:M99.title')}</h1>
            </div>
            <div className={'container-row'}>
              <Input
                error={errors.password}
                value={password}
                type={'password'}
                handleUnselect={handleOnBlur('password')}
                placeholder={t('common:label.password')}
                ariaLabel="password"
                handleChange={onChange('password')}
                autoFocus
              />
            </div>
            <div className={'container-row'}>
              <Input
                error={errors.confirmPassword}
                value={confirmPassword}
                type={'password'}
                handleUnselect={handleOnBlur('confirmPassword')}
                placeholder={t('common:label.confirm-password')}
                ariaLabel="Confirm Password"
                handleChange={onChange('confirmPassword')}
              />
            </div>
            <div className={'container-row'}>
              <Button
                onPress={onSubmit}
                title={t('common:btn.submit')}
                type={'orange'}
                size={'small'}
                disabled={false}
                enableEnter
              />
            </div>
          </div>
        </main>
        <Footer />
      </div>
      <ModalView
        isOpen={!!modals.isExpiredHash}
        onRequestClose={handleCloseExpiredModel}
        contentStyles={{
          transform: window.matchMedia('(max-width: 824px)').matches
            ? 'translate(-50%, -45%)'
            : 'translate(-50%, -58%)',
        }}
      >
        <>
          <div className={'b-modal__forgot-password'}>
            <h2 className={`b-modal__title`}>{t('awa:ER10.title')}</h2>
            {t('awa:ER10.msg', { x: modals.isMaxUsers })}
          </div>
          <div className="b-modal__buttons">
            <Button
              onPress={handleCloseExpiredModel}
              title={t('common:btn.ok')}
              size={'large'}
              type={'transparency'}
            />
          </div>
        </>
      </ModalView>
    </>
  );
};

export default ChangePasswordView;
