import { ActionModel } from '../../../models';
import { actionCreator } from '../../actionCreator';
import { BelEaDetailsModel } from '../../../models';
import { LOGOUT_SUCCESS } from '../../authData/authDataActions';

//-----BEL_GET_EA_DETAILS---//
export const BEL_GET_EA_DETAILS = 'beloved/BEL_GET_EA_DETAILS';
export const BEL_GET_EA_DETAILS_SUCCESS = 'beloved/BEL_GET_EA_DETAILS_SUCCESS';
export interface BelGetEaDetailsPayload {
  orgId: number;
  callback?: (data: BelEaDetailsModel) => void;
}
export const belGetEaDetails = actionCreator<BelGetEaDetailsPayload>(BEL_GET_EA_DETAILS);
export const belGetEaDetailsSuccess = actionCreator<BelEaDetailsModel>(BEL_GET_EA_DETAILS_SUCCESS);

export type BelEaActionsTypes =
  | ActionModel<typeof BEL_GET_EA_DETAILS_SUCCESS, BelEaDetailsModel>
  | ActionModel<typeof LOGOUT_SUCCESS>;
