import React, { useEffect, useState } from 'react';
import { Footer, Header } from '../../../common';
import styles from './RequestSubmitPendingStyle.module.scss';
import Input from '../../../controls/Inputs/Input';
import { useDispatch, useSelector } from 'react-redux';
import {
  rejectJoinOrganization,
  resendJoinOrganization,
} from '../../../../store/organization/organizationActions';
import { StateType } from '../../../../store/reducers';
import { getUser } from '../../../../store/profile/profileActions';
import ModalView from '../../../controls/ModalView/ModalView';
import { isMobileOnly } from 'react-device-detect';
import { Image } from '../../../controls';
import Logo from '../../../../assets/images/icons/group.png';
import { useTranslation } from 'react-i18next';

const RequestSubmitPending = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const user = useSelector((state: StateType) => state.profile.authUser);

  useEffect(() => {
    dispatch(getUser({}));
  }, []);

  const handleReject = () => {
    if (!user?.join_request) {
      return;
    }
    dispatch(
      rejectJoinOrganization({
        callback: () => {
          dispatch(getUser({}));
        },
      }),
    );
  };

  const handleResend = () => {
    if (!user?.join_request) {
      return;
    }
    dispatch(
      resendJoinOrganization({
        data: user.join_request,
        callback: () => {
          setModal(true);
        },
      }),
    );
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        <main className={'white-container content-center-wrapper d-block'}>
          <div className={'container-page-center'}>
            <div className={'container-row'}>
              <span className={styles.title}>{t('auth:submit-pending.title')}</span>
            </div>
            <div className={`container-row mt-0 mb-0`}>
              <div className={'input-wrapper'}>
                <Input
                  value={user.join_request?.name || ''}
                  type={'text'}
                  disabled={true}
                  placeholder={t('common:label.organization-official-name')}
                />
              </div>
            </div>
            <div className={`container-row mt-0 mb-0`}>
              <div className={'input-wrapper'}>
                <Input
                  value={user.join_request?.division || ''}
                  type={'text'}
                  disabled={true}
                  placeholder={t('common:label.division/department/unit')}
                />
              </div>
            </div>
            <div className={`container-row mt-0 mb-0`}>
              <div className={'input-wrapper'}>
                <Input
                  value={user.join_request?.email || ''}
                  type={'text'}
                  disabled={true}
                  placeholder={t('common:label.pm-email')}
                />
              </div>
            </div>
            <div className={`container-row mt-0 mb-0`}>
              <div className={styles.wrap}>
                <div className={styles.options_title}>You have 2 options:</div>
                <div className={styles.options}>
                  <div className={styles.options_item}>
                    <span className={styles.action} onClick={handleReject}>
                      {t('common:btn.cancel-request')}
                    </span>
                    <div className={styles.text}>{t('auth:submit-pending.msg.p1')}</div>
                  </div>
                  <div className={styles.options_item}>
                    <span className={styles.action} onClick={handleResend}>
                      {t('common:btn.resubmit-request')}
                    </span>
                    <div className={styles.text}>{t('auth:submit-pending.msg.p2')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ModalView
          isOpen={modal}
          onRequestClose={() => setModal(false)}
          contentStyles={{
            transform: window.matchMedia('(max-width: 824px)').matches
              ? 'translate(-50%, -45%)'
              : 'translate(-50%, -58%)',
          }}
        >
          <div className={`${styles.container} ${isMobileOnly ? styles.mobileContainer : ''}`}>
            <div className={`text-container`}>
              <div className={'container-row'}>
                <Image width={'190px'} height={'190px'} alt={t('awa:AT37')} source={Logo} />
              </div>
              <div className={'container-row'}>
                <span className={styles.title}>{t('auth:submit-pending.msg.p3')}</span>
              </div>
            </div>
          </div>
        </ModalView>
        <Footer />
      </div>
    </>
  );
};

export default RequestSubmitPending;
