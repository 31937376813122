import { GoogleCalendarsModel } from '../../../../models';

export const insertAccessToCalendar = async (
  accessToken: string,
  selectedCalendars: GoogleCalendarsModel,
) => {
  const REACT_APP_GOOGLE_SERVICE_ACCOUNT = process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT as string;
  const body = {
    role: 'writer',
    scope: {
      type: 'user',
      value: REACT_APP_GOOGLE_SERVICE_ACCOUNT,
    },
  };

  const allSelectedCalendars = new Map();
  selectedCalendars?.busy.map(calendar => allSelectedCalendars.set(calendar.value, calendar));
  selectedCalendars?.events.map(calendar => allSelectedCalendars.set(calendar.value, calendar));
  return await Promise.all(
    // @ts-ignore
    [...allSelectedCalendars.values()].map(async calendar => {
      const resp = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/${calendar.value}/acl?access_token=${accessToken}`,
        {
          method: 'POST',
          body: JSON.stringify(body),
        },
      );
      return resp.json();
    }),
  );
};

export const getCalendarListFetch = async (accessToken: string) => {
  const req = await fetch(
    `https://www.googleapis.com/calendar/v3/users/me/calendarList?access_token=${accessToken}`,
  );
  return await req.json();
};

export const removeRuleIds = async (accessToken: string, selectedCalendars: GoogleCalendarsModel) => {
  const REACT_APP_GOOGLE_SERVICE_ACCOUNT = process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT;
  const ruleId = `user:${REACT_APP_GOOGLE_SERVICE_ACCOUNT}`;

  const allSelectedCalendars = new Map();
  selectedCalendars?.busy.map(calendar => allSelectedCalendars.set(calendar.value, calendar));
  selectedCalendars?.events.map(calendar => allSelectedCalendars.set(calendar.value, calendar));
  return await Promise.all(
    // @ts-ignore
    [...allSelectedCalendars.values()].map(async calendar => {
      const resp = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/${calendar.value}/acl/${ruleId}?access_token=${accessToken}`,
        {
          method: 'DELETE',
        },
      );
      return resp;
    }),
  );
};

export const getUserInfoFetch = async (accessToken: string) => {
  const req = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${accessToken}`);
  return await req.json();
};
