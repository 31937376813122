import React, { ReactNode } from 'react';
import { ElmModel } from '../models';
import { ElmRoles } from '../constants/elm';

export const isElmUser = (elm: ElmModel, isPm: boolean): boolean => {
  // if user not pm and has ELM role = user
  if (!elm.user_elm_rel.elm_roles.length) return false;
  return !isPm && elm.user_elm_rel.elm_roles.includes(ElmRoles.USER);
};

export const hasElmRole = (elm: ElmModel, role: ElmRoles) => {
  return elm.user_elm_rel.elm_roles.includes(role);
};

export const getIdentityLabel = (name: string, isStr?: boolean): ReactNode => {
  switch (name) {
    case 'group_a':
      if (isStr) {
        return 'Man, Cisgender Man';
      }
      return (
        <div>
          Man <br /> Cisgender Man
        </div>
      );
    case 'group_b':
      if (isStr) {
        return 'Woman, Cisgender Woman, Transgender, Non Binary, Non Conforming, Queer, Agender, Self-describe';
      }
      return (
        <div>
          Woman
          <br />
          Cisgender Woman
          <br />
          Transgender
          <br />
          Non Binary
          <br />
          Non Conforming
          <br />
          Queer
          <br />
          Agender
          <br />
          Self-describe
        </div>
      );
    default:
      return name;
  }
};
