import React, { useEffect, useState } from 'react';
import { Button } from '../../../controls';
import styles from './JoinOrganizationPreviewStyle.module.scss';
import { Alert, Footer, Header } from '../../../common';
import { ROOTS } from '../../../../routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import { setSeenDeclinePopup } from '../../../../store/authData/authDataActions';
import { useTranslation } from 'react-i18next';

interface IProps {
  loader?: string;
  disable: boolean;
  handleChangeStep: (route: string) => void;
}

const JoinOrganizationPreviewView = ({ loader, handleChangeStep, disable }: IProps) => {
  const { t } = useTranslation();
  const [newAccount, setNewAccount] = useState(true);
  const [orgName, setOrgName] = useState('');
  const [orgAdminName, setAdminName] = useState('');
  const [declineModel, setDeclineModel] = useState<boolean>(false);
  const dispatch = useDispatch();
  const user = useSelector((state: StateType) => {
    return state.profile.authUser;
  });

  useEffect(() => {
    if (user !== null && user.orgs_role && user.orgs_role.length > 0) {
      if (user.orgs_role[0].user_type_id === 3) {
        setNewAccount(false);
      }
      setAdminName(user.orgs_role[0].invited_by_name);
      setOrgName(user.orgs_role[0].org_name);
    }
    if (user && user.join_request?.is_declined && !user.join_request?.popup_seen) {
      setDeclineModel(true);
    }
  }, [user]);

  const getContent = (status: boolean) => {
    if (status) {
      return t('awa:M4.msg.p1');
    } else {
      return t('awa:M4.msg.p2', { orgAdminName, orgName });
    }
  };

  const handleHideDeclineModel = () => {
    dispatch(setSeenDeclinePopup());
    setDeclineModel(false);
  };

  return (
    <>
      {loader}
      <div className={'container container-header white-container'}>
        <Header type={'awa'} />
        <main className={`white-container ${styles.wrapper}`}>
          <div className={'container-page-center'}>
            <div className={'container-row m-0'}>
              <span className={`b-title blue-text ${styles.title}`}>{t('awa:M2.title.p1')}</span>
            </div>
            <div className={'container-row m-0'}>
              <span className={'b-title orange-text m-0'}>{t('awa:M2.title.p2')}</span>
            </div>
            <div className={`${styles.container} container-row`}>
              <span className={'text-description gray-text'}>{getContent(newAccount)}</span>
            </div>
            <div className={'container-row'}>
              <Button
                onPress={() => handleChangeStep(ROOTS.ORGANIZATION_PREVIEW_CREATE)}
                title={t('common:btn.create-new-organization')}
                type={'orange-light'}
                disabled={disable}
                className={styles.button}
              />
            </div>
            <div className={'container-row'}>
              <Button
                onPress={() => handleChangeStep(ROOTS.ORGANIZATION_PREVIEW_JOIN)}
                title={t('common:btn.join-existing-organization')}
                type={'orange-light'}
                disabled={disable}
                className={styles.button}
              />
            </div>
          </div>
        </main>
        <Footer />
      </div>
      {declineModel ? (
        <Alert
          isOpen={declineModel}
          onRequestClose={handleHideDeclineModel}
          title={`Request to join denied`}
          text={
            <>
              <p>{t('auth:decline-request.title', { first_name: user?.user.first_name })}</p>
              <p>{t('auth:decline-request.msg.p1')}</p>
              <p>{t('auth:decline-request.msg.p2')}</p>
            </>
          }
        />
      ) : null}
    </>
  );
};

export default JoinOrganizationPreviewView;
