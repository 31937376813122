import React, { FC, useEffect, useState } from 'react';
import {
  basicDateFormat,
  convertToURL,
  cutString,
  sanitizedHTML,
  urlWithToken,
  validateURL,
} from '../../../../../../helpers';
import { Editor, Link, Loader } from '../../../../../controls';
import { useTranslation } from 'react-i18next';
import { BasicFileModel, EwpCreateStepEnum, EWPModel } from '../../../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../../store/reducers';
import useHasAccess from '../../../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../../../constants/roles';
import { useError } from '../../../../../../hooks/common/use-error';
import { updateEWP, UpdateEWPPayload } from '../../../../../../store/ewp/ewpActions';
import Api from '../../../../../../services/api';
import { DOMAIN_URI } from '../../../../../../constants';

interface IState {
  editVision: boolean;
  fields: {
    vision: string;
    introduction: string;
    dei_statement: string;
    plan_document_link: string;
    plan_document_id: number | null;
  };
}

interface IProps {
  ewp: EWPModel;
}

const VisionForm: FC<IProps> = ({ ewp }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAccessToEwpCRUD = useHasAccess(BelovedPermissions.EWP_CRUD);
  const isBeloved = useSelector((state: StateType) => state.profile.authUser?.beloved_user);
  const isAllowEditVision = ewp?.ewp_permissions.ewp_obj.edit || (isBeloved && hasAccessToEwpCRUD);
  let { error, setError } = useError<any>({});
  let [file, setFile] = useState<BasicFileModel | null>(null);
  let [fileLoading, setFileLoading] = useState(false);

  const initialState: IState = {
    editVision: false,
    fields: {
      dei_statement: '',
      vision: '',
      introduction: '',
      plan_document_link: '',
      plan_document_id: null,
    },
  };

  let [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (ewp) {
      fillVisionForm(ewp);
    }
  }, [ewp]);

  const fillVisionForm = (ewp: EWPModel) => {
    setFile(ewp.plan_document);
    setState(prevState => {
      const newState: IState = {
        ...prevState,
        fields: {
          ...prevState.fields,
          introduction: ewp.introduction,
          vision: ewp.vision,
          dei_statement: ewp.dei_statement,
          plan_document_link: ewp.plan_document_link || '',
          plan_document_id: ewp.plan_document?.id || null,
        },
      };
      return newState;
    });
  };

  const handleCancelVision = () => {
    if (!ewp) return;
    setState({ ...state, editVision: false });
    fillVisionForm(ewp);
  };

  const handleSaveVision = () => {
    if (!ewp) return;

    if (state.fields.plan_document_link && !validateURL(state.fields.plan_document_link)) {
      setError({ docLink: t('common:errors.invalid-URL') });
      return;
    }

    const data: UpdateEWPPayload['data'] = {
      ewp_screen: EwpCreateStepEnum.finish,
      introduction: state.fields.introduction,
      vision: state.fields.vision,
      dei_statement: state.fields.dei_statement,
      plan_document_link: state.fields.plan_document_link,
      plan_document_id: state.fields.plan_document_id,
    };
    dispatch(
      updateEWP({
        ewpId: ewp.id,
        orgId: ewp.organization_id,
        isDetails: true,
        data,
        callback: data => {
          if (data) {
            setState({ ...state, editVision: false });
          }
        },
      }),
    );
  };

  const handleChangeInput = (name: string, value: string) => {
    setError({});
    setState(state => {
      return {
        ...state,
        fields: {
          ...state.fields,
          [name]: value,
        },
      };
    });
  };

  const handleRemoveFile = async () => {
    if (!file) return;
    setFile(null);
    setState(state => {
      return {
        ...state,
        fields: {
          ...state.fields,
          plan_document_id: null,
        },
      };
    });
  };

  const validateFile = ({ type }: File): boolean => {
    let isValid = true;
    const isValidType = type.match(/(pdf)$/);
    if (!isValidType) {
      setError({ ...error, file: t('common:errors.pdf-file-type') });
      isValid = false;
    }
    return isValid;
  };

  const handleAddFile = async (e: any) => {
    const isValidType = validateFile(e.target.files[0]);
    if (!isValidType) return null;

    setError({});
    setFileLoading(true);
    const response = await Api.uploadFile(e.target.files[0], 0);
    setFileLoading(false);
    setFile(response.data.result.resource);
    setState(state => {
      return {
        ...state,
        fields: {
          ...state.fields,
          plan_document_id: response.data.result.resource.id,
        },
      };
    });
  };

  const renderDocTitle = () => {
    if (ewp) {
      if (ewp.plan_document_link || ewp.plan_document) {
        return (
          <div>
            {ewp.plan_document_link ? (
              <a
                className="d-block pl-4 mb-1 orange-light-text font-weight-bold"
                href={convertToURL(ewp.plan_document_link)}
                target="_blank"
                rel="noreferrer"
              >
                {cutString(ewp.plan_document_link, 50)}
              </a>
            ) : null}
            {ewp.plan_document ? (
              <a
                className="d-block pl-4 orange-light-text font-weight-bold"
                href={`${urlWithToken(`${DOMAIN_URI}${ewp.plan_document.file}`)}`}
                target="_blank"
                rel="noreferrer"
              >
                {cutString(ewp.plan_document.original_file_name, 50)}
              </a>
            ) : null}
          </div>
        );
      } else {
        return <div className="pl-4 font-grey font-weight-bold">{t('common:column.n-a')}</div>;
      }
    }
    return null;
  };

  return (
    <>
      {fileLoading ? <Loader /> : null}
      <div className="d-flex justify-content-end mb-3">
        {ewp.last_update ? (
          <div className="font-s font-grey mr-3">
            {t('common:column.last-updated')}: {basicDateFormat(ewp.last_update)}
          </div>
        ) : null}
        {isAllowEditVision ? (
          <>
            {state.editVision ? (
              <>
                <Link onClick={handleCancelVision} className="orange-light-text mr-3">
                  {t('common:btn.cancel')}
                </Link>
                <Link onClick={handleSaveVision} className="orange-light-text">
                  {t('common:btn.save')}
                </Link>
              </>
            ) : (
              <Link onClick={() => setState({ ...state, editVision: true })} className="b-link-icon -edit">
                {t('common:btn.edit')}
              </Link>
            )}
          </>
        ) : null}
      </div>
      <div className="b-panelData">
        {state.editVision ? (
          <div className="b-resourceForm__field -file mb-4">
            <div className="font-grey">
              <div>{t('common:label.strategic-plan-document')}:</div>
              {error.docLink ? <div className="error">{error.docLink}</div> : null}
            </div>
            <div>
              <input
                aria-label="Link"
                placeholder={t('common:label.url-of-the-document')}
                className="b-resourceForm__fieldInput"
                type="text"
                value={state.fields.plan_document_link}
                onChange={e => handleChangeInput('plan_document_link', e.target.value)}
              />
            </div>
            <div>
              {file ? (
                <div className="b-resourceForm__fileName">
                  <div>{cutString(file.original_file_name, 20)}</div>
                  <button onClick={handleRemoveFile} className="b-button-icon -deleteFile" />
                </div>
              ) : (
                <div>
                  <label className="b-uploadInput__label">
                    {t('common:label.upload-document')}
                    <input
                      id="doc-input"
                      accept="application/pdf"
                      className="b-uploadInput"
                      type="file"
                      onChange={e => handleAddFile(e)}
                    />
                  </label>
                  {error.file ? <div className="error">{t('common:errors.pdf-file-type')}</div> : null}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="d-flex mb-4">
            <div className="font-grey">{t('common:label.strategic-plan-document')}:</div>
            {renderDocTitle()}
          </div>
        )}
        <div
          className={`pb-2 mb-4 b-ewp__border b-ewp__wysiwyg ${
            !state.fields.dei_statement ? '-placeholder' : ''
          }`}
        >
          <div className="font-m font-weight-bold mb-4 font-navy">{t('common:label.dei-statement')}</div>
          {state.editVision ? (
            <div className={`mb-4`}>
              <Editor
                onChange={value => handleChangeInput('dei_statement', value)}
                defaultValue={sanitizedHTML(state.fields.dei_statement)}
                placeholder={t('common:label.ewp.dei_statement')}
              />
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizedHTML(ewp.dei_statement) || t('common:label.ewp.dei_statement'),
              }}
              className="b-editorText font-s font-grey mb-4 text-break"
            />
          )}
        </div>
        <div
          className={`pb-2 mb-4 b-ewp__border b-ewp__wysiwyg ${
            !state.fields.introduction ? '-placeholder' : ''
          }`}
        >
          <div className="font-m font-weight-bold mb-4 font-navy">
            {t('common:label.organization-introduction')}
          </div>
          {state.editVision ? (
            <div className="mb-4">
              <Editor
                onChange={value => handleChangeInput('introduction', value)}
                defaultValue={sanitizedHTML(state.fields.introduction)}
                placeholder={t('common:label.ewp.introduction')}
              />
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizedHTML(ewp.introduction || t('common:label.ewp.introduction')),
              }}
              className="b-editorText font-s font-grey mb-4 text-break"
            />
          )}
        </div>
        <div className={`mb-4 b-ewp__wysiwyg ${!state.fields.vision ? '-placeholder' : ''}`}>
          <div className="font-m font-weight-bold mb-4 font-navy">{t('common:label.vision')}</div>
          {state.editVision ? (
            <Editor
              onChange={value => handleChangeInput('vision', value)}
              defaultValue={sanitizedHTML(state.fields.vision)}
              placeholder={t('common:label.ewp.vision')}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizedHTML(ewp.vision || t('common:label.ewp.vision')),
              }}
              className="b-editorText font-s font-grey mb-4 text-break"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default VisionForm;
