import React, { FC } from 'react';
import { EwpElementStatuses } from '../../../../../models';
import { useTranslation } from 'react-i18next';

interface IProps {
  status: EwpElementStatuses;
}

const EwpElementsStatusLabel: FC<IProps> = ({ status }) => {
  const { t } = useTranslation();
  switch (status) {
    case EwpElementStatuses.NOT_STARTED:
      return <div className="b-status -prepCompl">{t('common:label.Not-Started-Yet')}</div>;
    case EwpElementStatuses.IN_PROGRESS:
      return <div className="b-status -inProgress">{t('common:status.in-progress')}</div>;
    case EwpElementStatuses.STALLED:
      return <div className="b-status -darkNavy">{t('common:label.stalled')}</div>;
    case EwpElementStatuses.NO_LONGER_PURSUING:
      return <div className="b-status -color5">{t('common:label.No-Longer-Pursuing')}</div>;
    case EwpElementStatuses.COMPLETED:
      return <div className="b-status -completed">{t('common:status.completed')}</div>;
    default:
      return null;
  }
};

export default EwpElementsStatusLabel;
