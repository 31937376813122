import React, { FC } from 'react';
import BG from '../../../../../assets/images/debrief/7.png';
import { DebriefModel, DebriefOrgTypeModel } from '../../../../../models';
import { useTranslation } from 'react-i18next';

interface IProps {
  data: DebriefModel;
  isFullScreen: boolean;
}

const Slide2: FC<IProps> = ({ data, isFullScreen }) => {
  const { t } = useTranslation();
  const middle = Math.round(data.org_types.length / 2);

  let list1: DebriefOrgTypeModel[] = data.org_types;
  let list2: DebriefOrgTypeModel[] = [];

  if (data.org_types.length > 8) {
    list1 = data.org_types.slice(0, middle);
    list2 = data.org_types.slice(middle, data.org_types.length);
  }

  const splitName = (name: string) => {
    if (name.indexOf('/') > -1) {
      const arr = name.split('/');
      const arr2 = arr.map((str, index) => {
        if (index !== arr.length - 1) {
          return str + '/';
        }
        return str;
      });
      return arr2.join(' ');
    }
    return name;
  };

  return (
    <div className="b-viewDebriefSlide2__wrapper">
      <img src={BG} alt={t('common:alt.bg')} />
      <div className={`b-viewDebriefSlide2 ${isFullScreen ? '-fullHeight' : ''}`}>
        <div className="b-viewDebriefSlide2__row b-viewDebriefSlide2__decor">
          <div className="b-viewDebriefSlide2__square -yellow">
            <div className="b-viewDebriefSlide2__title">{t('awa:M61.msg.p3')}</div>
            <div className="b-viewDebriefSlide2__text">{t('awa:M61.msg.p4')}</div>
          </div>
          <div className="b-viewDebriefSlide2__square -yellow">
            <div className="b-viewDebriefSlide2__title">{t('awa:M61.msg.p5')}</div>
            <div className="b-viewDebriefSlide2__text">26% to 50%</div>
          </div>
          <div className="b-viewDebriefSlide2__square -yellow">
            <div className="b-viewDebriefSlide2__title">{t('awa:M61.msg.p6')}</div>
            <div className="b-viewDebriefSlide2__text">51% to 74%</div>
          </div>
          <div className="b-viewDebriefSlide2__square -yellow">
            <div className="b-viewDebriefSlide2__title">{t('awa:M61.msg.p7')}</div>
            <div className="b-viewDebriefSlide2__text -yellow">75% to 90%</div>
          </div>
          <div className="b-viewDebriefSlide2__square -yellow">
            <div className="b-viewDebriefSlide2__title">{t('awa:M61.msg.p8')}</div>
            <div className="b-viewDebriefSlide2__text">91% to 100%</div>
          </div>
        </div>
        <div className="b-viewDebriefSlide2__decor -mb15 -orange">
          <div className="b-viewDebriefSlide2__row">
            {list1.map(item => {
              return (
                <div key={`org-t-${item.id}`} className="b-viewDebriefSlide2__square -orange">
                  {splitName(item.name)}
                </div>
              );
            })}
          </div>
          {list2.length ? (
            <div className="b-viewDebriefSlide2__row -noMb">
              {list2.map(item => {
                return (
                  <div key={`org-t-${item.id}`} className="b-viewDebriefSlide2__square -orange">
                    {splitName(item.name)}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <div className="b-viewDebriefSlide2__row b-viewDebriefSlide2__decor -red">
          <div className="b-viewDebriefSlide2__square -red">
            <div className="b-viewDebriefSlide2__title">{t('awa:M35.msg.p9')}</div>
            <div className="b-viewDebriefSlide2__text">
              {t('awa:M35.msg.p10.title')} {t('awa:M35.msg.p10.msg')}
            </div>
            <div className="b-viewDebriefSlide2__text">
              {t('awa:M35.msg.p11.title')} {t('awa:M35.msg.p11.msg')}
            </div>
            <div className="b-viewDebriefSlide2__text">
              {t('awa:M35.msg.p12.title')} {t('awa:M35.msg.p12.msg')}
            </div>
            <div className="b-viewDebriefSlide2__text">
              {t('awa:M35.msg.p13.title')} {t('awa:M35.msg.p13.msg')}
            </div>
          </div>
          <div className="b-viewDebriefSlide2__square -red">
            <div className="b-viewDebriefSlide2__title">{t('awa:M35.msg.p14')}</div>
            <div className="b-viewDebriefSlide2__text">
              {t('awa:M35.msg.p15.title')} {t('awa:M35.msg.p15.msg')}
            </div>
            <div className="b-viewDebriefSlide2__text">
              {t('awa:M35.msg.p16.title')} {t('awa:M35.msg.p16.msg')}
            </div>
            <div className="b-viewDebriefSlide2__text">
              {t('awa:M35.msg.p17.title')} {t('awa:M35.msg.p17.msg')}
            </div>
            <div className="b-viewDebriefSlide2__text">
              {t('awa:M35.msg.p18.title')} {t('awa:M35.msg.p18.msg')}
            </div>
            <div className="b-viewDebriefSlide2__text">
              {t('awa:M35.msg.p19.title')} {t('awa:M35.msg.p19.msg')}
            </div>
          </div>
          <div className="b-viewDebriefSlide2__square -red">
            <div className="b-viewDebriefSlide2__title">{t('awa:M35.msg.p20')}</div>
            <div className="b-viewDebriefSlide2__text">
              {t('awa:M35.msg.p21.title')} {t('awa:M35.msg.p21.msg')}
            </div>
            <div className="b-viewDebriefSlide2__text">
              {t('awa:M35.msg.p22.title')} {t('awa:M35.msg.p22.msg')}
            </div>
            <div className="b-viewDebriefSlide2__text">
              {t('awa:M35.msg.p23.title')} {t('awa:M35.msg.p23.msg')}
            </div>
            <div className="b-viewDebriefSlide2__text">
              {t('awa:M35.msg.p24.title')} {t('awa:M35.msg.p24.msg')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide2;
