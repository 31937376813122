import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IRouteParamsBase, KeyValueModel } from '../../../../../models';
import { StateType } from '../../../../../store/reducers';
import { ROOTS } from '../../../../../routes/routes';
import { ChartPieV2, HeadlineOrganization } from '../../../../common';
import { getElmReport } from '../../../../../store/elm/elmActions';
import { Loader } from '../../../../controls';
import { isEmptyObject, createArrayFrom, getIdentityLabel } from '../../../../../helpers';
import { ChartPieV2ItemModel } from '../../../../common/ChartPieV2/ChartPieV2';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { useTranslation } from 'react-i18next';

interface IRouteParams extends IRouteParamsBase {
  elmId: string;
}

const ElmOrgReportResp = () => {
  const { orgId, elmId } = useParams<IRouteParams>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let [orgName, setOrgName] = useState<string | null>(null);
  let [noSubmitted, setNoSubmitted] = useState<boolean | null>(null);
  const elmOrgReport = useSelector((state: StateType) => state.elm.elmOrgReport);
  const history = useHistory();
  const { navBack } = useNavBack();

  useEffect(() => {
    dispatch(
      getElmReport({
        data: {
          elmId: Number(elmId),
          type: 'org',
        },
        callback: ({ org_name, no_submitted }) => {
          if (org_name) {
            setOrgName(org_name);
          }
          if (no_submitted) {
            setNoSubmitted(no_submitted);
          }
        },
      }),
    );
  }, []);

  const handlePressTab = (key: 'resp' | 'scores') => {
    if (key === 'scores') {
      history.replace(
        ROOTS.ELM_ORG_REPORT_DOMAIN_RESULTS.replace(':orgId', `${orgId}`).replace(':elmId', `${elmId}`),
      );
    }
  };

  if (!elmOrgReport) return <Loader />;

  const getChartData = (
    data: KeyValueModel<{ per: number; count: number }>,
  ): KeyValueModel<ChartPieV2ItemModel> => {
    const res: KeyValueModel<ChartPieV2ItemModel> = {};
    for (let param in data) {
      res[param] = {
        label: getIdentityLabel(param.toString(), true),
        percent: data[param].per,
        val: data[param].count,
      };
    }
    return res;
  };

  const renderContent = () => {
    if (noSubmitted) {
      return (
        <div className="b-panel b-typography b-elmReport2__noContent">
          <p>{t('awa:M214.msg')}</p>
        </div>
      );
    }
    if (isEmptyObject(elmOrgReport)) {
      return (
        <div>
          <div className="b-panel b-typography b-elmReport2__noContent">
            <p>{t('pp:elm-report-3.p1')}</p>
            <p>- {t('pp:elm-report-3.p2')}</p>
            <p>- {t('pp:elm-report-3.p3')}</p>
            <p>- {t('pp:elm-report-3.p4')}</p>
            <p>- {t('pp:elm-report-3.p5')}</p>
          </div>
        </div>
      );
    }
    return (
      <>
        {elmOrgReport.domains_report ? (
          <div className="b-elmReport2__headMenu">
            <div className="b-elmReport2__tabs">
              <a tabIndex={0} className="-active">
                {t('common:label.Response-Rate')}
              </a>
              <a onClick={() => handlePressTab('scores')} tabIndex={0}>
                {t('common:label.ELM-Domain-Scores')}
              </a>
            </div>
          </div>
        ) : null}
        {elmOrgReport.response_rate ? (
          <div className="b-elmReport2__rate b-elmReport2__panel">
            <div>{t('common:label.Response-Rate')}</div>
            <div className="b-elmReport2__rateVal">
              <div className="b-elmReport2__rateVal-item">
                <div>{elmOrgReport.response_rate_by_role_type.all}%</div>
                <div className={'b-elmReport2__rateVal-subTitle'}>{t('common:label.overall')}</div>
              </div>
              <div className="b-elmReport2__rateVal-item">
                <div>{elmOrgReport.response_rate_by_role_type.leadership}%</div>
                <div className={'b-elmReport2__rateVal-subTitle'}>{t('common:label.leadership')}</div>
              </div>
              <div className="b-elmReport2__rateVal-item">
                <div>{elmOrgReport.response_rate_by_role_type.staff}%</div>
                <div className={'b-elmReport2__rateVal-subTitle'}>{t('common:label.staff')}</div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="b-elmReport2__pieCharts">
          {elmOrgReport.res_by_divisions ? (
            <div className="b-elmReport2__panel b-elmReport2__piePanel">
              <h2> {t('common:label.ELM-Responses-by-Division')}</h2>
              <div className="b-elmReport2__pieWrapper">
                <ChartPieV2
                  title={'Division/Departments/Units:'}
                  colors={createArrayFrom(
                    ['#d12e30', '#ee6513', '#fb9312', '#fcc500', '#f6e324'],
                    Object.keys(elmOrgReport.res_by_divisions).length,
                  )}
                  type="elmReport"
                  chart={getChartData(elmOrgReport.res_by_divisions)}
                />
              </div>
            </div>
          ) : null}
          {elmOrgReport.res_by_race ? (
            <div className="b-elmReport2__panel b-elmReport2__piePanel">
              <h2> {t('common:label.ELM-Responses-by-Race')}</h2>
              <div className="b-elmReport2__pieWrapper">
                <ChartPieV2
                  title={'Race Groups:'}
                  colors={createArrayFrom(
                    ['#d12e30', '#ee6513', '#fb9312', '#fcc500', '#f6e324'],
                    Object.keys(elmOrgReport.res_by_race).length,
                  )}
                  type="elmReport"
                  chart={getChartData(elmOrgReport.res_by_race)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <>
      <div className={'pageContainer pageContainer__content'}>
        <HeadlineOrganization
          orgName={orgName || ''}
          orgId={orgId || ''}
          headline={t('common:headlines.equity-lens-map')}
          customClasses={'-desktop'}
        />
        <a href="#" onClick={navBack} className="b-page-back-link">
          {t('common:label.Organization-Report')}
        </a>
        {renderContent()}
      </div>
    </>
  );
};

export default ElmOrgReportResp;
