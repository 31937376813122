import { useHistory, useLocation } from 'react-router';
import { BelResourceModel, BelResourceNavModel, BelResourceShortModel } from '../../models';
import { ROOTS } from '../../routes/routes';

export const useNavToViewResource = () => {
  const history = useHistory();
  const location = useLocation();
  const isBeloved = location.pathname.match('beloved');
  const isCohort = location.pathname.match('cohort');

  return (data: BelResourceShortModel | BelResourceModel | BelResourceNavModel) => {
    if (isCohort) {
      history.push(
        isBeloved
          ? ROOTS.BELOVED_RESOURCE_VIEW.replace(':id', `${data.id}`)
          : ROOTS.RESOURCE_VIEW.replace(':id', `${data.id}`),
      );
      return;
    }

    // if (data.referenced_links.length) {
    //   let url = data.referenced_links[0];
    //   const hasProtocol = url.match(/(http:\/\/|https:\/\/)/g);
    //
    //   if (!hasProtocol) {
    //     url = `https://${url}`;
    //   }
    //   window.open(url, '_blank');
    //   return null;
    // }

    history.push(
      isBeloved
        ? ROOTS.BELOVED_RESOURCE_VIEW.replace(':id', `${data.id}`)
        : ROOTS.RESOURCE_VIEW.replace(':id', `${data.id}`),
    );
  };
};
