import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function useValidationPhoneNumber() {
  const [errorPhoneNumber, setErrorPhoneNumber] = useState<string>('');
  const { t } = useTranslation();

  const onBlurPhoneValidation = (isValid: boolean) => {
    if (isValid) {
      setErrorPhoneNumber('');
    } else {
      setErrorPhoneNumber(t('common:errors.invalid-phone-format'));
    }
  };

  return { errorPhoneNumber, setErrorPhoneNumber, onBlurPhoneValidation };
}
