import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Button,
  Checkbox,
  ImageSecured,
  Loader,
  ResourceStatusLabel,
  Select,
  SimpleTooltip,
} from '../../../../controls';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import {
  belCreateResource,
  belUpdateResource,
} from '../../../../../store/beloved/resources/resourcesActions';
import {
  BelCreateResourceModel,
  BasicFileModel,
  BelResourceModel,
  BelResourceCommentModel,
  BelResourceOptionModel,
  ISelectOptions,
  KeyValueModel,
  NewResourceOptionType,
} from '../../../../../models';
import ResourceComments from '../parts/ResourceComments';
import Api from '../../../../../services/api';
import { DOMAIN_URI, ResourceStatuses } from '../../../../../constants';
import {
  basicDateFormat,
  getBasicDateFormatCodeForDatePicker,
  isEmptyObject,
  validate,
  validateURL,
} from '../../../../../helpers';
import { useHistory, useLocation } from 'react-router';
import { useGetResourceOptions } from '../../../../../hooks/resources/use-get-resource-options';
import { useNavToViewResource } from '../../../../../hooks/resources/use-nav-to-view-resource';
import useHasAccess from '../../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../../constants/roles';
import CreateOptionForm from '../parts/CreateOptionForm';
import { useError } from '../../../../../hooks/common/use-error';
import { Modal, PopupPortal } from '../../../../common';
import Cropper from 'react-cropper';
import { useImageCropper } from '../../../../../hooks/common/use-image-cropper';

interface IForm {
  type: ISelectOptions | null;
  access_type: ISelectOptions | null;
  ownership: ISelectOptions | null;
  categories: ISelectOptions[];
  sectors: ISelectOptions[];
  tags: ISelectOptions[];
  title: string;
  description: string;
  time_to_read: string;
  authors: string;
  link: string;
  docs: BasicFileModel[];
  thumbnail: BasicFileModel | null;
  notes: string;
  resource_comments: BelResourceCommentModel[];
  is_public: boolean;
}

interface IUpdateForm {
  status: ISelectOptions<ResourceStatuses> | null;
  published_date: Date | undefined;
}

interface ILocationState {
  updateData?: BelResourceModel;
}

const validateScheme = {
  required: ['title', 'type', 'ownership'],
};

const CreateResource = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<ILocationState | undefined>();
  const options = useGetResourceOptions();
  let { error, setError } = useError<any>({});
  const navToView = useNavToViewResource();
  let [optionType, setOptionType] = useState<NewResourceOptionType | null>(null);
  let [loaded, setLoaded] = useState(false);
  let [isLoading, setLoading] = useState(false);

  const { moveImageToCrop, imgToCrop, setImgToCrop, setCropper, getCroppedFile } = useImageCropper();

  const updateData = location.state?.updateData;
  const isReviewer = useHasAccess(BelovedPermissions.RESOURCE_LIBRARY_REVIEW);

  const initialForm: IForm = {
    type: null,
    access_type: null,
    ownership: null,
    categories: [],
    sectors: [],
    tags: [],
    title: '',
    description: '',
    time_to_read: '',
    authors: '',
    link: '',
    docs: [],
    thumbnail: null,
    resource_comments: [],
    notes: '',
    is_public: false,
  };

  const [form, setForm] = useState<IForm>(initialForm);

  const [updateForm, setUpdateForm] = useState<IUpdateForm>({
    status: null,
    published_date: undefined,
  });

  useEffect(() => {
    if (options && !loaded) {
      if (updateData) {
        setForm({
          type: options.types.find((item: ISelectOptions) => item.value === updateData.type.id) || null,
          access_type:
            options.access_types.find((item: ISelectOptions) => item.value === updateData.access_type.id) ||
            null,
          ownership:
            options.ownerships.find((item: ISelectOptions) => item.value === updateData.ownership.id) || null,
          categories: updateData.categories.map((item: BelResourceOptionModel) => ({
            label: item.title,
            value: item.id,
          })),
          sectors: updateData.sectors.map((item: BelResourceOptionModel) => ({
            label: item.title,
            value: item.id,
          })),
          tags: updateData.tags.map((item: BelResourceOptionModel) => ({
            label: item.title,
            value: item.id,
          })),
          title: updateData.title,
          description: updateData.description,
          time_to_read: updateData.time_to_read,
          authors: updateData.authors,
          link: updateData.referenced_links[0] || '',
          docs: updateData.docs,
          notes: updateData.notes,
          thumbnail: updateData.thumbnail[0],
          resource_comments: updateData.comments,
          is_public: updateData.distribution.id === 2,
        });
        const publishDate = updateData.published_date ? new Date(updateData.published_date) : undefined;

        setUpdateForm({
          // @ts-ignore
          status: options.statuses[updateData.status].dependent[0],
          published_date: publishDate,
        });
      } else {
        setForm({
          ...form,
          type: options.types[0],
          ownership: options.ownerships[0],
          access_type: options.access_types[0],
        });
      }
      setLoaded(true);
    }
  }, [options]);

  const onSubmitCropping = async () => {
    const file = getCroppedFile();
    if (!file) return;

    setLoading(true);
    setImgToCrop('');
    const response = await Api.uploadFile(file, 1);
    setLoading(false);
    setForm({
      ...form,
      thumbnail: response.data.result.resource,
    });
    setError({});
  };

  const onCreateOptionValue = (newValue: ISelectOptions, fieldName: NewResourceOptionType) => {
    let value: ISelectOptions | ISelectOptions[] = newValue;
    if (form[fieldName] && form[fieldName] instanceof Array) {
      const isExist = Boolean(
        (form[fieldName] as ISelectOptions[]).find(item => item.value === newValue.value),
      );
      if (!isExist) {
        //@ts-ignore
        value = [...form[fieldName], newValue];
      }
    }
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const handleChangeStatus = (value: ISelectOptions<ResourceStatuses>) => {
    const newForm = { ...updateForm };
    newForm.status = value;
    if (value.value === ResourceStatuses.PUBLISH) {
      newForm.published_date = new Date();
    } else {
      newForm.published_date = undefined;
    }
    setUpdateForm(newForm);
  };

  const handleChangeField = (
    fieldName: string,
    value: boolean | string | ISelectOptions | ISelectOptions[] | Date,
  ) => {
    if (
      (value as ISelectOptions).value === -1 ||
      (typeof value !== 'string' &&
        (value as ISelectOptions[]).length &&
        (value as ISelectOptions[]).find(item => item.value === -1))
    ) {
      setOptionType(fieldName as NewResourceOptionType);
      return null;
    }
    if (fieldName === 'published_date') {
      setUpdateForm({
        ...updateForm,
        [fieldName]: value as Date,
      });
    } else {
      setForm({
        ...form,
        [fieldName]: value,
      });
    }
    setError({});
  };

  const validateFile = (fieldName: string, { type }: File): boolean => {
    let isValid = true;
    if (fieldName === 'thumbnail') {
      const isValidType = type.match(/(gif|jpe?g|bmp|png)$/);
      if (!isValidType) {
        setError({ ...error, thumbnail: t('common:errors.only-images') });
        isValid = false;
      }
    } else {
      const isValidType = type.match(/(gif|jpe?g|bmp|png|pdf)$/);
      if (!isValidType) {
        setError({ ...error, refDocFile: t('common:errors.res-invalid-file-type') });
        isValid = false;
      }
    }
    return isValid;
  };

  const handleAddThumbnail = (event: any) => {
    const { files } = event.target;
    const isValidType = validateFile('thumbnail', files[0]);
    if (!isValidType) return null;

    moveImageToCrop(files[0]);
    event.target.value = '';
  };

  const handleAddFile = (e: any) => {
    const isValidType = validateFile('docs', e.target.files[0]);
    if (!isValidType) return null;

    setLoading(true);
    Api.uploadFile(e.target.files[0], 0).then(response => {
      setLoading(false);
      setForm({
        ...form,
        docs: [...form.docs, response.data.result.resource],
      });
      setError({});
    });
  };

  const removeFileRequest = async (fileId: number) => {
    const formData = new FormData();
    formData.append('id', String(fileId));
    setLoading(true);
    const response = await Api.delete('files/', undefined, formData, {
      'Content-Type': 'multipart/form-data',
    });
    if (response) {
      setLoading(false);
      return response;
    }
  };

  const handleRemoveDoc = async (fileId: number) => {
    const response = await removeFileRequest(fileId);

    if (response) {
      const newDocs = form.docs.filter(doc => doc.id !== fileId);
      setForm({
        ...form,
        docs: newDocs,
      });
    }
  };

  const handleRemoveThumbnail = async () => {
    if (!form.thumbnail) return;
    const response = await removeFileRequest(form.thumbnail?.id);

    if (response) {
      setForm({
        ...form,
        thumbnail: null,
      });
    }
  };

  const refDocError = (): KeyValueModel<string> => {
    if (form.link && !validateURL(form.link)) {
      return { refDoc: t('common:errors.invalid-URL') };
    }
    if (form.type?.value !== 1 && !form.link && !form.docs.length) {
      return { refDoc: t('common:errors.res-ref-doc') };
    }
    return {};
  };

  const handleSave = () => {
    let { errors } = validate(validateScheme, form);
    errors = { ...errors, ...refDocError() };
    setError(errors);

    if (isEmptyObject(errors)) {
      const data: BelCreateResourceModel = {
        title: form.title,
        description: form.description,
        notes: form.notes,
        time_to_read: form.time_to_read,
        authors: form.authors,
        type: form.type?.value as number,
        access_type: form.access_type?.value as number,
        ownership: form.ownership?.value as number,
        distribution: form.is_public ? 2 : 1,
        resource_categories: form.categories.map(cat => cat.value) as number[],
        resource_tags: form.tags.map(cat => cat.value) as number[],
        resource_sectors: form.sectors.map(cat => cat.value) as number[],
        thumbnail: form.thumbnail ? [form.thumbnail.id] : [],
        docs: form.docs.map(doc => doc.id),
        resource_comments: form.resource_comments.map(note => note.id),
        resource_referenced_links: form.link ? [form.link] : [],
      };

      if (updateData) {
        data.status = updateForm.status?.value as string;
        const published_date = dayjs
          .utc(updateForm.published_date, 'YYYY-MM-DD')
          .format('YYYY-MM-DD HH:00:00 Z');
        data.published_date = updateForm.published_date ? published_date : undefined;

        dispatch(
          belUpdateResource({
            id: updateData.id,
            data,
            callback: () => {
              history.go(-1);
            },
          }),
        );
      } else {
        dispatch(
          belCreateResource({
            data,
            callback: () => {
              history.go(-1);
            },
          }),
        );
      }
    }
  };

  const handleChangeNotes = (data: BelResourceCommentModel[]) => {
    setForm({ ...form, resource_comments: data });
  };

  const renderContent = () => {
    if (!options) return <Loader fitParent />;

    return (
      <div className="b-panel b-resourcePanel">
        {updateData ? (
          <>
            <div className="b-resourceForm__field -cols3">
              <div className="b-panelData__fieldTitle">{t('common:label.submitted-by')}</div>
              <div className="b-panelData__fieldValue">{updateData.submitter.name}</div>
              <div className="b-resourceForm__selfEnd">
                {isReviewer ? (
                  <Select
                    value={updateForm.status}
                    handleChange={value => handleChangeStatus(value)}
                    placeholder={t('common:label.status')}
                    options={options.statuses[updateData.status].dependent}
                  />
                ) : (
                  <ResourceStatusLabel status={updateData.status} />
                )}
              </div>
            </div>
            <div className="b-resourceForm__field -dates">
              <div className="b-panelData__fieldTitle">{t('common:label.date-created')}</div>
              <div className="b-panelData__fieldValue">
                {updateData.created_at ? basicDateFormat(updateData.created_at) : t('common:column.n-a')}
              </div>
              <div className="b-resourceForm__lastUpdate">
                <div className="b-panelData__fieldTitle">{t('common:column.last-updated')}</div>
                <div className="b-panelData__fieldValue">
                  {updateData.updated_at ? basicDateFormat(updateData.updated_at) : t('common:column.n-a')}
                </div>
              </div>
            </div>
          </>
        ) : null}
        <div className="b-resourceForm__field -type">
          <div className="b-resourceForm__select">
            <Select
              value={form.type}
              handleChange={value => handleChangeField('type', value)}
              placeholder={t('common:label.resource-type')}
              options={options.types}
              error={error.type}
            />
          </div>
          <div className="b-resourceForm__selfEnd">
            <SimpleTooltip
              id={'globe'}
              title={
                <div className="b-resourceForm__publicLabelTooltip">
                  {t('common:tooltip.create-resource-public')}
                </div>
              }
            >
              <Checkbox
                id="Application"
                onChange={() => handleChangeField('is_public', !form.is_public)}
                checked={form.is_public}
              >
                <div className="b-resourceForm__publicLabel">
                  <div className="b-resourceCard__headingIcon" />
                  <span className={'checkbox-text'}> {t('common:label.public')}</span>
                </div>
              </Checkbox>
            </SimpleTooltip>
          </div>
        </div>

        <div className="b-resourceForm__field -description">
          <div className="b-panelData__fieldTitle">
            {t('common:column.title')}
            {error.title ? <div className="error">{t('common:errors.field-is-required')}</div> : null}
          </div>
          <div>
            <input
              aria-label="Title"
              placeholder={t('common:label.resource-title')}
              className="b-resourceForm__fieldInput"
              type="text"
              value={form.title}
              onChange={e => handleChangeField('title', e.target.value)}
            />
          </div>
        </div>
        <div className="b-resourceForm__field -description">
          <div className="b-panelData__fieldTitle">
            {t('common:column.description')}
            {error.description ? <div className="error">{t('common:errors.field-is-required')}</div> : null}
          </div>
          <div>
            <textarea
              aria-label="Description"
              placeholder={t('common:label.resource-desc')}
              className="b-resourceForm__fieldInput"
              name="description"
              rows={3}
              value={form.description}
              onChange={e => handleChangeField('description', e.target.value)}
            />
          </div>
        </div>
        <div className="b-resourceForm__field -file">
          <div className="b-panelData__fieldTitle">
            {t('common:column.referenced-document')}
            {error.refDoc ? <div className="error">{error.refDoc}</div> : null}
          </div>
          <div>
            <input
              disabled={Boolean(form.docs.length)}
              aria-label="Link"
              placeholder={t('common:label.url-of-the-document')}
              className="b-resourceForm__fieldInput"
              type="text"
              value={form.link}
              onChange={e => handleChangeField('link', e.target.value)}
            />
          </div>
          <div>
            {form.docs.length ? (
              form.docs.map(doc => {
                return (
                  <div className="b-resourceForm__fileName" key={`doc-${doc.id}`}>
                    <div>{doc.original_file_name}</div>
                    <a
                      onClick={e => {
                        e.preventDefault();
                        handleRemoveDoc(doc.id);
                      }}
                      href="#"
                      className="b-button-icon -deleteFile"
                    />
                  </div>
                );
              })
            ) : (
              <>
                <label className={`b-uploadInput__label ${form.link.length ? '-disabled' : ''}`}>
                  {t('common:label.upload-document')}
                  <input
                    disabled={Boolean(form.link.length)}
                    id="doc-input"
                    accept="image/png, image/gif, image/jpeg, application/pdf"
                    className="b-uploadInput"
                    type="file"
                    onChange={e => handleAddFile(e)}
                  />
                </label>
                {error.refDocFile ? <div className="error">{error.refDocFile}</div> : null}
              </>
            )}
          </div>
        </div>
        <div className="b-resourceForm__field">
          <div className="b-panelData__fieldTitle">{t('common:column.time-to-read-view')}</div>
          <div>
            <input
              aria-label="Time"
              placeholder={t('common:label.time-in-minutes')}
              className="b-resourceForm__fieldInput"
              type="text"
              value={form.time_to_read}
              onChange={e => handleChangeField('time_to_read', e.target.value)}
            />
          </div>
        </div>
        <div className="b-resourceForm__field">
          <div className="b-panelData__fieldTitle">{t('common:column.thumbnail')}</div>
          <div>
            {form.thumbnail ? (
              <div className="b-resourceForm__thumbnailWrapper">
                <ImageSecured
                  src={`${DOMAIN_URI}${form.thumbnail.file}`}
                  className="b-resourceForm__thumbnail"
                  alt={t('awa:AT31')}
                />
                <a
                  onClick={e => {
                    e.preventDefault();
                    handleRemoveThumbnail();
                  }}
                  className="b-link-icon -delete"
                  href="#"
                >
                  {t('common:label.delete-image')}
                </a>
              </div>
            ) : (
              <>
                <label className="b-uploadInput__label">
                  {t('common:label.upload-image')}
                  <input
                    id="thumbnail-input"
                    accept="image/png, image/gif, image/jpeg"
                    className="b-uploadInput"
                    type="file"
                    onChange={e => handleAddThumbnail(e)}
                  />
                </label>
                {error.thumbnail ? <div className="error">{error.thumbnail}</div> : null}
              </>
            )}
          </div>
        </div>
        <div className="b-resourceForm__field -description">
          <div className="b-panelData__fieldTitle">{t('common:column.authors')}</div>
          <div>
            <input
              aria-label="Author"
              placeholder={t('common:label.authors-of-the-resource')}
              className="b-resourceForm__fieldInput"
              type="text"
              value={form.authors}
              onChange={e => handleChangeField('authors', e.target.value)}
            />
          </div>
        </div>
        <div className="b-resourceForm__field -description">
          <div className="b-panelData__fieldTitle">{t('common:btn.notes')}</div>
          <div>
            <textarea
              aria-label="Note"
              placeholder={t('common:label.leave-note-here')}
              className="b-resourceForm__fieldInput"
              name="note"
              rows={3}
              value={form.notes}
              onChange={e => handleChangeField('notes', e.target.value)}
            />
          </div>
        </div>
        <Select
          isMulti
          value={form.categories}
          handleChange={value => handleChangeField('categories', value)}
          placeholder={t('common:label.categories')}
          options={[...options.categories]}
          error={error.categories}
        />
        <Select
          isMulti
          value={form.sectors}
          handleChange={value => handleChangeField('sectors', value)}
          placeholder={t('common:label.target-sectors')}
          options={[...options.sectors]}
          error={error.sectors}
        />
        <Select
          value={form.access_type}
          handleChange={value => handleChangeField('access_type', value)}
          placeholder={t('common:label.access')}
          options={[...options.access_types, { value: -1, label: 'Other (please specify)' }]}
          error={error.access_type}
        />
        <Select
          isMulti
          value={form.tags}
          handleChange={value => handleChangeField('tags', value)}
          placeholder={t('common:label.tags')}
          options={[...options.tags, { value: -1, label: 'Other (please specify)' }]}
          error={error.tags}
        />
        <Select
          value={form.ownership}
          handleChange={value => handleChangeField('ownership', value)}
          placeholder={t('common:label.publisher')}
          options={options.ownerships}
          error={error.ownerships}
        />
        {updateData ? (
          <div className="b-resourceForm__field">
            <div className="b-panelData__fieldTitle">{t('common:label.publish-date')}</div>
            <div>
              {isReviewer ? (
                <DatePicker
                  disabled={updateForm.status?.value !== ResourceStatuses.PUBLISH}
                  placeholderText={t('common:column.n-a')}
                  minDate={new Date()}
                  dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                  selected={updateForm.published_date}
                  onChange={(date: Date) => {
                    handleChangeField('published_date', date);
                  }}
                  ariaLabelledBy="start_date"
                />
              ) : (
                <div className="b-panelData__fieldValue">
                  {updateData.published_date
                    ? basicDateFormat(updateData.published_date)
                    : t('common:column.n-a')}
                </div>
              )}
            </div>
          </div>
        ) : null}

        <ResourceComments
          resourceId={updateData?.id}
          data={form.resource_comments}
          onChange={handleChangeNotes}
        />
        <div className="b-resourceForm__buttons">
          <Button
            title={t('common:btn.cancel')}
            onPress={() => history.go(-1)}
            size={'large'}
            type={'transparency'}
          />
          {updateData ? (
            <Button
              title={t('common:btn.preview')}
              onPress={() => navToView(updateData)}
              size={'large'}
              type={'transparency'}
            />
          ) : null}
          <Button title={t('common:btn.save')} onPress={handleSave} size={'large'} />
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <main className="b-page">
        <h1 className="b-page__title">
          {t('common:tabs.resource-library')}:{' '}
          <span className="mark">
            {updateData ? t('common:btn.resource') : t('common:headlines.new-resource')}
          </span>
        </h1>

        {renderContent()}
      </main>
      {optionType ? (
        <CreateOptionForm
          onClose={() => setOptionType(null)}
          optionType={optionType}
          onSubmit={onCreateOptionValue}
        />
      ) : null}
      {imgToCrop ? (
        <PopupPortal portalElementId={'user-avatar-portal'}>
          <Modal minHeight={520} onClose={() => setImgToCrop('')}>
            <div>
              <div className="pt-4 d-flex mb-3 justify-content-center">
                <Cropper
                  style={{ height: 400, width: 400 }}
                  initialAspectRatio={1}
                  src={imgToCrop}
                  minCropBoxHeight={150}
                  minCropBoxWidth={150}
                  aspectRatio={1}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={instance => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
              </div>
              <div className="d-flex justify-content-center">
                <Button size="middle" title={t('common:label.crop-image')} onPress={onSubmitCropping} />
              </div>
            </div>
          </Modal>
        </PopupPortal>
      ) : null}
    </>
  );
};

export default CreateResource;
