import React, { useEffect, useState } from 'react';
import { Button } from '../../../controls';
import Input from '../../../controls/Inputs/Input';
import './ForgotPasswordStyle.scss';
import { Footer, Header } from '../../../common';
import ReCaptcha from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

interface IProps {
  errors: any;
  loader?: string;
  isEmail: boolean;
  username: string;
  onChange: (name: string, value: string) => void;
  onSubmit: () => void;
  handleOnBlur: (field: string) => () => void;
}

const ForgotPasswordView = ({
  errors,
  loader,
  username,
  onChange,
  isEmail,
  onSubmit,
  handleOnBlur,
}: IProps) => {
  const { t } = useTranslation();
  let [disable, setDisable] = useState(true);
  let [captcha, setCaptcha] = useState(true);

  useEffect(() => {
    if (!captcha && !Object.keys(errors).length && isEmail) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [captcha, isEmail, errors]);

  const handleChange = (value: any) => {
    if (value !== null) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  };

  return (
    <>
      {loader}
      <div className={'container container-header container-header white-container'}>
        <Header type={'awa'} />
        <main className={'white-container content-center-wrapper'}>
          <div className={'container-page-center'}>
            <h1 className={'container-row'}>
              <span className={'pre-title gray-text'}>{t('awa:M99.title')}</span>
            </h1>
            <div className={'container-row'}>
              <Input
                error={errors.username}
                value={username}
                type={'email'}
                handleUnselect={handleOnBlur('username')}
                placeholder={t('common:label.email-address')}
                ariaLabel="Email Address"
                handleChange={(value: string) => onChange('username', value)}
                autoFocus
              />
            </div>
            <div className={'container-row'}>
              <label className={`d-none`} htmlFor="g-recaptcha-response">
                recaptcha
              </label>
              <ReCaptcha sitekey={'6LcMxd8cAAAAALdzoUclyTcdXyPApidxbTAGJAEL'} onChange={handleChange} />
            </div>
            <div className={'container-row'}>
              <Button
                size={'small'}
                onPress={onSubmit}
                title={t('common:btn.submit')}
                type={'orange'}
                disabled={disable}
                enableEnter
              />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default ForgotPasswordView;
