import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataGrid from 'react-data-grid';
import { useHistory, useParams } from 'react-router';
import {
  BelParticipantModel,
  CohortUserModel,
  IRouteParamsBase,
  ISelectOptions,
  TableDataEntities,
} from '../../../../../models';
import { HeadlineOrganization, Modal, Pagination } from '../../../../common';
import { getColumns } from './parts/columns';
import { Button, Loader, Select, Link as CustomLink } from '../../../../controls';
import useTableData from '../../../../../hooks/common/use-table-data';
import { useDispatch } from 'react-redux';
import {
  addParticipant,
  availableParticipantResource,
  changeVisibilityParticipant,
  deleteParticipant,
} from '../../../../../store/cohort/cohortActions';
import { Modals, ModalsModel } from '../../../../../constants';
import { useRole } from '../../../../../helpers/roles';
import { Link } from 'react-router-dom';
import { ROOTS } from '../../../../../routes/routes';
import { useIsDarkMode } from '../../../../../hooks/common/use-is-dark-mode';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { useGetOrgCohortDetails } from '../../../../../hooks/cohorts/use-get-org-cohort-details';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import useTableConfig from '../../../../../hooks/common/use-table-config';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';

interface IRouteParams extends IRouteParamsBase {
  id: string;
  organizationId: string;
}

const selectStyles = {
  margin: '0 auto',
};

const CohortParticipantsDetails = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useIsDarkMode();
  const dispatch = useDispatch();
  const { id, orgId, organizationId } = useParams<IRouteParams>();
  const cohort = useGetOrgCohortDetails(organizationId || orgId);
  const [modals, setModals] = useState<ModalsModel>({});
  const [availableUsers, setAvailableUsers] = useState<ISelectOptions[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<ISelectOptions[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const access = useRole();
  const isAccess = !!access(['project_admin', 'project_manager']);
  const currentOrgName = cohort?.organizations.find(o => o.id === Number(organizationId))?.name || '';
  const isBeloved = location.pathname.match('beloved');
  const { navBack } = useNavBack();
  const history = useHistory();

  const {
    data,
    query,
    fetchData,
    handleChangePage,
    handleChangePageSize,
    handleSearch,
    handleSelectOne,
    selectedEntitiesIds,
    handleSort,
  } = useTableData(TableDataEntities.COHORT_PARTICIPANTS, { cohort_id: id, orgId: organizationId || orgId });
  const [forceRerender, setForceRerender] = useState<boolean>(false);

  const { columns, onColumnResize } = useTableConfig<BelParticipantModel>(
    TableDataEntities.COHORT_PARTICIPANTS,
    getColumns({ isAccess }),
    forceRerender,
  );

  useEffect(() => {
    fetchData(query);
  }, []);

  useEffect(() => {
    if (isAccess) {
      fetchAvailableElmUsers();
      // because of the hook and table change, we need async otherwise, the last column may not be added
      setTimeout(() => {
        setForceRerender(true);
      }, 100);
    }
  }, [isAccess]);

  const fetchAvailableElmUsers = () => {
    if (!isAccess) {
      return;
    }
    dispatch(
      availableParticipantResource({
        data: {
          organization_id: organizationId,
          cohort_id: id,
        },
        callback: data => {
          if (data) {
            const availableUsers = data.map((user: CohortUserModel) => {
              return { label: `${user.first_name} ${user.last_name}`, value: user.id };
            });
            setAvailableUsers(availableUsers);
          }
        },
      }),
    );
  };

  const userDidAdd = () => {
    setLoading(true);
    closeModal(Modals.isAddUser);
    dispatch(
      addParticipant({
        data: {
          cohort_id: id,
          organization_id: organizationId,
        },
        params: {
          users_id: selectedUsers.map(s => Number(s.value)),
        },
        callback: () => {
          fetchAvailableElmUsers();
          handleChangePage(1);
          setLoading(false);
          setSelectedUsers([]);
        },
      }),
    );
  };

  const handleSelectRow = (ids: Set<number>) => {
    handleSelectOne(ids);
  };

  const openModal = (modalName: string) => {
    setModals({
      ...modals,
      [modalName]: true,
    });
  };

  const closeModal = (modalName: string) => {
    setModals({
      ...modals,
      [modalName]: false,
    });
  };

  const handleChangeSelectedUsers = (users: ISelectOptions[]) => {
    setSelectedUsers(users);
  };

  const handleDeleteUser = () => {
    setLoading(true);
    dispatch(
      deleteParticipant({
        data: {
          cohort_id: id,
          organization_id: organizationId,
        },
        params: {
          users_id: selectedEntitiesIds,
        },
        callback: () => {
          fetchAvailableElmUsers();
          handleChangePage(1);
          handleChangeSelectedUsers([]);
          setLoading(false);
        },
      }),
    );
  };

  const handleVisibleChange = (isVisible: boolean) => {
    setLoading(true);
    dispatch(
      changeVisibilityParticipant({
        data: {
          cohort_id: id,
          organization_id: organizationId,
        },
        params: {
          team_ids: selectedEntitiesIds,
          is_visible: isVisible,
        },
        callback: () => {
          fetchAvailableElmUsers();
          handleChangePage(1);
          handleChangeSelectedUsers([]);
          setLoading(false);
        },
      }),
    );
  };

  const collectUserStatuses = (is_active: boolean): boolean => {
    return selectedEntitiesIds
      .map(id => {
        const findUser = data?.result.find(u => u.id === id);
        if (findUser) {
          return findUser.is_visible;
        }
      })
      .includes(is_active);
  };

  const renderContent = () => {
    if (!data) return <Loader fitParent />;

    return (
      <div>
        <DndProvider backend={HTML5Backend}>
          <DataGrid
            onRowClick={() => {}}
            onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
            columns={columns}
            onColumnResize={onColumnResize}
            sortColumns={query.sorting ? [query.sorting] : []}
            rows={data.result}
            onSelectedRowsChange={handleSelectRow}
            className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
            style={{
              height: ((data.result.length || 0) + 1) * 60,
            }}
            rowHeight={60}
            selectedRows={new Set(selectedEntitiesIds)}
            rowKeyGetter={(row: BelParticipantModel) => row.id}
          />
        </DndProvider>
        <Pagination data={data} handleChangePageSize={handleChangePageSize} changePage={handleChangePage} />
      </div>
    );
  };

  const renderEmptyAddUsers = () => {
    return (
      <>
        <p className={`b-modal__text`}>{t('awa:N36.msg')}</p>
        <div className="b-modal__buttons">
          <Link
            to={ROOTS.ORG_USERS.replace(':orgId', `${orgId}`)}
            className="orange-light-text font-s d-block mb-2"
          >
            {t('common:headlines.organization-users')}
          </Link>
        </div>
      </>
    );
  };

  const navToOrganization = () => {
    if (isBeloved) {
      history.push(ROOTS.BELOVED_ORGANIZATION_DETAILS.replace(':orgId', `${organizationId}`));
    } else {
      history.push(ROOTS.ORG_PROFILE.replace(':orgId', `${organizationId}`));
    }
  };

  return (
    <>
      <main className="b-page">
        {loading && <Loader fitParent />}
        <div className="b-page__header -desktop">
          <HeadlineOrganization
            orgName={cohort?.name || ''}
            orgId={cohort?.id || ''}
            headline={t('common:headlines.cohort-program')}
            customClasses={'-noDesktopMb mr-4'}
            backBtn={<EmptyLink onClick={navBack} className="b-button-icon-back" />}
            noOrgLogo
          />
        </div>
        <div className="b-page__controls">
          <CustomLink onClick={navToOrganization} className={'b-cohortParticipant__organization'}>
            <span className="mark">{currentOrgName}</span>
          </CustomLink>
          <div className="b-page__search">
            <form action="#" className="searchForm">
              <input
                type="search"
                value={query.search}
                onChange={e => handleSearch(e.target.value)}
                placeholder={t('common:label.search')}
                aria-label="Search field"
              />
              <span className="searchForm__icon" />
            </form>
          </div>
        </div>
        <div className="b-cohortParticipant__controls">
          {isAccess ? (
            <div className="b-resourceList__tools">
              <button
                disabled={!selectedEntitiesIds.length || !collectUserStatuses(false)}
                aria-label="Visibility-on"
                onClick={() => handleVisibleChange(true)}
                className="b-tableTool -visibility-on -mobView -mr"
              >
                {t('common:btn.cohort-visibility-on')}
              </button>
            </div>
          ) : null}
          {isAccess ? (
            <div className="b-resourceList__tools">
              <button
                disabled={!selectedEntitiesIds.length || !collectUserStatuses(true)}
                aria-label="Visibility-on"
                onClick={() => handleVisibleChange(false)}
                className="b-tableTool -visibility-off -mobView -mr"
              >
                {t('common:btn.cohort-visibility-off')}
              </button>
            </div>
          ) : null}
          {isAccess ? (
            <div className="b-resourceList__tools">
              <button
                disabled={!selectedEntitiesIds.length}
                aria-label="Delete User"
                onClick={handleDeleteUser}
                className="b-tableTool -delete -mobView -mr"
              >
                {t('common:btn.delete')}
              </button>
            </div>
          ) : null}
          <div className="b-modal__buttons">
            {isAccess ? (
              <button
                onClick={() => openModal(Modals.isAddUser)}
                className="b-tableTool -addPlus -mobView -mr"
              >
                {t('common:btn.add-new-user')}
              </button>
            ) : null}
          </div>
        </div>
        {renderContent()}
      </main>
      {modals.isAddUser ? (
        <Modal
          showCloseBtn={false}
          width={550}
          onClose={() => closeModal(Modals.isAddUser)}
          title={t('awa:N36.title')}
        >
          <div className="b-modal">
            <div>
              {availableUsers && availableUsers.length ? (
                <Select
                  isMulti
                  handleChange={handleChangeSelectedUsers}
                  value={selectedUsers}
                  options={availableUsers || []}
                  ariaLabel="Users"
                  wrapperStyles={selectStyles}
                />
              ) : (
                renderEmptyAddUsers()
              )}
            </div>
            {availableUsers && availableUsers.length ? (
              <div className="b-modal__buttons">
                <Button
                  onPress={() => closeModal(Modals.isAddUser)}
                  title={t('common:btn.cancel')}
                  size={'large'}
                  type={'transparency'}
                />
                <Button
                  disabled={!selectedUsers.length}
                  onPress={userDidAdd}
                  title={t('common:btn.add')}
                  size={'large'}
                  type={'orange-light'}
                />
              </div>
            ) : null}
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default CohortParticipantsDetails;
