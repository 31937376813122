import React, { useEffect, useRef, useState } from 'react';
import { Alert, ImageUploader, Modal } from '../../../common';
import styles from './MyProfile.module.scss';
import { Select, Input, Button, RadioButton, Autocomplete } from '../../../controls';
import MyAccountTabs from '../../../common/MyAccountTabs/MyAccountTabs';
import {
  ApiAddressModel,
  AuthUserModel,
  ISelectOptions,
  KeyValueModel,
  ProfileModel,
  UploadFileModel,
} from '../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import {
  basicDateFormat,
  email,
  getLatLnd,
  getLocation,
  isEmptyObject,
  phone,
  validate,
} from '../../../../helpers';
import {
  confirmSecondaryEmail,
  getProfile,
  getUser,
  resendSecondaryEmail,
  updateProfile,
} from '../../../../store/profile/profileActions';
import { countries, modalNames, Modals, ModalsModel } from '../../../../constants';
import {
  createEducation,
  createEthnicity,
  createGender,
  createPronouns,
  createRace,
} from '../../../../store/utils/utilsActions';
import { ResetPassword } from '../ResetPassword/ResetPassword';
import { IAddressFields } from '../../Profile/YourOfficeAddress/OfficeAddressContainer';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ModalView from '../../../controls/ModalView/ModalView';
import EmailNotVerifiedContainer from '../../Auth/EmailNotVerified/EmailNotVerifiedContainer';
import PhoneInput from '../../../controls/Inputs/PhoneInput';
import { useValidationPhoneNumber } from '../../../../hooks/common/use-validation-phone-number';

interface IState {
  errors: KeyValueModel<string>;
  profile: ProfileModel;
  initialProfile: ProfileModel;
}

const initialAddressFields: IAddressFields = {
  country: 'us',
  state: '',
  city: '',
  postal: '',
  address: '',
  suite: '',
  office_type: '',
};

const secondaryEmailValidator = {
  required: ['secondary_email'],
  custom: [email(['secondary_email'])],
};

const MyProfile = () => {
  const { t } = useTranslation();
  const currentUser = useSelector((state: StateType) => state.profile.authUser?.user);
  const user = useSelector((state: StateType) => state.profile.authUser);
  const [modal, setModal] = useState<string | false>(false);
  const [newField, setNewField] = useState<string>('');
  const dispatch = useDispatch();
  const gendersList = useSelector((state: StateType) => state.utils.data.genders);
  const racesList = useSelector((state: StateType) => state.utils.data.racesTypes);
  const ethnicityList = useSelector((state: StateType) => state.utils.data.ethnicityTypes);
  const educationsList = useSelector((state: StateType) => state.utils.data.educationsType);
  const pronounsList = useSelector((state: StateType) => state.utils.data.pronounsTypes);
  const [modals, setModals] = useState<ModalsModel>({});
  const { errorPhoneNumber, setErrorPhoneNumber, onBlurPhoneValidation } = useValidationPhoneNumber();
  const history = useHistory();

  const initialState: IState = {
    errors: {},
    profile: {} as ProfileModel,
    initialProfile: {} as ProfileModel,
  };

  const [state, setState] = useState<IState>(initialState);
  const [initialProfile, setInitialProfile] = useState<ProfileModel | null>({} as ProfileModel);
  const { profile, errors } = state;
  const isInternation = !(
    profile.office_address?.country && profile.office_address?.country.toLowerCase() === 'us'
  );

  const validator = {
    required: [
      'first_name',
      'last_name',
      'office_type',
      'gender',
      'pronouns_id',
      'race',
      'ethnicity',
      'education',
      'role',
      'email',
      user?.user.notification.permission_edit_phone ? 'phone_number' : '',
    ].filter(f => !!f),
    requiredFieldName: {
      zip: 'Sub-Organization name',
    },
    custom: user?.user.notification.permission_edit_phone ? [phone(['phone_number'])] : [],
  };

  const addressValidator = {
    required: ['country', 'address', 'postal', 'state', 'city'],
    requiredFieldName: {
      postal: isInternation ? t('common:label.international.postal') : t('common:label.us.postal'),
      state: isInternation ? t('common:label.international.state') : t('common:label.us.state'),
      city: isInternation ? t('common:label.international.city') : t('common:label.us.city'),
    },
  };

  const addressType3Validator = {
    required: ['country', 'postal', 'state', 'city'],
    requiredFieldName: {
      postal: isInternation ? t('common:label.international.postal') : t('common:label.us.postal'),
      state: isInternation ? t('common:label.international.state') : t('common:label.us.state'),
      city: isInternation ? t('common:label.international.city') : t('common:label.us.city'),
    },
  };

  useEffect(() => {
    const hash = new URLSearchParams(history.location.search).get('secondary_verification_hash');
    if (hash) {
      handleVerificationAccount(hash);
    }
  }, []);

  useEffect(() => {
    if (currentUser && isEmptyObject(state.profile)) {
      dispatch(
        getProfile({
          user_id: currentUser?.id,
          callback: getProfileSuccess,
        }),
      );
    }
  }, [currentUser?.id]);

  const handleVerificationAccount = (hash: string) => {
    dispatch(
      confirmSecondaryEmail({
        data: { hash: hash },
        callback: (response: any) => verificationResult(response),
      }),
    );
  };

  const verificationResult = (response: any) => {
    if (response && response.popup) {
      const popup = { ...response.popup };
      popup.showImage = true;
      setModals({
        [Modals.isConfirmEmail]: popup,
      });
    }
  };

  const getProfileSuccess = (profile: ProfileModel) => {
    setState(prevState => ({
      ...prevState,
      profile: profile,
    }));

    dispatch(
      getUser({
        callback: (user: AuthUserModel) => {
          if (user.user.notification.need_phone_number && !profile.phone_number) {
            setState(prevState => ({
              ...prevState,
              errors: {
                phone_number: t('common:errors.phone-number'),
              },
            }));
          }
        },
      }),
    );
    setInitialProfile(profile);
  };

  const handleChangeInput = (name: string, value: string) => {
    if (errors[name]) {
      delete errors[name];
    }
    setState(prevState => ({
      ...prevState,
      profile: {
        ...prevState.profile,
        [name]: value,
      },
    }));
  };

  const onChangeValue = ({ target }: any) => {
    const newErrors = { ...errors };
    delete newErrors['city'];
    delete newErrors['state'];
    delete newErrors['postal'];
    delete newErrors['address'];

    setState(prevState => ({
      ...prevState,
      errors: newErrors,
    }));

    if (target.value === '1') {
      setState(prevProps => ({
        ...prevProps,
        profile: {
          ...prevProps.profile,
          office_type: Number(target.value),
          office_address: (user?.orgs_role?.[0].address || {}) as any,
        },
      }));
    } else {
      if (initialProfile?.office_type === Number(target.value)) {
        setState(prevProps => ({
          ...prevProps,
          profile: {
            ...prevProps.profile,
            office_type: Number(target.value),
            office_address: initialProfile?.office_address as ApiAddressModel,
          },
        }));
        return;
      }
      setState(prevProps => ({
        ...prevProps,
        profile: {
          ...prevProps.profile,
          office_type: Number(target.value),
          office_address: initialAddressFields as unknown as ApiAddressModel,
        },
      }));
    }
  };

  const handleCancel = () => {
    dispatch(
      getProfile({
        user_id: currentUser?.id || 0,
        callback: getProfileSuccess,
      }),
    );
  };

  const handleSubmit = async () => {
    // TODO need update modal
    const updateData: any = {
      first_name: profile.first_name,
      email: profile.email,
      last_name: profile.last_name,
      secondary_email: profile.secondary_email || null,
      language: profile.language,
      office_type: profile.office_type,
      office_address: profile.office_address,
      gender: profile.gender_data && profile.gender_data.map(g => +g.value),
      pronouns_id: (profile.pronouns_data && profile.pronouns_data.value) || null,
      race: profile.race_data && profile.race_data.map(r => +r.value),
      ethnicity: (profile.ethnicity_data && profile.ethnicity_data?.map(e => +e.value)) || [],
      education: (profile.education_data && profile.education_data.value) || '',
      role: profile.role,
      avatar_id: profile.avatar_id,
    };

    if (user?.user.notification.permission_edit_phone && profile.phone_number) {
      updateData.phone_number = profile.phone_number;
    }

    let addressValidate: any;
    if (updateData.office_type === 3) {
      addressValidate = validate(addressType3Validator, updateData.office_address);
    } else {
      addressValidate = validate(addressValidator, updateData.office_address);
    }

    const { errors }: any = validate(validator, updateData);

    if (
      Object.values(addressValidate.errors || {}).length ||
      Object.values(errors || {}).length ||
      errorPhoneNumber
    ) {
      setState(prevState => ({
        ...prevState,
        errors: {
          ...(errors || {}),
          ...(addressValidate.errors || {}),
        },
      }));
      return;
    }

    if (updateData.office_type === 3) {
      const results = await getLatLnd({
        city: updateData.office_address.city,
        postal: updateData.office_address.postal,
        state: updateData.office_address.state,
        country: updateData.office_address.country,
      });
      if (results) {
        const { lat, lng } = results;
        updateData.office_address = {
          ...updateData.office_address,
          lat,
          lng,
        };
      }
    }

    dispatch(
      updateProfile({
        callback: res => {
          if (res.id) {
            toast.success(t('common:toast.account-updated'));
            dispatch(
              getProfile({
                user_id: currentUser?.id || 0,
                callback: getProfileSuccess,
              }),
            );
          } else {
            setState(prevState => ({
              ...prevState,
              errors: res,
            }));
          }
        },
        data: {
          id: profile.user_id,
          user: updateData,
        },
      }),
    );
  };

  const getErrorField = (field: string): string => {
    if (field === 'education_data') {
      return 'education';
    }
    if (field === 'ethnicity_data') {
      return 'ethnicity';
    }
    if (field === 'gender_data') {
      return 'gender';
    }
    if (field === 'pronouns_data') {
      return 'pronouns';
    }
    if (field === 'race_data') {
      return 'race';
    }
    return '';
  };

  const onChangeSelect = (field: string) => (value: { value: string; label: string }) => {
    if (
      value &&
      ((Array.isArray(value) && value?.find(f => f.value === 'create')) || value.value === 'create')
    ) {
      setModal(field);
      return;
    }

    delete errors[getErrorField(field)];

    setState({
      ...state,
      profile: {
        ...state.profile,
        [field]: value,
      },
      errors: errors,
    });
  };

  const closeModal = () => {
    setModal(false);
    if (state.errors.newField) {
      delete state.errors.newField;
    }
    setState(prevState => ({
      ...prevState,
      errors: errors,
    }));
    setNewField('');
  };

  const handleChangeNewField = (value: string) => {
    setNewField(value);
    if (state.errors.newField) {
      delete state.errors.newField;
    }
    setState(prevState => ({
      ...prevState,
      errors: errors,
    }));
  };

  const checkUniqSelfDescribe = (list: ISelectOptions[]): boolean => {
    if (list.map(m => m.label).includes(newField)) {
      setState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          // @ts-ignore
          newField: `${modalNames()[modal]} ${t('common:errors.already-exist')}`,
        },
      }));
      return false;
    }
    return true;
  };

  const handleCreateField = () => {
    if (!newField.trim().length) {
      setState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          // @ts-ignore
          newField: `${modalNames()[modal]} ${t('common:errors.required')}`,
        },
      }));
      return;
    }

    const multiRequest = {
      callback: (res: ISelectOptions) => {
        // @ts-ignore
        let values = profile[modal] || [];
        values.push(res);
        setState({
          ...state,
          profile: {
            ...state.profile,
            [modal as string]: values,
          },
        });
        closeModal();
      },
      data: {
        name: newField,
      },
    };

    const singleRequest = {
      callback: (res: ISelectOptions) => {
        setState({
          ...state,
          profile: {
            ...state.profile,
            [modal as string]: res,
          },
        });
        closeModal();
      },
      data: {
        name: newField,
      },
    };

    if (modal === 'gender_data') {
      checkUniqSelfDescribe(gendersList as ISelectOptions[]) && dispatch(createGender(multiRequest));
    } else if (modal === 'race_data') {
      checkUniqSelfDescribe(racesList as ISelectOptions[]) && dispatch(createRace(multiRequest));
    } else if (modal === 'ethnicity_data') {
      checkUniqSelfDescribe(ethnicityList as ISelectOptions[]) && dispatch(createEthnicity(multiRequest));
    } else if (modal === 'education_data') {
      checkUniqSelfDescribe(educationsList as ISelectOptions[]) && dispatch(createEducation(singleRequest));
    } else if (modal === 'pronouns_data') {
      checkUniqSelfDescribe(pronounsList as ISelectOptions[]) && dispatch(createPronouns(singleRequest));
    }
  };

  const locationChangeHandler = async (place: any, ref: any) => {
    const address_details = await getLocation(place, ref);
    setState((prevState: IState) => {
      return {
        ...prevState,
        profile: {
          ...prevState.profile,
          office_address: {
            ...prevState.profile.office_address,
            ...address_details,
          },
        },
        errors: state.errors,
        wasEdit: true,
      };
    });
  };

  const handleAddressChange =
    (name: string) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      if (state.errors?.[name]) {
        delete state.errors[name];
      }
      setState(prevState => ({
        ...prevState,
        profile: {
          ...prevState.profile,
          office_address: {
            ...prevState.profile.office_address,
            address: target.value,
          },
        } as any,
        errors: state.errors,
      }));
    };

  const handleResetPassword = (name: string) => {
    setModal(name);
  };

  const countryValue = countries.find(
    f =>
      f.value.toLowerCase() ===
      (profile.office_address &&
        profile.office_address.country &&
        profile.office_address.country.toLowerCase()),
  );

  const handleChangeAddressField = (field: string, value: string) => {
    setState(prevState => ({
      ...prevState,
      profile: {
        ...prevState.profile,
        office_address: {
          ...prevState.profile.office_address,
          [field]: value,
        },
      },
    }));
  };

  const handleChangeDivision = (value: string) => {
    setState(prevState => ({
      ...prevState,
      profile: {
        ...prevState.profile,
        role: {
          ...prevState.profile.role,
          division: value,
        },
      },
    }));
  };

  const handleChangePhone = (value: string) => {
    setErrorPhoneNumber('');
    setState(prevState => ({
      ...prevState,
      profile: {
        ...prevState.profile,
        phone_number: value,
      },
    }));
  };

  const handleChangeTitle = (value: string) => {
    setState(prevState => ({
      ...prevState,
      profile: {
        ...prevState.profile,
        role: {
          ...prevState.profile.role,
          title: value,
        },
      },
    }));
  };

  const passwordChangedSuccess = () => {
    if (currentUser?.id) {
      dispatch(
        getProfile({
          user_id: currentUser.id,
          callback: getProfileSuccess,
        }),
      );
    }
  };

  const onChangeCountry = (name: string, data: string) => {
    setState(prevState => ({
      ...prevState,
      profile: {
        ...prevState.profile,
        office_address: {
          [name]: data,
        } as any,
      },
    }));
  };

  const isBlockSaveButton = () => {
    return JSON.stringify(initialProfile) === JSON.stringify(state.profile);
  };

  const handleVerifyEmail = (e: any) => {
    e.preventDefault();
    const { errors } = validate(secondaryEmailValidator, profile);
    if (Object.values(errors || {}).length) {
      setState({
        ...state,
        errors: {
          ...state.errors,
          ...errors,
        },
      });
      return;
    }
    setModals({
      [Modals.isResendEmail]: true,
    });
  };

  const handleAvatarChange = (file: UploadFileModel | null) => {
    setState({
      ...state,
      profile: {
        ...state.profile,
        avatar: file ? file.file : null,
        avatar_id: file ? file.id : null,
      },
    });
  };

  const handleResendEmail = () => {
    dispatch(
      resendSecondaryEmail({
        data: {
          email: profile.secondary_email,
        },
        callback: res => {
          if (res) {
            toast.success(res.msg);
            setModals({
              [Modals.isResendEmail]: false,
            });
          }
        },
      }),
    );
  };

  return (
    <>
      <main className={'pageContainer pageContainer__content'}>
        <h1 className={'pageContainer__title'}>{t('common:headlines.my-account')}</h1>
        <MyAccountTabs activeItemAlias={'my-profile'} />
        {!isEmptyObject(profile) && (
          <div className={styles.form}>
            <ImageUploader
              placeholderText={`${profile.first_name[0]}${profile.last_name[0]}`}
              photo={profile.avatar}
              onChange={handleAvatarChange}
              type={'profile'}
            />
            <div className={styles.form__part}>
              <Input
                name={'first_name'}
                error={errors.first_name}
                value={profile.first_name || ''}
                type={'text'}
                placeholder={t('common:label.first-name')}
                ariaLabel="First name"
                handleChange={(value: string) => handleChangeInput('first_name', value)}
              />
              <Input
                name={'last_name'}
                error={errors.last_name}
                value={profile.last_name || ''}
                type={'text'}
                placeholder={t('common:label.last-name')}
                ariaLabel="Last name"
                handleChange={(value: string) => handleChangeInput('last_name', value)}
              />
              <Input
                name={'email'}
                error={errors.email}
                value={profile.email || ''}
                type={'text'}
                placeholder={t('common:label.email-address')}
                ariaLabel="Email Address"
                handleChange={(value: string) => handleChangeInput('email', value)}
                navToError
              />
              <div className={styles.password__wrap}>
                <Input
                  name={'pwd_last_update'}
                  error={''}
                  value={`Last changed: ${basicDateFormat(profile.pwd_last_update || profile.date_joined)}`}
                  type={'text'}
                  placeholder={t('common:label.password')}
                  ariaLabel="Password"
                  disabled={true}
                  hideErrorBlock
                  className={styles.password__input}
                  handleChange={() => null}
                />
                <span
                  className={styles.password__reset}
                  onClick={() => handleResetPassword(Modals.isResetPassword)}
                >
                  {t('common:btn.reset-password')}
                </span>
              </div>
              <div className={`${styles.resendEmail}`}>
                <Input
                  name={'secondary_email'}
                  error={errors.secondary_email}
                  value={profile.secondary_email}
                  type={'text'}
                  placeholder={t('common:label.secondary-email-address')}
                  ariaLabel="Secondary Email Address"
                  handleChange={(value: string) => handleChangeInput('secondary_email', value)}
                  navToError
                />
                {!profile.verified_secondary_email &&
                initialProfile?.secondary_email === profile.secondary_email ? (
                  <div className={`b-userControl__addLinkWrapper ${styles.resendEmail_btn}`}>
                    <a href="#" className={`b-link-add ${'-disabled'}`} onClick={handleVerifyEmail}>
                      {t('common:btn.resend-verification')}
                    </a>
                  </div>
                ) : null}
              </div>
              <Select
                value={[...(profile.gender_data || [])]}
                placeholder={t('common:label.gender-identity')}
                ariaLabel="Gender Identity"
                options={gendersList}
                error={errors.gender}
                handleChange={onChangeSelect('gender_data')}
                isMulti={true}
              />
              <Select
                value={profile.pronouns_data}
                placeholder={t('common:label.pronouns')}
                ariaLabel="Pronouns"
                options={pronounsList}
                error={errors.pronouns || errors.pronouns_id}
                handleChange={onChangeSelect('pronouns_data')}
              />
              <Select
                value={[...(profile.race_data || [])]}
                placeholder={t('common:label.race')}
                ariaLabel="Race"
                options={racesList}
                error={errors.race}
                handleChange={onChangeSelect('race_data')}
                isMulti={true}
              />
              <Select
                value={[...(profile.ethnicity_data || [])]}
                placeholder={t('common:label.ethnicity')}
                ariaLabel="Ethnicity"
                options={ethnicityList}
                error={errors.ethnicity}
                handleChange={onChangeSelect('ethnicity_data')}
                isMulti={true}
              />
              <Select
                value={profile.education_data || ''}
                placeholder={t('common:label.education')}
                ariaLabel="Education"
                options={educationsList}
                error={errors.education}
                handleChange={onChangeSelect('education_data')}
              />
              <Input
                name={'title'}
                error={''}
                value={profile.role.title}
                type={'text'}
                placeholder={t('common:label.my-title')}
                ariaLabel="My Title"
                handleChange={value => handleChangeTitle(value)}
              />
              <Input
                name={'division'}
                error={''}
                value={profile.role.division}
                type={'text'}
                placeholder={t('common:label.division/department/unit')}
                ariaLabel="Division/Department/Unit"
                handleChange={value => handleChangeDivision(value)}
              />
              <Input
                name={'organization'}
                error={''}
                value={profile.org_name || ''}
                type={'text'}
                placeholder={t('common:label.organization-official-name')}
                ariaLabel="Organization Official Name"
                disabled={true}
                handleChange={() => null}
              />
              <Input
                name={'pm_email'}
                error={''}
                value={profile.pm_email}
                type={'text'}
                disabled={true}
                placeholder={t('common:label.pm-email')}
                ariaLabel="Organization PM’s Email Address"
                handleChange={() => null}
              />
              {user?.user?.notification.permission_edit_phone ? (
                <PhoneInput
                  error={errorPhoneNumber}
                  value={profile.phone_number || ''}
                  handleChange={value => handleChangePhone(value)}
                  placeholder={t('common:label.phone-number')}
                  onBlurPhoneValidation={onBlurPhoneValidation}
                />
              ) : null}
            </div>
            <h2 className={styles.form__subTitle}>{t('common:headlines.your-office-address')}</h2>
            <div className={styles.form__radioPart}>
              <div className={'b-myProfile'}>
                <RadioButton
                  title={t('common:radio-btn.same-headquarter')}
                  value={1}
                  selectedValue={profile.office_type}
                  onChangeValue={onChangeValue}
                />
                <RadioButton
                  title={t('common:radio-btn.satellite-office')}
                  value={2}
                  selectedValue={profile.office_type}
                  onChangeValue={onChangeValue}
                />
                <RadioButton
                  title={t('common:radio-btn.work-remotely')}
                  value={3}
                  selectedValue={profile.office_type}
                  onChangeValue={onChangeValue}
                />
              </div>
            </div>
            <div className={styles.form__part}>
              <Select
                name={'country'}
                value={countryValue}
                placeholder={t('common:label.country')}
                ariaLabel="Country"
                options={countries}
                error={errors.country}
                handleChange={(data: ISelectOptions) => onChangeCountry('country', String(data.value))}
                disabled={profile.office_type === 1}
                height={52}
              />
              {profile.office_type !== 3 && (
                <Autocomplete
                  placeholder={t('common:label.address')}
                  ariaLabel="Address"
                  onChange={handleAddressChange}
                  errors={errors}
                  fields={profile.office_address || {}}
                  disabled={profile.office_type === 1}
                  onChangeLocation={locationChangeHandler}
                />
              )}
              {profile.office_type !== 3 && (
                <Input
                  name={'suite'}
                  error={''}
                  value={profile.office_address?.suite}
                  ariaLabel="Suite/Office/Floor"
                  type={'text'}
                  placeholder={
                    isInternation ? t('common:label.international.suite') : t('common:label.us.suite')
                  }
                  disabled={profile.office_type === 1}
                  handleChange={value => handleChangeAddressField('suite', value)}
                />
              )}
              <Input
                name={'city'}
                error={errors.city}
                value={profile.office_address?.city}
                ariaLabel="City"
                type={'text'}
                placeholder={isInternation ? t('common:label.international.city') : t('common:label.us.city')}
                disabled={
                  profile.office_type === 1
                  //     || !(profile.office_address.city && !profile.office_address.state))
                  // && profile.office_type !== 3
                }
                handleChange={(value: string) => handleChangeAddressField('city', value)}
              />
              <Input
                name={'state'}
                error={errors.state}
                value={profile.office_address?.state}
                ariaLabel="State"
                type={'text'}
                placeholder={
                  isInternation ? t('common:label.international.state') : t('common:label.us.state')
                }
                disabled={
                  profile.office_type === 1
                  //     || !(profile.office_address.city && !profile.office_address.state))
                  // && profile.office_type !== 3
                }
                handleChange={(value: string) => handleChangeAddressField('state', value)}
              />
              <Input
                name={'postal'}
                error={errors.postal}
                value={profile.office_address?.postal}
                ariaLabel="Zip"
                type={'text'}
                placeholder={
                  isInternation ? t('common:label.international.postal') : t('common:label.us.postal')
                }
                disabled={profile.office_type === 1}
                handleChange={value => handleChangeAddressField('postal', value)}
              />
            </div>
            <div className={styles.form__buttons}>
              <Button
                onPress={handleCancel}
                title={t('common:btn.cancel')}
                size={'small'}
                type={'transparency'}
                disabled={isBlockSaveButton()}
              />
              <Button
                onPress={handleSubmit}
                title={t('common:btn.save')}
                size={'small'}
                type={'orange-light'}
                disabled={isBlockSaveButton()}
              />
            </div>
          </div>
        )}
      </main>
      {modal && modal !== Modals.isResetPassword ? (
        <Modal
          width={545}
          onClose={closeModal}
          className={styles.modal}
          title={`${modalNames()[modal]}: ${
            modal === 'education_data' ? t('common:headlines.other') : t('common:headlines.self-describe')
          }`}
        >
          <div className={styles.container}>
            <div className={`container-row mb-0`}>
              <div className={'input-wrapper'}>
                <Input
                  name={'newField'}
                  error={errors.newField}
                  ariaLabel={t('common:headlines.self-describe')}
                  value={newField}
                  type={'text'}
                  handleChange={value => handleChangeNewField(value)}
                />
              </div>
            </div>
            <div className={styles.btns}>
              <Button
                className={styles.btn}
                title={t('common:btn.close')}
                onPress={closeModal}
                type={'transparency'}
                size={'large'}
              />
              <Button
                className={styles.btn}
                title={t('common:btn.save')}
                onPress={handleCreateField}
                type={'orange-light'}
                size={'large'}
              />
            </div>
          </div>
        </Modal>
      ) : null}
      {modal === Modals.isResetPassword ? (
        <ResetPassword closeModal={() => setModal(false)} passwordChangedSuccess={passwordChangedSuccess} />
      ) : null}
      {modals.isResendEmail ? (
        <Alert
          isOpen={!!modals.isResendEmail}
          onRequestClose={() => setModals({})}
          buttons={{
            left: {
              type: 'white',
              onClick: () => setModals({}),
              title: t('common:btn.cancel'),
            },
            right: {
              type: 'orange-light',
              onClick: handleResendEmail,
              title: t('common:btn.send'),
            },
          }}
          title={t('awa:N37.msg')}
          text={` `}
          contentStyles={{
            width: '350px',
            transform: 'translate(-50%, -80%)',
          }}
        />
      ) : null}
      <ModalView
        isOpen={!!modals[Modals.isConfirmEmail]}
        onRequestClose={() => setModals({})}
        contentStyles={{
          transform: window.matchMedia('(max-width: 824px)').matches
            ? 'translate(-50%, -45%)'
            : 'translate(-50%, -58%)',
        }}
      >
        <EmailNotVerifiedContainer
          showNotification={modals[Modals.isConfirmEmail]}
          handleShowNotification={() => setModals({})}
        />
      </ModalView>
    </>
  );
};

export default MyProfile;
