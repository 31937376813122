import React, { useState } from 'react';
import styles from './Input.module.scss';
import { useIMask } from 'react-imask';

interface IInput {
  error?: string;
  placeholder?: string;
  value: string;
  handleChange?: (value: string) => void;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  hideErrorBlock?: boolean;
  ariaLabel?: string;
  name?: string;
  mask?: any;
}

const defaultMask = [
  { mask: '' },
  {
    mask: 'num',
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        thousandsSeparator: ',',
        padFractionalZeros: true,
        radix: '.',
        mapToRadix: [','],
      },
    },
  },
];

const InputMask = ({
  error,
  placeholder,
  handleChange,
  value: defaultValue,
  disabled,
  className,
  inputClassName,
  hideErrorBlock,
  ariaLabel,
  name,
  mask,
}: IInput) => {
  const [isFocus, setFocus] = useState(false);

  const [opts] = useState({
    mask: mask || defaultMask,
  });

  const onAccept = (value: string) => {
    handleChange && handleChange(value);
  };

  const { ref } = useIMask(opts, { onAccept });

  const onInputFocus = (e: any) => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  return (
    <div className={`${styles.input_wrapper} ${className ? className : ''}`}>
      <div
        className={`${styles.input_placeholder_block} ${error ? styles.input_wrapper_error : ''} ${
          isFocus ? styles.input_label_focus : ''
        }`}
        data-content={(defaultValue || '').length > 0 ? placeholder : ''}
      >
        <input
          className={`
                ${styles.input}
                ${error ? styles.input_error : ''}
                ${disabled ? styles.input_disable : ''}
                ${isFocus ? styles.input_focus : ''}
                ${inputClassName || ''}
                `}
          placeholder={placeholder}
          defaultValue={defaultValue.replace(',', '') || ''}
          onFocus={onInputFocus}
          onBlur={onBlur}
          type={'text'}
          disabled={disabled}
          ref={ref}
          name={name}
          aria-label={ariaLabel || placeholder}
        />
      </div>
      {!hideErrorBlock && (
        <div className={`error-notification`}>
          <span className={`error-notification-text`}>{error}</span>
        </div>
      )}
    </div>
  );
};

export default InputMask;
