import { SortDirection, useRowSelection } from 'react-data-grid';
import { basicDateFormat, getEwpApproveStatusClass } from '../../../../../../helpers';
import { Checkbox } from '../../../../../controls';
import React from 'react';
import { t } from 'i18next';
import {
  ColumnType,
  EwpElementTypes,
  EwpReviewItem,
  IRouteParamsBase,
  TableDataEntities,
} from '../../../../../../models';
import useTableData from '../../../../../../hooks/common/use-table-data';
import { TableDateFilter } from '../../../../../common';
import { useParams } from 'react-router';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

export const getColumns = (): ColumnType<EwpReviewItem>[] => [
  {
    key: 'title',
    name: t('common:tabs.review-item'),
    resizable: true,
    sortable: true,
    editable: false,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'element_type',
    name: t('common:tabs.ewp-element'),
    resizable: true,
    sortable: true,
    width: 180,
    editable: false,
    formatter(props) {
      if (props.row.element_type === EwpElementTypes.priority) {
        return t('common:tabs.priority');
      }
      if (props.row.element_type === EwpElementTypes.goal) {
        return t('common:tabs.goal');
      }
      if (props.row.element_type === EwpElementTypes.strategy) {
        return t('common:label.strategy');
      }
      if (props.row.element_type === EwpElementTypes.key_action) {
        return t('common:label.key-action');
      }
      return null;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'requester_name',
    name: t('common:column.requester'),
    resizable: true,
    sortable: true,
    width: 130,
    editable: false,
    formatter(props) {
      return <>{props.row.requester_name || t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'reviewer_name',
    name: t('common:column.reviewer'),
    resizable: true,
    sortable: true,
    width: 130,
    editable: false,
    formatter(props) {
      return <>{props.row.reviewer_name || t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'approval_status_name',
    name: t('common:column.status'),
    resizable: true,
    sortable: true,
    width: 180,
    editable: false,
    formatter(props) {
      return (
        <span className={`b-tableStatusMark ${getEwpApproveStatusClass(props.row.approval_status_id)}`}>
          {props.row.approval_status_name}
        </span>
      );
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'last_update',
    name: t('common:column.last-update'),
    resizable: true,
    sortable: true,
    width: 100,
    editable: false,
    formatter(props) {
      return <>{props.row.last_update ? basicDateFormat(props.row.last_update) : t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      const ewpId = useParams<IRouteParamsBase>().id;
      const { handleSelectDateFilter, query } = useTableData(TableDataEntities.EWP_REVIEW_ITEMS, {
        ewp_id: ewpId,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'last_update'}
            selectedFilters={query.dateFilters['last_update']}
            periodFieldName={'last_update_period'}
            dateRangeFieldName={'last_update_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
          />
        );
      }
    },
  },
  {
    key: 'tools',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
  },
  {
    key: 'select',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
    headerRenderer(props) {
      return (
        <Checkbox
          checked={props.allRowsSelected}
          onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
        />
      );
    },
    formatter: props => {
      const [isRowSelected, onRowSelectionChange] = useRowSelection();

      return (
        <div className="b-rdgTable__cellCenter">
          <div className={'checkBox'} onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={isRowSelected}
              onChange={e => {
                onRowSelectionChange({
                  row: props.row,
                  checked: e.target.checked,
                  isShiftClick: false,
                });
              }}
            />
          </div>
        </div>
      );
    },
  },
];
