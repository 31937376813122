import React, { FC, ReactNode } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Plugin,
  ChartOptions,
  LegendItem,
  LegendElement,
  ChartEvent,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { KeyValueModel } from '../../../models';
ChartJS.register(ArcElement, Tooltip, Legend);

export interface ChartPieV2ItemModel {
  percent: number;
  label: ReactNode;
  val: number;
}

interface IProps {
  chart: KeyValueModel<ChartPieV2ItemModel>;
  type: 'elm' | 'ea' | 'elmReport';
  colors: string[];
  title?: string;
}

const ChartPieV2: FC<IProps> = ({ chart, colors, title }) => {
  const getTitle = () => {
    if (title) {
      return title;
    }
  };

  const state = {
    labels: Object.values(chart || {}).map(m => m.label),
    datasets: [
      {
        labels: Object.keys(chart || {}).map(m => m),
        backgroundColor: colors,
        borderWidth: 0,
        hoverOffset: 5,
        data: Object.values(chart || {}).map(m => m.percent),
      },
    ],
  };
  const options: ChartOptions<any> = {
    responsive: true,
    layout: {
      padding: {
        bottom: 5,
        top: 0,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (data: any) {
            const el: any = chart?.[data.dataset && data.dataset.labels[data.dataIndex]] || 0;
            return `${data.label}: ${el.val} (${data.formattedValue}%)`;
          },
        },
      },
      legend: {
        position: 'bottom',
        display: false,
        lineWidth: 0,
        labels: {
          boxWidth: 4,
          usePointStyle: true,
          pointStyle: 'circle',
        },
        onClick: (
          el: LegendElement<any>,
          e: ChartEvent,
          legendItem: LegendItem,
          legend: LegendElement<any>,
        ) => {},
      },
      title: {
        display: true,
      },
      datalabels: {
        labels: [
          {
            align: 'top',
            formatter: (value: string, data: any) => {
              if ((chart?.[data.dataset && data.dataset.labels[data.dataIndex]].percent || 0) < 9) {
                return '';
              }
              const count = chart?.[data.dataset && data.dataset.labels[data.dataIndex]].val || 0;
              return `${count}`;
            },
            font: {
              weight: 'bold',
              family: 'Rubik',
              size: '16px',
            },
            color: '#fff',
          },
          {
            align: 'bottom',
            formatter: (value: string, data: any) => {
              if ((chart?.[data.dataset && data.dataset.labels[data.dataIndex]].percent || 0) < 9) {
                return '';
              }
              return `(${value}%)`;
            },
            font: {
              weight: 'normal',
              family: 'Rubik',
              size: '11px',
            },
            color: '#fff',
          },
        ],
      },
    },
  };

  return (
    <>
      <div className="b-statusChart__container">
        <Pie data={state} options={options} plugins={[ChartDataLabels] as Plugin<any>[]} />
      </div>
      <div className="b-statusChart__label">{getTitle()}</div>
      <div className="b-statusMonthsChart__legend">
        {Object.values(chart).map((item, index) => {
          return (
            <div key={`legend-${item.label}${index}`} className="b-statusMonthsChart__legendItem -noBefore">
              <span className="point" style={{ backgroundColor: colors[index] }} />
              {item.label}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ChartPieV2;
