import React from 'react';
import MarketingPanel from '../parts/MarketingPanel';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HeadlineOrganization, OrgBreadcrumbs, OrgProfileTabs } from '../../../../common';
import { Button, CohortStatusLabel, ImageSecured, Loader } from '../../../../controls';
import { useSelectCurrentOrg } from '../../../../../hooks/org/use-select-current-org';
import { useGetOrgCohortList } from '../../../../../hooks/cohorts/use-get-org-cohort-list';
import { ROOTS } from '../../../../../routes/routes';
import { useGetOrgRoute } from '../../../../../hooks/org/use-get-org-route';
import { basicDateFormat } from '../../../../../helpers';
import { DOMAIN_URI } from '../../../../../constants';

const CohortList = () => {
  const { t } = useTranslation();
  const currentOrg = useSelectCurrentOrg();
  const data = useGetOrgCohortList();
  const COHORT_DETAILS_ROUTE = useGetOrgRoute(ROOTS.COHORT_DETAILS);

  const renderContent = () => {
    if (!data) {
      return <Loader />;
    }
    if (data.result.length) {
      return (
        <div className="b-page__cardsList">
          {data.result.map(item => {
            return (
              <Link
                to={COHORT_DETAILS_ROUTE.replace(':id', String(item.id))}
                key={item.id}
                className="b-panelItem b-cohortCard b-page__cardItem"
              >
                <h2 className="b-panelItem__statusContainer mb-2">
                  <div className="b-cohortCard__title">{item.name}</div>
                </h2>

                <div className="d-flex">
                  {item.image?.file ? (
                    <div className="b-avatar -cohort mr-3">
                      <ImageSecured src={`${DOMAIN_URI}${item.image.file}`} alt={item.name} />
                    </div>
                  ) : null}
                  <div className="mt-2">
                    <div className="b-panelItem__title">
                      <CohortStatusLabel status={item.status} />
                    </div>
                    {item.start_date || item.end_date ? (
                      <div className="b-panelItem__title">
                        {item.start_date ? basicDateFormat(item.start_date) : null} {t('common:label.thru')}{' '}
                        {item.start_date ? basicDateFormat(item.end_date) : null}
                      </div>
                    ) : null}
                    <div className="b-panelItem__title">{item.type_name}</div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      );
    }
    return <MarketingPanel />;
  };

  return (
    <main className={'b-page'}>
      <div className="b-page__header -desktop">
        <HeadlineOrganization
          orgName={currentOrg?.name || ''}
          orgId={currentOrg?.id || ''}
          headline={t('common:headlines.cohort-program')}
          customClasses={'-noDesktopMb mr-4'}
        />
      </div>
      <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
      <OrgProfileTabs activeItemAlias="cohort" />
      <div className="b-page__cards">{renderContent()}</div>
    </main>
  );
};

export default CohortList;
