import { SortColumn } from 'react-data-grid';
import { BelDebriefsActionsTypes, BEL_GET_DEBRIEFS_LIST_SUCCESS, SET_QUERY } from './debriefsActions';
import { KeyValueModel, PaginationModel, BelDebriefModel } from '../../../models';
import { LOGOUT_SUCCESS } from '../../authData/authDataActions';
import { QueryParamsModel } from '../../../hooks/common/use-table-data';

export type BelDebriefsState = {
  debriefsList: PaginationModel<BelDebriefModel[]> | null;
  query: QueryParamsModel;
};

const initialState: BelDebriefsState = {
  debriefsList: null,
  query: {
    sorting: { columnKey: 'debrief_scheduled_date', direction: 'DESC' },
    search: '',
    page_size: 15,
    page: 1,
    filters: {},
    dateFilters: {},
  },
};

const belDebriefsReducer = (
  state: BelDebriefsState = initialState,
  action: BelDebriefsActionsTypes,
): BelDebriefsState => {
  switch (action.type) {
    case BEL_GET_DEBRIEFS_LIST_SUCCESS: {
      return {
        ...state,
        debriefsList: action.payload,
        query: {
          ...state.query,
          page: action.payload.current_page,
        },
      };
    }
    case LOGOUT_SUCCESS:
      return { ...initialState };
    case SET_QUERY: {
      return {
        ...state,
        query: action.payload,
      };
    }
    default:
      return state;
  }
};

export default belDebriefsReducer;
