import {
  BelAdminToolActionsTypes,
  BEL_AT_GET_ROLES_SUCCESS,
  BEL_AT_SET_CURRENT_ROLE,
  BEL_AT_UPDATE_ROLE_SUCCESS,
  BEL_AT_CREATE_ROLE_SUCCESS,
  BEL_AT_GET_USERS_SUCCESS,
  BEL_AT_GET_ORG_USERS_SUCCESS,
  SET_ORG_USERS_QUERY,
  SET_USERS_QUERY,
} from './adminToolActions';
import {
  AdminToolRoleModel,
  AdminToolPermissionModel,
  AdminToolUserModel,
  PaginationModel,
  AdminToolOrgUserModel,
  ISelectOptions,
} from '../../../models';
import { LOGOUT_SUCCESS } from '../../authData/authDataActions';
import { QueryParamsModel } from '../../../hooks/common/use-table-data';

export type BelAdminToolState = {
  roles: AdminToolRoleModel[] | null;
  permissions: AdminToolPermissionModel[] | null;
  currentRole: AdminToolRoleModel | null;
  users: PaginationModel<AdminToolUserModel[]> | null;
  usersQuery: QueryParamsModel;
  orgUsers: PaginationModel<AdminToolOrgUserModel[]> | null;
  orgUsersQuery: QueryParamsModel;
  orgUserStatuses: ISelectOptions[] | null;
};

const initialState: BelAdminToolState = {
  roles: null,
  permissions: null,
  currentRole: null,
  users: null,
  usersQuery: {
    sorting: null,
    search: '',
    page_size: 15,
    page: 1,
    filters: {},
    dateFilters: {},
  },
  orgUsers: null,
  orgUsersQuery: {
    sorting: null,
    search: '',
    page_size: 15,
    page: 1,
    filters: {},
    dateFilters: {},
  },
  orgUserStatuses: null,
};

const belAdminToolReducer = (
  state: BelAdminToolState = initialState,
  action: BelAdminToolActionsTypes,
): BelAdminToolState => {
  switch (action.type) {
    case BEL_AT_GET_ROLES_SUCCESS: {
      const newState = { ...state };
      newState.permissions = action.payload.permissions;
      newState.roles = action.payload.roles;
      if (!state.currentRole) {
        newState.currentRole = action.payload.roles[0];
      } else {
        const currentRoleFormPayload = action.payload.roles.find(
          role => role.value === state.currentRole?.value,
        );
        if (currentRoleFormPayload) {
          newState.currentRole = currentRoleFormPayload;
        } else {
          newState.currentRole = action.payload.roles[0];
        }
      }
      return newState;
    }
    case BEL_AT_SET_CURRENT_ROLE:
      return {
        ...state,
        currentRole: action.payload,
      };
    case BEL_AT_UPDATE_ROLE_SUCCESS: {
      if (!state.roles) return state;
      const roles = state.roles.map(role => {
        if (role.value === action.payload.value) {
          return action.payload;
        }
        return role;
      });

      return {
        ...state,
        roles,
        currentRole: action.payload,
      };
    }
    case BEL_AT_CREATE_ROLE_SUCCESS: {
      if (!state.roles) return state;
      return {
        ...state,
        roles: [...state.roles, action.payload],
        currentRole: action.payload,
      };
    }
    case BEL_AT_GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload,
        usersQuery: {
          ...state.usersQuery,
          page: action.payload.current_page,
        },
      };
    }
    case BEL_AT_GET_ORG_USERS_SUCCESS: {
      return {
        ...state,
        orgUsers: action.payload,
        orgUsersQuery: {
          ...state.orgUsersQuery,
          page: action.payload.current_page,
        },
      };
    }
    case SET_USERS_QUERY: {
      return {
        ...state,
        usersQuery: action.payload,
      };
    }
    case SET_ORG_USERS_QUERY: {
      return {
        ...state,
        orgUsersQuery: action.payload,
      };
    }
    case LOGOUT_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default belAdminToolReducer;
