export enum Standards {
  diversity = 'Diversity',
  equity = 'Equity',
  inclusion = 'Inclusion',
}

export enum URLChartType {
  group = 'function-group',
  standard = 'standard',
}

export enum ChartAlias {
  diversity = 'diversity',
  sub_diversity = 'sub_diversity',
  equity = 'equity',
  sub_equity = 'sub_equity',
  inclusion = 'inclusion',
  sub_inclusion = 'sub_inclusion',
  f_group = 'f_group',
}
