import { call, all, take, StrictEffect, put } from 'redux-saga/effects';
import {
  CREATE_CREDIT,
  CreateCreditPayload,
  createCreditSuccess,
  GET_CREDITS_LIST,
  GET_CREDITS_OPTIONS,
  getCreditsList,
  GetCreditsListPayload,
  getCreditsListSuccess,
  getCreditsOptionsSuccess,
  UPDATE_CREDIT,
  UpdateCreditPayload,
  updateCreditSuccess,
} from './creditActions';
import Api from '../../services/api';
import { toast } from 'react-toastify';
import { t } from 'i18next';

function* createCreditSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: CreateCreditPayload } = yield take(CREATE_CREDIT);

    try {
      const response = yield call(Api.post, `beloved/organization_credits/`, data);
      if (response) {
        yield put(createCreditSuccess(response));
        callback && callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* getCreditsListSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { orgId },
    }: { payload: GetCreditsListPayload } = yield take(GET_CREDITS_LIST);

    try {
      const response = yield call(Api.get, `beloved/organization_credits/`, {
        organization_id: orgId,
        page: 1,
        page_size: 500,
      });

      if (response) {
        yield put(getCreditsListSuccess(response.result));
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* getCreditsOptionsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    yield take(GET_CREDITS_OPTIONS);

    try {
      const response = yield call(Api.get, `beloved/organization_credits/options/`);

      if (response) {
        yield put(getCreditsOptionsSuccess(response));
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* updateCreditSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data, creditId },
    }: { payload: UpdateCreditPayload } = yield take(UPDATE_CREDIT);

    try {
      const response = yield call(Api.patch, `beloved/organization_credits/${creditId}/`, undefined, data);
      if (response) {
        yield put(updateCreditSuccess(response));
        callback && callback(response);
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        toast.error(e.response.data[0]);
        yield put(getCreditsList({ orgId: data.organization_id }));
      }
    }
  }
}

export function* saga() {
  yield all([getCreditsListSaga(), createCreditSaga(), getCreditsOptionsSaga(), updateCreditSaga()]);
}
