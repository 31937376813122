import { call, all, take, StrictEffect, put, throttle } from 'redux-saga/effects';
import { getOrgIdFormRoute } from '../../helpers';
import Api from '../../services/api';
import * as actionTypes from './ewpActions';
import {
  CANCEL_EWP,
  CancelEWPPayload,
  CREATE_EWP,
  CreateEWPPayload,
  createEWPSuccess,
  GET_ELEMENTS_FILTER_OPTIONS,
  GET_EWP_AVAILABLE_MANAGERS,
  GET_EWP_DETAILS,
  GetAvailableManagersPayload,
  getElementsFilterOptionsSuccess,
  GetEWPDetailsPayload,
  setCreateEwpData,
  setEwpDetails,
  UPDATE_EWP,
  UpdateEWPPayload,
  updateEWPSuccess,
} from './ewpActions';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { ActionModel, EwpCreateStepEnum, EWPModel, PaginationModel } from '../../models';

function* getEwpReviewItemsSaga({
  payload: { callback, params, extraData },
}: ActionModel<typeof actionTypes.GET_EWP_REVIEW_ITEMS, actionTypes.GetEwpReviewItemsPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  const orgId = getOrgIdFormRoute();

  console.log('extraData', extraData);

  const response = yield call(
    Api.get,
    `organizations/${orgId}/ewp/ewp_elements/${extraData?.ewp_id}/`,
    params,
  );
  if (response) {
    yield put(actionTypes.getEwpReviewItemsSuccess(response));
    callback && callback();
  }
}

function* getEwpUsersSaga({
  payload: { callback, params, extraData },
}: ActionModel<typeof actionTypes.GET_EWP_USERS, actionTypes.GetEwpUsersPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  const orgId = getOrgIdFormRoute();

  const response = yield call(Api.get, `organizations/${orgId}/ewp/ewp_users/${extraData?.ewp_id}/`, params);
  if (response) {
    yield put(actionTypes.getEwpUsersSuccess(response));
    callback && callback();
  }
}

function* getPriorityListSaga({
  payload: { callback, params, extraData },
}: ActionModel<typeof actionTypes.GET_PRIORITY_LIST, actionTypes.GetPriorityListPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  const orgId = getOrgIdFormRoute();

  const response = yield call(Api.get, `organizations/${orgId}/ewp/${extraData?.ewp_id}/priorities/`, params);
  if (response) {
    yield put(actionTypes.getPriorityListSuccess(response));
    callback && callback();
  }
}

function* getGoalListSaga({
  payload: { callback, params, extraData },
}: ActionModel<typeof actionTypes.GET_GOAL_LIST, actionTypes.GetGoalListPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  const orgId = getOrgIdFormRoute();

  const response = yield call(Api.get, `organizations/${orgId}/ewp/${extraData?.ewp_id}/goals/`, params);
  if (response) {
    yield put(actionTypes.getGoalListSuccess(response));
    callback && callback();
  }
}

function* getStratsListSaga({
  payload: { callback, params, extraData },
}: ActionModel<typeof actionTypes.GET_STRATS_LIST, actionTypes.GetStratsListPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  const orgId = getOrgIdFormRoute();

  const response = yield call(Api.get, `organizations/${orgId}/ewp/${extraData?.ewp_id}/strategies/`, params);
  if (response) {
    yield put(actionTypes.getStratsListSuccess(response));
    callback && callback();
  }
}

function* getKaListSaga({
  payload: { callback, params, extraData },
}: ActionModel<typeof actionTypes.GET_KA_LIST, actionTypes.GetKaListPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  const orgId = getOrgIdFormRoute();

  const response = yield call(
    Api.get,
    `organizations/${orgId}/ewp/${extraData?.ewp_id}/key_actions/`,
    params,
  );
  if (response) {
    yield put(actionTypes.getKaListSuccess(response));
    callback && callback();
  }
}

function* belGetEwpListSaga({
  payload: { callback, params },
}: ActionModel<typeof actionTypes.BEL_GET_EWP_LIST, actionTypes.BelGetKaListPayload>): Generator<
  StrictEffect,
  void,
  any
> {
  const response = yield call(Api.get, 'beloved/ewp/', params);
  if (response) {
    yield put(actionTypes.belGetEwpListSuccess(response));
    callback && callback();
  }
}

function* getEWPDetailsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, orgId, isCreate },
    }: { payload: GetEWPDetailsPayload } = yield take(GET_EWP_DETAILS);

    try {
      const response: PaginationModel<EWPModel[]> = yield call(Api.get, `organizations/${orgId}/ewp/`);

      if (response) {
        if (isCreate) {
          yield put(setCreateEwpData(response.result[0] || null));
        } else {
          yield put(setEwpDetails(response.result[0] || null));
        }
        callback && callback(response.result[0] || null);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* createEWPSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback },
    }: { payload: CreateEWPPayload } = yield take(CREATE_EWP);
    try {
      const orgId = getOrgIdFormRoute();

      const response = yield call(Api.post, `organizations/${orgId}/ewp/`);

      if (response) {
        callback && callback();
        yield put(createEWPSuccess(response));
      }
    } catch (e: any) {
      callback && callback(null, e);
      toast.error(e.response.data.msg);
    }
  }
}

function* updateEWPSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data, ewpId, isDetails, orgId },
    }: { payload: UpdateEWPPayload } = yield take(UPDATE_EWP);

    try {
      const response = yield call(Api.patch, `organizations/${orgId}/ewp/${ewpId}/`, undefined, data);
      if (response) {
        if (isDetails) {
          yield put(setEwpDetails(response));
        } else {
          if (data.ewp_screen === EwpCreateStepEnum.finish) {
            yield put(setEwpDetails(response));
          }
          yield put(updateEWPSuccess(response));
        }
        callback && callback(response);
      }
    } catch (e: any) {
      if (e.response.data.detail) {
        toast.error(e.response.data.detail);
      } else {
        toast.error(t('common:toast.error'));
      }
    }
  }
}

function* cancelEWPSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, ewpId },
    }: { payload: CancelEWPPayload } = yield take(CANCEL_EWP);
    const orgId = getOrgIdFormRoute();

    try {
      const response = yield call(Api.delete, `organizations/${orgId}/ewp/${ewpId}/cancel_ewp/`);

      if (response) {
        callback && callback();
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* getElementsFilterOptionsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { orgId },
    }: { payload: { orgId: Number } } = yield take(GET_ELEMENTS_FILTER_OPTIONS);

    try {
      const response = yield call(Api.get, `utils/ewp/options/${orgId}/`);

      if (response) {
        yield put(getElementsFilterOptionsSuccess(response));
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* getAvailableManagersSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, orgId },
    }: { payload: GetAvailableManagersPayload } = yield take(GET_EWP_AVAILABLE_MANAGERS);
    try {
      const response = yield call(Api.get, `organizations/${orgId}/ewp/available_managers//`);
      if (response && callback) {
        callback(response);
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

export function* saga() {
  yield all([
    throttle(400, actionTypes.GET_PRIORITY_LIST, getPriorityListSaga),
    throttle(400, actionTypes.GET_EWP_REVIEW_ITEMS, getEwpReviewItemsSaga),
    throttle(400, actionTypes.GET_EWP_USERS, getEwpUsersSaga),
    throttle(400, actionTypes.GET_GOAL_LIST, getGoalListSaga),
    throttle(400, actionTypes.GET_STRATS_LIST, getStratsListSaga),
    throttle(400, actionTypes.GET_KA_LIST, getKaListSaga),
    throttle(400, actionTypes.BEL_GET_EWP_LIST, belGetEwpListSaga),
    getEWPDetailsSaga(),
    createEWPSaga(),
    updateEWPSaga(),
    getElementsFilterOptionsSaga(),
    cancelEWPSaga(),
    getAvailableManagersSaga(),
  ]);
}
