import {
  ActionModel,
  BelOrgDetailsModel,
  BelTeamAssignmentRolesModel,
  PmProfileModel,
} from '../../../models';
import { actionCreator } from '../../actionCreator';
import { LOGOUT_SUCCESS } from '../../authData/authDataActions';

//-----BEL_GET_ORGANIZATION_DETAILS---//
export const BEL_GET_ORGANIZATION_DETAILS = 'beloved/BEL_GET_ORGANIZATION_DETAILS';
export const BEL_GET_ORGANIZATION_DETAILS_SUCCESS = 'beloved/BEL_GET_ORGANIZATION_DETAILS_SUCCESS';
export interface BelGetOrganizationDetailsPayload {
  orgId: number;
  callback?: (data: BelOrgDetailsModel) => void;
}

export const belGetOrganizationDetails = actionCreator<BelGetOrganizationDetailsPayload>(
  BEL_GET_ORGANIZATION_DETAILS,
);
export const belGetOrganizationDetailsSuccess = actionCreator<BelOrgDetailsModel>(
  BEL_GET_ORGANIZATION_DETAILS_SUCCESS,
);

//-----BEL_GET_PM_PROFILE---//
export const BEL_GET_PM_PROFILE = 'beloved/BEL_GET_PM_PROFILE';
export interface BelGetPmProfilePayload {
  orgId: number;
  callback: (data: PmProfileModel) => void;
}
export const belGetPmProfile = actionCreator<BelGetPmProfilePayload>(BEL_GET_PM_PROFILE);

//-----BEL_REMOVE_SUB_ORG---//
export const BEL_REMOVE_SUB_ORG = 'beloved/BEL_REMOVE_SUB_ORG';
export interface BelRemoveSubOrgPayload {
  orgId: number;
  callback: (data: PmProfileModel) => void;
}
export const belRemoveSubOrg = actionCreator<BelRemoveSubOrgPayload>(BEL_REMOVE_SUB_ORG);

export const SET_BEL_ORGANIZATION_TEAM_ROLES = 'beloved/SET_BEL_ORGANIZATION_TEAM_ROLES';
export const setBelOrganizationTeamRolesList = actionCreator<BelTeamAssignmentRolesModel>(
  SET_BEL_ORGANIZATION_TEAM_ROLES,
);

export type BelOrganizationsActionsTypes =
  | ActionModel<typeof LOGOUT_SUCCESS>
  | ActionModel<typeof BEL_REMOVE_SUB_ORG, BelRemoveSubOrgPayload>
  | ActionModel<typeof LOGOUT_SUCCESS>
  | ActionModel<typeof BEL_GET_ORGANIZATION_DETAILS_SUCCESS, BelOrgDetailsModel>
  | ActionModel<typeof SET_BEL_ORGANIZATION_TEAM_ROLES, BelTeamAssignmentRolesModel>
  | ActionModel<typeof BEL_GET_PM_PROFILE, BelGetPmProfilePayload>;
