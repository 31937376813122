import React from 'react';
import { Header } from '../../../common';

import styles from './PrivacyPolicySignUp.module.scss';
import { useTranslation } from 'react-i18next';

const PrivacyPolicySignUp = () => {
  const mail = <a href="mailto:privacy@wearebeloved.org">privacy@wearebeloved.org</a>;
  const { t } = useTranslation();
  return (
    <div className={'container container-header white-container'}>
      <Header type={'awa_text'} />
      <div className={`white-container content-center-wrapper ${styles.container}`}>
        <section className={'pageContainer pageContainer__content'}>
          <div className={'b-formAccount -center'}>
            <h1>{t('pp:privacy.p1')}</h1>
            <p>{t('pp:privacy.p2')}</p>
            <h2>{t('pp:privacy.p3')}</h2>
            <p>
              {t('pp:privacy.p4')} (&quot;<b>{t('pp:privacy.p5')}</b>&quot;, &quot;<b>{t('pp:privacy.p6')}</b>
              &quot; {'pp:privacy.p7'}“<b>{t('pp:privacy.p8')}</b>”) {t('pp:privacy.p9')}“
              <b>{t('pp:privacy.p10')}</b>
              ”) {t('pp:privacy.p11')} “<b>{t('pp:privacy.p12')}</b>”) {t('pp:privacy.p13')}
            </p>
            <p>{t('pp:privacy.p14')}:</p>
            <ul>
              <li>{t('pp:privacy.p15')};</li>
              <li>{t('pp:privacy.p16')};</li>
              <li>{t('pp:privacy.p17')};</li>
              <li>
                {t('pp:privacy.p18')}“<b>{t('pp:privacy.p19')}</b>”); {t('pp:privacy.p20')}
              </li>
              <li>
                {t('pp:privacy.p21')}“<b>{t('pp:privacy.p22')}</b>”).
              </li>
            </ul>
            <p>
              {t('pp:privacy.p23')} <i>{t('pp:privacy.p24')}</i>). {t('pp:privacy.p25')}
            </p>
            <h2>{t('pp:privacy.p26')}.</h2>
            <p>{t('pp:privacy.p27')}</p>
            <p>
              <b>{t('pp:privacy.p28')}</b>: {t('pp:privacy.p29')}:
            </p>
            <ul>
              <li>{t('pp:privacy.p30')};</li>
              <li>{t('pp:privacy.p31')};</li>
              <li>{t('pp:privacy.p32')};</li>
              <li>{t('pp:privacy.p33')};</li>
              <li>
                {t('pp:privacy.p34')} <i>{t('pp:privacy.p35')}</i> {t('pp:privacy.p36')}{' '}
                <i>{t('pp:privacy.p37')}</i>); {t('pp:privacy.p38')}
              </li>
              <li>{t('pp:privacy.p39')}</li>
            </ul>
            <p>
              <b>{t('pp:privacy.p40')}</b>: {t('pp:privacy.p41')}:
            </p>
            <ul>
              <li>{t('pp:privacy.p42')}</li>
              <li>{t('pp:privacy.p43')}</li>
            </ul>
            <p>{t('pp:privacy.p44')}</p>
            <h2>{t('pp:privacy.p45')} </h2>
            <p>{t('pp:privacy.p46')}</p>
            <ul>
              <li>{t('pp:privacy.p47')}</li>
              <li>{t('pp:privacy.p48')}</li>
            </ul>
            <p>{t('pp:privacy.p49')}</p>
            <h2>{t('pp:privacy.p50')}</h2>
            <p>{t('pp:privacy.p51')}</p>
            <ul>
              <li>{t('pp:privacy.p52')}</li>
              <li>{t('pp:privacy.p53')}</li>
              <li>{t('pp:privacy.p54')}</li>
              <li>{t('pp:privacy.p55')}</li>
              <li>{t('pp:privacy.p56')}</li>
              <li>{t('pp:privacy.p57')}</li>
              <li>{t('pp:privacy.p58')}</li>
              <li>{t('pp:privacy.p59')}</li>
              <li>{t('pp:privacy.p60')}</li>
              <li>{t('pp:privacy.p61')}</li>
              <li>{t('pp:privacy.p62')}</li>
            </ul>
            <h2>{t('pp:privacy.p63')}</h2>
            <p>
              {t('pp:privacy.p64')} <i>{t('pp:privacy.p65')}</i> {t('pp:privacy.p66')}{' '}
              <i>{t('pp:privacy.p67')}</i> {t('pp:privacy.p68')}
            </p>
            <p>{t('pp:privacy.p69')}</p>
            <ul>
              <li>
                {t('pp:privacy.p70')} <i>{t('pp:privacy.p71')}</i> {t('pp:privacy.p72')}
              </li>
              <li>{t('pp:privacy.p73')}</li>
              <li>{t('pp:privacy.p74')}</li>
              <li>{t('pp:privacy.p75')}</li>
              <li>{t('pp:privacy.p76')}</li>
              <li>{t('pp:privacy.p77')}</li>
              <li>{t('pp:privacy.p78')}</li>
            </ul>
            <h2>{t('pp:privacy.p79')}</h2>
            <p>{t('pp:privacy.p80')}</p>
            <ul>
              <li>{t('pp:privacy.p81')}</li>
              <li>
                {t('pp:privacy.p82')} {mail}. {t('pp:privacy.p83')}
              </li>
              <li>{t('pp:privacy.p84')}</li>
            </ul>
            <h2>{t('pp:privacy.p85')}</h2>
            <p>{t('pp:privacy.p86')}</p>
            <ul>
              <li>
                <b>{t('pp:privacy.p87')}</b>: {t('pp:privacy.p88')}{' '}
                <a href="https://www.squarespace.com/privacy/">{t('pp:privacy.p89')}</a>.
              </li>
              <li>
                <b>{t('pp:privacy.p90')}</b>: {t('pp:privacy.p91')}{' '}
                <a href="https://www.quicken.com/privacy/us">{t('pp:privacy.p92')}</a>.
              </li>
              <li>
                <b>{t('pp:privacy.p93')}</b>: {t('pp:privacy.p94')}{' '}
                <a href="https://mailchimp.com/legal/privacy/">{t('pp:privacy.p95')}</a>.
              </li>
            </ul>
            <h2>{t('pp:privacy.p96')}</h2>
            <p>{t('pp:privacy.p97')}</p>
            <h2>{t('pp:privacy.p98')}</h2>
            <p>
              {t('pp:privacy.p99')} {mail}.
            </p>
            <h2>{t('pp:privacy.p100')}</h2>
            <p>{t('pp:privacy.p101')}</p>
            <h2>{t('pp:privacy.p102')}</h2>
            <p>{t('pp:privacy.p103')}</p>
            <p>{t('pp:privacy.p104')}</p>
            <h2>{t('pp:privacy.p105')}</h2>
            <p>{t('pp:privacy.p106')}</p>
            <h2>{t('pp:privacy.p107')}</h2>
            <h2>{t('pp:privacy.p108')}</h2>
            <p>{t('pp:privacy.p109')}</p>
            <p>{t('pp:privacy.p110')}</p>
            <p>
              {t('pp:privacy.p111')} {mail}.
            </p>
            <h2>{t('pp:privacy.p112')}</h2>
            <p>{t('pp:privacy.p113')}</p>
            <h2>{t('pp:privacy.p114')}</h2>
            <p>{t('pp:privacy.p115')}</p>
            <ul>
              <li>
                <b>{t('pp:privacy.p116')}</b>: {t('pp:privacy.p117')}
              </li>
              <li>
                <b>{t('pp:privacy.p118')}</b>: {t('pp:privacy.p119')}
                <ul>
                  <li>{t('pp:privacy.p120')}</li>
                  <li>{t('pp:privacy.p121')}</li>
                  <li>{t('pp:privacy.p122')}</li>
                  <li>{t('pp:privacy.p123')}</li>
                  <li>{t('pp:privacy.p124')}</li>
                  <li>{t('pp:privacy.p125')}</li>
                </ul>
              </li>
              <li>
                <b>{t('pp:privacy.p126')}</b>: {t('pp:privacy.p127')}
              </li>
              <li>
                <b>{t('pp:privacy.p128')}</b>: {t('pp:privacy.p129')}
              </li>
            </ul>
            <h2>{t('pp:privacy.p130')}</h2>
            <p>{t('pp:privacy.p131')}</p>
            <h2>{t('pp:privacy.p132')}</h2>
            <p>
              {t('pp:privacy.p133')} {mail}. {t('pp:privacy.p134')}
            </p>
            <ul>
              <li>
                <b>{t('pp:privacy.p135')}</b>: {t('pp:privacy.p136')} {mail}.
              </li>
              <li>
                <b>{t('pp:privacy.p137')}</b>: {t('pp:privacy.p138')} {mail}.
              </li>
              <li>
                <b>{t('pp:privacy.p139')}</b>: {t('pp:privacy.p140')}
              </li>
              <li>
                <b>{t('pp:privacy.p141')}</b>: {t('pp:privacy.p142')}
              </li>
              <li>
                <b>{t('pp:privacy.p143')}</b>: {t('pp:privacy.p144')}
              </li>
              <li>
                <b>{t('pp:privacy.p145')}</b>: {t('pp:privacy.p146')}
              </li>
              <li>
                <b>{t('pp:privacy.p147')}</b>: {t('pp:privacy.p148')}
              </li>
              <li>
                {t('pp:privacy.p149')}{' '}
                <a href="https://edpb.europa.eu/about-edpb/board/members_en">
                  https://edpb.europa.eu/about-edpb/board/members_en
                </a>
                .
              </li>
            </ul>
            <h2>{t('pp:privacy.p150')}</h2>
            <p>{t('pp:privacy.p151')}</p>
            <ul>
              <li>{t('pp:privacy.p152')}</li>
              <li>{t('pp:privacy.p153')}</li>
              <li>{t('pp:privacy.p154')}</li>
              <li>{t('pp:privacy.p155')}</li>
              <li>{t('pp:privacy.p156')}</li>
            </ul>
            <h2>{t('pp:privacy.p157')}</h2>
            <p>{t('pp:privacy.p158')}</p>
            <ul>
              <li>{t('pp:privacy.p159')}</li>
              <li>{t('pp:privacy.p160')}</li>
              <li>{t('pp:privacy.p161')}</li>
              <li>{t('pp:privacy.p162')}</li>
              <li>{t('pp:privacy.p163')}</li>
              <li>{t('pp:privacy.p164')}</li>
            </ul>
            <h2>{t('pp:privacy.p165')}</h2>
            <p>{t('pp:privacy.p166')}</p>
            <h2>{t('pp:privacy.p167')}?</h2>
            <p>{t('pp:privacy.p168')}</p>
            <p>
              Beloved Community <br />
              3157 Gentilly Blvd. <br />
              #176 <br />
              New Orleans, LA 70122 <br />
              {mail}
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicySignUp;
