import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  ElmModel,
  ElmUpdateModel,
  IRouteParamsBase,
  KeyValueModel,
  ScheduleTimeZoneModel,
} from '../../../../../models';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { AddMember, AddToCalendar, AddToCalendarDebrief, HeadlineOrganization } from '../../../../common';
import {
  closeAdminElm,
  getElmAManagers,
  getElmDetails,
  publishElmReport,
  sendNotification,
  updateElm,
} from '../../../../../store/elm/elmActions';
import { Button, Checkbox, ElmStatusLabel, Link, Loader, SimpleTooltip } from '../../../../controls';
import EditIc from '../../../../../assets/images/icons/round-image-edit.svg';

import { ElmRoles, ElmStatuses } from '../../../../../constants/elm';
import {
  basicDateFormat,
  filterByArray,
  getBasicDateFormatCodeForDatePicker,
  getBasicDateHourFormatCodeForDatePicker,
  getBasicDateHourFormatCodeTzForDatePicker,
  getDebriefDateTime,
  hasElmRole,
} from '../../../../../helpers';
import { ROOTS } from '../../../../../routes/routes';
import { useTranslation } from 'react-i18next';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { showAlert } from '../../../../common/Alert/RemoteAlert';
import { useSelectCurrentOrg } from '../../../../../hooks/org/use-select-current-org';
import { isMobileOnly } from 'react-device-detect';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';
import TimeZoneSelectView from '../../../../common/TimeZoneSelect/TimeZoneSelect';
import dayjs from 'dayjs';
import { ScheduleAlias } from '../../../../../constants';
import { addDays, setHours, setMinutes } from 'date-fns';
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';

interface IRouteParams extends IRouteParamsBase {
  elmId: string;
}

const ElmDetails = () => {
  const { orgId, elmId } = useParams<IRouteParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const managersList = useSelector((state: StateType) => state.elm.managersList);
  const { t } = useTranslation();
  const { navBack } = useNavBack();
  const currentOrg = useSelectCurrentOrg();
  let [loading, setLoading] = useState(false);
  let [selectedManagers, setSelectedManagers] = useState<number[]>([]);
  let [data, setData] = useState<ElmModel | null>(null);
  const [tz, setTz] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const isDebriefExist = data?.debrief_scheduled_date.book_full_date;
  const [tempDatePayload, setTempPayload] = useState<ElmUpdateModel | null>(null);
  const [tempSelectedDate, setSelectedDate] = useState<KeyValueModel<Date | null> | null>(null);

  useEffect(() => {
    dispatch(
      getElmDetails({
        elmId: Number(elmId),
        callback: data => {
          if (data) {
            setData(data);
          }
        },
      }),
    );
    dispatch(getElmAManagers());
  }, []);

  const handleSelectUser = (userId: number) => {
    if (selectedManagers.includes(userId)) {
      const result = [...selectedManagers].filter(id => id !== userId);
      setSelectedManagers(result);
    } else {
      setSelectedManagers([...selectedManagers, userId]);
    }
  };

  const handleCloseElm = (elm: ElmModel) => {
    const action = () => {
      dispatch(
        closeAdminElm({
          elmId: elm.id,
          callback: data => {
            setData(data);
          },
        }),
      );
    };

    showAlert({
      title: t('main:elm-close-admin.title'),
      text: (
        <>
          <p>{t('main:elm-close-admin.msg.p1')}</p>
          <p>{t('main:elm-close-admin.msg.p2')}</p>
        </>
      ),
      buttons: {
        left: {
          title: t('common:btn.no'),
          type: 'transparency',
        },
        right: {
          title: t('common:btn.yes'),
          type: 'primary',
          onClick: action,
        },
      },
    });
  };

  const handleChangeTimeZone = (timeZone: ScheduleTimeZoneModel) => {
    if (!data) {
      return;
    }
    setData({
      ...data,
      end_date: `${dayjs(data.end_date).tz(timeZone.value).format('YYYY-MM-DD HH:mm:00 Z')}`,
    });
    setTz(timeZone.value);
  };

  const handleAddManagers = (managerIds: number[], elm: ElmModel | null) => {
    if (elm) {
      let data: ElmUpdateModel = { manager_ids: managerIds };
      setLoading(true);
      dispatch(
        updateElm({
          elmId: elm.id as number,
          data,
          isDetails: true,
          callback: (isError, data) => {
            if (data) {
              setData(data);
            }
            setSelectedManagers([]);
            setLoading(false);
          },
        }),
      );
    }
  };

  const handleSendNotification = (elm: ElmModel | null) => {
    if (elm) {
      setLoading(true);
      dispatch(
        sendNotification({
          userIds: selectedManagers,
          elmId: elm.id as number,
          callback: () => {
            setLoading(false);
          },
        }),
      );
    }
  };

  const handleDeleteUser = (elm: ElmModel | null) => {
    if (elm) {
      let data: ElmUpdateModel = {};
      data.manager_ids = filterByArray(
        [...elm.managers].map(user => user.id),
        selectedManagers,
      ) as number[];
      setLoading(true);
      dispatch(
        updateElm({
          elmId: elm.id as number,
          data,
          isDetails: true,
          callback: (isError, data) => {
            if (data) {
              setData(data);
            }
            setSelectedManagers([]);
            setLoading(false);
          },
        }),
      );
    }
  };

  const handleViewUserList = () => {
    history.push(ROOTS.ELM_USER_LIST.replace(':orgId', orgId).replace(':elmId', String(elmId)));
  };

  const onChangeDate = (
    date: Date,
    fieldName: 'start_date' | 'end_date',
    event?: React.SyntheticEvent<any>,
  ) => {
    if (!data) return null;
    const payload: ElmUpdateModel = {
      end_date: new Date(data.end_date),
      start_date: new Date(data.start_date),
    };
    payload[fieldName] = date;
    if (fieldName === 'end_date') {
      // @ts-ignore
      const timeZoneGmt = dayjs().tz(tz).format('Z', { tz });
      const isSameDate = dayjs().tz(tz).isSame(date, 'd');
      if (isSameDate && event) {
        // payload[fieldName] = dayjs(endAt).$d;
        payload[fieldName] = roundToNearestMinutes(
          setHours(setMinutes(new Date(), dayjs().tz(tz).minute()), dayjs().tz(tz).hour()),
          { nearestTo: 30, roundingMethod: 'ceil' },
        );
        const isSameEndDate = dayjs(data.end_date).tz(tz).isSame(date, 'd');
        if (isSameEndDate) {
          const endAt = dayjs(data.end_date).format(`YYYY-MM-DD HH:mm:00 ${timeZoneGmt}`);
          payload[fieldName] = dayjs(endAt).$d;
        }
      } else {
        const endAt = dayjs(date).format(`YYYY-MM-DD HH:mm:00 ${timeZoneGmt}`);
        payload[fieldName] = dayjs(endAt).$d;
      }
    }
    if (fieldName === 'start_date' && date.getTime() > new Date(data.end_date as string).getTime()) {
      const endAt = dayjs(date).tz(tz).format('YYYY-MM-DD HH:mm:00 Z') as any;
      payload['end_date'] = dayjs(endAt).$d;
    }
    setTempPayload(payload);
    setSelectedDate({
      [fieldName]: date,
    });
  };

  const onCloseDatePicker = () => {
    if (!data || !tempDatePayload) {
      return;
    }
    setLoading(true);
    dispatch(
      updateElm({
        elmId: data.id as number,
        data: tempDatePayload,
        isDetails: true,
        callback: (isError, data) => {
          if (data) {
            setData(data);
          }
          setTempPayload(null);
          setLoading(false);
        },
      }),
    );
  };

  const handlePublishElmReport = (elm: ElmModel) => {
    dispatch(
      publishElmReport({
        elmId: elm.id,
        callback: data => {
          if (data) {
            setData(data);
          }
        },
      }),
    );
  };

  const _getEndMinDate = (data: ElmModel) => {
    const today = new Date();
    if (today.getTime() > new Date(data.start_date).getTime()) {
      return today;
    }

    return new Date(data.start_date);
  };

  const renderMetrics = (data: ElmModel) => {
    if (data.status < ElmStatuses.IN_PROGRESS) {
      if (data.import_file) {
        return (
          <>
            <div className="b-elmDetails__panelRow">
              <div className="b-elmDetails__panelParam -w50">
                <div className="b-elmDetails__title">{t('common:status.invited')}</div>
                <div className="b-elmDetails__val">{data.invited}</div>
              </div>
              <div className="b-elmDetails__panelParam -w50">
                <div className="b-elmDetails__title">{t('common:status.registered')}</div>
                <div className="b-elmDetails__val">{data.registered}</div>
              </div>
            </div>
          </>
        );
      }
    }
    if (data.status >= ElmStatuses.IN_PROGRESS) {
      return (
        <>
          <div className="b-elmDetails__panelRow">
            <div className="b-elmDetails__panelParam -w50">
              <div className="b-elmDetails__title">{t('common:status.submitted')}</div>
              <div className="b-elmDetails__val">{data.submitted}</div>
            </div>
            {data.status === ElmStatuses.IN_PROGRESS ? (
              <div className="b-elmDetails__panelParam -w50">
                <div className="b-elmDetails__title">{t('common:status.in-progress')}</div>
                <div className="b-elmDetails__val">{data.in_progress}</div>
              </div>
            ) : (
              <div className="b-elmDetails__panelParam -w50">
                <div className="b-elmDetails__title">{t('common:status.not-completed')}</div>
                <div className="b-elmDetails__val">{data.not_completed}</div>
              </div>
            )}
          </div>
          <div className="b-elmDetails__panelParam">
            <div className="b-elmDetails__title">{t('common:status.not-started')}</div>
            <div className="b-elmDetails__val">{data.not_started}</div>
          </div>
        </>
      );
    }
  };

  const renderElmBtn = (data: ElmModel) => {
    if (data.status === ElmStatuses.COMPLETED) {
      return (
        <div className="b-elmDetails__btns">
          <Button
            className="b-elmDetails__mainBtn -ml8"
            title={t('common:btn.close-admin')}
            type={'orange-light'}
            onPress={() => handleCloseElm(data)}
          />
        </div>
      );
    }

    if (data.status === ElmStatuses.COMPLETED_LOCKED) {
      return (
        <div className="b-elmDetails__btns">
          {!data.is_no_org_report ? (
            <Button
              title={t('common:btn.view-organization-report')}
              className="b-elmDetails__mainBtn"
              type={'bordered'}
              onPress={() =>
                history.push(
                  ROOTS.ELM_ORG_REPORT_RESP.replace(':orgId', orgId).replace(':elmId', String(elmId)),
                )
              }
            />
          ) : null}
          <Button
            disabled={data.is_user_report_published || !data.submitted}
            className="b-elmDetails__mainBtn -ml8"
            title={
              data.is_user_report_published
                ? t('common:btn.report-published')
                : t('common:btn.publish-report')
            }
            type={'orange-light'}
            onPress={() => handlePublishElmReport(data)}
          />
        </div>
      );
    }
    return null;
  };

  const handleScheduleDebrief = () => {
    if (!data || data?.status !== ElmStatuses.COMPLETED_LOCKED) {
      return;
    }
    history.push(
      ROOTS.DEBRIEF_SCHEDULING.replace(':debriefAlias', ScheduleAlias.elm)
        .replace(':orgId', orgId)
        .replace(':id', `${data.id}`),
    );
  };

  const renderScheduleDebriefBtn = () => {
    if (!data) {
      return;
    }
    if (isDebriefExist) {
      return (
        <>
          <div className="b-elmDetails__title">{t('common:headlines.debrief')}</div>
          <div className="b-elmDetails__val">
            <div className="b-elmDetails__val -alignFix font-m">
              {getDebriefDateTime(
                data.debrief_scheduled_date.book_full_date,
                data.debrief_scheduled_date?.alias || ScheduleAlias.elm,
              )}
            </div>
          </div>
        </>
      );
    }
    if (data?.status === ElmStatuses.COMPLETED_LOCKED) {
      return (
        <Link onClick={handleScheduleDebrief} className={`b-panelItem__link`}>
          {t('common:btn.schedule-debrief')}
        </Link>
      );
    }
  };

  const renderContent = () => {
    if (!data) {
      return <Loader fitParent />;
    }

    const isSameEndDate = dayjs().isSame(tempDatePayload?.end_date || data.end_date, 'd');
    const isBefore = dayjs().isBefore(tempDatePayload?.end_date || data.end_date);

    return (
      <>
        <div className="b-elmDetails__panel">
          <div className="b-elmDetails__panelHead">
            <SimpleTooltip
              id={`${data.id}-status-tt`}
              title={data.status >= ElmStatuses.COMPLETED ? t('awa:TT1.msg') : ''}
            >
              <ElmStatusLabel elm={data} />
            </SimpleTooltip>
            {data.status < 4 ? (
              <AddToCalendar type={'elm'} startDate={data.start_date} endDate={data.end_date} />
            ) : null}
          </div>
          <div className="b-elmDetails__panelRow">
            <div className="b-elmDetails__panelParam -w50">
              <div className="b-elmDetails__title">{t('common:headlines.start-date')}</div>
              <div className="b-elmDetails__val -alignFix">
                <DatePicker
                  disabled={data.status > ElmStatuses.SCHEDULED}
                  minDate={new Date()}
                  dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                  selected={tempSelectedDate?.start_date || new Date(data.start_date)}
                  customInput={<input type="text" aria-label={'start_date'} />}
                  onChange={(date: Date) => onChangeDate(date, 'start_date')}
                  onCalendarClose={onCloseDatePicker}
                />
              </div>
            </div>
          </div>
          <div className="b-elmDetails__panelRow">
            <div
              className={`b-elmDetails__panelParam ${
                data.status !== ElmStatuses.COMPLETED_LOCKED ? '-w50' : ''
              }`}
            >
              <div className="b-elmDetails__title">{t('common:headlines.end-date')}</div>
              <div className="b-elmDetails__val">
                <DatePicker
                  disabled={data.status === ElmStatuses.COMPLETED_LOCKED}
                  minDate={_getEndMinDate(data)}
                  dateFormat={
                    data.status !== ElmStatuses.COMPLETED_LOCKED
                      ? `${getBasicDateHourFormatCodeForDatePicker()}`
                      : `${getBasicDateHourFormatCodeTzForDatePicker()}`
                  }
                  selected={tempDatePayload?.end_date || dayjs(data.end_date).tz(tz).$d}
                  // selected={
                  //   isSameEndDate
                  //       ? dayjs(data.end_date).tz(tz).$d
                  //       : tempSelectedDate?.end_date || dayjs(data.end_date).tz(tz).$d
                  // }
                  customInput={<input type="text" aria-label={'end_date'} />}
                  onChange={(date: Date, event) => {
                    onChangeDate(date, 'end_date', event);
                  }}
                  onCalendarClose={onCloseDatePicker}
                  showTimeSelect
                  shouldCloseOnSelect={false}
                  // minTime={!isBefore || isSameEndDate ? setHours(setMinutes(new Date(), 1), 0) : undefined}
                  // maxTime={!isBefore || isSameEndDate ? setHours(setMinutes(new Date(), 2), 0) : undefined}
                  minTime={
                    isSameEndDate
                      ? setHours(setMinutes(new Date(), dayjs().tz(tz).minute()), dayjs().tz(tz).hour())
                      : undefined
                  }
                  maxTime={isSameEndDate ? setHours(setMinutes(new Date(), 30), 23) : undefined}
                />
              </div>
            </div>
            {data.status !== ElmStatuses.COMPLETED_LOCKED ? (
              <div className="b-elmDetails__panelParam -w50">
                <TimeZoneSelectView
                  value={tz}
                  onChange={handleChangeTimeZone}
                  height={40}
                  noError
                  wrapperStyles={{
                    width: '100%',
                  }}
                  placeholder={t('common:label.time-zone')}
                  ariaLabel="Time Zone"
                />
              </div>
            ) : null}
          </div>
          {hasElmRole(data, ElmRoles.USER) &&
          data.status >= ElmStatuses.IN_PROGRESS &&
          data.user_elm_rel.submitted_date ? (
            <div className="b-elmDetails__panelParam">
              <div className="b-elmDetails__title">{t('common:status.submitted')}</div>
              <div className="b-elmDetails__val -alignFix font-m">{basicDateFormat(data.end_date)}</div>
            </div>
          ) : null}
          <div className="b-elmDetails__panelRow">
            <div className="b-elmDetails__panelParam">
              <div className="b-elmDetails__title">{t('common:headlines.associate-ea')}</div>
              <div className="b-elmDetails__val">
                {data.equity_audit_name ? (
                  <>
                    {t('common:status.completed-on')} {basicDateFormat(data.equity_audit_name)}
                  </>
                ) : (
                  t('common:column.n-a')
                )}
              </div>
            </div>
            <div className="b-elmDetails__panelParam">{renderScheduleDebriefBtn()}</div>
          </div>
          {isDebriefExist ? (
            <div className="b-elmDetails__panelRow">
              <div className="b-elmDetails__panelParam" />
              <div className="b-elmDetails__panelParam">
                <div className="b-elmDetails__title">
                  {data.status === ElmStatuses.COMPLETED_LOCKED ? (
                    <a href="#" onClick={handleScheduleDebrief} className="b-EADetails__scheduleLink">
                      <span className="b-EADetails__scheduleLinkIcon" />
                      {t('common:btn.reschedule')}
                    </a>
                  ) : null}
                </div>
                <div className="b-elmDetails__val">
                  {data.debrief_scheduled_date.alias ? (
                    <AddToCalendarDebrief
                      alias={data.debrief_scheduled_date.alias}
                      date={new Date(data.debrief_scheduled_date.book_full_date)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
          <div className="b-elmDetails__divider" />
          <div className="b-elmDetails__panelParam">
            <Link className="b-link-icon-left " onClick={handleViewUserList}>
              <img src={EditIc} alt="user edit" />
              {t('common:label.edit-user-list')}
            </Link>
          </div>
          {renderMetrics(data)}
        </div>

        <div className="contentContainer">
          {!data.is_no_org_report ? (
            <div className="mb-4 b-EADetails__block50">
              <div className="d-flex w-100 mb-4 justify-content-between align-items-center">
                <div className="font-weight-bold font-navy">{t('common:headlines.managers')}</div>
                <div>
                  <button
                    disabled={!selectedManagers.length}
                    onClick={() => handleSendNotification(data)}
                    className="b-EADetails__btn b-EADetails__btn_send"
                  >
                    <span className="sr-only">{t('common:btn.send')}</span>
                  </button>
                  <button
                    disabled={!selectedManagers.length}
                    onClick={() => handleDeleteUser(data)}
                    className="b-EADetails__btn b-EADetails__btn_delete"
                  >
                    <span className="sr-only">{t('common:btn.delete')}</span>
                  </button>
                </div>
              </div>
              <div className="mb-4">
                {data.managers.map(user => {
                  const isChecked = selectedManagers.includes(user.id);

                  return (
                    <div
                      key={`user-m-${user.id}`}
                      className="d-flex mb-3 justify-content-between align-items-center"
                    >
                      <div className="font-weight-bold font-grey">{user.name}</div>
                      <div className="d-flex align-items-center">
                        <div className="pr-2 font-navy">
                          {user.status} {user.status_date}
                        </div>
                        <Checkbox noText checked={isChecked} onChange={() => handleSelectUser(user.id)} />
                      </div>
                    </div>
                  );
                })}
              </div>

              {managersList ? (
                <AddMember
                  usersList={managersList}
                  type={'managers'}
                  selectedUsers={data.managers.map(user => user.id)}
                  onSave={managerIds => handleAddManagers(managerIds, data)}
                />
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="-mobile">{renderElmBtn(data)}</div>
      </>
    );
  };

  return (
    <main className={'b-page'}>
      <div className="b-EADetails b-elm">
        <div className="b-page__header">
          <HeadlineOrganization
            orgName={currentOrg?.name || ''}
            orgId={currentOrg?.id || ''}
            headline={t('common:headlines.equity-lens-map')}
            customClasses={'-noDesktopMb'}
          />
          {data && !isMobileOnly ? (
            <div className="b-page__headerBtns -desktop">{renderElmBtn(data)}</div>
          ) : null}
        </div>
        <h2 className="b-page__title2 -backIcon font-navy">
          <EmptyLink onClick={navBack} className="b-button-icon-back" />
          {t('common:headlines.details')}
        </h2>
        {renderContent()}
      </div>
      {loading && <Loader fitParent />}
    </main>
  );
};

export default ElmDetails;
