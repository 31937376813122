import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './SimpleTooltip.module.scss';

type Place = 'top' | 'right' | 'bottom' | 'left';
interface IProps {
  title: JSX.Element | string;
  id: string;
  className?: string;
  place?: Place;
}

const SimpleTooltip: FC<IProps> = ({ id, title, children, className, place }) => {
  return (
    <div id={`s-tooltip-${id}`}>
      <div data-tip="" data-for={`s-tooltip-${id}`} aria-describedby={`s-tooltip-${id}`}>
        {children}
      </div>
      <ReactTooltip
        type={'light'}
        className={`${styles.body} ${className}`}
        effect="solid"
        id={`s-tooltip-${id}`}
        place={place || 'top'}
      >
        {title}
      </ReactTooltip>
    </div>
  );
};

export default SimpleTooltip;
