import React, { useState, FC, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Button, Loader } from '../../../../../controls';
import DatePicker from 'react-datepicker';
import SaveIc from '../../../../../../assets/images/icons/round-content-save-alt.svg';
import { AddToCalendar } from '../../../../../common';
import { CreateEAStep, EAUpdateData } from '../../../../../../models';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../../store/reducers';
import { getBasicDateFormatCodeForDatePicker } from '../../../../../../helpers';
import { useTranslation } from 'react-i18next';

interface IProps {
  onChangeStep: (step: CreateEAStep) => void;
  handleCancel: () => void;
  saveData: (data: EAUpdateData, isNext: boolean) => void;
}

interface IState {
  currentDate: Date;
}

const CreateEAStep3: FC<IProps> = ({ onChangeStep, handleCancel, saveData }) => {
  const { t } = useTranslation();

  const createEAData = useSelector((state: StateType) => state.equityAudit.createEAData);

  const initialState = {
    currentDate: new Date(),
  };

  let [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (createEAData && createEAData.scheduled_date) {
      setState(prevState => ({
        ...prevState,
        currentDate: new Date(createEAData.scheduled_date as string),
      }));
    }
  }, [createEAData]);

  const handleChangeDate = (date: Date) => {
    setState({ ...state, currentDate: date });
  };

  const handleSaveExit = (e: any) => {
    e.preventDefault();
    saveData(
      {
        scheduled_date: state.currentDate,
        ea_step: 'set_date',
      },
      false,
    );
  };

  const handleNext = () => {
    saveData(
      {
        scheduled_date: state.currentDate,
        ea_step: '2',
      },
      true,
    );
  };

  if (!createEAData) return <Loader fitParent />;

  return (
    <>
      <div className={'b-EACreate__step'}>
        <div className="b-EACreate__header">
          <h1 className={'pageContainer__title b-EATypo__pageTitle'}>{t('common:headlines.new-ea')}</h1>
          {!isMobileOnly && (
            <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
              <img src={SaveIc} alt={t('common:alt.saveIc')} />
              {t('common:btn.save-exit')}
            </a>
          )}
        </div>
        <div className={'b-step__orgWrapper'}>
          <div className="b-equityAudit__text16">{t('common:headlines.organization')}</div>
          <div className={'b-step__orgText'}>{createEAData.organization_name}</div>
        </div>
        <div className={'b-step__info'}>
          <div className={'b-step__titleWrapper'}>
            <div className={'b-step__title'}>{t('common:headlines.ea-date')}</div>
          </div>
          <div id="start_date" className={`b-equityAudit__text12 ${'b-step__textWrapper'}`}>
            {t('common:headlines.tentative-start-date')}
          </div>
          <div className={'b-EACreate__dateArea datepicker-no-panel'}>
            <DatePicker
              minDate={new Date()}
              dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
              selected={state.currentDate}
              onChange={handleChangeDate}
              ariaLabelledBy="start_date"
            />
            <AddToCalendar type={'ea'} startDate={state.currentDate} />
          </div>
          <div className="b-equityAudit__text12">{t('common:label.click-date')}</div>
        </div>
        <div className={`b-step__bottom`}>
          <div className={`b-step__bottom-left`}>
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={() => onChangeStep('set_ea_team')}
              title={t('common:btn.back')}
            />
            <Button
              className={'btn -type_form'}
              type={'transparency'}
              onPress={handleCancel}
              title={t('common:btn.cancel')}
            />
          </div>
          {isMobileOnly ? (
            <div className={`b-step__bottom-left`}>
              <a href="#" className={'b-EACreate__saveBtn'} onClick={handleSaveExit}>
                <img src={SaveIc} alt={t('common:alt.saveIc')} />
                {t('common:btn.save-exit')}
              </a>
              <Button
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleNext}
                title={t('common:btn.next')}
                enableEnter
              />
            </div>
          ) : (
            <Button
              className={'btn -type_form'}
              type={'orange-light'}
              onPress={handleNext}
              title={t('common:btn.next')}
              enableEnter
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CreateEAStep3;
