import React, { useEffect, useState } from 'react';
import { Button, Checkbox } from '../../../../components/controls';
import Input from '../../../controls/Inputs/Input';
import styles from './SignUpStyle.module.scss';
import { Footer, Header } from '../../../common';
import ReCaptcha from 'react-google-recaptcha';
import { ROOTS } from '../../../../routes/routes';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IProps {
  errors: IErrors;
  loading?: boolean;
  onChangeField: (field: string) => (value: any) => void;
  onSubmit: () => void;
  onValidate: () => void;
  onAgree: () => void;
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  onChecked: boolean;
  handleOnBlur: (field: string) => () => void;
}

interface IErrors {
  password: string;
  username: string;
  first_name: string;
  last_name: string;
}

const SignUpView = ({
  errors,
  loading,
  onChangeField,
  onValidate,
  handleOnBlur,
  username,
  first_name,
  last_name,
  password,
  onSubmit,
  onChecked,
  onAgree,
}: IProps) => {
  const { t } = useTranslation();
  const [captcha, setCaptcha] = useState(false);
  const [disable, setDisable] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (captcha && !onChecked) {
      if (!Object.keys(errors).length) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else {
      setDisable(true);
    }
  }, [captcha, onChecked, errors]);
  const handleChange = (value: any) => {
    onValidate();
    if (value !== null && !Object.keys(errors).length) {
      setCaptcha(true);
    } else {
      setCaptcha(false);
    }
  };
  const navTo = (route: string) => (e: any) => {
    e.preventDefault();
    history.push(route);
  };

  return (
    <>
      {loading}
      <div className={'container container-header white-container'}>
        <Header type={'awa_text'} />
        <main className={`white-container content-center-wrapper ${styles.container}`}>
          <div className={`container-page-center mb-5 ${styles.content}`}>
            <div className={'container-row'}>
              <h1 className={`b-title gray-text font-l ${styles.title_wrapper}`}>
                {t('auth:create-account')}
              </h1>
            </div>
            <div className={'container-row'}>
              <Input
                handleUnselect={handleOnBlur('first_name')}
                value={first_name}
                error={errors.first_name}
                type={'text'}
                placeholder={t('common:label.first-name')}
                ariaLabel="First Name"
                handleChange={onChangeField('first_name')}
                autoFocus
              />
            </div>
            <div className={'container-row'}>
              <Input
                handleUnselect={handleOnBlur('last_name')}
                value={last_name}
                type={'text'}
                error={errors.last_name}
                placeholder={t('common:label.last-name')}
                ariaLabel="Last Name"
                handleChange={onChangeField('last_name')}
              />
            </div>
            <div className={'container-row'}>
              <Input
                handleUnselect={handleOnBlur('username')}
                value={username}
                type={'email'}
                error={errors.username}
                placeholder={t('common:label.email-address')}
                ariaLabel="Email Address"
                handleChange={onChangeField('username')}
              />
            </div>
            <div className={'container-row'}>
              <Input
                handleUnselect={handleOnBlur('password')}
                value={password}
                type={'password'}
                error={errors.password}
                placeholder={t('common:label.password')}
                ariaLabel="Password"
                handleChange={onChangeField('password')}
              />
            </div>
            <div className={`container-row mb-4 ${styles.checkBox}`}>
              <Checkbox size={'lg'} checked={!onChecked} id="agreeCheck" onChange={onAgree}>
                <span className={'text-long-description gray-text'}>
                  {t('common:checkbox.agreement.agree-to')} &nbsp;
                  <a className="links gray-text links_underline_text" onClick={navTo(ROOTS.TERMS_CONDITIONS)}>
                    {t('common:checkbox.agreement.terms-conditions')}
                  </a>
                  &nbsp; {t('common:checkbox.agreement.and')} &nbsp;
                  <a
                    href={'#'}
                    className="links gray-text links_underline_text"
                    onClick={navTo(ROOTS.PRIVACY_POLICY)}
                  >
                    {t('common:checkbox.agreement.privacy-policy')}
                  </a>
                </span>
              </Checkbox>
            </div>
            <div className={'container-row'}>
              <label className={`d-none`} htmlFor="g-recaptcha-response">
                recaptcha
              </label>
              <ReCaptcha
                type={'image'}
                sitekey={'6LcMxd8cAAAAALdzoUclyTcdXyPApidxbTAGJAEL'}
                onChange={handleChange}
              />
            </div>
            <div className={'container-row container-button-wrapper'}>
              <Button
                disabled={disable}
                onPress={onSubmit}
                title={t('common:btn.sign-up')}
                size={'small'}
                type={'orange-light'}
                enableEnter
              />
            </div>
            <div className={'container-row'}>
              <span className={'text-description font-s font-spacing-s'}>
                {t('auth:have-account')}
                <a className={'links'} onClick={navTo(ROOTS.SIGNIN)} href={'#'}>
                  {' '}
                  {t('common:btn.login')}
                </a>
              </span>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default SignUpView;
