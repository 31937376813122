import React, { useEffect, useState } from 'react';
import { CheckedOptionsModel, BelCohortModel, IRouteParamsBase, ISelectOptions } from '../../../../../models';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Loader } from '../../../../controls';
import { arrayUniqueByKey } from '../../../../../helpers';
import { ROOTS } from '../../../../../routes/routes';
import { useTranslation } from 'react-i18next';
import {
  belSetGeneralResourceFields,
  belUpdateCohortField,
  getSelectedCohortResourceIds,
  setSelectedCohortResourceIds,
} from '../../../../../store/cohort/cohortActions';
import { toast } from 'react-toastify';
import { StateType } from '../../../../../store/reducers';
import { useGetCohortDetails } from '../../../../../hooks/beloved/cohorts/use-get-cohort-details';
import { useIsAccessEditCohort } from '../../../../../hooks/beloved/cohorts/use-edit-access';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';

const BelGeneralResources = () => {
  const { t } = useTranslation();
  const { id } = useParams<IRouteParamsBase>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [resources, setResources] = useState<CheckedOptionsModel[]>([]);
  const selectedResourceIds = useSelector((state: StateType) => state.cohort.selectedResourceIds);
  const savedGeneralResourceField = useSelector((state: StateType) => state.cohort.savedGeneralResourceField);
  const cohort = useGetCohortDetails();
  const isAccess = useIsAccessEditCohort(cohort);

  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (cohort && !savedGeneralResourceField) {
      const convertToISelect = (cohort.resources || []).map(r => {
        return { label: r.title, value: r.id };
      });
      setResources(convertToISelect);
    }
  }, [cohort]);

  useEffect(() => {
    if (selectedResourceIds.length) {
      dispatch(
        getSelectedCohortResourceIds({
          data: {
            resource_ids: selectedResourceIds,
          },
          callback: res => {
            if (res && savedGeneralResourceField) {
              setResources(arrayUniqueByKey([...savedGeneralResourceField, ...(res || [])], 'value'));
              dispatch(
                belSetGeneralResourceFields({
                  data: null,
                }),
              );
            }
          },
        }),
      );
      dispatch(setSelectedCohortResourceIds([]));
    }
  }, []);

  const navBack = (e?: any) => {
    e && e.preventDefault();
    history.go(-1);
  };

  const handleCheckResource = (item: ISelectOptions) => {
    const resourceIndex = resources.findIndex(f => f.value === item.value);
    if (resourceIndex < 0) {
      return;
    }
    const res = [...resources];
    res[resourceIndex].isCheck = !res[resourceIndex].isCheck;
    setResources(res);
  };
  const handleRemoveResource = () => {
    const array: CheckedOptionsModel[] = [...(resources || [])].filter(f => !f.isCheck);
    setResources(array);
  };

  const handleSave = () => {
    if (!cohort) {
      return;
    }
    setLoading(true);
    dispatch(
      belUpdateCohortField({
        data: {
          id: Number(id),
          resource_ids: resources.map(r => r.value),
        },
        callback: (res: BelCohortModel | null) => {
          if (res) {
            toast.success(t('common:toast.gr-was-updated'));
            history.push(ROOTS.BELOVED_COHORT_DETAILS.replace(':id', `${res.id}`));
          }
          setLoading(false);
        },
      }),
    );
  };

  const handleNavToResourceLib = (e: any) => {
    e.preventDefault();
    if (!cohort) {
      return;
    }
    dispatch(
      belSetGeneralResourceFields({
        data: resources,
      }),
    );
    history.push(ROOTS.BELOVED_COHORT_RESOURCE_LIB.replace(':id', `${cohort.id}`).replace(':sessionId', `0`));
  };

  const navToViewResource = (e: any, resource: ISelectOptions) => {
    e.preventDefault();
    ROOTS.BELOVED_RESOURCE_VIEW.replace(':id', `${resource.value}`);
  };

  const renderContent = () => {
    if (loading) {
      return <Loader fitParent />;
    }

    return (
      <div className={`b-EADetails b-elm b-cohortBel`}>
        <div className="b-EADetails__header">
          <h1 className="pageContainer__title b-EADetails__pageTitle">
            {t('common:headlines.additional-resources')}
          </h1>
        </div>
        <div className={`b-elmDetails__panel`}>
          <div className={`b-elmDetails__panelRow panelRow`}>
            <div className={`b-elmDetails__panelParam w-100`}>
              <div className={`b-elmDetails__title b-cohortBel__title__remove`}>
                {t('common:column.resources')}
                {(resources || []).length ? (
                  <button className="b-button-icon -delete" onClick={handleRemoveResource} />
                ) : null}
              </div>
            </div>
          </div>
          <div className={`b-elmDetails__panelRow panelRow`}>
            <div className={`b-elmDetails__panelParam w-100 list`}>
              {(resources || []).map((resource: CheckedOptionsModel, resourceIndex) => {
                return (
                  <div key={`resources-panel-${resourceIndex}`} className={`list__item`}>
                    <span>{resource.label}</span>
                    <div className={`resource`}>
                      <button
                        className="b-button-icon -viewResource"
                        onClick={e => navToViewResource(e, resource)}
                      />
                      <Checkbox
                        noText
                        checked={!!resource.isCheck}
                        onChange={() => handleCheckResource(resource)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="b-elmDetails__panelRow">
            <div className="b-elmDetails__panelParam">
              <div className={`b-elmDetails__title b-cohortBel__title__remove`}>
                <a onClick={handleNavToResourceLib} href="#" className="b-link-add">
                  {t('common:btn.add-new-resource')}
                </a>
              </div>
            </div>
          </div>
          {isAccess ? (
            <div className="b-EAModal__buttons mt-5">
              <Button
                className={'btn -type_form'}
                type={'transparency'}
                onPress={navBack}
                title={t('common:btn.cancel')}
              />
              <Button
                disabled={false}
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={() => handleSave()}
                title={t('common:btn.save')}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <main className="b-page">
      <h1 className="b-page__title">
        <EmptyLink onClick={navBack} className="b-button-icon-back" />
        {t('common:headlines.cohort-program')}: <span className="mark">{cohort?.name}</span>
      </h1>
      {renderContent()}
      {loading && <Loader fitParent />}
    </main>
  );
};

export default BelGeneralResources;
