import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataGrid from 'react-data-grid';
import { useHistory } from 'react-router';
import { ROOTS } from '../../../../../routes/routes';
import { BelResourceShortModel, TableDataEntities } from '../../../../../models';
import { Pagination } from '../../../../common';
import { getColumns } from '../parts/columns';
import { Link, Loader } from '../../../../controls';
import useTableData from '../../../../../hooks/common/use-table-data';
import { useDispatch } from 'react-redux';
import {
  belChangeResourceStatus,
  belDeleteResources,
} from '../../../../../store/beloved/resources/resourcesActions';
import { ResourceStatuses, BelovedPermissions } from '../../../../../constants';
import { useNavToViewResource } from '../../../../../hooks/resources/use-nav-to-view-resource';
import useHasAccess from '../../../../../hooks/beloved/adminTool/user-has-access';
import ResourceShare from '../parts/ResourceShare';
import { isEmptyObject } from '../../../../../helpers';
import FilterModal from '../parts/FilterModal';
import { useIsDarkMode } from '../../../../../hooks/common/use-is-dark-mode';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import useTableConfig from '../../../../../hooks/common/use-table-config';
import CheckedItems from '../../../../common/CheckedItems/CheckedItems';

const ResourceListTable = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useIsDarkMode();
  const history = useHistory();
  const dispatch = useDispatch();
  const navToView = useNavToViewResource();
  const hasAccessToCreate = useHasAccess(BelovedPermissions.RESOURCE_LIBRARY_CREATE_UPDATE);
  const isReviewer = useHasAccess(BelovedPermissions.RESOURCE_LIBRARY_REVIEW);

  const {
    data,
    query,
    fetchData,
    handleChangePage,
    handleChangePageSize,
    handleSearch,
    handleSelectOne,
    selectedEntitiesIds,
    setSelectedEntitiesIds,
    handleSort,
    setQuery,
  } = useTableData(TableDataEntities.RESOURCES);

  const onCellClick = (fieldName: string, data: BelResourceShortModel) => {
    if (fieldName === 'navToView') {
      navToView(data);
    }
  };

  const { columns, onColumnResize } = useTableConfig<BelResourceShortModel>(
    TableDataEntities.RESOURCES,
    getColumns({ onCellClick }),
  );

  let [filtersVisible, setFiltersVisible] = useState(false);

  useEffect(() => {
    fetchData({ ...query, page: 1 });
    dispatch(setQuery({ ...query, page: 1 }));
  }, []);

  const handleDelete = () => {
    dispatch(
      belDeleteResources({
        ids: selectedEntitiesIds,
        callback: () => {
          fetchData(query);
          setSelectedEntitiesIds([]);
        },
      }),
    );
  };

  const handleChangeStatus = (status: ResourceStatuses) => {
    dispatch(
      belChangeResourceStatus({
        status,
        ids: selectedEntitiesIds,
        callback: () => {
          fetchData(query);
          setSelectedEntitiesIds([]);
        },
      }),
    );
  };

  const navToCreate = () => {
    history.push(ROOTS.BELOVED_CREATE_RESOURCE);
  };

  const navToCards = () => {
    history.push(ROOTS.BELOVED_RESOURCE_LIST_CARDS);
  };

  const navToDetails = (data: BelResourceShortModel) => {
    history.push(ROOTS.BELOVED_RESOURCE_DETAILS.replace(':id', `${data.id}`));
  };

  const _isDeactiveBtnDisabled = (is_published: boolean) => {
    if (data) {
      const selectedResources = selectedEntitiesIds
        .map(id => {
          let resource = data.result.find(resource => resource.id === id);
          if (resource) return resource;
        })
        .filter(f => !!f);
      const has_published = !!selectedResources.find(resource => {
        if (is_published) {
          return resource.status !== ResourceStatuses.PUBLISH;
        } else {
          return resource.status === ResourceStatuses.PUBLISH;
        }
      });
      return !selectedEntitiesIds.length || !has_published;
    }
    return true;
  };

  const renderContent = () => {
    if (!data) return <Loader fitParent />;

    return (
      <div className="position-relative">
        <CheckedItems value={selectedEntitiesIds.length} total={data.count} />
        <DndProvider backend={HTML5Backend}>
          <DataGrid
            onRowClick={navToDetails}
            onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
            columns={columns}
            sortColumns={query.sorting ? [query.sorting] : []}
            rows={data.result}
            onSelectedRowsChange={handleSelectOne}
            className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
            style={{
              height: ((data.result.length || 0) + 1) * 60,
            }}
            onColumnResize={onColumnResize}
            rowHeight={60}
            selectedRows={new Set(selectedEntitiesIds)}
            rowKeyGetter={(row: BelResourceShortModel) => row.id}
          />
        </DndProvider>
        <Pagination data={data} handleChangePageSize={handleChangePageSize} changePage={handleChangePage} />
      </div>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <h1 className={'b-page__title'}>{t('common:tabs.resource-library')}</h1>
        <div className="b-tabs">
          <Link className="b-tabs__item b-tabs__item_active">
            <div className="b-tabs__icon b-tabs__icon_table" />
            <span className="b-tabs__text">{t('common:tabs.table')}</span>
          </Link>
          <Link className="b-tabs__item" onClick={navToCards}>
            <div className="b-tabs__icon b-tabs__icon_card" />
            <span className="b-tabs__text">{t('common:tabs.cards')}</span>
          </Link>
        </div>
        <div className="b-page__controls">
          <div className="b-tableToolContainer">
            {isReviewer ? (
              <>
                <button
                  disabled={_isDeactiveBtnDisabled(true)}
                  aria-label="Publish Resource"
                  onClick={() => handleChangeStatus(ResourceStatuses.PUBLISH)}
                  className="b-tableTool -userOn -mobView -mr"
                >
                  {t('common:btn.publish')}
                </button>
                <button
                  disabled={_isDeactiveBtnDisabled(false)}
                  aria-label="Unpublish Resource"
                  onClick={() => handleChangeStatus(ResourceStatuses.UNPUBLISH)}
                  className="b-tableTool -userOff -mobView -mr"
                >
                  {t('common:btn.unpublish')}
                </button>
                <button
                  disabled={!selectedEntitiesIds.length}
                  aria-label="Delete Resource"
                  onClick={handleDelete}
                  className="b-tableTool -delete -mobView -mr"
                >
                  {t('common:btn.delete')}
                </button>
              </>
            ) : null}
            {hasAccessToCreate ? (
              <button onClick={navToCreate} className="b-tableTool -addPlus -mobView -mr">
                {t('common:btn.resource')}
              </button>
            ) : null}
            {data ? (
              <ResourceShare type={'table'} data={data.result} selectedEntitiesIds={selectedEntitiesIds} />
            ) : null}
            <button
              aria-label="Click to Filter"
              onClick={() => setFiltersVisible(true)}
              className={`
                    b-tableTool
                    -mr
                    -filter
                    -colorGrey
                    ${!isEmptyObject(query.filters) ? '-active' : ''}
                  `}
            >
              {t('common:btn.filter')}
            </button>
          </div>
          <div className="b-page__search">
            <form action="#" className="searchForm">
              <input
                type="search"
                value={query.search}
                onChange={e => handleSearch(e.target.value)}
                placeholder={t('common:label.search')}
                aria-label="Search field"
              />
              <span className="searchForm__icon" />
            </form>
          </div>
        </div>
        {renderContent()}
      </main>
      {filtersVisible ? <FilterModal onClose={() => setFiltersVisible(false)} /> : null}
    </>
  );
};

export default ResourceListTable;
