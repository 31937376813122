import React, { useEffect, useState } from 'react';
import Calendar, { CalendarTileProperties, ViewCallback, ViewCallbackProperties } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CalendarStyles.scss';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSchedule,
  getScheduleCalendar,
  getScheduleDateDetails,
  getScheduleOptions,
} from '../../../store/payments/paymentsActions';
import { Button, Loader } from '../../controls';
import {
  CalendarDetailsModel,
  IRouteParamsBase,
  KeyValueModel,
  ScheduleDateModel,
  ScheduleTimeZoneModel,
} from '../../../models';
import { useHistory, useParams } from 'react-router-dom';
import { ScheduleAlias } from '../../../constants';
import { ROOTS } from '../../../routes/routes';
import { StateType } from '../../../store/reducers';
import { getTimeRanges, onlyUnique, validate } from '../../../helpers';
import BackIc from '../../../assets/images/icons/round-navigation-chevron-left.svg';
import AddToCalendarDebrief from '../AddToCalendarDebrief/AddToCalendarDebrief';
import ModalView from '../../controls/ModalView/ModalView';
import TimeZoneSelectView from '../TimeZoneSelect/TimeZoneSelect';
import { Alert } from '../index';
import { useTranslation } from 'react-i18next';
import { useNavBack } from '../../../hooks/common/use-nav-back';
import { showAlert } from '../Alert/RemoteAlert';

const timezone = require('dayjs/plugin/timezone');
const utc = require('dayjs/plugin/utc'); // dependent on utc plugin
const minMax = require('dayjs/plugin/minMax');

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(minMax);

const CalendarView = () => {
  const validator = {
    required: ['date', 'time', 'tz'],
  };
  const { t } = useTranslation();
  const { orgId, id, debriefAlias } = useParams<IRouteParamsBase>();
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [time, setTime] = useState<string>('');
  const [modal, setModal] = useState<ScheduleDateModel | null>(null);
  const [modalError, setModalError] = useState<string | null>('');
  const [rescheduleAlert, setRescheduleAlert] = useState<boolean | null>(false);
  const [busyTimeSlotAlert, setBusyTimeSlotAlert] = useState<boolean | null>(false);
  const [selectedDebrief, setSelectedDebrief] = useState<ScheduleDateModel | null>(null);
  const [minDate, setMinDate] = useState<Dayjs | null>(null);
  const [maxDate, setMaxDate] = useState<Dayjs | null>(null);
  const [errors, setErrors] = useState<KeyValueModel<string>>({});
  const [existDate, setExistDate] = useState<string[]>([]);
  const [existGMTDate, setExistGMTDate] = useState<ScheduleDateModel[]>([]);
  const [completedDate, setCompletedDate] = useState<string>('');
  const [isShowCurrentMonth, setIsShowCurrentMonth] = useState<boolean>(true);
  const [calendarAlias, setCalendarAlias] = useState<ScheduleAlias>();
  let calendarTimes = getTimeRanges(30, 'en');
  const { navBack } = useNavBack();

  const [tz, setTz] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const history = useHistory();
  const dispatch = useDispatch();
  const scheduleOptions = useSelector((state: StateType) => state.payments.scheduleOptions);
  const settings = scheduleOptions?.app_services?.find(a => a.alias === ScheduleAlias.ea);

  const handleGetScheduleCalendar = () => {
    dispatch(
      getScheduleCalendar({
        data: {
          entity_id: Number(id),
          alias: debriefAlias,
        },
        callback: (existingDates, alias) => {
          setExistGMTDate(existingDates.filter(onlyUnique));
          setCalendarAlias(alias);
          const unique = existingDates
            .map(m =>
              dayjs(m.book_full_date)
                .tz(tz || 'GMT')
                .format('YYYY-MM-DD HH:mm:00'),
            )
            .filter(onlyUnique);
          setExistDate(unique);
        },
        errorCallback: err => {
          if (err.response.status === 400) {
            showAlert({
              title: t('awa:ER15.title'),
              text: t('awa:ER15.msg'),
              buttons: {
                left: {
                  title: t('common:btn.cancel'),
                },
                right: {
                  title: t('common:btn.ok'),
                },
              },
            });
            history.go(-1);
          }
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(
      getScheduleDateDetails({
        callback: (res: CalendarDetailsModel) => {
          setCalendarAlias(res.alias);
          if (res.scheduled_date.book_date) {
            res.scheduled_date.book_date = dayjs(res.scheduled_date.book_full_date)
              .tz(tz || 'GMT')
              .format('YYYY-MM-DD');
            res.scheduled_date.book_time = dayjs(res.scheduled_date.book_full_date)
              .tz(tz || 'GMT')
              .format('HH:mm:00');
            res.scheduled_date.book_full_date = dayjs(res.scheduled_date.book_full_date)
              .tz(tz || 'GMT')
              .format('YYYY-MM-DD HH:mm:00 Z');
            setDate(new Date(dayjs(res.scheduled_date.book_full_date).tz(tz).format('YYYY-MM-DD 00:00:00')));
            setSelectedDebrief(res.scheduled_date);
            setTime(dayjs(res.scheduled_date.book_full_date).tz(tz).format('HH:mm:00'));
          }
          if (res.entity.completed_date) {
            setCompletedDate(res.entity.completed_date || '');
          }
        },
        data: {
          entity_id: Number(id),
          alias: debriefAlias,
        },
      }),
    );
    handleGetScheduleCalendar();
  }, []);

  useEffect(() => {
    if (selectedDebrief?.book_date) {
      const debrief = { ...selectedDebrief };
      debrief.book_date = dayjs(debrief.book_full_date)
        .tz(tz || 'GMT')
        .format('YYYY-MM-DD');
      debrief.book_time = dayjs(debrief.book_full_date)
        .tz(tz || 'GMT')
        .format('HH:mm:00');
      debrief.book_full_date = dayjs(debrief.book_full_date)
        .tz(tz || 'GMT')
        .format('YYYY-MM-DD HH:mm:00 Z');
      setSelectedDebrief(debrief);
      if (existGMTDate) {
        const unique = existGMTDate
          .map(m =>
            dayjs(m.book_full_date)
              .tz(tz || 'GMT')
              .format('YYYY-MM-DD HH:mm:00'),
          )
          .filter(onlyUnique);
        setExistDate(unique);
        // setExistDate(existGMTDate.map(m => dayjs(m.book_full_date).tz(tz || 'GMT').format('YYYY-MM-DD HH:mm:00')));
      }
    }
    if (completedDate) {
      setCompletedDate(
        dayjs(completedDate)
          .tz(tz || 'GMT')
          .format('YYYY-MM-DD HH:mm:00 Z') || '',
      );
    }
  }, [tz]);

  useEffect(() => {
    if (!scheduleOptions) {
      dispatch(getScheduleOptions({}));
    }
    handleRangeDates();
  }, [scheduleOptions, selectedDebrief, completedDate]);

  const handleRangeDates = () => {
    setMinDate(
      dayjs(dayjs().format('YYYY-MM-DD'))
        .tz(tz)
        .add(settings?.settings?.min_advance_scheduling || 0, 'h'),
    );
    setMaxDate(dayjs().add(settings?.settings?.max_advance_scheduling || 0, 'h'));
  };
  const changeDate = (value: Date) => {
    if (
      selectedDebrief &&
      dayjs(selectedDebrief?.book_full_date).tz(tz).format('YYYY-MM-DD') === dayjs(value).format('YYYY-MM-DD')
    ) {
      setDate(new Date(selectedDebrief.book_full_date));
      setTime(selectedDebrief.book_time);
    } else {
      setTime('');
    }
    setErrors({
      ...errors,
      date: '',
    });
    setIsShowCurrentMonth(true);
    setDate(value);
  };

  const handleSetTime = (time: string) => {
    if (isDisableTime(time)) {
      return;
    }
    setErrors({
      ...errors,
      time: '',
    });
    setTime(time);
  };

  const handleChangeSelect = (tz: ScheduleTimeZoneModel) => {
    if (
      selectedDebrief &&
      dayjs(date).format('YYYY-MM-DD') ===
        dayjs(selectedDebrief.book_full_date).tz(tz.value).format('YYYY-MM-DD')
    ) {
      setTime(dayjs(selectedDebrief.book_full_date).tz(tz.value).format('HH:mm:00'));
    } else if (selectedDebrief) {
      setTime('');
    }
    setTz(tz.value);
    setExistDate(
      existGMTDate
        .map(m => dayjs(m.book_full_date).tz(tz.value).format('YYYY-MM-DD HH:mm:00'))
        .filter(onlyUnique),
    );
    setErrors({
      ...errors,
      tz: '',
    });
  };

  const onBack = () => {
    history.go(-1);
  };

  const onSubmit = () => {
    const errors = validate(validator, { date, time, tz }).errors;

    if (Object.values(errors || {}).length) {
      setErrors(errors as any);
      return;
    }

    dispatch(
      createSchedule({
        data: {
          entity_id: Number(id),
          book_date: dayjs(date).format('YYYY-MM-DD'),
          book_time: time,
          book_timezone: String(tz || ''),
          alias: debriefAlias,
        },
        callback: (res, error) => {
          if (res) {
            setModal(res.scheduled_date);
          }

          if (error) {
            if (error.msg.alias === 'busy_time_slot') {
              setBusyTimeSlotAlert(true);
              handleGetScheduleCalendar();
            } else if (error.msg.alias === 'passed_date') {
              setRescheduleAlert(true);
            } else {
              setModalError(error.msg.msg || error.msg.text);
            }
          }
        },
      }),
    );
  };

  const renderAddToCalendar = () => {
    const timeName = calendarTimes.find(c => c.value === time)?.label || '';
    return (
      <div className={'addCalendar'}>
        <div className={'addCalendar__title'}>The Debrief has been scheduled</div>
        <div className={'addCalendar__date'}>
          {dayjs(date).format('MMMM D, YYYY')} at {timeName}
        </div>
      </div>
    );
  };

  const callbackModal = (modal: boolean | null) => {
    if (modal === false) {
      navBack();
    }
  };

  const existDateByCurrentTZ = existDate.map(e => dayjs(e).format('YYYY-MM-DD'));
  const checkIsExistDate = (props: CalendarTileProperties): boolean => {
    const date = dayjs(props.date).format('YYYY-MM-DD');
    return existDateByCurrentTZ.filter(f => f === date).length > 47;
  };
  const isDisableTime = (time: string): boolean => {
    if (!existDate) {
      return false;
    }
    // do not hide the saved scheduling time
    if (
      selectedDebrief &&
      dayjs(selectedDebrief.book_full_date).tz(tz).format('HH:mm:00') === time &&
      dayjs(selectedDebrief?.book_full_date).tz(tz).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
    ) {
      return false;
    }

    const hours: number = Number(time.split(':')[0]);
    const minutes: number = Number(time.split(':')[1]);

    // blocks 24h after last scheduling
    if (
      selectedDebrief &&
      dayjs(date)
        .add(hours, 'h')
        .add(minutes, 'm')
        .isBefore(dayjs(dayjs(completedDate).tz(tz).format('YYYY-MM-DD HH:mm:00')).add(1, 'd'))
    ) {
      return true;
    }
    // blocks 48h before scheduling
    if (
      !selectedDebrief &&
      completedDate &&
      dayjs(date)
        .add(hours, 'h')
        .add(minutes, 'm')
        .isBefore(dayjs(dayjs(completedDate).tz(tz).format('YYYY-MM-DD HH:mm:00')).add(2, 'd'))
    ) {
      return true;
    }

    // when selected scheduled date, hide all times without selected time
    if (
      selectedDebrief &&
      dayjs(selectedDebrief.book_full_date).tz(tz).format('YYYY-MM-DD HH:mm:00') ===
        dayjs(date).tz(tz).format('YYYY-MM-DD HH:mm:00') &&
      selectedDebrief.book_time !== time
    ) {
      return true;
    }

    // hide selected dates
    const convertToGMT = dayjs(date).add(hours, 'h').add(minutes, 'm');
    const convertTimeToGMTFormat = convertToGMT.format('YYYY-MM-DD HH:mm:00');

    /**
     * depending on the alias, check the possibility of choosing the current time.
     * For example: If need to do 60 minutes, and an interval of only 30 is available
     * @return {boolean} Returns the time slot, which after 30 or\and 60 minutes
     * */
    let checkNextHalfDate: string | null = null;
    let checkNextDate: string | null = null;
    switch (calendarAlias) {
      case ScheduleAlias.elm: {
        break;
      }
      case ScheduleAlias.ea: {
        checkNextHalfDate = convertToGMT.add(30, 'm').format('YYYY-MM-DD HH:mm:00');
        break;
      }
      case ScheduleAlias.eaELM: {
        checkNextHalfDate = convertToGMT.add(30, 'm').format('YYYY-MM-DD HH:mm:00');
        checkNextDate = convertToGMT.add(60, 'm').format('YYYY-MM-DD HH:mm:00');
        break;
      }
      default: {
        checkNextHalfDate = convertToGMT.add(30, 'm').format('YYYY-MM-DD HH:mm:00');
        break;
      }
    }

    let checkArray: boolean[] = [];
    if (checkNextHalfDate && checkNextHalfDate !== convertTimeToGMTFormat) {
      checkArray.push(existDate.includes(checkNextHalfDate));
    }
    if (checkNextDate && checkNextDate !== convertTimeToGMTFormat) {
      checkArray.push(existDate.includes(checkNextDate));
    }
    checkArray.push(existDate.includes(convertTimeToGMTFormat));
    return checkArray.includes(true);
  };

  const handleCloseModalError = () => {
    setModalError(null);
  };

  const closeRescheduleAlert = () => {
    setRescheduleAlert(false);
    history.push(ROOTS.EQUITY_AUDIT_LIST.replace(':orgId', `${orgId}`));
  };

  const onActiveStartDateChange: ViewCallback = (props: ViewCallbackProperties) => {
    setIsShowCurrentMonth(dayjs(props.activeStartDate).isSame(props.value, 'month'));
  };

  const renderSubTitle = () => {
    if (calendarAlias === ScheduleAlias.ea) {
      return (
        <>
          {t('main:debrief.60min.p1')}
          <b>{t('main:debrief.60min.p2')}</b>
          {t('main:debrief.60min.p3')}
        </>
      );
    } else if (calendarAlias === ScheduleAlias.elm) {
      return (
        <>
          {t('main:debrief.30min.p1')}
          <b>{t('main:debrief.30min.p2')}</b>
          {t('main:debrief.30min.p3')}
        </>
      );
    } else if (calendarAlias === ScheduleAlias.eaELM) {
      return (
        <>
          {t('main:debrief.90min.p1')}
          <b>{t('main:debrief.90min.p2')}</b>
          {t('main:debrief.90min.p3')}
        </>
      );
    } else {
      return null;
    }
  };

  if (!calendarAlias) {
    return <Loader fitParent />;
  }

  return (
    <>
      <span className="b-panelItem__val align-self-baseline mb-3 font-weight-normal">{renderSubTitle()}</span>
      <div className="b-calendar__content">
        <div className={'b-calendar'}>
          <Calendar
            locale={'en'}
            calendarType={'US'}
            formatShortWeekday={(locale: string, date: Date) => {
              return dayjs(date).format('dd');
            }}
            tileDisabled={(props: CalendarTileProperties) => {
              if (
                selectedDebrief &&
                dayjs(selectedDebrief?.book_date).format('YYYY-MM-DD') ===
                  dayjs(props.date).format('YYYY-MM-DD')
              ) {
                return false;
              }
              if (checkIsExistDate(props)) {
                return true;
              }
              if (minDate && minDate.isAfter(dayjs(props.date))) {
                return true;
              }
              return !!(maxDate && maxDate.isBefore(props.date));
            }}
            tileClassName={(props: CalendarTileProperties) => {
              return checkIsExistDate(props) ? 'react-calendar__month-view__days__day-exist' : '';
            }}
            onChange={changeDate}
            onActiveStartDateChange={onActiveStartDateChange}
            value={date}
            minDetail={'month'}
            prev2Label={null}
            next2Label={null}
            nextLabel={
              <img className={'nextLabel'} width={36} height={36} src={BackIc} alt={t('common:alt.backIc')} />
            }
            prevLabel={<img width={36} height={36} src={BackIc} alt={t('common:alt.backIc')} />}
          />
          <div className={'error-notification'}>
            {errors.date && (
              <span className={'error-notification-text'}>{t('common:errors.date-is-required')}</span>
            )}
          </div>
          <div className={'times'}>
            {isShowCurrentMonth &&
              date &&
              calendarTimes.map(t => {
                const isDisable = isDisableTime(t.value);
                return (
                  <div
                    className={`
                            time 
                            time${t.value === time ? '-active' : ''}
                            time${isDisable ? '-disabled' : ''}
                            `}
                    key={`time-${t.value}-${selectedDebrief?.book_full_date || ''}`}
                    onClick={() => handleSetTime(t.value)}
                  >
                    <span>{t.label}</span>
                  </div>
                );
              })}
            <div className={'error-notification'}>
              {errors.time && (
                <span className={'error-notification-text'}>{t('common:errors.time-is-required')}</span>
              )}
            </div>
          </div>
          <div className={'timeZone'}>
            <TimeZoneSelectView
              value={tz}
              onChange={(value: ScheduleTimeZoneModel) => handleChangeSelect(value)}
              height={52}
              wrapperStyles={{
                width: '332px',
              }}
              error={errors.timeZone}
              placeholder={t('common:label.time-zone')}
              ariaLabel="Time Zone"
            />
          </div>
          <div className={'btns'}>
            <Button
              onPress={onBack}
              title={t('common:btn.cancel')}
              type={'bordered'}
              disabled={false}
              size={'middle'}
            />
            <Button
              onPress={onSubmit}
              title={t('common:btn.schedule')}
              type={'orange-light'}
              disabled={!(date && time && isShowCurrentMonth)}
              size={'middle'}
            />
          </div>
          {date && modal && (
            <AddToCalendarDebrief
              date={new Date(modal.book_full_date)}
              alias={calendarAlias}
              customOpen={!!modal}
              customTitle={renderAddToCalendar()}
              callbackModal={callbackModal}
            />
          )}
          {modalError && (
            <ModalView
              isOpen={true}
              onRequestClose={handleCloseModalError}
              customContentStyles={{
                width: '350px',
              }}
            >
              <div className={'b-filtersModal__mobileModal'}>
                <h2>{t('common:errors.error')}</h2>
                <p>{modalError}</p>
              </div>
            </ModalView>
          )}
          {busyTimeSlotAlert && (
            <Alert
              isOpen={busyTimeSlotAlert}
              onRequestClose={() => setBusyTimeSlotAlert(false)}
              title={t('awa:N28.title')}
              text={t('awa:N28.msg')}
              buttons={{
                left: {
                  title: t('common:btn.ok'),
                  type: 'orange-light',
                  onClick: () => setBusyTimeSlotAlert(false),
                  enableEnter: true,
                },
              }}
            />
          )}
          {rescheduleAlert && (
            <Alert
              isOpen={rescheduleAlert}
              onRequestClose={() => closeRescheduleAlert()}
              title={t('awa:N24.title')}
              text={t('awa:N24.msg')}
              buttons={{
                left: {
                  title: t('common:btn.ok'),
                  type: 'orange-light',
                  onClick: () => closeRescheduleAlert(),
                  enableEnter: true,
                },
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CalendarView;
