import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Select, Tooltip } from '../../../controls';
import styles from './AddUserRoleStyles.module.scss';
import { useSelector } from 'react-redux';
import { CreateOrgModel, IRouteParamsBase, KeyValueModel, ISelectOptions } from '../../../../models';
import { StateType } from '../../../../store/reducers';
import { getUserRoleList, validate } from '../../../../helpers';
import { ROOTS } from '../../../../routes/routes';
import RadioSelect from '../../../controls/RadioSelect/RadioSelect';
import { isMobile } from 'react-device-detect';
import { Modal } from '../../../common';
import { useTranslation } from 'react-i18next';

interface OrganizationModel {
  label: string;
  value: string | number;
  disabled?: boolean;
  parent_org_name?: string;
}

interface IState {
  formData: CreateOrgModel;
  errors: KeyValueModel<string> | null;
  userTypes: OrganizationModel[];
}

interface IProps {
  saveAffiliation: (formData: CreateOrgModel) => void;
  handleClosePopup: () => void;
}

const validator = {
  required: ['role_id', 'type_id'],
};

const AddUserRole = ({ saveAffiliation, handleClosePopup }: IProps) => {
  const { t } = useTranslation();
  const { orgId } = useParams<IRouteParamsBase>();
  const history = useHistory();

  const currentOrg = useSelector((state: StateType) => state.organization.currentOrg);
  const userTypesSelector = useSelector((state: StateType) => state.utils.data.userTypesRoles);

  const user = useSelector((state: StateType) => state.profile.authUser);
  const initialState: IState = {
    formData: {
      role_id: null,
      type_id: null,
    },
    errors: {},
    userTypes: [],
  };

  const [state, setState] = useState<IState>(initialState);
  const [modal, setModal] = useState<boolean>(false);
  const { errors, formData, userTypes } = state;

  useEffect(() => {
    if (!currentOrg) {
      return;
    }
    setState({
      ...state,
      formData: {
        ...state.formData,
        org_id: currentOrg.id,
      },
    });
  }, [currentOrg]);

  useEffect(() => {
    const userTypes =
      userTypesSelector?.map(r => {
        return { label: r.name, value: r.id };
      }) || [];
    setState({
      ...state,
      userTypes: userTypes,
    });
  }, [userTypesSelector]);

  useEffect(() => {
    const userTypes =
      userTypesSelector?.map(r => {
        return { label: r.name, value: r.id };
      }) || [];

    setState({
      ...state,
      userTypes: userTypes,
    });
  }, [user, userTypesSelector]);

  const handleSubmit = () => {
    const { errors } = validate(validator, state.formData);
    setState({
      ...state,
      errors: errors,
    });
    if (!Object.values(errors || {}).length) {
      saveAffiliation(formData);
    }
  };

  const handleCancel = () => {
    handleClosePopup();
  };

  const handleChangeSelect = (name: string, data: ISelectOptions) => {
    if (data.value === 'create_sub_org') {
      history.push(ROOTS.CREATE_SUB_ORG.replace(':orgId', `${orgId}`));
    }
    if (errors && errors[name]) {
      delete errors[name];
    }
    setState({
      ...state,
      formData: {
        ...state.formData,
        [name]: data.value,
      },
      errors: errors,
    });
  };

  const userRoleList = getUserRoleList(userTypesSelector, formData.type_id);

  const handleModal = () => {
    setModal(!modal);
  };

  const roleContentRender = () => {
    return (
      <div className={styles.infoPopup}>
        {!isMobile && <strong className={styles.infoPopup_title}>{t('awa:M11.msg.p1')}</strong>}
        <div className={styles.infoPopup_item}>
          <span className={styles.infoPopup_subTitle}>{t('awa:M11.msg.p2.title')}</span>
          <p>{t('awa:M11.msg.p2.msg')}</p>
        </div>
        <div className={styles.infoPopup_item}>
          <span className={styles.infoPopup_subTitle}>{t('awa:M11.msg.p3.title')}</span>
          <p>{t('awa:M11.msg.p3.msg')}</p>
        </div>
        <div className={styles.infoPopup_item}>
          <span className={styles.infoPopup_subTitle}>{t('awa:M11.msg.p4.title')}</span>
          <p>{t('awa:M11.msg.p4.msg')}</p>
        </div>
        <div className={styles.infoPopup_item}>
          <span className={styles.infoPopup_subTitle}>{t('awa:M11.msg.p5.title')}</span>
          <p>{t('awa:M11.msg.p5.msg')}</p>
        </div>
        <div className={styles.infoPopup_item}>
          <span className={styles.infoPopup_subTitle}>{t('awa:M11.msg.p6.title')}</span>
          <p>{t('awa:M11.msg.p6.msg')}</p>
        </div>
        <div className={styles.infoPopup_item}>
          <span className={styles.infoPopup_subTitle}>{t('awa:M11.msg.p7.title')}</span>
          <p>{t('awa:M11.msg.p7.msg')}</p>
        </div>
        <div className={styles.infoPopup_item}>
          <span className={styles.infoPopup_subTitle}>{t('awa:M11.msg.p8.title')}</span>
          <p>{t('awa:M11.msg.p8.msg')}</p>
        </div>
      </div>
    );
  };

  return (
    <main className={'pageContainer pageContainer__content'}>
      <div>
        <div>
          <div className={styles.form}>
            <div className={styles.form__part}>
              <Select
                value={(formData.type_id && userTypes.find(f => f.value === formData.type_id)) || null}
                placeholder={t('common:label.user-type')}
                options={userTypes.filter(f => f.value !== 1) || []}
                error={(errors && errors.type_id) || ''}
                handleChange={(value: ISelectOptions) => handleChangeSelect('type_id', value)}
                height={52}
              />
            </div>
          </div>
          <div className={styles.form__additional}>
            <div className={styles.form__additionalCol}>
              <div className={styles.form__suborganization}>
                <div className={styles.form__suborganization_opener}>
                  <span>User Role</span>
                  {!isMobile ? (
                    <Tooltip
                      title={roleContentRender()}
                      place={'right'}
                      customProps={{
                        overridePosition: (position: { left: number; top: number }) => {
                          return { left: position.left, top: 0 };
                        },
                      }}
                    >
                      <div className={styles.toolsPanel_button}>
                        <span
                          className={`${styles.toolsPanel__icon} ${styles.toolsPanel__icon_info_outline}`}
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <button className={styles.toolsPanel_button} onClick={handleModal}>
                      <span
                        className={`${styles.toolsPanel__icon} ${styles.toolsPanel__icon_info_outline}`}
                      />
                    </button>
                  )}
                </div>
                <div className={'error-notification'}>
                  <span className={'error-notification-text'}>{errors?.role_id}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.form} ${styles.container_wrapper}`}>
            <div className={styles.form__part}>
              {userRoleList.length ? (
                <RadioSelect
                  value={(formData.role_id && userRoleList.find(f => f.value === formData.role_id)) || null}
                  handleChange={(value: ISelectOptions) => handleChangeSelect('role_id', value)}
                  values={userRoleList}
                  hideDescription={true}
                />
              ) : null}
            </div>
          </div>
          <div className={styles.form__additional}>
            <div className={styles.form__buttons}>
              <Button
                onPress={handleCancel}
                title={t('common:btn.cancel')}
                size={'large'}
                type={'transparency'}
              />
              <Button
                onPress={handleSubmit}
                title={t('common:btn.save')}
                size={'large'}
                type={'orange-light'}
                enableEnter
              />
            </div>
          </div>
        </div>
      </div>
      {modal ? (
        <Modal width={545} onClose={handleModal} classNameBack={styles.modalBack} title={t('awa:M11.msg.p1')}>
          {roleContentRender()}
        </Modal>
      ) : null}
    </main>
  );
};

export default AddUserRole;
