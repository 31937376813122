import { DebriefScheduledDateModel, EAUser, TeamType } from '../models';

export const getUserList = (
  team: number[],
  managers: number[],
  userList: EAUser[],
  teamType: TeamType | null,
): EAUser[] => {
  if (teamType === 'team') {
    if (managers.length) {
      return userList.filter(user => !managers.includes(user.id));
    } else {
      return userList;
    }
  } else {
    if (team.length) {
      return userList.filter(user => !team.includes(user.id));
    } else {
      return userList;
    }
  }
};

export const showViewDebriefBtn = (date: DebriefScheduledDateModel): boolean => {
  const today = new Date();
  let scheduleDate = new Date(date.book_full_date);

  // 3600000 - 1 hour
  const todayInt = today.getTime();
  const scheduleDateInt = scheduleDate.getTime() + scheduleDate.getTimezoneOffset() * 60000;

  const localOffset = (today.getTimezoneOffset() / 60) * -1;
  const offset = Number(date.timezone_offset) / 100;

  const o = localOffset + offset * -1;

  if (todayInt > scheduleDateInt + 3600000 * o + 3600000) {
    return true;
  }
  return false;
};

export const showRescheduleAlert = (date: DebriefScheduledDateModel): boolean => {
  const today = new Date();
  const scheduleDate = new Date(date.book_full_date);

  // 3600000 - 1 hour
  const todayInt = today.getTime();
  const scheduleDateInt = scheduleDate.getTime();

  if (todayInt > scheduleDateInt - 24 * 3600000) {
    return true;
  }

  return false;
};
