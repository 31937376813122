import React, { FC } from 'react';
import { Modal } from '../index';
import { Button } from '../../controls';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClose: () => void;
}

const CompareAgainstGlobalModal: FC<IProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      width={545}
      onClose={onClose}
      className={'b-chart__modal'}
      classNameTitle={'b-chart__modal__title'}
      title={t('awa:M55.title')}
    >
      <div>
        <p>{t('awa:M55.msg.p1')}</p>
        <p className={'b-chart__bold'}>{t('awa:M55.msg.p2')}</p>
        <p>{t('awa:M55.msg.p3')}</p>
        <p>{t('awa:M55.msg.p4')}</p>
        <p>{t('awa:M55.msg.p5')}</p>
        <div className={'container-row mt-5'}>
          <Button onPress={onClose} title={t('common:btn.ok')} type={'orange-light'} size={'small'} />
        </div>
      </div>
    </Modal>
  );
};

export default CompareAgainstGlobalModal;
