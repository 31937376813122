import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Select } from '../../../controls';
import styles from './AddAffiliation.module.scss';
import { useSelector } from 'react-redux';
import {
  CreateOrgModel,
  IRouteParamsBase,
  KeyValueModel,
  ISelectOptions,
  OrganizationProfileModel,
} from '../../../../models';
import { StateType } from '../../../../store/reducers';
import { isValidFields, validate } from '../../../../helpers';
import { ROOTS } from '../../../../routes/routes';
import { useTranslation } from 'react-i18next';
import UserRoleSelect from './parts/UserRoleSelect/UserRoleSelect';

interface OrganizationModel {
  label: string;
  value: string | number;
  disabled?: boolean;
  parent_org_name?: string;
}

interface IState {
  formData: CreateOrgModel;
  errors: KeyValueModel<string> | null;
  organizations: OrganizationModel[];
  userTypes: OrganizationModel[];
}

interface IProps {
  saveAffiliation: (formData: CreateOrgModel) => void;
  handleClosePopup: () => void;
  availableOrgs: ISelectOptions[];
  editAffiliationForm?: OrganizationProfileModel;
}

const validator = {
  required: ['org_id', 'role_id', 'type_id'],
};

const AddAffiliation = ({
  saveAffiliation,
  handleClosePopup,
  availableOrgs,
  editAffiliationForm,
}: IProps) => {
  const { t } = useTranslation();
  const { orgId } = useParams<IRouteParamsBase>();
  const history = useHistory();

  const currentOrg = useSelector((state: StateType) => state.organization.currentOrg);
  const userTypesSelector = useSelector((state: StateType) => state.utils.data.userTypesRoles);

  const user = useSelector((state: StateType) => state.profile.authUser);
  const initialState: IState = {
    formData: {
      role_id: null,
      org_id: null,
      type_id: null,
    },
    errors: {},
    organizations: [],
    userTypes: [],
  };

  const [state, setState] = useState<IState>(initialState);
  const { errors, organizations, formData } = state;

  useEffect(() => {
    if (editAffiliationForm) {
      setState(prevState => {
        return {
          ...prevState,
          formData: {
            org_id: editAffiliationForm.organization_id,
            role_id: editAffiliationForm.user_role_id,
            type_id: editAffiliationForm.user_type_id,
          },
        };
      });
    }
  }, []);

  useEffect(() => {
    if (editAffiliationForm) {
      setState(prevState => {
        return {
          ...prevState,
          formData: {
            org_id: editAffiliationForm.organization_id,
            role_id: editAffiliationForm.user_role_id,
            type_id: editAffiliationForm.user_type_id,
          },
        };
      });
    }
  }, []);

  useEffect(() => {
    if (!currentOrg) {
      return;
    }
    setState(prevState => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          org_id: currentOrg.id,
        },
      };
    });
  }, [currentOrg]);

  useEffect(() => {
    const userTypes =
      userTypesSelector?.map(r => {
        return { label: r.name, value: r.id };
      }) || [];
    setState(prevState => {
      return {
        ...prevState,
        userTypes: userTypes,
      };
    });
  }, [userTypesSelector]);

  useEffect(() => {
    const organizations: OrganizationModel[] = [...(availableOrgs || [])];
    organizations.push({ label: '—', value: 0, disabled: true });
    organizations.push({ label: 'Create New Sub-Organization', value: 'create_sub_org' });

    const userTypes =
      userTypesSelector?.map(r => {
        return { label: r.name, value: r.id };
      }) || [];

    setState(prevState => {
      return {
        ...prevState,
        organizations: organizations,
        userTypes: userTypes,
      };
    });
  }, [user, userTypesSelector]);

  const handleSubmit = () => {
    const { errors } = validate(validator, state.formData);
    setState({
      ...state,
      errors: errors,
    });
    if (!Object.values(errors || {}).length) {
      saveAffiliation(formData);
    }
  };

  const handleCancel = () => {
    handleClosePopup();
  };

  const handleChangeSelect = (name: string, data: ISelectOptions) => {
    if (data.value === 'create_sub_org') {
      history.push(ROOTS.CREATE_SUB_ORG.replace(':orgId', `${orgId}`));
    }
    if (errors && errors[name]) {
      delete errors[name];
    }
    setState({
      ...state,
      formData: {
        ...state.formData,
        [name]: data.value,
      },
      errors: errors,
    });
  };

  const organizationsList = organizations.map(org => {
    return {
      label: `${
        org.parent_org_name && org.parent_org_name !== t('common:column.n-a')
          ? `${org.parent_org_name} - `
          : ''
      }${org.label}`,
      value: org.value,
      disabled: org.disabled,
    };
  });

  const isDisableButton = isValidFields(validator, state.formData);
  return (
    <main className={'pageContainer pageContainer__content'}>
      <div className={styles.form}>
        <div className={styles.form__part}>
          {!editAffiliationForm ? (
            <Select
              value={(formData.org_id && organizations.find(f => f.value === formData.org_id)) || null}
              placeholder={t('common:label.organization')}
              ariaLabel="Organization"
              options={organizationsList || []}
              error={(errors && errors.org_id) || ''}
              handleChange={(value: ISelectOptions) => handleChangeSelect('org_id', value)}
              height={52}
              autoFocus
            />
          ) : null}
        </div>
      </div>
      <UserRoleSelect handleChangeSelect={handleChangeSelect} formData={formData} errors={errors} />
      <div className={styles.form__additional}>
        <div className={styles.form__buttons}>
          <Button
            onPress={handleCancel}
            title={t('common:btn.cancel')}
            size={'large'}
            type={'transparency'}
          />
          <Button
            onPress={handleSubmit}
            title={t('common:btn.save')}
            size={'large'}
            type={'orange-light'}
            disabled={isDisableButton}
            enableEnter
          />
        </div>
      </div>
    </main>
  );
};

export default AddAffiliation;
