import React, { FC, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import DatePicker from 'react-datepicker';
import { AddToCalendar, AddToCalendarDebrief, Alert, HeadlineOrganization, Modal } from '../../../../common';
import { Button, Checkbox, EAStatusLabel, Loader } from '../../../../controls';
import { useHistory, useParams } from 'react-router';
import { EAStatuses, EAUser, EquityAuditModel, IRouteParamsBase, TeamType } from '../../../../../models';
import {
  getEADetails,
  getUsersListForCreate,
  sendNotification,
  updateEA,
  UpdateEAPayload,
} from '../../../../../store/equityAudit/equityAuditActions';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { ROOTS } from '../../../../../routes/routes';
import {
  convertUserStatus,
  debriefFormat,
  filterByArray,
  showRescheduleAlert,
  showViewDebriefBtn,
  getUserList,
  basicDateFormat,
  getBasicDateFormatCodeForDatePicker,
  getDebriefDateTime,
} from '../../../../../helpers';
import { useRole } from '../../../../../helpers/roles';
import { ScheduleAlias, EA_ROLES } from '../../../../../constants';
import { useTranslation } from 'react-i18next';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import { useSelectCurrentOrg } from '../../../../../hooks/org/use-select-current-org';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';
import { Link as RouterLink } from 'react-router-dom';

interface IRouteParams extends IRouteParamsBase {
  eaId: string;
}

interface IState {
  selectedManagers: number[];
  selectedEATeam: number[];
  tempUsers: number[];
  modalType: TeamType | null;
  tipType: TeamType | null;
  data: EquityAuditModel | null;
}

const EADetails: FC = () => {
  const { t } = useTranslation();
  const { orgId, eaId } = useParams<IRouteParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const usersList = useSelector((state: StateType) => state.equityAudit.usersList);
  let [loading, setLoading] = useState(false);
  const access = useRole();
  const isPm = access(['project_manager']);
  let [rescheduleAlert, setRescheduleAlert] = useState(false);
  let [notMobileAlert, setNotMobileAlert] = useState(false);
  const { navBack } = useNavBack();
  const currentOrg = useSelectCurrentOrg();
  const user = useSelector((state: StateType) => state.profile.authUser);
  const isBeloved = user?.beloved_user;

  const initialState: IState = {
    data: null,
    selectedManagers: [],
    selectedEATeam: [],
    tempUsers: [],
    modalType: null,
    tipType: null,
  };

  let [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    dispatch(
      getEADetails({
        eaId: Number(eaId),
        isDetailsPage: true,
        callback: data => {
          if (data) {
            setState(prevState => ({ ...prevState, data }));
          }
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (isPm) {
      dispatch(getUsersListForCreate());
    }
  }, [isPm]);

  const handleResume = (eaId: number) => {
    history.push(ROOTS.CREATE_EA.replace(':orgId', orgId), { eaId });
  };

  const handleTake = (eaId: number) => {
    history.push(ROOTS.TAKING_EA.replace(':orgId', orgId), { eaId });
  };

  const handleSelectUser = (userId: number, teamType: TeamType) => {
    if (teamType === 'team') {
      if (state.selectedEATeam.includes(userId)) {
        const result = [...state.selectedEATeam].filter(id => id !== userId);
        setState({ ...state, selectedEATeam: result });
      } else {
        setState({ ...state, selectedEATeam: [...state.selectedEATeam, userId] });
      }
    } else {
      if (state.selectedManagers.includes(userId)) {
        const result = [...state.selectedManagers].filter(id => id !== userId);
        setState({ ...state, selectedManagers: result });
      } else {
        setState({ ...state, selectedManagers: [...state.selectedManagers, userId] });
      }
    }
  };

  const selectUserToAdd = (dest: 'team' | 'manager', userId: number) => {
    setState(prevState => {
      let newTeam = [...prevState.tempUsers];
      if (newTeam.includes(userId)) {
        newTeam = newTeam.filter(item => item !== userId);
      } else {
        newTeam.push(userId);
      }
      return {
        ...prevState,
        tempUsers: newTeam,
      };
    });
  };

  const handleAddUser = () => {
    let data: UpdateEAPayload['data'] = {};
    if (state.modalType === 'team') {
      data.audit_equity_team_ids = state.tempUsers;
    } else {
      data.audit_managers_ids = state.tempUsers;
    }
    setLoading(true);
    dispatch(
      updateEA({
        eaId: Number(eaId),
        data,
        isDetails: true,
        callback: data => {
          setLoading(false);
          setState(prevState => {
            const newState = {
              ...prevState,
              tempUsers: [],
              modalType: null,
            };
            if (data) {
              newState.data = data;
            }
            return newState;
          });
        },
      }),
    );
  };

  const closeUserModal = () => {
    setState({ ...state, modalType: null, tempUsers: [] });
  };

  const openUserModal = (modalType: TeamType) => (e: any) => {
    e.preventDefault();
    if (state.data) {
      if (modalType === 'team' && usersList && !usersList.length) {
        setState({
          ...state,
          tipType: 'team_empty',
        });
        return;
      }
      if (modalType === 'managers' && usersList && !usersList.length) {
        setState({
          ...state,
          tipType: 'managers_empty',
        });
        return;
      }
      setState(prevState => {
        const newState = { ...prevState, modalType };
        if (prevState.data) {
          if (modalType === 'managers') {
            newState.tempUsers = prevState.data.audit_managers.map(user => user.id);
          } else if (modalType === 'team') {
            newState.tempUsers = prevState.data.audit_equity_team.map(user => user.id);
          }
        }
        return newState;
      });
    }
  };

  const handleDeleteUser = (teamType: TeamType, ea: EquityAuditModel) => {
    let data: UpdateEAPayload['data'] = {};
    if (teamType === 'team') {
      data.audit_equity_team_ids = filterByArray(
        [...ea.audit_equity_team].map(user => user.id),
        state.selectedEATeam,
      ) as number[];
    } else {
      data.audit_managers_ids = filterByArray(
        [...ea.audit_managers].map(user => user.id),
        state.selectedManagers,
      ) as number[];
    }
    setLoading(true);
    dispatch(
      updateEA({
        eaId: Number(eaId),
        data,
        isDetails: true,
        callback: data => {
          setState(prevState => {
            const newState = { ...prevState };
            if (teamType === 'team') {
              newState.selectedEATeam = [];
            } else {
              newState.selectedManagers = [];
            }
            if (data) {
              newState.data = data;
            }
            return newState;
          });
          setLoading(false);
        },
      }),
    );
  };

  const handleSendNotification = (teamType: TeamType) => {
    setLoading(true);
    dispatch(
      sendNotification({
        target: teamType,
        ea_id: Number(eaId),
        userIds: teamType === 'team' ? state.selectedEATeam : state.selectedManagers,
        callback: () => {
          setState(prevState => {
            const newState = { ...prevState };
            if (teamType === 'team') {
              newState.selectedEATeam = [];
            } else {
              newState.selectedManagers = [];
            }
            return newState;
          });
          setLoading(false);
        },
      }),
    );
  };

  const handleChangeDate = (date: Date) => {
    setLoading(true);
    dispatch(
      updateEA({
        eaId: Number(eaId),
        data: {
          scheduled_date: date,
        },
        isDetails: true,
        callback: data => {
          if (data) {
            setState(prevState => ({ ...prevState, data }));
          }
          setLoading(false);
        },
      }),
    );
  };

  const hideTip = () => {
    setState({ ...state, tipType: null });
  };

  const navToReports = (eaId: number) => {
    history.push(ROOTS.REPORTS.replace(':orgId', orgId).replace(':eaId', `${eaId}`));
  };

  const navToViewDebrief = (ea: EquityAuditModel) => {
    if (isMobileOnly) {
      setNotMobileAlert(true);
    } else {
      history.push(ROOTS.VIEW_DEBRIEF.replace(':orgId', orgId).replace(':eaId', `${ea.id}`));
    }
  };

  const handleScheduleDebrief = (ea: EquityAuditModel) => {
    if (ea.debrief_scheduled_date?.book_date) {
      if (showViewDebriefBtn(ea.debrief_scheduled_date)) {
        navToViewDebrief(ea);
        return null;
      }
      if (showRescheduleAlert(ea.debrief_scheduled_date)) {
        setRescheduleAlert(true);
        return null;
      }
    }

    if (ea.debrief) {
      history.push(
        ROOTS.DEBRIEF_SCHEDULING.replace(':debriefAlias', ScheduleAlias.ea)
          .replace(':orgId', orgId)
          .replace(':id', `${ea.id}`),
      );
    } else {
      history.push(ROOTS.STRIPE_SETUP.replace(':orgId', orgId).replace(':eaId', `${ea.id}`), {
        prevPath: location.pathname,
      });
    }
  };

  const showRescheduleBtn = (ea: EquityAuditModel) =>
    ea.debrief_scheduled_date?.book_date && !showViewDebriefBtn(ea.debrief_scheduled_date);

  const debriefBtnName = (ea: EquityAuditModel) => {
    if (ea.debrief_scheduled_date?.book_date) {
      if (showViewDebriefBtn(ea.debrief_scheduled_date)) {
        return t('common:btn.view-debrief');
      }
      return t('common:btn.reschedule-debrief');
    } else if (ea.debrief) {
      return t('common:btn.schedule-debrief');
    } else {
      return t('common:btn.purchase-debrief');
    }
  };

  const renderRightPart = (ea: EquityAuditModel) => {
    if (ea.scheduled_date && ea.ea_status_id < EAStatuses.COMPLETED) {
      return (
        <>
          <div className="b-EADetails__dateHeading" id="EquityAuditDate">
            {t('common:headlines.ea-date')} <small>{t('main:tentative-start-date')}</small>
          </div>
          <div className="b-EADetails__dateRow">
            <div className="b-EADetails__date">
              <DatePicker
                disabled={ea.ea_status_id > EAStatuses.PREP_COMPLETED}
                minDate={new Date()}
                ariaLabelledBy="EquityAuditDate"
                dateFormat={`${getBasicDateFormatCodeForDatePicker()}`}
                selected={new Date(ea.scheduled_date)}
                onChange={handleChangeDate}
              />
            </div>
            <AddToCalendar type={'ea'} startDate={ea.scheduled_date} />
          </div>
        </>
      );
    } else if (ea.ea_status_id === EAStatuses.COMPLETED) {
      if (ea.debrief_scheduled_date?.book_full_date) {
        const handleSchedule = (e: any) => {
          e.preventDefault();
          if (showRescheduleAlert(ea.debrief_scheduled_date)) {
            setRescheduleAlert(true);
            return null;
          }
          history.push(
            ROOTS.DEBRIEF_SCHEDULING.replace(':debriefAlias', ScheduleAlias.ea)
              .replace(':orgId', orgId)
              .replace(':id', `${ea.id}`),
          );
        };
        return (
          <>
            <table className="b-EADetails__detailsList b-EADetails__rightDetails">
              <tr>
                <th className="b-EADetails__debriefMetricLabel">{t('common:label.debrief')}</th>
                <td>
                  {getDebriefDateTime(
                    ea.debrief_scheduled_date.book_full_date,
                    ea.debrief_scheduled_date?.alias || ScheduleAlias.ea,
                  )}
                </td>
              </tr>
              {!showViewDebriefBtn(ea.debrief_scheduled_date) ? (
                <>
                  <tr>
                    <th />
                    <td>
                      {ea.debrief_scheduled_date.alias ? (
                        <AddToCalendarDebrief
                          alias={ea.debrief_scheduled_date.alias}
                          date={new Date(ea.debrief_scheduled_date.book_full_date)}
                        />
                      ) : null}
                    </td>
                  </tr>
                  {isPm && (
                    <tr>
                      <th />
                      <td>
                        <a href="#" onClick={handleSchedule} className="b-EADetails__scheduleLink">
                          <span className="b-EADetails__scheduleLinkIcon" />
                          {t('common:btn.reschedule')}
                        </a>
                      </td>
                    </tr>
                  )}
                </>
              ) : null}
            </table>
          </>
        );
      }
    }
  };

  const renderEABtn = (ea: EquityAuditModel) => {
    const takeEAAccess =
      access(['project_manager']) ||
      (access(['audit_contributor']) && ea.ea_user_type?.id === EA_ROLES.EA_TEAM);

    if (ea.ea_status_id === EAStatuses.IN_PREPARATION && isPm) {
      return <Button onPress={() => handleResume(ea.id)} title={t('common:btn.resume-preparation')} />;
    }
    if (ea.ea_status_id === EAStatuses.PREP_COMPLETED && takeEAAccess) {
      return <Button onPress={() => handleTake(ea.id)} title={t('common:btn.take-audit')} />;
    }
    if (ea.ea_status_id === EAStatuses.IN_PROGRESS && takeEAAccess) {
      return <Button onPress={() => handleTake(ea.id)} title={t('common:btn.resume-audit')} />;
    }
    if (ea.ea_status_id === EAStatuses.COMPLETED) {
      return (
        <>
          {access(['project_manager', 'audit_contributor']) && (
            <Button
              className="button-mh"
              onPress={() => navToReports(ea.id)}
              title={t('common:btn.view-report')}
            />
          )}
          {access(['project_manager', 'project_admin']) &&
            !showRescheduleBtn(ea) &&
            ea.show_purchase_debrief && (
              <Button onPress={() => handleScheduleDebrief(ea)} title={debriefBtnName(ea)} />
            )}
          {access(['project_manager', 'project_admin']) && ea.show_view_debrief && (
            <Button onPress={() => navToViewDebrief(ea)} title={t('common:btn.view-debrief')} />
          )}
        </>
      );
    }
  };

  const renderUpdatedDate = (ea: EquityAuditModel) => {
    if (ea.ea_status_id === EAStatuses.COMPLETED) {
      return ea.completed_date ? basicDateFormat(ea.completed_date) : t('common:column.n-a');
    }
    return ea.last_update ? basicDateFormat(ea.last_update) : t('common:column.n-a');
  };

  const renderContent = () => {
    if (!state.data) {
      return <Loader fitParent />;
    }

    return (
      <>
        <div className="contentContainer b-EADetails__box">
          <div className="b-EADetails__boxCol">
            <EAStatusLabel ea={state.data} />
            <table className="b-EADetails__detailsList">
              <tr>
                <th>{t('common:column.pm')}</th>
                <td>{state.data.audit_pm.name}</td>
              </tr>
              <tr>
                <th>{t('common:column.started')}</th>
                <td>{basicDateFormat(state.data.created_at || '')}</td>
              </tr>
              <tr>
                <th>
                  {state.data.ea_status_id === EAStatuses.COMPLETED
                    ? t('common:column.completed')
                    : t('common:column.last-updated')}
                </th>
                <td>{renderUpdatedDate(state.data)}</td>
              </tr>
            </table>
          </div>
          <div className="b-EADetails__boxCol">{renderRightPart(state.data)}</div>
        </div>
        {state.data.has_recommended_resources || isBeloved ? (
          <RouterLink
            to={ROOTS.LINK_RESOURCE.replace(':entity', 'equity_audit').replace(
              ':entityId',
              `${state.data.id}`,
            )}
            className="d-flex align-items-center mb-4"
          >
            <div className="b-panelTitleOutside__iconWrapper">
              <div className="b-button-icon -colorOrange -noHover -icResources" />
            </div>
            <div className="font-m b-link ml-2 mr-3 font-weight-bold">
              {t('common:btn.recommended-resources')}
            </div>
          </RouterLink>
        ) : null}
        <div className="contentContainer d-lg-flex ">
          <div className="mb-4 b-EADetails__block50">
            <div className="d-flex w-100 mb-4 justify-content-between align-items-center">
              <div className="font-weight-bold font-navy">{t('common:label.Equity-Team')}</div>
              {isPm ? (
                <div>
                  <button
                    disabled={!state.selectedEATeam.length}
                    className="b-EADetails__btn b-EADetails__btn_send"
                    onClick={() => handleSendNotification('team')}
                  >
                    <span className="sr-only">{t('common:btn.send')}</span>
                  </button>
                  <button
                    disabled={!state.selectedEATeam.length}
                    onClick={() => handleDeleteUser('team', state.data as EquityAuditModel)}
                    className="b-EADetails__btn b-EADetails__btn_delete"
                  >
                    <span className="sr-only">{t('common:btn.delete')}</span>
                  </button>
                </div>
              ) : null}
            </div>
            <div className="mb-4">
              {state.data.audit_equity_team.map(user => {
                const isChecked = state.selectedEATeam.includes(user.id);
                return (
                  <div
                    key={`user-ea-${user.id}`}
                    className="d-flex mb-3 justify-content-between align-items-center"
                  >
                    <div className="font-weight-bold font-grey">{user.name}</div>
                    <div className="d-flex align-items-center">
                      <div className="pr-2 font-grey">{convertUserStatus(user.status, user.status_date)}</div>
                      {isPm && (
                        <Checkbox
                          noText
                          checked={isChecked}
                          onChange={() => handleSelectUser(user.id, 'team')}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {state.data.ea_status_id !== EAStatuses.COMPLETED && isPm ? (
              <a onClick={openUserModal('team')} href="#" className="b-link-add">
                {t('common:btn.add-new-member')}
              </a>
            ) : null}
          </div>
          <div className="mb-4 b-EADetails__block50 ml-lg-4">
            <div className="d-flex w-100 mb-4 justify-content-between align-items-center">
              <div className="font-weight-bold font-navy">{t('common:column.managers')}</div>
              {isPm ? (
                <div>
                  <button
                    disabled={!state.selectedManagers.length}
                    onClick={() => handleSendNotification('managers')}
                    className="b-EADetails__btn b-EADetails__btn_send"
                  >
                    <span className="sr-only">{t('common:btn.send')}</span>
                  </button>
                  <button
                    disabled={!state.selectedManagers.length}
                    onClick={() => handleDeleteUser('managers', state.data as EquityAuditModel)}
                    className="b-EADetails__btn b-EADetails__btn_delete"
                  >
                    <span className="sr-only">{t('common:btn.delete')}</span>
                  </button>
                </div>
              ) : null}
            </div>
            <div className="mb-4">
              {state.data.audit_managers.map(user => {
                const isChecked = state.selectedManagers.includes(user.id);
                return (
                  <div
                    key={`user-m-${user.id}`}
                    className="d-flex mb-3 justify-content-between align-items-center"
                  >
                    <div className="font-weight-bold font-grey">{user.name}</div>
                    <div className="d-flex align-items-center">
                      <div className="pr-2 font-grey">{convertUserStatus(user.status, user.status_date)}</div>
                      {isPm ? (
                        <Checkbox
                          noText
                          checked={isChecked}
                          onChange={() => handleSelectUser(user.id, 'managers')}
                        />
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
            {isPm && (
              <a href="#" onClick={openUserModal('managers')} className="b-link-add">
                {t('common:btn.add-new-member')}
              </a>
            )}
          </div>
        </div>
        {isMobileOnly && (
          <React.Fragment>
            <div className="b-EADetails__buttonRow">{renderEABtn(state.data)}</div>
          </React.Fragment>
        )}
      </>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <div className="b-EADetails">
          <div className="b-page__header">
            <HeadlineOrganization
              orgName={currentOrg?.name || ''}
              orgId={currentOrg?.id || ''}
              headline={t('main:ea.ea')}
              customClasses={'-noDesktopMb'}
            />
            {!isMobileOnly ? (
              <div className="b-page__headerBtns">{state.data && renderEABtn(state.data)}</div>
            ) : null}
          </div>
          <h2 className="b-page__title2 -backIcon font-navy">
            <EmptyLink onClick={navBack} className="b-button-icon-back" />
            {t('common:headlines.details')}
          </h2>
          {renderContent()}
        </div>
      </main>
      {state.modalType && (
        <Modal onClose={closeUserModal}>
          <div className={'b-EAModal'}>
            <div className={'b-EAModal__title'}>
              {isMobileOnly && <a onClick={closeUserModal} href="#" className={'b-EAModal__back'} />}
              {state.modalType === 'team'
                ? t('common:headlines.add-member')
                : t('common:headlines.add-managers')}
            </div>
            <div className={`b-step__title`}>{t('common:headlines.organization-users')}</div>
            <div className={`b-step__teamModal__list`}>
              {usersList &&
                state.data &&
                getUserList(
                  state.data.audit_equity_team.map(user => user.id),
                  state.data.audit_managers.map(user => user.id),
                  usersList as EAUser[],
                  state.modalType,
                ).map((item: EAUser) => {
                  return (
                    <div key={`user-${item.id}`} className={`b-step__teamModal__item`}>
                      <div className={'b-equityAudit__text16'}>{item.name}</div>
                      <Checkbox
                        noText
                        onChange={() => selectUserToAdd('team', item.id)}
                        checked={state.tempUsers.includes(item.id)}
                      />
                    </div>
                  );
                })}
            </div>
            <div className={`b-step__teamModal__tip`}>
              <div className={'b-equityAudit__text14'}>*{t('main:add-member.received')}.</div>
            </div>
            <div className="b-EAModal__buttons">
              <Button
                className={'btn -type_form'}
                type={'transparency'}
                onPress={closeUserModal}
                title={t('common:btn.cancel')}
              />
              <Button
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleAddUser}
                title={t('common:btn.save')}
                enableEnter
              />
            </div>
          </div>
        </Modal>
      )}
      {loading && <Loader fitParent />}
      {rescheduleAlert && (
        <Alert
          isOpen={rescheduleAlert}
          onRequestClose={() => setRescheduleAlert(false)}
          title={t('awa:N24.title')}
          text={t('awa:N24.msg')}
          buttons={{
            left: {
              title: t('common:btn.ok'),
              type: 'orange-light',
              onClick: () => setRescheduleAlert(false),
              enableEnter: true,
            },
          }}
        />
      )}
      {notMobileAlert && (
        <Alert
          isOpen={notMobileAlert}
          onRequestClose={() => setNotMobileAlert(false)}
          title={t('awa:M54.title')}
          text={t('awa:M54.msg')}
        />
      )}
      {state.tipType === 'team_empty' || state.tipType === 'managers_empty' ? (
        <Alert
          isOpen={true}
          onRequestClose={hideTip}
          title={t('awa:M100.title')}
          text={state.tipType === 'team_empty' ? t('awa:M100.msg') : t('awa:M101.msg')}
        />
      ) : null}
    </>
  );
};

export default EADetails;
