import * as actionTypes from './dashboardActions';
import { DashboardEAModel, DashboardELMModel, DashboardMapModel } from '../../models';

export type DashboardState = {
  dashboardMap: DashboardMapModel;
  ea: DashboardEAModel | null;
  elm: DashboardELMModel | null;
};

const initialState: DashboardState = {
  dashboardMap: {
    organizations: [],
    organization_teams: [],
  },
  ea: null,
  elm: null,
};

const dashboardReducer = (
  state: DashboardState = initialState,
  action: actionTypes.DashboardActionsTypes,
): DashboardState => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_MAP_SUCCESS: {
      return {
        ...state,
        dashboardMap: {
          organizations: action.payload.organizations,
          organization_teams: action.payload.organization_teams,
        },
      };
    }
    case actionTypes.GET_DASHBOARD_EA_SUCCESS: {
      return {
        ...state,
        ea: action.payload,
      };
    }
    case actionTypes.GET_DASHBOARD_ELM_SUCCESS: {
      return {
        ...state,
        elm: action.payload,
      };
    }
    default:
      return state;
  }
};

export default dashboardReducer;
