import { useDispatch, useSelector } from 'react-redux';
import { SortColumn } from 'react-data-grid';
import React, { useEffect, useState } from 'react';
import { StateType } from '../../store/reducers';
import {
  belGetResourceList,
  getLinkResourceList,
  setLinkResourceListQuery,
  setResourcesQuery,
} from '../../store/beloved/resources/resourcesActions';
import { KeyValueModel, PaginationModel, TableDataEntities } from '../../models';
import { getOrganizationUsers, setQuery as userSetQuery } from '../../store/organization/organizationActions';
import {
  belAvailableResource,
  betGetCohortParticipant,
  getCohortOrganization,
  setQuery as cohortResourceSetQuery,
} from '../../store/cohort/cohortActions';
import {
  setOrgUsersQuery,
  belAtGetOrgUsers,
  belAtGetUsers,
  setUsersQuery,
} from '../../store/beloved/adminTools/adminToolActions';
import {
  belGetEwpList,
  belSetEwpListQuery,
  getEwpReviewItems,
  getEwpUsers,
  getGoalList,
  getKaList,
  getPriorityList,
  getStratsList,
  setEwpReviewItemsQuery,
  setEwpUsersQuery,
  setGoalListQuery,
  setKaListQuery,
  setPriorityListQuery,
  setStratsListQuery,
} from '../../store/ewp/ewpActions';
import { getElmUserList, setQuery as elmUserSetQuery } from '../../store/elm/elmActions';
import { isEmptyObject } from '../../helpers';
import {
  belGetDebriefsList,
  setQuery as debriefsSetQuery,
} from '../../store/beloved/debriefs/debriefsActions';

export type DataTableFilterModel = KeyValueModel<(number | string | boolean)[]>;
export type DataTableDateFilterModel = KeyValueModel<KeyValueModel<(number | string)[]>>;

export interface QueryParamsModel {
  sorting: SortColumn | null;
  search: string;
  page_size: number;
  page: number;
  filters?: DataTableFilterModel;
  dateFilters?: DataTableDateFilterModel;
  organization_id?: number;
}

export type ReqParamsModel = KeyValueModel<number | string>;

type GetDataActionPayload = {
  params: ReqParamsModel;
  callback?: () => void;
  extraData?: any;
};

type GetDataAction = (payload: GetDataActionPayload) => any;

function useTableData<ED = any>(entityName: string, initExtraData?: ED) {
  const dispatch = useDispatch();
  let data: PaginationModel<any[]> | null;
  let query: QueryParamsModel;
  let setQuery: (query: QueryParamsModel) => any;
  let getDataAction: GetDataAction;

  let [selectedEntitiesIds, setSelectedEntitiesIds] = useState<number[]>([]);
  let [isFiltersChanged, setIsFiltersChanged] = useState(false);
  let [extraData, setExtraData] = useState<ED | undefined | null>(initExtraData);
  let [infiniteList, setInfiniteList] = useState<any[]>([]);

  if (
    entityName === TableDataEntities.RESOURCES ||
    entityName === TableDataEntities.RESOURCES_FAVORITE ||
    entityName === TableDataEntities.RESOURCES_RECOMMEND ||
    entityName === TableDataEntities.ORG_RESOURCES
  ) {
    data = useSelector((state: StateType) => state.belResource.resourceList);
    query = useSelector((state: StateType) => state.belResource.resourcesQuery);
    getDataAction = belGetResourceList;
    setQuery = setResourcesQuery;
  } else if (entityName === TableDataEntities.ELM_USER_LIST) {
    data = useSelector((state: StateType) => state.elm.elmUserList);
    query = useSelector((state: StateType) => state.elm.query);
    getDataAction = getElmUserList;
    setQuery = elmUserSetQuery;
  } else if (entityName === TableDataEntities.ORG_USERS) {
    data = useSelector((state: StateType) => state.organization.currentOrgUsers);
    query = useSelector((state: StateType) => state.organization.query);
    getDataAction = getOrganizationUsers;
    setQuery = userSetQuery;
  } else if (entityName === TableDataEntities.DEBRIEFS) {
    data = useSelector((state: StateType) => state.belDebriefs.debriefsList);
    query = useSelector((state: StateType) => state.belDebriefs.query);
    getDataAction = belGetDebriefsList;
    setQuery = debriefsSetQuery;
  } else if (entityName === TableDataEntities.COHORT_RESOURCES) {
    data = useSelector((state: StateType) => state.cohort.cohortResourceList);
    query = useSelector((state: StateType) => state.cohort.query);
    getDataAction = belAvailableResource;
    setQuery = cohortResourceSetQuery;
  } else if (entityName === TableDataEntities.COHORT_PARTICIPANTS) {
    data = useSelector((state: StateType) => state.cohort.cohortParticipantList);
    query = useSelector((state: StateType) => state.cohort.query);
    getDataAction = betGetCohortParticipant;
    setQuery = cohortResourceSetQuery;
  } else if (entityName === TableDataEntities.COHORT_ORGANIZATIONS) {
    data = useSelector((state: StateType) => state.cohort.cohortOrganizationalList);
    query = useSelector((state: StateType) => state.cohort.query);
    getDataAction = getCohortOrganization;
    setQuery = cohortResourceSetQuery;
  } else if (entityName === TableDataEntities.LINK_RESOURCE_LIST) {
    data = useSelector((state: StateType) => state.belResource.linkResource.linkResourceList);
    query = useSelector((state: StateType) => state.belResource.linkResource.linkResourceQuery);
    getDataAction = getLinkResourceList;
    setQuery = setLinkResourceListQuery;
  } else if (entityName === TableDataEntities.AT_ORG_USERS) {
    data = useSelector((state: StateType) => state.belAdminTool.orgUsers);
    query = useSelector((state: StateType) => state.belAdminTool.orgUsersQuery);
    getDataAction = belAtGetOrgUsers;
    setQuery = setOrgUsersQuery;
  } else if (entityName === TableDataEntities.AT_BEL_USERS) {
    data = useSelector((state: StateType) => state.belAdminTool.users);
    query = useSelector((state: StateType) => state.belAdminTool.usersQuery);
    getDataAction = belAtGetUsers;
    setQuery = setUsersQuery;
  } else if (entityName === TableDataEntities.EWP_PRIORITY_LIST) {
    data = useSelector((state: StateType) => state.ewp.priorityList);
    query = useSelector((state: StateType) => state.ewp.priorityListQuery);
    getDataAction = getPriorityList;
    setQuery = setPriorityListQuery;
  } else if (entityName === TableDataEntities.EWP_USERS) {
    data = useSelector((state: StateType) => state.ewp.ewpUsers);
    query = useSelector((state: StateType) => state.ewp.ewpUsersQuery);
    getDataAction = getEwpUsers;
    setQuery = setEwpUsersQuery;
  } else if (entityName === TableDataEntities.EWP_GOAL_LIST) {
    data = useSelector((state: StateType) => state.ewp.goalList);
    query = useSelector((state: StateType) => state.ewp.goalListQuery);
    getDataAction = getGoalList;
    setQuery = setGoalListQuery;
  } else if (entityName === TableDataEntities.EWP_STRATS_LIST) {
    data = useSelector((state: StateType) => state.ewp.stratsList);
    query = useSelector((state: StateType) => state.ewp.stratsListQuery);
    getDataAction = getStratsList;
    setQuery = setStratsListQuery;
  } else if (entityName === TableDataEntities.EWP_KA_LIST) {
    data = useSelector((state: StateType) => state.ewp.kaList);
    query = useSelector((state: StateType) => state.ewp.kaListQuery);
    getDataAction = getKaList;
    setQuery = setKaListQuery;
  } else if (entityName === TableDataEntities.BEL_EWP_LIST) {
    data = useSelector((state: StateType) => state.ewp.belEwpList);
    query = useSelector((state: StateType) => state.ewp.belEwpListQuery);
    getDataAction = belGetEwpList;
    setQuery = belSetEwpListQuery;
  } else if (entityName === TableDataEntities.EWP_REVIEW_ITEMS) {
    data = useSelector((state: StateType) => state.ewp.ewpReviewItems);
    query = useSelector((state: StateType) => state.ewp.ewpReviewItemsQuery);
    getDataAction = getEwpReviewItems;
    setQuery = setEwpReviewItemsQuery;
  }

  //@ts-ignore
  let [filterData, setFilterData] = useState<DataTableFilterModel>(query.filters);
  //@ts-ignore
  let [sortingData, setSortingData] = useState<QueryParamsModel['sorting']>(query.sorting);

  useEffect(() => {
    if (data) {
      setInfiniteList(prevState => {
        if (data) {
          return data.current_page === 1 ? data.result : [...prevState, ...data.result];
        }
        return prevState;
      });
    } //@ts-ignore
  }, [data?.result]);

  const fetchData = (query: QueryParamsModel, extra?: ED, callback?: GetDataActionPayload['callback']) => {
    let params: ReqParamsModel = {
      page: query.page,
      page_size: query.page_size,
    };
    if (query.search) {
      params.search = query.search;
    }
    if (query.sorting) {
      params.ordering =
        query.sorting.direction === 'DESC' ? query.sorting.columnKey : `-${query.sorting.columnKey}`;
    }
    if (query.filters) {
      Object.keys(query.filters).forEach(key => {
        //@ts-ignore
        params[key] = query.filters[key].join(',');
      });
    }
    if (!isEmptyObject(query.dateFilters)) {
      //@ts-ignore
      Object.keys(query.dateFilters).forEach(key => {
        //@ts-ignore
        Object.keys(query.dateFilters[key]).forEach(childKey => {
          // const arrParams = query.dateFilters[key][childKey].filter(item => item !== '');
          //@ts-ignore
          const arrParams = query.dateFilters[key][childKey];
          if (arrParams.length) {
            params[childKey] = arrParams.join(',');
          }
        });
      });
    }
    if (query.organization_id) {
      params.organization_id = query.organization_id;
    }
    const payload: GetDataActionPayload = { params, callback };
    if (extra) {
      setExtraData(extra);
    }
    if (extra || extraData) {
      payload.extraData = extra || extraData;
    }
    dispatch(getDataAction(payload));
  };

  const handleChangePage = (page: number) => {
    fetchData({ ...query, page });
    dispatch(setQuery({ ...query, page }));
  };

  const handleChangePageSize = (page_size: number) => {
    fetchData({ ...query, page_size, page: 1 });
    dispatch(setQuery({ ...query, page_size, page: 1 }));
  };

  const handleSearch = (text: string) => {
    const newQuery: QueryParamsModel = { ...query, search: text, page: 1 };
    dispatch(setQuery(newQuery));
    fetchData(newQuery);
  };

  const handleSelectOne = (selectedRows: Set<number>) => {
    // @ts-ignore
    setSelectedEntitiesIds([...selectedRows]);
  };

  const handleChangeDirection = () => {
    const queryParams = { ...query };
    if (!queryParams.sorting) {
      queryParams.sorting = {
        direction: 'ASC',
        columnKey: 'updated_at',
      };
    }
    const sortData: SortColumn = {
      ...queryParams.sorting,
      direction: queryParams.sorting.direction === 'ASC' ? 'DESC' : 'ASC',
    };
    handleSort(sortData);
  };

  const handleSort = (sortData: SortColumn, noSetState?: boolean) => {
    const newQuery: QueryParamsModel = {
      ...query,
      page: 1,
      sorting: sortData,
    };
    setSortingData(sortData);
    fetchData(newQuery);
    if (!noSetState) {
      dispatch(setQuery(newQuery));
    }
  };

  const handleChangeSortField = (field: string) => {
    const newSorting: QueryParamsModel['sorting'] =
      query.sorting?.columnKey === field ? null : { columnKey: field, direction: 'ASC' };
    setSortingData(newSorting);
  };

  const fetchMoreMobile = () => {
    fetchData({ ...query, page: query.page + 1 });
    dispatch(setQuery({ ...query, page: query.page + 1 }));
  };

  const handleSelectOneMob = (e: React.ChangeEvent<HTMLInputElement>, entityId: number) => {
    e.stopPropagation();
    const ids = [...selectedEntitiesIds];
    if (e.target.checked) {
      ids.push(entityId);
    } else {
      const findUserIndex = selectedEntitiesIds.findIndex(f => f === entityId);
      ids.splice(findUserIndex, 1);
    }
    setSelectedEntitiesIds(ids);
  };

  const handleSelectAll = () => {
    const selected: number[] = [];
    if (!data) return null;
    if (selectedEntitiesIds.length !== data.result.length) {
      data.result.map(item => {
        selected.push(item.id);
      });
    }
    setSelectedEntitiesIds(selected);
  };

  const handleApplyFilter = () => {
    const newQuery: QueryParamsModel = {
      ...query,
      filters: filterData,
      page: 1,
    };
    fetchData(newQuery);
    dispatch(setQuery(newQuery));
  };

  const handleClearFilters = () => {
    const newQuery: QueryParamsModel = {
      ...query,
      filters: {},
      page: 1,
    };
    fetchData(newQuery);
    dispatch(setQuery(newQuery));
  };

  const handleSelectFilter = (val: number | string, fieldName: string) => {
    let res = filterData[fieldName] ? [...filterData[fieldName]] : [];
    if (res.includes(val)) {
      res = res.filter(id => id !== val);
    } else {
      res.push(val);
    }

    if (!res.length) {
      const newData = { ...filterData };
      delete newData[fieldName];
      setFilterData(newData);
    } else {
      setFilterData({ ...filterData, [fieldName]: res });
    }

    setIsFiltersChanged(true);
  };

  const handleSelectDateFilter = (newFilters: DataTableDateFilterModel) => {
    const newQuery: QueryParamsModel = {
      ...query,
      dateFilters: {
        ...query.dateFilters,
        ...newFilters,
      },
      page: 1,
    };
    fetchData(newQuery);
    dispatch(setQuery(newQuery));
  };

  return {
    // @ts-ignore
    query, // @ts-ignore
    data, // @ts-ignore
    setQuery,
    fetchData,
    selectedEntitiesIds,
    setSelectedEntitiesIds,
    handleChangePage,
    handleChangePageSize,
    handleSearch,
    handleSelectOne,
    handleSort,
    sortingData,
    handleClearFilters,
    handleChangeDirection,
    handleChangeSortField,
    fetchMoreMobile,
    handleSelectOneMob,
    handleSelectAll,
    handleApplyFilter,
    handleSelectDateFilter,
    handleSelectFilter,
    isFiltersChanged,
    filterData,
    setExtraData,
    infiniteList,
    setSortingData,
    setFilterData,
  };
}

export default useTableData;
