import React, { FC } from 'react';
import {
  EwpElementApprovalStatuses,
  EwpElementTypes,
  EwpElStatusHistoryItemModel,
  EwpStatuses,
} from '../../../../../models';
import EwpStatusLabel from './EwpStatusLabel';
import GoalStatusLabel from './GoalStatusLabel';
import EwpElementStatusLabel from './EwpElementStatusLabel';
import { Hovered } from '../../../../controls';
import { basicDateFormat } from '../../../../../helpers';
import { useTranslation } from 'react-i18next';

interface IProps {
  type: EwpElementTypes;
  data: {
    approval_status: EwpElementApprovalStatuses;
    status: number;
    ewp_status_id: EwpStatuses;
    has_approved_parents: boolean;
    status_history: EwpElStatusHistoryItemModel[];
  };
}

const ElementStatusCombined: FC<IProps> = ({
  type,
  data: { approval_status, status, ewp_status_id, has_approved_parents, status_history },
}) => {
  const { t } = useTranslation();
  const elementCanBeStarted =
    approval_status === EwpElementApprovalStatuses.APPROVED &&
    ewp_status_id >= EwpStatuses.IN_PROGRESS &&
    has_approved_parents;

  const renderApprovalLabel = () => {
    if (
      status_history.length &&
      approval_status >= EwpElementApprovalStatuses.IN_REVIEW &&
      approval_status <= EwpElementApprovalStatuses.APPROVED
    ) {
      return (
        <Hovered
          content={
            <div className="font-s font-grey">
              <div className="pb-1">
                {t('common:filter.by')} {status_history[0].updated_by}
              </div>
              <div>{basicDateFormat(status_history[0].updated_at)}</div>
            </div>
          }
        >
          <EwpStatusLabel status={approval_status} />
        </Hovered>
      );
    }
    return <EwpStatusLabel status={approval_status} />;
  };

  if (elementCanBeStarted) {
    if (type === EwpElementTypes.priority) {
      return renderApprovalLabel();
    }
    if (type === EwpElementTypes.goal) {
      return <GoalStatusLabel status={status} />;
    }
    return <EwpElementStatusLabel status={status} />;
  }
  return renderApprovalLabel();
};

export default ElementStatusCombined;
