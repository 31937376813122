import { Button } from '../../../../../controls';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EwpPreparationStepEnum, IRouteParamsBase } from '../../../../../../models';
import { ROOTS } from '../../../../../../routes/routes';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

interface IProps {
  setCurrentStep: (step: EwpPreparationStepEnum) => void;
}

export const PreparationEWPIntro2 = ({ setCurrentStep }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { orgId } = useParams<IRouteParamsBase>();

  const navToCreate = () => {
    history.push(ROOTS.EWP_CREATE.replace(':orgId', `${orgId}`));
  };

  return (
    <div className={'b-step__container -centerContent'}>
      <h1 className={'b-step__bigTitle'}>{t('common:headlines.new-ewp')}</h1>
      <div className="b-step__p b-equityAudit__textCenter">
        {t('main:ewp-create.intro-2.msg.p1')}
        <span className={'b-step__orangeBold'}>{t('main:ewp-create.intro-2.msg.p2')}</span>
        {t('main:ewp-create.intro-2.msg.p3')}
      </div>
      <div className="b-step__wBox w-100" style={{ maxWidth: '362px' }}>
        <div className="b-step__numItem">
          <div className="b-step__num">1</div>
          <div className="paragraph">{t('main:ewp-create.intro-2.msg.p4')}</div>
        </div>
        <div className="b-step__numItem">
          <div className="b-step__num">2</div>
          <div className="paragraph">{t('main:ewp-create.intro-2.msg.p5')}</div>
        </div>
        <div className="b-step__numItem">
          <div className="b-step__num">3</div>
          <div className="paragraph">{t('main:ewp-create.intro-2.msg.p6')}</div>
        </div>
        <div className="b-step__numItem">
          <div className="b-step__num">4</div>
          <div className="paragraph">{t('main:ewp-create.intro-2.msg.p7')}</div>
        </div>
      </div>
      <div className="b-step__p b-equityAudit__textCenter">
        {t('main:ewp-create.intro-2.msg.p9')} <b>{t('main:ewp-create.intro-2.msg.p10')}</b>
      </div>
      <div className="b-step__btns">
        <Button
          className={'btn -type_form b-step__mr8'}
          type={'bordered'}
          onPress={() => setCurrentStep(EwpPreparationStepEnum.step1)}
          title={t('common:btn.review-steps')}
        />
        <Button
          className={'btn -type_form '}
          type={'orange-light'}
          onPress={navToCreate}
          title={t('common:btn.create-ewp')}
          enableEnter
        />
      </div>
    </div>
  );
};
