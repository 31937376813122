import React, { FC, useEffect, useState } from 'react';
import { Modal } from '../../../../common';
import { Button, Checkbox, Input, Select } from '../../../../controls';
import { email, validate, isEmptyObject } from '../../../../../helpers';
import {
  BelCreatePmFormModel,
  BelOrgDetailsModel,
  ISelectOptions,
  BelOrgPmModel,
} from '../../../../../models';
import { useDispatch } from 'react-redux';
import { belChangePmActivity, belCreatePm } from '../../../../../store/beloved/adminTools/adminToolActions';
import { belGetOrganizationDetails } from '../../../../../store/beloved/organizations/organizationsActions';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClose: () => void;
  orgData: BelOrgDetailsModel;
}

const PmControl: FC<IProps> = ({ onClose, orgData }) => {
  const validator = {
    required: ['first_name', 'last_name', 'email', 'user_type'],
    custom: [email(['email'])],
  };
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userTypes = [
    { label: 'Internal User', value: 2 },
    { label: 'Consultant', value: 3 },
  ];

  const initForm: BelCreatePmFormModel = {
    first_name: '',
    last_name: '',
    email: '',
    user_type: userTypes[0],
  };

  let [isNewPm, setIsNewPm] = useState(false);
  let [errors, setErrors] = useState<any>({});
  let [form, setForm] = useState<BelCreatePmFormModel>(initForm);

  let [pmList, setOmList] = useState<BelOrgPmModel[]>(orgData.org_pms);

  useEffect(() => {
    if (orgData) {
      setOmList(orgData.org_pms);
    }
  }, [orgData]);

  const handleSave = () => {
    dispatch(
      belChangePmActivity({
        orgId: orgData.id,
        data: pmList.map(user => ({ id: user.id, is_active: user.is_active })),
        callback: () => {
          refreshData();
          onClose();
        },
      }),
    );
  };

  const handleChangeUser = (user: BelOrgPmModel) => {
    const newPmList = pmList.map(pm => {
      if (pm.id === user.id) {
        return {
          ...pm,
          is_active: !pm.is_active,
        };
      }
      return pm;
    });
    setOmList(newPmList);
  };

  const refreshData = () => {
    dispatch(
      belGetOrganizationDetails({
        orgId: orgData.id,
      }),
    );
  };

  const handleChangeInput = (name: string, value: string) => {
    setForm(prevState => ({
      ...prevState,
      [name]: value,
    }));
    setErrors({});
  };

  const handleChangeSelect = (value: ISelectOptions) => {
    setForm(prevState => ({
      ...prevState,
      user_type: value,
    }));
    setErrors({});
  };

  const handleCreateUser = () => {
    let { errors } = validate(validator, form);
    setErrors(errors);

    if (errors && isEmptyObject(errors)) {
      dispatch(
        belCreatePm({
          data: form,
          orgId: orgData.id,
          callback: () => {
            setIsNewPm(false);
            refreshData();
          },
        }),
      );
    }
  };

  return (
    <>
      {isNewPm ? (
        <Modal title={t('common:label.Add-New-PM')} onClose={() => setIsNewPm(false)}>
          <div className="b-modal">
            <div className="b-modal__content">
              <div className="b-userControl__container">
                <Input
                  name={'first_name'}
                  error={errors.first_name}
                  value={form.first_name}
                  type={'text'}
                  placeholder={t('common:label.first-name')}
                  handleChange={(value: string) => handleChangeInput('first_name', value)}
                />
                <Input
                  name={'last_name'}
                  placeholder={t('common:label.last-name')}
                  error={errors.last_name}
                  value={form.last_name}
                  type={'text'}
                  handleChange={(value: string) => handleChangeInput('last_name', value)}
                />
                <Input
                  name={'email'}
                  error={errors.email}
                  value={form.email}
                  type={'text'}
                  placeholder={t('common:label.email')}
                  handleChange={(value: string) => handleChangeInput('email', value)}
                />
                <Select
                  value={form.user_type}
                  placeholder={t('common:label.user-type')}
                  options={userTypes}
                  error={errors.user_type}
                  handleChange={(value: ISelectOptions) => handleChangeSelect(value)}
                  height={52}
                />
              </div>
            </div>
            <div className="b-modal__buttons">
              <Button
                title={t('common:btn.cancel')}
                type="transparency"
                size="large"
                onPress={() => setIsNewPm(false)}
                className="button-mh"
              />
              <Button
                title={t('common:btn.save')}
                size="large"
                onPress={handleCreateUser}
                className="button-mh"
              />
            </div>
          </div>
        </Modal>
      ) : (
        <Modal title={t('common:label.Organization-PMs')} onClose={onClose}>
          <div className="b-modal">
            <div className="b-modal__content">
              <div className="b-userControl__container">
                <div className="b-userControl__list">
                  {pmList.map(user => {
                    return (
                      <div key={`pm-${user.id}`} className="b-userControl__item">
                        <span>
                          {user.first_name} {user.last_name}
                        </span>
                        <Checkbox noText checked={user.is_active} onChange={() => handleChangeUser(user)} />
                      </div>
                    );
                  })}
                </div>
                <div className="b-userControl__addLinkWrapper">
                  <a
                    href="#"
                    className="b-link-add"
                    onClick={e => {
                      e.preventDefault();
                      setIsNewPm(true);
                    }}
                  >
                    {t('common:label.Add-New-PM')}
                  </a>
                </div>
                <div className="b-userControl__text">*{t('main:texts.t1')}</div>
              </div>
            </div>
            <div className="b-modal__buttons">
              <Button
                title={t('common:btn.cancel')}
                type="transparency"
                size="large"
                onPress={onClose}
                className="button-mh"
              />
              <Button title={t('common:btn.save')} size="large" onPress={handleSave} className="button-mh" />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PmControl;
