import {
  CreditModel,
  CreateCreditModel,
  ActionModel,
  CreditOptionsModel,
  UpdateCreditModel,
} from '../../models';
import { actionCreator } from '../actionCreator';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';

//-----GET_CREDITS_OPTIONS----//
export const GET_CREDITS_OPTIONS = 'GET_CREDITS_OPTIONS';
export const getCreditsOptions = actionCreator(GET_CREDITS_OPTIONS);

export const GET_CREDITS_OPTIONS_SUCCESS = 'GET_CREDITS_OPTIONS_SUCCESS';
export const getCreditsOptionsSuccess = actionCreator<CreditOptionsModel>(GET_CREDITS_OPTIONS_SUCCESS);

//-----GET_CREDITS_LIST----//
export const GET_CREDITS_LIST = 'GET_CREDITS_LIST';
export interface GetCreditsListPayload {
  orgId: number;
  callback?: (elms: CreditModel[]) => void;
}
export const getCreditsList = actionCreator<GetCreditsListPayload>(GET_CREDITS_LIST);

export const GET_CREDITS_LIST_SUCCESS = 'GET_CREDITS_LIST_SUCCESS';
export const getCreditsListSuccess = actionCreator<CreditModel[]>(GET_CREDITS_LIST_SUCCESS);

//-----CREATE_CREDIT----//
export const CREATE_CREDIT = 'CREATE_CREDIT';
export interface CreateCreditPayload {
  data: CreateCreditModel;
  callback?: () => void;
}
export const createCredit = actionCreator<CreateCreditPayload>(CREATE_CREDIT);

export const CREATE_CREDIT_SUCCESS = 'CREATE_CREDIT_SUCCESS';
export const createCreditSuccess = actionCreator<CreditModel>(CREATE_CREDIT_SUCCESS);

//-----UPDATE_CREDIT----//
export const UPDATE_CREDIT = 'UPDATE_CREDIT';
export interface UpdateCreditPayload {
  creditId: number;
  data: UpdateCreditModel;
  callback?: (res: CreditModel) => void;
}
export const updateCredit = actionCreator<UpdateCreditPayload>(UPDATE_CREDIT);

export const UPDATE_CREDIT_SUCCESS = 'UPDATE_CREDIT_SUCCESS';
export const updateCreditSuccess = actionCreator<CreditModel>(UPDATE_CREDIT_SUCCESS);

export type CreditActionsTypes =
  | ActionModel<typeof GET_CREDITS_LIST, GetCreditsListPayload>
  | ActionModel<typeof GET_CREDITS_LIST_SUCCESS, CreditModel[]>
  | ActionModel<typeof CREATE_CREDIT, CreateCreditPayload>
  | ActionModel<typeof GET_CREDITS_OPTIONS_SUCCESS, CreditOptionsModel>
  | ActionModel<typeof CREATE_CREDIT_SUCCESS, CreditModel>
  | ActionModel<typeof UPDATE_CREDIT_SUCCESS, CreditModel>
  | ActionModel<typeof LOGOUT_SUCCESS>;
