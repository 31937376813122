import { ISelectOptions } from '../../models';
import Api from '../../services/api';
import { useState } from 'react';

export const useEwpAvailableUsers = (orgId?: number, ewpId?: number) => {
  let [availableUsers, setAvailableUsers] = useState<ISelectOptions[]>([]);

  const getAvailableProjectPM = () => {
    Api.get(`organizations/${orgId}/ewp/available_pm_users/${ewpId || ''}/`).then(res => {
      if (res) {
        setAvailableUsers(res);
      }
    });
  };
  const getAvailableTeam = () => {
    Api.get(`organizations/${orgId}/ewp/available_team/${ewpId || ''}/`).then(res => {
      if (res) {
        setAvailableUsers(res);
      }
    });
  };
  const getAvailableManagers = () => {
    Api.get(`organizations/${orgId}/ewp/available_managers/${ewpId || ''}/`).then(res => {
      if (res) {
        setAvailableUsers(res);
      }
    });
  };
  const getAvailableConsultant = () => {
    Api.get(`organizations/${orgId}/ewp/available_consultants/${ewpId || ''}/`).then(res => {
      if (res) {
        setAvailableUsers(res);
      }
    });
  };

  return {
    getAvailableProjectPM,
    getAvailableTeam,
    getAvailableManagers,
    getAvailableConsultant,
    availableUsers,
    setAvailableUsers,
  };
};
