import React from 'react';
import { SortDirection, useRowSelection } from 'react-data-grid';
import { AdminToolOrgUserModel, ColumnType } from '../../../../../models';
import { basicDateFormat } from '../../../../../helpers';
import { Checkbox } from '../../../../controls';
import i18n from 'i18next';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

export const getColumns = (): ColumnType<AdminToolOrgUserModel>[] => [
  {
    key: 'first_name',
    name: i18n.t('common:column.first'),
    width: 170,
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'last_name',
    name: i18n.t('common:column.last'),
    width: 170,
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'email',
    name: i18n.t('common:column.email'),
    sortable: true,
    editable: false,
    resizable: true,
    headerCellClass: 'b-rdgTable__headerCell',
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'organization_name',
    name: i18n.t('common:column.organization'),
    sortable: true,
    editable: false,
    resizable: true,
    headerCellClass: 'b-rdgTable__headerCell',
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'user_role_name',
    name: i18n.t('common:column.awa-role'),
    sortable: true,
    editable: false,
    resizable: true,
    headerCellClass: 'b-rdgTable__headerCell',
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'user_type_name',
    name: i18n.t('common:column.user-type'),
    sortable: true,
    editable: false,
    resizable: true,
    headerCellClass: 'b-rdgTable__headerCell',
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'status',
    name: i18n.t('common:column.status'),
    width: 120,
    sortable: true,
    editable: false,
    resizable: true,
    formatter(props) {
      return (
        <div className="b-adminToolUsers__statusCell">
          {props.row.status ? (
            <>
              <div>
                {props.row.status} {i18n.t('common:label.on')}
              </div>
              <div>
                {props.row.status_date ? basicDateFormat(props.row.status_date) : i18n.t('common:column.n-a')}
              </div>
            </>
          ) : (
            i18n.t('common:column.n-a')
          )}
        </div>
      );
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'tools',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
  },
  {
    key: 'select',
    name: '',
    width: 40,
    maxWidth: 40,
    disableDrag: true,
    headerRenderer(props) {
      return (
        <Checkbox
          checked={props.allRowsSelected}
          onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
        />
      );
    },
    formatter: props => {
      const [isRowSelected, onRowSelectionChange] = useRowSelection();

      return (
        <div className="b-rdgTable__cellCenter">
          <div className={'checkBox'} onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={isRowSelected}
              onChange={e => {
                onRowSelectionChange({
                  row: props.row,
                  checked: e.target.checked,
                  isShiftClick: false,
                });
              }}
            />
          </div>
        </div>
      );
    },
  },
];
