import React from 'react';
import { useTranslation } from 'react-i18next';
import Illus from '../../../../../assets/images/icons/illus_next_steps.png';
import iconStep2 from '../../../../../assets/images/icons/icon-step2.svg';
import iconStep3 from '../../../../../assets/images/icons/icon-step3.svg';
import iconStep1 from '../../../../../assets/images/icons/icon-step1.svg';

const MarketingPanel = () => {
  const { t } = useTranslation();

  return (
    <div className="b-nextSteps">
      <img className="b-nextSteps__illustration" width={122} height={122} src={Illus} alt={t('awa:AT25')} />
      <div className="b-nextSteps__holder">
        <span className="b-nextSteps__subtitle">{t('awa:M104.title')}</span>
        <h2 className="b-nextSteps__title">{t('awa:M104.msg.p1')}</h2>
        <p>{t('awa:M104.msg.p2')}</p>
        <b>{t('awa:M104.msg.p3')}</b>
        <ul className="b-nextSteps__list">
          <li>
            <div className="b-nextSteps__listIcon -item2" />
            {t('awa:M104.msg.p4')}
          </li>
          <li>
            <div className="b-nextSteps__listIcon -item3" />
            {t('awa:M104.msg.p5')}
          </li>
          <li>
            <div className="b-nextSteps__listIcon -item1" />
            {t('awa:M104.msg.p6')}
          </li>
        </ul>
        <a
          rel="noreferrer"
          target="_blank"
          className="b-nextSteps__linkBtn"
          href="mailto:support@awabybeloved.freshdesk.com?subject=Request for Cohort Information&body=Dear Beloved Team,%0d%0aI'm interested in learning about the Cohorts program. Please, contact me via phone <please add your phone number> or email.%0d%0a%0d%0aThank you!"
        >
          {t('awa:M73.msg.p7')}
        </a>
      </div>
    </div>
  );
};

export default MarketingPanel;
