import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { ChartBar, HeadlineOrganization, Modal } from '../../../../common';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Checkbox, Link, Loader } from '../../../../controls';
import { AvgModel, ChartLabelModel, ChartReportModel, IRouteParamsBase } from '../../../../../models';
import { Modals, ModalsModel, URLChartType } from '../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { getFGChart, getStandardChart } from '../../../../../store/report/reportActions';
import { ROOTS } from '../../../../../routes/routes';
import { StateType } from '../../../../../store/reducers';
import { useTranslation } from 'react-i18next';

interface IState {
  isCompare: boolean;
  isCompareGroup: boolean;
}

const ReportChartBar = () => {
  const { t } = useTranslation();
  const [state, setState] = useState<IState>({
    isCompare: false,
    isCompareGroup: false,
  });
  const [modals, setModals] = useState<ModalsModel>({});
  const { isCompare } = state;
  const history = useHistory();
  const { orgId, eaId, chartId } = useParams<IRouteParamsBase>();
  const [chart, setChart] = useState<ChartReportModel>();
  const standardChartSelect: ChartReportModel | null = useSelector(
    (state: StateType) => state.report.reportsData[Number(eaId)]?.standardChart,
  );
  const isBeloved = useSelector((state: StateType) => state.profile.authUser)?.beloved_user;
  const fgChartSelect: ChartReportModel | null = useSelector(
    (state: StateType) => state.report.reportsData[Number(eaId)]?.FGChart,
  );
  const dispatch = useDispatch();
  const isStandards = chartId === URLChartType.standard;
  const selectedAVG: AvgModel = history.location?.state?.selectedAVG;

  useEffect(() => {
    if (selectedAVG) {
      setState({
        ...state,
        isCompareGroup: true,
      });
    }
    if (chartId === URLChartType.standard && standardChartSelect) {
      getChartSuccess(standardChartSelect);
      return;
    } else if (chartId === URLChartType.group && fgChartSelect) {
      getChartSuccess(fgChartSelect);
      return;
    }
    const body = {
      data: {
        eaId: Number(eaId),
      },
      callback: getChartSuccess,
    };
    if (chartId === URLChartType.standard) {
      dispatch(getStandardChart(body));
    } else if (chartId === URLChartType.group) {
      dispatch(getFGChart(body));
    }
  }, []);

  const getChartSuccess = (chart: ChartReportModel) => {
    if (!chart.is_detailed) {
      history.push(ROOTS.REPORTS.replace(':orgId', orgId).replace(':eaId', `${eaId}`));
      return;
    }
    setChart(chart);
  };

  const handleCompare = () => {
    setState(prevState => ({
      ...prevState,
      isCompare: !prevState.isCompare,
    }));
  };

  const handleCompareGroup = () => {
    setState(prevState => ({
      ...prevState,
      isCompareGroup: !prevState.isCompareGroup,
    }));
  };

  const goBack = (e: any) => {
    e.stopPropagation();
    history.go(-1);
  };

  const openModal = (modal: Modals) => {
    setModals(prevState => ({
      ...prevState,
      [modal]: true,
    }));
  };

  const closeModal = (modal: Modals) => {
    setModals(prevState => ({
      ...prevState,
      [modal]: false,
    }));
  };

  const handleRedirectToAVGList = () => {
    const route = isBeloved
      ? ROOTS.BEL_GROUP_AVERAGES_SAVED_FOR_EAs
      : ROOTS.GROUP_AVERAGES_SAVED_FOR_EAs.replace(':orgId', `${orgId}`);
    history.replace(route, {
      reportEA: {
        orgId,
        eaId,
        chartId,
        selectedAVG: (selectedAVG && selectedAVG.id) || null,
      },
    });
  };

  const handleRowClick = (row: ChartLabelModel) => {
    if (chart && row.id && !chart.is_legacy_audit) {
      history.push(
        ROOTS.REPORT_STANDARD_RESPONSES.replace(':orgId', `${orgId}`)
          .replace(':eaId', `${eaId}`)
          .replace(':standardId', `${row.id}`),
      );
      return;
    }
  };

  const renderContent = () => {
    if (!chart && !((isStandards && standardChartSelect) || (!isStandards && fgChartSelect))) {
      return <Loader fitParent />;
    }
    if (chart) {
      return (
        <main className={'pageContainer pageContainer__content'}>
          <div className="b-report">
            {isMobileOnly && (
              <div className="b-report__notification">
                <b className="b-report__notificationTitle">{t('main:reports.ea-completed.title')}</b>
                <span>{t('main:reports.ea-completed.msg')}</span>
              </div>
            )}

            <div className="b-report__header">
              {isMobileOnly && <div className="b-report__arrow" />}
              <HeadlineOrganization
                headline={t('main:ea.ea')}
                orgName={chart.organization_name || ''}
                orgId={orgId}
                customClasses={'b-report__pageTitle'}
              />
              <div className="b-report__pageSubTitle">
                <Link tabIndex={0} className="b-report__backBtn" onClick={goBack}>
                  <div className="back-icon" />
                </Link>
                <span>{isStandards ? t('main:reports.standard-result') : t('main:reports.fg-result')}</span>
              </div>
            </div>
            <div className={'b-chart__chartWrap'}>
              <div className="b-report__holder">
                <ChartBar
                  chart={chart}
                  isCompare={isCompare}
                  additionalRow={state.isCompareGroup ? selectedAVG?.substandard_chart?.data : undefined}
                  rowClick={handleRowClick}
                />
              </div>
              <div className={'b-chart__compareBlock'}>
                {isStandards ? (
                  <div>
                    <Checkbox
                      disabled={!selectedAVG}
                      onChange={handleCompareGroup}
                      checked={state.isCompareGroup}
                      id="compareCheckGroup"
                      className={'b-chart__checkbox'}
                    >
                      <span className={'b-chart__compareBlock__text'}>
                        {t('common:checkbox.compare-against-group')}
                      </span>
                    </Checkbox>
                    <Link
                      onClick={handleRedirectToAVGList}
                      className="b-report__avg-subTitle orange-light-text ellipse b-link-icon -right"
                    >
                      {selectedAVG?.name || t('common:btn.select-avg')}
                    </Link>
                  </div>
                ) : null}
                <div>
                  <Checkbox
                    onChange={handleCompare}
                    checked={state.isCompare}
                    id="compareCheck"
                    className={'b-chart__checkbox'}
                  >
                    <span className={'b-chart__compareBlock__text'}>
                      {t('common:checkbox.compare-against-global')}
                    </span>
                    <button
                      className="b-report__btnInfo mt-1"
                      onClick={() => openModal(Modals.isGlobalResultInfo)}
                    >
                      <span className="sr-only">{t('common:btn.information')}</span>
                    </button>
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>
          {isStandards && (
            <div className={'b-chart__standards'}>
              <span>{t('main:reports.click-for-responses')}</span>
            </div>
          )}
        </main>
      );
    }
  };

  return (
    <>
      {renderContent()}
      {modals.isGlobalResultInfo ? (
        <Modal
          width={545}
          onClose={() => closeModal(Modals.isGlobalResultInfo)}
          className={'b-chart__modal'}
          classNameTitle={'b-chart__modal__title'}
          title={t('awa:M55.title')}
        >
          <div>
            <p>{t('awa:M55.msg.p1')}</p>
            <p className={'b-chart__bold'}>{t('awa:M55.msg.p2')}</p>
            <p>{t('awa:M55.msg.p3')}</p>
            <p>{t('awa:M55.msg.p4')}</p>
            <p>{t('awa:M55.msg.p5')}</p>
            <div className={'container-row mt-5'}>
              <Button
                onPress={() => closeModal(Modals.isGlobalResultInfo)}
                title={t('common:btn.ok')}
                type={'orange-light'}
                size={'small'}
              />
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default ReportChartBar;
