import React, { FC } from 'react';
import {
  EwpElementApprovalStatuses,
  EwpElementStatuses,
  EwpElementTypes,
  EwpStatuses,
  GoalStatuses,
} from '../../../../../models';

interface IProps {
  type: EwpElementTypes;
  approval_status: EwpElementApprovalStatuses;
  approval_status_name: string;
  status: number;
  status_name: string;
  ewp_status_id: EwpStatuses;
  has_approved_parents: boolean;
}

const ElementTableStatusCombined: FC<IProps> = ({
  type,
  approval_status,
  approval_status_name,
  status,
  status_name,
  ewp_status_id,
  has_approved_parents,
}) => {
  const elementCanBeStarted =
    approval_status === EwpElementApprovalStatuses.APPROVED &&
    ewp_status_id >= EwpStatuses.IN_PROGRESS &&
    has_approved_parents;

  const getStatusClass = (statusId: EwpElementStatuses) => {
    switch (statusId) {
      case EwpElementStatuses.NOT_STARTED:
        return 'color1';
      case EwpElementStatuses.STALLED:
        return 'color2';
      case EwpElementStatuses.NO_LONGER_PURSUING:
        return 'color3';
      case EwpElementStatuses.IN_PROGRESS:
        return 'inProgress';
      case EwpElementStatuses.COMPLETED:
        return 'completed';
    }
  };

  const getApprovalStatusClass = (statusId: EwpElementApprovalStatuses) => {
    switch (statusId) {
      case EwpElementApprovalStatuses.IN_PREP:
        return 'inProgress';
      case EwpElementApprovalStatuses.SUBMITTED_FOR_REVIEW:
        return 'color4';
      case EwpElementApprovalStatuses.IN_REVIEW:
        return 'color2';
      case EwpElementApprovalStatuses.REVIEW_COMPLETED:
        return 'color5';
      case EwpElementApprovalStatuses.APPROVED:
        return 'completed';
    }
  };

  const getGoalStatusClass = (statusId: GoalStatuses) => {
    switch (statusId) {
      case GoalStatuses.NOT_UPDATED_YET:
        return 'color1';
      case GoalStatuses.MET:
        return 'completed';
      case GoalStatuses.NO_CHANGE:
        return 'color2';
      case GoalStatuses.NEGATIVE_GROWTH:
        return 'color3';
      case GoalStatuses.POSITIVE_GROWTH:
        return 'color5';
      case GoalStatuses.NO_LONGER_PURSUING:
        return 'color3';
    }
  };

  if (elementCanBeStarted) {
    if (type === EwpElementTypes.priority) {
      return (
        <span className={`b-tableStatusMark ${getApprovalStatusClass(approval_status)}`}>
          {approval_status_name}
        </span>
      );
    }
    if (type === EwpElementTypes.goal) {
      return <span className={`b-tableStatusMark ${getGoalStatusClass(status)}`}>{status_name}</span>;
    }
    return <span className={`b-tableStatusMark ${getStatusClass(status)}`}>{status_name}</span>;
  }
  return (
    <span className={`b-tableStatusMark ${getApprovalStatusClass(approval_status)}`}>
      {approval_status_name}
    </span>
  );
};

export default ElementTableStatusCombined;
