import React, { FC } from 'react';
import { Modal } from '../index';
import { Button } from '../../controls';
import { useHistory, useParams } from 'react-router';
import { IRouteParamsBase, OrganizationDetailsModel } from '../../../models';
import { ROOTS } from '../../../routes/routes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StateType } from '../../../store/reducers';

interface IProps {
  onClose: () => void;
  noBtn?: boolean;
}

const ElmGuide: FC<IProps> = ({ onClose, noBtn }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { orgId } = useParams<IRouteParamsBase>();
  const currentOrg: OrganizationDetailsModel | null = useSelector(
    (state: StateType) => state.organization.currentOrg,
  );

  const handleStart = () => {
    history.push(ROOTS.CREATE_ELM_INTRO.replace(':orgId', orgId));
  };

  return (
    <Modal width={935} title={t('awa:M84.title')} onClose={onClose}>
      <div className={'b-EAModal'}>
        <div className="b-EAModal__content">
          <p>{t('awa:M84.msg.p1')}</p>
          <p>{t('awa:M84.msg.p2')}</p>
          <p>{t('awa:M84.msg.p3')}</p>
          <strong className="b-EAModal__subTitle">{t('awa:M84.msg.p4')}</strong>
          <p>{t('awa:M84.msg.p5')}</p>
          <p>{t('awa:M84.msg.p6')}</p>

          <dl className="b-EAModal__definitionList">
            <dt>{t('awa:M84.msg.p7')}</dt>
            <dd>{t('awa:M84.msg.p8')}</dd>
            <dt>{t('awa:M84.msg.p9')}</dt>
            <dd>{t('awa:M84.msg.p10')}</dd>
            <dt>{t('awa:M84.msg.p11')}</dt>
            <dd>{t('awa:M84.msg.p12')}</dd>
            <dt>{t('awa:M84.msg.p13')}</dt>
            <dd>{t('awa:M84.msg.p14')}</dd>
            <dt>{t('awa:M84.msg.p15')}</dt>
            <dd>{t('awa:M84.msg.p16')}</dd>
          </dl>

          <p>{t('awa:M84.msg.p17')}</p>

          <dl className="b-EAModal__definitionList">
            <dt>{t('awa:M84.msg.p18')}</dt>
            <dd>{t('awa:M84.msg.p19')}</dd>
            <dt>{t('awa:M84.msg.p20')}</dt>
            <dd>{t('awa:M84.msg.p21')}</dd>
          </dl>

          <strong className="b-EAModal__subTitle">{t('awa:M84.msg.p22')}</strong>

          <p>{t('awa:M84.msg.p23')}</p>

          <ul className="b-EAModal__list">
            <li>{t('awa:M84.msg.p24')}</li>
            <li>{t('awa:M84.msg.p25')} </li>
            <li>{t('awa:M84.msg.p26')} </li>
            <li>{t('awa:M84.msg.p27')}</li>
            <li>{t('awa:M84.msg.p28')}</li>
            <li>{t('awa:M84.msg.p29')}</li>
            <li>{t('awa:M84.msg.p30')} </li>
          </ul>
          {!noBtn && (
            <Button
              disabled={!currentOrg?.has_completed_ea}
              // disabled={true}
              title={t('common:btn.get-started')}
              type={'orange-light'}
              size={'middle'}
              onPress={handleStart}
              className={'button-alignCenter'}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ElmGuide;
