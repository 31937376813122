import Slide2 from '../assets/images/debrief/2.png'; // AT16
import Slide3 from '../assets/images/debrief/3.png';
import Slide4 from '../assets/images/debrief/4.png';
import Slide5 from '../assets/images/debrief/5.png'; // 7, 11, 14, 17, 18
import Slide6 from '../assets/images/debrief/6.png';
import Slide8 from '../assets/images/debrief/8.png';
import Slide9 from '../assets/images/debrief/9.png';
import Slide10 from '../assets/images/debrief/10.png';
import Slide12 from '../assets/images/debrief/12.png';
import Slide13 from '../assets/images/debrief/13.png'; // AT21
import Slide15 from '../assets/images/debrief/15.png';
import Slide16 from '../assets/images/debrief/16.png';
import Slide19 from '../assets/images/debrief/19.png';
import Slide20 from '../assets/images/debrief/20.png';
import Slide2Thumb from '../assets/images/debrief/slide-2.png';
import Slide3Thumb from '../assets/images/debrief/slide-3.png';
import Slide4Thumb from '../assets/images/debrief/slide-4.png';
import Slide5Thumb from '../assets/images/debrief/slide-5.png';
import Slide6Thumb from '../assets/images/debrief/slide-6.png';
import Slide8Thumb from '../assets/images/debrief/slide-8.png';
import Slide9Thumb from '../assets/images/debrief/slide-9.png';
import Slide10Thumb from '../assets/images/debrief/slide-10.png';
import Slide12Thumb from '../assets/images/debrief/slide-12.png';
import Slide13Thumb from '../assets/images/debrief/slide-13.png';
import Slide15Thumb from '../assets/images/debrief/slide-15.png';
import Slide16Thumb from '../assets/images/debrief/slide-16.png';
import Slide19Thumb from '../assets/images/debrief/slide-19.png';
import Slide20Thumb from '../assets/images/debrief/slide-20.png';
import Slide1Thumb from '../assets/images/debrief/first_slide_thumb.png';
import Slide7Thumb from '../assets/images/debrief/slide-7.png';
import ChartThumb from '../assets/images/debrief/slide-11.png';
import ChartThumb2 from '../assets/images/debrief/slide-18.png';

import { DebriefSlideModel } from '../models';
import i18n from 'i18next';

export enum DebriefSlideTypes {
  image = 'image',
  content = 'content',
}

export enum DebriefSlideContentTypes {
  slide_1 = 'slide_1',
  slide_2 = 'slide_2',
  chart_1 = 'chart_1',
  chart_2 = 'chart_2',
  chart_3 = 'chart_3',
  chart_4 = 'chart_4',
}

export const getSlides = (): DebriefSlideModel[] => {
  return [
    {
      type: DebriefSlideTypes.content,
      contentType: DebriefSlideContentTypes.slide_1,
      thumbPath: Slide1Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 1 }),
    },
    {
      imgPath: Slide2,
      type: DebriefSlideTypes.image,
      thumbPath: Slide2Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 2 }),
      alt: i18n.t('awa:AT67'),
    },
    {
      imgPath: Slide3,
      type: DebriefSlideTypes.image,
      thumbPath: Slide3Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 3 }),
      alt: i18n.t('awa:AT68'),
    },
    {
      imgPath: Slide4,
      type: DebriefSlideTypes.image,
      thumbPath: Slide4Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 4 }),
      alt: i18n.t('awa:AT69'),
    },
    {
      imgPath: Slide5,
      type: DebriefSlideTypes.image,
      thumbPath: Slide5Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 5 }),
      alt: i18n.t('awa:AT70'),
    },
    {
      imgPath: Slide6,
      type: DebriefSlideTypes.image,
      thumbPath: Slide6Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 6 }),
      alt: i18n.t('awa:AT71'),
    },
    {
      type: DebriefSlideTypes.content,
      contentType: DebriefSlideContentTypes.slide_2,
      thumbPath: Slide7Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 7 }),
    },
    {
      imgPath: Slide8,
      type: DebriefSlideTypes.image,
      thumbPath: Slide8Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 8 }),
      alt: i18n.t('awa:AT72'),
    },
    {
      imgPath: Slide9,
      type: DebriefSlideTypes.image,
      thumbPath: Slide9Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 9 }),
      alt: i18n.t('awa:AT73'),
    },
    {
      imgPath: Slide10,
      type: DebriefSlideTypes.image,
      thumbPath: Slide10Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 10 }),
      alt: i18n.t('awa:AT74'),
    },
    {
      type: DebriefSlideTypes.content,
      contentType: DebriefSlideContentTypes.chart_1,
      thumbPath: ChartThumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 11 }),
    },
    {
      imgPath: Slide12,
      type: DebriefSlideTypes.image,
      thumbPath: Slide12Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 12 }),
      alt: i18n.t('awa:AT75'),
    },
    {
      imgPath: Slide13,
      type: DebriefSlideTypes.image,
      thumbPath: Slide13Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 13 }),
      alt: i18n.t('awa:AT76'),
    },
    {
      type: DebriefSlideTypes.content,
      contentType: DebriefSlideContentTypes.chart_2,
      thumbPath: ChartThumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 14 }),
    },
    {
      imgPath: Slide15,
      type: DebriefSlideTypes.image,
      thumbPath: Slide15Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 15 }),
      alt: i18n.t('awa:AT77'),
    },
    {
      imgPath: Slide16,
      type: DebriefSlideTypes.image,
      thumbPath: Slide16Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 16 }),
      alt: i18n.t('awa:AT78'),
    },
    {
      type: DebriefSlideTypes.content,
      contentType: DebriefSlideContentTypes.chart_3,
      thumbPath: ChartThumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 17 }),
    },
    {
      type: DebriefSlideTypes.content,
      contentType: DebriefSlideContentTypes.chart_4,
      thumbPath: ChartThumb2,
      altThump: i18n.t('awa:AT50-AT66', { slide: 18 }),
    },
    {
      imgPath: Slide19,
      type: DebriefSlideTypes.image,
      thumbPath: Slide19Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 19 }),
      alt: i18n.t('awa:AT79'),
    },
    {
      imgPath: Slide20,
      type: DebriefSlideTypes.image,
      thumbPath: Slide20Thumb,
      altThump: i18n.t('awa:AT50-AT66', { slide: 20 }),
      alt: i18n.t('awa:AT80'),
    },
  ];
};
